<template>
  <v-date-picker
    style="border-radius: 0.8rem !important"
    :popover="{ visibility: visibility }"
    :first-day-of-week="0"
    ref="calendar"
    :max-date="onlyFilterMonth ? disable_max_date : disableMaxDate"
    @drag="drag"
    :min-date="onlyFilterMonth ? disable_min_date : disableMinDate"
    locale="pt-BR"
    @input="handleFilterDate"
    v-model="range"
    :is-range="isRange"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div class="row">
        <div class="col-12" v-if="isRange">
          <div
            class="d-flex w-100"
            :style="hideAllStyles ? '' : 'height: 30px;'"
          >
            <img
              src="https://img.icons8.com/ios/100/606062/calendar--v1.png"
              :style="hideAllStyles ? 'display: none' : ''"
              :class="
                sizePercentage[size] <= 106
                  ? 'custom-input-date-picker-small mt-0'
                  : 'custom-input-date-picker-normal mt-1'
              "
              class="m-0 text-light vc-custom-clock-icon align-middle pt-2 pl-2 ml-1"
            />
            <input
              style="text-align: center"
              :style="
                hideAllStyles
                  ? `border: none !important; height: ${sizePercentage[size]}%; background: transparent; min-width: 200px;`
                  : `height: ${sizePercentage[size]}%; background: white;`
              "
              :value="
                formatStart(inputValue) +
                (hideAllStyles ? ' ➜ ' : '    ➜    ') +
                inputValue.end
              "
              v-on="inputEvents.start"
              class="form-control"
              :class="disableBoxShadow === true ? 'disable-box-shadow' : ''"
              readonly
              :disabled="disabled"
            />
          </div>
        </div>
        <div class="col-12" v-else>
          <input
            style="text-align: center"
            :style="
              hideAllStyles
                ? `border: none !important; height: 100%; background: transparent;`
                : `height: ${sizePercentage[size]}%;`
            "
            :value="inputValue"
            v-on="inputEvents"
            class="form-control"
            :class="disableBoxShadow && 'readonly-style'"
            readonly
            :disabled="disabled"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div
        v-if="[1].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setStart()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Início
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setToday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Hoje
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="setMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês
            </base-button>
          </div>
        </div>
      </div>
      <div
        v-if="[2].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setAll()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Todos
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês atual
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="setLastMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês anterior
            </base-button>
          </div>
        </div>
      </div>
      <div
        v-if="[3].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setYesterday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Ontem
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setToday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Hoje
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="setTomorrow()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Amanhã
            </base-button>
          </div>
        </div>
      </div>
      <div
        v-if="[4].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setToday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Hoje
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês atual
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="setLastMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês anterior
            </base-button>
          </div>
        </div>
      </div>
      <div
        v-if="[5].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setToday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Hoje
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setTomorrow()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Amanhã
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="currentMonthFromToday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês atual
            </base-button>
          </div>
        </div>
      </div>
      <div
        v-if="[6].includes(filterable)"
        class="bg-gray-100 p-2 border-t rounded-b-lg"
      >
        <div class="row">
          <div class="col pr-1 mt-1">
            <base-button
              @click.prevent="setYesterday()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Ontem
            </base-button>
          </div>
          <div class="col pl-1 pr-1 mt-1">
            <base-button
              @click.prevent="setMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês atual
            </base-button>
          </div>
          <div class="col pl-1 mt-1">
            <base-button
              @click.prevent="setLastMonth()"
              style="border: none"
              block
              size="sm"
              type="primary"
              outline
            >
              Mês anterior
            </base-button>
          </div>
        </div>
      </div>
    </template>
  </v-date-picker>
</template>
<script>
import moment from "moment";

/** Caso ultilize o default_filterable, informe o ref como 'date-picker' **/
export default {
  name: "InputDatePicker",
  data() {
    return {
      sizePercentage: {
        sm: 97,
        small: 105,
        medium: 119,
        md: 136,
        lg: 180,
      },
      disable_min_date: null,
      disable_max_date: null,
      selecting: false,
      range: {
        start: null,
        end: null,
      },
      rangeStartAsNull: {
        start: null,
        end: null,
      },
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
      description: "Desabilitar input",
    },
    onlyFilterMonth: {
      type: Boolean,
      default: () => false,
      description:
        "Habilita o filtro apenas para o mes de referencia (o primeiro clique de filtragem)",
    },
    size: {
      type: String,
      default: "md",
    },
    visibility: {
      type: String,
      default: "hover",
    },
    disableMaxDate: {
      type: [Date, Boolean, String],
      default: null,
    },
    disableMinDate: {
      type: [Date, Boolean, String],
      default: null,
    },
    filterable: {
      required: false,
      default: null,
    },
    isRange: {
      type: Boolean,
      default: true,
    },
    defaultRange: {
      type: [Object, String],
      default: () => ({
        start: moment(new Date()).format("MM/DD/YYYY"),
        end: moment(new Date()).format("MM/DD/YYYY"),
        /** Este campo informa que por padrão o filtro de data será utilizado no backend,
         apenas quando o usuário selecionar uma data.
         Se informado como false, só ira utilizar a data como filtro se o usuário alterar o campo
         **/
        default_filterable: true,
      }),
      description: "Alcance padrão",
    },
    disableBoxShadow: {
      type: Boolean,
      default: false,
      description:
        "Adiciona estilos para desabilitar o box shadow e aumentar border-radius, conforme o novo padrão.",
    },
    hideAllStyles: {
      type: Boolean,
      default: false,
      description:
        "Remove bordas, esconde ícone e define propriedades de estilo para 'unset'. Deixa somente texto visível",
    },
  },
  methods: {
    drag(e) {
      /**
       * Valida se usuário começou a selecionar a data
       */
      if (!this.selecting) {
        let start_at = new Date(e.start);
        let start_day = new Date(
          start_at.getFullYear(),
          start_at.getMonth(),
          1
        );
        let last_day = new Date(
          start_at.getFullYear(),
          start_at.getMonth() + 1,
          0
        );
        this.disable_min_date = moment(start_day).format("YYYY-MM-DD 00:00:00");
        this.disable_max_date = moment(last_day).format("YYYY-MM-DD 00:00:00");
        this.selecting = true;
      }
    },
    setStart() {
      this.$refs.calendar.hidePopover();
      this.$nextTick(() => {
        this.range = {
          start: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
          end: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
          default_filterable: false,
        };

        this.$emit("handleFilterDate", this.range);
      });
    },
    setToday() {
      this.range = {
        start: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        end: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    setMonth() {
      this.range = {
        start: moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    setLastMonth() {
      this.range = {
        start: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1)
        ).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    currentMonthFromToday() {
      this.range = {
        start: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    setAll() {
      this.range = {
        start: moment(new Date(Date.now())).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().getFullYear(), new Date().getMonth() + 2)
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    setYesterday() {
      this.range = {
        start: moment(
          new Date(new Date().setDate(new Date().getDate() - 1))
        ).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().setDate(new Date().getDate() - 1))
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    setTomorrow() {
      this.range = {
        start: moment(
          new Date(new Date().setDate(new Date().getDate() + 1))
        ).format("YYYY-MM-DD 00:00:00"),
        end: moment(
          new Date(new Date().setDate(new Date().getDate() + 1))
        ).format("YYYY-MM-DD 00:00:00"),
      };
      this.$emit("handleFilterDate", this.range);
      this.$refs.calendar.hidePopover();
    },
    /** Se por padrão o filtro de data não for utilizado, a nomenclatura é ínicio > até hoje **/
    formatStart(inputValue) {
      let start_at = inputValue.start;
      if (
        this.range.hasOwnProperty("default_filterable") &&
        !this.range.default_filterable
      ) {
        return "Início";
      }
      return start_at;
    },
    handleFilterDate(e) {
      this.range.start = new Date(
        moment(this.range.start).format("YYYY-MM-DD 00:00:00")
      );
      this.range.end = new Date(
        moment(this.range.end).format("YYYY-MM-DD 00:00:00")
      );
      this.$emit("handleFilterDate", this.range);
      this.handleInput(e);
      /**
       * Após usuário concluir a data filtrada, limpa a data desabilitada
       * @type {boolean}
       */
      this.disable_min_date = false;
      this.disable_max_date = false;
      this.selecting = false;
    },
    handleInput(e) {
      this.$emit("input", e);
    },
    resetDate() {
      this.$nextTick(() => {
        this.range = this.defaultRange;
      });
    },
  },
  beforeMount() {
    this.range = this.defaultRange;
    if (typeof this.defaultRange === "object") {
      this.range.start = moment(this.range.start).format("MM/DD/YYYY");
      this.range.end = moment(this.range.end).format("MM/DD/YYYY");
    } else {
      this.range = moment(this.range).format("MM/DD/YYYY");
    }
  },
};
</script>

<style>
.bg-gray-100 {
  background-color: #f7fafc;
}

.border-t {
  min-height: 3.1rem !important;
  border-top: 1px solid #adb5bd;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.custom-input-date-picker-normal {
  height: 22px;
}

.custom-input-date-picker-small {
  height: 25px;
}

.disable-box-shadow {
  border-radius: 8px !important;
  box-shadow: none !important;
  border: 0.5px solid #e8e8e8 !important;
}
</style>
<style scoped>
.readonly-style:read-only {
  background-color: #e9ecef;
  border-radius: 8px;
  box-shadow: none !important;
}
</style>
