import { moneyToFloat } from "@/helpers";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";
const billPayMixin = {
  data() {
    return {
      bill_pay_types: [
        {
          value: 0,
          text: "Pendente",
          color: "warning",
        },
        {
          value: 1,
          text: "Pago",
          color: "success",
        },
        {
          value: 2,
          text: "Atrasado",
          color: "danger",
          hidden: true,
        },
        {
          value: 3,
          text: "Cancelado",
          color: "danger",
        },
        {
          value: 4,
          text: "Serasa",
          color: "info",
        },
        {
          value: 5,
          text: "Cartório",
          color: "default",
        },
        {
          value: 6,
          text: "Protestado",
          color: "warning",
        },
        {
          value: 7,
          text: "Jurídico",
          color: "indigo",
        },
        {
          value: 8,
          text: "Aprovado",
          color: "approved",
        },
        {
          value: 9,
          text: "Estornado",
          color: "warning",
        },
      ],
    };
  },
  methods: {
    billPayTypesAvailable(current_status) {
      return this.bill_pay_types.filter((item) => item.value !== current_status && !item.hidden);
    },
    getDocuments(bill_pay) {
      this.loadingEntryLaunch = true;
      let payload = {
        entity_id: bill_pay.entity_id,
        company_plant_id: bill_pay.company_plant_id,
        company_plant_buyer_id: bill_pay.company_plant_buyer_id,
      };
      if (bill_pay.id) {
        payload.bill_pay_id = bill_pay.id;
      }
      this.$store.dispatch("entryLaunch/getByEntityCompanyPlantAndCompanyPlantBuyer", payload).then((response) => {
        this.loadingEntryLaunch = false;
      });
    },
    /** Calcula valor final da parcela */
    calcFinalValue(item) {
      let value_to_remove = item.discounts_fees !== "0,00" ? moneyToFloat(item.discounts_fees) : 0;
      let value_to_add = item.fees_fines !== "0,00" ? moneyToFloat(item.fees_fines) : 0;
      let current_total_value = moneyToFloat(item.total_value);
      if(value_to_remove > current_total_value) {
        this.$notify({ type: "danger", message: "O valor do desconto deve ser menor que o valor total da parcela." });
        value_to_remove = 0;
        item.discounts_fees = 0;
      }
      item.final_value = (current_total_value - value_to_remove + value_to_add).toFixed(2);
    },
    getBankAccounts(bill_pay, reset = true) {
      this.loadingBankAccount = true;
      let companyPlantBuyer = this.company_plant_buyers.find((item) => item.id === bill_pay.company_plant_buyer_id)
      this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant",
        {
          allocated: 1,
          type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
          type_id: this.$_general_settings.show_bank_account_by_issuer ? companyPlantBuyer.company_plant_issuer_id : bill_pay.company_plant_id
        }).then((response) => {

        this.bank_accounts = response.data || [];
        let invalid_bank_account = this.bank_accounts.findIndex(item => item.bank_account_id === bill_pay.bank_account_id ) === -1;
        if (! reset) {
          return
        }
        if (this.bank_accounts.length && invalid_bank_account) {
          if (!this.$_general_settings.show_bank_account_by_issuer) {
            let default_bank_account =
              this.bank_accounts.filter((item) => item.default === 1)[0] || this.bank_accounts[0];
            bill_pay.bank_account_id = default_bank_account.bank_account_id;
          }
        } else if(invalid_bank_account) {
          bill_pay.bank_account_id = null;
        }
        else {
          bill_pay.bank_account_id= response.data.find(item => item.default).bank_account_id
        }
        this.loadingBankAccount = false;
      });
    },
    changedCompanyPlant(bill_pay) {
      this.getCompanyPlantBuyers();
      this.getBankAccounts(bill_pay)
    },
  },
};
export default billPayMixin;
