import { BaseListFilterType, initBaseListFilterType, RangeDateType } from "../../../../shared/types";
import { SyncTypeEnum } from "../../shared/enums/SyncTypeEnum";
import { SyncGeneralStatusEnum } from "../enums/SyncGeneralStatusEnum";

/**
 * Type para filtro de listagem
 *
 * @typedef {Object} SyncGeneralListFilterExtType
 * @property {RangeDateType} range - Intervalo entre datas
 * @property {keyof typeof SyncGeneralStatusEnum.keys | null} status - Status da integração
 * @property {keyof typeof SyncTypeEnum.keys | null} type
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & SyncGeneralListFilterExtType)} SyncGeneralListFilterType
 */
export const SyncGeneralListFilterType = {};

/**
 * Inicializar SyncGeneralListFilterType
 *
 * @returns {SyncGeneralListFilterType}
 */
export const initSyncGeneralListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    range: {
      start: null,
      end: null,
    },
    type: null,
    status: null,
  };
};
