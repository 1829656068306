import * as types from './mutation_types'
import {cursorPaginate, destroy, setUrlWithNextPage, update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";

const endPoint = '/technology/imports'
export default {
  namespaced: true,
  state: {
    items: [],
    equipment_types: [],
  },
  getters: {
    fetch: state => state.items,
    getEquipmentTypes: state => state.equipment_types,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload
    },
    [types.SET_TYPES](state, payload) {
      state.equipment_types = payload
    },
  },
  actions: {
    fetchItems({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(`${endPoint}`, params),
        {params: params})
        .then(({data}) => {
          cursorPaginate(state, {data: data.data, next_page: params.next_page}, 'items')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    add(_, params) {
      let formData = new FormData();
        formData.append(`hardened_state_equipment_id`, params.hardened_state_equipment_id);
        formData.append(`file`, params.file);
      return createAxios().post(`${endPoint}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
