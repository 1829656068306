import InvoiceListPage from "../views/list/InvoiceListPage.vue";

export default [
  {
    path: '/billing/opened',
    name: 'billing.opened.index.view',
    component: InvoiceListPage,
    meta: {
      title: 'Faturamento',
      description: 'Em Aberto',
      icon: 'fa fa-home',
      permission: 'billing.billed.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'billing.dashboard.index'},
        {title: 'Em aberto', name: null},
      ]
    },
  },
];
