import { SyncHubEnum } from '../../shared/enums/SyncHubEnum';

/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} SyncHubType
 * @property {keyof typeof SyncHubEnum.keys} sync_hub
 * @property {?number} id // [V]
 * @property {boolean} is_active
 * @property {string} url
 * @property {string} auth_url
 * @property {string} auth_username
 * @property {string} auth_password
 * @property {string} token // [V | Sankhya]
 * @property {string} app_key // [V | Sankhya]
 * @property {number} formulation_external_id // [V | Sankhya]
 * @property {number} service_external_id // [V | Sankhya]
 * @property {number} additional_external_id // [V | Sankhya]
 * @property {number} single_additional_external_id // [V | Sankhya]
 * @property {number} formulation_apport_external_id // [V | Sankhya]
 * @property {number} service_apport_external_id // [V | Sankhya]
 * @property {number} additional_apport_external_id // [V | Sankhya]
 * @property {number} single_additional_apport_external_id // [V | Sankhya]
 */
export const SyncHubType = {};

/**
 * Inicializar SyncHubType
 * 
 * @returns {SyncHubType}
 */
export const initSyncHubType = () => {
  return {
    id: null,
    sync_hub: SyncHubEnum.keys.PUZL,
    is_active: true,
    url: "",
    auth_url: "",
    auth_username: "",
    auth_password: "",
    token: "",
    app_key: "",
    formulation_external_id: null,
    service_external_id: null,
    additional_external_id: null,
    single_additional_external_id: null,
    formulation_apport_external_id: null,
    service_apport_external_id: null,
    additional_apport_external_id: null,
    single_additional_apport_external_id: null,
  }
};