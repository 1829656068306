var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body mt-n2 p-0"},[_c('div',{staticClass:"row"},[_c('ShadedTable',{attrs:{"length":_vm.$_concretePieces.length,"headers":_vm.headers,"loading":_vm.loadingSkeleton}},[_vm._l((_vm.$_concretePieces),function(item,index){return _c('template',{slot:index},[_c('ShadedCol',{staticStyle:{"max-width":"90px"}},[_c('div',{staticClass:"d-flex m-0 mt-1",staticStyle:{"gap":"8px"}},[_c('div',{staticStyle:{"min-width":"130px"}},[_c('AppSwitch',{attrs:{"asyncClick":() => _vm.changeStatus(item)},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1),(item.reuse)?_c('div',{staticClass:"pt-1 pl-2"},[_c('el-popover',{staticClass:"ml-0",attrs:{"placement":"bottom","trigger":"click"}},[_c('div',[_c('div',{staticClass:"font-weight-500 fs-12",staticStyle:{"justify-content":"center","text-align":"center"}},[_vm._v(" PERMITE REUSO ")])]),_c('base-button',{staticClass:"p-0 m-0 shadow-none",attrs:{"slot":"reference","outiline":"","size":"sm","type":"secundary"},slot:"reference"},[_c('img',{attrs:{"width":"24px","src":"/img/icons/reuse-grenn.png"}})])],1)],1):_vm._e()])]),_c('ShadedCol',[_c('div',{staticClass:"font-weight-400 fs-14 m-0 pt-2"},[_vm._v(" "+_vm._s(item.description)+" ")])]),_c('ShadedCol',{staticStyle:{"max-width":"200px"}},[_c('div',{staticClass:"applicationCards",staticStyle:{"gap":"12px"}},[_c('div',[_c('base-button',{class:item.level_feature.includes(1) ? 'active' : null,style:({
                  backgroundColor: item.level_feature.includes(1)
                    ? '#F2F4F9'
                    : '#FFFFFF',
                  borderColor: '#E8E8E8',
                }),attrs:{"disabled":_vm.buttonDisabled,"outline":"","block":""},on:{"click":function($event){$event.preventDefault();return _vm.updateLevelFeature(item, 1)}}},[_c('div',{staticClass:"fs-13 font-weight-400",style:({
                    color: item.level_feature.includes(1)
                      ? '#1a70b7'
                      : '#2B2D32',
                  })},[_vm._v(" BAIXA ")])])],1),_c('div',[_c('base-button',{class:item.level_feature.includes(2) ? 'active' : null,style:({
                  backgroundColor: item.level_feature.includes(2)
                    ? '#F2F4F9'
                    : '#FFFFFF',
                  borderColor: '#E8E8E8',
                }),attrs:{"disabled":_vm.buttonDisabled,"outline":"","block":""},on:{"click":function($event){$event.preventDefault();return _vm.updateLevelFeature(item, 2)}}},[_c('div',{staticClass:"fs-13 font-weight-400",style:({
                    color: item.level_feature.includes(2)
                      ? '#1a70b7'
                      : '#2B2D32',
                  })},[_vm._v(" MÉDIA ")])])],1),_c('div',[_c('base-button',{class:item.level_feature.includes(3) ? 'active' : null,style:({
                  backgroundColor: item.level_feature.includes(3)
                    ? '#F2F4F9'
                    : '#FFFFFF',
                  borderColor: '#E8E8E8',
                }),attrs:{"disabled":_vm.buttonDisabled,"outline":"","block":""},on:{"click":function($event){$event.preventDefault();return _vm.updateLevelFeature(item, 3)}}},[_c('div',{staticClass:"fs-13 font-weight-400",style:({
                    color: item.level_feature.includes(3)
                      ? '#1a70b7'
                      : '#2B2D32',
                  })},[_vm._v(" ALTA ")])])],1)])]),_c('ShadedCol',{staticStyle:{"max-width":"20px"}},[_c('div',{staticClass:"ml-auto"},[_c('base-dropdown',{staticClass:"m-0 mt-1",attrs:{"menuOnRight":""}},[_c('div',{staticClass:"dropdown-toggle rounded m-0",attrs:{"slot":"title-container"},slot:"title-container"},[_c('img',{attrs:{"width":"32","src":"/img/icons/icons8/ios/settings--v1_primary.png"}})]),_c('a',{staticClass:"dropdown-item ts-12 font-weight-400",on:{"click":function($event){$event.preventDefault();return _vm.handleEditConcretePiece(item.uuid)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/create-new-yellow.png","width":"14px","height":"14px"}}),_vm._v(" Editar ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item ts-12 font-weight-400",on:{"click":function($event){$event.preventDefault();return _vm.handleHistoryConcretePiece(item)}}},[_c('img',{attrs:{"src":"/img/icons/order-history.png","width":"14px","height":"14px"}}),_vm._v(" Histórico ")]),_c('div',{staticClass:"dropdown-divider p-0 m-0"}),_c('a',{staticClass:"dropdown-item ts-12 font-weight-400",on:{"click":function($event){$event.preventDefault();return _vm.handleDeleteConcretePiece(item.uuid)}}},[_c('img',{attrs:{"src":"/img/icons/icons8/ios/delete-red.png","width":"14px","height":"14px"}}),_vm._v(" Excluir ")])])],1)])],1)})],2),_c('EditConcretePiece',{ref:"editConcretePiece"}),_c('HistoryConcretePiece',{ref:"historyConcretePiece"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }