import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} PaymentMethodListType
 * @property {number} id
 * @property {boolean} status
 * @property {boolean} default
 * @property {string} name
 * @property {boolean} puzl_customer
 * @property {boolean} allow_approve
 * @property {?string} external_code
 * @property {boolean} display_in_anticipation
 * @property {int} payment_type - 0-DÉBITO, 1-CRÉDITO
 * @property {boolean} transaction_code
 * @property {boolean} is_manual_invoice_receipt
 * @property {boolean} is_manual_receivable_confirmation
 * @property {boolean} is_manual_supplier_payment
 * @property {boolean} show_records_on_credit_anticipation_window
 * @property {boolean} allow_auto_billing
 * @property {?string} contract_details
 * @property {boolean} payment_intermediaries_exists
 * @property {Array} payment_intermediary_ids
 * @property {string} created_at
 * @property {?string} updated_at
 * @property {string} created_by_user_name
 * @property {?string} updated_by_user_name
 */
export const PaymentMethodListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<PaymentMethodListType> } )} PaymentMethodListPagerType
 */
export const PaymentMethodListPagerType = {};

/**
 * Inicializar PaymentMethodListPagerType
 * 
 * @returns {PaymentMethodListPagerType}
 */
export const initPaymentMethodListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

