import {
  BaseListFilterType,
  initBaseListFilterType,
  RangeValueType,
  RangeDateType,
} from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CreditReleaseListFilterExtType
 * @property {null|0|1} status - [null] Todos: null
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {null|number[]} payment_method_ids
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CreditReleaseListFilterExtType)} CreditReleaseListFilterType
 */
export const CreditReleaseListFilterType = {};

/**
 * Inicializar CreditReleaseListFilterType
 *
 * @returns {CreditReleaseListFilterType}
 */
export const initCreditReleaseListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [],
  custom_search: {
    columns: [],
    values: [],
  },
  status: null,
  release_status: null,
  range: {
    start: null,
    end: null,
    default_filterable: true
  },
  payment_method_ids: null
});
