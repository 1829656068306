<template>
  <base-input :input-group-classes="input_group_classes">
    <input
      :maxlength="max_length"
      @change="handleChange"
      :disabled="disabled"
      v-model="internalValue"
      :placeholder="placeholder"
      inputmode="numeric"
      class="form-control form-control-sm input-limit"
      :class="[internalValue === 0 ? '' : internalValue > 0 ? 'is-valid' : 'is-invalid', inputClass]"
    />
    <template #append>
      <slot></slot>
    </template>
  </base-input>
</template>

<!-- Use a separate script block to declare options (like the model configuration) -->
<script>
export default {
  // This tells Vue that v-model uses the `start_value` prop
  // and updates via the `value` event.
  model: {
    prop: 'start_value',
    event: 'value'
  }
}
</script>

<script setup>
import { ref, watch, getCurrentInstance, toRefs } from 'vue'
import { mask1 } from '@/plugins'

// Define props
const props = defineProps({
  options: {
    type: Object,
    default: () => ({})
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  start_value: {
    type: [Number, String],
    default: 0
  },
  max_length: {
    type: [Number, String],
    default: "6"
  },
  input_group_classes: {
    type: String,
    default: "input-group-sm pb-2"
  },
  inputClass: {
    type: String,
    default: ""
  }
})

// Define emits (for the custom v-model event and change)
const emit = defineEmits(['value', 'change'])

// Destructure some props for easier access in the template
const { input_group_classes, max_length, disabled, placeholder } = toRefs(props)

// Create a reactive internal value that mirrors the incoming start_value.
const internalValue = ref(props.start_value)

// Get the component instance to access global properties (e.g. $notify, $helper)
const { proxy } = getCurrentInstance()

// Watch the incoming start_value prop and update internalValue if it changes.
watch(
  () => props.start_value,
  (newVal) => {
    if (internalValue.value !== newVal) {
      internalValue.value = newVal
    }
  },
  { immediate: true, deep: true }
)

// Watch internalValue for changes to validate and format the value.
watch(
  internalValue,
  (newVal, oldVal) => {
    const decimals = (props.options && props.options.decimals) || 0
    const formattedNew = newVal ? mask1(newVal.toString(), decimals) : newVal
    const formattedOld = oldVal ? mask1(oldVal.toString(), decimals) : 0

    const min = props.options && props.options.min != null ? props.options.min : null
    const maxVal = props.options && props.options.max != null ? props.options.max : null

    // If negatives are not allowed and a negative value is entered:
    if (min !== null && newVal && min >= 0 && newVal.toString().includes('-')) {
      proxy.$notify({
        type: 'danger',
        message: 'Valor fora do limite permitido!'
      })
      internalValue.value = proxy.$helper.checkNumberValue(formattedOld)
      emit('value', internalValue.value)
      return
    }

    // If the new value is outside the allowed range:
    if (min !== null && maxVal !== null && (formattedNew > maxVal || formattedNew < min)) {
      proxy.$notify({
        type: 'danger',
        message: 'Valor fora do limite permitido!'
      })
      internalValue.value = proxy.$helper.checkNumberValue(formattedOld)
      emit('value', internalValue.value)
      return
    }

    // Otherwise, update with the formatted value.
    internalValue.value = proxy.$helper.checkNumberValue(formattedNew)
    emit('value', internalValue.value)
  }
)

// Handle the change event on the input.
const handleChange = (event) => {
  emit('change', event)
}
</script>

<style scoped>
.input-limit {
  height: 28px !important;
}
.input-sm-rounded {
  //border-radius: 8px !important;
  height: 31px !important;
}
</style>
