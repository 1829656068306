import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { EntityBillReceiveSetNeedsReviewType } from "../types";

/** URLS */
const urls = Object.freeze({
  BASE: "/financial/entity-bill-receive",
});

/** ACTIONS */
const actions = {
  /**
   * Coloca o card em revisão
   *
   * @param {EntityBillReceiveSetNeedsReviewType} data
   * @returns {Promise<void>}
   */
  setNeedsReview: async (data) => {
    const res = await httpClient.post(`${urls.BASE}/needs-review`, data);
    if (res.status !== HttpStatusEnum.HTTP_OK) {
      handleUnexpectedStatusCode(res);
      return;
    }
  },
};

export default {
  actions,
};
