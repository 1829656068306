/**
 * Type para colocar o card em revisão
 *
 * @typedef {Object} EntityBillReceiveSetNeedsReviewType
 * @property {number} entity_bill_receive_id
 * @property {string} observations
 */
export const EntityBillReceiveSetNeedsReviewType = {};

/**
 * Inicializar EntityBillReceiveSetNeedsReviewType
 *
 * @returns {EntityBillReceiveSetNeedsReviewType}
 */
export const initEntityBillReceiveSetNeedsReviewType = () => ({
  entity_bill_receive_id: null,
  observations: null,
});
