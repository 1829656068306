<template>
  <div v-if="props.agroup_selecteds.length" class="scrolling-box bg-white" style="margin-top: -2%">
    <div class="agroup-float">
      <div class="container">
        <card>
          <div class="row">
            <div class="col-md-12 p-1">
              <base-button style="border-radius: 4px !important;"
                class="pointer"
                :disabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !props.selected_agroup.has_deduct || !props.selected_agroup.bank_account_id"
                block type="warning" @click.prevent="handleClickAgroup()">
                <i class="fa-solid fa-layer-group"/>
                <span style="font-size: 0.75rem">
                  FATURAR AGRUPADOS
                </span>
                <span style="color: black !important;"
                  class="badge badge-light text-dark">
                  {{ props.agroup_selecteds.length }}
                </span>
              </base-button>
            </div>
            <div class="col-md-12 p-1">
              <base-button style="border-radius: 4px !important;"
                class="pointer"
                :disabled="!filter_agroup.company_plant_id || !filter_agroup.company_plant_issuer_id || !props.selected_agroup.has_deduct || !props.selected_agroup.bank_account_id"
                block
                type="primary"
                @click.prevent="showModalIndividualWithFilter()">
                <i class="fa-solid fa-layer-group"/>
                <span style="font-size: 0.75rem">
                  VISUALIZAR AGRUPADOS
                </span>
              </base-button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 p-1">
              <base-input input-classes="form-control-sm"
                placeholder="CENTRAL">
                <PuzlSelect
                  :clearable="false"
                  @change="getCompanyPlantIssuer()"
                  placeholder="CENTRAL"
                  style="min-width: 200px"
                  v-model="props.filter_agroup.company_plant_id"
                  :items="props.plants"
                />
              </base-input>
            </div>
            <div class="col-md-6 p-1">
              <base-input input-classes="form-control-sm"
                placeholder="CNPJ EMISSOR">
                <PuzlSelect
                  @change="getIss"
                  label="ein"
                  :clearable="false"
                  placeholder="CNPJ EMISSOR"
                  style="min-width: 200px"
                  v-model="props.filter_agroup.company_plant_issuer_id"
                  :items="props.company_plant_issuers"
                />
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 p-1">
              <base-input input-classes="form-control-sm">
                <PuzlSelect @change="getPaymentTerms()"
                  size="mini"
                  v-model="props.selected_agroup.payment_method_id"
                  placeholder="MÉTODO"
                  :items="getPaymentMethods(props.selected_agroup.payment_methods) ? getPaymentMethods(props.selected_agroup.payment_methods) : []"
                  filterable>
                </PuzlSelect>
              </base-input>
            </div>
            <div class="col-md-4 p-1">
              <base-input input-classes="form-control-sm">
                <PuzlSelect @change="setRequireBalance(props.selected_agroup)"
                  size="mini"
                  v-model="props.selected_agroup.payment_term_id"
                  placeholder="CONDIÇÃO"
                  :disabled="!props.selected_agroup.payment_method_id"
                  :items="props.payment_terms_by_method[props.selected_agroup.id] ? props.payment_terms_by_method[props.selected_agroup.id] : []"
                  filterable>
                </PuzlSelect>
              </base-input>
            </div>
            <div class="col-md-4 p-1">
              <base-input input-classes="form-control-sm">
                <el-select size="mini"
                  v-model="props.selected_agroup.bank_account_id"
                  placeholder="Conta"
                  filterable>
                  <el-option v-for="item in props.agroup_bank_accounts" :key="item.id"
                             :disabled="!props.filter_agroup.company_plant_id"
                             :label="item.bank_account.name"
                             :value="item.bank_account_id">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12 px-2">
              <hr class="mb-1 mt-2">
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12 px-2 d-flex" style="align-items: center">
              <label
                style="font-size: 11px;font-weight: 400 !important;"
                class="label form-control-label m-0 mr-2">
                Reter ISS
              </label>
              <AppOnOffToggle :disabled="!selected_agroup.has_deduct"
                v-model="selected_agroup.iss_retain" />
            </div>
          </div>
          <div class="row my-2">
            <div class="col-12 px-2 text-left">
              <label
                style="font-size: 11px"
                class="label form-control-label text-danger m-0 mr-2"
                :class="hasAnyFederalTaxRetained(selected_agroup) ? 'text-danger' : 'text-gray'"
              >
                Retenção federal
              </label>
              <base-button
                outline
                slot="reference"
                size="sm"
                type="secundary"
                class="p-0 m-0 shadow-none"
                @click.prevent="showModalFederalTaxes(selected_agroup)"
              >
                <i
                  class="fa-solid fa-hand-holding-dollar text-darkred"
                  :class="hasAnyFederalTaxRetained(selected_agroup) ? 'text-darkred' : 'text-gray'"
                />
              </base-button>
            </div>
          </div>
          <div class="row px-2 my-2">
            <div class="col-md-7 show-md text-right float-right"
                 v-if="selected_agroup.balance > 0">
              <div class="float-right text-right d-flex" style="align-items: center">
                <label
                  style="font-size: 11px;font-weight: 400 !important;"
                  class="label form-control-label m-0 mr-2"
                >
                  COMPENSAR ({{ $helper.toMoney(selected_agroup.balance) }})
                </label>
                <AppOnOffToggle :disabled="requireBalance(selected_agroup)"
                  v-model="selected_agroup.use_anticipation_balance" />
              </div>
            </div>
            <div class="col-md-12 show-mobile" v-if="selected_agroup.balance > 0">
              <div class="float-right text-right d-flex" style="align-items: center">
                <label
                  style="font-size: 11px;font-weight: 400 !important;"
                  class="label form-control-label m-0 mr-2"
                >
                  COMPENSAR ({{ $helper.toMoney(selected_agroup.balance) }})
                </label>
                <AppOnOffToggle :disabled="requireBalance(selected_agroup)"
                  v-model="selected_agroup.use_anticipation_balance" />
              </div>
            </div>
          </div>
          <div class="row px-2 mt-2" v-if="!props.selected_agroup.has_deduct">
            <div class="col-md-12 px-0">
              <base-button
                style="width: 100%"
                size="sm"
                type="danger"
                outline
                class="text-uppercase"
                @click.prevent="handleCreateIss()">
                <i class="fa-solid fa-triangle-exclamation"/>
                {{ props.selected_agroup.has_deduct_title }} (sem regra de iss cadastrada)
              </base-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script setup>
//#region Imports
import {defineEmits, onMounted, ref, watch} from "vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {AppOnOffToggle} from "@/components/AppGlobal";
import {strToNum} from "@/helpers";
//#endregion

//#region ComponentRefs
//#endregion

//#region Data
const props = defineProps({
  plants: {
    type: Array,
    default: () => [],
    required: true,
  },
  agroup_bank_accounts: {
    type: Array,
    default: () => [],
    required: true,
  },
  company_plant_issuers: {
    type: Array,
    default: () => [],
    required: true,
  },
  paymentMethods: {
    type: Array,
    default: () => [],
    required: true,
  },
  invoiced: {
    type: Number,
    default: 0,
    required: true,
  },
  agroup_selecteds: {
    type: Array,
    default: () => [],
    required: true,
  },
  filter_agroup: {
    type: Object,
    default: () => {},
  },
  selected_agroup: {
    type: Object,
    default: () => {},
  },
  payment_terms_by_method: {
    type: [Array, Object],
    required: true,
  }
});
//#endregion

//#region Computeds
//#endregion

//#region Methods
function handleClickAgroup() {
  emit("handleClickAgroup");
}

function showModalIndividualWithFilter(item) {
  emit("showModalIndividualWithFilter", item);
}

function getCompanyPlantIssuer(){
  emit("getCompanyPlantIssuer");
}

function getBankAccountsByPlantId(company_plant_id) {
  emit("getBankAccountsByPlantId", company_plant_id);
}

function getIss(){
  emit("getIss");
}

function getPaymentTerms() {
  if(typeof props.selected_agroup.payment_method_id === 'undefined') return [];

  emit("getPaymentTerms", props.selected_agroup);
}

function showModalFederalTaxes(){
  emit("showModalFederalTaxes", props.selected_agroup);
}

function handleCreateIss() {
  emit("handleCreateIss", props.selected_agroup);
}

function getPaymentMethods(item) {
  if(typeof item?.payment_methods === 'undefined' || !props.paymentMethods) return [];

  const payment_method_splited = item.payment_methods.split(',').map((item) => Number(item));
  return props.paymentMethods.filter((item) => payment_method_splited.includes(item.id));
}

function setRequireBalance(item) {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  if (payment_term && !item.use_anticipation_balance && payment_term.requires_balance) {
    item.use_anticipation_balance = 1;
  }
}

function hasAnyFederalTaxRetained(item) {
  return (item.cofins_retained_flag && strToNum(item.cofins_rate) > 0) ||
    (item.csll_retained_flag && strToNum(item.csll_rate) > 0) ||
    (item.inss_retained_flag && strToNum(item.inss_rate) > 0) ||
    (item.ir_retained_flag && strToNum(item.ir_rate) > 0) ||
    (item.pis_retained_flag && strToNum(item.pis_rate) > 0);
}

function requireBalance(item) {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  if (!payment_term) {
    return false;
  }

  return Boolean(payment_term.requires_balance);
}

const emit = defineEmits([
  "handleClickAgroup",
  "getCompanyPlantIssuer",
  "showModalIndividualWithFilter",
  "getBankAccountsByPlantId",
  "getPaymentTerms",
  "showModalFederalTaxes",
  "getIss",
  "handleCreateIss",
  "updateListingManager"
]);
//#endregion

//#region Lifecycle
onMounted(() => {});
//#endregion
</script>
<style scoped>
@media only screen and (min-width: 600px) {
  .agroup-float {
    z-index: 10 !important;
    position: fixed;
    top: 2% !important;
    right: 15px;
    width: 500px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .agroup-float {
    z-index: 1 !important;
    position: fixed;
    bottom: 30% !important;
    width: 100vw !important;
    text-align: center;
  }
}
</style>
