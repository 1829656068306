<template>
  <div style="min-height: 1000px;background-color: #ffff;">
    <div class="mb-4" style="margin: 0 2rem;">
      <div class="row align-items-center justify-content-between align-items-center pb-2" style="padding-top: 1.3rem;">
        <div class="d-flex align-items-center">
          <img height="30px" src="/img/icons/cart-warning.png" class="mr-2" />
          <h2 style="font-size: 1.2rem !important;color: #F2B532;" class="nowrap mb-0 mt-1">
            Requisição de compra
          </h2>
        </div>
        <div class="d-flex align-content-center">
          <div class="mr-3 d-none d-sm-block" @click="handleShowModal({ ref: 'modalCreateProductService' })">
            <base-button type="success" block style="border-radius: 4px !important; width: 13rem;">
              <img src="/img/icons/plus-math--v1-white.png" width="19">
              Cadastro de itens
            </base-button>
          </div>
          <div class="mr-3 d-none d-sm-block" @click="handleShowModalCreatePurchaseCategory()">
            <base-button type="warning" block style="border-radius: 4px !important">
              <img src="/img/icons/product-documents.png" width="19">
              Cadastro de categorias
            </base-button>
          </div>
          <div class="back-button mb-2 mr-3" style="width: 14rem;">
            <BackButton />
          </div>
        </div>
      </div>
      <!-- Botao Switch -->
      <div class="dropdown-divider p-0 m-0"></div>
      <div class="d-flex justify-content-center justify-content-md-end r mt-3">
        <div class="switch-button d-flex">
          <div @click="changeStep('document')" class="w-100 d-flex justify-content-center"
            :class="requestStep === 'document' ? 'selected-switch-button' : 'disabled-switch-button'">
            <img v-if="requestStep === 'document'" width="20" src="/img/icons/icons8/ios/info-squared_white.png"
              class="mr-2" />
            <img v-if="requestStep === 'items'" width="20" src="/img/icons/icons8/ios/info-squared_warning.png"
              class="mr-2" />
            Documento
          </div>
          <div @click="changeStep('items')" class="w-100 d-flex justify-content-center"
            :class="requestStep === 'items' ? 'selected-switch-button' : 'disabled-switch-button'">
            <img v-if="requestStep === 'document'" width="22" src="/img/icons/big-parcel-primary.png" class="mr-2" />
            <img v-if="requestStep === 'items'" width="22" src="/img/icons/big-parcel-white.png" class="mr-2" />
            Itens
          </div>
        </div>
      </div>

      <!-- Etapas de documentacao e itens -->
      <DocumentRequest v-show="requestStep === 'document'" @purchaseRequestDocument="purchaseRequestDocument"
        :requestDocument="requestDocument" />
      <ItemsRequest v-show="requestStep === 'items'" @purchaseRequestItem="purchaseRequestItem"
        :productServiceTypeId="requestDocument.product_service_hub_type_id" :requestItem="items" />

      <ModalPurchaseCategory @fetchCategories="handleFetchCategories" ref="modalCreatePurchaseCategory" />

      <div class="w-100 d-flex justify-content-end my-4">
        <base-button type="success" native-type="submit" @click.prevent="save()" title="Salvar">
          <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
          Salvar
        </base-button>
      </div>
    </div>
  </div>
</template>


<script setup>
//#region Imports
import { ref, computed, onMounted, getCurrentInstance } from "vue";
import store from "@/store/index";
import { useRoute, useRouter } from "vue-router";
import BackButton from '@/components/Utils/BackButton';
import DocumentRequest from "../create/Shared/_Document.vue";
import ItemsRequest from "../create/Shared/_Items.vue";
import ModalPurchaseCategory from '../create/Shared/_ModalPurchaseCategory.vue';
import { dialogs } from "../../../../../../../helpers";
import CreateProductService from "../../../../../Configuration/ProductService/Shared/_ModalCreate.vue";
import purchaseRequestStore from "../../store/purchaseRequestStore";
import router from "@/routes";
//#endregion

//#region Refs
const requestStep = ref('document');
const requestDocument = ref({});
const requestItem = ref({});
const items = ref([]);
const can_edit_category = ref(false);
//#endregion

// #region ComponentRefs
const documentRequest = ref(null);
const modalCreateProductService = ref(null);
const modalCreatePurchaseCategory = ref(null);

//#region Computeds
const $_user = computed(() => store.getters["auth/getUser"]);
//#endregion

//#region Methods

function handleShowModalOrderDenied() {
  modalCreateProductService.value.handleCreateModal();
}

function handleShowModalCreatePurchaseCategory() {
  modalCreatePurchaseCategory.value.handleCreateModal();
}
function init() {
  setDocument();
}

async function setDocument() {
  const { proxy } = getCurrentInstance();
  const response = await purchaseRequestStore.actions.show(proxy.$route.params.id);
  requestDocument.value = response;
  items.value = response.items;
}

function setViewType(productServiceTypeId) {
  listType.value = productServiceTypeId;
}

function purchaseRequestDocument(updatedValue) {
  requestDocument.value = updatedValue;
}

function purchaseRequestItem(updatedValue) {
  requestItem.value = updatedValue;
}

function changeStep(step) {
  if (requestDocument.value?.product_service_hub_type_id) {
    requestStep.value = step;
  } else {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Selecione um tipo de item antes de prosseguir!');
  }
}

async function save() {
  if (requiredFieldsIsFilled()) {
    await purchaseRequestStore.actions.update({ ...requestDocument.value, ...requestItem.value });
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Requisição salva!');
    router.push('/purchase/request');
  }
}

function requiredFieldsIsFilled() {
  const requiredFields = ['deadline', 'requester_id', 'cost_center_id', 'input_company_plant_id', 'company_plant_buyer_id', 'product_service_hub_type_id'];
  for (const field of requiredFields) {
    if (!requestDocument.value.hasOwnProperty(field) || !requestDocument.value[field]) {
      dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Preencha todos os campos obrigatórios!');
      changeStep('document');
      return false;
    }
  }
  if (!requestItem.value.hasOwnProperty('items') || !Array.isArray(requestItem.value.items) || requestItem.value.items.length === 0) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Adicione pelo menos um item!');
    changeStep('items');
    return false;
  }
  return true;
}

function handleShowModal({ ref, data }) {
  if (ref === 'modalCreatePurchaseCategory' && !can_edit_category.value) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Usuário não tem permissão para criar categorias!');
    return;
  }
  modalCreatePurchaseCategory.value.handleCreateModal(data);
}

function handleFetchCategories() {
  documentRequest.value.listCategories();
}

async function getPurchasePermission() {
  try {
    const response = await store.dispatch('user/getPurchasePermission', $_user.value.id);
    can_edit_category.value = response.data.purchase_category_edit;
  } catch (error) {
    dialogs.notify(dialogs.TYPE_ENUM.ERROR, 'Erro ao carregar permissões de compra.');
  }
}
//#endregion

//#region Lifecycle
onMounted(() => {
  init();
  getPurchasePermission();
});
//#endregion
</script>

<style scoped>
@media (max-width: 768px) {
  .back-button {
    width: 6rem !important;
  }
}

/* Botao switch */
.switch-button {
  height: 2.5rem;
  border: 0.5px solid #DCDFE6;
  box-shadow: 0px 4px 2px 0px #00000040;
  border-radius: 20px;
  align-items: center;
  width: 24rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 8px !important;
}

.switch-button>div {
  font-size: 12px;
  height: 100%;
  align-items: center;
}

.switch-button>div:first-of-type {
  align-items: center;
  border-radius: 20px 0 0 20px;
}

.switch-button>div:nth-child(2) {
  border-radius: 0 20px 20px 0;
}

.selected-switch-button {
  background-color: #1a70b7;
  color: #ffffff;
}

.green-button {
  border-radius: 10px;
  border: 1px solid #149E57;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  color: #149E57;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
