<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/exchange.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div v-if="entity">
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-3 mb-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/contract-job-primary.png" width="20">
                  <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                    {{ finance_type_mode ? "Favorecido/Fornecedor" : "Pagador/Cliente" }}
                  </h4>
                  <hr class="ml-3 m-0" style="width: 100%;">
                </div>
              </div>
              <div v-if="entity.id" class="row mb-3 align-items-center">
                <div class="col-12">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    {{ entity.entity_name }}
                  </h4>
                  <h4 class="new-default-black-font font-weight-normal mt-1">
                    {{ entity.document_number }}
                  </h4>
                </div>
              </div>
              <div class="row mb-2 align-items-center">
                <div class="col-8">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Parcela
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <div class="installment-count">
                    <h4 class="m-0 p-0 mr-2">1</h4>
                    <div class="vertical-divider"></div>
                    <h4 class="m-0 p-0 ml-2">1</h4>
                  </div>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-8">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    valor total da parcela
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <div class="installment-count">
                    <h4 class="m-0 p-0 mr-2">R$</h4>
                    <div class="vertical-divider vl-90"></div>
                    <h4 class="m-0 p-0 ml-2 text-right">{{ formatToCurrencyWithoutSign(amount) }}</h4>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/exchange-dollar_primary.png" width="18">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    Operação
                  </h4>
                  <div class="ml-3 text-right" style="width: 100%;">
                    <div v-if="finance_type_id && $_financeTypes.length"
                         class="divider-header" :class="getOperationType.class">
                      {{ getOperationType.text }}
                    </div>
                    <hr class="m-0" style="width: 100%;">
                  </div>
                </div>
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Data da baixa <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker
                        v-model="discharge_at"
                        :class="errors[0] ? 'is-invalid' : ''"
                        :disableMaxDate="new Date()"
                        :mode="'date'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Método <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required">
                    <PuzlSelect
                      :items="payment_methods"
                      :clearable=false
                      :multiple="false"
                      v-model="payment_method_id"
                      :disabled="amount === '0,00'"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-2 align-items-center">
                <div class="col-6">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    Conta bancária <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-6">
                  <validation-provider rules="required">
                    <PuzlSelect
                      v-model="bank_account_id"
                      :disabled="loadingBankAccounts"
                      :items="bank_accounts"
                      :multiple="false"
                      :clearable="false"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-6">
                  <h5 class="new-default-black-font font-weight-normal m-0">
                    Observações
                  </h5>
                </div>
                <div class="col-6">
                  <base-input input-group-classes="input-group-sm">
                    <textarea type="text" rows="4"
                              maxlength="60"
                              v-model="status_reason"
                              class="form-control form-control-sm" placeholder=" ...">
                    </textarea>
                  </base-input>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-right">
                  <base-button type="danger"
                               size="sm"
                               style="width: 150px;" @click.prevent="closeModal">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button type="success"
                               size="sm"
                               style="width: 150px;"
                               :disabled="!discharge_at || !payment_method_id"
                               native-type="submit">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/icons8/ios/checked.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Confirmar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {VMoney} from "v-money";
import PuzlSelect from "@/components/PuzlSelect";
import cursorPaginate from "@/mixins/cursorPaginate";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {formatErrorValidation} from "@/plugins";
import moment from "moment";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    InputDateTimePicker
  },
  name: "ModalConfirmFinance",
  props: {
    updateScreen: {type: Function},
  },
  data() {
    return {
      modal: false,
      title: 'Confirmação de operação financeira',
      loading: false,
      loadingBankAccounts: true,
      id: null,
      chart_account_id: null,
      cnpj: "",
      note: "",
      company_plant_id: null,
      company_plant_issuer_id: null,
      bank_account_id: null,
      cost_center_id: null,
      discharge_at: null,
      payment_method_id: null,
      finance_type_id: null,
      finance_installment_id: null,
      finance_type_mode: null,
      amount: 0,
      status_reason: "",
      action: null,
      payment_methods: [],
      bank_accounts: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      entity: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  methods: {
    formatToCurrencyWithoutSign(value) {
      if (isNaN(value)) return 0;

      return Number(value).toLocaleString('pt-BR', {minimumFractionDigits: 2});
    },
    save() {
      this.$store.dispatch("financeInstallments/changeStatus", {
        id: this.id,
        finance_installment_id: this.finance_installment_id,
        action: this.action,
        discharge_at: this.discharge_at,
        status_reason: this.status_reason,
        bank_account_id: this.bank_account_id,
        payment_method_id: this.payment_method_id
      })
        .then(() => {
          this.updateScreen();
          this.closeModal();
        }).catch(error => {
        if (error.response) {
          const errors = error && error.response && error.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({type: "danger", message: errors});
        }
        this.loadingSkeleton = false;
      });
    },
    closeModal() {
      this.modal = false;
    },
    async openModal(finance_id, finance_installment_id, action) {
      await this.$store.dispatch('generalSetting/show')

      this.fetchAll();

      this.id = finance_id;
      this.finance_installment_id = finance_installment_id;
      this.action = action;
      this.status_reason = "";

      this.$store
        .dispatch("finances/show", finance_id)
        .then(response => {
          const item = response.data;

          this.cnpj = item.finance_installments[0].document_number;
          this.chart_account_id = item.finance_installments[0].chart_account_id;
          this.note = item.note;
          this.company_plant_id = item.company_plant_id;
          this.company_plant_issuer_id = item.company_plant_issuer_id;
          this.cost_center_id = item.finance_installments[0].cost_center_id;
          this.discharge_at = null;
          this.payment_method_id = item.finance_installments[0].payment_method_id;
          this.finance_type_id = item.finance_type_id;
          this.finance_type_mode = item.finance_type_mode;
          this.amount = item.finance_installments[0].amount.toFixed(2);

          this.entity = {
            entity_name: item.entity_name,
            id: item.entity_id,
            document_number: item.entity_document,
          };

          this.fetchBankAccounts(item.finance_installments[0].bank_account_id);
        });

      this.modal = true;
    },
    fetchBankAccounts(bank_account_id) {
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: this.$_general_settings.show_bank_account_by_issuer ? this.company_plant_issuer_id : this.company_plant_id
          }
        )
        .then((response) => {
          this.loadingBankAccounts = false;
          this.bank_accounts = response.data.map(({bank_account: {name}, bank_account: {id}}) => ({name, id}));
          this.bank_account_id = bank_account_id;
        });
    },
    fetchPaymentMethods() {
      this.$store
        .dispatch("paymentMethod/fetchItems")
        .then((response) => {
          this.payment_methods = response.data.filter(item => item.display_in_anticipation);
        });
    },
    fetchOperationTypes() {
      const like = {
        is_active: [1],
      };

      this.$store
        .dispatch("financeTypes/fetchItems", {
          page: this.paginate.nextUrl,
          like: like,
          limit: 1000,
        })
        .then(response => {
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.loadingSkeleton = false;
        });
    },
    fetchAll() {
      this.fetchOperationTypes();
      this.fetchPaymentMethods();
      this.fetchBankAccounts()
    },
  },
  computed: {
    ...mapGetters({
      $_financeTypes: "financeTypes/fetch",
      $_general_settings: "generalSetting/show",
    }),
    getOperationType() {
      let operationType = this.$_financeTypes.find(item => item.id === this.finance_type_id);
      return operationType.mode ?
        {
          text: "pagamento/débito",
          class: "",
        } : {
          text: "Recebimento/crédito",
          class: "text-success"
        };
    },
  },
};
</script>

<style scoped>
.divider-header {
  background: #e1e1e1;
  padding: 3px 20px;
  color: #db4539;
  /*  color: #6b8184;*/
  font-size: 11px;
  width: fit-content;
  display: inline-flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.installment-count {
  background: #e9ecef !important;
  width: fit-content;
  padding: 0px 15px;
  border: 1px solid white;
  box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 7px !important;
  display: inline-flex;
  align-items: center;
}

.installment-count h4 {
  color: #5c5757;
}

.installment-count .vertical-divider {
  width: 1px;
  height: 30px;
  background: white;
  margin: 0px 7px;
  transform: rotate(25deg);
}

.installment-count .vl-90 {
  transform: rotate(0deg);
}
</style>
