<template>
  <div>
    <div>
      <div class="d-flex mt-1 mb-3 mx-8 p-0 justify-content-center align-items-center">
        <div class="col pr-0">
          <base-button
            @click.prevent="selected = 0" :class="selected === 0 && 'active'"
            class="button-border-left border-light" outline type="primary" block
          >
          <span class="new-default-black-font" :class="selected === 0 ? 'text-white' : 'text-dark'">
            <AppIcon 
              icon="info-squared" 
              :color="selected === 0 ? 'white' : 'warning'" 
              width="20" class="mr-2" 
              isHover />
            IDENTIFICAÇÃO
            </span>
          </base-button>
        </div>
        <div class="col pl-0">
          <base-button
            @click.prevent="selected = 1" :class="selected === 1 && 'active'"
            class="button-border-right btn-left-none border-light" outline type="primary" block
          >
            <span class="new-default-black-font" :class="selected === 1 ? 'text-white' : 'text-dark'">
              <AppIcon 
                icon="security-shield" 
                :color="selected === 1 ? 'white' : 'primary'" 
                width="20" 
                class="mr-2" 
                isHover />
              PERMISSÕES
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(onSubmitClick)"
            autocomplete="off"
          >
            <div class="container-fluid">
              <section class="my-5">
                <!-- IDENTIFICAÇÃO -->
                <div v-if="selected === 0">
                  <div class="row mt-2 mb-3">
                    <div class="col-12" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Identificação
                      </h2>
                      <hr class="ml-3 m-0" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Status -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <div class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Status
                      </h4>
                    </div>
                    <div class="col-4">
                      <AppSwitch v-model="formData.status"/>
                    </div>
                  </div>
                  <!-- Nome -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label for="name" class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Nome
                      </h4>
                    </label>
                    <div class="col">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            id="name"
                            v-model="formData.name"
                            class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Forma de pagamento -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Forma de pagamento
                      </h4>
                    </label>
                    <div class="col">
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select v-model="formData.payment_type" :items="[
                            {
                              id: 0,
                              name: 'Débito',
                            },
                            {
                              id: 1,
                              name: 'Crédito',
                            }
                          ]"/>
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <!-- Mediador de pagamento -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Mediador de pagamento
                      </h4>
                    </label>
                    <div class="col">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select 
                        placeholder="Nenhum" 
                        multiple 
                        v-model="formData.payment_intermediary_ids" 
                        :items="paymentIntermediaries"
                      />
                      </base-input>
                    </div>
                  </div>
                  <!-- Códigoe externo -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label for="external_code" class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Código externo
                      </h4>
                    </label>
                    <div class="col">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          id="external_code"
                          v-model="formData.external_code"
                          type="text"
                          class="form-control form-control-sm" />
                      </base-input>
                    </div>
                  </div>
                  <!-- Código Sap -->
                  <div v-if="hasSap()" class="row mt-2 mb-3 align-items-center">
                    <label for="sap_code" class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Código sap (Créditos e Antecipações)
                      </h4>
                    </label>
                    <div class="col">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          id="sap_code"
                          v-model="formData.sap_code_for_entity_bill_receive"
                          type="text"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                  </div>
                  <!-- Detalhes para Propostas/Contratos -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label for="details" class="col">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Detalhes para propostas/contratos
                      </h4>
                    </label>
                    <div class="col">
                      <base-input input-group-classes="input-group-sm">
                        <textarea
                          id="details"
                          v-model="formData.contract_details"
                          type="text"
                          class="form-control form-control-sm" 
                        />
                      </base-input>
                    </div>
                  </div>
                </div>
                <!-- PERMISSÕES -->
                <div v-else>
                  <div class="row mt-2 mb-3">
                    <div class="col" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Permissões Gerais
                      </h2>
                      <hr class="ml-3 m-0 col" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Permitir aprovação -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Permitir aprovação
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.allow_approve"/>
                    </div>
                  </div>
                  <!-- Exige código de transação -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Exige código de transação
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.transaction_code"/>
                    </div>
                  </div>
                  <!-- Exibir em antecipação -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Exibir em antecipação
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.display_in_anticipation"/>
                    </div>
                  </div>
                  <!-- Contas a Receber -->
                  <div class="row mt-2 mb-3">
                    <div class="col" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Contas a Receber
                      </h2>
                      <hr class="ml-3 m-0 col" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Permite recebimento de fatura manual -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Permite baixa manual
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.is_manual_invoice_receipt"/>
                    </div>
                  </div>
                  <!-- Contas a Pagar -->
                  <div class="row mt-2 mb-3">
                    <div class="col" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Contas a Pagar
                      </h2>
                      <hr class="ml-3 m-0 col" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Permite pagamento de fornecedor manual-->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Permite baixa manual
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.is_manual_supplier_payment"/>
                    </div>
                  </div>
                  <!-- Faturamento -->
                  <div class="row mt-2 mb-3">
                    <div class="col" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Faturamento
                      </h2>
                      <hr class="ml-3 m-0 col" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Permite faturamento automático-->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Permite faturamento automático
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.allow_auto_billing"/>
                    </div>
                  </div>
                  <!-- Créditos e Antecipações -->
                  <div class="row mt-2 mb-3">
                    <div class="col" style="display: flex; align-items: center">
                      <h2 class="new-default-black-font m-0 p-0">
                        Créditos e Antecipações
                      </h2>
                      <hr class="ml-3 m-0 col" style="width: 100%" />
                    </div>
                  </div>
                  <!-- Permite confirmação de recebíveis manual -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Permite baixa manual
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.is_manual_receivable_confirmation"/>
                    </div>
                  </div>
                  <!-- Registros ficam visíveis na tela de créditos e antecipações -->
                  <div class="row mt-2 mb-3 align-items-center">
                    <label class="col-8">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Registros ficam visíveis na tela de créditos e antecipações
                      </h4>
                    </label>
                    <div class="col-4">
                      <AppSwitch v-model="formData.show_records_on_credit_anticipation_window"/>
                    </div>
                  </div>
                </div>
                <div class="row mt-5 mb-4">
                  <div class="col-12 text-right">
                  <AppButton
                    label="Cancelar"
                    type="danger"
                    class="ml-2"
                    :hasOutline="true"
                    @click="onCancelClick"
                  />
                  <AppButton
                    label="Salvar"
                    type="success"
                    class="ml-2"
                    native-type="submit"
                    :isDisabled="invalid"
                    :isLoading="isSaving"
                    />
                  </div>
                </div>
              </section>
            </div>
          </form>
    </validation-observer>
  </div>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed, ref, reactive, getCurrentInstance } from "vue";
import { PaymentMethodType } from "../../types";
import paymentMethodStore from "../../store/paymentMethodStore.js";
import { ValidationObserver } from "vee-validate";
import { AppButton, AppSwitch, AppIcon } from "../../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";
import { usePaymentMethodHook } from "../../hooks/usePaymentMethodHook.js";
import { hasSap } from "../../../../../modules/sap/shared/helpers/sapHelper.js"
//#endregion

//#region Data
const selected = ref(0);

const { paymentIntermediaries } = usePaymentMethodHook();
//#endregion

//#region Props
/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {PaymentMethodType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const isSaving = computed(() => paymentMethodStore.getters.getIsSaving());

//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style scoped>
</style>