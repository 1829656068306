<template>
  <div>
    <modal :show.sync="modal.create" size="md">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ modal.title }}</h5>
      </template>
      <form autocomplete="off">
        <div class="row ml-3">
          <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
            <h3>Valor Bruto:</h3>
          </label>
          <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
            <h3>{{ (totalValue || 0) | currency() }}</h3>
          </label>
        </div>
        <div class="row ml-3">
          <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
            <h3>Retenções Federais:</h3>
          </label>
          <label class="col-md-6 m-0 p-0 col-form-label form-control-label">
            <h3>{{ (totalFederalTaxes || 0) | currency() }}</h3>
          </label>
        </div>
        <!-- Campo cofins -->
        <div class="form-group row m-0 p-0 mb-1 mt-2">
          <label class="col-md-2 pb-1 pb-0 col-form-label form-control-label"> COFINS </label>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  @input="calcValuesformData()"
                  v-model="form_data.cofins_rate"
                  v-mask="['#,##', '##,##', '###,##']"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.cofins_retained_flag"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0"> % </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  v-on:blur="cofinsValueOnExit()"
                  v-model="form_data.cofins_value"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.cofins_retained_flag"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-2 pt-1">
            <base-switch
                @input="cofinsRetainedRateFlagClick()"
                v-model="form_data.cofins_retained_flag"
                type="success"
                offText="Não"
                onText="Sim"
            ></base-switch>
          </div>
        </div>

        <!-- Campo csll -->
        <div class="form-group row m-0 p-0 mb-1">
          <label class="col-md-2 pb-1 pb-0 col-form-label form-control-label"> CSLL </label>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  @input="calcValuesformData()"
                  v-model="form_data.csll_rate"
                  v-mask="['#,##', '##,##', '###,##']"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.csll_retained_flag"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0"> % </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  v-on:blur="csllValueOnExit()"
                  v-money="money"
                  v-model="form_data.csll_value"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.csll_retained_flag"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-2 pt-1">
            <base-switch
                @input="csllRetainedRateFlagClick()"
                v-model="form_data.csll_retained_flag"
                type="success"
                offText="Não"
                onText="Sim"
            ></base-switch>
          </div>
        </div>

        <!-- Campo inss -->
        <div class="form-group row m-0 p-0 mb-1">
          <label class="col-md-2 pb-1 pb-0 col-form-label form-control-label"> INSS </label>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  @input="calcValuesformData()"
                  v-model="form_data.inss_rate"
                  v-mask="['#,##', '##,##', '###,##']"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.inss_retained_flag"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0"> % </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  v-on:blur="inssValueOnExit()"
                  v-money="money"
                  v-model="form_data.inss_value"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.inss_retained_flag"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-2 pt-1">
            <base-switch
                @input="inssRetainedRateFlagClick()"
                v-model="form_data.inss_retained_flag"
                type="success"
                offText="Não"
                onText="Sim"
            ></base-switch>
          </div>
        </div>

        <!-- Campo ir -->
        <div class="form-group row m-0 p-0 mb-1">
          <label class="col-md-2 pb-1 pb-0 col-form-label form-control-label"> IR </label>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  @input="calcValuesformData()"
                  v-model="form_data.ir_rate"
                  v-mask="['#,##', '##,##', '###,##']"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.ir_retained_flag"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0"> % </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  v-on:blur="irValueOnExit()"
                  v-money="money"
                  v-model="form_data.ir_value"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.ir_retained_flag"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-2 pt-1">
            <base-switch
                @input="irRetainedRateFlagClick()"
                v-model="form_data.ir_retained_flag"
                type="success"
                offText="Não"
                onText="Sim"
            ></base-switch>
          </div>
        </div>

        <!-- Campo pis -->
        <div class="form-group row m-0 p-0 mb-1">
          <label class="col-md-2 pb-1 pb-0 col-form-label form-control-label"> PIS </label>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  @input="calcValuesformData()"
                  v-model="form_data.pis_rate"
                  v-mask="['#,##', '##,##', '###,##']"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.pis_retained_flag"
              />
              <template slot="append">
                <small class="input-group-sm p-0 m-0"> % </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-4 pt-1">
            <base-input input-group-classes="input-group-sm">
              <input
                  v-on:blur="pisValueOnExit()"
                  v-model="form_data.pis_value"
                  v-money="money"
                  type="text"
                  class="form-control form-control-sm"
                  :disabled="!form_data.pis_retained_flag"
              />
              <template slot="prepend">
                <small class="input-group-sm p-0 m-0"> R$ </small>
              </template>
            </base-input>
          </div>
          <div class="col-md-2 pt-1">
            <base-switch
                @input="pisRetainedRateFlagClick()"
                v-model="form_data.pis_retained_flag"
                type="success"
                offText="Não"
                onText="Sim"
            ></base-switch>
          </div>
        </div>

        <!-- MODAL FOOTER -->
        <div class="modal-footer">
          <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
          <base-button type="success" @click="save()" :loading="isLoading">Salvar</base-button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {progress, strToNum, toMoney} from "@/helpers";
import {debounce} from "@/plugins";

export default {
  name: "ModalFederalTaxes",
  data() {
    return {
      modal: {
        title: 'Retenção de tributos federais',
        create: false,
      },
      concrete_selecteds: [],
      services_selecteds: [],
      additionals_selecteds: [],
      single_selecteds: [],
      params: null,
      form_data: {
        cofins_rate: 0,
        csll_rate: 0,
        inss_rate: 0,
        ir_rate: 0,
        pis_rate: 0,
        cofins_value: 0,
        csll_value: 0,
        inss_value: 0,
        ir_value: 0,
        pis_value: 0,
      },
      filter: null,
      totalValue: 0,
      totalFederalTaxes: 0,
      isLoading: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
    };
  },
  watch: {
    "form_data.cofins_rate": {
      handler: function (value) {
        if (this.exceededLimit(value, 100, "100%")) {
          this.form_data.cofins_rate = "100,00";
        }
      },
    },
    "form_data.csll_rate": {
      handler: function (value) {
        if (this.exceededLimit(value, 100, "100%")) {
          this.form_data.csll_rate = "100,00";
        }
      },
    },
    "form_data.inss_rate": {
      handler: function (value) {
        if (this.exceededLimit(value, 100, "100%")) {
          this.form_data.inss_rate = "100,00";
        }
      },
    },
    "form_data.ir_rate": {
      handler: function (value) {
        if (this.exceededLimit(value, 100, "100%")) {
          this.form_data.ir_rate = "100,00";
        }
      },
    },
    "form_data.pis_rate": {
      handler: function (value) {
        if (this.exceededLimit(value, 100, "100%")) {
          this.form_data.pis_rate = "100,00";
        }
      },
    },
    "form_data.cofins_value": {
      handler: function (value) {
        if (this.exceededLimit(value, this.totalValue, toMoney(this.totalValue))) {
          this.form_data.cofins_value = this.totalValue.toFixed(2);
        }
      },
    },
    "form_data.csll_value": {
      handler: function (value) {
        if (this.exceededLimit(value, this.totalValue, toMoney(this.totalValue))) {
          this.form_data.csll_value = this.totalValue.toFixed(2);
        }
      },
    },
    "form_data.inss_value": {
      handler: function (value) {
        if (this.exceededLimit(value, this.totalValue, toMoney(this.totalValue))) {
          this.form_data.inss_value = this.totalValue.toFixed(2);
        }
      },
    },
    "form_data.ir_value": {
      handler: function (value) {
        if (this.exceededLimit(value, this.totalValue, toMoney(this.totalValue))) {
          this.form_data.ir_value = this.totalValue.toFixed(2);
        }
      },
    },
    "form_data.pis_value": {
      handler: function (value) {
        if (this.exceededLimit(value, this.totalValue, toMoney(this.totalValue))) {
          this.form_data.pis_value = tthis.totalValue.toFixed(2);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      $_invoices: "billingInvoice/getListBills",
      contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async handleCreate(params, filter) {

      const loader = progress.showLoader()
      await this.$store.dispatch("contractProposal/show", params.uuid);

      this.params = params;
      this.filter = filter;
      this.form_data = this.$helper.cloneObject(params);
      this.prepareFormData();
      this.concrete_selecteds = [];
      this.services_selecteds = [];
      this.additionals_selecteds = [];
      this.single_selecteds = [];
      this.modal.create = true;
      this.isLoading = true;
      return await Promise.all([
        this.$store.dispatch('companyPlantIssuer/fetchItems'),
        this.$store.dispatch('billingInvoice/getBills',
            {
              filter: this.filter,
              contract_proposal_id: this.form_data.id
            }),
      ]).then(() => {
        this.$_invoices.forEach(function (item) {
          switch (item.type) {
            case 1:
              this.concrete_selecteds = this.$helper.Array.selecteds(this.concrete_selecteds, item.id)
              break;
            case 2:
              this.services_selecteds = this.$helper.Array.selecteds(this.services_selecteds, item.id)
              break;
            case 3:
              this.additionals_selecteds = this.$helper.Array.selecteds(this.additionals_selecteds, item.id)
              break;
            case 4:
              this.single_selecteds = this.$helper.Array.selecteds(this.single_selecteds, item.id)
              break;
          }
        }, this)
        this.totalValue = this.getTotalValue();
        this.totalFederalTaxes = this.sumFederalTaxes();
        this.calcValuesformData();
        this.isLoading = false;
      }).catch(error => {
        this.isLoading = false;
      }).finally(() => {
        loader.hide()
      })
    },
    prepareFormData(){
      if (!this.form_data.cofins_retained_flag) {
        this.form_data.cofins_rate = "0,00";
      }
      if (!this.form_data.csll_retained_flag) {
        this.form_data.csll_rate = "0,00";
      }
      if (!this.form_data.inss_retained_flag) {
        this.form_data.inss_rate = "0,00";
      }
      if (!this.form_data.ir_retained_flag) {
        this.form_data.ir_rate = "0,00";
      }
      if (!this.form_data.pis_retained_flag) {
        this.form_data.pis_rate = "0,00";
      }
    },
    sumFederalTaxes(){
      if (!this.form_data) {
        return 0
      }
      const total = this.getTotalValue();
      let federalTaxes = 0;
      if ((strToNum(this.form_data.cofins_rate) > 0) && (this.form_data.cofins_retained_flag)) {
        federalTaxes += total * (strToNum(this.form_data.cofins_rate)/100);
      }
      if ((strToNum(this.form_data.csll_rate) > 0) && (this.form_data.csll_retained_flag)) {
        federalTaxes += total * (strToNum(this.form_data.csll_rate)/100);
      }
      if ((strToNum(this.form_data.inss_rate) > 0) && (this.form_data.inss_retained_flag)) {
        federalTaxes += total * (strToNum(this.form_data.inss_rate)/100);
      }
      if ((strToNum(this.form_data.ir_rate) > 0) && (this.form_data.ir_retained_flag)) {
        federalTaxes += total * (strToNum(this.form_data.ir_rate)/100);
      }
      if ((strToNum(this.form_data.pis_rate) > 0) && (this.form_data.pis_retained_flag)) {
        federalTaxes += total * (strToNum(this.form_data.pis_rate)/100);
      }
      return federalTaxes;
    },
    sumConcrete(){
      let initial = 0
      this.theorical_adjusted = 0
      return this.$_invoices.reduce((a, b) => {
        let value = b.type === 1 && this.concrete_selecteds.includes(b.id) && b.status === 1 ? strToNum(b.value) : 0
        return a + strToNum(value)
      }, initial)
    },
    sumServices(){
      let initial = 0
      return this.$_invoices.reduce((a, b) => {
        let value = b.type === 2 && this.services_selecteds.includes(b.id) && b.status === 1 ? strToNum(b.value) : 0
        return a + strToNum(value)
      }, initial)
    },
    sumAdditionals(){
      let initial = 0
      return this.$_invoices.reduce((a, b) => {
        let value = b.type === 3 && this.additionals_selecteds.includes(b.id) && b.status === 1 ? strToNum(b.value) : 0
        return a + strToNum(value)
      }, initial)
    },
    sumSingle(){
      let initial = 0
      return this.$_invoices.reduce((a, b) => {
        let value = b.type === 4 && this.single_selecteds.includes(b.id) ? strToNum(b.value) : 0
        return a + strToNum(value)
      }, initial)
    },
    getTotalValue(){
      return (strToNum(this.sumConcrete()) + strToNum(this.sumServices()) + strToNum(this.sumAdditionals()) + strToNum(this.sumSingle()));
    },
    calcValuesformData() {
      const total = this.getTotalValue();
      // COFINS
      this.form_data.cofins_value = this.form_data.cofins_retained_flag
          ? (total * (strToNum(this.form_data.cofins_rate) / 100)).toFixed(2)
          : "0,00";
      // CSLL
      this.form_data.csll_value = this.form_data.csll_retained_flag
          ? (total * (strToNum(this.form_data.csll_rate) / 100)).toFixed(2)
          : "0,00";
      // INSS
      this.form_data.inss_value = this.form_data.inss_retained_flag
          ? (total * (strToNum(this.form_data.inss_rate) / 100)).toFixed(2)
          : "0,00";
      // IR
      this.form_data.ir_value = this.form_data.ir_retained_flag
          ? (total * (strToNum(this.form_data.ir_rate) / 100)).toFixed(2)
          : "0,00";
      // PIS
      this.form_data.pis_value = this.form_data.pis_retained_flag
          ? (total * (strToNum(this.form_data.pis_rate) / 100)).toFixed(2)
          : "0,00";
      this.totalFederalTaxes = this.sumFederalTaxes();
      this.$forceUpdate();
    },
    cofinsValueOnExit() {
      this.form_data.cofins_rate = (strToNum(this.form_data.cofins_value) / this.getTotalValue() * 100).toFixed(2);
      this.calcValuesformData();
    },
    cofinsRetainedRateFlagClick() {
      this.form_data.cofins_rate = this.form_data.cofins_retained_flag ? this.params.default_cofins_rate : "0,00";
      this.calcValuesformData();
    },
    csllValueOnExit() {
      this.form_data.csll_rate = (strToNum(this.form_data.csll_value) / this.getTotalValue() * 100).toFixed(2);
      this.calcValuesformData();
    },
    csllRetainedRateFlagClick() {
      this.form_data.csll_rate = this.form_data.csll_retained_flag ? this.params.default_csll_rate : "0,00";
      this.calcValuesformData();
    },
    inssValueOnExit() {
      this.form_data.inss_rate = (strToNum(this.form_data.inss_value) / this.getTotalValue() * 100).toFixed(2);
      this.calcValuesformData();
    },
    inssRetainedRateFlagClick() {
      this.form_data.inss_rate = this.form_data.inss_retained_flag ? this.params.default_inss_rate : "0,00";
      this.calcValuesformData();
    },
    irValueOnExit() {
      this.form_data.ir_rate = (strToNum(this.form_data.ir_value) / this.getTotalValue() * 100).toFixed(2);
      this.calcValuesformData();
    },
    irRetainedRateFlagClick() {
      this.form_data.ir_rate = this.form_data.ir_retained_flag ? this.params.default_ir_rate : "0,00";
      this.calcValuesformData();
    },
    pisValueOnExit() {
      this.form_data.pis_rate = (strToNum(this.form_data.pis_value) / this.getTotalValue() * 100).toFixed(2);
      this.calcValuesformData();
    },
    pisRetainedRateFlagClick() {
      this.form_data.pis_rate = this.form_data.pis_retained_flag ? this.params.default_pis_rate : "0,00";
      this.calcValuesformData();
    },
    save() {
      try {
        this.isLoading = true;
        const fields = [
          'cofins_rate', 'cofins_value', 'cofins_retained_flag',
          'csll_rate', 'csll_value', 'csll_retained_flag',
          'inss_rate', 'inss_value', 'inss_retained_flag',
          'ir_rate', 'ir_value', 'ir_retained_flag',
          'pis_rate', 'pis_value', 'pis_retained_flag'
        ];
        fields.forEach(field => this.params[field] = this.form_data[field]);
      } finally {
        this.isLoading = false;
      }
      this.$emit('update_selected')
      this.closeModal();
    },
    exceededLimit(value, limit, limitDisplay) {
      if (!value || value.toString().charAt(value.length-3) !== ",") {
        return;
      }
      if (strToNum(value) > limit) {
        this.msgUp(limitDisplay);
        return true;
      }
    },
    msgUp: debounce(function (limitDisplay) {
      this.$notify({
        type: "danger",
        message: `O valor não pode ser superior a ${limitDisplay}.`,
      });
      this.$forceUpdate();
    }, 200),
  },
}
</script>

<style scoped>
</style>
