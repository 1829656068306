<template>
  <div>
    <PuzlEmptyData v-if="!company_plant_issuers.length && !$parent.loading" />
    <div class="row card-wrapper" v-if="company_plant_issuers.length">
      <div class="col-lg-4" v-for="(item, indexCompany) in company_plant_issuers" :key="indexCompany">
        <div class="card" style="box-shadow: none !important; border-radius: 8px !important; border: 0.5px solid #E8E8E8;">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <section class="top-section d-flex align-items-center mb-1">
                <span v-if="item.status" class="status-badge-success d-flex align-items-center">
                  <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                  Ativo
                </span>
                <span v-else class="status-badge-danger d-flex align-items-center">
                  <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                  Inativo
                </span>
                <div class="row m-0 ml-2">
                    <span class="mx-1">
                      <el-popover placement="bottom" trigger="hover">
                        <div style="width: 300px;">
                          <span style="font-weight: 500;color: #2B2D32;font-size: 12px;">
                            INFORMAÇÕES
                          </span>
                          <hr class="popover-hr">
                          <div class="w-100 d-flex align-items-start mt-3">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Inscrição Municipal</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.municipal_registration || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Inscrição Estadual</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.state_registration || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Código IBGE</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.ibge_code || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Cod. Serviço do Município</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.municipal_service_code || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">Item da lista de serviço LC116</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.service_list_item || "-" }}</span>
                          </div>
                          <div class="w-100 d-flex align-items-start mt-1">
                            <span style="font-size: 10px; font-weight: 300; color: #2B2D32;">CNAE</span>
                            <hr class="flex-grow-1 mx-3">
                            <span style="font-size: 10px; font-weight: 400; color: #2B2D32;">{{ item.cnae || "-" }}</span>
                          </div>
                        </div>
                        <base-button
                          slot="reference"
                          class="shadow-none p-0 m-0"
                          outiline
                          size="sm"
                          type="secondary"
                        >
                          <img class="pointer" style="width: 20px" src="/img/icons/icons8/ios/info-circle_warning.png" />
                        </base-button>
                      </el-popover>
                    </span>
                  </div>
              </section>
              <div class="ml-auto">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="empty" size="sm" class="dropdown-toggle shadow-none mt--2 mr--2" style="border-radius: 4px !important;">
                    <img height="30px" width="30px" src="/img/icons/icons8/ios/settings--v1_primary.png" class="mr-1 mt-n1"/>
                  </base-button>
                  <span class="dropdown-item mt-n1" @click.prevent="editIssuerDocument(item.id)">
                    <img src="/img/icons/create-new.png" width="19px" height="19px" class="imgBaseButton">
                    EDITAR
                  </span>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <span class="dropdown-item" :class="!$hasPrivilege(1) ? 'mb-n2' : ''" @click="handleRetentionModal(item)">
                    <img src="/img/icons/ledger.png" width="19px" height="19px" class="imgBaseButton">
                    RETENÇÕES
                  </span>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a class="dropdown-item" @click="handleModalAddBankAccount(item.id)">
                    <img src="/img/icons/bank-building.png" width="19px" height="19px" class="imgBaseButton">
                    Conta Bancária
                  </a>
                  <div v-if="$hasPrivilege(1)" class="dropdown-divider p-0 m-0"></div>
                  <span v-if="$hasPrivilege(1)" class="dropdown-item mb-n2" @click.prevent="removeIssuerDocument(item.id)">
                    <img src="/img/icons/icons8/ios/delete--v1.png" width="19px" height="19px" class="imgBaseButton">
                    EXCLUIR
                  </span>
                </base-dropdown>
              </div>
            </div>
            <section class="mid-section mt-2 d-flex flex-column">
              <span class="company-plant-name">
                  {{ item.alias_name }}
                </span>
                <span class="company-plant-document">
                  {{ item.ein }}
                </span>
            </section>
            <section class="bottom-section mt-3 mb-n2">
              <div v-if="item.certificate_exists && item.expired" class="d-flex align-items-center certificate-badge expired-certificate-badge py-1">
                <span class="d-flex align-items-center justify-content-center w-100" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/grand-master-key_gray.png" alt="grand-master-key_gray" width="16">
                  Certificado vencido: {{ item.expired_at | parseDate("DD MMM YYYY") }}
                </span>
              </div>
              <div v-else-if="item.certificate_exists && isAtLeastTwoMonthsAhead(item.expired_at)" class="d-flex align-items-center certificate-badge valid-certificate-badge py-1">
                <span class="d-flex align-items-center justify-content-center w-100" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/grand-master-key_gray.png" alt="grand-master-key_gray" width="16">
                  Certificado válido: {{ item.expired_at | parseDate("DD MMM YYYY") }}
                </span>
              </div>
              <div v-else-if="item.certificate_exists && !isAtLeastTwoMonthsAhead(item.expired_at)" class="d-flex align-items-center certificate-badge close-expiration-certificate-badge py-1">
                <span class="d-flex align-items-center justify-content-center w-100" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/grand-master-key_gray.png" alt="grand-master-key_gray" width="16">
                  Certificado válido: {{ item.expired_at | parseDate("DD MMM YYYY") }}
                </span>
              </div>
              <div v-else class="d-flex align-items-center certificate-badge no-certificate-badge py-1">
                <span class="d-flex align-items-center justify-content-center w-100" style="gap: 5px;">
                  <img src="/img/icons/icons8/ios/grand-master-key_gray.png" alt="grand-master-key_gray" width="16">
                  Sem Certificado válido
                </span>
              </div>
            </section>
            <section v-show="!loadingGeneralSettings && $_general_settings.show_bank_account_by_issuer" class="bottom-section mt-3 mb-n2">
              <div>
                <collapse class="border rounded mt-2">
                  <collapse-item name="1" class="header-gray" back-ground="border-header-indigo-bottom">
                    <h5 slot="title" class="mb-0 ">Contas</h5>
                    <SkeletonPuzlFullWidth v-show="loadingBankAccount && !item.bank_accounts"/>
                    <div class="row mb-2" v-for="(bank_account, indexBankAccount) in item.bank_accounts"
                         :key="indexBankAccount">
                      <div class="col-md-12 p-1">
                        <ul class="list-unstyled mb-1">
                          <li>
                            <small class="font-weight-bold">
                              {{ bank_account.bank_account.name }}
                            </small>
                            <br/>
                            <small class="font-weight-normal m-0">{{ bank_account.bank_account.bank.code }} |
                              {{ bank_account.bank_account.bank.name }}</small>
                            <br/>
                            <small class="font-weight-normal m-0">Ag. {{
                                bank_account.bank_account.number_without_digit_agency
                              }}-{{ bank_account.bank_account.digit_agency }}</small>
                            <br/>
                            <small class="font-weight-normal m-0">
                              C/{{ bank_account.bank_account.account_type }}: {{
                                bank_account.bank_account.number_without_digit_account
                              }}-{{ bank_account.bank_account.digit_account }}
                            </small>
                            <br/>
                          </li>
                        </ul>
                      </div>
                      <div class="col-md-8 p-1">
                        <base-button
                          block
                          size="sm"
                          outline
                          :disabled="loadingBankAccount"
                          @click.prevent="setDefaultBankAccount(item, bank_account.bank_account_id)"
                          :class="bank_account.default === 1 ? 'active' : ''"
                          type="primary"
                        ><i class="fas fa-user-check"></i>Padrão
                        </base-button
                        >
                      </div>
                      <div class="col-md-4 p-1" >
                        <base-button size="sm" block type="danger"
                                     :disabled="bank_account.default"
                                     @click.prevent="handleDeleteCompanyPlantBankAccount(item, bank_account.bank_account_id)">
                          <i class="mt-1 fas text-center fa-trash"></i>
                        </base-button>
                      </div>
                    </div>
                    <div v-show="item.bank_accounts  && !item.bank_accounts.length">
                      <h5 class="m-0">Não há contas.</h5>
                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </section>

          </div>
        </div>
      </div>
    </div>
    <ModalCreateRetentions ref="ModalCreateRetentions"/>
    <ModalAddBankAccount @updateCompanyPlantIssuer="updateCompanyPlantIssuer" ref="modalAddBankAccount"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCreateRetentions from "./_RetentionCreate.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import { date } from "../../../../../../helpers";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth.vue";
import ModalAddBankAccount from "@/views/Modules/Configuration/Plant/Shared/_ModalAddBankAccount.vue";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  name: "ListIssuerDocument",
  components: {
    ModalAddBankAccount,
    SkeletonPuzlFullWidth,
    ModalCreateRetentions,
    PuzlEmptyData,
  },
  data() {
    return {
      loadingGeneralSettings: true,
      showModalEdit: false,
      loadingBankAccount: false,
    };
  },
  computed: {
    ...mapGetters({
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_general_settings: "generalSetting/show",
    })
  },
  methods: {
    updateCompanyPlantIssuer(company_plant_issuer_id) {
      let index = this.company_plant_issuers.findIndex((item) => item.id === company_plant_issuer_id)
      this.$store.dispatch('companyPlantIssuer/show', company_plant_issuer_id).then(response => {
        this.company_plant_issuers[index].bank_accounts = response.data.bank_accounts
      })
    },
    handleModalAddBankAccount(company_plant_issuer_id) {
      if (! this.$_general_settings.show_bank_account_by_issuer) {
       return this.$notify({ type: 'warning', message: 'A conta bancária está definida por central'});
      }
      this.$refs.modalAddBankAccount.openModal(
        company_plant_issuer_id,
        GetBankAccountEnum.COMPANY_PLANT_ISSUER
      );
    },
    handleRetentionModal(entity){
      this.$refs['ModalCreateRetentions'].handleCreateRetentionModal(entity);
    },
    editIssuerDocument(id) {
      this.$router.push({path: `/configuration/plant/document/issuer-document/edit/${id}`});
    },
    setDefaultBankAccount(plantIssuer, bank_account_id) {
      this.loadingBankAccount = true;
      let loader = this.$loading.show();
      this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
      this.$store
        .dispatch("companyPlantBankAccount/setDefaultCompanyPlantBankAccount", {
          company_plant_id: 0,
          company_plant_issuer_id: plantIssuer.id,
          bank_account_id: bank_account_id,
          type: GetBankAccountEnum.COMPANY_PLANT_ISSUER
        })
        .then(response => {
          this.$notify({type: response.error_type, message: response.message});
          this.loadingBankAccount = false;
          this.$forceUpdate();
          plantIssuer.bank_accounts.forEach((item) => {
            if (item.bank_account_id === bank_account_id) {
              item.default = 1
            }
            else {
              item.default = 0
            }
          })
          loader.hide();
        })
    },
    handleDeleteCompanyPlantBankAccount(plantIssuer, bank_account_id) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            this.$notify({type: "info", message: "Estamos trabalhando em sua solicitação."});
            this.$store
              .dispatch("companyPlantIssuer/destroyBankAccountByPlantIssuer", {
                company_plant_issuer_id: plantIssuer.id,
                bank_account_id: bank_account_id
              })
              .then(response => {
                plantIssuer.bank_accounts.splice(plantIssuer.bank_accounts.findIndex((item) => item.bank_account_id === bank_account_id), 1);
                this.$forceUpdate();
                this.$notify({type: 'success', message: response.message});
                loader.hide();
              }).catch(error => {
              this.$notify({
                type: "danger",
                message: error.data.message
              });
              loader.hide();
            });
          }
        });
    },
    removeIssuerDocument(id) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store
        .dispatch("companyPlantIssuer/destroy", id)
        .then(response => {
          this.$notify({ type: response.error_type, message: response.message });
          this.$Progress.finish();
          loader.hide();
        })
        .catch(error => {
          this.$notify({ type: error.data.error_type, message: error.data.message });
          this.$Progress.finish();
          loader.hide();
        });
    },
    /**
     * Verifica se a data recebida está pelo menos
     * 2 meses a frente da data atual
     * @param {string} timestamp
     * @returns {boolean}
     */
    isAtLeastTwoMonthsAhead(timestamp) {
      const targetDate = date.make(timestamp);
      const twoMonthsFromNow = date.make().add(2, date.DURATION_INPUT.MONTHS);

      return targetDate.isSameOrAfter(twoMonthsFromNow.toDate());
    }
  },
  mounted() {
    this.loadingGeneralSettings = true
    this.$store
      .dispatch("generalSetting/show").then(response => {
      this.loadingGeneralSettings = false
    })
  }
};
</script>

<style scoped>
.status-badge-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-badge-danger {
  background-color: #FCF3F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.company-plant-name {
  font-size: 16px;
  font-weight: 500;
  color: #2B2D32;
}
.company-plant-document {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
.certificate-badge {
  border-radius: 16px;
}
.certificate-badge span {
  font-size: 12px;
  font-weight: 400;
  color: #606062;
}
.valid-certificate-badge {
  background-color: #F2F4F9;
}
.expired-certificate-badge {
  background-color: #FCF3F3;
}
.close-expiration-certificate-badge {
  background-color: #FEF9F2;
}
.no-certificate-badge {
  background-color: #E8E8E8;
}
.popover-hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 108%;
  margin-left: -11px;
}
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 10%;
}
</style>
