<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/exchange.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <FinanceForm :finance="finance" @close="closeModal()" />
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import FinanceForm from './Forms/_FinanceForm';
import cursorPaginate from "@/mixins/cursorPaginate";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";

export default {
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    InputDateTimePicker,
    FinanceForm
  },
  name: "ModalCreateFinance",
  props: {},
  data() {
    return {
      modal: false,
      title: 'Operação financeira',
      finance: {},
    }
  },
  methods: {
    closeModal() {
      this.$emit('refresh');
      this.modal = false;
    },
    openModal(entity){
      this.finance.id = null;
      this.finance.entity = entity;
      this.finance.note = "";
      this.finance.document_number = "";
      this.finance.company_plant_id = null;
      this.finance.company_plant_issuer_id = null;
      this.finance.bank_account_id = null;
      this.finance.cost_center_id = null;
      this.finance.expire_at = moment().format("YYYY/MM/DD");
      this.finance.payment_method_id = null;
      this.finance.finance_type_id = null;
      this.finance.amount = null;
      this.finance.finance_installment_id = null;

      this.modal = true;
    },
  },
};
</script>

<style scoped>

</style>
