<template>
  <div class="contract-proposal-nav">
    <!-- Tela Celular -->
    <div class="hide-above-lg mt-3">
      <div class="row mb-3 col-md-6 col-lg-6 main-menu mr-8 ml-2">
        <div class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1" v-if="!activeGeneral">
          <a @click.prevent="getEditPath" href="#" title="Principal">
            <div
              :class="[
                'text-center',
                'shadow',
                'icon',
                'icon-shape',
                'background-color-circle',
                'rounded-circle',
                { 'active-icon': activeGeneral },
              ]"
            >
              <img src="/img/icons/home.png" width="25px" height="25px" />
            </div>
          </a>
        </div>
        <div
          v-if="!activeFormulation"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="goToFormulation" href="#" title="Formulações">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img src="/img/icons/test-tube.png" width="25px" height="25px" />
            </div>
          </a>
        </div>
        <div
          v-if="!activeServices"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="goToServices" href="#" title="Serviços">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img src="/img/icons/todo-list.png" width="25px" height="25px" />
            </div>
          </a>
        </div>
        <div
          v-if="!activeAdditional"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="getAdditionalPath" href="#" title="Adicionais">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img src="/img/icons/plus-math.png" width="25px" height="25px" />
            </div>
          </a>
        </div>
        <div
          v-if="!activeSchedules && entity"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="goToSchedules" href="#" title="Programações">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img src="/img/icons/calendar.png" width="25px" height="25px" />
            </div>
          </a>
        </div>
        <div
          v-if="!activePayments && entity"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="goToPayments" href="#" title="Recebimentos">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img
                src="/img/icons/card-in-use.png"
                width="25px"
                height="25px"
              />
            </div>
          </a>
        </div>
        <div
          v-if="!activeInvoices && entity"
          class="col-md-1 col-sm-1 col-lg-1 mb-2 px-1"
        >
          <a @click.prevent="goToInvoices" href="#" title="Faturas">
            <div
              class="text-center shadow icon icon-shape background-color-circle rounded-circle"
            >
              <img
                src="/img/icons/receipt-terminal.png"
                width="25px"
                height="25px"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
    <!-- Tela PC -->
    <div class="hide-below-lg">
      <div class="row mt-1 mb-3 justify-content-center">
        <!-- sapcer -->
        <div class="col pr-0">
          <a @click.prevent="getEditPath">
            <base-button
              class="border-new-default-gray base-button"
              :class="{ active: activeGeneral }"
              outline
              style="
                border-top-left-radius: 1.5rem !important;
                border-bottom-left-radius: 1.5rem !important;
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              "
              type="primary"
              block
            >
              <img
                :src="
                  activeGeneral
                    ? '/img/icons/home-white.png'
                    : '/img/icons/home.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeGeneral ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Geral
              </span>
            </base-button>
          </a>
        </div>
        <div class="col px-0">
          <a @click.prevent="goToFormulation">
            <base-button
              type="primary"
              style="border-radius: 0 !important"
              class="border-new-default-gray base-button"
              :class="{ active: activeFormulation }"
              outline
              block
            >
              <img
                :src="
                  activeFormulation
                    ? '/img/icons/test-tube-white.png'
                    : '/img/icons/test-tube.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeFormulation ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Formulações
              </span>
            </base-button>
          </a>
        </div>
        <div class="col px-0">
          <a @click.prevent="goToServices">
            <base-button
              type="primary"
              style="border-radius: 0 !important"
              class="border-new-default-gray base-button"
              :class="{ active: activeServices }"
              outline
              block
            >
              <img
                :src="
                  activeServices
                    ? '/img/icons/todo-list-white.png'
                    : '/img/icons/todo-list.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeServices ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Serviços
              </span>
            </base-button>
          </a>
        </div>
        <div class="col px-0">
          <a @click.prevent="getAdditionalPath">
            <base-button
              type="primary"
              :style="
                entity
                  ? 'border-radius: 0 !important;'
                  : 'border-top-left-radius: 0 !important; ' +
                    'border-bottom-left-radius: 0 !important; ' +
                    'border-top-right-radius: 1.5rem !important; ' +
                    'border-bottom-right-radius: 1.5rem !important'
              "
              class="border-new-default-gray base-button"
              :class="{ active: activeAdditional }"
              outline
              block
            >
              <img
                :src="
                  activeAdditional
                    ? '/img/icons/plus-math-white.png'
                    : '/img/icons/plus-math.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeAdditional ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Adicionais
              </span>
            </base-button>
          </a>
        </div>
        <div class="col px-0" v-if="entity">
          <a @click.prevent="goToSchedules">
            <base-button
              type="primary"
              style="border-radius: 0 !important"
              class="border-new-default-gray base-button"
              :class="{ active: activeSchedules }"
              outline
              block
            >
              <img
                :src="
                  activeSchedules
                    ? '/img/icons/calendar-white.png'
                    : '/img/icons/calendar.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeSchedules ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Programações
              </span>
            </base-button>
          </a>
        </div>
        <div class="col px-0" v-if="entity">
          <a @click.prevent="goToPayments">
            <base-button
              type="primary"
              style="border-radius: 0 !important"
              class="border-new-default-gray base-button"
              :class="{ active: activePayments }"
              outline
              block
            >
              <img
                :src="
                  activePayments
                    ? '/img/icons/card-in-use-white.png'
                    : '/img/icons/card-in-use.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activePayments ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Recebimentos
              </span>
            </base-button>
          </a>
        </div>
        <div class="col pl-0" v-if="entity">
          <a @click.prevent="goToInvoices">
            <base-button
              class="border-new-default-gray base-button"
              style="
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 1.5rem !important;
                border-bottom-right-radius: 1.5rem !important;
              "
              :class="{ active: activeInvoices }"
              outline
              type="primary"
              block
            >
              <img
                :src="
                  activeInvoices
                    ? '/img/icons/receipt-terminal-white.png'
                    : '/img/icons/receipt-terminal.png'
                "
                width="20px"
                height="20px"
                class=" mr-1"
              />
              <span
                :class="activeInvoices ? 'text-white' : 'text-dark'"
                style="font-size: 10px"
              >
                Faturas
              </span>
            </base-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ContractProposalEnum } from "@/enum/ContractProposalEnum";
import { AppIcon } from "./AppGlobal";

export default {
  data() {
    return {
      ContractProposalEnum: ContractProposalEnum,
    };
  },
  props: {
    contractUuid: {
      type: String,
      required: true,
    },
    contractStatus: {
      type: Number,
      required: false,
    },
    entity: {
      type: Object,
      required: false,
    },
    activeGeneral: {
      type: Boolean,
      required: false,
    },
    activeFormulation: {
      type: Boolean,
      required: false,
    },
    activeServices: {
      type: Boolean,
      required: false,
    },
    activeAdditional: {
      type: Boolean,
      required: false,
    },
    activeSchedules: {
      type: Boolean,
      required: false,
    },
    activePayments: {
      type: Boolean,
      required: false,
    },
    activeInvoices: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    getEditPath() {
      this.$router.push(
        `/commercial/contract-proposal/edit/${this.contractUuid}`
      );
    },
    getAdditionalPath() {
      this.$router.push(
        `/commercial/contract-proposal/additional/${this.contractUuid}`
      );
    },
    goToFormulation() {
      this.$router.push(
        `/commercial/contract-proposal/formulation/${this.contractUuid}`
      );
    },
    goToServices() {
      this.$router.push(
        `/commercial/contract-proposal/services/${this.contractUuid}`
      );
    },
    goToSchedules() {
      this.$router.push(
        `/operational/contract-proposal/schedule/${this.contractUuid}`
      );
    },
    goToPayments() {
      this.$router.push(
        `/commercial/contract-proposal/payments/${this.contractUuid}`
      );
    },
    goToInvoices() {
      this.$router.push(
        `/commercial/contract-proposal/invoices/${this.contractUuid}`
      );
    },
  },
};
</script>
<style lang="scss">
@import '@/style/PuzlCustom/App.scss';
.contract-proposal-nav{
  .active-icon {
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
    transform: scale(1.1);
    background-color: #d3d3d3 !important;
  }

  .base-button:hover{
    background-color: $primary !important;
    border-color: transparent;

    span{
      color: white !important;
    }
  }

  .base-button.active:hover span{
    color: white !important;
  }
}
</style>

<style scoped lang="scss">
.border-new-default-gray {
  border: 1px solid #efefef;
  box-shadow: 0px 0px 2px rgba(50, 50, 93, 0.11),
    0px 2px 25px rgba(0, 0, 0, 0.08);
  border-left: 0px;
}

.hide-above-lg{
  .shadow{
    background-color: white;
  }
}

@media screen and (min-width: 768px) {
  .col {
    max-width: 18%;

    .base-button{
      display: flex;
      align-items: center;
      gap: 8px;
      background-color: white;

      &:hover{
        filter: none;
      }
    }    

    span {
      font-family: Fredoka;
      font-size: 14px !important;
      font-weight: 500;
    }
  }
}
</style>
