export const AppIconVariants = Object.freeze({
  defaultVariants: Object.freeze({
    color: "dark",
    icon: "default.svg",
  }),
  variants: Object.freeze({
    color: Object.freeze({
      dark: "dark",
      white: "white",
      primary: "primary",
      success: "success",
      warning: "warning",
      danger: "danger",
      muted: "muted",
      gray: "gray",
    }),
    svg: Object.freeze({
      'add-new': 'add-new.svg',
      agreement: 'agreement.svg',
      attach: 'attach.svg',
      autograph: "autograph.svg",
      automation: "automation.svg",
      attach: "attach.svg",
      attention: "attention.svg",
      'bar-chart': 'bar-chart.svg',
      barcode: 'barcode.svg',
      blocked: 'blocked.svg',
      'brick-wall': 'brick-wall.svg',
      bot: 'bot.svg',
      briefcase: "briefcase.svg",
      'business-building': 'business-building.svg',
      'cart': 'cart.svg',
      calendar: "calendar.svg",
      cancel: "cancel.svg",
      'card-payment': "card-payment.svg",
      check: "check.svg",
      "check-dollar": "check-dollar.svg",
      cheque: "cheque.svg",
      "chemical-plant": "chemical-plant.svg",
      "chemical-plant-3": "chemical-plant-3.svg",
      "card-payment": "card-payment.svg",
      cash: "cash.svg",
      cheque: "cheque.svg",
      "chemical-plant": "chemical-plant.svg",
      "chemical-plant-3": "chemical-plant-3.svg",
      cheque: "cheque.svg",
      clock: 'clock.svg',
      close: "close.svg",
      'closed-eye': "closed-eye.svg",
      'complete-task': "complete-task.svg",
      'copy-to-clipboard': "copy-to-clipboard.svg",
      modal_close: "modal_close.svg",
      disconnected: "disconnected.svg",
      'double-tick': "double-tick.svg",
      down: 'down.svg',
      downloading: 'downloading.svg',
      "contact-details": "contact-details.svg",
      contact: "contact.svg",
      copy: "copy.svg",
      create: "create.svg",
      "double-tick": 'double-tick.svg',
      down: "down.svg",
      email: "email.svg",
      eraser: "eraser.svg",
      email: "email.svg",
      error: "error.svg",
      exclamation: "exclamation.svg",
      "external-link-squared": "external-link-squared.svg",
      "escrow-process": "escrow-process.svg",
      eye: "eye.svg",
      filter: "filter.svg",
      hourglass: "hourglass.svg",
      "impossible-shapes": "impossible-shapes.svg",
      "info-squared": "info-squared.svg",
      info: "info.svg",
      "insurance-agent": "insurance-agent.svg",
      keypad: "keypad.svg",
      linkedin: "linkedin.svg",
      logo: 'logo.svg',
      "male-user": "male-user.svg",
      "merchant-account": "merchant-account.svg",
      "order-history": "order-history.svg",
      padlock: "padlock.svg",
      "payment-terms": "payment-terms.svg",
      phone: "phone.svg",
      pix: 'pix.svg',
      "plus-math-squared": "plus-math-squared.svg",
      "plus-math": "plus-math.svg",
      printer: "printer.svg",
      'profit-report': "profit-report.svg",
      "progress-indicator": "progress-indicator.svg",
      ratings: "ratings.svg",
      receipt: "receipt.svg",
      "puzzle-matching": "puzzle-matching.svg",
      recycling: 'recycling.svg',
      registrations: "registrations.svg",
      restart: "restart.svg",
      reload: "reload.svg",
      save: "save.svg",
      search: "search.svg",
      "security-shield": "security-shield.svg",
      settings: "settings.svg",
      shutdown: "shutdown.svg",
      "signing-document": "signing-document.svg",
      "squared-dollar": "squared-dollar.svg",
      "thumbs-up": "thumbs-up.svg",
      "thumbs-down": "thumbs-down.svg",
      "trash-can": "trash-can.svg",
      'sorting-arrows-horizontal': "sorting-arrows-horizontal.svg",
      'star-filled': "star-filled.svg",
      'test-tube': "test-tube.svg",
      'thumbs-down': 'thumbs-down.svg',
      'thumbs-up': 'thumbs-up.svg',
      'todo-list': 'todo-list.svg',
      'trash-can': "trash-can.svg",
      vlc: "vlc.svg",
      wallet: "wallet.svg",
      water: "water.svg",
      "numeric-selection": "numeric.svg",
      wallet: "wallet.svg",
      website: 'website.svg',
      "circled-right": "circled-right.svg",
      "vector": "vector.svg",
      "big-parcel": "big-parcel.svg",
      "handshake": "handshake.svg",
      writing: "writing.svg",
      "event-log": "event-log.svg",
      "push-pin": "push-pin.svg",
      "thumbs-up-down": "thumbs-up-down.svg",
      "salary-male": "salary-male.svg",
      "crowdfunding": "crowdfunding.svg",
      "blockly-blue": "blockly-blue.svg",
      "split-transaction": "split-transaction.svg",
      "more-than": "more-than.svg",
      "duration-finance": "duration-finance.svg",
      "query-inner-join": "query-inner-join.svg",
      physics: 'physics.svg',
      attention: 'attention.svg',
      "back-arrow": "back-arrow.svg",
      "stopwatch": "stopwatch.svg",
      "chat-bubble": "chat-bubble.svg",
      "connected": "connected.svg",
      "phone-contact": "phone-contact.svg",
      "shopping-cart": "shopping-cart.svg",
      "summer-sales": "summer-sales.svg",
      "price-tag-usd": "price-tag-usd.svg",
    }),
    png: Object.freeze({
      default: "/pngs/default.svg.png", //Deixei so pra caso tenha que fazer com PNG tambem
    }),
  }),
});
