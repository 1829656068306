import * as types from "./mutation_types";
import { destroy, update } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";
const endPoint = "/mix/slumpflow/";
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    activeItems(state) {
      return state.items
        .filter((item) => item.status === true)
        .sort((a, b) => a.minimum - b.minimum);
    },
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
  },
  actions: {
    fetchItems({ commit }, params) {
      return createAxios()
        .get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit, state }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .put(endPoint + payload.uuid, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateStatus({}, payload) {
      return createAxios()
        .put(`${endPoint}update-status/${payload.id}/${payload.status}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit, dispatch }, uuid) {
      return createAxios()
        .get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit, dispatch }, uuid) {
      return createAxios()
        .delete(endPoint + uuid)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updatePercentage({ commit, dispatch }, params) {
      return createAxios()
        .post(`${endPoint}percentage`, params)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    getSlumpFinal({ commit, dispatch }, params) {
      return createAxios()
        .get(`${endPoint}${params.slump_flow_id}/slump-final`, {
          params: params,
        })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
