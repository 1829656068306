<template>
  <div>
    <base-header class="bg-gray-content">
      <puzl-breadcrumb />
      <div class="row mb-3">
        <div class="col-md-2 mb-2">
          <base-button @click.prevent="handleCreateModal" block size="lg" type="success" class="text-uppercase">
            <i class="fas fa-plus"></i> novo
          </base-button>
        </div>
        <base-dropdown class="col-md-2 mb-2">
          <base-button slot="title-container" block size="lg" type="default" class="dropdown-toggle text-uppercase">
            <i class="fas fa-external-link-alt"></i> Cadastros
          </base-button>
          <div>
            <router-link :to="{ path: '/equipment/fuel-control/fuel-tank' }">
              <a class="dropdown-item">Reservatórios de Combustível</a>
            </router-link>
          </div>
        </base-dropdown>
        <div class="col-md-auto card text-center mb-0 mr-3 ml-3 mt-n1 mb-1 ml-md-auto d-flex align-items-center">
          <div class="row p-2">
            <img class="d-block m-auto" style="height: 30px" src="/img/icons/fuel-gas-green-96.png"/>
            <div class="col-md-auto pt-2 pt-md-0">
              <h4 class="text-default mb-0">Abastecimento Total</h4>
              <h4 class="text-default mb-0 numeric">{{ $_total_liter }} Litros</h4>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <List />
    <ModalType ref="modalType" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import List from "./Shared/_List";
import ModalType from "./Shared/_ModalType";
import {mapGetters} from "vuex";
export default {
  name: "Index",
  components: { PuzlBreadcrumb, List, ModalType },
  computed: {
    ...mapGetters({
      $_total_liter: "fuelControl/getTotalLiter",
    }),
  },
  methods: {
    handleCreateModal() {
      this.$refs.modalType.openModal();
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive");
  },
};
</script>

<style></style>
