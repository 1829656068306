<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <AppIcon icon="order-history" color="dark" width="22" class="mr-3" />
          <h5 class="text-title mb-0">Histórico de operações</h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton icon="close" type="link" width="auto" size="sm" :hasOutline="true" @click.prevent="closeModal" />
      </template>
      <div class="mb-2 px-2 mx-1">
        <!-- Itens -->
        <section>
          <div class="row" v-for="(item, index) in history" :key="index">
            <!-- Ícone e círculos -->
            <div class="col-2 py-2 mt-1">
              <!-- Linha vertical com efeito de Threads -->
              <div class="history-vertical-line" v-if="history.length > 1 && index + 1 !== history.length"></div>
              <div class="history-step">
                <div class="history-step-icon">
                  <!-- Ícone circulado -->
                  <div class="history-circle-icon">
                    <AppIcon :icon="getIconName(item.status)" :color="getIconColor(item.status)" width="13" />
                  </div>

                  <div class="history-step-ellipsis">
                    <nav class="circle-1"></nav>
                    <nav class="circle-2"></nav>
                    <nav class="circle-3"></nav>
                  </div>
                </div>
              </div>
            </div>

            <!-- Card das informações de cada etapa do histórico -->
            <div class="col-10">
              <div class="history-step-infos">
                <div class="d-flex">
                  <h4 class="new-default-black-font m-0">{{ statusEnum[item.status].text }}</h4>
                  <h4 v-if="item.status === PurchaseRequestHistoryStatusEnum.keys.APPROVED
                    || item.status === PurchaseRequestHistoryStatusEnum.keys.DENIED"
                    class="new-default-black-font m-0">
                    ({{ item.item_quantity_approved + '/' + item.item_quantity }})
                  </h4>
                </div>
                <h5 v-if="item.status_reason !== null" class="new-default-black-font m-0 font-weight-normal">{{
                  item.status_reason }}</h5>
                <h6 class="new-default-gray-font font-weight-normal mb-0 mt-1">{{ item.user_name }}</h6>
                <h6 class="new-default-gray-font font-weight-normal m-0">{{ item.created_at | parseDate('DD MMM YYYY')
                  }}</h6>
              </div>
            </div>
          </div>
        </section>
        <hr class="my-3" />
        <div class="container px-4 mb-3">
          <div class="offset-8 col-4 px-2">
            <AppButton label="Fechar" :isBlock="true" @click.prevent="closeModal" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { ref } from "vue";
import { progress } from "@/helpers";
import purchaseRequestStore from "../../../store/purchaseRequestStore";
import PurchaseRequestHistoryStatusEnum from "../../../enums/PurchaseRequestHistoryStatusEnum";
import { AppIcon, AppButton } from "@/components/AppGlobal";
//#endregion

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

//#region Data
const isOpen = ref(false);
const history = ref([]);
const purchaseRequest = ref(null);
const statusEnum = ref({
  0: {
    text: 'Criação da requisição',
    description: "CREATED",
    img: "/img/icons/icons8/ios/folder-invoices.png"
  },
  1: {
    text: 'Editado',
    description: "EDITED",
    img: "/img/icons/icons8/ios/hourglass_warning.png"
  },
  2: {
    text: 'Aprovação da requisição',
    description: "APPROVED",
    class: "primary",
    img: "/img/icons/icons8/ios/thumb-up_primary.png"
  },
  3: {
    text: 'Requisição negada',
    description: "DENIED",
    img: "/img/icons/icons8/ios/thumbs-down_danger.png"
  },
  4: {
    text: 'Cancelado',
    description: "CANCELLED",
    img: "/img/icons/icons8/ios/cancel_danger.png"
  },
});
//#endregion

//#region Methods
/**
 * Fechar modal
 */
function closeModal() {
  isOpen.value = false;
}

/**
 * Abrir modal
 * @param {object} purchaseRequestData
 */
function openModal(purchaseRequestData) {
  purchaseRequest.value = purchaseRequestData;
  getAll(purchaseRequestData.id);
}

/**
 * Obter histórico da requisição
 * @param {number} id
 */
function getAll(id) {
  const loader = progress.showLoader();
  purchaseRequestStore.actions.getHistory(id)
    .then(response => {
      history.value = response.data;
      isOpen.value = true;
    })
    .finally(() => {
      loader.hide();
    });
}

/**
 * Obter nome do ícone
 * @param {number} status
 * @returns {string}
 */
function getIconName(status) {
  switch (status) {
    case PurchaseRequestHistoryStatusEnum.keys.CREATED:
      return "cart";
    case PurchaseRequestHistoryStatusEnum.keys.EDITED:
      return "hourglass";
    case PurchaseRequestHistoryStatusEnum.keys.APPROVED:
      return "thumbs-up";
    case PurchaseRequestHistoryStatusEnum.keys.DENIED:
      return "thumbs-down";
    case PurchaseRequestHistoryStatusEnum.keys.CANCELED:
      return "cancel";
    default:
      return "";
  }
}

/**
 * Obter cor do ícone
 * @param {number} status
 * @returns {string}
 */
function getIconColor(status) {
  switch (status) {
    case PurchaseRequestHistoryStatusEnum.keys.CREATED:
      return "warning";
    case PurchaseRequestHistoryStatusEnum.keys.EDITED:
      return "warning";
    case PurchaseRequestHistoryStatusEnum.keys.APPROVED:
      return "primary";
    case PurchaseRequestHistoryStatusEnum.keys.DENIED:
      return "danger";
    case PurchaseRequestHistoryStatusEnum.keys.CANCELED:
      return "danger";
    default:
      return "";
  }
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} ModalConciliationHistoryExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
.history-step-icon {
  display: inline-flex;
  align-items: center;
}

.history-circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}

.history-step-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.history-step-ellipsis nav {
  border: 1px solid #828282;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}

.history-step-infos {
  position: relative;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

.history-vertical-line {
  background: #e8e8e8;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 29px;
  top: 38px;
}

.circle-1 {
  width: 8px;
  height: 8px;
}

.circle-2 {
  width: 4px;
  height: 4px;
}

.circle-3 {
  width: 2px;
  height: 2px;
}
</style>