<template>
  <div class="bg-white floating-button">
    <base-button @click.prevent="handleSubmit()"
      :disabled="props.invoiced === 0"
      block
      class="border-0 text-success">
      <img class="mr-1" src="/img/icons/icons8/ios/check-dollar_success.png" width="20">
      Faturar
      <div
        class="ml-1 no-shadow-card-success"
        style="display: inline-flex;">
        <h4
          class="m-0 p-0">
          {{ props.invoiced }} / {{ props.contract_proposals.length }}
        </h4>
      </div>
    </base-button>
  </div>
</template>
<script setup>
//#region Imports
import {defineEmits, onMounted, ref} from "vue";
//#endregion

//#region ComponentRefs
//#endregion

//#region Data
const props = defineProps({
  invoiced: {
    type: Number,
    default: 0,
    required: true,
  },
  contract_proposals: {
    type: Array,
    default: () => [],
    required: true,
  }
});

//#endregion

//#region Computeds
//#endregion

//#region Methods
function handleSubmit() {
  emit("handleSubmit");
}

const emit = defineEmits([
  "handleSubmit",
]);
//#endregion

//#region Lifecycle
onMounted(() => {});
//#endregion
</script>
<style scoped>
.floating-button {
  position: fixed;
  z-index: 3;
  overflow: hidden;
  max-width: 310px;
  min-width: 190px;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  color: #000000;
}

.card-with-box-shadow {
  border: 1px solid #E8E8E8;
  box-shadow: none;
  border-radius: 10px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.floating-button div.card-with-box-shadow {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0 !important;
}

.floating-button button {
  border-radius: 0 !important;
  background: #8bd5af52;
  display: inline-flex;
  gap: 20px;
}

.no-shadow-card-success {
  border-radius: 6px !important;
  background: transparent;
  padding: 0 5px;
  border: 1px solid #149E57;
}

.no-shadow-card-success h4 {
  color: #149E57;
  padding: 0;
  font-size: 11px;
  letter-spacing: -0.5px;
}
</style>
