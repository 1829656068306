<template>
  <div>
    <modal
      :backgroundColor="'#E8E8E8'"
      :showClose="false"
      :show.sync="isOpen" size="md-2">
      <template slot="header">
        <div class="d-flex align-items-center container ml-n3 w-100" style="gap: 15px;">
            <AppIcon icon="price-tag-usd" color="success" width="25px"/>
          <span class="text-title font-weight-400" style="font-size: 15px">
             {{ contractSelecteds ? "Confirmação de reajuste" : "Histórico de reajuste" }}
            </span>
          <div class="ml-auto mr-n4">
            <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
                 width="17px">
          </div>
        </div>
      </template>
      <div class="px-3 pt-2" v-if="!contractSelecteds">
        <div class="row">
          <div class="col-12 fs-12">
            Ajustado por: {{adjustmentData.user_name}}
          </div>
          <div class="col-12 fs-12">
           Data do ajuste: {{adjustmentData.date_at}}
          </div>
          <div class="col-12">
            <hr class="mt-3 mb-2">
          </div>
        </div>
      </div>
      <div class="px-3 pt-2">
        <div class="row  mb-2">
          <div class="col-md-12 d-flex align-items-center mb-2" style="gap: 15px;">
            <AppIcon icon="info-squared" color="warning" />
            <h4 class="mb-0 font-weight-400">
              Identificação
            </h4>
          </div>
          <div class="col-md-12">
            <card square :shadow="false">
              <div class="row" v-if="contractSelecteds">
                <div class="col-8 fs-12">TOTAL DE CONTRATOS</div>
                <div class="col-4 text-right fs-12">{{ contractSelecteds ? contractSelecteds.length : 0 }}</div>
              </div>
              <div class="row" v-else>
                <div class="col-8 fs-12">CONTRATO</div>
                <div class="col-4 text-right fs-12">{{ payload.contract_proposal }}</div>
              </div>
              <div class="row pt-3">
                <div class="col-7 fs-12">DATA</div>
                <div class="col-5 text-right mt-n1 d-flex d-inline-block">
                  <button type="button" class="btn base-button btn-default btn-sm input-data-btn">
                    <AppIcon icon="calendar" /></button>
                  <InputDatePicker v-model="payload.date_at"
                                   v-if="defaultRange && !contractSelecteds"
                                   :default-range="defaultRange"
                                   :disabled="!contractSelecteds"
                                   :disable-min-date="new Date()"
                                   :backgroundClass="'bg-white border-radius-square'"
                                   disableBoxShadow size="small"
                                   :is-range="false"/>
                  <InputDatePicker v-model="payload.date_at"
                                  v-else
                                   :default-range="defaultRange"
                                   :disabled="!contractSelecteds"
                                   :disable-min-date="new Date()"
                                   :backgroundClass="'bg-white border-radius-square'"
                                   disableBoxShadow size="small"
                                   :is-range="false"/>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="px-3 pt-n2">
        <div class="row  mb-2">
          <div class="col-md-12">
            <h4 class="font-weight-400">
              <AppIcon icon="test-tube" color="primary" class="mr-2"/>
              Concreto
            </h4>
          </div>
          <div class="col-md-12">
            <card square :shadow="false">
              <div class="card" style="box-shadow: none !important;margin-bottom: -5px">
                <div class="card-header" style="background: #dfdfdf85; padding: 10px">
                  <div class="row">
                    <div class="col-6 fs-12">REAJUSTE</div>
                    <div class="col-3 text-center fs-12">%</div>
                    <div class="col-3 text-center fs-12" style="text-transform: none;">R$/m<sup>3</sup></div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6 fs-12">Preço de venda</div>
                    <div class="col-3 text-center">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        v-model="payload.concrete.percentage"
                        @change="validateConcrete('value')"
                        input-class="input-sm-rounded"
                        :min-value="-99.99"
                        :max-value="99.99"
                        :decimal-places="2"
                        class="no-box-shadow app-tab-input"/>
                    </div>
                    <div class="col-3 text-right">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        @change="validateConcrete('percentage')"
                        v-model="payload.concrete.value"/>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="px-3 pt-n2">
        <div class="row  mb-2">
          <div class="col-md-12">
            <h4 class="font-weight-400">
              <AppIcon icon="todo-list" class="mr-2" />
              Serviço
            </h4>
          </div>
          <div class="col-md-12">
            <card square :shadow="false">
              <div class="card" style="box-shadow: none !important;margin-bottom: -5px">
                <div class="card-header" style="background: #dfdfdf85; padding: 10px">
                  <div class="row">
                    <div class="col-6 fs-12">REAJUSTE</div>
                    <div class="col-3 text-center fs-12">%</div>
                    <div class="col-3 text-center fs-12" style="text-transform: none;">R$/m<sup>3</sup></div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6 fs-12">Preço mínimo</div>
                    <div class="col-3 text-center">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        v-model="payload.service.value.percentage"
                        @change="validateService('value', 'value')"
                        input-class="input-sm-rounded"
                        :min-value="-99.99"
                        :max-value="99.99"
                        :decimal-places="2"
                        class="no-box-shadow app-tab-input"/>
                    </div>
                    <div class="col-3 text-right">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        @change="validateService('value', 'percentage')"
                        v-model="payload.service.value.value"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pt-1 fs-12">Preço por M3</div>
                    <div class="col-3 text-center pt-1">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        v-model="payload.service.meter.percentage"
                        @change="validateService('meter', 'value')"
                        input-class="input-sm-rounded"
                        :min-value="-99.99"
                        :max-value="99.99"
                        :decimal-places="2"
                        class="no-box-shadow app-tab-input"/>
                    </div>
                    <div class="col-3 text-right pt-1">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        @change="validateService('meter', 'percentage')"
                        v-model="payload.service.meter.value"/>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 pt-1 fs-12">Preço por hora</div>
                    <div class="col-3 text-center pt-1">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        v-model="payload.service.hour.percentage"
                        @change="validateConcrete('hour', 'value')"
                        input-class="input-sm-rounded"
                        :min-value="-99.99"
                        :max-value="99.99"
                        :decimal-places="2"
                        class="no-box-shadow app-tab-input"/>
                    </div>
                    <div class="col-3 text-right pt-1">
                      <AppInputNumber
                        :isDisabled="!contractSelecteds"
                        @change="validateService('hour', 'percentage')"
                        v-model="payload.service.hour.value"/>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="row px-3" v-if="contractSelecteds">
        <div class="col-md-12 d-flex justify-content-center justify-content-md-end align-items-center"
             style="gap: 8px;"
        >
          <AppButton
            :hasOutline="true"
            @click="onCancelClick()"
            label="Cancelar"
            type="danger"
          />
          <AppButton
            @click.prevent="handleSubmit"
            label="Confirmar"
            type="success"
            native-type="submit"
            :isDisabled="invalid"
            :isLoading="isSaving"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>

//#region Imports
import store from "@/store/index";

import {computed, reactive, ref} from "vue";
import {dialogs, progress} from "../../../../../helpers";
import InputDatePicker from "@/components/InputDatePicker.vue";
import {AppButton, AppInputLimit, AppInputNumber} from "@/components/AppGlobal";
import entityWalletStore from "@/modules/administrative/entity-wallet/store/entityWalletStore";
import { AppIcon } from "../../../../../components/AppGlobal";
//#endregion

//#region Data
const defaultRange = ref(null)

const adjustmentData = ref({
  user_name: null,
  date_at: null
})
const contractSelecteds = ref([])
const isOpen = ref(false);

const payload = ref({})

/** Store Getters */
const isSaving = ref(false);
//#endregion


//#region Computeds
const invalid = computed(() => {
  const value = payload?.value;

  if (!value.date_at) {
    return true;
  }
  if (!value) {
    return true;
  }

  const checks = [
    value.concrete,
    value.service?.value,
    value.service?.meter,
    value.service?.hour,
  ];

  for (const item of checks) {
    if (item.percentage > 0 || item.value > 0) {
      return false;
    }
  }

  return true;
})
//#endregion

//#region Emits
const emit = defineEmits(['update'])
//#endregion

//#region Methods
function validateConcrete(field) {
  if (payload.value.concrete[field] > 0) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Defina o ajuste em % ou R$/m³, individualmente."
    );
    payload.value.concrete[field] = 0
  }
}

function validateService(index, field) {
  if (payload.value.service[index][field] > 0) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Defina o ajuste em % ou R$/m³, individualmente."
    );
    payload.value.service[index][field] = 0
  }
}

async function handleSubmit() {
  isSaving.value = true
  const loader = progress.showLoader();
  let params = {
    selecteds: contractSelecteds.value,
    adjustment: payload.value
  }
  entityWalletStore.actions.adjustment(params)
    .then(() => {
      dialogs.notify();
      emit('update')
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
      isSaving.value = false
    });
}

function onCancelClick() {
  isOpen.value = false
}

function reset() {
  return {
    contract_proposal: null,
    date_at: null,
    concrete: {
      percentage: 0,
      value: null
    },
    service: {
      hour: {
        percentage: 0,
        value: null
      },
      meter: {
        percentage: 0,
        value: null
      },
      value: {
        percentage: 0,
        value: null
      }
    }
  }
}

/** Abrir modal */
async function openModal(selecteds, adjustment = null) {
  contractSelecteds.value = selecteds
  payload.value = reset()
  defaultRange.value = null
  if (adjustment) {
    payload.value.contract_proposal = adjustment.contract_proposal
    payload.value.concrete = adjustment.concrete
    payload.value.service = adjustment.service
    payload.value.date_at = adjustment.date_at.date
    defaultRange.value = adjustment.date_at.date
    contractSelecteds.value = null
    adjustmentData.value.user_name = adjustment.user_adjustment
    adjustmentData.value.date_at = adjustment.adjusted_at
  }
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}
//#endregion

/**
 * @typedef {Object} ModalCreateAdjustmentExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
</script>

<style>
.input-data-btn {
  border-radius: 2px !important;
  margin-right: 0px !important;
  box-shadow: none !important;
  height: 36px !important;
  background: white !important;
  border: 1px solid #d5cccc !important;
}
</style>
