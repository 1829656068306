<template>
  <div class="" v-bind="$attrs">
    <AppFixedPageTitle
      title="Checklist realizados"
      icon="/img/icons/icons8/ios/concrete-pump-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <AppSearchBar
              :searchBarFilter.sync="searchBarFilter"
              :rangeParams="{ is_range: true }"
              @onSearchClick="handleFilter"
              @onClearClick="resetDefaultSearchBarFilter()"
            >
            </AppSearchBar>
          </div>
        </div>
      </template>
      <template slot="header-buttons">
        <!-- <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click="add"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <base-button
            block
            type="light"
            class="new-puzl-default"
            style="border-radius: 50px !important"
            @click.prevent="$router.go(-1)"
          >
            <img width="19" src="/img/icons/circled-left.png" class="mr-1" />
            Voltar
          </base-button>
        </AppPageHeaderActions> -->
      </template>
    </AppPageHeader>
    <AppTabSelect
      :isMultiSelect="false"
      :isShowViewTypes="false"
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
    >
    </AppTabSelect>
    <List :items="listItemsCompleted" :loading="loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  initSearchBarFilterType,
  AppSearchBar,
  AppTabSelect,
} from "@/components/AppGlobal";
import List from "./Shared/_List";

export default {
  name: "CheckListMade",
  inheritAttrs: false,
  components: {
    AppSearchBar,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppTabSelect,
    List,
  },
  data() {
    return {
      loading: false,
      filter: {},
      status: this.initStates(),
      searchBarFilter: initSearchBarFilterType(),
    };
  },
  methods: {
    /**
     * @returns {void}
     */
    init() {
      let loader = this.$loading.show();
      this.loading = true;
      this.$store
        .dispatch("checklistActivity/fetch", this.filter)
        .then((response) => {
          this.loading = false;
          loader.hide();
        })
        .catch(() => {
          this.loading = false;
          loader.hide();
        });
    },
    /**
     * @returns {void}
     */
    resetDefaultSearchBarFilter() {
      this.searchBarFilter = this.defaultSearchBarFilter();
      delete this.filter.search;
      delete this.filter.status_id;
      this.filter.start = this.searchBarFilter.range.start;
      this.filter.end = this.searchBarFilter.range.end;
      this.init();
    },
    onTabSelectItemClick(status) {
      this.handleFilter(status.id);
    },
    /**
     * @returns {{ id: number|null, name: string, quantity: number }[]}
     */
    initStates() {
      return [
        { id: null, name: "Todos", quantity: 0 },
        { id: 0, name: "Pendentes", quantity: 0 },
        { id: 1, name: "N. Realizados", quantity: 0 },
        { id: 2, name: "Concluídos", quantity: 0 },
      ];
    },
    /**
     * @returns {object}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        company_plant: {
          items: [],
          selected: null,
        },
        range: {
          items: [],
          selected: 0,
          start: moment().format("YYYY-MM-DD"),
          end: moment().add(1, "days").format("YYYY-MM-DD"),
        },
        custom_search_values: [],
      };
    },
    /**
     * @param {number|null} statusId
     * @returns {void}
     */
    handleFilter(statusId = null) {
      this.filter.status_id = statusId;
      this.filter.search = this.searchBarFilter.custom_search_values;
      this.filter.start = moment(this.searchBarFilter.range.start).format(
        "YYYY-MM-DD"
      );
      this.filter.end = moment(this.searchBarFilter.range.end).format(
        "YYYY-MM-DD"
      );
      if (
        !this.searchBarFilter.custom_search_values ||
        !this.searchBarFilter.custom_search_values.length
      ) {
        delete this.filter.search;
      }
      if (statusId === null) {
        delete this.filter.status_id;
      }
      this.init();
    },
  },
  mounted() {
    this.resetDefaultSearchBarFilter();
    this.init();
  },
  computed: {
    ...mapGetters({
      $_checklist_activities: "checklistActivity/fetch",
    }),
    tabSelectItems() {
      this.status = this.initStates();
      return this.status.map((state) => {
        return {
          id: state.id,
          name: state.name,
          suffix: "",
          selected:
            this.filter.status_id === state.id ||
            (this.filter.status_id === undefined && state.id === null)
              ? true
              : false,
        };
      });
    },
    /**
     * Lista de itens concluídos que serão mostrados na visualização
     */
    listItemsCompleted() {
      return this.$_checklist_activities.map((activity) => {
        activity.duration = null;
        activity.mileage = null;
        activity.mileage = null;
        if (Array.isArray(activity.types_equipment_categories_name)) {
          let types_equipment_categories_name =
            activity.types_equipment_categories_name;
          activity.types_equipment_categories_name =
            types_equipment_categories_name.join(",");
        }

        try {
          activity.mileage_hour_meter = JSON.parse(activity.mileage_hour_meter);
          if (Array.isArray(activity.mileage_hour_meter)) {
            for (let value of activity.mileage_hour_meter) {
              activity.mileage = value.mileage;
              activity.hourMeter = value.hourMeter;
            }
          }
        } catch (error) {}
        if (activity.start_at && activity.finished_at) {
          let startDate = moment(activity.start_at);
          let endDate = moment(activity.finished_at);
          activity.duration = `${endDate.diff(startDate, 'minutes')} minutos`;
        }
        return activity;
      });
    },
  },
};
</script>

<style scoped></style>
