const keys = Object.freeze({
  NO_STOCK: 0,
  STOCK: 1,
  USE_AND_CONSUMPTION: 2,
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.NO_STOCK:
      return "Sem estoque";
    case keys.STOCK:
      return "Estoque";
    case keys.USE_AND_CONSUMPTION:
      return "Uso e consumo";
    default:
      return "";
  }
};

export const EntryLaunchItemStockStatusEnum = {
  keys,
  getTitle,
};
