import mutations from "./dreStoreMutations";
import getters from "./dreStoreGetters";
import {listResources} from "../../../../shared/store";
import {DreConfigurationList, DreConfigurationUpdate, DreConfigurationUpdateMultiple, DreList} from "../services";
import store from "@/shared/libraries/store";
import {dreStateKeys} from "@/modules/administrative/dre/store/dreStateKeys";

const list = async (filter, isAccumulateItems = false) => {
  return await listResources(filter, isAccumulateItems, mutations, getters, DreList);
};
const getConfiguration = async () => {
  const list = await DreConfigurationList.execute();
  mutations.setConfiguration(list)
  return list
};

const updateConfiguration = async (id, params) => {
  return await DreConfigurationUpdate.execute(id, params)
}
const update = async (params) => {
  return await DreConfigurationUpdateMultiple.execute(params)
}
const setPageState = (filters) => store.commit(dreStateKeys.PAGE_STATES, filters);
const resetStates = () => {
  store.resetStates(Object.values(dreStateKeys));
}
export default {
  list,
  getConfiguration,
  updateConfiguration,
  update,
  setPageState,
  resetStates
};
