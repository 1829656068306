<template>
  <div>
    <div class="container-fluid">
      <div v-if="props.contract_proposals.length"
           class="row card-wrapper">
        <div class="col-md-12" v-for="(item, index) in props.contract_proposals" :key="index">
          <div class="card">
            <div class="card-body" style="padding: 15px 25px;">
              <div class="row align-items-center">
                <!-- Dados do contrato -->
                <div class="col-3">
                  <div class="row">
                    <div class="col-12 px-2" style="display: flex;align-items: center;">
                      <h2 class="new-default-black-font my-0 mr-2">
                        {{ item.code }}
                      </h2>
                      <el-popover trigger="click" placement="left" class="p-0 pr-0 mr-1">
                        <div class="row">
                          <div class="m-0 p-0">
                            <h3 slot="title"
                                style="display: flex;align-items: center;"
                                class="px-3">
                              <img class="mr-2" src="/img/icons/icons8/ios/chemical-plant-v1.png" width="18">
                              <h3 class="new-default-black-font m-0 p-0 font-weight-500">Central</h3>
                            </h3>
                            <div class="row align-items-center py-0 px-3 mb-3">
                              <div class="col-md-12">
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.company_plant_name }}
                                </h5>
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.document }}
                                </h5>
                              </div>
                            </div>
                            <hr class="mt-1 mb-2">
                            <h3 slot="title"
                                style="display: flex;align-items: center;"
                                class="px-3">
                              <img class="mr-2" src="/img/icons/salesman.png" width="18">
                              <h3 class="new-default-black-font m-0 p-0 font-weight-500">Vendedor</h3>
                            </h3>
                            <div class="row align-items-center py-0 px-3 mb-3">
                              <div class="col-md-12">
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.seller }}
                                </h5>
                              </div>
                            </div>
                            <hr class="mt-1 mb-2">
                            <h3 slot="title"
                                style="display: flex;align-items: center;"
                                class="px-3">
                              <img class="mr-2" src="/img/icons/icons8/ios/contract-job_primary.png" width="18">
                              <h3 class="new-default-black-font m-0 p-0 font-weight-500">Dados do cliente</h3>
                            </h3>
                            <div class="row align-items-center py-0 px-3 mb-3">
                              <div class="col-md-12">
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.customer_name }}
                                </h5>
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.entity_document }}
                                </h5>
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.entity_addresses_address }}, {{ item.entity_addresses_number }} <br>
                                  {{ item.entity_addresses_district }}, CEP: {{ item.entity_addresses_postal_code }},
                                  {{ item.entity_addresses_city }}/{{ item.entity_addresses_state }}
                                </h5>
                              </div>
                            </div>
                            <hr class="mt-1 mb-2">
                            <h3 slot="title"
                                style="display: flex;align-items: center;"
                                class="px-3">
                              <img class="mr-2" src="/img/icons/icons8/ios/brick-wall_warning.png" width="18">
                              <h3 class="new-default-black-font m-0 p-0 font-weight-500">Dados da Obra</h3>
                            </h3>
                            <div class="row align-items-center py-0 px-3 mb-1">
                              <div class="col-md-12">
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.construction_name }}
                                </h5>
                                <h5 class="new-default-black-font font-weight-normal m-0">
                                  {{ item.address }}, {{ item.number }} - {{ item.district }} <br>
                                  {{ item.postal_code }}, {{ item.city }} / {{ item.state }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/info.png" width="22">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.has_pending_service || item.has_pending_travels" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                        <span v-if="item.has_pending_service && item.has_pending_travels" class="new-default-black-font font-weight-normal"> Há viagens e serviços em andamento </span>
                        <span v-if="item.has_pending_service && !item.has_pending_travels" class="new-default-black-font font-weight-normal"> Há serviços em andamento </span>
                        <span v-if="item.has_pending_travels && !item.has_pending_service" class="new-default-black-font font-weight-normal"> Há viagens em andamento </span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.balance > 0" trigger="click" placement="left" class="p-0 pr-0 mr-1">
                        <span
                      style="width: 190px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                      class="row">
                      <div
                        class="col-12 mb-2"
                        style="display: flex;
                        align-items: center;">
                        <img src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png" class="mr-2" width="22">
                        <h4
                          style="font-size: 14px;"
                          class="p-0 m-0 font-weight-500 new-default-black-font">
                          Saldo
                        </h4>
                      </div>
                      <div
                        class="col-12 mb-2 px-0">
                        <hr class="gray-divider m-0">
                      </div>
                      <div
                        class="col-12">
                        <h4
                          class="font-weight-normal new-default-black-font m-0 mb-1">
                          {{ $helper.toMoney(item.balance) }}
                        </h4>
                      </div>
                    </span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png" width="22">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row align-items-end">
                    <div class="col-10 px-2">
                      <h3 class="font-weight-400 new-default-black-font m-0">
                        {{ item.customer_name }}
                      </h3>
                      <h3 class="font-weight-400 new-default-black-font m-0">
                        {{ item.entity_document }}
                      </h3>
                    </div>
                    <div v-if="item.billing_guidelines" class="col-2 text-right">
                      <el-popover trigger="hover" placement="left" class="p-0 m-0">
                    <span
                      style="width: 190px; overflow: hidden; margin-right: -12px; margin-left: -12px;"
                      class="row">
                      <div
                        class="col-12 mb-2"
                        style="display: flex;
                        align-items: center;">
                        <img src="/img/icons/icons8/ios/error--v1_danger.png" class="mr-2" width="22">
                        <h4
                          style="font-size: 14px;"
                          class="p-0 m-0 font-weight-500 new-default-black-font">
                          Orientações
                        </h4>
                      </div>
                      <div
                        class="col-12 mb-2 px-0">
                        <hr class="gray-divider m-0">
                      </div>
                      <div
                        class="col-12">
                        <h5
                          class="font-weight-normal new-default-black-font m-0 mb-1">
                          {{ item.billing_guidelines }}
                        </h5>
                      </div>
                    </span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img width="22" src="/img/icons/icons8/ios/error--v1_danger.png"/>
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                </div>
                <!-- Dados da obra-->
                <div class="col-2 pt-4">
                  <div class="row align-items-end">
                    <div class="col-12">
                      <h4 class="font-weight-400 new-default-black-font m-0">
                        {{ item.construction_name }}
                      </h4>
                      <h4 class="font-weight-400 new-default-black-font m-0">
                        {{ item.city }} / {{ item.state }}
                      </h4>
                    </div>
                  </div>
                </div>
                <!-- Botões de ações-->
                <div class="col-2 px-5">
                  <div class="row">
                    <div class="col-12 mb-2" v-show="!props.agroup_selecteds.length && !item.is_pending">
                      <base-button type="light" block
                        @click.prevent="$emit('setIss', item, index)"
                        :class="item.iss_retain ? 'new-btn-danger' : 'new-btn-light'"
                        class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                        size="sm">
                        <img v-show="!item.iss_retain" src="/img/icons/chemical-plant-black.png" width="14"/>
                        <img v-show="item.iss_retain" src="/img/icons/icons8/ios/name--v1_danger.png" width="14"/>

                        <span :class="item.iss_retain && 'text-danger fs-10'" class="fs-10">
                          {{ item.iss_retain ? 'ISS RETIDO' : 'ISS DEVIDO' }}
                        </span>
                      </base-button>
                    </div>
                    <div class="col-12 mb-2">
                      <base-button type="light" block
                        @click.prevent="$emit('showModalFederalTaxes', item)"
                        :class="hasAnyFederalTaxRetained(item) ? 'new-btn-danger' : 'new-btn-light'"
                        class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                        size="sm">
                        <img v-show="!hasAnyFederalTaxRetained(item)" src="/img/icons/ledger.png"
                             width="14"/>
                        <img v-show="hasAnyFederalTaxRetained(item)" src="/img/icons/icons8/ios/ledger_danger.png"
                             width="14"/>
                        <span :class="hasAnyFederalTaxRetained(item) && 'text-danger'" class="fs-10">
                           FEDERAL
                        </span>
                      </base-button>
                    </div>
                    <div class="col-12" v-if="item.balance > 0">
                      <base-button type="light" block
                        @click.prevent="item.use_anticipation_balance = !item.use_anticipation_balance"
                        :class="item.use_anticipation_balance ? 'new-btn-primary' : 'new-btn-light'"
                        class="fs-10 new-default-black-font fs-9 new-mini-card-default"
                        size="sm">
                        <img v-show="!item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_black.png"
                             width="14"/>
                        <img v-show="item.use_anticipation_balance"
                             src="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
                             width="14"/>
                        <span :class="item.use_anticipation_balance && 'text-primary'" class="fs-10">
                         COMPENSAR
                      </span>
                      </base-button>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <!-- Dropdowns -->
                  <div v-if="!props.agroup_selecteds.includes(item.key) && !item.is_pending" class="row px-2 mb-2">
                    <div class="col-md-4 px-2 pt-1">
                      <base-input style="font-size: 5px !important;" input-classes="form-control-sm">
                        <PuzlSelect
                          @change="$emit('getPaymentTerms', item)"
                          v-model="item.payment_method_id"
                          placeholder="MÉTODO"
                          :disabled="props.paymentMethods === null"
                          :items="getPaymentMethods(item)"
                          :clearable="false"
                        />
                      </base-input>
                    </div>
                    <div class="col-md-4 px-2 pt-1">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          @change="setRequireBalance(item)"
                          v-model="item.payment_term_id"
                          placeholder="CONDIÇÃO"
                          :disabled="!item.payment_method_id || props.paymentTerms === null || typeof props.paymentTerms === 'undefined'"
                          :items="props.payment_terms_by_method[item.id] ? props.payment_terms_by_method[item.id] : []"
                          :clearable="false"
                        />
                      </base-input>
                    </div>
                    <div class="col-md-4 px-2 pt-1">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          @change="setRequireBalance(item)"
                          v-model="item.bank_account_id"
                          placeholder="Conta"
                          :disabled="props.bankAccounts === null"
                          :items="getBankAccounts(item.bank_accounts)"
                          :clearable="false"
                        />
                      </base-input>
                    </div>
                  </div>
                  <!-- Botão para faturamento individual e visualização de fatura -->
                  <div class="row px-2" v-if="!props.invoice && !item.is_pending && !props.agroup_selecteds.length">
                    <div v-if="validRequiresBalance(item, 3) && item.payment_term_id" class="col-md-12 px-2">
                      <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige compensação igual ao crédito</span>
                    </div>
                    <div v-if="validRequiresBalance(item, 1) && item.payment_term_id" class="col-md-12 px-2">
                      <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige compensação total</span>
                    </div>
                    <div v-if="validRequiresBalance(item) && item.payment_term_id" class="col-md-12 px-2">
                      <span><img src="/img/icons/icons8/ios/box-important--v1_danger.png" width="16"> Condição definida exige crédito antecipado</span>
                    </div>
                    <template v-if="!item.has_deduct">
                      <div class="col-12 px-2">
                        <base-button
                          type="light"
                          block
                          class="light-primary-button"
                          @click.prevent="$emit('handleCreateIss', item)"
                          size="sm">
                          <img src="/img/icons/icons8/ios/error--v1_danger.png" class="mr-2" width="19"/>
                          <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                            {{ item.has_deduct_title }} (sem regra de iss cadastrada)
                          </h5>
                        </base-button>
                      </div>
                    </template>
                    <template v-else>
                      <div class="col-12 px-2" v-if="!props.agroup_selecteds.length">
                        <base-button v-if="!item.is_pending"
                                     :disabled="disableIndividualInvoice(item)"
                                     type="light"
                                     block
                                     class="light-primary-button"
                                     :style="disableIndividualInvoice(item) && 'opacity: 50%'"
                                     @click.prevent="$emit('showModalIndividual', item, true, index)"
                                     size="sm">
                          <img src="/img/icons/check.png" class="mr-2" width="19"/>
                          <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                            FATURAMENTO INDIVIDUAL
                          </h5>
                        </base-button>
                      </div>
                      <div v-else class="col-12 text-center" style="margin-top: -50px !important">
                        <a href="#" @click.prevent="$emit('setToAgroup', item, index)" v-if="props.agroup_selecteds.includes(item.key)">
                          <i class="fa-solid fa-layer-group fa-2x"
                             style="font-size: 24px;">
                          </i>
                          <i class="fa-regular fa-circle-check " style="font-size: 15px;"></i>
                        </a>
                      </div>
                    </template>
                  </div>
                  <!-- Visualizar em aberto -->
                  <div class="row px-2" v-if="props.invoice && !item.is_pending">
                    <template >
                      <div class="col-12 px-2" >
                        <base-button v-if="!item.is_pending"
                                     :disabled="disableIndividualInvoice(item)"
                                     type="light"
                                     block
                                     class="light-success-button"
                                     :style="disableIndividualInvoice(item) && 'opacity: 50%'"
                                     @click.prevent="$emit('showModalIndividual', item, false)"
                                     size="sm">
                          <img src="/img/icons/icons8/ios/choose_success.png" class="mr-2" width="19"/>
                          <h5 class="new-default-black-font m-0 p-0 font-weight-400">
                            VISUALIZAR EM ABERTO
                          </h5>
                        </base-button>
                      </div>
                    </template>
                  </div>
                  <!-- Faturamento em segundo plano -->
                  <div class="row px-2" v-if="item.is_pending">
                    <div class="col-12 px-2">
                      <i class="mb-2 fa-solid fa-robot fa-2x text-primary"></i>
                      <br>
                      <span class="new-default-black-font font-weight-400" style="opacity: 60%;">
                        Faturamento em segundo plano
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-1">

                </div>
              </div>
              <!-- Opções -->
              <SettingOptions v-if="!item.is_pending && !props.agroup_selecteds.length && !props.invoice"
                :item="item"
                @setToAgroup="$emit('setToAgroup', item, index)"
                @handleCreateModalBilling="$emit('handleCreateModalBilling', item.uuid)" />
              <!-- Divs quando "agrupar" está ativo -->
              <div
                style="position: absolute; right: 20px; top: 20px;"
                v-if="props.contract_proposal_agroup === item.code && props.contract_proposal_agroup && !item.is_pending && !props.agroup_selecteds.includes(item.key) && props.agroup_selecteds.length > 0"
                class="text-center">
                <img src="/img/icons/icons8/ios/puzzle-unmatched_secondary.png"
                     class="pointer"
                     @click.prevent="$emit('setToAgroup', item, index)"
                     width="35"
                     alt="unmatched">
              </div>
              <div
                style="position: absolute; right: 20px; top: 20px;"
                v-if="props.contract_proposal_agroup === item.code && props.contract_proposal_agroup && !item.is_pending && props.agroup_selecteds.includes(item.key) && props.agroup_selecteds.length > 0"
                class="text-center">
                <img src="/img/icons/icons8/ios/puzzle-matching_primary.png"
                     class="pointer"
                     @click.prevent="$emit('setToAgroup', item, index)"
                     width="35"
                     alt="matched">
              </div>
              <!-- Botão de selecionar para faturamento-->
              <div
                style="position: absolute; right: 20px; top: 20px;"
                v-if="!validPaymentTerm(item) && !item.is_pending && props.invoice && !item.has_pending_service && !item.has_pending_travels && item.payment_term_id && item.has_deduct && !item.is_pending && item.bank_account_id && item.payment_method_id && !Boolean(item.pending_message)"
                class="text-right">
                <a href="#" @click.prevent="$emit('setInvoice', index, item.invoice)"
                >
                  <img
                    :src="item.invoice ? '/img/icons/icons8/ios/checked-checkbox.png' : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
                    class="btn-send-emails" width="32"
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
//#region Imports
import {strToNum} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect.vue";
import SettingOptions from "./Common/SettingOptions.vue"

//#endregion

//#region ComponentRefs

//#endregion

//#region Data
const props = defineProps({
  contract_proposals: {
    type: [Array, Object],
    required: false,
  },
  agroup_selecteds: {
    type: Array,
    required: false,
  },
  contract_proposal_agroup: {
    type: String,
    required: false,
  },
  paymentMethods: {
    type: Array,
    required: false,
    default: () => null
  },
  paymentTerms: {
    type: Array,
    required: false,
    default: () => null
  },
  loadingSkeleton: {
    type: Boolean,
    required: true,
  },
  payment_terms_by_method: {
    type: Array,
    required: false,
  },
  payment_intemerdiaries_selected: {
    type: Object,
    required: false,
    default: () => []
  },
  bankAccounts: {
    type: Array,
    required: false,
  },
  invoice: {
    type: Boolean,
    required: true,
    default: false
  }
})
//#endregion

//#region Computeds

//#endregion

//#region Methods

function hasAnyFederalTaxRetained(item) {
  return (item.cofins_retained_flag && strToNum(item.cofins_rate) > 0) ||
    (item.csll_retained_flag && strToNum(item.csll_rate) > 0) ||
    (item.inss_retained_flag && strToNum(item.inss_rate) > 0) ||
    (item.ir_retained_flag && strToNum(item.ir_rate) > 0) ||
    (item.pis_retained_flag && strToNum(item.pis_rate) > 0);
}

function getPaymentMethods(item) {
  if(!props.paymentMethods) return [];

  const payment_method_splited = item.payment_methods.split(',').map((item) => Number(item));
  return props.paymentMethods.filter((item) => payment_method_splited.includes(item.id));
}

function getBankAccounts(bank_accounts) {
  if(!props.bankAccounts) return [];

  const bankAccountsSplited = bank_accounts.split(',').map((item) => Number(item));

  return props.bankAccounts.filter((item) =>
    bankAccountsSplited.includes(item.id)
  );
}

const validRequiresBalance = (item, requires_balance) => {
  if(!props.paymentTerms) return false;

  // Localiza o termo de pagamento correspondente
  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  // Retorna false se o termo de pagamento não for encontrado
  if (!payment_term) {
    return false;
  }

  // Verifica se o termo de pagamento exige o saldo informado
  return payment_term.requires_balance === requires_balance;
}

const disableIndividualInvoice = (item) => {
  return (
    !item.has_deduct ||
    !item.bank_account_id ||
    item.has_pending_service ||
    item.has_pending_travels ||
    item.is_pending ||
    !item.payment_method_id ||
    item.pending_message ||
    !item.payment_term_id ||
    validPaymentTerm(item)
  );
}

function validPaymentTerm (item) {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find((term) => term.id === item.payment_term_id);

  if (!payment_term) {
    return true;
  }

  if (payment_term.requires_balance && payment_term.requires_balance === 2) {
    if (Number(item.balance) > 0 && !item.use_anticipation_balance) {
      return true;
    }
    if (Number(item.balance) < 1) {
      return true;
    }
  }

  return false;
}

const setRequireBalance = (item) => {
  if(!props.paymentTerms) return false;

  const payment_term = props.paymentTerms.find(
    (term) => term.id === item.payment_term_id
  );

  if (payment_term && !item.use_anticipation_balance && payment_term.requires_balance) {
    item.use_anticipation_balance = 1;
  }
}
//#endregion

//#region Lifecycle

//#endregion

</script>
<style scoped>
.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border: 1px solid #eeeeee;
  border-radius: 16px !important;
}

label {
  font-size: 0.8rem;
}

.entry-launch-custom-float {
  position: fixed;
  bottom: 150px;
  right: 40px;
  background-color: #1a70b7 !important;
  text-align: center;
  -webkit-box-shadow: 2px 2px 3px #999;
  box-shadow: 2px 2px 3px #999;
}


@media only screen and (min-width: 600px) {
  .agroup-float {
    z-index: 10 !important;
    position: fixed;
    top: 2% !important;
    right: 15px;
    width: 500px;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .agroup-float {
    z-index: 1 !important;
    position: fixed;
    bottom: 30% !important;
    width: 100vw !important;
    text-align: center;
  }
}

.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #e96262;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
  display: inline-flex;
  height: 31px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div {
  color: #606062;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
  outline: none;
}

.active-hoverable-warning {
  background: #ffc107 !important;
  color: white !important;
}

.active-hoverable-danger {
  background: #DB4539 !important;
  color: white !important;
}

.active-hoverable img {
  filter: brightness(0) invert(1);
}

.light-primary-button {
  background: #F2F4F9 !important;
  border: 1px solid #C6D2E5 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  padding: 5px 0px;
}

.light-success-button {
  background: #F2F7F3 !important;
  border: 1px solid #E8E8E8 !important;
  font-weight: 400 !important;
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  padding: 5px 0px;
}
</style>
