/**
 * Type para Incluir/Editar/Visualizar permissões do usuário
 *
 * @typedef {Object} UserPermissionType
 * @property {number} id - ID do usuário.
 * @property {string} uuid - UUID do usuário.
 * @property {string|null} external_code - Código externo do usuário.
 * @property {boolean} notify - Indica se o usuário deve ser notificado.
 * @property {boolean} status - Status do usuário.
 * @property {boolean} is_user - Indica se é um usuário ativo.
 * @property {boolean} is_intermediary - Indica se é um intermediário.
 * @property {boolean} is_seller - Indica se é um vendedor.
 * @property {boolean} is_concrete - Indica se está relacionado a concreto.
 * @property {string[]|null} view_sellers - Lista de vendedores visíveis.
 * @property {string[]|number[]|null} bank_account_ids - IDs das contas bancárias associadas.
 * @property {boolean} is_service - Indica se é um serviço.
 * @property {boolean} is_external_service - Indica se é um serviço externo.
 * @property {boolean} is_invoicing_emails - Indica se recebe emails de faturamento.
 * @property {boolean} is_entry_driver - Indica se é um motorista de entrada.
 * @property {string} name - Nome do usuário.
 * @property {string|null} surname - Sobrenome do usuário.
 * @property {string} short_name - Nome curto do usuário.
 * @property {string|null} phone - Telefone do usuário.
 * @property {string} image - Imagem do usuário.
 * @property {string} email - Email do usuário.
 * @property {string} password - Senha do usuário.
 * @property {string} reset_password - Token de redefinição de senha.
 * @property {string[]} company_plant_uuids	 - Centrais associadas ao usuário.
 * @property {Object} operational - Dados operacionais do usuário.
 * @property {Object[]} purchase_categories - Categorias de compra associadas.
 * @property {Object|null} financial - Informações financeiras do usuário.
 * @property {Object} user_commercial - Informações comerciais do usuário.
 * @property {Object[]} user_commercial_commision - Comissões comerciais do usuário.
 * @property {Object} detail - Detalhes do usuário.
 * @property {Object|null} permission_contract - Informações de contrato do usuário.
 * @property {boolean} internal_type - Tipo interno do usuário.
 * @property {boolean} has_access_to_all_plants - Indica se tem acesso a todas as Centrais.
 * @property {number} alert_group_id 
 * @property {Array<{ role_id: number, name: string }>} role - Lista de categorias com permissão de aprovação.
 * @property {number} role_id
 */
export const UserPermissionType = {};

/**
 * Inicializar UserPermissionType
 * 
 * @returns {UserPermissionType}
 */
export const initUserPermissionType = () => {
  return {
    id: null,
    uuid: null,
    external_code: null,
    notify: false,
    status: true,
    is_user: false,
    is_intermediary: false,
    is_seller: false,
    is_concrete: false,
    view_sellers: null,
    bank_account_ids: null,
    is_service: false,
    is_external_service: false,
    is_invoicing_emails: false,
    is_entry_driver: false,
    name: "",
    surname: null,
    short_name: "",
    phone: null,
    image: "default.png",
    email: "",
    password: "",
    reset_password: "",
    company_plant_uuids: ['all'],
    operational: {},
    purchase_categories: [],
    financial: null,
    user_commercial: {},
    user_commercial_commision: [],
    detail: {},
    permission_contract: null,
    internal_type: false,
    has_access_to_all_plants: true,
    role: [],
    role_id: null,
    alert_group_id: null,
  };
};
