<template>
  <div class="container-fluid">
    <div class="card main-card mt-4">
      <ShadedTable :length="items.length" :headers="headers" :loading="loading">
        <template v-for="(item, index) in items" :slot="index">
          <ShadedCol>
            <div class="d-flex justify-content-start align-items-center">
              <div
                class="status-container"
                :class="getStatus(item.status).class"
              >
                <img
                  v-if="getStatus(item.status).iconSrc"
                  :src="getStatus(item.status).iconSrc"
                  width="16px"
                  height="16px"
                /><span>{{ getStatus(item.status).text }}</span>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.start_at ?? item.scheduling_date | parseDate("DD/MM/YYYY HH:mm:ss") }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.user_equipment ?? "------" }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.name }}
            </h5>
          </ShadedCol>
          <!-- <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.equipment_category_name }}
            </h5>
          </ShadedCol> -->
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.types_equipment_categories_name }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.mileage ? `${item.mileage} km` : "" }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.hourMeter ? `${item.hourMeter} horas` : "" }}
            </h5>
          </ShadedCol>
          <ShadedCol>
            <h5 class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ item.duration ?? "------" }}
            </h5>
          </ShadedCol>
        </template>
      </ShadedTable>
      <PuzlEmptyData
        v-if="!loading && (!items || !items.length)"
      ></PuzlEmptyData>
    </div>
  </div>
</template>

<script>
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";

export default {
  name: "List",
  inheritAttrs: false,
  components: {
    SkeletonPuzlGrid,
    SkeletonPuzlFullWidth,
    PuzlEmptyData,
    ShadedTable,
    ShadedCol,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /**
     * @param {number} statusId
     * @returns {Object}
     * @property {string} class
     * @property {string} text
     * @property {string} iconSrc
     */
    getStatus(statusId) {
      let statusList = {};
      statusList[0] = {
        class: "yellow",
        text: "Pendente",
        iconSrc: "/img/icons/icons8/ios/loading-warning.png",
      };
      statusList[1] = {
        class: "red",
        text: "N. Realizado",
        iconSrc: "/img/icons/cancel-red.png",
      };
      statusList[2] = {
        class: "blue",
        text: "Concluido",
        iconSrc: "/img/icons/icons8/ios/success-double-check-primary.png",
      };
      statusList[3] = {
        class: "red",
        text: "Expirado",
        iconSrc: "/img/icons/off-red.png",
      };
      return statusList[+statusId];
    },
  },
  computed: {},
  data() {
    return {
      headers: [
        "STATUS",
        "DATA",
        "USUÁRIO",
        "CHECKLIST",
        // "CATEGORIA",
        "TIPO",
        "KM",
        "HORÍMETRO",
        "DURAÇÃO",
      ],
    };
  },
};
</script>

<style scoped>
div.status-container {
  border-radius: 16px;
  height: 22px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 8px 12px 8px 12px;
  max-width: 145px;
}

div.status-container > span {
  font-family: "Fredoka", sans-serif;
  font-weight: 400;
  line-height: 12.1px;
}

div.status-container.red {
  background-color: #fcf3f3;
  color: #db4539;
  border: 1px solid #fcf3f3;
}

div.status-container.green {
  background-color: #f2f7f3;
  color: #149e57;
  border: 1px solid #f2f7f3;
}

div.status-container.blue {
  background-color: #f2f4f9;
  color: #1a70b7;
  border: 1px solid #f2f4f9;
}

div.status-container.yellow {
  background-color: #fef9f2;
  color: #f2b532;
  border: 1px solid #fef9f2;
}

div.status-container.gray {
  background-color: #ccc;
  color: #2b2d32;
  border: 1px solid #ccc;
}
</style>
