<template>
  <div class="row card-wrapper" v-if="$_purchase_budget_listed.items">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in $_purchase_budget_listed.items" :key="index">
      <div class="card main-card">
        <div class="p-3 pb-0 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <div @click="redirectToCreate(item)" class="align-self-center card-status mr-3 mb-1 pointer"
                :class="getStatusClass(item.budget_status)">
                <img class="mr-1" ref="icon" height="17" :src="getStatusIcon(item.budget_status)" />
                {{ getStatusText(item.budget_status) }}
              </div>
              <div style="margin-top: 3px;">
                <!-- ICONES -->
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div class="mr-2"
                    @click="showButtonsModal = false; handleShowModalListItems({ ...item, id: item.purchase_request_id })">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                        </div>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                  <div v-if="item.has_active_budget_entity" class="mr-2">
                    <span slot="reference" @click="handleShowModalListEntities(item.id)">
                        <img src="/img/icons/icons8/ios/new-store_primary.png" width="25">
                      </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <span style="font-size: 12px; font-weight: 400; color: #606062;">
                {{ item.input_company_plant_name }}
              </span>
            </div>
            <div class="d-flex">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32;">
                {{ 'RC-' + item.purchase_request_id }}
              </span>

            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                {{ item.purchase_category_description }}
              </span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                PRAZO DE COMPRA: {{ item.deadline | parseDate("DD MMM YYYY") }}
              </span>
            </div>
          </div>
          <!-- Menu engrenagem -->
          <div v-if="item.budget_status != PurchaseBudgetStatusEnum.keys.COMPLETED">
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div @click="updateBudgetStatus(PurchaseBudgetStatusEnum.keys.CANCELED, item)"
                v-if="item.budget_status != PurchaseBudgetStatusEnum.keys.CANCELED && item.budget_status != PurchaseBudgetStatusEnum.keys.COMPLETED">
                <a class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                  Cancelar
                </a>
              </div>
              <a v-if="item.budget_status == PurchaseBudgetStatusEnum.keys.CANCELED"
                @click.prevent="updateBudgetStatus(PurchaseBudgetStatusEnum.keys.PENDING, item)"
                class="dropdown-item d-flex" style="font-size: 12px;">
                <img src="/img/icons/icons8/ios/circle-left_success.png" width="18" alt="">
                Retomar
              </a>
            </base-dropdown>
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval :showButtons="false" :showUpdateButton="true" ref="modalListItemsApproval" />
    <ModalListEntities :showButtons="false" :showUpdateButton="true" ref="modalListEntities" />
  </div>
</template>

<script setup>
import { ref, defineEmits, computed } from "vue";
import PurchaseBudgetStatusEnum from "../../enums/PurchaseBudgetStatusEnum";
import ModalListItemsApproval from "../../../Request/views/list/Shared/_ModalListItemsApproval.vue";
import purchaseBudgetStore from "../../store/purchaseBudgetStore";
import { dialogs } from "../../../.../../../../../../helpers";
import router from "@/routes";
import ModalListEntities from "./Shared/ModalListEntities.vue";

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

// #region Data
const modalListItemsApproval = ref({});
const modalListEntities = ref({});
// #endregion

// #region Computeds
/** Store Getters */
const $_purchase_budget_listed = computed(() => purchaseBudgetStore.getters.getListed());
// #endregion

// #region Methods
function handleShowModalListItems(data) {
  modalListItemsApproval.value.handleCreateModal(data);
}
function handleShowModalListEntities(id) {
  modalListEntities.value.openModal(id);
}

const redirectToCreate = (budget) => {
  if (budget.budget_status === PurchaseBudgetStatusEnum.keys.CANCELED) {
    notify({ type: "warning", message: "Cotação cancelada" });
    return;
  }
  router.push(`/purchase/budget/create/${budget.purchase_request_id}`);
}

const getStatusText = (status) => {
  switch (status) {
    case PurchaseBudgetStatusEnum.keys.PENDING:
      return "Pendente";
    case PurchaseBudgetStatusEnum.keys.OPEN:
      return "Aberta";
    case PurchaseBudgetStatusEnum.keys.COMPLETED:
      return "Concluída";
    default:
      return "Cancelado";
  }
}

const getStatusClass = (status) => {
  switch (status) {
    case PurchaseBudgetStatusEnum.keys.PENDING:
      return "pending-status";
    case PurchaseBudgetStatusEnum.keys.COMPLETED:
      return "completed-status";
    default:
      return "denied-status";
  }
}

const getStatusIcon = (status) => {
  switch (status) {
    case PurchaseBudgetStatusEnum.keys.PENDING:
      return "/img/icons/icons8/ios/hourglass_gray.png";
    case PurchaseBudgetStatusEnum.keys.OPEN:
      return "/img/icons/icons8/ios/shopping-cart.png";
    case PurchaseBudgetStatusEnum.keys.COMPLETED:
      return "/img/icons/icons8/ios/double-tick-primary.png";
    default:
      return "/img/icons/icons8/ios/cancel_danger.png";
  }
}

async function updateBudgetStatus(status, budget) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente cancelar a cotação?');
  if (isConfirmed) {
    budget.status = status;
    await purchaseBudgetStore.actions.updatePartial(budget);
    emit("fetch");
    dialogs.notify();
  }
}
</script>


<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 8px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.2rem 0.6rem;
  margin-top: 0.1rem;
}

.footer-button {
  background-color: #F2B532D9;
  border-radius: 30px 0 10px 0;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  align-self: end;
  color: #ffffff;
  cursor: pointer;
}

.pending-status {
  background-color: #e8e8e8;
  color: #606062
}

.approved-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.completed-status {
  background-color: #F2F4F9;
  color: #1A70B7;
}

.dropdown-status .dropdown-toggle::after {
  display: none;
}
</style>
