import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";
import { ContractProposalEnum } from "../Enum/ContractProposalEnum";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ContractProposalListFilterExtType
 * @property {keyof typeof ContractProposalEnum.keys} status
 * @property {'contract' | 'proposal'} type
 * @property {string | null} seller_uuid
 * @property {string | null} state
 * @property {string | null} city
 * @property {string | null} entity_document
 * @property {number | null} situation_id
 * @property {boolean} approvation_status
 * @property {boolean} has_filter_pendencies
 * @property {boolean} pending_payment - PMC-2482 - Existe mas foi removido do filtro de listagem
 * @property {boolean} has_pending_launch -  PMC-2482 - Existe mas foi removido do filtro de listagem
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ContractProposalListFilterExtType)} ContractProposalListFilterType
 */
export const ContractProposalListFilterType = {};

/**
 * Inicializar ContractProposalListFilterType
 *
 * @returns {ContractProposalListFilterType}
 */
export const initContractProposalListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    range: {
      start: null,
      end: null,
    },
    status: null,
    type: null,
    seller_uuid: null,
    state: null,
    city: null,
    entity_document: null,
    situation_id: null,
    approvation_status: null,
    has_filter_pendencies: null,

    // PMC-2482 - Foram removidos do layout
    // pending_payment: null,
    // has_pending_launch: null,
  }
};

