import { PagerType, initPagerType } from "../../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} CompetitorListType
 * @property {number} id
 * @property {string} uuid
 * @property {string} name
 * @property {boolean} status
 * @property {string} observation
 * @property {string} created_at
 * @property {?string} updated_at
 * @property {number} created_by_user_id
 * @property {?number} updated_by_user_id
 * @property {string} created_by_user_name
 * @property {string} updated_by_user_name
 */
export const CompetitorListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<CompetitorListType> } )} CompetitorListPagerType
 */
export const CompetitorListPagerType = {};

/**
 * Inicializar CompetitorListPagerType
 * 
 * @returns {CompetitorListPagerType}
 */
export const initCompetitorListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

