<template>
  <div class="contract-proposal-business-partner-modal" v-if="contractProposal">
    <modal :show.sync="isOpen" class="business-partner-modal">
      <template slot="header">
        <AppIcon icon="handshake" color="warning" width="24px" class="mr-3" />
        <h5 class="modal-title">PARCEIRO COMERCIAL</h5>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
          class="close"
        />
      </template>
      <!-- Contrato -->
      <div class="modal-body-title">
        <AppIcon icon="info-squared" color="warning" width="22" />
        <h5>Contrato</h5>
      </div>
      <div class="content-card">
        <div class="app-card-title">{{ contractProposal.code }}</div>
        <div class="app-card-item mb-1">
          {{ contractProposal.entity.entity_name }}
        </div>
        <div class="app-card-item">
          {{ contractProposal.construction.construction_name }}
        </div>
      </div>
      <!-- PARCEIRO COMERCIAL -->
      <div class="modal-body-title">
        <AppIcon icon="handshake" color="warning" width="24px" />
        <h5>Parceiro Comercial</h5>
      </div>
      <div class="content-card">
        <div class="app-card-item mb-2">
          {{ contractProposal.BusinessPartner.entity_name }}
        </div>
        <div class="app-card-item">
          {{ contractProposal.BusinessPartner.document }}
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="app-modal-footer">
        <button class="app-button danger" @click="deleteBusinessPartner">
          Excluir
        </button>
        <button class="app-button warning" @click="selectBusinessPartner">
          Editar
        </button>
      </div>
    </modal>
    <ModalSearchEntity
      @selected="updateBusinessPartner"
      ref="modalSearchEntity"
    />
  </div>
</template>

<script setup>
//#region Imports
import { computed, getCurrentInstance, ref } from "vue";
import { useStore } from "@/shared/hooks/useStore.js";
import { dialogs, progress } from "../../../../../helpers";

import { AppButton, AppIcon } from "../../../../../components/AppGlobal";

import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";

//#endregion

//#region Emits
const emit = defineEmits(["contractProposalUpdated", "deletedBusinessPartner"]);
//#endregion

//#region ComponentRefs

//#endregion

//#region Data
const { proxy } = getCurrentInstance();
const store = useStore();
const contractProposal = ref(null);
const isOpen = ref(false);

//#endregion

//#region Computeds
/** Store Getters */
const $_user = computed(() => store.getters["auth/getUser"]);
//#endregion

//#region Methods
function closeModal() {
  isOpen.value = false;
}

/**
 * @param {object} contractProposal
 */
function openModal(contractProposalObject) {
  isOpen.value = true;
  contractProposal.value = contractProposalObject;
}

function selectBusinessPartner() {
  proxy.$refs.modalSearchEntity.openModal();
}

function updateBusinessPartner(entity) {
  progress.start();
  store
    .dispatch("businessPartner/storeBusinessPartner", {
      id: contractProposal.value.id,
      business_partner_id: entity.id,
      business_partner_created_by_user_id: $_user.value.id,
    })
    .then((response) => {
      contractProposal.value.BusinessPartner.entity_name =
        response.data.BusinessPartner.entity_name;
      contractProposal.value.BusinessPartner.document =
        response.data.BusinessPartner.document;
      contractProposal.value.userBusinessPartnerCreated.name =
        response.data.userBusinessPartnerCreated.name;
      contractProposal.value.business_partner_created_at =
        response.data.business_partner_created_at;
      emit("contractProposalUpdated", response.data);
      dialogs.notify(
        dialogs.TYPE_ENUM.SUCCESS,
        "Parceiro comercial atualizado com sucesso!"
      );
    })
    .finally(() => {
      progress.finish();
    });
}

function deleteBusinessPartner() {
  dialogs.confirmRemove().then((result) => {
    if (result) {
      progress.start();
      store
        .dispatch(
          "businessPartner/deleteBusinessPartnerContractProposalById",
          contractProposal.value.id
        )
        .then(() => {
          emit("deletedBusinessPartner", contractProposal.value.id);
          closeModal();
          dialogs.notify(
            dialogs.TYPE_ENUM.SUCCESS,
            "Parceiro comercial excluído com sucesso!"
          );
        })
        .finally(() => {
          progress.finish();
        });
    }
  });
}

//#endregion

//#region Lifecycle

//#endregion

//#region DefineExposes
/**
 * @typedef {Object} ContractProposalBusinesspartnerModal
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.contract-proposal-business-partner-modal .business-partner-modal {
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $warning-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 12px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  .modal-body {
    padding: 24px !important;
    margin: 0 !important;
    flex-direction: column;

    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }
    }
  }

  .app-modal-footer {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
    @media screen and (max-width: 767.98px) {
      justify-content: space-between;
    }

    button.app-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      padding: 8px 20px;
      background-color: white;
      border: 1px solid $muted-medium;
      border-radius: 4px;
      min-width: 128px;
      min-height: 30px !important;
      @include rhythm(font-size, 14);
      line-height: 14px;
      font-family: Fredoka;
      font-weight: 500 !important;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
      @media screen and (max-width: 767.98px) {
        flex: 0 0 48%;
        max-width: 50%;
      }

      &:hover {
        .app-icon {
          filter: brightness(0) invert(1);
        }
        img {
          filter: brightness(0) invert(1);
        }
      }

      &:disabled {
        opacity: 0.5;

        &:hover {
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      &.danger {
        color: $danger;

        &:hover {
          background-color: $danger;
          border-color: $danger;
          color: white;
        }
      }

      &.warning {
        color: $warning;

        &:hover {
          background-color: $warning;
          border-color: $warning;
          color: white;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.content-card {
  width: 100%;
  background-color: white;
  border: 1px solid $muted-lighter;
  border-radius: 8px;
  padding: 1rem 12px;
  margin-bottom: 24px;
}

.app-card-title {
  font-family: Fredoka;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500 !important;
  margin-bottom: 12px;
}
.app-card-item {
  font-family: Fredoka;
  font-size: 14px;
  line-height: 14px;
}
</style>
