import httpClient from "@/shared/libraries/httpClient";
import {HttpStatusEnum} from "@/shared/enums";
import {handleUnexpectedStatusCode} from "@/shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/administrative/entity-wallet+microservice",
});

/** MUTATIONS */

/** ACTIONS */
const actions = {
  /**
   *
   * @param params
   * @returns {Promise<*>}
   */
  adjustment: async function (params) {
    const res = await httpClient.post(`${urls.BASE}/adjustment`, params);

    switch (res.status) {
      case HttpStatusEnum.HTTP_OK: return res.data.data;
      default: handleUnexpectedStatusCode(res);
    }
  }
}

export default {
  actions,
};
