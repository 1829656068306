<template>
  <div class="general-settings" v-if="isOpen">
    <modal :show.sync="isOpen" size="xl" class="general-settings-modal">
      <template slot="header">
        <AppIcon icon="settings" color="primary" width="24px" class="mr-3"/>
        <h5 class="modal-title">Configurações</h5>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
          class="close"
        />
      </template>

      <!-- <AppTabs :tabs="tabs" :componentsMap="tabComponents"/> -->
      <AppTab defaultValue="general">
        <!-- Tab List -->
        <AppTabList slot="tab-list">
          <AppTabTrigger value="general" title="geral" icon="automation"/>
          <AppTabTrigger
            value="technology"
            title="Tecnologia"
            icon="physics"
            color="primary"
          />
          <AppTabTrigger
            value="commercial"
            title="comercial"
            icon="briefcase"
            color="success"
          />
          <AppTabTrigger
            value="operational"
            title="operacional"
            icon="chemical-plant-3"
            color="warning"
          />
          <AppTabTrigger
            value="financial"
            title="financeiro"
            icon="duration-finance"
            color="danger"
          />
          <AppTabTrigger
            value="email"
            title="PADRÃO DE E-MAIL"
            icon="email"
            color="primary"
          />
          <AppTabTrigger
            value="integration"
            title="integração"
            icon="query-inner-join"
            @click="fetchSyncHub"
          />
        </AppTabList>

        <!-- TabContent -->
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
          class="w-100 h-100 d-block"
        >
          <form class="needs-validation h-100" autocomplete="off">
            <template v-if="!loadingStore">
              <!-- General -->
              <AppTabContent title="Geral" icon="automation" value="general">
                <!-- Geral -->
                <AppTabSection title="geral">
                  <div class="app-form-group">
                    <label for="">APLICAÇÃO DE IMPOSTO GERAL EM MARGEM</label>
                    <AppSwitch
                      v-model="localSettings.geral_tax_application_in_margin"
                    />
                  </div>
                  <AppTabSectionConditional
                    v-if="localSettings.geral_tax_application_in_margin == 1"
                  >
                    <div class="app-form-group">
                      <label>margem</label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 100,
                          decimals: 0,
                        }"
                        v-model="localSettings.general_tax_margin_percent"
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                  </AppTabSectionConditional>
                  <hr/>
                  <div class="app-form-group">
                    <label for=""
                    >DESCONSIDERAR PENDÊNCIA DE LIBERAÇÃO DE CRÉDITO</label
                    >
                    <AppSwitch
                      v-model="localSettings.disable_pending_credit_release"
                    />
                  </div>
                  <AppTabSectionConditional
                    v-if="!localSettings.disable_pending_credit_release"
                  >
                    <div class="app-form-group">
                      <label>
                        DADOS DA CARTEIRA DE CLIENTES/FORNECEDORES
                      </label>
                      <PuzlSelect
                        class="app-tab-input"
                        :clearable="false"
                        v-model="localSettings.entity_credit_source"
                        :items="getTitles(GeneralSettingEntityCreditSourceEnum)"
                      />
                    </div>
                  </AppTabSectionConditional>
                  <div class="app-form-group">
                    <label for="">Habilitar validação da o.c a partir do lançamento de entrada de nota</label
                    >
                    <AppSwitch
                      v-model="localSettings.enable_oc_validation_on_invoice_entry"
                    />
                  </div>
                </AppTabSection>
                <!-- NOTA FISCAL DE CARREGAMENTO -->
                <AppTabSection title="NOTA FISCAL DE CARREGAMENTO">
                  <div class="app-form-group">
                    <label> Ocultar número de NF-e em relatórios? </label>
                    <AppSwitch v-model="localSettings.hide_nfe_report"/>
                  </div>
                </AppTabSection>
                <!-- NOTA FISCAL DE SERVIÇO  -->
                <AppTabSection title="NOTA FISCAL DE SERVIÇO">
                  <div class="app-form-group">
                    <label> OCULTAR VALORES UNITÁRIOS EM DESCRIÇÃO</label>
                    <AppSwitch
                      v-model="localSettings.is_hide_nfse_value_in_description"
                    />
                  </div>
                </AppTabSection>
                <AppTabSection title="Conta bancária">
                  <div class="app-form-group">
                    <label>Exibir conta bancária por filial?</label>
                    <AppSwitch
                      v-model="localSettings.show_bank_account_by_issuer"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Technology -->
              <AppTabContent
                title="TECNOLOGIA"
                icon="physics"
                color="primary"
                value="technology"
              >
                <!-- Geral -->
                <AppTabSection title="GERAL">
                  <div class="app-form-group">
                    <label for="">
                      APROVAR "REDIRECIONAMENTO" AUTOMATICAMENTE
                    </label>
                    <AppSwitch
                      v-model="localSettings.automatically_approve_redirect"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      EXIGIR QUE MCC ESTEJA NO MAPA DE PONTO DE CARGA PARA
                      ALTERAÇÃO DE REFERÊNCIA COMERCIAL</label
                    >
                    <AppSwitch
                      v-model="
                        localSettings.is_mcc_on_map_for_commercial_pref_change
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      EXIGIR CÓDIGO DE MOLDAGEM PARA CONCLUIR CARREGAMENTO
                    </label>
                    <AppSwitch v-model="localSettings.require_molding_code"/>
                  </div>
                  <hr/>
                  <div class="app-form-group">
                    <label for=""> PRAZO DE APLICAÇÃO DO CONCRETO </label>

                    <div class="prazo-wrapper">
                      <div class="prepend">
                        <AppIcon icon="stopwatch" width="12px"/>
                      </div>
                      <InputTimePicker
                        class="app-tab-input prazo-input"
                        minuteInterval="1"
                        v-model="localSettings.concrete_application_period"
                      />
                    </div>
                  </div>
                </AppTabSection>
                <!-- CARREGAMENTO COM DESVIOS -->
                <AppTabSection title="CARREGAMENTO COM DESVIOS">
                  <div class="app-form-group">
                    <label for="">BLOQUEAR</label>
                    <AppSwitch
                      v-model="localSettings.is_block_loads_with_deviation"
                    />
                  </div>
                  <AppTabSectionConditional
                    v-if="localSettings.is_block_loads_with_deviation == 1"
                  >
                    <div class="app-form-group">
                      <label> Aglomerante </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="
                          localSettings.block_loads_binder_percentage_value
                        "
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                    <div class="app-form-group">
                      <label> Agregado graúdo </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="
                          localSettings.block_loads_coarse_aggregate_value
                        "
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                    <div class="app-form-group">
                      <label> Agregado miúdo </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="localSettings.block_loads_fine_aggregate_value"
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                    <div class="app-form-group">
                      <label> Aditivo </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="localSettings.block_loads_additive_value"
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                    <div class="app-form-group">
                      <label> Adição </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="localSettings.block_loads_addition_value"
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                    <div class="app-form-group">
                      <label> Água </label>
                      <AppInputLimit
                        :options="{
                          min: -50,
                          max: 99,
                          decimals: 0,
                        }"
                        v-model="localSettings.block_loads_water_value"
                        class="no-box-shadow app-tab-input"
                      >
                        <small>%</small>
                      </AppInputLimit>
                    </div>
                  </AppTabSectionConditional>
                </AppTabSection>
                <AppTabSection title="RELATÓRIO DE PESAGEM">
                  <div class="app-form-group">
                    <label for="">Exibir grupo ao invés do mcc</label>
                    <AppSwitch
                      v-model="localSettings.show_group_instead_mcc"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">Exibir traço referência sem ajuste</label>
                    <AppSwitch
                      v-model="localSettings.show_mixe_reference"
                    />
                  </div>
                  <div v-if="localSettings.show_mixe_reference" class="app-form-group">
                    <label for="">Ocultar traço referência</label>
                    <AppSwitch
                      v-model="localSettings.hide_per_m3"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Commercial -->
              <AppTabContent
                title="comercial"
                icon="briefcase"
                color="success"
                value="commercial"
              >
                <!-- CONTRATOS -->
                <AppTabSection title="contratos">
                  <div class="app-form-group">
                    <label for=""> Imprimir propostas com pendências </label>
                    <AppSwitch v-model="localSettings.pending_proposals"/>
                  </div>
                  <div class="app-form-group">
                    <label for="">alterar o endereço da obra</label>
                    <AppSwitch v-model="localSettings.construction_address"/>
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Exigir anexo de obra para salvar novO contrato
                    </label>
                    <AppSwitch
                      v-model="
                        localSettings.is_attachment_required_for_new_proposal_contract
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label for=""> Exigir nível de aplicação </label>
                    <AppSwitch
                      v-model="localSettings.demand_level_responsibility"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Exigir informe de bomba externa e convencional
                    </label>
                    <AppSwitch
                      v-model="
                        localSettings.is_external_and_conventional_pump_report_required
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Exibir teor de agregados graúdos na inclusão de
                      traços</label
                    >
                    <AppSwitch v-model="localSettings.show_coarse_aggregate"/>
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Cobrar adicional de carregamentos de "retira"
                    </label>
                    <AppSwitch v-model="localSettings.is_charge_extra_loads"/>
                  </div>
                </AppTabSection>

                <AppTabSection title="CRM - MODAL DE OBRA">
                  <div class="app-form-group">
                    <label for="">
                      OBRIGATORIEDADE DOS DADOS DE CRM NO CADASTRO DE OBRA
                    </label>
                    <AppSwitch
                      v-model="localSettings.is_required_crm_data_construction"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Operacional -->
              <AppTabContent
                title="Operacional"
                icon="chemical-plant-3"
                color="warning"
                value="operational"
              >
                <AppTabSection title="carregamento">
                  <div class="app-form-group">
                    <label for=""> REALIZAR CARREGAMENTO RETROATIVO </label>
                    <AppSwitch v-model="localSettings.allow_backloading"/>
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      EMITIR A O.S. DE VIAGEM ANTES DE CONCLUIR A CARGA
                    </label>
                    <AppSwitch
                      v-model="
                        localSettings.is_concrete_os_issued_before_loading_completion
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      REALIZAR CORTE MANUAL DE ADITIVO E ÁGUA
                    </label>
                    <AppSwitch
                      v-model="localSettings.is_manual_cut_additive_water"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      AO IMPRIMIR NF-e, INCLUIR O.S. NA SEGUNDA PÁGINA
                    </label>
                    <AppSwitch v-model="localSettings.add_os_to_danfe"/>
                  </div>
                  <div class="app-form-group">
                    <label
                      for=""
                      class="d-flex align-items-center"
                      style="gap: 16px"
                    >
                      TRAVAR CARREGAMENTO SEM ANEXO

                      <el-popover trigger="click" placement="bottom">
                        CONSIDERA O ANEXO NA PENÚLTIMA CARGA

                        <template slot="reference">
                          <AppIcon
                            icon="info"
                            color="warning"
                            width="20"
                            class="pointer"
                          />
                        </template>
                      </el-popover>
                    </label>
                    <AppSwitch v-model="localSettings.disable_charging_without_attachment"/>
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      CONCLUIR VIAGEM APÓS CONCLUSÃO DA CARGA
                    </label>
                    <AppSwitch
                      v-model="
                        localSettings.finish_travel_after_charging
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      EXIBIR VOLUME ACUMULADO NA O.S. DA VIAGEM
                    </label>
                    <AppSwitch
                      v-model="localSettings.is_cumulative_load_volume"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      PREENCHER NOME DO MOTORISTA NO MDF-e
                    </label>
                    <AppSwitch v-model="localSettings.show_driver_mdfe"/>
                  </div>
                  <div class="app-form-group">
                    <label>
                      BLOQUEAR CARGA COM VOLume ACIMA DA CAPACIDADE DO EQUIP.
                    </label>
                    <AppSwitch
                      v-model="
                        localSettings.block_charging_equipment_limit_volumec
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      IGNORAR DESVIO DE CARREGAMENTO NA ÁGUA RETIDA DA VIAGEM
                    </label>
                    <AppSwitch v-model="localSettings.ignore_water_diversion"/>
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Financial -->
              <AppTabContent
                title="FINANCEIRO"
                icon="duration-finance"
                color="danger"
                value="financial"
              >
                <AppTabSection title="FATURAMENTO AUTOMÁTICO">
                  <div class="app-form-group">
                    <label for=""> HABILITAR </label>
                    <AppSwitch v-model="localSettings.automatic_billing"/>
                  </div>
                  <div class="app-form-group">
                    <label for=""> DEFINIR COMO PADRÃO EM CONTRATOS </label>
                    <AppSwitch v-model="localSettings.contract_proposal"/>
                  </div>
                  <div class="app-form-group">
                    <label for=""> FATURAR COM SALDO PARCIAL </label>
                    <AppSwitch v-model="localSettings.partial_balance"/>
                  </div>
                </AppTabSection>
                <AppTabSection title="FATURAMENTO MANUAL">
                  <div class="app-form-group">
                    <label for="">
                      Padrão de crédito de antecipação sempre igual a sim
                    </label>
                    <AppSwitch
                      v-model="localSettings.partial_balance_manual_billing"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Permitir incluir anexo de boleto manual
                    </label>
                    <AppSwitch
                      v-model="localSettings.allow_bank_billet_as_attachment"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="">
                      Permitir entregas de meses diferentes na mesma fatura
                    </label>
                    <AppSwitch
                      v-model="localSettings.allow_delivery_different_month"
                    />
                  </div>
                </AppTabSection>
                <AppTabSection title="EMISSÕES AUTOMÁTICAS">
                  <div class="app-form-group">
                    <label for=""> ENVIO AUTOMÁTICO DE E-MAIL </label>
                    <AppSwitch v-model="localSettings.automatic_email"/>
                  </div>
                  <div class="app-form-group">
                    <label for=""> EMISSÃO AUTOMÁTICA DE NFS-E </label>
                    <AppSwitch v-model="localSettings.automatic_nfse"/>
                  </div>
                  <div class="app-form-group">
                    <label for=""> EMISSÃO AUTOMÁTICA DE BOLETO </label>
                    <AppSwitch v-model="localSettings.automatic_billet"/>
                  </div>
                  <div class="app-form-group">
                    <label for=""> BLOQUEAR NFS-E CONFORME CONFIG. DO CONTRATO </label>
                    <AppSwitch v-model="localSettings.block_nfse_by_contract_param"/>
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- PADRÃO DE E-MAIL -->
              <AppTabContent
                title="PADRÃO DE E-MAIL"
                icon="email"
                color="primary"
                value="email"
              >
                <!-- Geral -->
                <AppTabSection title="geral">
                  <div class="app-form-group">
                    <label for=""> Empresa </label>
                    <PuzlSelect
                      @input="changedEmailDefaultIssuerGroupId"
                      v-model="email_default_issuer_group_id"
                      :items="$_company_plant_issuer_groups"
                      :clearable="false"
                      class="app-tab-input email-input"
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="emailDefault && emailDefault[current_model]"
                  >
                    <label for=""> Modelo </label>
                    <PuzlSelect
                      v-model="current_model"
                      :items="models"
                      :clearable="false"
                      class="app-tab-input"
                    />
                  </div>
                </AppTabSection>
                <!-- E-Mail -->
                <AppTabSection
                  title="E-mail"
                  v-if="emailDefault && emailDefault[current_model]"
                >
                  <div class="app-form-group">
                    <label for=""> ASSUNTO </label>
                    <div class="assunto-wrapper">
                      <input
                        type="text"
                        v-model="emailDefault[current_model].subject_prefix"
                        class="app-tab-input app-input email-input"
                        @input="emailChanges = true"
                      />
                      <div class="append">- EMPRESA | FATURA 123</div>
                    </div>
                  </div>
                  <div class="app-form-group email-text">
                    <label for=""> MENSAGEM </label>
                    <button
                      label=""
                      class="ml-auto set-default-button btn-reset"
                      @click.prevent="setDefaultParams()"
                    >
                      padrão
                    </button>
                  </div>
                  <div class="app-form-group">
                    <mention
                      class="w-100"
                      style="font-size: 2rem !important"
                      :default_comment="emailDefault[current_model].body"
                      :items="Object.values($_mentions[current_model].mentions)"
                      v-model.lazy="emailDefault[current_model].body"
                      rows="12"
                      @input="emailChanges = true"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Integration -->
              <AppTabContent
                title="INTEGRAÇÕES"
                icon="query-inner-join"
                value="integration"
              >
                <!-- ERP -->
                <AppTabList title="ERP">
                  <AppTabTrigger
                    title="Sankhya"
                    value="sankhya"
                    @click="changedSyncHubSelected(SyncHubEnum.keys.SANKHYA)"
                    customTrigger
                    :hasIntegration="
                      syncHubSelected === SyncHubEnum.keys.SANKHYA
                    "
                  />
                  <AppTabTrigger
                    title="Sap"
                    value="sap"
                    @click="changedSyncHubSelected(SyncHubEnum.keys.SAP)"
                    customTrigger
                    :hasIntegration="syncHubSelected === SyncHubEnum.keys.SAP"
                  />
                  <AppTabTrigger
                    title="Totvs"
                    value="totvs"
                    @click="changedSyncHubSelected(SyncHubEnum.keys.TOTVS)"
                    customTrigger
                    :hasIntegration="syncHubSelected === SyncHubEnum.keys.TOTVS"
                  />
                </AppTabList>
                <!-- Outros -->
                <AppTabList title="outros">
                  <AppTabTrigger
                    v-if="$hasPrivilege(1)"
                    title="régua de cobrança"
                    value="billing-rules"
                    customTrigger
                    :hasIntegration="!!localSettings.billing_rule"
                  />
                  <AppTabTrigger
                    title="whatsapp"
                    value="whatsapp"
                    customTrigger
                    :hasIntegration="!!localSettings.is_enable_whatsapp_api"
                  />
                </AppTabList>
              </AppTabContent>
              <!-- Sankhya -->
              <AppTabContent
                title="Sankhya"
                :value="SyncHubEnum.keys.SANKHYA"
                icon="query-inner-join"
                class="integration-form"
                v-if="localSyncHubValues"
              >
                <AppTabSection title="Configuração">
                  <div class="app-form-group">
                    <label for=""> Habilitar </label>
                    <AppSwitch
                      v-model="localSyncHubValues.is_active"
                      @click="setCurrentActiveSyncHub(SyncHubEnum.keys.SANKHYA)"
                    />
                  </div>
                  <template
                    v-if="localSyncHubValues && localSyncHubValues.is_active"
                  >
                    <div class="app-form-group">
                      <label for="whatsapp-token"> url de Autenticação</label>
                      <input
                        type="text"
                        v-model="localSyncHubValues.auth_url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Url base </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Usuário</label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_username"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Senha </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_password"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Token </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.token"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> AppKey </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.app_key"
                        class="app-input"
                      />
                    </div>
                  </template>
                </AppTabSection>
                <AppTabSection title="Apontamentos Principais"
                  v-if="
                    localSyncHubValues && localSyncHubValues.is_active &&
                    localSyncHubValues.sync_hub === SyncHubEnum.keys.SANKHYA"
                  >
                  <!-- Apontamentos Principais -->
                  <div class="app-form-group py-2">
                    <label for="whatsapp-business-id"> Código Externo da Formulação </label>
                    <input
                      id="whatsapp-business-id"
                      type="text"
                      v-model="localSyncHubValues.formulation_external_id"
                      class="app-input"
                    />
                  </div>
                </AppTabSection>
                <AppTabSection title="Apontamentos de Rateio"
                  v-if="localSyncHubValues && localSyncHubValues.is_active">
                  <!-- Apontamentos de Rateio -->
                    <div class="app-form-group py-2">
                      <label for="whatsapp-business-id"> Código Externo Rateio da Formulação </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.formulation_apport_external_id"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group py-2">
                      <label for="whatsapp-business-id"> Código Externo Rateio do Serviço de Concretagem </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.service_apport_external_id"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group py-2">
                      <label for="whatsapp-business-id"> Código Externo Rateio do Adicional </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.additional_apport_external_id"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group py-2">
                      <label for="whatsapp-business-id"> Código Externo Rateio do Avulso </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.single_additional_apport_external_id"
                        class="app-input"
                      />
                    </div>
                </AppTabSection>
              </AppTabContent>
              <!-- SAP -->
              <AppTabContent
                title="SAP"
                :value="SyncHubEnum.keys.SAP"
                icon="query-inner-join"
                class="integration-form"
                v-if="localSyncHubValues"
              >
                <AppTabSection title="Configuração">
                  <div class="app-form-group">
                    <label for=""> Habilitar </label>
                    <AppSwitch
                      v-model="localSyncHubValues.is_active"
                      @click="setCurrentActiveSyncHub(SyncHubEnum.keys.SAP)"
                    />
                  </div>
                  <template
                    v-if="localSyncHubValues && localSyncHubValues.is_active"
                  >
                    <div class="app-form-group">
                      <label for="whatsapp-token"> Ulr de Autenticação</label>
                      <input
                        type="text"
                        v-model="localSyncHubValues.auth_url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Url base </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Usuário</label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_username"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Senha </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_password"
                        class="app-input"
                      />
                    </div>
                  </template>
                </AppTabSection>
              </AppTabContent>
              <!-- Totvs -->
              <AppTabContent
                title="Totvs"
                :value="SyncHubEnum.keys.TOTVS"
                icon="query-inner-join"
                class="integration-form"
                v-if="localSyncHubValues"
              >
                <AppTabSection title="Configuração">
                  <div class="app-form-group">
                    <label for=""> Habilitar </label>
                    <AppSwitch
                      v-model="localSyncHubValues.is_active"
                      @click="setCurrentActiveSyncHub(SyncHubEnum.keys.TOTVS)"
                    />
                  </div>
                  <template
                    v-if="localSyncHubValues && localSyncHubValues.is_active"
                  >
                    <div class="app-form-group">
                      <label for="whatsapp-token"> url de Autenticação</label>
                      <input
                        type="text"
                        v-model="localSyncHubValues.auth_url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Url base </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.url"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Usuário</label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_username"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Senha </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.auth_password"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> Token </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.token"
                        class="app-input"
                      />
                    </div>
                    <div class="app-form-group">
                      <label for="whatsapp-business-id"> AppKey </label>
                      <input
                        id="whatsapp-business-id"
                        type="text"
                        v-model="localSyncHubValues.app_key"
                        class="app-input"
                      />
                    </div>
                  </template>
                </AppTabSection>
              </AppTabContent>
              
              <!-- Régua de cobrança -->
              <AppTabContent
                v-if="$hasPrivilege(1)"
                title="Régua de cobrança"
                value="billing-rules"
                icon="query-inner-join"
              >
                <AppTabSection title="Configuração">
                  <div class="app-form-group">
                    <label for=""> Habilitar régua de cobrança </label>
                    <AppSwitch v-model="localSettings.billing_rule"/>
                  </div>
                  <div
                    class="app-form-group"
                    v-if="localSettings?.billing_rule"
                  >
                    <label>Para os seguintes métodos de pagamento</label>
                    <PuzlSelect
                      class="w-100"
                      v-model="localSettings.billing_rule_payment_method_uuids"
                      :items="$_payment_methods"
                      :multiple="true"
                      customKey="uuid"
                    />
                  </div>
                  <hr v-if="localSettings?.billing_rule"/>
                  <div
                    class="app-form-group"
                    v-if="localSettings?.billing_rule"
                  >
                    <label>Para os seguintes métodos de pagamento</label>
                    <AppSwitch
                      v-model="localSettings.overdue_installment_reminders"
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      localSettings?.billing_rule &&
                      localSettings.overdue_installment_reminders
                    "
                  >
                    <label> Dias antes do vencimento </label>
                    <AppInputLimit
                      :options="{
                        min: 0,
                        max: 99,
                        decimals: 0,
                      }"
                      v-model="localSettings.days_before_due_date"
                      class="app-tab-input no-box-shadow"
                    >
                      <small>DIAS</small>
                    </AppInputLimit>
                  </div>
                  <hr v-if="localSettings?.billing_rule"/>
                  <div
                    class="app-form-group"
                    v-if="localSettings?.billing_rule"
                  >
                    <label> Lembretes de parcelas vencidas</label>
                    <AppSwitch
                      v-model="
                        localSettings.overdue_installment_reminders_expired
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      localSettings?.billing_rule &&
                      localSettings.overdue_installment_reminders_expired
                    "
                  >
                    <label>
                      RECORRÊNCIA DE COBRANÇA DAS PARCELAS VENCIDAS. ENVIAR
                      EMAIL A CADA:
                    </label>
                    <AppInputLimit
                      v-model="localSettings.overdue_billing_recurring"
                      :options="{ min: 0, max: 99, decimals: 0 }"
                      class="no-box-shadow app-tab-input"
                    >
                      <small>DIAS</small>
                    </AppInputLimit>
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      localSettings?.billing_rule &&
                      localSettings.overdue_installment_reminders_expired
                    "
                  >
                    <label>
                      NÃO COBRAR PARCELA VENCIDA APÓS QUANTIDADE DE DIAS
                      CONFIGURADA
                    </label>
                    <AppInputLimit
                      :options="{
                        min: 0,
                        max: 99,
                        decimals: 0,
                      }"
                      v-model="localSettings.maximum_collection_time"
                      class="no-box-shadow app-tab-input"
                    >
                      <small>DIAS</small>
                    </AppInputLimit>
                  </div>
                  <label
                    class="warning-text"
                    v-if="
                      localSettings.billing_rule &&
                      localSettings.overdue_installment_reminders_expired
                    "
                  >
                    CONFORME CONFIGURADO, Serão realizadas
                    <span class="bold">"{{ billingRecurrenceLimit }}" </span
                    >cobranças de cada parcela a cada
                    <span class="bold">
                      "{{ localSettings.overdue_billing_recurring }} dias"
                    </span>
                    APÓS O VENCIMENTO. (LIMITE: 4 VEZES)
                  </label>

                  <label
                    v-if="
                      localSettings.billing_rule &&
                      localSettings.overdue_installment_reminders_expired &&
                      billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT
                    "
                    class="m-0 col-md-12 col-form-label form-control-label text-right text-danger"
                  >
                    LIMITE DE RECORRÊNCIA EXCEDIDO (MÁX. DE 4)
                  </label>
                </AppTabSection>
              </AppTabContent>
              <!-- Whatsapp -->
              <AppTabContent
                title="Whatsapp"
                value="whatsapp"
                icon="query-inner-join"
              >
                <AppTabSection title="FATURAMENTO AUTOMÁTICO">
                  <div class="app-form-group">
                    <label for=""> HABILITAR API DO WHATSAPP </label>
                    <AppSwitch v-model="localSettings.is_enable_whatsapp_api"/>
                  </div>
                  <div class="app-form-group">
                    <label for="whatsapp-token">Token</label>
                    <input
                      id="whatsapp-token"
                      type="text"
                      v-model="localSettings.whatsapp_api_token"
                      class="app-input app-tab-input"
                    />
                  </div>
                  <div class="app-form-group">
                    <label for="whatsapp-business-id">
                      IDENTIFICAÇÃO DA CONTA DO WHATSAPP BUSINESS
                    </label>
                    <input
                      id="whatsapp-business-id"
                      type="text"
                      v-model="localSettings.whatsapp_api_business_id"
                      class="app-input app-tab-input"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- App Tab Footer -->
              <div class="app-tab-footer">
                <AppButton
                  label="cancelar"
                  hasOutline
                  size="sm"
                  width="128px"
                  type="danger"
                  @click="closeModal"
                />
                <AppButton
                  label="salvar"
                  hasOutline
                  size="sm"
                  width="128px"
                  type="success"
                  :isLoading="isSaving"
                  :isDisabled="
                    !!(
                      invalid ||
                      (localSettings.billing_rule &&
                        localSettings.overdue_installment_reminders_expired &&
                        billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT) ||
                      isSaving
                    )
                  "
                  @click.prevent="
                    !(
                      invalid ||
                      (localSettings.billing_rule &&
                        localSettings.overdue_installment_reminders_expired &&
                        billingRecurrenceLimit > BILLING_RECURRENCE_LIMIT)
                    ) && handleSubmit(handleUpdate)
                  "
                />
              </div>
            </template>
            <div
              class="h-100 d-flex justify-content-center align-items-stretch"
              v-else
            >
              <div class="d-inline-flex">
                <i
                  class="fas fa-circle-notch fa-spin m-auto"
                  style="color: #b0b0b0; font-size: 60px"
                />
              </div>
            </div>
          </form>
        </validation-observer>
      </AppTab>
    </modal>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import {dialogs, progress, getTitles} from "../../../../helpers";
import {useStore} from "@/shared/hooks/useStore.js";
import {hasPrivilege, hasPermission} from "@/plugins/permissions";
import syncHubStore from "../../../sync/sync-hub/store/syncHubStore";
import {formatErrorValidation} from "@/plugins";

import {SyncHubEnum} from "../../../sync/shared/enums/SyncHubEnum";
import GeneralSettingEntityCreditSourceEnum from "../enums/GeneralSettingEntityCreditSourceEnum";
import sapConfigurationStore from "../../../sap/sap-configuration/store";
import {initSyncHubType} from "../../../sync/sync-hub/types/SyncHubType";

import {
  AppButton,
  AppIcon,
  AppTab,
  AppTabContent,
  AppTabSection,
  AppTabTrigger,
  AppTabList,
  AppSwitch,
  AppTabSectionConditional,
  AppInputLimit,
} from "../../../../components/AppGlobal";
// import InputLimit from "@/components/Utils/InputLimit";
import PuzlSelect from "@/components/PuzlSelect";
import InputTimePicker from "@/components/InputTimePickerV2";
import Mention from "@/components/Inputs/Mention";

//------------------------------//
// #region Data
const model_enum = {
  invoice: 0,
  bill: 1,
  contract: 2,
  reminder: 3,
};

const isOpen = ref(false);
const {proxy} = getCurrentInstance();
const store = useStore();
const BILLING_RECURRENCE_LIMIT = ref(4);
const syncHubSelected = ref(null);
const localSyncHubValues = ref(null);
const loadingStore = ref(true);
const isSaving = ref(false);
const current_model = ref(0);
const email_default_issuer_group_id = ref(null);
const emailDefault = ref(null);
const emailChanges = ref(false);
const models = ref([
  {id: model_enum.invoice, name: "Fatura"},
  {id: model_enum.bill, name: "Cobrança"},
  {id: model_enum.contract, name: "Contrato"},
  {id: model_enum.reminder, name: "Lembrete"},
]);
const localSettings = ref({});
//#endregion

//------------------------------//
// #region Computed
const $_setting = computed(() => store.getters["generalSetting/show"]);
const $_payment_methods = computed(
  () => store.getters["paymentMethod/fetchUsable"]
);
const $_sync_hub = computed(() => syncHubStore.getters.getItem());
const $_company_plant_issuer_groups = computed(
  () => store.getters["companyPlantIssuerGroup/fetch"]
);
const $_sapConfiguration = computed(() => {
  return sapConfigurationStore.getItem();
});
const $_mentions = computed(
  () => store.getters["companyEmailParam/getListMentions"]
);

const billingRecurrenceLimit = computed(() => {
  if (
    localSettings.value &&
    localSettings.value.maximum_collection_time > 0 &&
    localSettings.value.overdue_billing_recurring > 0
  ) {
    return Math.trunc(
      localSettings.value.maximum_collection_time /
      localSettings.value.overdue_billing_recurring
    );
  }
  return 0;
});

//#endregion

function setCurrentActiveSyncHub(SyncHubName) {
  syncHubSelected.value = SyncHubName;
}

//------------------------------//
//#region Methods
async function openModal() {
  loadingStore.value = true;
  isOpen.value = true;
  await store.dispatch("generalSetting/show");
  await store.dispatch("companyEmailParam/getMentions");
  await store.dispatch("companyPlantIssuerGroup/fetchItems", {
    order_by: [{column: "company_plant_issuer_groups.name", is_desc: false}],
  });
  await store.dispatch("paymentMethod/fetchItems");
  syncHubSelected.value = await syncHubStore.actions.findActiveTitle();
  changedSyncHubSelected(syncHubSelected.value);
  proxy.$refs.formValidator.validate();
  localSettings.value = $_setting.value;
  loadingStore.value = false;
}

function closeModal() {
  isOpen.value = false;
}

async function fetchSyncHub() {
  syncHubSelected.value = await syncHubStore.actions.findActiveTitle();
}

async function changedSyncHubSelected(syncHub) {
  loadingStore.value = true;
  if (!syncHub) {
    return;
  }
  try {
    const response = await syncHubStore.actions.find(syncHub);

    if (response.sync_hub) {
      localSyncHubValues.value = {...$_sync_hub.value};
    } else {
      localSyncHubValues.value = initSyncHubType();
    }

    // syncHubSelected.value = syncHub;
  } catch (e) {
    dialogs.notifyError();
  }
  loadingStore.value = false;
}

async function handleUpdate() {
  progress.start();
  isSaving.value = true;
  await updateSyncHub(syncHubSelected.value);
  if (emailChanges.value) {
    updateEmailDefault();
  }

  store
    .dispatch("generalSetting/update", localSettings.value)
    .then((response) => {
      const payload = {
        waID: response.data.whatsapp_api_business_id,
        token: response.data.whatsapp_api_token,
        base_url: window.location.origin,
      };
      dialogs.notify(response.error_type, response.message);
      store
        .dispatch("whatsappApi/setupWhatsappApi", payload)
        .then((response) => {
          dialogs.notify(response.error_type, response.message);
        })
        .catch((error) =>
          dialogs.notify(
            error.error_type,
            "Não foi possível configurar a api do whatsapp. erro: " +
            error.message
          )
        );
    })
    .catch((error) => {
      if (error.status == 200) {
        dialogs.notify("danger", error.data.message);
      } else if (error.response && error.response.status === 422) {
        let errors = formatErrorValidation(error.response.data.errors);
        dialogs.notify("danger", errors);
      }
    })
    .finally(() => {
      progress.finish();
      isSaving.value = false;
      closeModal();
    });
}

async function updateSyncHub(syncHub) {

  if (!syncHub) {
    return;
  }
  try {
    await syncHubStore.actions.createOrUpdate({
      ...localSyncHubValues.value,
      sync_hub: syncHub,
    });
  } catch(error) {
    dialogs.notifyError({
      type: 'danger',
      message: error.message
    })
  }
}

/**
 * Atualizar Padrão de E-mail
 * @param {boolean} closeModal Fechar Modal. Padrão: True
 */
function updateEmailDefault() {
  isSaving.value = true;
  progress.start();
  dialogs.notify("info", "Estamos trabalhando em sua solicitação.");
  store
    .dispatch("companyEmailParam/storeOrUpdate", emailDefault.value)
    .then(({error_type, message}) => {
      dialogs.notify(error_type, message);
    })
    .catch((error) => {
      const errors = error.status
        ? error.data.message
        : formatErrorValidation(error.response.data.errors);
      dialogs.notify("danger", errors);
    })
    .finally(() => {
      progress.finish();
      isSaving.value = false;
    });
}

function changedEmailDefaultIssuerGroupId() {
  updateEmailDefault(false);
  current_model.value = 0;
  progress.start();
  const params = {
    filter: {
      company_plant_issuer_group_id: email_default_issuer_group_id,
    },
  };
  store
    .dispatch("companyEmailParam/fetchItems", params)
    .then((response) => {
      // Adicione modelos ausentes
      emailDefault.value = response.data;
      Object.values(model_enum).forEach((modelValue) => {
        if (emailDefault.value.some((item) => item.model === modelValue)) {
          emailDefault.value.push({
            id: null,
            company_plant_issuer_group_id: email_default_issuer_group_id.value,
            model: modelValue,
            subject_prefix: "",
            body: "",
          });
        }
      });
      // Ordenar por modelos
      emailDefault.value.sort((a, b) => a.model - b.model);
      dialogs.notify(response.error_type, response.message);
    })
    .catch((error) => {
      const errors = error.status
        ? error.data.message
        : formatErrorValidation(error.response.data.errors);
      dialogs.notify("danger", errors);
    })
    .finally(() => {
      progress.finish();
    });

  emailDefault.value = null;
}

function setDefaultParams() {
  const keepGoing =
    current_model.value >= 0 &&
    $_mentions &&
    $_mentions.value[current_model.value];
  if (!keepGoing) {
    return;
  }
  emailDefault.value[current_model.value].body =
    $_mentions.value[current_model.value].default;
}

//#endregion

//------------------------------//
//#region DefineExposes
/**
 * @typedef {Object} StockLocationCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion

//------------------------------//
//#region lifeCycleHooks
onMounted(async () => {
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.general-settings .general-settings-modal {
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $primary-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 0 !important;
    }

    h5.modal-title {
      margin-left: 12px;
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  .modal-body {
    padding: 0 !important;
    margin: 0 !important;
    flex-direction: column;

    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

.integration-form {
  @include media-query(up, lg) {
    .app-input {
      flex: 0 0 68%;
    }
  }
  @include media-query(down, lg) {
    .app-input {
      width: 100%;
      margin-top: 4px;
    }
  }
}

.email-input.app-tab-input {
  @include media-query(up, lg) {
    flex: 0 0 68%;
  }
  @include media-query(down, lg) {
    width: 100%;
    margin-top: 4px;
  }
}

.prazo-wrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  @include media-query(down, lg) {
    width: 100%;
    margin-top: 4px;
  }

  .prepend {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border: 1px solid $muted-medium;
    border-right: none;
    padding: 8px;
  }

  .prazo-input {
    width: 100%;
    flex: 1;
  }
}

.set-default-button {
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
  border-radius: 4px;
  padding: 4px 12px;
  font-family: Fredoka;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $primary;
    color: white;
    transform: none !important;
  }
}

.assunto-wrapper {
  @include media-query(up, lg) {
    flex: 0 0 68%;
  }
  @include media-query(down, lg) {
    width: 100%;
    margin-top: 4px;
  }

  display: flex;
  align-items: stretch;

  .email-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .append {
    font-family: Fredoka;
    font-weight: 400;
    font-size: 10px;
    line-height: 12.1px;
    color: $muted;
    background-color: $muted-light;
    padding: 0 8px;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    display: flex;
    align-items: center;
  }
}

.warning-text {
  text-align: left;
  font-family: Fredoka;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $dark;
  background-color: $danger-lighter;
  padding: 8px 12px;
  border-radius: 8px;
  @include media-query(down, lg) {
    font-size: 12px;
  }

  .bold {
    font-weight: 500;
    color: inherit;
    font-size: 16px;
    @include media-query(down, lg) {
      font-size: 14px;
    }
  }
}

.app-tab-footer {
  display: flex;
  justify-content: end;
  gap: 8px;
  padding: 0 24px 32px;
}
</style>

<style lang="scss">
.prazo-wrapper {
  .prazo-input .display-time {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
</style>
