import { render, staticRenderFns } from "./StatusBadge.vue?vue&type=template&id=403faed7&scoped=true"
import script from "./StatusBadge.vue?vue&type=script&setup=true&lang=js"
export * from "./StatusBadge.vue?vue&type=script&setup=true&lang=js"
import style0 from "./StatusBadge.vue?vue&type=style&index=0&id=403faed7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403faed7",
  null
  
)

export default component.exports