/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} PurchaseBudgetType
 * @property {?number} id [V]
 * @property {?number} purchase_request_id [C]
 * @property {string} code [C,A]
 * @property {string} deadline [C,A]
 * @property {?number} requester_id [C]
 * @property {string} note [C,A]
 * @property {string} purchase_justification [C,A]
 * @property {number} status [C,A]
 * @property {?number} product_service_hub_type_id [C]
 * @property {?number} cost_center_id [C]
 * @property {?number} input_company_plant_id [C]
 * @property {?number} company_plant_buyer_id [C]
 * @property {string} created_by_user_name [V]
 * @property {string} created_by_user_email [V]
 * @property {boolean} has_active_budget_entity [V]
 * @property {string} cost_center_name [V]
 * @property {string} purchase_category_description [V]
 * @property {string} input_company_plant_name [V]
 * @property {boolean} has_attachments [V]
 * @property {number} budget_status [V]
 */
export const PurchaseBudgetType = {};

/**
 * Inicializar PurchaseBudgetType
 * 
 * @returns {PurchaseBudgetType}
 */
export const initPurchaseBudgetType = () => {
  return {
    id: null,
    purchase_request_id: null,
    code: '',
    deadline: '',
    requester_id: null,
    note: '',
    purchase_justification: '',
    status: 1,
    product_service_hub_type_id: null,
    cost_center_id: null,
    input_company_plant_id: null,
    company_plant_buyer_id: null,
    created_by_user_name: '',
    created_by_user_email: '',
    has_active_budget_entity: false,
    cost_center_name: '',
    purchase_category_description: '',
    input_company_plant_name: '',
    has_attachments: false,
    budget_status: 0
  };
};