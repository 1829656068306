<template>
  <div>
    <AppFixedPageTitle
      title="Conciliação Bancária"
      icon="/img/icons/icons8/ios/exchange.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/progress-indicator_grey.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.status"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                class="form-control-label fs-11 new-default-gray-font font-weight-400"
              >
                Conta Bancária
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.bank_account_id"
                :items="$_bank_accounts"
                :disableBoxShadow="true"
                placeholder="Conta Bancária"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            text="Novo"
            type="success"
            @click.prevent="handleCreate"
            icon="/img/icons/icons8/ios/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
      :isHideTableOnMobileActive="true"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems"
        variant="text-only"
      />
    </AppTabSelect>
    <BankReconciliationListPageCards
      v-if="listType === 'cards'"
      :loading="loading"
      @openTransaction="openTransaction"
      @handleDelete="handleDelete"
      @changeStatus="changeStatus"
    />
    <BankReconciliationListPageTable
      v-if="listType === 'table'"
      :loading="loading"
      @openTransaction="openTransaction"
      @handleDelete="handleDelete"
      @changeStatus="changeStatus"
    />
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <PuzlEmptyData v-if="!$_bank_reconciliations.length && !loading"/>
        </div>
      </div>
    </div>

    <ModalCreate ref="modalCreate" />
    <modalImportTextFile ref="modalImportTextFile" />
  </div>
</template>

<script setup>
//#region Imports
import BankReconciliationListPageCards from './BankReconciliationListPageCards.vue'
import BankReconciliationListPageTable from './BankReconciliationListPageTable.vue'
import {computed, getCurrentInstance, onMounted, reactive, ref} from "vue";
import ModalCreate from "@/views/Modules/Financial/BankReconciliation/Shared/_ModalCreate.vue"
import ModalImportTextFile from "@/views/Modules/Financial/CardConciliation/Shared/_ModalCreate.vue";
import PuzlSelect from "@/components/PuzlSelect.vue";
import { BankReconciliationEnum } from "@/enum/BankReconciliationEnum"
import {
  AppFixedPageTitle,
  AppPageHeader, AppPageHeaderActions, AppPageHeaderActionsButton,
  AppSearchBar,
  AppSearchBarFilterSection,
  AppSelect,
  AppTabSelect,
  initSearchBarFilterType,
  SearchBarFilterType
} from "@/components/AppGlobal";
import {
  initBankReconciliationListFilterType,
  BankReconciliationListFilterType
} from "@/views/Modules/Financial/BankReconciliation/Types";
import {progress, date, dialogs} from "@/helpers";
import router from "@/routes";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
//#endregion

//#region Data
const store = getCurrentInstance().proxy.$store;

const $_bank_reconciliations = computed(() => store.getters["bankReconciliation/fetch"]);
const $_bank_accounts = computed(() => store.getters["bankAccount/fetch"]);

const modalCreate = ref(null);
const modalImportTextFile = ref(null);

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());
/** @type {BankReconciliationListFilterType} */
const filter = reactive(initBankReconciliationListFilterType());

const listType = ref('cards');
const loading = ref(true);
const loadingBankAccounts = ref(true);

const orderBy = reactive({
  selected: 0,
  items: [
    {
      id: 0,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: [],
    },
  ]
});

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: "Todos",
      selected: filter.status === null,
    },
    {
      id: BankReconciliationEnum.PENDING,
      name: "Pendentes",
      selected: filter.status === BankReconciliationEnum.PENDING,
    },
    {
      id: BankReconciliationEnum.FINISHED,
      name: "Concluídos",
      selected: filter.status === BankReconciliationEnum.FINISHED,
    },
  ];
})

const status = [
  {
    id: BankReconciliationEnum.PENDING,
    name: "Pendente"
  },
  {
    id: BankReconciliationEnum.FINISHED,
    name: "Concluído"
  },
];
//#endregion

//#region Methods
function handleCreate () {
  modalCreate.value.handleCreate()
}

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  }
}

/**
* Limpa os filtros e atualiza a lista de itens.
*
* @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
* @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
*/
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return {
    ...initSearchBarFilterType(),
    custom_search_values: null,
    range: {
      items: [],
      selected: null,
      start: firstDay,
      end: lastDay
    }
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {BankReconciliationListFilterType}
 */
function defaultFilter() {
  return {
    ...initBankReconciliationListFilterType(),
  }
}

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
}

async function listItems() {
  progress.start();
  prepareFilter();
  loading.value = true;
  await store.dispatch("bankReconciliation/fetchItems", {
    filter: filter
  })
  .finally(() => {
    loading.value = false;
    progress.finish();
  });
}

function handleDelete(item) {
  if (Number(item.bills_to_pay_reconciled_count) > 0
    || Number(item.bills_to_receive_reconciled_count)) {
    return dialogs.notify({
      type: 'warning',
      message: 'Não é possível remover esse extrato! há transações conciliadas.'
    })
  }

  dialogs.confirmRemove().then((result) => {
    if(result) {
      store.dispatch("bankReconciliation/destroy", item.id).then((response) => {
        dialogs.notify({
          type: response.error_type,
          message: response.message
        })
        progress.finish()
      }).catch((error) => {
        dialogs.notify({
          type: error.data.error_type,
          message: error.data.message
        })
      })
    }
  }).catch(() => progress.finish())
}

function openTransaction(item) {
  router.push({
    name: 'cash_flow.bank_reconciliation.transaction.view',
    params: {
      bank_reconciliation_id: item.id,
      filters: filter
    },
  })
}

function changeStatus(item){
  progress.start()
  const params = {
    id: item.id,
    status: Number(item.status) === 0
      ? 1
      : 0,
  }
  store.dispatch('bankReconciliation/changeStatus', params)
    .then((response) => {
      item.status = params.status;
      dialogs.notify( 'success',response.message);
      progress.finish()
    }).catch(error => {
    let errors = error.status
      ? error.data.message
      : formatErrorValidation(error.response.data.errors);
    dialogs.notify( 'danger', errors);
    progress.finish()
  })
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
  store.dispatch("bankAccount/fetchItems", {
    filter:{
      status:true
    }
  }).then((response) => {
    loadingBankAccounts.value = false;
  });
});
//#endregion
</script>

<style>

</style>
