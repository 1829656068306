<template>
  <modal size="xxl" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Lista de itens
      </h5>
    </template>
    <div class="p-3">
      <!-- INFORMAÇOES -->
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">Informação</span>
      </div>
      <div class="d-flex justify-content-between mt-4">
        <div class="">
          <div style="font-size: 18px ;font-weight: 500; color: #2B2D32;">{{ purchaseOrder.order_code ||
            purchaseOrder.code
            }}</div>
          <div style="font-size: 14px ;font-weight: 400; color: #2B2D32;">{{ purchaseOrder.purchase_category_description
            }}
          </div>
          <div style="font-size: 14px ;font-weight: 500; color: #2B2D32;">{{ purchaseOrder.entity_name }}</div>
          <div style="font-size: 14px ;font-weight: 400; color: #2B2D32;">{{ purchaseOrder.entity_document }}</div>
        </div>
        <div class="d-flex">
          <div class="mr-3 pointer">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 11px">{{ purchaseOrder.created_by_user_name }}</div>
              <div style="font-size: 11px">{{ purchaseOrder.created_by_user_email }}</div>
              <span slot="reference">
                <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
              </span>
            </el-popover>
          </div>
          <div class="mr-2 pointer">
            <el-popover trigger="click" placement="bottom" class="p-0">
              <div style="font-size: 12px">{{ purchaseOrder.purchase_justification }}</div>
              <span slot="reference">
                <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="26" />
              </span>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="dropdown-divider my-4" style="width: -webkit-fill-available;" />
      <div class="d-flex align-items-center">
        <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2" />
        <span style="font-size: 16px ;font-weight: 500; color: #2B2D32;">Itens</span>
      </div>

      <!-- TABELA -->
      <div class="mt-3 d-flex" style="overflow-x: scroll">
        <div style="width: -webkit-fill-available;">
          <table class="table resume-table table-custom-shaded mb-1">
            <thead>
              <tr>
                <th class="text-left new-default-black-font">Descrição Do Produto</th>
                <th class="text-left new-default-black-font">Qto</th>
                <th class="text-left new-default-black-font">Unt</th>
                <th class="text-left new-default-black-font">Última Compra</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- Status -->
                <td class="">{{ item.description }}</td>
                <td class="font-weight-500">{{ item.request_quantity }}</td>
                <td class="">{{ item.input_unit }}</td>
                <td class="">
                  <span style="font-size: 14px !important; color: #2B2D32; font-weight: 500">
                    R$ {{ item.preco }}
                  </span>
                  <el-popover class="p-0" placement="right" trigger="click">
                    <div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                          <span class="font-weight-500">Última Compra</span>
                        </div>
                        <div class="d-flex align-items-center" style="font-size: 11px;">
                          <img src="/img/icons/calendar-black.png" width="15px" class="mr-2" />
                          {{ item.dataCompra }}
                        </div>
                      </div>
                    </div>
                    <div class="my-2" style="display: grid !important">
                      <span>{{ item.fornecedor }}</span>
                      <span>{{ item.cnpj }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div style="display: grid !important">
                        <span style="font-size: 12px;" class="fs-1">Quantidade</span>
                        <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{
                          item.request_quantity
                          }}</span>
                      </div>
                      <div style="display: grid !important">
                        <span style="font-size: 12px;" class="fs-1">Preço Unit.</span>
                        <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{ item.unit_value
                          }}</span>
                      </div>
                    </div>
                    <base-button outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-indigo">
                      <img src="/img/icons/icons8/ios/info-circle_warning.png" width="18">
                    </base-button>
                  </el-popover>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between p-1 px-3 font-weight-400 mb-2"
            style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1.5px; color: #2B2D32;">
            <div>Total</div>
          </div>
          <div class="mb-2"
            style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1px; color: #2B2D32;">
            <div class="p-1 px-3">Desconto</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Ipi</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Icms</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Valor Líquido</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Condições De Pagamento</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Prazo De Entrega</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Valor do frete</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">Validade Da Cotação</div>
          </div>
        </div>

        <div style="min-width: 300px;">
          <table class="table table-custom-shaded mb-1">
            <thead>
              <tr>
                <th class="text-left new-default-black-font">Preço Unit.</th>
                <th class="text-left new-default-black-font">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <!-- Status -->
                <td style="color: #606062">
                  <img class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-down-success.png" alt="copy-2" />
                  {{ $helper.toMoney(item.unit_value) }}
                </td>
                <td style="color: #2b2d32; font-weight: 700 !important">
                  {{ $helper.toMoney(item.unit_value * item.request_quantity) }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="d-flex justify-content-between p-1 px-3 font-weight-500"
            style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; margin: 0 1.5px; color: #2b2d32;">
            <div>{{ $helper.toMoney(purchaseOrder.total_value) }}</div>
          </div>
          <div class="my-2 font-weight-500" style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;
                background-color: #f2f2f2; color: #2B2D32; margin: 0 1px;">
            <div class="p-1 px-3">{{ $helper.toMoney(purchaseOrder.discount) }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">{{ $helper.toMoney(purchaseOrder.ipi) }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">{{ $helper.toMoney(purchaseOrder.icms) }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">{{ $helper.toMoney(purchaseOrder.net_value) }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">{{ purchaseOrder.payment_method || 'N/A' }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">
              <span v-if="purchaseOrder.delivery_deadline">
                {{ $helper.parseDate(purchaseOrder.delivery_deadline, "DD MMM YYYY") }}</span>
              <span v-else>N/A</span>
            </div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">{{ $helper.toMoney(purchaseOrder.delivery_cost) }}</div>
            <div class="dropdown-divider p-0 m-0" />
            <div class="p-1 px-3">
              <span v-if="purchaseOrder.quotation_validity">
                {{ $helper.parseDate(purchaseOrder.quotation_validity, "DD MMM YYYY") }}
                </span>
              <span v-else>N/A</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Botoes de aprovação -->
      <div class="d-flex justify-content-end mb-2 mt-3 text-center">
        <div class="aprovation-button bg-primary mr-2"
          @click.prevent="updateStatusOrder(purchaseOrder, PurchaseOrderStatusEnum.OPEN, true)"
          v-if="purchaseOrder.order_status === PurchaseOrderStatusEnum.PENDING || purchaseOrder.order_status === PurchaseOrderStatusEnum.DENIED">
          <img class="mr-2" width="20" src="/img/icons/thumb-up--v1-white.png">
          Aprovar
        </div>
        <div class="aprovation-button bg-danger mr-0"
          @click.prevent="updateStatusOrder(purchaseOrder, PurchaseOrderStatusEnum.DENIED)"
          v-if="purchaseOrder.order_status === PurchaseOrderStatusEnum.PENDING">
          <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down.png">
          Negar
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";

export default {
  name: "ModalListItemsApproval",
  components: {
  },
  data() {
    return {
      modal: false,
      purchaseOrder: null,
      items: [],
      PurchaseOrderStatusEnum: PurchaseOrderStatusEnum,
    }
  },
  methods: {
    handleCreateModal(data) {
      this.purchaseOrder = data;
      this.listCategories();
    },
    closeModal() {
      this.modal = false
    },
    listCategories() {
      let loader = this.$loading.show()
      this.$store.dispatch('purchaseOrder/fetchOrderItems', this.purchaseOrder.id)
        .then((response) => {
          this.items = response.data;
          loader.hide()
          this.modal = true;
        }).catch(() => {
          loader.hide()
        });
    },
    updateStatusOrder(purchaseOrder, status, isApproval = false) {
      const permissions = this.checkPermission(purchaseOrder, status);

      if (!permissions.hasPermission) {
        this.$notify({ type: 'warning', message: permissions.text });
        return;
      }
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: `Tem certeza que deseja ${permissions.action} essa requisição?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            const purchaseData = {
              id: purchaseOrder.id,
              purchase_order_id: purchaseOrder.id,
              status,
              code: purchaseOrder.order_code
            };
            const route = isApproval ? 'approval' : 'update';
            this.$store.dispatch(`purchaseOrder/${route}`, purchaseData)
              .then(() => {
                this.$emit('fetch');
                loader.hide();
                this.$notify({ type: 'success', message: 'Status atualizado' });
                this.closeModal();
              })
              .catch(() => {
                loader.hide();
                this.$notify({ type: 'error', message: 'Erro ao atualizar o status!' });
              });
          }
        });
    },
    checkPermission(purchaseOrder, status) {
      if (purchaseOrder.limit_order_approval_value && purchaseOrder.total_value > purchaseOrder.max_approval_value) {
        this.$notify({ type: 'warning', message: 'Usuário não possui permissão para aprovar esse valor' });
        return
      }
      switch (status) {
        case this.PurchaseOrderStatusEnum.COMPLETED:
          return {
            hasPermission: purchaseOrder.purchase_order_complete && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para concluir!",
            action: "Concluir"
          };
        case this.PurchaseOrderStatusEnum.OPEN:
          if (purchaseOrder.order_status === PurchaseOrderStatusEnum.COMPLETED ||
            purchaseOrder.order_status === PurchaseOrderStatusEnum.CANCELED) {
            return {
              hasPermission: purchaseOrder.purchase_order_resume && purchaseOrder.can_approve,
              text: "Usuário não tem permissão para retomar!",
              action: "Retomar"
            };
          }
          return {
            hasPermission: purchaseOrder.purchase_order_approve && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para aprovar!",
            action: "Aprovar"
          };
        case this.PurchaseOrderStatusEnum.CANCELED:
          return {
            hasPermission: purchaseOrder.purchase_order_cancel && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para cancelar!",
            action: "Cancelar"
          };
        case this.PurchaseOrderStatusEnum.DENIED:
          return {
            hasPermission: purchaseOrder.purchase_order_denied && purchaseOrder.can_approve,
            text: "Usuário não tem permissão para negar!",
            action: "Negar"
          };
        default:
          return {
            hasPermission: false,
            text: "Ação inválida!",
            action: ""
          };
      }
    },
  },
}
</script>

<style scoped>
.table-custom-shaded td {
  padding: 0.6rem !important;
  font-weight: 500 !important;
  color: #2B2D32;
}
.resume-table td:last-child {
  padding: 0.55rem !important;
}

.resume-table th:last-child {
  background-color: #fceccc !important;
}

.resume-table td:last-child {
  background-color: #fceccc !important;
  color: #2B2D32 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Botoes de aprovação */
.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}

.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
</style>
