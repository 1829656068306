import * as types from '../mutation_types'
import {destroy, update, cursorPaginate, setUrlWithNextPage} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/plantissuer/'
export default {
  namespaced: true,
  state: {
    items: [],
    activeItems: [],
    item: [],
  },
  getters:{
    show: state => state.item,
    fetch: state => state.items,
    activeItems: state => state.activeItems,
  },
  mutations: {
    [types.SET] (state, payload){
      state.items = payload
    },
    [types.PUSH] (state, payload){
      state.items.push(payload)
    },
    [types.SHOW] (state, payload){
      state.item = payload
    },
    [types.DESTROY] (state, id){
      destroy(state, id)
    },
    [types.UPDATE] (state, payload){
      update(state, payload)
    },
  },
  actions: {
    setItem({commit}, item) {
      commit(types.SHOW, item);
    },
    fetchItems({commit}, params) {
      return createAxios().get(endPoint, {params: params})
        .then(({data}) =>{
          commit(types.SET, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    fetchItemsPaginate({commit, state}, params) {
      return createAxios().get(setUrlWithNextPage(endPoint, params),
        {params: params})
        .then(({data}) =>{
          cursorPaginate(state, {data : data.data, next_page: params.next_page})
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    add({commit, state}, params) {

      let formData = new FormData()

      formData.append('certificate_file', params.certificate_file)

      for (var key in params.companyPlantIssuer) {
        if (params.companyPlantIssuer[key] !== null) {
        formData.append(key, params.companyPlantIssuer[key])
        }
      }
      return createAxios().post(endPoint,  formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(({data}) =>{
          commit(types.PUSH, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    update({commit}, params) {
      let formData = new FormData()

      formData.append('certificate_file', params.certificate_file)

      for (var key in params.companyPlantIssuer) {
        if (params.companyPlantIssuer[key] !== null) {
          formData.append(key, params.companyPlantIssuer[key])
          }
      }
      return createAxios().post(`${endPoint}update/${params.companyPlantIssuer.id}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(({data}) =>{
          commit(types.UPDATE, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    show({commit, dispatch}, id) {
      return createAxios().get(endPoint+id)
        .then(({data}) =>{
          commit(types.SHOW, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    destroy({commit, dispatch}, id) {
      return createAxios().delete(endPoint+id)
        .then(({data}) =>{
          commit(types.DESTROY, id)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    /**
     * Método deverá ser refatorado para novo padrão
     * @param _
     * @param {integer} bank_account_id
     * @returns {Promise<any>}
     */
    destroyBankAccountByPlantIssuer(_, params) {
      return createAxios().delete(`/plant-issuer/${params.company_plant_issuer_id}/bank-account/${params.bank_account_id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    getTaxes ({ commit, dispatch }, id) {
      return createAxios().get(`/company/plant/plant-issuer/get-taxes-by-company-plant-issuer/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    saveOrUpdateTaxes ({ commit, state }, payload) {
      return createAxios().post(`/company/plant/plant-issuer/save-or-update-taxes-for-company-plant-issuer/${payload.companyPlantIssuerId}`, payload.taxes)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getIssuerCityByCompanyPlantId({_}, companyPlantId) {
      return createAxios().get(`company/plant/plant-issuer/get-company-plant-issuers/by-plant/${companyPlantId}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
