export const DetachedStatusEnum = Object.freeze({
  IS_PENDING: 0, // Aberto
  IS_BILLED: 1, // Pendente
});

const getTitle = (value) => {
  switch (value) {
      case DetachedStatusEnum.IS_PENDING:
          return "Faturados";
      case DetachedStatusEnum.IS_BILLED:
          return "Em aberto";
      default:
          return "";
  }
};

export default {
  keys: DetachedStatusEnum,
  getTitle,
};