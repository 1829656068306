import { BaseListFilterType, initBaseListFilterType, RangeDateType, RangeValueType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} FinancialApprovalListFilterExtType
 * @property {boolean|null} status
 * @property {int|null} type
 * @property {Array|null} bank_accounts
 * @property {boolean|null} pending_card_integration
 * @property {boolean|null} is_nsu_code_duplicated
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {RangeValueType|null} range_value
 * @property {?number} company_plant_id
 * @property {?array} payment_method_ids
 * @property {boolean|null} needs_review
 * @property {boolean} has_sync_error
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & FinancialApprovalListFilterExtType)} FinancialApprovalListFilterType
 */
export const FinancialApprovalListFilterType = {};

/**
 * Inicializar FinancialApprovalListFilterType
 *
 * @returns {FinancialApprovalListFilterType}
 */
export const initFinancialApprovalListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    type: 1,
    bank_accounts: [],
    pending_card_integration: false,
    range_value: {
      min: null,
      max: null
    },
    is_nsu_code_duplicated: false,
    range: {
      start: null,
      end: null,
    },
    company_plant_id: null,
    needs_review: null,
    payment_method_ids: null,
    order_list_by: '',
    has_sync_error: false,
  };

}