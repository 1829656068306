import store from "../../../../shared/libraries/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";
import { InvoiceGetSyncBankBilletsType } from "../types";

/** URLS */
const urls = Object.freeze({
  BASE: "/financial/invoices",
});

/** KEYS */
const keys = Object.freeze({
  SYNC_BANK_BILLETS: "INVOICE_SYNC_BANK_BILLETS",
  IS_FINDING_SYNC_BANK_BILLETS: "INVOICE_IS_FINDING_SYNC_BANK_BILLETS",
});

/** GETTERS */
const getters = {
  /** @returns {?InvoiceGetSyncBankBilletsType} */
  getSyncBankBillets: () => store.getState(keys.SYNC_BANK_BILLETS),

  /** @returns {boolean} */
  getIsFindingSyncBankBillets: () => store.getState(keys.SYNC_BANK_BILLETS) ?? false,
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {InvoiceGetSyncBankBilletsType} item
   * @returns {InvoiceGetSyncBankBilletsType}
   */
  setSyncBankBillets: (item) => store.commit(keys.SYNC_BANK_BILLETS, item),
  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFindingSyncBankBillets: (isFinding) => store.commit(keys.IS_FINDING_SYNC_BANK_BILLETS, isFinding),
}

/** ACTIONS */
const actions = {
  /**
   * Localizar Boleto Bancário do SYNC por ID
   *
   * @param {number} invoiceId - ID da fatura
   * @returns {Promise<?InvoiceGetSyncBankBilletsType>} - Dados do boleto do SYNC
   */
  findSyncBankBillets: async (invoiceId) => {
    try {
      mutations.setIsFindingSyncBankBillets(true);
      const url = `${urls.BASE}/${invoiceId}/sync-bank-billets`;
      const res = await httpClient.get(url);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const found = res.data.data;
      return mutations.setSyncBankBillets(found);
    } finally {
      mutations.setIsFindingSyncBankBillets(false);
    }
  },
  /**
   * Verifica se a fatura está cancelada em aplicativo de terceiro [Sync]
   * 
   * @param {number} invoiceId - ID da fatura
   * @returns {Promise<boolean>} Indica se a fatura está cancelada em App de Terceiro [Sync]
   */
  hasSyncInvoiceCanceled: async (invoiceId) => {
    const url = `${urls.BASE}/${invoiceId}/sync-is-canceled`;
    const res = await httpClient.get(url);
    if (res.status !== HttpStatusEnum.HTTP_OK) {
      handleUnexpectedStatusCode(res);
      return;
    }

    return res.data.data;
  },
  /**
   * Reintegra a fatura com app de terceiro
   * @param {number} invoiceId 
   * @param {boolean} isResync
   * @returns {Promise<void>} 
   */
   resyncInvoice: async (invoiceId, isResync = true) => {
    const url = `${urls.BASE}/${invoiceId}/sync-nfse/${isResync ? 1 : 0}`;
    const res = await httpClient.post(url);
    if (res.status !== HttpStatusEnum.HTTP_OK) {
      handleUnexpectedStatusCode(res);
      return;
    }
  }
}

export default {
  actions,
  getters,
  mutations,
};
