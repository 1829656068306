<template>
  <div class="row card-wrapper">
    <div
      class="col-lg-4"
      v-for="(contract_proposal, index) in $_contract_proposal_listed.items"
      :key="index"
    >
      <div class="card contract-proposal-card">
        <div class="card-header d-flex align-items-center">
          <!-- Status badge -->
          <ContractProposalStatusBadge
            v-if="contract_proposal.entity_uuid"
            @click.prevent="handleChangeStatus(contract_proposal)"
            :status="contract_proposal.status"
          >
          </ContractProposalStatusBadge>
          <ContractProposalStatusBadge
            v-else
            @click.prevent="handleChangeStatus(contract_proposal)"
            :status="contract_proposal.status"
            isProposal
          >
          </ContractProposalStatusBadge>
          <!-- User updated/create popover -->
          <el-popover
            trigger="click"
            placement="bottom"
            class="mr-2"
            style="height: 20px"
            v-if="
              contract_proposal?.created_by_user_name ||
              contract_proposal?.updated_by_user_name
            "
          >
            <div class="table-text d-flex flex-column">
              <span class="popover-title"> Última Atualização </span>
              <hr class="popover-title-border full" />
              <span
                v-if="contract_proposal?.updated_by_user_name"
                class="popover-text"
              >
                {{ contract_proposal?.updated_by_user_name }} <br />
                {{
                  contract_proposal.updated_at
                    | parseDate("DD MMM YYYY - HH:mm")
                }}
              </span>
              <span v-else class="popover-text">
                {{ contract_proposal?.created_by_user_name }}<br />
                {{
                  contract_proposal.created_at
                    | parseDate("DD MMM YYYY - HH:mm")
                }}
              </span>
            </div>
            <base-button
              outline
              slot="reference"
              size="sm"
              type="secundary"
              class="p-0 m-0 shadow-none"
            >
              <AppIcon icon="male-user" width="20" />
            </base-button>
          </el-popover>
          <!-- Histórico de cliente -->
          <AppIcon
            v-if="contract_proposal.entity_uuid"
            icon="wallet"
            color="primary"
            title="Histórico de cliente"
            class="pointer mr-2"
            width="20"
            @click="handleEntityHistory(contract_proposal)"
          />
          <!-- Info contrato popover -->
          <el-popover trigger="click" placement="left" class="mr-2">
            <div class="row" v-if="loadingContractProposal">
              <div class="col-12 text-center">
                <img width="40px" src="/img/loading.gif" />
              </div>
            </div>
            <div
              class="row"
              v-if="
                updated_info[contract_proposal.id] && !loadingContractProposal
              "
            >
              <div class="p-4">
                <div>
                  <h3 slot="title">
                    <img src="/img/icons/salesman.png" width="22" />
                    Vendedor
                  </h3>
                  <span class="h4 small">
                    {{ updated_info[contract_proposal.id].seller_name }}
                  </span>
                </div>

                <hr class="bg-secondary" style="margin-top: 12px" />

                <div class="">
                  <h3 slot="title">
                    <img
                      src="/img/icons/icons8/ios/chemical-plant-v1.png"
                      width="22"
                    />
                    Central
                  </h3>
                  <span class="h4 small">
                    {{ updated_info[contract_proposal.id].plant.name }}
                  </span>
                </div>

                <hr class="bg-secondary" style="margin-top: 12px" />

                <div
                  v-if="
                    updated_info[contract_proposal.id] &&
                    updated_info[contract_proposal.id].service_receiver
                  "
                  class=""
                >
                  <h3 slot="title">
                    <img
                      class="mr-1"
                      src="/img/icons/icons8/ios/contract-job_primary.png"
                      width="22"
                    />
                    Dados do cliente
                  </h3>
                  <div class="row align-items-center pb-0 mb-3">
                    <div class="col-md-12">
                      <span class="mt-4 p">
                        <span class="h4 small">
                          <span class="text-dark h5">CNPJ/CPF:</span>
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .document || ""
                          }}
                        </span>
                      </span>
                      <br />
                      <span
                        class="mt-4 p"
                        v-if="
                          updated_info[contract_proposal.id] &&
                          updated_info[contract_proposal.id].service_receiver &&
                          updated_info[contract_proposal.id].service_receiver
                            .address
                        "
                      >
                        <span class="h4 small">
                          <span class="text-dark h5">ENDEREÇO:</span>
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.city
                          }}
                          /
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.state
                          }}
                        </span>
                        <br />
                        <span class="h4 small">
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.address || ""
                          }},
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.number || ""
                          }},
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.district || ""
                          }}
                        </span>
                      </span>
                      <br />
                      <span
                        class="mt-4 p"
                        v-if="
                          updated_info[contract_proposal.id] &&
                          updated_info[contract_proposal.id].service_receiver &&
                          updated_info[contract_proposal.id].service_receiver
                            .address
                        "
                      >
                        <span class="h4 small">
                          {{
                            updated_info[contract_proposal.id].service_receiver
                              .address.postal_code || ""
                          }}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <hr
                  v-if="
                    updated_info[contract_proposal.id] &&
                    updated_info[contract_proposal.id].service_receiver
                  "
                  class="bg-secondary"
                  style="margin-top: 5px"
                />

                <h3 slot="title" class="">
                  <img
                    class="mr-1"
                    src="/img/icons/icons8/ios/brick-wall_warning.png"
                    width="22"
                  />
                  Dados da Obra
                </h3>
                <div class="row align-items-center pb-0">
                  <div class="col-md-12">
                    <span
                      v-if="
                        updated_info[contract_proposal.id].construction
                          .estimated_volume
                      "
                      class="mt-4 p"
                    >
                      <span class="h4 small">
                        <span class="text-dark h5">VOLUME PREVISTO:</span>
                        {{
                          updated_info[contract_proposal.id].construction
                            .estimated_volume || "-"
                        }}
                        <small>m<sup>3</sup></small>
                      </span>
                    </span>
                    <br
                      v-if="
                        updated_info[contract_proposal.id].construction
                          .estimated_volume
                      "
                    />
                    <span class="mt-4 p">
                      <span class="h4 small">
                        <span class="text-dark h5">ENDEREÇO:</span>
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.city
                        }}
                        /
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.state
                        }}
                      </span>
                      <br />
                      <span class="h4 small">
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.address || ""
                        }},
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.number || ""
                        }},
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.district || ""
                        }}
                      </span>
                    </span>
                    <br />
                    <span class="mt-4 p">
                      <span class="h4 small">
                        {{
                          updated_info[contract_proposal.id].construction
                            .default_address.postal_code || ""
                        }}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <base-button
              @click.prevent="getContractProposal(contract_proposal)"
              outline
              slot="reference"
              size="sm"
              type="secundary"
              class="p-0 m-0 shadow-none"
              style="height: 22px"
            >
              <AppIcon
                icon="info"
                title="Informações complementares"
                width="20"
                color="warning"
              />
            </base-button>
          </el-popover>
          <!-- Construction attachamentes -->
          <!-- <AppIcon
            v-if="
              contract_proposal.total_construction_attachments > 0 &&
              contract_proposal.entity_uuid
            "
            @click="
              handleShowConstructionAttachments(
                contract_proposal.construction_id,
                contract_proposal.construction_name
              )
            "
            width="22"
            class="pointer mr-2"
            :title="`Total de anexos: ${contract_proposal.total_construction_attachments}`"
            icon="paperclip"
            color="success"
          /> -->
          <!-- Imprimir/enviar -->
          <AppIcon
            v-if="!contract_proposal.entity_uuid"
            class="pointer mr-2"
            @click="openModalPrint(contract_proposal)"
            title="Imprimir/enviar"
            width="20"
            color="primary"
            icon="printer"
          >
          </AppIcon>
          <!-- Ass. Digital -->
          <AppIcon
            @click="handleShowModalDigitalSignature(contract_proposal)"
            v-if="
              digitalSignatureConfig &&
              digitalSignatureConfig.enable_digital_signature &&
              contract_proposal.entity_uuid
            "
            class="pointer mr-2"
            icon="autograph"
            title="Assinatura digital"
            width="20"
          />
          <!-- Fiador -->
          <AppIcon
            v-if="
              contract_proposal.guarantor_entity_id &&
              contract_proposal.entity_uuid
            "
            class="pointer mr-2"
            icon="insurance-agent"
            width="20"
            @click="openGuarantorEntityModal(contract_proposal.id)"
            color="danger"
            title="Fiador"
          />
          <!-- Parceiro Comercial  -->
          <AppIcon
            v-if="
              contract_proposal.business_partner_id &&
              contract_proposal.entity_uuid
            "
            @click.prevent="openBusinessPartnerModal(contract_proposal.id)"
            icon="handshake"
            color="warning"
            width="22px"
            class="pointer"
          />
          <!-- Action button -->
          <base-dropdown menuOnRight class="action-button">
            <div slot="title-container" class="dropdown-toggle m-0">
              <AppIcon
                icon="settings"
                color="primary"
                width="30"
                class="rotate-icon"
              />
            </div>
            <router-link
              :to="{
                name: 'commercial.contract-proposal.edit',
                path: '/commercial/contract-proposal/edit',
                params: { contract_proposal_uuid: contract_proposal.uuid },
              }"
            >
              <a class="dropdown-item">
                <AppIcon
                  icon="create"
                  color="warning"
                  width="20"
                  class="mr-2"
                />
                Editar
              </a>
            </router-link>
            <hr class="my-1" />
            <a
              class="dropdown-item"
              @click.prevent="
                handlePortfolioProjection(contract_proposal.id, [
                  contract_proposal.code,
                  contract_proposal.customer_name,
                  contract_proposal.construction_name,
                  contract_proposal.company_plant_id,
                ])
              "
            >
              <AppIcon icon="ratings" width="20" class="mr-2" color="primary" />
              Projeção de Carteira
            </a>
            <hr class="my-1" />
            <a
              v-if="!contract_proposal.entity_uuid"
              @click="handleShowModalSearchEntity(contract_proposal)"
              class="dropdown-item"
            >
              <AppIcon icon="agreement" width="20" class="mr-2" />
              Gerar contrato
            </a>
            <hr class="my-1" v-if="!contract_proposal.entity_uuid" />
            <a
              v-if="
                contract_proposal.entity_uuid &&
                hasPermission(
                  'additional_permissions.contract-proposal-formulation.details.view'
                )
              "
              class="dropdown-item"
              @click.prevent="handleModalEbitdaAnalysis(contract_proposal)"
            >
              <AppIcon
                icon="bar-chart"
                color="success"
                width="20"
                class="mr-2"
              />
              Ebitda
            </a>
            <hr class="my-1" v-if="contract_proposal.entity_uuid" />
            <a
              class="dropdown-item"
              v-if="contract_proposal.entity_uuid"
              @click.prevent="handleGeneralResume(contract_proposal.id)"
            >
              <AppIcon
                icon="profit-report"
                color="success"
                width="20"
                class="mr-2"
              />
              Resumo Geral
            </a>
            <div
              class="dropdown-divider p-0 m-0"
              v-if="contract_proposal.entity_uuid"
            />
            <a
              v-if="contract_proposal.entity_uuid"
              class="dropdown-item"
              @click.prevent="openBusinessPartnerModal(contract_proposal.id)"
            >
              <AppIcon
                icon="handshake"
                color="warning"
                width="23px"
                class="mr-3"
              />
              <span class="text-right">Parceiro comercial</span>
            </a>
            <hr class="my-1" v-if="contract_proposal.entity_uuid" />
            <a
              v-if="contract_proposal.entity_uuid"
              class="dropdown-item"
              @click.prevent="openGuarantorEntityModal(contract_proposal.id)"
            >
              <AppIcon
                icon="insurance-agent"
                color="danger"
                width="20"
                class="mr-2"
              />
              Fiador
            </a>
            <hr class="my-1" v-if="contract_proposal.entity_uuid" />
            <a
              class="dropdown-item"
              @click.prevent="openModalPrint(contract_proposal)"
            >
              <AppIcon icon="printer" color="primary" width="20" class="mr-2" />
              Imprimir
            </a>
            <hr class="my-1" />
            <a
              v-if="contract_proposal.entity_uuid"
              class="dropdown-item"
              @click.prevent="
                handleContractProposalApprovalHistory(contract_proposal)
              "
            >
              <AppIcon icon="order-history" width="20" class="mr-2" />
              Histórico
            </a>
            <hr class="my-1" v-if="contract_proposal.entity_uuid" />
            <a
              class="dropdown-item"
              @click.prevent="handleRemoveContractProposal(contract_proposal)"
            >
              <AppIcon
                icon="trash-can"
                color="danger"
                width="20"
                class="mr-2"
              />
              EXCLUIR
            </a>
          </base-dropdown>
        </div>

        <div class="card-body">
          <h3>
            {{ contract_proposal.code }}
            <span
              style="font-size: 11px !important"
              title="Situação"
              v-if="contract_proposal.situation_name"
              class="font-weight-500 mr-1"
            >
              <span style="margin-left: 5px; margin-right: 3px"> | </span>
              {{ contract_proposal.situation_name }}
            </span>
          </h3>

          <h4>
            {{ contract_proposal.customer_name }}
          </h4>
          <h4>
            {{ contract_proposal.construction_name }}
          </h4>

          <!-- Botão financeiro -->
          <div style="display: block; min-height: 16px; margin-top: 2px">
            <div
              v-if="
                hasPermission('additional_permissions.financial_approvation')
              "
              :title="
                !hasPermission('additional_permissions.financial_approvation')
                  ? 'Sem permissão para alterar'
                  : ''
              "
            >
              <base-dropdown
                v-show="
                  contract_proposal.necessary_approvation &&
                  contract_proposal.entity_uuid
                "
              >
                <button
                  slot="title-container"
                  class="dropdown-toggle financial-badge"
                  style="font-size: 10px; min-width: 110px"
                  :class="[
                    financialBadgeLayout(contract_proposal.approvation).class,
                  ]"
                >
                  <AppIcon
                    :icon="
                      financialBadgeLayout(contract_proposal.approvation).img
                    "
                    width="10px"
                    :color="
                      financialBadgeLayout(contract_proposal.approvation).color
                    "
                    class="mr-1"
                  />

                  Financeiro
                  <AppIcon
                    icon="down"
                    width="7px"
                    class="ml-1"
                    :color="
                      financialBadgeLayout(contract_proposal.approvation).color
                    "
                  />
                </button>
                <span
                  class="dropdown-item"
                  :disabled="
                    !hasPermission(
                      'additional_permissions.financial_approvation'
                    ) ||
                    [
                      ContractProposalEnum.INACTIVE,
                      ContractProposalEnum.PROPOSAL,
                      ContractProposalEnum.COMPLETED,
                    ].includes(contract_proposal.status)
                  "
                  @click.prevent="
                    approve(
                      contract_proposal,
                      financialApprovalStatusEnum.financial_pending
                    )
                  "
                >
                  <AppIcon
                    icon="clock"
                    width="10px"
                    color="warning"
                    class="mr-1"
                  />
                  PENDENTE
                </span>
                <div class="dropdown-divider p-0 m-0"></div>
                <span
                  class="dropdown-item"
                  :disabled="
                    !hasPermission(
                      'additional_permissions.financial_approvation'
                    ) ||
                    [
                      ContractProposalEnum.INACTIVE,
                      ContractProposalEnum.PROPOSAL,
                      ContractProposalEnum.COMPLETED,
                    ].includes(contract_proposal.status)
                  "
                  @click.prevent="
                    approve(
                      contract_proposal,
                      financialApprovalStatusEnum.financial_approval
                    )
                  "
                >
                  <AppIcon
                    icon="thumbs-up"
                    width="10px"
                    color="success"
                    class="mr-1"
                  />
                  APROVAR
                </span>
                <div class="dropdown-divider p-0 m-0"></div>
                <span
                  class="dropdown-item"
                  :disabled="
                    !hasPermission(
                      'additional_permissions.financial_approvation'
                    ) ||
                    [
                      ContractProposalEnum.INACTIVE,
                      ContractProposalEnum.PROPOSAL,
                      ContractProposalEnum.COMPLETED,
                    ].includes(contract_proposal.status)
                  "
                  @click.prevent="
                    approve(
                      contract_proposal,
                      financialApprovalStatusEnum.financial_disapproval
                    )
                  "
                >
                  <AppIcon
                    icon="thumbs-down"
                    width="10px"
                    color="danger"
                    class="mr-1"
                  />
                  REPROVAR
                </span>
              </base-dropdown>
            </div>
          </div>
        </div>
        <!-- Footer Contract-->
        <div
          class="app-card-footer d-flex align-items-center justify-content-between"
          v-if="contract_proposal.entity_uuid"
        >
          <router-link :to="pushRouter(contract_proposal, 'formulations')">
            <a
              title="Concretos"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="test-tube" color="primary" width="22px" />
              <div
                v-if="contract_proposal.pending_formulation"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
            </a>
          </router-link>
          <router-link :to="pushRouter(contract_proposal, 'services')">
            <a
              title="Serviços"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="todo-list" color="dark" width="22px" />
              <div
                v-if="contract_proposal.pending_services"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
            </a>
          </router-link>
          <router-link :to="pushRouter(contract_proposal, 'additionals')">
            <a
              title="Adicionais"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="add-new" color="warning" width="22px" />
            </a>
          </router-link>
          <router-link
            :to="`/operational/contract-proposal/schedule/${contract_proposal.uuid}`"
          >
            <a
              title="Programações"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="calendar" color="primary" width="22px" />
            </a>
          </router-link>
          <router-link :to="pushRouter(contract_proposal, 'payments')">
            <a
              title="Recebimentos"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="card-payment" color="success" width="22px" />
            </a>
          </router-link>
          <router-link
            :to="`/commercial/contract-proposal/invoices/${contract_proposal.uuid}`"
          >
            <a
              title="Faturas"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="cheque" color="primary" width="22px" />
              <div
                v-if="contract_proposal.pending_launch_value > 0"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
            </a>
          </router-link>
        </div>
        <!-- Footer proposal-->
        <div
          class="app-card-footer d-flex align-items-center justify-content-around"
          v-else
        >
          <router-link :to="pushRouter(contract_proposal, 'formulations')">
            <a
              title="Concretos"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="test-tube" color="primary" width="22px" />
              <div
                v-if="contract_proposal.pending_formulation"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
              <div
                v-if="contract_proposal.pending_formulation"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
            </a>
          </router-link>
          <router-link :to="pushRouter(contract_proposal, 'services')">
            <a
              title="Serviços"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="todo-list" color="muted" width="22px" />
              <div
                v-if="contract_proposal.pending_services"
                style="
                  position: absolute;
                  top: 0;
                  right: 0;
                  background: white;
                  border-radius: 11px;
                  width: 20px;
                "
              >
                <AppIcon icon="exclamation" color="danger" width="20px" />
              </div>
            </a>
          </router-link>
          <router-link :to="pushRouter(contract_proposal, 'additionals')">
            <a
              title="Adicionais"
              class="pointer avatar rounded-pill bg-white"
              style="border: 1px solid #e8e8e8"
            >
              <AppIcon icon="add-new" color="warning" width="22px" />
            </a>
          </router-link>
        </div>
      </div>
    </div>
    <ModalChangeStatus @changeStatus="load" ref="changeStatus" />
    <ModalContractProposalApprovals
      @fetch="load"
      ref="contractProposalApprovals"
    />
    <ModalEntityHistory ref="modalEntityHistory" />
    <!-- <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="
        updatedTotalConstructionAttachmentAfterUpload
      "
      @updateTotalAttachmentAfterDeletion="
        updateTotalConstructionAttachmentAfterDeletion
      "
    /> -->
    <ModalPrintOut @open="openModalContractPrint" ref="ModalPrint" />
    <ModalGuarantorEntity
      ref="modalGuarantorEntity"
      @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      @contractProposalUpdated="updateContractAfterGuarantorEntityEdition"
    />
    <ModalDigitalSignatureList ref="modalDigitalSignature" />
    <ModalSearchEntityContratProposal
      ref="modalSearchEntityContratProposal"
      @setEntity="setEntity"
    />
    <ModalPortfolioProjection ref="modalPortfolioProjection" />
    <ModalEbitdaAnalysis ref="modalEbitdaAnalysis" />
    <ModalGeneralResume ref="modalGeneralResume" />
    <ModalContractProposalApprovalHistory
      ref="contractProposalApprovalHistory"
    />
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, reactive, ref, defineEmits } from "vue";
import contractProposalStore from "../../../store/contractProposalStore";
import { AppIcon } from "../../../../../../components/AppGlobal";
import { ContractProposalEnum } from "../../../Enum/ContractProposalEnum";
import { hasPermission } from "@/plugins/permissions";
import { useStore } from "@/shared/hooks/useStore.js";
import { dialogs, progress } from "../../../../../../helpers";
import { formatErrorValidation } from "@/plugins";
import ContractProposalStatusBadge from "../../shared/ContractProposalStatusBadge.vue";
import ModalContractProposalApprovals from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalContractProposalApprovals";
import ModalPrintOut from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalPrintOut";
import ModalChangeStatus from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalChangeStatus";
import ModalGuarantorEntity from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalGuarantorEntity";
import ModalPortfolioProjection from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalPortfolioProjection.vue";
import ModalDigitalSignatureList from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalDigitalSignatureList.vue";
import ModalEbitdaAnalysis from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalEbitdaAnalysis";
import ModalSearchEntityContratProposal from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalSearchEntity";
import ModalGeneralResume from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalGeneralResume.vue";
import ModalContractProposalApprovalHistory from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalContractProposalApprovalHistory.vue";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalEntityHistory from "@/views/Modules/Configuration/Entity/Shared/_ModalHistory";
import ModalConstructionAttachmentList from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";

const emit = defineEmits([
  "fetch",
  "contractProposalUpdated",
  "deletedGuarantorEntity",
  "contractProposalUpdated",
  "openBusinessPartnerModal",
  "openGuarantorEntityModal",
]);

const { digitalSignatureConfig } = defineProps({
  digitalSignatureConfig: {
    type: Object,
    require: true,
  },
});

const $_contract_proposal_listed = computed(() =>
  contractProposalStore.getters.getListed()
);
const $_settings = computed(() => store.getters["generalSetting/show"]);
const $_company_plants = computed(() => store.getters["plant/activeItems"]);
const $_user = computed(() => store.getters["auth/getUser"]);

const { proxy } = getCurrentInstance();
const store = useStore();

const loading = ref(false);
const loadingContractProposal = ref(false);
const contractId = ref(null);
const selectedProposalToConvertToContract = ref(null);
const is_cno_required_prop_contracts = ref(false);
const is_municipal_work_code_required_prop_contracts = ref(false);
const is_art_encapsulation_required = ref(false);
/**
 * Mantive a estrutura que ja existia pois estou refatorando somente o list
 */
const updated_info = reactive({});
const foundContractProposal = reactive({});
const contractProposals = ref([...$_contract_proposal_listed.value.items]);

const financialApprovalStatusEnum = {
  financial_pending: 0,
  financial_approval: 1,
  financial_disapproval: 2,
};

/**
 * Layout para badges
 */
const badgeComponentStatusMap = {
  [ContractProposalEnum.ACTIVE]: {
    icon: "check",
    color: "success",
    text: "Ativo",
    bg: "#F2F7F3",
    textColor: "#149e57",
  },
  [ContractProposalEnum.INACTIVE]: {
    icon: "shutdown",
    color: "danger",
    text: "Bloqueado",
    bg: "#fcf3f3",
    textColor: "#db4539",
  },
  [ContractProposalEnum.PENDING_APPROVAL]: {
    icon: "hourglass",
    color: "warning",
    text: "Pendente",
    bg: "#FEF9F2",
    textColor: "#f2b532",
  },
  [ContractProposalEnum.PENDING_REVIEW]: {
    icon: "hourglass",
    color: "warning",
    text: "Pendente",
    bg: "#FEF9F2",
    textColor: "#f2b532",
  },
  [ContractProposalEnum.COMPLETED]: {
    icon: "double-tick",
    color: "primary",
    text: "Concluído",
    bg: "#f2f4f9",
    textColor: "#1a70b7",
  },
};

/**
 * Metodos copiados da pagina antiga de contratos
 * src/views/Modules/Commercial/ContractProposal/Shared/_List.vue
 * start
 */

async function handleRemoveContractProposal(contractProposal) {
  const confirmRemoval = await dialogs.confirmRemove();
  if (confirmRemoval) {
    progress.start();
    dialogs.notify(
      dialogs.TYPE_ENUM.INFO,
      "Estamos trabalhando em sua solicitação."
    );
    try {
      const response = await store.dispatch(
        "contractProposal/destroy",
        contractProposal.uuid
      );
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, response.message);
      contractProposalStore.actions.removeContractProposalFromList(
        contractProposal.id
      );
    } catch (error) {
      dialogs.notifyError({
        type: dialogs.TYPE_ENUM.DANGER,
        message: error.data.message,
      });
    } finally {
      progress.finish();
    }
  }
}

/**
 * @param {string}uuid
 */
function handleContractProposalApprovals(uuid) {
  proxy.$refs.contractProposalApprovals.openModal(uuid);
}

/**
 * Abre modal bla
 */
function openModalContractPrint() {
  proxy.$refs.modalContractPrint.openModal();
}

function handlePortfolioProjection(id, contract) {
  proxy.$refs.modalPortfolioProjection.openModal(id, contract);
}

function handleShowConstructionAttachments(constructionId, constructionName) {
  proxy.$refs.modalConstructionAttachmentList.handleCreateModal(
    constructionId,
    constructionName
  );
}

function handleModalEbitdaAnalysis(contract_proposal) {
  proxy.$refs.modalEbitdaAnalysis.openModal(
    contract_proposal.id,
    contract_proposal.uuid
  );
}

async function handleShowModalSearchEntity(contract_proposal) {
  await getContractProposal(contract_proposal);

  // Validações de configurações gerais da central antes de gerar um contrato.
  const isValid = await validateContractProposal(contract_proposal);
  if (!isValid) {
    return;
  }

  selectedProposalToConvertToContract.value =
    updated_info[contract_proposal.id];
  proxy.$refs.modalSearchEntityContratProposal.openModal(
    updated_info[contract_proposal.id]
  );
}

function updateContractAfterGuarantorEntityEdition(contractProposal) {
  emit("contractProposalUpdated", contractProposal);
}

function updateContractAfterGuarantorEntityDeletion(contractProposalId) {
  emit("deletedGuarantorEntity", contractProposalId);
}

function handleGeneralResume(id) {
  proxy.$refs.modalGeneralResume.openModal(id);
}

async function handleContractProposalApprovalHistory(contractProposal) {
  store.dispatch("contractProposal/getHistoriesByContractProposalUuid", contractProposal.uuid).then((response) => {
    loadingContractProposal.value = false;
    proxy.$refs.contractProposalApprovalHistory.openModal();
  });
}

async function updatedTotalConstructionAttachmentAfterUpload(constructionId) {
  const response = await store.dispatch(
    "constructionAttachment/getTotalAttachmentByConstructionId",
    constructionId
  );
  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }
  const foundContractProposal = contractProposals.value.find(
    (item) => item.construction_attachments[0].construction_id == constructionId
  );
  foundContractProposal.total_construction_attachments =
    response.data.total_construction_attachments;
}

async function validateContractProposal(contract_proposal) {
  // Busca central para recuperar uuid
  const companyPlant = $_company_plants.value.find(
    (item) => item.id === updated_info[contract_proposal.id].company_plant_id
  );

  // Recupera dados de configurações gerais
  const response = await store.dispatch(
    "plant/getGeneralSettings",
    companyPlant.uuid
  );
  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }
  is_cno_required_prop_contracts.value =
    response.data.is_cno_required_prop_contracts;
  is_municipal_work_code_required_prop_contracts.value =
    response.data.is_municipal_work_code_required_prop_contracts;
  is_art_encapsulation_required.value =
    response.data.is_art_encapsulation_required;

  // Validações
  if (
    is_cno_required_prop_contracts.value &&
    updated_info[contract_proposal.id].cno
  ) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Preencha o campo CNO para gerar o contrato."
    );
    return false;
  }

  if (
    is_municipal_work_code_required_prop_contracts.value &&
    updated_info[contract_proposal.id].city_hall_construction_code
  ) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Preencha o campo Cód. de obra da prefeitura para gerar o contrato."
    );
    return false;
  }

  if (
    is_art_encapsulation_required.value &&
    updated_info[contract_proposal.id].art_code
  ) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Preencha o campo Art/nº de controle do encapsulamento para gerar o contrato."
    );
    return false;
  }

  return true;
}

async function getContractProposal(contract_proposal) {
  if (updated_info[contract_proposal.id]) {
    return;
  }
  loadingContractProposal.value = true;
  const response = await store.dispatch(
    "contractProposal/show",
    contract_proposal.uuid
  );
  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }
  updated_info[contract_proposal.id] = response.data;
  loadingContractProposal.value = false;
}

async function handleChangeStatus(contractProposal) {
  if (
    [
      ContractProposalEnum.PENDING_APPROVAL,
      ContractProposalEnum.PENDING_REVIEW,
    ].includes(contractProposal.status)
  ) {
    if (!hasPermission("additional_permissions.commercial_approvation")) {
      return;
    }
    handleContractProposalApprovals(contractProposal.uuid);
  }
  if (
    ![
      ContractProposalEnum.ACTIVE,
      ContractProposalEnum.INACTIVE,
      ContractProposalEnum.PROPOSAL,
      ContractProposalEnum.COMPLETED,
    ].includes(contractProposal.status)
  ) {
    return;
  }
  loading.value = true;
  progress.start();
  dialogs.notify(
    dialogs.TYPE_ENUM.INFO,
    "Estamos trabalhando em sua solicitação."
  );

  await getContractProposal(contractProposal);
  const response = await store.dispatch("contractProposal/fetchSituations");
  loading.value = false;
  progress.finish();
  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.ERROR, response.message);
    return;
  }
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, response?.message);
  proxy.$refs.changeStatus.handleChangeStatus(
    updated_info[contractProposal.id]
  );
}

async function handleEntityHistory(contract_proposal) {
  await getContractProposal(contract_proposal);
  await proxy.$refs.modalEntityHistory.openModal(
    updated_info[contract_proposal.id].entity.id,
    updated_info[contract_proposal.id].entity,
    contract_proposal.id
  );
}

async function openModalPrint(contractProposal) {
  await getContractProposal(contractProposal);
  if ($_settings.value.pending_proposals && contractProposal.status === 1) {
    proxy.$refs.ModalPrint.openModal(updated_info[contractProposal.id]);
  }
  else if (!$_settings.value.pending_proposals && contractProposal.status !== 1) {
    dialogs.notifyError(
      dialogs.TYPE_ENUM.DANGER,
      "Só é possível imprimir propostas ou contratos se o card estiver como ATIVO."
    );
  }
  else {
    proxy.$refs.ModalPrint.openModal(updated_info[contractProposal.id]);
  }
}

async function handleShowModalDigitalSignature(contract_proposal) {
  await getContractProposal(contract_proposal);
  proxy.$refs.modalDigitalSignature.handleCreateModal({
    contract_proposal: updated_info[contract_proposal.id],
    digitalSignatureConfig: digitalSignatureConfig,
  });
}

function openBusinessPartnerModal(id) {
  emit("openBusinessPartnerModal", id);
}

function openGuarantorEntityModal(id) {
  emit("openGuarantorEntityModal", id);
}

function pushRouter(contract_proposal, router) {
  switch (router) {
    case "formulations":
      return {
        name: "commercial.contract-proposal.formulation",
        params: { contract_proposal_uuid: contract_proposal.uuid },
      };
    case "services":
      return {
        name: "commercial.contract-proposal.services.view",
        params: { contract_proposal_uuid: contract_proposal.uuid },
      };
    case "additionals":
      return {
        name: "commercial.contract-proposal.additional.view",
        params: { contract_proposal_uuid: contract_proposal.uuid },
      };
    case "payments":
      return {
        name: "commercial.contract-proposal.payments.view",
        params: {
          contract_proposal_uuid: contract_proposal.uuid,
          construction_name: contract_proposal.construction_name,
          customer_name: contract_proposal.customer_name,
        },
      };
    case "invoices":
      return {
        name: "commercial.sales-resume.index.view",
        params: {
          contract_uuid: contract_proposal.uuid,
        },
      };
  }
}

async function setEntity(entity) {
  progress.start();
  // Busca a proposta selecionada para realizar o update com os dados corretos, sem erros sql.
  try {
    const response = await store.dispatch(
      "contractProposal/show",
      selectedProposalToConvertToContract.value.uuid
    );
    Object.assign(foundContractProposal, response.data);
  } catch (error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, error.message);
    return;
  }

  foundContractProposal.entity = entity;

  // Corrige formatação do campo 'volume médio por bt'
  const fixedValue = foundContractProposal?.average_volume_per_bt.replace(
    ",",
    "."
  );
  foundContractProposal.average_volume_per_bt =
    parseFloat(fixedValue).toFixed(1);

  // Back-end espera o uuid de entity no objeto service_receiver.uuid
  if (foundContractProposal?.service_receiver === null) {
    foundContractProposal.service_receiver = {};
  }
  foundContractProposal.service_receiver.uuid = entity.uuid;

  // Atualiza status do card, deixando ele como um contrato ativo.
  if (foundContractProposal && foundContractProposal.entity) {
    let contractProposal = contractProposals.value.find(
      (item) => item.id === selectedProposalToConvertToContract.value.id
    );

    if (contractProposal.status == ContractProposalEnum.PROPOSAL) {
      contractProposal.status = ContractProposalEnum.ACTIVE;
      foundContractProposal.status = ContractProposalEnum.ACTIVE;
    }
  }

  try {
    const responseUpdate = store.dispatch(
      "contractProposal/update",
      foundContractProposal
    );

    if (responseUpdate.status === 422) {
      let message = formatErrorValidation(responseUpdate.response.data);
      dialogs.notify(dialogs.TYPE_ENUM.DANGER, message);
      return;
    }

    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, responseUpdate.message);
  } catch (error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, error.message);
    return;
  }

  // Remove o card da listagem de propostas, pois agora ele é um contrato.
  // Estamos removendo apenas localmente, já que, para uma proposta se tornar
  // um contrato, basta salvarmos o cliente na sua respectiva tabela.
  contractProposalStore.actions.removeContractProposalFromList(
    foundContractProposal.id
  );
  progress.finish();
}

async function approve(contractProposal, status) {
  dialogs.notify(
    dialogs.TYPE_ENUM.INFO,
    "Estamos trabalhando em sua solicitação."
  );
  try {
    const response = store.dispatch("contractProposal/putApprove", {
      id: contractProposal.id,
      status: status,
    });
    if (!response.error) {
      contractProposal.approvation = status;
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, response.message);
    }
  } catch {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }
}

async function load() {
  emit("fetch");
}

/**
 * Metodos copiados da pagina antiga de contratos
 * src/views/Modules/Commercial/ContractProposal/Shared/_List.vue
 * end
 */

function financialBadgeLayout(status) {
  switch (status) {
    case financialApprovalStatusEnum.financial_pending:
      return {
        class: "warning",
        img: "hourglass",
        color: "warning",
      };
    case financialApprovalStatusEnum.financial_approval:
      return {
        class: "success",
        img: "thumbs-up",
        color: "success",
      };
    case financialApprovalStatusEnum.financial_disapproval:
      return {
        class: "danger",
        img: "thumbs-down",
        color: "danger",
      };
    default:
      return {
        class: "",
        img: "",
        color: "",
      };
  }
}
</script>

<style lang="scss">
.contract-proposal-card {
  .dropdown-menu {
    padding: 0.4rem 0 !important;
  }
  .financial-badge::after {
    content: none;
  }
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.contract-proposal-card {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid $muted-light;
  margin-bottom: 1rem;

  .app-badge {
    margin-right: 12px;
    padding: 4px;
    border-radius: 50px;
    min-height: 24px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.success {
      &:hover {
        background-color: $success !important;
      }
    }
    &.danger {
      &:hover {
        background-color: $danger !important;
      }
    }
    &.warning {
      &:hover {
        background-color: $warning !important;
      }
    }
    &.primary {
      &:hover {
        background-color: $primary !important;
      }
    }

    &:hover {
      cursor: pointer;
      color: white !important;

      .app-icon {
        color: white !important;
      }
    }

    &.proposta {
      background-color: $muted-light;
      &:hover {
        background-color: $muted;
      }
    }
  }

  .dropdown {
    margin-left: auto;
  }
  .dropdown-toggle::after {
    background-image: url("/img/icons/more-than.png");
  }

  .dropdown-toggle {
    &.financial-badge::after {
      display: none;
    }

    &::after {
      display: inline-block;
      content: "";
      background-image: url("/img/icons/more-than.png");
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      height: 12px;
      width: 12px;
      transform: rotate(90deg);
      margin-left: 3px;
    }
  }

  .action-button {
    .dropdown-toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      &::after {
        vertical-align: middle;
      }
    }
  }

  .base-button:hover .invert-on-hover,
  .btn:hover .invert-on-hover {
    filter: brightness(0) invert(1);
  }

  .pointer {
    transition: all ease-in-out 0.15s;
    &:hover {
      transform: translateY(-3px) !important;
    }
  }

  .card-header {
    border-bottom: none !important;
    box-shadow: none !important;
    padding: 16px;
    padding-bottom: 0;
    border-radius: 10px !important;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: 16px;

    & * {
      line-height: 1;
    }

    h3 {
      color: $dark;
      font-weight: 500 !important;
      font-size: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
    }

    h4 {
      color: $dark;
      font-weight: 400 !important;
      font-size: 14px;
      margin-bottom: 6px;
    }
    .financial-badge {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid $muted;
      text-transform: uppercase;
      font-weight: 400 !important;
      padding: 4px 8px;
      border-radius: 20px !important;

      &.warning {
        color: $warning;
        border-color: $warning;
      }
      &.success {
        color: $success;
        border-color: $success;
      }
      &.danger {
        border-color: $danger;
        color: $danger;
      }
    }
  }

  .app-card-footer {
    padding: 16px;
    padding-top: 0;
    .rounded-pill.avatar {
      position: relative;
      width: 48px;
      height: 48px;
    }
  }
}

.popover-title-border {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 1px;
  width: 100%;
  position: relative;
  border-color: #e8e8e8 !important;

  &.full {
    border-color: transparent !important;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e8e8e8;
      top: 50%;
      transform: translate(-12px, 50%);
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: 50%;
      transform: translate(12px, -50%);
      background-color: #e8e8e8;
    }
  }
}
</style>
