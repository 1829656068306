<template>
  <div>
    <AppFixedPageTitle
      title="Programações"
      icon="/img/icons/chemical-plant-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            text="novo"
            type="success"
            @click.prevent="handleSearchContract"
            icon="/img/icons/plus-math--v1-white.png"
          />
          <AppPageHeaderActionsButton
            text="Entregas"
            type="dark"
            @click.prevent="$router.push('/operational/schedule/in_course')"
            icon="/img/icons/icons8/ios/concrete-mixer-white.png"
          />
          <AppPageHeaderActionsButton
            text="Cadastros"
            type="warning"
            :isDropdown="true"
            icon="/img/icons/product-documents.png"
          >
            <AppPageHeaderActionsButtonDropItem
              text="Corte de Água"
              icon="/img/icons/icons8/ios/svgs/water.svg"
              @click.prevent="$router.push('/configuration/general/water')"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Temp. Ambiente"
              icon="/img/icons/icons8/ios/svgs/dew-point_danger.svg"
              @click.prevent="$router.push('/configuration/general/temperature')"
            />
             <AppPageHeaderActionsButtonDropItem
              text="Umidade"
              icon="/img/icons/icons8/ios/svgs/humidity.svg"
              @click.prevent="$router.push('/configuration/general/moisture')"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Escala de motorista"
              icon="/img/icons/interstate-truck.png"
              @click="openModalDriverScale"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
      :isHideTableOnMobileActive="true"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid">
      <!-- Cards aqui -->
      <Cards v-if="listType === 'cards'"
        :schedules="schedules"
        :init="listItems"
        :loadingSkeleton="loadingSkeleton"
        @handleDeleteProgramming="handleDeleteProgramming"
        @handleModalEntityCreditLimit="handleModalEntityCreditLimit"
        @handleListContacts="handleListContacts"
        @handleCreateModalService="handleCreateModalService"
        @handleModalListService="handleModalListService"
        @handleCreateServiceCycleModal="handleCreateServiceCycleModal"
        @handleHistorie="handleHistorie"
        @handleCreateModalServiceCancellation="handleCreateModalServiceCancellation"
        @showPendencies="showPendencies"
      />
      <Table v-if="listType === 'table'"
        :schedules="schedules"
        :init="listItems"
        :loadingSkeleton="loadingSkeleton"
        @handleDeleteProgramming="handleDeleteProgramming"
        @handleModalEntityCreditLimit="handleModalEntityCreditLimit"
        @handleListContacts="handleListContacts"
        @handleCreateModalService="handleCreateModalService"
        @handleModalListService="handleModalListService"
        @handleCreateServiceCycleModal="handleCreateServiceCycleModal"
        @handleHistorie="handleHistorie"
        @handleCreateModalServiceCancellation="handleCreateModalServiceCancellation"
        @showPendencies="showPendencies"
      />
      <PuzlEmptyData v-if="!schedules.length && !loadingSkeleton"></PuzlEmptyData>
      <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true, true)"/>
    </div>
    <ModalSearchContract ref="searchContract" @setContractProposal="setContractProposal" />

    <!-- Modals Shared Between Cards and Table -->
    <ModalEntityCreditLimit ref="modalEntityCreditLimit" @updated="updateScreen()"/>
    <ModalListContacts ref="modalListContacts"/>
    <ModalEditService @updatedService="handleChangeService" ref="modalScheduleService"/>
    <ModalScheduleListService @addedService="handleChangeService" ref="modalScheduleListService"/>
    <ModalServiceCycle @updatedCycle="handleChangeService" ref="modalServiceCycle"/>
    <ModalScheduleHistory ref="modalScheduleHistorie"/>
    <ModalServiceCancellation @updatedService="handleChangeService" ref="modalServiceCancellation"/>
    <ModalPendencies ref="modalPendencies" />
    <ModalDriverScale ref="modalDriverScale" />
  </div>
</template>

<script>
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import ModalSearchContract from "./Shared/_ModalSearchContract";
import ModalEntityCreditLimit from "@/views/Modules/Configuration/Entity/Shared/_ModalCreditLimit.vue";
import ModalEditService from "./Service/_Edit.vue";
import ModalScheduleListService from "./Shared/_ModalScheduleListService";
import ModalServiceCycle from "@/views/Modules/Operational/Schedule/Service/_ModalServiceCycle.vue";
import ModalScheduleHistory from "@/views/Modules/Operational/Schedule/Shared/_ModalScheduleHistory.vue";
import ModalServiceCancellation from "./Service/_ModalServiceCancellation";
import ModalPendencies from "@/views/Modules/Operational/Schedule/Shared/_ModalPendencies.vue";
import {mapGetters} from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlSelect from "@/components/PuzlSelect.vue";
import { ScheduleListFilterType, initScheduleListFilterType } from "./types";
import { date } from "../../../../helpers";
import {ScheduleEnum} from "@/enum/ScheduleEnum";
import ModalDriverScale from "../../../../modules/operational/schedule/driver-scale/ModalDriverScale.vue";
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  AppSelect,
  AppTabSelect, AppViewTrigger, initSearchBarFilterType
} from "@/components/AppGlobal";
import entityFinancialStore from "../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";
import EntityFinancialHubEnum from '../../../../modules/configuration/entity/aggregates/entity-financial/enums/EntityFinancialHubEnum';
import ModalListContacts from "../../Commercial/ContractProposal/Shared/Contacts/_ModalListContacts.vue";

export default {
  name: "IndexSchedule",
  mixins: [cursorPaginate],
  components: {
    ModalEntityCreditLimit, ModalServiceCycle, ModalScheduleHistory, ModalPendencies,
    AppViewTrigger,
    AppPageHeader, AppPageHeaderActionsButtonDropItem, AppPageHeaderActions, AppPageHeaderActionsButton,
    AppTabSelect, AppFixedPageTitle, AppSelect,
    AppSearchBar,
    AppSearchBarFilterSection,
    Cards,
    Table,
    ModalSearchContract,
    PuzlSelect,
    ModalEditService,
    PuzlEmptyData,
    ModalServiceCancellation,
    ModalScheduleListService,
    ModalListContacts,
    ModalDriverScale,
  },
  data() {
    return {
      listType: "cards",
      loadingSkeleton: true,
      searchBarFilter: initSearchBarFilterType(),
      filter: initScheduleListFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      scheduleEnum: ScheduleEnum,
      isLoading: true,
      isScreenWidthBiggerThan992: true,
      previousPlantId: null,
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [],
          },
          {
            id: 1,
            name: "VOLUME MAIOR PARA O MENOR",
            selected_name: "MAIOR > MENOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "total_volume",
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "VOLUME MENOR PARA O MAIOR",
            selected_name: "MENOR > MAIOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "total_volume",
              is_desc: false,
            }],
          },
          {
            id: 3,
            name: "LANÇAMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "LANÇ. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "id",
              is_desc: true,
            }],
          },
          {
            id: 4,
            name: "LANÇAMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "LANÇ. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "id",
              is_desc: false,
            }],
          },
        ]
      },
      range: {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      schedules: "schedule/fetch",
      company_plants: "plant/activeItems",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.status === null,
        },
        {
          id: this.scheduleEnum.PENDING,
          name: 'Pendentes',
          selected: this.filter.status === this.scheduleEnum.PENDING,
        },
        {
          id: this.scheduleEnum.IN_PROGRESS,
          name: 'Em andamento',
          selected: this.filter.status === this.scheduleEnum.IN_PROGRESS,
        },
        {
          id: this.scheduleEnum.COMPLETED,
          name: 'Concluídos',
          selected: this.filter.status === this.scheduleEnum.COMPLETED,
        },
        {
          id: this.scheduleEnum.SELLER_REQUEST,
          name: 'Solicitações',
          selected: this.filter.status === this.scheduleEnum.SELLER_REQUEST,
        },
      ];
    },
  },
  mounted(){
    this.clearFilter();
  },
  methods: {
    showPendencies(schedule){
      this.$refs.modalPendencies.openModal(schedule);
    },
    handleCreateModalServiceCancellation(uuid){
      this.$refs.modalServiceCancellation.handleCreate(uuid);
    },
    handleHistorie(uuid) {
      this.$refs.modalScheduleHistorie.openModal(uuid);
    },
    handleCreateServiceCycleModal(schedule, service) {
      this.$refs.modalServiceCycle.handleCreate(schedule, service);
    },
    handleModalListService(contract_proposal_uuid, schedule_uuid, company_plants, params) {
      this.$refs.modalScheduleListService.openModal(contract_proposal_uuid, schedule_uuid, company_plants, params);
    },
    handleCreateModalService(service, company_plants){
      this.$refs.modalScheduleService.handleCreate(service, company_plants);
    },
    handleChangeService(params) {
      this.$store.dispatch("schedule/forceUpdate", params);
    },
    handleListContacts(contract_proposal_uuid) {
      this.$store.dispatch("contractProposal/show", contract_proposal_uuid).then((response) => {
        this.$refs.modalListContacts.openModal(response.data);
      });
    },
    async handleModalEntityCreditLimit(contract_proposal) {
      let loader = this.$loading.show();
      this.$refs.modalEntityCreditLimit.openModal(contract_proposal.entity_id, contract_proposal.document.length === 18, true);
      loader.hide();
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      this.$emit("setToday")
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());

      this.previousPlantId = this.searchBarFilter.company_plant_selected;

      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão do filtro principal
     * @returns {ScheduleListFilterType}
     */
    defaultFilter() {
      const defaultFilter = {
        ...initScheduleListFilterType(),
        status: 10,
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };

      return defaultFilter;
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [],
          selected: null,
          start: this.currentDate,
          end: this.currentDate,
        },
      }
    },
    listItems(isAccumulateItems = false, requestedByTabSwitcher = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      this.prepareFilter();

      let isChangingPlantId = this.previousPlantId !== this.searchBarFilter.company_plant_selected;
      if(isChangingPlantId){
        this.previousPlantId = this.searchBarFilter.company_plant_selected;
      }

      if(!requestedByTabSwitcher && !isChangingPlantId){
        const isSameDate = date.make(this.filter.range.end).isSame(this.filter.range.start, 'day');
        const isToday = date.make().isSame(this.filter.range.end, 'day');
        this.filter.status = isSameDate && isToday ? 10 : null;
      }

      this.$store
        .dispatch("schedule/fetchItems",
          {
            filter: this.filter,
            next_page: this.filter.next_page
          },
        )
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false;
        });
    },
    prepareFilter() {
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;

      const selectedItem = this.orderBy.items[this.orderBy.selected];
      const selectedFilter = selectedItem.filter.length ? selectedItem.filter[0] : null;

      this.filter.order_by = selectedFilter
        ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
        : "";
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.status === item.id;
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.status = item.id;
      this.listItems(false, true);
    },
    setContractProposal(contract){
      this.$router.push({
        path: "/operational/schedule/create",
        name: "operational.schedule.create",
        params: {
          contract_proposal_uuid: contract.uuid
        }
      });
    },
    handleDeleteProgramming(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("schedule/destroy", uuid)
              .then(response => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                this.listItems();
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                  this.$Progress.finish();
                  this.listItems();
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    updateScreen(){
      this.listItems();
    },
    handleSearchContract() {
      this.$refs.searchContract.openModal();
    },
    handleGantt() {
      this.$router.push("/operational/schedule/gantt?sidebar=0");
    },
    openModalDriverScale() {
      this.$refs.modalDriverScale.openModal();
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
