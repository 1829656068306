import { moneyToFloat } from "../../../../../helpers";
import moment from 'moment';
import { initStockLocationListFilterType } from "../../../../../modules/configuration/stock-location/types"
import stockLocationStore from "../../../../../modules/configuration/stock-location/store/stockLocationStore";
import { useEntryLaunchStockHook } from "../../../../../modules/configuration/entry-launch/hooks/useEntryLaunchStockHook";

const entryLaunchMixin = {
  data() {
    return {
      selected_items: null,
      freight_modalities: [
        {"id":1,"name":"Por conta do emitente","code":"0"},
        {"id":2,"name":"Por conta do destinatário/remetente","code":"1"},
        {"id":3,"name":"Por conta de terceiros","code":"2"},
        {"id":4,"name":"Transporte próprio por conta do remetente","code":"3"},
        {"id":5,"name":"Transporte próprio por conta do destinatário","code":"4"}
      ],
      operation_source:false,
      associate_index_item: null,
      default_product_service_hub_types: [4, 6, 7, 9, 11],
      stock_locations: [],
      fuel_tank_stock_locations: [],
      loadingStockLocation:false,
      loadingChartAccount:true,
      dontHasFinancial:false,
      loadingOperationSource:false,
      freight_by_entry_launch:0,
      max_date:false,
      is_paid:false,
      entry_launch_types:{
        '1': "Serviço",
        "2": "MCC",
        "3": "Produtos e Serviços",
        "4": "Frete",
        "5": "Tributos Automáticos",
        "6": "Empréstimo",
        "7": "Financiamento",
        "8": "Não informado",
        "9": "Folha de pagamento",
        "10": "Taxas e Tributos",
        "11": "Devolução de Receita",
        },
      tab_value : "document",
    };
  },
  computed: {
   formattedDocumentTemplates() {
    if(!this.$_document_templates) return [];
    return this.$_document_templates.map(function(item){
     item.label = `${item.code ? item.code + ' - ' : ''}${item.name}`
     return item;
    })
   },
   getChildrenPercentage() {
    if(!this.entry_launch.children_balance || !this.entry_launch.total_value) {
      return 100;
    }
    return 100 - (Number(this.entry_launch.children_balance) / Number(this.entry_launch.total_value) * 100);
   },
   getChildrenValue() {
    if(!this.entry_launch.children_balance) {
      return this.entry_launch.total_value;
    }
    return Number(this.entry_launch.total_value) - Number(this.entry_launch.children_balance);
   }
  },
  created() {
    this.getMaxDate();
  },
  methods: {
    associatedItem(item, check_items = true) {
      const current_item = item;
      this.loadingItems = true;
      this.$notify({ type: "info", message: "Estamos trabalhando em sua solicitação." });
      this.$store
        .dispatch("productService/associate", {
          new_product_service_hub_id: current_item.product_service_hub_id,
          entity_id: this.entry_launch.entity_id,
          entry_launch_item_id: this.items[this.associate_index_item].id,
          old_product_service_hub_id: this.items[this.associate_index_item].product_service_hub_id,
          product_service_id: this.items[this.associate_index_item].product_service_id,
        })
        .then((response) => {
          if (this.has_internal_item === false && this.entry_launch.imported === 0) {
            this.entry_launch.product_service_hub_type_id = current_item.product_service_hub_type_id;
          }
          const currentStockStatus = this.items[this.associate_index_item].stock_status;
          this.items[this.associate_index_item] = response.data;
          this.items[this.associate_index_item].product_service_hub = {
            product_service_hub_type_id: response.data.product_service_hub_type_id,
            category_name: response.data.category_name,
            product_name: response.data.product_name,
            purchase_unit: response.data.purchase_unit,
            ncm: response.data.ncm,
            supplier_name: response.data.supplier_name,
            origin_name: response.data.origin_name,
            type_name: response.data.type_name,
            is_fuel: response.data.is_fuel,
          };

          this.items[this.associate_index_item].puzl_code = current_item.code;
          this.items[this.associate_index_item].external_type = 0;
          this.items[this.associate_index_item].base_price = current_item.price;
          this.items[this.associate_index_item].stock_status = currentStockStatus;

          if(this.hasFuelItem()) {
            this.getFuelTankStockLocations();
          }
          if (this.entry_launch.imported === 0 && check_items) {
            this.checkItemsRules();
          }
          this.$notify({ type: response.error_type, message: response.message });
          this.loadingItems = false;
          if(parseInt(response.data.product_service_hub_type_id) !== parseInt(this.entry_launch.product_service_hub_type_id)){
            this.entry_launch.product_service_hub_type_id = response.data.product_service_hub_type_id;
          }
          this.getOperationSources(true);
        });
    },
    greaterThanBasePrice(base_price, current_price) {
      return base_price && base_price !== Number(current_price).toFixed(2);
    },
    getStockLocations(reset = true) {
      if(!this.entry_launch.entry_company_plant_id) return;
      this.loadingStockLocation = true;
      if(reset) {
        this.resetStockLocations();
        this.fuel_tank_stock_locations = [];
      }
      /* MCC busca locais de dosagem */
      switch (parseInt(this.entry_launch.product_service_hub_type_id)) {
        case 2:
          this.$store.dispatch("plantChargePointLocation/getByCompanyPlant", this.entry_launch.entry_company_plant_id).then((response) => {
            this.stock_locations = [{id: 0, name: 'Sem Local'}, ... response.data];
            this.loadingStockLocation = false;
          });
          break;
        /* Produto busca locais de estoque e se houver combustíveis busca os reservatórios também */
        case 3:
          stockLocationStore
            .actions
            .list({...initStockLocationListFilterType(), limit: 100})
            .then((listed) => {
              this.stock_locations = [{id: 0, name: 'Sem Local'}, ... listed.items];
            });

          /* Se tiver itens do tipo combustível dispara busca reservatórios */
          if(this.hasFuelItem()) {
            this.getFuelTankStockLocations(reset)
          } else {
            this.loadingStockLocation = false;
          }
          break;
        default:
          this.loadingStockLocation = false;
      }
    },
    getStockLocationsByCmcCategory(cmc_category_id) {
      return this.stock_locations.filter(item => item.cmc_category_id === cmc_category_id || item.id === 0);
    },
    applyToAllItems(value, type, is_fuel) {
      if(!is_fuel) is_fuel = 0;
      this.$forceUpdate();
      /* Se não for o primeiro a ser definido ou for do tipo mcc interrompe a função */
      if(
        this.items.length <= 1 ||
        this.items.filter(item => item[type] != null).length !== 1 ||
        (type === 'stock_location_id' && this.entry_launch.product_service_hub_type_id === 2)
      ) return;
      this.$Swal.confirmAction('Deseja aplicar para todos os itens?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
          this.items.map(function(item) {
            item.product_service_hub.is_fuel = item.product_service_hub.is_fuel ? item.product_service_hub.is_fuel : 0;
            /* local de estoque é variável para produtos do tipo de combustível. */
            if(type !== 'stock_location_id' || item.product_service_hub.is_fuel === is_fuel) {
              item[type] = value;
            }
            return item;
          });
          this.$forceUpdate();
        }
      });
    },
    resetStockLocations() {
      this.items.map(item => item.stock_location_id = null)
    },
    handleModalEquipmentApportionment(item, index = null) {
      this.$refs.modalEquipmentApportionment.openModal(item, index, this.is_paid);
    },
    handleModalOperationSourceCreate() {
      this.$refs.modalOperationSourceCreate.handleCreateModal();
    },
    setEquipmentApportionments(value, index) {
      if(!value.length) return;
       /* Se for necessário buscar pela posição */
      if(index !== null) {
        this.items[index].equipment_apportionments = value;
      }
      /* Senão, busca pelo id */
      else {
        this.items.find(item => item.product_service_hub_id === value[0].product_service_hub_id).equipment_apportionments = value;
      }
      this.applyEquipmentApportionmentsToAllItems(value);
    },
    applyEquipmentApportionmentsToAllItems(base_equipment_apportionments) {
      /* Se não for o primeiro a ser definido interrompe a função */
      if(this.items.length <= 1 || this.items.filter(item => item.equipment_apportionments && item.equipment_apportionments.length > 0).length !== 1) return;
      this.$Swal.confirmAction('Deseja aplicar para todos os itens?', ['Sim', 'Não']).then((result) => {
        if (result.isConfirmed) {
            this.items.map(function(item){
              let equipment_apportionments = JSON.parse(JSON.stringify(base_equipment_apportionments));
              equipment_apportionments.map(function(children){
                children.value = ((children.percentage / 100) * item.total_value).toFixed(2);
                children.product_service_hub_id = item.product_service_hub_id
              })
              /* valor real */
              const real_total_value = equipment_apportionments.reduce((a, b) => parseFloat(a) + parseFloat(b.value), 0);
              /* Diferença de valor real e teórico */
              const difference = this.$helper.toDecimal(real_total_value - item.total_value, 2, true);
              /* Se houver resto positivo, remove do valor total */
              if(difference > 0) {
                equipment_apportionments[0].value = (parseFloat(equipment_apportionments[0].value) - difference).toFixed(2);
              }
              else if(difference < 0) {
                equipment_apportionments[0].value = (parseFloat(equipment_apportionments[0].value) + Math.abs(difference)).toFixed(2);
              }
              item.equipment_apportionments = equipment_apportionments;
            }, this)
          }
      });
    },
    getEquipments() {
      let filter = {
        company_plant_uuid: this.company_plants.find((item) => item.id == this.entry_launch.entry_company_plant_id).uuid,
      };
      return this.$store.dispatch("equipment/getByCompanyPlantAndService", { filter: filter });
    },
    /**
     * Função a qual trata o valor total alterado de uma parcela de contas a pagar
     *
     * @param {string} value | valor total já alterado da parcela
     * @param {integer} index | posição da parcela que está sendo alterada
     * @param {array} installments | array de parcelas que serão verificadas/alteradas
     * @param {object} data | objeto a ter o valor total verificado
     */
    changedInstallmentTotalValue(value, index, installments, data) {
      let new_final_value = moneyToFloat(value);
      const last_index = installments.length - 1;
      let current_total_value =  typeof data.total_value === 'string' && data.total_value.includes(",") ? moneyToFloat(data.total_value) : parseFloat(data.total_value)
      switch(true) {
        /* Se for maior ou igual ao valor total da despesa será zerado */
        case new_final_value >= current_total_value:
          this.$notify({ type: "danger", message: "O valor total deve ser menor que o valor total do item." });
          new_final_value = 0;
          installments[index].total_value = 0;
          break;
        /* Se for menor ou igual ao desconto, o valor do desconto será zerado */
        case new_final_value <= moneyToFloat(installments[index].discounts_fees):
          this.$notify({ type: "danger", message: "O valor deve ser maior que o valor de desconto, desconto zerado." });
          installments[index].discounts_fees = 0;
          new_final_value += moneyToFloat(installments[index].fees_fines);
          break;
        /* Por padrão acrescenta taxas e subtrai descontos */
        default :
        new_final_value -= moneyToFloat(installments[index].discounts_fees)
        new_final_value += moneyToFloat(installments[index].fees_fines)
      }
      installments[index].final_value = Number(new_final_value).toFixed(2)
      /* Redefine valor total e final da primeira parcela */
      const sum_installments = installments.reduce(function(new_value, obj, index) {
        if(index !== last_index) {
          new_value += obj.total_value.includes(",") ? moneyToFloat(obj.total_value) : parseFloat(obj.total_value);
        }
        return new_value;
      }, 0);
      const first_value = (current_total_value - sum_installments).toFixed(2);
      installments[last_index].total_value = first_value;
      installments[last_index].final_value = first_value;
      /* Redefine valor final da despesa */
      data.final_value =
        installments.reduce((new_value, obj) => new_value +=  obj.final_value.includes(",") ? moneyToFloat(obj.final_value) : parseFloat(obj.final_value), 0)
    },
    checkFinancial(setItemsStockStatus = false) {
      if(this.entry_launch.operation_source_id) {
        this.getChartAccounts();
        this.operation_source = this.$_operation_sources.find(item => item.id === this.entry_launch.operation_source_id);
        if(!this.operation_source) {
          this.entry_launch.operation_source_id = null;
          if(typeof this.hasOperationSource !== 'undefined') this.hasOperationSource = false;
          return;
        }
        this.dontHasFinancial = !this.operation_source.is_generate_financial;
        
        if(setItemsStockStatus) {
          useEntryLaunchStockHook().setItemsStockStatus(this.items, this.entry_launch.product_service_hub_type_id, this.operation_source.is_generate_stock);
        }
       
        return;
      }
      this.getParentsWithChildrenBalance();
      this.dontHasFinancial = false;
      this.operation_source = false;
    },
    /**
     * Normaliza valor manipulado ou não por v-money.
     *
     * @param {string|float|null} value
     * @returns {string|float|null}
     */
    normalizeValue(value) {
      switch(true) {
        case !value :
          return null;
        case value.includes(",") :
          return moneyToFloat(value)
        default :
          return parseFloat(value);
      }
    },
    getOperationSources(handle_check_financial = false) {
      this.loadingOperationSource = true;
      this.$store.dispatch("operationSource/fetchItems", {filter:
        {
          product_service_hub_type_id : this.entry_launch.product_service_hub_type_id,
          type : 0,
        }
      }).then(() => {
        this.loadingOperationSource = false;
        if(handle_check_financial) {
          this.checkFinancial(true);
        }
      });
    },
    getChartAccounts() {
      this.loadingChartAccount = true;
      this.$store.dispatch("managerialChartAccount/getByOperationSource", this.entry_launch.operation_source_id).then(() => {
        this.loadingChartAccount = false;
      });
    },

    /**
     * Verifica existem itens do tipo combustível.
     *
     * @returns {bool}
     */
    hasFuelItem() {
      return this.items.findIndex(
        item => item.product_service_hub.is_fuel === 1
      ,this) >= 0;
    },

    /**
     * Busca de reservatórios.
     *
     * @param {boolean} reset
     * @returns {void}
     */
     getFuelTankStockLocations(reset = false) {
      if(!this.fuel_tank_stock_locations.length || reset) {
        this.loadingStockLocation = true;
        this.$store.dispatch("fuelTank/getByCompanyPlant", this.entry_launch.entry_company_plant_id).then((response) => {
          this.loadingStockLocation = false;
          this.fuel_tank_stock_locations = [{id: 0, name: 'Sem Local'}, ... response.data];
        });
      }
    },

    /**
     * Verifica se aba de documento está preenchida com todos os campos obrigatórios.
     *
     * @returns {bool}
     */
    pendingDocumentTab() {
     switch(true) {
      case !this.entry_launch.entry_company_plant_id :
      case !this.entry_launch.company_plant_id :
      case !this.entry_launch.product_service_hub_type_id :
      case !this.entry_launch.operation_source_id :
      case !this.entry_launch.company_plant_buyer_id :
      case !this.entry_launch.entry_date :
      case !this.entry_launch.document_number :
      case !this.entry_launch.document_template_id :
      case !this.entry_launch.series :
      case !this.entry_launch.issue_date :
        return true;
      default:
        return false;
     }
    },

    /**
     * Verifica se aba de pagamento está preenchida com todos os campos obrigatórios.
     *
     * @returns {bool}
     */
    pendingPaymentTab() {
      switch(true) {
        case this.entry_launch.operation_source_id && this.dontHasFinancial:
          return false;
        case !this.bill_pay_installments.length :
        case !this.entry_launch.bank_account_id :
        case this.bill_pay_installments.length && this.bill_pay_installments.findIndex(item => !item.due_date) >= 0 :
          return true;
        default:
          return false;
       }
    },

    /**
     * Verifica se aba de itens está preenchida com todos os campos obrigatórios.
     *
     * @returns {bool}
     */
    pendingItemTab() {
        switch(true) {
          case this.items.length === 0:
          case this.items.findIndex(item => !item.chart_account_id) >= 0:
          case this.items.findIndex(item => !item.stock_location_id && item.stock_location_id !== 0) >= 0 && [2,3].includes(this.entry_launch.product_service_hub_type_id):
            return true;
          default:
            return false;
        }
    },

    /**
     * Avança para próxima aba
     *
     * @returns {void}
     */
    goToNextTab() {
      this.selected_items = null;

      switch(this.tab_value) {
        case 'document':
          this.tab_value = 'items';
          break;
        case 'items':
          this.tab_value = 'transport';
          break;
        case 'transport':
          this.tab_value = 'payment';
          break;
        default:
          this.tab_value = 'document';
      }
    },

    /**
     * Define se botão de próxima aba deve ser habilitado
     *
     * @returns {bool}
     */
    getNextTabButtonStatus() {
      switch(true) {
        case this.tab_value === 'payment':
        case this.tab_value === 'document' && (!this.entry_launch.product_service_hub_type_id || !this.entry_launch.company_plant_buyer_id):
        case this.tab_value === 'transport' && (this.bill_pay_installments.length < 1 || this.entry_launch.total_value <= 0 || this.dontHasFinancial):
          return true;
        default:
          return false;
      }
    },

    /**
     * Replica prazo de parcelas para todos os itens.
     *
     * @param {object} data
     * @returns {void}
     */
    applyDueDateToAllInstallments(data) { //here
      // Por prazo
      if(data.type === 'Prazo') {
        let previous_due_date = null
        this.bill_pay_installments.map((installment) => {
          if(previous_due_date) {
            installment.due_date = moment(previous_due_date).add(data.days, 'days').format('YYYY-MM-DD 00:00:00');
          }
          /* Replica vencimento original */
          if(data.replicate_to_original_due_date) {
            installment.original_due_date = installment.due_date;
          }
          previous_due_date = installment.due_date;
        })
      } 
      // Por data fixa
      else {
        let previous_due_date = null
        this.bill_pay_installments.map((installment) => {
          if(installment.index > 1) {
            installment.due_date = moment(previous_due_date).add(1, 'M').format('YYYY-MM-DD 00:00:00');
            /* Replica vencimento original */
            if(data.replicate_to_original_due_date) {
              installment.original_due_date = installment.due_date;
            }
            previous_due_date = installment.due_date;
          } else {
            previous_due_date = data.days;
          }
        })
      }
    },

    /**
     * Ativa ou desativa a marcação de itens
     *
     * @returns {void}
     */
    setSelectedItems() {
       !this.selected_items ? this.addAllToSelectedItems() : this.selected_items = null; 
    },

    /**
     * Adiciona item ao array de itens marcados
     *
     * @param {integer} index
     * @returns {void}
     */
    addToSelectedItems(index) {
      this.selected_items.push(index)
    },

    /**
     * Adiciona todos os itens ao array de itens marcados
     *
     * @returns {void}
     */
    addAllToSelectedItems() {
      this.selected_items = this.items.reduce((array, item, index) => (array.push(index), array), []);
    },

    /**
     * Remove todos itens do array de itens marcados
     *
     * @returns {void}
     */
    removeAllFromSelectedItems() {
      this.selected_items = [];
    },

    /**
     * Remove item do array de itens marcados
     *
     * @param {integer} index
     * @returns {void}
     */
    removeFromSelectedItems(index) {
      this.selected_items.splice(index, 1)
    },

    /**
     * Aplica plano de contas ou rateio de equipamentos para itens de despesas
     *
     * @param {object|integer} value
     * @param {string} key
     * @returns {void}
     */
    applyToSelectedItems(value, key) {
      this.items.map(function(item, index) {
        if(this.selected_items.includes(index)) {
          switch(key) {
            case 'chart_account_id' :
              item[key] = value;
              break;
            case 'equipment_apportionments' :
              let current_value = this.$helper.cloneObject(value);
              current_value.map(function(apportionment) {
                apportionment.value = Number(item.total_value *  (apportionment.percentage / 100)).toFixed(2);
                return apportionment;
              });
              /* valor real */
              const real_total_value = current_value.reduce((a, b) => parseFloat(a) + parseFloat(b.value), 0);
              /* Diferença de valor real e teórico */
              const difference = this.$helper.toDecimal(real_total_value - item.total_value, 2, true);
              /* Se houver resto positivo, remove do valor total */
              if(difference > 0) {
                current_value[0].value = (parseFloat(current_value[0].value) - difference).toFixed(2);
              }
              /* Se houver resto negativo, adiciona ao valor total */
              else if(difference < 0) {
                current_value[0].value = (parseFloat(current_value[0].value) + Math.abs(difference)).toFixed(2);
              }
              item.equipment_apportionments = current_value;
              break;
            case 'stock_status' :
              item[key] = value;
              break;
            default :
              if(!item.is_fuel) {
                item[key] = value;
              }
          }
        }
      }, this)
      this.$forceUpdate();
    },

    handleModalSearchEntryLaunch() {
      this.$refs.modalSearchEntryLaunch.openModal(this.entry_launch);
    },

    /**
     * Registra frete
     *
     * @param {object} value
     * @returns {void}
     */
    storeSelectedFreight(item) {
      this.freight_entity = {
        entity_name : item.entity_name,
        document : item.document,
      };

      this.freight = {
        entity_id : item.entity_id,
        entry_launch_id : null,
        freight_modality_id : this.entry_launch.freight_type && this.entry_launch.freight_type !== 9 ?
          this.entry_launch.freight_type + 1 : 2,
        quantity : 1.000,
        total_value : item.total_value,
        id : item.id,
      };
      this.freight_by_entry_launch = 1;
    },

    /**
     * Busca data máxima permitida em componentes de data para fluxo de entradas
     *
     * @returns {void}
     */
    getMaxDate() {
      this.max_date = new Date(this.$helper.parseDate(moment(), 'YYYY-MM-DD HH:mm:ss'));
    },

    /**
     * Verifica se despesa está quitada
     *
     * @returns {void}
     */
    checkPayment() {
      let total_value = 0;
      if(typeof this.entry_launch.total_value === 'string' && this.entry_launch.total_value.includes(',')) {
        total_value = moneyToFloat(this.entry_launch.total_value);
      } else {
        total_value = parseFloat(this.entry_launch.total_value);
      }

      const paid_value = this.bill_pay_installments.reduce(function(new_value, obj) {
        if(obj.status === 1) {
          new_value += (obj.total_value.includes(',') ? moneyToFloat(obj.total_value) : parseFloat(obj.total_value));
        }
        return new_value
      }, 0);
      this.is_paid = paid_value >= total_value;
    },

    /**
     * Informa se formData ultrapassa o limite de 8000
     * 
     * @param {object} formData 
     * @returns {boolean}
     */
    formDataLengthIsValid(formData) {
      return Array.from(formData.keys()).length < 8000
    },

    async getParentsWithChildrenBalance() {
      if(this.entry_launch.product_service_hub_type_id && this.entry_launch.company_plant_buyer_id) {
        const company_plant_buyer = this.company_plant_buyers.find((item) => item.id === this.entry_launch.company_plant_buyer_id);
          // Busca notas mães
          this.$store.dispatch("entryLaunch/getParentsWithChildrenBalance", {
            product_service_hub_type_id: this.entry_launch.product_service_hub_type_id,
            entity_id: this.entry_launch.entity_id,
            company_plant_issuer_id: company_plant_buyer ? company_plant_buyer.issuer.id : null,
            company_plant_buyer_id: company_plant_buyer ? null : this.entry_launch.company_plant_buyer_id,
          }).then(() => {
            this.loadingParents = false;
          });
        }
    },

    /**
     * Formata novo valor monetário em 3 casas decimais
     * 
     * @param {object} formData 
     * @returns {boolean}
     */
    toMoneyWithThreeDecimals(value) {
      const rounded_value = parseFloat(value).toFixed(3);
      const [total_value, cents] = rounded_value.toString().split('.');
      return `R$ ${total_value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${cents}`;
    },
    async copyAccessKey(accessKey) {
      await navigator.clipboard.writeText(accessKey);
      this.$notify({
        type: "success",
        message: "Código copiado",
      });
    },
    handleFreightDelete(entry_launch_id) {
      this.$Swal.confirmDelete().then(async (result) => {
        if (result.isConfirmed) {
          // Se frete já foi persistido
          if(this.freight.entry_launch_id) {
            const loader = this.$loading.show();
            await this.$store.dispatch("entryLaunch/freightDisassociate", entry_launch_id)
            loader.hide();
          }
          this.freight = {
            entity_id : null,
            entry_launch_id : null,
            freight_modality_id : null,
            quantity : 1.000,
            total_value : 0,
            id : null,
          };
          this.freight_entity = null;
        }
      });
    },
    /**
     * @param {object} entry_launch_item - Item da entrada
     * @param {number} entry_launch_item_line_number - Linha do item
     */
    handleOpenModalTaxes(entry_launch_item, entry_launch_item_line_number) {
      this.$refs.modalTaxes.openModal(entry_launch_item, entry_launch_item_line_number);
    },
    /**
     * @param {object} tax - Imposto
     * @returns {object} 
     */
    getEntryLaunchItemTaxTransformed(tax) {
      const transformValue = value => value && value.includes(",") ? value.replaceAll(".", "").replace(",", ".") : value;
      tax.icms_value = transformValue(tax.icms_value);
      tax.icms_with_replacement_value =  transformValue(tax.icms_with_replacement_value);
      tax.icms_exonerated_value = transformValue(tax.icms_exonerated_value);
      tax.ipi_value = transformValue(tax.ipi_value);
      tax.ipi_returned_value = transformValue(tax.ipi_returned_value);
      tax.pis_value = transformValue(tax.pis_value);
      tax.cofins_value = transformValue(tax.cofins_value);
      tax.issqn_value = transformValue(tax.issqn_value);
      tax.import_value = transformValue(tax.import_value);
      tax.fcp_with_replacement_value = transformValue(tax.fcp_with_replacement_value);
      tax.freight_value = transformValue(tax.freight_value);
      tax.insurance_value = transformValue(tax.insurance_value);
      tax.other_value = transformValue(tax.other_value);
      tax.service_value = transformValue(tax.service_value);
      tax.discount_value = transformValue(tax.discount_value);
      return tax;
    }
  },
};
export default entryLaunchMixin;
