ALTA<template>
  <div  class="contract-proposal-create-page">
    <AppFixedPageTitle
      :title="
        customerConstructionParams?.byCreateTemporaryCustomer ? `NOVA PROPOSTA` : `NOVO CONTRATO`
      "
      icon="/img/icons/icons8/ios/briefcase.png"
    />

    <div class="container-fluid py-4">
      <div class="page-header d-flex flex-column align-items-start">
        <div class="contract-info mb-3" v-if="customerConstructionParams && !loadingCustomerName">
          <h3 class="d-block">
            CLIENTE/OBRA:
            <span v-if="entity">
              {{ customerName }}
            </span>
            <span v-else>
              {{ customerConstructionParams.customer_name }}
            </span>
          </h3>
        </div>

        <div class="contract-info mb-3 mt-n3" v-if="construction && entity">
          <h3 class="d-block">
            CÓDIGO:
            <span> {{ code }} </span>
          </h3>
          <h3 class="d-block">
            CLIENTE:
            <span>
              {{ entity?.entity_name }}
            </span>
          </h3>
          <h3 class="d-block">
            OBRA:
            <span>
              {{ construction?.construction_name }}
            </span>
          </h3>
        </div>

        <div class="header-buttons">
          <AppPageHeaderActions>
            <AppPageHeaderActionsButton
              type="light"
              @click="goBackToTargetPage"
              text="voltar"
              icon="/img/icons/circled-left.png"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              v-if="
                !construction &&
                (customerConstructionParams && !customerConstructionParams.byCreateTemporaryCustomer)
              "
              type="primary"
              @click="openModalSearchEntityAndConstruction(customerConstructionParams)"
              icon="/img/icons/icons8/ios/search.png"
              text="Procurar"
            >
            </AppPageHeaderActionsButton>
          </AppPageHeaderActions>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <validation-observer
        v-slot="{ invalid, handleSubmit }"
        ref="formValidator"
      >

        <div class="row">
          <!-- CARD CLIENTE -->
          <div class="col-md-6 pr-md-2 "
               v-if="!customerConstructionParams || !customerConstructionParams.byCreateTemporaryCustomer"
          >
            <ContractProposalEditCard
              title="Cliente"
              icon="business-building"
              color="success"
              class="reset-input-css"
            >
              <template #card-header-buttons>
                <a class="app-button mr-1"
                   @click.prevent="handleShowEntityAttachments(
                entity.id,
                entity.entity_name,
                entity.document
              )"
                >
                  <AppIcon icon="attach" color="success" width="12px"
                  />
                  anexos
                  <span
                    style="font-size: 12px; color: #2B2D32;"
                    v-if="totalEntityAttachments > 0"
                  >
                {{ totalEntityAttachments }}
              </span>
                </a>
                <a
                  class="app-button"
                  v-if="!loadingEntityAttachments && entity"
                  @click="openModalEditEntity(entity.uuid)"
                >
                  <AppIcon icon="create" color="warning" width="12px"/>
                  editar
                </a>
                <a
                  class="app-button"
                  v-if="!loadingEntityAttachments && !entity"
                  @click.prevent="openModalCreateEntity(construction)"
                >
                  <AppIcon icon="plus-math" color="success" width="12px" />
                  Cadastrar
                </a>
              </template>

              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Nome
                </label>
                <div class="col-md-7">
              <span
                class="form-control-sm text-truncate"
              >
                {{ entity?.entity_name }}
              </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  CPF/CNPJ
                </label>
                <div class="col-md-7">
            <span class="form-control-sm">
              {{ entity?.document }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Logradouro
                </label>
                <div class="col-md-7">
            <span
              class="form-control form-control-sm text-truncate"
            >
              {{ entity?.address
              ? (entity.address.address || '') + ', ' + (entity.address.number || '')
              : ''
              }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Bairro
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ entity?.address?.district }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Município
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ entity?.address?.city }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  uf
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ entity?.address?.state }}
            </span>
                </div>
              </div>

            </ContractProposalEditCard>
          </div>
          <!-- CARD OBRA -->
          <div class="col-md-6 pl-md-2">
            <ContractProposalEditCard
              title="Obra"
              icon="brick-wall"
              color="warning"
              class="reset-input-css"
            >

              <template #card-header-buttons>
                <a
                  v-if="construction"
                  @click.prevent="handleListContacts"
                  class="app-button mr-1"
                >
                  <AppIcon icon="contact" color="warning" width="12px" />
                  contatos
                </a>
                <a class="app-button mr-1"
                   @click.prevent="handleShowConstructionAttachments(construction.id, construction.construction_name)"
                   v-if="!loadingConstructionAttachments"
                >
                  <AppIcon icon="attach" color="success" width="12px"/>
                  anexos
                  <span
                    style="font-size: 12px; color: #2B2D32;"
                    v-if="totalConstructionAttachments > 0"
                  >
                  {{ totalConstructionAttachments }}
                </span>
                </a>
                <a class="app-button"
                   v-if="!loadingConstructionAttachments && construction"
                   @click="openModalEditConstruction(construction.uuid)"
                >
                  <AppIcon icon="create" color="warning" width="12px"/>
                  editar
                </a>
                <a
                  class="app-button"
                  v-if="!loadingConstructionAttachments && !construction"
                  @click.prevent="handleShowModalCustomerConstruction(entity)"
                >
                  <AppIcon icon="plus-math" color="success" width="12px"/>
                  Cadastrar
                </a>
              </template>

              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Nome
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ (construction?.construction_name) }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Central
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{
                construction?.plant?.name
              }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Logradouro
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{
                construction?.default_address
                  ? (construction.default_address.address || '') + ', ' + (construction.default_address.number || '')
                  : ''
              }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Bairro
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ construction?.default_address?.district }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Município
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ construction?.default_address?.city }}
            </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  uf
                </label>
                <div class="col-md-7">
            <span class="form-control form-control-sm">
              {{ construction?.default_address?.state }}
            </span>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
        </div>
        <!-- CARD CONTRATO -->
        <ContractProposalEditCard
          v-if="shouldDisplayDiv"
          title="contrato"
          icon="briefcase"
          color="success"
          class="reset-input-css contract-card"
        >
          <template  #contract-input>
          <span class="ml-0 ml-md-3">
            <validation-provider rules="required"
                                 v-slot="{errors}"
                                 immediate
            >
              <base-input input-classes="form-control-sm"
                          :class="{ 'is-invalid': errors.length}"
              >
                <puzl-select
                  v-model="contract_proposal.contract_uuid"
                  :items="contracts"
                  customKey="uuid"
                  :loading="loadingContract"
                  :disabled="loadingContract"/>
              </base-input>
            </validation-provider>
          </span>
          </template>
          <template  #card-header-buttons>
            <el-popover width="365" trigger="click" placement="left">
              <div class="row rules-popover reset-input-css justify-content-between">
                <label class="popover-title col-12 px-3">
                  REGRAS ESPECIAIS
                </label>
                <div class="pl-3 mb-4 d-flex flex-column justify-content-center align-items-center "
                     @click="validPermissionCanIncludeReidi"
                >
                  <label class="popover-label">
                    REIDI
                  </label>
                  <AppSwitch
                    v-model="contract_proposal.reidi"
                    :disabled="!$_financial_limit.invoice_can_include_reidi"
                  >
                  </AppSwitch>
                </div>
                <div class="pr-3 mb-4 d-flex flex-column justify-content-center align-items-center">
                  <label class="popover-label">
                    Fixar retenção de iss
                  </label>
                  <div class="d-flex">
                    <AppSwitch
                      @click="handleOwedIss"
                      v-model="contract_proposal.iss_retain_fixed"
                      class="mr-1"
                    >
                    </AppSwitch>
                    <AppSwitch
                      v-if="contract_proposal.iss_retain_fixed"
                      v-model="contract_proposal.iss_retain_fixed_retained"
                      style="min-width: 115px;"
                      :toogleLayout="{
                      off: {
                        iconColor: 'muted',
                        backgroundColor: '#E8E8E8',
                        icon: 'chemical-plant',
                        label: 'ISS DEVIDO',
                        labelColor: '#606062'
                      },
                      on:
                      {
                        iconColor: 'danger',
                        backgroundColor: '#FCF3F3',
                        icon: 'shutdown',
                        label: 'ISS RETIDO',
                        labelColor: '#DB4539'
                      }
                    }"
                    >
                    </AppSwitch>
                  </div>
                </div>
                <div class="col-12 mb-1 d-flex align-items-center justify-content-between">
                  <label class="popover-label">
                    FIXAR CNPJ EMISSOR?
                  </label>
                  <AppSwitch
                    v-model="contract_proposal.issuer_fixed"
                    @input="validIssuerFixed"
                  >
                  </AppSwitch>
                </div>
                <div class="col-12 mb-4">
                  <PuzlSelect
                    @change="getBankAccounts()"
                    :disabled="!contract_proposal.issuer_fixed"
                    :label="'business_name'"
                    v-model="contract_proposal.company_plant_issuer_id_fixed"
                    :items="company_plant_issuers"
                    style="min-height: 32px;"
                  />
                </div>
                <div class="col-12">
                  <label class="popover-label">
                    INSC. ESTADUAL
                  </label>
                </div>
                <div class="col-12 pb-1">
                  <input
                    v-model="contract_proposal.state_registration"
                    class="form-control form-control-sm"/>
                </div>
              </div>
              <div class="app-button"
                   slot="reference"
              >
                <AppIcon icon="star-filled" color="primary" />
                REGRAS ESPECIAIS
              </div>
            </el-popover>
          </template>

          <div class="app-form-group app-row row">
            <div class="col-md-4 pr-md-2">
              <label class="mb-2 col-form-label form-control-label">
                Central
              </label>
              <validation-provider rules="required"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input class="form-control-dropdown"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <PuzlSelect
                    v-model="contract_proposal.company_plant_id"
                    :items="plants"
                    @change="setISS()"
                    @input="getValidityPriceByPlant();contract_proposal.company_plant_issuer_id_fixed = null"
                    :loading="loadingPlant"
                    :disabled="loadingPlant"
                  />
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 px-md-2">
              <label class="mb-2  col-form-label form-control-label">
                Vendedor
              </label>
              <validation-provider rules="required"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input input-classes="form-control-sm"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <PuzlSelect
                    v-model="contract_proposal.seller"
                    customKey="uuid"
                    :items="users"
                    :loading="loadingUsers"
                    :disabled="loadingUsers"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 pl-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Volume médio por BT
                <span v-if="contract_proposal.average_volume_per_bt < 3">
                  <i class="fas fa-exclamation-triangle text-danger ml-1 mr-1"></i>
                  <small class="text-dark" style="color: #7e7e7e;font-size: 14px;">Valor mínimo: 3</small>
                </span>
              </label>
              <validation-provider rules="required|min_value:3"
                                   v-slot="{errors}"
                                   immediate
              >
                <input-limit
                  :class="{ 'is-invalid': errors.length}"
                  class="input-limit"
                  :disabled="[5,0].includes(contract_proposal.status)"
                  :options="{
                    min: 0,
                    max: 49.9,
                    decimals: 1
                  }"
                  :start_value="contract_proposal.average_volume_per_bt"
                  v-model="contract_proposal.average_volume_per_bt"
                  @value="contract_proposal.average_volume_per_bt = $event"
                >
                  <!-- <small class="input-group-sm p-0 m-0">
                    m<sup>3</sup>
                  </small> -->
                </input-limit>
              </validation-provider>
            </div>
          </div>

          <div class="app-form-group app-row row">
            <div class="col-md-4 pr-md-2">
              <label class="pb-2 col-form-label form-control-label">
                CNO
              </label>
              <validation-provider
                :rules="entity && is_cno_required_prop_contracts ? 'required|min:14' : 'min:14'"
                v-slot="{errors}"
                immediate
              >
                <base-input input-classes="form-control-sm"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <input
                    class="form-control form-control-sm"
                    maxlength="15"
                    v-mask="'##.###.#####/##'"
                    v-model="contract_proposal.cno"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 p-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Código de obra
              </label>
              <validation-provider
                :rules="
                entity && is_municipal_work_code_required_prop_contracts
                  ? 'required' : ''
              "
                v-slot="{errors}"
                immediate
              >
                <base-input input-group-classes="input-group-sm"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <input class="form-control form-control-sm"
                         v-model="contract_proposal.city_hall_construction_code"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 pl-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Art/nº de controle do encapsulamento
              </label>
              <validation-provider :rules="entity && is_art_encapsulation_required && 'required' || ''"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input input-group-classes="input-group-sm"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <input class="form-control form-control-sm" v-model="contract_proposal.art_code"/>
                </base-input>
              </validation-provider>
            </div>
          </div>
        </ContractProposalEditCard>

        <div class="row">
          <!-- CARD FATURAMENTO -->
          <div class="col-md-6 pr-md-2"
               v-if="shouldDisplayDiv"
          >
            <ContractProposalEditCard
              title="faturamento"
              icon="cheque"
              color="primary"
              class="reset-input-css invoice-card"
            >

              <template #card-header-buttons>
                <div class="d-flex justify-content-center align-items-center flex-column flex-md-row"
                     style="gap: 8px"
                >
                <span class="new-default-black-font">
                  POR MEDIÇÃO
                </span>
                  <AppSwitch
                    v-model="contract_proposal.measurement"
                  >
                  </AppSwitch>
                </div>
              </template>

              <div class="form-group row invoice-form-group">
                <!-- <div class="col-md-12 mb-2" v-if="contract_proposal.necessary_approvation">
                  <div class="col-form-label form-control-label">
                    Aprovação financeira
                  </div>
                  <base-button
                    :class="contract_proposal.approvation === 1 && 'active'"
                    :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                    :title="!$hasPermission('additional_permissions.financial_approvation') ? 'Sem permissão para alterar' : ''"
                    type="success"
                    size="sm"
                    @click.prevent="contract_proposal.approvation = 1"
                    outline
                  >
                    Aprovado
                  </base-button>
                  <base-button
                    :class="contract_proposal.approvation === 2 && 'active'"
                    :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                    :title="!$hasPermission('additional_permissions.financial_approvation') ? 'Sem permissão para alterar' : ''"
                    type="danger"
                    size="sm"
                    @click.prevent="contract_proposal.approvation = 2"
                    outline
                  >
                    Reprovado
                  </base-button>
                  <br>
                  <span v-if="contract_proposal.approvation_user">
                    Revisado por: <strong>{{ contract_proposal.approvation_user }}</strong>
                  </span>
                  <span v-if="contract_proposal.approvation === 1 && !contract_proposal.approvation_user">
                    Aprovado automaticamente pelo sistema.
                  </span>
                </div> -->

                <div class="col-md-6 mb-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Métodos de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm"
                                :class="{ 'is-invalid': errors.length}"
                    >
                      <PuzlSelect
                        v-model="contract_proposal.payment_methods_ids"
                        :items="payment_methods"
                        :multiple="true"
                        :loading="loadingMethod"
                        @input="setPaymentTermsByMethod()"/>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6 mb-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Condições de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm"
                                :class="{ 'is-invalid': errors.length}"
                    >
                      <PuzlSelect
                        v-model="contract_proposal.payment_terms_uuids"
                        :items="payment_terms_by_principal"
                        customKey="uuid"
                        :multiple="true"
                        @input="setMethods"
                        :loading="loadingPaymentTerm"
                        @change="setAll()"
                        :disabled="loadingPaymentTerm || !contract_proposal.payment_methods_ids.length"/>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6 mt-2">
                  <label class="pb-2 col-form-label form-control-label">
                    Obs. para nota fiscal de serviço
                  </label>
                  <div
                    class="mb-1"
                    v-if="entity?.is_nfse_observations_required"
                  >
                    <a href="#" class="text-dark" @click.prevent="SetServiceInvoiceNotes()">
                      <b style="font-size: 12px"> Número de empenho: </b>
                      <span
                        class="badge mr-1 ml-1"
                        style="background-color: #ffff; border: 1px solid #ccc; padding: 4px; border-radius: 4px;"
                      >
                      <i style="font-size: 15px" class="zoom pointer fa-regular fa-clipboard text-dark"></i>
                      <span style="font-size: 10px" class="font-weight-500">
                        Preencher
                      </span>
                    </span>
                    </a>
                  </div>
                  <validation-provider
                    :rules="entity?.is_nfse_observations_required ? 'required' : ''"
                    v-slot="{errors}"
                    immediate
                  >
                    <base-input input-classes="form-control-sm" >
                    <textarea
                      :class="{ 'is-invalid': errors.length}"
                      v-model="contract_proposal.observation"
                      maxlength="400"
                      class="form-control"
                      rows="2"
                      style="resize: none; height: 121px;"
                      placeholder="..."
                    />
                      <small class="text-muted">
                        {{ (contract_proposal.observation ? contract_proposal.observation.length : 0) }}/400
                      </small>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mt-2">
                  <label class="pb-2 custom-spacer col-form-label form-control-label"
                         :style="entity?.is_nfse_observations_required
                    ? 'margin-bottom: 29px !important;'
                    : ''"
                  >
                    obs. para o faturamento
                  </label>
                  <base-input input-classes="form-control-sm">
                  <textarea
                    v-model="contract_proposal.billing_guidelines"
                    maxlength="400"
                    class="form-control"
                    rows="2"
                    style="resize: none; height: 121px;"
                    placeholder="..."
                  />
                    <small class="text-muted">
                      {{ (contract_proposal.billing_guidelines ? contract_proposal.billing_guidelines.length : 0) }}/400
                    </small>
                  </base-input>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
          <!-- CARD AUTOMATIZAÇÃO -->
          <div class="col-md-6 pr-md-2">
            <ContractProposalEditCard
              v-if="contract_proposal.payment_methods_ids.length && contract_proposal.payment_terms_uuids.length"
              title="Automatização"
              icon="bot"
              color="danger"
              class="reset-input-css automation-card"
              :style="minHeightStyle"
            >

              <div
                class="row justify-content-between align-items-center badge-wrapper"
                v-if="construction && contract_proposal.payment_terms_uuids.length">
                <div class="d-flex flex-column align-items-center col-md-3 mb-4"
                >
                  <label>
                    NF-E
                  </label>
                  <AppSwitch
                    @input="setNfse"
                    v-model="contract_proposal.automatic_nfe"
                  >
                  </AppSwitch>
                </div>
                <div class="d-flex flex-column align-items-center col-md-3 mb-4">
                  <label>
                    NFS-E
                  </label>
                  <AppSwitch
                    @input="unsetIssRetain()"
                    v-model="contract_proposal.automatic_nfse"
                  >
                  </AppSwitch>
                </div>
                <div class="d-flex flex-column align-items-center col-md-3 mb-4">
                  <label>
                    Faturamento
                  </label>
                  <AppSwitch
                    @input="setAll()"
                    v-model="contract_proposal.automatic_billing"
                    :disabled="loadingGeneralSettings"
                  >
                  </AppSwitch>
                </div>
                <div
                  class="d-flex flex-column align-items-center col-md-3 mb-4"
                >
                  <label v-if="contract_proposal.automatic_billing">
                    boleto
                  </label>
                  <AppSwitch
                    @input="setTicket(contract_proposal.billing.automatic_ticket)"
                    v-model="contract_proposal.billing.automatic_ticket"
                    :disabled="!contract_proposal.automatic_billing"
                    v-if="contract_proposal.automatic_billing"
                  >
                  </AppSwitch>
                </div>
              </div>

              <div v-if="contract_proposal.automatic_billing && contract_proposal.payment_terms_uuids.length " class="row">
                <div class="col-md-6 pb-3 pr-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Método de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.payment_method_id"
                        :items="Payment_methods"
                        @input="getPaymentTerms()"
                        @change="getPaymentIntermediaries()"
                        :disabled="loadingMethod || contract_proposal.billing.automatic_ticket || !contract_proposal.payment_terms_uuids.length"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pl-md-2">
                  <label class="mb-2col-form-label form-control-label">
                    Condição de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.payment_term_id"
                        :items="filteredPaymentTermsByMethod"
                        @input="setRange()"
                        :disabled="loadingPaymentTerm || !contract_proposal.billing.payment_method_id"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pr-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Conta bancária
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.bank_account_id"
                        :items="bank_accounts"
                        :label="['bank_account', 'name']"
                        :recursiveKey="['bank_account', 'id']"
                        :labelMask="true"
                        :loading="loadingBankAccounts"
                        :disabled="[5,0].includes(contract_proposal.status)"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pl-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Frequência do Faturamento
                  </label>
                  <div class="d-flex justify-content-center align-items-center" style="gap: 4px;">
                    <div class="frequency-button"
                         @click.prevent="contract_proposal.billing.range = 1;frequency_selecteds = []"
                         :class="{'is-active': contract_proposal.billing.range === 1 }"
                    >
                      DIÁRIO
                    </div>
                    <div class="frequency-button"
                         :class="{'is-active': contract_proposal.billing.range === 2 }"
                         @click.prevent="contract_proposal.billing.range = 2;frequency_selecteds = []"
                    >
                      SEMANA
                    </div>
                    <div class="frequency-button"
                         :class="{'is-active': contract_proposal.billing.range === 3 }"
                         @click.prevent="contract_proposal.billing.range = 3;frequency_selecteds = []"
                    >
                      MÊS
                    </div>
                  </div>

                  <div class="date-picker-wrapper" v-if="[2,3].includes(contract_proposal.billing.range)">
                    <template v-if="contract_proposal.billing.range === 2">
                      <table>
                        <thead>
                        <tr>
                          <th v-for="item in weekDays.slice(0, 4)">
                          <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                              item.name
                            }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in weekDays.slice(4, 7)">
                          <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                              item.name
                            }}</span>
                          </th>
                        </tr>
                        </thead>
                      </table>
                    </template>
                    <template v-if="contract_proposal.billing.range === 3">
                      <table>
                        <thead>
                        <tr>
                          <th v-for="item in generateRange(1, 7)">
                          <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                              item
                            }}</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th v-for="item in generateRange(8, 14)">
                          <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                              item
                            }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(15, 21)">
                          <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                              item
                            }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(22, 28)">
                          <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                              item
                            }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(29, 31)">
                          <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                              item
                            }}</span>
                          </th>
                        </tr>
                        </tbody>
                      </table>
                    </template>
                  </div>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center justify-content-md-end align-items-center"
               style="gap: 8px;"
          >
            <AppButton
              :hasOutline="true"
              @click="goBackToTargetPage"
              label="Cancelar"
              type="danger"
            />
            <AppButton
              @click.prevent="handleSaveAndActive"
              label="Salvar e ativar"
              type="success"
              :disabled="saving || invalid || ($route.query.fromContractScreen == 'true' && (!construction || !entity))"
              :isLoading="loadingSave"
            />
          </div>
        </div>
      </validation-observer>
    </div>
    <ModalChangeStatus ref="changeStatus"/>
    <ModalSearchCpfCnpj ref="searchCpfCnpj" @set_entity="setEntity"/>
    <ModalListContacts ref="listContacts" />
    <ModalCreateContacts ref="createContacts" />
    <ModalCreateEntity
      @set_entity="setEntity"
      @set_entity_name="updateCustomerNameAfterEntityRegistration"
      ref="createEntity"
    />
    <ModalCreateCustomerConstruction
      @set_construction="setConstruction"
      ref="CreateCustomerConstruction"
      :entityProps="entityProps"
    />
    <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="updatedTotalConstructionAttachmentAfterUpload"
      @updateTotalAttachmentAfterDeletion="updateTotalConstructionAttachmentAfterDeletion"/>
    <ModalEntityAttachmentList
      ref="modalEntityAttachmentList"
      @storedAttachment="updatedTotalEntityAttachmentAfterUpload"
      @deletedLastAttachment="updateTotalEntityAttachmentAfterDeletion"/>
    <ModalEditEntity @updatedEntity="updateEntityAfterEdit" ref="modalEditEntity"/>
    <ModalEditConstruction @updatedConstruction="updateConstructionAfterEdit" ref="modalEditConstruction"/>
    <ModalSearchCustomerConstruction @setConstruction="setConstruction" ref="modalSearchCustomerConstruction"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {onlyNumbers} from "@/helpers";
//Codigo da totvs não sera mais usado
// import {construction as $_totvs} from "@/plugins/microservices/totvs";
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton, AppSwitch, AppButton } from "../../../../../components/AppGlobal";
import ContractProposalEditCard from "../components/ContractProposalEditCard/ContractProposalEditCard.vue";
import inputLimit from "@/components/Utils/InputLimit";
import SpinnerPuzl from "@/components/SpinnerPuzl";
import PuzlSelect from "@/components/PuzlSelect";
import ModalChangeStatus from "./_ModalChangeStatus";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ModalSearchCpfCnpj from "./_ModalSearchCpfCnpj";
import ModalSearchCustomerConstruction from './_ModalSearchCustomerConstruction';
import ModalEditConstruction from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_Edit";
import ModalConstructionAttachmentList
  from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList";
import ModalCreateEntity from "@/views/Modules/Configuration/Entity/Shared/_Create";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import ModalCreateCustomerConstruction from './_ModalCreateCustomerConstruction';
import ModalListContacts from  "./Contacts/_ModalListContacts.vue";
import ModalCreateContacts from "./Contacts/_ModalCreateContacts.vue";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  name: "CreateContractProposal",
  components: {
    ModalChangeStatus,
    ModalListContacts,
    ModalSearchCpfCnpj,
    ModalCreateEntity,
    ModalCreateCustomerConstruction,
    PuzlSelect,
    inputLimit,
    ModalConstructionAttachmentList,
    ModalEntityAttachmentList,
    ModalEditEntity,
    ModalEditConstruction,
    SpinnerPuzl,
    ModalSearchCustomerConstruction,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppSwitch,
    ContractProposalEditCard,
    AppButton,
    AppIcon,
    ModalCreateContacts,
  },
  data() {
    return {
      block_iss: false,
      loadingPlant: true,
      loadingConstruction: false,
      loadingContract: true,
      payment_intermediaries_exists: false,
      payment_terms_by_method: [],
      loadingPaymentTerm: true,
      loadingUsers: true,
      loadingCode: true,
      loadingSave: false,
      Payment_methods: [],
      loadingBankAccounts: false,
      bank_accounts: [],
      loadingCompanyPlantIssuer: false,
      loadingPaymentIntermediaries: false,
      invalid: false,
      loadingMethod: false,
      construction: null,
      contractInfo: null,
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      entity: null,
      entityProps: null,
      entity_is_company: false,
      without_fields: true,
      loadingGeneralSettings: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      payment_terms_by_principal: [],
      frequency_selecteds: [],
      weekDays: [
        {
          id: 0,
          name: 'DOM',
        },
        {
          id: 1,
          name: 'SEG',
        },
        {
          id: 2,
          name: 'TER',
        },
        {
          id: 3,
          name: 'QUA',
        },
        {
          id: 4,
          name: 'QUI',
        },
        {
          id: 5,
          name: 'SEX',
        },
        {
          id: 5,
          name: 'SAB',
        },
      ],
      payment_intemerdiaries_selected: [],
      saving: false,
      contract_proposal: {
        company_plant_id: null,
        company_plant_issuer_id_fixed: null,
        issuer_fixed: false,
        price_validity: null,
        measurement: 0,
        customer_construction_uuid: null,
        seller: '',
        reidi: false,
        state_registration: null,
        observation: '',
        billing_guidelines: '',
        billing: {
          automatic_nfe: false,
          automatic_nfse: false,
          automatic_ticket: false,
          range: '',
          bank_account_id: '',
          emails: '',
          company_plant_id: '',
          payment_term_id: '',
          payment_method_id: '',
          payment_intermediary_id: '',
          company_plant_issuer_id: '',
        },
        entity_uuid: null,
        entity_id: null,
        average_volume_per_bt: '8,0',
        contract_uuid: null,
        payment_terms_uuids: [],
        payment_methods_ids: [],
        status: 2,
        code: null,
        automatic_billing: false,
        automatic_nfe: true,
        automatic_nfse: true,
        iss_retain: false,
        city_hall_construction_code: '',
        art_code: '',
      },
      disablePriceValidity: false,
      is_cno_required_prop_contracts: false,
      is_municipal_work_code_required_prop_contracts: false,
      is_art_encapsulation_required: false,
      totalConstructionAttachments: null,
      totalEntityAttachments: null,
      loadingConstructionAttachments: true,
      loadingEntityAttachments: true,
      isRequiredConstructionAttachment: false,
      isHideEntityCard: false,
      isHideConstructionCard: false,
      customerConstructionParams: null,
      constructionParams: null,
      customerName: null,
      loadingCustomerName: false,
      ContractProposalEnum: ContractProposalEnum,
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      company_plant_issuers: "plant/getCompanyPlantIssuers",
      code: "contractProposal/getMaxCode",
      price_validity: "contractProposal/getPriceValidity",
      $_bank_accounts: 'bankAccount/fetch',
      users: "user/fetch",
      contracts: 'contractContracts/fetch',
      payment_terms: "paymentTerm/fetch",
      payment_methods: "paymentMethod/fetchUsable",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_financial_limit: "permissionFinancialLimit/show",
      $_general_settings: "generalSetting/show",
    }),
    filteredPaymentTermsByMethod() {
      return this.payment_terms_by_method.filter((item) => this.contract_proposal.payment_terms_uuids.includes(item.uuid));
    },
    /**
     * Ao realizar cadastro com cliente/obra temporário, apenas os dados da obra devem ser preenchidos.
     * Caso for realizar o contrato completo, dados de obra e tomador devem ser preeenchidos,
     * para liberar o restante do formulário.
     */
    shouldDisplayDiv() {
      // (query params)
      if (this.customerConstructionParams) {
        if (this.customerConstructionParams.byCreateTemporaryCustomer) {
          return this.construction;
        } else {
          return this.entity && this.construction;
        }
      } else {
        return this.entity && this.construction;
      }
    },
    minHeightStyle(){
      if(this.entity?.is_nfse_observations_required && this.contract_proposal.payment_methods_ids.length ){
        return {minHeight: '425px'}
      } else if (!this.entity?.is_nfse_observations_required && this.contract_proposal.payment_methods_ids.length ){
        return {minHeight: "397px"}
      } else {
        return {}
      }
    },
  },
  watch: {
    'contract_proposal.company_plant_id': async function (val) {
      this.bank_accounts = []
      this.contract_proposal.billing.bank_account_id = null
      await this.getCompanyPlantIssuers()
      if (!this.$_general_settings.show_bank_account_by_issuer) this.getBankAccounts(val)
    },
  },
  methods: {
    getBankAccounts(set = true, typeableId = null) {
      if (set) {
        this.contract_proposal.billing.bank_account_id = null
      }
      this.loadingBankAccounts = true;
      let typeId = typeableId
      if (!typeableId) {
        if (this.$_general_settings.show_bank_account_by_issuer) {
          if (this.contract_proposal.company_plant_issuer_id_fixed) {
            typeId = this.contract_proposal.company_plant_issuer_id_fixed
          } else {
            typeId = this.company_plant_issuers.find((item) => item.default)?.id
          }
        } else {
          typeId = this.contract_proposal.company_plant_id
        }
      }
      if (!typeId) {
        this.loadingBankAccounts = false
        return this.bank_accounts = []
      }
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: typeId,
          })
        .then((response) => {
          this.loadingBankAccounts = false;
          this.bank_accounts = response.data;
          if (!this.$_general_settings.show_bank_account_by_issuer) {
            const company_plant = this.plants.find(
              (item) => item.id === this.contract_proposal.company_plant_id
            );
            if (set) {
              this.contract_proposal.billing.bank_account_id = company_plant.bank_account_id
            }
          } else {
            this.contract_proposal.billing.bank_account_id = this.bank_accounts.find((item) => item.default).bank_account_id
          }
        }).finally(() => {
          this.loadingBankAccounts = false
      })
    },

    goBackToTargetPage(){
      if(this.customerConstructionParams?.byCreateTemporaryCustomer){
        this.$router.push('/commercial/proposal')
      }else{
        this.$router.push('/commercial/contract')
      }
    },
    handleOwedIss(){
      if(this.contract_proposal.iss_retain_fixed){
        this.contract_proposal.iss_retain_fixed_retained = false;
      }
    },
    generateRange(start, end) {
      return Array.from({length: (end - start + 1)}, (_, index) => start + index);
    },
    setFrequency(item) {
      if (this.frequency_selecteds.includes(item)) {
        const index = this.frequency_selecteds.indexOf(item);
        if (index !== -1) {
          this.frequency_selecteds.splice(index, 1);
        }
      } else {
        this.frequency_selecteds.push(item)
      }
    },
    async validIssuerFixed() {
      if (!this.contract_proposal.issuer_fixed) {
        this.contract_proposal.company_plant_issuer_id_fixed = null;
        if (this.$_general_settings.show_bank_account_by_issuer) {
          await this.getCompanyPlantIssuers()
          if (!this.$_general_settings.show_bank_account_by_issuer) this.getBankAccounts(val)
        }
      } else {
        const default_plant_issuer = this.company_plant_issuers.find((item) => item.default);
        if (default_plant_issuer) {
          this.$_general_settings.show_bank_account_by_issuer && this.getBankAccounts(default_plant_issuer.id)
          return this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
      }
    },
    validPermissionCanIncludeReidi() {
      if (!this.$_financial_limit.invoice_can_include_reidi) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para realizar alteração'
        });
      }
    },
    setAll() {
      if (!this.contract_proposal.automatic_billing) {
        return
      }
      if (this.Payment_methods.length === 1) {
        this.contract_proposal.billing.payment_method_id = this.Payment_methods[0].id
        this.getPaymentTerms()
        this.getPaymentIntermediaries()
      }
    },
    unsetIssRetain() {
      if (!this.contract_proposal.automatic_nfse) {
        this.contract_proposal.iss_retain = false
      }
    },
    rangeOffTypes(range_off, type) {
      if (range_off > 1) {
        if (type === 0) {
          return range_off + ' dias'
        } else {
          return range_off + ' horas'
        }
      }
      if (type === 0) {
        return range_off + ' dia'
      } else {
        return range_off + ' hora'
      }
    },
    setISS() {
      const plant = this.plants.find((item) => item.id === this.contract_proposal.company_plant_id);
      if (plant) {
        this.checkPlantGeneralSettings(plant.uuid);
        const default_plant_issuer = plant.company_issuers.find((item) => item.default);
        if (!default_plant_issuer || !this.entity) {
          return this.contract_proposal.iss_retain = false
        }
        this.$store.dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuer", {
          entityId: this.entity.id, issuerId: default_plant_issuer.id
        }).then(response => {
          if (!this.block_iss) {
            if (!response.data.length) {
              this.contract_proposal.iss_retain = false
            } else {
              const has_issqn = response.data.some((item) => item.name = 'ISSQN')
              if (has_issqn) {
                this.contract_proposal.iss_retain = true
              }
            }
          }
        })
      }
    },
    setRange() {
      const term = this.payment_terms_by_method.find((item) => item.id === this.contract_proposal.billing.payment_term_id)
      this.range_frequency_options[3] = {
        id: 4,
        name: this.rangeOffTypes(term.range_off, term.range_off_type)
      }
      this.contract_proposal.billing.range = term.range
      this.contract_proposal.billing.range_off = term.range_off
      this.contract_proposal.billing.range_off_type = term.range_off_type
    },
    setPaymentTermsByMethod() {
      this.contract_proposal.billing.range = null
      this.contract_proposal.payment_terms_uuids = []
      this.contract_proposal.billing.payment_intermediary_id = null
      let payment_terms = []
      if (this.entity_is_company) {
        payment_terms = this.payment_terms.filter((item) => item.legal_person)
      } else {
        payment_terms = this.payment_terms.filter((item) => item.physical_person)
      }
      this.payment_terms_by_principal = payment_terms.filter((item) => {
        return item.allow_in_proposal && item.payment_methods.some(r => this.contract_proposal.payment_methods_ids.includes(r.id))
      })
    },
    setMethods() {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
      this.contract_proposal.billing.payment_method_id = null
      this.Payment_methods = this.Payment_methods.filter((item) => this.contract_proposal.payment_methods_ids.includes(item.id))
      this.getPaymentTerms()
    },
    setNfse() {
      if (!this.contract_proposal.automatic_nfe) {
        this.contract_proposal.automatic_nfse = false
        this.unsetIssRetain()
      }
    },
    updateConstruction() {
      this.loadingConstruction = true
      this.$store.dispatch('customerConstructionConstructions/show', this.construction.uuid).then(response => {
        this.construction.contact = response.data.contact
        this.loadingConstruction = false
      })
    },
    formatAccountLabel(item) {
      return item.name
    },
    getPaymentIntermediaries() {
      this.contract_proposal.billing.payment_intermediary_id = null
      this.payment_intemerdiaries_selected = []
    },
    getPaymentTerms() {
      this.contract_proposal.billing.range = null
      this.contract_proposal.billing.payment_term_id = null
      let paymentMethod = this.payment_methods.find(item => item.id === this.contract_proposal.billing.payment_method_id)
      if (!paymentMethod) {
        return
      }
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
      if (this.filteredPaymentTermsByMethod.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_term_id = this.filteredPaymentTermsByMethod[0].id
        this.setRange()
      }
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal);
    },
    /**
     * Abre modal de cadastro de ontatos (obra ou cliente)
     * @param {string} form_origin ('entity' ou 'construction')
     * @param {number} entity_id
     * @param {string} construction_uuid
     */
    handleCreateContacts(form_origin = null, entity_id = null, construction_uuid = null) {
      this.$refs.createContacts.openModal(
        form_origin,
        entity_id,
        construction_uuid
      );
    },
    getCompanyPlantIssuers() {
      this.loadingCompanyPlantIssuer = true
      this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
        id: this.contract_proposal.company_plant_id
      }).then(response => {
        const default_plant_issuer = response.data.find(item => item.default)
        this.$_general_settings.show_bank_account_by_issuer && default_plant_issuer && this.getBankAccounts(default_plant_issuer.id)
        if (default_plant_issuer && this.contract_proposal.issuer_fixed && set_plant_issuer && !this.contract_proposal.company_plant_issuer_id_fixed) {
          this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    /**
     * @param {object} entity
     */
    handleShowModalCustomerConstruction(entity) {
      let address_construction = entity && entity.address || null;

      this.$refs.CreateCustomerConstruction.openModal(
        address_construction,
        this.$route.query.customerConstruction ? JSON.parse(this.$route.query.customerConstruction) : null,
      );
    },
    handleSearchCpfCnpj() {
      this.$refs.searchCpfCnpj.handleSearchCpfCnpj();
    },
    handleChangeStatus(contractProposalUuid, status) {
      this.$refs.changeStatus.handleChangeStatus(contractProposalUuid, status);
    },
    /**
     * @param {object} construction
     */
    setConstruction(construction) {
      this.contract_proposal.billing.emails = []
      this.construction = construction;
      this.isHideConstructionCard = false;
      this.contract_proposal.customer_construction_uuid = construction.uuid;
      this.updateConstruction()
      this.$store.dispatch("entity/getEntityByDocument", {document: construction.customer_construction.document})
        .then((response) => {
          this.setEntity(response.data);
        });

      //Carrega total de anexos da obra selecionada associada ao contrato.
      this.loadingConstructionAttachments = true;
      this.$store.dispatch("constructionAttachment/getTotalAttachmentByConstructionId", this.construction.id)
        .then((response) => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
          this.loadingConstructionAttachments = false;
        });
      this.$refs.modalSearchCustomerConstruction.closeModal();

      /**
       * Após realizar o cadastro, verifica se a cidade da obra salva é igual
       * a cidade do endereço padrão do emissor de central (cnpj)
       */
      this.$store.dispatch('companyPlantIssuer/getIssuerCityByCompanyPlantId', construction.plant.id)
        .then(response => {
          if (response.data.company_plant_issuer_addres_city === construction.default_address.city) {
            /**
             * Se regra específica em tela (configuração de nfs-e - migrate):
             * 'informar código da obra' estiver ativo.
             */
            if (response.data.nfse_load_city_hall_construction_code) {
              /**
               * Preenche com o 'Cód. de obra da prefeitura' com o
               * 'Código padrão quando município da obra igual do emissor'
               */
              this.contract_proposal.city_hall_construction_code =
                response.data.standard_code_for_matching_municipalities;
            }
          } else {
            this.contract_proposal.city_hall_construction_code = '';
          }
        });
    },
    handleSave() {
      this.contract_proposal.status = 4
      this.loadingSave = true
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      this.$store.dispatch("contractProposal/add", this.contract_proposal).then(response => {
        //Codigo da totvs não sera mais usado
        // if (this.entity) {
        //   $_totvs.store({...this.construction, ...{entity_id: this.entity.id}});
        // }
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: response.error_type, message: response.message})
        this.$router.push('/commercial/contract-proposal/formulation/create/' + response.data.uuid)
      }).catch(error => {
        if (error.response && error.response.status && error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        this.$Progress.finish()
        this.loadingSave = false
      })
    },
    handleSaveAndActive() {
      if (this.saving) {
        return;
      }
      this.saving = true
      if (this.contract_proposal.billing.range && this.contract_proposal.billing.range !== 1 && !this.frequency_selecteds.length) {
        this.saving = false
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Informe a frequência de faturamento para continuar',
        });
      }
      if (this.entity) {
        if (this.customerConstructionParams && this.customerConstructionParams.document !== this.entity.document) {
          this.$notify({
            type: 'warning',
            message: 'Os documentos fornecidos para tomador e cliente/obra não são iguais. Por favor, verifique e tente novamente.”'
          });
          this.saving = false
          return;
        }

        if (this.constructionParams && this.constructionParams.customer_construction_document !== this.entity.document) {
          this.$notify({
            type: 'warning',
            message: 'Os documentos fornecidos para tomador e cliente/obra não são iguais. Por favor, verifique e tente novamente.”'
          });
          this.saving = false
          return;
        }
      }

      if (this.isRequiredConstructionAttachment && this.totalConstructionAttachments === 0) {
        this.$notify({
          type: 'warning',
          message: 'Anexe pelo menos um arquivo ao contrato/obra.'
        });
        this.saving = false
        return;
      }

      if (
        this.entity && (
          !this.entity.address ||
          !this.entity.address.postal_code ||
          !this.entity.address.state ||
          !this.entity.address.city ||
          !this.entity.address.address ||
          !this.entity.address.number ||
          !this.entity.address.district
        )
      ) {
        this.$notify({
          type: 'warning',
          message: 'Cadastre endereço para o tomador.'
        });
        this.saving = false
        return;
      }

      if (this.customerConstructionParams && this.customerConstructionParams.byCreateTemporaryCustomer) {
        this.contract_proposal.status = ContractProposalEnum.PROPOSAL;
      } else {
        this.contract_proposal.status = ContractProposalEnum.ACTIVE;
      }

      this.loadingSave = true
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.code = this.code
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      this.contract_proposal.invoice_frequency = {...this.frequency_selecteds}
      this.$store.dispatch("contractProposal/add", this.contract_proposal).then(response => {
        //Codigo da totvs não sera mais usado
        // if (this.entity) {
        //   $_totvs.store({...this.construction, ...{entity_id: this.entity.id}});
        // }
        this.loadingSave = false
        this.$Progress.finish()
        this.$notify({type: response.error_type, message: response.message})
        this.$router.push('/commercial/contract-proposal/formulation/create/' + response.data.uuid)
      }).catch(error => {
        if (error.response && error.response.status && error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        this.$Progress.finish()
        this.loadingSave = false
      }).finally(() => this.saving = false)
    },
    /**
     * @param {object} entity
     */
    setEntity(entity) {
      this.entity = entity;
      if (this.entity) {
        this.isHideEntityCard = false;
        const document_length = onlyNumbers(entity.document).length
        if (document_length <= 11) {
          this.contract_proposal.iss_retain = false
          this.block_iss = true
        } else {
          this.block_iss = false
        }
        this.contract_proposal.entity_uuid = entity.uuid;
        this.contract_proposal.entity_id = entity.id;
        this.$store.dispatch("customerConstruction/getCustomerByDocument", {document: entity.document}).then((response) => {
          if (this.entityProps) {
            this.entityProps = response.data;
          } else {
            this.entityProps = entity;
          }
        });

        //Carrega total de anexos de clientes/fornecedores, conforme a obra selecionada associada ao contrato.
        this.loadingEntityAttachments = true;
        this.$store.dispatch("entity/getTotalAttachmentsByEntityId", this.entity.id)
          .then((response) => {
            this.totalEntityAttachments = response.data.total_entity_attachments;
            this.loadingEntityAttachments = false;
          });
      }
    },
    SetServiceInvoiceNotes() {
      this.contract_proposal.observation = this.entity.default_text;
    },
    /**
     * Atualiza o nome do cliente de uma construção após o cadastro de um tomador,
     * caso o CPF/CNPJ do tomador já esteja cadastrado em cliente/obra.
     * @param {string} entityName
     */
    updateCustomerNameAfterEntityRegistration(entityName) {
      if (this.construction) {
        this.construction.customer_construction.customer_name = entityName;
      }
      if (this.$route.query.customerConstruction) {
        this.customerName = entityName;
      }
    },
    setTicket(val) {
      if (val) {
        this.contract_proposal.billing.payment_method_id = 5
        this.getPaymentTerms()
      }
    },
    getValidityPriceByPlant() {
      if (this.contract_proposal.company_plant_id) {
        this.$Progress.start()
        this.disablePriceValidity = true
        this.$store.dispatch("contractProposal/getPriceValidityByPlantId", this.contract_proposal.company_plant_id).then(response => {
          this.$Progress.finish()
          this.disablePriceValidity = false
          if (!this.contract_proposal.price_validity) {
            this.contract_proposal.price_validity = this.price_validity
          }
        })
      }
    },
    /**
     * Verifica as configurações gerais da central selecionada para deixar os campos
     * 'cno' e 'Cód. de obra da prefeitura' obrigatórios.
     * @param {string} company_plant_uuid
     */
    checkPlantGeneralSettings(company_plant_uuid) {
      this.$store.dispatch("plant/getGeneralSettings", company_plant_uuid).then((response) => {
        this.is_cno_required_prop_contracts = response.data.is_cno_required_prop_contracts;
        this.is_municipal_work_code_required_prop_contracts = response.data.is_municipal_work_code_required_prop_contracts;
        this.is_art_encapsulation_required = response.data.is_art_encapsulation_required;
      });
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */
    handleShowConstructionAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos de obras após realizar o upload.
     * @param {number} constructionId
     */
    updatedTotalConstructionAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
        });
    },
    /**
     * Atualiza total de anexos após deletar um anexo.
     */
    updateTotalConstructionAttachmentAfterDeletion() {
      this.totalConstructionAttachments--;
    },
    /**
     * Abre modal de listagem de anexos de clientes/fornecedores.
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowEntityAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * Atualiza total de anexos de clientes/fornecedores após realizar o upload.
     * @param {number} entityId
     */
    updatedTotalEntityAttachmentAfterUpload(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        this.totalEntityAttachments = response.data.total_entity_attachments;
      });
    },
    /**
     * Após excluir um anexo de clientes/fornecedores, é atualizado o total de arquivos anexados.
     */
    updateTotalEntityAttachmentAfterDeletion() {
      this.totalEntityAttachments--;
    },
    /**
     * @param {object} construction
     */
    openModalCreateEntity(construction) {
      const open = true;
      const customer = false;
      const params = null;
      const isDocumentDisabled = null;
      const customerConstruction = this.$route.query.customerConstruction ?
        JSON.parse(this.$route.query.customerConstruction) :
        JSON.parse(this.$route.query.construction);
      const construnctionAddress = construction ? construction.addresses[0] : null;

      this.$refs.createEntity.openModal(
        open,
        customer,
        params,
        isDocumentDisabled,
        customerConstruction,
        construnctionAddress
      );
    },
    /**
     * Abre modal de edição de cliente/fornecedor.
     * @param {string} entityUuid
     */
    openModalEditEntity(entityUuid) {
      this.$refs.modalEditEntity.openModal(true, entityUuid);
    },
    /**
     * Atualiza dados do cliente/fornecedor após edição na modal.
     * @param {object} entity
     */
    updateEntityAfterEdit(entity) {
      this.setEntity(entity);
      if (this.$route.query.customerConstruction) {
        this.customerName = entity.entity_name;
      }
    },
    /**
     * Abre modal de edição de obra.
     * @param {string} constructionUuid
     */
    openModalEditConstruction(constructionUuid) {
      this.$refs.modalEditConstruction.handleEditModal(constructionUuid)
    },
    /**
     * Atualiza dados do obra após edição na modal.
     * @param {object} construction
     */
    updateConstructionAfterEdit(construction) {
      this.setConstruction(construction);
    },
    /**
     * Abre modal incial do cadastro de propostas/contratos.
     * @param {object} customerConstruction
     */
    openModalSearchEntityAndConstruction(customerConstruction) {
      this.$refs.modalSearchCustomerConstruction.openModal(customerConstruction);
    },
    /**
     * Carrega dados do cliente/fornecedor e obra conforme os parâmetros da rota,
     * de acordo com a obra selecionada pela pesquisa do documento do cliente/obra.
     */
    loadEntityAndConstructionData() {
      let loader = this.$loading.show();
      this.constructionParams = JSON.parse(this.$route.query.construction);
      Promise.all([
        this.$store.dispatch("entity/getEntityByDocument", {document: this.constructionParams.customer_construction_document})
          .then((response) => {
            if (response.data) {
              this.setEntity(response.data);
            }
          }),
        this.$store.dispatch("customerConstructionConstructions/show", this.constructionParams.uuid)
          .then(response => {
            if (response.data) {
              this.setConstruction(response.data);
            }
          }),
      ]).then(() => {
        loader.hide();
      });
    },
    /**
     * Se o usuário não selecionou uma obra,
     * o sistema vai tentar preencher automaticamente os dados do tomador,
     * conforme o documento do cliente/obra recém cadastrado na tela anterior.
     */
    loadEntityForCustomerConstructionRoute() {
      let loader = this.$loading.show();
      this.loadingCustomerName = true;
      this.customerConstructionParams = JSON.parse(this.$route.query.customerConstruction);
      this.$store.dispatch("entity/getEntityByDocument", {
        document: this.customerConstructionParams.document
      }).then((response) => {
        if (response.data) {
          this.setEntity(response.data);
          this.customerName = response.data.entity_name;
        }
      }).finally(() => {
        loader.hide();
        this.loadingCustomerName = false;
      });
    },
    hideCommonElements() {
      this.isHideEntityCard = true;
      this.isHideConstructionCard = true;
      this.loadingEntityAttachments = false;
      this.loadingConstructionAttachments = false;
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
  },
  async mounted() {
    // Quando obra for selecionada.
    if (this.$route.query.construction) {
      this.loadEntityAndConstructionData();
    } else {
      this.hideCommonElements();
    }
    // Quando for cadastrado um novo cliente/obra na tela anterior.
    if (this.$route.query.customerConstruction) {
      this.loadEntityForCustomerConstructionRoute();
    } else {
      this.hideCommonElements();
    }
    await this.fetchFinancialLimits(this.$user.id)
    this.loadingPaymentIntermediaries = true
    this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
      this.loadingPaymentIntermediaries = false
    })
    this.contract_proposal.billing.range = null
    this.$store.dispatch("paymentMethod/fetchItems")
      .then(() => {
      }).catch(() => {
    })
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.loadingPlant = false;
    });
    this.loadingBankAccounts = true
    this.$store.dispatch('bankAccount/fetchItems', {})
      .then(response => {
        this.loadingBankAccounts = false
      })
      .catch(error => {
        this.loadingBankAccounts = false
      })
    this.$store.dispatch("contractContracts/fetchItems").then(() => {
      this.loadingContract = false;
      let [contract] = this.contracts.filter(item => item.default === true) || null
      this.contract_proposal.contract_uuid = contract.uuid
    });
    this.$store.dispatch("paymentTerm/fetchItems").then(() => {
      this.loadingPaymentTerm = false;
    });
    this.$store.dispatch("user/getSellersSimplified").then(() => {
      this.loadingUsers = false;
      const is_seller = this.users.find(item => item.uuid === this.$store.state.auth.user.uuid)
      if (is_seller) {
        this.contract_proposal.seller = this.$store.state.auth.user.uuid
      }
    });
    this.$store.dispatch("contractProposal/fetchCode").then(() => {
      this.loadingCode = false;
    });
    this.loadingGeneralSettings = true
    await this.$store.dispatch('generalSetting/show').then((response) => {
      this.loadingGeneralSettings = false
      this.contract_proposal.automatic_billing = response.data['contract_proposal']
      this.isRequiredConstructionAttachment = response.data['is_attachment_required_for_new_proposal_contract'];
    });
    this.getCompanyPlantIssuers()
    this.$watch('entity', function (entity) {
      this.entity_is_company = false
      if (entity.document.replace(/[^0-9]/g, '') > 11) {
        this.entity_is_company = true
      }
      this.setISS()
    })
    this.$watch('code', function (code) {
      this.contract_proposal.code = code
    })
    this.$watch('construction', function (value) {
      const entity_id = null;
      this.handleCreateContacts(
        'construction',
        entity_id,
        value.uuid
      );
      if (value && value.plant && !this.contract_proposal.company_plant_id) {
        this.contract_proposal.company_plant_id = value.plant.id
        this.contract_proposal.seller = value.seller.uuid
        this.setISS()
      }
    })
  }
};
</script>

<style lang="scss">
@import '@/style/PuzlCustom/App.scss';

.contract-proposal-create-page {
  .contract-card{
    .app-card-title-wrapper{
      min-height: 32px;
      @media screen and (min-width: 768px) {
        .vs__dropdown-toggle{
          min-width: 210px;
        }
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .vs__dropdown-toggle{
          min-width: 300px;
          width: 100%;
          margin-top: 12px;
        }
      }
    }
  }

  .vs--single > div.vs__dropdown-toggle{
    border-color: $muted-medium;
    height: 32px !important;
  }

  .vs--disabled > div.vs__dropdown-toggle{
    height: fit-content !important;
  }

  .input-limit,
  .input-limit .form-group,
  .input-limit .form-control,
  .input-limit input{
    height: 32px !important;
    font-size: 12px !important;
    font-family: Fredoka;
  }


  .is-invalid {
    border: 1px solid $danger !important;
    border-radius: 4px;
    animation: shake 0.3s ease-in-out;

    .input-limit{
      border: 1px solid $danger !important;
      border-radius: 4px;
      animation: shake 0.3s ease-in-out;
    }
  }

  .reset-input-css{
    .input-group-sm > input, .input-group-text, input{
      box-shadow: none !important;
    }

    .vs__selected{
      font-size: 12px !important;
      border: none !important;
      font-family: Fredoka;
    }

    &.invoice-card .vs__selected{
      padding: 4px;
      margin: 4px 0 0 8px;
      font-family: Fredoka;
      background-color: $muted-light;
    }

    .badge-wrapper a{
      width: 100%;
    }
  }
}
</style>

<style scoped lang="scss">
@import '@/style/PuzlCustom/App.scss';

.is-invalid {
  border: 1px solid $danger !important;
  border-radius: 4px;
}

.contract-proposal-create-page{
  .page-header{
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .header-buttons{
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .contract-info {
    h3{
      margin-bottom: 4px;
    }
    span {
      font-weight: 400 !important;
    }
  }

  .app-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    min-width: 100px;
    border: 1px solid $muted-light;
    border-radius: 8px;
    font-family: Fredoka;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: $dark;
    line-height: 1;
    transition: all .2s ease-in-out;


    &:hover{
      background-color: $muted-lighter;
      cursor: pointer;
    }
  }

  .app-form-group{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .custom-spacer{
    @media screen and (max-width: 768px) {
      margin-bottom: 0 !important;
    }
  }
}

.reset-input-css{
  label{
    color: $dark;
    font-family: Fredoka;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    &.popover-label{
      font-size: 12px;
    }

    &.popover-title{
      padding: 0 15px 12px;
      margin-bottom: 12px;
      position: relative;

      &::before{
        position: absolute;
        content: '';
        width: 99%;
        height: 1px;
        bottom: 0;
        left: 1px;
        background-color: $muted-light;
      }
    }
  }

  .form-control-sm{
    display: flex;
    align-items: center;
    padding: 8px;
    height: 32px;
    font-family: Fredoka;
    font-size: 12px;
    font-weight: 400;
    color: $dark;
    border: 1px solid $muted-medium;
  }

  .col-md-7{
    padding-right: 0 !important;
  }

  @media screen and (max-width:768px) {
    .app-form-group{
      display: flex;
      flex-direction: column;
      gap: 8px;

      & .col-md-7{
        padding: 0;
      }
    }

    .invoice-form-group{
      & > div{
        padding: 0;
      }
    }
  }

  .app-form-group.app-row{
    margin-bottom: 0;
    .col-md-4{
      margin-bottom: 16px;
    }
  }

  .input-group-sm > input, .input-group-text, input{
    box-shadow: none !important;
  }

  .frequency-button{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 32px;
    border: 1px solid $muted-medium;
    border-radius: 4px;
    transition: all .2s ease-in-out;

    &.is-active{
      color: $success;
      background-color: $success-lighter;
      border-color: $success;
    }

    &:hover{
      cursor: pointer;
      background-color: $muted-lighter;
    }
  }

  .date-picker-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $muted-medium;
    border-top-color: transparent;
    padding: 1rem;
  }
}


.frequency:not(.frequency-selected) {
  font-family: system-ui;
  font-size: 12px;
  min-width: 25px;
}

.frequency:hover {
  background-color: #dbd9d9;
}

.frequency-selected {
  font-family: system-ui;
  font-size: 12px !important;
  color: white;
  min-width: 25px;
  background-color: #e65119;
}

/* Botão anexos */
.custom-button {
  background-color: white;
  border: 2px solid #198754;
  border-radius: 50px;
  padding: 0px -1px;
  width: max-content;
}

.custom-button .col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-button .vertical-line {
  border-left: 2px solid #198754;
  height: 100%;
  margin-left: -2px;
  margin-right: -2px;
}

.custom-button {
  height: 30px;
}

/* editar cliente e obra */
.invert-color:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
</style>
