<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div>
              <multi-filter @fetch="handleSearch" :filter="{}" :without_filter="true"/>
            </div>
            <div>
              <div
                class="pl-3 pr-3 pt-2"
                v-for="(entity, key) in entities.data"
                :key="key"
              >
                <div class="row d-flex justify-content-between">
                  <div class="col-9">
                    <h4 class="p-0">
                      {{ entity.entity_name }}
                    </h4>
                    <h5 class="small p-0">
                      {{ entity.document }}
                    </h5>
                  </div>
                  <div
                    class="col-3 d-flex align-items-center justify-content-end"
                  >
                    <base-button
                      size="sm"
                      type="success"
                      :disabled="!loading"
                      @click.prevent="setEntity(entity)"
                    >
                      <i class="ni ni-check-bold mt-1"></i>
                    </base-button>
                  </div>
                </div>
                <div class="dropdown-divider p-0 m-0"></div>
              </div>
             <pagination @navegate="navegate" :source="entities"/>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()"
              >Cancelar
              </base-button
              >
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Pagination from '@/components/Utils/Pagination'
import MultiFilter from "@/components/Utils/MultiFilterV2";

export default {
  components: {
    MultiFilter,
    Pagination
  },
  name: "ModalSearchCpfCnpj",
  data() {
    return {
      modal: false,
      title: "Associar CPF CNPJ",
      validated: false,
      loading: true,
      params: {
        page: 1,
        per_page: 5,
      },
      filter: {
        name_or_document: ""
      },
      opened_by_admin_for_entity_edit_in_contract_edit: false,
    };
  },
  computed: {
    ...mapGetters({
      entities: "contractProposal/getListEntities"
    })
  },
  methods: {
    init() {
      this.loading = false;
      this.filter.customer = 1
      this.$store
        .dispatch("contractProposal/fetchEntities", {
          filter: this.filter,
          page: this.params.page
        })
        .then(response => {
          this.loading = true;
        });
    },
    navegate(page) {
      this.params.page = page
      this.init()
    },
    closeModal() {
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    handleSearchCpfCnpj(opened_by_admin_for_entity_edit_in_contract_edit = false) {
      if (opened_by_admin_for_entity_edit_in_contract_edit) {
        this.opened_by_admin_for_entity_edit_in_contract_edit = true;
      }

      this.modal = true;
    },
    handleSearch(filter) {
      this.filter = filter
      this.params.page = 1
      this.init();
    },
    setEntity(entity) {
      this.$notify({
        type: "success",
        message: "Tomador de Serviço selecionado com sucesso!"
      });
      
      // Se for aberto no editar contrato, clicando no botão "Alterar tomador" que só pode ser acessado pelo ADM.
      if (this.opened_by_admin_for_entity_edit_in_contract_edit) {
        entity.opened_by_admin_for_entity_edit_in_contract_edit = true;
      }

      this.$emit("set_entity", entity);
      this.modal = false;
    },
    save() {
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style></style>
