<template>
  <a href="#" @click.prevent="onClick">
    <badge :class="badgeClass" :style="badgeStyle">
      <i
        v-if="isLoading"
        class="mr-1 fas fa-circle-notch fa-spin"
        :style="isLoadingStyle"
      />
      <!-- TODO: Criar PROP para dimensão do icone, alinhamento, etc... -->
      <AppIcon
        v-else
        :icon="icon"
        :color="iconColor"
        width="14" 
        class="mr-1"
        align="bottom"
      />
      {{ props.label }} {{ selectedLabel }}
    </badge>
  </a>
</template>

<script setup>
//#region Imports
import { computed, defineProps, ref, watch } from "vue";
import { AppIcon, SwitchToogleLayoutsType } from "..";
import { dialogs } from "../../../helpers";
import { AppSwitchVariants } from ".";
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {boolean|0|1|undefined|null} value
 * @property {?Function} asyncClick - Evento de clique assíncrono que indica falha se a promessa retornar `true`.
 * @property {Promise<boolean>} asyncClick.return - Retornar `true` para indicar um erro no processo
 * @property {?boolean} hasAsyncNotifications - Mostrar notificações para método assíncrono. Padrão: `true`.
 * @property {?SwitchToogleLayoutsType} toogleLayout - Layout das opções on e off.
 * @property {?string} offLabel - Título da opção off.
 * @property {?string} onLabel - Título da opção on.
 * @property {?boolean} hasNotifications - Mostrar notificações para método síncrono. Padrão: `false`.
 * @property {?boolean} isLoading - Mostrar loading no icone do Switch.
 * @property {?string} label - Título do Switch.
 * @property {?boolean} disabled - Desativar interação do mouse/toque.
 */
/** @type {Props} */
const props = defineProps({
  value: {
    type: [Boolean, Number],
    default: false,
    required: false,
  },
  asyncClick: {
    type: Function,
    default: AppSwitchVariants.defaultVariants.asyncClick,
    required: false,
  },
  hasAsyncNotifications: {
    type: Boolean,
    default: AppSwitchVariants.defaultVariants.hasAsyncNotifications,
    required: false,
  },
  toogleLayout: {
    type: Object,
    default: AppSwitchVariants.defaultVariants.toogleLayout,
    required: false,
  },
  offLabel: {
    type: String,
    default: undefined,
    required: false,
  },
  onLabel: {
    type: String,
    default: undefined,
    required: false,
  },
  hasNotifications: {
    type: Boolean,
    default: AppSwitchVariants.defaultVariants.hasNotifications,
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: AppSwitchVariants.defaultVariants.isLoading,
    required: false,
  },
  label: {
    type: String,
    default: AppSwitchVariants.defaultVariants.label,
    required: false,
  },
  disabled: {
    type: Boolean,
    default: AppSwitchVariants.defaultVariants.disabled,
    required: false,
  },
});
//#endregion

//#region Emits
const emit = defineEmits([
  "input", 
  "click"
]);
//#endregion

//#region Data
const localValue = ref(props.value);
const localIsLoading = ref(false);
const defaultToogleLayout = AppSwitchVariants.defaultVariants.toogleLayout();
//#endregion

//#region Computeds
// Computed para garantir que localValue seja tratado corretamente como Boolean
const localValueAsBoolean = computed(() => {
  return localValue.value === 1 || localValue.value === true;
});

/** Class do Badge */
const badgeClass = computed(() => {
  return [
    localValueAsBoolean.value 
      ? "active-badge" 
      : "inactive-badge",
    "px-3",
    "py-2",
    "badge-mobile",
  ];
});

/** Style do Badge */
const badgeStyle = computed(() => {
  return {
    backgroundColor: localValueAsBoolean.value 
      ? props.toogleLayout?.on?.backgroundColor ?? defaultToogleLayout.on.backgroundColor
      : props.toogleLayout?.off?.backgroundColor ?? defaultToogleLayout.off.backgroundColor,
    color: localValueAsBoolean.value 
      ? props.toogleLayout?.on?.labelColor ?? defaultToogleLayout.on.labelColor
      : props.toogleLayout?.off?.labelColor ?? defaultToogleLayout.off.labelColor,  
  };
});

/** Ícone SVG */
const icon = computed(() => {
  return localValueAsBoolean.value 
    ? props.toogleLayout?.on?.icon ?? defaultToogleLayout.on.icon
    : props.toogleLayout?.off?.icon ?? defaultToogleLayout.off.icon;
});

/** Cor do ícone SVG */
const iconColor = computed(() => {
  return localValueAsBoolean.value 
    ? props.toogleLayout?.on?.iconColor ?? defaultToogleLayout.on.iconColor
    : props.toogleLayout?.off?.iconColor ?? defaultToogleLayout.off.iconColor
});

/** Indica se o componente está em processo de carregamento */
const isLoading = computed(() => props.isLoading || localIsLoading.value);

/** Cor do ícone de loading */
const isLoadingStyle = computed(() => {
  return { 
    color: localValueAsBoolean.value 
      ? props.toogleLayout?.on?.iconColor ?? defaultToogleLayout.on.iconColor
      : props.toogleLayout?.off?.iconColor ?? defaultToogleLayout.off.iconColor
  }
});

/** Retorna a descrição da opção selecionada com base no valor atual */
const selectedLabel = computed(() => {
  if (localValueAsBoolean.value) {
    return props.onLabel ?? props.toogleLayout?.on?.label ?? defaultToogleLayout.on.label;
  }
  return props.offLabel ?? props.toogleLayout?.off?.label ?? defaultToogleLayout.off.label;
});
//#endregion

//#region Watchers
/** Sincroniza o valor local com o valor externo e emite alterações */
watch(() => props.value, (newValue) => (localValue.value = newValue));
watch(localValue, (newValue) => emit("input", newValue));
//#endregion

//#region Methods
/** Evento clique do Switch */
async function onClick() {
  if (localIsLoading.value || props.disabled) {
    return;
  }
  isAsyncClick() 
    ? onAsyncClick() 
    : onSyncClick();
}

/**
 * Verifica se o evento de clique é assíncrono
 * @returns {boolean}
 */
function isAsyncClick() {
  return typeof props.asyncClick === "function";
}

/** Evento clique assíncrono do Switch */
async function onAsyncClick() {
  let hasError = false;
  let exception = undefined;

  try {
    localIsLoading.value = true;
    const currentValue = localValue.value;

    // Executar método assíncrono
    try {
      hasError = await props.asyncClick();
    } catch (error) {
      hasError = true;
      exception = error;
    }

    // Verifica se houve erro na execução
    if (hasError) {
      if (props.hasAsyncNotifications) {
        dialogs.notifyError();
      }
      localValue.value = currentValue;
      if (exception) {
        throw exception;
      }
      return;
    }

    // Execução bem-sucedida
    emit("click", localValue.value);
    if (props.hasAsyncNotifications) {
      dialogs.notify();
    }
  } finally {
    localIsLoading.value = false;
  }
}

/** Evento clique síncrono do Switch */
async function onSyncClick() {
  const isBooleanType = typeof localValue.value === 'boolean';
  isBooleanType 
    ? localValue.value = !localValue.value 
    : localValue.value = localValue.value === 1 
      ? 0 
      : 1;
  if (props.hasNotifications) {
    dialogs.notify();
  }
  emit("click", localValue.value);  
}
//#endregion
</script>

<style scoped lang="scss">
@import "./AppSwitch.scss";
</style>
