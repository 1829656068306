<template>
    <div>
        <modal size="lg" :show.sync="modal.create">
            <template slot="header">
                <div style="display: flex;align-items: center;">
                    <img style="display: inline-flex;" src="/img/icons/icons8/ios/briefcase.png" width="22">
                    <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white"> EDITAR TAREFA </h5>
                </div>      
            </template>
            <div>
                <div class="row">
                    <div class="col-12" style="display: flex; align-items: center;">
                        <img src="/img/icons/icons8/ios/info-squared.png" width="22">
                        <h4 class="new-default-black-font m-0 mx-3 ">
                            Identificação
                        </h4>
                        <hr class="ml-3" style="width: 100%;">
                    </div>
                        </div>
                            <div class="form-group">
                                <div class="form-group row m-0 p-0 mb-2">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Criador
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <base-input input-classes="form-control-sm">
                                            <input
                                                v-model="$_task.creator_name"
                                                type="text"
                                                class="form-control form-control-sm"
                                                disabled
                                            />
                                        </base-input>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0 mt-3">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Data de Criação
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <base-input input-group-classes="input-group-sm">
                                            <InputDateTimePicker
                                                v-model="$_task.created_at"
                                                :mode="'date'"
                                                :disableMaxDate="false"
                                                :disabled="true"/>
                                        </base-input>
                                    </div>
                            </div>
                    </div>
                <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
                    <form class="needs-validation" @submit.prevent="handleSubmit(update)" autocomplete="off">
                        <div class="container-fluid">

                            <div class="row">
                                <div class="col-12" style="display: flex; align-items: center;">
                                    <img src="/img/icons/create-new.png" width="22">
                                    <h4 class="new-default-black-font m-0 mx-3 ">
                                        Edição
                                    </h4>
                                    <hr class="ml-3" style="width: 100%;">
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Responsável
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                                <PuzlSelectSearch
                                                    :items="$_users"
                                                    :value="$_task.receiver_user_id"
                                                    v-model="$_task.receiver_user_id"
                                                    :loading="loadingUsers"
                                                    class="select-xl new-default-black-font"
                                                    clearable
                                                    @search="handleFilterUser"
                                                    @input="debouncedFilterUser"
                                                />
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Data Limite
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-group-classes="input-group-sm">
                                            <InputDateTimePicker
                                                v-model="$_task.limit_date"
                                                :mode="'date'"
                                                :disableMaxDate="false"/>
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 mb-4 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Urgência
                                            <span class="text-danger">&nbsp;*</span>
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                                <PuzlSelect
                                                v-model="$_task.priority"
                                                :items="priority"
                                                />
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Título da Tarefa
                                            <span class="text-danger"
                                            >&nbsp;*</span
                                            >
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <validation-provider rules="required">
                                            <base-input input-classes="form-control-sm">
                                            <input
                                                v-model="$_task.description"
                                                type="text"
                                                class="form-control form-control-sm"
                                            />
                                            </base-input>
                                        </validation-provider>
                                    </div>
                                </div>
                                <div class="form-group row m-0 p-0">
                                    <div class="col-md-5 mb-1 pr-4">
                                        <div class="pb-0 mb-1 form-control-label">
                                            Resumo da Tarefa
                                        </div>
                                    </div>
                                    <div class="col-md-7 mb-2 pr-4">
                                        <base-input input-group-classes="input-group-sm">
                                            <textarea
                                            v-model="$_task.observation"
                                            rows="4"
                                            type="text"
                                            class="form-control form-control-sm"
                                            @input="checkText"
                                            />
                                            <span class="float-right">
                                              {{ typedCharacters($_task) }} / {{ characterLimit }}
                                            </span>
                                        </base-input>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <base-button type="danger" @click="closeModal">
                                        <img class="mr-2" src="/img/icons/cancel-white.png" width="18px" height="18px" />
                                        Cancelar
                                    </base-button>
                                    <base-button type="success" native-type="submit" :loading="loadingStore" v-bind:disabled="invalid">
                                        <img class="mr-2" src="/img/icons/save.png" width="18px" height="18px" />
                                        Salvar
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </modal>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import InputFile from "@/components/Utils/InputFile";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import {debounce} from "@/plugins";
const { formatErrorValidation } = require("@/plugins");

export default {
  name: "ModalEditTask",
  components: {
    PuzlSelect,
    InputDateTimePicker,
    InputFile,
    PuzlSelectSearch,
  },
  data() {
    return {
      modal: {
        title: "Editar Tarefa",
        create: false,
      },
      priority: [
        { id: 1, name: "Baixa" },
        { id: 2, name: "Média" },
        { id: 3, name: "Alta" },
      ],
      loadingStore: false,
      loadingUsers: true,
      userId: null,
      characterLimit: 500,
    };
  },
  computed: {
    ...mapGetters({
      $_users: "user/fetch",
      $_task: "task/show",
    }),
  },

  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal(taskId) {
      this.$Progress.start();
      this.getUsers();
      this.$store
        .dispatch("task/show", taskId)
        .then((response) => {
          this.modal.create = true;
        })
        .catch((error) => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message,
          });
        }).finally(() => {
            this.$Progress.finish();
        });
    },
    update() {
      this.$Progress.start();
      this.loadingStore = true;
      this.$store
        .dispatch("task/update", this.$_task)
        .then((response) => {
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
            this.$Progress.finish();
          }
        }).finally(() => {
            this.$Progress.finish();
            this.loadingStore = false;
        });
    },
    checkText() {
      if(this.task.observation.length >= this.characterLimit) {
        this.task.observation = this.task.observation.substring(0, this.characterLimit);
      }
    },
    typedCharacters(task) {
      return task.observation == null ? 0 : task.observation.length;
    },
    /**
     * Busca os dados com base no texto digitado pelo usuário.
     * @param {string} label
     */
     debouncedFilterUser: debounce(function (label) {
        // Converte o ID para o nome apenas ao selecionar no input, sem afetar a busca digitada.
        if (typeof label === 'number') {
            const user = this.$_users.find((item) => item.id === label);
            if (user) {
                label = user.label;
            }
        }

        this.$store.dispatch('user/getSearch', { search: label }).then(() => {
            this.loadingUsers = false;
        });
    }, 300),
    /**
     * Inicia o loading e chama a função responsável por trazer os dados.
     * @param {string} label - Pesquisa do usuário
     */
    handleFilterUser: function (label) {
        if (label) {
            this.loadingUsers = true;
            this.debouncedFilterUser(label);
        }
    },
    getUsers() {
        this.$store.dispatch('user/getSearch', { search: ''}).then(() => {
            this.loadingUsers = false;
        });
    },
  },
  mounted() {},
};
</script>
