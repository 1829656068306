const keys = Object.freeze({
  ENTITY: "entity",
  PAYMENT_METHOD: "payment_method",
  PAYMENT_TERM: "payment_term",
  ENTITY_BILL_RECEIVE: "entity_bill_receive",
  PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE: "payment_method_for_entity_bill_receive",
  COMPANY_PLANT_ISSUER: "company_plant_issuer",
  COMPANY_PLANT_ISSUER_GROUP: "company_plant_issuer_group",
  COMPANY_PLANT: "company_plant",
  BANK: "bank",
  XML_TRAVEL: "xml_travel",
  INVOICE: "invoice",
  FORMULATION: "formulation",
  SERVICE: "service",
  ADDITIONAL: "additional",
  SINGLE_ADDITIONAL: "single_additional",
  STOCK_MOVEMENT: "stock_movement",
  SCHEDULE_TRAVEL: "schedule_travel",
  SERVICE_TRAVEL: "service_travel",
  ADDITIONAL_TRAVEL: "additional_travel",
  SELLER: "seller",
  ENTRY_LAUNCH: "entry_launch",
  MANUAL_STOCK_MOVEMENT: "manual_stock_movement",
  UF: "uf",
  CITY: "city",
  ENTITY_FINANCIAL: "entity_financial",
});

const getTitle = (value) => {
  switch (value) {
    case keys.ENTITY:
      return "Cliente/Fornecedor";
    case keys.PAYMENT_METHOD:
      return "Método de Pagamento";
    case keys.PAYMENT_TERM:
      return "Condição de Pagamento";
    case keys.ENTITY_BILL_RECEIVE:
      return "Créditos e Antecipações";
    case keys.PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE:
      return "Método de Pagamento para Créditos e Antecipações";
    case keys.COMPANY_PLANT_ISSUER:
      return "Filial";
    case keys.COMPANY_PLANT_ISSUER_GROUP:
      return "Empresa";
    case keys.COMPANY_PLANT:
      return "Central";
    case keys.BANK:
      return "Banco";
    case keys.XML_TRAVEL:
      return "Xml de carregamento";
    case keys.INVOICE:
      return "Faturas/NFS-e";
    case keys.FORMULATION:
      return "Traço";
    case keys.SERVICE:
      return "Serviço";
    case keys.ADDITIONAL:
      return "Adicional";
    case keys.SINGLE_ADDITIONAL:
      return "Avulso";
    case keys.STOCK_MOVEMENT:
      return "Estoque";
    case keys.SCHEDULE_TRAVEL:
      return "Viagem";
    case keys.SERVICE_TRAVEL:
      return "Serviço de Viagem";
    case keys.ADDITIONAL_TRAVEL:
      return "Adicional de Viagem";
    case keys.SELLER:
      return "Vendedor";
    case keys.ENTRY_LAUNCH:
      return "Nota de entrada";
    case keys.MANUAL_STOCK_MOVEMENT:
      return "Estoque manual";
    case keys.UF:
      return "UF";
    case keys.CITY:
      return "Cidade";
    case keys.ENTITY_FINANCIAL:
      return "Carteira do cliente";
    default:
      return "";
  }
};

export const SyncTypeEnum = {
  keys,
  getTitle,
};
