/**
 * Type para representar um item de requisição de compra
 *
 * @typedef {Object} PurchaseRequestItemType
 * @property {number} id [V]
 * @property {string} code [C,A]
 * @property {string} deadline [C,A]
 * @property {number} requester_id [C]
 * @property {string} note [C,A]
 * @property {string} purchase_justification [C,A]
 * @property {number} status [C,A]
 * @property {number} product_service_hub_type_id [C]
 * @property {number} purchase_category_id [C]
 * @property {number} cost_center_id [C]
 * @property {number} input_company_plant_id [C]
 * @property {number} company_plant_buyer_id [C]
 * @property {string} created_by_user_name [V]
 * @property {string} created_by_user_email [V]
 * @property {string} purchase_category_description [V]
 * @property {string} cost_center_name [V]
 * @property {boolean} has_active_budget [V]
 * @property {boolean} can_approve [V]
 * @property {string} input_company_plant_name [V]
 * @property {boolean} purchase_request_approve [V]
 * @property {boolean} has_attachments [V]
 */
export const PurchaseRequestItemType = {};

/**
 * Inicializar PurchaseRequestItemType
 * 
 * @returns {PurchaseRequestItemType}
 */
export const initPurchaseRequestItemType = () => {
  return {
    id: 0,
    code: '',
    deadline: '',
    requester_id: 0,
    note: '',
    purchase_justification: '',
    status: 0,
    product_service_hub_type_id: 0,
    purchase_category_id: 0,
    cost_center_id: 0,
    input_company_plant_id: 0,
    company_plant_buyer_id: 0,
    created_by_user_name: '',
    created_by_user_email: '',
    purchase_category_description: '',
    cost_center_name: '',
    has_active_budget: false,
    can_approve: false,
    input_company_plant_name: '',
    purchase_request_approve: false,
    has_attachments: false
  };
};
