<template>
  <div>
    <AppFixedPageTitle title="Integrações" icon="/img/icons/icons8/ios/settings-3--v1.png" />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="$_syncGeneralIsListing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
          :showCompanyPlants="false"
        >
          <AppSearchBarFilterSection class="pb-5 mb-5" name="dados" icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="col-md-12  mb-3 px-0 text-left">
              <label class="form-control-label fs-11 new-default-gray-font font-weight-400"> Integrações </label>
              <PuzlSelect
                style="width: 100%"
                v-model="filter.type"
                :items="syncTypeItems"
                :disableBoxShadow="true"
                placeholder="Selecione"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      :isShowViewTypes="false"
      @onViewTypeChange="(type) => (listType = type)"
    >
      <AppSelect v-model="orderBy.selected" :items.sync="orderBy.items" @onSelectItemClick="listItems(false)" variant="text-only" />
    </AppTabSelect>
    <div class="container-fluid">
      <SyncGeneralListPageTable @onEditItemClick="listItems" />
      <AppViewTrigger v-if="!$_syncGeneralIsListing" @onIntersected="listItems(true)" />
      <PuzlEmptyData v-if="!$_syncGeneralIsListing && !$_syncGeneralListed.items.length" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from "vue";
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
  AppSearchBarFilterSection,
} from "@/components/AppGlobal";
import SyncGeneralStore from "../../store/SyncGeneralStore";
import { initSyncGeneralListFilterType, SyncGeneralListPagerType, initSyncGeneralListPagerType } from "../../types";
import { date, getTitles } from "../../../../../helpers";
import SyncGeneralListPageTable from "./SyncGeneralListPageTable.vue";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import { SyncGeneralStatusEnum } from "../../enums/SyncGeneralStatusEnum";
import { SyncTypeEnum } from "../../../shared/enums/SyncTypeEnum";
//#endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {SyncGeneralListPagerType} - Filtro principal */
const filter = reactive(initSyncGeneralListFilterType());

const currentDate = date.make().format(date.FORMAT.ISO_8601);

const listType = ref("table");

const orderBy = reactive({
  items: [
    {
      id: null,
      name: "PADRÃO",
      selected_name: "ORDENAR",
      filter: "created.asc",
    },
  ],
  selected: 0,
});

const syncTypeItems = getTitles(SyncTypeEnum).filter((item) => {
  return ![
    SyncTypeEnum.keys.PAYMENT_METHOD, 
    SyncTypeEnum.keys.PAYMENT_METHOD_FOR_ENTITY_BILL_RECEIVE,
    SyncTypeEnum.keys.PAYMENT_TERM, 
    SyncTypeEnum.keys.CITY, 
    SyncTypeEnum.keys.UF, 
    SyncTypeEnum.keys.SELLER, 
    SyncTypeEnum.keys.BANK, 
    SyncTypeEnum.keys.COMPANY_PLANT, 
    SyncTypeEnum.keys.COMPANY_PLANT_ISSUER, 
    SyncTypeEnum.keys.COMPANY_PLANT_ISSUER_GROUP, 
    SyncTypeEnum.keys.FORMULATION, 
    SyncTypeEnum.keys.SERVICE,
    SyncTypeEnum.keys.ADDITIONAL,
    SyncTypeEnum.keys.SINGLE_ADDITIONAL,
  ].includes(item.id);
});
//#endregion

//#region Computeds
const $_syncGeneralListed = computed(() => SyncGeneralStore.getters.getListed());
const $_syncGeneralIsListing = computed(() => SyncGeneralStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: "Todos",
      selected: filter.status === null,
    },
    {
      id: SyncGeneralStatusEnum.keys.INTEGRATED,
      name: SyncGeneralStatusEnum.getTitle(SyncGeneralStatusEnum.keys.INTEGRATED),
      selected: filter.status === SyncGeneralStatusEnum.keys.INTEGRATED,
    },
    {
      id: SyncGeneralStatusEnum.keys.ERROR,
      name: SyncGeneralStatusEnum.getTitle(SyncGeneralStatusEnum.keys.ERROR),
      selected: filter.status === SyncGeneralStatusEnum.keys.ERROR,
    },
  ];
});
//#endregion

//#region Methods
/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  }
}

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? SyncGeneralStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      start: filter.range.start ?? currentDate,
      end: filter.range.end ?? currentDate,
    },
  };
}

/**
 * Padrão do filtro principal
 * @returns {SyncGeneralListPagerType}
 */
function defaultFilter() {
  return initSyncGeneralListFilterType();
}

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  SyncGeneralStore.actions.list(filter, isAccumulateItems);
}

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range = searchBarFilter.range;
  filter.order_list_by = orderBy.items[orderBy.selected].filter;
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  SyncGeneralStore.mutations.resetStates();
  SyncGeneralStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>
