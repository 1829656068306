export const PurchaseBudgetStatusEnum = Object.freeze({
  PENDING: 0, // Pendente
  OPEN: 1, // Aberto
  DENIED: 2, // Negado
  COMPLETED: 3, // Concluído
  CANCELED: 4 // Cancelado
});

const getTitle = (value) => {
  switch (value) {
      case PurchaseBudgetStatusEnum.PENDING:
          return "Pendentes";
      case PurchaseBudgetStatusEnum.OPEN:
          return "Abertas";
      case PurchaseBudgetStatusEnum.DENIED:
          return "Negados";
      case PurchaseBudgetStatusEnum.CANCELED:
          return "Cancelados";
      case PurchaseBudgetStatusEnum.COMPLETED:
          return "Concluídas";
      default:
          return "";
  }
};

export default {
  keys: PurchaseBudgetStatusEnum,
  getTitle,
};