import { PagerType, initPagerType } from "../../../../../../shared/types";

/**
 * Type para item da listagem de PurchaseRequest
 *
 * @typedef {Object} PurchaseRequestListType
 * @property {number} id
 * @property {string} name
 * @property {boolean} status
 * @property {string} observation
 * @property {string} created_at
 * @property {?string} updated_at
 * @property {number} created_by_user_id
 * @property {?number} updated_by_user_id
 * @property {string} created_by_user_name
 * @property {string} updated_by_user_name
 */
export const PurchaseRequestListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<PurchaseRequestListType> } )} PurchaseRequestListPagerType
 */
export const PurchaseRequestListPagerType = {};

/**
 * Inicializar PurchaseRequestListPagerType
 *
 * @returns {PurchaseRequestListPagerType}
 */
export const initPurchaseRequestListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  };
};
