<template>
  <div style="min-height: 800px;">
    <AppFixedPageTitle title="Cotação" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="$_purchase_budget_is_listing"
          @onSearchClick="listItems"
          @onClearClick="clearFilter">
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            @click.prevent="$router.push('/purchase/request/create')"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png">
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" :isShowViewTypes="false" />
    <div class="container-fluid">
    <!-- CARDS -->
    <PurchaseBudgetListPageCards class="mt-4" @fetch="listItems" />
      <AppViewTrigger v-if="!$_purchase_budget_is_listing" @onIntersected="listItems(true)" />
      <div class="d-flex" v-if="$_purchase_budget_is_listing">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData v-if="!$_purchase_budget_is_listing && $_purchase_budget_listed.items.length <= 0" />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  initSearchBarFilterType,
} from '../../../../../../../components/AppGlobal';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PurchaseBudgetListPageCards from './PurchaseBudgetListPageCards.vue';
import PurchaseBudgetStatusEnum from "../../enums/PurchaseBudgetStatusEnum";
import { initPurchaseBudgetListFilterType } from "../../types";
import { date } from "../../../.../../../../../../helpers";
import purchaseBudgetStore from "../../store/purchaseBudgetStore";


const searchBarFilter = reactive(initSearchBarFilterType());
const filter = reactive(initPurchaseBudgetListFilterType());
const currentDate = date.make().format(date.FORMAT.ISO_8601);

//#region Computeds
/** Store Getters */
const $_purchase_budget_listed = computed(() => purchaseBudgetStore.getters.getListed());
const $_purchase_budget_is_listing = computed(() => purchaseBudgetStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: PurchaseBudgetStatusEnum.keys.PENDING,
      name: PurchaseBudgetStatusEnum.getTitle(PurchaseBudgetStatusEnum.keys.PENDING),
      selected: filter.status === PurchaseBudgetStatusEnum.keys.PENDING,
    },
    {
      id: PurchaseBudgetStatusEnum.keys.COMPLETED,
      name: PurchaseBudgetStatusEnum.getTitle(PurchaseBudgetStatusEnum.keys.COMPLETED),
      selected: filter.status === PurchaseBudgetStatusEnum.keys.COMPLETED,
    },
  ];
});

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
 function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? purchaseBudgetStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
 function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      start: filter.range.start ?? currentDate,
      end: filter.range.start ?? date.make(currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
    },
  }
};

/**
 * Padrão do filtro principal
 * @returns {PurchaseBudgetListFilterType}
 */
 function defaultFilter() {
  return {
    ...initPurchaseBudgetListFilterType(),
  }
};

/**
 * @param {TabSelectItemType} item
 */
 function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
 function listItems(isAccumulateItems = false) {
  prepareFilter();
  purchaseBudgetStore.actions.list(filter, isAccumulateItems);
};


function prepareFilter() {
  filter.input_company_plant_id = searchBarFilter.company_plant_selected;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.deadline_range = searchBarFilter.range;
}

onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  purchaseBudgetStore.mutations.resetStates();
  purchaseBudgetStore.mutations.setPageState({ filter, searchBarFilter });
});
</script>