<template>
  <div>
    <modal :show.sync="modal.open" :backgroundColor="'#F2F4F9'" size="lg">
      <template slot="header">
        <div
          class="d-flex align-items-center container-fluid"
          style="gap: 12px; margin-left: -28px"
        >
          <div>
            <img
              src="/img/icons/icons8/ios/icon-plus-success.png"
              height="28"
              width="28"
            />
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="container-fluid">
              <div class="row mt-2 mb-3">
                <div class="col-12" style="display: flex; align-items: center">
                  <img
                    src="/img/icons/icons8/ios/info-squared_warning.png"
                    width="22"
                  />
                  <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
                  <hr class="ml-3 m-0" style="width: 100%" />
                </div>
              </div>
              <div class="card">
                <div class="d-flex align-items-center">
                  <div>
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Status
                    </h4>
                  </div>
                  <div class="ml-auto" style="min-width: 130px">
                    <AppSwitch v-model="concrete_piece.status" />
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <div>
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Permite Reuso
                    </h4>
                  </div>
                  <div class="ml-auto" style="min-width: 130px">
                    <AppSwitch v-model="concrete_piece.reuse" />
                  </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                  <div>
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Puzl Connect
                    </h4>
                  </div>
                  <div class="ml-auto" style="min-width: 130px">
                    <AppSwitch v-model="concrete_piece.customer_puzl" />
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Descrição
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="concrete_piece.description"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Nível De Aplicação
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          multiple
                          v-model="concrete_piece.level_feature"
                          :items="feature_levels"
                          customKey="value"
                          label="label"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-right">
                  <AppButton
                    label="Cancelar"
                    type="danger"
                    class="ml-2"
                    :hasOutline="true"
                    @click="closeModal()"
                  />
                  <AppButton
                    label="Salvar"
                    type="success"
                    class="ml-2"
                    native-type="submit"
                    :isDisabled="invalid"
                    :isLoading="isSaving"
                  />
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { AppButton, AppSwitch } from "../../../../../../components/AppGlobal";

export default {
  name: "Create",
  components: {
    PuzlSelect,
    AppButton,
    AppSwitch,
  },
  data() {
    return {
      modal: {
        title: "Nova Peça",
        open: false,
      },
      concrete_piece: {
        uuid: null,
        status: true,
        reuse: false,
        customer_puzl: false,
        description: null,
        level_feature: null,
        level_feature_old: null,
        user: null,
      },
      loadingSave: false,
    };
  },
  computed: {
    ...mapGetters({
      feature_levels: "mixConcretePiece/feature_levels",
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false;
    },
    handleEditModal(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      this.$store
        .dispatch("mixConcretePiece/show", uuid)
        .then((response) => {
          this.concrete_piece = {
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            reuse: response.data.reuse || false,
            customer_puzl: response.data.customer_puzl || false,
            description: response.data.description || null,
            level_feature: response.data.level_feature || null,
            user: this.$user.id,
            id: response.data.id,
          };
          this.concrete_piece.level_feature_old =
            this.concrete_piece.level_feature;
          this.modal.open = true;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$Progress.finish();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors);
            errors = errors.flat().toString();
            this.$notify({ type: "danger", message: errors });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("mixConcretePiece/update", this.concrete_piece)
        .then((response) => {
          this.loadingSave = false;
          this.modal.open = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors);
            errors = errors.flat().toString().split(",").join("<br />");
            this.$notify({ type: "danger", message: errors });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}
.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  padding: 16px 12px;
  border: 1px solid #ececec;
}
</style>
