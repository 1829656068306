<template>
  <div>
    <div class="container-fluid">
      <card>
        <div>
          <div class="row card-wrapper" v-if="bill_receives.length && !isLoading">
            <div class="col-lg-4" v-for="(item, index) in bill_receives" :key="index">
              <div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
                <div class="p-3 px-4">
                  <div class="row align-items-center mb-2">
                    <div class="col-10 d-flex align-items-center">
                      <div
                        class="mr-2"
                        style="display: inline-flex;"
                        :class="paidText(item).type"
                      >
                        <h4 class="font-weight-normal m-0 p-0">
                          {{ paidText(item).text }}
                        </h4>
                      </div>

                      <div v-if="item.nsu_code && item.payment_intermediary_has_credentials === 1" class="mx-2">
                          <el-popover v-if="item.card_integration_status === 0" trigger="hover" placement="right">
                            <span> Pendente </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_warning.png" width="22">
                            </base-button>
                          </el-popover>
                          <el-popover v-if="item.card_integration_status === 1" trigger="hover" placement="right"
                                      class="p-0 pr-0">
                            <span> Integrado </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_success.png" width="22">
                            </base-button>
                          </el-popover>
                          <el-popover v-if="item.card_integration_status === 2" trigger="hover" placement="right"
                                      class="p-0 pr-0">
                            <span> Divergente </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 m-0 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/api_danger.png" width="22">
                            </base-button>
                          </el-popover>
                        </div>
                        <div class="mx-2" >
                        <el-popover trigger="hover" placement="right">
                          <div class="d-flex mb-2" style="gap: 15px; font-weight: 500; color: #2b2d32">
                            <span class="py-1">INFORMAÇÕES</span>
                          </div>
                          <hr style="margin-left:-12px;margin-right:-12px;" class="mt-2 mb-3" />
                          <!-- Banco -->
                          <div class="row m-0">
                            <AppIcon class="mr-2" icon="merchant-account" color="dark" width="21" />
                            <span style="padding-top:2px" class="mb-0 new-default-black-font">Banco</span>
                            <div style="border-radius: 0.375rem;" class="w-100 border py-2 px-3 fs-12 mt-2">
                              <span class="popover-text">{{ item.bank_account_name }}</span>
                            </div>
                          </div>
                          <!-- Solicitante -->
                          <div class="row ml-0 mr-0 mt-3">
                            <AppIcon class="mr-2" icon="male-user" color="dark" width="21" />
                            <span style="padding-top:2px" class="mb-0 new-default-black-font">Solicitante</span>
                            <div style="border-radius: 0.375rem;" class="w-100 border py-2 px-3 fs-12 mt-2">
                              <span class="popover-text">{{ item.user_name }}</span>
                              <br>
                              <span class="popover-text">{{ date.make(item.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}</span>
                            </div>
                          </div>

                          <!-- Central -->
                          <div class="row ml-0 mr-0 mt-3">
                            <AppIcon class="mr-2" icon="chemical-plant" color="dark" width="21" />
                            <span style="padding-top:2px" class="mb-0 new-default-black-font">Central</span>
                            <div style="border-radius: 0.375rem;" class="w-100 border py-2 px-3 fs-12 mt-2">
                              <span class="popover-text">{{ item.company_plant_name }}</span>
                            </div>
                          </div>

                          <div
                            class="d-flex"
                            slot="reference"
                            size="sm"
                            type="secundary"
                            style="box-shadow: none"
                          >
                            <AppIcon class="" icon="info" color="warning" width="21" />
                          </div>
                        </el-popover>
                        </div>
                    </div>
                    <div class="col-2 text-right">
                      <base-dropdown menuOnRight>
                        <div slot="title-container"
                          class="dropdown-toggle rounded m-0">
                          <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png">
                        </div>
                        <a
                          class="dropdown-item"
                          @click="handleEditEntity(item.id)"
                          style="align-items: center;display: flex;">
                          <img src="/img/icons/edit.png" width="22" alt="">
                          Editar
                        </a>
                        <a
                          v-if="pixDisabled(item) === false"
                          class="dropdown-item"
                          @click="handleModalPix(item.id)"
                          style="align-items: center;display: flex;"
                          >
                          <img src="/img/icons/qr-code--v1.png" width="22" alt="">
                          PIX
                        </a>

                        <!-- Histórico de lançamento -->
                        <a
                          class="dropdown-item"
                          @click="handleEntityBillReceiveHistoryListModal(item.id)"
                          style="align-items: center;display: flex;"
                          >
                            <AppIcon
                              icon="order-history"
                              color="dark"
                              width="22"
                              class="mr-2"
                            />
                            Histórico
                        </a>
                        <div v-if="['Aprovar', 'Receber'].includes(BillReceiveStatus[item.installments[0]['status']])" class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item"
                          style="align-items: center;display: flex;"
                          v-if="['Aprovar', 'Receber'].includes(BillReceiveStatus[item.installments[0]['status']])"
                          @click="downloadAdvanceReceipt(item.installments[0]['entity_bill_receive_id'])">
                          <img src="/img/icons/icons8/ios/print-primary.png" width="22" alt="">
                          Imprimir
                        </a>

                        <!-- Totvs -->
                        <div v-if="hasTotvs && (item.installment_statistics.approved_value > 0 || item.installment_statistics.pay_value > 0)" class="dropdown-divider p-0 m-0"></div>
                        <a @click="handleModalEntityBillReceiveIntegrationInfo(item.id)"  v-if="hasTotvs" class="dropdown-item">
                          <img src="/img/icons/icons8/ios/downloading-updates-primary.png" width="22px" height="22px" />
                          Totvs
                        </a>

                        <div class="dropdown-divider p-0 m-0"></div>
                        <a class="dropdown-item"
                          style="align-items: center;display: flex;"
                          @click="handleDeleteEntity(item.bill_receive_id, index)">
                          <img src="/img/icons/icons8/ios/delete_danger.png" width="22" alt="">
                          Excluir
                        </a>
                      </base-dropdown>
                    </div>
                  </div>
                  <h2 class="new-default-black-font mb-0" style="line-height:normal">
                    {{ item.contract_proposal_code ?? '-' }}
                  </h2>
                  <div class="d-flex">
                    <div class="payment-method">
                      {{ item.payment_method }}
                    </div>
                    <div class="payment-term ml-2">
                        {{ item.payment_term }}
                    </div>
                  </div>
                  <div class="row mt-n1">
                    <div class="col-12 my-1">
                      <h1 class="new-default-black-font" style="display: flex;align-items: center;">
                        {{ item.total_value | currency() }}
                      </h1>
                    </div>
                    <div class="col-8">
                      <h5 class="mt-n2 new-default-gray-font font-weight-500">{{ item.competence | parseDate('DD MMM YYYY') }}</h5>
                    </div>
                    <div class="col-4 text-right mt-n3">
                      <el-popover v-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.INTEGRATED" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Integrado com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/connected_success.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover v-else-if="hasTotvs && item.is_integrated_on_totvs === TotvsStatusEnum.ERROR" trigger="hover" placement="right" class="mr-1">
                        <span class="p-2">
                          <span class="text-center">Erro de integração com a totvs</span>
                        </span>
                        <span slot="reference">
                          <img src="/img/icons/icons8/ios/disconnected_danger.png" width="25px">
                        </span>
                      </el-popover>
                      <el-popover v-if="item.nsu_code && item.is_nsu_code_duplicated"
                        trigger="hover" placement="right" class="p-0 pr-0">
                        <span class="text-uppercase new-default-black-font">Duplicidade de código</span>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img class="mr-1" src="/img/icons/icons8/ios/break--v2_warning.png" width="26">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.any_has_billet && !item.any_has_pending_billet" trigger="hover" placement="left" class="p-0 pr-0">
                        <span v-if="item.installment_statistics.pending_value > 0 || item.installment_statistics.late_value > 0">
                          EMITIDO
                        </span>
                        <span v-else-if="item.installment_statistics.canceled_value > 0 || item.all_are_canceled">
                          CANCELADO
                        </span>
                        <span v-else>
                          RECEBIDO
                        </span>
                        <base-button @click.prevent="downloadBilletBatch(item.bank_billet_batch_id)" outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img v-if="item.installment_statistics.pending_value > 0 || item.installment_statistics.late_value > 0"
                            class="mr-2" src="/img/icons/barcode_warning.png" width="26">
                          <img v-else-if="item.installment_statistics.canceled_value > 0 || item.all_are_canceled"
                            class="mr-2" src="/img/icons/barcode_danger.png" width="26">
                          <img v-else class="mr-2" src="/img/icons/barcode_success.png" width="26">
                        </base-button>
                      </el-popover>
                      <el-popover v-if="item.any_has_pending_billet" trigger="hover" placement="left" class="p-0 pr-0">
                        <span>
                          EMITIR
                        </span>
                        <base-button @click.prevent="downloadBilletBatch(item.bank_billet_batch_id)" outline slot="reference" size="sm" type="secundary"
                                     class="p-0 m-0 shadow-none text-indigo">
                          <img class="mr-2" src="/img/icons/barcode.png" width="26">
                        </base-button>
                      </el-popover>
                      <img v-show="item.exists_attachments"
                        class="pointer ml-1 p-0"
                        @click.prevent="handleShowEntityAttachments(item.bill_receive_id, index)"
                        src="/img/icons/paperclip-green.png" width="26">
                      <el-popover v-if="item.observations" trigger="hover" placement="right" class="p-0 pr-0">
                        <div style="max-width: 350px;">
                          <h3 class="text-uppercase new-default-black-font" style="display: flex;align-items: center;">
                            <img class="mr-2" src="/img/icons/icons8/ios/light-on.png" width="26">
                            Observações
                          </h3>
                          <div class="new-default-black-font" style="word-break: break-word;max-width: 350px;font-size: 12px;">
                            {{ item.observations }}
                          </div>
                        </div>
                        <base-button outline slot="reference" size="sm" type="secundary"
                                     class="ml-1 p-0 mr-0 my-0 shadow-none text-indigo">
                          <img src="/img/icons/error-red.png" width="26">
                        </base-button>
                      </el-popover>
                    </div>
                  </div>
                  <div class="row mx-1 my-2">
                    <div class="col-6 p-0">
                      <div class="calc-bar-text" v-if="getPaidAmount(item).value > 0">
                        {{ getPaidAmount(item).value | currency() }} RECEBIDO
                      </div>
                    </div>
                    <div class="col-6 p-0 text-right">
                      <div class="calc-bar-text" v-if="item.installment_statistics.canceled_value > 0">
                        CANCELADO
                      </div>
                      <div class="calc-bar-text" v-if="getOpenValuesAmount(item).value > 0">
                        {{ getOpenValuesAmount(item).value | currency() }} EM ABERTO
                      </div>
                    </div>
                  </div>
                  <div class="row mx-1">
                    <div :style="{ 'width': getPaidAmount(item).percent }">
                      <hr :style="{ 'border-top-right-radius': getOpenValuesAmount(item).border, 'border-bottom-right-radius': getOpenValuesAmount(item).border }" class="green-divider">
                    </div>
                    <div :style="{ 'width': getOpenValuesAmount(item).percent }">
                      <hr :style="{ 'border-top-left-radius': getPaidAmount(item).border, 'border-bottom-left-radius': getPaidAmount(item).border }" class="red-divider">
                    </div>
                    <div style="width: 100%;" class="calc-bar-text" v-if="item.installment_statistics.canceled_value > 0">
                      <hr class="red-divider" style="border-radius: 10px;">
                    </div>
                  </div>
                  <div :class="'parcelas-' + index" class="hide mt-4 mb-1">
                    <div class="container px-0">
                      <h4 class="px-1 new-default-gray-font">Parcelas</h4>
                    </div>
                    <div class="container px-0">
                      <div style="border-radius: 15px !important;" class="px-4 py-3 mb-1 card-with-box-shadow"
                        v-for="(installment,
                          indexInstallment) in item.installments"
                          :key="indexInstallment">
                        <div class="row">
                          <div class="col-6">
                            <div class="row">
                              <div class="col-12">
                                <div class="no-break-md">
                                  <h4 style="display: inline-flex;" class="new-default-black-font">Parcela {{ installment.index }}</h4>
                                  <!--  CASO ESTEJA RECEBIDO E NÃO ESTEJA APROVADO, ENVIA O TYPE 1 PARA VERIFICAR PERMISSÃO reverse -->
                                  <div style="display: inline;" v-if="installment.status !== 8 && installment.status === 1">
                                    <base-dropdown v-if="!checkIfDisable(installment, 1)">
                                      <div class="ml-sm-3 mb-2 mb-md-0"
                                        slot="title-container"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12" alt="">
                                      </div>
                                      <div>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 10, index, indexInstallment)"
                                          class="dropdown-item">
                                          Estornar
                                        </a>
                                      </div>
                                     </base-dropdown>
                                     <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                     <div v-else
                                      class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                  <!--  CASO NÃO ESTEJA RECEBIDO E NEM APROVADO, ENVIA O TYPE 2 PARA VERIFICAR PERMISSÃO cancel e receive -->
                                  <div style="display: inline;" v-if="installment.status !== 8 && installment.status !== 1">
                                    <base-dropdown v-if="!checkIfDisable(installment, 2)">
                                      <div
                                        slot="title-container"
                                        class="ml-sm-3 mb-2 mb-md-0"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12">
                                      </div>
                                      <div>
                                        <a
                                          @click.prevent="changeEntityInstallmentStatus(installment, 1, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                          class="dropdown-item">
                                          Receber</a>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 8, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.approve_installment')"
                                          class="dropdown-item">
                                          Aprovar</a>
                                        <a @click.prevent="
                                          changeEntityInstallmentStatus(installment, 0, index, indexInstallment)"
                                          v-if="installment.status !== 0 && installment.status !== 2 && $hasPermission('bill_receive.reverse_installment')"
                                          class="dropdown-item">Pendente</a>
                                        <a @click.prevent="changeEntityInstallmentStatus(installment, 3, index, indexInstallment)"
                                          v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                          class="dropdown-item">
                                          Cancelar
                                        </a>
                                      </div>
                                    </base-dropdown>
                                    <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                    <div v-else
                                      class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                  <!-- CASO ESTEJA APROVADO -->
                                  <div style="display: inline;" v-if="installment.status === 8">
                                    {{  }}
                                    <!--  VERIFICA SOMENTE PERMISSÃO cancel e receive -->
                                    <base-dropdown v-if="loadingInstallmentStatus || ($hasPermission('bill_receive.cancel_installment') && $hasPermission('bill_receive.receive_installment'))">
                                      <div class="ml-sm-3 mb-2 mb-md-0"
                                        slot="title-container"
                                        style="display: inline-flex;align-items: center;"
                                        :class="getColor(installment.status)">
                                        <h4 class="font-weight-normal m-0 p-0 mr-2">
                                          {{ getTitle(installment.status) }}
                                        </h4>
                                        <img :src="getDropdownColor(installment.status)" width="12">
                                      </div>
                                      <div>
                                        <a @click.prevent=" changeEntityInstallmentStatus(installment, 1, index, indexInstallment)"
                                          v-if="installment.status !== 1 && $hasPermission('bill_receive.receive_installment')"
                                          class="dropdown-item">
                                          Receber
                                        </a>
                                        <a @click.prevent=" changeEntityInstallmentStatus(installment, 3, index, indexInstallment)"
                                          v-if="installment.status !== 3 && $hasPermission('bill_receive.cancel_installment')"
                                          class="dropdown-item">
                                          Cancelar
                                        </a>
                                      </div>
                                    </base-dropdown>
                                    <!--  SE NÃO TIVER PERMISSÃO, MOSTRA SOMENTE TEXTO COM SOMBRA -->
                                    <div v-else class="ml-sm-3 mb-2 mb-md-0"
                                      style="display: inline-flex;align-items: center;"
                                      :class="getColor(installment.status)">
                                      <h4 class="font-weight-normal m-0 p-0 mr-2">
                                        {{ getTitle(installment.status) }}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-12">
                                <img class="pointer" src="/img/icons/order-history.png"
                                  @click="handleShowHistory({installment_id: installment.id, type: 0})"
                                  width="25" alt="">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="row" style="float: right;display: block;">
                              <div class="col-12">
                                <div style="display: inline-block;">
                                  <h5 style="display: inline;font-size: 11px" class="mr-1 my-0 font-weight-normal new-default-gray-font">
                                    Valor Total
                                  </h5>
                                  <el-popover v-if="installment.total_value != installment.value" trigger="click" placement="left">
                                    <div class="row" style="max-width: 250px;">
                                      <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                        <img src="/img/icons/icons8/ios/info-squared.png" class="mr-2" width="20"> <b class="new-default-black-font">Info Parcela {{ installment.index }}</b>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Valor total:
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          {{ installment.value | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Descontos:
                                        </span>
                                      </div>
                                     <div class="col-6">
                                        <span v-if="installment.discounts === 'N/A' || !installment.discounts" class="h5 font-weight-normal">
                                            N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal text-danger">
                                          {{ parseFloat(installment.discounts) | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Taxas:
                                        </span>
                                      </div>
                                      <div class="col-6 ">
                                        <span v-if="installment.discounts_fees === 'N/A' || !installment.discounts_fees" class="h5 font-weight-normal">
                                            N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal">
                                            {{ parseFloat(installment.discounts_fees) | currency() }}
                                          </b>
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Juros/multa:
                                        </span>
                                      </div>
                                      <div class="col-6">
                                        <span v-if="installment.fees_fines === 'N/A' || !installment.fees_fines" class="h5 font-weight-normal">
                                          N/A
                                        </span>
                                        <span v-else class="h5 font-weight-normal">
                                          {{ parseFloat(installment.fees_fines) | currency() }}
                                        </span>
                                      </div>
                                      <div class="col-12">
                                        <hr class="mb-2 mt-2">
                                      </div>
                                      <div class="col-6">
                                        <span class="h5 font-weight-normal">
                                          Valor final:
                                        </span>
                                      </div>
                                      <div class="col-6 ">
                                        <span class="h5 font-weight-normal">
                                          <b>{{ installment.total_value | currency() }}</b>
                                        </span>
                                      </div>
                                    </div>
                                    <img v-if="installment.fees_fines > 0 || installment.discounts_fees > 0"
                                      slot="reference" style="display: inline;" src="/img/icons/discount_success.png" class="pointer" width="20" alt="">
                                    <img v-else
                                      slot="reference" style="display: inline;" src="/img/icons/discount--v1_danger.png" class="pointer" width="20" alt="">
                                  </el-popover>
                                </div>
                              </div>
                              <div class="col-12">
                                <h2 class="my-0 mt-n1" style="font-size: 18px;">{{ installment.value | currency() }}</h2>
                              </div>
                              <div class="col-12">
                                <h5 style="font-size: 11px" class="my-0 mt-n1 font-weight-normal new-default-gray-font">{{ installment.due_date | parseDate('DD MMM YYYY') }}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 align-items-center">
                  <div class="col-6">
                    <el-popover v-if="item.nsu_code"
                      trigger="click" placement="right" class="">
                      <span class="text-uppercase new-default-black-font">Código: {{ item.nsu_code }}</span>
                      <base-button outline slot="reference" size="sm" type="secundary"
                                   class="ml-3 mt-n5 shadow-none text-indigo">
                        <img src="/img/icons/break-v2.png" width="30">
                      </base-button>
                    </el-popover>
                  </div>
                  <div class="col-6 text-center" style="text-align: right !important;padding: 0px">
                    <div class="card-blue-bottom text-center dropdown-toggle" @click.prevent="showParcelas(index)">
                      <a style="height: 40px; display: inline-block;"
                        href="#">
                        <h3 style="text-transform: none;padding: 8px;padding-right: 5px;">
                          <span class="mr-1">
                            <img src="/img/icons/icons8/ios/how-many-quest--v2.png" width="22px">
                          </span>
                          <small :id="'parcelas-toggle-' + index" class="mr-1 text-white" style="font-size: 14px">
                            PARCELAS
                          </small>
                        </h3>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row card-wrapper" v-show="isLoading">
            <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
          </div>
          <puzl-empty-data v-if="!bill_receives.length && !isLoading"/>
        </div>
      </card>
    </div>
    <ModalHistory ref="ModalHistory"/>
    <ModalPixQRcode ref="modalPixQrcode" @updated="$emit('init')"/>
    <ModalAlterStatus ref="ModalAlterStatus"
                      :show="showModalAlterStatus"
                      @close="closeModalAlterStatus"
                      @saveAndClose="saveAndCloseAlterInstallment"
    />
    <modal-generate-billet @fetch="fetch" ref="modalGenerateBillet"/>
    <modal-generate-billet-has-generated ref="modalGenerateBilletHasGenerated"/>
    <EditPayment @showInvoices="getInvoices" ref="editPayment"/>
    <ModalAttachment ref="modalAttachment"/>
    <ModalAddInvoice ref="modalAddInvoice"/>
    <modal-edit-entity @updated="$emit('init')" ref="modalEditEntity"/>
    <modal-confirm-payment
      @updateInstallment="updateInstallment"
      ref="modalConfirmPayment"
    />
    <modal-share-payment
      @fetch="fetch"
      ref="modalSharePayment"
    />
    <ModalEntityBillReceiveIntegrationInfo ref="modalEntityBillReceiveIntegrationInfo" />
    <EntityBillReceiveHistoryListModal ref="entityBillReceiveHistoryListModal" />
  </div>
</template>

<script>
import ModalAlterStatus from '../../../../Financial/BillReceive/Shared/Modals/_ModalAlterStatus'
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditPayment from "./_Edit";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment";
import ModalGenerateBillet from '../../../../Financial/BillReceive/Shared/Modals/_ModalGenerateBillet'
import ModalGenerateBilletHasGenerated from '../../../../Financial/BillReceive/Shared/Modals/_ModalGenerateBilletHasGenerated'
import ModalHistory from "../../../../Financial/BillReceive/Shared/Modals/_ModalHistory";
import ModalPixQRcode from './_ModalPixQRcode.vue';
import ModalAddInvoice from './_ModalAddInvoice'
import PuzlEmptyData from "../../../../../../components/PuzlEmptyData";
import ModalConfirmPayment from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalEntityBillReceiveConfirmPayment';
import ModalSharePayment from './_ModalSharePayment'
import {base_url_ms} from '@/plugins'
import ModalEditEntity from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalEditEntity'
import { hasTotvs } from "@/plugins/microservices/totvs";
import ModalEntityBillReceiveIntegrationInfo from "@/views/Modules/Totvs/EntityBillReceive/_ModalEntityBillReceiveIntegrationInfo";
import { TotvsStatusEnum } from "@/enum/TotvsStatusEnum";
import EntityBillReceiveHistoryListModal from "../../../../../../modules/financial/entity-bill-receive/aggregates/entity-bill-receive-history/views/list/EntityBillReceiveHistoryListModal"
import { AppIcon } from "../../../../../../components/AppGlobal";
import { date } from "../../../../../../helpers";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  name: "List",
  components: {
    PuzlEmptyData,
    SkeletonPuzlGrid,
    ModalAlterStatus,
    ModalHistory,
    EditPayment,
    ModalGenerateBillet,
    ModalGenerateBilletHasGenerated,
    ModalAttachment,
    ModalAddInvoice,
    ModalConfirmPayment,
    ModalSharePayment,
    ModalEditEntity,
    ModalPixQRcode,
    ModalEntityBillReceiveIntegrationInfo,
    EntityBillReceiveHistoryListModal,
    AppIcon,
  },
  data() {
    return {
      type: 1,
      BillReceiveStatus: {
        '0': "Pendente",
        '1': "Receber",
        '3': "Cancelado",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
        '8': "Aprovar",
      },
      PaymentInstallmentStatus: {
        '0': "pending",
        '1': "received",
        '2': "overdue",
        '3': "canceled",
        '4': "serasa",
        '5': "registry",
        '6': "protested",
        '7': "legal",
        '8': "approved",
        '9': "denied"
      },
      base_url_ms: base_url_ms(),
      loadingInstallmentStatus: false,
      showModalAlterStatus: false,
      hasTotvs: hasTotvs(),
      TotvsStatusEnum: TotvsStatusEnum,
      date: date,
    };
  },
  computed: {
    ...mapGetters({
      bill_receives: "billReceive/getEntities",
      $_commercial_permission: "commercialPermission/show",
      $_general_settings: "generalSetting/show",
    }),
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    pixDisabled(billReceive) {
      let count = 0;
      for(let i in billReceive.installments) {
        if(billReceive.installments[i].status == 0 || billReceive.installments[i].status == 2) {
          count++;
        }
      }
      return billReceive.total_installment > 1 || count < 1;
    },
    checkIfDisable(installment, type){
      if(type !== 1){
        return (
          this.loadingInstallmentStatus
          || !this.$hasPermission('bill_receive.reverse_installment')
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
        );
      } else {
        return (
          this.loadingInstallmentStatus
          || (!this.$hasPermission('bill_receive.receive_installment') && !this.$hasPermission('bill_receive.cancel_installment'))
          || ['canceled', 'approved', 'denied'].includes(this.PaymentInstallmentStatus[installment.status])
        );
      }
    },
    getPaidAmount(item){
      return {
        value: item.installment_statistics.approved_value + item.installment_statistics.pay_value,
        percent: (item.installment_statistics.approved_value + item.installment_statistics.pay_value) / (item.total_value/100) + '%',
        border: ((item.installment_statistics.approved_value + item.installment_statistics.pay_value) / (item.total_value/100)) == 0 ? '10px' : '0px'
      };
    },
    getOpenValuesAmount(item){
      return {
        value: item.installment_statistics.pending_value + item.installment_statistics.late_value,
        percent: (item.installment_statistics.pending_value + item.installment_statistics.late_value) / (item.total_value/100) + '%',
        border: ((item.installment_statistics.pending_value + item.installment_statistics.late_value) / (item.total_value/100)) == 0 ? '10px' : '0px'
      };
    },
    async handleShowEntityAttachments(bill_receive_id, index) {
      let loader = this.$loading.show()
      await this.getEntityAttachments(bill_receive_id, 0, index)
      const item = this.bill_receives[index]
      loader.hide()
      this.$refs.modalAttachment.openAttachment(item.attachments)
    },
    async getEntityAttachments(id, type, index) {
      if (!this.bill_receives[index].attachments) {
        this.bill_receives[index].attachments = 1;
        await this.$store
          .dispatch("billReceive/getAttachments", {
            id: id,
            type: type
          })
          .then(response => {
            this.bill_receives[index].attachments = response.data;
            this.$forceUpdate();
          });
      }
    },
    handleEditEntity(id) {
      const bill_receive = this.bill_receives.find((item) => item.id === id)
      // Se o usuário não tiver permissão para editar recebimentos, eo registro não estiver como revisão, retorna erro
      if (
        ! this.$_commercial_permission.is_receipt_editing_allowed &&
        !((bill_receive.installment_statistics.late_value > 0 || bill_receive.installment_statistics.pending_value > 0) && bill_receive.needs_review)
      )
      {
        this.$notify({
          type: 'danger',
          message: 'Usuário não tem permissão para editar o recebimento.'
        });
        return;
      }

      // O usuário não pode editar um registro com status "Aprovado"
      // se não tiver permissão para aprovar antecipações.
      const billReceiveStatusApproved = 8;
      const hasBillReceiveStatusApproved = bill_receive.installments.some(installment => installment.status == billReceiveStatusApproved);
      const userCannotEdit = hasBillReceiveStatusApproved && !this.$hasPermission('bill_receive.approve_installment');
      if (userCannotEdit) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para EDITAR esse registro.'
        });
        return;
      }

      this.$refs.modalEditEntity.handleCreateModal({
        id: bill_receive.entity_id,
        entity_name: bill_receive.entity_name,
        document: bill_receive.document
      }, bill_receive.bill_receive_id)
    },
    handleModalPix(id) {
      const bill_receive = this.bill_receives.find((item) => item.id === id)
      this.$refs.modalPixQrcode.openModal(bill_receive);
    },
    handleDeleteEntity(id, index) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("billReceive/destroyEntity", id)
              .then(response => {
                this.$Progress.finish();
                this.bill_receives.splice(index, 1)
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    showParcelas(index){
      this.$el.querySelector('.parcelas-' + index).classList.toggle('hide');

      if(this.$el.querySelector('.parcelas-' + index).classList.contains('hide')){
        this.$el.querySelector('#parcelas-toggle-' +  index).innerHTML = "PARCELAS";
      } else {
        this.$el.querySelector('#parcelas-toggle-' +  index).innerHTML = "OCULTAR";
      }
    },
    /**
     * Aba recebimentos
     * @param {object} filter
     */
    fetch(filter = null) {
      this.$Progress.start();
      this.$store
        .dispatch(
          "contractProposalPayment/getByContractProposal",
          {
            uuid: this.$route.params.contract_proposal_uuid,
            filter: filter
          }
        )
        .then(response => {
          this.$Progress.finish();
        })
        .catch(error => {
          this.$Progress.finish();
        });
    },
    paidText(payment) {
      if(
        (payment.installment_statistics.late_value > 0 || payment.installment_statistics.pending_value > 0) &&
        payment.needs_review
      ) {
        return {
          text: 'Revisão',
          type: 'mini-card-yellow',
        }
      }
      if (payment.installment_statistics.late_value > 0) {
        return {
          text: 'Atrasado',
          type: 'mini-card-red',
          img: '/img/icons/hourglass-white.png'
        }
      }
      if (payment.installment_statistics.pending_value > 0) {
        return {
          text: 'Pendente',
          type: 'mini-card-yellow',
          img: '/img/icons/hourglass-white.png'
        }
      }
      if (payment.installment_statistics.canceled_value > 0) {
        return {
          text: 'Cancelado',
          type: 'mini-card-red',
          img: '/img/icons/cancel-white.png'
        }
      }
      if (payment.installment_statistics.approved_value == payment.total_value) {
        return {
          text: 'Aprovado',
          type: 'mini-card-blue',
          img: '/img/icons/thumb-up--v1-white.png'
        }
      }
      if (payment.installment_statistics.denied_value == payment.total_value) {
        return {
          text: 'Negado',
          type: 'mini-card-red',
          img: '/img/icons/icons8/ios/thumbs-down.png'
        }
      }
      return {
        text: 'Recebido',
        type: 'mini-card-green',
        img: '/img/icons/icons8/ios/ok--v1.png'
      }
    },
    closeModalAlterStatus() {
      this.loadingInstallmentStatus = false
      this.showModalAlterStatus = false
    },
    updateInstallment(params) {
      if (this.type === 1) {
        let bill_receive_id = this.payments[params.index].bill_receive_id
        this.payments[params.index] = params.data
        this.payments[params.index].bill_receive_id = bill_receive_id
      } else {
        let bill_receive_id = this.bill_receives[params.index].bill_receive_id
        this.bill_receives[params.index] = params.data.entity
        this.bill_receives[params.index].bill_receive_id = bill_receive_id
      }
      this.$forceUpdate()
    },
    handleShowHistory(payment) {
      this.$refs.ModalHistory.openModal(payment)
    },
    saveAndCloseAlterInstallment(params) {
      switch (params.type) {
        case 0:
          params.response.competence = this.bill_receives[params.indexPayment].competence;
          this.bill_receives[params.indexPayment] = params.response
          this.loadingInstallmentStatus = false
          this.showModalAlterStatus = false
          break;
        case 1:
          params.response.invoices = this.payments[params.indexPayment].invoices ?? [];
          this.payments[params.indexPayment] = params.response
          this.loadingInstallmentStatus = false
          this.payments[params.indexPayment].installments[params.index].status = params.status;
          this.showModalAlterStatus = false
          if ([0, 2, 1, 8].includes(parseInt(params.status))) {
            this.$parent.getContractProposal()
          }
          if (params.status === 3) {
            this.$parent.contract_proposal.pending_launch_value += parseFloat(params.response.total_value);
          }
          break;
      }
    },
    downloadBilletBatch(bank_billet_batch_id) {
      if (!bank_billet_batch_id) {
        this.$notify({
          type: 'warning',
          message: 'O lote ainda não foi gerado. Favor entre em contato com o suporte.'
        });
      }
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('bankBillet/getPdfFileUrl', bank_billet_batch_id)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `Lote ${bank_billet_batch_id}.pdf`);
          await link.click();
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide()
        });
    },
    getInvoices(payment) {
      payment.invoices = 1
      this.$forceUpdate()
      this.$store
        .dispatch("contractProposalPayment/getInvoices", payment.id)
        .then(response => {
          this.$forceUpdate()
          payment.invoices = response.data
        });
    },
    async changeEntityInstallmentStatus(installment, status, indexPayment, indexInstallment) {
      await this.$store.dispatch('generalSetting/show')
      status = parseInt(status)
      if (installment.billet && installment.billet.bank_billet.status === 1 && status === 1) {
        return this.$Swal.confirmAction('O Boleto já foi emitido para esta parcela, se continuar, o boleto será cancelado.', ['Sim', 'Não'])
          .then((result) => {
            if (result.isConfirmed) {
              let loader = this.$loading.show()
              return Promise.all([
                this.$store.dispatch("billReceive/showLight", this.bill_receives[indexPayment].bill_receive_id),
                this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant",  {
                  allocated: 1,
                  type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
                  type_id: this.$_general_settings.show_bank_account_by_issuer ? this.bill_receives[indexPayment].issuer_id : this.bill_receives[indexPayment].company_plant_id
                })
              ]).then(() => {
                loader.hide()
                let disabled_change_bank_account = this.bill_receives[indexPayment].installment_statistics.pay_value > 0
                return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account, 1)
              })
            }
          }).catch()
      }
      if (this.loadingInstallmentStatus) {
        return;
      }
      const bill_receive = this.bill_receives[indexPayment]
      // if (bill_receive.any_has_paid_billet && status != 1) {
      //   this.$notify({
      //     type: "warning",
      //     message: "Não foi possível alterar o status. Há uma parcela com pagamento confirmado para este pagamento"
      //   });
      //   return;
      // }
      if (parseInt(status) === 1) {
        let loader = this.$loading.show()
        return Promise.all([
          this.$store.dispatch("billReceive/showLight", this.bill_receives[indexPayment].bill_receive_id),
          this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant",
            {
              allocated: 1,
              type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
              type_id: this.$_general_settings.show_bank_account_by_issuer ? this.bill_receives[indexPayment].issuer_id : this.bill_receives[indexPayment].company_plant_id
            })
        ]).then(() => {
          loader.hide()
          let disabled_change_bank_account = this.bill_receives[indexPayment].installment_statistics.pay_value > 0
          return this.$refs.modalConfirmPayment.handleCreateModal(installment.id, indexPayment, installment.total_value, disabled_change_bank_account)
        })
      }
      this.loadingInstallmentStatus = true;
      this.$Progress.start();
      if (status != 1) {
        this.showModalAlterStatus = true
        return this.$refs.ModalAlterStatus.setBillReceive({
          id: installment.id,
          type: 0,
          status: status,
          indexPayment: indexPayment,
          index: indexInstallment,
          received_at: installment.paid_at ? installment.paid_at : null
        })
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: installment.id,
          status: status,
          type: 0
        })
        .then(response => {
          installment.status = status;
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.bill_receives[indexPayment] = response.data
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.loadingInstallmentStatus = false;
          this.$Progress.finish();
        });
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "mini-card-yellow";
        case 1:
          return "mini-card-green";
        case 2:
        case 9:
        case 3:
          return "mini-card-red";
        case 8:
          return 'mini-card-blue';
      }
    },
    getDropdownColor(status) {
      switch (status) {
        case 0:
          return "/img/icons/expand-arrow--v2_warning.png";
        case 1:
          return "/img/icons/expand-arrow--v2_success.png";
        case 2:
        case 3:
        case 9:
          return "/img/icons/expand-arrow--v2_danger.png";
        case 8:
          return "/img/icons/expand-arrow--v2_primary.png";
      }
    },
    downloadAdvanceReceipt(entity_bill_receive_id) {
      this.$Progress.start();
      let loader = this.$loading.show();
      this.$store.dispatch('billingInvoice/downloadAdvanceReceipt', {
          entity_bill_receive_id: entity_bill_receive_id
        }
      ).then(response => {
        let blob = new Blob([response], {type: 'application/pdf'});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank');
      }).finally(() => {
        loader.hide();
        this.$Progress.finish();
      });
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Recebido";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
        case 8:
          return "Aprovado";
        case 9:
          return "Negado";
      }
    },

    handleModalEntityBillReceiveIntegrationInfo(entity_bill_receive_id) {
      this.$refs.modalEntityBillReceiveIntegrationInfo.openModal(entity_bill_receive_id);
    },

    handleEntityBillReceiveHistoryListModal(entity_bill_receive_id) {
      this.$refs.entityBillReceiveHistoryListModal.openModal(entity_bill_receive_id);
    }
  },
  mounted() {
    this.type = 0
    this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
  },
  beforeUpdate() {
    this.$helper.keepGlobalUser(this);
  }
};
</script>

<style scoped>
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 190px;
  min-width: 190px;
  float: right;
}

.hide {
  display: none;
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 1rem !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4{
  font-size: 12px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.new-default-gray-font {
  color: #606062;
}

.green-divider {
  background-color:  #50bd40;
  color: #50bd40;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.red-divider {
  background-color: #f26257;
  color: #f26257;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calc-bar-text {
  color: #606062;
  font-size: 11px;
}

.payment-method {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 16px;
  color: #2B2D32;
  text-wrap: nowrap;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.payment-term {
  border-left: 1px solid #E8E8E8;
  font-weight: 400;
  margin-top: 0.5rem;
  font-size: 12px;
  line-height: 16px;
  padding-left: 0.4rem;
  color: #606062;
  height: 1rem;
}

.popover-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #606062;
}

@media (min-width: 1200px) {
  .no-break-md {
    white-space: nowrap;
  }
}
</style>
