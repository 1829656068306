import store from "../../../../../../shared/libraries/store";
import { initDetachedListPagerType, DetachedListFilterType, DetachedListPagerType, DetachedType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../../shared/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/financial/billing/detached",
  SINGLE: "/financial/single",
  REPORT: "/financial/billing/detached/excel",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "FINANCIAL_ITEM",
  LISTED: "FINANCIAL_LISTED",
  IS_LISTING: "FINANCIAL_IS_LISTING",
  IS_FINDING: "FINANCIAL_IS_FINDING",
  IS_SAVING: "FINANCIAL_IS_SAVING",
  IS_REMOVING: "FINANCIAL_IS_REMOVING",
  PAGE_STATES: "FINANCIAL_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?DetachedType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {DetachedListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initDetachedListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: DetachedListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {DetachedType} item
   * @returns {DetachedType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {DetachedListPagerType} listed
   * @returns {DetachedListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {DetachedType} created
   * @returns {DetachedType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {DetachedListPagerType} listed
   * @returns {DetachedListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {DetachedType} updated
   * @returns {DetachedType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: DetachedListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {DetachedType} data
   * @returns {Promise<DetachedType>}
   */
  create: async (data) => await createSource(data, mutations, urls.SINGLE + "+microservice"),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?DetachedType>}
   */
  find: async (id) => await findSource(id, mutations, urls.SINGLE + "+microservice"),

  /**
   * Listar
   *
   * @param {DetachedListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?DetachedListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.SINGLE + "+microservice"),

  /**
   * Atualizar por ID
   *
   * @param {DetachedType} data
   * @param {number} id
   * @returns {Promise<DetachedType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.SINGLE + "+microservice"),

  /**
   * Aprovar
   *
   * @param {number} id
   * @param {string} status
   * @returns {Promise<DetachedType>}
   */
  approve: async (id, status) => {
    try {
      mutations.setIsSaving(true);

      const url = `financial/billing/detached/approve/${id}`;
      const res = await httpClient.put(url, { status });
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Gerar relatório Excel
   *
   * @param {DetachedListFilterType} filter
   * @returns {Promise<void>}
   */
  getReportExcel: async (filter) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.REPORT}/1`;
      const res = await httpClient.post(url, { filter });
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data;
    } finally {
      mutations.setIsSaving(false);
    }
  },
}

export default {
  actions,
  getters,
  mutations,
};