<template>
  <div>
    <modal size="xl"
      :show.sync="isOpen"
      :backgroundColor="'#F2F4F9'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/wallet.png" alt="wallet" width="25px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <div @click="isOpen=false;">
                <AppIcon icon="close" width="17" class="pointer" />
              </div>
            </div>
          </div>
        </template>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-4 contract-info-section">
            <div class="info-client-section" v-if="$_contract_proposal && $_contract_proposal.entity">
              <p class="p-0 m-0 puzl-black" style="font-size: 16px;font-weight: 500;" >{{ $_contract_proposal.entity.entity_name }}</p>
              <div class="d-flex align-items-center" style="gap: 10px;">
                <div>
                  <span class="w-100 mr-1 puzl-black" style="font-size: 16px;" >{{ $_contract_proposal.entity.document }}</span>
                </div>
                <div v-if="contract_proposal_uuid" class="access-contract-button pointer ml-1">
                  <div class="mx-2 my-0 d-flex align-items-center" style="gap: 5px;" >
                    <section class="mb-1">
                      <img src="/img/icons/icons8/ios/external-link_dark.png" alt="external-link_dark" width="19px">
                    </section>
                    <a :href="`/commercial/contract-proposal/invoices/${contract_proposal_uuid}`" style="font-size: 12px;" class="mid-section puzl-black">
                      Acessar Contrato
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="contract-select-section my-3">
              <base-input input-group-classes="input-group-sm">
                <puzl-select
                  label="$code$ | $construction_name$ | $customer_name$"
                  :labelMask="true"
                  :loading="loading"
                  :disabled="loading || filter.sync_hub === EntityFinancialHubEnum.keys.SAP"
                  :items="contract_proposals"
                  @input="listItems;getContractUuid($event)"
                  v-model="filter.contract_proposal_id"
                  placeholder="Contratos"
                />
              </base-input>
            </div>
            <div @click="getSapEntityFinancial()" v-if="hasSapValue" class="total-values-section d-flex flex-column" style="gap: 5px;">
              <div class="contract-value-actions-button pointer col-4">
                  <span class="mx-4 d-flex align-items-center justify-content-center action-button-inside-value-cards puzl-black" style="" @click.prevent="openModalCreditLimit">
                    SAP
                  </span>
              </div>
            </div>
            <div v-if="$_history?.sync_entity_error" class="text-center mt-2"> 
              <div class="col-12 mb-2"> 
                <img src="/img/icons/error-red.png" width="25" height="25" />
              </div>
              <div class="col-12">
                <span class="font-weight-400 mb-0 new-default-black-font">Atenção! O Cliente/Fornecedor possui erro de integração </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-8 contract-values-section d-flex flex-column" style="gap: 18px;">
            <div class="d-flex contract-values-cards-section" style="gap: 7px;">
              <div
                class="contract-value-card total-balance-card w-100"
                :class="
                  $_history?.net_total_balance > 0
                  ? 'positive-total-balance'
                  : $_history?.net_total_balance < 0
                  ? 'negative-total-balance'
                  : 'total-balance'
                "
              >
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section">
                    <img
                      :src="
                        $_history?.net_total_balance > 0
                          ? '/img/icons/icons8/ios/ring-clock_success.png'
                          : $_history?.net_total_balance < 0
                          ? '/img/icons/icons8/ios/ring-clock_danger.png'
                          : '/img/icons/icons8/ios/ring-clock_dark.png'
                      "
                      alt="ring-clock_danger"
                      width="24px"
                    />
                  </section>
                  <section class="mt-2" >
                    <section
                      class="mid-section"
                      :class="
                        $_history?.net_total_balance > 0
                        ? 'puzl-success'
                        : $_history?.net_total_balance < 0
                        ? 'puzl-danger'
                        : 'puzl-black'
                      ">
                      Saldo Total
                    </section>
                    <span
                      style="font-weight: 500;font-size: 16px;"
                      :class="
                        $_history?.net_total_balance > 0
                        ? 'puzl-success'
                        : $_history?.net_total_balance < 0
                        ? 'puzl-danger'
                        : 'puzl-black'
                      ">
                      {{ $_history?.net_total_balance | currency }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;">
                    <img
                      src="/img/icons/icons8/ios/us-dollar-circled--v1.png"
                      alt="us-dollar-circled--v1"
                      width="23px"
                      v-show="!$hasPrivilege(1)"
                    />
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <span>
                              <span style="font-weight: 500;color: #2B2D32;font-size: 14px;">
                                VALIDAÇÃO PUZL
                              </span>
                              <hr class="hr-popover-puzl-validation">
                              <div class="d-flex my-2" style="gap: 10px;">
                                <div class="contract-value-actions-button">
                                  <span
                                    class="mx-2 my-1 puzl-black pointer action-button-inside-value-cards"
                                    style="font-size: 13px;"
                                    @click="handleCopyAnticipationBalanceTestToAnticipationBalance"
                                  >
                                    COPIAR
                                  </span>
                                </div>
                                <div class="contract-value-actions-button" @click="handleRecalculate">
                                  <span class="mx-2 my-1 puzl-black pointer action-button-inside-value-cards" style="font-size: 13px;">
                                    RECALCULAR
                                  </span>
                                </div>
                              </div>
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img
                              v-if="$hasPrivilege(1)"
                              src="/img/icons/icons8/ios/us-dollar-circled--v1.png"
                              alt="us-dollar-circled--v1"
                              width="23px"
                            />
                          </base-button>
                        </el-popover>
                      </div>
                    <div v-if="$hasPermission('bill_receive.anticipation_balance_transfer')" class="contract-value-actions-button pointer ml-auto">
                      <span class="mx-3 puzl-black d-flex align-items-center action-button-inside-value-cards" style="font-size: 10px;" @click.prevent="openModalAnticipationBalanceTransfer">
                        TRANSFERIR
                      </span>
                    </div>
                  </section>
                  <section class="mt-2">
                    <section class="mid-section puzl-black">
                      CRÉD. E ANTECIP.
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.anticipation_balance | currency }}
                    </span>
                    <br>
                    <span v-if="$_user.internal_type === 1">
                      {{$_history.anticipation_balance_test | currency  }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/icons8/ios/add-square_success.png"
                      alt="add-square_success"
                      width="23px"
                    />
                    <div class="contract-value-actions-button pointer ml-auto">
                      <span class="mx-4 d-flex align-items-center justify-content-center action-button-inside-value-cards puzl-black" style="font-size: 10px;" @click.prevent="openModalCreditLimit">
                        ALTERAR
                      </span>
                    </div>
                  </section>
                  <section class="mt-2" >
                    <section class="mid-section puzl-black">
                      LIMITE DE CRÉD.
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.free_credit | currency }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/icons8/ios/u-turn-to-left_danger.png"
                      alt="u-turn-to-left_danger"
                      width="23px"
                    />
                    <div class="contract-value-actions-button ml-auto pointer">
                      <span
                        v-if="source_contract_proposal_id || ($_contract_proposal && $_contract_proposal.entity)"
                          class="mx-4 d-flex align-items-center action-button-inside-value-cards justify-content-center puzl-black"
                          style="font-size: 10px;"
                          @click.prevent="source_contract_proposal_id
                          ? $router.push(`/configuration/entry-launch/contract-proposal/${source_contract_proposal_id}`)
                          : $_contract_proposal && $_contract_proposal.entity
                          ? $router.push(`/configuration/entry-launch/entity/${$_contract_proposal.entity.uuid}`)
                          : ''
                        "
                      >
                        DEVOLVER
                      </span>
                    </div>
                  </section>
                  <section class="mt-2" >
                    <section class="mid-section puzl-black">
                      DEVOLUÇÕES
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ (($_history?.recipe_devolution || 0) * -1) | currency }}
                    </span>
                  </section>
                </div>
              </div>
            </div>
            <div class="d-flex contract-values-cards-section" style="gap: 7px;" >
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/icons8/ios/cheque_dark.png"
                      alt="cheque_dark"
                      width="23px"
                    />
                  </section>
                  <section class="mt-2" >
                    <section class="mid-section puzl-black">
                      A FATURAR
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.opened_billing | currency }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/icons8/ios/receive-dollar_success.png"
                      alt="receive-dollar_success"
                      width="23px"
                    />
                  </section>
                  <section class="mt-2" >
                    <section class="mid-section puzl-black">
                      RECEBIDO
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.bill_receive_total_paid | currency }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/hourglass-yellow.png"
                      alt="hourglass-yellow"
                      width="23px"
                    />
                  </section>
                  <section class="mt-2">
                    <section class="mid-section puzl-black">
                      PENDENTE
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.bill_receive_total_pending | currency }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="contract-value-card total-balance w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;" >
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <img
                      src="/img/icons/icons8/ios/ring-clock_danger.png"
                      alt="ring-clock_danger"
                      width="23px"
                    />
                  </section>
                  <section class="mt-2" >
                    <section class="mid-section puzl-black">
                      ATRASADO
                    </section>
                    <span style="font-weight: 500;font-size: 16px;" class="puzl-black">
                      {{ $_history?.bill_receive_total_overdue | currency }}
                    </span>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppTabSelect
        :items="tabSelectItems"
        :isShowViewTypes="false"
        @onTabSelectItemClick="handleChangeTableType"
      >
        <img src="/img/icons/excel.png" class="pointer" width="22" alt="excel" @click.prevent="getExcelReport">
      </AppTabSelect>
      <div class="container container-fluid" v-show="table_type === EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY">
        <div class="entity-anticipation-balance-history-table">
          <PuzlEmptyData v-if="!$_entity_anticipation_balance_histories.length && !loadingSkeleton" />
          <div v-else class="row">
            <ShadedTable :length="$_entity_anticipation_balance_histories.length" :headers="headersEntityAnticipationBalanceHistory" :loading="loadingSkeleton" class="border-table-entity" >
              <template v-for="(item, indexItem) in $_entity_anticipation_balance_histories" :slot="indexItem" >
                <ShadedCol style="width: 15%;height: fit-content;">
                  <div class="d-flex align-items-center">
                    <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                      {{ date.make(item.operation_date).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                    </span>
                    <div class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <span>
                              <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                                USUÁRIO
                              </span>
                              <hr class="hr-popover-user">
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ item.user_name }} </span>
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold">
                    {{ item.origin }}
                  </span>
                </ShadedCol>
                <ShadedCol style="width: 45%;height: fit-content;">
                  <div class="d-flex align-items-center">
                    <span class="text-left content-table-semi-bold">
                      {{ item.description }}
                    </span>
                    <div v-if="item.observations !== null"class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <div>
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ item.observations }} </span>
                            </div>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/chat-bubble.png" alt="chat-bubble" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold text-center" :class="`text-${EntityAnticipationBalanceHistoryTypeEnum.getColor(item.type)}`">
                    {{ EntityAnticipationBalanceHistoryTypeEnum.getTitle(item.type) }}
                  </span>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold">
                    {{ item.value | currency }}
                  </span>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <NewPagination class="ml-auto" @navegate="navegate" :source="source" :loading="loadingSkeleton" v-if="$_entity_anticipation_balance_histories.length" />
        </div>
      </div>
      <div class="container container-fluid" v-show="table_type === EntityTableTypeEnum.keys.ENTITY_CREDIT_HISTORY">
        <div class="entity-credit-history-table">
        <PuzlEmptyData v-if="!$_credit_history.length && !loadingSkeleton" />
          <div v-else class="row">
            <ShadedTable :length="$_credit_history.length" :headers="headersCreditHistory" :loading="loadingSkeleton" class="border-table-entity" >
              <template v-for="(itemCreditHistory, indexItem) in $_credit_history" :slot="indexItem" >
                <ShadedCol style="width: 15%;height: fit-content;">
                  <div class="d-flex align-items-center">
                    <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                      {{ date.make(itemCreditHistory.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                    </span>
                    <div class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <span>
                              <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                                USUÁRIO
                              </span>
                              <hr class="hr-popover-user">
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ itemCreditHistory.user_name }} </span>
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold">
                    <div class="d-flex">
                      <span class="text-left content-table-semi-bold">
                        {{ date.make(itemCreditHistory.due_date).format(date.FORMAT.ABBREVIATED_DATE) }}
                      </span>
                    </div>
                    <div v-if="itemCreditHistory.observation !== null"class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <div>
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ itemCreditHistory.observation }} </span>
                            </div>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/chat-bubble.png" alt="chat-bubble" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </span>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold">
                    {{ itemCreditHistory.credit | currency }}
                  </span>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <pagination class="ml-auto" @navegate="creditHistoryNavegate" :source="source" :loading="loadingSkeleton" v-if="$_credit_history.length" />
        </div>
      </div>
      <div class="container container-fluid" v-show="table_type === EntityTableTypeEnum.keys.RECIPE_DEVOLUTION">
        <div class="entity-credit-history-table">
        <PuzlEmptyData v-if="!$_recipe_devolution_items.length && !loadingSkeleton" />
          <div v-else class="row">
            <ShadedTable :length="$_recipe_devolution_items.length" :headers="headersRecipeDevolutions" :loading="loadingSkeleton" class="border-table-entity" >
              <template v-for="(itemDevolution, indexItem) in $_recipe_devolution_items" :slot="indexItem" >
                <ShadedCol style="width: 15%;height: fit-content;">
                  <div class="d-flex align-items-center">
                    <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                      {{ date.make(itemDevolution.entry_date).format(date.FORMAT.ABBREVIATED_DATE) }}
                    </span>
                    <div class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <span>
                              <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                                USUÁRIO
                              </span>
                              <hr class="hr-popover-user">
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ itemDevolution.user_name }} </span>
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <span v-if="itemDevolution.operation_source_is_generate_financial" class="content-table-semi-bold">
                    {{ itemDevolution.payment_method_name }}
                    {{
                      `| ${itemDevolution.bill_pay_installments_quantity} Parcela${itemDevolution.bill_pay_installments_quantity > 1 ? "s" : ""}`
                    }}
                  </span>
                  <span v-else class="content-table-semi-bold">
                    Sem financeiro
                  </span>
                  <div v-if="itemDevolution.operation_source_id">
                    <el-popover
                        class=""
                        placement="right"
                        trigger="click"
                      >
                        <div>
                          <span>
                            <span style="font-weight: 500;color: #2B2D32;font-size: 12px;">
                              {{ itemDevolution.operation_source_name }}
                            </span>
                            <hr class="hr-popover-user">
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Gera Financeiro
                              </span>
                              <img :src="itemDevolution.operation_source_is_generate_financial ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Movimenta Estoque
                              </span>
                              <img :src="itemDevolution.operation_source_is_generate_stock ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Pagamento Antecipado
                              </span>
                              <img :src="itemDevolution.operation_source_is_entry_value ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                            <div class="d-flex justify-content-between my-1">
                              <span style="font-weight: 300;font-size: 10px;color: #2B2D32">
                                Contabiliza
                              </span>
                              <img :src="itemDevolution.operation_source_is_generate_tax_book ? '/img/icons/icons8/ios/checked-checkbox--v1.png' : '/img/icons/icons8/ios/unavailable_danger.png'" width="16px">
                            </div>
                          </span>
                        </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/brand/logo.png" alt="logo" width="20px">
                          </base-button>
                        </el-popover>
                  </div>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span class="content-table-semi-bold text-danger">
                    {{
                      ((itemDevolution.operation_source_is_generate_financial
                        ? itemDevolution.bill_pay_installments_final_value
                        : itemDevolution.final_value) *
                        -1)
                        | currency
                    }}
                  </span>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <pagination class="ml-auto" @navegate="recipeDevolutionNavegate" :source="source" :loading="loadingSkeleton" v-if="$_recipe_devolution_items.length"/>
        </div>
      </div>
      <div class="container container-fluid" v-show="table_type === EntityTableTypeEnum.keys.ANTICIPATION_TRANSFER">
        <div class="entity-credit-history-table">
        <PuzlEmptyData v-if="!$_anticipation_balance_transfers.length && !loadingSkeleton" />
          <div v-else class="row">
            <ShadedTable :length="$_anticipation_balance_transfers.length" :headers="headersTransfers" :loading="loadingSkeleton" class="border-table-entity" >
              <template v-for="(itemTransfers, indexItem) in $_anticipation_balance_transfers" :slot="indexItem" >
                <ShadedCol style="width: 15%;height: fit-content;">
                  <div class="d-flex align-items-center">
                    <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                      {{ date.make(itemTransfers.created_at).format(date.FORMAT.ABBREVIATED_DATE) }}
                    </span>
                    <div class="ml-auto">
                      <div class="mx-1">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="click"
                        >
                          <div class="mb-n2">
                            <span>
                              <span style="font-weight: 500;color: #2B2D32;font-size: 13px;">
                                USUÁRIO
                              </span>
                              <hr class="hr-popover-user">
                              <span style="font-weight: 400;color: #606062;font-size: 11px;"> {{ itemTransfers.user_name }} </span>
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" alt="user-male-circle--v1" width="22px">
                          </base-button>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </ShadedCol>
                <ShadedCol>
                  <span class="text-left content-table-semi-bold">
                    <span v-if="itemTransfers.type === 'target'" class="text-success" > Recebido de </span>
                    <span v-else class="text-danger" > Transferido para </span>
                  </span>
                </ShadedCol>
                <ShadedCol>
                  <span class="text-left content-table-semi-bold">
                    <span v-if="itemTransfers.type === 'target'" > {{ `${itemTransfers.from_entity_name} | ${itemTransfers.from_document}` }} </span>
                    <span v-else > {{ `${itemTransfers.to_entity_name} | ${itemTransfers.to_document}` }} </span>
                  </span>
                </ShadedCol>
                <ShadedCol style="width: 15%;height: fit-content;">
                  <span v-if="itemTransfers.type === 'target'" class="content-table-semi-bold text-success">
                    {{ itemTransfers.value | currency }}
                  </span>
                  <span v-else class="content-table-semi-bold text-danger">
                    {{ (itemTransfers.value * -1) | currency }}
                  </span>
                </ShadedCol>
              </template>
            </ShadedTable>
          </div>
          <pagination class="ml-auto" @navegate="anticipationBalanceNavegate" :source="source" :loading="loadingSkeleton" v-if="$_anticipation_balance_transfers.length"/>
        </div>
      </div>
      <div class="card-footer text-right mt-n4 d-flex justify-content-center border-top-0">
        <base-button type="secondary" @click="closeModal()"> Fechar</base-button>
      </div>
    </modal>
    <ModalCreditLimit @updated="listItems" ref="modalCreditLimit" />
    <ModalAnticipationBalanceTransfer
      @updated="listItems"
      @updatedAfterAnticipationBalanceTransfer="updateAfterAnticipationBalanceTransfer"
      ref="modalAnticipationBalanceTransfer" />
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted, Ref, getCurrentInstance, defineEmits } from 'vue';
import {
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
  AppIcon
} from "../../../../../components/AppGlobal";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import NewPagination from "@/components/Utils/PaginationV2";
import Pagination from "@/components/Utils/Pagination";
import { dialogs, progress, date } from '../../../../../helpers';
import EntityAnticipationBalanceHistoryTypeEnum from "../Shared/Enums/EntityAnticipationBalanceHistoryTypeEnum";
import EntityBillReceiveStatusEnum from "../Shared/Enums/EntityBillReceiveStatusEnum";
import EntityTableTypeEnum from "../Shared/Enums/EntityTableTypeEnum";
import ModalCreditLimit from "./_ModalCreditLimit.vue";
import ModalAnticipationBalanceTransfer from "./_ModalAnticipationBalanceTransfer";
import ShadedTable from "../../../../../components/ShadedTable.vue";
import ShadedCol from "../../../../../components/ShadedCol.vue";
import entityFinancialStore from "../../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";
import { initEntityFinancialGetByEntityFilterType, EntityFinancialGetByEntityFilterType } from '../../../../../modules/configuration/entity/aggregates/entity-financial/types/EntityFinancialGetByEntityFilterType';
import EntityFinancialHubEnum from '../../../../../modules/configuration/entity/aggregates/entity-financial/enums/EntityFinancialHubEnum';
import { hasSap } from "../../../../../modules/sap/shared/helpers/sapHelper";
//#endregion

//#region Emits
const emit = defineEmits([
  "updatedAfterAnticipationBalanceTransfer",
]);
//#endregion

//#region Data
const title = "CARTEIRA DO CLIENTE/FORNECEDOR";
const store = getCurrentInstance().proxy.$store;
const typeColors = {
  neutral: "light",
  negative: "danger",
  approved: "approved",
  positive: "success",
};
const headersEntityAnticipationBalanceHistory = [
  "Data",
  "Origem",
  "Descrição",
  "Movimento",
  "Valor"
];
const headersCreditHistory = [
  "Data",
  "Valido Até",
  "Valor"
];
const headersRecipeDevolutions = [
  "Data",
  "Pagamento",
  "Valor"
];
const headersTransfers = [
  "Data",
  "Status",
  "Cliente",
  "Valor"
]


const isOpen = ref(false);
const loading = ref(true);
const loadingSkeleton = ref(false);
const id = ref(null);
const table_type = ref(null);
const source = ref(null);
const contract_proposal_uuid = ref(null);
const source_contract_proposal_id = ref(null);
const contract_proposals = ref([]);
const hasSapValue = hasSap();

/** @type {EntityFinancialGetByEntityFilterType} */
const filter = reactive(initEntityFinancialGetByEntityFilterType());
const params = reactive({
  page: 1,
});

const $_user = computed(() => store.getters['auth/getUser']);
const $_history = computed(() => entityFinancialStore.getters.getItem());
const $_entity_anticipation_balance_histories = computed(() => store.getters["entityAnticipationBalanceHistory/fetch"]);
const $_recipe_devolution_items = computed(() => store.getters["entryLaunch/fetch"]);
const $_anticipation_balance_transfers = computed(() => store.getters["entityAnticipationBalanceTransfer/fetch"]);
const $_contract_proposal = computed(() => store.getters["contractProposal/show"]);
const $_credit_history = computed(() => store.getters["entityCredit/fetch"]);
const tabSelectItems = computed(() => {
  return [
    {
      id: EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY,
      name: EntityTableTypeEnum.getTitle(EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY),
      selected: table_type.value === EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY,
    },
    {
      id: EntityTableTypeEnum.keys.ENTITY_CREDIT_HISTORY,
      name: EntityTableTypeEnum.getTitle(EntityTableTypeEnum.keys.ENTITY_CREDIT_HISTORY),
      selected: table_type.value === EntityTableTypeEnum.keys.ENTITY_CREDIT_HISTORY,
    },
    {
      id: EntityTableTypeEnum.keys.RECIPE_DEVOLUTION,
      name: EntityTableTypeEnum.getTitle(EntityTableTypeEnum.keys.RECIPE_DEVOLUTION),
      selected: table_type.value === EntityTableTypeEnum.keys.RECIPE_DEVOLUTION,
    },
    {
      id: EntityTableTypeEnum.keys.ANTICIPATION_TRANSFER,
      name: EntityTableTypeEnum.getTitle(EntityTableTypeEnum.keys.ANTICIPATION_TRANSFER),
      selected: table_type.value === EntityTableTypeEnum.keys.ANTICIPATION_TRANSFER,
    },
  ];
})

/** @type {Ref<ModalCreditLimit>} */
const modalCreditLimit = ref(null);
/** @type {Ref<ModalAnticipationBalanceTransfer>} */
const modalAnticipationBalanceTransfer = ref(null);
//#endregion

//#region Methods
function closeModal() {
  isOpen.value = false;
}
/**
 * @param {number} entity_id
 * @param {object} entity
 * @param {number} contract_proposal_id
 */
async function openModal(entity_id, entity = false, contract_proposal_id = null) {
  loading.value = true;
  id.value = entity_id;
  source_contract_proposal_id.value = contract_proposal_id ? contract_proposal_id : $_contract_proposal.value.id;
  params.page = 1;
  filter.contract_proposal_id = null;
  filter.sync_hub = EntityFinancialHubEnum.keys.PUZL;
  contract_proposals.value = [];

  if(entity) {
    $_contract_proposal.value.entity = entity;
  }

  await listItems();
  isOpen.value = true;

  getContractProposalInformation();
}

async function listItems() {
  progress.start();
  const loader = progress.showLoader();

  table_type.value = EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY;
  await entityFinancialStore.actions.getByEntity(id.value, filter);
  loader.hide();
  progress.finish();
}

async function getContractProposalInformation() {
  await store.dispatch("contractProposal/getAllByEntity", { entity_id: id.value, manipulate_state: false })
    .then((response) => {
      contract_proposals.value = response.data;
      loading.value = false;
    });

  getEntityAnticipationBalanceHistory();
}

async function getEntityAnticipationBalanceHistory() {
  loadingSkeleton.value = true;

  const newParams = {
    id: id.value,
    page: params.page,
    order_by: [
      {
        column: "entity_anticipation_balance_histories.operation_date",
        is_desc: true,
      }
    ],
    limit: 10,
    paginate_type: 2 // simplePaginate
  }

  return store.dispatch("entityAnticipationBalanceHistory/fetch", newParams)
    .then((response) => {
      source.value = response.data;
      loadingSkeleton.value = false;
    })
}

async function getRecipeDevolutionItems() {
  loadingSkeleton.value = true;

  return store.dispatch("entryLaunch/getRecipeDevolutionByEntity", {
    id: id.value,
    page: params.page
  })
  .then((response) => {
    source.value = response;
    loadingSkeleton.value = false;
  })
}
/**
 *
 * @param {number} page
 */
function navegate(page) {
  params.page = page
  getEntityAnticipationBalanceHistory();
}

function openModalCreditLimit() {
  modalCreditLimit.value.openModal($_contract_proposal.value.entity.id, $_contract_proposal.value.entity.document.length === 18);
}

function openModalAnticipationBalanceTransfer() {
  modalAnticipationBalanceTransfer.value.openModal($_contract_proposal.value.entity.uuid);
}

async function getCreditHistory() {
  loadingSkeleton.value = true;

  return store.dispatch("entityCredit/getHistory", {
    id: id.value,
    page: params.page
  })
  .then((response) => {
    source.value = response;
    loadingSkeleton.value = false;
  })
}

async function getEntityAnticipationBalanceTransfers() {
  loadingSkeleton.value = true;

  return store.dispatch("entityAnticipationBalanceTransfer/fetch", {
    id: id.value,
    page: params.page
  })
  .then((response) => {
    source.value = response;
    loadingSkeleton.value = false;
  })
}

/**
 *
 * @param {number} page
 */
function creditHistoryNavegate(page) {
  params.page = page;
  getCreditHistory();
}

/**
 *
 * @param {number} page
 */
function anticipationBalanceNavegate(page) {
  params.page = page;
  getEntityAnticipationBalanceTransfers();
}

/**
 *
 * @param {number} page
 */
function recipeDevolutionNavegate(page) {
  params.page = page;
  getRecipeDevolutionItems();
}

/**
 *
 * @param {TabSelectItemType} type
 */
function handleChangeTableType(type) {

  if (table_type.value === type.id) return;
  table_type.value = type.id;
  params.page = 1;

  switch(type.id) {
    case EntityTableTypeEnum.keys.ENTITY_ANTICIPATION_BALANCE_HISTORY:
      getEntityAnticipationBalanceHistory();
      break;
    case EntityTableTypeEnum.keys.ENTITY_CREDIT_HISTORY:
      getCreditHistory();
      break;
    case EntityTableTypeEnum.keys.RECIPE_DEVOLUTION:
      getRecipeDevolutionItems();
      break;
    case EntityTableTypeEnum.keys.ANTICIPATION_TRANSFER:
      getEntityAnticipationBalanceTransfers();
      break;
  }
}

/**
 * Dados do cadastro após a transferência de saldo de antecipação entre clientes/fornecedores (entity),
 * que serão capturados em contas a receber.
 * @param {number} from_entity_id
 * @param {number} to_entity_id
 */
function updateAfterAnticipationBalanceTransfer(from_entity_id, to_entity_id) {
  emit("updatedAfterAnticipationBalanceTransfer");
}

async function handleRecalculate() {
  const loader = progress.showLoader();

  return store.dispatch("entityAnticipationBalanceHistory/recalculate", id.value)
    .then(response => {
      dialogs.notify(
        "success",
        response.message
      );
    })
  .finally(() => {
    loader.hide();
    closeModal();
  })
}

async function handleCopyAnticipationBalanceTestToAnticipationBalance() {
  const loader = progress.showLoader();

  return store.dispatch("entityAnticipationBalanceHistory/copyAnticipationBalanceTestToAnticipationBalance", id.value)
  .finally(() => {
    loader.hide();
    closeModal();
  })
}

async function handleModalAnticipationBalanceTransfer() {
  this.$refs.modalAnticipationBalanceTransfer.openModal(this.$_contract_proposal.entity.uuid);
}

async function getExcelReport() {
  const loader = progress.showLoader();

  store.dispatch("entityAnticipationBalanceHistory/getExcelReport", id.value)
  .then(async (response) => {
    let blob = new Blob([response], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "Movimentações.xlsx");
    await link.click();

    dialogs.notify();
  })
  .catch(() => {
    dialogs.notify(
      "danger",
      "Erro ao realizar o download do arquivo excel!"
    );
  })
  .finally(() => {
    loader.hide();
  })
}

function getContractUuid(value) {
  contract_proposal_uuid.value = contract_proposals.value.find((item) => item.id === filter.contract_proposal_id).uuid;
}

async function getSapEntityFinancial() {
  progress.start();
  const loader = progress.showLoader();
  filter.contract_proposal_id = null;
  filter.sync_hub = EntityFinancialHubEnum.keys.SAP;
  await entityFinancialStore.actions.getByEntity(id.value, filter);
  loader.hide();
  progress.finish();
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} EntityHistoryCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion

</script>

<style scoped>
hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
}
.hr-popover-puzl-validation {
  margin: 7px 0;
  width: 114%;
  margin-left: -11px;
}
.hr-popover-user {
  margin: 7px 0;
  width: 118%;
  margin-left: -11px;
}
.style-table {
  font-weight: 400;
  width: 15px;
}
.text-approved {
  color: rgb(79, 64, 255);
}
.text-light {
  color: #838383;
}
.access-contract-button {
  background-color: #fff;
  border-radius: 8px;
  border: 0.5px solid #E8E8E8
}
.puzl-black {
  color: #2B2D32;
}
.puzl-danger {
  color: #DB4539;
}
.puzl-success {
  color: #149E57;
}
.contract-value-card {
  border-radius: 8px;
  border: 0.5px solid #0000000D;
}
.contract-value-actions-button {
  border: 0.5px solid #2B2D32;
  border-radius: 4px;
  background-color: #fff;
}
.contract-value-actions-button:hover {
  background-color: #1A70B7;
  border: 0.5px solid #1A70B7;
  .action-button-inside-value-cards {
    color: #fff;
  }
}
.negative-total-balance {
  background-color: #FCF3F3;
}
.positive-total-balance {
  background-color: #F2F7F3;
}
.total-balance {
  background-color: #fff;
}
.border-table-entity {
  padding: 10px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
}
.content-table-semi-bold {
  font-size: 14px;
  color: #2B2D32;
  font-weight: 500;
}
.action-button-inside-value-cards {
  margin-top: 1.5px;
  margin-bottom: 1.5px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .contract-values-cards-section {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .contract-value-card {
    flex: 0 0 calc(50% - 10px);
    margin-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>
