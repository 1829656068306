<template>
  <modal :show.sync="isOpen" size="md" class="pb-5 modal">
    <template slot="header" class="teste">
      <h5 class="modal-title p-0 m-0 mt-1">
        <img src="/img/icons/icons8/ios/error--v1_danger.png" height="25" class="mr-2" />
        {{ title }}
      </h5>
    </template>
    <div class="p-3">
      <div class="modal-text">
        <div>
          <span>{{ text }}</span>
        </div>
        <div class="mt-2">
          <span>justificativa</span>
        </div>
        <div>
          <base-input input-group-classes="input-group-sm">
            <textarea v-model="data.justification" type="text" rows="3" class="form-control" placeholder="...">
        </textarea>
          </base-input>
        </div>
      </div>
      <div class="row d-flex justify-content-end mt-4 mr-1">
        <div @click="closeModal" class="d-flex align-items-center button-cancel col-auto px-4" style="gap: 5px">
          <span class="font-weight-500"> Cancelar </span>
        </div>
        <div @click="updateStatusOrder" class="d-flex align-items-center button-save col-auto py-1 ml-3 px-4"
          style="gap: 5px">
          <span class="font-weight-500"> Salvar </span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script setup>
//#region Imports
import { ref } from "vue";
import { dialogs, progress } from "@/helpers";
import moment from "moment";
import purchaseRequestStore from "../../../store/purchaseRequestStore";
//#endregion

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

//#region Data
const title = ref("Itens");
const text = ref("Tem certeza que deseja aprovar a requisição?");
const isOpen = ref(false);
const purchaseData = ref(null);
const data = ref({
  date: moment().format("YYYY-MM-DD"),
  justification: null,
});
//#endregion

//#region Methods
/**
 * Abrir modal
 * @param {object} orderData
 */
function openModal(orderData) {
  purchaseData.value = orderData;
  title.value = orderData.modalTitle;
  text.value = orderData.modalText;
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Atualizar status do pedido para CANCELED */
function updateStatusOrder() {
 
  if (!data.value.justification) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Justificação obrigatória!');
    return;
  };
  progress.start();
  const loader = progress.showLoader();
  purchaseData.value.status_reason = data.value.justification;
  purchaseRequestStore.actions.update(purchaseData.value)
    .then(() => {
      emit("fetch");
      closeModal();
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Status da requisição atualizado com sucesso!');
    })
    .finally(() => {
      loader.hide();
      progress.finish();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} ModalOrderDeniedJustificationExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>


<style scoped>
.modal >>> .modal-header {
  background-color: #fcf3f3 !important;
}

.modal>>>.modal-body {
  margin-top: 0 !important;
}

.modal-title {
  color: #4b4b50;
}

.modal-text span {
  color: #2B2D32 !important;
}

.button-cancel {
  width: 128;
  height: 32;
  padding-top: 8px 12px;
  gap: 10px;
  color: #ffffff;
  background-color: #DB4539;
  border-radius: 4px;
}
.button-save {
  width: 128;
  height: 32;
  padding-top: 8px 12px;
  gap: 10px;
  border: 0.5px solid #CFCFCF;
  border-radius: 4px;
  color: #2B2D32
}
</style>
