<template>
  <div>
    <modal :borderInfo="'2psx solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template #header>
        <div style="display: flex; align-items: center">
          <img src="/img/icons/icons8/ios/new-store-white.png" width="25" />
          <h5 class="modal-title p-0 m-0 ml-3 text-white">
            {{ title }}
          </h5>
        </div>

      </template>
      <div class="entities-container">
        <div v-for="entity in entities" class="entity-card">
          <div class="entity-card-status">
            <img class="mr-1" src="/img/icons/calendar-gray.png" width="16px" height="16px" />
            <span>{{ $helper.parseDate(entity.created_at, 'DD/MM/YYYY')  }}</span>
          </div>
          <div class="entity-info">
            <span>{{ entity.entity_name }}</span>
            <span>{{ entity.entity_document }}</span>
          </div>
          <div class="entity-budget">
            {{ $helper.toMoney(sumTotalValueItemEntityBudget(entity)) }}
          </div>
        </div>
      </div>
      <div @click="closeModal" class="close-button">
        Fechar
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region === Imports ===
import { ref } from "vue";
import { dialogs, progress } from "../../../../../../../../helpers";
import purchaseBudgetStore from '../../../../Budget/store/purchaseBudgetStore';
//#endregion

//#region === Data ===
const isOpen = ref(false);
const title = "Fornecedores";
const entities = ref([]);
//#endregion

//#region === Methods ===
async function openModal(id) {
  await fetchEntityBudgets(id);
  isOpen.value = true;
}

async function fetchEntityBudgets(entityId) {
  progress.start();
  const loader = progress.showLoader();
  const response = await purchaseBudgetStore.actions.fetchEntityBudgets({
    order_by: [{ column: 'purchase_budget_entities.id' }],
    purchase_budget_id: entityId,
  });
  entities.value = response.items;
  progress.finish();
  loader.hide();
}

function sumTotalValueItemEntityBudget(entity) {
  return entity.items.reduce(
    (total, item) => total + (parseFloat(item.final_value) || 0),
    0
  );
}

function closeModal() {
  isOpen.value = false;
}
//#endregion

defineExpose({
  openModal,
});
</script>
<style scoped>
.entities-container {
  width: 530;
  height: 444;
  border-radius: 8px;
  border-width: 1px;
  padding: 12px;
  gap: 10px;
  border: 1px solid #E8E8E8;
  max-height: 500px;
  overflow: auto;
  margin: 0.7rem
}

.modal-body {
  display: none;
}

.entity-card {
  width: 494;
  height: 132;
  border-radius: 8px;
  border-width: 0.5px;
  padding: 12px;
  gap: 8px;
  border: 0.5px solid #E8E8E8;
  box-shadow: 0px 5px 10px 0px #0000000D;
  margin-bottom: 0.7rem;
}

.entity-card-status {
  width: 130px;
  height: 24;
  border-radius: 16px;
  gap: 4px;
  padding: 6px 6px;
  padding-left: 12px;
  background: #E8E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.entity-card-status span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #606062;
}

.entity-info {
  display: grid;
  margin-top: 0.5rem;
  color: #2B2D32;
}

.entity-budget {
  font-weight: 500;
  font-size: 18px;
  color: #2B2D32;
  margin-top: 0.2rem;
}

.close-button {
  border: 0.5px solid var(--Cores-Cinza-200, #CFCFCF);
  width: 128px;
  height: 32px;
  border-radius: 4px;
  gap: 10px;
  padding: 8px 12px;
  margin: 0.3rem 0.9rem 0.6rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  font-weight: 500;
  cursor: pointer;
  color: #2B2D32;
}
</style>
