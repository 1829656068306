<template>
  <div class="contract-proposal-edit-page">
    <AppFixedPageTitle
      :title="
        contract_proposal?.entity ? `EDIÇÃO DE CONTRATO` : `EDIÇÃO DE PROPOSTA`
      "
      icon="/img/icons/icons8/ios/briefcase.png"
    />
    <div class="container-fluid py-4">
      <div v-if="contract_proposal" class="d-flex justify-content-between align-items-start page-header">
        <div class="contract-info">
          <!-- Status badge -->
          <div
            v-if="contract_proposal.entity"
            class="app-badge"
            @click.prevent="handleUpdateStatus(contract_proposal)"
            :style="{
                backgroundColor:
                  badgeComponentStatusMap[contract_proposal.status].bg,
                color:
                  badgeComponentStatusMap[contract_proposal.status].textColor,
              }"
          >
            <AppIcon
              :icon="badgeComponentStatusMap[contract_proposal.status].icon"
              width="18"
              :color="
                  badgeComponentStatusMap[contract_proposal.status].color
                "
              class="mr-1"
            />
            {{ badgeComponentStatusMap[contract_proposal.status].text }}
          </div>
          <div
            v-else
            class="app-badge proposta"
            @click.prevent="handleUpdateStatus(contract_proposal)"
          >
            proposta
          </div>
          <h3 class="d-block">
            CÓDIGO:
            <span> {{ contract_proposal.code }} </span>
          </h3>
          <h3 class="d-block">
            CLIENTE:
            <span>
              {{
                contract_proposal.entity ? contract_proposal.entity.entity_name : contract_proposal.construction.customer_construction.customer_name
              }}
            </span>
          </h3>
          <h3 class="d-block">
            OBRA:
            <span>
              {{ contract_proposal.construction.construction_name }}
            </span>
          </h3>
        </div>
        <div class="header-buttons">
          <AppPageHeaderActions>
            <AppPageHeaderActionsButton
              type="warning"
              @click.prevent="openModalPrint(contract_proposal)"
              text="imprimir"
              icon="/img/icons/icons8/ios/svgs/printer.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              v-if="contract_proposal && !contract_proposal?.entity"
              type="success"
              @click="handleShowModalSearchEntity(contract_proposal)"
              text="Gerar Contrato"
              icon="/img/icons/icons8/ios/svgs/agreement.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              v-if="contract_proposal?.entity"
              type="primary"
              @click.prevent="handleEntityHistory(contract_proposal.entity.id)"
              text="saldos"
              icon="/img/icons/icons8/ios/svgs/wallet.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              type="light"
              @click="goBackToTargetPage"
              text="voltar"
              icon="/img/icons/circled-left.png"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>

          </AppPageHeaderActions>
        </div>
      </div>
      <!-- Header Menus -->
      <ContractProposalNavigation
        :contractUuid="contract_proposal.uuid"
        :contractStatus="contract_proposal.status"
        :entity="contract_proposal.entity"
        :activeGeneral="true"
        style="margin-top: 12px;"
        v-if="load_counter >= 6"
      />
    </div>

    <div class="container-fluid">
      <!-- Start Cards -->
      <validation-observer v-slot="{ invalid }" ref="formValidator">
        <div class="row">
          <!-- CARD CLIENTE -->
          <div class="col-md-6 pr-md-2 "
               v-if="contract_proposal && contract_proposal.entity"
          >
            <ContractProposalEditCard
              title="Cliente"
              icon="business-building"
              color="success"
              class="reset-input-css"
              show-accordion-button
            >
              <template #card-header-buttons>
                <a class="app-button mr-1"
                   v-if="!contract_proposal.has_payments && $hasPrivilege(1)"
                   @click.prevent="handleSearchCpfCnpj()"
                >
                  <i class="fas fa-edit" style="width: 12px; height: 12px;"></i> Alterar tomador
                </a>
                <a class="app-button mr-1"
                   @click.prevent="handleShowEntityAttachments(
                  contract_proposal.entity.id,
                  contract_proposal.entity.entity_name,
                  contract_proposal.entity.document
                )"
                >
                  <AppIcon icon="attach" color="success" width="12px"
                  />
                  anexos
                  <span
                    style="font-size: 12px; color: #2B2D32;"
                    v-if="totalEntityAttachments > 0"
                  >
                  {{ totalEntityAttachments }}
                </span>
                </a>
                <a class="app-button"
                   @click="handleEditEntity(contract_proposal.entity.uuid)"
                >
                  <AppIcon icon="create" color="warning" width="12px"/>
                  editar
                </a>
              </template>

              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Nome
                </label>
                <div class="col-md-7">
                <span
                  v-if="contract_proposal && contract_proposal.service_receiver"
                  :title="contract_proposal.service_receiver.entity_name"
                  class="form-control-sm text-truncate"
                >
                  {{
                    contract_proposal &&
                    contract_proposal.service_receiver &&
                    contract_proposal.service_receiver.entity_name
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  CPF/CNPJ
                </label>
                <div class="col-md-7">
              <span class="form-control-sm">
                {{
                  contract_proposal &&
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.document
                }}
              </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Logradouro
                </label>
                <div class="col-md-7">
              <span
                v-if="contract_proposal && contract_proposal.service_receiver && contract_proposal.service_receiver.address"
                :title="`
                  ${contract_proposal.service_receiver.address.address},
                  ${contract_proposal.service_receiver && contract_proposal.service_receiver.address.number}
                `"
                class="form-control form-control-sm text-truncate"
              >
                {{
                  contract_proposal &&
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.address.address
                }},
                {{
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.address.number
                }}
              </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Bairro
                </label>
                <div class="col-md-7">
              <span class="form-control form-control-sm">
                {{
                  contract_proposal &&
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.address.district
                }}
              </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Município
                </label>
                <div class="col-md-7">
              <span class="form-control form-control-sm">
                {{
                  contract_proposal &&
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.address.city
                }}
              </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  uf
                </label>
                <div class="col-md-7">
              <span class="form-control form-control-sm">
                {{
                  contract_proposal &&
                  contract_proposal.service_receiver &&
                  contract_proposal.service_receiver.address.state
                }}
              </span>
                </div>
              </div>

            </ContractProposalEditCard>
          </div>
          <!-- CARD OBRA -->
          <div class="col-md-6 pl-md-2">
            <ContractProposalEditCard
              title="Obra"
              icon="brick-wall"
              color="warning"
              class="reset-input-css"
              show-accordion-button
            >

              <template #card-header-buttons>
                <a class="app-button mr-1"
                   @click.prevent="handleListContacts"
                   v-if="load_counter >= 6 && (contract_proposal)"
                >
                  <AppIcon icon="contact" color="warning" width="12px"
                  />
                  contatos
                </a>
                <a class="app-button mr-1"
                   @click.prevent="handleShowConstructionAttachments(
                      contract_proposal.construction.id,
                      contract_proposal.construction.construction_name
                    )"
                   v-if="load_counter >= 6"
                >
                  <AppIcon icon="attach" color="success" width="12px"/>
                  anexos
                  <span
                    style="font-size: 12px; color: #2B2D32;"
                    v-if="totalConstructionAttachments > 0"
                  >
                      {{ totalConstructionAttachments }}
                    </span>
                </a>
                <a class="app-button"
                   v-if="contract_proposal && contract_proposal.construction"
                   @click="handleEditConstruction()"
                >
                  <AppIcon icon="create" color="warning" width="12px"/>
                  editar
                </a>
              </template>

              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Nome
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal &&
                    contract_proposal.construction &&
                    contract_proposal.construction.construction_name
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Central
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal.plant.name
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Logradouro
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal &&
                    contract_proposal.construction &&
                    contract_proposal.construction.default_address.address +
                    ", " +
                    contract_proposal.construction.default_address.number
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Bairro
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal &&
                    contract_proposal.construction &&
                    contract_proposal.construction.default_address.district
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  Município
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal &&
                    contract_proposal.construction &&
                    contract_proposal.construction.default_address.city
                  }}
                </span>
                </div>
              </div>
              <div class="app-form-group">
                <label class="col-md-5 col-form-label form-control-label">
                  uf
                </label>
                <div class="col-md-7">
                <span class="form-control form-control-sm">
                  {{
                    contract_proposal &&
                    contract_proposal.construction &&
                    contract_proposal.construction.default_address.state
                  }}
                </span>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
        </div>
        <!-- CARD CONTRATO -->
        <ContractProposalEditCard
          title="contrato"
          icon="briefcase"
          color="success"
          class="reset-input-css contract-card"
        >
          <template #contract-input>
            <span class="ml-0 ml-md-3">
              <validation-provider rules="required"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                  <puzl-select
                    v-model="contract_proposal.contract_uuid"
                    :items="contracts"
                    customKey="uuid"
                    :loading="loadingContract"
                    :disabled="loadingContract"/>
                </base-input>
              </validation-provider>
            </span>
          </template>
          <template #card-header-buttons>
            <el-popover width="365" trigger="click" placement="left">
              <div class="row rules-popover reset-input-css justify-content-between">
                <label class="popover-title col-12 px-3">
                  REGRAS ESPECIAIS
                </label>
                <div class="pl-3 mb-4 d-flex flex-column justify-content-center align-items-center "
                     @click="validPermissionCanIncludeReidi"
                >
                  <label class="popover-label">
                    REIDI
                  </label>
                  <AppSwitch

                    v-model="contract_proposal.reidi"
                    :disabled="!$_financial_limit.invoice_can_include_reidi"
                  >
                  </AppSwitch>
                </div>
                <div class="pr-3 mb-4 d-flex flex-column justify-content-center align-items-center">
                  <label class="popover-label">
                    Fixar retenção de iss
                  </label>
                  <div class="d-flex">
                    <AppSwitch
                      @click="handleOwedIss"
                      v-model="contract_proposal.iss_retain_fixed"
                      class="mr-1"
                    >
                    </AppSwitch>
                    <AppSwitch
                      v-if="contract_proposal.iss_retain_fixed"
                      v-model="contract_proposal.iss_retain_fixed_retained"
                      style="min-width: 115px;"
                      :toogleLayout="{
                        off: {
                          iconColor: 'muted',
                          backgroundColor: '#E8E8E8',
                          icon: 'chemical-plant',
                          label: 'ISS DEVIDO',
                          labelColor: '#606062'
                        },
                        on:
                        {
                          iconColor: 'danger',
                          backgroundColor: '#FCF3F3',
                          icon: 'shutdown',
                          label: 'ISS RETIDO',
                          labelColor: '#DB4539'
                        }
                      }"
                    >
                    </AppSwitch>
                  </div>
                </div>
                <div class="col-12 mb-1 d-flex align-items-center justify-content-between">
                  <label class="popover-label">
                    FIXAR CNPJ EMISSOR?
                  </label>
                  <AppSwitch
                    v-model="contract_proposal.issuer_fixed"
                    @input="validIssuerFixed"
                  >
                  </AppSwitch>
                </div>
                <div class="col-12 mb-4">
                  <PuzlSelect
                    @change="clearBankAccountByIssuer();getBankAccounts()"
                    :disabled="!contract_proposal.issuer_fixed"
                    :label="'business_name'"
                    v-model="contract_proposal.company_plant_issuer_id_fixed"
                    :items="company_plant_issuers"
                    style="min-height: 32px;"
                  />
                </div>
                <div class="col-12">
                  <label class="popover-label">
                    INSC. ESTADUAL
                  </label>
                </div>
                <div class="col-12 pb-1">
                  <input
                    v-model="contract_proposal.state_registration"
                    class="form-control form-control-sm"/>
                </div>
              </div>
              <div class="app-button"
                   slot="reference"
              >
                <AppIcon icon="star-filled" color="primary"/>
                REGRAS ESPECIAIS
              </div>
            </el-popover>
          </template>

          <div class="app-form-group app-row row">
            <div class="col-md-4 pr-md-2">
              <label class="mb-2 col-form-label form-control-label">
                Central
              </label>
              <validation-provider rules="required"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input class="form-control-dropdown" :class="{ 'is-invalid': errors.length}">
                  <PuzlSelect
                    v-model="contract_proposal.company_plant_id"
                    :items="plants"
                    @change="checkPlantGeneralSettings();contract_proposal.company_plant_issuer_id_fixed = null;clearBankAccount()"
                    :loading="loadingPlant"
                    :disabled="
                          loadingPlant ||
                          [5,0].includes(contract_proposal.status) ||
                          (opened_billing > 0 && !$_commercial_permission.contract_editor_and_seller)
                        "
                  />
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 px-md-2">
              <label class="mb-2  col-form-label form-control-label">
                Vendedor
              </label>
              <validation-provider rules="required"
                                   v-slot="{errors}"
                                   immediate
              >
                <base-input input-classes="form-control-sm"
                            :class="{ 'is-invalid': errors.length}"
                >
                  <PuzlSelect
                    v-model="contract_proposal.seller"
                    :items="users" customKey="uuid"
                    :loading="loadingUsers"
                    :disabled="
                        loadingUsers ||
                        [5,0].includes(contract_proposal.status) ||
                        (opened_billing > 0 && !$_commercial_permission.contract_editor_and_seller)
                      "
                  />
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 pl-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Volume médio por BT
                <span v-if="contract_proposal.average_volume_per_bt < 3">
                    <i class="fas fa-exclamation-triangle text-danger ml-1 mr-1"></i>
                    <small class="text-dark" style="color: #7e7e7e;font-size: 14px;">Valor mínimo: 3</small>
                  </span>
              </label>
              <validation-provider rules="required|min_value:3"
                                   v-slot="{errors}"
                                   immediate
              >
                <input-limit
                  :class="{ 'is-invalid': errors.length}"
                  class="input-limit"
                  :disabled="[5,0].includes(contract_proposal.status)"
                  :options="{
                      min: 0,
                      max: 49.9,
                      decimals: 1
                    }"
                  :start_value="contract_proposal.average_volume_per_bt"
                  v-model="contract_proposal.average_volume_per_bt"
                  @value="contract_proposal.average_volume_per_bt = $event"
                >
                  <!-- <small class="input-group-sm p-0 m-0">
                    m<sup>3</sup>
                  </small> -->
                </input-limit>
              </validation-provider>
            </div>
          </div>

          <div class="app-form-group app-row row">
            <div class="col-md-4 pr-md-2">
              <label class="pb-2 col-form-label form-control-label">
                CNO
              </label>
              <validation-provider
                :rules="contract_proposal.entity && is_cno_required_prop_contracts ? 'required|min:14' : 'min:14'"
                v-slot="{errors}"
                immediate
              >
                <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length }">
                  <input
                    class="form-control form-control-sm"
                    maxlength="15"
                    v-mask="'##.###.#####/##'"
                    v-model="contract_proposal.cno"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 p-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Código de obra
              </label>
              <validation-provider
                :rules="
                  contract_proposal.entity && is_municipal_work_code_required_prop_contracts
                    ? 'required' : ''
                "
                v-slot="{errors}"
                immediate
              >
                <base-input input-group-classes="input-group-sm" :class="{ 'is-invalid': errors.length}">
                  <input class="form-control form-control-sm"
                         v-model="contract_proposal.city_hall_construction_code"/>
                </base-input>
              </validation-provider>
            </div>
            <div class="col-md-4 pl-md-2">
              <label class="pb-2 col-form-label form-control-label">
                Art/nº de controle do encapsulamento
              </label>
              <validation-provider
                :rules="contract_proposal.entity && is_art_encapsulation_required && 'required' || ''"
                v-slot="{errors}"
                immediate
              >
                <base-input input-group-classes="input-group-sm" :class="{ 'is-invalid': errors.length}">
                  <input class="form-control form-control-sm" v-model="contract_proposal.art_code"/>
                </base-input>
              </validation-provider>
            </div>
          </div>
        </ContractProposalEditCard>

        <div class="row">
          <!-- CARD FATURAMENTO -->
          <div class="col-md-6 pr-md-2">
            <ContractProposalEditCard
              title="faturamento"
              icon="cheque"
              color="primary"
              class="reset-input-css invoice-card"
            >

              <template #card-header-buttons>
                <div class="d-flex justify-content-center align-items-center flex-column flex-md-row"
                     style="gap: 8px"
                >
                  <span class="new-default-black-font">
                    POR MEDIÇÃO
                  </span>
                  <AppSwitch
                    v-model="contract_proposal.measurement"
                  >
                  </AppSwitch>
                </div>
              </template>

              <div class="form-group row invoice-form-group">
                <div class="col-md-12 mb-2"
                     v-if="contract_proposal.necessary_approvation &&  $hasPermission('additional_permissions.financial_approvation')">
                  <div
                    class="d-flex flex-column flex-md-row justify-content-between align-items-center reset-input-css">
                    <label class="mr-md-auto">
                      Aprovação financeira
                    </label>
                    <div style="display: block; min-height: 16px; margin-top: 2px">
                      <base-dropdown
                      >
                        <button
                          slot="title-container"
                          class="dropdown-toggle financial-badge"
                          style="font-size: 10px; min-width: 110px"
                          :class="[
                            financialBadgeLayout(contract_proposal.approvation).class,
                          ]"
                        >
                          <AppIcon
                            :icon="
                              financialBadgeLayout(contract_proposal.approvation).img
                            "
                            width="10px"
                            :color="
                              financialBadgeLayout(contract_proposal.approvation).color
                            "
                            class="mr-1"
                          />

                          {{ financialBadgeLayout(contract_proposal.approvation).text }}
                          <AppIcon
                            icon="down"
                            width="7px"
                            class="ml-1"
                            :color="
                              financialBadgeLayout(contract_proposal.approvation).color
                            "
                          />
                        </button>
                        <span
                          class="dropdown-item"
                          @click.prevent="setApprovationStatusAndUser(0)"
                        >
                          <AppIcon
                            icon="clock"
                            width="10px"
                            color="warning"
                            class="mr-1"
                          />
                          Pendente
                        </span>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <span
                          class="dropdown-item"
                          @click.prevent="setApprovationStatusAndUser(1)"
                        >
                          <AppIcon
                            icon="thumbs-up"
                            width="10px"
                            color="success"
                            class="mr-1"
                          />
                          APROVAR
                        </span>
                        <div class="dropdown-divider p-0 m-0"></div>
                        <span
                          class="dropdown-item"
                          @click.prevent="
                            setApprovationStatusAndUser(2)
                          "
                        >
                          <AppIcon
                            icon="thumbs-down"
                            width="10px"
                            color="danger"
                            class="mr-1"
                          />
                          REPROVAR
                        </span>
                      </base-dropdown>
                    </div>
                  </div>

                  <span
                    class="financial-approval-text"
                    v-if="temporary_user_approvation && !contract_proposal.approvation_user"
                  >
                    Revisado por: <strong>{{ temporary_user_approvation }}</strong>
                  </span>
                  <span
                    class="financial-approval-text"
                    v-if="contract_proposal.approvation_user"
                  >
                    Revisado por: <strong>{{ contract_proposal.approvation_user }}</strong>
                  </span>
                  <span class="financial-approval-text"
                        v-if="contract_proposal.approvation === 1 && !contract_proposal.approvation_user && !temporary_user_approvation"
                  >
                    Aprovado automaticamente pelo sistema.
                  </span>
                </div>

                <div class="col-md-6 mb-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Métodos de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.payment_methods_ids"
                        :items="payment_methods"
                        :multiple="true"
                        :loading="loadingMethod"
                        @input="setPaymentTermsByMethod()"
                        :disabled="[5,0].includes(contract_proposal.status)"/>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6 mb-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Condições de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.payment_terms_uuids"
                        :items="payment_terms_by_principal"
                        customKey="uuid"
                        :multiple="true"
                        @input="setMethods"
                        :loading="loadingPaymentTerm"
                        @change="setAll()"
                        :disabled="loadingPaymentTerm || !contract_proposal.payment_methods_ids.length || [5,0].includes(contract_proposal.status)"
                      />
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6 mt-2">
                  <label class="pb-2 col-form-label form-control-label">
                    Obs. para nota fiscal de serviço
                  </label>
                  <div
                    class="mb-1"
                    v-if="contract_proposal.entity && contract_proposal.entity.is_nfse_observations_required"
                  >
                    <a href="#" class="text-dark" @click.prevent="SetServiceInvoiceNotes()">
                      <b style="font-size: 12px"> Número de empenho: </b>
                      <span
                        class="badge mr-1 ml-1"
                        style="background-color: #ffff; border: 1px solid #ccc; padding: 4px; border-radius: 4px;"
                      >
                        <i style="font-size: 15px" class="zoom pointer fa-regular fa-clipboard text-dark"></i>
                        <span style="font-size: 10px" class="font-weight-500">
                          Preencher
                        </span>
                      </span>
                    </a>
                  </div>
                  <validation-provider
                    :rules="
                      contract_proposal?.entity?.is_nfse_observations_required ? 'required' : ''
                    "
                    v-slot="{errors}"
                    immediate
                  >
                    <base-input input-classes="form-control-sm">
                      <textarea
                        :class="{ 'is-invalid': errors.length}"
                        v-model="contract_proposal.observation"
                        maxlength="400"
                        class="form-control"
                        rows="2"
                        style="resize: none; height: 121px;"
                        placeholder="..."
                      />
                      <small class="text-muted">
                        {{ (contract_proposal.observation ? contract_proposal.observation.length : 0) }}/400
                      </small>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mt-4 mt-md-2">
                  <label class="pb-2 col-form-label form-control-label custom-spacer"
                         :style="contract_proposal?.entity?.is_nfse_observations_required
                    ? 'margin-bottom: 29px !important;'
                    : ''"
                  >
                    obs. para o faturamento
                  </label>
                  <base-input input-classes="form-control-sm">
                    <textarea
                      v-model="contract_proposal.billing_guidelines"
                      maxlength="400"
                      class="form-control"
                      rows="2"
                      style="resize: none; height: 121px;"
                      placeholder="..."
                    />
                    <small class="text-muted">
                      {{ (contract_proposal.billing_guidelines ? contract_proposal.billing_guidelines.length : 0) }}/400
                    </small>
                  </base-input>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
          <!-- CARD AUTOMATIZAÇÃO -->
          <div class="col-md-6 pr-md-2">
            <ContractProposalEditCard
              title="Automatização"
              icon="bot"
              color="danger"
              class="reset-input-css"
              :style="minHeightStyle"
            >

              <div class="row justify-content-between align-items-center badge-wrapper">
                <div class="d-flex flex-column align-items-center col-md-3 mb-4">
                  <label>
                    NF-E
                  </label>
                  <AppSwitch
                    @input="setNfse"
                    v-model="contract_proposal.automatic_nfe"
                  >
                  </AppSwitch>
                </div>
                <div class="d-flex flex-column align-items-center col-md-3 mb-4">
                  <label>
                    NFS-E
                  </label>
                  <AppSwitch
                    @input="unsetIssRetain()"
                    v-model="contract_proposal.automatic_nfse"
                  >
                  </AppSwitch>
                </div>
                <div class="d-flex flex-column align-items-center col-md-3 mb-4">
                  <label>
                    Faturamento
                  </label>
                  <AppSwitch
                    @input="setAll()"
                    v-model="contract_proposal.automatic_billing"
                  >
                  </AppSwitch>
                </div>
                <div class="d-flex flex-column align-items-center col-md-3 mb-4"
                >
                  <label v-if="contract_proposal.automatic_billing">
                    boleto
                  </label>
                  <AppSwitch
                    @input="setTicket(contract_proposal.billing.automatic_ticket)"
                    v-model="contract_proposal.billing.automatic_ticket"
                    :disabled="!contract_proposal.automatic_billing || [5,0].includes(contract_proposal.status)"
                    v-if="contract_proposal.automatic_billing"
                  >
                  </AppSwitch>
                </div>
              </div>

              <div v-if="contract_proposal.automatic_billing" class="row">
                <div class="col-md-6 pb-3 pr-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Método de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.payment_method_id"
                        :items="Payment_methods"
                        :loading="loadingMethod"
                        @input="getPaymentTerms()"
                        @change="getPaymentIntermediaries()"
                        :disabled="loadingMethod || [5,0].includes(contract_proposal.status)"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pl-md-2">
                  <label class="mb-2col-form-label form-control-label">
                    Condição de pagamento
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.payment_term_id"
                        :items="filteredPaymentTermsByMethod"
                        @input="setRange"
                        :loading="loadingPaymentTerm"
                        :disabled="loadingPaymentTerm || !contract_proposal.billing.payment_method_id || [5,0].includes(contract_proposal.status)"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pr-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Conta bancária
                  </label>
                  <validation-provider rules="required"
                                       v-slot="{errors}"
                                       immediate
                  >
                    <base-input input-classes="form-control-sm" :class="{ 'is-invalid': errors.length}">
                      <PuzlSelect
                        v-model="contract_proposal.billing.bank_account_id"
                        :items="bank_accounts"
                        :label="['bank_account', 'name']"
                        :recursiveKey="['bank_account', 'id']"
                        :labelMask="true"
                        :loading="loadingBankAccounts"
                        :disabled="[5,0].includes(contract_proposal.status)"/>
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3 pl-md-2">
                  <label class="mb-2 col-form-label form-control-label">
                    Frequência do Faturamento
                  </label>
                  <div class="d-flex justify-content-center align-items-center" style="gap: 4px;">
                    <div class="frequency-button"
                         @click.prevent="contract_proposal.billing.range = 1;frequency_selecteds = []"
                         :class="{'is-active': contract_proposal.billing.range === 1 }"
                    >
                      DIÁRIO
                    </div>
                    <div class="frequency-button"
                         :class="{'is-active': contract_proposal.billing.range === 2 }"
                         @click.prevent="contract_proposal.billing.range = 2;frequency_selecteds = []"
                    >
                      SEMANA
                    </div>
                    <div class="frequency-button"
                         :class="{'is-active': contract_proposal.billing.range === 3 }"
                         @click.prevent="contract_proposal.billing.range = 3;frequency_selecteds = []"
                    >
                      MÊS
                    </div>
                  </div>

                  <div class="date-picker-wrapper" v-if="[2,3].includes(contract_proposal.billing.range)">
                    <template v-if="contract_proposal.billing.range === 2">
                      <table>
                        <thead>
                        <tr>
                          <th v-for="item in weekDays.slice(0, 4)">
                              <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                                  item.name
                                }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in weekDays.slice(4, 7)">
                              <span @click.prevent="setFrequency(item.id)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item.id) && 'frequency-selected'">{{
                                  item.name
                                }}</span>
                          </th>
                        </tr>
                        </thead>
                      </table>
                    </template>
                    <template v-if="contract_proposal.billing.range === 3">
                      <table>
                        <thead>
                        <tr>
                          <th v-for="item in generateRange(1, 7)">
                              <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                  item
                                }}</span>
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <th v-for="item in generateRange(8, 14)">
                              <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                  item
                                }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(15, 21)">
                              <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                  item
                                }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(22, 28)">
                              <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                  item
                                }}</span>
                          </th>
                        </tr>
                        <tr>
                          <th v-for="item in generateRange(29, 31)">
                              <span @click.prevent="setFrequency(item)" class="badge frequency pointer"
                                    :class="frequency_selecteds.includes(item) && 'frequency-selected'">{{
                                  item
                                }}</span>
                          </th>
                        </tr>
                        </tbody>
                      </table>
                    </template>
                  </div>
                </div>
              </div>
            </ContractProposalEditCard>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center justify-content-md-end align-items-center"
               style="gap: 8px;"
          >
            <AppButton
              :hasOutline="true"
              @click="refresh()"
              label="Cancelar"
              type="danger"
            />
            <AppButton
              @click.prevent="handleSubmit"
              label="Salvar"
              type="success"
              native-type="submit"
              :isDisabled="invalid || load_counter < 6"
              :isLoading="loadingSave"
            />
          </div>
        </div>
      </validation-observer>

      <ContractProposalEditCard
        title="Informações Complementares"
        class="mt-4"
      >
        <template #card-header-buttons>
          <AppButton
            icon="plus-math"
            label="Nova"
            type="success"
            hasOutline
            @click.prevent="handleCreateAdditionalObservation(contract_proposal.uuid)"
          >
          </AppButton>
        </template>

        <div
          v-for="(additional_information,
          indexAdditionalInformaticon) in contract_proposal.comments"
          :key="indexAdditionalInformaticon"
        >
          <card class="mb-1 border card--hover card-body-molde">
            <div class="row d-flex justify-content-between">
              <div class="col-md-4">
                <h5>
                  {{ additional_information.created_at | parseDate }} | {{ additional_information.user }}
                </h5>
              </div>
              <div class="col-md-7">
                <h5>{{ additional_information.title || "" }}</h5>
                <p>
                  {{ additional_information.observation || "" }}
                </p>
              </div>
              <div class="col-md-1 d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  class="btn btn-sm rounded btn-danger"
                  @click.prevent="handleRemoveAdditionalInformation(
                    additional_information.id,
                    indexAdditionalInformaticon
                  )"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
          </card>
        </div>

      </ContractProposalEditCard>
    </div>
    <ModalChangeStatus @changeStatus="changeStatus" ref="changeStatus"/>
    <ModalContractProposalPendingStatus
      @fetch="changeStatus"
      ref="contractProposalApprovals"
    />
    <ModalPrintOut @open="openModalContractPrint" ref="ModalPrint"/>
    <ModalContractPrint ref="modalContractPrint"/>
    <ModalEntityHistory ref="modalEntityHistory"/>
    <ModalSearchCpfCnpj @set_entity="set_entity" ref="searchCpfCnpj"/>
    <ModalCreateEntity ref="createEntity"/>
    <ModalCreateAdditionalObservation ref="createAdditionalObservation"/>
    <ModalEditEntity
      @updatedEntity="set_entity"
      @set_entity_name="updateCustomerNameAfterEntityEdit"
      ref="editEntity"
    />
    <ModalEditConstruction @updatedConstruction="updateConstruction" ref="editConstruction"/>
    <ModalConstructionAttachmentList
      ref="modalConstructionAttachmentList"
      @updatedTotalAttachmentAfterUpload="updatedTotalConstructionAttachmentAfterUpload"
      @updateTotalAttachmentAfterDeletion="updateTotalConstructionAttachmentAfterDeletion"/>
    <ModalEntityAttachmentList
      ref="modalEntityAttachmentList"
      @storedAttachment="updatedTotalEntityAttachmentAfterUpload"
      @deletedLastAttachment="updateTotalEntityAttachmentAfterDeletion"/>
    <ModalSearchEntity ref="modalSearchEntity" @setEntity="set_entity"/>
    <ModalListContacts ref="listContacts"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect"
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import {onlyNumbers} from "@/helpers";
import inputLimit from "@/components/Utils/InputLimit";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
//Codigo da totvs não sera mais usado
// import {construction as $_totvs} from "@/plugins/microservices/totvs";
import {hasPermission} from "@/plugins/permissions";
import ModalChangeStatus from "./_ModalChangeStatus";
import ModalPrintOut from './_ModalPrintOut';
import ModalContractPrint from './_ModalContractPrint';
import ModalSearchCpfCnpj from "./_ModalSearchCpfCnpj";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import ModalCreateEntity from "@/views/Modules/Configuration/Entity/Shared/_Create";
import ModalCreateAdditionalObservation from "./_ModalCreateAdditionalObservation";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList";
import ModalSearchEntity from "./_ModalSearchEntity";
import ModalContractProposalPendingStatus
  from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalContractProposalApprovals";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import ModalEditConstruction from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_Edit";
import ModalConstructionAttachmentList
  from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_ModalConstructionAttachmentList";
import ModalListContacts from "./Contacts/_ModalListContacts.vue";

// componentes novos, coposition api
import {
  AppFixedPageTitle,
  AppPageHeaderActions,
  AppIcon,
  AppPageHeaderActionsButton,
  AppSwitch,
  AppButton
} from "../../../../../components/AppGlobal";
import ContractProposalEditCard from "../components/ContractProposalEditCard/ContractProposalEditCard.vue";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";
import {clear} from "core-js/internals/task";

export default {
  name: "EditContractProposal",
  components: {
    ModalChangeStatus,
    ModalSearchCpfCnpj,
    ModalCreateEntity,
    ModalCreateAdditionalObservation,
    PuzlSelect,
    SkeletonPuzlGrid,
    inputLimit,
    ModalEditEntity,
    ModalEditConstruction,
    ContractProposalNavigation,
    ModalConstructionAttachmentList,
    ModalEntityAttachmentList,
    ModalSearchEntity,
    ModalPrintOut,
    ModalContractPrint,
    ModalContractProposalPendingStatus,
    ModalEntityHistory,
    AppFixedPageTitle,
    AppIcon,
    AppButton,
    AppSwitch,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    ContractProposalEditCard,
    ModalListContacts,
  },
  data() {
    return {
      loadingPlant: true,
      frequency_selecteds: [],
      block_iss: false,
      payment_terms_by_method: [],
      Payment_methods: [],
      loadingContract: true,
      loadingPaymentTerm: true,
      payment_intermediaries_exists: false,
      loadingMethod: false,
      loadingSave: false,
      loadingUsers: true,
      entity_is_company: false,
      loadingCompanyPlantIssuer: false,
      loadingPaymentIntermediaries: false,
      loadingBalance: false,
      payment_intemerdiaries_selected: [],
      invalid: false,
      loading: false,
      users: [],
      loadingBankAccounts: false,
      bank_accounts: [],
      payment_terms_by_principal: [],
      weekDays: [
        {
          id: 0,
          name: 'DOM',
        },
        {
          id: 1,
          name: 'SEG',
        },
        {
          id: 2,
          name: 'TER',
        },
        {
          id: 3,
          name: 'QUA',
        },
        {
          id: 4,
          name: 'QUI',
        },
        {
          id: 5,
          name: 'SEX',
        },
        {
          id: 6,
          name: 'SAB',
        },
      ],
      range_frequency_options: [
        {
          id: 1,
          name: 'Diariamente'
        },
        {
          id: 2,
          name: 'Semanalmente'
        },
        {
          id: 3,
          name: 'Mensalmente'
        },
        {
          id: 4,
          name: 'Personalizado'
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      saving: false,
      loader: null,
      load_counter: 0,
      // config gerais de central:
      is_cno_required_prop_contracts: false,
      is_municipal_work_code_required_prop_contracts: false,
      is_art_encapsulation_required: false,
      totalConstructionAttachments: null,
      totalEntityAttachments: null,
      isHideEntityCard: true,
      isHideConstructionCard: true,
      opened_billing: null,
      loadingConstructionAttachments: true,
      loadingEntityAttachments: true,
      ContractProposalEnum: ContractProposalEnum,
      temporary_user_approvation: null,
      financialApprovalStatusEnum: {
        financial_pending: 0,
        financial_approval: 1,
        financial_disapproval: 2,
      },
      /**
       * Layout para badges
       */
      badgeComponentStatusMap: {
        [ContractProposalEnum.ACTIVE]: {
          icon: "check",
          color: "success",
          text: "Ativo",
          bg: "#F2F7F3",
          textColor: "#149e57",
        },
        [ContractProposalEnum.INACTIVE]: {
          icon: "shutdown",
          color: "danger",
          text: "Bloqueado",
          bg: "#fcf3f3",
          textColor: "#db4539",
        },
        [ContractProposalEnum.PENDING_APPROVAL]: {
          icon: "clock",
          color: "warning",
          text: "Pendente",
          bg: "#FEF9F2",
          textColor: "#f2b532",
        },
        [ContractProposalEnum.PENDING_REVIEW]: {
          icon: "clock",
          color: "warning",
          text: "Pendente",
          bg: "#FEF9F2",
          textColor: "#f2b532",
        },
        [ContractProposalEnum.COMPLETED]: {
          icon: "double-tick",
          color: "primary",
          text: "Concluído",
          bg: "#f2f4f9",
          textColor: "#1a70b7",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      company_plant_issuers: "plant/getCompanyPlantIssuers",
      code: "contractProposal/getMaxCode",
      price_validity: "contractProposal/getPriceValidity",
      $_bank_accounts: 'bankAccount/fetch',
      contracts: 'contractContracts/fetch',
      payment_terms: "paymentTerm/fetch",
      payment_methods: "paymentMethod/fetchUsable",
      contract_proposal: "contractProposal/show",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_commercial_permission: "commercialPermission/show",
      $_financial_limit: "permissionFinancialLimit/show",
      $_user: "auth/getUser",
      $_general_settings: "generalSetting/show",
    }),
    filteredPaymentTermsByMethod() {
      return this.payment_terms_by_method.filter((item) => this.contract_proposal.payment_terms_uuids.includes(item.uuid));
    },
    minHeightStyle() {
      if (this.contract_proposal?.entity?.is_nfse_observations_required && this.contract_proposal.payment_methods_ids.length) {
        return {minHeight: '425px'}
      } else if (!this.contract_proposal?.entity?.is_nfse_observations_required && this.contract_proposal.payment_methods_ids.length) {
        return {minHeight: "397px"}
      } else {
        return {}
      }
    },
  },
  watch: {
    'contract_proposal.company_plant_id': async function (val) {
      this.bank_accounts = []
      await this.getCompanyPlantIssuers()
      if (!this.$_general_settings.show_bank_account_by_issuer) this.getBankAccounts(false, val)
    },
    'load_counter'(value) {
      // Pausa o loading quando todas as requisições estiverem carregadas.
      if (value === 6) {
        this.loader.hide();
        this.$Progress.finish();
      }
    },
  },
  methods: {
    clear,
    financialBadgeLayout(status) {
      switch (status) {
        case this.financialApprovalStatusEnum.financial_pending:
          return {
            class: "warning",
            img: "hourglass",
            color: "warning",
            text: "Pendente",
          };
        case this.financialApprovalStatusEnum.financial_approval:
          return {
            class: "success",
            img: "thumbs-up",
            color: "success",
            text: "Aprovado",

          };
        case this.financialApprovalStatusEnum.financial_disapproval:
          return {
            class: "danger",
            img: "thumbs-down",
            color: "danger",
            text: "Reprovado",
          };
        default:
          return {
            class: "warning",
            img: "hourglass",
            color: "warning",
            text: "Pendente",

          };
      }
    },
    handleOwedIss() {
      if (this.contract_proposal.iss_retain_fixed) {
        this.contract_proposal.iss_retain_fixed_retained = false;
      }
    },
    openModalContractPrint() {
      this.$refs.modalContractPrint.openModal()
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal);
    },
    handleEntityHistory(entity_id) {
      this.$refs.modalEntityHistory.openModal(entity_id);
    },
    handleContractProposalApprovals(uuid) {
      this.$refs.contractProposalApprovals.openModal(uuid);
    },
    openModalPrint(contractProposal) {
      this.$refs.ModalPrint.openModal(contractProposal)
    },
    goBackToTargetPage() {
      if (!this.contract_proposal.entity) {
        this.$router.push('/commercial/proposal')
      } else {
        this.$router.push('/commercial/contract')
      }
    },
    setFrequency(item) {
      if (this.frequency_selecteds.includes(item)) {
        const index = this.frequency_selecteds.indexOf(item);
        if (index !== -1) {
          this.frequency_selecteds.splice(index, 1);
        }
      } else {
        this.frequency_selecteds.push(item)
      }
    },
    openFrequencyPicker() {
      if (this.contract_proposal.billing.range === 4) {
        this.$refs['frequency-picker'].handleMouseEnter();
      } else {
        this.$refs['frequency-picker'].showPopper = false;
      }
    },
    async validIssuerFixed() {
      if (!this.contract_proposal.issuer_fixed) {
        this.contract_proposal.company_plant_issuer_id_fixed = null;
        if (this.$_general_settings.show_bank_account_by_issuer) {
          await this.getCompanyPlantIssuers()
          if (!this.$_general_settings.show_bank_account_by_issuer) this.getBankAccounts(val)
        }
      } else {
        const default_plant_issuer = this.company_plant_issuers.find((item) => item.default);
        if (default_plant_issuer) {
          this.$_general_settings.show_bank_account_by_issuer && this.getBankAccounts(default_plant_issuer.id)
          return this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
      }
    },
    validPermissionCanIncludeReidi() {
      if (!this.$_financial_limit.invoice_can_include_reidi) {
        this.$notify({
          type: 'danger',
          message: 'Usuário não possui permissão para realizar alteração'
        });
      }
    },
    resetAutomaticBillingInfo() {
      const resetBilling = {
        payment_method_id: null,
        payment_term_id: null,
        range: null,
      }

      this.contract_proposal.billing = {...this.contract_proposal.billing, ...resetBilling};

    },
    setAll() {
      if (this.contract_proposal.status === ContractProposalEnum.COMPLETED) {
        return;
      }
      if (!this.contract_proposal.automatic_billing) {
        // Alteração solicitada pelo Marcos para zerar os campos de formulações quando estiver inativado.
        // Isso pode impactar outras páginas. Se houver problemas, comente apenas a função resetAutomaticBillingInfo
        // e mantenha o return como estava anteriormente.
        this.resetAutomaticBillingInfo()
        return
      }
      const companyPlant = this.plants.find(item => item.id === this.contract_proposal.company_plant_id)
      if (companyPlant) {
        this.contract_proposal.billing.bank_account_id = companyPlant.bank_account_id
      }
      this.setMethods()
    },
    unsetIssRetain() {
      if (!this.contract_proposal.automatic_nfse) {
        this.contract_proposal.iss_retain = false
      }
    },
    setTicket(val) {
      if (val) {
        this.contract_proposal.billing.payment_method_id = 5
      }
    },
    getPaymentIntermediaries(unset_payment_intermediary = true) {
      if (unset_payment_intermediary) {
        this.contract_proposal.billing.payment_intermediary_id = null
      }
      this.payment_intemerdiaries_selected = []
    },
    rangeOffTypes(range_off, type) {
      if (range_off > 1) {
        if (type === 0) {
          return range_off + ' dias'
        } else {
          return range_off + ' horas'
        }
      }
      if (type === 0) {
        return range_off + ' dia'
      } else {
        return range_off + ' hora'
      }
    },
    setPaymentTermsByMethod(clear = true) {
      if (clear) {
        this.contract_proposal.billing.range = null
        this.contract_proposal.payment_terms_uuids = []
        this.contract_proposal.billing.payment_intermediary_id = null
        this.payment_intermediaries_exists = false
      }
      let payment_terms = []
      if (this.entity_is_company) {
        payment_terms = this.payment_terms.filter((item) => item.legal_person)
      } else {
        this.block_iss = true
        payment_terms = this.payment_terms.filter((item) => item.physical_person)
      }
      this.payment_terms_by_principal = payment_terms.filter((item) => {
        return item.allow_in_proposal && item.payment_methods.some(r => this.contract_proposal.payment_methods_ids.includes(r.id))
      })
    },
    getPaymentTerms(clear = true) {
      if (clear) {
        this.contract_proposal.billing.payment_term_id = null
      }
      let paymentMethod = this.payment_methods.find(item => item.id === this.contract_proposal.billing.payment_method_id)
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
      if (!this.contract_proposal.billing.payment_term_id &&
        this.filteredPaymentTermsByMethod.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_term_id = this.filteredPaymentTermsByMethod[0].id
        this.setRange()
      }
    },
    formatAccountLabel(item) {
      return item.name
    },
    setNfse() {
      if (!this.contract_proposal.automatic_nfe) {
        this.contract_proposal.automatic_nfse = false
        this.unsetIssRetain()
      }
    },
    generateRange(start, end) {
      return Array.from({length: (end - start + 1)}, (_, index) => start + index);
    },
    set_entity(entity) {
      this.contract_proposal.service_receiver = entity
      const document_length = onlyNumbers(entity.document).length
      if (document_length <= 11) {
        this.contract_proposal.iss_retain = false
        this.block_iss = true
      } else {
        this.block_iss = false
      }

      this.contract_proposal.entity = entity;
      this.fetchEntityAtachments();
      this.handleSubmit();
    },
    /**
     * Atualiza o nome do cliente de uma construção após a edição de um tomador,
     * caso o CPF/CNPJ do tomador já esteja cadastrado em cliente/obra.
     * @param {string} entityName
     */
    updateCustomerNameAfterEntityEdit(entityName) {
      this.contract_proposal.construction.customer_construction.customer_name = entityName;
    },
    /**
     * Atualiza dados da obra após edição.
     * @param {object} customerConstruction
     */
    updateConstruction(customerConstruction) {
      this.loadingConstruction = true;
      this.contract_proposal.construction = customerConstruction;
      /**
       * Após realizar a edição da obra, verifica se a cidade salva é igual
       * a cidade do endereço padrão do emissor de central (cnpj)
       */
      this.$store.dispatch('companyPlantIssuer/getIssuerCityByCompanyPlantId', this.contract_proposal.company_plant_id)
        .then(response => {
          if (response.data.company_plant_issuer_addres_city == customerConstruction.default_address.city) {
            /**
             * Se regra específica em tela (configuração de nfs-e - migrate):
             * 'informar código da obra' estiver ativo.
             */
            if (response.data.nfse_load_city_hall_construction_code) {
              /**
               * Preenche com o 'Cód. de obra da prefeitura' com o
               * 'Código padrão quando município da obra igual do emissor'
               */
              this.contract_proposal.city_hall_construction_code =
                response.data.standard_code_for_matching_municipalities;
            }
          } else {
            this.contract_proposal.city_hall_construction_code = '';
          }
        });
    },
    setRange() {
      const term = this.payment_terms_by_method.find((item) => item.id === this.contract_proposal.billing.payment_term_id)
      this.contract_proposal.billing.range = term.range
      this.contract_proposal.billing.range_off = term.range_off
      this.contract_proposal.billing.range_off_type = term.range_off_type
    },
    setMethods(clear = true) {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
      if (clear) {
        this.contract_proposal.billing.payment_method_id = null
      }
      this.Payment_methods = this.Payment_methods.filter((item) => this.contract_proposal.payment_methods_ids.includes(item.id))
      if (!this.contract_proposal.billing.payment_method_id &&
        this.Payment_methods.length === 1 && this.contract_proposal.automatic_billing) {
        this.contract_proposal.billing.payment_method_id = this.Payment_methods[0].id
        this.getPaymentIntermediaries()
      }
      this.getPaymentTerms(clear)
    },
    setApprovationStatusAndUser(status) {
      this.$store.dispatch("contractProposal/putApprove", {
        id: this.contract_proposal.id,
        status: status,
      }).then((response) => {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        this.contract_proposal.approvation = status;
        this.temporary_user_approvation = this.$_user.name;
      }).catch((error) => {
        this.$notify({
          type: "danger",
          message: "Algo deu errado. Tente novamente."
        });
      })
    },
    handleSubmit() {
      if (this.contract_proposal.billing.range && this.contract_proposal.billing.range !== 1 && !this.frequency_selecteds.length) {
        this.saving = false
        return this.$swal.fire({
          icon: 'error',
          title: 'Aviso',
          text: 'Informe a frequência de faturamento para continuar',
        });
      }
      this.loadingSave = true;
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });

      if (
        this.contract_proposal.entity && (
          !this.contract_proposal.entity.address ||
          !this.contract_proposal.entity.address.postal_code ||
          !this.contract_proposal.entity.address.state ||
          !this.contract_proposal.entity.address.city ||
          !this.contract_proposal.entity.address.address ||
          !this.contract_proposal.entity.address.number ||
          !this.contract_proposal.entity.address.district
        )
      ) {
        this.$notify({
          type: 'warning',
          message: 'Cadastre endereço para o tomador.'
        });
        this.saving = false
        return;
      }

      let payment_terms = []
      for (let uuid of this.contract_proposal.payment_terms_uuids) {
        const item = this.payment_terms.find((item) => item.uuid === uuid)
        payment_terms.push(item)
      }
      this.contract_proposal.necessary_approvation = payment_terms.some((item) => item.financial_approval === true)
      if (this.contract_proposal.necessary_approvation) {
        this.contract_proposal.necessary_approvation_with_different = true
      }
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.contract_proposal.status = ContractProposalEnum.ACTIVE;
      }
      this.contract_proposal.invoice_frequency = {...this.frequency_selecteds}
      this.$store
        .dispatch("contractProposal/update", this.contract_proposal)
        .then(response => {
          //Codigo da totvs não sera mais usado
          // if (this.contract_proposal && this.contract_proposal.entity) {
          //   $_totvs.update({...this.contract_proposal.construction, ...{entity_id: this.contract_proposal.entity.id}});
          // }
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({type: "danger", message: message});
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        }).finally(() => this.saving = false);
    },
    getCompanyPlantIssuers(set_plant_issuer = true) {
      this.loadingCompanyPlantIssuer = true
      this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
        id: this.contract_proposal.company_plant_id
      }).then(response => {
        const default_plant_issuer = response.data.find(item => item.default)
        this.$_general_settings.show_bank_account_by_issuer && default_plant_issuer && this.getBankAccounts(default_plant_issuer.id)
        if (default_plant_issuer && this.contract_proposal.issuer_fixed && set_plant_issuer && !this.contract_proposal.company_plant_issuer_id_fixed) {
          this.contract_proposal.company_plant_issuer_id_fixed = default_plant_issuer.id
        }
        this.loadingCompanyPlantIssuer = false
      }).catch(error => {
        this.loadingCompanyPlantIssuer = false
      })
    },
    handleSearchConstruction() {
      this.$refs.searchConstruction.handleSearchConstruction(
        "customer_name or construction_name"
      );
    },
    handleSearchCpfCnpj() {
      const opened_by_admin_for_entity_edit_in_contract_edit = true;
      this.$refs.searchCpfCnpj.handleSearchCpfCnpj(opened_by_admin_for_entity_edit_in_contract_edit);
    },
    handleCreateCpfCnpj() {
      this.$refs.createEntity.openModal(true);
    },
    async handleUpdateStatus(contractProposal) {
      this.loading = true

      if (
        [
          this.ContractProposalEnum.PENDING_APPROVAL,
          this.ContractProposalEnum.PENDING_REVIEW,
        ].includes(contractProposal.status)
      ) {
        if (!hasPermission("additional_permissions.commercial_approvation")) {
          return;
        }
        this.handleContractProposalApprovals(contractProposal.uuid);
      }
      if (
        ![
          this.ContractProposalEnum.ACTIVE,
          this.ContractProposalEnum.INACTIVE,
          this.ContractProposalEnum.PROPOSAL,
          this.ContractProposalEnum.COMPLETED,
        ].includes(contractProposal.status)
      ) {
        return;
      }
      this.$Progress.start()
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });

      try {
        const response = await this.$store.dispatch("contractProposal/fetchSituations");
        this.$Progress.finish();
        this.loading = false
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      } catch (error) {
        this.$notify({
          type: response.error_type,
          message: response.message
        });
        return;
      }

      this.$refs.changeStatus.handleChangeStatus(contractProposal);
    },
    handleRemoveAdditionalInformation(id, index) {
      this.$Progress.start();
      const payload = {
        id: id
      };
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: 'info',
              message: 'Estamos trabalhando em sua solicitação.'
            });
            this.$store
              .dispatch("contractProposal/remove_comment", payload)
              .then(response => {
                this.contract_proposal.comments.splice(index, 1);
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
              }).catch(error => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message
              });
            });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    handleCreateAdditionalObservation(contratctProposalUuid) {
      this.$refs.createAdditionalObservation.openModal(contratctProposalUuid);
    },
    handleEditEntity() {
      this.$refs.editEntity.openModal(true, this.contract_proposal.entity.uuid);
    },
    handleEditConstruction() {
      this.$refs.editConstruction.handleEditModal(this.contract_proposal.construction.uuid);
    },
    active(status, value) {
      if (status == value) {
        return "active";
      }
    },
    filteredPaymentTerms() {
      if (this.entity_is_company) {
        return this.payment_terms.filter((item) => item.legal_person)
      } else {
        return this.payment_terms.filter((item) => item.physical_person)
      }
    },
    sketch(status) {
      if (status === 4) {
        return 'text-white bg-light'
      }
    },
    changeStatus(status) {
      this.contract_proposal.status = status
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(() => {
          this.loading = false;
        });
    },
    fetchFinancialLimits(userId) {
      this.$store.dispatch('permissionFinancialLimit/show', userId);
    },
    async fetch() {
      this.block_iss = false
      this.loadingMethod = true
      this.load_counter = 0;
      this.loader = this.$loading.show();
      this.$Progress.start();
      await this.fetchCommercialPermissions();
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.load_counter++;
        this.loadingPlant = false;
        this.checkPlantGeneralSettings();
      });
      this.$store.dispatch("contractContracts/fetchItems").then(() => {
        this.load_counter++;
        this.loadingContract = false;
      });
      this.loading = true;
      this.loadingUsers = true
      this.$store.dispatch("user/getSellersSimplified").then((response) => {
        this.load_counter++;
        this.users = response.data;
        this.loadingUsers = false;
      });
      this.$store
        .dispatch(
          "contractProposal/showSimplified",
          this.$route.params.contract_proposal_uuid
        )
        .then(async (response) => {
          this.load_counter++;
          if (this.contract_proposal && this.contract_proposal.entity) {
            const document_length = onlyNumbers(this.contract_proposal.entity.document).length
            if (document_length <= 11) {
              this.contract_proposal.iss_retain = false
              this.block_iss = true
            } else {
              this.block_iss = false
            }
          }
          this.frequency_selecteds = response.data.invoice_frequency
          this.$store.dispatch("paymentMethod/fetchItems")
            .then(() => {
              this.load_counter++;
              this.loadingPaymentIntermediaries = true
              this.$store.dispatch("paymentIntermediary/fetchItems").then(() => {
                this.getPaymentIntermediaries(false)
                this.loadingPaymentIntermediaries = false
              })
              this.loadingMethod = false;
              this.$store.dispatch("paymentTerm/fetchItems").then(() => {
                this.loadingPaymentTerm = false;
                this.setPaymentTermsByMethod(false)
                if (this.contract_proposal.automatic_billing) {
                  this.setMethods(false)
                }
              });
            }).catch(() => {
          })
          const is_seller = this.users.find(item => item.uuid === this.$store.state.auth.user.uuid)
          if (is_seller && !this.contract_proposal.seller) {
            this.contract_proposal.seller = this.$store.state.auth.user.uuid
          }
          this.entity_is_company = false
          if (
            this.contract_proposal &&
            this.contract_proposal.service_receiver &&
            this.contract_proposal.service_receiver.document.replace(/[^0-9]/g, '') > 11
          ) {
            this.entity_is_company = true
          }
          if (!this.contract_proposal.automatic_billing) {
            this.contract_proposal.billing.company_plant_id = this.contract_proposal.plant.id
          }
          await this.getCompanyPlantIssuers(false)
          this.fetchEntityAtachments();
          this.fetchConstructionAtachments();
          this.fetchEntityHistory();
          this.getBankAccounts(false)
          this.loading = false;
        }).catch(() => {
        this.loading = false;
      });
    },
    SetServiceInvoiceNotes() {
      this.contract_proposal.observation = this.contract_proposal.entity.default_text;
    },
    /**
     * Verifica as configurações gerais da central selecionada para deixar os campos
     * 'cno' e 'Cód. de obra da prefeitura' obrigatórios.
     */
    checkPlantGeneralSettings() {
      const plant = this.plants.find((item) => item.id === this.contract_proposal.company_plant_id);
      this.$store.dispatch("plant/getGeneralSettings", plant.uuid).then((response) => {
        this.is_cno_required_prop_contracts = response.data.is_cno_required_prop_contracts;
        this.is_municipal_work_code_required_prop_contracts = response.data.is_municipal_work_code_required_prop_contracts;
        this.is_art_encapsulation_required = response.data.is_art_encapsulation_required;
        this.load_counter++;
      });
    },
    refresh() {
      this.fetch();
    },
    /**
     * Carrega total de anexos da obra associada ao contrato.
     */
    fetchConstructionAtachments() {
      this.loadingConstructionAttachments = true;
      this.$store.dispatch("constructionAttachment/getTotalAttachmentByConstructionId", this.contract_proposal.construction.id)
        .then((response) => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
          this.load_counter++;
          this.loadingConstructionAttachments = false;
        });
    },
    /**
     * Abre modal de listagem de anexos da obra.
     * @param {number} constructionId
     * @param {string} constructionName
     */

    handleShowConstructionAttachments(constructionId, constructionName) {
      this.$refs.modalConstructionAttachmentList.handleCreateModal(constructionId, constructionName);
    },
    /**
     * Atualiza total de anexos após realizar o upload.
     *  @param {number} constructionId
     */
    updatedTotalConstructionAttachmentAfterUpload(constructionId) {
      this.$store.dispatch('constructionAttachment/getTotalAttachmentByConstructionId', constructionId)
        .then(response => {
          this.totalConstructionAttachments = response.data.total_construction_attachments;
        });
    },
    /**
     * Atualiza total de anexos após deletar um anexo.
     */
    updateTotalConstructionAttachmentAfterDeletion() {
      this.totalConstructionAttachments--;
    },
    /**
     * Carrega total de anexos da obra associada ao contrato.
     */
    fetchEntityAtachments() {
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.loadingEntityAttachments = true;
        this.$store.dispatch("entity/getTotalAttachmentsByEntityId", this.contract_proposal.entity.id)
          .then((response) => {
            this.totalEntityAttachments = response.data.total_entity_attachments;
            this.load_counter++;
            this.loadingEntityAttachments = false;
          });
      }
    },
    /**
     * Abre modal de listagem de anexos de clientes/fornecedores.
     * @param {number} entityId
     * @param {string} name
     * @param {string} document
     */
    handleShowEntityAttachments(entityId, name, document) {
      this.$refs.modalEntityAttachmentList.handleCreateModal(entityId, name, document);
    },
    /**
     * Atualiza total de anexos de clientes/fornecedores após realizar o upload.
     * @param {number} entityId
     */
    updatedTotalEntityAttachmentAfterUpload(entityId) {
      this.$store.dispatch('entity/getTotalAttachmentsByEntityId', entityId).then(response => {
        this.totalEntityAttachments = response.data.total_entity_attachments;
      });
    },
    /**
     * Após excluir um anexo de clientes/fornecedores, é atualizado o total de arquivos anexados.
     */
    updateTotalEntityAttachmentAfterDeletion() {
      this.totalEntityAttachments--;
    },
    /**
     * @param {number} status
     */
    getStatusText(status) {
      switch (status) {
        case 0:
          return 'inativo';
        case 1:
          return 'ativo';
        case 2:
          return 'Pendente de revisão';
        case 3:
          return 'Pendente de aprovação';
        case 5:
          return 'concluído';
        default:
          return 'proposta';
      }
    },
    clearBankAccount() {
      this.contract_proposal.billing.bank_account_id = null
    },
    clearBankAccountByIssuer() {
      if (this.$_general_settings.show_bank_account_by_issuer) {
        this.clearBankAccount()
      }
    },
    getBankAccounts(set = true, typeableId = null) {
      this.loadingBankAccounts = true;
      let typeId = typeableId
      if (!typeableId) {
        if (this.$_general_settings.show_bank_account_by_issuer) {
          if (this.contract_proposal.company_plant_issuer_id_fixed) {
            typeId = this.contract_proposal.company_plant_issuer_id_fixed
          } else {
            typeId = this.company_plant_issuers.find((item) => item.default)?.id
          }
        } else {
          typeId = this.contract_proposal.company_plant_id
        }
      }
      if (!typeId) {
        this.loadingBankAccounts = false
        return this.bank_accounts = []
      }
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: typeId,
          })
        .then((response) => {
          this.loadingBankAccounts = false;
          this.bank_accounts = response.data;
          if (!this.$_general_settings.show_bank_account_by_issuer) {
            const company_plant = this.plants.find(
              (item) => item.id === this.contract_proposal.company_plant_id
            );
            if (set) {
              this.contract_proposal.billing.bank_account_id = company_plant.bank_account_id
            }
          } else {
            this.contract_proposal.billing.bank_account_id = this.bank_accounts.find((item) => item.default).bank_account_id
          }
        }).finally(() => this.loadingBankAccounts = false)
    },
    /**
     * @param {number} status
     * @param {object} entity
     */
    getButtonType(status, entity) {
      switch (status) {
        case ContractProposalEnum.INACTIVE:
          return 'danger';
        case ContractProposalEnum.ACTIVE:
          if (entity) {
            return 'success';
          } else {
            return 'light';
          }
        case ContractProposalEnum.COMPLETED:
          return 'primary';
        default:
          return 'light';
      }
    },
    fetchCommercialPermissions() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    /**
     * Busca o histórico de clientes/fornecedores, para determinar se o componente
     * PuzlSelect de 'central' e 'vendedor' devem ser desabilitados ou não.
     *
     * Se 'opened_billing' for maior que zero, a permissão do usuário
     * ($_commercial_permission.contract_editor_and_seller) é verificada.
     *
     * Se 'opened_billing' for igual a zero, qualquer usuário pode editar o PuzlSelect,
     * independentemente de suas permissões.
     */
    fetchEntityHistory() {
      if (this.contract_proposal && this.contract_proposal.entity) {
        this.$store.dispatch("entityHistory/getHistory", {
          filter: {},
          id: this.contract_proposal.entity.id,
        }).then((response) => {
          this.opened_billing = response.data.opened_billing; // Campo 'a faturar' da modal histórico de cliente.
          this.load_counter++;
        });
      }
    },
    /**
     * Abre modal de pesquisa do tomador por documento.
     * @param {object} contract_proposal
     */
    handleShowModalSearchEntity(contract_proposal) {
      // Validações de configurações gerais da central antes de gerar um contrato.
      if (this.is_cno_required_prop_contracts && !this.contract_proposal.cno) {
        this.$notify({
          type: 'warning',
          message: 'O campo CNO é obrigatório para gerar o contrato.'
        });
        return;
      }

      if (this.is_municipal_work_code_required_prop_contracts && !this.contract_proposal.city_hall_construction_code) {
        this.$notify({
          type: 'warning',
          message: 'O campo Cód. de obra da prefeitura é obrigatório para gerar o contrato.'
        });
        return;
      }

      if (this.is_art_encapsulation_required && !this.contract_proposal.art_code) {
        this.$notify({
          type: 'warning',
          message: 'O campo Art/nº de controle do encapsulamento é obrigatório para gerar o contrato.'
        });
        return;
      }

      this.$refs.modalSearchEntity.openModal(contract_proposal);
    },
  },
  async mounted() {
    await this.fetchFinancialLimits(this.$user.id)
    await this.$store.dispatch('generalSetting/show')
    this.fetch()
  }
};
</script>

<style lang="scss">
@import '@/style/PuzlCustom/App.scss';

.contract-proposal-edit-page {
  .financial-badge::after {
    content: none;
  }

  .financial-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $muted;
    text-transform: uppercase;
    font-weight: 400 !important;
    padding: 4px 8px;
    border-radius: 20px !important;

    &.warning {
      color: $warning;
      border-color: $warning;
    }

    &.success {
      color: $success;
      border-color: $success;
    }

    &.danger {
      border-color: $danger;
      color: $danger;
    }
  }

  .contract-card {
    .app-card-title-wrapper {
      min-height: 32px;
      @media screen and (min-width: 768px) {
        .vs__dropdown-toggle {
          min-width: 210px;
        }
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .vs__dropdown-toggle {
          min-width: 300px;
          width: 100%;
          margin-top: 12px;
        }
      }
    }
  }

  .vs--single > div.vs__dropdown-toggle {
    border-color: $muted-medium;
    height: 32px !important;
  }

  .vs--disabled > div.vs__dropdown-toggle {
    height: fit-content !important;
  }

  .input-limit,
  .input-limit .form-group,
  .input-limit .form-control,
  .input-limit input {
    height: 32px !important;
    font-size: 12px !important;
    font-family: Fredoka;
  }


  .is-invalid {
    border: 1px solid $danger !important;
    border-radius: 4px;
    animation: shake 0.3s ease-in-out;

    .input-limit {
      border: 1px solid $danger !important;
      border-radius: 4px;
      animation: shake 0.3s ease-in-out;
    }
  }

  .reset-input-css {
    .input-group-sm > input, .input-group-text, input {
      box-shadow: none !important;
    }

    .vs__selected {
      font-size: 12px !important;
      border: none !important;
      font-family: Fredoka;
    }

    &.invoice-card .vs__selected {
      padding: 4px;
      margin: 4px 0 0 8px;
      font-family: Fredoka;
      background-color: $muted-light;
    }

    .badge-wrapper a {
      width: 100%;
    }
  }
}


</style>

<style scoped lang="scss">

@import "@/style/PuzlCustom/App.scss";

.is-invalid {
  border: 1px solid $danger !important;
  border-radius: 4px;
  animation: shake 0.3s ease-in-out;
}

/* Shake animation */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.contract-proposal-edit-page {

  .page-header {
    @media screen and (max-width: 768px) {
      flex-direction: column-reverse;
      flex-wrap: wrap;

      .header-buttons {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  .contract-info {
    h3 {
      margin-bottom: 4px;
    }

    span {
      font-weight: 400 !important;
    }
  }

  .app-badge {
    margin-bottom: 8px;
    padding: 4px;
    border-radius: 50px;
    min-height: 24px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px 0px #0000001a;

    &:hover {
      cursor: pointer;
    }

    &.proposta {
      background-color: $muted-light;
    }
  }

  .app-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    min-width: 100px;
    border: 1px solid $muted-light;
    border-radius: 8px;
    font-family: Fredoka;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: $dark;
    line-height: 1;
    transition: all .2s ease-in-out;


    &:hover {
      background-color: $muted-lighter;
      cursor: pointer;
    }
  }

  .app-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .invoice-form-group {
    @media screen and (max-width: 768px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .custom-spacer {
    @media screen and (max-width: 768px) {
      margin-bottom: 0 !important;
    }
  }

  .app-button-financial {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $muted-light;
    padding: 4px 20px;
    background-color: white;
    min-height: 0 !important;
    transition: all .2s ease-in-out;


    &.danger {
      color: $danger;

      &:hover {
        border-color: $danger;
        background-color: $danger;
        color: white;
      }
    }

    &.success {
      color: $success;

      &:hover {
        border-color: $success;
        background-color: $success;
        color: white;
      }
    }
  }

  .financial-approval-text {
    color: $dark;
    font-size: 12px;
    display: block;
    margin-bottom: 4px;

    @media screen and (min-width: 992px) {
      margin-top: -4px;
    }
  }
}

.reset-input-css {
  label {
    color: $dark;
    font-family: Fredoka;
    font-size: 14px;
    font-weight: 400;
    padding: 0;

    &.popover-label {
      font-size: 12px;
    }

    &.popover-title {
      padding: 0 15px 12px;
      margin-bottom: 12px;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 99%;
        height: 1px;
        bottom: 0;
        left: 1px;
        background-color: $muted-light;
      }
    }
  }

  .form-control-sm {
    display: flex;
    align-items: center;
    padding: 8px;
    height: 32px;
    font-family: Fredoka;
    font-size: 12px;
    font-weight: 400;
    color: $dark;
    border: 1px solid $muted-medium;
  }

  .col-md-7 {
    padding-right: 0 !important;
  }

  @media screen and (max-width: 768px) {
    .app-form-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & .col-md-7 {
        padding: 0;
      }
    }

    .invoice-form-group {
      & > div {
        padding: 0;
      }
    }
  }

  .app-form-group.app-row {
    margin-bottom: 0;

    .col-md-4 {
      margin-bottom: 16px;
    }
  }

  .input-group-sm > input, .input-group-text, input {
    box-shadow: none !important;
  }

  .frequency-button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 32px;
    border: 1px solid $muted-medium;
    border-radius: 4px;
    transition: all .2s ease-in-out;

    &.is-active {
      color: $success;
      background-color: $success-lighter;
      border-color: $success;
    }

    &:hover {
      cursor: pointer;
      background-color: $muted-lighter;
    }
  }

  .date-picker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $muted-medium;
    border-top-color: transparent;
    padding: 1rem;
  }
}


.frequency:not(.frequency-selected) {
  font-family: system-ui;
  font-size: 12px;
  min-width: 25px;
  padding: 6px;
}

.frequency:hover {
  background-color: #dbd9d9;
}

.frequency-selected {
  font-family: system-ui;
  font-size: 12px;
  padding: 6px;
  color: white;
  min-width: 25px;
  background-color: #e65119;
}
</style>
