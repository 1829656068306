const keys = Object.freeze({
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  XXL: "xxl",
});

/**
 * @param {string} value
 * @returns {string}
 */
const getHeight = (value) => {
  switch (value) {
    case keys.SM: return "32px";
    case keys.MD: return "36px";
    case keys.LG: return "40px";
    case keys.XL: return "48px";
    case keys.XXL: return "56px";
    default: return "36px";
  }
};

export default {
  keys,
  getHeight,
};