<template>
  <div>
    <modal size="lg" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div class="col-md-12 table-responsive">
        <div class="row" style="max-height: 400px">
          <div class="col-md-12 mb-1">
            <base-input placeholder="Pesquisar">
              <template slot="append">
                <i class="fas fa-search"></i>
              </template>
            </base-input>
            <div class="row mt-2" v-if="loadingSkeleton">
              <div class="col-md-12">
                <SkeletonPuzl v-for="item in 5" class="mb-2" type="button"/>
              </div>
            </div>
            <div class="row mt-2" style="height: 70%;overflow: auto;" v-else>
              <div class="col-12" v-for="additional in additionals">
                <div class="border p-2 card--hover mt-1">
                  <div class="d-flex justify-content-between">
                    <div class="col-10">
                      <div class="d-flex justify-content-between">
                        <div>
                    <span class="h5 d-block">
                      {{ additional.short_description }}
                    </span>
                        </div>
                        <div>
                    <span class="h5 d-block">
                     {{ additional.standard_value}} {{additional.billing_unit_description}}
                    </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto">
                      <button type="button" :disabled="loading"
                              class="btn btn-sm rounded-circle btn-success" @click.prevent="save(additional.uuid)">
                        <i class="fas fa-check"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--          <div class="col-md-6"  v-for="additional in additionals">-->
          <!--            <div class="">-->
          <!--              <card class="mb-1 border" style="min-height: 360px">-->
          <!--                <div class="row align-items-center">-->
          <!--                  <div class="col">-->
          <!--                    <div>-->
          <!--                      <h4> {{additional.short_description}}</h4>-->
          <!--                    </div>-->
          <!--                    <div>-->
          <!--                      <p class="text-truncate" style="width: 25vh" :title="additional.description">-->
          <!--                        <small>{{additional.description}}</small></p>-->
          <!--                    </div>-->
          <!--                  </div>-->
          <!--                  <div class="col-auto">-->
          <!--                    <button type="button" class="btn btn-sm rounded-circle btn-success" @click="removeMold(index)">-->
          <!--                      <i class="fas fa-check"></i>-->
          <!--                    </button>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <div class="row mb-2">-->
          <!--                  <div class="col-6">Valor padrão:</div>-->
          <!--                  <small class="col-6"><b>{{ additional.standard_value }}</b></small>-->
          <!--                  <div class="col-6">Unid. de Cobrança:</div>-->
          <!--                  <small class="col-6"><b>{{ additional.billing_unit_description }}</b></small>-->
          <!--                </div>-->
          <!--                <template class="">-->
          <!--                  <div class="row">-->
          <!--                    <div class="col-6"><small><b>Parâmetro A</b></small></div>-->
          <!--                    <div class="col-6"><small>-->
          <!--                      {{-->
          <!--                        (additional.params_description &&-->
          <!--                          additional.params_description["a"] &&-->
          <!--                          additional.params_description["a"].param) ||-->
          <!--                        "-"-->
          <!--                      }}-->
          <!--                    </small></div>-->
          <!--                    <div class="col-6"><small><b>Valor A</b></small></div>-->
          <!--                    <div class="col-6"><small>-->
          <!--                      {{-->
          <!--                        (additional.params_description &&-->
          <!--                          additional.params_description["a"] &&-->
          <!--                          additional.params_description["a"].values) ||-->
          <!--                        "-"-->
          <!--                      }}-->
          <!--                    </small></div>-->
          <!--                    <div class="col-6"><small><b>Parâmetro B</b></small></div>-->
          <!--                    <div class="col-6"><small>-->
          <!--                      {{-->
          <!--                        (additional.params_description &&-->
          <!--                          additional.params_description["b"] &&-->
          <!--                          additional.params_description["b"].param) ||-->
          <!--                        "-"-->
          <!--                      }}-->
          <!--                    </small></div>-->
          <!--                    <div class="col-6"><small><b>Valor B</b></small></div>-->
          <!--                    <div class="col-6"><small>-->
          <!--                      {{-->
          <!--                        (additional.params_description &&-->
          <!--                          additional.params_description["b"] &&-->
          <!--                          additional.params_description["b"].values) ||-->
          <!--                        "-"-->
          <!--                      }}-->
          <!--                    </small></div>-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--                <div class="row mt-2 text-primary">-->
          <!--                  <div class="col-6" v-for="date in additional.dates" :key="date">-->
          <!--                    <small class="checklist-title mb-0" v-if="date === 0">-->
          <!--                      Dia útil-->
          <!--                    </small>-->
          <!--                    <small class="checklist-title mb-0" v-if="date === 1">-->
          <!--                      Sábado-->
          <!--                    </small>-->
          <!--                    <small class="checklist-title mb-0" v-if="date === 2">-->
          <!--                      Domingo-->
          <!--                    </small>-->
          <!--                    <small class="checklist-title mb-0" v-if="date === 3">-->
          <!--                      Feriado-->
          <!--                    </small>-->
          <!--                  </div>-->
          <!--                  </div>-->
          <!--              </card>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzl from "../../../../../../components/SkeletonPuzl";

export default {
  components: {SkeletonPuzl},
  name: "ModalAddAdditionals",
  data() {
    return {
      modal: false,
      title: 'Incluir Adicionais',
      disabledSave: false,
      loading: false,
      loadingSkeleton: false,
    }
  },
  computed: {
    ...mapGetters({
      additionals: 'additional/additionals',
    }),
  },
  methods: {
    init() {
      this.loadingSkeleton = true
      let params = {
        filter : {
          dont_associated : true,
          schedule_travel_uuid: this.$route.params.travel_uuid
        }
      }
      this.$store.dispatch('additional/fetchAdditionals', params).then(() => {
        this.loadingSkeleton = false
      }).catch(() =>
        this.loadingSkeleton = false
      )
    },
    openModal() {
      this.init()
      this.modal = true
    },
    save(uuid) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      let params = {
        additional_uuid : uuid,
        schedule_travel_uuid: this.$route.params.travel_uuid
      }
      this.loading = true;
      this.$Progress.start();
      this.$store
        .dispatch(
          "scheduleAdditional/add",
          params)
        .then(response => {
          this.loading = false;
          this.$Progress.finish();
          let index = this.$store.state.additional.items.findIndex((item) => item.uuid === response.data.additional_uuid)
          this.$store.state.additional.items.splice(index, 1)
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        }).catch(error => {
        this.loading = false;
        this.$Progress.finish();
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        });
      });
    },
    close: function () {
      this.$emit('getAdditionals')
      this.modal = false
    }
  }
}
;
</script>

<style></style>
