<template>
  <div class="user-permission" v-if="isOpen">
    <modal :show.sync="isOpen" size="xl" class="user-permission-modal">
      <template slot="header">
        <AppIcon icon="settings" color="primary" width="24px" class="mr-3" />
        <h5 class="modal-title">Configurações</h5>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
          class="close"
        />
      </template>
      <AppTab defaultValue="general" ref="tabsRef">
        <AppTabList slot="tab-list">
          <AppTabTrigger value="general" title="geral" icon="automation" />
          <AppTabTrigger
            value="technology"
            title="Tecnologia"
            icon="physics"
            color="primary"
          />
          <AppTabTrigger
            value="commercial"
            title="comercial"
            icon="briefcase"
            color="success"
          />
          <AppTabTrigger
            value="operational"
            title="operacional"
            icon="chemical-plant-3"
            color="warning"
          />
          <AppTabTrigger
            value="financial"
            title="financeiro"
            icon="duration-finance"
            color="danger"
          />
          <AppTabTrigger
            value="purchases"
            title="Compras"
            icon="shopping-cart"
            color="warning"
          />
        </AppTabList>

        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(handleUpdate)"
            autocomplete="off"
          >
            <template v-if="!loadingStore">
              <!-- GERAL -->
              <AppTabContent title="Geral" icon="automation" value="general">
                <!-- Grupos -->
                <AppTabSection title="Grupos">
                  <div class="app-form-group">
                    <label>PERMISSÃO</label>
                    <PuzlSelect
                      class="app-tab-input"
                      v-model="user.role_id"
                      :items="$_group_permissions"
                      :loading="loadingStore"
                      :disabled="loadingStore"
                      :disableBoxShadow="true"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>Alerta</label>
                    <PuzlSelect
                      class="app-tab-input"
                      v-model="user.alert_group_id"
                      :items="$_alert_groups"
                      :loading="loadingStore"
                      :disabled="loadingStore"
                      :disableBoxShadow="true"
                    />
                  </div>
                </AppTabSection>
                <AppTabSection title="Alocação">
                  <div class="app-form-group">
                    <label>Alertas</label>
                    <AppSwitch v-model="user.notify" />
                  </div>
                  <div class="app-form-group">
                    <label>Central</label>
                    <PuzlSelect
                      class="app-tab-input"
                      v-model="user.company_plant_uuids"
                      :items="$_plants"
                      customKey="uuid"
                      :multiple="true"
                      :loading="loadingStore"
                      :disabled="loadingStore"
                      @input="setAllPlants"
                      :disableBoxShadow="true"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>Vendedores</label>
                    <PuzlSelect
                      class="app-tab-input"
                      v-model="user.view_sellers"
                      :items="$_sellers"
                      :multiple="true"
                      :loading="loadingStore"
                      :disabled="loadingStore"
                      @input="setAllSellers"
                      :disableBoxShadow="true"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>CONTA BANCÁRIA</label>
                    <PuzlSelect
                      class="app-tab-input"
                      v-model="user.bank_account_ids"
                      :items="$_bank_accounts"
                      :multiple="true"
                      :loading="loadingStore"
                      :disabled="loadingStore"
                      @input="setAllBankAccounts"
                      :disableBoxShadow="true"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Tecnologia -->
              <AppTabContent
                title="Tecnologia"
                icon="physics"
                value="technology"
                color="primary"
              >
                <AppTabSection title="Geral">
                  <div class="app-form-group">
                    <label>
                      Alterar referências comerciais dos grupos de MCC
                    </label>
                    <AppSwitch
                      v-model="
                        userTechPermission.is_cmc_group_business_references_modified
                      "
                      @click="changeLimit"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      Alterar referências técnicas dos grupos de MCC
                    </label>
                    <AppSwitch
                      v-model="
                        userTechPermission.is_change_technical_references_of_mcc_groups
                      "
                      @click="changeLimit"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      Visualizar dados técnicos dos traços em lançamento de
                      resultados de ensaios
                    </label>
                    <AppSwitch
                      v-model="
                        userTechPermission.is_view_technical_data_of_traces_in_test_results_launch
                      "
                      @click="changeLimit"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar preços de MCC </label>
                    <AppSwitch
                      v-model="userTechPermission.is_mcc_prices_changed"
                      @click="changeLimit"
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      Concluir carregamentos com desvios acima do limite
                      configurado
                    </label>
                    <AppSwitch
                      v-model="
                        userTechPermission.is_block_completion_possible_with_excessive_deviation
                      "
                      @click="changeLimit"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Comercial -->
              <AppTabContent
                title="Comercial"
                icon="briefcase"
                value="commercial"
                color="success"
              >
                <!-- Limitação de desconto -->
                <AppTabSection title="Limitação de desconto">
                  <div class="app-form-group">
                    <label> Aprovação de preço mínimo </label>
                    <AppSwitch
                      v-model="userCommercialPermission.minimum_price_approval"
                      @click="changeLimit"
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="userCommercialPermission.minimum_price_approval"
                  >
                    <label> LIMITAR DESCONTO</label>
                    <AppSwitch
                      v-model="userCommercialPermission.limit_variation"
                    />
                  </div>
                  <AppTabSectionConditional
                    v-if="userCommercialPermission.limit_variation"
                  >
                    <div class="app-form-group custom-fields">
                      <label> Por margem de usinagem de concreto </label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userCommercialPermission.margin_machining_limit
                        "
                        :minValue="0"
                        :maxValue="1000000"
                        prepend="R$"
                      />
                      <AppInputNumber
                        v-model="
                          userCommercialPermission.margin_machining_limit_percentage
                        "
                        :minValue="0"
                        :maxValue="100"
                        append="%"
                        :decimalPlaces="1"
                      />
                    </div>
                    <div class="app-form-group custom-fields">
                      <label> Por preço mínimo de venda do concreto</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="userCommercialPermission.margin_sell_limit"
                        :minValue="0"
                        :maxValue="1000000"
                        prepend="R$"
                      />
                      <AppInputNumber
                        v-model="
                          userCommercialPermission.margin_sell_limit_percentage
                        "
                        :minValue="0"
                        :maxValue="100"
                        append="%"
                        :decimalPlaces="1"
                      />
                    </div>
                    <div class="app-form-group custom-fields">
                      <label> Por preço mínimo de serviços (valor fixo)</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userCommercialPermission.minimum_price_service_limit
                        "
                        :minValue="0"
                        :maxValue="1000000"
                        prepend="R$"
                      />
                      <AppInputNumber
                        v-model="
                          userCommercialPermission.minimum_price_service_limit_percentage
                        "
                        :minValue="0"
                        :maxValue="100"
                        append="%"
                        :decimalPlaces="1"
                      />
                    </div>
                    <div class="app-form-group custom-fields">
                      <label>
                        Por preço mínimo de serviços por m<sup>3</sup></label
                      >
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userCommercialPermission.minimum_price_m3_service_limit
                        "
                        :minValue="0"
                        :maxValue="1000000"
                        prepend="R$"
                      />
                      <AppInputNumber
                        v-model="
                          userCommercialPermission.minimum_price_m3_service_limit_percentage
                        "
                        :minValue="0"
                        :maxValue="100"
                        append="%"
                        :decimalPlaces="1"
                      />
                    </div>
                    <div class="app-form-group custom-fields">
                      <label> Por preço mínimo de serviços por hora</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userCommercialPermission.minimum_price_hour_service_limit
                        "
                        :minValue="0"
                        :maxValue="1000000"
                        prepend="R$"
                      />
                      <AppInputNumber
                        v-model="
                          userCommercialPermission.minimum_price_hour_service_limit_percentage
                        "
                        :minValue="0"
                        :maxValue="100"
                        append="%"
                        :decimalPlaces="1"
                      />
                    </div>
                  </AppTabSectionConditional>
                  <label
                    class="warning-text"
                    v-if="userCommercialPermission.limit_variation"
                  >
                    <AppIcon icon="error" color="danger" width="20px" />
                    Campos com desconto igual a zero, serão desconsiderados
                  </label>
                </AppTabSection>
                <!-- CONTRATO -->
                <AppTabSection title="Contrato">
                  <div class="app-form-group">
                    <label> Alterar situação de contratos</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.change_contract_situation
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Visualizar detalhes de margem</label>
                    <AppSwitch
                      v-model="userCommercialPermission.view_margin_details"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar preço de venda de viagens entregues</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.change_sale_price_of_delivered_trip
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar e/ou excluir preços de adicionais</label>
                    <AppSwitch
                      v-model="userCommercialPermission.edit_additional_values"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Editar central e vendedor do contrato</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.contract_editor_and_seller
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      Excluir informações complementares com data menor que
                      hoje</label
                    >
                    <AppSwitch
                      v-model="
                        userCommercialPermission.is_supplementary_info_before_today
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> editar recebimentos</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.is_receipt_editing_allowed
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> editar faturas/boletos</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.is_billing_document_modifiable
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Ignorar assinatura digital dos contratos</label>
                    <AppSwitch
                      v-model="
                        userCommercialPermission.ignore_digital_signature
                      "
                    />
                  </div>
                </AppTabSection>
                <!-- Cadastro de cliente/tomador -->
                <AppTabSection title=" Cadastro de cliente/tomador ">
                  <div class="app-form-group">
                    <label>
                      Editar o campo de vendedor intermediador e/ou parceiro
                      comercial</label
                    >
                    <AppSwitch
                      v-model="
                        userCommercialPermission.is_edit_intermediary_seller_field
                      "
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- Operacional -->
              <AppTabContent
                title="Operacional"
                icon="chemical-plant-3"
                value="operational"
                color="warning"
              >
                <!-- Programação e viagens -->
                <AppTabSection title="PROGRAMAÇÃO E VIAGENS">
                  <div class="app-form-group">
                    <label> CancelaR viagens </label>
                    <AppSwitch
                      v-model="userOperationalPermission.travel_cancellation"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> CancelaR serviços </label>
                    <AppSwitch
                      v-model="userOperationalPermission.service_cancellation"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> CancelaR adicionais </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.cancellation_of_additions
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar volume de serviços </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_edit_schedule_service_volume
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar data da concretagem </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_edit_schedule_travel_finished_at
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar motorista e equipamento </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.is_user_allowed_to_change_driver_and_equip
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> “NÃO Realizar” Viagem com O.S já emitida </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_not_perform_travel_with_number
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label>
                      Emitir O.S de serviços em programações concluídas ou
                      pendentes
                    </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_issue_schedule_service_order_in_finished_or_pending_schedule
                      "
                    />
                  </div>
                </AppTabSection>
                <!-- Carregamento -->
                <AppTabSection title="carregamento">
                  <div class="app-form-group">
                    <label> Aprovar reuso </label>
                    <AppSwitch
                      v-model="userOperationalPermission.reuse_approval"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Limpar MCC no carregamento </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_clear_schedule_travel_mcc
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Realizar carregamento manual </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.can_manual_schedule_travel_charging
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Justificar viagem sem anexo </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.is_user_allowed_justify_travel_without_attachment
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Editar produto do material </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.allow_edit_material_product
                      "
                    />
                  </div>
                </AppTabSection>
                <!-- ESTOQUE -->
                <AppTabSection title="Estoque">
                  <div class="app-form-group">
                    <label> DETALHES DE ESTOQUE </label>
                    <AppSwitch
                      v-model="userOperationalPermission.stock_details"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> LANÇAMENTO DE AJUSTES DE ESTOQUE </label>
                    <AppSwitch
                      v-model="
                        userOperationalPermission.release_of_stock_adjustments
                      "
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- FINANCEIRO -->
              <AppTabContent
                title="Financeiro"
                icon="duration-finance"
                value="financial"
                color="danger"
              >
                <AppTabList title="OPÇÕES">
                  <AppTabTrigger
                    title="liberação de crédito"
                    value="credit"
                    customTrigger
                  />
                  <AppTabTrigger
                    title="contas a pagar - limitação de crédito"
                    value="bills-pay"
                    customTrigger
                  />
                  <AppTabTrigger
                    title="contas a receber - limitação de crédito"
                    value="bills-received"
                    customTrigger
                  />
                  <AppTabTrigger
                    title="faturamento"
                    value="invoice"
                    customTrigger
                  />
                </AppTabList>
              </AppTabContent>
              <!-- LIBERAÇÃO DE CRÉDITO -->
              <AppTabContent
                title="LIBERAÇÃO DE CRÉDITO"
                icon="duration-finance"
                value="credit"
                color="danger"
              >
                <AppTabSection title="geral">
                  <div class="app-form-group">
                    <label> PESSOAL FÍSICA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userCreditPermission.physical_value"
                      :minValue="0"
                      prepend="R$"
                      @blur="
                        userCreditPermission.juridical_value =
                          userCreditPermission.physical_value
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> PESSOA JURÍDICA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userCreditPermission.juridical_value"
                      :minValue="0"
                      prepend="R$"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> PRAZO MÁXIMO</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userCreditPermission.days"
                      prepend="dias"
                      :minValue="0"
                      :maxValue="360"
                      :decimalPlaces="0"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- CONTAS A RECEBER -->
              <AppTabContent
                title="contas a receber - limitação de crédito"
                icon="duration-finance"
                value="bills-received"
                color="danger"
              >
                <!-- Geral -->
                <AppTabSection title="geral">
                  <div class="app-form-group">
                    <label> Ativo</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_general_values
                      "
                    />
                  </div>
                  <template
                    v-if="userFinancialLimitPermission.is_limit_general_values"
                  >
                    <div class="app-form-group">
                      <label> DESCONTOS</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userFinancialLimitPermission.general_discounts_fees
                        "
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                    <div class="app-form-group">
                      <label> TAXAS</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="userFinancialLimitPermission.fees"
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                    <div class="app-form-group">
                      <label> Inadimplência máxima</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userFinancialLimitPermission.general_maximum_default
                        "
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                  </template>
                </AppTabSection>
                <!-- ALTERAÇÃO DE VENCIMENTO -->
                <AppTabSection title="ALTERAÇÃO DE VENCIMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.disable_max_extension_due_date
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.disable_max_extension_due_date
                    "
                  >
                    <label> Prazo máximo de alteração</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.general_extension_due_date
                      "
                      prepend="dias"
                      :minValue="0"
                      :maxValue="360"
                      :decimalPlaces="0"
                    />
                  </div>
                </AppTabSection>
                <!-- APROVAÇÃO -->
                <AppTabSection title="APROVAÇÃO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_approval_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="userFinancialLimitPermission.is_limit_approval_values"
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userFinancialLimitPermission.approval_per_day"
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- RECEBER -->
                <AppTabSection title="RECEBER">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_receipt_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="userFinancialLimitPermission.is_limit_receipt_values"
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userFinancialLimitPermission.receivement_per_day"
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- CANCELAMENTO -->
                <AppTabSection title="CANCELAMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_cancellation_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.is_limit_cancellation_values
                    "
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.cancellation_per_day
                      "
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- ESTORNO DE RECEBÍVEIS -->
                <AppTabSection title="ESTORNO DE RECEBÍVEIS">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_reversal_of_receivable_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.is_limit_reversal_of_receivable_values
                    "
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.reversal_of_receivable_per_day
                      "
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- CONTAS A PAGAR -->
              <AppTabContent
                title="contas a pagar - limitação de crédito"
                icon="duration-finance"
                value="bills-pay"
                color="danger"
              >
                <!-- Geral -->
                <AppTabSection title="geral">
                  <div class="app-form-group">
                    <label> Ativo</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.bill_pay_is_limit_general_values
                      "
                    />
                  </div>
                  <template
                    v-if="
                      userFinancialLimitPermission.bill_pay_is_limit_general_values
                    "
                  >
                    <div class="app-form-group">
                      <label> DESCONTOS</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userFinancialLimitPermission.bill_pay_general_discounts_fees
                        "
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                    <div class="app-form-group">
                      <label> TAXAS</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="userFinancialLimitPermission.bill_pay_fees"
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                    <div class="app-form-group">
                      <label> Inadimplência máxima</label>
                      <AppInputNumber
                        class="app-tab-input"
                        v-model="
                          userFinancialLimitPermission.bill_pay_general_maximum_default
                        "
                        :minValue="0"
                        prepend="R$"
                      />
                    </div>
                  </template>
                </AppTabSection>
                <!-- ALTERAÇÃO DE VENCIMENTO -->
                <AppTabSection title="ALTERAÇÃO DE VENCIMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.bill_pay_is_limit_amounts_change_due_date
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.bill_pay_is_limit_amounts_change_due_date
                    "
                  >
                    <label> Prazo máximo de alteração</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.bill_pay_general_extension_due_date
                      "
                      prepend="dias"
                      :minValue="0"
                      :maxValue="360"
                      :decimalPlaces="0"
                    />
                  </div>
                </AppTabSection>
                <!-- APROVAÇÃO -->
                <AppTabSection title="APROVAÇÃO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.bill_pay_is_limit_approval_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.bill_pay_is_limit_approval_values
                    "
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.bill_pay_approval_per_day
                      "
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- PAGAMENTO -->
                <AppTabSection title="PAGAMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_receipt_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="userFinancialLimitPermission.is_limit_receipt_values"
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userFinancialLimitPermission.payment_per_day"
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- CANCELAMENTO -->
                <AppTabSection title="CANCELAMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.bill_pay_is_limit_cancellation_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.bill_pay_is_limit_cancellation_values
                    "
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.bill_pay_cancellation_per_day
                      "
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
                <!-- ESTORNO DE PAGAMENTO -->
                <AppTabSection title="ESTORNO DE PAGAMENTO">
                  <div class="app-form-group">
                    <label> PERMITIR</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.is_limit_payment_reversal_values
                      "
                    />
                  </div>
                  <div
                    class="app-form-group"
                    v-if="
                      userFinancialLimitPermission.is_limit_payment_reversal_values
                    "
                  >
                    <label> POR DIA</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="
                        userFinancialLimitPermission.payment_reversal_per_day
                      "
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- FATURAMENTO -->
              <AppTabContent
                title="FATURAMENTO"
                icon="duration-finance"
                value="invoice"
                color="danger"
              >
                <AppTabSection title="GERAL">
                  <div class="app-form-group">
                    <label> Alterar preço de concreto</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_change_concrete_price
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Alterar valor de serviço</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_change_service_value
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label>Alterar valor de adicionais</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_change_additional_value
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label>Aprovar avulso</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_add_single
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Estornar fatura</label>
                    <AppSwitch
                      v-model="userFinancialLimitPermission.invoice_can_reverse"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Cancelar nfs-e</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_cancel_nfse
                      "
                    />
                  </div>
                  <div class="app-form-group">
                    <label> Incluir desconto de reidi</label>
                    <AppSwitch
                      v-model="
                        userFinancialLimitPermission.invoice_can_include_reidi
                      "
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <!-- COMPRAS -->
              <AppTabContent
                title="COMPRAS"
                icon="shopping-cart"
                value="purchases"
                color="warning"
              >
                <!-- Geral -->
                <AppTabSection title="GERAL">
                  <div class="app-form-group">
                    <label> Visualização por categoria</label>
                    <PuzlSelect
                      v-model="userPurchasePermission.view_categories"
                      :items="[
                        { id: 0, description: 'Todos' },
                        ...purchase_categories,
                      ]"
                      label="description"
                      :multiple="true"
                      @input="setAllViewCategories"
                      :disableBoxShadow="true"
                      class="app-tab-input"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> PERMISSÃO POR CATEGORIA</label>
                    <PuzlSelect
                      v-model="userPurchasePermission.edit_categories"
                      :items="[
                        { id: 0, description: 'Todos' },
                        ...purchase_categories,
                      ]"
                      label="description"
                      :multiple="true"
                      @input="setAllEditCategories"
                      :disableBoxShadow="true"
                      class="app-tab-input"
                    />
                  </div>
                  <div class="app-form-group">
                    <label> EDIÇÃO DE CATEGORIA</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_category_edit"
                    >
                    </AppSwitch>
                  </div>
                </AppTabSection>
                <!-- REQUISIÇÃO -->
                <AppTabSection title="REQUISIÇÃO">
                  <div class="app-form-group">
                    <label> Realizar aprovação</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_request_approve"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> Visualização apenas de requisições proprias</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_request_view_own"
                    >
                    </AppSwitch>
                  </div>
                </AppTabSection>
                <!-- COTAÇÃO -->
                <AppTabSection title="COTAÇÃO">
                  <div class="app-form-group">
                    <label> Visualização apenas de requisições proprias</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_budget_view_own"
                    >
                    </AppSwitch>
                  </div>
                </AppTabSection>
                <!-- ORDEM DE COMPRA -->
                <AppTabSection title="ORDEM DE COMPRA">
                  <div class="app-form-group">
                    <label> APROVAR</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_order_approve"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> CANCELAR</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_order_cancel"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> CONCLUIR</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_order_complete"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> RETOMAR</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_order_resume"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> NEGAR</label>
                    <AppSwitch
                      v-model="userPurchasePermission.purchase_order_denied"
                    >
                    </AppSwitch>
                  </div>
                  <div class="app-form-group">
                    <label> LIMITAR VALOR DE APROVAÇÃO</label>
                    <AppSwitch
                      v-model="
                        userPurchasePermission.limit_order_approval_value
                      "
                    >
                    </AppSwitch>
                  </div>
                  <div
                    class="app-form-group"
                    v-if="userPurchasePermission.limit_order_approval_value"
                  >
                    <label> VALOR MÁXIMO</label>
                    <AppInputNumber
                      class="app-tab-input"
                      v-model="userPurchasePermission.max_approval_value"
                      prepend="R$"
                      :minValue="0"
                    />
                  </div>
                </AppTabSection>
              </AppTabContent>
              <div class="app-tab-footer">
                <AppButton
                  label="cancelar"
                  hasOutline
                  size="sm"
                  width="128px"
                  type="danger"
                  @click="closeModal"
                />
                <AppButton
                  label="salvar"
                  hasOutline
                  size="sm"
                  width="128px"
                  type="success"
                  :isLoading="isSaving"
                  :isDisabled="isSaving"
                  @click.prevent="handleSubmit(handleUpdate)"
                />
              </div>
            </template>
            <div
              class="h-100 d-flex justify-content-center align-items-stretch"
              v-else
            >
              <div class="d-inline-flex">
                <i
                  class="fas fa-circle-notch fa-spin m-auto"
                  style="color: #b0b0b0; font-size: 60px"
                />
              </div>
            </div>
          </form>
        </validation-observer>
      </AppTab>
    </modal>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import {
  UserPermissionType,
  initUserPermissionType,
} from "../types/UserPermissionType";
import {
  UserTechPermissionType,
  initUserTechPermissionType,
} from "../types/UserTechPermissionType";
import {
  UserCommercialPermissionType,
  initUserCommercialPermissionType,
} from "../types/UserCommercialPermissionType";
import {
  UserOperationalPermissionType,
  initUserOperationalPermissionType,
} from "../types/UserOperationalPermissionType";
import {
  UserCreditPermissionType,
  initUserCreditPermissionType,
} from "../types/UserCreditPermissionType";
import {
  UserFinancialLimitPermissionType,
  initUserFinancialLimitPermissionType,
} from "../types/UserFinancialLimitPermissionType";
import {
  UserPurchasePermissionType,
  initUserPurchasePermissionType,
} from "../types/UserPurchasePermissionType";

import { useStore } from "@/shared/hooks/useStore.js";
import { dialogs } from "../../../../helpers";
import { formatErrorValidation } from "@/plugins";

import {
  AppButton,
  AppIcon,
  AppTab,
  AppTabContent,
  AppTabSection,
  AppTabTrigger,
  AppTabList,
  AppSwitch,
  AppTabSectionConditional,
  AppInputNumber,
} from "../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";

//------------------------------//
// #region Emit
const emit = defineEmits(["storedAfterCenterAllocation"]);
// #endregion

//------------------------------//
// #region Data
const store = useStore();
const isOpen = ref(false);
const loadingStore = ref(true);
const isSaving = ref(false);
/** @type {import('vue').Ref<array>} */
const purchase_categories = ref([]);
/** @type {import('vue').Ref<number>} */
const loggedInUserId = ref(null);

/** @type {UserPermissionType} */
const user = reactive(initUserPermissionType());
/** @type {UserTechPermissionType} */
const userTechPermission = reactive(initUserTechPermissionType());
/** @type {UserCommercialPermissionType} */
const userCommercialPermission = reactive(initUserCommercialPermissionType());
/** @type {UserOperationalPermissionType} */
const userOperationalPermission = reactive(initUserOperationalPermissionType());
/** @type {UserCreditPermissionType} */
const userCreditPermission = reactive(initUserCreditPermissionType());
/** @type {UserFinancialLimitPermissionType} */
const userFinancialLimitPermission = reactive(
  initUserFinancialLimitPermissionType()
);
/** @type {UserPurchasePermissionType} */
const userPurchasePermission = reactive(initUserPurchasePermissionType());
//#endregion

//------------------------------//
// #region Computed
/** @type {import('vue').ComputedRef<UserPermissionType>} */
const $_user = computed(() => store.getters["user/show"]);
const $_sellers = computed(() => store.getters["user/getSellerWithAll"]);
const $_group_permissions = computed(
  () => store.getters["authGroupPermission/fetch"]
);
const $_plants = computed(() => store.getters["plant/getPlantWithAll"]);
const $_financial_limit = computed(
  () => store.getters["permissionFinancialLimit/show"]
);
const $_bank_accounts = computed(
  () => store.getters["bankAccount/fetchWithAll"]
);
const $_alert_groups = computed(() => store.getters["alertGroup/fetch"]);
//#endregion

//------------------------------//
//#region Methods
/**
 * @param {number} userId
 * @param {string} userUuid
 * @param {number} userIdLoggedIn - id usuário logado
 */
async function openModal(userId, userUuid, userIdLoggedIn) {
  loadingStore.value = true;
  isOpen.value = true;
  user.id = userId;
  user.uuid = userUuid;
  loggedInUserId.value = userIdLoggedIn;
  await fetchInitialData();
  setUserId();
  loadingStore.value = false;
}

function closeModal() {
  resetModalData();
  isOpen.value = false;
}

function setUserId() {
  userCommercialPermission.user_id = user.id;
  userCreditPermission.user_id = user.id;
  userFinancialLimitPermission.user_id = user.id;
  userOperationalPermission.user_id = user.id;
  userPurchasePermission.user_id = user.id;
  userTechPermission.user_id = user.id;
}

function resetModalData() {
  resetUserData();
  resetUserTechData();
  resetUserCommercialData();
  resetUserOperationalData();
  resetUserCreditData();
  resetUserFinancialLimitData();
  resetUserPurchaseData();
}

function resetUserData() {
  Object.assign(user, initUserPermissionType());
}

function resetUserTechData() {
  Object.assign(userTechPermission, initUserTechPermissionType());
}

function resetUserCommercialData() {
  Object.assign(userCommercialPermission, initUserCommercialPermissionType());
}

function resetUserOperationalData() {
  Object.assign(userOperationalPermission, initUserOperationalPermissionType());
}

function resetUserCreditData() {
  Object.assign(userCreditPermission, initUserCreditPermissionType());
}

function resetUserFinancialLimitData() {
  Object.assign(
    userFinancialLimitPermission,
    initUserFinancialLimitPermissionType()
  );
}

function resetUserPurchaseData() {
  Object.assign(userPurchasePermission, initUserPurchasePermissionType());
}

async function fetchInitialData() {
  await Promise.all([
    fetchUser(),
    fetchUserRoles(),
    fetchUserAlertGroup(),
    fetchAlertGroups(),
    fetchUserCredit(),
    fetchPlants(),
    fetchSellers(),
    fetchTechPermissions(),
    fetchCommercialPermissions(),
    fetchOperationalPermissions(),
    fetchFinancialLimitPermissions(),
    fetchPurchasePermissions(),
    fetchPurchaseCategories(),
    fetchUserPermissions(),
  ]);
}

async function fetchUserPermissions() {
  store.dispatch("authGroupPermission/fetchItems", 0);
}

async function fetchUser() {
  try {
    const response = await store.dispatch("user/show", user.uuid);

    if (!response?.error && response?.data) {
      setUserData();
      setPurchaseDataFromUserFetch();
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

function setUserData() {
  if ($_user.value?.role?.length) {
    user.role_id = $_user.value.role[0].id;
  }
  user.has_access_to_all_plants = $_user.value.has_access_to_all_plants;
  user.view_sellers = $_user.value.view_sellers;
  user.bank_account_ids = $_user.value.bank_account_ids;
  user.notify = $_user.value.notify;
}

function setPurchaseDataFromUserFetch() {
  userPurchasePermission.user_id = user.id;
  if (
    $_user.value.purchase_categories.length > 0 &&
    !userPurchasePermission.view_all_categories
  ) {
    userPurchasePermission.view_categories =
      $_user.value.purchase_categories.map((category) => category.id);
  }
  if (
    $_user.value.purchase_categories.length > 0 &&
    !userPurchasePermission.edit_all_categories
  ) {
    userPurchasePermission.edit_categories = $_user.value.purchase_categories
      .filter((category) => category.pivot.can_approve === 1)
      .map((category) => category.id);
  }
}

async function fetchAlertGroups() {
  await store.dispatch("alertGroup/getAllSimplified");
}

async function fetchTechPermissions() {
  try {
    const response = await store.dispatch(
      "technicalPermission/getByUserId",
      user.id
    );

    if (!response?.error && response?.data) {
      setUserTechPermissions(response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

async function fetchUserCredit() {
  try {
    const response = await store.dispatch("userCredit/getByUser", user.id);
    if (response.data) {
      Object.assign(userCreditPermission, response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

/**
 * @param {UserTechPermissionType} userTechData
 */
function setUserTechPermissions(userTechData) {
  Object.assign(userTechPermission, userTechData);
}

async function fetchCommercialPermissions() {
  try {
    const response = await store.dispatch(
      "commercialPermission/getByUserId",
      user.id
    );
    if (!response?.error && response?.data) {
      setUserCommercialPermissions(response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

/**
 * @param {UserCommercialPermissionType} userCommercialData
 */
function setUserCommercialPermissions(userCommercialData) {
  Object.assign(userCommercialPermission, userCommercialData);
}

function changeLimit() {
  if (!userCommercialPermission.minimum_price_approval) {
    userCommercialPermission.limit_variation = false;
  }
}

async function fetchOperationalPermissions() {
  try {
    const response = await store.dispatch(
      "operationalPermission/getByUserId",
      user.id
    );

    if (!response?.error && response?.data) {
      setUserOperationalPermissions(response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

/**
 * @param {UserOperationalPermissionType} userOperationalData
 */
function setUserOperationalPermissions(userOperationalData) {
  Object.assign(userOperationalPermission, userOperationalData);
}

async function fetchFinancialLimitPermissions() {
  try {
    const response = await store.dispatch(
      "permissionFinancialLimit/show",
      user.id
    );

    if (!response?.error && response?.data) {
      setFinancialLimitPermissions(response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

/**
 * @param {UserFinancialLimitPermissionType} userFinancialLimitData
 */
function setFinancialLimitPermissions(userFinancialLimitData) {
  Object.assign(userFinancialLimitPermission, userFinancialLimitData);
}

//#region CopiedCode
/** Codigo copiado do componente antigo PMC-1914 */
async function fetchPurchaseCategories() {
  return store
    .dispatch("purchaseCategory/fetchItemsPaginate", {
      order_by: [{ column: "purchase_categories.id" }],
    })
    .then((response) => {
      purchase_categories.value = response.data.items.filter(
        (item) => item.status === true
      );
    });
}

/** Codigo copiado do componente antigo PMC-1914 */
async function fetchPurchasePermissions() {
  try {
    const response = await store.dispatch(
      "user/getPurchasePermission",
      user.id
    );

    if (!response?.error && response?.data) {
      setPurchasePermissions(response.data);
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

/** Codigo copiado do componente antigo PMC-1914 */
/**
 * @param {UserPurchasePermissionType} userPurchaseData
 */
function setPurchasePermissions(userPurchaseData) {
  Object.assign(userPurchasePermission, userPurchaseData);
  userPurchasePermission.order_permission = getSelectedIds();
}

/** Codigo copiado do componente antigo PMC-1914 */
function getSelectedIds() {
  const mapping = {
    1: "purchase_order_approve",
    2: "purchase_order_cancel",
    3: "purchase_order_complete",
    4: "purchase_order_resume",
    5: "purchase_order_denied",
  };

  const selectedIds = [];

  for (const key in mapping) {
    if (userPurchasePermission[mapping[key]] === true) {
      selectedIds.push(parseInt(key));
    }
  }

  return selectedIds;
}

function setAllViewCategories() {
  const index = userPurchasePermission.view_categories.findIndex(
    (item) => item == 0
  );
  if (userPurchasePermission.view_categories.length > 1 && index === 0) {
    userPurchasePermission.view_categories.splice(0, 1);
  }
  if (
    userPurchasePermission.view_categories.length > 1 &&
    userPurchasePermission.view_categories.includes(0)
  ) {
    userPurchasePermission.view_categories = [];
    userPurchasePermission.view_categories.push(0);
  }
}

function setAllEditCategories() {
  const index = userPurchasePermission.edit_categories.findIndex(
    (item) => item == 0
  );
  if (userPurchasePermission.edit_categories.length > 1 && index === 0) {
    userPurchasePermission.edit_categories.splice(0, 1);
  }
  if (
    userPurchasePermission.edit_categories.length > 1 &&
    userPurchasePermission.edit_categories.includes(0)
  ) {
    userPurchasePermission.edit_categories = [];
    userPurchasePermission.edit_categories.push(0);
  }
}
//#endregion CopiedCode

async function fetchPlants() {
  try {
    const response = await store.dispatch("userPlant/show", user.uuid);
    const { plants, has_access_to_all_plants } = response.data;

    let plantUuids = [];

    if (plants?.[0] === "all" || has_access_to_all_plants === 1) {
      plantUuids = plants;
    } else if (Array.isArray(plants)) {
      plantUuids = plants.filter((item) => item.uuid).map((item) => item.uuid);
    }

    user.company_plant_uuids = plantUuids;
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

function setAllPlants() {
  const { company_plant_uuids } = user;
  const hasAll = company_plant_uuids.includes("all");
  const isFirstItemAll = company_plant_uuids[0] === "all";

  //Limpar opcao "Todos"
  if (isFirstItemAll && company_plant_uuids.length > 1) {
    company_plant_uuids.shift();
    user.has_access_to_all_plants = 0;
  }

  // Quando "Todos" for escolhido limpar o array, e setar valor "all"
  if (hasAll && company_plant_uuids.length > 1) {
    user.company_plant_uuids = ["all"];
    user.has_access_to_all_plants = 1;
  }
}

async function fetchUserRoles() {
  try {
    await store.dispatch("userRole/show", user.id);
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

async function fetchUserAlertGroup() {
  try {
    const { data } = await store.dispatch(
      "alertGroup/showUserAlertGroup",
      user.id
    );
    if (data) {
      user.alert_group_id = data;
    }
  } catch (error) {
    dialogs.notifyError({ type: "danger", message: error.message });
  }
}

async function fetchSellers() {
  try {
    await store.dispatch("user/fetchItemsFilterable", {
      filter: {
        is_seller: 1,
      },
    });
  } catch (e) {
    dialogs.notifyError({ type: "danger", message: e.message });
  }
}

function setAllSellers() {
  const { sellers } = user;
  const hasAll = sellers.includes("all");
  const isFirstItemAll = sellers[0] === "all";

  //Limpar opcao "Todos"
  if (isFirstItemAll && sellers.length > 1) {
    sellers.shift();
  }

  // Quando "Todos" for escolhido limpar o array, e setar valor "all"
  if (hasAll && sellers.length > 1) {
    user.sellers = ["all"];
  }
}

function setAllBankAccounts() {
  const { bank_account_ids } = user;
  const hasAll = bank_account_ids.includes("all");
  const isFirstItemAll = bank_account_ids[0] === "all";

  //Limpar opcao "Todos"
  if (isFirstItemAll && bank_account_ids.length > 1) {
    bank_account_ids.shift();
  }

  // Quando "Todos" for escolhido limpar o array, e setar valor "all"
  if (hasAll && bank_account_ids.length > 1) {
    user.bank_account_ids = ["all"];
  }
}

async function updatePurchasePermission() {
  userPurchasePermission.view_all_categories =
    userPurchasePermission.view_categories.includes(0);
  userPurchasePermission.edit_all_categories =
    userPurchasePermission.edit_categories.includes(0);

  const formatedPurchaseCategories = transformCategories(
    userPurchasePermission
  );

  Object.assign(userPurchasePermission.categories, formatedPurchaseCategories);

  try {
    return await store.dispatch(
      "user/updatePurchasePermissions",
      userPurchasePermission
    );
  } catch {
    dialogs.notify({
      type: "danger",
      message: "Erro ao salvar configuraçoes de compras!",
    });
  }
}

/**
 * @param {UserPurchasePermissionType} input
 */
function transformCategories(input) {
  const result = { categories: [] };
  input.view_categories.forEach((category) => {
    if (category) {
      const canApprove = input.edit_categories.includes(category);
      result.categories.push({
        purchase_category_id: category,
        can_approve: canApprove,
      });
    }
  });
  input.edit_categories.forEach((category) => {
    if (!input.view_categories.includes(category)) {
      if (category) {
        result.categories.push({
          purchase_category_id: category,
          can_approve: true,
        });
      }
    }
  });
  return result.categories;
}

//formatar campos null para 0 para o backend
function formatUserFinancialLimits() {
  Object.keys(userFinancialLimitPermission).forEach((key) => {
    if (
      userFinancialLimitPermission[key] === null &&
      key !== "updated_by_user_id"
    ) {
      userFinancialLimitPermission[key] = 0;
    }
  });
}

//Tratando caso de novos usuarios pra nao dar erro 422
function formatUserCredit() {
  Object.keys(userCreditPermission).forEach((key) => {
    if (
      userCreditPermission[key] === null &&
      (key === "juridical_value" || key === "physical_value")
    ) {
      userCreditPermission[key] = 0;
    }
  });
}

async function createOrUpdateFinancialLimits() {
  formatUserFinancialLimits();
  formatUserCredit();
  if ($_financial_limit.value.user_id) {
    await updateFinancialLimits();
  } else {
    await storeFinancialLimits();
  }

  // Store userCredit
  return await store.dispatch("userCredit/add", userCreditPermission);
}

async function storeFinancialLimits() {
  try {
    return store.dispatch(
      "permissionFinancialLimit/add",
      userFinancialLimitPermission
    );
  } catch (error) {
    if (error.status === 200) {
      dialogs.notify({ type: "danger", message: error.data.message });
    } else if (error.response.status === 422) {
      let errors = formatErrorValidation(error.response.data.errors);
      dialogs.notify({ type: "danger", message: errors });
    }
  }
}

async function updateFinancialLimits() {
  try {
    await store.dispatch(
      "permissionFinancialLimit/update",
      userFinancialLimitPermission
    );
  } catch (error) {
    if (error.status === 200) {
      dialogs.notify({ type: "danger", message: error.data.message });
    } else if (error.response.status === 422) {
      let errors = formatErrorValidation(error.response.data.errors);
      dialogs.notify({ type: "danger", message: errors });
    }
  }
}

async function updateUserRole() {
  if (user.role_id) {
    return await store.dispatch("userRole/add", {
      role_id: user.role_id,
      user_id: user.id,
    });
  }
}

async function handleUpdate() {
  isSaving.value = true;

  //Central
  if (
    user.company_plant_uuids.includes("all") ||
    user.company_plant_uuids.length === 0
  ) {
    user.company_plant_uuids = [];
    user.has_access_to_all_plants = 1;
  }

  Promise.allSettled([
    updatePurchasePermission(),
    createOrUpdateFinancialLimits(),
    updateUserRole(),
    store.dispatch("userPlant/add", {
      user_uuid: user.uuid,
      company_plant_uuids: user.company_plant_uuids,
      has_access_to_all_plants: user.has_access_to_all_plants,
    }),
    store.dispatch("user/setViewSellers", {
      users: user.view_sellers,
      user_id: user.id,
    }),
    store.dispatch("user/setBankAccount", {
      user_id: user.id,
      bank_account_ids: user.bank_account_ids,
    }),
    store.dispatch(
      "commercialPermission/createOrUpdate",
      userCommercialPermission
    ),
    store.dispatch(
      "operationalPermission/createOrUpdate",
      userOperationalPermission
    ),
    store.dispatch("technicalPermission/store", userTechPermission),
    store.dispatch("user/setNotify", {
      user_id: user.id,
      notify: user.notify,
    }),
    store.dispatch("alertGroup/storeUserAlertGroup", {
      user_id: user.id,
      alert_group_id: user.alert_group_id,
    }),
    store.dispatch("permissionChange/store", {
      user_id: user.id,
      user_id_logged_in: loggedInUserId.value,
    }),
  ])
    .then((response) => {
      //Verificando se alguma promisse deu erro
      const error = response.find((promise) => promise.status === "rejected");
      if (error) {
        dialogs.notifyError({
          type: "danger",
          message: "Erro ao salvar permissões",
        });
      } else {
        dialogs.notify("success", "Permissões do usuário salvas com sucesso");
      }
      // response[3].data é o resultado da ação dispatch "userPlant/add"
      // response[11].data é o resultado da ação dispatch "permissionChange/store"
      emit(
        "storedAfterCenterAllocation",

        response[3].value.data,
        response[11].value.data
      );
      closeModal();
    })
    .catch((error) => {
      let errors = error.status
        ? error.data.message
        : formatErrorValidation(error.response.data.errors);
      dialogs.notify({ type: "danger", message: errors });
    })
    .finally(() => {
      isSaving.value = false;
    });
}
//#endregion

//------------------------------//
//#region DefineExposes
/**
 * @typedef {Object} StockLocationCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion

//------------------------------//
//#region lifeCycleHooks
onMounted(async () => {});
//#endregion
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";
.warning-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  font-family: Fredoka;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $dark;
  background-color: $danger-lighter;
  padding: 8px 12px;
  border-radius: 8px;
  @include media-query(down, lg) {
    font-size: 12px;
  }
}

.custom-fields {
  label {
    padding-right: 16px;
  }

  label + .app-input-number {
    @include media-query(up, lg) {
      margin-right: 16px;
    }
  }
  .app-input-number {
    flex: 1;
    max-width: 132px !important;
    width: 132px !important;
    @include media-query(down, lg) {
      width: 100% !important;
      max-width: 100% !important;
      min-width: 100%;
    }
  }
  .app-input-number + .app-input-number {
    margin-top: 4px;
  }
}
</style>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";
.user-permission .user-permission-modal {
  overflow: auto;
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $primary-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 0 !important;
    }

    h5.modal-title {
      margin-left: 12px;
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  .modal-body {
    padding: 0 !important;
    margin: 0 !important;
    flex-direction: column;

    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }
    }
  }
}
</style>
