<template>
  <div class="card py-4 px-5 mx-1" style="background: rgba(43, 45, 50, 0.05);">
    <div :class="indexInstallment > 0 ? 'mt-3' : ''" v-for="(installment, indexInstallment) in list" :key="indexInstallment">
      <div class="row">
        <label class="col-md-6 col-form-label form-control-label d-flex align-items-center mt-1 mb-0">
            <i style="font-size: 5px;" class="fa fa-circle text-primary mr-3"></i>
          Parcela {{ installment.index }}
        </label>
        <!-- Vencimento  -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Vencimento</label>
          <input-date-time-picker @input="changedDueDate(installment)" :mode="'date'" v-model="installment.due_date" />
        </div>
        <!-- Vencimento original  -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Vencimento original </label>
          <input-date-time-picker :mode="'date'" v-model="installment.original_due_date" />
        </div>
      </div>

      <div class="row mt-2">
        <!-- Valor bruto  -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Valor bruto</label>
          <base-input input-group-classes="input-group-sm">
            <input
              :disabled="installment.index === items.length"
              @blur="changedInstallmentTotalValue($event.target.value, indexInstallment, items, entry_launch)"
              v-money="money"
              maxlength="10"
              inputmode="numeric"
              v-model.lazy="installment.total_value"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0"> R$ </small>
            </template>
          </base-input>
        </div>
        <!-- Descontos/Taxas  -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Descontos/Taxas </label>
          <base-input input-group-classes="input-group-sm">
            <input
              @keydown="$event.key === '-' ? $event.preventDefault() : null"
              @blur="$emit('getFinalValue', false)"
              inputmode="numeric"
              v-money="money"
              maxlength="10"
              v-model.lazy="installment.discounts_fees"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0"> R$ </small>
            </template>
          </base-input>
        </div>
        <!-- Juros/Multas -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Juros/Multas </label>
          <base-input input-group-classes="input-group-sm">
            <input
              @keydown="$event.key === '-' ? $event.preventDefault() : null"
              @blur="$emit('getFinalValue', false)"
              maxlength="10"
              v-money="money"
              v-model.lazy="installment.fees_fines"
              class="form-control form-control-sm"
            />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0"> R$ </small>
            </template>
          </base-input>
        </div>
        <!-- Valor final -->
        <div class="col-md-3">
          <label class="col-form-label form-control-label"> Valor final </label>
          <base-input prependClass="bg-primary" input-group-classes="input-group-sm bg-primary text-white">
            <input  disabled v-money="money" maxlength="10" v-model.lazy="installment.final_value" class="form-control form-control-sm bg-primary text-white" />
            <template slot="prepend">
              <small class="input-group-sm p-0 m-0 bg-primary text-white"> R$ </small>
            </template>
          </base-input>
        </div>
      </div>
      <hr class="m-0 col-12 pr-0 pl-0 mr-2 mt-4 text-primary bg-primary" />
    </div>
    <pagination v-show="items && items.length > 12" @navegate="navegate" :source="source" />
    <ModalSetDueDateInterval @store="$emit('applyDueDate', $event)" ref="modalSetDueDateInterval" />
  </div>
</template>

<script>
import billPayMixin from "@/views/Modules/Financial/BillPay/Mixins/bill_pay_mixin";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import ModalSetDueDateInterval from "./_ModalSetDueDateInterval";
import Pagination from "@/components/Utils/Pagination";
import { VMoney } from "v-money";
import {mapGetters} from "vuex";

export default {
  name: "Installments",
  components: { InputDateTimePicker, Pagination, ModalSetDueDateInterval },
  mixins: [billPayMixin, entryLaunchMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
      description: "Parcelas",
    },
    entry_launch: {
      type: Object,
      default: () => {},
      description: "Despesa",
    },
    must_copy_due_date: {
      type: Boolean,
      default: false,
      description: "Define se a data de vencimento deve ser copiada para a data original de vencimento das parcelas",
    },
  },
  computed: {
    list() {
      const from = this.source.meta.current_page > 1 ? (this.source.meta.current_page - 1) * 12 : 0;
      return this.items.slice(from, this.source.meta.current_page * 12);
    },
    ...mapGetters({
      $_general_settings: "generalSetting/show",
    }),
  },
  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      source: {
        has_more: true,
        meta: {
          current_page: 1,
          last_page: 1,
          to: 12,
          from: 1,
        },
      },
    };
  },
  directives: { money: VMoney },
  methods: {
    /**
     * Navegação entre as páginas
     *
     * @param {integer} page
     */
    navegate(page) {
      const from = page > 1 ? (page - 1) * 12 : 0;
      this.source.meta.current_page = page;
      this.source.meta.from = page > 1 ? from : 1;
      this.source.meta.to = this.list.length + this.source.meta.from;
      this.source.has_more = this.items.slice(from + 12, (page + 1) * 12).length > 0;
      this.source.meta.last_page = Math.ceil(this.items.length / 12);
    },
    /**
     * Reseta a paginação das parcelas
     */
    resetPage() {
      this.source.meta = {
        current_page: 1,
        last_page: Math.ceil(this.items.length / 12),
        to: 12,
        from: 1,
      };
      this.source.has_more = true;
    },
    changedDueDate(installment) {
      if (this.must_copy_due_date && installment.due_date) {
        installment.original_due_date = installment.due_date + " 00:00:00";
      }
      /* Se for a primeira parcela e existirem parcelas posteriores */
      installment.index === 1 && this.items.length > 1 && this.handleModalSetDueDateInterval();
    },

    handleModalSetDueDateInterval() {
      this.$refs.modalSetDueDateInterval.openModal(this.items[0].due_date);
    },
  },
  async mounted() {
    await this.$store.dispatch('generalSetting/show')
  }
};
</script>
