<template>
  <div>
    <modal size="md" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-3">{{ title }}</h5>
      </template>
      <div class="container">
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <div class="col-md-12">
            <label for="date-of-issue">
              <input 
                @click="
                  filterType.date_of_issue = 1, 
                  filterType.entry_stock_date = 0, 
                  filterType.release_date_of = 0
                "
                class="form-check-input" 
                type="radio" 
                id="date-of-issue" 
                name="flexRadioDefault" 
                checked
              >
              <b>Data de emissão</b>
            </label>
            <InputDatePicker
              ref="datePicker1"
              @handleFilterDate="handleFilterDate"
              :filterable="4"
              :default-range="{start: filter.range.start, end: filter.range.end}"
            />
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <div class="col-md-12">
            <label for="entry-stock-date">
              <input
                @click="
                  filterType.entry_stock_date = 1, 
                  filterType.date_of_issue = 0, 
                  filterType.release_date_of = 0
                "
                class="form-check-input" 
                type="radio" 
                id="entry-stock-date" 
                name="flexRadioDefault"
              >
              <b>Data de entrada/estoque</b>
            </label>
            <InputDatePicker
              ref="datePicker2"
              @handleFilterDate="handleFilterDate2"
              :filterable="4"
              :default-range="{start: filter.range.start, end: filter.range.end}"
            />
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-4">
          <div class="col-md-12">
            <label for="release-date-of">
              <input
                @click="
                  filterType.release_date_of  = 1, 
                  filterType.entry_stock_date = 0, 
                  filterType.date_of_issue = 0
                "
                class="form-check-input"
                type="radio"
                id="release-date-of"
                name="flexRadioDefault"
              >
              <b>Data de lançamento</b>
            </label>
            <InputDatePicker
              ref="datePicker3"
              @handleFilterDate="handleFilterDate3"
              :filterable="4"
              :default-range="{start: filter.range.start, end: filter.range.end}"
            />
          </div>
        </div>

        <!-- Data de vencimento -->
        <div v-if="!isGroupedByDocument" class="form-group row m-0 p-0 mb-1 mt-4">
          <div class="col-md-12">
            <label for="release-date-of">
              <input
                @click="setBillPayDueDateFilterType"
                class="form-check-input"
                type="radio"
                id="release-date-of"
                name="flexRadioDefault"
              >
              <b>Data de vencimento</b>
            </label>
            <InputDatePicker
              ref="datePicker4"
              @handleFilterDate="handleFilterDate4"
              :filterable="4"
              :default-range="{start: filter.range.start, end: filter.range.end}"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="col-md-12 form-control-label mt-1"> Central </label>
          <div class="col-md-12">
            <PuzlSelect
              v-model="selectedCompanyPlants"
              :items="[{id: 'all', name: 'Todos'}, ...$_plants]" 
              customKey="id"  
              :multiple="true"
              :loading="isLoadingCompanyPlantList"
              :disabled="isLoadingCompanyPlantList"
              @input="setAll"
            />
          </div>
        </div>

        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="col-md-12 form-control-label mt-1"> Plano de contas </label>
          <div class="col-md-12">
            <PuzlSelect
              @input="applyToAllItems($event , 'chart_account_id')"
              placeholder="Plano de Contas"
              v-model="filter.chart_account_id"
              :items="$_chart_accounts"
              :clearable="false"
              :labelMask="true"
              label="$code$ $name$"
              :selectable="(i) => i.extra_field.length"
              extraField="parents"
              :loading="loadingChartAccount"
              :disabled="loadingChartAccount" />
          </div>
        </div>
        <div class="row d-flex align-items-center mb-1 mx-0 mt-3">
          <label class="col-md-12 form-control-label mt-1"> Fornecedor </label>
          <div class="col-md-12">
            <PuzlSelectSearch
                @search="handleFilterEntity"
                :loading="loadingEntity"
                v-model="filter.entity_ids"
                clearable
                multiple
                :items="entities"
            >
            </PuzlSelectSearch>
          </div>
        </div>
        <div class="form-group row m-0 p-0 mb-1 mt-3">
          <label class="col-6 form-control-label mt-1"> Separar por rateio </label>
          <div class="col-6 justify-content-end d-flex">
            <i
              role="button"
              v-if="is_grouped_by_equipment_apportionment"
              @click="is_grouped_by_equipment_apportionment = false"
              class="fas fa-check-square fa-2x text-success"
            />
            <i v-else @click="is_grouped_by_equipment_apportionment = true" role="button" class="fas fa-square fa-2x" />    
          </div>
        </div>
      </div>
      
      <div class="col-md-12 mt-2 text-right p-0 mt-4">
        <base-button
          @click.prevent="close"
          type="secondary"
          class="text-capitalize"
        >
          Cancelar
        </base-button>
        <base-button
          @click.prevent="submitAndClose"
          type="success"
          native-type="submit"
          :loading="isLoading"
        >
          <span class="btn-label"><i class="fa-solid fa-file-excel"></i></span>
          Download
        </base-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import moment from 'moment';
import entryLaunchMixin from "@/views/Modules/Configuration/EntryLaunch/Mixins/entry_launch_mixin";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import {debounce} from "@/plugins";

export default {
  name: "ModalEntryLaunchExcelReport",
  mixins: [entryLaunchMixin],
  components: {
    PuzlSelectSearch,
    InputDatePicker,
    PuzlSelect,
  },
  data() {
    return {
      title: "Gerar Relatório de Despesas (Excel)",
      modal: false,
      filter: {
        range: null,
        company_plants: [],
        chart_account_id: null,
        filter_type: {
          date_of_issue: null, // data de emissão
          entry_stock_date: null, // data de entrada/estoque
          release_date_of: null, // data de lançamento
          bill_pay_due_date: null // data de vencimento contas a pagar
        },
        dateFilterSelected: null,
        entity_ids: [],
      },
      range: {
        start: null,
        end: null,
      },
      defaultRange: {
        start: new Date( new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date( new Date().getFullYear(), new Date().getMonth() + 1, 0),
      },
      selectedCompanyPlants: [],
      isLoading: false,
      isLoadingCompanyPlantList: true,
      filterType: {
        'date_of_issue' : 1,
        'entry_stock_date': 0,
        'release_date_of': 0,
        'bill_pay_due_date': 0,
      },
      isGroupedByDocument: false,
      loadingChartAccount: true,
      is_grouped_by_equipment_apportionment: false,
      entities: [],
      loadingEntity:false,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
      $_chart_accounts: "chartAccount/fetch",
    }),
  },
  methods: {
    /**
     * @param {bool} isGroupedByDocument - Relatório de entradas agrupado por documento do emissor da central
     */
    openModal(isGroupedByDocument) {
      this.loadData();
      this.modal = true;
      this.entities = [];
      this.filter.range = this.defaultRange;
      this.is_grouped_by_equipment_apportionment = false;
      this.loadingEntity = false;
      this.filterType = {
        'date_of_issue': 1,
        'entry_stock_date': 0,
        'release_date_of': 0,
        'bill_pay_due_date': 0,
      };
      this.selectedCompanyPlants = [];
      this.filter.entity_ids = [];
      this.isGroupedByDocument = isGroupedByDocument;
    },
    close() {
      this.modal = false;
    },
    async submitAndClose() {
      // Filtro de dados
      this.filter.company_plants = !this.selectedCompanyPlants.includes("all") 
        ? this.selectedCompanyPlants 
        : [];

      // Para relatório de entradas.
      this.filter.group_by_document = this.isGroupedByDocument ? 1 : 0;
      
      // Envia como true apenas o filtro de data selecionado.
      this.filter.filter_type.date_of_issue = 0;
      this.filter.filter_type.entry_stock_date = 0;
      this.filter.filter_type.release_date_of = 0;
      this.filter.filter_type.bill_pay_due_date = 0;
      let selectedKey = Object.keys(this.filterType).find(key => this.filterType[key] === 1);
      this.filter.filter_type[selectedKey] = this.filterType[selectedKey];
      // Se tipo de filtro for vencimento de contas a pagar considerar agrupamento por rateio de equipamentos
      if(this.filter.filter_type.bill_pay_due_date) {
        this.is_grouped_by_equipment_apportionment = true;
      }
      
      if(this.is_grouped_by_equipment_apportionment) {
        this.filter.filter_type.is_grouped_by_equipment_apportionment = this.is_grouped_by_equipment_apportionment;
      } else {
        delete this.filter.filter_type.is_grouped_by_equipment_apportionment;
      }

      // Baixar Relatório em Excel
      let loader = this.$loading.show();
      this.isLoading = true;
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      this.$store
        .dispatch("entryLaunch/getEntryLaunchExcelReport", { filter: this.filter })
        .then(async (response) => {
          if (response instanceof Blob) {
            let blob = new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute("download", "Relatório de despesas.xlsx");
            await link.click();
            this.close();
            this.$notify({
              type: "success",
              message: "Solicitação realizada com sucesso!",
            });
          }
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.error,
          });
        }).finally(() => {
          loader.hide();
          this.isLoading = false;
        });
    },
    handleFilterDate(filter) {
      this.filter.range = filter;
      this.filter.dateFilterSelected = 'datePicker1'
    },
    handleFilterDate2(filter) {
      this.filter.range = filter;
      this.filter.dateFilterSelected = 'datePicker4';
    },
    handleFilterDate3(filter) {
      this.filter.range = filter;
      this.filter.dateFilterSelected = 'datePicker3'
    },
    setAll() {
      const index = this.selectedCompanyPlants.findIndex((item) => item == 'all');
      if (this.selectedCompanyPlants.length > 1 && index === 0) {
        return this.selectedCompanyPlants.splice(0, 1)
      }
      if (this.selectedCompanyPlants.length > 1 && this.selectedCompanyPlants.includes("all")) {
        this.selectedCompanyPlants = []
        this.selectedCompanyPlants.push("all")
      }
    },
    handleFilterDate4(filter) {
      this.filter.range = filter;
      this.filter.dateFilterSelected = 'datePicker4'
    },

    setBillPayDueDateFilterType() {
      this.filterType = {
        release_date_of  : 0, 
        entry_stock_date : 0, 
        date_of_issue :0,
        bill_pay_due_date : 1
      };
    },

    loadData() {
      this.isLoadingCompanyPlantList = true;
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.isLoadingCompanyPlantList = false;
      });
      this.loadingChartAccount = true;
      this.$store.dispatch("chartAccount/fetchItems").then(() => {
        this.loadingChartAccount = false;
      });
    },

    handleFilterEntity: debounce(function (e) {
      if (!e) return;
      this.loadingEntity = true;
      this.$store
          .dispatch("entity/getSearch", {
            search: e,
          })
          .then((response) => {
            let merged = [...response.data, ...this.entities];
            merged = [...new Map(merged.map((item) => [item["id"], item])).values()];
            this.$set(this, "entities", merged);
            this.loadingEntity = false;
          });
    }, 300),
  },
};
</script>

<style></style>
