<template>
  <div class="contract-proposal-guarantor-entity-modal">
    <modal :show.sync="modal.create" class="guarantor-entity-modal">
      <template slot="header">
        <AppIcon
          icon="insurance-agent"
          color="danger"
          width="24px"
          class="mr-3"
        />
        <h5 class="modal-title">Fiador</h5>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
          class="close"
        />
      </template>
      <!-- Contrato -->
      <div class="modal-body-title">
        <AppIcon icon="info-squared" color="warning" width="22" />
        <h5>Contrato</h5>
      </div>
      <div class="content-card">
        <div class="app-card-title">{{ contractProposal.code }}</div>
        <div class="app-card-item mb-1">
          {{ contractProposal.entity.entity_name }}
        </div>
        <div class="app-card-item">
          {{ contractProposal.construction.construction_name }}
        </div>
      </div>
      <div class="modal-body-title">
        <AppIcon icon="insurance-agent" color="danger" width="24px" />
        <h5>Fiador</h5>
      </div>
      <div class="content-card">
        <div class="app-card-item mb-2">
          {{ contractProposal.guarantorEntity.entity_name }}
        </div>
        <div class="app-card-item">
          {{ contractProposal.guarantorEntity.document }}
        </div>
      </div>
      <div class="app-modal-footer">
        <button class="app-button danger" @click="deleteGuarantorEntity">
          Excluir
        </button>
        <button class="app-button warning" @click="selectGuarantorEntity">
          Editar
        </button>
      </div>
    </modal>
    <ModalSearchEntity
      @selected="updateGuarantorEntity"
      ref="modalSearchEntity"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import { AppButton, AppIcon } from "../../../../../components/AppGlobal";

export default {
  name: "ModalGuarantorEntity",
  components: { ModalSearchEntity, AppIcon, AppButton },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  data() {
    return {
      modal: {
        title: "Fiador",
        create: false,
      },
      contractProposal: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} contractProposal
     */
    openModal(contractProposal) {
      this.modal.create = true;
      this.contractProposal = contractProposal;
    },
    selectGuarantorEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    /**
     * @param {object} entity
     */
    updateGuarantorEntity(entity) {
      this.$Progress.start();
      this.$store
        .dispatch("contractProposal/storeGuarantorEntity", {
          id: this.contractProposal.id,
          guarantor_entity_id: entity.id,
          guarantor_entity_created_by_user_id: this.$_user.id,
        })
        .then((response) => {
          this.contractProposal.guarantorEntity.entity_name =
            response.data.guarantorEntity.entity_name;
          this.contractProposal.guarantorEntity.document =
            response.data.guarantorEntity.document;
          this.contractProposal.userGuarantorEntityCreated.name =
            response.data.userGuarantorEntityCreated.name;
          this.contractProposal.guarantor_entity_created_at =
            response.data.guarantor_entity_created_at;
          this.contractProposal.guarantorEntity.address.address =
            response.data.guarantorEntity.address.address;
          this.contractProposal.guarantorEntity.address.number =
            response.data.guarantorEntity.address.number;
          this.contractProposal.guarantorEntity.address.district =
            response.data.guarantorEntity.address.district;
          this.contractProposal.guarantorEntity.address.postal_code =
            response.data.guarantorEntity.address.postal_code;
          this.contractProposal.guarantorEntity.address.city =
            response.data.guarantorEntity.address.city;
          this.contractProposal.guarantorEntity.address.state =
            response.data.guarantorEntity.address.state;
          this.$emit("contractProposalUpdated", response.data);
          this.$notify({
            type: "success",
            message: "Fiador atualizado com sucesso!",
          });
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    deleteGuarantorEntity() {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store
            .dispatch(
              "contractProposal/deleteGuarantorEntityContractProposalById",
              this.contractProposal.id
            )
            .then(() => {
              this.$emit("deletedGuarantorEntity", this.contractProposal.id);
              this.closeModal();
              this.$notify({
                type: "success",
                message: "Fiador excluído com sucesso!",
              });
            })
            .finally(() => {
              this.$Progress.finish();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";
.contract-proposal-guarantor-entity-modal .guarantor-entity-modal {
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $danger-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 12px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }

  .modal-body {
    padding: 24px !important;
    margin: 0 !important;
    flex-direction: column;

    .modal-body-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin-bottom: 8px;

      h5 {
        margin-bottom: 0;
        color: $dark;
        @include rhythm(font-size, 18);
        font-weight: 500;
      }
    }
  }

  .app-modal-footer {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: end;
    @media screen and (max-width: 767.98px) {
      justify-content: space-between;
    }

    button.app-button {
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      padding: 8px 20px;
      background-color: white;
      border: 1px solid $muted-medium;
      border-radius: 4px;
      min-width: 128px;
      min-height: 30px !important;
      @include rhythm(font-size, 14);
      line-height: 14px;
      font-family: Fredoka;
      font-weight: 500 !important;
      transition: all ease-in-out 0.2s;
      cursor: pointer;
      @media screen and (max-width: 767.98px) {
        flex: 0 0 48%;
        max-width: 50%;
      }

      &:hover {
        .app-icon {
          filter: brightness(0) invert(1);
        }
        img {
          filter: brightness(0) invert(1);
        }
      }

      &:disabled {
        opacity: 0.5;

        &:hover {
          pointer-events: none;
          cursor: not-allowed;
        }
      }

      &.danger {
        color: $danger;

        &:hover {
          background-color: $danger;
          border-color: $danger;
          color: white;
        }
      }

      &.warning {
        color: $warning;

        &:hover {
          background-color: $warning;
          border-color: $warning;
          color: white;
        }
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.content-card {
  width: 100%;
  background-color: white;
  border: 1px solid $muted-lighter;
  border-radius: 8px;
  padding: 1rem 12px;
  margin-bottom: 24px;
}

.app-card-title {
  font-family: Fredoka;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500 !important;
  margin-bottom: 12px;
}
.app-card-item {
  font-family: Fredoka;
  font-size: 14px;
  line-height: 14px;
}
</style>
