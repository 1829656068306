<template>
  <div>
    <modal headerClasses="py-2" :style="'padding: 1.25rem;'" :backgroundColor="'rgb(253, 245, 228)'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center h-100">
          <AppIcon icon="thumbs-up-down" color="warning" width="22" class="mr-3" />
          <h5 class="text-title mb-0 text-warning">Revisar lançamento</h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton icon="close" type="link" width="auto" size="sm" :hasOutline="true" @click.prevent="closeModal" />
      </template>
      <div class="mb-2 px-2 mx-1">
        <section>
          <h4 class="new-default-black-font mb-2">Observação</h4>
          <div class="w-100">
            <base-input input-group-classes="input-group-sm">
              <textarea
                v-model="data.observations"
                type="text"
                rows="4"
                class="form-control form-control-sm white-bg-021020241711"
                placeholder=" ..."
              >
              </textarea>
            </base-input>
          </div>
        </section>
        <hr class="my-3" />
        <div class="container mb-3 px-2">
          <div class="row">
            <div class="offset-4 col-4 px-2">
              <AppButton label="Cancelar" type="danger" :hasOutline="true" :isBlock="true" @click.prevent="closeModal" />
            </div>
            <div class="col-4 px-2">
              <AppButton label="Salvar" type="primary" :hasOutline="true" :isBlock="true" @click.prevent="save" />
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { ref, reactive, defineEmits } from "vue";
import { AppIcon, AppButton } from "../../../../../components/AppGlobal";
import { progress, dialogs } from "../../../../../helpers";
import { EntityBillReceiveSetNeedsReviewType, initEntityBillReceiveSetNeedsReviewType } from "../../types";
import entityBillReceiveStore from "../../store/entityBillReceiveStore";
//#endregion

//#region Emits
const emit = defineEmits(["updated"]);
//#endregion

//#region Data
const isOpen = ref(false);
/** @type {EntityBillReceiveSetNeedsReviewType} */
const data = reactive(initEntityBillReceiveSetNeedsReviewType());
//#endregion

//#region Methods
async function openModal(entity_bill_receive_id) {
  Object.assign(data, initEntityBillReceiveSetNeedsReviewType());
  data.entity_bill_receive_id = entity_bill_receive_id;
  isOpen.value = true;
}

function closeModal() {
  isOpen.value = false;
}

async function save() {
  const loader = progress.showLoader();
  await entityBillReceiveStore.actions
    .setNeedsReview(data)
    .then(() => {
      dialogs.notify();
      emit("updated");
    })
    .catch((error) => {
      dialogs.notifyError({
        type: dialogs.TYPE_ENUM.DANGER,
        message: error.message,
      });
    })
    .finally(() => {
      closeModal();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
defineExpose({
  openModal,
});
//#endregion
</script>
