<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar v-bind:disabled="menu">
      <template slot="links">
        <AppButton
          :label="isSideBarExpanded ? 'Configurações' : ''"
          class="m-2 d-flex justify-content-center align-items-center"
          :width="isSideBarExpanded ? '90%' : '80%'"
          icon="settings"
          @click.prevent="handleCreateGenerateSettings()"
        >
        </AppButton>

        <!-- Geral -->
        <sidebar-item
          v-show="has_any_permission('configuration')"
          ref="configuration"
          @closeAllModule="closeAll"
          :link="{ name: 'Geral', path: '/configuration',icon: 'sidebar-icon-settings'}"
        >
          <sidebar-item
            v-show="has_permission('configuration.issuer-document.index.view')"
            :link="{ name: 'Filiais', path: '/configuration/plant/document/issuer-document'}"
          />
          <sidebar-item
            v-show="has_permission('configuration.cash-flow-bank-account.index.view')"
            :link="{ name: 'Contas', path: '/configuration/bank-account'}"
          />
          <sidebar-item
            v-show="has_permission('configuration.plant.index.view')"
            :link="{ name: 'Centrais', path: '/configuration/general' }"
          />
          <sidebar-item
            v-show="has_permission('configuration.sync-general.index.view')"
            :link="{ name: 'Integrações', path: '/configuration/sync-general' }"
          />
        </sidebar-item>

        <!-- Cadastro -->
        <sidebar-item
          v-show="has_any_permission('records')"
          ref="registration"
          @closeAllModule="closeAll"
          :link="{ name: 'Cadastro', path: '/records',icon: 'sidebar-icon-registration'}"
        >
          <sidebar-item
            v-show="has_permission('records.entity.index.view')"
            :link="{ name: 'Clientes e fornec.', path: '/configuration/entity/dashboard'}"
          />
          <sidebar-item
            v-show="has_permission('records.user.index.view')"
            :link="{ name: 'Usuários', path: '/configuration/user'}"
          />
          <sidebar-item
            v-show="has_permission('records.stock-locations.index.view')"
            :link="{ name: 'Locais de Estoque', path: '/configuration/stock-locations'}"
          />
          <sidebar-item
            v-show="has_permission('records.cost-centers.index.view')"
            :link="{ name: 'Centro de Custo', path: '/configuration/general/cost-centers'}"
          />
          <sidebar-item
            v-show="has_permission('records.payment-intermediary.index.view')"
            :link="{ name: 'Mediador de Pag.', path: '/financial/bill-receive/payment-intermediary'}"
          />
          <sidebar-item
            v-show="has_permission('records.payment-method.index.view')"
            :link="{ name: 'Métodos de Pag.', path: '/financial/bill-receive/payment-methods'}"
          />
          <sidebar-item
            v-show="has_permission('records.payment-term.index.view')"
            :link="{ name: 'Condições de Pag.', path: '/financial/bill-receive/payment-term'}"
          />
        </sidebar-item>

        

        <!-- Gerencial -->
        <sidebar-item
          v-show="has_any_permission('administrative')"
          ref="administrative"
          @closeAllModule="closeAll"
          :link="{ name: 'Gerencial', path: '/administrative', icon: 'sidebar-icon-bullish'}"
        >
          <sidebar-item v-show="has_permission('administrative.dre.index.view')"
                        :link="{ name: 'DRE Anual', path: '/administrative/dre' }"></sidebar-item>
          <!-- <sidebar-item v-show="has_permission('administrative.dre-puzl.index.view')"
                        :link="{ name: 'DRE PUZL', path: '/administrative/dre-puzl' }"></sidebar-item> -->
          <sidebar-item v-show="has_permission('administrative.general-resume.index.view')"
                      :link="{ name: 'Resumo Geral', path: '/administrative/general-resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.mcc-resume.index.view')"
                        :link="{ name: 'Resumo MCC', path: '/administrative/mcc-resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.signature-resume.index.view')"
                        :link="{ name: 'Assinatura digital', path: '/administrative/digital-signature-resume' }" />
          <sidebar-item v-show="has_permission('administrative.reuse-control.index.view')"
                        :link="{ name: 'Controle de reuso', path: '/administrative/reuse-control' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.entity-wallet.index.view')"
                        :link="{ name: 'Carteira de clientes', path: '/administrative/entity-wallet' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.production-resume.index.view')"
                        :link="{ name: 'Resumo de Produção', path: '/administrative/production-resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.driver-resume.index.view')"
                        :link="{ name: 'Resumo de Motorista', path: '/administrative/driver-resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('administrative.equipment-resume.index.view')"
                        :link="{ name: 'Resumo de Equipamento', path: '/administrative/equipment-resume' }"></sidebar-item>
          <sidebar-item
                        v-show="has_permission('administrative.delivery-resume.index.view')"
                        :link="{ name: 'Resumo de entregas', path: '/administrative/delivery-resume' }"></sidebar-item>
          <sidebar-item
                        v-show="has_permission('administrative.horary-resume.index.view')"
                        :link="{ name: 'Resumo de horários', path: '/administrative/horary-resume' }"></sidebar-item>
        </sidebar-item>

        <hr class="bg-ligth m-0">

        <!-- Tecnologia -->
        <sidebar-item
          v-show="has_any_permission('technology')"
          ref="technology"
          @closeAllModule="closeAll"
          :link="{ name: 'Tecnologia', path: '/technology', icon: 'sidebar-icon-physics'}"
        >
          <sidebar-item
            v-show="has_permission('technology.dashboard.index.view')"
            :link="{ name: 'Dashboard', path: '/technology/dashboard?sidebar=0' }"
          />
          <sidebar-item
            v-show="has_permission('technology.dre.index.view')"
            :link="{ name: 'DRE Técnico', path: '/technology/technical-dre' }"
          />
          <sidebar-item
            v-show="has_permission('technology.puzl-adjustment.index.view')"
            :link="{ name: 'Puzl Adjust', path: '/technology/adjustments' }"
          />
          <sidebar-item
            v-show="has_permission('technology.mix.index.view')"
            :link="{ name: 'Formulações', path: '/technology/mix/grid' }"
          />
          <sidebar-item
            v-show="has_permission('technology.cmc.index.view')"
            :link="{ name: 'MCC', path: '/technology/cmc/grid' }"
          />
          <sidebar-item
            v-show="has_permission('technology.test.index.view')"
            :link="{ name: 'Ensaios', path: '/technology/test' }"
          />
          <sidebar-item
            v-show="has_permission('technology.molding-resume.index.view')"
            :link="{ name: 'Resumo de Moldagem', path: '/technology/molding-resume' }"
          />
          <sidebar-item
            v-show="has_permission('technology.approval-technical.index.view')"
            :link="{ name: 'Aprovação Técnica', path: '/technology/approval-technical' }"
          />
        </sidebar-item>

        <!-- Comercial -->
        <sidebar-item
          v-show="has_any_permission('commercial')"
          ref="commercial"
          @closeAllModule="closeAll"
          :link="{ name: 'Comercial', path: '/commercial', icon: 'sidebar-icon-client-company'}"
        >
          <sidebar-item v-show="has_permission('commercial.dashboard.index.view')"
                        :link="{ name: 'Dashboard', path: '/commercial/dashboard?sidebar=0' }"></sidebar-item>
          <!--          <sidebar-item :link="{ name: 'Mapa PUZL', path: '/all' }"></sidebar-item>-->
          <sidebar-item
            v-show="has_permission('commercial.contract-proposal.index.view')"
            :link="{ name: 'Contratos', path: '/commercial/contract' }"
          ></sidebar-item>
          <sidebar-item
            v-show="has_permission('commercial.contract-proposal.index.view')"
            :link="{ name: 'Propostas', path: '/commercial/proposal' }"
          ></sidebar-item>
          <sidebar-item v-show="has_permission('commercial.customer-construction.index.view')"
                        :link="{ name: 'Prospecção de obras', path: '/commercial/customer-construction' }"></sidebar-item>
          <sidebar-item v-show="has_permission('commercial.additional.index.view')"
                        :link="{ name: 'Adicionais', path: '/commercial/additional' }"></sidebar-item>
          <sidebar-item v-show="has_permission('commercial.comission.index.view')"
                        :link="{ name: 'Comissões', path: '/commercial/commission' }"></sidebar-item>
          <sidebar-item v-show="has_permission('commercial.concrete-price.index.view')"
                        :link="{ name: 'Preço de Concreto', path: '/commercial/concrete-price' }"></sidebar-item>
          <sidebar-item v-show="has_permission('commercial.service-price.index.view')"
                        :link="{ name: 'Preço de Serviço', path: '/commercial/service-price' }"></sidebar-item>
          <!--          <sidebar-item :link="{ name: 'Relatórios', path: '/all' }"></sidebar-item>-->
         <sidebar-item v-show="has_permission('commercial.sales-resume.index.view')"
          :link="{ name: 'Resumo de Vendas', path: '/commercial/sales-resume' }"></sidebar-item>
        </sidebar-item>

        <!-- Operacional -->
        <sidebar-item
          v-show="has_any_permission('operational')"
          ref="operational"
          @closeAllModule="closeAll"
          :link="{ name: 'Operacional', path: '/all', icon: 'sidebar-icon-chemical-plant' }"
        >
          <sidebar-item
            v-show="has_permission('operational.dashboard.index.view')"
            :link="{ name: 'Dashboard', path: '/operational/dashboard?sidebar=0' }"
          />
          <sidebar-item
            v-show="has_permission('operational.schedule.index.view')"
            :link="{ name: 'Programação e Pesagem', path: '/operational/schedule' }"
          />
          <sidebar-item
            v-show="has_permission('operational.schedule-travel-gantt.index.view')"
            :link="{ name: 'Gantt', path: '/operational/schedule-travel-gantt' }"
          />
          <sidebar-item
            v-show="has_permission('operational.schedule-travel-gantt.index.view')"
            :link="{ name: 'Gantt (Beta)', path: '/operational/schedule-travel-puzl-gantt' }"
          />
          <!-- <sidebar-item :link="{ name: 'Viagens', path: '/operational/kanban' }" /> -->
          <sidebar-item
            v-show="has_permission('operational.resume-additional.index.view')"
            :link="{ name: 'Resumo de Adicionais', path: '/operational/resume-additional' }"
          />
          <sidebar-item
            v-show="has_permission('operational.schedule-resume.index.view')"
            :link="{ name: 'Resumo de programações', path: '/operational/schedule/resume' }"
          />
          <sidebar-item
            v-show="has_permission('operational.daily-resume.index.view')"
            :link="{ name: 'Resumo Diário', path: '/operational/daily-resume' }"
          />
          <sidebar-item
            v-show="has_permission('operational.charge-resume.index.view')"
            :link="{ name: 'Resumo de cargas', path: '/operational/charge-resume' }"
          />
          <!-- <sidebar-item :link="{ name: 'Mapa de Viagens', path: '/all' }" /> -->
          <!-- <sidebar-item :link="{ name: 'Reúso PUZL', path: '/all' }" /> -->
        </sidebar-item>

        <!-- Serviços -->
        <sidebar-item
          v-show="has_any_permission('service')"
          ref="services"
          @closeAllModule="closeAll"
          :link="{ name: 'Serviços', path: '/all', icon: 'sidebar-icon-group' }"
        >
          <sidebar-item
            v-show="has_permission('service.dashboard.index.view')"
            :link="{ name: 'Agenda', path: '/service/schedule-service-agenda' }">
          </sidebar-item>
        </sidebar-item>

        <!-- Estoque -->
        <sidebar-item
          v-show="has_any_permission('stock')"
          ref="stock"
          @closeAllModule="closeAll"
          :link="{ name: 'Estoque', path: '/all', icon: 'sidebar-icon-open-box'}"
        >
          <sidebar-item
            v-show="has_permission('stock.dashboard.index.view')"
            :link="{ name: 'Dashboard', path: '/operational/dashboard?sidebar=0' }"></sidebar-item>
          <sidebar-item v-show="has_permission('stock.index.view')"
                        :link="{ name: 'Estoque MCC', path: '/operational/stock' }"></sidebar-item>
          <!--          <sidebar-item v-show="has_permission('stock.stock-balance.index.view')"-->
          <!--             :link="{ name: 'Saldos de estoque', path: '/operational/stock-balance' }"></sidebar-item>-->
          <sidebar-item v-show="has_permission('stock.stock-resume.index.view')"
                        :link="{ name: 'Resumo de saídas', path: '/operational/stock/resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('stock.entry-resume.index.view')"
                        :link="{ name: 'Resumo de entradas', path: '/operational/stock/entry-resume' }"></sidebar-item>
          <sidebar-item v-show="has_permission('stock.stock-movements.index.view')"
                        :link="{ name: 'Movimentos de estoque', path: '/operational/stock/stock-movements' }"></sidebar-item>
          <sidebar-item v-show="has_permission('stock.stock-current.index.view')"
                        :link="{ name: 'Estoque atual', path: '/operational/stock/current' }"></sidebar-item>
          <sidebar-item v-show="has_permission('stock.stock-analyze.index.view')"
                        :link="{ name: 'Análise de estoque', path: '/operational/stock/analyze' }"></sidebar-item>
        </sidebar-item>

        <!-- Equipamento -->
        <sidebar-item
          v-show="has_any_permission('equipment')"
          ref="equipment"
          @closeAllModule="closeAll"
          :link="{ name: 'Equipamento', path: '/equipment/equipment', icon: 'sidebar-icon-concrete-pump'}"
        >
          <sidebar-item
            v-show="has_permission('equipment.equipment.index.view')"
            :link="{ name: 'Equipamentos', path: '/equipment/equipment' }">
          </sidebar-item>
          <sidebar-item
            :link="{ name: 'Abastecimento', path: '/equipment/fuel-control' }">
          </sidebar-item>
          <sidebar-item
            v-show="has_permission('equipment.fuel-resume.index.view')"
            :link="{ name: 'Resumo de abastecimento', path: '/equipment/fuel-resume' }"
          >
          </sidebar-item>
        </sidebar-item>

        <!-- Checklist -->
        <sidebar-item
          v-show="has_any_permission('checklist')"
          ref="checklist"
          @closeAllModule="closeAll"
          :link="{ name: 'Checklist', path: '/all', icon: 'sidebar-icon-checklist' }"
        >
          <sidebar-item
            v-show="has_permission('checklist.registrations.index.view')"
            :link="{ name: 'Cadastros', path: '/equipment/checklist' }">
          </sidebar-item>
          <sidebar-item
            v-show="has_permission('checklist.made.index.view')"
            :link="{ name: 'Realizados', path: '/equipment/checklist-made' }">
          </sidebar-item>
          <!-- <sidebar-item
            v-show="has_permission('equipment.checklist.index.view')"
            :link="{ name: 'Aprovações', path: '/equipment/checklist-approved' }">
          </sidebar-item> -->
          <!-- <sidebar-item
            v-show="has_permission('equipment.checklist.index.view')"
            :link="{ name: 'Resumo', path: '/equipment/checklist' }">
          </sidebar-item> -->
        </sidebar-item>

        <hr class="bg-ligth m-0">

        <!-- Compras -->
        <sidebar-item
          v-show="has_permission('purchases')"
          ref="purchases"
          @closeAllModule="closeAll"
          :link="{ name: 'Compras', path: '/purchases', icon: 'sidebar-icon-purchases'}"
        >
          <sidebar-item
            v-show="has_permission('purchases.request.index.view')"
            :link="{ name: 'requisição', path: '/purchase/request' }"
          />
          <sidebar-item
            v-show="has_permission('purchases.budget.index.view')"
            :link="{ name: 'cotação', path: '/purchase/budget' }"
          />
          <sidebar-item
            v-show="has_permission('purchases.order.index.view')"
            :link="{ name: 'Ordem de compra', path: '/purchase/order' }"
          />
        </sidebar-item>

        <!-- Entradas -->
        <sidebar-item
          v-show="has_any_permission('entries')"
          ref="entries"
          @closeAllModule="closeAll"
          :link="{ name: 'Entrada', path: '/entries', icon: 'sidebar-icon-login-rounded'}"
        >
          <sidebar-item
            v-show="has_permission('entries.entry-launch.index.view')"
            :link="{ name: 'Entradas, Despesas e Custos', path: '/configuration/entry-launch' }"
          />
          <sidebar-item
            v-show="has_permission('entries.product-service.index.view')"
            :link="{ name: 'Itens', path: '/configuration/product-service' }"
          />
          <sidebar-item
            v-show="has_permission('entries.entry-launch.external.index.view')"
            :link="{ name: 'Sefaz', path: '/configuration/entry-launch/external/0' }"
          />
          <sidebar-item
            v-show="has_permission('entries.divergent-price.dashboard.index')"
            :link="{ name: 'Preços MCC', path: '/technology/cmc/divergent-price' }"
          />
        </sidebar-item>

        <!-- Saídas -->
        <sidebar-item
          v-show="has_any_permission('exit')"
          ref="exit"
          @closeAllModule="closeAll"
          :link="{ name: 'Saída', path: '/exit', icon: 'sidebar-icon-login-rounded-right'}"
        >
          <sidebar-item
            v-show="has_permission('exit.exit-launch.index.view')"
            :link="{ name: 'Pedidos', path: '/configuration/exit-launch' }"
          />
        </sidebar-item>

        <!-- Financeiro -->
        <sidebar-item
          v-show="has_any_permission('financial')"
          ref="financial"
          @closeAllModule="closeAll"
          :link="{ name: 'Financeiro', path: '/financial', icon: 'sidebar-icon-finance'}"
        >
          <sidebar-item v-show="has_permission('financial.dashboard.index.view')"
                        :link="{ name: 'Dashboard', path: '/financial/dashboard?sidebar=0' }"></sidebar-item>
          <!--          <sidebar-item v-show="has_permission('financial.cash-flow.index.view')" :link="{ name: 'Fluxo de Caixa', path: '/financial/cash-flow' }"></sidebar-item>-->
          <sidebar-item v-show="has_permission('financial.finances.index.view')"
                        :link="{ name: 'Operações financeiras', path: '/financial/finances' }"></sidebar-item>
          <sidebar-item v-show="has_permission('financial.bill-pay.index.view')"
                        :link="{ name: 'Contas a Pagar', path: '/financial/bill-pay' }"></sidebar-item>
          <sidebar-item v-show="has_permission('financial.bill-receive.index.view')"
                        :link="{ name: 'Contas a Receber', path: '/financial/bill-receive' }"></sidebar-item>
          <sidebar-item v-show="has_permission('financial.entity-bill-receive.index.view')"
                        :link="{ name: 'Créditos e antecipações', path: '/financial/entity-bill-receive' }"></sidebar-item>
          <sidebar-item v-show="has_permission('financial.approvals.index.view')"
                        :link="{ name: 'Aprovação de antecipação', path: '/financial/approvals' }"></sidebar-item>
          <sidebar-item
            v-show="has_permission('financial.credit-release.index.view')"
            :link="{ name: 'Liberação de Crédito', path: '/financial/credit-release' }"
          >
          </sidebar-item>
          <!--          <sidebar-item v-show="has_permission('financial.general-resume.index.view')"-->
          <!--          :link="{ name: 'Resumo Geral', path: '/financial/general-resume' }"></sidebar-item>-->
          <!--          <sidebar-item :link="{ name: 'DRE PUZL', path: '/financial/dre' }"></sidebar-item>-->
        </sidebar-item>

        <!-- Faturamento -->
        <sidebar-item
          v-show="has_any_permission('billing')"
          ref="billing"
          @closeAllModule="closeAll"
          :link="{ name: 'Faturamento', path: '/billing', icon: 'sidebar-icon-check'}"
        >
          <sidebar-item v-show="has_permission('billing.billed.index.view')"
                        :link="{ name: 'Faturas/NFs-e', path: '/billing/billed' }"></sidebar-item>
          <sidebar-item v-show="has_permission('billing.billed.index.view')"
                        :link="{ name: 'Em aberto', path: '/billing/opened' }"></sidebar-item>
          <sidebar-item v-show="has_permission('billing.single.index.view')"
                        :link="{ name: 'Avulsos', path: '/billing/single' }"></sidebar-item>
          <sidebar-item v-show="has_permission('billing.bank-billet.index.view')"
                        :link="{ name: 'Boletos', path: '/billing/bank-billet' }"></sidebar-item>
        </sidebar-item>

        <!-- Fluxo de caixa -->
        <sidebar-item
          v-show="has_any_permission('cash_flow')"
          ref="cash_flow"
          @closeAllModule="closeAll"
          :link="{ name: 'Fluxo de caixa', path: '/cash_flow', icon: 'sidebar-icon-exchange' }"
        >
          <sidebar-item v-show="has_permission('cash_flow.bank-balance.index.view')"
                        :link="{ name: 'Saldo de contas', path: '/cash-flow/bank-balance' }"></sidebar-item>
          <sidebar-item v-show="has_permission('cash_flow.finance-transactions.index.view')"
                        :link="{ name: 'Conciliação Financeira', path: '/cash-flow/finance-transactions' }"></sidebar-item>
          <sidebar-item v-show="has_permission('cash_flow.bank-reconciliation.index.view')"
                        :link="{ name: 'Conciliação bancária', path: '/cash_flow/bank-reconciliation' }"></sidebar-item>
          <sidebar-item
            v-show="has_permission('cash_flow.electronic-bank-statement.index.view')"
            :link="{ name: 'Conciliação de cartões', path: '/financial/card-conciliation' }"
          >
          </sidebar-item>
          <sidebar-item
            v-show="has_permission('cash_flow.account-statement.index.view')"
            :link="{ name: 'Extrato', path: '/cash-flow/account-statement' }"></sidebar-item>
          <sidebar-item v-if="$hasPrivilege(1)" v-show="has_permission('cash_flow.cashier-resume.index.view')"
                        :link="{ name: 'Resumo de Caixa', path: '/cash-flow/cashier-resume' }"></sidebar-item>
        </sidebar-item>

        <!-- Fiscal -->
        <sidebar-item
          v-show="has_any_permission('fiscal')"
          ref="fiscal"
          @closeAllModule="closeAll"
          :link="{ name: 'Fiscal', path: '/fiscal', icon: 'sidebar-icon-ledger' }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/fiscal/dashboard?sidebar=0' }"
            v-show="has_permission('fiscal.dashboard.index.view')"
          />
          <sidebar-item
            :link="{ name: 'Tipos de Operação PUZL', path: '/fiscal/operation-sources' }"
            v-show="has_permission('fiscal.operation-source.index.view')"
          />
          <sidebar-item v-show="has_permission('fiscal.nfe_issuedes.index.view')"
                        :link="{ name: 'NF-e Emitidas', path: '/financial/nfe_issuedes' }"/>
          <sidebar-item v-show="has_permission('fiscal.mdfe.index.view')"
                        :link="{ name: 'MDF-e', path: '/fiscal/mdfe' }"/>
          <sidebar-item
            v-show="has_permission('fiscal.fees_and_taxes.index.view')"
            :link="{ name: 'Taxas e Tributos', path: '/configuration/fees-and-taxes' }" />
          <sidebar-item v-show="has_permission('fiscal.iss-resume.index.view')"
                        :link="{ name: 'Resumo ISS', path: '/fiscal/iss-resume' }"/>
          <sidebar-item v-show="has_permission('fiscal.accounting-chart-account.index.view')"
                        :link="{ name: 'Plano Contábil', path: '/fiscal/accounting-chart-account' }"/>
          <sidebar-item v-show="has_permission('fiscal.chart-account.index.view')"
                        :link="{ name: 'Plano Gerencial', path: '/configuration/chart-account-resume' }"/>
        </sidebar-item>

        <hr class="bg-ligth m-0">

        <!-- Motorista -->
        <sidebar-item
          v-show="has_any_permission('driver')"
          ref="driver"
          @closeAllModule="closeAll"
          :link="{ name: 'Motorista', path: '/driver', icon: 'sidebar-icon-interstate-truck' }"
        >
          <sidebar-item v-show="has_permission('driver.dashboard.index.view')"
                        :link="{ name: 'Dashboard', path: '/all' }"></sidebar-item>
          <sidebar-item v-show="has_permission('driver.in-course.index.view')"
                        :link="{ name: 'Entregas', path: '/driver/in-course' }"></sidebar-item>
        </sidebar-item>
        <!--        <sidebar-item-->
        <!--          ref="customer"-->
        <!--          @closeAllModule="closeAll"-->
        <!--          :link="{-->
        <!--            name: 'Cliente',-->
        <!--            path: '/maintenance',-->
        <!--            icon: 'fas fa-users text-primary' }">-->
        <!--          <sidebar-item :link="{ name: 'Proposta Contrato', path: '/all' }"></sidebar-item>-->
        <!--        </sidebar-item>-->

      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
      <!--      <div class="row mr-2 mt-2" v-if="this.$route.meta.global_filter">-->
      <!--        <div style="z-index: 2" class="col-12 text-right">-->
      <!--          <base-button style="width: 62px;height: 60px" @click="handleShowCompanyPlantFilter" type="facebook" class="btn-icon-only rounded-circle">-->
      <!--            <span class="btn-inner&#45;&#45;icon"><i class="fa-solid fa-magnifying-glass"></i></span>-->
      <!--          </base-button>-->
      <!--        </div>-->
      <!--        <div class="col-8"/>-->
      <!--        <div v-if="showFilter" style="margin-top: -0.8rem;z-index: 1" class="col-md-4">-->
      <!--          <card style="height: 130px;margin-left: 10px">-->
      <!--            <div class="card-body">-->
      <!--              <div class="row p-0 " style="margin-top: -2rem">-->
      <!--                <div class="col-12">-->
      <!--                  <label>Central</label>-->
      <!--                  <base-input input-classes="form-control">-->
      <!--                    <el-select class="select-lg"-->
      <!--                               @change="setGlobalFilter()"-->
      <!--                               v-model="global_filter.plant_id"-->
      <!--                               :disabled="filter_disabled"-->
      <!--                               size="mini"-->
      <!--                               placeholder="Selecione"-->
      <!--                               filterable-->
      <!--                    >-->
      <!--                      <el-option label="Selecione" value=""></el-option>-->
      <!--                      <el-option v-for="plant in $_plants" :key="plant.id"-->
      <!--                                 :label="plant.name"-->
      <!--                                 :value="plant.id"-->
      <!--                      >-->
      <!--                      </el-option>-->
      <!--                    </el-select>-->
      <!--                  </base-input>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </card>-->
      <!--        </div>-->
      <!--      </div>-->
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200"
                         origin="center top" mode="out-in">
          <router-view>
          </router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <GeneralSettingsModal ref="openGeneralSettingsModal" />
  </div>
</template>

<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import {hasPermission, hasAnyPermission} from "@/plugins/permissions";
import ModalGeneralSettings from '../Modules/Configuration/_ModalGeneralSettings.vue';
import GeneralSettingsModal from "../../modules/configuration/general-setting/views/GeneralSettingsModal.vue";
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import {
  FadeTransition
} from "vue2-transitions";
import {mapGetters} from "vuex";
import { AppButton } from "../../components/AppGlobal";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    ModalGeneralSettings,
    GeneralSettingsModal,
    AppButton,
  },
  data() {
    return {
      menu: true,
      has_permission: hasPermission,
      has_any_permission: hasAnyPermission,
      showFilter: false,
      global_filter: {
        plant_id: null,
      },
      filter_disabled: false,
    };
  },
  computed: {
    ...mapGetters({
      $_plants: "plant/activeItems",
    }),
    isSideBarExpanded() {
      return this.$sidebar.hovered || !this.$sidebar.isMinimized;
    }
  },
  methods: {
    handleCreateGenerateSettings() {
      if (!this.has_permission('configuration.configurations.index.view')) {
        this.$notify({
          type: 'danger',
          message: 'Usuário sem permissão para acessar modal de config. gerais.'
        });
        return;
      }
      this.$refs.openGeneralSettingsModal.openModal();
    },
    handleShowCompanyPlantFilter() {
      this.showFilter = !this.showFilter
    },
    async setGlobalFilter() {
      this.$filterable = this.global_filter.plant_id
    },
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
    closeAll() {
      this.$refs.configuration.minimizeAll()
      this.$refs.registration.minimizeAll()
      this.$refs.entries.minimizeAll()
      this.$refs.exit.minimizeAll()
      this.$refs.fiscal.minimizeAll()
      // this.$refs.records.minimizeAll()
      this.$refs.technology.minimizeAll()
      this.$refs.commercial.minimizeAll()
      this.$refs.operational.minimizeAll()
      this.$refs.equipment.minimizeAll()
      this.$refs.purchases.minimizeAll()
      this.$refs.financial.minimizeAll()
      this.$refs.driver.minimizeAll()
      this.$refs.cash_flow.minimizeAll()
      this.$refs.services.minimizeAll()
      this.$refs.stock.minimizeAll()
      this.$refs.billing.minimizeAll()
      this.$refs.administrative.minimizeAll()
      // this.$refs.customer.minimizeAll()
      this.$forceUpdate()
    }
  },
  watch: {
    '$route.query.sidebar': function (sideBarDisabled) {
      if (sideBarDisabled) {
        this.$sidebar.toggleMinimize(false, true);
        this.$forceUpdate()
      }
    },
  },
  mounted() {
    this.filter_disabled = true
    this.$store.dispatch("plant/fetchItemsActive").then(() => {
      this.filter_disabled = false
    })
    this.initScrollbar();
    this.$root.$on('changedMenu', (event) => {
      if (this.menu === false) {
        this.menu = true;
      }
    })
  },
};
</script>

<style lang="scss">
.nav-link-text {
  color: #7e7e7e;
  font-size: 0.87rem;
}

.nav-link-text:hover {
  color: black;
}

.active > span {
  color: #2d3748;
}

.sidebar-icon-entity {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/client-company.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-user {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/icons8/ios/user-male-blue.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-settings {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/settings.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-registration {
  width: 21px;
  height: 21px;
  background-color: #f2b532;
  -webkit-mask: url('/img/icons/icons8/ios/svgs/registrations.svg') no-repeat left;
  mask: url('/img/icons/icons8/ios/svgs/registrations.svg') no-repeat left;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.sidebar-icon-login-rounded {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/login-rounded.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-login-rounded-right {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/login-rounded-right.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-physics {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/physics.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-client-company {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/icons8/ios/briefcase-green.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-chemical-plant {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/chemical-plant.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-open-box {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/open-box.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-concrete-pump {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/concrete-pump.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-bullish {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/bullish.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-finance {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/finance.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-check {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/check.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-exchange {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/exchange.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-ledger {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/ledger.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-interstate-truck {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/interstate-truck.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}
.sidebar-icon-purchases {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/cart-warning.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-group {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/group.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.sidebar-icon-checklist {
  width: 21px;
  height: 21px;
  background-image: url('/img/icons/checklist.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

</style>
