<template>
  <div>
    <modal :show.sync="modal.create" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <div v-if="item.product_service_hub" class="border-left border-3 border-primary pr-1 mt-2 ml-2">
          <div class="ml-2">
            <h4 class="m-0">{{ item.product_service_hub.product_name }}</h4>
            <h4 class="m-0">{{ item.total_value | currency }}</h4>
          </div>
        </div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation p-2" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <span @click.prevent="addItem" class="btn btn-md btn-success p-1 mb-2 ml-1 float-right mr-0">
                <i class="fas fa-plus"></i> Novo
              </span>
              <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th class="text-center text-white bg-default">Centro de custo</th>
                    <th class="text-center text-white bg-default">Equipamento</th>
                    <th class="text-center text-white bg-default">%</th>
                    <th class="text-center text-white bg-default">R$</th>
                    <th class="text-center text-white bg-default"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in payload" :key="index">
                    <td class="text-center col-3">
                      <validation-provider :rules="!item.equipment_id ? 'required' : ''">
                        <PuzlSelect
                          @input="changedCostCenter($event, item)"
                          placeholder="Centro de custo"
                          v-model="item.cost_center_id"
                          :items="$_cost_centers"
                        />
                      </validation-provider>
                    </td>
                    <td class="text-center col-3">
                      <validation-provider :rules="!item.cost_center_id ? 'required' :''">
                        <base-input input-group-classes="input-group-sm">
                          <puzl-select
                            @input="changedEquipment($event, item)"
                            :clearable="false"
                            v-model="item.equipment_id"
                            :items="$_equipments"
                            label="$code$ - $plate$"
                            :labelMask="true"
                          />
                        </base-input>
                      </validation-provider>
                    </td>
                    <td class="text-center col-2 align-middle">
                      <validation-provider rules="required|min_value:0.01">
                        <base-input input-group-classes="input-group-sm">
                          <input-limit
                            :start_value="item.percentage"
                            :options="{
                              min: 0.0,
                              max: 100.0,
                              decimals: 1,
                            }"
                            v-model="item.percentage"
                            @value="item.percentage = $event"
                            @change="calcValue($event.target.value, index)"
                          >
                            <small>%</small>
                          </input-limit>
                        </base-input>
                      </validation-provider>
                    </td>
                    <td class="text-center col-4">
                      <validation-provider :rules="valueRequired(item.value)">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            :disabled="index === 0"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model.lazy="item.value"
                            @blur="calcPercentage($event.target.value, index)"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0"> R$ </small>
                          </template>
                        </base-input>
                      </validation-provider>
                    </td>
                    <td class="text-center col-2 align-middle">
                      <i
                        v-show="item.equipment_id || item.cost_center_id"
                        @click.prevent="handleDelete(index)"
                        role="button"
                        class="fas fa-trash text-danger pt-1"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit" :disabled="invalid">Salvar</base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { VMoney } from "v-money";
import { cloneObject, moneyToFloat } from "@/helpers";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import InputLimit from "@/components/Utils/InputLimit.vue";

export default {
  name: "ModalEquipmentApportionment",
  components: { PuzlSelect, InputLimit },
  mixins: [entryLaunchMixin],
  data() {
    return {
      modal: {
        title: "Rateio de Equipamentos",
        create: false,
      },
      payload: [],
      item: {},
      index: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      entry_launch_is_paid: false,
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      $_equipments: "equipment/fetch",
      $_company_plants: "plant/activeItems",
      $_cost_centers: "costCenter/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    async openModal(item, index, entry_launch_is_paid = false) {
      this.item = item;
      this.index = index;
      this.entry_launch_is_paid = entry_launch_is_paid;
      if (item.equipment_apportionments && item.equipment_apportionments.length) {
        item.equipment_apportionments.map(function (item) {
          item.percentage = Number(item.percentage).toFixed(1);
          return item;
        });
        this.payload = item.equipment_apportionments;
      } else {
        this.payload = [
          {
            value: Number(item.total_value).toFixed(2),
            percentage: "100.0",
            entry_launch_item_id: null,
            product_service_hub_id: item.product_service_hub_id,
            equipment_id: null,
          },
        ];
      }
      this.modal.create = true;
    },
    async store() {
      /* Caso esteja pago persistirá no banco */
      if (this.entry_launch_is_paid) {
        const loader = this.$loading.show();
        let payload = this.$helper.cloneObject(this.payload);
        payload.map(function (item) {
          item.value = this.$helper.moneyToFloat(item.value);
          return item;
        }, this);

        await this.$store
          .dispatch("entryLaunchItem/storeEquipmentApportionments", {
            id: this.item.id,
            product_service_hub_id: this.item.product_service_hub_id,
            equipment_apportionments: payload,
          })
          .then((response) => {
            this.$notify({
              type: response.error_type,
              message: response.message,
            });
          })
          .catch((error) => {
            if (error.status) {
              this.$notify({
                type: "danger",
                message: error.data.message,
              });
            }
          })
          .finally(() => {
            loader.hide();
          });
      }
      this.$emit("added", this.payload, this.index);
      this.closeModal();
    },
    addItem() {
      if (this.payload.length) {
        this.payload.push({
          value: 0,
          percentage: "0.0",
          entry_launch_item_id: null,
          product_service_hub_id: this.item.product_service_hub_id,
          equipment_id: null,
        });
      } else {
        this.payload = [
          {
            value: Number(this.item.total_value).toFixed(2),
            percentage: "100.0",
            entry_launch_item_id: null,
            product_service_hub_id: this.item.product_service_hub_id,
            equipment_id: null,
          },
        ];
      }
    },
    calcPercentage(value, index) {
      let current_value = moneyToFloat(value);
      /* Verifica valor do item */
      if (current_value >= this.item.total_value && index !== 0) {
        this.$notify({ type: "danger", message: "O valor deve ser menor que o valor total do item." });
        return (this.payload[index].value = 0);
      }
      /* Calcula porcentagem do item atual. */
      this.payload[index].percentage = ((current_value / this.item.total_value) * 100).toFixed(1);

      /* Redefine porcentagem do primeiro item */
      this.payload[0].percentage = (
        100 - this.payload.reduce((new_value, obj, index) => new_value + (index !== 0 ? parseFloat(obj.percentage) : 0), 0)
      ).toFixed(1);

      /* Novo valor total */
      let new_total_value =
        this.item.total_value - this.payload.reduce((new_value, obj, index) => new_value + (index !== 0 ? moneyToFloat(obj.value) : 0), 0);
      /* Diferença de valor real e teórico */
      const difference = this.$helper.toDecimal(new_total_value - this.item.total_value, 2, true);
      /* Se houver resto positivo, remove do valor total */
      if (difference > 0) {
        new_total_value - difference;
      } else if (difference < 0) {
        new_total_value + difference;
      }
      /* Redefine valor do primeiro item */
      this.payload[0].value = new_total_value.toFixed(2);
    },
    calcValue(value, index) {
      const current_value = moneyToFloat(this.payload[index].value);
      /* Verifica valor do item */
      if (current_value >= this.item.total_value && index !== 0) {
        this.$notify({ type: "danger", message: "O valor deve ser menor que o valor total do item." });
        return (this.payload[index].value = 0);
      }

      /* Calcula valor do item atual. */
      let new_first_item_value = this.item.total_value * (value / 100);
      this.payload[index].value = new_first_item_value.toFixed(2);

      /* Redefine porcentagem do primeiro item */
      this.payload[0].percentage = (
        100 - this.payload.reduce((new_value, obj, index) => new_value + (index !== 0 ? parseFloat(obj.percentage) : 0), 0)
      ).toFixed(1);

      /* Novo valor total */
      let new_total_value =
        this.item.total_value -
        this.payload.reduce((new_value, obj, index) => new_value + (index !== 0 ? this.normalizeValue(obj.value) : 0), 0);
      /* Diferença de valor real e teórico */
      const difference = this.$helper.toDecimal(new_total_value - this.item.total_value, 2, true);
      /* Se houver resto positivo, remove do valor total */
      if (difference > 0) {
        new_total_value - difference;
      } else if (difference < 0) {
        new_total_value + difference;
      }
      /* Redefine valor do primeiro item */
      this.payload[0].value = new_total_value.toFixed(2);
    },
    valueRequired(value) {
      return value === "0,00" ? "min_value:1" : "";
    },
    changedEquipment(value, current_item) {
      if (this.payload.filter((item) => item.equipment_id === value && item.cost_center_id === current_item.cost_center_id).length !== 1) {
        current_item.equipment_id = null;
        return this.$notify({ type: "danger", message: "Não são permitidos equipamentos duplicados com o mesmo centro de custo." });
      }
    },
    changedCostCenter(value, current_item) {
      if (this.payload.filter((item) => item.equipment_id === current_item.equipment_id && item.cost_center_id === value).length !== 1) {
        current_item.cost_center_id = null;
        current_item.equipment_id = null;
        return this.$notify({ type: "danger", message: "Não são permitidos equipamentos duplicados com o mesmo centro de custo." });
      }
    },
    handleDelete(index) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.payload.splice(index, 1);
          let last_index = this.payload.length - 1;
          /* Se ainda houver registros */
          if (last_index >= 0) {
            this.calcPercentage(this.payload[last_index].value, last_index);
          }
        }
      });
    },
  },
};
</script>

<style>
.modal {
  overflow-y: scroll !important;
}
</style>
