<template>
  <div class="competitor-list-cards">
    <div class="row card-wrapper">
      <div
        class="col-lg-4"
        v-for="item in $_competitor_listed.items"
        :key="item.id"
      >
        <div class="competitor-card card">
          <div class="competitor-card-header">
            <AppSwitch
              style="min-width: 120px"
              v-model="item.status"
              :asyncClick="() => updateStatus(item)"
            />
            <div class="row mt-1 ml-2">
              <div>
                <el-popover class="ml-0" placement="bottom" trigger="click">
                  <div id="competitor-popover">
                    <div class="popover-title">
                      <span v-if="item.updated_by_user_name === null"
                        >Criação</span
                      >
                      <span v-else>Última Atualização</span>
                    </div>
                    <div class="hr"></div>
                    <div class="popover-content">
                      <span
                        class="popover-text"
                        v-if="item.updated_by_user_name === null"
                      >
                        {{ item.created_by_user_name }}<br />
                        {{
                          date
                            .make(item.created_at)
                            .format(date.FORMAT.SHORT_DATE_AND_TIME)
                        }}
                      </span>
                      <span class="popover-text" v-else>
                        {{ item.updated_by_user_name }}<br />
                        {{
                          date
                            .make(item.updated_at)
                            .format(date.FORMAT.SHORT_DATE_AND_TIME)
                        }}
                      </span>
                    </div>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <AppIcon icon="male-user" width="25" />
                  </base-button>
                </el-popover>
              </div>
              <div class="mx-2">
                <el-popover class="ml-0 p-0" placement="bottom" trigger="click">
                  <div id="competitor-popover">
                    <div class="popover-title">
                      <span>Observação</span>
                    </div>
                    <div class="hr"></div>
                    <div class="popover-content">
                      <span class="popover-text" v-if="item.observation">
                        {{ item.observation }}
                      </span>
                      <span class="popover-text" v-else> Sem Observações </span>
                    </div>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <AppIcon icon="chat-bubble" width="25" />
                  </base-button>
                </el-popover>
              </div>
            </div>
            <base-dropdown class="ml-auto" menuOnRight>
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img
                  class="rotate-icon"
                  width="32"
                  src="/img/icons/icons8/ios/settings--v1_primary.png"
                />
              </div>
              <a
                @click.prevent="onEditItemClick(item.id)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
              >
                <img width="18" src="/img/icons/edit.png" />
                EDITAR
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a
                @click.prevent="onRemoveItemClick(item.id)"
                class="dropdown-item font-weight-500 new-default-black-font"
                style="align-items: center; display: flex"
              >
                <img width="18" src="/img/icons/delete.png" />
                EXCLUIR
              </a>
            </base-dropdown>
          </div>
          <span class="competitor-card-title">
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="$_competitor_is_listing">
      <SkeletonPuzlGrid v-for="index in 3" :key="index" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, ref } from "vue";
import competitorStore from "../../store/competitorStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import { AppSwitch, AppIcon } from "../../../../../../components/AppGlobal";
import { CompetitorListType } from "../../types/CompetitorListPagerType";
import { date } from "../../../../../../helpers";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_competitor_listed = computed(() => competitorStore.getters.getListed());
const $_competitor_is_listing = computed(() =>
  competitorStore.getters.getIsListing()
);
//#endregion

//#region Methods
/**
 * Editar item
 * @param {number} id
 */
function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Atualizar Status por ID
 * @param {CompetitorListType} item
 */
async function updateStatus(item) {
  await competitorStore.actions.updateStatus(!item.status, item.id);
}
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

#competitor-popover {
  max-width: 300px;
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.hr {
  flex-grow: 1;
  height: 1px;
  transform: scaleY(0.5);
  transform-origin: top;
  background-color: $muted-light;
  width: calc(100% + 24px);
  margin-left: -12px;
}

.popover-title,
.popover-title span {
  display: flex;
  margin-bottom: 6px;
  gap: 15px;
  font-weight: 400;
  color: $dark;
  font-size: 14px;
}

.popover-content {
  margin-top: 6px;
  word-break: break-word;
  white-space: normal;
  text-align: left;
  font-weight: 300;
}

.popover-text {
  color: $dark;
  font-size: 12px;
}
.competitor-list-cards {
  .competitor-card {
    height: fit-content;
    box-shadow: 0px 5px 10px 0px #0000000D !important;
    border-radius: 8px !important;
    border: 0.5px solid $muted-light;
    padding: 16px 16px 20px 16px;
    gap: 16px;

  }
  .competitor-card-header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
  }
  .competitor-card-title {
    font-size: 16px; 
    font-weight: 500; 
    color: $dark;
    margin: 0;
  }
}
</style>
