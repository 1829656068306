<template>
  <div style="min-height: 800px;">
    <AppFixedPageTitle title="Avulsos" icon="/img/icons/check.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :showCompanyPlants="true"
          :isLoading="$_detached_is_listing" @onSearchClick="listItems" @onClearClick="clearFilter">
          <AppSearchBarFilterSection name="dados" icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="mt-2 w-100">
              <div class="text-left">
                <label class="form-control-label">
                  Valor do pagamento
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <base-input inputmode="numeric" v-model="filter.range_value.min" v-money="money"
                      input-group-classes="input-group-sm" placeholder="de" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                  <div class="col-6 pl-1">
                    <base-input v-money="money" inputmode="numeric" input-group-classes="input-group-sm"
                      placeholder="de" v-model="filter.range_value.max" size="sm">
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton @click.prevent="downloadExcel" text="Exportar" type="success"
            icon="/img/icons/icons8/ios/excel-white.png" />
          <AppPageHeaderActionsButton @click.prevent="handleShowModalSearchContract" text="novo" type="success"
            icon="/img/icons/plus-math--v1-white.png" />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" @onViewTypeChange="handleViewTypeChange">
    </AppTabSelect>
    <div class="container-fluid">
      <!-- CARDS -->
      <DetachedListPageCards v-if="listType === 'cards'" class="mt-4" @fetchDetached="listItems" />
      <!-- TABLE -->
      <DetachedListPageTable v-if="listType === 'table'" class="mt-4" @fetchDetached="listItems" />
      <AppViewTrigger v-if="!$_detached_is_listing" @onIntersected="listItems(true)" />
      <div class="d-flex" v-if="$_detached_is_listing">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData v-if="!$_detached_is_listing && $_detached_listed.items.length <= 0" />
    </div>
    <ModalSearchContract @openCreateSingle="handleShowCreateSingle" ref="modalSearchContract" />
    <CreateSingle :contractProposalId="contractProposalId" :contractProposalCode="contractProposalCode"
      :constructionName="constructionName" :customerConstructionName="customerConstructionName"
      ref="modalCreateSingle" />
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import ModalSearchContract from "@/views/Modules/Operational/Schedule/Shared/_ModalSearchContract";
import CreateSingle from '@/views/Modules/Financial/Billing/BillingProposal/Shared/_CreateSingle';
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  TabSelectItemType,
  AppViewTrigger,
  AppTabSelect,
  initSearchBarFilterType,
  AppSearchBarFilterSection
} from '../../../../../../../components/AppGlobal';
import detachedStore from "../../store/DetachedStore";
import DetachedListPageCards from './DetachedListPageCards.vue';
import DetachedListPageTable from './DetachedListPageTable.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import DetachedStatusEnum from "../../enums/DetachedStatusEnum";
import { initDetachedListFilterType } from "../../types";
import { date, strToNum, moneyToFloat, progress, dialogs } from "../../../.../../../../../../helpers";
//#endregion

// #region ComponentRefs
const modalSearchContract = ref(null);
const modalCreateSingle = ref(null);
// #endregion

//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());
/** @type {DetachedListFilterType} - Filtro principal */
const filter = reactive(initDetachedListFilterType());
const currentDate = date.make().format(date.FORMAT.ISO_8601);
const money = ref({
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
});
 const listType = ref("cards");
const contractProposalId = ref(null);
const contractProposalCode = ref(null);
const constructionName = ref(null);
const customerConstructionName = ref(null);
//#endregion


//#region Computeds
/** Store Getters */
const $_detached_listed = computed(() => detachedStore.getters.getListed());
const $_detached_is_listing = computed(() => detachedStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: DetachedStatusEnum.keys.IS_BILLED,
      name: DetachedStatusEnum.getTitle(DetachedStatusEnum.keys.IS_BILLED),
      selected: filter.status === DetachedStatusEnum.keys.IS_BILLED,
    },
    {
      id: DetachedStatusEnum.keys.IS_PENDING,
      name: DetachedStatusEnum.getTitle(DetachedStatusEnum.keys.IS_PENDING),
      selected: filter.status === DetachedStatusEnum.keys.IS_PENDING,
    },
  ];
});
//#endregion

//#region Methods
function handleShowModalSearchContract(data) {
  modalSearchContract.value.openModal(true, null, null);
}
function handleShowCreateSingle(id, code, construction, customer) {
  contractProposalId.value = id;
  contractProposalCode.value = code;
  constructionName.value = construction;
  customerConstructionName.value = customer;
  modalCreateSingle.value.openModal();
}
async function downloadExcel() {
  progress.start();
  const loader = progress.showLoader();
  await detachedStore.actions.getReportExcel(filter).then(() => {
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Solicitação realizada com sucesso! O relatório será exportado em segundo plano.');
  })
    .finally(() => {
      progress.finish();
      loader.hide();
    });;
}
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? detachedStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      start: filter.range.start ?? currentDate,
      end: filter.range.start ?? date.make(currentDate)
        .add(1, date.DURATION_INPUT.YEARS)
        .format(date.FORMAT.ISO_8601),
    },
  }
};

function handleViewTypeChange(type) {
  listType.value = type;
}

/**
 * Padrão do filtro principal
 * @returns {DetachedListFilterType}
 */
function defaultFilter() {
  return {
    ...initDetachedListFilterType(),
  }
};

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  detachedStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
  filter.range_value.min = strToNum(filter.range_value.min) > 0 ? moneyToFloat(filter.range_value.min) : null;
  filter.range_value.max = strToNum(filter.range_value.max) > 0 ? moneyToFloat(filter.range_value.max) : null;
};
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  detachedStore.mutations.resetStates();
  detachedStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>