export const ContractProposalStatusEnum = Object.freeze({
  INACTIVE: 0, // Inativo
  ACTIVE: 1, // Ativo
  PENDING_REVIEW: 2, // Pendente de revisão
  PENDING_APPROVAL: 3, // Pendente de aprovação
  PROPOSAL: 4, // Proposta (antigo rascunho)
  COMPLETED: 5, // Completo
  ENTITY_UPDATED: 6, // Atualização de cliente/fornecedor
  SELLER_UPDATED: 7, // Alteração de vendedor
  COMPANY_PLANT_UPDATED: 8, // Alteração de central
  ADDRESS_UPDATED: 9, // Alteração do endereço da obra
  PAYMENT_TERM_UPDATED: 10, // Alteração na condição de pagamento
  PAYMENT_METHOD_UPDATED: 11, // Alteração no método de pagamento
  FINANCIAL_APPROVED: 12, // Aprovação financeira
  FINANCIAL_DISAPPROVED: 13, // Reprovação financeira
  FINANCIAL_PENDING: 14, // Pendente de aprovação financeira
  SITUATION_UPDATED: 15, // Alteração de situação
  ADJUSTMENT: 99, // Reajuste de contrato
});
