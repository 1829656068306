<template>
  <div :style="listType === 'table' && 'display: grid;'">
    <div v-if="[paymentInstallmentStatusEnum.DENIED.ID, paymentInstallmentStatusEnum.CANCELED.ID].includes(bill_receive.status) && listType === 'table'"
      class="new-minicard-red new-minicard-table">
      <div>
        <h4>
          {{ billReceiveStatusEnum[bill_receive.status] }}
        </h4>
      </div>
    </div>
    <div v-if="[paymentInstallmentStatusEnum.DENIED.ID, paymentInstallmentStatusEnum.CANCELED.ID].includes(bill_receive.status) && listType === 'cards'"
      class="new-minicard-red">
      <h4>
        {{ billReceiveStatusEnum[bill_receive.status] }}
      </h4>
    </div>
    <base-dropdown v-if="![paymentInstallmentStatusEnum.DENIED.ID, paymentInstallmentStatusEnum.CANCELED.ID].includes(bill_receive.status)">
      <div v-if="listType === 'table'"
        :class="miniCardTypes[bill_receive.status]"
        slot="title-container"
        class="new-minicard-table">
        <div>
          <img :src="statusIcon[bill_receive.status]">
          <h4>
            {{ billReceiveStatusEnum[bill_receive.status] }}
          </h4>
          <img :src="dropdownColors[bill_receive.status]" width="12">
        </div>
      </div>
      <div v-if="listType === 'cards'"
        :class="miniCardTypes[bill_receive.status]"
        slot="title-container">
        <img :src="statusIcon[bill_receive.status]">
        <h4>
           {{ billReceiveStatusEnum[bill_receive.status] }}
        </h4>
        <img :src="dropdownColors[bill_receive.status]" width="12">
      </div>
      <a v-if="statusIndex != bill_receive.status
        && bill_receive.type === billReceiveTypeEnum.ENTITY_BILL_RECEIVES
        && Number(bill_receive.status) !== 1
        && !(bill_receive.status == 2
        && statusIndex == 0)"
        v-for="(item, statusIndex) in getBillReceiveStatus()"
        class="dropdown-item"
        @click="changeInstallmentStatus(
          bill_receive.installment_id,
          bill_receive.type,
          statusIndex,
          index,
          1)">
        {{ item }}
      </a>
      <a v-if="statusIndex != bill_receive.status &&
        bill_receive.type === billReceiveTypeEnum.CONTRACT_PROPOSAL_PAYMENTS
        && Number(bill_receive.status) !== 1 &&
        !(bill_receive.status == 2
        && statusIndex == 0)"
        v-for="(item, statusIndex) in getBillReceiveStatusWithoutApprove()"
        class="dropdown-item"
        @click="changeInstallmentStatus(
          bill_receive.installment_id,
          bill_receive.type,
          statusIndex,
          index,
          1)">
        {{ item }}
      </a>
      <a v-if="Number(bill_receive.status) === 1 &&
        $hasPermission('bill_receive.reverse_installment')"
        class="dropdown-item"
        @click="changeInstallmentStatus(
          bill_receive.installment_id,
          bill_receive.type,
          10,
          index)">
        Estornar
      </a>
    </base-dropdown>
  </div>
</template>
<script>
const BILL_RECEIVE_STATUS_ENUM = Object.freeze({
  '0': "Pendente",
  '1': "Recebido",
  '2': "Atrasado",
  '3': "Cancelado",
  '4': "Serasa",
  '5': "Cartório",
  '6': "Protestado",
  '7': "Jurídico",
  '8': "Aprovado",
  '9': "Negado",
});

const BILL_RECEIVE_TYPE_ENUM = Object.freeze({
  ENTITY_BILL_RECEIVES: 0,
  CONTRACT_PROPOSAL_PAYMENTS: 1,
});

export default {
  name: "BillReceivePossibleActions",
  props: {
    bill_receive: Object,
    paymentInstallmentStatusEnum: Object,
    changeInstallmentStatus: { type: Function },
    listType: String,
    index: Number
  },
  data(){
    return {
      billReceiveStatusEnum: BILL_RECEIVE_STATUS_ENUM,
      billReceiveTypeEnum: BILL_RECEIVE_TYPE_ENUM,
      BillReceiveStatus: {
        '0': "Pendente",
        '8': 'Aprovar',
        '1': "Receber",
        '3': "Cancelar",
      },
      BillReceiveStatusWithoutApprove: {
        '0': "Pendente",
        '1': "Receber",
        '3': "Cancelar",
        '4': "Serasa",
        '5': "Cartório",
        '6': "Protestado",
        '7': "Jurídico",
      },
      miniCardTypes: {
        '0': "new-minicard-yellow",
        '1': "new-minicard-green",
        '2': "new-minicard-red",
        '3': "new-minicard-red",
        '4': "new-minicard-blue",
        '5': "new-minicard-blue",
        '6': "new-minicard-yellow",
        '7': "new-minicard-black",
        '8': "new-minicard-blue",
      },
      dropdownColors: {
        '0': "/img/icons/expand-arrow--v2_warning.png",
        '1': "/img/icons/expand-arrow--v2_success.png",
        '2': "/img/icons/expand-arrow--v2_danger.png",
        '3': "/img/icons/expand-arrow--v2_danger.png",
        '4': "/img/icons/expand-arrow--v2_primary.png",
        '5': "/img/icons/expand-arrow--v2_primary.png",
        '6': "/img/icons/expand-arrow--v2_warning.png",
        '7': "/img/icons/expand-arrow--v2_default.png",
        '8': "/img/icons/expand-arrow--v2_primary.png",
      },
      statusIcon: {
        '0': "/img/icons/icons8/ios/hourglass_warning.png",
        '1': "/img/icons/icons8/ios/receive-dollar_success.png",
        '2': "/img/icons/icons8/ios/clock-danger.png",
        '3': "/img/icons/icons8/ios/cancel_danger.png",
        '4': "/img/icons/expand-arrow--v2_primary.png",
        '5': "/img/icons/icons8/ios/sheriff_primary.png",
        '6': "/img/icons/icons8/ios/strike_warning.png",
        '7': "/img/icons/icons8/ios/svgs/justice.svg",
        '8': "/img/icons/icons8/ios/thumb-up_primary.png",
        '9': "/img/icons/icons8/ios/thumbs-down_danger.png",
      },
    }
  },
  methods: {
    getBillReceiveStatusWithoutApprove() {
      const status = this.$helper.cloneObject(this.BillReceiveStatusWithoutApprove)
      if (!this.$hasPermission('bill_receive.receive_installment')) {
        // Deletando receber
        delete status['1']
        // Deletando Serasa
        delete status['4']
        // Deletando Cartório
        delete status['5']
        // Deletando Protestado
        delete status['6']
        // Deletando Jurídico
        delete status['7']
      }
      if (!this.$hasPermission('bill_receive.cancel_installment')) {
        // Deletando Cancelar
        delete status['3']
      }
      return status
    },
    getBillReceiveStatus() {
      const status = this.$helper.cloneObject(this.BillReceiveStatus)
      if (!this.$hasPermission('bill_receive.receive_installment')) {
        // Deletando Receber
        delete status['1']
      }
      if (!this.$hasPermission('bill_receive.approve_installment')) {
        // Deletando Aprovar
        delete status['8']
      }
      if (!this.$hasPermission('bill_receive.cancel_installment')) {
        // Deletando Cancelar
        delete status['3']
      }
      return status
    },
  },
};
</script>
<style scoped>
.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
/*  box-shadow: 0 0 5px 0 #149e5752;*/
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #d2272752;*/
}

.new-minicard-yellow {
  background-color: #FEF9F2;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #fdef0b52;*/
}

.new-minicard-blue {
  background-color: #F2F4F9;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #0b7bfd52;*/
}

.new-minicard-gray {
  background-color: #646e7d2e;
  border-radius: 15px;
  padding: 5px 12px;
/*  box-shadow: 0 0 5px 0 #5d636a52;*/
}

.new-minicard-black {
  background-color: #2B2D32;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-yellow h4 {
  color: #f2b532;
}

.new-minicard-black h4 {
  color: white;
}

.new-minicard-blue h4 {
  color: #1b6eba;
}

.new-minicard-gray h4 {
  color: #66696c;
}

.new-minicard-red, .new-minicard-green, .new-minicard-yellow, .new-minicard-blue, .new-minicard-gray, .new-minicard-black {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child, .new-minicard-yellow img:first-child, .new-minicard-blue img:first-child, .new-minicard-gray img:first-child, .new-minicard-black img:first-child {
  width: 16px;
}

.new-minicard-black img:first-child, .new-minicard-black img:last-child {
  filter: brightness(0) invert(1);
}

.new-minicard-table {
  display: block;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
}

.new-minicard-red h4, .new-minicard-green h4, .new-minicard-yellow h4, .new-minicard-blue h4, .new-minicard-gray h4, .new-minicard-black h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}
</style>
