import ServiceListPage from "../views/list/ServiceListPage.vue";

export default [
  {
    path: '/configuration/services',
    name: 'Serviços',
    component: ServiceListPage,
    meta: {
      title: 'Configurações',
      description: 'Serviços',
      icon: 'fa fa-home',
      breadcrumbs: [
        {title: 'Dashboard', name: 'configuration.service.dashboard.index'},
        {title: 'Serviços', name: null},
      ]
    },
  },
];