/**
 * Type para Incluir/Editar/Visualizar permissões operacionais do usuário
 *
 * @typedef {Object} UserOperationalPermissionType
 * @property {number} user_id - ID do usuário.
 * @property {boolean} travel_cancellation - Permite cancelamento de viagem.
 * @property {boolean} service_cancellation - Permite cancelamento de serviço.
 * @property {boolean} cancellation_of_additions - Permite cancelamento de acréscimos.
 * @property {boolean} reuse_approval - Aprovação de reutilização.
 * @property {boolean} stock_details - Permite visualizar detalhes de estoque.
 * @property {boolean} release_of_stock_adjustments - Permite liberação de ajustes de estoque.
 * @property {boolean} can_edit_schedule_service_volume - Pode editar volume de serviço agendado.
 * @property {boolean} can_edit_schedule_travel_finished_at - Pode editar data de finalização da viagem agendada.
 * @property {boolean} can_not_perform_travel_with_number - Não pode realizar viagem com número.
 * @property {boolean} can_manual_schedule_travel_charging - Pode agendar cobrança manual de viagem.
 * @property {boolean} can_clear_schedule_travel_mcc - Pode limpar MCC da viagem agendada.
 * @property {boolean} can_issue_schedule_service_order_in_finished_or_pending_schedule - Pode emitir ordem de serviço em agendamento finalizado ou pendente.
 * @property {boolean} is_user_allowed_to_change_driver_and_equip - Pode alterar motorista e equipamento.
 * @property {boolean} is_user_allowed_justify_travel_without_attachment - Pode justificar viagem sem anexo.
 */
export const UserOperationalPermissionType = {};

/**
 * Inicializar UserOperationalPermissionType
 * 
 * @returns {UserOperationalPermissionType}
 */
export const initUserOperationalPermissionType = () => {
    return {
        user_id: null,
        travel_cancellation: false,
        service_cancellation: false,
        cancellation_of_additions: false,
        reuse_approval: false,
        stock_details: false,
        release_of_stock_adjustments: false,
        can_edit_schedule_service_volume: false,
        can_edit_schedule_travel_finished_at: false,
        can_not_perform_travel_with_number: false,
        can_manual_schedule_travel_charging: false,
        can_clear_schedule_travel_mcc: false,
        can_issue_schedule_service_order_in_finished_or_pending_schedule: false,
        is_user_allowed_to_change_driver_and_equip: false,
        is_user_allowed_justify_travel_without_attachment: false,
    };
};
