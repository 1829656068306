<template>
  <div>
    <AppFixedPageTitle
      title="Nova Programação"
      icon="/img/icons/icons8/ios/svgs/calendar.svg"
    />
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loadingData">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        :contractInfo="contract_proposal"
      >
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            type="light"
            @click.prevent="$router.go(-1)"
            text="Voltar"
            icon="/img/icons/circled-left.png"
            class="invert-color"
            >
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>
    </div>
    <div class="container-fluid">
      <div>
        <!-- Start Card Choose Mixe -->
        <card>
          <!-- Start Schedule -->
          <div>
            <div class="row align-items-center mb-3">
              <div class="col-12">
                <div class="border-header-warning-bottom">
                  <h5 class="h4 p-1 mb-0">Programação</h5>
                </div>
              </div>
            </div>
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-4 mb-1">
                <div class="pb-0 col-form-label form-control-label pb-2 pt-1">
                  Data da concretagem
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                  <!-- Aviso -->
                  <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                    Programação deve ser definida considerando o horário de chegada do primeiro caminhão na obra.
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <i style="font-size: 20px !important" class="fas fa-info-circle"/>
                    </base-button>
                  </el-popover>
                </div>
                <base-input input-group-classes="input-group-sm">
                  <input-date-time-picker :disableDate="new Date()" :spaceMinutes="15"
                                          v-model="schedule.concreting_date" :size="'lg'"/>
                </base-input>
              </div>

              <div class="col-md-4 mb-2">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Centrais
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                  <base-button
                    v-if="schedule.companies_distances.length > 0 && !loadingData"
                    size="sm"
                    type="warning"
                    class="text-uppercase"
                    @click.prevent="handleDistanceTravelPlant"
                  >
                    <i class="fa fa-clock"></i>
                  </base-button>
                  <span v-show="incompleteCycle" class="ml-2"><i
                    class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong style="color: #7e7e7e">Ciclo incompleto!</strong>
                  </span>
                  <span v-show="!incompleteCycle && highTravelDuration" class="ml-2"><i
                    class="fas fa-exclamation-triangle text-danger mr-2"></i>
                    <strong style="color: #7e7e7e">Conferir tempos de trajeto!</strong>
                  </span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="schedule.company_plants_uuid"
                      :items="plants"
                      customKey="uuid"
                      :multiple="true"
                      @input="getDistance"
                      :loading="loadingPlant"
                      :disabled="loadingPlant"/>
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-4">
                <div class="pb-0 col-form-label form-control-label pb-2 pt-1">
                  Data do pedido
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <base-input input-group-classes="input-group-sm">
                  <el-date-picker
                    v-model="schedule.request_date"
                    size="mini"
                    type="datetime"
                    format="dd/MM/yyyy HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    block
                    disabled
                  >
                  </el-date-picker>
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-6">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Endereço da obra
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </div>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      v-model="schedule.address_construction_uuid"
                      :items="addresses"
                      @input="getDistance"
                      customKey="uuid"
                      :labelMask="true"
                      :loading="loadingPlant"
                      :disabled="loadingPlant"
                      label="address$, $number$, $district$, $city$/$state$ - $country$"/>
                  </base-input>
                </validation-provider>
              </div>

              <div class="col-md-3">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Aguardar liberação do cliente
                </div>
                <base-switch
                  v-model="schedule.await_customer_release"
                  type="primary"
                  offText="não"
                  onText="sim"
                  class="primary"
                ></base-switch>
              </div>

              <div class="col-md-3">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Vistoria
                </div>
                <base-switch v-model="schedule.survey" type="primary" offText="não" onText="sim"
                             class="primary"></base-switch>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1" v-if="schedule.await_customer_release">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <div class="pt-1 pb-2 col-form-label form-control-label">
                  Viagens liberadas
                </div>
                <base-input input-group-classes="input-group-sm" group class="mb-2">
                  <input
                    inputmode="numeric"
                    v-on:input="schedule.free_travels = formatString($event.target.value, '0', '999', '')"
                    v-model="schedule.free_travels"
                    type="text"
                    class="form-control form-control-sm"
                  />
                </base-input>
              </div>
            </div>

            <!--Start Contact Construction -->
            <div>
              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pb-0 pt-1 mb-1 col-form-label form-control-label">
                    Responsável pelo Recebimento
                    <span class="text-danger">&nbsp;*</span>
                    <a href="javascript:void(0)" @click.prevent="handleAddResponsible"
                       class="ml-1 btn btn-sm btn-success p-1">Novo</a>
                  </div>
                  <puzl-select
                    v-model="schedule.responsible_receiver_uuid"
                    :items="contacts"
                    customKey="uuid"
                    @input="setContact"
                    :loading="loadingContact"
                    :disabled="loadingContact || loadingPlant"/>
                </div>

                <!-- Start Contact Details -->
                <div class="col-md-6 mb-1 pb-1">
                  <div class="pb-1 pt-1 col-form-label form-control-label">
                    &nbsp;
                  </div>
                  <collapse class="border rounded p-0">
                    <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                      <h5 slot="title" class="mb-0">Contato</h5>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          Nome:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm">{{ schedule.contact.name }}</span>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          Celular:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm"> {{ schedule.contact.cell_phone }}</span>
                        </div>
                      </div>
                      <div class="form-group row m-0 p-0 mb-1">
                        <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                          E-mail:
                        </label>
                        <div class="col-md-8">
                          <span class="form-control form-control-sm">{{ schedule.contact.email }}</span>
                        </div>
                      </div>
                    </collapse-item>
                  </collapse>
                </div>
                <!-- End Contact Details -->
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-12">
                <div class="pb-0 pt-1 col-form-label form-control-label">
                  Orientações para produção
                </div>
                <base-input input-group-classes="input-group-sm">
                  <textarea v-model="schedule.orientations" type="text" rows="3" maxlength="300"
                            class="form-control form-control-sm"> </textarea>
                </base-input>
              </div>
            </div>
          </div>
          <!-- End Schedule -->
        </card>

        <transition name="fade">
          <card v-if="handleValidFields()">
            <!-- Start Formulation -->
            <div>
              <div class="row align-items-center mb-3">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-primary-bottom">
                    <h5 class="h4 p-1 mb-0">Formulação</h5>
                  </div>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <div class="col-md-6">
                  <div class="pt-1 pb-2 col-form-label form-control-label">
                    Peça a concretar
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                    <base-button @click.prevent="handleAddNewPiece()" class="btn-square" size="sm" type="primary" outline><i class="fa-regular fa-square-plus"></i>Adicionar
                    </base-button>
                  </div>
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <puzl-select
                        v-model="schedule.piece_id"
                        :items="concreted_pieces"
                        label="description"
                        @change="resetMixDesign(true)"
                        :loading="loadingPlant"
                        :disabled="loadingPlant"/>
                    </base-input>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <div class="pb-0 pt-1 col-form-label form-control-label">
                    Identificação da peça
                  </div>
                  <base-input input-group-classes="input-group-sm pt-2" group class="mb-2">
                    <input v-model="schedule.piece_identification" type="text" class="form-control form-control-sm"/>
                  </base-input>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3 mb-3">
                <div v-if="!mix_design || !mix_design.uuid" class="checklist-item border-primary p-2">
                  <base-button :disabled="!schedule.piece_id" block type="primary" @click.prevent="addMixe">
                    incluir traço
                  </base-button>
                </div>
                <div class="col-12" v-if="mix_design.uuid">
                  <div class="row">
                    <div class="col-md-4 border-left border-3 border-primary p-1 mt-2">
                      <h5 class="h mb-0">
                        <badge type="primary" class="text-white">
                          TR-{{ mix_design.mix_integration_code_id }}
                        </badge>
                      </h5>
                      <h5 class="h mb-0">
                        {{ mix_design.title }}
                      </h5>
                      <div class="">
                        <span class="p small">
                          {{ mix_design.body }}
                        </span>
                      </div>
                      <div class="mt-n1">
                        <span class="p small">
                          {{ mix_design.footer }}
                        </span>
                      </div>
                      <div class="mt-n1" v-if="mix_design.thecnical_data"><span class="p small" style="font-size: 11px !important;"><span
                        style="font-size: 12px;font-weight: 500;">Dados técnicos:</span> {{ mix_design.thecnical_data }} </span>
                      </div>
                    </div>
                    <div :class="{['border-left border-3 border-success p-1 mt-2'] : bySeller}" class="col-md-7">
                      <div v-if="bySeller">
                        <h5 class="mb-0 font-weight-normal">
                          Preço Vigente | V.{{ mix_design.version }}
                        </h5>
                        <h5 class="mb-0 mt-2">
                          {{ mix_design.value | currency }} m³
                        </h5>
                      </div>
                    </div>

                    <div class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center pt-1">
                      <base-button class="p-1" block size="sm" type="danger" @click="resetMixDesign">
                        <i class="fa fa-trash text-white"></i>
                      </base-button>
                    </div>
                  </div>
                  <div
                    class="mt-2 border-left border-3 border-indigo p-2 "
                    v-if="mix_design.puzl_additions && mix_design.puzl_additions.length"
                    style="padding-left: 4px!important;"
                  >
                    <div v-for="(puzl_addition, index) in mix_design.puzl_additions" :key="puzl_addition.id">
                      <h5 class="text-indigo mb-0">
                        {{ puzl_addition.name }}
                        ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                        <span class="text-indigo" v-if="index != mix_design.puzl_additions.length - 1">+</span>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Início centrais -->
              <div
                class="border-left border-light border-3 mb-3"
                v-for="(companyPlant, index) in schedule.companies_distances"
                :key="companyPlant.uuid"
              >
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                    <h4>{{ companyPlant.label }}</h4>
                  </div>
                </div>
                <!-- Volume -->
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Volume
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="companyPlant.volume = formatString($event.target.value, '0', '999.9', 1)"
                        v-on:blur="volumeCalc($event.target.value, index)"
                        @change="getTravelQuantity(index)"
                        v-model="companyPlant.volume"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                      </template>
                    </base-input>
                  </div>

                  <!-- Max. por viagem -->
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Volume máx. por viagem
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="companyPlant.max_volumes_per_travel = formatString($event.target.value, '0', '12.0', 1)"
                        @change="getTravelQuantity(index)"
                        v-model="companyPlant.max_volumes_per_travel"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                      </template>
                    </base-input>
                  </div>

                  <!-- Número de viagens -->
                  <div class="col-md-4 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Número de viagens
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="companyPlant.travels" inputmode="numeric"
                             class="form-control form-control-sm"/>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <!-- Totais-->
              <div class="border-left border-light border-3">
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-5 pt-1 pb-0 col-form-label form-control-label">
                    <h4>Total</h4>
                  </div>
                </div>
                <!-- Volume -->
                <div class="form-group row m-0 p-0 mb-1">
                  <div class="col-md-6 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Volume
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-model="schedule.total_volume"
                        v-mask="['#.#', '##.#', '###.#', '####.#', '#####.#', '######.#', '#######.#', '########.#']"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                      </template>
                    </base-input>
                  </div>

                  <!-- Número de viagens -->
                  <div class="col-md-6 pr-0">
                    <div class="pt-1 pb-0 col-form-label form-control-label">
                      Número de viagens
                      <span class="text-danger">&nbsp;*&nbsp;</span>
                    </div>
                    <base-input input-group-classes="input-group-sm">
                      <input disabled v-model="schedule.total_travels" inputmode="numeric"
                             class="form-control form-control-sm"/>
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-12 row mt-3 w-100">
                <div class="col-md-5"></div>
                <div v-if="stayTimeGreater" class="col-md-6 pl-5">
                  <p class="p-3 schedule_danger_message_stay_time ml-1 rounded text-center">
                    Atenção, a partir do intervalo entre <br/>
                    descargas informado, haverá mais de um equipamento em obra.
                  </p>
                </div>
                <div class="col-md-1"></div>
              </div>
              <div class="d-flex justify-content-center">
                <div class="border-left border-warning border-3 p-2 mt-2 col-10">
                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-4 pr-0">
                      <div class="pt-1 pb-0 col-form-label form-control-label">
                        Tempo de permanência em obra
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="checkStayTime"
                          v-model="mix_design.formulation_cycle.time_construction_stay"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            minutos
                          </small>
                        </template>
                      </base-input>
                    </div>

                    <div class="col-md-4 pr-0">
                      <div class="pt-1 pb-0 col-form-label form-control-label">
                        Intervalo entre descargas
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="checkStayTime"
                          v-model="mix_design.formulation_cycle.interval_releases"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            minutos
                          </small>
                        </template>
                      </base-input>
                    </div>

                    <div class="col-md-4 pr-0">
                      <div class="pt-1 pb-0 col-form-label form-control-label">
                        Ajuste de intervalo
                      </div>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          @change="checkStayTime"
                          v-model="mix_design.formulation_cycle.interval_adjustment"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0">
                            minutos
                          </small>
                        </template>
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1">
                    <div class="col-md-4 pr-0">
                      <div class="pt-1 pb-0 col-form-label form-control-label">
                        Caminhões necessários
                      </div>
                      <base-input input-group-classes="input-group-sm mt-2">
                        <input v-model="schedule.equipment" type="text" class="form-control form-control-sm" disabled/>
                      </base-input>
                    </div>

                    <div class="col-md-8 pr-0">
                      <div class="pb-0 pt-1 col-form-label form-control-label">
                        Tipo de distribuição
                        <span class="text-danger">&nbsp;*&nbsp;</span>
                      </div>
                      <div class="col-md-12 d-flex justify-content-between p-0">
                        <base-button
                          block
                          size="sm"
                          type="success"
                          outline
                          class="text-capitalize mt-2"
                          :class="schedule.distribution_type === 1 ? 'active' : ''"
                          @click.prevent="changeDistributionType(1)"
                        >
                          Sequencial
                        </base-button>

                        <base-button
                          block
                          size="sm"
                          type="primary"
                          outline
                          class="text-capitalize"
                          :class="schedule.distribution_type === 2 ? 'active' : ''"
                          @click.prevent="changeDistributionType(2)"
                        >
                          Alternada
                        </base-button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-0 p-0 mb-1 mt-2">
                    <div class="col-md-12 pr-0 text-right d-flex justify-content-between">
                      <base-button
                        @click.prevent="handleEditTravelDistribution"
                        type="danger"
                        outline
                        size="sm"
                        style="margin-top: 0.5rem;"
                        block
                        :disabled="!this.schedule.travels || !this.schedule.travels.length > 0"
                      >
                        Editar viagens
                      </base-button>
                      <base-button
                        :disabled="
                          !this.schedule.company_plants_uuid ||
                            !this.schedule.concreting_date ||
                            !this.mix_design.formulation_cycle.time_construction_stay ||
                            !this.schedule.total_volume ||
                            !this.schedule.total_travels
                        "
                        type="danger"
                        outline
                        size="sm"
                        block
                        @click.prevent="handleTravelDistribution"
                      >
                        Gerar distribuição das viagens
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <base-button
                type="success"
                native-type="submit"
                :disabled="!schedule.piece_id || !mix_design.uuid || incompleteCycle"
                :loading="loadingSave"
                @click.prevent="store(0)"
              >Salvar
              </base-button>
            </div>
          </card>
        </transition>

        <transition name="fade">
          <card v-if="handleValidFields()">
            <!-- Start Formulation || -->
            <div>
              <div class="row align-items-center mb-3 pb-1">
                <div class="col-12">
                  <!-- Title -->
                  <div class="border-header-success-bottom">
                    <h5 class="h4 p-1 mb-0">Serviços</h5>
                  </div>
                </div>
              </div>

              <div :class="{['mb-3'] : schedule.services.length}" class="row">
                <div class="col-md-2 mb-2 mb-md-0">
                  <base-button
                    @click.prevent="handleSearchService(schedule.services);changeScheduleServiceType(null)"
                    outline
                    block
                    size="lg"
                    type="success"
                    :class="{['active']: schedule.services.length}"
                  >
                    <i class="fas fa-plus"></i>
                    NOVO
                  </base-button>
                </div>
                <div class="col-md-2 mb-2 mb-md-0">
                  <base-button
                    @click.prevent="changeScheduleServiceType(0)"
                    :class="{['active']: schedule.service_type === 0}"
                    outline
                    block
                    size="lg"
                    type="primary"
                  >
                    <img class="mr-1" :src="`/img/icons/concrete-pump-${schedule.service_type === 0 ? 'white' : 'blue'}.png`" width="21px" height="21px" />
                    BOMBA EXTERNA
                  </base-button>
                </div>
                <div @click.prevent="changeScheduleServiceType(1)" class="col-md-2 mb-2 mb-md-0">
                  <base-button
                    :class="{['active']: schedule.service_type === 1}"
                    outline
                    block
                    size="lg"
                    type="light"
                  >
                    <i style="height: 21px;display: inline-block;margin-bottom: -5px;" class="sidebar-icon-concrete-pump mr-1"></i>
                    CONVENCIONAL
                  </base-button>
                </div>
              </div>

              <div
                class="border-left border-warning border-3 p-2 d-flex justify-content-between mb-4"
                v-for="(service, indexService) in schedule.services"
                :key="indexService"
              >
                <div class="col-11">
                  <div class="form-group row m-0 mb-4">
                    <div class="col-md-4 d-flex align-items-center pr-0">
                      <div class="col-8 p-0">
                        <h4 class="mb-0">{{ service.service.name }}</h4>
                        <h4 class="small mb-0">
                          {{ service.service.description }}
                        </h4>
                      </div>
                      <div class="col-2 mt-1 pl-1 pr-0 pr-md-1">
                        <el-popover v-if="bySeller" trigger="hover" placement="bottom" class="p-0  pr-0">
                          <div :class="getServiceTextColor(service)" class="mt-2 border-left border-3 p-2">
                            <div class="row">
                              <div class="col-12">
                                <h4 class="text-muted">
                                  Preço fixo
                                </h4>
                              </div>
                              <div class="col-6 text-center">
                                <span>Mínimo</span>
                                <h5 type="primary">
                                  {{ service.service.price_fixed | currency() }}
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-if="service.proposal_price_fixed">
                                <span>Venda</span>
                                <h5
                                  :class="getServicePriceTextColor(service.proposal_price_fixed, service.service.price_fixed)">
                                  {{ service.proposal_price_fixed | currency() }}
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-else>
                                <span>Venda</span>
                                <br>
                                -
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h4 class="text-muted">
                                  Preço por <span style="text-transform: none">m</span><sup>3</sup>
                                </h4>
                              </div>
                              <div class="col-6 text-center">
                                <span>Mínimo</span>
                                <h5 type="primary" v-if="service.service.by_meters">
                                  {{ service.service.by_meters | currency() }}
                                </h5>
                                <h5 v-else>
                                  -
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-if="service.proposal_by_meters">
                                <span>Venda</span>
                                <h5
                                  :class="getServicePriceTextColor(service.proposal_by_meters, service.service.by_meters)">
                                  {{ service.proposal_by_meters | currency() }}
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-else>
                                <span>Venda</span>
                                <br>
                                -
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h4 class="text-muted">
                                  Preço por hora
                                </h4>
                              </div>
                              <div class="col-6 text-center">
                                <span>Mínimo</span>
                                <h5 type="primary" v-if="service.service.by_hour">
                                  {{ service.service.by_hour | currency() }}
                                </h5>
                                <h5 v-else>
                                  -
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-if="service.proposal_by_hours">
                                <span>Venda</span>
                                <h5
                                  :class="getServicePriceTextColor(service.proposal_by_hours, service.service.by_hour)">
                                  {{ service.proposal_by_hours | currency() }}
                                </h5>
                              </div>
                              <div class="col-6 text-center" v-else>
                                <span>Venda</span>
                                <br>
                                -
                              </div>
                            </div>
                          </div>
                          <base-button block slot="reference" size="sm" type="success"
                                       class="shadow-none text-success pb-0">
                            <i style="font-size: 18px !important" class="fa fa-dollar-sign text-white mb-0"/>
                          </base-button>
                        </el-popover>
                      </div>
                      <div class="col-2 mt-1 pl-1 pr-0 pr-md-1">
                        <base-button block @click.prevent="removeService(indexService)" size="sm" type="danger">
                          <i class="fas fa-trash text-white"></i>
                        </base-button>
                      </div>
                    </div>
                    <div v-if="schedule.companies_distances && schedule.companies_distances.length"
                         class="col-md-4 pr-0">
                      <div class="pt-1 pb-0 col-form-label form-control-label">
                        Central
                        <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                      </div>
                      <validation-provider rules="required">
                        <base-input input-group-classes="input-group-sm">
                          <el-select
                            v-model="service.company_plant_uuid"
                            class="form-control-sm p-0"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            @change="getServicePayload(service)"
                          >
                            <el-option
                              v-for="plant in schedule.companies_distances"
                              :key="plant.company_plant_uuid"
                              :label="plant.company_plant_name ? plant.company_plant_name : plant.label"
                              :value="plant.company_plant_uuid"
                            >
                            </el-option>
                          </el-select>
                        </base-input>
                      </validation-provider>
                    </div>
                    <div v-if="schedule.companies_distances && schedule.companies_distances.length"
                         class="col-md-4 pr-0">
                      <div>
                        <div class="pt-1 pb-0 col-form-label form-control-label">
                          Cnpj emissor
                          <template v-if="service.company_plant_fixed_issuer_not_found">
                            <el-popover trigger="click"
                                        placement="right"
                            >
                              <h5 class="font-weight-normal m-0">CNPJ Emissor fixo não encontrado para central
                                selecionada.</h5>
                              <base-button outline slot="reference" size="sm" type="secundary"
                                           class="shadow-none text-indigo ml-n2" style="margin-top: -10px">
                                <img src="/img/icons/icons8/ios/error--v1_danger.png" width="18">
                              </base-button>
                            </el-popover>
                          </template>
                          <template v-else>
                            <span class="text-danger align-middle">&nbsp;*&nbsp;</span>
                          </template>                        </div>
                        <validation-provider rules="required">
                          <base-input input-group-classes="input-group-sm">
                            <el-select
                              v-model="service.company_plant_issuer_id"
                              class="form-control-sm p-0"
                              size="mini"
                              placeholder="Selecione"
                              filterable
                              :disabled="loadingPlantIssuer || contract_proposal.issuer_fixed"
                            >
                              <el-option
                                v-for="issuer in service.issuers"
                                :key="issuer.id"
                                :label="issuer.business_name"
                                :value="issuer.id"
                              />
                            </el-select>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div class="form-group row m-0 p-0 mb-1 mt-n3">
                        <div class="col-md-4 pr-0">
                          <div class="pt-1 pb-0 col-form-label form-control-label">
                            Volume previsto
                            <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <base-input input-group-classes="input-group-sm">
                            <input
                              v-on:input="service.volume = formatString($event.target.value, '0', '999.9', '1')"
                              v-model="service.volume"
                              type="text"
                              class="form-control form-control-sm"
                            />
                            <template slot="append">
                              <small class="input-group-sm p-0 m-0"> m<sup>3</sup> </small>
                            </template>
                          </base-input>
                        </div>

                        <div class="col-md-4 pr-0">
                          <div class="pb-0 pt-1 col-form-label form-control-label">
                            Início previsto
                            <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker :size="'lg'" v-model="service.start_at"/>
                          </base-input>
                        </div>

                        <div class="col-md-4 pr-0">
                          <div class="pb-0 pt-1 col-form-label form-control-label">
                            Fim previsto
                            <span class="text-danger">&nbsp;*&nbsp;</span>
                          </div>
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker :size="'lg'" v-model="service.end_at"/>
                          </base-input>
                        </div>
                      </div>
                    </div>

                    <div v-show="!bySeller" class="form-group row m-0 p-0 mb-1">
                      <div class="col-md-6 pr-0">
                        <div class="pb-1 col-form-label form-control-label">
                          Equipamento
                        </div>
                        <validation-provider rules="required">
                          <base-input input-classes="form-control-sm">
                            <el-select
                              v-model="service.equipment"
                              size="mini"
                              placeholder="Selecione"
                              filterable
                              :disabled="loadingEquipment"
                              @change="$forceUpdate()"
                            >
                              <el-option label="Selecione" :value="null"></el-option>
                              <el-option
                                v-if="
                                  !service.service_price ||
                                    !service.service_price.service.equipment_category_uuid ||
                                    service.service_price.service.equipment_category_uuid === equipment.category.uuid
                                "
                                v-for="equipment in service.equipments_reference"
                                :key="equipment.uuid"
                                :label="equipment.code + ' - ' + equipment.plate"
                                :value="equipment.uuid"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </validation-provider>
                      </div>

                      <div class="col-md-6 pr-0">
                        <div class="pb-1 col-form-label form-control-label">
                          Responsáveis
                        </div>
                        <validation-provider>
                          <base-input input-classes="form-control-sm">
                            <el-select
                              v-model="service.employers"
                              size="mini"
                              placeholder="Selecione"
                              filterable
                              multiple
                              :disabled="loadingUser"
                              @change="$forceUpdate()"
                            >
                              <el-option label="Selecione" :value="null"></el-option>
                              <el-option
                                v-for="employer in service.employers_reference"
                                :key="employer.uuid"
                                :label="employer.name"
                                :value="employer.user_uuid"
                              ></el-option>
                            </el-select>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>

                    <!-- Observaçoes -->
                    <div class="form-group row m-0 p-0 mb-1">
                      <div class="col-md-12 pr-0">
                        <div class="pt-1 pb-0 col-form-label form-control-label">
                          <div class="">
                            Observações
                          </div>
                        </div>
                        <validation-provider>
                          <base-input input-classes="form-control-sm">
                            <textarea v-model="service.observations" type="text" rows="2"
                                      class="form-control form-control-sm"> </textarea>
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="schedule.services.length" class="modal-footer">
              <base-button
                type="success"
                native-type="submit"
                @click.prevent="store(0)"
                :loading="loadingSave"
                :disabled="!schedule.piece_id || !mix_design.uuid || incompleteCycle"
              >Salvar
              </base-button>
            </div>
          </card>
        </transition>
      </div>
    </div>
    <ModalAddMixe ref="addMixe" @setMixDesign="getMixDesign"/>
    <ModalAddService ref="addService" @setServices="setServices"/>
    <ModalTravelDistribution @distributionGenerated="setTravels" ref="travelDistribution"
                             @endLoad="loadingSave = false"/>
    <ModalEditTravelDistribution @updatedTravels="updatedTravels" ref="travelEditDistribution"/>
    <ModalDistanceTravelPlant ref="distanceTravelPlant" @setDistances="saveDistances"/>
    <ModalSearchResponsible @setContact="setContact" ref="searchResponsible"/>
    <ModalAddResponsible @setContact="setContact" ref="addResponsible"/>
    <modal-add-new-piece @fetch="getFormulations" ref="ModalAddNewPiece"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import moment from "moment";
import ModalAddMixe from "./_ModalAddMixe";
import ModalAddService from "./_ModalAddService";
import ModalSearchResponsible from "./_ModalSearchResponsible";
import ModalTravelDistribution from "./_ModalTravelDistribution";
import ModalEditTravelDistribution from "./_ModalEditTravelDistribution";
import ModalDistanceTravelPlant from "./_ModalDistanceTravelPlant";
import ModalAddResponsible from "./_ModalAddResponsible";
import {checkNumberValue} from "@/helpers";
import {mask1, formatErrorValidation, formatDate} from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import BackButton from "@/components/Utils/BackButton";
import PuzlSelect from "@/components/PuzlSelect";

import ContractProposalPageHeader from '../../../Commercial/ContractProposal/components/ContractProposalPageHeader/ContractProposalPageHeader.vue'
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../components/AppGlobal";
import ModalAddNewPiece from '../../../Commercial/ContractProposal/Formulation/Shared/_ModalAddNewPiece.vue'

export default {
  name: "CreateSchedule",
  components: {
    ModalTravelDistribution,
    ModalAddMixe,
    ModalAddService,
    ModalDistanceTravelPlant,
    ModalSearchResponsible,
    ModalAddResponsible,
    ModalEditTravelDistribution,
    InputDateTimePicker,
    BackButton,
    PuzlSelect,
    ModalAddNewPiece,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  data() {
    return {
      format_mixe: period_types,
      schedule: {
        company_plants_uuid: [],
        piece_id: null,
        request_date: null,
        concreting_date: null,
        address_construction_uuid: null,
        mix_concreted_piece_uuid: null,
        contact: {},
        orientations: null,
        piece_identification: null,
        companies_distances: [],
        responsible_receiver_uuid: null,
        max_volumes_per_travel: [],
        travels_quantity: [],
        total_volume: null,
        total_travels: null,
        free_travels: 0,
        interval_adjustment: null,
        contract_proposal_uuid: null,
        contract_proposal_formulation_uuid: null,
        distribution_type: 1,
        services: [],
        status: this.bySeller ? 2 : 14,
        travels: [],
        survey: false,
        interval_releases: null,
        await_customer_release: false,
        service_type: null,
      },
      concreted_pieces: [],
      loadingSave: false,
      loadingPlant: true,
      loadingData: true,
      addresses: [],
      loadingEquipment: true,
      loadingPlantIssuer: false,
      loadingUser: true,
      loadingContact: true,
      stayTimeGreater: false,
      contract_proposal: {
        uuid: null,
        construction: {
          uuid: null,
          construction_name: null,
          customer_construction: {
            customer_name: null
          }
        },
        code: null
      },
      mix_design: {
        uuid: null,
        formulation_cycle: {
          time_construction_stay: null,
          interval_releases: null,
          interval_adjustment: null
        }
      },
      contract_proposal_has_services: false,
      schedule_service_types: {
        '0': 'Bomba externa',
        '1': 'Convencional',
      },
    };
  },
  props: {
    bySeller: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    /* Responsável por manipular os horários dos serviços de acordo com a data de concretagem */
    'schedule.concreting_date'(new_value, old_value) {
      if (new_value && old_value && new_value !== old_value) {
        const difference_minutes = this.$helper.dateDiff(old_value, new_value, false) / 60;
        if (difference_minutes !== 0) {
          const method_name = difference_minutes > 0 ? 'add' : 'subtract';
          this.schedule.services.map(function (item) {
            item.start_at = moment(item.start_at)[method_name](Math.abs(difference_minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss');
            item.end_at = moment(item.end_at)[method_name](Math.abs(difference_minutes), 'minutes').format('YYYY-MM-DD HH:mm:ss');
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      contract_proposal_formulations: "formulations/show",
      contacts: "customerConstructionConstructionsContact/fetch",
    }),
    incompleteCycle() {
      return this.schedule.companies_distances.findIndex(item => item.distance < 2 || item.send_duration < 2 || item.return_duration < 2) >= 0
    },
    highTravelDuration() {
      return this.schedule.companies_distances.findIndex(item => item.send_duration > 100 || item.return_duration > 100) >= 0
    }
  },
  methods: {
    handleValidFields() {
      if (
        this.schedule.request_date &&
        this.schedule.contact.name &&
        this.schedule.concreting_date &&
        this.schedule.company_plants_uuid.length &&
        this.schedule.address_construction_uuid.length
      ) {
        return true;
      }
      return false;
    },
    addMixe() {
      this.$refs.addMixe.openModal(this.contract_proposal.uuid, this.schedule.piece_id);
    },
    removeService(index) {
      this.schedule.services.splice(index, 1);
      if(!this.schedule.services.length) {
        this.schedule.service_type = null;
      }
    },
    async handleSearchService(services) {
      if (this.schedule.travels.length === 0) {
        let loader = this.$loading.show();
        let params = await this.generateDistributionData();
        let payload = await this.$refs.travelDistribution.onlyCalcTravels(params, this.schedule.companies_distances, true);
        loader.hide();
        if (payload === false) {
          this.$refs.addService.openModal(this.contract_proposal.uuid, services);
        }
      } else {
        this.$refs.addService.openModal(this.contract_proposal.uuid, services);
      }
    },
    /**
     * Adiciona nova peça a concretar na formulação
     */
    handleAddNewPiece() {
        this.$refs.ModalAddNewPiece.openModal(this.contract_proposal.uuid)
    },
    handleAddResponsible() {
      this.$refs.addResponsible.openModal(this.contract_proposal.construction.uuid);
    },
    generateDistributionData() {
      let company_plants = [];
      let time_clear = this.mix_design.formulation_cycle.time_clear;
      let new_time_mixing = this.mix_design.formulation_cycle.time_mixing;
      let load_truck_time = this.mix_design.formulation_cycle.load_truck_time;
      this.schedule.companies_distances.map(function (item) {
        let data = {
          company_plant_uuid: item.company_plant_uuid,
          volume: item.volume ?? 0,
          max_volume_per_travel: item.max_volumes_per_travel ?? 0,
          travels: item.travels ?? 0,
          send_duration: item.send_duration ?? 0,
          return_duration: item.return_duration ?? 0,
          time_clear: time_clear ?? 0,
          new_time_mixing: new_time_mixing ?? 0,
          load_truck_time: load_truck_time ?? 0
        };
        company_plants.push(data);
      });
      let params = {
        distribution_type: this.schedule.distribution_type,
        time_construction_stay: this.mix_design.formulation_cycle.time_construction_stay,
        unload_interval: this.mix_design.formulation_cycle.interval_releases,
        concreting_date: this.schedule.concreting_date,
        total_travels: this.schedule.total_travels,
        interval_releases: this.mix_design.formulation_cycle.interval_adjustment,
        company_plants: company_plants
      };
      return params;
    },
    updatedTravels(travels) {
      this.schedule.travels = travels;
      var self = this;
      this.schedule.companies_distances.map(function (obj) {
        obj.travels = self.schedule.travels.filter(item => item.send_plant_uuid === obj.company_plant_uuid).length;
      });
      this.schedule.companies_distances.map(function (obj) {
        let volume = 0;
        self.schedule.travels.map(function (travel) {
          if (travel.send_plant_uuid === obj.company_plant_uuid) {
            volume += parseFloat(travel.volume);
          }
        });
        obj.volume = volume;
      });
      this.$forceUpdate();
      this.getVolume(0);
    },
    async handleTravelDistribution() {
      let params = await this.generateDistributionData();
      this.$refs.travelDistribution.openModal(params, this.schedule.companies_distances);
    },
    handleDistanceTravelPlant() {
      this.$refs.distanceTravelPlant.openModal(this.schedule.companies_distances);
    },
    async handleEditTravelDistribution() {
      let params = await this.generateDistributionData();
      this.$refs.travelEditDistribution.openModal(params, this.schedule.companies_distances, this.schedule.travels);
    },
    /** Verifica se google conseguiu achar o endereço */
    checkDistances() {
      let required = ['distance', 'return_duration', 'send_duration'];
      let validated = true
      this.schedule.companies_distances.map(function (obj) {
        for (var prop in obj) {
          if (required.includes(prop) && parseInt(obj[prop]) === 0) {
            validated = false;
            break;
          }
        }
      }, this)
      return validated;
    },
    handleSearchResponsible(uuid) {
      this.$refs.searchResponsible.openModal(uuid);
    },
    changeDistributionType(value) {
      this.schedule.distribution_type = value;
    },
    saveDistances(distances) {
      this.schedule.companies_distances = distances;
    },
    getMixDesign(mix_design) {
      this.mix_design = mix_design;
    },
    volumeCalc(value, index) {
      if (value % 1 != 0) {
        let decimals = value.split(".")[1].trim();
        let num = value.split(".")[0].trim();
        if (decimals > 5) {
          value = num + "." + "5";
        } else if (decimals < 5) {
          value = num + "." + "0";
        }
        this.schedule.companies_distances[index].volume = value;
      }

      this.getVolume(index);
      this.getTravelQuantity(index);
      this.$forceUpdate();
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    getVolume(index) {
      var soma = 0;
      this.schedule.companies_distances.map(function (item) {
        if (item.volume) {
          var value = parseFloat(item.volume);
          soma += value;
        }
      });
      this.schedule.total_volume = soma.toFixed(1);
      this.$forceUpdate();
    },
    /**
     * @description Função realiza a divisão de volumes pela quantidade max de volume por viagem
     * , setando arredondando para cima o valor de viagens no respectivo index.
     * @author {Gustavo Pontes}
     * @copyright Puzl © 2021
     * @version {0.1}
     * @param   {index} obrigatório   index dos volumes
     * @returns {}
     */
    getTravelQuantity(index) {
      this.getVolume(index);
      if (
        this.schedule.companies_distances[index] &&
        this.schedule.companies_distances[index].max_volumes_per_travel &&
        this.schedule.companies_distances[index].max_volumes_per_travel > 0 &&
        this.schedule.companies_distances[index].volume > 0
      ) {
        let value = this.schedule.companies_distances[index].volume / this.schedule.companies_distances[index].max_volumes_per_travel;
        // caso seja decimal, removemos as casas após a virgula e somamos 1 ao valor final.
        if (Number.isInteger(value) === false) {
          value = Math.trunc(value);
          value += 1;
        }
        this.schedule.companies_distances[index].travels = value;
        // calculando total de viagens
        let total_value = 0;
        this.schedule.companies_distances.map(function (item) {
          let travel_value = parseInt(item.travels);
          if (travel_value) {
            total_value += travel_value;
          }
        });
        this.schedule.total_travels = total_value;
        this.$forceUpdate();
      }
    },
    getDistance() {
      this.$Progress.start();
      this.loadingData = true;
      this.loadingPlant = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });

      /* Caso exista um serviço com uma central que não exista mais, limpamos os campos principais */
      this.schedule.services.map(function (item) {
        if (!this.schedule.company_plants_uuid.includes(item.company_plant_uuid)) {
          item.company_plant_uuid = null;
          item.company_plant_issuer_id = null;
        }
      }, this);

      var self = this;
      var allDistances = [];
      this.schedule.total_volume = null;
      this.schedule.total_travels = null;
      this.schedule.travels_quantity = [];
      this.schedule.max_volumes_per_travel = [];
      this.schedule.travels = [];

      self.schedule.company_plants_uuid.map(function (value, key) {
        let params = {
          address_construction_uuid: self.schedule.address_construction_uuid,
          company_uuid: value
        };
        allDistances.push(params);
      });
      self.$store
        .dispatch("customerConstruction/getDistanceByCompany", {
          distances: allDistances
        })
        .then(response => {
          self.schedule.companies_distances = response.data;
          self.schedule.companies_distances.map(function (item, key) {
            item.volume = null;
            item.max_volume_per_travel = null;
            item.travels = null;
            item.new_time_mixing = null;
            item.time_clear = null;
          });
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          self.$Progress.finish();
          if(this.schedule.companies_distances.findIndex(item => item.duration < 1 || item.distance < 1) >= 0) {
            this.handleDistanceTravelPlant();
          }
          this.loadingData = false;
          this.loadingPlant = false;
        });
    },
    setTravels(travels, time, condition) {
      this.schedule.travels = travels;
      if (condition === false) {
        this.store(1);
      }
    },
    setServices(service) {
      this.schedule.services.push(service);
      if (this.schedule.travels.length > 0) {
        var self = this;
        this.schedule.services.map(function (obj) {
          if (!obj.start_at) {
            obj.start_at = formatDate(self.schedule.travels[0].arrival_time);
            obj.end_at = formatDate(self.schedule.travels[self.schedule.travels.length - 1].construction_exit_time);
          }
          if (!obj.volume) {
            obj.volume = self.schedule.total_volume;
          }
          if (!obj.company_plant_uuid && self.schedule.company_plants_uuid.length > 0) {
            obj.company_plant_uuid = self.schedule.company_plants_uuid[0];
            self.getServicePayload(obj);
          }
        });
      }
    },
    async getServicePayload(service) {
      this.loadingEquipment = true;
      this.loadingUser = true;
      service.equipment = null;
      service.company_plant_fixed_issuer_not_found = false;
      service.employers = [];
      const equipment_category_id = await this.getEquipmentCategoryIdByContractProposalServiceUuid(service.uuid);
      this.getCompanyPlantIssuers(service)
      this.$store.dispatch("equipment/getByCompanyPlantAndService", {
            filter : {
              company_plant_uuid : service.company_plant_uuid,
              status : 1, // ativo,
              with_equipment_restriction : service.service.description,
              category_id : equipment_category_id
            }
          }
        ).then(response => {
        this.loadingEquipment = false;
        service.equipments_reference = response.data;
        this.$store.dispatch("user/getByCompanyPlant", {
          company_plant_uuid: service.company_plant_uuid,
          type: 'service_driver'
        }).then(response => {
          service.employers_reference = response.data;
          this.loadingUser = false;
        });
      });
    },
    finalFormat(schedule) {
      schedule.mix_concreted_piece_uuid = this.concreted_pieces.filter(item => item.id === schedule.piece_id)[0].uuid;
      schedule.time_construction_stay = this.mix_design.formulation_cycle.time_construction_stay;
      schedule.interval_releases = this.mix_design.formulation_cycle.interval_releases;
      schedule.interval_adjustment = this.mix_design.formulation_cycle.interval_adjustment;
      schedule.contract_proposal_formulation_uuid = this.mix_design.uuid;
      schedule.company_plants = this.schedule.companies_distances;
      schedule.free_travels = schedule.await_customer_release ? schedule.free_travels : null;
      schedule.service_type = schedule.services.length ? null : schedule.service_type;
      let new_services = [];
      const self = this;
      schedule.services.map(function (obj, key) {
        let start_at = obj.start_at ? moment(obj.start_at).format("YYYY-MM-DD HH:mm:ss") : "";
        let end_at = obj.end_at ? moment(obj.end_at).format("YYYY-MM-DD HH:mm:ss") : "";
        let service = {
          status: self.bySeller ? 5 : 0,
          volume: obj.volume,
          start_at: start_at,
          end_at: end_at,
          service_uuid: obj.uuid,
          equipment_uuid: obj.equipment,
          employers: obj.employers,
          observations: obj.observations,
          company_plant_uuid: obj.company_plant_uuid,
          company_plant_issuer_id: obj.company_plant_issuer_id
        };
        new_services.push(service);
      });
      schedule.services_uuid = new_services;

      schedule.travels.map(function (obj, key) {
        obj.status = self.bySeller ? 9 : 0;
        obj.arrival_time = formatDate(obj.arrival_time);
        obj.construction_exit_time = formatDate(obj.construction_exit_time);
        obj.free_for_travel = formatDate(obj.free_for_travel);
        obj.load_truck_time = formatDate(obj.load_truck_time);
        obj.construction_id = self.contract_proposal.construction.id;
        obj.customer_construction_id = self.contract_proposal.construction.customer_construction.id;
      });
      return schedule;
    },
    async store(forwarded) {
      if (this.checkDistances() === false) {
        this.$notify({
          type: "danger",
          message: 'É necessário preencher todos parâmetros de viagens para Central!'
        });
        this.$refs.distanceTravelPlant.openModal(this.schedule.companies_distances);
        return false;
      }
      if (forwarded === 0) {
        this.$Progress.start();
        this.loadingSave = true;
      }
      if (this.schedule.travels.length === 0) {
        let params = await this.generateDistributionData();
        await this.$refs.travelDistribution.onlyCalcTravels(params, this.schedule.companies_distances);
        return false;
      }
      if (this.schedule.services.length === 0 && this.contract_proposal_has_services === true) {
        let need_service = await this.$Swal.confirmAction('Deseja incluir um serviço para essa programação?', ['Sim', 'Não, continuar']).then((result) => {
          if (result.isConfirmed) {
            return this.handleSearchService(this.schedule.services);
          }
          return false;
        });
        if (need_service !== false) {
          this.loadingSave = false;
          return need_service;
        }
      }
      let schedule = this.finalFormat(this.schedule);
      this.$store
        .dispatch("schedule/add", schedule)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push(this.bySeller ? "/operational/contract-proposal/schedule/" + this.$route.params.contract_proposal_uuid : "/operational/schedule");
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.loadingSave = false;
            this.$Progress.finish();
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loadingSave = false;
          }
        });
    },
    resetMixDesign(openable = false) {
      this.mix_design = {
        uuid: null,
        formulation_cycle: {
          time_construction_stay: null,
          interval_releases: null,
          interval_adjustment: null
        }
      };
      if (openable === true) {
        this.addMixe();
      }
    },
    checkStayTime() {
      let time_stay = this.mix_design.formulation_cycle.time_construction_stay ?? 0;
      let interval_releases = this.mix_design.formulation_cycle.interval_releases ?? 0;
      let interval_adjustment = this.mix_design.formulation_cycle.interval_adjustment ?? 0;
      if (time_stay > interval_releases + interval_adjustment && this.mix_design.formulation_cycle.interval_releases) {
        this.stayTimeGreater = true;
      } else {
        this.stayTimeGreater = false;
      }
    },
    getFormulations() {
      let loader = this.$loading.show()
      this.concreted_pieces = []
      this.$Progress.start();
      this.$store
        .dispatch("formulations/fetchBasic", {
          uuid: this.contract_proposal.uuid
        })
        .then(response => {
          var self = this;
          var pieces_uuid = [];
          // lendo todas as peças de todas formulações da proposta e mandando para nosso array
          response.data.map(function (obj, key) {
            obj.concreted_pieces.map(function (piece, pieceKey) {
              if (!pieces_uuid.includes(piece.uuid)) {
                self.concreted_pieces.push(piece);
                pieces_uuid.push(piece.uuid);
              }
            });
          });
          loader.hide()
          this.$Progress.finish();
        });
    },
    /**
     * @description Função irá puxar os dados proposta contrato, cliente/obra, além de trazer
     * o endereço padrão da obra e formatar todas as peças à concretar desta proposta.
     * @author {Gustavo Pontes}
     * @copyright Puzl © 2021
     * @version {0.1}
     * @param   {contract_proposal_uuid} obrigatório   Parâmetro recebido pelo vue route
     * @returns {}
     */
    getContractProposal() {
      this.$Progress.start();
      this.loadingData = true;
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(response => {
        // montando objeto de proposta contrato
        this.schedule.contract_proposal_uuid = response.data.uuid;
        this.contract_proposal.uuid = response.data.uuid;
        this.contract_proposal.issuer_fixed = response.data.issuer_fixed;
        this.contract_proposal.company_plant_issuer_id_fixed = response.data.company_plant_issuer_id_fixed;
        if (response.data.plant && response.data.plant.uuid) {
          this.contract_proposal.company_plant_uuid = response.data.plant.uuid;
        }
        this.contract_proposal.construction.id = response.data.construction.id;
        this.contract_proposal.construction.construction_name = response.data.construction.construction_name;
        this.contract_proposal.construction.customer_construction.customer_name =
          response.data.construction.customer_construction.customer_name;
        this.contract_proposal.construction.customer_construction.id = response.data.construction.customer_construction.id;
        this.contract_proposal.code = response.data.code;
        this.contract_proposal.construction.uuid = response.data.construction.uuid;
        this.schedule.request_date = moment().format("YYYY-MM-DD HH:mm:ss");
        // buscando cliente obra de acordo com com contrato.
        this.$store.dispatch("customerConstructionConstructions/show", response.data.construction.uuid).then(response => {
          this.addresses = response.data.addresses;
          // atribuindo nosso endereço selecionado para oque vier como padrão na requisição.
          let address = this.addresses.filter(item => item.default === true)[0] || this.addresses[0]
          this.schedule.address_construction_uuid = address.uuid;
          this.fillWithFirstCompanyPlant();
          this.$store
            .dispatch("formulations/fetchBasic", {
              uuid: this.contract_proposal.uuid
            })
            .then(response => {
              var self = this;
              var pieces_uuid = [];
              // lendo todas as peças de todas formulações da proposta e mandando para nosso array
              response.data.map(function (obj, key) {
                obj.concreted_pieces.map(function (piece, pieceKey) {
                  if (!pieces_uuid.includes(piece.uuid)) {
                    self.concreted_pieces.push(piece);
                    pieces_uuid.push(piece.uuid);
                  }
                });
              });
              this.loadingData = false;
              this.loadingPlant = false;
              this.$Progress.finish();
              this.$notify({
                type: response.error_type,
                message: response.message
              });
              this.$store
                .dispatch("customerConstructionConstructionsContact/getByConstruction", {
                  uuid: this.contract_proposal.construction.uuid,
                  filter: false,
                  is_active_contacts: true,
                })
                .then(response => {
                  this.loadingContact = false;
                });
            });
        });
      });
      this.$store.dispatch("contractService/fetchItems", {uuid : this.$route.params.contract_proposal_uuid, filter: { status:1}}).then(response => {
        this.contract_proposal_has_services = response.data && response.data.length > 0
      });
    },
    setContact(contact = false) {
      if (contact && contact.uuid) {
        this.schedule.responsible_receiver_uuid = contact.uuid
        this.schedule.contact = contact;
        return;
      }
      this.schedule.contact = this.contacts.filter(item => item.uuid === this.schedule.responsible_receiver_uuid, this)[0];
    },
    getCompanyPlantIssuers(service) {
      this.loadingPlantIssuer = true;
      service.company_plant_issuer_id = null;
      let id = this.plants.filter(item => item.uuid === service.company_plant_uuid)[0].id
      this.$store
        .dispatch(
          "plant/fetchCompanyPlantIssuersByPlant",
          {id: id}
        ).then(response => {
        service.issuers = response.data
        if (response.data.length) {
          let default_plant_issuer = response.data.find((item) => item.default === 1) || response.data[0]
          /**
           * verifica se possui CNPJ Emissor fixo
           */
          if (this.contract_proposal.issuer_fixed) {
            let fixed_issuer_id = this.contract_proposal.company_plant_issuer_id_fixed
            /**
             * verifica se o CNPJ Emissor fixo pertence a central selecionada
             */
            let has_issuer = response.data.find((item) => item.id === fixed_issuer_id)

            if (has_issuer) {
              service.company_plant_issuer_id = fixed_issuer_id
              service.company_plant_fixed_issuer_not_found = false
            } else {
              service.company_plant_fixed_issuer_not_found = true
              service.company_plant_issuer_id = null
            }
          } else {
            if (default_plant_issuer && !service.company_plant_issuer_id) {
              service.company_plant_issuer_id = default_plant_issuer.id
            }
          }
        }
        this.loadingPlantIssuer = false;
      });
    },
    async generateTravels() {
      let params = await this.generateDistributionData();
      this.$store.dispatch("scheduleTravelDistribution/fetchItems", params).then(response => {
        return response.data;
      });
    },

    /**
     * Compara valores de preços de serviços e retorna cor adequada
     *
     * @param {float} a
     * @param {float} b
     * @return {string}
     */
    getServicePriceTextColor(a, b) {
      switch (true) {
        case Number(a) >= Number(b):
          return 'text-success'
        case Number(a) < Number(b):
          return 'text-danger';
        default :
          return 'text-light';
      }
    },

    /**
     * Verifica valores de serviço e retorna cor adequada
     *
     * @param {object} service
     * @return {string}
     */
    getServiceTextColor(service) {
      switch (true) {
        case service.proposal_price_fixed && service.proposal_price_fixed < service.service.price_fixed:
        case service.proposal_by_hours && service.proposal_by_hours < service.service.by_hour :
        case service.proposal_by_meters && service.proposal_by_meters < service.service.by_meters:
          return 'border-danger'
        default :
          return 'border-success';
      }
    },

    /**
     * Preenchimento automático de central para programação, caso haja somente uma central.
     *
     * @return {void}
     */
    fillWithFirstCompanyPlant() {
      if (this.plants.length === 1 && this.schedule.address_construction_uuid) {
        this.schedule.company_plants_uuid = [this.plants[0].uuid];
        this.getDistance();
      }
      /* Preenchimento com central de contrato */
      else if (this.contract_proposal.company_plant_uuid) {
        this.schedule.company_plants_uuid = [this.contract_proposal.company_plant_uuid];
        this.getDistance();
      }
    },

    /**
     * Altera tipo de serviço da programação
     *
     * @param {integer|null} type
     */
    changeScheduleServiceType(type) {
      if(['Bomba externa', 'Convencional'].includes(this.schedule_service_types[type])) {
        this.schedule.services = [];
      }

      this.schedule.service_type = type;
    },
    async getEquipmentCategoryIdByContractProposalServiceUuid(contract_proposal_service_uuid) {
      const response = await this.$store.dispatch("contractService/getEquipmentCategoryIdByContractProposalServiceUuid", contract_proposal_service_uuid);
      return response.data;
    },
  },
  mounted() {
    this.$store.dispatch("plant/fetchItemsActive");
    this.getContractProposal();
  }
};
</script>

<style>
body {
  overflow-y: scroll !important;
}

#datetime-schedule-input {
  max-height: 30px !important;
}

.el-date-range-picker .el-picker-panel__footer {
  display: inherit !important;
  font-size: 13px;
}

.el-date-range-picker .el-picker-panel__footer .el-picker-panel__link-btn {
  color: #fff;
  background-color: #149e57;
  margin-right: 3px !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 10px !important;
}

.el-date-range-picker .el-picker-panel__footer .el-button--text {
  display: none !important;
}

.el-picker-panel__footer {
  display: none !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
