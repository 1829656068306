<template>
  <div>
    <modal class="modal-general-resume" :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">{{ title }}</h5>
      </template>
      <div v-if="$_resume">
        <div class="row m-0 mb-3">
          <div class="col-6">
            <base-button
              @click.prevent="type = 'performed'"
              type="success"
              outline
              size="sm"
              block
              :class="{ active: type === 'performed' }"
            >
              REALIZADO
            </base-button>
          </div>
          <div class="col-6">
            <base-button @click.prevent="type = 'expected'" type="primary" outline size="sm" block
                         :class="{ active: type === 'expected' }">
              PREVISTO
            </base-button>
          </div>
        </div>
        <div class="ml-3 mr-3 mb-2">
          <h3 class="mb-0 font-weight-normal">
            Obra:
            <b>{{ $_contract_proposal.construction.construction_name }} </b>
          </h3>
          <h3 class="font-weight-normal mb-0">
            Cliente:
            <b>{{ $_contract_proposal.construction.customer_construction.customer_name }}</b>
          </h3>
          <h3 class="font-weight-normal mb-0">
            Cód. Contrato:
            <b>{{ $_contract_proposal.code }}</b>
          </h3>
          <h4>
            <small>
              {{ $_contract_proposal.construction.default_address.address }},
              {{ $_contract_proposal.construction.default_address.number }}<span
              v-if="$_contract_proposal.construction.default_address.complement">, {{
                $_contract_proposal.construction.default_address.complement
              }}</span> - {{ $_contract_proposal.construction.default_address.district }}
            </small>
          </h4>
          <h4 class="mt-n2">
            <small>
              {{ $_contract_proposal.construction.default_address.city }} /
              {{ $_contract_proposal.construction.default_address.state }}
            </small>
          </h4>
        </div>

        <!-- Realizado -->
        <div v-if="type === 'performed'">
          <div class="pl-3 pr-3 pt-0 pb-3">
            <!-- Total -->
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Total</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.performed_value | currency() }} </span>
              </div>
            </div>
            <div class="row" v-if="billing.has_deduction && billing.retained_rate_flag">
              <div class="col-6" >
                <h3 class="mb-0">Total líquído</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.performed_value - billing.iss_retatin | currency() }} </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h4 class="mb-0">{{ $_resume.schedule.concreting_date | parseDate }}</h4>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h4 mb-0 text-success numeric">
                  {{ $_resume.schedule.volume_performed }} <span style="text-transform: none">m³</span> ({{ $_resume.schedule.travel_performed }})
                </span>
              </div>
            </div>
            <div v-if="billing.has_deduction && billing.retained_rate_flag">
              <hr class="mt-3 mb-3"/>
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0 text-danger">Retenção de ISSQN</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric text-danger">{{ billing.iss_retain | currency() }} </span>
                </div>
                <div class="col-6">
                  <h3><small>Dedução</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <h3><small class="numeric font-weight-600">{{ billing.deduct }} %</small></h3>
                </div>
                <div class="col-6 mt-n2">
                  <h3><small>Base de cálculo</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end mt-n2">
                  <h3><small class="numeric font-weight-600">{{ billing.base_deduct }} %</small></h3>
                </div>
                <div class="col-6 mt-n2">
                  <h3><small>Aliquota</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end mt-n2">
                  <h3><small class="numeric font-weight-600">{{ billing.aliquota }} %</small></h3>
                </div>
              </div>
            </div>
            <div>
              <hr class="mt-3 mb-3"/>
              <!-- Concreto -->
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0 text-primary">Concreto</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.formulation.performed_value | currency() }} </span>
                </div>
              </div>
            </div>

            <!-- Volume cancelado -->
            <div class="row mb-1" v-if="$_resume.formulation.cancelled_volume > 0">
              <div class="col-6">
                <h4 class="mb-0 text-danger">Volume cancelado</h4>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-normal h4 mb-0 numeric">{{ $_resume.formulation.cancelled_volume }} <span  style="text-transform: none">m³</span> </span>
              </div>
            </div>

            <!-- Resumo concreto -->
            <div>
              <div class="p-3">
                <div class="border-left border-3 border-primary p-2 row">
                  <h5 class="mb-0 col-7 pl-0">
                    {{ $_resume.formulation.title }}
                  </h5>
                  <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                    <h4 class="mb-0 numeric">
                      {{ $_resume.formulation.total_formulation_billing | currency() }}
                    </h4>
                  </div>
                  <span class="p small col-8 pl-0">
                    {{ $_resume.formulation.body }}
                  </span>
                  <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                    <h4 class="mb-0 font-weight-normal numeric">{{ $_resume.formulation.value | currency }} /<span class="font-weight-normal text-lowercase">m³</span></h4>
                  </div>
                </div>
                <div class="p-2 row mt-2" v-if="$_resume.formulation.puzl_additions.length">
                  <div class="col-md-12 pl-2">
                    <div class="border-left border-3 border-indigo p-2 row">
                      <div v-for="(puzl_addition, index) in $_resume.formulation.puzl_additions"
                           :key="puzl_addition.id">
                        <h5 class="text-indigo mb-0">
                          {{ puzl_addition.name }}
                          ({{ puzl_addition.volume }} <span class="text-lowercase">kg/m<sup>3</sup></span>)
                          <span class="text-indigo"
                                v-if="index != $_resume.formulation.puzl_additions.length - 1">+</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resumo serviços -->
            <div v-if="$_resume.performed_service_value > 0">
              <hr class="mt-3 mb-3"/>
              <!-- Serviços -->
              <div class="row mt-3">
                <div class="col-6">
                  <h3 class="mb-0 text-info">Serviços</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.performed_service_value | currency() }} </span>
                </div>
              </div>
              <!-- Fixo -->
              <div class="row" v-if="$_resume.performed_minimum_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Fixo</h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.performed_minimum_service_quantity
                    }}</span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{
                      $_resume.performed_minimum_service_value | currency()
                    }} </span>
                </div>
              </div>
              <!-- Por m³ -->
              <div class="row" v-if="$_resume.performed_m3_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Por <span class="font-weight-normal text-lowercase">m³</span></h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.performed_m3_service_quantity
                    }} <span class="font-weight-normal text-lowercase">m³</span></span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{
                      $_resume.performed_m3_service_value | currency()
                    }} </span>
                </div>
              </div>
              <!-- Por hora -->
              <div class="row mb-2" v-if="$_resume.performed_hour_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Por hora</h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.performed_hour_service_quantity
                    }}</span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{
                      $_resume.performed_hour_service_value | currency()
                    }}</span>
                </div>
              </div>
              <div v-for="(service, index_service) in $_resume.services" :key="index_service">
                <div class="pl-3 pr-3 pt-3 pb-1">
                  <div class="border-left border-3 border-info p-2 row">
                    <h4 class="mb-0 col-7 pl-0">
                      {{ service.service_name }}
                    </h4>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0 numeric">
                        {{ service.performed_value | currency() }}
                      </h4>
                    </div>
                    <h5 class="mb-0 font-weight-normal" v-if="service.service_description">
                      {{ service.service_description }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resumo adicionais -->
            <div v-if="$_resume.additionals.length">
              <hr class="mt-3 mb-3"/>
              <!-- Adicionais -->
              <div class="row mt-3">
                <div class="col-6">
                  <h3 class="mb-0 custom-text-orange">Adicionais</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.performed_additional_cost | currency() }} </span>
                </div>
              </div>
            </div>
            <div v-for="(additional, index_additional) in $_resume.additionals" :key="index_additional">
              <div class="pl-3 pr-3 pt-3 pb-1">
                <div class="border-orange border-3 border-orange p-2 row">
                  <h4 class="mb-0 col-8 pl-0">
                    {{ additional.short_description }}
                  </h4>
                  <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                    <h4 class="mb-0 numeric font-weight-normal">
                      {{ additional.total_additional_cost | currency() }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <base-button type="secondary" @click="closeModal()">Fechar</base-button>
          </div>
        </div>
        <!-- Previsto -->
        <div v-else>
          <div class="pl-3 pr-3 pt-0 pb-3">
            <!-- Total -->
            <div class="row">
              <div class="col-6">
                <h3 class="mb-0">Total</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.expected_value | currency() }} </span>
              </div>
            </div>
            <div class="row" v-if="billing.has_deduction && billing.retained_rate_flag">
              <div class="col-6" >
                <h3 class="mb-0">Total líquído</h3>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.performed_value - billing.predicted_iss_retain | currency() }} </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h4 class="mb-0">{{ $_resume.schedule.concreting_date | parseDate }}</h4>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h4 mb-0 text-success numeric">
                  {{ $_resume.formulation.expected_volume }}<span class="font-weight-normal text-lowercase"> m³</span> ({{ $_resume.formulation.expected_quantity }})
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <h4 class="mb-0">Retira de terceiros</h4>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <span class="font-weight-bold h4 mb-0 numeric">
                 {{ $_resume.formulation.concrete_removal ? "Sim" : "Não" }}
                </span>
              </div>
            </div>
            <div v-if="billing.has_deduction && billing.retained_rate_flag">
              <hr class="mt-3 mb-3"/>
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0 text-danger">Retenção de ISSQN</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric text-danger">{{ billing.predicted_iss_retain | currency() }} </span>
                </div>
                <div class="col-6">
                  <h3><small>Dedução</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <h3><small class="numeric font-weight-600">{{ billing.deduct }} %</small></h3>
                </div>
                <div class="col-6 mt-n2">
                  <h3><small>Base de cálculo</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end mt-n2">
                  <h3><small class="numeric font-weight-600">{{ billing.base_deduct }} %</small></h3>
                </div>
                <div class="col-6 mt-n2">
                  <h3><small>Aliquota</small></h3>
                </div>
                <div class="col-6 d-flex justify-content-end mt-n2">
                  <h3><small class="numeric font-weight-600">{{ billing.aliquota }} %</small></h3>
                </div>
              </div>
            </div>
            <div>
              <hr class="mt-3 mb-3"/>
              <!-- Concreto -->
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0 text-primary">Concreto</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.formulation.expected_value | currency() }} </span>
                </div>
              </div>
            </div>

            <!-- Resumo concreto -->
            <div>
              <div class="p-3">
                <div class="border-left border-3 border-primary p-2 row">
                  <h5 class="mb-0 col-7 pl-0">
                    {{ $_resume.formulation.title }}
                  </h5>
                  <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                    <h4 class="mb-0 numeric">
                      {{ $_resume.formulation.total_formulation_billing | currency() }}
                    </h4>
                  </div>
                  <span class="p small col-8 pl-0">
                    {{ $_resume.formulation.body }}
                  </span>
                  <div class="col-4 pl-0 pr-0 d-flex justify-content-end">
                    <h4 class="mb-0 font-weight-normal numeric">{{ $_resume.formulation.value | currency }} / <span class="font-weight-normal text-lowercase">m³</span></h4>
                  </div>
                </div>
                <div class="p-2 row mt-2" v-if="$_resume.formulation.puzl_additions.length">
                  <div class="col-md-12 pl-2">
                    <div class="border-left border-3 border-indigo p-2 row">
                      <div v-for="(puzl_addition, index) in $_resume.formulation.puzl_additions"
                           :key="puzl_addition.id">
                        <h5 class="text-indigo mb-0">
                          {{ puzl_addition.name }}
                          ({{ puzl_addition.volume }} <span class="font-weight-normal text-lowercase">kg/m<sup>3</sup></span>)
                          <span class="text-indigo"
                                v-if="index != $_resume.formulation.puzl_additions.length - 1">+</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resumo serviços -->
            <div v-if="$_resume.expected_service_value > 0">
              <hr class="mt-3 mb-3"/>
              <!-- Serviços -->
              <div class="row mt-3">
                <div class="col-6">
                  <h3 class="mb-0 text-info">Serviços</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.expected_service_value | currency() }} </span>
                </div>
              </div>
              <!-- Fixo -->
              <div class="row" v-if="$_resume.expected_minimum_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Fixo</h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.expected_minimum_service_quantity
                    }}</span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{
                      $_resume.expected_minimum_service_value | currency()
                    }} </span>
                </div>
              </div>
              <!-- Por m³ -->
              <div class="row" v-if="$_resume.expected_m3_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Por <span class="font-weight-normal text-lowercase">m³</span></h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.expected_m3_service_quantity
                    }} <span class="font-weight-normal text-lowercase">m³</span></span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{ $_resume.expected_m3_service_value | currency() }} </span>
                </div>
              </div>
              <!-- Por hora -->
              <div class="row mb-2" v-if="$_resume.expected_hour_service_value > 0">
                <div class="col-3">
                  <h4 class="mb-0">Por hora</h4>
                </div>
                <div class="col-3">
                  <span class="font-weight-normal h4 mb-0 pr-3 ml-n4 numeric">{{
                      $_resume.expected_hour_service_quantity
                    }}</span>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-normal h4 mb-0 numeric">{{
                      $_resume.expected_hour_service_value | currency()
                    }}</span>
                </div>
              </div>
              <div v-for="(service, index_service) in $_resume.services" :key="index_service">
                <div class="pl-3 pr-3 pt-3 pb-1">
                  <div class="border-left border-3 border-info p-2 row">
                    <h4 class="mb-0 col-7 pl-0">
                      {{ service.service_name }}
                    </h4>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0 numeric">
                        {{ service.expected_value | currency() }}
                      </h4>
                    </div>
                    <h5 class="mb-0 font-weight-normal" v-if="service.service_description">
                      {{ service.service_description }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <!-- Resumo adicionais -->
            <div v-if="$_resume.expected_missing_volume_additional_value > 0">
              <hr class="mt-3 mb-3"/>
              <!-- Adicionais -->
              <div class="row mt-3">
                <div class="col-6">
                  <h3 class="mb-0 custom-text-orange">Adicionais</h3>
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <span class="font-weight-bold h3 mb-0 numeric">{{ $_resume.expected_missing_volume_additional_value | currency() }} </span>
                </div>
              </div>
                <div class="pl-3 pr-3 pt-3 pb-1">
                  <div class="border-orange border-3 p-2 row">
                    <h4 class="mb-0 col-7 pl-0">
                      M3 Faltante
                    </h4>
                    <div class="col-5 pl-0 pr-0 d-flex justify-content-end">
                      <h4 class="mb-0 numeric">
                        {{ $_resume.expected_missing_volume_additional_value | currency() }}
                      </h4>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <div class="w-100 row">
              <div class="col-md-6">
                <base-button block type="secondary" @click="closeModal()">FECHAR</base-button>
              </div>
              <div class="col-md-6">
                <base-button block type="indigo" @click="handleCreateEntityModal()">
                  <i class="fas fa-dollar-sign"/>
                  ANTECIPAÇÃO
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <ModalCreateEntity ref="modalCreateEntity" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ModalCreateEntity from '../../../../Financial/BillReceive/Entity/Shared/Modals/_ModalCreateEntity'

export default {
  components: { ModalCreateEntity },
  name: "ModalResume",
  data() {
    return {
      modal: false,
      title: "Resumo",
      type: "performed",
      entity_document_is_cnpj: 0,
      billing: {
        has_deduction: false,
        retained_rate_flag: 0,
        company_plant_issuer: {},
      }
    };
  },
  computed: {
    ...mapGetters({
      $_resume: "scheduleResume/scheduleResume",
      $_contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    async openModal(id) {
      let loader = this.$loading.show();
      await this.$store.dispatch("scheduleResume/getBySchedule", id).then(async (response) => {
        this.type = this.$_resume.performed_value > 0 ? "performed" : "expected";
        this.$notify({
          type: response.error_type,
          message: response.message,
        });
        loader.hide();
        /**
         * Valida se foi realizado, se sim busca o ISS
         */
        if (this.type === 'performed') {
          this.entity_document_is_cnpj = this.$helper.onlyNumbers(this.$_contract_proposal.entity.document).length > 11;
          const plant_issuers = await this.$store.dispatch('plant/fetchCompanyPlantIssuersByPlant', {
            id: this.$_contract_proposal.company_plant_id
          }).then((response) => response.data)
          this.billing = {
            retained_rate_flag: 0,
            company_plant_issuer: plant_issuers.find(item => item.default),
            total: this.$_resume.performed_value,
            has_deduction: false,
          }
          if (this.billing.company_plant_issuer.id) {
            let params = {
              entity_id: this.$_contract_proposal.entity.id,
              commercial_tax_name: 'ISSQN',
              construction_city: this.$_contract_proposal.construction.default_address.city,
              issuers: [
                {
                  id: this.billing.company_plant_issuer.id,
                  tax_regime: this.billing.company_plant_issuer.tax_regime,
                }
              ]
            }
            await this.$store.dispatch("FeesAndTaxes/getActiveRetainedRatesBetweenEntityAndIssuerIds", params).then(response => {
              this.billing.not_found = false
              const has_issqn = response.data.commercial_taxes.find((a) => a.company_plant_issuer_id === this.billing.company_plant_issuer.id)
              if (has_issqn) {
                this.billing.retained_rate_flag = !!has_issqn
              }
              const deduction = response.data.deduction_rules.find((a) => a.regime == this.billing.company_plant_issuer.tax_regime)
              if (!deduction) {
                this.billing.has_deduction = false
                this.dont_allow_save = true
                this.billing.tax_not_found = true
              }
              else {
                this.billing.has_deduction = true
                this.billing.deduct = deduction.deduct
                this.billing.base_deduct = deduction.base
                this.billing.aliquota = deduction.retained_rate
              }
              const issuerConstructionCitiesEqual = this.billing.company_plant_issuer.city === this.$_contract_proposal.construction.default_address.city;
              const issuerEntityCitiesEqual = this.billing.company_plant_issuer.city === this.$_contract_proposal.entity.address.city;
              if (issuerConstructionCitiesEqual) {
                if (!this.billing.company_plant_issuer.nfse_rule1_equal_construction_default) {
                  if (issuerEntityCitiesEqual) {
                    this.billing.retained_rate_flag = this.entity_document_is_cnpj
                      ? this.billing.company_plant_issuer.nfse_rule1_within_city_iss_legal
                      : this.billing.company_plant_issuer.nfse_rule1_within_city_iss_individual;
                  } else {
                    this.billing.retained_rate_flag = this.entity_document_is_cnpj
                      ? this.billing.company_plant_issuer.nfse_rule1_outside_city_iss_legal
                      : this.billing.company_plant_issuer.nfse_rule1_outside_city_iss_individual;
                  }
                }
              } else {
                if (!this.billing.company_plant_issuer.nfse_rule2_diff_construction_default) {
                  if (issuerEntityCitiesEqual) {
                    this.billing.retained_rate_flag = this.entity_document_is_cnpj
                      ? this.billing.company_plant_issuer.nfse_rule2_within_city_iss_legal
                      : this.billing.company_plant_issuer.nfse_rule2_within_city_iss_individual;
                  } else {
                    this.billing.retained_rate_flag = this.entity_document_is_cnpj
                      ? this.billing.company_plant_issuer.nfse_rule2_outside_city_iss_legal
                      : this.billing.company_plant_issuer.nfse_rule2_outside_city_iss_individual;
                  }
                }
              }
              this.billing.retained_rate_flag = !!this.billing.retained_rate_flag;
              this.billing.iss_retain = 0
              if (deduction) {
                const deduct = this.billing.total * Number(deduction.deduct) / 100;
                const base = this.billing.total - deduct;
                const deduct_predicted = this.$_resume.expected_value * Number(deduction.deduct) / 100;
                const base_predicted = this.$_resume.expected_value - deduct_predicted;
                this.billing.deduction = deduct
                this.billing.base = base
                this.billing.iss_retain = base * Number(deduction.retained_rate) / 100;
                this.billing.predicted_iss_retain = base_predicted * Number(deduction.retained_rate) / 100;
              }
            })
          }
        }
        this.modal = true;
      });
    },
    handleCreateEntityModal() {
      this.$refs.modalCreateEntity.handleCreateModal(
        this.$_contract_proposal.entity,
        this.$_contract_proposal.company_plant_id,
        Number(this.$_resume.expected_value).toFixed(2),
        this.$_contract_proposal.id,
      )
    },
  },
};
</script>

<style>
.modal-general-resume {
  overflow-y: scroll !important;
}

.custom-text-orange {
  color: #f77455 !important;
}

.border-header-orange-bottom .card-header {
  border-bottom: 1px solid #f77455;
  background-color: #f8f9fe !important;
}

.border-orange {
  border-left: 1px solid #f77455 !important;
}
</style>
