/**
 * Type para Incluir/Editar/Visualizar permissões de limite financeiro do usuário
 *
 * @typedef {Object} UserFinancialLimitPermissionType
 * @property {number|null} user_id - ID do usuário.
 * @property {boolean} is_limit_general_values - Indica se há limite geral de valores.
 * @property {number|null} general_discounts_fees - Taxas de desconto geral.
 * @property {number|null} fees - Taxas gerais.
 * @property {number|null} general_maximum_default - Padrão máximo geral.
 * @property {boolean} disable_max_extension_due_date - Indica se a extensão máxima do vencimento está desativada.
 * @property {number|null} general_extension_due_date - Extensão geral da data de vencimento.
 * @property {boolean} is_limit_approval_values - Indica se há limite para valores de aprovação.
 * @property {number|null} approval_per_day - Aprovações por dia.
 * @property {boolean} is_limit_receipt_values - Indica se há limite para valores de recebimento.
 * @property {number|null} receivement_per_day - Recebimentos por dia.
 * @property {boolean} is_limit_cancellation_values - Indica se há limite para valores de cancelamento.
 * @property {number|null} cancellation_per_day - Cancelamentos por dia.
 * @property {boolean} is_limit_reversal_of_receivable_values - Indica se há limite para estornos de recebíveis.
 * @property {number|null} reversal_of_receivable_per_day - Estornos de recebíveis por dia.
 * @property {boolean} bill_pay_is_limit_general_values - Indica se há limite geral de valores para pagamentos.
 * @property {number|null} bill_pay_general_discounts_fees - Taxas de desconto geral para pagamentos.
 * @property {number|null} bill_pay_fees - Taxas gerais para pagamentos.
 * @property {number|null} bill_pay_general_maximum_default - Padrão máximo geral para pagamentos.
 * @property {boolean} bill_pay_is_limit_amounts_change_due_date - Indica se há limite para alteração da data de vencimento de pagamentos.
 * @property {number|null} bill_pay_general_extension_due_date - Extensão geral da data de vencimento de pagamentos.
 * @property {boolean} bill_pay_is_limit_approval_values - Indica se há limite para valores de aprovação de pagamentos.
 * @property {number|null} bill_pay_approval_per_day - Aprovações de pagamento por dia.
 * @property {boolean} is_limit_payment_values - Indica se há limite para valores de pagamento.
 * @property {number|null} payment_per_day - Pagamentos por dia.
 * @property {boolean} bill_pay_is_limit_cancellation_values - Indica se há limite para valores de cancelamento de pagamentos.
 * @property {number|null} bill_pay_cancellation_per_day - Cancelamentos de pagamento por dia.
 * @property {boolean} is_limit_payment_reversal_values - Indica se há limite para estornos de pagamento.
 * @property {number|null} payment_reversal_per_day - Estornos de pagamento por dia.
 * @property {boolean} invoice_can_change_concrete_price - Indica se pode alterar preço do concreto.
 * @property {boolean} invoice_can_change_service_value - Indica se pode alterar valor do serviço.
 * @property {boolean} invoice_can_change_additional_value - Indica se pode alterar valores adicionais.
 * @property {boolean} invoice_can_add_single - Indica se pode adicionar item único na fatura.
 * @property {boolean} invoice_can_reverse - Indica se pode reverter fatura.
 * @property {boolean} invoice_can_cancel_nfse - Indica se pode cancelar NFS-e.
 * @property {boolean} invoice_can_include_reidi - Indica se pode incluir REIDI na fatura.
 * @property {number|null} created_by_user_id - ID do usuário que criou.
 * @property {number|null} updated_by_user_id - ID do usuário que atualizou.
 */
export const UserFinancialLimitPermissionType = {};

/**
 * Inicializar UserFinancialLimitPermissionType
 * 
 * @returns {UserFinancialLimitPermissionType}
 */
export const initUserFinancialLimitPermissionType = () => {
    return {
        user_id: null,
        is_limit_general_values: true,
        general_discounts_fees: null,
        fees: null,
        general_maximum_default: null,
        disable_max_extension_due_date: true,
        general_extension_due_date: null,
        is_limit_approval_values: true,
        approval_per_day: null,
        is_limit_receipt_values: true,
        receivement_per_day: null,
        is_limit_cancellation_values: true,
        cancellation_per_day: null,
        is_limit_reversal_of_receivable_values: true,
        reversal_of_receivable_per_day: null,
        bill_pay_is_limit_general_values: true,
        bill_pay_general_discounts_fees: null,
        bill_pay_fees: null,
        bill_pay_general_maximum_default: null,
        bill_pay_is_limit_amounts_change_due_date: true,
        bill_pay_general_extension_due_date: null,
        bill_pay_is_limit_approval_values: true,
        bill_pay_approval_per_day: null,
        is_limit_payment_values: true,
        payment_per_day: null,
        bill_pay_is_limit_cancellation_values: true,
        bill_pay_cancellation_per_day: null,
        is_limit_payment_reversal_values: true,
        payment_reversal_per_day: null,
        invoice_can_change_concrete_price: false,
        invoice_can_change_service_value: false,
        invoice_can_change_additional_value: false,
        invoice_can_add_single: false,
        invoice_can_reverse: false,
        invoice_can_cancel_nfse: false,
        invoice_can_include_reidi: false,
    };
};
