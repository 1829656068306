<template>
  <div>
    <div class="container-fluid">
      <div class="row card-wrapper">
        <div class="col-md-4" v-for="(item, index) in $_bank_reconciliations" :key="index">
          <div class="card card-bank-reconciliation">
            <div class="container p-0">
              <div class="row align-items-center">
                <div class="col-10">
                  <div class="d-flex align-items-center">
                    <StatusBadge
                      @eventHandler="eventHandler"
                      :item="item"
                      minWidth="160"
                    />
                  </div>
                </div>
                <div class="col-2 text-right">
                  <SettingOptions
                    @eventHandler="eventHandler"
                    :item="item"
                  />
                </div>
              </div>
              <div class="row mt-4 align-items-center">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <img :src="'/img/icons/banks/'+ item.bank_code + '.png'"
                     width="20"
                     alt="conta">
                    <h3 class="new-default-black-font my-0 ml-2">
                      {{ item.account_name }}
                    </h3>
                  </div>
                </div>
                <div class="col-12">
                  <h4 class="new-default-gray-font font-weight-400 mt-3 mb-1">
                    Agência: {{ item.agency }}-{{ item.agency_digit || '#' }}
                  </h4>
                  <h4 class="new-default-gray-font font-weight-400">
                    Conta: {{ item.account }}-{{ item.account_digit || '#'}}
                  </h4>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="mt-2 mb-3">
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h4 class="new-default-black-font font-weight-400 m-0">
                    {{ item.start_at | parseDate('DD/MM/YYYY') }} até {{ item.end_at | parseDate('DD/MM/YYYY') }}
                  </h4>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-6 pr-2">
                  <a
                    class="document-placement-card"
                    href="#"
                  >
                    <nav>
                      <div class="d-flex align-items-baseline">
                        <h5 class="new-default-black-font m-0">
                          R$
                        </h5>
                        <h2 class="new-default-black-font m-0 ml-2">
                          {{  toMoneyWithoutCurrencyLayout(item.total_receipts) }}
                        </h2>
                      </div>
                      <div class="d-flex align-items-center">
                        <h5 class="new-default-black-font m-0">
                          Recebimentos
                        </h5>
                        <h5 class="new-default-black-font m-0 ml-2">
                          {{ Number(item.bills_to_receive_reconciled_count) + Number(item.credit_operations_associations_count) }}/
                        </h5>
                        <h5 class="text-success m-0">
                          {{ item.bills_to_receive_count }}
                        </h5>
                      </div>
                    </nav>
                  </a>
                </div>
                <div class="col-6 pl-2">
                  <a
                    class="document-placement-card"
                    href="#"
                  >
                    <nav>
                      <div class="d-flex align-items-baseline">
                        <h5 class="new-default-black-font m-0">
                          R$
                        </h5>
                        <h2 class="new-default-black-font m-0 ml-2">
                          {{  toMoneyWithoutCurrencyLayout(item.total_payments) }}
                        </h2>
                      </div>
                      <div class="d-flex align-items-center">
                        <h5 class="new-default-black-font m-0">
                          Pagamentos
                        </h5>
                        <h5 class="new-default-black-font m-0 ml-2">
                          {{ Number(item.bills_to_pay_reconciled_count) + Number(item.debit_operations_associations_count) }}/
                        </h5>
                        <h5 class="text-danger m-0">
                          {{ item.bills_to_pay_count }}
                        </h5>
                      </div>
                    </nav>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SkeletonPuzlGrid
          v-show="props.loading"
          v-for="(index) in 3"
          :key="index">
        </SkeletonPuzlGrid>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, getCurrentInstance, defineEmits} from "vue";
import SettingOptions from "./Common/SettingOptions.vue";
import StatusBadge from "./Common/StatusBadge";
import {toMoneyWithoutCurrencyLayout} from "@/helpers";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
})

/** Eventos emitidos */
const emit = defineEmits([
  "handleDelete",
  "openTransaction",
  "changeStatus",
]);

const store = getCurrentInstance().proxy.$store;

const $_bank_reconciliations = computed(() => store.getters["bankReconciliation/fetch"]);

function eventHandler(event, item) {
  emit(event, item);
}
</script>
<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.card-bank-reconciliation {
  -webkit-box-shadow: 0 5px 10px 0 #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid $muted-light;
  box-shadow: 0 5px 10px 0 #0000000D !important;
  padding: 16px 20px 20px;

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }

    .reuse-container-card {
      margin-top: 8px;
      border: 1px solid #f4f4f4;
      border-radius: 8px;
      padding: 18px 5px;

      h5, h6 {
        padding-left: 0;
      }
    }
  }

  hr {
    background-color: $muted-light;
    opacity: 0.5;
    color: $muted-light;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }

  .pointer {
    transition: all ease-in-out 0.15s;
    &:hover {
      transform: translateY(-3px) !important;
    }
  }

  .document-placement-card {
    text-align: center;
    height: 75px;
    box-shadow: 0px 5px 10px 0px #0000000D;
    display: flex;
    border: 1px solid $muted-light;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    &.transparent-card {
      box-shadow: none;
      border: none;
      height: 22px;
    }

    nav {
      display: block;
      align-items: center;

      h5 {
        font-size: 12px;
        margin: 0 0 0 5px;
        height: 20px;
        font-weight: 400 !important;
        color: $dark;
      }

      img {
        height: 18px;
        margin-right: 5px;
      }
    }
  }
}
</style>
