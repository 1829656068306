<template>
  <div>
    <modal :show.sync="isOpen" class="competitor-create" size="lg">
      <template #header>
        <AppIcon icon="plus-math" color="success" width="21" />
        <h5 class="modal-title">{{ title }}</h5>
      </template>

      <CompetitorForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref } from "vue";
import { initCompetitorType, CompetitorType } from "../../types";
import { dialogs, progress } from "../../../../../../helpers";
import CompetitorForm from "../shared/CompetitorForm.vue";
import competitorStore from "../../store/competitorStore";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Novo Concorrente";
/** @type {CompetitorType} */
const formData = reactive(initCompetitorType());
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initCompetitorType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  competitorStore.actions.create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} CompetitorCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.modal.competitor-create {
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $success-lighter !important;
    align-items: center !important;

    &.pb-1 {
      padding-bottom: 24px !important;
    }

    .app-icon {
      margin-right: 12px;
    }

    h5.modal-title {
      color: $dark !important;
      margin-bottom: 0 !important;
      @include rhythm(font-size, 20);
    }

    button.close {
      padding: 0;
      display: flex;
      color: $dark !important;
      margin: 0;
      margin-left: auto;
      min-height: auto !important;
      // aumentar area de click
      margin-right: -32px;
      padding: 32px;
    }
  }
}
</style>
