<template>
  <div>
    <modal :show.sync="isOpen" size="lg" class="header-color">
      <template slot="header">
        <h5 class="modal-title p-0 m-0">
          <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" height="25" class="mr-2" />
          Lista de itens
        </h5>
      </template>
      <div class="p-3">
        <!-- INFORMAÇÕES -->
        <div class="d-flex align-items-center">
          <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
          <span style="font-size: 18px; color: #2b2d32; font-weight: 500" class="ml-2 mr-3">IDENTIFICAÇÃO</span>
        </div>
        <div class="d-flex justify-content-between info-content">
          <div>
            <div style="font-size: 18px; font-weight: 500; color: #2b2d32">
              {{ "RC-" + purchaseData.id }}
            </div>
            <div style="font-size: 14px; font-weight: 400; color: #2b2d32">
              manutenção
            </div>
            <div class="mt-2" style="font-size: 12px; font-weight: 400; color: #606062">
              {{ purchaseData.input_company_plant_name }}
            </div>
            <div class="mt-1" style="font-size: 12px; font-weight: 400; color: #606062">
              prazo: {{ purchaseData.deadline | parseDate("DD MMM YYYY") }}
            </div>
          </div>
          <div class="d-flex">
            <div class="mr-3">
              <el-popover trigger="click" placement="bottom" class="p-0">
                <span class="font-weight-500">Solicitante:</span>
                <div style="font-size: 11px">{{ purchaseData.created_by_user_name }}</div>
                <div style="font-size: 11px">{{ purchaseData.created_by_user_email }}</div>
                <span slot="reference">
                  <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26" />
                </span>
              </el-popover>
            </div>
            <div class="mr-2">
              <el-popover trigger="click" placement="bottom" class="p-0">
                <div class="p-0" style="max-width: 14rem">
                  <span style="font-size: 14px">Observações</span>
                  <div class="my-1" style="height: 1px; width: 100%; background-color: #e8e8e8" />
                  <div class="d-block mt-2">
                    <div style="font-size: 12px" class="font-weight-500">
                      Para a requisição de compras
                    </div>
                    <div style="font-size: 12px" class="mt-1">{{ purchaseData.note }}</div>
                  </div>
                  <div class="my-1" style="height: 1px; width: 100%; background-color: #e8e8e8" />
                  <div class="d-block mt-2">
                    <div style="font-size: 12px" class="font-weight-500">
                      Necessidade da compra
                    </div>
                    <div style="font-size: 12px" class="mt-1">
                      {{ purchaseData.purchase_justification }}
                    </div>
                  </div>
                </div>
                <span slot="reference">
                  <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="26" />
                </span>
              </el-popover>
            </div>
          </div>
        </div>
        <div class="dropdown-divider my-4" style="width: -webkit-fill-available" />
        <div class="d-flex align-items-center">
          <img width="25" src="/img/icons/big-parcel-primary.png" class="mr-2" />
          <span style="font-size: 16px; font-weight: 500; color: #2b2d32">Itens</span>
        </div>
        <!-- TABELA -->
        <div class="table-container mt-4 pt-0">
          <div class="row mx-1">
            <table class="table table-custom-shaded table-sm mb-9 pb-3 mt-4">
              <thead>
                <tr class="fixed">
                  <th class="text-center" @click="selectAll">
                    <img width="18" src="/img/icons/double-box-tick-primary.png" class="mr-2 pointer" />
                  </th>
                  <th class="text-left new-default-black-font">Item</th>
                  <th class="text-left new-default-black-font">Marca</th>
                  <th class="text-left new-default-black-font">Quantidade</th>
                  <th class="text-left new-default-black-font">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in requestItems" :key="item.id">
                  <td class="text-center">
                    <img v-if="requestItems[index]" @click="toggleStatus(index)" width="18" class="mr-2 pointer" :src="requestItems[index].status
                        ? '/img/icons/box-tick-primary.png'
                        : '/img/icons/black-square.svg'
                      " />
                  </td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.brand }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>
                    <base-dropdown menuOnRight>
                      <div slot="title-container" class="dropdown-toggle rounded m-0">
                        <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                      </div>
                      <a class="dropdown-item font-weight-500 new-default-black-font"
                        style="align-items: center; display: flex" @click="removePurchaseRequestItem(item.id)">
                        <img width="23" height="23" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                        Remover
                      </a>
                    </base-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="showButtons && purchaseData.status != PurchaseRequestStatusEnum.keys.CANCELED"
          class="d-flex justify-content-end mb-2 mt-3 text-center">
          <div v-if="purchaseData.status != PurchaseRequestStatusEnum.keys.DENIED"
            @click="save(PurchaseRequestStatusEnum.keys.DENIED)" class="aprovation-button bg-danger mr-0 mr-2">
            <img class="mr-2" width="20" src="/img/icons/icons8/ios/thumbs-down.png" />
            negar
          </div>
          <div @click="save(PurchaseRequestStatusEnum.keys.APPROVED)" class="aprovation-button bg-primary"
            v-if="purchaseData.status != PurchaseRequestStatusEnum.keys.APPROVED">
            <img class="mr-2" width="20" src="/img/icons/thumb-up--v1-white.png" />
            aprovar
          </div>
        </div>
      </div>
      <ModalPurchaseRequestJustification @fetch="fetchPurchaseRequests" ref="modalPurchaseRequestJustification" />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { ref, reactive, computed, defineEmits } from "vue";
import PurchaseRequestStatusEnum from "../../../enums/PurchaseRequestStatusEnum";
import PurchaseRequestItemStatusEnum from "../../../enums/PurchaseRequestItemStatusEnum";
import store from "@/store/index";
import { dialogs, progress } from "@/helpers";
import ModalPurchaseRequestJustification from "./_ModalPurchaseRequestJustification.vue";
import purchaseRequestStore from "../../../store/purchaseRequestStore";
//#endregion

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

//#region Props
const props = defineProps({
  showUpdateButton: {
    type: Boolean,
  },
  showButtons: {
    type: Boolean,
    default: true,
  },
});
//#endregion

//#region ComponentRefs
const modalPurchaseRequestJustification = ref(null);
//#endregion

//#region Data
const isOpen = ref(false);
const purchaseData = ref(null);
const requestItems = ref([]);
const filter = reactive({
  order_by: [
    {
      column: "purchase_request_items.id",
    },
  ],
  purchase_request_id: null,
});
//#endregion

//#region Computeds
const $_user = computed(() => store.getters["auth/getUser"]);
//#endregion

//#region Methods
function openModal(data) {
  purchaseData.value = data;
  filter.purchase_request_id = purchaseData.value.id;
  init();
}

function closeModal() {
  isOpen.value = false;
}

function init() {
  purchaseRequestStore.actions.fetchRequestItems(filter)
    .then((response) => {
      if (purchaseData.value.isDeniedItems) {
        if (purchaseData.value.status === PurchaseRequestStatusEnum.keys.DENIED) {
          requestItems.value = response.data;
        } else {
          requestItems.value = response.data.filter((item) => item.status === PurchaseRequestItemStatusEnum.keys.DENIED);
        }
      } else {
        requestItems.value = response.data.filter((item) => item.status === PurchaseRequestItemStatusEnum.keys.APPROVED);
      }
    })
    .finally(() => {
      isOpen.value = true;
      progress.finish();
    });
}

function handleShowModalRequestJustification(data) {
  modalPurchaseRequestJustification.value.openModal(data);
}

function save(status) {
  const data = { ...purchaseData.value };
  data.items = requestItems.value;
  data.updated_by_user_id = $_user.value.id;
  data.status = status;
  // Verifica se o usuário tem permissão
  if (!purchaseData.value.can_approve) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Usuário não tem permissão para aprovar/negar requisição!');
    return;
  }
  // Verifica se pelo menos um item foi aprovado
  if (
    !requestItems.value.some((item) => item.status === PurchaseRequestItemStatusEnum.keys.APPROVED) &&
    status === PurchaseRequestStatusEnum.keys.APPROVED
  ) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Selecione pelo menos um item!');
    return;
    // Verifica se a cotação possui orçamento ativo
  } else if (purchaseData.value.has_active_budget && status === PurchaseRequestStatusEnum.keys.DENIED) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'A cotação já possui orçamento ativo!');
    return;
  } else if (
    // Verifica se pelo menos um item foi negado para justificação
    requestItems.value.some((item) => item.status === PurchaseRequestItemStatusEnum.keys.DENIED) &&
    status === PurchaseRequestStatusEnum.keys.APPROVED
  ) {
    data.modalTitle = "Itens não aprovados";
    data.modalText = "Alguns itens não foram aprovados, deseja continuar?";
    handleShowModalRequestJustification(data);
    return;
  // Verifica se a requisiçao foi negada
  } else if (status === PurchaseRequestStatusEnum.keys.DENIED) {
    data.modalTitle = "negar requisição?";
    data.modalText = "Essa operação será registrada no sistema, deseja continuar?";
    handleShowModalRequestJustification(data);
    return;
  }

  progress.start();
  const loader = progress.showLoader();

  purchaseRequestStore.actions.update(data)
    .then(() => {
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Requisição salva!');
      closeModal();
      emit("fetch");
    })
    .finally(() => {
      loader.hide();
    });
}

function fetchPurchaseRequests() {
  closeModal();
  emit("fetch");
}

function removePurchaseRequestItem(id) {
  progress.start();
  store.dispatch("purchaseRequest/destroy", id)
    .then(() => {
      dialogs.notify({ type: "success", message: "Item removido!" });
      init();
    })
    .finally(() => {
      progress.finish();
    });
}

function toggleStatus(index) {
  const newStatus = requestItems.value[index].status === 1 ? 0 : 1;
  requestItems.value[index].status = newStatus;
}

function selectAll() {
  requestItems.value = requestItems.value.map((item) => ({
    ...item,
    status: 1,
  }));
}
//#endregion

//#region DefineExposes
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
.header-color >>> .modal-header {
  background-color: #e8e8e8 !important;
}
.header-color >>> .modal-title {
  color: #2B2D32 !important;
  
}
.header-color >>> .fa-times {
  color: #2B2D32 !important;
}
.table-container {
  overflow: auto;
  max-height: 16rem;
  width: 100%;
  background-color: #f4f8fb;
  padding: 1rem;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
}

.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}

.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.info-content {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 8px 12px;
  margin-top: 1rem;
}
</style>
