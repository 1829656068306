import { BaseListFilterType, initBaseListFilterType } from "@/shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} InvoiceListFilterExtType
 * @property {RangeDateType|null} range - Intervalo entre datas
 * @property {Array|null} global - Usado para buscas no padrão antigo da API
 * @property {Array<Number>} seller_id - IDs dos vendedores
 * @property {RangeValueType} range_nfse_number - Intervalo entre o números do código das NFSe, Mínimo e Máximo
 * @property {RangeValueType} range_invoice_value - Intervalo entre o valor das FATURAS, Mínimo e Máximo
 * @property {RangeValueType} range_invoice_number - Intervalo entre o número das FATURAS, Mínimo e Máximo
 * @property {String|null} order_by - String com informações para o tipo de ordenação. Ex.: code.asc
 * @property {0|1} without_car - [0..1]
 * @property {0|1} nfse_pending_issue - [0..1]
 * @property {0|1} with_iss - [0..1]
 * @property {0|1} canceled - [0..1]
 * @property {0|1} not_canceled - [0..1]
 * @property {0|1} verify_status - [0..1]
 * @property {0|1} filter_by_default - [0..1]
 * @property {null|0|1} by_measurement - [null..0..1] null: todos, 0: diário (Não por medição), 1: Por medição
 * @property {Boolean} new_default - Formata a resposta da API para o novo padrão
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & InvoiceListFilterExtType)} InvoiceListFilterType
 */
export const InvoiceListFilterType = {};

/**
 * Inicializar InvoiceListFilterType
 *
 * @returns {InvoiceListFilterType}
 */
export const initInvoiceListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    range: {
      items: [],
      selected: null,
      start: null,
      end: null,
    },
    global: [],
    filter: {
      columns: [],
      values: [],
    },
    seller_id: null,
    range_nfse_number: {
      min: null,
      max: null
    },
    range_invoice_value: {
      min: null,
      max: null
    },
    range_invoice_number: {
      min: null,
      max: null
    },
    order_by: null,
    without_car: 0,
    nfse_pending_issue: 0,
    with_iss: 0,
    canceled: 0,
    not_canceled: 1,
    verify_status: 0,
    filter_by_default: 1,
    by_measurement: null,
    new_default: true,
  };
}
