import store from "../../../../shared/libraries/store";
import { initSyncGeneralListPagerType, SyncGeneralListFilterType, SyncGeneralListPagerType } from "../types";
import { listSources } from "../../../../shared/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/sync/sync-general",
});

/** KEYS */
const keys = Object.freeze({
  LISTED: "SYNC_GENERAL_LISTED",
  IS_LISTING: "SYNC_GENERAL_IS_LISTING",
  PAGE_STATES: "SYNC_GENERAL_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {SyncGeneralListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initSyncGeneralListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: SyncGeneralListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {SyncGeneralListPagerType} listed
   * @returns {SyncGeneralListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {SyncGeneralListPagerType} listed
   * @returns {SyncGeneralListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: SyncGeneralListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};

/** ACTIONS */
const actions = {
  /**
   * Listar
   *
   * @param {SyncGeneralListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<SyncGeneralListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * @param {number} id
   * @returns {Promise<void>}
   */
  resend: async function (id) {
    const res = await httpClient.post(`${urls.BASE}/resend/${id}`);
    switch (res.status) {
      case HttpStatusEnum.HTTP_OK:
        return res.data.data;
      default:
        handleUnexpectedStatusCode(res);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};
