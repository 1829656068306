<template>
  <div :class="['app-tabs', $attrs.class]">
    <div class="tab-list" :style="listStyle">
      <slot name="tab-list"></slot>
    </div>
    <div class="tab-content" :style="contentStyle">
      <slot />
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  provide,
  onMounted,
  computed,
  onUnmounted,
} from "vue";

const props = defineProps({
  defaultValue: {
    type: String,
    required: true,
  },
});

// Reactive flag for mobile view; you could also use a composable or CSS media queries.
const isMobile = ref(window.innerWidth < 992);
const updateIsMobile = () => {
  isMobile.value = window.innerWidth < 992;
};

// Set the active tab differently based on device
const activeTab = ref(isMobile.value ? null : props.defaultValue);
// This ref will hold the currently rendered component (when one is chosen)
const renderedComponent = ref(null);

// A history stack to keep track of previously rendered components
const historyStack = ref([]);

// Add a flag to track if we're transitioning between tabs

const listStyle = computed(() => {
  if (!isMobile.value) return { minWidth: "35%" };
  return isMobile.value && renderedComponent.value
    ? {
        width: "0",
        opacity: "1",
        transition: "width 0.3s ease, opacity 0.2s ease",
      }
    : {
        width: "100%",
        minWidth: "100%",
        opacity: "1",
        transition: "width 0.3s ease, opacity 0.2s ease",
      };
});

const contentStyle = computed(() => {
  if (!isMobile?.value) {
    return { width: "100%", transition: "width 0.3s ease, opacity 0.2s ease" };
  } else if (!renderedComponent?.value) {
    return {
      width: 0,
      opacity: 0,
      transition: "width 0.3s ease, opacity 0.2s ease",
    };
  } else {
    return {
      width: "100%",
      opacity: 1,
      transition: "width 0.3s ease, opacity 0.2s ease",
    };
  }
});

const setActiveTab = (value) => {
  activeTab.value = value;
  renderedComponent.value = null;
  historyStack.value = [];
};

const setRenderedComponent = (component, currentComponent) => {
  if (!isMobile.value) {
    historyStack.value.push(currentComponent);
    historyStack.value.push(component);
    renderedComponent.value = component;
    return;
  }

  if (renderedComponent.value) {
    historyStack.value.push(renderedComponent.value);
  }
  renderedComponent.value = component;
};

// Function to go back to the previous component (if any)
const goBack = () => {
  if (!isMobile.value) {
    historyStack.value.pop();
    renderedComponent.value = historyStack.value.at(-1);
    historyStack.value.pop();
    return;
  }
  if (historyStack.value.length) {
    renderedComponent.value = historyStack.value.pop();
  } else {
    // No previous component; show the list again
    renderedComponent.value = null;
  }
};

// Computed property to determine if we can go back
const canGoBack = computed(
  () =>
    historyStack.value.length > 0 || (isMobile.value && renderedComponent.value)
);

// Provide these reactive values and functions to child components (so that your tab items or list items can use them)
provide("activeTab", activeTab);
provide("setActiveTab", setActiveTab);
provide("renderedComponent", renderedComponent);
provide("setRenderedComponent", setRenderedComponent);
provide("goBack", goBack);
provide("canGoBack", canGoBack);
provide("isMobile", isMobile);

onMounted(() => {
  window.addEventListener("resize", updateIsMobile);
  updateIsMobile();
});
onUnmounted(() => {
  window.removeEventListener("resize", updateIsMobile);
});
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
.app-tabs {
  display: flex;
  flex-direction: row;
}

.tab-content,
.tab-list {
  transition: all 0.3s ease-in-out;
}

.tab-list {
  border-right: 1px solid $muted-light;
  overflow: hidden;
}
</style>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.app-tabs {
  .back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    @include media-query(down, lg) {
      width: 110px !important;
    }
    .button-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }

  .badge {
    @include media-query(down, lg) {
      font-size: 10px !important;
      width: 84px;
      min-width: 0;
    }
  }

  .app-tab-footer {
    display: flex;
    justify-content: end;
    gap: 8px;
    padding: 0 24px 32px;
  }
}
</style>
