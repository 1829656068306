import { render, staticRenderFns } from "./AppTabList.vue?vue&type=template&id=ca7a0588&scoped=true"
import script from "./AppTabList.vue?vue&type=script&setup=true&lang=js"
export * from "./AppTabList.vue?vue&type=script&setup=true&lang=js"
import style0 from "./AppTabList.vue?vue&type=style&index=0&id=ca7a0588&prod&scoped=true&lang=scss"
import style1 from "./AppTabList.vue?vue&type=style&index=1&id=ca7a0588&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca7a0588",
  null
  
)

export default component.exports