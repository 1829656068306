<template>
  <div>
    <div class="row" v-if="$_servicePrice.length">
      <div
        class="col-lg-4 mb-n3"
        v-for="(item, index) in $_servicePrice"
        :key="index"
      >
        <div class="card main-card p-4">
          <div class="d-flex align-items-center" style="gap: 8px">
            <div style="min-width: 130px">
              <AppSwitch
                v-model="item.status"
                :asyncClick="() => updateStatus(item)"
              />
            </div>
            <div class="ml-1">
              <el-popover trigger="click" placement="bottom">
                <div class="font-weight-400 fs-13" style="padding-bottom: 8px">
                  Central
                </div>
                <div style="height: 0.5px; background-color: #e8e8e8"></div>
                <div
                  v-for="(itemPlant, indexPlant) in item.company_plants"
                  :key="indexPlant"
                  style="padding-top: 8px; display: flex; align-items: center"
                >
                  <div class="font-weight-300 fs-12">
                    {{ itemPlant.name }}
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img width="26px" src="/img/icons/chemical-plant-grey.png" />
                </base-button>
              </el-popover>
            </div>
            <div>
              <el-popover trigger="click" placement="bottom">
                <div
                  class="font-weight-400 fs-13"
                  style="padding-bottom: 8px; min-width: 270px"
                >
                  Preços
                </div>
                <div style="height: 0.5px; background-color: #e8e8e8"></div>
                <!-- Preço minimo (fixo) -->
                <div
                  v-if="item.price_fixed"
                  class="d-flex align-items-center"
                  style="padding-top: 12px"
                >
                  <div class="text-left" style="min-width: max-content">
                    <h5 class="font-weight-300 mb-0">Preço mínimo(fixo)</h5>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background-color: #e8e8e8;
                      margin: 0 8px;
                    "
                  ></div>
                  <div class="text-right" style="min-width: max-content">
                    <h5 class="font-weight-400 mb-0" style="font-size: 12px">
                      {{ $helper.toMoney(item.price_fixed) }}
                    </h5>
                  </div>
                </div>
                <!-- Preço metro -->
                <div
                  v-if="item.by_meters"
                  class="d-flex align-items-center"
                  style="padding-top: 12px"
                >
                  <div class="text-left" style="min-width: max-content">
                    <h5 class="font-weight-300 mb-0" style="font-size: 12px">
                      Preço por /m<sup>3</sup>
                    </h5>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background-color: #e8e8e8;
                      margin: 0 8px;
                    "
                  ></div>
                  <div class="text-right" style="min-width: max-content">
                    <h5 class="font-weight-400 mb-0" style="font-size: 12px">
                      {{ $helper.toMoney(item.by_meters) }}
                    </h5>
                  </div>
                </div>
                <!-- Preço por hora -->
                <div
                  v-if="item.by_hour"
                  class="d-flex align-items-center"
                  style="padding-top: 12px"
                >
                  <div class="text-left" style="min-width: max-content">
                    <h5 class="font-weight-300 mb-0">Preço por hora</h5>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background-color: #e8e8e8;
                      margin: 0 8px;
                    "
                  ></div>
                  <div class="text-right" style="min-width: max-content">
                    <h5 class="font-weight-400 mb-0">
                      {{ $helper.toMoney(item.by_hour) }}
                    </h5>
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img width="26px" src="/img/icons/price-tag-usd-green.png" />
                </base-button>
              </el-popover>
            </div>
            <div>
              <el-popover class="ml-0" placement="bottom" trigger="click">
                <div style="min-width: 270px">
                  <div class="font-weight-400 fs-13">LOGS DE ATIVIDADE</div>
                  <hr class="mt-2 mb-2" />
                  <div class="font-weight-400 fs-13 mb-1">CRIAÇÃO</div>
                  <div class="log-card">
                    <div
                      v-if="item.created_by_user"
                      class="font-weight-300 fs-12"
                    >
                      {{ item.created_by_user.name }}
                    </div>
                    <div v-else class="font-weight-300 fs-12">-</div>
                    <div class="font-weight-300 fs-12">
                      {{
                        $helper.parseDate(item.created_at, "DD MMM YYYY HH:mm")
                      }}
                    </div>
                  </div>
                  <div v-if="item.updated_by_user" class="mt-3">
                    <div class="font-weight-400 fs-13 mb-1">
                      ÚLTIMA ALTERAÇÃO
                    </div>
                    <div class="log-card">
                      <div class="font-weight-300 fs-12">
                        {{ item.updated_by_user.name }}
                      </div>
                      <div class="font-weight-300 fs-12">
                        {{
                          $helper.parseDate(
                            item.updated_at,
                            "DD MMM YYYY HH:mm"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img
                    width="26px"
                    src="/img/icons/icons8/ios/user-male-circle--v1.png"
                  />
                </base-button>
              </el-popover>
            </div>
            <base-dropdown
              menuOnRight
              style="display: block; margin-left: auto"
            >
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img
                  width="32"
                  src="/img/icons/icons8/ios/settings--v1_primary.png"
                />
              </div>
              <a
                class="dropdown-item ts-12 font-weight-400"
                @click.prevent="handleServicePriceEdit(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/create-new-yellow.png"
                  width="14px"
                  height="14px"
                />
                Editar
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a
                class="dropdown-item ts-12 font-weight-400"
                @click.prevent="handleServicePriceDelete(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/delete-red.png"
                  width="14px"
                  height="14px"
                />
                Excluir
              </a>
            </base-dropdown>
          </div>
          <div class="font-weight-500 fs-16" style="padding-top: 16px">
            {{ item.service.service_name }}
          </div>
          <div class="font-weight-400 fs-14">
            {{ item.description.description }}
          </div>
        </div>
      </div>
    </div>
    <edit-service-price></edit-service-price>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditType from "./_ModalEdit";
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import EditServicePrice from "./_ModalEdit.vue";
import { AppSwitch } from "../../../../../components/AppGlobal";
import { mapGetters } from "vuex";
export default {
  name: "List",
  props: {
    loadingSkeleton: Boolean,
    init: { type: Function },
  },
  components: {
    SkeletonPuzlGrid,
    EditType,
    AppIcon,
    AppSwitch,
    EditServicePrice,
  },
  data() {
    return {
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_servicePrice: "servicePrice/fetch",
    }),
  },
  methods: {
    handleServicePriceDelete(uuid) {
      this.$Progress.start();
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Você não poderá recuperar este registro!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, apague isto!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$axios
              .delete("/commercial/service/price/" + uuid)
              .then(({ data }) => {
                this.$notify({ type: data.error_type, message: data.message });
                this.init();
                this.$Progress.finish();
              })
              .catch((error) => {
                this.$notify({
                  type: error.response.data.error_type,
                  message: error.response.data.message,
                });
              });
          }
        })
        .finally(() => {
          this.$Progress.finish();
          init();
        });
    },

    handleServicePriceEdit(uuid) {
      EventBus.$emit("handleEditServicePrice", uuid);
    },

    updateStatus(servicePrice) {
      this.$swal
        .fire({
          title: "Você tem certeza que quer alterar o status?",
          text: "Essa ação será salva no Log de Atividade!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, altere o status!",
          cancelButtonText: "Não, mantenha o status atual",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            const previousStatus = servicePrice.status;
            servicePrice.status = +!servicePrice.status;
            this.$store
              .dispatch("servicePrice/updateStatus", servicePrice)
              .then((response) => {
                if (response.data) {
                  this.$notify({
                    type: "success",
                    message: "Status atualizado com sucesso!",
                  });
                } else {
                  servicePrice.status = previousStatus;
                  this.$notify({
                    type: "danger",
                    message: "Erro ao atualizar status!",
                  });
                }
              })
              .catch((error) => {
                servicePrice.status = previousStatus;
                this.$notify({
                  type: "danger",
                  message: "Erro ao atualizar status!",
                });
              });
          }
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}

.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid #ececec;
}

.log-card {
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 8px 12px;
  border: 1px solid #ececec;
}

.new-minicard-green {
  background-color: #f2f7f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-red {
  background-color: #fcf3f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.app-button {
  padding: 0 !important;
}

.bullet {
  width: 5px;
  height: 5px;
  border: 1px solid #1a70b7; /* Apenas a borda é colorida */
  border-radius: 50%;
}
</style>
