<template>
  <div>
    <AppFixedPageTitle
      title="Programações"
      icon="/img/icons/icons8/ios/svgs/calendar.svg"
    />
    <ListContractProposalSchedule />
  </div>
</template>

<script>
import ListContractProposalSchedule from "./Shared/_List";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import BackButton from "@/components/Utils/BackButton";
import { mapGetters } from "vuex";
import {AppFixedPageTitle} from '../../../../../components/AppGlobal'


export default {
  name: "IndexContractProposalSchedule",
  components: {
    ListContractProposalSchedule,
    PuzlBreadcrumb,
    BackButton,
    AppFixedPageTitle,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      $_contract_proposal: "contractProposal/show",
    }),
  },
  methods: {
    handleSearchContract() {
      this.$refs.searchContract.openModal();
    },
  },
  beforeMount() {
    this.loading = true
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then((response) => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}
</style>
