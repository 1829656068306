<template>
  <div>
    <modal
      :show.sync="isOpen"
      :backgroundColor="'#fef9f2'"
      bodyClasses="p-0"
      :showClose="false"
      size="sm"
    >
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/create-new.png"
            alt="Error"
            width="24"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-3"
              style="font-size: 16px;">
            {{ title }}
          </h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click.prevent="closeModal"
        />
      </template>
      <div class="container px-3 py-2">
        <div class="row">
          <div class="col-12 mb-2">
            <h5 class="new-default-black-font m-0"
                style="font-size: 13.5px;">
              Dedução
            </h5>
          </div>
          <div class="col-6 pr-1">
            <div class="input-custom-group">
              <div>%</div>
              <input inputmode="numeric"
                v-money="percentageOptions"
                v-model.lazy="deduct_in_percentage"
                @input="handleInputPercentage"
                @focus="setFocus('percentage')"
                @blur="clearFocus()"
              />
            </div>
          </div>
          <div class="col-6 pl-1">
            <div class="input-custom-group">
              <div>R$</div>
              <input inputmode="numeric"
                v-money="moneyOptions"
                v-model.lazy="deduct_in_value"
                @input="handleInputValue"
                @focus="setFocus('value')"
                @blur="clearFocus()"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container px-4 mb-3">
        <div class="row">
          <div class="col-12 mt-4 text-right">
            <h4 class="new-default-black-font font-weight-500 m-0">
              Valor Bruto: R$ {{ toMoneyWithoutCurrencyLayout(final_value) }}
            </h4>
          </div>
        </div>
      </div>
      <div class="container px-4 my-4">
        <div class="row">
          <div class="col-12 text-right px-2" style="gap: 10px; display: flex; justify-content: flex-end">
            <AppButton
              label="Cancelar"
              type="danger"
              :isBlock="false"
              :hasOutline="true"
              @click.prevent="closeModal"
            />
            <AppButton
              label="Salvar"
              type="success"
              :isBlock="false"
              @click.prevent="save"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>
<script setup>
//#region Imports
import {defineEmits, onMounted, ref, watch} from "vue";
import {AppButton} from "../../../../../../components/AppGlobal";
import {strToNum, toMoneyWithoutCurrencyLayout} from "../../../../../../helpers";
//#endregion

//#region ComponentRefs
//#endregion

//#region Data
const isOpen = ref(false);
const default_perc_deduction = ref(0);
const default_value_deduction = ref(0);
const deduct_in_value = ref(0);
const deduct_in_percentage = ref(0);
const final_value = ref(0);
const focusedField = ref(null);
const lastInputChanged = ref(null);

const title = "EDITAR DEDUÇÃO";
const moneyOptions = ref({
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
});
const percentageOptions = ref({
  decimal: ".",
  thousands: "",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false
});
//#endregion

//#region Computeds
watch(deduct_in_percentage, (newPercentage) => {
  if (focusedField.value !== "percentage") return; // Só executa se o campo % estiver em foco

  let new_deduct_in_percentage = strToNum(newPercentage);

  if (!isNaN(new_deduct_in_percentage) && final_value.value) {
    deduct_in_value.value = (Math.trunc((final_value.value / 100) * new_deduct_in_percentage * 100) / 100).toFixed(2);
  }
});

watch(deduct_in_value, (newDeduct) => {
  if (focusedField.value !== "value") return; // Só executa se o campo R$ estiver em foco

  let new_deduct_in_value = strToNum(newDeduct);

  if (!isNaN(new_deduct_in_value) && final_value.value) {
    deduct_in_percentage.value = (Math.trunc((new_deduct_in_value / final_value.value) * 100 * 100) / 100).toFixed(2);
  }
});

// Métodos para definir o campo em foco
//#endregion

//#region Methods
const handleInputPercentage = (event) => {
  let deduct_in_percentage = event.target.value;

  if (deduct_in_percentage > 100) {
    event.target.value = '100.00';
  }
}

const handleInputValue = (event) => {
  let new_deduct_in_value = event.target.value;

  if (new_deduct_in_value > final_value.value) {
    event.target.value = toMoneyWithoutCurrencyLayout(final_value.value);
  }
}

const setFocus = (field) => {
  focusedField.value = field;
  lastInputChanged.value = field
}

const clearFocus = () => {
  focusedField.value = null;
}

function save() {
  emit("handleSave", {
    'has_deduct_changed': strToNum(default_perc_deduction.value) !== strToNum(deduct_in_percentage.value) || strToNum(default_value_deduction.value) !== strToNum(deduct_in_value.value),
    'deduct_in_percentage': strToNum(deduct_in_percentage.value),
    'deduct_in_value': strToNum(deduct_in_value.value),
    'lastInputChanged': lastInputChanged.value,
  });
  closeModal();
}

/**
 * Abrir Modal
 * @param {number|string} currentPercDeduction
 * @param {number|string} currentValDeduction
 * @param {number|string} defaultValDeduction
 * @param {number|string} defaultPercDeduction
 * @param {number|string} finalValue
 */
function openModal(currentPercDeduction, currentValDeduction, defaultValDeduction, defaultPercDeduction, finalValue) {
  final_value.value = strToNum(finalValue);
  default_perc_deduction.value = strToNum(defaultPercDeduction);
  default_value_deduction.value = strToNum(defaultValDeduction);
  deduct_in_percentage.value = 0;
  deduct_in_value.value = 0;

  // updateValues(strToNum(currentPercDeduction));
  isOpen.value = true;
}

/**
 * Atualizar valores do modal
 * @param {number} currentPercDeduction
 */
function updateValues(currentPercDeduction) {
  if (!isNaN(currentPercDeduction) && final_value.value) {
    deduct_in_value.value = (Math.trunc((final_value.value / 100) * currentPercDeduction * 100) / 100).toFixed(2);
  } else {
    deduct_in_value.value = 0;
    deduct_in_percentage.value = 0;
  }
}

function closeModal() {
  isOpen.value = false;
}

/**
 * @typedef {Object} ModalSapBills
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
  closeModal,
});

const emit = defineEmits([
  "handleSave",
]);
//#endregion

//#region Lifecycle
onMounted(() => {});
//#endregion
</script>
<style scoped>
.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
}

.input-custom-group:has(> div) input {
  border-left: 1px solid #e96262;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
  display: inline-flex;
  height: 31px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div {
  color: #606062;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
  outline: none;
}
</style>
