<template>
  <div>
    <AppFixedPageTitle
      title="LIBERAÇÃO DE CRÉDITO"
      icon="/img/icons/icons8/ios/duration-finance.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
         :searchBarFilter.sync="searchBarFilter"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
          :rangeParams.sync="rangeParams"
          :showCompanyPlants="true"
        >
          <AppSearchBarFilterSection
            name="dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                :disabled="loadingPaymentMethod"
                :items="$_payment_methods"
                :disableBoxShadow="true"
                placeholder="Métodos de pagamento"
                v-model.lazy="filter.payment_method_ids"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
    />
    <div class="container-fluid">
      <DisableCreditReleaseNotification v-if="isDisablePendingCreditRelease" />
      <PuzlEmptyData v-else-if="!$_credit_releases.length && !loadingCreditRelease"/>
      <List
        v-if="listType === 'cards'"
        @onUpdateStatusClick="listItems"
        :loading="loadingCreditRelease"
      />
      <Table
        v-if="listType === 'table'"
        @onUpdateStatusClick="listItems"
        :loading="loadingCreditRelease"
      />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import List from "./Shared/_List";
import Table from "./Shared/_Table";
import { reactive, ref, computed, onMounted, onUnmounted, Ref, getCurrentInstance, defineEmits } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  AppSelect,
  TabSelectItemType,
  AppSearchBarFilterSection,
  initSearchBarRangeParamsType,
  BOTTOM_FILTER_ENUM
} from '../../../../components/AppGlobal';
import { CreditReleaseListFilterType, initCreditReleaseListFilterType } from "./types/CreditReleaseListFilterType";
import CreditReleaseStatusEnum from "./Enums/CreditReleaseStatusEnum";
import { dialogs, progress, date } from '../../../../helpers';
import PuzlEmptyData from '../../../../components/PuzlEmptyData.vue';
import DisableCreditReleaseNotification from "./Shared/DisableCreditReleaseNotification.vue";
import PuzlSelect from "@/components/PuzlSelect";
//#endregion

//#region Data
const store = getCurrentInstance().proxy.$store;

/** type {SearchBarRangeParamsType} - Filtro do AppSearchBar */
const rangeParams = {
  ...initSearchBarRangeParamsType(),
  disable_min_date: new Date(),
  bottom_filter: null
};

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());
/** @type {CreditReleaseListFilterType} */
const filter = reactive(initCreditReleaseListFilterType());

const listType = ref('cards');
const isDisablePendingCreditRelease = ref(null);
const currentDate = date.make().format(date.FORMAT.ISO_8601);
const loadingPaymentMethod = ref(true);
const loadingCreditRelease = ref(true);

const $_credit_releases = computed(() => store.getters["creditRelease/fetch"]);
const $_general_settings = computed(() => store.getters["generalSetting/show"]);
const $_payment_methods = computed(() => store.getters["paymentMethod/fetch"]);

const tabSelectItems = computed(() => {
  return [
    {
      id: CreditReleaseStatusEnum.keys.ALL,
      name: CreditReleaseStatusEnum.getTitle(CreditReleaseStatusEnum.keys.ALL),
      selected: filter.release_status === CreditReleaseStatusEnum.keys.ALL,
    },
    {
      id: CreditReleaseStatusEnum.keys.INSTALLMENT_PAYMENT,
      name: CreditReleaseStatusEnum.getTitle(CreditReleaseStatusEnum.keys.INSTALLMENT_PAYMENT),
      selected: filter.release_status === CreditReleaseStatusEnum.keys.INSTALLMENT_PAYMENT,
    },
    {
      id: CreditReleaseStatusEnum.keys.BARTER_PAYMENT,
      name: CreditReleaseStatusEnum.getTitle(CreditReleaseStatusEnum.keys.BARTER_PAYMENT),
      selected: filter.release_status === CreditReleaseStatusEnum.keys.BARTER_PAYMENT,
    },
    {
      id: CreditReleaseStatusEnum.keys.PREPAID_PAYMENT,
      name: CreditReleaseStatusEnum.getTitle(CreditReleaseStatusEnum.keys.PREPAID_PAYMENT),
      selected: filter.release_status === CreditReleaseStatusEnum.keys.PREPAID_PAYMENT,
    },
  ];
})
//#endregion

//#region Methods
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      items: [],
      selected: null,
      start: filter.range.start ?? currentDate,
      end: filter.range.end ??
        date.make().add(1, date.DURATION_INPUT.WEEKS).format(date.FORMAT.ISO_8601),
    }
  }
};

/**
 * Padrão do filtro principal
 * @returns {CreditReleaseListFilterType}
 */
function defaultFilter() {
  return {
    ...initCreditReleaseListFilterType(),
    release_status: null,
    payment_method_ids: null
  };
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = null;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.global = searchBarFilter.custom_search_values; // improviso do searchbar devido à api antiga
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
};

async function listItems() {
  progress.start();
  prepareFilter();
  loadingCreditRelease.value = true;
  await store.dispatch("creditRelease/fetchItems", {
    filter: filter,
    next_page: null
  })
  .finally(() => {
    loadingCreditRelease.value = false;
    progress.finish();
  });
}

/**
 * @param {TabSelectItemType} item
 */
 function onTabSelectItemClick(item) {
  if (filter.release_status !== item.id) {
    filter.release_status = item.id;
    listItems();
  }
}

/**
 * Verifica se liberação de crédito está desabilitada
 * para esta base.
 */
async function checkPendingCreditRelease() {
  await store.dispatch("generalSetting/show");

  if($_general_settings && $_general_settings.value.disable_pending_credit_release) {
    isDisablePendingCreditRelease.value = true;
  } else {
    isDisablePendingCreditRelease.value = false;
    clearFilter(true, true);
  }
}

//#endregion

//#region Lifecycle
onMounted(() => {
  checkPendingCreditRelease();
  store.dispatch("paymentMethod/fetchItems").then(() => loadingPaymentMethod.value = false);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
});
//#endregion
</script>

<style scoped>
</style>
