<template>
  <div class="row card-wrapper" v-if="$_purchase_requests_listed.items">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in $_purchase_requests_listed.items" :key="index">
      <div class="card main-card">
        <div class="p-3">
          <div>
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex">
                  <div @click="handleShowModalListItemsApproval(item)" class="align-self-center card-status mr-3 mb-1 pointer"
                    :class="getStatusClass(item.status)">
                    <img ref="icon" height="17" class="mr-1" :src="getStatusIcon(item.status)" />
                    {{ getStatusText(item.status) }}
                  </div>
                  <div style="margin-top: 3px;">
                    <!-- ICONES -->
                    <div class="d-flex pointer">
                      <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                        <img src="/img/icons/paperclip-green.png" width="22">
                      </div>
                      <div v-if="PurchaseRequestStatusEnum.keys.PENDING === item.status" class="mr-2" @click="handleShowModalListItemsApproval(item)">
                        <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                      </div>
                      <div v-if="item.has_approved_items && item.status !== PurchaseRequestStatusEnum.keys.PENDING" class="mr-2" @click="handleShowModalListItems(item, false)">
                        <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                      </div>
                      <div v-if="item.has_denied_items && item.status !== PurchaseRequestStatusEnum.keys.PENDING"
                        class="mr-2" @click="handleShowModalListItems(item, true)">
                        <img src="/img/icons/icons8/ios/menu-squared_danger.png" width="22" />
                      </div>
                      <div class="mr-2">
                        <el-popover trigger="click" placement="bottom" class="p-0">
                          <span class="font-weight-500">Solicitante:</span>
                          <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                          <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                          <span slot="reference">
                            <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                          </span>
                        </el-popover>
                      </div>
                      <div class="mr-2">
                        <el-popover trigger="click" placement="bottom" class="p-0">
                          <div class="p-0" style="max-width: 14rem;">
                            <span style="font-size: 14px; font-weight: 500;">Observações</span>
                            <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                            <div class="d-block mt-2">
                              <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                              <div style="font-size: 12px;" class="mt-1 info-content">{{ item.note }}</div>
                            </div>
                            <div class="d-block mt-2">
                              <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                              <div style="font-size: 12px;" class="mt-1 info-content">{{ item.purchase_justification }}</div>
                            </div>
                          </div>
                          <span slot="reference">
                            <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                          </span>
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex mt-2">
                  <span style="font-size: 20px ;font-weight: 500; color: #2B2D32;">
                    {{ 'RC-' + item.id }}
                  </span>
                </div>
                <div class="mt-1">
                  <span style="font-size: 16px ;font-weight: 400; color: #2B2D32;">{{ item.purchase_category_description
                  }}</span>
                </div>
              </div>

              <!-- Menu engrenagem -->
              <div>
                <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
                  <div slot="title-container" class="dropdown-toggle rounded">
                    <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
                  </div>
                  <div
                    v-if="item.status != PurchaseRequestStatusEnum.keys.CANCELED && item.status != PurchaseRequestStatusEnum.keys.APPROVED">
                    <a @click="redirectToEdit(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                      <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                      Editar
                    </a>
                    <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                  </div>
                  <a @click="handleShowModalHistory(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                    Histórico
                  </a>
                  <div @click="removePurchaseRequest(item)"
                    v-if="item.status != PurchaseRequestStatusEnum.keys.CANCELED && !item.has_active_budget">
                    <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                    <a class="dropdown-item d-flex" style="font-size: 12px;">
                      <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                      Cancelar
                    </a>
                  </div>
                </base-dropdown>
              </div>
            </div>
            <hr class="gray-divider">
            <div class="mt-1">
              <span style="font-size: 16px; font-weight: 400; color: #2B2D32;">
                {{ item.input_company_plant_name }}
              </span>
            </div>
            <div>
              <span style="font-size: 16px ;font-weight: 400; color: #2B2D32;">
                PRAZO: {{ item.deadline | parseDate("DD MMM YYYY") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval @fetchPurchaseRequests="fetchPurchaseRequests" ref="modalListItemsApproval" />
    <ModalListItems @fetchPurchaseRequests="fetchPurchaseRequests" ref="modalListItems" />
    <ModalAttachment ref="modalAttachment" />
    <ModalPurchaseRequestHistory ref="modalHistory" />
  </div>
</template>

<script setup>
//#region Imports
import { ref, defineEmits, computed } from "vue";
import ModalListItemsApproval from "./Shared/_ModalListItemsApproval.vue";
import ModalListItems from "./Shared/_ModalListItems.vue";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment.vue";
import ModalPurchaseRequestHistory from "./Shared/_ModalPurchaseRequestHistory.vue";
import PurchaseRequestStatusEnum from "../../enums/PurchaseRequestStatusEnum";
import purchaseRequestStore from "../../store/purchaseRequestStore";
import { dialogs } from "../../../../../../../helpers";
import router from "@/routes";
//#endregion

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

//#region ComponentRefs
const modalListItemsApproval = ref(null);
const modalListItems = ref(null);
const modalAttachment = ref(null);
const modalHistory = ref(null);
//#endregion

//#region Computeds
const $_purchase_requests_listed = computed(() => purchaseRequestStore.getters.getListed());
//#endregion

// #region Methods
function handleShowModalListItemsApproval(data) {
  modalListItemsApproval.value.openModal(data);
}
function handleShowModalListItems(data, isDeniedItems) {
  data.isDeniedItems = isDeniedItems;
  modalListItems.value.openModal(data);
}
function handleShowModalHistory(data) {
  data.modalTitle = "Histórico de Requisição";
  data.modalText = "Histórico de Requisição";
  modalHistory.value.openModal(data);
}

async function handleShowAttachments(id) {
  filter.value.purchase_request_id = id;
  const loader = dialogs.showLoading();
  const attachments = await getAttachments();
  modalRefs.value.modalAttachment.openAttachment(attachments.data.items);
  loader.hide();
}

async function getAttachments() {
  return await purchaseRequestStore.actions.fetchRequestAttachments(filter.value);
}

function redirectToEdit(item) {
  if (!item.can_approve) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Você não possui permissão para editar requisição!')
    return;
  }
  router.push('/purchase/request/edit/' + item.id);
}

function getStatusText(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "Pendente";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "Aprovado";
    case PurchaseRequestStatusEnum.keys.DENIED: return "Negado";
    default: return "Cancelado";
  }
}

function getStatusClass(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "pending-status";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "approved-status";
    case PurchaseRequestStatusEnum.keys.DENIED: return "denied-status";
    default: return "denied-status";
  }
}

function getStatusIcon(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "/img/icons/icons8/ios/thumb-up_primary.png";
    case PurchaseRequestStatusEnum.keys.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
    default: return "/img/icons/icons8/ios/cancel_danger.png";
  }
}

async function removePurchaseRequest(purchaseRequest) {
  if (purchaseRequest.has_active_budget) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Já foi iniciada a cotação dessa RC!')

    return;
  }

  const isConfirmed = await dialogs.confirmAction("Tem certeza que deseja CANCELAR essa requisição?");
  if (isConfirmed) {
    purchaseRequest.status = PurchaseRequestStatusEnum.keys.CANCELED;
    await purchaseRequestStore.actions.update(purchaseRequest);
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Requisição cancelada!');
    emit("fetch");
  }
}

function fetchPurchaseRequests() {
  emit("fetch");
}
//#endregion
</script>
<style scoped>
.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.info-content {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 3px 5px;
  margin-top: 1rem;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 12px;
  text-align: center;
  font-weight: 400 !important;
  margin-top: 0.1rem;
  width: 122px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 9px;
  margin-bottom: 7px;
}
</style>
