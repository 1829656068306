<template>
<div class="dropdown">
  <!-- botão puzl -->
  <button style="border-radius: 1.7rem !important;width: 100%" @click="handleMenu()" title="Menu" class="btn btn-primary btn-custom" type="button">
    <span class="custom-img"><img src="/img/brand/logo.png" width="35px"></span>
  </button>

  <!-- Menu principal -->
  <ul v-show="menu" class="dropdown-menu show custom-menu">
    <router-link :to="{
      path: '/commercial/contract-proposal/payments',
      name: 'commercial.contract-proposal.payments.view',
      params: { contract_proposal_uuid: contract_proposal.uuid }
    }">
      <li class="dropdown-item" v-if="contract_proposal.entity">
        <a class="dropdown-item custom-title text-gray">Recebimentos</a>
        <img style="width: 30px" src="/img/icons/card-in-use.png"/>
      </li>
    </router-link>

    <a @click.prevent="$router.push(`/commercial/contract-proposal/invoices/${contract_proposal.uuid}`)">
      <li class="dropdown-item" v-if="contract_proposal.entity">
        <a class="dropdown-item custom-title text-gray">Faturas</a>
        <img style="width: 30px" src="/img/icons/receipt-terminal.png"/>
      </li>
    </a>

    <a @click.prevent="$router.push(`/operational/contract-proposal/schedule/${contract_proposal.uuid}`)">
      <li class="dropdown-item" v-if="contract_proposal.entity">
        <a class="dropdown-item custom-title text-gray">Programações</a>
        <img style="width: 30px" src="/img/icons/calendar.png"/>
      </li>
    </a>

    <router-link :to="{
      path:
        '/commercial/contract-proposal/additional/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.additional.view',
      params: { contract_proposal_uuid: contract_proposal.uuid }
    }">
      <li class="dropdown-item">
        <a class="dropdown-item custom-title text-gray">Adicionais</a>
        <img style="width: 30px" src="/img/icons/plus-math.png"/>
      </li>
    </router-link>

    <router-link :to="{
      path: '/commercial/contract-proposal/services',
      name: 'commercial.contract-proposal.services.view',
      params: { contract_proposal_uuid: contract_proposal.uuid }
    }">
      <li class="dropdown-item">
        <a class="dropdown-item custom-title text-gray">Serviços</a>
        <img style="width: 30px" src="/img/icons/todo-list.png"/>
      </li>
    </router-link>

    <router-link :to="{
      path: '/commercial/contract-proposal/formulation',
      name: 'commercial.contract-proposal.formulation',
      params: { contract_proposal_uuid: contract_proposal.uuid }
    }">
      <li class="dropdown-item"><a class="dropdown-item custom-title text-gray">Traços</a>
        <img style="width: 30px" src="/img/icons/test-tube.png"/>
      </li>
    </router-link>
    <div class="dropdown-divider"></div>
    <li @click="handleMenuEdit()" class="dropdown-item">
      <a class="dropdown-item custom-title dropleft text-gray">Editar</a>
      <img style="width: 30px" src="/img/icons/edit.png"/>
    </li>
    <router-link :to="{ path: `/commercial/contract-proposal/edit/${contract_proposal.uuid}` }">
      <li class="dropdown-item">
        <a class="dropdown-item custom-title text-gray">Principal</a>
        <img style="width: 30px" src="/img/icons/home.png"/>
      </li>
    </router-link>
  </ul>

  <!-- menu de edição -->
  <ul v-show="menuEdit" class="dropdown-menu show custom-dropdown">
    <li class="dropdown-item" @click.prevent="handleEditConstruction()">
      <a class="dropdown-item text-gray">Obra</a>
      <span><i class="fa-solid fa-2x fa-helmet-safety text-orange"></i></span>
    </li>
    <li
      class="dropdown-item"
      @click.prevent="handleEditEntity(contract_proposal.entity.uuid)"
      v-if="contract_proposal.entity"
    >
      <a class="dropdown-item text-gray">Tomador</a>
      <span><i class="fa-solid fa-2x fa-hand-holding-dollar text-danger" /></span>
    </li>
  </ul>

  <!-- Menu lateral -->
  <ul v-show="menu" class="dropdown-menu show custom-list">
    <li>
      <div class="row">
        <div class="col-md-3 col-lg-3 custom-icon">
          <div class="custom-item">
            <button title="Crédito" type="button" class="btn base-button btn-success btn-sm icon-credit display-4" :disabled="contract_proposal.status === 0
                                || contract_proposal.status === 4
                                || contract_proposal.status === 5" @click.prevent="handleModalEntityCreditLimit()">
              <i class="ni ni-fat-add icon-credit-plus"></i>
            </button>
            <span>Crédito</span>
          </div>
        </div>
        <div title="Resumo" class="col-md-3 col-lg-3 custom-icon" @click.prevent="handleGeneralResume(contract_proposal.id)">
          <div class="custom-item">
            <i data-v-55d45a30="" class="fas fa-file text-info"></i>
            <span>Resumo</span>
          </div>
        </div>
        <div title="Imprimir" class="col-md-3 col-lg-3 custom-icon" @click.prevent="openModalPrint(contract_proposal)">
          <div class="custom-item">
            <i class="fa-solid fa-print text-default" />
            <span>Imprimir</span>
          </div>
        </div>
        <div title="Contatos" class="col-md-3 col-lg-3 custom-icon" @click.prevent="handleListContacts">
          <div class="custom-item">
            <i class="fas fa-id-card-alt text-warning"></i>
            <span>Contatos</span>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <ModalGeneralResume ref="modalGeneralResume" />
  <ModalPrintOut @open="openModalContractPrint" ref="ModalPrint" />
  <ModalContractPrint ref="modalContractPrint" />
  <ModalListContacts @updateConstruction="updateConstruction" ref="listContacts" />
  <ModalEditConstruction @updatedConstruction="updateConstruction" ref="editConstruction" />
  <ModalEditEntity @updatedEntity="set_entity" ref="editEntity" />
  <ModalEntityCreditLimit ref="modalEntityCreditLimit" />
</div>
</template>

<script>
import ModalGeneralResume from "../../Shared/_ModalGeneralResume.vue";
import ModalPrintOut from '../../Shared/_ModalPrintOut';
import ModalContractPrint from '../../Shared/_ModalContractPrint';
import ModalListContacts from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalListContacts";
import ModalEditConstruction from "@/views/Modules/Commercial/CustomerConstruction/Constructions/Shared/_Edit";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import ModalEntityCreditLimit from "@/views/Modules/Configuration/Entity/Shared/_ModalCreditLimit";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import entityFinancialStore from "../../../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";
import EntityFinancialHubEnum from '../../../../../../modules/configuration/entity/aggregates/entity-financial/enums/EntityFinancialHubEnum';

export default {
  name: 'FloatButton',
  components: {
    ModalGeneralResume,
    ModalPrintOut,
    ModalContractPrint,
    ModalListContacts,
    ModalEditConstruction,
    ModalEditEntity,
    ModalEntityCreditLimit
  },
  props: {
    contract_proposal: {
      type: {
        String,
        Number,
        Array,
        Object
      },
      default: null,
    }
  },
  data() {
    return {
      menu: false,
      menuEdit: false,
      ContractProposalEnum: ContractProposalEnum,
    }
  },
  methods: {
    handleMenu() {
      if (this.menu === true) {
        this.menuEdit = false;
      }

      this.menu = !this.menu;
    },
    handleMenuEdit() {
      this.menuEdit = !this.menuEdit;
    },
    handleGeneralResume(id) {
      this.$refs.modalGeneralResume.openModal(id);
    },
    openModalContractPrint() {
      this.$refs.modalContractPrint.openModal()
    },
    openModalPrint(contractProposal) {      
      this.$refs.ModalPrint.openModal(contractProposal)
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal.construction.uuid);
    },
    updateConstruction() {
      this.loadingConstruction = true
      this.$store.dispatch('customerConstructionConstructions/show', this.contract_proposal.construction.uuid).then(response => {
        this.contract_proposal.construction.contact = response.data.contact
        this.loadingConstruction = false
      })
    },
    handleEditConstruction() {
      this.$refs.editConstruction.handleEditModal(this.contract_proposal.construction.uuid);
    },
    handleEditEntity() {
      this.$refs.editEntity.openModal(true, this.contract_proposal.entity.uuid);
    },
    async handleModalEntityCreditLimit() {
      let loader = this.$loading.show();
      const has_history = true;
      this.$refs.modalEntityCreditLimit.openModal(this.contract_proposal.entity.id, this.contract_proposal.entity.document.length === 18, has_history);
      loader.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-custom {
  position: fixed !important;
  bottom: 60px;
  z-index: 100;
  max-width: 93px;
  height: 91px;
  transform: scale(0.7);
  border-radius: 50%;
  background-color: white !important;
  border: none;
  right: 0px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.span-custom {
  position: static !important;
  height: unset !important;
  border-radius: 50%;
}

.custom-img {
  position: static !important;
}

.btn-custom:hover {
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
}

.btn-custom:active {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important;
}

.custom-menu {
  position: fixed;
  top: unset;
  bottom: 105px;
  padding: 19px;
  width: 199px !important;
  min-width: unset;
  right: 17px;
  left: unset;

  li {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 0px;
  }
}

.custom-list {
  position: fixed;
  top: unset;
  bottom: 25px;
  padding: 19px;
  max-width: 420px !important;
  min-width: unset;
  right: 94px;
  left: unset;

  i {
    font-size: 24px;
  }
}

.custom-dropdown {
  position: fixed;
  top: unset;
  bottom: 150px;
  right: 202px;
  width: 155px !important;
  min-width: unset;
  left: unset;

  li {
    display: flex;
    padding: 2px 20px 2px 5px;
  }
}

.custom-item {
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    display: none;
  }

  i {
    align-self: center;
  }

}
.custom-title {
  font-size: 0.7rem;
}
.custom-fa {
  font-size: 1.7em;
}

@media(min-width: 540px) {
  .custom-menu {
    top: unset !important;
    bottom: 128px;
    width: 220px !important;
  }

  .btn-custom {
    transform: scale(0.75);
  }

  .custom-list {
    padding: 16px 30px;
    top: unset;
    bottom: 13px;

    i {
      font-size: 36px;
    }

  }

  .icon-credit {
    height: 36px !important;
    width: 28px !important;
  }

  .icon-credit-plus {
    font-size: 15px;
  }

  .custom-item>span {
    display: block;
  }

  .custom-dropdown {
    bottom: 165px;
    right: 243px;
  }

  .custom-title {
    font-size: 0.875rem;
  }

  .custom-fa {
    font-size: 2.0em;
  }
}

.row {
  flex-wrap: nowrap !important;
}

.icon-credit {
  width: 20px;
  display: flex;
  height: 24px;
  align-self: center;

  &:hover {
    transform: unset;
  }
}

.icon-credit-plus {
  font-size: 11px;
}
</style>
