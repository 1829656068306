<template>
    <div>
      <modal :show.sync="modal.edit" :backgroundColor="'##F2F7F3'" size="lg">
        <template slot="header">
          <div
            class="d-flex align-items-center container-fluid"
            style="gap: 12px; margin-left: -28px"
          >
            <div>
              <AppIcon
                icon="create"
                color="warning"
                width="24px"
                class="mr-2"
              />
            </div>
            <span class="text-title mt-n1">
              {{ modal.title }}
              <span v-if="contact.form_origin === 'construction'" class="text-title mt-n1">Obra</span>
              <span v-else class="text-title mt-n1">Cliente</span>
            </span>
          </div>
        </template>
        <template slot="close-button">
          <AppButton
            icon="close"
            width="auto"
            type="link"
            size="sm"
            :hasOutline="true"
            @click="closeModal()"
          />
        </template>
        
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(update)"
            autocomplete="off"
          >
            <div class="p-3">
              <!-- Seção de Identificação -->
              <div class="section-container mb-4">
                <div class="section-header d-flex align-items-center mb-3">
                  <div class="section-icon mr-2">
                    <AppIcon
                      icon="info-squared"
                      color="warning"
                      width="22px"
                    />
                  </div>
                  <h6 class="section-title mb-0">Identificação</h6>
                </div>
                
                <div class="section-content p-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="label-item">Receber e-mail financeiro</label>
                    <ToggleButton 
                      :initialValue="contact?.is_financial ?? 0" 
                      @update:initialValue="contact.is_financial = $event"
                    />
                  </div>

                  <!-- Formulário de dados do contato -->
                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Nome
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                        <input 
                          type="text" 
                          v-model="contact.name" 
                          class="form-control"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      E-mail
                      <span v-if="contact.is_financial" class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider :rules="contact.is_financial ? 'required|email' : ''" v-slot="{ errors }">
                        <base-input input-group-classes="input-group-sm">
                          <input 
                            type="email" 
                            v-model="contact.email" 
                            class="form-control"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Cargo
                    </label>
                    <div class="col-md-9">
                      <base-input input-group-classes="input-group-sm">
                        <input 
                          type="text" 
                          v-model="contact.office" 
                          class="form-control"
                        />
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Celular
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input 
                            type="text" 
                            v-model="contact.phone"
                            class="form-control" 
                            v-mask="'(##) #####-####'"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Seção de Assinatura Digital -->
              <div class="section-container mb-4">
                <div class="section-header d-flex align-items-center mb-3">
                  <div class="section-icon mr-2">
                    <AppIcon
                      icon="autograph"
                      color="primary"
                      width="22px"
                    />
                  </div>
                  <h6 class="section-title mb-0">Assinatura digital</h6>
                </div>

                <div class="section-content p-3 mt-n2">
                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Parte
                    </label>
                    <div class="col-md-9">
                      <base-input input-group-classes="input-group-sm">
                      <select 
                        id="contactSignatureType" 
                        class="form-control"
                        v-model="contact.signature_type"
                      >
                        <option value="nenhum">NENHUM</option>
                        <option value="contratante">CONTRATANTE</option>
                        <option value="testemunha">TESTEMUNHA</option>
                        <option value="fiador">FIADOR</option>
                      </select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="d-flex justify-content-end mt-4">
                <AppButton
                  label="Cancelar"
                  type="danger"
                  :hasOutline="true"
                  :disabled="invalid"
                  class="mr-2"
                  @click="closeModal()"
                />
                <AppButton
                  label="Salvar"
                  type="success"
                  native-type="submit"
                  :isDisabled="invalid"
                  :isLoading="loadingSave"
                />
              </div>
            </div>
          </form>
        </validation-observer>
      </modal>
    </div>
  </template>
  
  <script>
  import AppButton from "../../../../../../components/AppGlobal/AppButton/AppButton.vue";
  import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
  import ToggleButton from '../../../../../../components/ToggleButton';
  
  export default {
    name: "ModalEditContact",
    components: { 
      AppButton,
      AppIcon,
      ToggleButton,
    },
    data() {
      return {
        modal: {
          title: "Editar Contato",
          edit: false,
        },
        contact: {
          id: null,                 // ID para identificar o contato (usado para atualizar)
          uuid: null,               // UUID para contatos de construction
          name: '',                 // Obrigatório nas 2 tabelas
          office: '',               // Obrigatório nas 2 tabelas
          email: '',                // Opcional nas 2 tabelas
          phone: '',                // Opcional nas 2 tabelas
          cell_phone: '',           // Só existe em `contact_constructions`
          sector: '',               // Só existe em `contact_constructions`
          is_financial: false,       // Obrigatório nas 2 tabelas
          status: true,             // Só existe em `contact_constructions`
          signature_type: 'nenhum', // Opcional nas 2 tabelas
          is_user: false,           // Só existe em `contact_constructions`
          is_administrator: false,  // Só existe em `contact_constructions`
          is_technical: false,      // Só existe em `contact_constructions`
          is_commercial: false,     // Só existe em `contact_constructions`
          is_operational: false,    // Só existe em `contact_constructions`
          form_origin: null,        // Indica a origem do formulário ('entity' ou 'construction')
          entity_id: null,          // Só existe em `entity_contacts`
          construction_uuid: null,  // Só existe em `contact_constructions`
        },
        loadingSave: false,
        invalid: false,
      };
    },
    methods: {
      closeModal() {
        this.modal.edit = false;
      },
      /**
       * @param {string} form_origin 
       * @param {number|string} primary_key_contact
       */
      openModal(form_origin = null, primary_key_contact) {
        this.resetForm();
        
        // Definir a origem do formulário
        this.contact.form_origin = form_origin;
        
        if (form_origin === 'entity') {
          this.loadEntityContact(primary_key_contact);
        } else if (form_origin === 'construction') {
          this.loadConstructionContact(primary_key_contact);
        }
      },
      /**
       * Carrega os dados de um contato de entidade
       * @param {number} id
       */
      loadEntityContact(id) {
        this.$Progress.start();
        this.$store.dispatch("entityContact/showWithoutCommit", {id: id})
          .then((response) => {
            const contactData = response.data;

            this.contact = {
              ...this.contact,
              id: contactData.id,
              name: contactData.name,
              email: contactData.email || '',
              office: contactData.office || '',
              phone: contactData.phone || '',
              is_financial: !!contactData.is_financial,
              entity_id: contactData.entity_id,
              form_origin: 'entity',
              signature_type: contactData.signature_type || 'nenhum',
            };
            
            this.modal.edit = true;
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao carregar dados do contato'
            });
          })
          .finally(() => {
            this.$Progress.finish();
          });
      },
      /**
       * Carrega os dados de um contato de obra
       * @param {string} uuid
       */
      loadConstructionContact(uuid) {
        this.$Progress.start();
        
        this.$store.dispatch("customerConstructionConstructionsContact/show", uuid)
          .then((response) => {
            const contactData = response.data;
            
            this.contact = {
              ...this.contact,
              uuid: contactData.uuid,
              name: contactData.name,
              email: contactData.email || '',
              office: contactData.office || '',
              phone: contactData.cell_phone || contactData.phone || '',
              cell_phone: contactData.cell_phone || '',
              is_financial: !!contactData.is_financial,
              is_user: !!contactData.is_user,
              is_administrator: !!contactData.is_administrator,
              is_technical: !!contactData.is_technical,
              is_commercial: !!contactData.is_commercial,
              is_operational: !!contactData.is_operational,
              sector: contactData.sector || '',
              status: !!contactData.status,
              construction_uuid: contactData.construction_uuid,
              form_origin: 'construction',
              signature_type: contactData.signature_type || 'nenhum',
            };
            
            this.modal.edit = true;
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao carregar dados do contato'
            });
          })
          .finally(() => {
            this.$Progress.finish();
          });
      },
      update() {
        this.$Progress.start();
        this.loadingSave = true;
        
        if (this.contact.form_origin === 'entity') {
          this.updateEntityContact();
        } else if (this.contact.form_origin === 'construction') {
          this.updateConstructionContact();
        }
      },
      updateEntityContact() {
        // Preparar dados para envio
        const contactData = {
          id: this.contact.id,
          name: this.contact.name,
          email: this.contact.email,
          office: this.contact.office,
          phone: this.contact.phone,
          is_financial: this.contact.is_financial,
          entity_id: this.contact.entity_id,
          signature_type: this.contact.signature_type
        };
        
        this.$store.dispatch("entityContact/update", contactData)
          .then((response) => {
            this.$notify({
              type: 'success',
              message: 'Contato atualizado com sucesso!'
            });
            
            this.$emit('contactEntitySaved', {
              entityData:  response.data
            });

            this.closeModal();
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao atualizar contato'
            });
          })
          .finally(() => {
            this.loadingSave = false;
            this.$Progress.finish();
          });
      },
      updateConstructionContact() {
        // Garantir que cell_phone seja preenchido corretamente
        this.contact.cell_phone = this.contact.phone;
        
        // Preparar dados para envio
        const contactData = {
          uuid: this.contact.uuid,
          name: this.contact.name,
          email: this.contact.email,
          office: this.contact.office,
          cell_phone: this.contact.cell_phone,
          phone: this.contact.phone,
          is_financial: this.contact.is_financial,
          is_user: this.contact.is_user,
          is_administrator: this.contact.is_administrator,
          is_technical: this.contact.is_technical,
          is_commercial: this.contact.is_commercial,
          is_operational: this.contact.is_operational,
          sector: this.contact.sector,
          status: this.contact.status,
          construction_uuid: this.contact.construction_uuid,
          signature_type: this.contact.signature_type,
        };
        
        this.$store.dispatch("customerConstructionConstructionsContact/update", contactData)
          .then(() => {
            this.$notify({
              type: 'success',
              message: 'Contato atualizado com sucesso!'
            });
            this.closeModal();
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao atualizar contato'
            });
          })
          .finally(() => {
            this.loadingSave = false;
            this.$Progress.finish();
          });
      },
      resetForm() {
        this.contact = {
          id: null,
          uuid: null,
          name: '',
          office: '',
          email: '',
          phone: '',
          cell_phone: '',
          sector: '',
          is_financial: false,
          status: true,
          signature_type: 'nenhum',
          is_user: false,
          is_administrator: false,
          is_technical: false,
          is_commercial: false,
          is_operational: false,
          form_origin: null,
          entity_id: null,
          construction_uuid: null,
        };
      },
    }
  };
  </script>
  
  <style scoped>
  .section-container {
    border-radius: 8px;
    overflow: hidden;
  }
  
  .section-header {
    padding: 8px 12px;
  }
  
  .section-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .section-title {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }
  
  .section-content {
    background-color: white;
    border-radius: 8px;
    border: 1px solid #eaeaea;
  }

  .form-group select {
    background-color: white;
    height: 40px;
  }
  </style>