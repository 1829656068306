/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} ScheduleTravelType
 * @property {number} id - Identificador único do agendamento.
 * @property {string} uuid - UUID do agendamento.
 * @property {number} can_issue_mdfe - Indica se pode emitir MDF-e (0 ou 1).
 * @property {?number} retained_water - Quantidade de água retida, se aplicável.
 * @property {boolean} additional_exists - Indica se existem adicionais.
 * @property {number} index - Índice do agendamento.
 * @property {?string} observation - Observações sobre o agendamento.
 * @property {?string} automation_code - Código de automação, se aplicável.
 * @property {?string} xml - XML associado ao agendamento, se aplicável.
 * @property {number} status - Status do agendamento.
 * @property {?number} number - Número do agendamento, se criado.
 * @property {string} number_created_at - Data de criação do número do agendamento.
 * @property {?string} finished_at - Data de finalização do agendamento.
 * @property {string} schedule_uuid - UUID do cronograma associado.
 * @property {string} load_truck_time - Horário de carregamento do caminhão.
 * @property {string} arrival_time - Horário de chegada ao destino.
 * @property {string} plant_arrival_time - Horário de chegada à usina.
 * @property {string} plant_exit_time - Horário de saída da usina.
 * @property {string} construction_exit_time - Horário de saída da construção.
 * @property {string} free_for_travel - Horário de liberação para viagem.
 * @property {Plant} send_plant - Dados da usina de envio.
 * @property {Plant} return_plant - Dados da usina de retorno.
 * @property {string} volume - Volume transportado.
 * @property {string} accumulated - Volume acumulado.
 * @property {?string} equipment_uuid - UUID do equipamento utilizado, se aplicável.
 * @property {boolean} hardened_state - Indica se o concreto está endurecido.
 * @property {boolean} has_empty_fresh_test - Indica se houve teste de concreto fresco vazio.
 * @property {?string} equipment - Equipamento utilizado, se aplicável.
 * @property {?string} driver_uuid - UUID do motorista, se aplicável.
 * @property {?string} driver - Nome do motorista, se aplicável.
 * @property {?string} driver_document - Documento do motorista, se aplicável.
 * @property {?string} charge_point - Ponto de carga, se aplicável.
 * @property {string} end_load_truck_time - Horário de término do carregamento do caminhão.
 * @property {?string} accomplished_start_load_truck_time - Hora real de início do carregamento.
 * @property {?string} accomplished_end_load_truck_time - Hora real de término do carregamento.
 * @property {string} construction_stay_time - Tempo de permanência na construção.
 * @property {string} send_route_time - Tempo da rota de envio.
 * @property {string} return_route_time - Tempo da rota de retorno.
 * @property {string} ballast - Tempo de espera no balastro.
 * @property {string} load_truck_diff - Diferença no tempo de carregamento.
 * @property {string} travel_cycle - Tempo total do ciclo de viagem.
 * @property {string} concrete_cycle - Tempo do ciclo de concreto.
 * @property {CompanyPlant[]} company_plants - Lista de usinas da empresa.
 * @property {?string} cycle - Ciclo do concreto, se aplicável.
 * @property {boolean} isset_manual_dosage - Indica se a dosagem manual foi configurada.
 * @property {?string} water_control - Controle de água, se aplicável.
 * @property {Array} concrete_left_over - Lista de sobras de concreto.
 * @property {number} count_volume_left_over - Volume total de sobras de concreto.
 * @property {number} schedule_status - Status do cronograma.
 * @property {number} automation_status - Status da automação.
 * @property {?string} reuse - Informações de reuso, se aplicável.
 * @property {boolean} has_mcc - Indica se há MCC.
 * @property {boolean} allowed_charging_configuration - Indica se a configuração de carga é permitida.
 * @property {boolean} was_billed - Indica se foi faturado.
 * @property {number} should_automatically_fill - Indica se deve ser preenchido automaticamente.
 * @property {?string} finished_travel_at - Data de finalização da viagem, se aplicável.
 * @property {?string} user_finished_travel - Usuário que finalizou a viagem, se aplicável.
 * @property {?string} mdfe - Informações do MDF-e, se aplicável.
 * @property {?string} reuse_title - Título do reuso, se aplicável.
 * @property {?string} reuse_body - Corpo do reuso, se aplicável.
 * @property {?string} reuse_footer - Rodapé do reuso, se aplicável.
 * @property {?string} charge_point_uuid - UUID do ponto de carga, se aplicável.
 * @property {?string} updated_by_user_name - Nome do usuário que atualizou os dados, se aplicável.
 * @property {?string} schedule_travel_cycle_attachment_id - ID do anexo do ciclo de viagem, se aplicável.
 */

/**
 * @typedef {Object} Plant
 * @property {string} uuid - UUID da usina.
 * @property {number} id - Identificador da usina.
 * @property {string} name - Nome da usina.
 * @property {number} distance - Distância até a usina.
 */

/**
 * @typedef {Object} CompanyPlant
 * @property {string} uuid - UUID da usina da empresa.
 * @property {string} name - Nome da usina da empresa.
 * @property {string} max_volume - Volume máximo suportado.
 * @property {number} send_duration - Duração da rota de envio.
 * @property {number} return_duration - Duração da rota de retorno.
 */
export const ScheduleTravelType = {};

/**
 * Inicializar CompanyPlantBankAccountType
 *
 * @returns {ScheduleTravelType}
 */
export const initScheduleTravelType = () => {
  return {
    id: 0,
    uuid: '',
    can_issue_mdfe: 0,
    retained_water: null,
    additional_exists: false,
    index: 0,
    observation: null,
    automation_code: null,
    xml: null,
    status: 0,
    number: null,
    number_created_at: '',
    finished_at: null,
    schedule_uuid: '',
    load_truck_time: '',
    arrival_time: '',
    plant_arrival_time: '',
    plant_exit_time: '',
    construction_exit_time: '',
    free_for_travel: '',
    send_plant: {
      uuid: '',
      id: 0,
      name: '',
      distance: 0,
    },
    return_plant: {
      uuid: '',
      id: 0,
      name: '',
      distance: 0,
    },
    volume: '',
    accumulated: '',
    equipment_uuid: null,
    hardened_state: false,
    has_empty_fresh_test: false,
    equipment: null,
    driver_uuid: null,
    driver: null,
    driver_document: null,
    charge_point: null,
    end_load_truck_time: '',
    accomplished_start_load_truck_time: null,
    accomplished_end_load_truck_time: null,
    construction_stay_time: '',
    send_route_time: '',
    return_route_time: '',
    ballast: '',
    load_truck_diff: '',
    travel_cycle: '',
    concrete_cycle: '',
    company_plants: [],
    cycle: null,
    isset_manual_dosage: false,
    water_control: null,
    concrete_left_over: [],
    count_volume_left_over: 0,
    schedule_status: 0,
    automation_status: 0,
    reuse: null,
    has_mcc: false,
    allowed_charging_configuration: false,
    was_billed: false,
    should_automatically_fill: 0,
    finished_travel_at: null,
    user_finished_travel: null,
    mdfe: null,
    reuse_title: null,
    reuse_body: null,
    reuse_footer: null,
    charge_point_uuid: null,
    updated_by_user_name: null,
    schedule_travel_cycle_attachment_id: null,
  };
};

