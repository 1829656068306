import ServiceDescriptionListPage from "../views/list/ServiceDescriptionListPage.vue";

export default [
  {
    path: '/commercial/service-descriptions',
    name: 'commercial.service-description.view',
    component: ServiceDescriptionListPage,
    meta: {
      title: 'Comercial',
      description: 'Descrição de Serviços',
      icon: 'fa fa-home',
      permission: 'commercial.service-desciption.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'commercial.service-desciption.dashboard.index'},
        {title: 'Descrição de Serviços', name: null},
      ]
    },
  },
];
