<template>
  <div class="container-fluid"
    v-if="!loadingSkeleton && travels.length">
    <div class="row">
      <div class="col-md-12 table-responsive mt-2">
        <table class="table-layout-3-1">
          <thead>
            <tr>
              <th style="width: 15px;">
                <div class="column-head-layout-3-1">
                  Status
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Volume
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  Acumulado
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  Hora
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Motorista
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Caminhão
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Central
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Ponto de carga
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  O.S
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  NFe
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  MDFe
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  INFO
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Ação
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="(travels.length || travels) && !loadingSkeleton"
              v-for="(travel, indexTravel) in travels" :key="indexTravel">
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <StatusBadge
                      minWidth="140"
                      :status="travel.status"
                    />
                    <div class="d-flex align-items-center ml-2">
                      <div class="icon-spot"
                           :style="(loadingSchedule || loadingSkeleton) ? 'opacity: 0.4' : ''"
                           @click.prevent="
                        !(loadingSchedule || loadingSkeleton)
                        ? $emit('handleTravelCycle', travel.uuid, Schedule.address_construction)
                        : ''
                      "
                      >
                        <AppIcon
                          icon="clock"
                          color="success"
                          title="tempo de carregamento"
                          class="ml-2"
                          width="20"
                        />
                      </div>

                      <template v-if="[scheduleTravelEnum.FINISHED, scheduleTravelEnum.CANCELED, scheduleTravelEnum.UNREALIZED].includes(travel.status)">
                        <div class="icon-spot"
                             :style="(!travel.isset_manual_dosage || travel.concrete_left_over.length) ? 'opacity: 0.4' : ''"
                             @click.prevent="handleWaterControl(travel.uuid, indexTravel)">
                          <AppIcon
                            icon="water"
                            color="primary"
                            title="Traços"
                            class="ml-2"
                            width="20"
                          />
                          <span
                            v-if="!travel.water_control || emptyWaterControl(travel.water_control)"
                            class="badge badge-md badge-circle badge-floating badge-warning border-white">
                          <i class="fas fa-exclamation"/>
                        </span>
                        </div>

                        <div class="icon-spot"
                             :style="!travel.isset_manual_dosage ? 'opacity: 0.4' : ''"
                             @click.prevent="handleFreshStateTest(travel.uuid, indexTravel)">
                          <AppIcon
                            icon="vlc"
                            color="warning"
                            title="Paradas"
                            class="pointer ml-2"
                            width="20"
                          />
                          <span
                            v-if="travel.has_empty_fresh_test"
                            class="badge badge-md badge-circle badge-floating badge-warning border-white">
                          <i class="fas fa-exclamation"/>
                        </span>
                        </div>
                      </template>

                      <div class="icon-spot"
                           @click.prevent="handleModalScheduleTravelCycleAttchment(travel.cycle.id)">
                        <AppIcon
                          v-if="travel.schedule_travel_cycle_attachment_id"
                          icon="attach"
                          color="success"
                          title="Anexo"
                          class="pointer ml-2"
                          width="20"
                        />
                      </div>

                      <div class="icon-spot"
                           v-show="travel.automation_code"
                           @click.prevent="$emit('copy', travel.automation_code)">
                        <AppIcon
                          icon="copy-to-clipboard"
                          color="primary"
                          title="Copiar código automação"
                          class="pointer ml-2"
                          width="20"
                        />
                      </div>
                      <img src="/img/brand/logo.png"
                           width="20"
                           class="pointer ml-2"
                           @click.prevent="showAdjustments(travel)"
                           alt="logo">
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <h4 class="m-0 new-default-black-font">
                      {{ travel.volume }}
                    </h4>
                    <p class="m-0 new-default-black-font text-lowercase h4 ml-1">m</p>
                    <h4 class="m-0 new-default-black-font">
                      ³
                    </h4>
                    <p class="new-default-black-font font-weight-normal h4 my-0 ml-2">
                      ({{ indexTravel + 1 }})
                    </p>
                    <AppIcon
                      v-if="enableEdit(travel)"
                      @click.prevent="$emit('handleVolumeEdit', travel)"
                      icon="create"
                      color="warning"
                      width="20"
                      class="pointer ml-3 mr-2"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center justify-content-center">
                    <h4 class="m-0 new-default-black-font font-weight-normal">
                      {{ travel.accumulated }}
                    </h4>
                    <p class="m-0 new-default-black-font text-lowercase h4 ml-1  font-weight-normal">m</p>
                    <h4 class="m-0 new-default-black-font font-weight-normal">
                      ³
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 new-default-black-font font-weight-normal text-center">
                    <template v-if="travel.status !== 1">
                      {{ travel.load_truck_time ? travel.load_truck_time.slice(-5) : "" }}
                    </template>
                    <template v-if="travel.status === 1 && travel.cycle">
                      {{ travel.cycle.start_load_truck_time ? travel.cycle.start_load_truck_time.slice(11, -3) : "" }}
                      -
                      {{ travel.cycle.free_for_travel ? travel.cycle.free_for_travel.slice(11, -3) : "" }}
                    </template>
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 new-default-black-font font-weight-normal">
                    {{ travel.driver }}
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 new-default-black-font font-weight-normal"
                    v-if="travel.equipment">
                    {{ travel.equipment.code }} ({{ travel.equipment.plate }})
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <h4 class="m-0 new-default-black-font font-weight-normal">
                      {{ travel.send_plant.short_name }}
                    </h4>
                    <AppIcon
                      icon="sorting-arrows-horizontal"
                      :color="travel.send_plant.uuid !== travel.return_plant.uuid ? 'danger' : 'primary'"
                      title="centrais"
                      class="pointer mx-2"
                      width="15"
                    />
                    <h4 class="m-0 new-default-black-font font-weight-normal"
                      v-if="travel.send_plant.uuid !== travel.return_plant.uuid">
                      {{ travel.return_plant.short_name }}
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 v-if="travel.charge_point" class="m-0 new-default-black-font font-weight-normal">
                    {{ travel.charge_point }}
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center justify-content-center"
                       v-if="travel.number"
                       @click.prevent="downloadProof(travel)">
                    <DocumentPlacement
                      isTransparent
                      icon="/img/icons/icons8/ios/os.png"
                      :text="travel.number"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center justify-content-center"
                       v-if="travel.xml"
                       @click.prevent="downloadDanfe(travel.uuid, travel.xml)">
                    <DocumentPlacement
                      isTransparent
                      :icon="getTextXml(travel.xml).icon"
                      :text="getTextXml(travel.xml).title"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center justify-content-center"
                       v-if="travel.mdfe"
                       @click.prevent="downloadMdfe(travel.mdfe)">
                    <DocumentPlacement
                      isTransparent
                      :icon="getTextMDFe(travel.mdfe).icon"
                      :text="getTextMDFe(travel.mdfe).title"
                    />
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center justify-content-center">
                    <CustomPopover
                      v-if="travel.concrete_left_over.length > 0"
                      title="Reaproveitamento - Reuso"
                      width="340"
                      top="18px"
                      right="0">
                      <AppIcon
                        icon="recycling"
                        color="success"
                        title="Reciclagem"
                        class="mr-2 pointer"
                        width="20"
                        slot="reference"
                      />
                      <div class="custom-popover-body">
                        <div class="reuse-container-card" v-for="(leftOver, index) in travel.concrete_left_over">
                          <div class="container">
                            <div class="row my-1">
                              <div class="col-12">
                                <div class="d-flex align-items-center">
                                  <AppIcon
                                    icon="recycling"
                                    :color="borderLeftOver[`${leftOver.category}${leftOver.category_type}`]"
                                    title="Reciclagem"
                                    class="pointer"
                                    width="20"
                                    slot="reference"
                                  />
                                  <h6 class="m-0 new-default-black-font ml-2">
                                    {{ categories[`${leftOver.category}${leftOver.category_type}`] }}
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-12">
                                <h6 class="m-0 new-default-black-font">
                                  Quantidade: {{ leftOver.volume }} m³
                                </h6>
                                <h6 class="m-0 new-default-gray-font"
                                    v-for="(reason, index) in leftOver.schedule_travel_left_over_reason"
                                    :key="index">
                                  {{ reason.left_over_adjustment.reason }}
                                </h6>
                              </div>
                            </div>
                            <div class="row mt-3">
                              <div class="col-6 pr-2">
                                <AppButton
                                  @click.prevent="$emit('handleEditConcreteLeftOver', travel, leftOver, travel.count_volume_left_over)"
                                  class="reuse-button200220252053"
                                  label="Editar"
                                  icon="create"
                                  :size="buttonSizeEnum.keys.SM"
                                  isBlock
                                  hasOutline
                                  type="warning" />
                              </div>
                              <div class="col-6 pl-2">
                                <AppButton
                                  @click.prevent="
                                    $emit('handleDeleteConcreteLeftOver', leftOver, {
                                      index: indexTravel,
                                      index_left_over: index,
                                    })
                                  "
                                  class="reuse-button200220252053"
                                  label="Remover"
                                  :size="buttonSizeEnum.keys.SM"
                                  icon="trash-can"
                                  isBlock
                                  hasOutline
                                  type="danger" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomPopover>
                    <CustomPopover
                      v-if="travel.additional_exists"
                      width="180"
                      top="24px"
                      right="4px"
                      title="adicionais">
                      <AppIcon
                        @click.once="getAdditionals(travel.uuid)"
                        icon="add-new"
                        color="warning"
                        title="adicionar"
                        class="mr-2 pointer"
                        width="20"
                        slot="reference"
                      />
                      <div class="custom-popover-body">
                        <div class="row" v-if="loadingAdditionals.includes(travel.uuid)">
                          <div class="col-12" v-for="i in 3" :key="i">
                            <skeleton-puzl type="text"></skeleton-puzl>
                          </div>
                        </div>
                        <template v-else>
                          <template v-if="travel.additionals && travel.additionals.length"
                                    v-for="(additional, index) in travel.additionals">
                            <h6 class="m-0 new-default-black-font">
                              {{ additional.additional.short_description }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font">
                              {{ additional.reason }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font">
                              {{ additional.additional.standard_value }} {{ additional.additional.billing_unit_description }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font" v-if="additional.justification">
                              <el-popover trigger="hover" placement="top" class="p-0">
                                <span>{{ additional.justification || "Justificativa não informada" }}</span>
                                <badge v-if="!additional.status"
                                  class="fs-10 font-weight-normal mt-2"
                                  slot="reference"
                                  type="danger"> CANCELADO </badge>
                              </el-popover>
                            </h6>
                            <hr v-if="index < travel.additionals.length-1"
                                class="m-2">
                          </template>
                          <template v-else>
                            <h6 class="m-0 new-default-gray-font">
                              Nenhum adicional vinculado.
                            </h6>
                          </template>
                        </template>
                      </div>
                    </CustomPopover>
                    <CustomPopover
                      :title="setTitleReuse(travel.reuse.category_id)"
                      v-if="travel.reuse"
                      width="340"
                      top="18px"
                      right="-10px">
                      <AppIcon
                        icon="recycling"
                        :color="travel.reuse.category === 1 ? 'success' : 'warning'"
                        title="Reciclagem"
                        class="pointer"
                        width="20"
                        slot="reference"
                      />
                      <div class="custom-popover-body">
                        <h6 class="m-0 new-default-black-font">
                          <div class="d-flex align-items-center">
                            <img width="24" src="/img/icons/os.png"/>
                            <h4 class="m-0 ml-2 font-weight-400 new-default-black-font">
                              {{ travel.reuse.number }}
                            </h4>
                          </div>
                        </h6>
                        <h6 class="mx-0 my-1 new-default-gray-font">
                          Quantidade: {{ travel.reuse.volume.replace(".", ",") }}m<sup>3</sup>
                        </h6>
                        <hr class="m-2">
                        <h6 class="m-0 new-default-black-font">
                          <div class="d-flex align-items-center">
                            <img src="/img/icons/test-tube.png" width="20">
                            <h4 class="m-0 ml-2 font-weight-400 new-default-black-font">
                              {{ travel.reuse_title }}
                            </h4>
                          </div>
                        </h6>
                        <h6 class="mx-0 my-1 new-default-gray-font">
                          {{ travel.reuse_body }}
                        </h6>
                        <h6 class="m-0 new-default-gray-font">
                          {{ travel.reuse_footer }}
                        </h6>
                      </div>
                    </CustomPopover>
                    <div class="test-active-spot"
                         v-if="![scheduleTravelEnum.FINISHED, scheduleTravelEnum.CANCELED, scheduleTravelEnum.UNREALIZED].includes(travel.status)"
                         @click.prevent="(travel.status === 1 || loadingHardenedState || loadingSchedule) ? '' : toggleTestButton(travel)"
                         :class="{'active': travel.hardened_state, 'disabled': (travel.status === 1 || loadingHardenedState || loadingSchedule)}">
                      <AppIcon
                        icon="impossible-shapes"
                        color="primary"
                        title="Testes"
                        class="pointer"
                        width="20"
                      />
                      <h5>
                        Ensaio
                      </h5>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <SettingsOptions
                    :iconSize="25"
                    @eventHandler="eventHandler"
                    :operationalPermission="$_operational_permission"
                    :travel="travel"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {base_url_ms} from "@/plugins";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ModalStoreMDFe from '../../../Schedule/Charging/Shared/_ModalStoreMDFe';
import StatusBadge from "./StatusBadge.vue";
import {AppButton, AppIcon} from "@/components/AppGlobal";
import DocumentPlacement from "@/views/Modules/Operational/Schedule/Weighing/Shared/DocumentPlacement.vue";
import CustomPopover from "@/views/Modules/Operational/Schedule/Weighing/Shared/CustomPopover.vue";
import ButtonSizeEnum from "@/components/AppGlobal/AppButton/enums/ButtonSizeEnum";
import SettingsOptions from "@/views/Modules/Operational/Schedule/Weighing/Shared/SettingOptions.vue";
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";

export default {
  name: "Table",
  components: {
    SettingsOptions,
    AppButton,
    CustomPopover,
    DocumentPlacement,
    AppIcon,
    StatusBadge,
    ModalStoreMDFe,
    SkeletonPuzl
  },
  props: {
    loadingSchedule: Boolean,
    loadingSkeleton: Boolean,
    downloadProof: Function,
    handleWaterControl: Function,
    emptyWaterControl: Function,
    showAdjustments: Function,
    handleFreshStateTest: Function,
    handleModalScheduleTravelCycleAttchment: Function,
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      travels: "travels/fetch",
      Schedule: "schedule/show",
    }),
  },
  data() {
    return {
      base_url_ms,
      buttonSizeEnum: ButtonSizeEnum,
      scheduleTravelEnum: ScheduleTravelEnum,
      loadingAdditionals: [],
      loadingHardenedState: false,
      status: {
        0: "released",
        1: "finished",
        2: "loading",
        4: "waiting_for_release",
        5: "in_progress",
        6: "canceled",
        7: "unrealized",
        8: "has_pendency",
        9: "seller_request",
      },
      status_color: {
        0: "text-success-light",
        1: "text-primary",
        2: "text-indigo",
        3: "text-indigo",
        4: "text-warning",
        5: "text-info",
        6: "text-danger",
        7: "text-danger",
        8: "text-warning",
      },
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
      borderLeftOver: {
        11: "success",
        12: "primary",
        21: "warning",
        22: "danger",
      },
    };
  },
  methods: {
    enableEdit(travel) {
      return travel.status !== this.scheduleTravelEnum.FINISHED &&
        travel.status !== this.scheduleTravelEnum.IN_PROGRESS &&
        travel.status !== this.scheduleTravelEnum.CANCELED &&
        travel.status !== this.scheduleTravelEnum.UNREALIZED &&
        travel.status !== this.scheduleTravelEnum.LOADING &&
        travel.isset_manual_dosage === false;
    },
    eventHandler(func, args){
      this.$emit(func, args);
    },
    toggleTestButton(travel){
      travel.hardened_state = !travel.hardened_state;
      this.setHardenedState(travel);
    },
    setHardenedState(travel) {
      let params = {
        schedule_travel_uuid: travel.uuid,
        hardened_state: travel.hardened_state,
        features: this.Schedule.contract_proposal_formulation.features,
      };
      this.loadingHardenedState = true;
      this.$store
        .dispatch("travelConfiguration/storeDefaultHardenedStates", params)
        .then((response) => {
          this.loadingHardenedState = false;
          this.$notify({type: "success", message: response.message});
        })
        .catch(() => (this.loadingHardenedState = false));
    },
    setTitleReuse(type) {
      return this.categories[type];
    },
    getAdditionals(travel_uuid) {
      this.loadingAdditionals.push(travel_uuid);
      this.$Progress.start();
      let params = {
        filter: {
          schedule_travel_uuid: travel_uuid,
        },
      };
      this.$store
        .dispatch("scheduleAdditional/fetchItems", params)
        .then((response) => {
          this.travels.find((item) => item.uuid === travel_uuid).additionals = response.data;
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, 1);
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, travel_uuid);
          this.$Progress.finish();
          this.$notify({
            type: error.error_type,
            message: error.message,
          });
        });
    },
    getTextMDFe(mdfe) {
      switch (mdfe.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/mdfesefaz.png"
          }
        case 1:
          return {
            title: "n. " + mdfe.number,
            class: "new-default-black-font",
            icon: "/img/mdfesefaz.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
        case 3:
          return {
            title: "n. " + mdfe.number,
            class: "text-success",
            icon: "/img/mdfesefaz.png"
          }
        case 4:
          return {
            title: "n. " + mdfe.number,
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
      }
    },
    downloadMdfe(mdfe) {
      let loader = this.$loading.show();
      const url = this.base_url_ms + 'download-s3?url=' + mdfe.pdf_file_path
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", mdfe.chNFe +'.pdf');
        await link.click()
      });
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
    handleClearMcc(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$Progress.start()
          this.$store
            .dispatch("travels/deleteClearMcc", {uuid: uuid, force: false})
            .then(response => {
              this.$notify({type: 'success', message: response.message})
            })
            .catch(error => {
              this.$notify({type: 'danger', message: error.data.message})
            }).finally(() => {
            this.$Progress.finish();
            loader.hide()
            this.$parent.init()
          });
        }
      }).catch()
    },
    handleShowModalStoreMDFe(schedule_travel) {
      this.$refs.ModalStoreMDFe.openModal({
        schedule_travel_id: schedule_travel.id,
        name: schedule_travel.driver,
        document: schedule_travel.driver_document
      })
    },
    async downloadDanfe(schedule_travel_uuid, xml) {
      if (xml.status !== 1) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml.key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
    getStatus(status) {
      switch (this.status[status]) {
        case "released":
          return "success";
        case "finished":
        case "in_progress":
          return "primary";
        case "canceled":
          return "danger";
        default:
          return "light";
      }
    },
    getStatusLabel(status) {
      switch (this.status[status]) {
        case "released":
          return "Liberado";
        case "finished":
          return "Concluído";
        case "loading":
          return "Carregando";
        case "waiting_for_release":
          return "Aguardando liberação";
        case "in_progress":
          return "Em andamento";
        case "canceled":
          return "Cancelado";
        case "unrealized":
          return "Não realizado";
        case "has_pendency":
          return "Pendente";
        case "seller_request":
          return "Solicitação de vendedor";
      }
    },
   getTextXml(xml) {
      switch (xml.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
        case 1:
          return {
            title: "n. " + xml.number,
            class: "new-default-black-font",
            icon: "/img/nfe.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/nfe.png"
          }
        case 3:
          return {
            title: "Cancelando NF-e",
            class: "text-warning",
            icon: "/img/nfe_in_cancelation_progress.png"
          }
        case 4:
          return {
            title: "n. " + xml.number,
            class: "text-danger",
            icon: "/img/nfe%20cancelada%20icon.svg"
          }
        case 5:
          return {
            title: "Duplicada",
            class: "text-primary",
            icon: "/img/nfe.png"
          }
        case 6:
          return {
            title: "Denegada",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.table-layout-3-1 {
  width: 100%;
  border-collapse: collapse;

  h4 {
    font-size: 12px;
  }

  .icon-spot {
    display: inline-flex;
    cursor: pointer;

    span.badge {
      position: relative;
      margin-left: -9px;
      height: 16px;
      width: 16px;

      i.fa-exclamation {
        font-size: 10px;
      }
    }
  }

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }

    .reuse-container-card {
      margin-top: 8px;
      border: 1px solid #f4f4f4;
      border-radius: 8px;
      padding: 18px 5px;

      h5, h6 {
        padding-left: 0;
      }
    }
  }

  thead {
    tr {
      th {
        text-align: left;
        border: none;
        padding: 0;

        div.column-head-layout-3-1 {
          background: $muted-light;
          border: 1px solid #DFDFDF;
          border-right: none;
          border-left: none;
          color: $dark;
          font-size: 12px;
          font-weight: 500;
          padding: 10px;
        }

        &:first-child div.column-head-layout-3-1 {
          border-top-left-radius: 8px;
          border-left: 1px solid #DFDFDF;
        }

        &:last-child div.column-head-layout-3-1 {
          border-top-right-radius: 8px;
          border-right: 1px solid #DFDFDF;
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 2px solid $muted-light;

      td {
        text-align: left;
        border: none;
        padding: 0;

        div.column-body-layout-3-1 {
          background: white;
          padding: 12px;
          height: 50px;
          box-shadow: 10px 5px 10px 0 #00000026;
        }
      }

      &:last-child {

        :first-child div.column-body-layout-3-1 {
          border-bottom-left-radius: 8px;
        }

        :last-child div.column-body-layout-3-1 {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
</style>
