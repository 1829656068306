<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in $_financial_approval_listed.items" :key="item.id">
        <div class="card main-card mb-3">
          <div class="card-content">
            <div class="d-flex justify-content-between">
              <div>
                <div class="d-flex align-items-center">
                  <!-- status -->
                  <div class="status-badge mr-3" :style="getCardStatus(item).color">
                    <img class="mr-2" ref="icon" height="17" :src="getCardStatus(item).icon" />
                    {{ getCardStatus(item).text }}
                  </div>
                  <el-popover trigger="hover" placement="right">
                    <div class="d-flex mb-2" style="gap: 15px; font-weight: 500; color: #2b2d32">
                      <span class="py-1">INFORMAÇÕES</span>
                    </div>
                    <hr style="margin-left:-12px;margin-right:-12px;" class="mt-2 mb-3" />
                    <!-- Solicitante -->
                    <div class="row m-0"> 
                      <AppIcon class="mr-2" icon="male-user" color="dark" width="21" />
                      <span style="padding-top:2px" class="mb-0 new-default-black-font">Solicitante</span>
                      <div style="border-radius: 0.375rem;" class="w-100 border py-2 px-3 fs-12 mt-2"> 
                        <span class="popover-text">{{ item.user_name }}</span> 
                        <br>
                        <span class="popover-text">{{ date.make(item.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}</span>
                      </div>
                    </div>

                    <!-- Central -->
                    <div class="row ml-0 mr-0 mt-3"> 
                      <AppIcon class="mr-2" icon="chemical-plant" color="dark" width="21" />
                      <span style="padding-top:2px" class="mb-0 new-default-black-font">Central</span>
                      <div style="border-radius: 0.375rem;" class="w-100 border py-2 px-3 fs-12 mt-2"> 
                        <span class="popover-text">{{ item.company_plant }}</span> 
                      </div>
                    </div>

                    <div
                      class="d-flex"
                      slot="reference"
                      size="sm"
                      type="secundary"
                      style="box-shadow: none"
                    >
                      <AppIcon class="" icon="info" color="warning" width="21" />
                    </div>
                  </el-popover>
                  <div class="ml-2" v-if="item.exists_attachments" @click.prevent="handleShowAttachments(item)">
                    <img class="icon-img" width="25px" src="/img/icons/attach-green.png" />
                  </div>
                  <el-popover v-if="item.nsu_code && item.is_nsu_code_duplicated" trigger="hover" placement="right"
                    class="p-0 pr-0 ml-2">
                    <span class="text-uppercase new-default-black-font">Duplicidade de código</span>
                    <base-button outline slot="reference" size="sm" type="secundary"
                      class="p-0 m-0 shadow-none text-indigo">
                      <img class="mr-1" src="/img/icons/icons8/ios/break--v2_warning.png" width="26">
                    </base-button>
                  </el-popover>
                  <div
                    v-if="item.nsu_code && item.payment_intermediary_has_credentials === 1 || item.payment_intermediary_accept_txt_file === 1"
                    class="font-weight-bold ml-2" style="cursor: pointer;">
                    <img @click="handleModalRedeRevalidate(item.id, item.payment_intermediary_id)"
                      v-if="item.card_integration_status === 0" title="Pendente"
                      src="/img/icons/icons8/ios/api_warning.png" width="22">
                    <img v-if="item.card_integration_status === 1" src="/img/icons/icons8/ios/api_success.png"
                      title="Integrado" width="22">
                    <img @click="handleModalRedeRevalidate(item.id, item.payment_intermediary_id)"
                      v-if="item.card_integration_status === 2" src="/img/icons/icons8/ios/api_danger.png"
                      title="Divergente" width="22">
                  </div>
                  
                  <!-- Integrações -->
                  <el-popover v-if="item.sync_error_message || item.sync_integration_external_id" trigger="hover" placement="right" class="p-0 pr-0 ml-2"> 
                    <span v-if="item.sync_error_message" class="text-uppercase new-default-black-font"> Erro : {{ item.sync_error_message }}</span>
                    <span v-else class="text-uppercase new-default-black-font"> Integrado: {{ item.sync_integration_external_id }}</span>
                    <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none text-indigo">
                      <AppIcon class="mb-n1" icon="connected" :color="item.sync_error_message ? 'danger' : 'success'" width="24" />
                    </base-button>
                  </el-popover>
                </div>
              </div>
              <div class="text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="34" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a @click.prevent="handleEditEntity(item)" class="dropdown-item edit-item">
                    <img width="18" src="/img/icons/create-new.png" />
                    EDITAR
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <!-- Totvs -->
                  <div v-if="hasTotvs && (item.approved_value > 0 || item.pay_value > 0)"
                    class="dropdown-divider p-0 m-0"></div>
                  <a @click="handleModalEntityBillReceiveIntegrationInfo(item.id)" v-if="hasTotvs"
                    class="dropdown-item">
                    <img src="/img/icons/icons8/ios/downloading-updates-primary.png" width="22px" height="22px" />
                    Totvs
                  </a>
                  <div
                    v-if="hasPermission('bill_receive.anticipation_balance_transfer') && item.anticipation_balance > 0">
                    <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                    <a class="dropdown-item d-flex"
                      @click.prevent="handleModalAnticipationBalanceTransfer(item.entity_uuid)"
                      style="font-size: 12px;">
                      <img width="15" src="/img/icons/sorting-arrows-horizontal-red.png">
                      Transferir
                    </a>
                  </div>
                  <div v-if="item.contract_proposal_uuid" class="dropdown-divider p-0 m-0"></div>
                  <a 
                    v-if="item.contract_proposal_uuid" 
                    @click="$router.push(`/commercial/contract-proposal/payments/${item.contract_proposal_uuid}`);"
                    class="dropdown-item"
                  >
                    <AppIcon class="mr-2" icon="external-link-squared" color="dark" width="15" />
                    Acessar contrato
                  </a>
                  <div v-if="!item.pay_value > 0 && !item.canceled_value > 0 && hasPermission('bill_receive.cancel_installment')" class="dropdown-divider p-0 m-0"></div>
                  <a 
                    v-if="!item.pay_value > 0 && !item.canceled_value > 0 && hasPermission('bill_receive.cancel_installment')" 
                    @click.prevent="updateStatus(item.bill_receive_id, EntityBillReceiveInstallmentEnum.CANCELED)"
                    class="dropdown-item"
                  >
                    <AppIcon class="mr-2" icon="cancel" color="danger" width="15" />
                    Cancelar
                  </a>
                </base-dropdown>
              </div>
            </div>
            <div class="bank-account">
              {{ item.bank_account }}
            </div>
            <div class="d-flex">
              <div class="payment-method">
                {{ item.payment_method }}
                <span v-if="item.nsu_code" class="nsu-code">{{ item.nsu_code }}</span>
              </div>
              <div class="payment-term ml-2">
                {{ item.payment_term }}
              </div>
            </div>

            <!-- <div class="row mt-2">
              <div class="col-12">
                <h4 class="item-name">{{ item.name }}</h4>
              </div>
            </div> -->
            <div class="d-flex align-items-center">

              <div class="value-card d-flex align-items-end">
                <p style="font-size: 16px; line-height: 12px;"
                  class="m-0 new-default-black-font font-weight-bold p-0 mr-1 mt-1">
                  R$
                </p>
                {{ $helper.toMoneyWithoutCurrencyLayout(item.total_value) }}
              </div>
            </div>

            <div class="competence" v-if="item.user_name">
              {{ $helper.parseDate(item.competence, "DD MMM YYYY") }}
            </div>
            <div class="divider"></div>
            <h2 class="new-default-black-font mb-0" style="line-height:normal">
              {{ item.contract_proposal_code ?? '-' }}
            </h2>
            <div class="company-details mt-1">
              <div> {{ truncateText(item.entity_name, 53) }}</div>
              <div>{{ item.document }}</div>
            </div>
            <!-- Botões de ações -->
            <div v-if="showAllowApproveEntity(item)" class="action-buttons">
              <div class="aprovation-button" @click.prevent="updateStatus(item.bill_receive_id, 8)">
                <img class="mr-2" width="18" src="/img/icons/icons8/ios/thumb-up_primary.png">
                Aprovar
              </div>
              <div v-if="(item.late_value > 0 || item.pending_value > 0) && !item.needs_review" class="aprovation-button deny" @click.prevent="handleNeedsReviewModal(item.id)">
                <AppIcon class="mr-2" icon="thumbs-up-down" color="warning" width="18" />
                Revisar
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="$_financial_approval_is_listing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index" />
    </div>

    <ModalRedeRevalidate ref="modalRedeRevalidate" />
    <ModalAttachment ref="modalAttachment" />
    <ModalEditEntity @updated="loadingSkeleton = true" ref="modalEditEntity" />
    <ModalEntityBillReceiveIntegrationInfo ref="modalEntityBillReceiveIntegrationInfo" />
    <ModalAnticipationBalanceTransfer @updated="loadingSkeleton = true" ref="modalAnticipationBalanceTransfer" />
    <EntityBillReceiveSetNeedsReviewModal @updated="emit('onEditItemClick')" ref="entityBillReceiveSetNeedsReviewModal" />
    <ModalAlterStatus 
      @saveAndClose="showModalAlterStatus = !showModalAlterStatus; emit('onEditItemClick')" 
      ref="modalAlterStatus" 
      :show="showModalAlterStatus"
      @close="showModalAlterStatus = !showModalAlterStatus"
    />
  </div>
</template>


<script setup>
//#region Imports
import { computed, defineEmits, ref } from "vue";
import financialApprovalStore from "../../store/financialApprovalStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import { dialogs, date, truncateText } from '../../../../../../helpers';
import { hasPermission } from "@/plugins/permissions";
import store from "@/store/index";
import ModalRedeRevalidate from "../../../../../../../src/modules/financial/electronic-bank-statement/views/shared/ModalRedeRevalidate";
import ModalAttachment from "../../../BillReceive/Shared/Modals/_ModalAttachment.vue";
import EntityBillReceiveSetNeedsReviewModal from "../../../../../../modules/financial/entity-bill-receive/views/modals/EntityBillReceiveNeedsReviewModal"
import ModalEditEntity from '@/views/Modules/Financial/BillReceive/Entity/Shared/Modals/_ModalEditEntity';
import ModalEntityBillReceiveIntegrationInfo from "@/views/Modules/Totvs/EntityBillReceive/_ModalEntityBillReceiveIntegrationInfo";
import ModalAnticipationBalanceTransfer from '@/views/Modules/Configuration/Entity/Shared/_ModalAnticipationBalanceTransfer';
import { AppIcon } from "../../../../../../components/AppGlobal";
import { hasTotvs as getHasTotvs } from "@/plugins/microservices/totvs";
import { EntityBillReceiveInstallmentEnum } from "../../../../../../enum/EntityBillReceiveInstallmentEnum";
import { BillReceiveTypeEnum } from "../../../../../../enum/BillReceiveTypeEnum";
import ModalAlterStatus from "../../../BillReceive/Shared/Modals/_ModalAlterStatus.vue"

//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
]);
//#endregion

//#region Data
const hasTotvs = getHasTotvs();
const showModalAlterStatus = ref(false);
//#endregion

//#region Computeds
/** Store Getters */
const $_financial_approval_listed = computed(() => financialApprovalStore.getters.getListed());
const $_financial_approval_is_listing = computed(() => financialApprovalStore.getters.getIsListing());
//#endregion

//#region ComponentRefs
/** @type {Ref<modalRedeRevalidate>} */
const modalRedeRevalidate = ref(null);

/** @type {Ref<modalAttachment>} */
const modalAttachment = ref(null);

/** @type {Ref<modalEditEntity>} */
const modalEditEntity = ref(null);

/** @type {Ref<modalEntityBillReceiveIntegrationInfo>} */
const modalEntityBillReceiveIntegrationInfo = ref(null);

/** @type {Ref<modalAnticipationBalanceTransfer>} */
const modalAnticipationBalanceTransfer = ref(null);

/** @type {Ref<EntityBillReceiveSetNeedsReviewModal>} */
const entityBillReceiveSetNeedsReviewModal = ref(null);
 
/** @type {Ref<ModalAlterStatus>} */
const modalAlterStatus = ref(null);
//#endregion

//#region Methods
/**
 * Abrir modal
 *
 * @param {number} id - Id da entidade
 * @param {number} payment_intermediary_id - Id do pagamento intermediário
 */
function handleModalRedeRevalidate(id, payment_intermediary_id) {
  modalRedeRevalidate.value.openModal(id, payment_intermediary_id);
}

/**
 * Abrir modal de informações de integração com entidade
 *
 * @param {number} id - Id da entidade
 */
function handleModalEntityBillReceiveIntegrationInfo(id) {
  modalEntityBillReceiveIntegrationInfo.value.openModal(id);
}

/**
 * Abrir modal para transferência de saldo de antecipação
 *
 * @param {string} uuid - UUID da entidade
 */
function handleModalAnticipationBalanceTransfer(uuid) {
  modalAnticipationBalanceTransfer.value.openModal(uuid);
}

/**
 * Editar entidade
 *
 * @param {Object} item - Objeto representando a entidade
 */
function handleEditEntity(item) {
  const hasBillReceiveStatusApproved = item.approved_value > 0;
  const userCannotEdit = hasBillReceiveStatusApproved && !hasPermission('bill_receive.approve_installment');
  if (userCannotEdit) {
    dialogs.notify("danger", "Usuário não possui permissão para EDITAR esse registro.");
    return;
  }
  modalEditEntity.value.handleCreateModal({
    id: item.id,
    entity_name: item.entity_name,
    document: item.document
  }, item.bill_receive_id);
}

/**
 * Obter o texto, cor e ícone do status do card
 *
 * @param {Object} payment - Objeto representando o pagamento
 * @returns {{text: string, color: string, icon: string}} - Detalhes do status
 */
function getCardStatus(payment) {
  let statusText =
   (payment.late_value > 0 || payment.pending_value > 0) && payment.needs_review ? 'Revisão' :
    payment.pay_value > 0 ? 'Recebido' :
      payment.late_value > 0 ? 'Atrasado' :
        payment.pending_value > 0 ? 'Pendente' :
          payment.canceled_value > 0 ? 'Cancelado' :
            payment.approved_value > 0 ? 'Aprovado' :
              payment.denied_value > 0 ? 'Negado' :
                'Recebido';

  let statusType =
   (payment.late_value > 0 || payment.pending_value > 0) && payment.needs_review ? 'background-color: #fdf5e4; color: #F5C865' :
    payment.pay_value > 0 ? 'background-color: #d4ede0; color: #149E57' :
      payment.late_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
        payment.pending_value > 0 ? 'background-color: #fdf5e4; color: #F5C865' :
          payment.canceled_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
            payment.approved_value > 0 ? 'background-color: hsl(207, 75%, 41%, 20%); color: #1A70B7' :
              payment.denied_value > 0 ? 'background-color: #f9dedc; color: #DB4539' :
                'background-color: #db4539;';

  let statusIcon =
   (payment.late_value > 0 || payment.pending_value > 0) && payment.needs_review ? '/img/icons/icons8/ios/thumbs-up-down_warning.png' :
    payment.pay_value > 0 ? '/img/icons/icons8/ios/check-dollar_success.png' :
      payment.late_value > 0 ? '/img/icons/icons8/ios/ring-clock_danger.png' :
        payment.pending_value > 0 ? '/img/icons/icons8/ios/hourglass_warning.png' :
          payment.canceled_value > 0 ? '/img/icons/icons8/ios/cancel_danger.png' :
            payment.approved_value > 0 ? '/img/icons/icons8/ios/thumb-up_primary.png' :
              payment.denied_value > 0 ? '/img/icons/icons8/ios/thumbs-down_danger.png' :
                '/img/icons/icons8/ios/cancel_danger.png';

  return {
    text: statusText,
    color: statusType,
    icon: statusIcon
  };
}

/**
 * Exibir anexos da entidade
 *
 * @param {Object} item - Objeto representando a entidade
 * @returns {Promise<void>}
 */
async function handleShowAttachments(item) {
  const attachments = await financialApprovalStore.actions.getAttachments(item.bill_receive_id);
  modalAttachment.value.openAttachment(attachments);
}

/**
 * Verificar se é permitido aprovar a entidade
 *
 * @param {Object} entity - Objeto representando a entidade
 * @returns {boolean} - Indica se a aprovação é permitida
 */
function showAllowApproveEntity(entity) {
  if (!entity.allow_approve) return false;
  if (entity.approved_value > 0 || entity.denied_value > 0 || entity.pay_value > 0) return false;
  return hasPermission('bill_receive.approve_installment') &&
    (entity.pending_value > 0 || entity.late_value > 0);
}

/**
 * Atualizar status da entidade
 *
 * @param {number} id - Id da entidade
 * @param {number} status - Novo status
 * @returns {Promise<void>}
 */
async function updateStatus(id, status) {
  // Direciona cancelamento para modal de alteração de status
  if(status === EntityBillReceiveInstallmentEnum.CANCELED) {
    return handleModalAterStatus(id, status);
  }
  
  const isConfirmed = await dialogs.confirmAction('Você tem certeza?',['Sim', 'Não']);
  if (isConfirmed) {
    const response = await store.dispatch("billReceive/show", id);
    const installment = response.data.installments.find((item) =>
      [0, 2].includes(item.status)
    );
    if (!installment) {
      dialogs.notify("success", "Não há parcelas pendentes.");
      return;
    }
    await financialApprovalStore.actions.updateStatus(installment.id, status);
    emit("onEditItemClick");
  }
}

function handleNeedsReviewModal(id) {
   entityBillReceiveSetNeedsReviewModal.value.openModal(id);
}

async function handleModalAterStatus(id, status) {
  const response = await store.dispatch("billReceive/show", id);
  const installment = response.data.installments[0];
    modalAlterStatus.value.setBillReceive({ 
    id: installment.id,
    type: BillReceiveTypeEnum.ENTITY_BILL_RECEIVES,
    status: status,
    indexPayment: null,
    index: null,
    received_at: installment.paid_at ? installment.paid_at : null
  });
  showModalAlterStatus.value = true;
}
//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}

.card-content {
  padding: 1rem 1.5rem;
}

.status-badge {
  border-radius: 16px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 400 !important;
  width: 121px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-details {
  font-size: 12px !important;
}

.popover-content {
  margin: 0.5rem;
}

.popover-detail {
  font-size: 12px;
  margin-top: 0.5rem;
}

.bank-account {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 16px;
  color: #2B2D32;
  font-weight: bold;
  margin-top: 0.8rem;
}

.payment-method {
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 16px;
  color: #2B2D32;
  text-wrap: nowrap;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.payment-term {
  border-left: 1px solid #E8E8E8;
  font-weight: 400;
  margin-top: 0.5rem;
  font-size: 12px;
  line-height: 16px;
  padding-left: 0.4rem;
  color: #606062;
  height: 1rem;
}

.nsu-code {
  border-left: 1px solid hsl(223, 8%, 18%, 75%);
  padding: 0 5px;
  line-height: 12px;
}

.item-name {
  font-weight: bold;
  font-size: 1rem;
  color: #2B2D32;
}

.value-card {
  font-weight: 500 !important;
  font-size: 27px;
  line-height: 20px;
  color: #2B2D32;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.competence {
  font-size: 14px;
  font-weight: 400 !important;
}

.divider {
  border-top: 1px solid hsl(240, 1%, 38%, 15%);
  margin: 0.8rem 0;
}

.company-details {
  color: #2B2D32;
  font-size: 14px;
  font-weight: 400 !important;
  margin: 0.6rem 0;
}

.company-details div:first-child {
  font-size: 13px;
  font-weight: 300 !important;
}

.action-buttons {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.aprovation-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  background: #FFF;
  box-shadow: 0px 5px 10px 0px #0000000D;
  cursor: pointer;
  width: 48%;
  height: 2rem !important;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  font-size: 12px;
}

.aprovation-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}

.dropdown-item {
  align-items: center;
  display: flex;
}

.edit-item img,
.delete-item img {
  margin-right: 0.5rem;
}
</style>
