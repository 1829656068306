const keys = Object.freeze({
  INTEGRATED: "integrated",
  ERROR: "error",
});

const getTitle = (value) => {
  switch (value) {
    case keys.INTEGRATED:
      return "Integrado";
    case keys.ERROR:
      return "Erro";
    default:
      return "";
  }
};

export const SyncGeneralStatusEnum = {
  keys,
  getTitle,
};
