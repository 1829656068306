<template>
  <div class="row card-wrapper" v-if="!loading && concrete_prices.length">
    <div
      class="col-md-6 col-lg-4 col-xlg-4"
      v-for="(concrete_price, index) in concrete_prices"
      :key="index"
    >
      <!-- Basic with action button -->
      <div class="card main-card concrete-price-card">
        <div class="card-body p-0">
          <div class="d-flex mb-4">
            <div
              class="status-badge"
              :style="getBackground(concrete_price.status)"
            >
              <img
                class="pointer"
                :src="getStatusIcon(concrete_price.status)"
                width="16px"
                height="16px"
              />
              <h4
                :style="getStatusColor(concrete_price.status)"
                class="font-weight-normal m-0 p-0 ml-1"
              >
                {{ Number(concrete_price.status) == 1 ? "ATIVO" : "COMERCIAL" }}
              </h4>
            </div>

            <a @click.prevent="handleModalPuzlAdjust(concrete_price)" href="#">
              <img
                src="https://img.icons8.com/ios/100/2b2d32/calculator--v1.png"
                width="25px"
                height="25px"
              />
            </a>
            <el-popover trigger="click" placement="bottom" class="p-0 ml-2">
              <div class="param-border">
                <h4
                  class="mb-0 new-default-black-font"
                  style="padding-bottom: 12px"
                >
                  PARÂMETROS
                </h4>
              </div>
              <div
                style="min-width: 270px"
                v-for="(
                  price_parameter, index
                ) in concrete_price.price_parameter"
                :key="index"
              >
                <div
                  v-if="index === 'mcc_price'"
                  class="d-flex align-items-center"
                  style="padding-top: 12px"
                >
                  <div class="text-left" style="min-width: max-content">
                    <h5 class="new-default-blue-font mb-0">
                      {{ price_parameter.name }}
                    </h5>
                  </div>

                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background-color: #e8e8e8;
                      margin: 0 8px;
                    "
                  ></div>

                  <div class="text-right" style="min-width: max-content">
                    <h5 class="new-default-blue-font mb-0">
                      {{ price_parameter.price }}
                    </h5>
                  </div>
                </div>
                <div
                  v-if="index !== 'mcc_price'"
                  class="d-flex align-items-center padding"
                  style="padding-top: 12px"
                >
                  <div class="text-left">
                    <h5
                      :class="[
                        price_parameter.tax
                          ? 'text-danger'
                          : 'new-default-black-font',
                        price_parameter.mcc ? '' : 'small',
                      ]"
                      class="mb-0"
                      style="min-width: max-content"
                    >
                      {{ price_parameter.name }}
                    </h5>
                  </div>
                  <div
                    style="
                      width: 100%;
                      height: 1px;
                      background-color: #e8e8e8;
                      margin: 0 8px;
                    "
                  ></div>
                  <div class="text-right">
                    <h5
                      :class="[
                        price_parameter.tax
                          ? 'text-danger'
                          : 'new-default-black-font',
                        price_parameter.mcc ? '' : 'small',
                      ]"
                      class="mb-0"
                      style="min-width: max-content"
                    >
                      {{ price_parameter.price }}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                slot="reference"
                size="sm"
                type="secundary"
                style="box-shadow: none"
              >
                <img
                  class="pointer"
                  src="/img/icons/icons8/ios/adjust_primary.png"
                  width="26px"
                  height="26px"
                />
              </div>
            </el-popover>
            <a
              class="ml-auto"
              href="#"
              @click.prevent="copy(`TR-${concrete_price.mix_code.id}`)"
            >
              <badge
                v-if="concrete_price.mix_code.id"
                style="font-size: 14px !important"
                title="Clique aqui para copiar"
                class="pointer font-weight-500"
                type="default"
              >
                TR-{{ concrete_price.mix_code.id }}
              </badge>
            </a>
          </div>
          <!-- Title -->
          <h4
            class="new-default-black-font font-weight-400 mt-4"
            style="font-size: 12px"
          >
            {{ concrete_price.plant.plant_name }}
          </h4>
          <h4 class="mb-0">
            <span
              class="new-default-black-font font-weight-500"
              style="font-size: 14px"
              v-for="(feature, indexFeature) in concrete_price.mixe.features"
              :key="indexFeature"
            >
              {{ feature.feature_name }} {{ feature.item }}
              {{ feature.unit_letter }}
              <span
                v-if="indexFeature < concrete_price.mixe.features.length - 1"
                >+</span
              >
            </span>
          </h4>

          <div class="d-flex flex-wrap">
            <small
              class="mb-1 font-weight-400 new-default-black-font"
              style="font-size: 14px"
            >
              {{ concrete_price.service.type }}
            </small>
            <small
              class="mb-1 font-weight-400 new-default-black-font"
              v-show="concrete_price.service.service_name"
              style="font-size: 14px"
            >
               &nbsp;{{ '| ' +  concrete_price.service.service_name + ` `}}
            </small>
            <small
              class="small font-weight-400 new-default-black-font"
              v-show="concrete_price.service.service_type"
              style="font-size: 14px"
            >
               &nbsp;{{ '| ' +  concrete_price.service.service_type + ` `}}
            </small>
            <small
              class="mb-1 font-weight-400 new-default-black-font"
              style="font-size: 14px"
            >
              &nbsp;{{ '| ' +  concrete_price.mixe.slump + ` `}}
            </small>
            <small
              class="mb-1 font-weight-400 new-default-black-font"
              style="font-size: 14px"
            >
               &nbsp;{{ '| ' +  concrete_price.mixe.product + ` `}}
            </small>
          </div>
        </div>
        <!-- Preço Minimo, MCC e Margem -->
        <div class="d-flex flex-wrap mt-3" style="gap: 8px">
          <div class="mini-card-novo">
            <h4 class="checklist-title mb-0 new-default-black-font">
              {{ concrete_price.price.minimum_price }}
            </h4>
            <small class="new-default-black-font">{{
              isMobile ? "P. MÍNIMO" : "PREÇO MÍNIMO"
            }}</small>
          </div>
          <div class="mini-card-novo danger">
            <h4 class="checklist-title mb-0 new-default-black-font">
              {{ concrete_price.price_parameter["mcc_price"].price }}
            </h4>
            <small class="new-default-black-font">MCC</small>
          </div>
          <div class="mini-card-novo success">
            <h4 class="checklist-title mb-0 new-default-black-font">
              {{
                calculateGrossMargin(
                  concrete_price.price.minimum_price,
                  concrete_price.price_parameter["mcc_price"].price,
                  concrete_price.tax
                ) | currency()
              }}
            </h4>
            <small class="new-default-black-font">Margem</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardsViewConcretePrice",
  props: {
    loading: Boolean,
    concrete_prices: Array,
    calculateGrossMargin: { type: Function },
    showParametters: { type: Function },
    handleModalPuzlAdjust: { type: Function },
    copy: { type: Function },
  },
  components: {},
  data() {
    return {
      isMobile: window.innerWidth <= 768,
    };
  },
  methods: {
    getStatusIcon(status) {
      switch (status) {
        case 1:
          return "/img/icons/icons8/ios/ok-grenn.png";
        case 5:
          return "/img/icons/icons8/ios/hourglass_primary.png";
        default:
          return "/img/icons/icons8/ios/ok-grenn.png";
      }
    },

    getStatusColor(status) {
      switch (status) {
        case 1:
          return "color: #149e57";
        case 5:
          return "color: #1A70B7";
        default:
          return "color: #149e57";
      }
    },

    getBackground(status) {
      switch (status) {
        case 1:
          return "background-color: #F2F7F3";
        case 5:
          return "background-color: #F2F4F9";
        default:
          return "background-color: #F2F7F3";
      }
    },
    updateBreakpoint() {
      this.isMobile = window.innerWidth <= 768;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
.card-wrapper .concrete-price-card {
  padding: 16px 16px 20px;
  box-shadow: none !important;
  border-radius: 50px;

  .status-badge {
    padding: 4px 21px;
    border-radius: 50px;
    min-width: max-content;
    display: flex;
    align-items: center;
    margin-right: 12px;
    width: 130px;
    justify-content: center;
  }

  .mini-card-novo {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    small {
      text-align: center;
    }
    &.success {
      background-color: $success-lighter;
    }
    &.danger {
      background-color: $danger-lighter;
    }
  }
}

.param-border {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 1px;
    background-color: #e8e8e8;
    width: 100%;
    bottom: 0;
    left: -12px;
  }

  &::after {
    position: absolute;
    content: "";
    height: 1px;
    background-color: #e8e8e8;
    width: 100%;
    bottom: 0;
    right: -12px;
  }
}

.parametter-disabled {
  height: 0px;
  overflow: hidden;
}
.parametters-list {
  -webkit-transition: all 3s ease;
  -moz-transition: all 3s ease;
  -o-transition: all 3s ease;
  -ms-transition: all 3s ease;
  transition: all 3s ease;
}
.blue-divider {
  background-color: #7ec5ff;
  color: #7ec5ff;
  margin: 0;
  margin-bottom: 15px;
}
.card-blue-bottom {
  border-top-left-radius: 35px;
  border-bottom-right-radius: 10px;
  background-color: #1b6eba;
  color: white;
  max-width: 140px;
  min-width: 140px;
  float: right;
}
.mini-card {
  display: grid;
  grid-template-columns: 34% 33% 33%;
  overflow: hidden;
  padding: 0;
}

.mini-card div {
  display: inline-grid;
  padding: 3px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.mini-card div h5 {
  font-weight: 600 !important;
}

.mini-card div h5 {
  font-size: 14px !important;
}

.mini-card div small {
  font-size: 10px !important;
}

.mini-card div small {
  font-size: 10px !important;
  font-weight: 500 !important;
}

.main-card {
  box-shadow: none !important;
}
.card {
  border-radius: 10px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3) !important;
}
</style>
