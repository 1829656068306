<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable
        :length="$_concretePieces.length"
        :headers="headers"
        :loading="loadingSkeleton"
      >
        <template v-for="(item, index) in $_concretePieces" :slot="index">
          <ShadedCol style="max-width: 90px">
            <div class="d-flex m-0 mt-1" style="gap: 8px">
              <div style="min-width: 130px">
                <AppSwitch
                  v-model="item.status"
                  :asyncClick="() => changeStatus(item)"
                />
              </div>
              <div class="pt-1 pl-2" v-if="item.reuse">
                <el-popover class="ml-0" placement="bottom" trigger="click">
                  <div>
                    <div
                      class="font-weight-500 fs-12"
                      style="justify-content: center; text-align: center"
                    >
                      PERMITE REUSO
                    </div>
                  </div>
                  <base-button
                    slot="reference"
                    class="p-0 m-0 shadow-none"
                    outiline
                    size="sm"
                    type="secundary"
                  >
                    <img width="24px" src="/img/icons/reuse-grenn.png" />
                  </base-button>
                </el-popover>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div class="font-weight-400 fs-14 m-0 pt-2">
              {{ item.description }}
            </div>
          </ShadedCol>
          <ShadedCol style="max-width: 200px">
            <div class="applicationCards" style="gap: 12px">
              <div>
                <base-button
                  :class="item.level_feature.includes(1) ? 'active' : null"
                  :disabled="buttonDisabled"
                  @click.prevent="updateLevelFeature(item, 1)"
                  outline
                  block
                  :style="{
                    backgroundColor: item.level_feature.includes(1)
                      ? '#F2F4F9'
                      : '#FFFFFF',
                    borderColor: '#E8E8E8',
                  }"
                >
                  <div
                    class="fs-13 font-weight-400"
                    :style="{
                      color: item.level_feature.includes(1)
                        ? '#1a70b7'
                        : '#2B2D32',
                    }"
                  >
                    BAIXA
                  </div>
                </base-button>
              </div>
              <div>
                <base-button
                  :class="item.level_feature.includes(2) ? 'active' : null"
                  :disabled="buttonDisabled"
                  @click.prevent="updateLevelFeature(item, 2)"
                  outline
                  block
                  :style="{
                    backgroundColor: item.level_feature.includes(2)
                      ? '#F2F4F9'
                      : '#FFFFFF',
                    borderColor: '#E8E8E8',
                  }"
                >
                  <div
                    class="fs-13 font-weight-400"
                    :style="{
                      color: item.level_feature.includes(2)
                        ? '#1a70b7'
                        : '#2B2D32',
                    }"
                  >
                    MÉDIA
                  </div>
                </base-button>
              </div>
              <div>
                <base-button
                  :class="item.level_feature.includes(3) ? 'active' : null"
                  :disabled="buttonDisabled"
                  @click.prevent="updateLevelFeature(item, 3)"
                  outline
                  block
                  :style="{
                    backgroundColor: item.level_feature.includes(3)
                      ? '#F2F4F9'
                      : '#FFFFFF',
                    borderColor: '#E8E8E8',
                  }"
                >
                  <div
                    class="fs-13 font-weight-400"
                    :style="{
                      color: item.level_feature.includes(3)
                        ? '#1a70b7'
                        : '#2B2D32',
                    }"
                  >
                    ALTA
                  </div>
                </base-button>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol style="max-width: 20px">
            <div class="ml-auto">
              <base-dropdown class="m-0 mt-1" menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded m-0">
                  <img
                    width="32"
                    src="/img/icons/icons8/ios/settings--v1_primary.png"
                  />
                </div>
                <a
                  class="dropdown-item ts-12 font-weight-400"
                  @click.prevent="handleEditConcretePiece(item.uuid)"
                >
                  <img
                    src="/img/icons/icons8/ios/create-new-yellow.png"
                    width="14px"
                    height="14px"
                  />
                  Editar
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a
                  class="dropdown-item ts-12 font-weight-400"
                  @click.prevent="handleHistoryConcretePiece(item)"
                >
                  <img
                    src="/img/icons/order-history.png"
                    width="14px"
                    height="14px"
                  />
                  Histórico
                </a>
                <div class="dropdown-divider p-0 m-0"></div>
                <a
                  class="dropdown-item ts-12 font-weight-400"
                  @click.prevent="handleDeleteConcretePiece(item.uuid)"
                >
                  <img
                    src="/img/icons/icons8/ios/delete-red.png"
                    width="14px"
                    height="14px"
                  />
                  Excluir
                </a>
              </base-dropdown>
            </div>
          </ShadedCol>
        </template>
      </ShadedTable>
      <EditConcretePiece ref="editConcretePiece"></EditConcretePiece>
      <HistoryConcretePiece ref="historyConcretePiece"></HistoryConcretePiece>
    </div>
  </div>
</template>
<script>
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import EditConcretePiece from "./_Edit";
import HistoryConcretePiece from "./_History.vue";
import { AppSwitch } from "../../../../../../components/AppGlobal";
import { mapGetters } from "vuex";

export default {
  name: "TableTests",
  props: {
    loadingSkeleton: Boolean,
  },
  components: {
    ShadedTable,
    ShadedCol,
    AppSwitch,
    EditConcretePiece,
    HistoryConcretePiece,
  },
  data() {
    return {
      headers: ["Status", "Peça", "Nível de Aplicação", "Ação"],
      filter: {},
      buttonDisabled: false,
    };
  },
  methods: {
    handleEditConcretePiece(uuid) {
      this.$refs.editConcretePiece.handleEditModal(uuid);
    },
    handleHistoryConcretePiece(concretePiece) {
      this.$refs.historyConcretePiece.handleHistoryModal(concretePiece.id);
    },
    handleDeleteConcretePiece(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("mixConcretePiece/destroy", uuid)
              .then((response) => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                  this.$Progress.finish();
                } else if (error.response && error.response.status === 422) {
                  let errors = formatErrorValidation(
                    error.response.data.errors
                  );
                  this.$notify({
                    type: "danger",
                    message: errors,
                  });
                }
                this.$Progress.finish();
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    /**
     * @param {object} concretePiece
     */
    changeStatus(concretePiece) {
      const previousStatus = concretePiece.status;
      concretePiece.status = +!concretePiece.status;
      this.$store
        .dispatch("mixConcretePiece/updateStatus", concretePiece)
        .then((response) => {
          if (response.data) {
            this.$notify({
              type: "success",
              message: "Status atualizado com sucesso!",
            });
          } else {
            concreteṔiece.status = previousStatus;
            this.$notify({
              type: "danger",
              message: "Erro ao atualizar status!",
            });
          }
        })
        .catch((error) => {
          concreteṔiece.status = previousStatus;
          this.$notify({
            type: "danger",
            message: "Erro ao atualizar status!",
          });
        });
    },
    /**
     * @param {object} concreteṔiece
     * @param {number} levelFeature
     */
    updateLevelFeature(concreteṔiece, levelFeature) {
      this.buttonDisabled = true;

      setTimeout(() => {
        this.buttonDisabled = false;
      }, 1000);

      // Esta verificando se o level feature clicado (baixo, medio ou alto) esta ativo ou não
      let isLevelFeatureIncluded = concreteṔiece.level_feature.includes(
        levelFeature
      )
        ? 1
        : 0;
      let mixConcretePiece = {
        id: concreteṔiece.id,
        levelFeature: levelFeature,
        addOrRemove: isLevelFeatureIncluded,
        user: this.$user.id,
      };

      this.$store
        .dispatch("mixConcretePiece/updateLevelFeature", mixConcretePiece)
        .then((response) => {
          this.$notify({
            type: "success",
            message: "Nível de aplicação atualizado com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error,
          });
        });
    },
  },
  computed: {
    ...mapGetters({
      $_concretePieces: "mixConcretePiece/fetch",
    }),
  },
};
</script>

<style scoped>
.applicationCards {
  display: flex;
  gap: 12px;
}

.applicationCards > div {
  flex: 1; /* Faz com que todos os filhos ocupem o mesmo espaço */
  min-width: 0; /* Previne que o flex cause overflow */
}
</style>
