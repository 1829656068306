import store from "../../../../shared/libraries/store";
import { initCompanyPlantBankAccountListPagerType, CompanyPlantBankAccountListPagerType, CompanyPlantBankAccountType } from "../types";
import { createSource, findSource, listSources, removeSource } from "@/shared/store";

/** URLS */
const urls = Object.freeze({
  BASE: "/company/plant/bank-account/",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "BANK_ACCOUNT_ITEM",
  LISTED: "BANK_ACCOUNT_LISTED",
  IS_LISTING: "BANK_ACCOUNT_IS_LISTING",
  IS_FINDING: "BANK_ACCOUNT_IS_FINDING",
  IS_SAVING: "BANK_ACCOUNT_IS_SAVING",
  IS_REMOVING: "BANK_ACCOUNT_IS_REMOVING",
  PAGE_STATES: "BANK_ACCOUNT_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?CompanyPlantBankAccountType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {CompanyPlantBankAccountListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initCompanyPlantBankAccountListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {CompanyPlantBankAccountType} item
   * @returns {CompanyPlantBankAccountType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {CompanyPlantBankAccountListPagerType} listed
   * @returns {CompanyPlantBankAccountListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {CompanyPlantBankAccountType} data
   * @returns {Promise<CompanyPlantBankAccountType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?CompanyPlantBankAccountType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {Number} company_plant_id - ID da central para busca das contas bancárias
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?CompanyPlantBankAccountListPagerType>} - Retorno paginado
   */
  list: async (company_plant_id, isAccumulateItems = false) => {
    await listSources({}, isAccumulateItems, mutations, getters, `${urls.BASE}by-plant/${company_plant_id}`);
  },

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),
}

export default {
  actions,
  getters,
  mutations,
};
