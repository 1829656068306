<template>
  <div>
    <AppFixedPageTitle
      title="Adicionais"
      icon="/img/icons/icons8/ios/svgs/add-new.svg"
    />
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loading">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        v-else
        :contractInfo="contract_proposal"
      >
        <AppPageHeaderActions>
          <router-link :to="{
            name:'commercial.contract-proposal.additional.create',
            path:'/commercial/contract-proposal/additional/create',
            params: { contract_proposal_uuid: contract_proposal.uuid}
          }">
            <AppPageHeaderActionsButton
              :disabled="loading || [0, 5].includes(contract_proposal.status)"
              type="success"
              text="Novo"
              icon="/img/icons/icons8/ios/svgs/plus-math.svg"
              class="invert-color"
              >
            </AppPageHeaderActionsButton>
          </router-link>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>

      <ContractProposalNavigation
        :contractUuid="contract_proposal.uuid"
        :contractStatus="contract_proposal.status"
        :entity="contract_proposal.entity"
        :activeAdditional="true"
        style="margin-top: 12px;"
      />
    </div>

    <div class="container-fluid">
      <div class="row card-wrapper">
        <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingSkeleton" :key="index"></SkeletonPuzlGrid>
      </div>

      <PuzlEmptyData v-if="contract_additionals && !contract_additionals.length && !loadingSkeleton"/>

      <!-- Start Card ContractProposal -->
      <div class="row card-wrapper">
        <div v-if="!loadingSkeleton" class="col-md-6 col-lg-4 col-xlg-4"
             v-for="(additional, indexAdditional) in contract_additionals" :key="indexAdditional">
          <div class="card">
            <div class="card-header pointer" style="padding: 0px !important; height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-button
                    @click.prevent="
                      additional.status ?
                      handleSetStatus(additional.uuid, 0, indexAdditional, additional) :
                      handleSetStatus(additional.uuid, 1, indexAdditional, additional)"
                    size="sm"
                    :style="{ backgroundColor: additional.status ? '#149E57' : '#DB4539' }"
                    class="text-white text-uppercase btn-header" style="opacity: 100%; color: #4f40ff; border: none;"
                    block>
                    {{ additional.status ? "Ativo" : "Inativo" }}
                  </base-button>
                </div>
              </div>
            </div>
            <div class="p-3 mt-n3">
              <div class="mt-4 row align-items-center mb-2">
                <div class="col-8">
                  <!-- Title -->
                  <div>
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div> {{ additional.user }} </div>
                      <div> {{ additional.created_at | parseDate('DD MMM YYYY hh:mm') }} </div>
                      <span slot="reference">
                        <badge type="default" class="mx-1 py-2">
                          V.{{ additional.version }}
                        </badge>
                      </span>
                    </el-popover>
                    <a href="#" @click.prevent="handleOpenModalHistoric(additional.histories)">
                      <badge
                        v-if="additional.histories.length"
                        class="mx-1" style="background-color: grey; color: white;"
                      >
                        <img src="/img/icons/log.png" width="16px" height="16px" />
                        Histórico
                      </badge>
                    </a>
                  </div>
                  <div>
                    <h5 class="h5 mt-1">
                      {{ additional.short_description }}
                    </h5>
                  </div>
                </div>
                <div class="col-4 text-right mt-n4" v-show="additional.editable">
                  <base-dropdown menuOnRight>
                    <base-button
                      :disabled="[0,5].includes(contract_proposal.status)"
                      slot="title-container"
                      type="primary"
                      class="dropdown-toggle p-2 rounded m-0"
                    >
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <a class="dropdown-item" v-show="additional.editable && ![0,5].includes(contract_proposal.status)" @click.prevent="handleNewValidity(additional)">
                      <i class="fas fa-calendar text-primary"></i>
                      <span style="margin-left: -5px;" class="text-right">Nova Vigência</span>
                    </a>
                    <div v-show="additional.editable && ![0,5].includes(contract_proposal.status)" class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" v-show="additional.editable && ![0,5].includes(contract_proposal.status)"
                      @click.prevent="handleEditAdditional(additional)">
                      <i class="fas fa-edit text-warning"></i>
                      <span style="margin-left: -10px;" class="text-right">Editar</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0" v-if="additional.editable && ![0,5].includes(contract_proposal.status)"></div>
                    <a v-show="additional.editable && ![0,5].includes(contract_proposal.status)" class="dropdown-item"
                      @click.prevent="handleDeleteAdditional(additional.uuid)">
                      <i class="fas fa-times text-danger"></i>
                      <span style="margin-left: -3px;" class="text-right">Excluir</span>
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <!-- Card body -->
              <div class="row mt-n3">
                <div class="col-12">
                  <span class="h5">
                    {{ additional.standard_value }} {{ additional.billing_unit_description }}
                  </span>
                </div>
              </div>
              <div class="row mb-1">
                <div class="col-12">
                  <span class="h5" v-if="additional.params && additional.params.a">
                    {{ additional.params.a.description }}
                  </span>
                  <span class="h5" v-if="additional.params && additional.params.a && additional.params.b">
                    e
                  </span>
                  <span class="h5" v-if="additional.params && additional.params.b">
                    {{ additional.params.b.description }}
                  </span>
                </div>
              </div>
              <div class="row">
                <div class="col-12 text-right">
                  <a href="#" class="px-2">
                    <el-popover trigger="hover" placement="bottom" class="p-0">
                      <p class="h5" style="text-transform: lowercase;">
                        {{ additional.description }}
                      </p>
                      <img slot="reference" src="/img/icons/speech-bubble-with-dots.png" width="28px" height="28px" />
                    </el-popover>
                  </a>
                  <el-popover trigger="hover" placement="right" class="p-0">
                    <h5> Serviços </h5>
                    <hr class="w-100 bg-dark mt-n1" />
                    <div
                      class="col-12 mb-4"
                      v-for="(itemService, index) in additional.services" :key="index"
                    >
                      <li class="mx-n3 mt-n4 h5" style="font-size: 13px;">
                        {{ itemService }}
                      </li>
                    </div>
                    <h5> Data </h5>
                    <hr class="w-100 bg-dark mt-n1" />
                    <div v-for="date in additional.dates" :key="date" class="col-12 mb-4">
                      <li class="mx-n3 mt-n4 h5" style="font-size: 13px;" v-if="date === 0">
                        Dia útil
                      </li>
                      <li class="mx-n3 mt-n4 h5" style="font-size: 13px;" v-if="date === 1">
                        Sábado
                      </li>
                      <li class="mx-n3 mt-n4 h5" style="font-size: 13px;" v-if="date === 2">
                        Domingo
                      </li>
                      <li class="mx-n3 mt-n4 h5" style="font-size: 13px;" v-if="date === 3">
                        Feriado
                      </li>
                    </div>
                    <img slot="reference" src="/img/icons/info.png" width="28px" height="28px" />
                  </el-popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Card ContractProposal -->
    </div>
    <ModalEditAdditional ref="editAdditional"/>
    <ModalNewValidity @refresh="init" ref="newValidity"/>
    <ModalHistoric ref="modalHistoric" />
  </div>

</template>
<script>
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEditAdditional from './_Edit'
import ModalNewValidity from './_NewValidity'
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import BackButton from "../../../../../../components/Utils/BackButton";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ModalHistoric from './_ModalHistoric';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ContractProposalPageHeader from '../../components/ContractProposalPageHeader/ContractProposalPageHeader.vue'
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../../components/AppGlobal";


export default {
  name: 'ListContractProposalAdditional',
  components: {
    BackButton,
    ModalEditAdditional,
    SkeletonPuzlGrid,
    ModalNewValidity,
    SkeletonPuzl,
    ModalHistoric,
    PuzlEmptyData,
    ContractProposalNavigation,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
      contract_additionals: "contractAdditional/fetch",
    })
  },
  data() {
    return {
      format_mixe: period_types,
      loadingSkeleton: false,
      ContractProposalEnum: ContractProposalEnum,
    }
  },
  methods: {
    init() {
      this.loadingSkeleton = true
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(response => {
          this.$store
            .dispatch(
              "contractAdditional/fetchItems", response.data.uuid
            )
            .then(() => {
              this.loadingSkeleton = false;
            });
          this.loading = false;
        });
    },
    handleEditAdditional(additional) {
      if (additional.schedule_additional_exists) {
        this.$notify({
          type: 'warning',
          message: 'Este adicional já possui saída! Crie uma nova vigência.'
        });
        return this.$refs.newValidity.openModal(additional)
      }
      this.$refs.editAdditional.openModal(additional)
    },
    handleNewValidity(additional) {
      this.$refs.newValidity.openModal(additional)
    },
    handleSetStatus(uuid, status, index, additional) {
      if (!additional.editable) {
        return this.$notify({
          type: 'danger',
          message: 'Não foi possível concluir a solicitação. Esse adicional não é editável.'
        });
      }
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        uuid: uuid,
        status: status,
      }
      this.$store.dispatch('contractAdditional/setStatus', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.$store.state.contractAdditional.items[index].status = status
      }).catch(error => {
        this.$notify({
          type: error.data.error_type,
          message: error.data.message
        });
      })
    },
    handleDeleteAdditional(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação."
          });
          this.loading = true;
          this.$Progress.start();
          this.$store
            .dispatch(
              "contractAdditional/destroy",
              uuid)
            .then(response => {
              this.init()
              this.loading = false;
              this.$Progress.finish();
              this.$notify({
                type: response.error_type,
                message: response.message
              });
            }).catch(error => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          });
        }
      })
    },
    /**
     * @param {oject} histories
     */
    handleOpenModalHistoric(histories) {
      this.$refs.modalHistoric.openModal(histories);
    },
  },
  mounted() {
    this.init()
  }
}
</script>
<style>
.border-3 {
  border-width: 4px !important;
}
.rounded-circle {
  border: 1px solid #D3D3D3;
}
.background-color-circle {
  background-color: #f3f4fa;
}
.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .text-dark {
  color: white !important;
}
</style>
