import * as types from "./mutation_types";
import { destroy, update } from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";
const endPoint = "/mix/additional-classification/";
export default {
  namespaced: true,
  state: {
    items: [],
    items_by_subtypes: [],
    item: [],
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
    fetchBySubtype: (state) =>
      state.items_by_subtypes.sort(
        (a, b) => a.technical_description > b.technical_description
      ),
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.SET_BY_UUID_SUBTYPE](state, payload) {
      state.items_by_subtypes = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, uuid) {
      destroy(state, uuid);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
  },
  actions: {
    fetchItems({ commit }, params) {
      return createAxios()
        .get(endPoint, { params: params })
        .then(({ data }) => {
          commit(types.SET, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    fetchItemsByUuidSubtype({ commit, dispatch }, uuid) {
      return createAxios()
        .get(endPoint + "subtype/" + uuid)
        .then(({ data }) => {
          commit(types.SET_BY_UUID_SUBTYPE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit, state }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .put(endPoint + payload.uuid, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateStatus({}, payload) {
      return createAxios()
        .put(`${endPoint}update/status/${payload.id}/${payload.status}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit, dispatch }, uuid) {
      return createAxios()
        .get(endPoint + uuid)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit, dispatch }, uuid) {
      return createAxios()
        .delete(endPoint + uuid)
        .then(({ data }) => {
          commit(types.DESTROY, uuid);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
