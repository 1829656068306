<template>
  <div>
    <div class="row card-wrapper" v-if="schedules.length && !loadingSkeleton">
      	<div class="col-lg-4" v-for="(schedule, scheduleIndex) in schedules" :key="scheduleIndex">
	      	<div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
		  			<div class="p-3 px-4">
						<div class="row align-items-center">
							<div class="col-10">
								<el-popover v-if="schedule.next_travel" trigger="click" placement="bottom">
									<span class="row" style="width: 250px;">
										<div class="col-12 mb-3" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/icons8/ios/delivery-time--v1.png" width="22">
											<h4 class="p-0 m-0">Tempo de concretagem</h4>
										</div>
			              <div class="col-5 pr-0">
			                <h5 class="font-weight-normal">Início: </h5>
			              </div>
			              <div class="col-7 text-right pl-0">
			              	<h5 class="font-weight-normal">{{ schedule.first_arrival_time }}</h5>
			              </div>
			              <div class="col-5 pr-0">
											<h5 class="font-weight-normal">Término:</h5>
										</div>
										<div class="col-7 text-right pl-0">
											<h5 class="font-weight-normal">{{ schedule.last_exit_time }}</h5>
										</div>
									</span>
									<hr class="mb-3 mt-2">
									<span class="row" style="width: 250px;">
			              <div class="col-6 pr-0">
											<h5 class="new-default-black-font">Duração</h5>
										</div>
										<div class="col-6 text-right pl-0">
											<h5 class="new-default-black-font">{{ schedule.concreting_time }}</h5>
										</div>
									</span>
									<base-button outline slot="reference" size="sm" type="secundary"
					             		class="p-0 m-0 mr-2 shadow-none text-indigo">
								  		<img src="/img/icons/icons8/ios/delivery-time--v1.png" width="25">
									</base-button>
								</el-popover>
								<el-popover trigger="click" placement="bottom">
									<span style="width: 200px;"
										class="row">
	                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1_success.png" width="22">
											<h4 class="p-0 m-0">Vendedor</h4>
										</div>
			              <div class="col-12">
			                <h5 class="font-weight-normal" style="word-break: break-word;">
			                	{{ schedule.contract_proposal.seller_name }}
			                </h5>
			              </div>
	                </span>
									<base-button outline slot="reference" size="sm" type="secundary"
					             		class="p-0 m-0 mr-2 shadow-none text-indigo">
								  		<img src="/img/icons/icons8/ios/user-male-circle--v1_success.png" width="25">
									</base-button>
								</el-popover>
								<el-popover trigger="click" placement="bottom">
									<span style="width: 200px;"
										class="row">
	                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/icons8/ios/map--v1_primary.png" width="22">
											<h4 class="p-0 m-0">Endereço</h4>
										</div>
			              <div class="col-12">
			                <h5 class="font-weight-normal" style="word-break: break-word;">{{ `${schedule.address_construction.address}, ${schedule.address_construction.number}, ${schedule.address_construction.district} ${schedule.address_construction.city}/${schedule.address_construction.state}-${schedule.address_construction.country}`}} </h5>
			              </div>
	                </span>
									<base-button outline slot="reference" size="sm" type="secundary"
					             		class="p-0 m-0 mr-2 shadow-none text-indigo">
								  		<img src="/img/icons/icons8/ios/map--v1_primary.png" width="25">
									</base-button>
								</el-popover>
								<base-button @click.prevent="$emit('handleListContacts', schedule.contract_proposal.uuid)"
									outline size="sm" type="secundary" class="p-0 m-0 mr-2 shadow-none text-indigo">
							  		<img src="/img/icons/icons8/ios/phone-contact_warning.png" width="25">
								</base-button>
							</div>
							<div class="col-2 text-right">
							  <base-dropdown menuOnRight>
							    <div slot="title-container"
							      class="dropdown-toggle rounded m-0">
							      <img width="35" src="/img/icons/icons8/ios/settings--v1_primary.png">
							    </div>
	                <router-link
	                	v-if="!['rescheduled','seller_request'].includes(schedule_status[schedule.status.status])"
	                  :to="{
	                    path: '/operational/schedule/weighing/:schedule_uuid',
	                    name: 'operational.schedule.weighing.index',
	                    params: { schedule_uuid: schedule.uuid }
	                  }"
	                >
	                  <a class="dropdown-item">
	                    <i class="fas fa-calendar text-primary main-icon-menu"></i>
	                    <span class="text-right">Viagens</span>
	                  </a>
	                </router-link>
	                <div v-if="!['rescheduled','not_performed'].includes(schedule_status[schedule.status.status])"
	                     class="dropdown-divider p-0 m-0"></div>
	                <a v-if="!['rescheduled','not_performed', 'canceled'].includes(schedule_status[schedule.status.status])"
	                  class="dropdown-item"
	                  @click.prevent="
	                    $emit('handleModalListService', schedule.contract_proposal.uuid, schedule.uuid, schedule.company_plants, {
	                      total_volume: schedule.total_volume,
	                      first_arrival_time: schedule.first_arrival_time,
	                      last_exit_time: schedule.last_exit_time
	                    })
	                  "
	                >
	                  <i class="fas fa-plus text-success main-icon-menu"></i>
	                  <span class="text-right">Serviço</span>
	                </a>
	                <div v-if="!['rescheduled'].includes(schedule_status[schedule.status.status])" class="dropdown-divider p-0 m-0"></div>
	                <a class="dropdown-item" @click.prevent="$emit('handleHistorie', schedule.uuid)">
	                  <i class="fas fa-history text-indigo main-icon-menu"></i>
	                  <span class="text-right">Histórico</span>
	                </a>
	                <router-link
	                  :to="{
	                    path: '/operational/schedule/:schedule_uuid',
	                    name: 'operational.schedule.edit',
	                    params: { schedule_uuid: schedule.uuid }
	                  }"
	                >
	                  <div v-if="!['canceled','rescheduled'].includes(schedule_status[schedule.status.status])"
	                       class="dropdown-divider p-0 m-0"></div>
	                  <a v-if="editions(schedule.status.status, schedule.travel_performed, schedule.cancelled_travels)"
	                     class="dropdown-item">
	                    <i class="fas fa-edit text-warning main-icon-menu"></i>
	                    <span class="text-right">Editar</span>
	                  </a>
	                </router-link>
	                <div v-if="!['canceled','rescheduled'].includes(schedule_status[schedule.status.status])"
	                     class="dropdown-divider p-0 m-0"></div>
	                <a
	                  v-if="
	                      !['canceled','rescheduled','in_progress','not_performed','completed'].includes(schedule_status[schedule.status.status]) &&
	                      !schedule.travel_performed > 0 &&
	                      !schedule.cancelled_travels > 0
	                  "
	                  @click.prevent="$emit('handleDeleteProgramming', schedule.uuid)"
	                  class="dropdown-item"
	                >
	                  <i class="fas fa-times text-danger main-icon-menu"></i>
	                  <span>Excluir</span>
	                </a>
							  </base-dropdown>
							</div>
						</div>
						<!-- LAYOUT MOBILE -->
						<div class="show-mobile row mt-2 pt-1 align-items-center">
							<div class="col-12 col-md-4" style="display: flex; align-items: center;">
								<h4 class="m-0 p-0 mr-3" style="font-size: 18px;">
									{{ schedule.concreting_date }}
								</h4>
        			</div>
							<div class="col-12 col-md-8">
			          <button v-if="['pending'].includes(schedule_status[schedule.status.status])"
			          	style="border-radius: 10px !important; margin: 0; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;"
			            slot="title-container"
			            @click.prevent="$emit('showPendencies', schedule)"
			            class="ml-0 px-2 btn text-uppercase btn-outline-warning colorize-btn-img"
			            type="button">
			            Pendente
			            <img src="/img/icons/icons8/ios/info-circle_warning.png" width="13px" class="ml-1" />
			          </button>
								<div v-else style="display: inline-flex;"
	                :class="getColor(schedule.status.status)">
	                <h4 class="font-weight-normal m-0 p-0">
	                	{{ schedule.status.label }}
	              	</h4>
	            	</div>
              	<img style="border-radius: 3px !important;border-radius: 3px !important;padding: 2px;"
              		@click.prevent="authorizeRequest(schedule.uuid)"
              		class="pointer card-with-box-shadow ml-2"
                   v-if="['seller_request'].includes(schedule_status[schedule.status.status]) && !loadingAuthorizeRequest"
            			src="/img/icons/icons8/ios/checkmark.png" width="18">
          			<i v-if="['seller_request'].includes(schedule_status[schedule.status.status]) && !loadingAuthorizeRequest"
          				@click.prevent="authorizeRequest(schedule.uuid)"
          				class="ml-1 pointer new-default-black-font"
          				style="font-size: 11px;font-style: normal;">Aprovar</i>
							</div>
						</div>
						<!-- LAYOUT MD -->
						<div class="show-md row mt-2 pt-1 align-items-center">
							<div class="col-12" style="display: flex; align-items: center;">
								<h4 class="m-0 p-0 mr-3" style="font-size: 18px;">
									{{ schedule.concreting_date }}
								</h4>
			          <button v-if="['pending'].includes(schedule_status[schedule.status.status])"
			          	style="border-radius: 10px !important; margin: 0; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;"
			            slot="title-container"
			            @click.prevent="$emit('showPendencies', schedule)"
			            class="ml-0 px-2 btn text-uppercase btn-outline-warning colorize-btn-img"
			            type="button">
			            Pendente
			            <img src="/img/icons/icons8/ios/info-circle_warning.png" width="13px" class="ml-1" />
			          </button>
								<div v-else style="display: inline-flex;"
	                :class="getColor(schedule.status.status)">
	                <h4 class="font-weight-normal m-0 p-0">
	                	{{ schedule.status.label }}
	              	</h4>
	            	</div>
              	<img style="border-radius: 3px !important;border-radius: 3px !important;padding: 2px;"
              		@click.prevent="authorizeRequest(schedule.uuid)"
              		class="pointer card-with-box-shadow ml-2" v-if="['seller_request'].includes(schedule_status[schedule.status.status]) && !loadingAuthorizeRequest"
            			src="/img/icons/icons8/ios/checkmark.png" width="18">
          			<i v-if="['seller_request'].includes(schedule_status[schedule.status.status]) && !loadingAuthorizeRequest"
          				@click.prevent="authorizeRequest(schedule.uuid)"
          				class="ml-1 pointer new-default-black-font"
          				style="font-size: 11px;font-style: normal;">Aprovar</i>
							</div>
						</div>
						<div class="row mt-1 align-items-center">
							<div class="col-12 new-default-black-font font-weight-400" style="font-size: 14px">
								<span v-for="(plant, indexPlant) in schedule.company_plants"
	                	:key="indexPlant">
                	<i v-if="indexPlant > 0" style="font-style: normal;">+</i>
                	<i :class="schedule.status.companies && schedule.status.companies.includes(plant.uuid) ? 'text-danger' : ''"
                		style="font-style: normal;">
                		{{ plant.company_plant_name }}
                	</i>
								</span>
							</div>
						</div>
						<div class="row mt-2 pt-1 align-items-center">
							<div class="col-12" style="display: flex; align-items: center;">
								<h4 class="new-default-black-font m-0 p-0 mr-2" style="font-size: 16px;">
									{{schedule.contract_proposal.code}}
								</h4>
								<img class="pointer" v-if="hasCreditPendency(schedule)"
									@click.prevent="$emit('handleModalEntityCreditLimit', schedule.contract_proposal)"
									src="/img/icons/icons8/ios/plus-2-math_success.png" width="22" alt="">
							</div>
						</div>
						<div class="row mt-1 align-items-center">
							<div class="col-12 new-default-black-font font-weight-400" style="font-size: 14px">
								{{ schedule.contract_proposal.customer_name }}
							</div>
							<div class="col-12 new-default-black-font font-weight-400" style="font-size: 14px">
								{{ schedule.contract_proposal.construction_name }}
							</div>
						</div>
						<div class="row mt-2 align-items-center">
							<div class="col-6 mb-n3 new-default-black-font font-weight-400" style="display: flex; align-items: center;">
								<h4 class="m-0 p-0">{{ schedule.concreted_piece.description }}</h4>
								<el-popover v-if="schedule.piece_identification" trigger="click" placement="right">
									<span style="width: 200px;"
										class="row">
	                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/info.png" width="22">
											<h4 class="p-0 m-0">Descrição da peça</h4>
										</div>
			              <div class="col-12">
			                <h5 class="font-weight-normal" style="word-break: break-word;">
			                	{{ schedule.piece_identification }}
			                </h5>
			              </div>
	                </span>
									<img slot="reference" width="15" class="ml-2 pointer" src="/img/icons/info.png" alt="">
								</el-popover>
							</div>
							<div class="col-6 text-right" style="">
								<el-popover v-if="schedule.orientations" trigger="click" placement="left">
									<span style="width: 290px;"
										class="row">
	                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/icons8/ios/error--v1_danger.png" width="22">
											<h4 class="p-0 m-0">Orientações de concretagem</h4>
										</div>
			              <div class="col-12">
			                <h5 class="font-weight-normal" style="word-break: break-word;">
			                	{{ schedule.orientations }}
			                </h5>
			              </div>
	                </span>
									<img class="ml-2 pointer"
										slot="reference"
										src="/img/icons/icons8/ios/error--v1_danger.png" width="25">
								</el-popover>
								<el-popover trigger="click" placement="left">
									<img class="ml-2 pointer"
										slot="reference"
										src="/img/icons/icons8/ios/test-tube_primary.png" width="25">
	                <div style="margin-bottom: 5px" v-if="item.mix_code_id" v-for="item in schedule.company_plants">
	                  <i title="Copiar código do traço" @click.prevent="copyMixCode(item.mix_code_id)"
	                     style="font-size: 19px" class="zoom pointer fa-regular fa-clipboard text-primary"></i>
	                  <span class="font-weight-400 ml-2">{{ item.company_plant_name }} <badge style="font-size: 10px"
	                                                                                        type="default">{{
	                    item.mix_code_id
	                  }}</badge></span>
	                </div>
	                <div class="border-left border-3 border-primary p-2 mt-1">
	                  <h5 class="h4 mb-0">
	                    {{ schedule.contract_proposal_formulation.title }}
	                  </h5>
	                  <div class="mt-2 mb-3">
	                    <h5 class="small">
	                      {{ schedule.contract_proposal_formulation.body }}
	                    </h5>
	                  </div>
	                  <div class="mt-2 mb-3">
	                    <h5 class="small">
	                      {{ schedule.contract_proposal_formulation.footer }}
	                    </h5>
	                  </div>
	                </div>
	                <div
	                  class="mt-2 border-left border-3 border-indigo p-2 "
	                  v-if="schedule.contract_proposal_formulation.puzl_additions && schedule.contract_proposal_formulation.puzl_additions.length">
	                  <div v-for="(puzl_addition, index) in schedule.contract_proposal_formulation.puzl_additions"
	                       :key="puzl_addition.id">
	                    <h5 class="text-indigo mb-0">
	                      {{ puzl_addition.name }}
	                      ({{ puzl_addition.volume }} <span style="text-transform: none">kg/m</span><sup>3</sup>)
	                      <span class="text-indigo"
	                            v-if="index != schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>
	                    </h5>
	                  </div>
	                </div>
	                <div v-if="$hasPermission('operational.change_feature_level')"
	                       class="form-group row m-0 p-0 mb-1 mr-n3">
	                    <label class="col-md-12 pb-0 col-form-label form-control-label">
	                      Nível de Aplicação
	                      <span class="text-danger">&nbsp;*</span>
	                    </label>
	                    <div class="col-md-12">
	                      <validation-provider rules="required" v-slot="{ errors }">
	                        <base-input input-group-classes="input-group-sm">
	                          <puzl-select :clearable="false" @change="setFeatureLevel(schedule)"
                                         :loading="loadingFeatureLevels"
                                         v-model="schedule.feature_level" :items="$_feature_levels"
	                                       customKey="value" label="label"/>
	                        </base-input>
	                      </validation-provider>
	                    </div>
	                    <div class="col-md-12 mt-2" style="width: 50px !important;" v-if="schedule.feature_level">
	                      <table class="table ">
	                        <thead>
	                        <tr>
	                          <th>
	                            <div class="d-flex" style="font-size: 12px">
	                              Peça
	                              <i v-if="!schedule.has_feature_level.piece" data-v-4743fe08=""
	                                 class="fa-solid fa-ban text-danger"
	                                 style="font-size: 17px;margin-left: 10px;"></i>
	                              <i v-else class="fa-regular fa-circle-check text-success"
	                                 style="font-size: 17px;margin-left: 10px;"></i>
	                            </div>
	                          </th>
	                          <th>
	                            <div class="d-flex" style="font-size: 12px">
	                              Traço
	                              <i v-if="!schedule.has_feature_level.item" data-v-4743fe08=""
	                                 class="fa-solid fa-ban text-danger"
	                                 style="font-size: 17px;margin-left: 10px;"></i>
	                              <i v-else class="fa-regular fa-circle-check text-success"
	                                 style="font-size: 17px;margin-left: 10px;"></i>
	                            </div>
	                          </th>
	                        </tr>
	                        </thead>
	                        <tbody>
	                        <tr>
	                        </tr>
	                        </tbody>
	                      </table>
	                    </div>
	                </div>
	              </el-popover>
							</div>
						</div>
						<hr class="mt-2 mb-2">
						<div class="row mt-4 align-items-end">
							<div class="col-8 new-default-black-font font-weight-400" style="display: flex; align-items: end;">
								<div class="mr-2">
									<p v-show="['in_progress', 'pending'].includes(schedule_status[schedule.status.status])" style="font-size: 10px;" class="m-0 p-0 font-weight-400">
										Intervalo Atual
										<i style="font-style: normal;font-size: 11px;" class="text-danger">{{ schedule.current_interval }} min</i>
									</p>
									<p style="font-size: 12px;" class="font-weight-400 m-0 p-0 mt-n1">{{ schedule.volume_performed }} m³ ({{ schedule.travel_performed }}) Realizado</p>
								</div>
								<el-popover v-if="['in_progress', 'pending', 'released'].includes(schedule_status[schedule.status.status])" trigger="click" placement="left">
									<span style="width: 290px;"
										class="row">
	                	<div class="col-8 mb-3" style="display: flex; align-items: center;">
											<img class="mr-2" src="/img/icons/icons8/ios/concrete-mixer-dark_reverse.png" width="22">
											<h4 class="p-0 m-0">Carregamento</h4>
										</div>
										<div class="col-4 pl-0 mb-3 d-flex align-items-center">
											<base-button
												@click.prevent="schedule.latest_charging && $router.push('/operational/schedule/charging/'+ schedule.latest_charging.uuid)"
												block
												size="sm"
												type="success"
												class="text-uppercase"
												outline
												>
												Acessar
											</base-button>
										</div>
			              <div class="col-6 pr-0">
			                <h5 class="font-weight-normal">Última bt</h5>
			              </div>
			              <div class="col-6 text-right pl-0">
			              	<h5 class="font-weight-normal">{{ schedule.latest_charging ? schedule.latest_charging.equipment_code : '' }}</h5>
			              </div>
			              <div class="col-6 pr-0">
											<h5 class="font-weight-normal">Último Motorista</h5>
										</div>
										<div class="col-6 text-right pl-0">
											<h5 class="font-weight-normal">{{ schedule.latest_charging ? schedule.latest_charging.driver_name : '' }}</h5>
										</div>
			              <div class="col-6 pr-0">
											<h5 class="font-weight-normal">Última entrega</h5>
										</div>
										<div class="col-6 text-right pl-0">
											<h5 v-if="schedule.latest_charging && schedule.latest_charging.finished_at" class="font-weight-normal">{{ schedule.latest_charging.finished_at  | parseDate('HH:mm')  }}</h5>
										</div>
	                </span>
									<hr class="mb-3 mt-3">
									<span class="row" style="width: 290px;">
			              <div v-if="!['canceled', 'rescheduled', 'completed', 'not_performed'].includes(schedule_status[schedule.status.status])"
			              	class="col-6 pr-0">
											<h5 class="new-default-black-font">Próximo previsto</h5>
										</div>
										<div v-if="!['canceled', 'rescheduled', 'completed', 'not_performed'].includes(schedule_status[schedule.status.status])"
											class="col-6 text-right pl-0">
											<h5 v-if="schedule.next_travel_uuid && (! schedule.latest_charging || schedule.latest_charging.uuid !== schedule.next_travel_uuid)" class="new-default-black-font">{{ schedule.next_travel }}</h5>
										</div>
			              <div v-show="['in_progress', 'pending'].includes(schedule_status[schedule.status.status])" class="col-6 pr-0">
											<h5 class="new-default-black-font">Intervalo Atual</h5>
										</div>
										<div v-show="['in_progress', 'pending'].includes(schedule_status[schedule.status.status])" class="col-6 text-right pl-0">
											<h5 class="new-default-black-font">{{ schedule.current_interval }} min</h5>
										</div>
										<div class="col-12">
											<div class="card-with-box-shadow text-center pointer">

                      	<div @click.prevent="schedule.next_travel_uuid && $router.push('/operational/schedule/charging/'+ schedule.next_travel_uuid)" class="py-2" style="display: inline-flex; align-items: center;">
                      		<i style="font-style: normal;font-size: 12px;" class="new-default-black-font font-weight-normal mr-1">Próximo carregamento</i>
                      	</div>
											</div>
										</div>
									</span>
									<img class="ml-2 pointer"
										slot="reference"
										src="/img/icons/icons8/ios/concrete-mixer-dark_reverse.png" width="32">
								</el-popover>
							</div>
							<div v-if="! schedule.programmed_volume " class="col-4 text-right font-weight-400 new-default-black-font" style="font-size: 12px;">
								{{ schedule.total_volume < schedule.previous_total_volume ? schedule.previous_total_volume : schedule.total_volume }} M³ {{ "(" + schedule.total_travels + ")" }} PREVISTO
							</div>
							<div v-else class="col-4 text-right font-weight-400 new-default-black-font" style="font-size: 12px;">
								{{ schedule.programmed_volume }} M³ PREVISTO
							</div>
						</div>
	        	<div class="row mt-2">
	        		<div v-if="schedule.volume_performed != schedule.total_volume && schedule.volume_performed > 0" class="col-12" style="display: flex;">
	            	<div :style="{ 'width': getPerformedPercent(schedule).performed }">
	              		<hr :style="{ 'border-top-right-radius': '0px', 'border-bottom-right-radius': '0px' }" class="green-divider">
	            	</div>
	          		<div :style="{ 'width': getPerformedPercent(schedule).remaining }">
	              		<hr :style="{ 'border-top-left-radius': '0px', 'border-bottom-left-radius': '0px' }" class="gray-divider">
	            	</div>
	          	</div>
	        		<div v-else-if="schedule.volume_performed == 0" class="col-12" style="display: flex;">
	          		<div :style="{ 'width': '100%' }">
	              		<hr style="border-radius: 10px;" class="gray-divider">
	            	</div>
	          	</div>
	        		<div v-else class="col-12" style="display: flex;">
	          		<div :style="{ 'width': '100%' }">
              		<hr style="border-radius: 10px;" class="green-divider">
	            	</div>
	          	</div>
	        	</div>
					</div>
					<div>
	        	<div class="row mt-0">
	        		<div class="col-6 pb-3">
	        			<div class="card ml-4 p-1 px-2" style="display: inline;border-radius: 10px !important;">
	        				{{ schedule.id }}
	        			</div>
	        		</div>
	        		<div class="col-6">
	        			<div v-if="schedule.services.length" class="card-blue-bottom text-center dropdown-toggle pointer"  @click.prevent="showServicos(scheduleIndex)">
	                <a style="height: 40px; display: inline-block;"
	                  href="#">
	                  <img v-if="schedule.has_pending_schedule_service_order"
											style="position: absolute; right: 170px; top: -10px; background: white; border-radius: 15px;"
	                  	src="/img/icons/icons8/ios/box-important--v1_danger.png" width="22">
	                  <h3 style="text-transform: none;padding: 8px;padding-right: 5px;">
	                    <span class="mr-1">
	                      <img src="/img/icons/icons8/ios/how-many-quest--v2.png" width="22px">
	                    </span>
	                    <small :id="'servico-toggle-' + scheduleIndex" class="mr-1 text-white" style="font-size: 14px">
	                      SERVIÇO
	                    </small>
	                  </h3>
	                </a>
	              </div>
	        		</div>
	        	</div>
					</div>
					<div v-if="schedule.services.length" class="hide" :class="'servico-' + scheduleIndex">
						<div  class="mx-4" style="margin-top: -1px;">
							<hr class="blue-divider">
						</div>
						<div class="p-3 px-4">
							<div class="row">
								<div class="col-12 pl-4 new-default-black-font font-weight-500" style="font-size: 14px">
									Serviços
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<div v-for="(service, indexService) in schedule.services" :key="indexService" style="border-radius: 15px !important;" class="px-4 py-3 mb-1 mt-3 card-with-box-shadow">
										<div class="row align-items-center">
											<div class="col-6">
												<div :class="getServiceColor(service.status)" style="display: inline-block;">
		                      <h4 class="font-weight-normal m-0 p-0">
		                        {{ getServiceText(service.status) }}
		                      </h4>
		                  	</div>
											</div>
											<div class="col-6 text-right">
												<el-popover v-if="service.employers.length" trigger="click" placement="bottom">
															<!-- Se está cancelada -->
														<div v-if="['not_confirmed'].includes(schedule_status[schedule.status.status])">
															<span v-if="service.updated_user_name" style="width: 290px;"
																class="row">
							                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
																	<img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
																	<h4 class="p-0 m-0">
																		Usuário
						                      </h4>
																</div>
									              <div class="col-12 pr-0">
									                <h5 class="font-weight-normal">
					                      	  {{ service.updated_user_name }}
					                        </h5>
									              </div>
							                </span>
															<hr v-if="service.updated_user_name" class="mb-3 mt-3">
															<span class="row" style="width: 290px;">
							                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
																	<img class="mr-2" src="/img/icons/icons8/ios/concrete-mixer-dark_reverse.png" width="22">
																	<h4 class="p-0 m-0">Justificativa</h4>
																</div>
									              <div class="col-12">
									              	<h5 class="font-weight-normal">{{ service.cancellation_reason }}</h5>
									              </div>
															</span>
														</div>
															<!-- Se não está cancelada -->
														<div v-else>
															<span style="width: 290px;"
																v-for="(employer, indexEmployer) in service.employers"
				                        :key="indexEmployer"
				                        v-if="service.employers.length"
																class="row">
							                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
																	<img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
																	<h4 class="p-0 m-0">
																		Responsável
						                      </h4>
																</div>
									              <div class="col-12 pr-0">
									                <h5 class="font-weight-normal">
					                      	  {{ employer.name }}
					                        </h5>
									              </div>
							                </span>
															<hr v-if="service.equipment" class="mb-3 mt-3">
															<span v-if="service.equipment" class="row" style="width: 290px;">
							                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
																	<img class="mr-2" src="/img/icons/icons8/ios/concrete-pump.png" width="22">
																	<h4 class="p-0 m-0">Equipamento</h4>
																</div>
									              <div class="col-12">
									              	<h5 class="font-weight-normal">{{ service.equipment.code }}</h5>
									              </div>
									              <div class="col-12 mt-n2">
									              	<h5 class="font-weight-normal">{{ service.equipment.plate }}</h5>
									              </div>
															</span>
														</div>
													<base-button outline slot="reference" size="sm" type="secundary"
									             		class="p-0 m-0 mr-2 shadow-none text-indigo">
												  		<img src="/img/icons/icons8/ios/concrete-pump.png" width="25">
													</base-button>
												</el-popover>
												<el-popover trigger="click" placement="bottom">
													<span style="width: 140px;"
														class="row">
					                	<div class="col-12 mb-1" style="display: flex; align-items: center;">
															<img class="mr-2" src="/img/icons/chemical-plant-black.png" width="22">
															<h4 class="p-0 m-0">Central</h4>
														</div>
							              <div class="col-12">
							                <h5 class="font-weight-normal">{{ service.company_plant }}</h5>
							              </div>
					                </span>
													<base-button outline slot="reference" size="sm" type="secundary"
									             		class="p-0 m-0 mr-2 shadow-none text-indigo">
												  		<img src="/img/icons/chemical-plant-black.png" width="25">
													</base-button>
												</el-popover>
												<el-popover trigger="click" placement="bottom">
													<span style="width: 280px;"
														class="row">
					                	<div class="col-12 mb-2" style="display: flex; align-items: center;">
															<img class="mr-2" src="/img/icons/icons8/ios/delivery-time--v1.png" width="22">
															<h4 class="p-0 m-0">Tempo de bombeamento</h4>
														</div>
							              <div class="col-5 pr-0">
							                <h5 class="font-weight-normal">Início:</h5>
							              </div>
							          		<div class="col-7 pl-0 text-right">
							                <h5 class="font-weight-normal">{{ service.start_at ? service.start_at : "" }}</h5>
							              </div>
							              <div class="col-5 pr-0">
							                <h5 class="font-weight-normal">Término:</h5>
							              </div>
							          		<div class="col-7 pl-0 text-right">
							                <h5 class="font-weight-normal">{{ service.end_at ? service.end_at : "" }}</h5>
							              </div>
					                </span>
					                <hr class="mt-2 mb-2">
													<span style="width: 280px;"
														class="row">
							              <div class="col-6 pr-0">
							                <h5 class="font-weight-normal">Duração:</h5>
							              </div>
							          		<div class="col-6 pl-0">
							                <h5 class="font-weight-normal">{{ service.start_at - service.start_at }}</h5>
							              </div>
					                </span>
													<base-button outline slot="reference" size="sm" type="secundary"
									             		class="p-0 m-0 mr-2 shadow-none text-indigo">
												  		<img src="/img/icons/icons8/ios/delivery-time--v1.png" width="25">
													</base-button>
												</el-popover>
												<base-button outline slot="reference"
													v-if="!['Cancelado', 'Não realizado'].includes(schedule_service_status[service.status]) && (service.was_billed === false || hasOperationalPermission('is_user_allowed_to_change_driver_and_equip'))"
													@click.prevent="handleScheduleService(service, schedule.company_plants, schedule.uuid)"
													size="sm" type="secundary"
													class="p-0 m-0 mr-2 shadow-none text-indigo">
											  		<img src="/img/icons/create-new.png" width="25">
												</base-button>
											</div>
										</div>
										<div class="row mt-3 align-items-center">
											<div class="col-12" style="display: flex; align-items: center;">
												<h4 class="new-default-black-font m-0 p-0 mr-2" style="font-size: 14px;">
													{{ service.service_name }}
												</h4>
											</div>
											<div class="col-12 new-default-black-font font-weight-400" style="font-size: 12px">
												{{ service.service_description }}
											</div>
										</div>
										<div class="row mt-4 align-items-end">
											<div class="col-6">
	                      <span class="font-weight-400 new-default-black-font" style="font-size: 11px;"" v-if="service.volume_performed">
	                        {{ service.volume_performed + " m" }}
	                        <sup>3</sup>
	                        (1) REALIZADO
	                      </span>
	                      <span class="font-weight-400 new-default-black-font" style="font-size: 11px;" v-else>
	                        0 m
	                        <sup>3</sup>
	                        (1) REALIZADO
	                      </span>
											</div>
											<div class="col-6 text-right">
												<span class="font-weight-400 new-default-black-font" style="font-size: 11px;">
	                        {{ service.volume ? service.volume + " m" : "" }}
	                        <sup v-if="service.volume">3</sup>
	                        (1) PREVISTO
	                      </span>
											</div>
										</div>
		              	<div class="row mt-2">
		              		<div v-if="service.volume_performed != service.volume && service.volume_performed > 0"
		              			class="col-12" style="display: flex;">
		                  	<div :style="{ 'width': getPerformedPercentService(service).performed }">
		                    		<hr :style="{ 'border-top-right-radius': '0px', 'border-bottom-right-radius': '0px' }" class="green-divider">
		                  	</div>
		                		<div :style="{ 'width': getPerformedPercentService(service).remaining }">
		                    		<hr :style="{ 'border-top-left-radius': '0px', 'border-bottom-left-radius': '0px' }" class="gray-divider">
		                  	</div>
		                	</div>
					        		<div v-else-if="!service.volume_performed" class="col-12" style="display: flex;">
					          		<div :style="{ 'width': '100%' }">
					              		<hr style="border-radius: 10px;" class="gray-divider">
					            	</div>
					          	</div>
					        		<div v-else class="col-12" style="display: flex;">
					          		<div :style="{ 'width': '100%' }">
				              		<hr style="border-radius: 10px;" class="green-divider">
					            	</div>
					          	</div>
		              	</div>
		              	<div class="row mt-2">
		              		<div v-if="!(['Cancelado', 'Não realizado'].includes(schedule_service_status[service.status]) ||
                          service.order_status === null ||
                          (service.order_status !== 1 && service.order_status !== 0))" class="col p-1 px-2">
	                     	<a class="card-with-box-shadow text-center" @click.prevent="$emit('handleCreateServiceCycleModal', schedule, service)" style="display: inherit;"
	                      	href="#">
	                      	<div class="py-2" style="display: inline-flex; align-items: center;">
	                        		<img class="mr-1" width="20" src="/img/icons/clock--v1.png"/>
	                        		<i style="font-style: normal;font-size: 12px;" class="new-default-black-font font-weight-normal mr-1">Ciclo</i>
	                      	</div>
	                    	</a>
	                  	</div>
	                  	<div class="col p-1 px-2">
	                     	<a v-if="!service.code && !['Não realizado', 'Cancelado', 'Solicitação do vendedor', 'Pendências'].includes(schedule_service_status[service.status])"
	                     		class="card-with-box-shadow text-center pointer"
													@click.prevent="createServiceOrder(service, schedule.company_plants, schedule.uuid, scheduleIndex, indexService)"
	                     		style="display: inherit;"
	                      	href="#">
	                      	<div class="py-2" style="display: inline-flex; align-items: center;">
	                        		<img class="mr-1" width="20" src="/img/icons/icons8/ios/os.png"/>
	                        		<i style="font-style: normal;font-size: 12px" class="new-default-black-font font-weight-normal mr-1">EMITIR</i>
	                      	</div>
	                    	</a>
	                     	<a v-if="service.code" class="card-with-box-shadow text-center pointer" style="display: inherit;"
	                     		@click.prevent="getReport(service.order_uuid)">
	                      	<div class="py-2" style="display: inline-flex; align-items: center;">
	                        		<img :src="getServiceOrderColor(service.order_status)"
	                        			class="mr-1" width="20" />
	                        		<i style="font-style: normal;font-size: 12px" class="new-default-black-font font-weight-normal mr-1">Nº {{ service.code }}</i>
	                        		<i style="font-style: normal;font-size: 12px" class="new-default-black-font font-weight-normal mr-1"
	                        			v-if="['Cancelado'].includes(schedule_service_status[service.status])">- Cancelada</i>
	                      	</div>
	                    	</a>
	                  	</div>
	                  	<div v-if="!['Cancelado', 'Em andamento', 'Concluído'].includes(schedule_service_status[service.status])" class="col p-1 px-2">
	                     	<a class="card-with-box-shadow text-center" style="display: inherit;"
	                     		@click.prevent="deleteService(service.uuid)"
	                      	href="#">
	                      	<div class="py-2" style="display: inline-flex; align-items: center;">
	                        		<img class="mr-1" width="20" src="/img/icons/icons8/ios/delete_danger.png"/>
	                        		<i style="font-style: normal;font-size: 12px" class="new-default-black-font font-weight-normal mr-1">Excluir</i>
	                      	</div>
	                    	</a>
	                  	</div>
	                  	<div v-if="service.code && service.order_status !== 2 && service.was_billed === false"
	                  		class="col p-1 px-2">
	                     	<a class="card-with-box-shadow text-center" style="display: inherit;"
	                     		@click.prevent="cancelServiceOrder(service.uuid)"
	                      	href="#">
	                      	<div class="py-2" style="display: inline-flex; align-items: center;">
	                        		<img class="mr-1" width="20" src="/img/icons/icons8/ios/cancel-2_danger.png"/>
	                        		<i style="font-style: normal;font-size: 12px" class="new-default-black-font font-weight-normal mr-1">Cancelar</i>
	                      	</div>
	                    	</a>
	                  	</div>
		              	</div>
									</div>
								</div>
							</div>
						</div>
					</div>
	  		</div>
    	</div>
    </div>
    <div class="row card-wrapper" v-show="loadingSkeleton">
      <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
    </div>
  </div>
</template>

<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {formatErrorValidation} from "@/plugins";
import { hasOperationalPermission } from "@/plugins/permissions";
import {mapGetters} from "vuex";

export default {
  name: "CardsSchedules",
  props: {
  	schedules: Array,
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzlGrid,
    PuzlSelect
  },
  data() {
    return {
      format_mixe: period_types,
      loadingAuthorizeRequest: false,
      loadingFeatureLevels: false,
      schedule_length: null,
      colors: ["", "success-light", "primary", "warning", "success"],
      schedule_status: {
        0: 'canceled',
        1: 'rescheduled',
        2: 'seller_request',
        3: 'not_confirmed',
        8: 'interrupted',
        9: 'charging',
        10: 'in_progress',
        11: 'completed',
        13: 'released',
        14: 'unknown',
        15: 'not_performed',
        16: 'pending',
      },
      schedule_service_status: {
        0: "Liberado",
        1: "Concluído",
        2: "Não realizado",
        3: "Cancelado",
        4: "Em andamento",
        5: "Solicitação do vendedor",
        6: "Pendências",
      },
	  hasOperationalPermission: hasOperationalPermission,
    };
  },
  computed: {
    ...mapGetters({
      $_feature_levels: 'mixConcretePiece/feature_levels',
    })
  },
  methods: {
    getPerformedPercent(schedule){
    	const perfomed = schedule.volume_performed/(schedule.total_volume/100);
    	const remaining = 100-perfomed;

    	return {
    		performed: perfomed + '%',
    		remaining: remaining + '%',
    	};
    },
    getPerformedPercentService(service){
    	const perfomed = service.volume_performed/(service.volume/100);
    	const remaining = 100-perfomed;

    	return {
    		performed: perfomed + '%',
    		remaining: remaining + '%',
    	};
    },
    showServicos(index){
      this.$el.querySelector('.servico-' + index).classList.toggle('hide');

      if(this.$el.querySelector('.servico-' + index).classList.contains('hide')){
        this.$el.querySelector('#servico-toggle-' +  index).innerHTML = "SERVIÇO";
      } else {
        this.$el.querySelector('#servico-toggle-' +  index).innerHTML = "OCULTAR";
      }
    },
    createServiceOrder(service, company_plants, schedule_uuid, scheduleIndex, serviceIndex) {
      this.$swal
        .fire({
          title: "Confirmar emissão da O.S?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            let service_order = {
              service_uuid: service.uuid,
              company_plant_uuid: service.company_plant_uuid,
              equipment_uuid: service.equipment_uuid,
              employers: service.employers,
              status: 0
            };

            this.$store
              .dispatch("scheduleService/addServiceOrder", service_order)
              .then(response => {
                this.$notify({
                  type: response.error_type,
                  message: response.message
                });
                let service_order_uuid = response.data.uuid;
                const newService = response.data;
                this.$store
                  .dispatch("scheduleService/generateReport", service_order_uuid)
                  .then(response => {
                    let blob = new Blob([response], {
                      type: "application/pdf"
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    window.open(link, "_blank");

                    this.schedules[scheduleIndex].services[serviceIndex].status = newService.service.status;
                    this.schedules[scheduleIndex].services[serviceIndex].order_status = newService.status;
                    this.schedules[scheduleIndex].services[serviceIndex].code = newService.code;
                    this.schedules[scheduleIndex].services[serviceIndex].order_uuid = newService.uuid;
                    loader.hide();
                  }).catch(error => {
                    loader.hide();
                  });
              })
              .catch(error => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message
                  });
                } else {
                  let message = formatErrorValidation(error.response.data.errors)
                  this.$notify({
                    type: 'danger',
                    message: message
                  })
                  service.schedule_uuid = schedule_uuid
                  this.$emit('handleCreateModalService', service, company_plants);
                }
                loader.hide();
              });
          }
        });
    },
    handleScheduleService(service, plants, schedule_uuid) {
      service.schedule_uuid = schedule_uuid
      this.$emit('handleCreateModalService', service, plants);
    },
    handleCreateServiceCycleModal(schedule, service) {
      this.$emit('handleCreateServiceCycleModal', schedule, service);
    },
    editions(status, completed_travels, cancelled_travels) {
      let forbidden = [
        'canceled',
        'rescheduled',
        'charging',
        'in_progress',
        'completed',
        'not_performed',
      ];
      return forbidden.includes(this.schedule_status[status]) || completed_travels > 0 || cancelled_travels > 0 ? false : true;
    },
    setFeatureLevel(schedule) {
      this.$nextTick(() => {
        let loader = this.$loading.show()
        let params = {
          schedule_uuid: schedule.uuid,
          feature_level: schedule.feature_level
        }
        this.$store.dispatch('schedule/postSetFeatureLevel', params).then(({data}) => {
          schedule.has_feature_level = data
          this.$forceUpdate()
          loader.hide()
        })
      })
    },
    hasPendingService(services) {
      return services.findIndex((item) => item.status === 6) !== -1;
    },
    deleteService(uuid) {
      this.$Swal
        .confirmDelete()
        .then(result => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store
              .dispatch("scheduleService/destroy", uuid)
              .then(response => {
                this.$notify({type: response.error_type, message: response.message});
                this.$Progress.finish();
                this.$emit('handleChangeService', response.data);
              })
              .catch(error => {
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    cancelServiceOrder(uuid) {
      this.$swal
        .fire({
          title: "Confirmar cancelamento da O.S?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill"
          },
          buttonsStyling: false
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$emit('handleCreateModalServiceCancellation', uuid);
          }
        });
    },
    /**
     * função para copiar valores
     * @param val
     */
    copyMixCode(val) {
      this.$helper.copy(val)
      this.$notify({type: 'success', message: 'Código do traço copiado com sucesso!'})
    },
    getReport(service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", service_order_uuid)
        .then(response => {
          let blob = new Blob([response], {
            type: "application/pdf"
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch(error => {
          loader.hide();
        });
    },
    getColor(status) {
        // 0: 'canceled',
        // 1: 'rescheduled',
        // 2: 'seller_request',
        // 3: 'not_confirmed',
        // 8: 'interrupted',
        // 9: 'charging',
        // 10: 'in_progress',
        // 11: 'completed',
        // 13: 'released',
        // 14: 'unknown',
        // 15: 'not_performed',
        // 16: 'pending',

      let color = "";
      switch (status) {
        case 0:
        case 3:
        case 15:
          color = "mini-card-red";
          break;
        case 1:
      	case 2:
				case 8:
        case 16:
          color = "mini-card-yellow";
          break;
        case 10:
          color = "mini-card-gray";
          break;
        case 11:
          color = "mini-card-blue";
          break;
        case 13:
          color = "mini-card-green";
          break;
      }
      return color;
    },
    getServiceColor(status) {
        // 0: "Liberado",
        // 1: "Concluído",
        // 2: "Não realizado",
        // 3: "Cancelado",
        // 4: "Em andamento",
        // 5: "Solicitação do vendedor",
        // 6: "Pendências",

      switch (status) {
        case 0:
        case 1:
          return "mini-card-green";
        case 2:
        case 3:
          return "mini-card-red";
        case 4:
          return "mini-card-gray";
        case 5:
        case 6:
          return "mini-card-yellow";
      }
    },
    getServiceText(status) {
      switch (status) {
        case 0:
          return "Liberado";
        case 1:
          return "Concluído";
        case 2:
          return "Não realizado";
        case 3:
          return "Cancelado";
        case 4:
          return "Em andamento";
        case 5:
          return "Solicitação do vendedor";
        case 6:
          return "Pendências";
      }
    },
    getServiceOrderColor(status) {
      switch (status) {
        case 0:
          return "/img/icons/icons8/ios/os.png";
        case 1:
          return "/img/icons/icons8/ios/os.png";
        case 2:
          return "/img/icons/icons8/ios/os.png";
      }
    },
    authorizeRequest(uuid) {
      this.$swal.fire({
        title: "Você tem certeza?",
        text:
          "Ao confirmar você estará autorizando a programação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continuar!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill"
        },
        buttonsStyling: false
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.loadingAuthorizeRequest = true;
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação."
            });
            this.$store.dispatch("schedule/authorizeRequest", uuid)
              .then(response => {
                this.loadingAuthorizeRequest = false;
                this.$notify({type: response.error_type, message: response.message});
                this.$Progress.finish();
              })
              .catch(error => {
                this.$notify({
                  type: "danger",
                  message: error.data.message
                });
                this.loadingAuthorizeRequest = false;
                this.$Progress.finish();
              });
          }
        })
    },
    hasCreditPendency(schedule) {
      return schedule.status.pendencies && schedule.status.pendencies.length && schedule.status.pendencies.findIndex(item => item.includes('crédito')) !== -1;
    }
  },
  mounted() {
    this.loadingFeatureLevels = true;
    this.$store.dispatch('mixConcretePiece/getFeatureLevels').then(() => {
      this.loadingFeatureLevels = false;
    });
  },
};
</script>

<style scoped>
.text-success-light {
  color: #68b879 !important;
}

.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border: 0.5px solid #E8E8E8;
  border-radius: 16px !important;
}

.card-border-top-success-light {
  border-top: 8px solid #8edb9f;
}

.text-warning-strength {
  color: #68b879 !important;
}

.card-border-top-warning-strength {
  border-top: 8px solid #8edb9f;
}

.card-border-top-default {
  border-top: 8px solid #172b4d;
}

.main-icon-menu {
  min-width: 18px;
}

.text-light {
  color: #8e8e8f !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-gray {
  background-color: #646e7d2e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #5d636a52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-gray h4 {
  color: #66696c;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4, .mini-card-gray h4 {
  font-size: 12px;
}

.green-divider {
  background-color:  #50bd40;
  color: #50bd40;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.gray-divider {
  background-color: #606062;
  color: #606062;
  margin: 0;
  margin-bottom: 15px;
  padding-top: 3px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-blue-bottom {
  border-top-left-radius: 35px;
  background-color: #1b6eba;
  color: white;
  max-width: 190px;
  min-width: 190px;
  float: right;
}

.blue-divider {
	background-color: #7ec5ff;
	color: #7ec5ff;
	margin: 0;
	margin-bottom: 15px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.hide {
  display: none;
}
</style>
