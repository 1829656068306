<template>
  <div class="row" v-if="$_purchase_requests_listed.items.length > 0">
    <div class="col-md-12 table-responsive" style="min-height: 280px;">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="text-center">Status</th>
            <th>Código</th>
            <th>Categoria</th>
            <th>Planta</th>
            <th>Prazo</th>
            <th class="text-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in $_purchase_requests_listed.items" :key="index">
            <!-- Status -->
            <td>
              <div class="d-flex align-items-center justify-content-center">
                <div class="card-status" :class="getStatusClass(item.status)">
                  <img height="17" class="mr-1" :src="getStatusIcon(item.status)" />
                  {{ getStatusText(item.status) }}
                </div>
              </div>
            </td>
            <!-- Código -->
            <td>
              <div class="d-flex align-items-center justify-content-between">
                <span>
                  {{ 'RC-' + item.id }}
                </span>
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div v-if="PurchaseRequestStatusEnum.keys.PENDING === item.status" class="mr-2"
                    @click="handleShowModalListItemsApproval(item)">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div v-if="item.has_approved_items && item.status !== PurchaseRequestStatusEnum.keys.PENDING" class="mr-2" @click="handleShowModalListItems(item, false)">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div
                    v-if="item.has_denied_items && item.status !== PurchaseRequestStatusEnum.keys.PENDING"
                    class="mr-2" @click="handleShowModalListItems(item, true)">
                    <img src="/img/icons/icons8/ios/menu-squared_danger.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1 info-content">{{ item.note }}</div>
                        </div>
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1 info-content">{{ item.purchase_justification }}
                          </div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </td>
            <!-- Categoria -->
            <td>
              <span style="font-size: 16px; font-weight: 400; color: #2B2D32;">
                {{ item.purchase_category_description }}
              </span>
            </td>
            <!-- Planta -->
            <td>
              <span style="font-size: 16px; font-weight: 400; color: #2B2D32;">
                {{ item.input_company_plant_name }}
              </span>
            </td>
            <!-- Prazo -->
            <td>
              <span style="font-size: 16px; font-weight: 400; color: #2B2D32;">
                {{ item.deadline | parseDate("DD MMM YYYY") }}
              </span>
            </td>
            <!-- Ações -->
            <td>
              <base-dropdown menuOnRight>
                <div slot="title-container" class="dropdown-toggle rounded">
                  <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png">
                </div>
                <div
                  v-if="item.status != PurchaseRequestStatusEnum.keys.CANCELED && item.status != PurchaseRequestStatusEnum.keys.APPROVED">
                  <a @click="redirectToEdit(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                    Editar
                  </a>
                  <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                </div>
                <a @click="handleShowModalHistory(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                  Histórico
                </a>
                <div @click="removePurchaseRequest(item)"
                  v-if="item.status != PurchaseRequestStatusEnum.keys.CANCELED && !item.has_active_budget">
                  <div style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);"></div>
                  <a class="dropdown-item d-flex" style="font-size: 12px;">
                    <img width="18" src="/img/icons/icons8/ios/delete_danger.png">
                    Cancelar
                  </a>
                </div>
              </base-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ModalListItemsApproval @fetchPurchaseRequests="fetchPurchaseRequests" ref="modalListItemsApproval" />
    <ModalListItems @fetchPurchaseRequests="fetchPurchaseRequests" ref="modalListItems" />
    <ModalAttachment ref="modalAttachment" />
    <ModalPurchaseRequestHistory ref="modalHistory" />
  </div>
</template>

<script setup>
//#region Imports
import { ref, defineEmits, computed } from "vue";
import ModalListItemsApproval from "./Shared/_ModalListItemsApproval.vue";
import ModalListItems from "./Shared/_ModalListItems.vue";
import ModalAttachment from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalAttachment.vue";
import ModalPurchaseRequestHistory from "./Shared/_ModalPurchaseRequestHistory.vue";
import PurchaseRequestStatusEnum from "../../enums/PurchaseRequestStatusEnum";
import purchaseRequestStore from "../../store/purchaseRequestStore";
import { dialogs } from "../../../../../../../helpers";
import router from "@/routes";
//#endregion

//#region Emits
const emit = defineEmits(["fetch"]);
//#endregion

//#region ComponentRefs
const modalListItemsApproval = ref(null);
const modalListItems = ref(null);
const modalAttachment = ref(null);
const modalHistory = ref(null);
//#endregion

//#region Computeds
const $_purchase_requests_listed = computed(() => purchaseRequestStore.getters.getListed());
//#endregion

// #region Methods
function handleShowModalListItemsApproval(data) {
  modalListItemsApproval.value.openModal(data);
}

function handleShowModalListItems(data, isDeniedItems) {
  data.isDeniedItems = isDeniedItems;
  modalListItems.value.openModal(data);
}

function handleShowModalHistory(data) {
  data.modalTitle = "Histórico de Requisição";
  data.modalText = "Histórico de Requisição";
  modalHistory.value.openModal(data);
}

async function handleShowAttachments(id) {
  filter.value.purchase_request_id = id;
  const loader = dialogs.showLoading();
  const attachments = await getAttachments();
  modalRefs.value.modalAttachment.openAttachment(attachments.data.items);
  loader.hide();
}
async function getAttachments() {
  return await purchaseRequestStore.actions.fetchRequestAttachments(filter.value);
}
function redirectToEdit(item) {
  if (!item.can_approve) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Você não possui permissão para editar requisição!')
    return;
  }
  router.push('/purchase/request/edit/' + item.id);
}

function getStatusText(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "Pendente";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "Aprovado";
    case PurchaseRequestStatusEnum.keys.DENIED: return "Negado";
    default: return "Cancelado";
  }
}

function getStatusClass(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "pending-status";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "approved-status";
    case PurchaseRequestStatusEnum.keys.DENIED: return "denied-status";
    default: return "denied-status";
  }
}

function getStatusIcon(status) {
  switch (status) {
    case PurchaseRequestStatusEnum.keys.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
    case PurchaseRequestStatusEnum.keys.APPROVED: return "/img/icons/icons8/ios/thumb-up_primary.png";
    case PurchaseRequestStatusEnum.keys.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
    default: return "/img/icons/icons8/ios/cancel_danger.png";
  }
}

async function removePurchaseRequest(purchaseRequest) {
  if (purchaseRequest.has_active_budget) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Já foi iniciada a cotação dessa RC!')

    return;
  }

  const isConfirmed = await dialogs.confirmAction("Tem certeza que deseja CANCELAR essa requisição?");
  if (isConfirmed) {
    purchaseRequest.status = PurchaseRequestStatusEnum.keys.CANCELED;
    await purchaseRequestStore.actions.update(purchaseRequest);
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Requisição cancelada!');
    emit("fetch");
  }
}

function fetchPurchaseRequests() {
  emit("fetch");
}
//#endregion
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

* {
  font-family: Fredoka;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 400 !important;
  margin-top: 0.1rem;
  width: 122px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 9px;
  margin-bottom: 7px;
}

.table {
  $default-table-text-color: #2b2d32;
  border-collapse: separate;
  border-spacing: 2px 2px;

  th,
  td {
    box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    color: $default-table-text-color;
    vertical-align: middle;
    border-radius: 5px !important;
    font-size: 12px !important;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media screen and (max-width: 768px) {
      min-width: max-content;

      .permission-mobile {
        min-width: 190px;
      }
    }
  }

  th {
    padding-left: 8px;
    padding-right: 8px;
    background: #cccccc !important;
    font-family: Fredoka !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  th:first-of-type,
  td:last-of-type {
    width: 5% !important;
  }

  td {
    font-weight: 400 !important;
    font-family: Fredoka !important;
    font-size: 8px !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;

    span {
      font-size: 12.35px !important;
    }


    &.p-16 {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }

  .limit-text {
    display: inline-block;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.info-content {
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  padding: 3px 5px;
  margin-top: 1rem;
}



td>div {
  font-size: 12px !important;
}
</style>