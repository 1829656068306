<template>
  <div class="row card-wrapper" v-if="$_purchase_orders_listed.items">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in $_purchase_orders_listed.items" :key="index">
      <div class="card main-card">
        <div class="pt-3 px-3 pb-0 d-flex justify-content-between">
          <div>
            <div class="d-flex">
              <base-dropdown menuOnRight class="d-flex justify-content-center dropdown-status">
                <div slot="title-container" class="dropdown-toggle rounded">
                  <div class="align-self-center card-status mr-3 mb-1 pointer d-flex align-items-center"
                    :class="getStatusClass(item.order_status)">
                    <img class="mr-1" ref="icon" height="17" :src="getStatusIcon(item.order_status)" />
                    <span style="font-size: 13px; margin: 3px 3px 0 0;">{{ getStatusText(item.order_status) }}</span>
                    <img class="mr-1" ref="icon" height="15" :src="getArrowIcon(item.order_status)" />
                  </div>
                </div>
                <div>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.OPEN"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.keys.COMPLETED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/double-tick-primary.png" width="18" alt="">
                    Concluir
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.COMPLETED || item.order_status == PurchaseOrderStatusEnum.keys.CANCELED"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.keys.OPEN)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/circle-left_success.png" width="18" alt="">
                    Retomar
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.keys.OPEN, true)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/thumb-up_success.png" width="18" alt="">
                    Aprovar
                  </a>
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.PENDING"
                    @click.prevent="updateStatusOrder(item, PurchaseOrderStatusEnum.keys.DENIED)"
                    class="dropdown-item d-flex" style="font-size: 12px;">
                    <img src="/img/icons/icons8/ios/thumbs-down_danger.png" width="18" alt="">
                    Negar
                  </a>
                  <div v-if="item.order_status == PurchaseOrderStatusEnum.keys.OPEN"
                    style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                  <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.OPEN || item.order_status == PurchaseOrderStatusEnum.keys.PENDING"
                    @click.prevent="handleShowModalOrderDenied(item)" class="dropdown-item d-flex"
                    style="font-size: 12px;">
                    <img width="18" src="/img/icons/icons8/ios/unavailable_danger.png">
                    Cancelar
                  </a>
                </div>
              </base-dropdown>
              <div style="margin-top: 3px;">
                <div class="d-flex pointer">
                  <div v-if="item.has_attachments" class="mr-2" @click.prevent="handleShowAttachments(item.id)">
                    <img src="/img/icons/paperclip-green.png" width="22">
                  </div>
                  <div class="mr-2" @click="handleShowModalListItems(item)">
                    <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <span class="font-weight-500">Solicitante:</span>
                      <div style="font-size: 11px">{{ item.created_by_user_name }}</div>
                      <div style="font-size: 11px">{{ item.created_by_user_email }}</div>
                      <span slot="reference">
                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                      </span>
                    </el-popover>
                  </div>
                  <div class="mr-2">
                    <el-popover trigger="click" placement="bottom" class="p-0">
                      <div class="p-0" style="max-width: 14rem;">
                        <span style="font-size: 14px; font-weight: 500;">Observações</span>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Para a requisição de compras</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.note }}</div>
                        </div>
                        <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                        <div class="d-block mt-2">
                          <div style="font-size: 12px;" class="font-weight-500">Necessidade da compra</div>
                          <div style="font-size: 12px;" class="mt-1">{{ item.purchase_justification }}</div>
                        </div>
                      </div>
                      <span slot="reference">
                        <img src="/img/icons/speech-bubble-with-dots--v1-black.png" height="22" />
                      </span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1" tabindex="0" data-bs-toggle="tooltip" :title="item.code">
              <span style="font-size: 18px ;font-weight: 600; color: #2B2D32; cursor: pointer;">
                {{ item.already_approved ? 'OC-' + item.id : 'RC-' + item.purchase_request_id }}
              </span>
            </div>
            <div class="d-flex">
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ $helper.parseDate(item.deadline) }}
              </span>
            </div>
            <div>
              <span style="font-size: 12px ;font-weight: 400; color: #606062;">
                {{ item.purchase_category_description }}
              </span>
            </div>
            <div>
              <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">
                {{ item.entity_name }}
              </span>
            </div>
          </div>
          <div>
            <base-dropdown menuOnRight class="d-flex justify-content-center" style="height: fit-content">
              <div slot="title-container" class="dropdown-toggle rounded">
                <img width="30" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a v-if="item.order_status == PurchaseOrderStatusEnum.keys.COMPLETED || item.order_status == PurchaseOrderStatusEnum.keys.OPEN"
                  @click="downloadOrderResumePdf(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img src="/img/icons/icons8/ios/print-primary.png" width="22" alt="">
                  Imprimir
                </a>
                <div
                  v-if="item.order_status == PurchaseOrderStatusEnum.keys.COMPLETED || item.order_status == PurchaseOrderStatusEnum.keys.OPEN"
                  style="border-bottom: 1px solid hsl(0, 0%, 0%, 10%);" />
                <a @click="handleShowModalHistory(item)" class="dropdown-item d-flex" style="font-size: 12px;">
                  <img width="18" src="/img/icons/icons8/ios/order-history_secondary.png">
                  Histórico
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div class="dropdown-divider p-0 mx-3" />
        <div class="mx-3">
          <el-popover trigger="click" placement="top" class="p-0">
            <div style="font-size: 13.5px !important; font-weight: 500; letter-spacing: 1px;">Saldos</div>
            <div class="dropdown-divider p-0 mx-0" />
            <table style="width: 100%; font-size: 12px; margin-top: 8px;">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Realizado</th>
                  <th>Saldo</th>
                  <th>Barra</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="subItem in item.items" :key="subItem.id">
                  <el-popover trigger="click" placement="top" class="p-0">
                    {{ subItem.description }}
                    <span slot="reference">
                      <td class="truncate-text">{{ subItem.description }}</td>
                    </span>
                  </el-popover>
                  <td>{{ $helper.toMoney(subItem.total_amount - subItem.balance) }}</td>
                  <td>{{ $helper.toMoney(subItem.balance) }}</td>
                  <td>
                    <div class="progress-bar-container mt-0">
                      <div class="progress-bar"
                        :style="{ width: progressPercentage(subItem.total_amount, subItem.balance) + '%' }"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <span slot="reference">
              <div class="d-flex justify-content-between">
                <div>
                  <div class="mx-1">
                    <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                      Valor total
                    </span>
                  </div>
                  <div class="mx-1">
                    <span style="font-size: 15px ;font-weight: 500; color: #2B2D32;">
                      {{ $helper.toMoney(item.total_value) }}
                    </span>
                  </div>
                </div>
                <div>
                  <div>
                    <span style="font-size: 12px ;font-weight: 400; color: #2B2D32;">
                      Saldo
                    </span>
                  </div>
                  <span style="font-size: 15px ;font-weight: 500; color: #2B2D32;">
                    {{ $helper.toMoney(item.balance) }}
                  </span>
                </div>
              </div>
            </span>
          </el-popover>
        </div>
        <div class="progress-bar-container mb-3 mx-3">
          <div class="progress-bar" :style="{ width: progressPercentage(item.total_value, item.balance) + '%' }"></div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval @fetch="fetchEntityBudgets" @chageStatus="updateStatusOrder" ref="modalListItemsApproval" />
    <ModalOrderDeniedJustification @fetch="fetchEntityBudgets" ref="modalOrderDeniedJustification" />
    <ModalPurchaseOrderHistory ref="modalHistory" />
  </div>
</template>

<script setup>
// #region Imports
import { computed, defineEmits, ref, onMounted } from "vue";
import { useStore } from "vuex";
import PurchaseOrderStatusEnum from "../../enums/PurchaseOrderStatusEnum";
import ModalListItemsApproval from "../shared/_ModalListItemsApproval.vue";
import ModalOrderDeniedJustification from "../shared/_ModalOrderDeniedJustification";
import ModalPurchaseOrderHistory from "../shared/_ModalPurchaseOrderHistory.vue";
import { dialogs, progress } from "@/helpers";
import purchaseOrderStore from "../../store/purchaseOrderStore";
import store from "@/store/index";
// #endregion

// #region Emits
const emit = defineEmits(["fetch"]);
// #endregion

// #region ComponentRefs
const modalListItemsApproval = ref(null);
const modalOrderDeniedJustification = ref(null);
const modalHistory = ref(null);
// #endregion

// #region Computeds
/** Store Getters */
const $_purchase_orders_listed = computed(() => purchaseOrderStore.getters.getListed());
const $_is_loading = computed(() => purchaseOrderStore.getters.getIsListing());
// #endregion

// #region Methods
function handleShowModalListItems(data) {
  modalListItemsApproval.value.handleCreateModal(data);
}
function handleShowModalOrderDenied(data) {
  modalOrderDeniedJustification.value.handleCreateModal(data);
}
function handleShowModalHistory(data) {
  modalHistory.value.handleCreateModal(data);
}

/**
 * Atualiza o status da ordem de compra.
 * @param {Object} purchaseOrder - Dados da ordem de compra.
 * @param {number} status - Novo status.
 * @param {boolean} isApproval - Define se a ação é de aprovação.
 */
async function updateStatusOrder(purchaseOrder, status, isApproval = false) {
  const permissions = checkPermission(purchaseOrder, status);
  if (!permissions.hasPermission) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, permissions.text);
    return;
  }

  const isConfirmed = await dialogs.confirmAction(`Tem certeza que deseja ${permissions.action} essa requisição?`);
  if (isConfirmed) {
    progress.start();
    const loader = progress.showLoader();
    await store.dispatch(`purchaseOrder/${isApproval ? "approval" : "update"}`, {
      id: purchaseOrder.id,
      purchase_order_id: purchaseOrder.id,
      status,
      code: purchaseOrder.order_code,
    });
    progress.finish();
    loader.hide();
    emit("fetch");
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Status atualizado!');
  }
}

function progressPercentage(totalValue, balance) {
  return ((totalValue - balance) / totalValue) * 100;
}

/**
 * Baixa o relatório da ordem de compra em PDF.
 * @param {Object} order - Ordem de compra.
 */
async function downloadOrderResumePdf(order) {
  if (order.url) {
    window.open(order.url, "_blank");
    return;
  }

  progress.start();
  const loader = progress.showLoader();
  try {
    const response = await store.dispatch("purchaseOrder/getReportPdf", order.id);
    window.open(response.data, "_blank");
  } finally {
    progress.finish();
    loader.hide();
  }
}

function checkPermission(purchaseOrder, status) {
  if (purchaseOrder.limit_order_approval_value && purchaseOrder.total_value > purchaseOrder.max_approval_value) {
    this.$notify({ type: 'warning', message: 'Usuário não possui permissão para aprovar esse valor' });
    return
  }
  switch (status) {
    case PurchaseOrderStatusEnum.keys.COMPLETED:
      return {
        hasPermission: purchaseOrder.purchase_order_complete && purchaseOrder.can_approve,
        text: "Usuário não tem permissão para concluir!",
        action: "Concluir"
      };
    case PurchaseOrderStatusEnum.keys.OPEN:
      if (purchaseOrder.order_status === PurchaseOrderStatusEnum.keys.COMPLETED ||
        purchaseOrder.order_status === PurchaseOrderStatusEnum.keys.CANCELED) {
        return {
          hasPermission: purchaseOrder.purchase_order_resume && purchaseOrder.can_approve,
          text: "Usuário não tem permissão para retomar!",
          action: "Retomar"
        };
      }
      return {
        hasPermission: purchaseOrder.purchase_order_approve && purchaseOrder.can_approve,
        text: "Usuário não tem permissão para aprovar!",
        action: "Aprovar"
      };
    case PurchaseOrderStatusEnum.keys.CANCELED:
      return {
        hasPermission: purchaseOrder.purchase_order_cancel && purchaseOrder.can_approve,
        text: "Usuário não tem permissão para cancelar!",
        action: "Cancelar"
      };
    case PurchaseOrderStatusEnum.keys.DENIED:
      return {
        hasPermission: purchaseOrder.purchase_order_denied && purchaseOrder.can_approve,
        text: "Usuário não tem permissão para negar!",
        action: "Negar"
      };
    default:
      return {
        hasPermission: false,
        text: "Ação inválida!",
        action: ""
      };
  }
}

function getStatusText(status) {
  switch (status) {
    case PurchaseOrderStatusEnum.keys.PENDING: return "Pendente";
    case PurchaseOrderStatusEnum.keys.OPEN: return "Aberta";
    case PurchaseOrderStatusEnum.keys.DENIED: return "Negado";
    case PurchaseOrderStatusEnum.keys.COMPLETED: return "Concluída";
    default: return "Cancelado";
  }
}
function getStatusClass(status) {
  switch (status) {
    case PurchaseOrderStatusEnum.keys.PENDING: return "pending-status";
    case PurchaseOrderStatusEnum.keys.OPEN: return "approved-status";
    case PurchaseOrderStatusEnum.keys.COMPLETED: return "completed-status";
    default: return "denied-status";
  }
}
function getStatusIcon(status) {
  switch (status) {
    case PurchaseOrderStatusEnum.keys.PENDING: return "/img/icons/icons8/ios/hourglass_warning.png";
    case PurchaseOrderStatusEnum.keys.OPEN: return "/img/icons/icons8/ios/shopping-cart.png";
    case PurchaseOrderStatusEnum.keys.DENIED: return "/img/icons/icons8/ios/thumbs-down_danger.png";
    case PurchaseOrderStatusEnum.keys.COMPLETED: return "/img/icons/icons8/ios/double-tick-primary.png";
    default: return "/img/icons/icons8/ios/cancel_danger.png";
  }
}
function getArrowIcon(status) {
  switch (status) {
    case PurchaseOrderStatusEnum.keys.PENDING: return "/img/icons/icons8/ios/arrow_down_warning.png";
    case PurchaseOrderStatusEnum.keys.OPEN: return "/img/icons/icons8/ios/arrow_down_success.png";
    case PurchaseOrderStatusEnum.keys.DENIED: return "/img/icons/icons8/ios/arrow_down_danger.png";
    case PurchaseOrderStatusEnum.keys.COMPLETED: return "/img/icons/icons8/ios/arrow_down_primary.png";
    default: return "/img/icons/icons8/ios/arrow_down_danger.png";
  }
}

/**
 * Atualiza a lista de ordens de compra.
 */
function fetchEntityBudgets() {
  emit("fetch");
}

// #endregion

// #region Lifecycle
onMounted(() => {
  fetchEntityBudgets();
});
// #endregion
</script>

<style scoped>
table th {
  font-size: 13px !important;
  padding-right: 2rem !important;
}

table td {
  font-size: 12px !important;
  padding-right: 2rem !important;
  padding-top: 0.3rem !important;
  font-weight: 600 !important;
}

table td:first-child {
  font-weight: 500 !important;
}

table td:last-child {
  min-width: 9rem;
  padding-right: 1rem !important;
}

.main-card {
  border-radius: 10px;
  border: 1px solid #E8E8E8;
  box-shadow: none !important;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.card-status {
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.3rem 0.8rem;
  margin-top: 0.1rem;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.completed-status {
  background-color: #f2f4f9;
  color: #1A70B7;
}

.dropdown-status .dropdown-toggle::after {
  display: none;
}

.progress-bar-container {
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 8px;
}

.progress-bar {
  height: 100%;
  background-color: #a3b7d7;
  transition: width 0.3s ease;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
  display: inline-block;
}

@media screen and (max-width: 600px) {
  table td:last-child {
    min-width: 2rem;
    padding-right: 1rem !important;
  }

  .truncate-text {
    max-width: 125px;
  }

  table th {
    padding-right: 1rem !important;
  }

  table td {
    padding-right: 1rem !important;
  }
}
</style>
