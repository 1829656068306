<template>
  <div>
    <modal size="lg"
      :show.sync="isOpen"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/icons8/ios/plus-2-math_success.png" alt="plus-2-math_success" width="25px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
          </div>
        </template>
        <template slot="close-button" >
          <AppButton
            icon="close"
            width="auto"
            type="link"
            size="sm"
            :hasOutline="true"
            @click="closeModal"
          />
        </template>
      </template>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="d-flex" style="gap: 7px;">
              <div class="credit-release-value-card bg-normal w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;">
                  <section class="top-section">
                    <AppIcon
                      icon="calendar"
                      color="dark"
                      width="24"
                    />
                  </section>
                  <section class="mt-2">
                    <section class="mid-section puzl-dark">
                      Valor Programado
                    </section>
                    <span
                      style="font-weight: 500;font-size: 16px;"
                      class="puzl-dark"
                    >
                      {{ toMoney($_programmed_value) }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="credit-release-value-card bg-normal w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;">
                  <section class="top-section">
                    <AppIcon
                      icon="wallet"
                      color="primary"
                      width="24"
                    />
                  </section>
                  <section class="mt-2">
                    <section class="mid-section puzl-dark">
                      Saldo Total
                    </section>
                    <span
                      style="font-weight: 500;font-size: 16px;"
                      class="puzl-dark"
                    >
                      {{ toMoney($_history.total_balance) }}
                    </span>
                  </section>
                </div>
              </div>
              <div class="credit-release-value-card bg-primary-puzl w-100">
                <div class="mx-2 my-2 d-flex flex-column" style="gap: 5px;">
                  <section class="top-section d-flex align-items-center" style="gap: 10px;" >
                    <AppIcon
                      icon="check-dollar"
                      color="primary"
                      width="24"
                    />
                    <div
                      class="d-flex align-items-center p-2 ml-auto pointer"
                      :class="!state.with_addition ? 'additional-button' : 'additional-button-checked'"
                      style="gap: 5px;"
                      @click.prevent="applyAddition"
                    >
                      <AppIcon
                        icon="check"
                        :color="state.with_addition ? 'white' : 'dark'"
                        width="16"
                        class="align-self-center"
                      />
                      <span class="align-self-center"> 10% </span>
                    </div>
                  </section>
                  <section class="mt-2">
                    <section class="mid-section puzl-primary">
                      Total para Liberação
                    </section>
                    <span
                      style="font-weight: 500;font-size: 16px;"
                      class="puzl-primary"
                    >
                      {{ toMoney(state.total_value) }}
                    </span>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="d-flex align-items-center" style="gap: 8px;">
              <AppIcon
                icon="squared-dollar"
                color="success"
                width="25"
                heigth="25"
                class="align-self-center m-0"
              />
              <span class="text-title"> Liberação </span>
              <div class="d-flex ml-auto" style="gap: 8px;" >
                <AppButton
                  label="Zerar Crédito"
                  icon="eraser"
                  type="danger"
                  class="custom-button-280220251741 pointer"
                  :hasOutline="true"
                  size="sm"
                  width="20"
                  :isBlock="false"
                  @click.prevent="create(false)"
                  :isDisabled="!state.user_credit_config.credit || !$_item.id"
                />
                <AppButton
                  label="Replicar Liberação"
                  icon="copy"
                  type="primary"
                  class="custom-button-170220251444 pointer"
                  :hasOutline="true"
                  size="sm"
                  width="20"
                  :isBlock="false"
                  @click.prevent="$_item.credit = state.total_value.toFixed(2)"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-2">
            <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
              <form
                @submit.prevent="handleSubmit(create)"
                class="needs-validation"
                autocomplete="off"
              >
                <div class="credit-release-form p-3 d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <span class="credit-release-input-title" > Limite de Crédito </span>
                    <div class="ml-auto" style="width: 40%;" >
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm">
                        <input
                          v-money="state.money"
                          maxlength="13"
                          inputmode="numeric"
                          v-model.lazy="$_item.credit"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          type="text"
                          class="form-control form-control-sm"
                        />
                        <template slot="prepend">
                          <small class="input-group-sm p-0 m-0"> R$ </small>
                        </template>
                      </base-input>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <span class="credit-release-input-title" > Expiração de Crédito </span>
                    <div class="d-flex align-items-center ml-auto custom-width-170220251503" style="gap: 5px;width: 40%;">
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm">
                        <InputDateTimePicker
                          ref="datePicker"
                          :disableDate="state.min_date"
                          :disableMaxDate="state.max_date"
                          :disabled="state.loadingConcretingDate"
                          mode="date"
                          v-model="$_item.due_date"
                        />
                      </base-input>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <span class="credit-release-input-title align-self-start " > Observações </span>
                    <div class="ml-auto" style="width: 40%;">
                      <base-input :disableBoxShadow="true" input-group-classes="input-group-sm">
                        <textarea
                          v-model="$_item.observation"
                          type="text"
                          rows="4"
                          class="form-control form-control-sm"
                        >
                        </textarea>
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="col-12 mt-3 ml-3">
                  <div class="puzl-danger text-center" v-if="!state.has_credit_limit" >
                    Usuário não possui limite disponível para liberação de crédito!
                  </div>
                  <div class="modal-footer">
                    <div class="d-flex" style="gap: 8px;" >
                      <AppButton
                        label="Cancelar"
                        type="danger"
                        :hasOutline="true"
                        size="md"
                        :isBlock="false"
                        @click="closeModal"
                      />
                      <AppButton
                        label="Salvar"
                        type="success"
                        :hasOutline="true"
                        size="md"
                        :isBlock="false"
                        :isDisabled="!state.user_credit_config.credit || invalid ||$_item.credit == '0,00'"
                        native-type="submit"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { VMoney } from "v-money";
import { reactive, ref, computed, onMounted, onUnmounted, Ref, getCurrentInstance, defineEmits } from 'vue';
import { AppButton, AppIcon } from "../../../../../components/AppGlobal";
import { parseDate, moneyToFloat, dialogs, cloneObject, progress } from "../../../../../helpers";
import moment from 'moment';
import entityFinancialStore from "../../../../../modules/configuration/entity/aggregates/entity-financial/store/entityFinancialStore";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { ValidationObserver } from "vee-validate";
import { formatErrorValidation } from "@/plugins";
import EntityFinancialHubEnum from '../../../../../modules/configuration/entity/aggregates/entity-financial/enums/EntityFinancialHubEnum';
import { toMoney, date } from "../../../../../helpers";
//#endregion

//#region Data
const title = "Limite de Crédito";
const isOpen = ref(false);
const loading = ref(true);
const store = getCurrentInstance().proxy.$store;
const state = reactive({
  money: {
    decimal: ",",
    thousands: ".",
    prefix: "",
    suffix: "",
    precision: 2,
    masked: false,
  },
  min_date: null,
  max_date: false,
  is_juridical: false,
  user_credit_config: {
    credit: null,
    days: false,
  },
  has_credit_limit: true,
  entity_id: null,
  loading: false,
  total_value: 0,
  loadingConcretingDate: false,
  with_addition: false,
});

const $_user = computed(() => store.getters["auth/getUser"]);
const $_item = computed(() => store.getters["entityCredit/show"]);
const $_programmed_value = computed(() => store.getters["entityCredit/getProgrammedValue"]);
const $_history = computed(() => entityFinancialStore.getters.getItem());
//#endregion

//#region Emits
const emit = defineEmits(["onSubmitClick"]);
//#endregion

//#region Methods
/**
 *
 * @param {number} entity_id
 * @param {boolean} is_juridical
 * @param {boolean} has_history - indica se tratá histórico
 */
async function openModal(entity_id, is_juridical = true, has_history = false) {

  if(has_history) {
    await entityFinancialStore.actions.getByEntity(entity_id, { sync_hub: EntityFinancialHubEnum.keys.PUZL });
  }

  loading.value = true;
  state.is_juridical = true;
  state.entity_id = entity_id;
  state.max_date = false;
  state.min_date = new Date(parseDate(moment(), 'YYYY-MM-DD 00:00:00'));
  state.user_credit_config = {
    credit: null,
    days: false
  };
  state.with_addition = false;
  state.loadingConcretingDate = false;

  store.dispatch("entityCredit/show", entity_id)
    .then(async (response) => {
      await normalize();
      getProgrammedValue();
      getLastConcretingDate();
      store.dispatch("userCredit/getByUser", $_user.value.id)
        .then((response) => {
          if(response.data) {
            state.user_credit_config.days = response.data.days;
            state.user_credit_config.credit = state.is_juridical ? response.data.juridical_value : response.data.physical_value;

            let max_date = getUserConfigMaxDate();
            if ($_item.value.due_date) {
              /* Se a data definida já for maior que o limite máximo, o limite máximo passará a ser a data definida para evitar conflitos */
              state.max_date = moment($_item.value.due_date).isSameOrAfter(max_date) ? $_item.value.due_date : max_date;
            } else {
              state.max_date = max_date;
            }
          } else {
            dialogs.notifyError("danger", "Limite de crédito não definido!");
            state.has_credit_limit = false;
          }

          state.max_date = new Date(state.max_date);
          state.min_date = new Date(state.min_date);
          loading.value = false;
          isOpen.value = true;
        })
    });
}

/**
 * Normaliza os dados para exibição
 */
function normalize() {
  if($_item && $_item.value.id) {
    const due_date = new Date(parseDate($_item.value.due_date, "YYYY-MM-DD 00:00:00"));

    if(date.make().isAfter(due_date)) {
      $_item.value.due_date = null;
    } else {
      $_item.value.due_date = due_date;
    }
    return $_item.value.credit = $_item.value.status === false ? 0 : $_item.value.credit;
  }

  return $_item.value.entity_id = state.entity_id;
};

/**
 * Busca valor programado para cliente
 */
function getProgrammedValue() {
  loading.value = true;

  store.dispatch("entityCredit/getProgrammedValue", state.entity_id)
    .then((response) => {
      getTotalValue();
      loading.value = false;
    });
};

/**
 * Calcula valor total
 */
function getTotalValue() {
  if($_history && $_history.value.total_balance) {
    const programmed_value = parseFloat($_programmed_value.value);
    const balance = $_history.value.total_balance < 0 ?
    Math.abs($_history.value.total_balance.toFixed(2)) :
    -Math.abs($_history.value.total_balance.toFixed(2));

    const total_value = programmed_value + balance;
    state.total_value = total_value > 0 ? total_value : 0;

    if($_item.value.credit && $_item.value.credit !== '0,00' && $_item.value.status) {
      const credit = $_item.value.credit.includes(",") ? moneyToFloat($_item.value.credit) : parseFloat($_programmed_value.value);
      state.total_value += credit;
    }
  } else {
    state.total_value = $_programmed_value.value;
  }
};

/**
 * Busca data de concretagem de última programação
 */
function getLastConcretingDate() {
  if(!$_item.value.due_date) {
    state.loadingConcretingDate = true;
    store.dispatch("schedule/getLastConcretingDateByEntity", state.entity_id)
      .then((response) => {
        if(response.data) {
          $_item.value.due_date = response.data;
        }
        state.loadingConcretingDate = false;
      });
  }
};

/**
 * Retorna a data máxima permitida para o usuário
 *
 * @returns {Date}
 */
function getUserConfigMaxDate() {
  return new Date(moment(parseDate(moment(),'YYYY-MM-DD 00:00:00')).add(state.user_credit_config.days, "days").format("YYYY-MM-DD 00:00:00"));
};

/**
 * Formata o item para gravação
 *
 * @returns {Object}
 */
function finalFormat(enabled) {
  let item = cloneObject($_item.value);
  item.credit = enabled ? moneyToFloat(item.credit) : 0;
  item.status = enabled;

  return item;
};

function applyAddition() {
  state.with_addition = !state.with_addition;

  if(state.with_addition && state.total_value > 0) {
    state.total_value += parseFloat($_programmed_value.value) * 0.1;
  } else {
    getTotalValue();
  }
};

async function create(enabled = true) {
  if(!checkRules(enabled)) return;

  progress.start();

  await store.dispatch("entityCredit/add", finalFormat(enabled))
    .then((response) => {
      dialogs.notify();
      onSubmitClick();
      closeModal();
    })
    .catch((error) => {
      if(error.status == 200) {
        dialogs.notifyError("danger", error.data.message);
      } else if(error.response && error.response.status === 422) {
        let errors = formatErrorValidation(error.response.data.errors);
        dialogs.notifyError("danger", errors);
      }
    })
    .finally(() => progress.finish());
};

/**
 * Dispara verificações de validação para crédito e true para válido e false para inválido
 *
 * @returns {boolean}
 */
function checkRules(enabled) {
  switch(true) {
    case state.max_date && (new Date(parseDate(moment($_item.value.due_date), 'YYYY-MM-DD 00:00:00')) > getUserConfigMaxDate()):
      dialogs.notifyError({message: "A data selecionada excede o limite permitido!"});
      return false;
    case state.user_credit_config.credit && parseFloat(moneyToFloat($_item.value.credit)) > parseFloat(state.user_credit_config.credit):
      dialogs.notifyError({message: "O crédito informado excede o limite permitido!"});
      return false;
    case enabled === false && $_item.value.status === false:
      dialogs.notifyError({message: "O crédito atual encontra-se zerado!"});
      return false;
    default:
      return true;
  }
};

function onSubmitClick() {
  emit("onSubmitClick");
}

function closeModal() {
  isOpen.value = false;
};
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} EntityHistoryCreateModalExpose
 * @property {typeof openModal} openModal
 */
 defineExpose({
  openModal,
});
//#endregion

</script>

<script>
export default {
  directives: {
    money: VMoney,
  },
};
</script>

<style scoped>
.credit-release-value-card {
  border: 0.5px solid #0000000D;
  border-radius: 8px;
}
.bg-danger-puzl {
  background-color: #FCF3F3;
}
.bg-success-puzl {
  background-color: #F2F7F3;
}
.bg-primary-puzl {
  background-color: #F2F4F9;
}
.bg-normal {
  background-color: #fff;
}
.bg-grey-puzl {
  background-color: #E8E8E8;
}
.puzl-success {
  color: #149E57;
}
.puzl-danger {
  color: #DB4539;
}
.puzl-primary {
  color: #1A70B7;
}
.puzl-dark {
  color: #2B2D32;
}
.additional-button {
  border-radius: 16px;
  background-color: #E8E8E8;
}
.additional-button-checked {
  background-color: #1A70B7;
  color: #fff;
  border-radius: 16px;
}
.credit-release-form {
  border: 0.5px solid #0000000D;
  border-radius: 16px;
}
.credit-release-input-title {
  font-size: 14px;
  font-weight: 400;
  color: #2B2D32;
}
</style>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";
.custom-button-170220251444 {
  border: 0.5px solid #1A70B7 !important;
  &:hover {
    div.app-icon {
      color: #fff !important;
    }
  }
}
.custom-width-170220251503 {
  div.disable-box-shadow-input-group {
    div.w-100 {
      width: auto !important;
    }
    width: 100% !important;
  }
}
.custom-button-280220251741 {
  border: 0.5px solid $danger !important;
  &:hover {
    div.app-icon {
      color: #fff !important;
    }
  }
}
.custom-width-170220251503 {
  div.disable-box-shadow-input-group {
    div.w-100 {
      width: auto !important;
    }
    width: 100% !important;
  }
}
</style>
