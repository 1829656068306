<template>
  <!-- CONTRACT INFO -->
  <div
    v-if="contractInfo"
    class="d-flex justify-content-between align-items-start page-header contract-proposal-edit-header"
  >
    <div class="contract-info">
      <h3 class="d-block" v-if="!showCpfNumber">
        CÓDIGO:
        <span> {{ contractInfo?.code }} </span>
      </h3>
      <h3 class="d-block">
        CLIENTE:
        <span>
          {{
            contractInfo?.entity?.name
              ? contractInfo?.entity?.entity
              : contractInfo?.construction?.customer_construction?.customer_name
          }}
        </span>
      </h3>
      <h3 class="d-block" v-if="!showCpfNumber">
        OBRA:
        <span>
          {{ contractInfo?.construction?.construction_name }}
        </span>
      </h3>
      <h3 class="d-block" v-if="showCpfNumber">
        CPF/CNPJ:
        <span>
          {{ contractInfo?.entity?.document }}
        </span>
      </h3>
    </div>
    <!-- HEADER BUTTONS -->
    <div class="header-buttons">
      <slot></slot>
    </div>
  </div>
  
</template>

<script setup>
import { onMounted } from "vue";

const props = defineProps({
  contractInfo: {
    type: Object | null,
    required: true,
  },
  showCpfNumber: {
    type: Boolean,
    default: false,
    required: false,
  },
});

onMounted();
</script>

<style lang="scss">
.contract-proposal-edit-header.page-header {
  .header-buttons {
    @media screen and (max-width: 768px) {
      a {
        width: 100%;
        display: flex;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.page-header {
  h3 {
    margin-bottom: 4px;
  }
  span {
    font-weight: 400 !important;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;

    .header-buttons {
      width: 100%;
      margin-bottom: 24px;
    }
  }
}
</style>
