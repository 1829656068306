<template>
  <div>
    <AppFixedPageTitle
      title="Faturas"
      icon="/img/icons/icons8/ios/svgs/receipt.svg"
    />
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loadingSkeleton">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        v-else
        :contractInfo="contract_proposal"
      >
        <AppPageHeaderActions>
          <div 
            @click.prevent="handleCreate(contract_proposal.pending_launch_value)"
            class="bg-white new-default-black-font pending-invoice-btn"
            v-show="contract_proposal.pending_launch_value >= 0.05"
          >
            <img class="mr-1" height="26" src="/img/icons/icons8/ios/box-important--v1_danger.png"/>
            <div style="font-size: 12px;margin-right: 5px;">LANÇAMENTO PENDENTE:</div>
            <nav style="font-size: 12px;margin-right: 5px;">{{ contract_proposal.pending_launch_value | currency }}</nav>
            <img height="30" src="/img/icons/add.png"/>
          </div>

          <AppPageHeaderActionsButton
            type="success"
            @click.prevent="handleCreate()"
            text="Novo"
            icon="/img/icons/icons8/ios/svgs/plus-math.svg"
            class="invert-color"
            >
          </AppPageHeaderActionsButton>
          <AppPageHeaderActionsButton
            :disabled="Object.values(contract_proposal).length == 0"
            type="primary"
            @click="handleEntityHistory(contract_proposal.entity.id)"
            text="Saldo"
            icon="/img/icons/icons8/ios/svgs/wallet.svg"
            class="invert-color"
            >
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>

      <div :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          v-if="contract_proposal"
          :contractUuid="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :entity="contract_proposal.entity"
          :activeInvoices="true"
          style="margin-top: 12px;"
        />
      </div>

      <div class="row align-items-center p-0">
        <div class="container-fluid">
          <div class="mt-4 mb-2" style="display: flex; align-items: center;">
            <i class="pointer fa-regular fa-hard-drive fa-2x"></i>
            <i class="opacity-25 pointer fa-solid fa-table-list fa-2x ml-1"></i>
            <button style="margin: 0;width: 115px; justify-content: center; min-height: 28px; padding: 0; font-size: 11px; display: flex; align-items: center;" slot="title-container"
              @click="handleFilterAdvancedStatus('cancelled')"
              :class="{ active: filter.advanced === 'cancelled' }"
              class="ml-2 btn text-uppercase btn-outline-danger colorize-btn-img"
              type="button">
              <img v-bind:src="filter.advanced === 'cancelled' ? '/img/icons/close-white.png' : '/img/icons/close.png'" width="16px" class="mr-1" />
              Cancelada
            </button>
          </div>
        </div>
      </div>
    </div>
    <ListInvoices ref="list"/>
    <CreatePayment ref="createPayment"/>
    <float-button v-if="contract_proposal" :contract_proposal="contract_proposal" />
    <modal-create-entity @added="$refs.list.getBillReceives()" ref="modalCreateEntity"/>
    <ModalEntityHistory ref="modalEntityHistory"/>
  </div>
</template>

<script>
import ListInvoices from "./Shared/_List";
import CreatePayment from "../Payments/Shared/_Create";
import {mapGetters} from "vuex";
import BackButton from "../../../../../components/Utils/BackButton";
import MultiFilter from "../../../../../components/Utils/MultiFilterV2";
import ModalGeneralResume from "../Shared/_ModalGeneralResume.vue";
import ModalListContacts from "../../../Operational/Schedule/Weighing/Shared/_ModalListContacts";
import FloatButton from "../components/FloatButton/Index.vue";
import CreateNew from "../components/FloatButton/CreateNew/Index.vue";
import ModalCreateEntity from '../../../Financial/BillReceive/Entity/Shared/Modals/_ModalCreateEntity'
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import ContractProposalPageHeader from '../components/ContractProposalPageHeader/ContractProposalPageHeader.vue'
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../components/AppGlobal";

export default {
  components: {
    MultiFilter,
    BackButton,
    ListInvoices,
    CreatePayment,
    ModalGeneralResume,
    ModalListContacts,
    FloatButton,
    CreateNew,
    ModalCreateEntity,
    ModalEntityHistory,
    SkeletonPuzl,
    ContractProposalNavigation,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
    }),
  },
  data() {
    return {
      filter: {
        advanced: null,
      },
      listingType: {
        'Antecipação': 0,
        'Recebimentos': 1,
      },
      loadingSkeleton: true,
    };
  },
  methods: {
    handleCreateEntity() {
      this.$refs.modalCreateEntity.handleCreateModal(this.contract_proposal.entity, this.contract_proposal.company_plant_id)
    },
    handleCreate(default_value = false) {
      this.$refs.createPayment.handleCreateModal(this.contract_proposal.plant, default_value);
    },
    handleFilterAdvancedStatus(key) {
      this.filter.advanced = this.filter.advanced === key ? null : key;
      this.$refs.list.load(this.filter);
    },
    load() {
      this.$refs.list.load(this.filter);
    },
    handleGeneralResume() {
      this.$refs.modalGeneralResume.openModal(this.contract_proposal.id);
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal.construction.uuid);
    },
    handleEntityHistory(entity_id) {
      this.$refs.modalEntityHistory.openModal(entity_id);
    },
    getContractProposal() {
      this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {})
    }
  },
  mounted() {
    this.$refs.list.loadingSkeleton = true
    this.loadingSkeleton = true
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {
      this.loadingSkeleton = false
      this.$refs.list.load({});
      this.$store.dispatch("contractProposalCredit/getBalance", this.contract_proposal.id).then(response => {
        this.contract_proposal.balance = response.data
      });
    });
  },
};
</script>

<style>
.custom-pending-card .card-body {
  padding: 0.6rem !important
}
.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}
.rounded-circle {
  border: 1px solid #D3D3D3;
}
.background-color-circle {
  background-color: #f3f4fa;
}
.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .text-dark {
  color: white !important;
}
</style>

<style scoped lang="scss">
@import '@/style/PuzlCustom/App.scss';
.colorize-btn-img:hover img, .show img {
  filter: brightness(0) invert(1);
}

.pending-invoice-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  transition: all 0.15s ease;
  border: 1px solid $danger;
  padding: 0 12px;
  border-radius: 30px;
  margin-right: 8px;

  &:hover{
   cursor: pointer;
   transform: translateY(-3px);
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
  }
}
</style>
