import {
  BaseListFilterType,
  initBaseListFilterType,
  RangeDateType,
} from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} BankReconciliationListFilterExtType
 * @property {null|0|1} status - Todos: null, 1: Concluído, 2: Pendentes
 * @property {RangeDateType} range - Intervalo entre datas
 * @property {Array<Number>} bank_account_id
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & BankReconciliationListFilterExtType)} BankReconciliationListFilterType
 */
export const BankReconciliationListFilterType = {};

/**
 * Inicializar BankReconciliationListFilterType
 *
 * @returns {BankReconciliationListFilterType}
 */
export const initBankReconciliationListFilterType = () => {
  const now = new Date();
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  return {
    ...initBaseListFilterType(),
    custom_search: {
      columns: [],
      values: [],
    },
    status: null,
    range: {
      start: firstDay,
      end: lastDay,
      default_filterable: true
    },
    bank_account_id: null
  };
};
