<template>
    <div>
      <modal :show.sync="modal.create" :backgroundColor="'##F2F7F3'" size="lg">
        <template slot="header">
          <div
            class="d-flex align-items-center container-fluid"
            style="gap: 12px; margin-left: -28px"
          >
            <div>
              <AppIcon
                icon="plus-math"
                color="success"
                width="24px"
                class="mr-2"
              />
            </div>
            <span class="text-title mt-n1">
              {{ modal.title }}
              <span v-if="contact.form_origin === 'construction'" class="text-title mt-n1">Obra</span>
              <span v-else class="text-title mt-n1">Cliente</span>
            </span>
          </div>
        </template>
        <template slot="close-button">
          <AppButton
            icon="close"
            width="auto"
            type="link"
            size="sm"
            :hasOutline="true"
            @click="closeModal()"
          />
        </template>
        
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="p-3">
              <!-- Seção de Identificação -->
              <div class="section-container mb-4">
                <div class="section-header d-flex align-items-center mb-3">
                  <div class="section-icon mr-2">
                    <AppIcon
                      icon="info-squared"
                      color="warning"
                      width="22px"
                    />
                  </div>
                  <h6 class="section-title mb-0">Identificação</h6>
                </div>
                
                <div class="section-content p-3">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <label class="label-item">Receber e-mail financeiro</label>
                    <ToggleButton 
                      :initialValue="contact?.is_financial ?? 0" 
                      @update:initialValue="contact.is_financial = $event"
                    />
                  </div>
                  
                  <!-- Formulário de dados do contato -->
                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Nome
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                        <input 
                          type="text" 
                          v-model="contact.name" 
                          class="form-control"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      E-mail
                      <span v-if="contact.is_financial" class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider :rules="contact.is_financial ? 'required|email' : ''" v-slot="{ errors }">
                        <base-input input-group-classes="input-group-sm">
                          <input 
                            type="email" 
                            v-model="contact.email" 
                            class="form-control"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Cargo
                    </label>
                    <div class="col-md-9">
                      <base-input input-group-classes="input-group-sm">
                        <input 
                          type="text" 
                          v-model="contact.office" 
                          class="form-control"
                        />
                      </base-input>
                    </div>
                  </div>

                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Celular
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-9">
                      <validation-provider rules="required" v-slot="{errors}">
                        <base-input input-group-classes="input-group-sm">
                          <input 
                            type="text" 
                            v-model="contact.phone"
                            class="form-control" 
                            v-mask="'(##) #####-####'"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Seção de Assinatura Digital -->
              <div class="section-container mb-4">
                <div class="section-header d-flex align-items-center mb-3">
                  <div class="section-icon mr-2">
                    <AppIcon
                      icon="autograph"
                      color="primary"
                      width="22px"
                    />
                  </div>
                  <h6 class="section-title mb-0">Assinatura digital</h6>
                </div>

                <div class="section-content p-3 mt-n2">
                  <div class="form-group row p-0 mb-2">
                    <label class="col-md-3 pb-0 col-form-label label-item">
                      Parte
                    </label>
                    <div class="col-md-9">
                      <base-input input-group-classes="input-group-sm">
                      <select 
                        id="contactSignatureType" 
                        class="form-control"
                        v-model="contact.signature_type"
                      >
                        <option value="nenhum">NENHUM</option>
                        <option value="contratante">CONTRATANTE</option>
                        <option value="testemunha">TESTEMUNHA</option>
                        <option value="fiador">FIADOR</option>
                      </select>
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="d-flex justify-content-end mt-4">
                <AppButton
                  label="Cancelar"
                  type="danger"
                  :hasOutline="true"
                  class="mr-2"
                  @click="closeModal()"
                />
                <AppButton
                  label="Salvar"
                  type="success"
                  native-type="submit"
                  :isDisabled="invalid"
                  :isLoading="loadingSave"
                />
              </div>
            </div>
          </form>
        </validation-observer>
      </modal>
    </div>
  </template>
  
  <script>
  import AppButton from "../../../../../../components/AppGlobal/AppButton/AppButton.vue";
  import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
  import ToggleButton from '../../../../../../components/ToggleButton';
  
  export default {
    name: "ModalListContacts",
    components: { 
      AppButton,
      AppIcon,
      ToggleButton,
    },
    data() {
      return {
        modal: {
          title: "Novo Contato",
          create: false,
        },
        contact: {                  // Tabelas: (`contact_constructions` e `entity_contacts`)
          name: '',                 // Obrigatório nas 2 tabelas
          office: '',               // Obrigatório nas 2 tabelas
          email: '',                // Opcional nas 2 tabelas
          phone: '',                // Opcional nas 2 tabelas
          cell_phone: '',           // Só existe em `contact_constructions`
          sector: '',               // Só existe em `contact_constructions`
          is_financial: false,       // Obrigatório nas 2 tabelas
          status: true,             // Só existe em `contact_constructions`
          signature_type: 'nenhum', // Opcional nas 2 tabelas
          is_user: false,           // Só existe em `contact_constructions`
          is_administrator: false,  // Só existe em `contact_constructions`
          is_technical: false,      // Só existe em `contact_constructions`
          is_commercial: false,     // Só existe em `contact_constructions`
          is_operational: false,    // Só existe em `contact_constructions`
          form_origin: null,        // Indica a origem do formulário ('entity' ou 'construction')
          entity_id: null,          // Só existe em `entity_contacts`
          construction_uuid: null,  // Só existe em `contact_constructions`
        },
        loadingSave: false,
        invalid: false,
        entityId: null,
        constructionUuid: null,
      };
    },
    methods: {
      closeModal() {
        this.modal.create = false;
      },
      /**
       * @param {string} form_origin ('entity' ou 'construction')
       * @param {number} entity_id 
       * @param {string} construction_uuid 
       */
      openModal(form_origin = null, entity_id = null, construction_uuid = null) {
        this.resetForm();
        this.contact.form_origin = form_origin;

        this.contact.entity_id = entity_id ?? null;
        this.contact.construction_uuid = construction_uuid ?? null;

        this.modal.create = true;
      },
      save() {
        this.$Progress.start();
        this.loadingSave = true;

        if (this.contact.form_origin === 'entity') {
          this.$store.dispatch("entityContact/add", this.contact).then(() => {
            this.closeModal();
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao cadastrar contato do cliente'
            });
          })
          .finally(() => {
            this.loadingSave = false;
            this.$Progress.finish();
          });
        }

        if (this.contact.form_origin === 'construction') {
          // Garantir que cell_phone seja preenchido corretamente
          this.contact.cell_phone = this.contact.phone;

          this.$store.dispatch("customerConstructionConstructionsContact/add", this.contact).then(() => {
            this.closeModal();
          })
          .catch(() => {
            this.$notify({
              type: 'danger',
              message: 'Erro ao cadastrar contato da obra'
            });
          })
          .finally(() => {
            this.loadingSave = false;
            this.$Progress.finish();
          });
        }
      },
      resetForm() {
        this.contact = {
          id: null,
          uuid: null,
          name: '',
          office: '',
          email: '',
          phone: '',
          cell_phone: '',
          sector: '',
          is_financial: false,
          status: true,
          signature_type: 'nenhum',
          is_user: false,
          is_administrator: false,
          is_technical: false,
          is_commercial: false,
          is_operational: false,
          form_origin: null,
          entity_id: null,
          construction_uuid: null,
        };
      },
    },
  };
  </script>
  
  <style scoped>
.section-container {
  border-radius: 8px;
  overflow: hidden;
}

.section-header {
  padding: 8px 12px;
}

.section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-title {
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.section-content {
  background-color: white;
  border-radius: 8px;
  border: 1px solid #eaeaea;
}

.form-group select {
  background-color: white;
  height: 40px;
}
</style>