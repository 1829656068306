<template>
  <div class="contract-proposal-list-page">
    <AppFixedPageTitle
      :title="props.type === 'contract' ? 'Contratos' : 'Propostas'"
      icon="/img/icons/client-company.png"
      class="invert-color"
    />

    <AppPageHeader>
      <template #search-bar>
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :isLoading="$_contract_proposal_is_listing"
          :showCompanyPlants="true"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
          :rangeParams="{
            is_range: true,
            bottom_filter: BOTTOM_FILTER_ENUM.START_TODAY_MONTH,
            only_filter_month: false,
          }"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="info-squared"
            color="warning"
          >
            <div class="col-md-12">
              <label> Cpf/Cnpj </label>
              <validation-provider :rules="'document'" v-slot="{ errors }">
                <input
                  :style="errors.length ? 'border: 1px solid #DB4539' : ''"
                  v-model="filter.entity_document"
                  type="text"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  class="app-input"
                />
                <span v-if="errors.length > 0" class="text-danger">
                  CPF/CNPJ Inválido
                </span>
              </validation-provider>
            </div>
            <div class="col-md-12">
              <label>Vendedor</label>
              <PuzlSelectSearch
                :value="filter.seller_uuid"
                :loading="isLoading"
                v-model="filter.seller_uuid"
                customKey="seller_uuid"
                @search="handleFilterSeller"
                @input="debouncedFilterSeller"
                clearable
                class="new-default-black-font"
                :items="$_users"
              />
            </div>
            <div class="col-md-12">
              <label>Situação</label>
              <PuzlSelect
                v-model="filter.situation_id"
                :items="$_situations"
                class="new-default-black-font"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </div>
            <div class="col-md-12">
              <label> UF da obra {{ filter.state }}</label>

              <PuzlSelect
                v-model="filter.state"
                :items="$_states"
                customKey="letter"
                label="letter"
                @input="getCities(filter.state)"
                class="new-default-black-font"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </div>
            <div class="col-md-12">
              <label>Município da obra {{ filter.city }}</label>
              <PuzlSelect
                v-model="filter.city"
                :items="$_cities"
                label="title"
                customKey="title"
                class="new-default-black-font"
                :disabled="$_cities.length === 0 || !filter.state"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="push-pin"
            color="primary"
          >
            <BaseButtonHoverable
              @click="filterByStatus(ContractProposalEnum.PENDING_APPROVAL)"
              :active="filter.status === ContractProposalEnum.PENDING_APPROVAL"
              size="sm"
              class="col-12"
              type="warning"
              icon="hourglass"
              platform="ios"
            >
              PENDENTE DE APROVAÇÃO
            </BaseButtonHoverable>
            <BaseButtonHoverable
              @click="filterByStatus(ContractProposalEnum.PENDING_REVIEW)"
              :active="filter.status === ContractProposalEnum.PENDING_REVIEW"
              size="sm"
              type="warning"
              icon="hourglass"
              platform="ios"
            >
              PENDENTE DE REVISÃO
            </BaseButtonHoverable>
            <BaseButtonHoverable
              @click="handleFilterApprovationStatus()"
              :active="filter.approvation_status"
              size="sm"
              type="warning"
              icon="hourglass"
              platform="ios"
            >
              APROVAÇÃO FINANCEIRA
            </BaseButtonHoverable>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template #header-buttons>
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            v-if="props.type === 'contract'"
            type="success"
            @click.prevent="openModalSearchEntityAndConstruction"
            text="novo"
          />
          <AppPageHeaderActionsButton
            v-else
            type="success"
            @click.prevent="handleShowModalCreateTemporaryCustomerConstruction"
            text="novo"
          />
          <AppPageHeaderActionsButton
            v-if="hasPermission('commercial.contract-proposal.details.view')"
            type="warning"
            text="cadastros"
            icon="/img/icons/product-documents.png"
            isDropdown
          >
            <AppPageHeaderActionsButtonDropItem
              text="TERMOS DE PROPOSTA"
              icon="/img/icons/icons8/ios/document.png"
              @click.prevent="handleShowModalProposalTerms"
            />
            <AppPageHeaderActionsButtonDropItem
              text="TERMOS DE CONTRATO"
              icon="/img/icons/icons8/ios/agreement_primary.png"
              link="/commercial/contract-proposal/contracts"
            />
            <AppPageHeaderActionsButtonDropItem
              text="ASSINATURA DIGITAL"
              icon="/img/icons/autograph-black.png"
              @click.prevent="handleShowModalDigitalSignature"
            />
            <AppPageHeaderActionsButtonDropItem
              text="SITUAÇÃO DO CONTRATO"
              icon="/img/icons/icons8/ios/traffic-light_success.png"
              link="/commercial/contract-proposal/situations"
            />
            <AppPageHeaderActionsButtonDropItem
              text="OBJEÇÕES DO CONTRATO"
              icon="/img/icons/icons8/ios/questions_danger.png"
              link="/commercial/contract-proposal/reason-cancellation"
            />
            <AppPageHeaderActionsButtonDropItem
              text="CONCORRENTES"
              icon="/img/icons/icons8/ios/leaderboard.png"
              link="/commercial/contract-proposal/competitors"
            />
          </AppPageHeaderActionsButton>
          <AppPageHeaderActionsButton
            v-if="
              hasPermission('commercial.report-contract-proposal.index.view')
            "
            type="dark"
            text="RELATÓRIOS"
            icon="/img/icons/graph-report.png"
            isDropdown
          >
            <AppPageHeaderActionsButtonDropItem
              text="Faturamento"
              icon="/img/icons/check.png"
              @click.prevent="handleShowModalReport"
            />
            <AppPageHeaderActionsButtonDropItem
              text="ENTREGAS"
              icon="/img/icons/icons8/ios/agreement_primary.png"
              @click.prevent="handleShowModalScheduleReport()"
            />
            <AppPageHeaderActionsButtonDropItem
              text="ENTREGAS(Beta)"
              icon="/img/icons/icons8/ios/agreement_primary.png"
              @click.prevent="
                handleShowModalScheduleReport((is_new_layout = 1))
              "
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItems"
      isShowViewTypes
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
      isHideTableOnMobileActive
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>

    <div
      class="container-fluid mt-4"
      v-if="$_contract_proposal_listed.items.length > 0"
    >
      <ContractProposalListPageCards
        v-if="listType === 'cards'"
        :digitalSignatureConfig="digitalSignatureConfig"
        @openBusinessPartnerModal="openBusinessPartnerModal"
        @openGuarantorEntityModal="openGuarantorEntityModal"
        @fetch="listItems(false)"
        @contractProposalUpdated="
          updateContractAfterGuarantorEntityRegistrationOrUpdate
        "
        @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      />
      <ContractProposalListPageTable
        v-else
        :digitalSignatureConfig="digitalSignatureConfig"
        @fetch="listItems"
        @openBusinessPartnerModal="openBusinessPartnerModal"
        @openGuarantorEntityModal="openGuarantorEntityModal"
        @contractProposalUpdated="
          updateContractAfterGuarantorEntityRegistrationOrUpdate
        "
        @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      />
      <AppViewTrigger
        v-if="!$_contract_proposal_is_listing"
        @onIntersected="listItems(true)"
      />
    </div>
    <div class="container-fluid mt-4">
      <div
        class="row card-wrapper"
        v-if="$_contract_proposal_is_listing && listType === 'cards'"
      >
        <SkeletonPuzlGrid v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData
        v-else-if="
          $_contract_proposal_listed.items.length === 0 &&
          !$_contract_proposal_is_listing
        "
      />
    </div>
    <ContractProposalBusinessPartnerModal
      ref="modalBusinessPartner"
      @deletedBusinessPartner="updateContractAfterBusinessPartnerDeletion"
      @contractProposalBusinessPartnerUpdated="
        updateContractAfterBusinessPartnerRegistration
      "
    />
    <ModalSearchCustomerConstruction ref="modalSearchCustomerConstruction" />
    <ModalDigitalSignatureConfigurations
      ref="modalDigitalSignatureConfigurations"
      @fetch="getDigitalSignatureConfigurations"
    />
    <ModalProposalTerms ref="modalProposalTerms" />
    <ModalReport ref="modalReport" />
    <ModalScheduleReport ref="modalScheduleReport" />
    <ModalCreateTemporaryCustomerConstruction
      ref="modalCreateTemporaryCustomerConstruction"
    />
    <ModalSearchEntity
      @selected="handleSelectedEntity"
      ref="modalSearchEntity"
    />
    <ModalGuarantorEntity
      ref="modalGuarantorEntity"
      @deletedGuarantorEntity="updateContractAfterGuarantorEntityDeletion"
      @contractProposalUpdated="
        updateContractAfterGuarantorEntityRegistrationOrUpdate
      "
    />
  </div>
</template>

<script setup>
//#region Imports
import {
  computed,
  reactive,
  ref,
  onMounted,
  onUnmounted,
  getCurrentInstance,
} from "vue";

import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppTabSelect,
  AppSelect,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  SearchBarFilterType,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  TabSelectItemType,
  AppPageHeaderActionsButtonDropItem,
  AppViewTrigger,
  BOTTOM_FILTER_ENUM,
} from "../../../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelectSearch from "@/components/PuzlSelectSearch.vue";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ContractProposalListPageCards from "./ContractProposalListPageCards.vue";
import ContractProposalListPageTable from "./ContractProposalListPageTable.vue";

import {
  ContractProposalListFilterType,
  initContractProposalListFilterType,
} from "../../../types";
import { ContractProposalEnum } from "../../../Enum/ContractProposalEnum";
import ContractProposalListRangeTypeEnum from "../../../Enum/ContractProposalListRangeTypeEnum";
import contractProposalStore from "../../../store/contractProposalStore";
import { useStore } from "@/shared/hooks/useStore.js";
import { date, dialogs, getTitles, progress } from "../../../../../../helpers";
import { hasPermission } from "@/plugins/permissions";
import { debounce } from "@/plugins";
import { getPuzlUser } from "@/plugins/storage";
import { verifyDocument } from "@/helpers";

import ContractProposalBusinessPartnerModal from "../../shared/ContractProposalBusinessPartnerModal.vue";
import ModalDigitalSignatureConfigurations from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalDigitalSignatureConfigurations.vue";
import ModalSearchCustomerConstruction from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalSearchCustomerConstruction.vue";
import ModalReport from "@/views/Modules/Financial/Billing/Shared/_ModalReport";
import ModalProposalTerms from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalProposalTerms.vue";
import ModalScheduleReport from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalScheduleReport.vue";
import ModalCreateTemporaryCustomerConstruction from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalCreateTemporaryCustomerConstruction";
import ModalBusinessPartner from "../../../../../../views/Modules/Commercial/ContractProposal/Shared/_ModalBusinessPartner.vue";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import ModalGuarantorEntity from "@/views/Modules/Commercial/ContractProposal/Shared/_ModalGuarantorEntity";

//#endregion

//#region ComponentRefs
const props = defineProps({
  type: {
    type: String,
    default: "contract",
  },
});

//#endregion

//#region Data
const { proxy } = getCurrentInstance();
const store = useStore();

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {ContractProposalListFilterType} - Filtro principal */
const filter = reactive(initContractProposalListFilterType());

/**
 * TODO:
 * Apos refatorar o store do resto da pagina tem que retirar loading daqui e usar o da store
 */
const loadingStatuses = reactive({
  loadingSellers: false,
  loadingSituations: false,
  loadingStates: false,
});

const currentDate = date.make().format(date.FORMAT.ISO_8601);

/**
 * @type {import('vue').Ref<'cards' | 'table'>}
 */
const listType = ref("cards");

const contractId = ref(null);
const user = reactive(getPuzlUser());
const orderBy = reactive({
  items: [
    {
      id: 0,
      name: "A-Z(Cliente)",
      selected_name: "A-Z(Cliente)",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [
        {
          column: "customer_constructions.customer_name",
          is_desc: false,
        },
      ],
    },
    {
      id: 1,
      name: "Z-A(Cliente)",
      selected_name: "Z-A(Cliente)",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [
        {
          column: "customer_constructions.customer_name",
          is_desc: true,
        },
      ],
    },
    {
      id: 2,
      name: "REGISTRO MAIS NOVO PARA O MAIS VELHO ",
      selected_name: "REG. MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [
        {
          column: "contract_proposals.created_at",
          is_desc: true,
        },
      ],
    },
    {
      id: 3,
      name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "REG. MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [
        {
          column: "contract_proposals.created_at",
          is_desc: false,
        },
      ],
    },
  ],
  selected: 2,
});

/**
 * TODO:
 * Depois que refatorar o store tem que criar esse type, por enquanto vou deixar explicito aqui mesmo
 */
const digitalSignatureConfig = reactive({
  contract: null,
  created_at: "",
  default_signatory_company_plant: null,
  enable_digital_signature: null,
  id: null,
  min_contracted_signatures: null,
  min_contracted_signatures_proposal: null,
  min_contractor_signatures: null,
  min_contractor_signatures_proposal: null,
  min_guarantor_signatures: null,
  min_witness_signatures: null,
  monthly_signature_limit: null,
  proposal: null,
  updated_at: "",
});
//#endregion

//#region Computeds
/** Store Getters */
const $_contract_proposal_listed = computed(() =>
  contractProposalStore.getters.getListed()
);
const $_contract_proposal_is_listing = computed(() =>
  contractProposalStore.getters.getIsListing()
);
const $_commercial_permission = computed(
  () => store.getters["commercialPermission/show"]
);
const $_users = computed(() => store.getters["user/fetch"]);
const $_states = computed(() => store.getters["localizations/states"]);
const $_cities = computed(() => store.getters["localizations/cities"]);
const $_situations = computed(
  () => store.getters["contractSituation/activeItems"]
);

/**
 * Loading global, pra não ter que criar varias variaveis
 */
const isLoading = computed(() => {
  return Object.values(loadingStatuses).some((status) => status);
});

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: "Todos",
      selected: filter.status === null && !filter.has_filter_pendencies,
    },
    {
      id: "has_filter_pendencies",
      name: "Pendentes",
      selected:
        filter.has_filter_pendencies === true ||
        filter.status === ContractProposalEnum.PENDING_APPROVAL ||
        filter.status === ContractProposalEnum.PENDING_REVIEW,
    },
    {
      id: ContractProposalEnum.ACTIVE,
      name: "Ativos",
      selected: filter.status === ContractProposalEnum.ACTIVE,
    },
    {
      id: ContractProposalEnum.INACTIVE,
      name: "Inativo",
      selected: filter.status === ContractProposalEnum.INACTIVE,
    },
    {
      id: ContractProposalEnum.COMPLETED,
      name: "Concluídos",
      selected: filter.status === ContractProposalEnum.COMPLETED,
    },
  ];
});

//#endregion

//#region Methods

/**
 * Atualiza os dados do contrato após cadastrar o parceiro comercial, para aparecer ícone no card.
 * @param {number} contractProposalId
 */
function updateContractAfterBusinessPartnerRegistration(contractProposal) {
  const foundContract = $_contract_proposal_listed.value.items.find(
    (item) => item.id == contractProposal.id
  );

  if (foundContract) {
    foundContract.business_partner_id = contractProposal.business_partner_id;
    foundContract.businessPartner = contractProposal.BusinessPartner;
  }
}

/**
    /**
     * @param {number} id
     */
function openGuarantorEntityModal(id) {
  if (!$_commercial_permission.value.is_edit_intermediary_seller_field) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Usuário sem permissão para alterar o fiador!"
    );
    return;
  }

  contractId.value = id;
  store
    .dispatch("contractProposal/getGuarantorEntityContractProposalById", id)
    .then((response) => {
      if (response.data.guarantor_entity_id) {
        proxy.$refs.modalGuarantorEntity.openModal(response.data);
      } else {
        proxy.$refs.modalSearchEntity.openModal(null, true, "guarantor_entity");
      }
    });
}

async function openBusinessPartnerModal(id) {
  if (!$_commercial_permission.value.is_edit_intermediary_seller_field) {
    dialogs.notify(
      dialogs.TYPE_ENUM.WARNING,
      "Usuário sem permissão para alterar o parceiro comercial!"
    );
    return;
  }
  contractId.value = id;
  const response = await store.dispatch(
    "businessPartner/getBusinessPartnerContractProposalById",
    id
  );

  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }

  if (response.data.business_partner_id) {
    proxy.$refs.modalBusinessPartner.openModal(response.data);
  } else {
    proxy.$refs.modalSearchEntity.openModal(null, true, "business_partner");
  }
}
/**
 * Atualiza os dados do contrato após deletar o parceiro comercial.
 * @param {number} contractProposalId
 */
function updateContractAfterBusinessPartnerDeletion(contractProposalId) {
  const foundContract = $_contract_proposal_listed.value.items.find(
    (item) => item.id == contractProposalId
  );
  if (foundContract) {
    foundContract.business_partner_id = null;
    foundContract.businessPartner = null;
  }
}

/**
 * Método de encapsulamento que recebe evento do emit do componente ModalSearchEntity.
 * @param {object} entity
 * @param {undefined} parentPayload
 * @param {string} type
 */
function handleSelectedEntity(entity, parentPayload, type) {
  if (type === "guarantor_entity") {
    storeGuarantorEntity(entity);
  } else if (type === "business_partner") {
    storeBusinessPartner(entity);
  }
}

async function storeGuarantorEntity(entity) {
  progress.start();

  const response = await store.dispatch(
    "contractProposal/storeGuarantorEntity",
    {
      id: contractId.value,
      guarantor_entity_id: entity.id,
      guarantor_entity_created_by_user_id: user.id,
    }
  );

  if (!response || response.error) {
    dialogs.notifyError(dialogs.TYPE_ENUM.DANGER, response.message);
    return;
  }

  updateContractAfterGuarantorEntityRegistrationOrUpdate(response.data);

  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, "Fiador cadastrado com sucesso!");
  progress.finish();
}

/**
 * @param {object} entity
 */
function storeBusinessPartner(entity) {
  progress.start();

  store
    .dispatch("businessPartner/storeBusinessPartner", {
      id: contractId.value,
      business_partner_id: entity.id,
      business_partner_created_by_user_id: user.id,
    })
    .then((response) => {
      updateContractAfterBusinessPartnerRegistration(response.data);
      dialogs.notify(
        dialogs.TYPE_ENUM.SUCCESS,
        "Parceiro comercial cadastrado com sucesso!"
      );
    })
    .catch((error) => {
      dialogs.notifyError({
        type: dialogs.TYPE_ENUM.DANGER,
        message: error.message,
      });
    })
    .finally(() => {
      progress.finish();
    });
}

/**
 * Metodos copiados da pagina antiga de contratos
 * src/views/Modules/Commercial/ContractProposal/Index.vue
 * start
 */
function openModalSearchEntityAndConstruction() {
  proxy.$refs.modalSearchCustomerConstruction.openModal();
}

function handleShowModalDigitalSignature() {
  proxy.$refs.modalDigitalSignatureConfigurations.handleCreateModal();
}

function handleShowModalCreateTemporaryCustomerConstruction() {
  proxy.$refs.modalCreateTemporaryCustomerConstruction.openModal();
}

/**
 * TODO:
 * Depois que refatorar o store tem que usar o dispatch novo, por enquanto vou deixar chamando o velho
 */
async function getDigitalSignatureConfigurations() {
  const response = await store.dispatch("digitalSignatureConfiguration/show");
  if (response?.data) {
    Object.assign(digitalSignatureConfig, response.data);
  }
}

function handleShowModalProposalTerms() {
  proxy.$refs.modalProposalTerms.handleCreateModal();
}

/**
 * Codigo teve que ficar assim pois nao sei pq no antigo abre dois modals ao mesmo tempo
 */
async function handleShowModalReport() {
  await proxy.$refs.modalReport.openModal();
  await proxy.$refs.modalReport.$refs.modalSearchContract.openModal(false);
}

async function handleFilterApprovationStatus() {
  filter.approvation_status = +!filter.approvation_status;
}

async function handleFilterPendingPayment() {
  filter.pending_payment = +!filter.pending_payment;
}

function filterByStatus(status) {
  if (filter.status === status) {
    filter.status = null;
  } else {
    filter.has_filter_pendencies = null;
    filter.status = status;
  }
}

function getFilterPendencies() {
  filter.status = null;
  filter.has_filter_pendencies = !filter.has_filter_pendencies;
}

async function handleFilterHasPendingLaunch() {
  filter.has_pending_launch = +!filter.has_pending_launch;
}

/**
 * Atualiza os dados do contrato após cadastrar o fiador, para aparecer ícone no card.
 * @param {number} contractProposalId
 */
function updateContractAfterGuarantorEntityRegistrationOrUpdate(
  contractProposal
) {
  const foundContract = $_contract_proposal_listed.value.items.find(
    (item) => item.id == contractProposal.id
  );
  if (foundContract) {
    foundContract.guarantor_entity_id = contractProposal.guarantor_entity_id;
    foundContract.guarantorEntity = contractProposal.guarantorEntity;
  }
}

function updateContractAfterGuarantorEntityDeletion(contractProposalId) {
  const foundContract = $_contract_proposal_listed.value.items.find(
    (item) => item.id == contractProposalId
  );
  if (foundContract) {
    foundContract.guarantor_entity_id = null;
    foundContract.guarantorEntity = null;
  }
}

/**
 * Determina se vai usar o layout antigo ou novo do pdf.
 * @param {0|1} is_new_layout
 */
function handleShowModalScheduleReport(is_new_layout = 0) {
  proxy.$refs.modalScheduleReport.openModal(is_new_layout);
}

/**
 * Inicia o loading e chama a função responsável por trazer as options de seller_uuid.
 * @param {string} label - Pesquisa do usuário
 */
function handleFilterSeller(label) {
  if(label) {
    loadingStatuses.loadingSellers = true;
    debouncedFilterSeller(label);
  }
}

function getSellers(search = '') {
  const isSeller = 1;
  store.dispatch('user/getSearch', { search: search, is_seller: isSeller }).then((response) => {
    loadingStatuses.loadingSellers = false;
  });
};

/**
 * Busca os dados com base no texto digitado pelo usuário.
 * @param {string} label
 */
const debouncedFilterSeller = debounce((label) => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

  if (typeof label === 'string' && uuidRegex.test(label)) {
    const user = $_users.value.find((item) => item.seller_uuid == label);
    if (user) {
      label = user.label;
    }
  }
  
  const isSeller = 1;
  store.dispatch("user/getSearch", { search: label, is_seller: isSeller }).finally(() => {
    loadingStatuses.loadingSellers = false;
  });
}, 300);

/**
 * TODO:
 * Depois que refatorar o store tem que usar o dispatch novo, por enquanto vou deixar chamando o velho
 */
async function getSituations() {
  loadingStatuses.loadingSituations = true;
  await store.dispatch("contractSituation/getItems");
  loadingStatuses.loadingSituations = false;
}

async function getStates() {
  loadingStatuses.loadingStates = true;
  await store.dispatch("localizations/fetchStates");
  loadingStatuses.loadingStates = false;
}

async function getCities(state) {
  await store.dispatch("localizations/fetchCities", { uf: state });
}

async function getSettings() {
  await store.dispatch("generalSetting/show");
}

function fetchFilterOptions() {
  getSellers();
  getSituations();
  getStates();
  getSettings();
}

/**
 * Metodos copiados da pagina antiga de contratos
 * end
 */

/**
 * Selecionar tipo de listagem
 *
 * @param {'card' | 'table'} type - Atualiza a lista após limpar os filtros.
 */
function handleViewTypeChange(type) {
  listType.value = type;
}

/**
 * tive que tratar o status e o has_filter_pendencies ao mesmo tempo
 * pois o has_filter_pendencies e quando status === PENDING_APPROVALS && status === PENDING_REVIEW
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (item.id === "has_filter_pendencies") {
    if (
      filter.status === ContractProposalEnum.PENDING_APPROVAL ||
      filter.status === ContractProposalEnum.PENDING_REVIEW
    ) {
      filter.has_filter_pendencies = null;
      filter.status = null;
    } else {
      getFilterPendencies();
    }
  } else {
    filter.has_filter_pendencies = null;
    filter.status = item.id;
  }
  listItems();
}

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters
    ? contractProposalStore.getters.getPageState()
    : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  const defaultSearchBarFilter = {
    ...initSearchBarFilterType(),
    range: {
      items: [
        {
          id: 0,
          name: "Últimas Atualizações",
          selected_name: "Atual",
        },
        {
          id: 1,
          name: "Data Criado",
          selected_name: "Cria",
        },
      ],
      selected: 0,
      start:
        filter.range.start ??
        date
          .make()
          .subtract(2, date.DURATION_INPUT.MONTHS)
          .setStartOf(date.UNIT_OF_TIME.MONTH)
          .format(date.FORMAT.ISO_8601),
      end: filter.range.end ?? currentDate,
    },
  };
  return defaultSearchBarFilter;
}

/**
 * Padrão do filtro principal
 * @returns {ContractProposalListFilterType}
 */
function defaultFilter() {
  const defaultFilter = {
    ...initContractProposalListFilterType(),
    range: {
      start:
        filter.range.start ??
        date
          .make()
          .subtract(2, date.DURATION_INPUT.MONTHS)
          .setStartOf(date.UNIT_OF_TIME.MONTH)
          .format(date.FORMAT.ISO_8601),
      end: filter.range.end ?? currentDate,
    },
  };
  return defaultFilter;
}

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  contractProposalStore.actions.list(filter, isAccumulateItems);
}

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.type = props.type;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
  filter.company_plant_id = searchBarFilter.company_plant_selected;
  //tratando caso do botao inicio que a data de inicio sera null.
  filter.range.start =
    searchBarFilter.range.default_filterable === false
      ? null
      : searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
  const rangeTypeValue = getTitles(ContractProposalListRangeTypeEnum)[
    searchBarFilter.range.selected
  ].name;
  filter.range_type = rangeTypeValue;
}
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
  getDigitalSignatureConfigurations();
  fetchFilterOptions();

});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  contractProposalStore.mutations.resetStates();
  contractProposalStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>

<style lang="scss">
.contract-proposal-list-page {
  .marcadores div + div {
    gap: 8px;
    button {
      color: #606062 !important;
      box-shadow: none !important;
      justify-content: start !important;
      padding: 6px 8px;
      &:hover {
        color: white !important;
      }
    }

    button.btn-danger,
    button.btn-warning {
      color: white !important;
    }
  }

  .header {
    @media (min-width: 1200px) {
      .new-puzl-default {
        min-width: 150px !important;
        max-width: 150px !important;
        display: flex;

        button {
          width: 100% !important;
          font-weight: 400 !important;
        }
      }
    }
  }

  .invert-color img {
    filter: brightness(100);
  }
}
</style>

<style scoped lang="scss">
.app-input {
  width: 100%;
  height: 32px;
  box-shadow: none !important;
  display: block;
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  border: 0.5px solid #e8e8e8;
}
</style>

<style lang="scss">
@import '@/style/PuzlCustom/App.scss';
.contract-proposal-list-page {
  .ef-section-item-column .col-md-12 {
    padding: 0;
  }
  .btn-icon.btn-fab {
    justify-content: start !important;
    box-shadow: none !important;
    min-height: 32px;
    font-family: Fredoka;
    font-weight: 400;
    font-size: 10px;
    color: $muted !important;

    &:hover{
      color: white !important;
    }
    img {
      height: 16px;
      width: 16px;
    }
  }
}
</style>
