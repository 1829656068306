<template>
  <div>
    <modal size="md"
      :show.sync="modal.create"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/brand/logo.png" alt="logo" width="20px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/info-squared.png" width="20">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      Informação
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
              </div>
              <div class="form-group">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        NOME
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input
                            v-model="operation_source.name"
                            type="text"
                            class="form-control form-control-sm"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        TIPO
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="operation_source.type"
                            :items="operation_source_types"
                            customKey="value"
                            label="text"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <!-- Regime tributário -->
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label font-weight-400" style="font-size: 14px;">
                      Regime Tributário
                      <span class="text-danger">&nbsp;*</span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          @input="changedTaxRegimes($event, operation_source)"
                          :multiple="true"
                          v-model="operation_source.tax_regimes"
                          label="label"
                          :items="$_tax_regimes"
                          customKey="value"
                          :disableBoxShadow="true"
                      />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        CATEGORIA
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect
                            v-model="operation_source.product_service_hub_type_id"
                            :items="[{ id: 2, name: 'MCC' }, ...product_service_hub_types]"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        GERAR DOCUMENTO
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect :disabled="operation_source.type === 0" :clearable="false" label="label"
                            v-model="operation_source.document_template_id" :items="formattedDocumentTemplates()"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                        PLANO DE CONTAS GERENCIAL
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                          <PuzlSelect :multiple="true" :clearable="false" label="$code$. $name$" :labelMask="true"
                            v-model="operation_source.chart_accounts" :items="chartAccounts"
                            :disableBoxShadow="true"
                          />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/administrative-tools_primary.png" width="20">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                      Configuração
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
                <div class="col-12 mt-n1">
                  <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Financeiro e estoque</span>
                </div>
              </div>
              <div class="form-group mt-3">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        GERA FINANCEIRO
                    </div>
                  </div>
                  <div v-if="operation_source.is_generate_financial === true" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-success" @click="operation_source.is_generate_financial = false;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-if="operation_source.is_generate_financial === false" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-danger" @click="operation_source.is_generate_financial = true;">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div v-if="[ProductServiceHubTypeEnum.keys.CMC, ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE].includes(operation_source.product_service_hub_type_id)" class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        MOVIMENTA ESTOQUE
                    </div>
                  </div>
                  <div v-if="operation_source.is_generate_stock === true" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-success" @click="operation_source.is_generate_stock = false;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-if="operation_source.is_generate_stock === false" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-danger" @click="operation_source.is_generate_stock = true;">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                        NOTA MÃE
                    </div>
                  </div>
                  <div v-if="operation_source.is_entry_value === true" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-success" @click="operation_source.is_entry_value = false;">
                      <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                      Ativo
                    </span>
                  </div>
                  <div v-if="operation_source.is_entry_value === false" class="col-md-7 mb-2 px-0">
                    <span class="d-flex float-right align-items-center status-button-danger" @click="operation_source.is_entry_value = true;">
                      <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                      Inativo
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-3">
                    <span style="font-size: 14px; font-weight: 400; color: #2B2D32;">Contábil e Fiscal</span>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <div class="form-group row m-0 p-0">
                    <div class="col-md-5 my-1 px-0">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                          CONTABILIZA
                      </div>
                    </div>
                    <div v-if="operation_source.is_computable === true" class="col-md-7 mb-2 px-0">
                      <span class="d-flex float-right align-items-center status-button-success" @click="operation_source.is_computable = false;">
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </span>
                    </div>
                    <div v-if="operation_source.is_computable === false" class="col-md-7 mb-2 px-0">
                      <span class="d-flex float-right align-items-center status-button-danger" @click="operation_source.is_computable = true;">
                        <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                        Inativo
                      </span>
                    </div>
                  </div>
                  <div class="form-group row m-0 p-0">
                    <div class="col-md-5 my-1 px-0">
                      <div class="pb-0 mb-1 form-control-label" style="font-size: 12px; font-weight: 400; color:#606062">
                          GERA LIVRO FISCAL
                      </div>
                    </div>
                    <div v-if="operation_source.is_generate_tax_book === true" class="col-md-7 mb-2 px-0">
                      <span class="d-flex float-right align-items-center status-button-success" @click="operation_source.is_generate_tax_book = false;">
                        <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                        Ativo
                      </span>
                    </div>
                    <div v-if="operation_source.is_generate_tax_book === false" class="col-md-7 mb-2 px-0">
                      <span class="d-flex float-right align-items-center status-button-danger" @click="operation_source.is_generate_tax_book = true;">
                        <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                        Inativo
                      </span>
                    </div>
                  </div>
                  <div class="modal-footer px-0">
                    <div class="d-flex pr-2" style="gap: 18px;">
                      <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="closeModal">
                        <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                        <span style="font-weight: 500;">
                          Cancelar
                        </span>
                      </div>
                      <div class="d-flex align-items-center button-save" style="gap: 5px;" @click.prevent="store">
                        <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                        <span style="font-weight: 500;">
                          Salvar
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
const { formatErrorValidation } = require("@/plugins");
import operationSourceMixin from "../Mixins/operation_source_mixin";
import {OperationTypesEnum} from "@/enum/OperationTypesEnum";
import ProductServiceHubTypeEnum from "../../../../../modules/configuration/product-service-hub-type/enums/ProductServiceHubTypeEnum";

export default {
  name: "ModalCreate",
  mixins: [operationSourceMixin],
  components: {
    PuzlSelect,
  },
  data() {
    return {
      modal: {
        title: "Tipo de Operação Puzl",
        create: false,
      },
      operation_source: {
        status: true,
        type: 0,
        name: null,
        is_generate_stock: true,
        is_generate_financial: true,
        is_entry_value: false,
        tax_regimes: [],
        product_service_hub_type_id: null,
        document_template_id: null,
        is_computable: true,
        is_generate_tax_book: true,
        chart_accounts: null,
      },
      chartAccounts: [],
      loadingChartAccounts: false,
      loadingStore: false,
      OperationTypesEnum: OperationTypesEnum,
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    handleCreateModal() {
      this.operation_source = {
        status: true,
        type: 0,
        name: null,
        is_generate_stock: false,
        is_generate_financial: true,
        is_entry_value: false,
        tax_regimes: [],
        product_service_hub_type_id: null,
        document_template_id: null,
        is_computable: true,
        is_generate_tax_book: true,
        chart_accounts: null,
      };
      this.modal.create = true;
      this.loadChartAccounts();
    },
    store() {
      let loader = this.$loading.show();
      this.loadingStore = true;
      let payload = { ...this.operation_source };
      payload.tax_regimes = JSON.stringify(payload.tax_regimes);
      this.$store
        .dispatch("operationSource/add", payload)
        .then((response) => {
          this.loadingStore = false;
          loader.hide();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.closeModal();
          this.$emit('created');
        })
        .catch((error) => {
          if (error.status === 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          loader.hide();
          this.loadingStore = false;
        });
    },
    changedType(type) {
      this.operation_source.type = type.value;
      this.operation_source.document_template_id = null;
    },
    loadChartAccounts() {
      this.chartAccounts = [];
      this.loadingChartAccounts = true;
      this.$store.dispatch("chartAccount/getFirstLevel").then(response => {
        this.chartAccounts = response.data;
        this.loadingChartAccounts = false;
      });
    }
  },
};
</script>
<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
</style>
