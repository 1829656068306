<template>
  <div class="container-fluid">
    <!-- Filtros -->
    <section>
      <multi-filter :type="5" :defaultRange="filter.range" @clearFilter="clearFilter()" @handleFilterDate="handleFilterDate" @fetch="init">
        <template slot="dates">
          <div class="col-md-12 text-left">
            <label class="form-control-label"> Data de venda </label>
            <input-date-picker :defaultRange="filter.range" @handleFilterDate="handleFilterDate" />
          </div>
        </template>
        <template slot="dropdown-instead-input">
          <base-input class="d-block col-12 p-0" input-classes="form-control form-control-sm">
            <puzl-select 
              @input="init({})"
              class="w-100 select-xl"
              :disabled="loadingSkeleton || !$_payment_intermediaries.length"
              placeholder="Mediador"
              :items="$_payment_intermediaries"
              v-model="filter.payment_intermediary_id"
            />
          </base-input>
        </template>
        <template slot="data">
          <div class="row pr-3 mt-1 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label"> Parcelas </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <input
                    @change="setRange()"
                    v-model="filter.range_installment_number.min"
                    placeholder="de"
                    class="form-control form-control-sm btn-first-child"
                  />
                </div>
                <div class="col-6 pl-1">
                  <input
                    @change="setRange()"
                    v-model="filter.range_installment_number.max"
                    placeholder="até"
                    class="form-control form-control-sm"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row pr-3 mt-2 pl-3">
            <div class="col-12 text-left">
              <label class="form-control-label"> Valor da venda </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <base-input
                    @change="setRange()"
                    v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                    inputmode="numeric"
                    v-model="filter.range_value.min"
                    input-group-classes="input-group-sm"
                    placeholder="de"
                    size="sm"
                  >
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
                <div class="col-6 pl-1">
                  <base-input
                    @change="setRange()"
                    v-mask="['#,##', '##,##', '###,##', '#,###.##', '##,###.##', '###,###.##']"
                    inputmode="numeric"
                    v-model="filter.range_value.max"
                    input-group-classes="input-group-sm"
                    placeholder="até"
                    size="sm"
                  >
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0"> R$ </small>
                    </template>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template slot="status">
          <div class="col-12">
            <base-button-hoverable
              @click="
                filter.status = 1;
                init({});
              "
              size="sm"
              :active="filter.status === 1"
              type="primary"
              icon="ok"
              platform="ios"
            >
              Integrado
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              @click="
                filter.status = 0;
                init({});
              "
              size="sm"
              :active="filter.status === 0"
              type="success"
              icon="no-registry"
              platform="ios"
            >
              Sem registro
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              @click="
                filter.status = 2;
                init({});
              "
              size="sm"
              :active="filter.status === 2"
              type="danger"
              icon="not-equal-2"
              platform="ios"
            >
              Divergente
            </base-button-hoverable>
          </div>
        </template>
        <template slot="bookmarks">
          <div class="col-12">
            <base-button-hoverable
              size="sm"
              :active="filter.modality_name === 'Crédito'"
              @click="
                filter.modality_name = 'Crédito';
                init({});
              "
              type="success"
              icon="mastercard-credit-card"
              platform="ios"
            >
              Crédito
            </base-button-hoverable>
          </div>
          <div class="col-12 mt-1">
            <base-button-hoverable
              size="sm"
              :active="filter.modality_name === 'Débito'"
              @click="
                filter.modality_name = 'Débito';
                init({});
              "
              type="primary"
              icon="money--v1"
              platform="ios"
            >
              Débito
            </base-button-hoverable>
          </div>
        </template>
        <template slot="order-by">
          <el-popover trigger="click" placement="bottom" class="p-0 float-right pr-0 ml-3">
            <h6>
              <a @click.prevent="handleOrderBy(1)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 1 && 'text-primary'"
                  class="fa-solid fa-angles-down"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Data de venda : da mais nova para mais velha
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a @click.prevent="handleOrderBy(2)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 2 && 'text-primary'"
                  class="fa-solid fa-angles-up"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Data de venda : da mais velha para mais nova
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a @click.prevent="handleOrderBy(3)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 3 && 'text-primary'"
                  class="fa-solid fa-angles-down"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Valor da venda : do menor para maior
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a @click.prevent="handleOrderBy(4)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 4 && 'text-primary'"
                  class="fa-solid fa-angles-up"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Valor da venda : do maior para menor
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a @click.prevent="handleOrderBy(5)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 5 && 'text-primary'"
                  class="fa-solid fa-angles-down"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Código : do menor para o maior
              </a>
            </h6>
            <hr class="mb-1 mt-n2" />
            <h6>
              <a @click.prevent="handleOrderBy(6)" href="#" class="font-weight-400" style="font-size: 12px">
                <i
                  :class="filter.order_by === 6 && 'text-primary'"
                  class="fa-solid fa-angles-up"
                  style="font-size: 12px; margin-right: 5px"
                ></i>
                Código : do maior para o menor
              </a>
            </h6>
            <a href="#" slot="reference">
              <h5 slot="reference" class="pt-1">ORDENAR <i class="fa-solid fa-chevron-down"></i></h5>
            </a>
          </el-popover>
        </template>
      </multi-filter>
    </section>

    <!-- Tipos -->
    <section>
      <ul role="tablist" class="nav nav-tabs nav-justified">
        <li role="presentation" class="nav-item">
          <a
            @click.prevent="handleChangeLiquidationType(0)"
            role="tab"
            aria-selected="true"
            aria-setsize="4"
            aria-posinset="1"
            href="#"
            target="_self"
            class="nav-link text-primary border-primary"
            style="padding: 8px 0"
            :style="filter.liquidation_type === 0 ? 'color: white !important;' : 'background-color: white;'"
            :class="filter.liquidation_type === 0 && 'active bg-primary'"
          >
            <b class="font-weight-500"> VENDAS </b>
          </a>
        </li>
        <li role="presentation" class="nav-item">
          <a
            @click.prevent="handleChangeLiquidationType(1)"
            role="tab"
            tabindex="-1"
            aria-selected="false"
            aria-setsize="4"
            aria-posinset="2"
            href="#"
            target="_self"
            class="nav-link text-primary border-primary"
            style="padding: 8px 0"
            :style="filter.liquidation_type === 1 ? 'color: white !important;' : 'background-color: white;'"
            :class="filter.liquidation_type === 1 && 'active bg-primary'"
          >
            <b class="font-weight-500"> LIQUIDAÇÕES </b>
          </a>
        </li>
      </ul>
    </section>

    <!-- Tabela de vendas -->
    <section>
      <div style="border-radius: 0 !important" class="card">
        <div class="card-body">
          <div class="col-md-12 p-0 table-responsive">
            <table class="table table-sm table-bordered">
              <thead>
                <tr class="fixed">
                  <th class="text-center">Status</th>
                  <th class="text-center">Código</th>
                  <th class="text-center">Data da venda</th>
                  <th class="text-center">Valor da venda</th>
                  <th class="text-center">Tipo</th>
                  <th class="text-center">Parcelas</th>
                  <th class="text-center">Bandeira</th>
                  <th class="text-center">Cliente</th>
                  <th class="text-center">Mediador</th>
                  <th class="text-center">Ponto de venda</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="loadingSkeleton">
                  <tr v-for="(item, skeletonIndex) in 10" :key="skeletonIndex">
                    <th colspan="10">
                      <skeleton-puzl type="button" />
                    </th>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(item, index) in $_items" :key="index">
                    <th class="text-center">
                      <base-button 
                        block 
                        size="sm" 
                        outline 
                        :type="status[item.status].color" 
                        class="active text-uppercase mr-0"
                        @click="status[item.status].label === 'Divergente' && handleRedeRevalidateModal(item.entity_bill_receive_id, item.payment_intermediary_id)"
                      >
                        {{ status[item.status].label === "Integrado" && item.liquidation_type !== 0 ? "Liquidado" : status[item.status].label }}
                      </base-button>
                    </th>
                    <th class="text-center">
                      {{ item.nsu_code }}
                    </th>
                    <th class="text-center">
                      {{ item.date | parseDate("DD/MM/YYYY") }}
                    </th>
                    <th class="text-center">
                      {{ item.total_value | currency }}
                    </th>
                    <th class="text-center">
                      {{ item.modality_name }}
                    </th>
                    <th class="text-center">
                      <el-popover trigger="hover" placement="bottom" class="p-0 pr-0">
                        <h4 slot="reference" class="mb-0 text-grey2">{{ item.total_installments }}</h4>
                        <div>
                          <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center">Parcela</th>
                                <th v-if="item.liquidation_type !== 0" class="text-center">Tipo</th>
                                <th class="text-center">Previsão</th>
                                <th v-if="item.liquidation_type !== 0" class="text-center">Processamento</th>
                                <th class="text-center">Total</th>
                                <th class="text-center">Taxa</th>
                                <th class="text-center">Final</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(installment, installment_index) in item.installments" :key="installment_index">
                                <td class="text-center">{{ installment.installment_index }}</td>
                                <td v-if="item.liquidation_type !== 0" class="text-center">
                                  {{ getLiquidationTypeName(installment.liquidation_type) }}
                                </td>
                                <td class="text-center">{{ installment.expected_payment_date | parseDate("DD/MM/YYYY") }}</td>
                                <td v-if="item.liquidation_type !== 0" class="text-center">
                                  {{ installment.paid_at | parseDate("DD/MM/YYYY") }}
                                </td>
                                <td class="text-center">{{ installment.installment_total_value | currency }}</td>
                                <td class="text-center">{{ installment.installment_tax_value | currency }}</td>
                                <td class="text-center">{{ installment.installment_final_value | currency }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </el-popover>
                    </th>
                    <th class="text-center">
                      {{ item.flag_name }}
                    </th>
                    <th class="text-center">
                      <span class="style-table numeric font-weight-bold"> {{ item.entity_name || "-" }} </span>
                    </th>
                    <th class="text-center">
                      {{ item.payment_intermediary_name }}
                    </th>
                    <th class="text-center">
                      {{ item.payment_intermediary_merchant_description}}
                    </th>
                  </tr>
                </template>
              </tbody>
            </table>
            <PuzlEmptyData v-if="!loadingSkeleton && !$_items.length" />
            <div class="col-md-12">
              <pagination @navegate="navegate" :source="source" :loading="loadingSkeleton" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <ModalRedeRevalidate ref="modalRedeRevalidate" />
  </div>
</template>

<script>
import MultiFilter from "@/components/Utils/MultiFilterV3";
import { mapGetters } from "vuex";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import Pagination from "@/components/Utils/Pagination";
import InputDatePicker from "@/components/InputDatePicker";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import { debounce } from "@/plugins";
import PuzlSelect from "@/components/PuzlSelect";
import ModalRedeRevalidate from "../../../../../modules/financial/electronic-bank-statement/views/shared/ModalRedeRevalidate"

export default {
  components: { MultiFilter, PuzlEmptyData, SkeletonPuzl, Pagination, InputDatePicker, BaseButtonHoverable, PuzlSelect, ModalRedeRevalidate },
  name: "List",
  data() {
    return {
      loadingSkeleton: false,
      filter: {
        payment_intermediary_id:null,
        range_installment_number: {
          min: null,
          max: null,
        },
        range_value: {
          min: null,
          max: null,
        },
        status: null,
        order_by: 1,
        modality_name: null,
        liquidation_type: 0,
        range: {
          start: new Date(new Date().setDate(new Date().getDate() - 1)),
          end: new Date(new Date().setDate(new Date().getDate() - 1)),
        },
      },
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      liquidation_type: 0,
      source: null,
      params: {
        page: 1,
      },
      status: {
        0: {
          label: "Sem registro",
          color: "light",
        },
        1: {
          label: "Integrado",
          color: "success",
        },
        2: {
          label: "Divergente",
          color: "danger",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      $_items: "electronicBankStatement/fetch",
      $_payment_intermediaries : "paymentIntermediary/fetch",
    }),
  },
  methods: {
    handleFilterDate(range) {
      this.filter.range = range;
      this.init();
    },

    init(filter = null) {
      this.loadingSkeleton = true;
      this.filter = filter ? { ...this.filter, ...filter } : this.filter;

      this.$store.dispatch("electronicBankStatement/fetch", { filter: this.filter, page: this.params.page }).then((response) => {
        this.$_items.map((item) => {
          item.installments = JSON.parse(item.installments);
        });
        this.source = response;
        this.loadingSkeleton = false;
      });
    },

    handleFilterDefaultDate(filter) {
      this.filter_by_default = 0;
      this.range = filter;
      this.init({});
    },

    /**
     * Dispara filtro de tipo
     *
     * @param {string} liquidation_type
     * @returns {void}
     */
    handleChangeLiquidationType(liquidation_type) {
      this.filter.liquidation_type = liquidation_type;
      this.params.page = 1;
      this.init();
    },

    /**
     * Retorna nome do tipo de liquidação
     *
     * @param {string} liquidation_type
     * @returns {string}
     */
    getLiquidationTypeName(liquidation_type) {
      switch (liquidation_type) {
        case "1":
          return "Liquidação normal";
        case "2":
          return "Liquidação antecipada";
        case "5":
          return "Liquidação amortizada";
      }
    },

    /**
     * Navega entre as páginas
     *
     * @param {integer} page
     * @returns void
     */
    navegate(page) {
      this.params.page = page;
      this.init();
    },

    /**
     * Dispara ordenação
     *
     * @param {integer} value
     * @returns void
     */
    handleOrderBy(value) {
      this.filter.order_by = value;
      this.init({});
    },

    /**
     * Limpa filtros
     *
     * @returns void
     */
    clearFilter() {
      this.filter = {
        payment_intermediary_id:null,
        range_installment_number: {
          min: null,
          max: null,
        },
        range_value: {
          min: null,
          max: null,
        },
        range: this.filter.range,
        status: null,
        order_by: 1,
        modality_name: null,
        liquidation_type: this.filter.liquidation_type,
      };
      this.params.page = 1;
      this.init();
    },

    setRange: debounce(function () {
      this.init();
    }, 100),

    handleRedeRevalidateModal(entityBillReceiveId, paymentIntermediaryId) {
      this.$refs.modalRedeRevalidate.openModal(entityBillReceiveId, paymentIntermediaryId);
    }
  },

  mounted() {
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    this.filter.range =  {
      start: yesterday,
      end: yesterday,
    },
    this.init();
    this.$store.dispatch("paymentIntermediary/fetchItems");
  },
};
</script>
