<template>
  <div>
    <a href="#" @click.prevent="downloadExcel">
      <img class="float-right mt-n2 mr-5" style="width: 25px" src="/img/icons/excel.png" />
    </a>
    <div class="container-fluid">
      <div style="display: flex;align-items: center;">
        <div>
          <i
            @click.prevent="setViewType('cards')"
            :class="{'opacity-25': listType !== 'cards'}"
            class="pointer fa-regular fs-22 fa-hard-drive"
          />
          <i
            @click.prevent="setViewType('table')"
            :class="{'opacity-25': listType !== 'table'}"
            class="pointer fa-solid fs-22 fa-table-list ml-1"
          />
        </div>
      </div>
      <MultiFilter
        @clearFilters="clearFilters"
        :status="multi_filter"
        ref="multi-filter"
        @fetch="init"
        :filter="filter"
      >
        <div class="col-md-3 p-2">
          <InputDatePicker :filterable="4" :default-range="range" ref="date-picker" @handleFilterDate="handleFilterDate"/>
        </div>
        <div class="col-md-3 p-2">
          <PuzlSelect 
            v-model="filter.fuel_tank_ids" 
            :items="$_fuel_tanks"  
            :multiple="true"
            :disabled="loadingFuelTank"
            :loading="loadingFuelTank" 
            @input="init({})"
            label="$name$ | $product_name$"
            placeholder="Bomba"
            :labelMask="true"
            class="select-xl"
          />
        </div>
      </MultiFilter>

      <!-- CARDS ---->
      <div v-if="listType === 'cards'">
        <div class="row card-wrapper" v-show="loadingSkeleton">
          <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
        </div>
        <!-- Dados vazios -->
        <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton"/>
        <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton">
          <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
            <card>
              <!-- Card header -->
              <div class="row align-items-center mb-1">
                <div class="col-9 d-flex align-items-center pr-0">
                  <el-popover v-if="item.statistics" trigger="hover" placement="bottom" class="p-0 float-right pr-0">
                    <h4 class="mb-0" slot="reference">
                      <badge :type="getStatusColor(item.status)">
                        <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                      </badge>
                    </h4>
                    <div>
                        <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center text-primary">LITROS</th>
                          <th class="text-center text-warning">KM</th>
                          <th class="text-center text-indigo">HORAS</th>
                          <th class="text-center text-success">VOLUME(M³)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">{{ item.total_liter ? item.total_liter.replace('.', ',') : 0 }}</td>
                          <td class="text-center">
                            {{ item.travelled_distance ? item.travelled_distance.toString().replace('.', ',') : 0}}
                          </td>
                          <td class="text-center">
                            {{ item.travelled_hour ? item.travelled_hour.replace('.', ',') : 0 }}
                          </td>
                          <td class="text-center">
                            {{ item.volume ? item.volume.replace('.', ',') : 0 }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center font-weight-bold">
                            {{ $helper.toMoney(item.statistics.value_per_liter) }} (/l)
                          </td>
                          <td class="text-center font-weight-bold">
                            {{ $helper.toMoney(item.statistics.value_per_km) }} (/km)
                          </td>
                          <td class="text-center font-weight-bold">
                            {{ $helper.toMoney(item.statistics.value_per_hour) }} (/h)
                          </td>
                          <td class="text-center font-weight-bold">
                            {{ $helper.toMoney(item.statistics.value_per_volume) }} (/m³)
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">{{ item.statistics.liter_per_km }} (l/km)</td>
                          <td class="text-center">
                            {{ item.statistics.km_per_liter }} (km/l)
                          </td>
                          <td class="text-center">
                            {{ item.statistics.hour_per_liter }} (h/l)
                          </td>
                          <td class="text-center">
                            {{ item.statistics.volume_per_liter }} (m³/l)
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">
                            {{ item.statistics.liter_per_volume }} (l/m³)
                          </td>
                          <td class="text-center">
                            {{ item.statistics.km_per_volume }} (km/m³)
                          </td>
                          <td class="text-center">
                            {{ item.statistics.hour_per_volume }} (h/m³)
                          </td>
                          <td class="text-center">
                            {{ item.statistics.volume_per_km }} (m³/km)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </el-popover>
                  <h4 v-else class="mb-0" slot="reference">
                    <badge :type="getStatusColor(item.status)">
                      <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                    </badge>
                  </h4>
                  <el-popover trigger="hover" placement="bottom" class="p-0 float-right pr-0 ml-3">
                    <span> {{ item.creator_user_name }} </span> <br/>
                    <span> {{ $helper.parseDate(item.created_at) }} </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <i style="font-size: 20px !important" class="fas fa-user p-2 text-primary"/>
                    </base-button>
                  </el-popover>
                </div>
                <div class="col-3 text-right pl-0">
                  <base-dropdown menuOnRight>
                    <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <a class="dropdown-item" @click="handleModalEdit(item.id)">
                      <i class="fas fa-edit text-warning main-icon-menu"></i>
                      Editar
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" @click="handleDelete(item.id)">
                      <i class="fas fa-times text-danger main-icon-menu"></i>
                      Excluir
                    </a>
                  </base-dropdown>
                </div>
              </div>
              <div class="mb-2">
                <h4 class="mb-0">{{ item.company_plant_name }}</h4>
                <h4 class="mb-0 font-weight-normal">{{ item.fuel_tank_name }}</h4>
              </div>
              <div v-show="item.entity_id" class="mb-2">
                <h4 class="mb-0">{{ item.entity_name }}</h4>
                <h4 class="mb-0 font-weight-normal">{{ item.entity_document }}</h4>
              </div>
              <div class="mb-2">
                <h4 v-if="item.equipment_id" class="mb-0 font-weight-normal">
                  <b>{{ item.equipment_code }} </b> | {{ item.equipment_plate }}
                </h4>
                <h4 v-else class="mb-0">{{ item.plate }}</h4>
                <h4 v-if="item.driver_id" class="mb-0 font-weight-normal">
                  {{ item.driver_name }}
                </h4>
                <h4 v-else class="mb-0 font-weight-normal">{{ item.driver_observations }}</h4>
              </div>
              <div class="text-center">
                <h4 class="mb-0 col-12 p-0">
                  {{ Number(item.total_liter).toFixed(1).replace('.', ',') }} Litros | {{ $helper.toMoney(item.total_value) }}
                </h4>
                <h4 class="mb-0 col-12 p-0 font-weight-normal">
                  {{ $helper.parseDate(item.date) }}
                </h4>
              </div>
              <div v-if="item.observations" class="border-3 border-danger border-left pl-1 mt-2">
                <h5 class="mb-0 pl-1 font-weight-normal">{{ item.observations }}</h5>
              </div>
            </card>
          </div>
        </div>
      </div>

      <!-- TABELA -->
      <div v-if="listType === 'table'">
        <div class="row">
          <ShadedTable :length="$_items.length" :headers="headers" :loading="loadingSkeleton">
            <template v-for="(item, index) in $_items" :slot="index">
              <ShadedCol>
                <div class="mr-2">
                  <el-popover v-if="item.statistics" trigger="hover" placement="bottom" class="p-0 pr-0">
                    <h4 class="mb-0" slot="reference">
                      <badge :type="getStatusColor(item.status)">
                        <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                      </badge>
                    </h4>
                    <div>
                      <table style="margin-bottom: 0px" class="table table-sm table-bordered">
                        <thead>
                          <tr>
                            <th class="text-center text-primary">LITROS</th>
                            <th class="text-center text-warning">KM</th>
                            <th class="text-center text-indigo">HORAS</th>
                            <th class="text-center text-success">VOLUME(M³)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">{{ item.total_liter ? item.total_liter.replace('.', ',') : 0 }}</td>
                            <td class="text-center">
                              {{ item.travelled_distance ? item.travelled_distance.toString().replace('.', ',') : 0}}
                            </td>
                            <td class="text-center">
                              {{ item.travelled_hour ? item.travelled_hour.replace('.', ',') : 0 }}
                            </td>
                            <td class="text-center">
                              {{ item.volume ? item.volume.replace('.', ',') : 0 }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center font-weight-bold">
                              {{ $helper.toMoney(item.statistics.value_per_liter) }} (/l)
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ $helper.toMoney(item.statistics.value_per_km) }} (/km)
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ $helper.toMoney(item.statistics.value_per_hour) }} (/h)
                            </td>
                            <td class="text-center font-weight-bold">
                              {{ $helper.toMoney(item.statistics.value_per_volume) }} (/m³)
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">{{ item.statistics.liter_per_km }} (l/km)</td>
                            <td class="text-center">
                              {{ item.statistics.km_per_liter }} (km/l)
                            </td>
                            <td class="text-center">
                              {{ item.statistics.hour_per_liter }} (h/l)
                            </td>
                            <td class="text-center">
                              {{ item.statistics.volume_per_liter }} (m³/l)
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">
                              {{ item.statistics.liter_per_volume }} (l/m³)
                            </td>
                            <td class="text-center">
                              {{ item.statistics.km_per_volume }} (km/m³)
                            </td>
                            <td class="text-center">
                              {{ item.statistics.hour_per_volume }} (h/m³)
                            </td>
                            <td class="text-center">
                              {{ item.statistics.volume_per_km }} (m³/km)
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </el-popover>
                  <h4 v-else class="mb-0" slot="reference">
                    <badge :type="getStatusColor(item.status)">
                      <i class="fas fa-gas-pump"/> &nbsp;{{ getStatusText(item.status) }}
                    </badge>
                  </h4>
                </div>
              </ShadedCol>
              <ShadedCol>
                <div class="d-flex align-items-center gap-2">
                  <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left">
                    {{ item.company_plant_name }}
                  </h5>

                  <el-popover trigger="hover" placement="bottom">
                    <span> {{ item.creator_user_name }} </span> <br/>
                    <span> {{ $helper.parseDate(item.created_at) }} </span>
                    <base-button outiline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none">
                      <i class="fas fa-user p-2 text-primary" style="font-size: 20px !important"/>
                    </base-button>
                  </el-popover>
                </div>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left">
                  {{ item.fuel_tank_name }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5
                  class="new-default-black-font font-weight-normal m-0 mt-1 text-left"
                  v-show="item.entity_id"
                  :title="item.entity_document"
                >
                  {{ item.entity_name }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left" v-if="item.driver_id">
                  {{ item.driver_name }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 v-if="item.equipment_id" class="new-default-black-font font-weight-normal m-0 mt-1 text-left">
                  {{ item.equipment_code }} | {{ item.equipment_plate }}
                </h5>
                <h5 v-else class="new-default-black-font font-weight-normal m-0 mt-1 text-left">
                  {{ item.plate }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left text-uppercase">
                  {{ $helper.parseDate(item.date) }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left text-uppercase">
                  {{ Number(item.total_liter).toFixed(1).replace('.', ',') }} Litros
                </h5>
              </ShadedCol>
              <ShadedCol>
                <h5 class="new-default-black-font font-weight-normal m-0 mt-1 text-left">
                  {{ $helper.toMoney(item.total_value) }}
                </h5>
              </ShadedCol>
              <ShadedCol>
                <div class="text-center pl-0">
                  <base-dropdown menuOnRight>
                    <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                      <i class="fas fa-cog"></i>
                    </base-button>
                    <a class="dropdown-item" @click="handleModalEdit(item.id)">
                      <i class="fas fa-edit text-warning main-icon-menu"></i>
                      EDITAR
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a class="dropdown-item" @click="handleDelete(item.id)">
                      <i class="fas fa-times text-danger main-icon-menu"></i>
                      EXCLUIR
                    </a>
                  </base-dropdown>
                </div>
              </ShadedCol>
            </template>
          </ShadedTable>
        </div>
      </div>
    </div>
    <ModalEdit ref="modalEdit"/>
    <LoadingPagination :show="loading && !loadingSkeleton"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalEdit from "./_ModalEdit";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import fuelControlMixin from "../Mixins/fuel_control_mixin";
import InputDatePicker from "@/components/InputDatePicker";
import PuzlSelect from "@/components/PuzlSelect";
import moment from "moment";
import ShadedTable from "../../../../../components/ShadedTable.vue";
import ShadedCol from "../../../../../components/ShadedCol.vue";
import { AppIcon } from "../../../../../components/AppGlobal";

export default {
  name: "List",
  mixins: [cursorPaginate, fuelControlMixin],
  components: {
    SkeletonPuzlGrid,
    PuzlEmptyData,
    ModalEdit,
    MultiFilter,
    LoadingPagination,
    InputDatePicker,
    PuzlSelect,
    ShadedTable,
    ShadedCol,
    AppIcon,
  },
  data() {
    return {
      loadingSkeleton: true,
      filter: {
        status: 3, // todos
      },
      multi_filter: {
        0: {
          name: "INTERNO",
          type: "primary",
        },
        1: {
          name: "TERCEIROS",
          type: "warning",
        },
        2: {
          name: "EXTERNO",
          type: "dark",
        },
        3: {
          name: "TODOS",
          type: "primary",
        },
      },
      range: {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      },
      loadingFuelTank: true,
      listType: 'cards', // cards | table
      headers: [
        "Viagem",
        "Central",
        "Bomba",
        "Fornecedor",
        "Motorista",
        "Equipamento",
        "Data",
        "Abastecimento",
        "Total",
        "Ações",
      ]
    };
  },
  computed: {
    ...mapGetters({
      $_items: "fuelControl/fetch",
      $_fuel_tanks: "fuelTank/fetch",
    }),
  },
  methods: {
    init(filter = null) {
      this.startCursor(filter);
      filter = this.filter.range ? this.filter : {range: this.range};
      this.handleGetTotalLiter(filter)
      this.$store
        .dispatch("fuelControl/fetch", {
          filter: filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    handleModalEdit(id) {
      this.$refs.modalEdit.openModal(id);
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      delete this.filter.range;
      this.range = {
        start: moment().format("YYYY-MM-DD hh:mm:ss"),
        end: moment().format("YYYY-MM-DD hh:mm:ss"),
      };
      this.init();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store
            .dispatch("fuelControl/destroy", id)
            .then((response) => {
              this.$notify({
                type: response.error_type,
                message: response.message,
              });
              loader.hide();
            })
            .catch((error) => {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              loader.hide();
            });
        }
      });
    },
    /**
     * Busca total de litros abastecidos
     * @param {object|null} filter 
     * @returns void
     */
    handleGetTotalLiter(filter) {
      this.$store.dispatch("fuelControl/getTotalLiter", {filter : filter });
    },
    /**
     * @param {string} type 
     */
    setViewType(type) {
      this.listType = type
    },
    downloadExcel() {
      const loader = this.$loading.show();
      this.$store.dispatch("fuelControl/getReportExcel", this.filter).then(() => {
        this.$notify({
          type: "success",
          message: "Solicitação realizada com sucesso! O relatório sera exportado em segundo plano.",
        });
      }).catch(error => {
        const errors = error && error.response && error.response.status === 422
          ? formatErrorValidation(error.response.data.data)
          : error.response.data.message;
        this.$notify({ type: "danger", message: errors });
      }).finally(() => {
        loader.hide();
      });
    },
  },
  mounted() {
    this.init();
    this.$store.dispatch("fuelTank/fetchNotPaginated").then( () => this.loadingFuelTank = false)
  },
};
</script>

<style scoped>
th {
  font-weight: 700;
  font-family: Courier New, monospace;
}
td {
  /*font-family: Courier New, monospace;*/
  text-transform: none;
}
.main-icon-menu {
  min-width: 18px;
}
</style>
