<template>
  <div style="min-height: 800px; background-color: #ffff;">
    <div class="mb-4" style=" margin: 0 2rem;">
      <div class="row align-items-center justify-content-between align-items-center pb-2" style="padding-top: 1.3rem;">
        <div class="d-flex align-items-center">
          <img height="30px" src="/img/icons/cart-warning.png" class="mr-2" />
          <h2 style="font-size: 1.2rem !important;color: #F2B532;" class="nowrap mb-0 mt-1">
            Formulário de cotação
          </h2>
        </div>
        <div class="mb-2 mr-3 back-button">
          <BackButton />
        </div>
      </div>
      <hr class="gray-divider mt-3 mb-4">
      <div>
        <div style="font-size: 15px">
          <span style="font-size: 15px;" class="font-weight-600">Requerimento:</span> {{ 'RC-' + purchaseRequest.id }}
        </div>
        <div style="font-size: 15px; margin: 0.3rem 0;">
          <span style="font-size: 15px;" class="font-weight-600">Solicitante:</span>
          {{ purchaseRequest.created_by_user_name }}
        </div>
        <div style="font-size: 15px">
          <span style="font-size: 15px;" class="font-weight-600">Central De Faturamento:</span>
          {{ purchaseRequest.input_company_plant_name }}
        </div>
      </div>
      <div class="items-conatiner w-100 mt-4">
        <div class="header-items-conatiner d-block d-md-flex">
          <div class="d-flex align-items-center">
            <img width="22" src="/img/icons/big-parcel-primary.png" class="mr-2" />
            <span style="font-size: 14px;" class="font-weight-600">Itens</span>
          </div>
          <div class="d-block d-md-flex mt-2 mt-md-0">
            <div @click="handleShowModalListItemsApproval({ ...purchaseRequest, can_approve: true })"
              class="header-button text-nowrap mr-0 mr-md-3 mb-2 mb-md-0 font-weight-500" style="border-color:#CCCCCC">
              <img src="/img/icons/icons8/ios/menu-squared-2-dark.png" width="22" />
            </div>
            <div @click="handleShowModalAddItems()"
              class="header-button mr-0 mr-md-3 mb-2 mb-md-0 text-nowrap px-3 px-md-4 font-weight-500"
              style="border-color: #149E57; color: #149E57">
              <img height="18" src="/img/icons/icons8/ios/add-success.png" class="mr-1" /> Incluir Item
            </div>
            <div @click="handleShowModalAddEntity()" class="header-button text-nowrap  mb-2 mb-md-0  font-weight-500"
              style="border-color:#1A70B7; color: #1A70B7">
              <img height="16" src="/img/icons/icons8/ios/add-primary.png" class="mr-1" /> Incluir Fornecedor
            </div>
            <div @click="fetchEntityBudgets()"
              class="header-button text-nowrap ml-0 ml-md-3 px-3 px-md-4 font-weight-500" style="border-color:#CCCCCC">
              <img height="19" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
            </div>
          </div>
        </div>
        <!-- BODY -->
        <div class="body-items-conatiner d-flex align-items-end" style="overflow-y: scroll;">
          <div class="row d-inline-block">
            <div class="d-flex justify-content-between mb-2" style="height: 5.5rem;">
              <div @click="selectBestSupplierItems()" class="best-value-provider mr-2" style="border-color: #1A70B7; color: #1A70B7;" >
                <img src="/img/icons/icons8/ios/business-building.png" height="30px" class="mr-2" />
                Melhor fornecedor
              </div>
              <div @click="selectLowestValueItems()" class="best-value-provider ml-2" style="border-color: #149E57; color: #149E57;">
                <img src="/img/icons/icons8/ios/low-price_success.svg" height="30px" class="mr-2">
                Melhores Preços
              </div>
            </div>
            <div>
              <table class="table resume-table table-custom-shaded mb-2">
                <thead>
                  <tr>
                    <th class="text-left new-default-black-font">Descrição Do Produto</th>
                    <th class="text-left new-default-black-font">Marca</th>
                    <th class="text-left new-default-black-font">Qto</th>
                    <th class="text-left new-default-black-font">UN.</th>
                    <th class="text-left new-default-black-font">Última Compra</th>
                  </tr>
                </thead>
                <!-- ITENS DA REQUISIÇÃO -->
                <tbody>
                  <tr v-for="(item, index) in requestItems" :key="index">
                    <!-- Status -->
                    <td class="">{{ item.description }}</td>
                    <td class="">{{ item.brand }}</td>
                    <td class="">{{ item.quantity }}</td>
                    <td class="">{{ item.input_unit }}</td>
                    <td class="">
                      <span style="font-size: 14px !important; color: #2B2D32; font-weight: 500">
                        R$ {{ item.preco }}
                      </span>
                      <el-popover class="p-0" placement="right" trigger="click">
                        <div>
                          <div class="d-flex justify-content-between">
                            <div>
                              <img class="mr-2" src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                              <span class="font-weight-500">Última Compra</span>
                            </div>
                            <div class="d-flex align-items-center" style="font-size: 11px;">
                              <img src="/img/icons/calendar-black.png" width="15px" class="mr-2" />
                              {{ item.dataCompra }}
                            </div>
                          </div>
                        </div>
                        <div class="my-2" style="display: grid !important">
                          <span>{{ item.fornecedor }}</span>
                          <span>{{ item.cnpj }}</span>
                        </div>
                        <div class="d-flex justify-content-between">
                          <div style="display: grid !important">
                            <span style="font-size: 12px;" class="fs-1">Quantidade</span>
                            <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{
                              item.quantidadeDetalhes }}</span>
                          </div>
                          <div style="display: grid !important">
                            <span style="font-size: 12px;" class="fs-1">Preço Unit.</span>
                            <span style="font-size: 18px; line-height: 12px;" class="font-weight-500">{{
                              item.precoDetalhes }}</span>
                          </div>
                        </div>
                        <base-button outline slot="reference" size="sm" type="secundary"
                          class="p-0 m-0 shadow-none text-indigo">
                          <img src="/img/icons/icons8/ios/info-circle_warning.png" width="20">
                        </base-button>
                      </el-popover>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-600"
                style="background-color: #c4e7d5; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;">
                <div>Total</div>
                <div>{{ $helper.toMoney(sumTotalValueAllItemEntityBudgets()) }}</div>
              </div>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-600 mt-1 mb-2"
                style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040;">
                <div>Total Selecionado</div>
                <div>{{ $helper.toMoney(sumTotalValueSellectedAllItemEntityBudget()) }}</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div v-if="totalDiscount > 0" class="p-1 px-3">Desconto</div>
                <div v-if="totalDiscount > 0" class="dropdown-divider p-0 m-0" />
                <div v-if="totalIpi > 0" class="p-1 px-3">Ipi</div>
                <div v-if="totalIpi > 0" class="dropdown-divider p-0 m-0" />
                <div v-if="totalIcms > 0" class="p-1 px-3">Icms</div>
                <div v-if="totalIcms > 0" class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3 font-weight-600">Valor Líquido</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3">Condição do frete</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Frete por conta do</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Valor do frete</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Transportadora</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Contato da Transportadora</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3 font-weight-600">Valor Final</div>
              </div>
              <div class="mb-2"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px;box-shadow: 0px 4px 4px 0px #00000040;">
                <div class="p-1 px-3">Condição de pagamento</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Prazo de entrega</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">Validade da cotação</div>
              </div>
              <div class="d-flex justify-content-center p-1 px-3 pointer" @click="createAllOrders()"
                style="background-color: #149E57; border-radius: 5px; color: #ffff; box-shadow: 0px 4px 4px 0px #00000040;">
                <img height="18px" src="/img/icons/cart.png" class="mr-2" />
                <div>Gerar Todas As Ordens De Compra</div>
              </div>
            </div>
          </div>
          <!-- FORNECEDORES -->
          <div class="row d-inline-block ml-4" v-for="(budgetEntity, index) in purchaseEntitiesBudgets" :key="index">
            <div>
              <div class="w-100 p-2" style="border: 0.5px solid #DCDFE6; border-radius: 8px 8px 3px 3px;">
                <div style="max-width: 250px;overflow: hidden; text-overflow: ellipsis; text-wrap: nowrap;">
                  {{ budgetEntity.entity_name }}
                </div>
                <div>{{ budgetEntity.entity_document }}</div>
                <div class="d-flex" style="justify-content: space-between;">
                  <div class="d-flex">
                    <div class="pointer px-3 d-flex align-items-center mr-1"
                      @click="budgetEntity.code ? copyEntitylink(budgetEntity.code) : createAndCopyEntitylink(budgetEntity.id)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px; padding: 0.15rem 0; font-size: 13px;">
                      <img class="mr-2" width="18"
                        :src="budgetEntity.code ? '/img/icons/icons8/ios/copy-2_primary.png' : '/img/icons/copy-success.png'"
                        alt="copy-2" />
                      {{ budgetEntity.code ? 'Copiar Link' : 'Criar Link' }}
                    </div>
                    <div v-if="budgetEntity.code" class="pointer px-2 mr-1 d-flex align-items-center"
                      @click="regenerateAndCopyEntitylink(budgetEntity)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px;">
                      <img height="19" src="/img/icons/icons8/ios/synchronize.png" alt="synchronize" />
                    </div>
                    <div v-if="budgetEntity.code" class="pointer px-2 d-flex align-items-center"
                      @click="allowOrDenyEntityEdit(budgetEntity)"
                      style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px;">
                      <img width="18"
                        :src="budgetEntity.entity_can_edit ? '/img/icons/unlock.png' : '/img/icons/icons8/ios/lock_danger.png'"
                        alt="copy-2" />
                    </div>
                  </div>
                  <div class="pointer px-3"
                    style="box-shadow: 0px 4px 4px 0px #00000040; border: 0.5px solid #DCDFE6; border-radius: 5px; padding: 0.15rem 0; font-size: 13px;">
                    <base-dropdown menuOnRight>
                      <div slot="title-container" class="dropdown-toggle rounded m-0">
                        <img width="22" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                      </div>
                      <a @click="handleShowModalEditEntity(budgetEntity)"
                        class="dropdown-item font-weight-500 new-default-black-font"
                        style="align-items: center; display: flex">
                        <img width="18" src="https://img.icons8.com/ios/100/f2b532/create-new.png" alt="create-new" />
                        Editar
                      </a>
                      <div class="dropdown-divider p-0 m-0"></div>
                      <a @click="handleDelete(budgetEntity)"
                        class="dropdown-item font-weight-500 new-default-black-font"
                        style="align-items: center; display: flex">
                        <img width="19" src="/img/icons/icons8/ios/delete--v1_danger.png" />
                        Remover
                      </a>
                    </base-dropdown>
                  </div>
                </div>
              </div>
              <table class="table table-custom-shaded mb-2">
                <thead>
                  <tr>
                    <th class="text-left new-default-black-font">PREÇO Unit.</th>
                    <th class="text-left new-default-black-font d-flex justify-content-between">
                      TOTAL
                      <img @click="selectOrRemoveAllByBudgetIndex(index)" class="mr-1 pointer" width="20"
                        src="/img/icons/icons8/ios/double-box-tick-grey.png" alt="copy-2" />
                    </th>
                  </tr>
                </thead>
                <!-- ITENS DOS FORNECEDORES -->
                <tbody>
                  <tr v-for="(item, itemIndex) in budgetEntity.items" :key="itemIndex">
                    <td class="">
                      <img v-if="item.best_value && item.unit_value > 0 && purchaseEntitiesBudgets.length > 1"
                        class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-down-success.png" alt="copy-2" />
                      <img v-if="!item.best_value && item.unit_value > 0 && purchaseEntitiesBudgets.length > 1"
                        class="mr-1" width="15" src="/img/icons/icons8/ios/arrow-up-danger.png" alt="copy-2" />
                      {{ $helper.toMoney(item.unit_value) }}
                    </td>
                    <td v-if="purchaseEntitiesBudgets[index].items[itemIndex].selected"
                      @click="removeItem(index, itemIndex)"
                      class="pointer font-weight-700 d-flex justify-content-between" style="color: #2B2D32;">
                      {{ $helper.toMoney(item.final_value) }}
                      <img class="ml-1" width="22" src="/img/icons/icons8/ios/circled-tick-grey.png" alt="copy-2" />
                    </td>
                    <td v-else @click="selectItem(index, itemIndex)"
                      class="pointer font-weight-700 d-flex justify-content-between" style="color: #2B2D32;">
                      {{ $helper.toMoney(item.final_value || 0) }}
                      <img class="ml-1" width="22" src="/img/icons/circle.png" alt="copy-2" />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-500"
                style="background-color: #c4e7d5; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; color: #2B2D32">
                <div>{{ $helper.toMoney(sumTotalValueItemEntityBudget(index)) }}</div>
              </div>
              <div class="d-flex justify-content-between p-1 px-3 font-weight-500 mt-1 mb-2"
                style="background-color: #c6dbed; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; color: #2B2D32;">
                <div>{{ $helper.toMoney(sumSelectedItemsEntityBudget(index)) }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div v-if="totalDiscount > 0" class="p-1 px-3">{{ $helper.toMoney(sumTaxesAndCosts(budgetEntity,
                  'discount_value')) }}</div>
                <div v-if="totalDiscount > 0" class="dropdown-divider p-0 m-0" />
                <div v-if="totalIpi > 0" class="p-1 px-3">{{ $helper.toMoney(sumTaxesAndCosts(budgetEntity,
                  'ipi_value')) }}</div>
                <div v-if="totalIpi > 0" class="dropdown-divider p-0 m-0" />
                <div v-if="totalIcms > 0" class="p-1 px-3">{{ $helper.toMoney(sumTaxesAndCosts(budgetEntity,
                  'icms_value')) }}</div>
                <div v-if="totalIcms > 0" class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ $helper.toMoney(calculateNetAmount(budgetEntity, index)) }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ budgetEntity.shipping_condition ? 'FOB' : 'CIF' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3 text-nowrap">{{ budgetEntity.freight_modality_name || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ $helper.toMoney(budgetEntity.delivery_cost) }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ budgetEntity.delivery_company_name || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">{{ budgetEntity.delivery_contact || 'N/A' }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ $helper.toMoney(calculateFinalAmount(budgetEntity, index)) }}</div>
              </div>
              <div class="mb-2 font-weight-500"
                style="border: 0.5px solid #CCCCCC; border-radius: 5px; box-shadow: 0px 4px 4px 0px #00000040; background-color: #f2f2f2; color: #2B2D32">
                <div class="p-1 px-3">{{ budgetEntity.payment_method || 'N/A' }}</div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">
                  <span v-if="budgetEntity.delivery_deadline">
                    {{ $helper.parseDate(budgetEntity.delivery_deadline, "DD MMM YYYY") }}</span>
                  <span v-else>N/A</span>
                </div>
                <div class="dropdown-divider p-0 m-0" />
                <div class="p-1 px-3">
                  <span v-if="budgetEntity.quotation_validity">
                    {{ $helper.parseDate(budgetEntity.quotation_validity, "DD MMM YYYY") }}</span>
                  <span v-else>N/A</span>
                </div>
              </div>
              <div @click="createOrder(budgetEntity, index)"
                class="d-flex justify-content-center p-1 px-5 pointer font-weight-500 text-nowrap"
                style="border: 0.5px solid #149E57; border-radius: 5px; color: #149E57; box-shadow: 0px 4px 4px 0px #00000040;">
                <img height="18px" src="/img/icons/icons8/ios/shopping-cart.png" class="mr-2" />
                <div>Gerar ordem de compra</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalListItemsApproval :showUpdateButton="true" @fetch="getPurchaseRequestItems" ref="modalListItemsApproval" />
    <ModalAddItems :fromBudget="true" @addProductService="addProductService"
      :itemType="purchaseRequest.product_service_hub_type_id" ref="modalAddItems" />
    <ModaEditEntityBudget @fetchEntityBudgets="fetchEntityBudgets()" @addProductService="addProductService"
      ref="modalEditEntity" />
    <ModalAddEntity @selected="addEntityBudget" ref="modalAddEntity" />
  </div>
</template>

<script setup>
//#region Imports
import { ref, watch, onMounted, getCurrentInstance } from 'vue';
import BackButton from '@/components/Utils/BackButton';
import ModalAddItems from "../../../Request/views/create/Shared/_ModalAddItems.vue";
import ModalAddEntity from "./shared/_ModalAddEntity.vue";
import ModaEditEntityBudget from "./shared/_ModaEditEntityBudget.vue";
import ModalListItemsApproval from "../../../Request/views/list/Shared/_ModalListItemsApproval.vue";
import { dialogs, progress } from '../../../.../../../../../../helpers';
import purchaseBudgetStore from '../../store/purchaseBudgetStore';
import purchaseOrderStore from '../../../Order/store/purchaseOrderStore';
import purchaseRequestStore from "../../../Request/store/purchaseRequestStore";
import PurchaseBudgetStatusEnum from "../../enums/PurchaseBudgetStatusEnum";
import PurchaseBudgetItemStatusEnum from "../../enums/PurchaseBudgetItemStatusEnum";

//#endregion


//#region Data
const requestItems = ref([]);
const purchaseRequest = ref(null);
const purchaseEntitiesBudgets = ref([]);
const totalIpi = ref(null);
const totalIcms = ref(null);
const totalDiscount = ref(null);
//#endregion

watch(
  purchaseEntitiesBudgets,
  (newValue) => {
    totalIpi.value = sumAllTaxesAndCosts(newValue, 'ipi_value');
    totalIcms.value = sumAllTaxesAndCosts(newValue, 'icms_value');
    totalDiscount.value = sumAllTaxesAndCosts(newValue, 'discount_value');
  },
  { deep: true }
);

const modalAddItems = ref(null);
const modalAddEntity = ref(null);
const modalListItemsApproval = ref(null);
const modalEditEntity = ref(null);

function handleShowModalAddItems() {
  modalAddItems.value.handleCreateModal();
}
function handleShowModalAddEntity() {
  modalAddEntity.value.handleCreateModal();
}
function handleShowModalListItemsApproval(data) {
  modalListItemsApproval.value.handleCreateModal(data);
}
function handleShowModalEditEntity(data) {
  modalEditEntity.value.handleCreateModal(data);
}

function selectLowestValueItems() {
  const lowestValueByDescription = new Map();

  // Primeiro, encontra o menor valor para cada descrição
  purchaseEntitiesBudgets.value.forEach(budget => {
    budget.items.forEach(item => {
      const value = parseFloat(item.final_value);
      if (value > 0) {
        if (!lowestValueByDescription.has(item.description) || value < lowestValueByDescription.get(item.description)) {
          lowestValueByDescription.set(item.description, value);
        }
      }
    });
  });

  // Agora, marca os itens que têm o menor valor da sua descrição
  purchaseEntitiesBudgets.value.forEach(budget => {
    budget.items.forEach(item => {
      item.selected = parseFloat(item.final_value) === lowestValueByDescription.get(item.description);
    });
  });
}


function selectBestSupplierItems() {
  // Filtra os fornecedores que preencheram todos os itens
  const suppliersWithAllItems = purchaseEntitiesBudgets.value.filter(budget => {
    return budget.items.every(item => parseFloat(item.final_value) > 0);
  });
  // Calcula o preço total de cada fornecedor
  const suppliersWithTotalPrice = suppliersWithAllItems.map(budget => {
    const totalPrice = budget.items.reduce((sum, item) => sum + parseFloat(item.final_value), 0);
    return { ...budget, totalPrice };
  });
  // Encontra o fornecedor com o menor preço total
  const bestSupplier = suppliersWithTotalPrice.reduce((prev, curr) => 
    prev.totalPrice < curr.totalPrice ? prev : curr
  );

  // Desmarca todos os itens de todos os fornecedores
  purchaseEntitiesBudgets.value.forEach(budget => {
    budget.items.forEach(item => {
      item.selected = false;
    });
  });

  // Seleciona todos os itens do melhor fornecedor
  bestSupplier.items.forEach(item => {
    item.selected = true;
  });
}

function sumTaxesAndCosts(budgetEntity, taxAndCostField) {
  let total = 0;
  budgetEntity.items?.forEach((item) => {
    if (item.selected) {
      if (item[taxAndCostField]) {
        const value = parseFloat(item[taxAndCostField]) || 0;
        total += value;
      }
    }
  });

  return total.toFixed(2);
}

function sumAllTaxesAndCosts(budgetEntities, taxAndCostField) {
  let total = 0;
  budgetEntities.forEach((budgetEntity) => {
    budgetEntity.items?.forEach((item) => {
      if (item.selected) {
        const value = parseFloat(item[taxAndCostField]) || 0;
        total += value;
      }
    });
  });

  return total.toFixed(2);
}

//#region Methods
function sumTotalValueSellectedAllItemEntityBudget() {
  let total = 0;
  purchaseEntitiesBudgets.value.forEach((budget) => {
    budget.items.forEach((item) => {
      if (item.selected) {
        const value = parseFloat(item.final_value) || 0;
        total += value;
      }
    });
  });
  return total;
}

function sumTotalValueAllItemEntityBudgets() {
  let total = 0;
  purchaseEntitiesBudgets.value.forEach((budget) => {
    budget.items.forEach((item) => {
      const value = parseFloat(item.final_value) || 0;
      total += value;
    });
  });
  return total;
}


async function getPurchaseRequest() {
  const { proxy } = getCurrentInstance();
  const response = await purchaseRequestStore.actions.show(proxy.$route.params.id);
  purchaseRequest.value = response;
  requestItems.value = response.items.filter(item => item.status === PurchaseBudgetItemStatusEnum.keys.APPROVED);
  fetchEntityBudgets();
}

async function getPurchaseRequestItems() {
  const response = await purchaseRequestStore.actions.fetchRequestItems({ purchase_request_id: purchaseRequest.value.id });
  requestItems.value = response.data.filter(item => item.status === PurchaseBudgetStatusEnum.keys.APPROVED);
  fetchEntityBudgets();
}

async function fetchEntityBudgets() {
  progress.start();
  const loader = progress.showLoader();
  const response = await purchaseBudgetStore.actions.fetchEntityBudgets({
    order_by: [{ column: 'purchase_budget_entities.id' }],
    purchase_budget_id: purchaseRequest.value.purchase_budget_id,
  });
  purchaseEntitiesBudgets.value = response.items;
  if (purchaseEntitiesBudgets.value.length > 1) {
    setBestValueItem();
  }
  updateEntityBudgetItems(requestItems.value, purchaseEntitiesBudgets.value);
  progress.finish();
  loader.hide();
}

function updateEntityBudgetItems(items, budgets) {
  const itemIds = items.map(item => item.id);

  // Atualiza o array 'items' de cada cotação
  budgets.forEach(budgetItem => {
    // Mantém apenas os itens que existem no array 'itens'
    budgetItem.items = budgetItem.items.filter(budgetEntityItem => {
      return itemIds.includes(parseInt(budgetEntityItem.purchase_request_item_id));
    });
    items.forEach(item => {
      const itemExists = budgetItem.items.some(budgetEntityItem =>
        parseInt(budgetEntityItem.purchase_request_item_id) === item.id
      );

      if (!itemExists) {
        budgetItem.items.push({
          id: item.id,
          purchase_entity_budget_id: budgetItem.id,
          purchase_request_item_id: item.id,
          quantity: parseFloat(item.quantity.toString().replace(",", ".")).toFixed(3),
          selected: 0,
          unit_value: "0.00",
          description: item.description,
          input_unit: item.input_unit,
          request_quantity: parseFloat(item.quantity.toString().replace(",", ".")).toFixed(3),
          best_value: false
        });
      }
    });
  });

  purchaseEntitiesBudgets.value = budgets;
}

async function addProductService(data) {
  const index = requestItems.value.findIndex(item => item.product_service_id === data.product_service_id);
  if (index !== -1) {
    requestItems.value[index].quantity = data.quantity;
    requestItems.value[index].brand = data.brand || '';
    requestItems.value[index].status = data.status;
  } else {
    data.status = 0;
    requestItems.value.push(data);
  }
  await save();
  await getPurchaseRequestItems();
  handleShowModalListItemsApproval({ ...purchaseRequest.value, can_approve: true });
}

async function save() {
  purchaseRequest.value.items = requestItems.value;
  await purchaseRequestStore.actions.update(purchaseRequest.value);
  await fetchEntityBudgets();
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Item adicionado com sucesso!');
}

function copyEntitylink(code) {
  navigator.clipboard.writeText(`${window.location.origin}/entity-login/${code}`);
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Link copiado com sucesso!');
}

async function regenerateAndCopyEntitylink(data) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente renovar o link de acesso?');
  if (isConfirmed) {
    await createAndCopyEntitylink(data.id);
  }
}

async function createAndCopyEntitylink(EntityBudgetId) {
  progress.start();
  const loader = progress.showLoader();
  const response = await purchaseBudgetStore.actions.updateCode(EntityBudgetId);
  progress.finish();
  loader.hide();
  // Copiar link para o clipboard
  navigator.clipboard.writeText(`${window.location.origin}/entity-login/${response.code}`);
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Link copiado com sucesso!');
  await fetchEntityBudgets();
}

async function allowOrDenyEntityEdit(budgetEntity) {
  budgetEntity.entity_can_edit = !budgetEntity.entity_can_edit;
  await purchaseBudgetStore.actions.updateEntityCanEdit(budgetEntity);
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Alteração salva com sucesso!');
}

async function addEntityBudget(entity) {
  const entityAlreadyExists = purchaseEntitiesBudgets.value.some(budget => budget.entity_id === entity.id);
  if (entityAlreadyExists) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Esse fornecedor já existe nessa cotação!');
    return;
  }

  const budgetEntity = {
    purchase_budget_id: purchaseRequest.value.purchase_budget_id,
    entity_id: entity.id,
    items: requestItems.value.map(item => ({ ...item, purchase_request_item_id: item.id }))
  };

  await purchaseBudgetStore.actions.addEntityBudget(budgetEntity);
  await fetchEntityBudgets();
  dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Fornecedor adicionado com sucesso!');
}

function selectItem(budgetIndex, itemIndex) {
  if (!Number(purchaseEntitiesBudgets.value[budgetIndex].items[itemIndex].unit_value) > 0) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Item com valor zerado.');
    return;
  }

  const selectedPurchaseRequestItemId = purchaseEntitiesBudgets.value[budgetIndex].items[itemIndex].purchase_request_item_id;
  purchaseEntitiesBudgets.value.forEach((budget, bIndex) => {
    budget.items.forEach((item, iIndex) => {
      if (item.purchase_request_item_id === selectedPurchaseRequestItemId) {
        purchaseEntitiesBudgets.value[bIndex].items[iIndex].selected = false;
      }
    });
  });

  const currentItem = purchaseEntitiesBudgets.value[budgetIndex].items[itemIndex];
  purchaseEntitiesBudgets.value[budgetIndex].items[itemIndex].selected = !currentItem.selected;
}

function removeItem(budgetIndex, itemIndex) {
  purchaseEntitiesBudgets.value[budgetIndex].items[itemIndex].selected = false;
}

function sumTotalValueItemEntityBudget(budgetIndex) {
  return purchaseEntitiesBudgets.value[budgetIndex].items.reduce(
    (total, item) => total + (parseFloat(item.final_value) || 0),
    0
  );
}

function sumSelectedItemsEntityBudget(budgetIndex) {
  return purchaseEntitiesBudgets.value[budgetIndex].items.reduce((total, item) => {
    return item.selected ? total + (parseFloat(item.final_value) || 0) : total;
  }, 0);
}

function calculateNetAmount(budgetEntity, index) {
  const sumSelected = parseFloat(sumSelectedItemsEntityBudget(index)) || 0;
  return (sumSelected).toFixed(2);
}

function calculateFinalAmount(budgetEntity, index) {
  const sumSelected = parseFloat(sumSelectedItemsEntityBudget(index)) || 0;
  const delivery = parseFloat(budgetEntity.delivery_cost) || 0;

  return (sumSelected + delivery).toFixed(2);
}

function selectOrRemoveAllByBudgetIndex(budgetIndex) {
  const areAllSelected = purchaseEntitiesBudgets.value[budgetIndex].items
    .filter(item => item.unit_value > 0)
    .every(item => item.selected);

  const newState = !areAllSelected;

  purchaseEntitiesBudgets.value.forEach((budget, bIndex) => {
    if (bIndex !== budgetIndex) {
      budget.items.forEach((item, itemIndex) => {
        purchaseEntitiesBudgets.value[bIndex].items[itemIndex].selected = false;
      });
    }
  });

  purchaseEntitiesBudgets.value[budgetIndex].items.forEach((item, index) => {
    purchaseEntitiesBudgets.value[budgetIndex].items[index].selected = item.unit_value > 0 ? newState : item.selected;
  });
}

async function handleDelete(budgetEntity) {
  const isConfirmed = await dialogs.confirmAction('Tem certeza que deseja remover essa cotação?');
  if (isConfirmed) {
    await purchaseBudgetStore.actions.remove(budgetEntity.id);
    await fetchEntityBudgets();
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Cotação removida com sucesso!');
  }
}

function setBestValueItem() {
  const minValues = {};
  purchaseEntitiesBudgets.value.forEach(budget => {
    budget.items.forEach(item => {
      const id = item.purchase_request_item_id;
      const unitValue = parseFloat(item.unit_value);
      if (unitValue > 0 && (!minValues[id] || unitValue < minValues[id])) {
        minValues[id] = unitValue;
      }
    });
  });
  purchaseEntitiesBudgets.value.forEach(budget => {
    budget.items.forEach(item => {
      const id = item.purchase_request_item_id;
      const unitValue = parseFloat(item.unit_value);
      item.best_value = unitValue > 0 && unitValue === minValues[id];
    });
  });
}

async function createOrder(budgetEntity, index) {
  const isConfirmed = await dialogs.confirmAction('Tem certeza que deseja criar essa ordem?', ['Continuar!', 'Cancelar']);
  if (isConfirmed) {
    const selectedItems = budgetEntity.items
      .filter(item => item.selected)
      .map(item => ({ budget_entity_item_id: item.id }));
    if (selectedItems.length === 0) {
      dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Selecione pelo menos um item');
      return;
    }
    await purchaseOrderStore.actions.add({
      purchase_orders: [{
        purchase_budget_id: budgetEntity.purchase_budget_id,
        purchase_budget_entity_id: budgetEntity.id,
        total_value: sumSelectedItemsEntityBudget(index),
        items: selectedItems
      }]
    });
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Ordem de compra criada com sucesso');
  }
}

async function createAllOrders() {
  const isConfirmed = await dialogs.confirmAction('Você tem certeza?', ['Continuar!', 'Cancelar']);
  if (isConfirmed) {
    const purchaseOrders = purchaseEntitiesBudgets.value.map((budgetEntity, index) => {
      const selectedItems = budgetEntity.items
        .filter(item => item.selected)
        .map(item => ({ budget_entity_item_id: item.id }));
      if (selectedItems.length > 0) {
        return {
          purchase_budget_id: budgetEntity.purchase_budget_id,
          purchase_budget_entity_id: budgetEntity.id,
          total_value: sumSelectedItemsEntityBudget(index),
          items: selectedItems
        };
      }
    }).filter(Boolean);
    if (purchaseOrders.length === 0) {
      dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Selecione ao menos um item para criar ordens de compra');
      return;
    }
    progress.start();
    const loader = progress.showLoader();
    await purchaseOrderStore.actions.add({ purchase_orders: purchaseOrders });
    progress.finish();
    loader.hide();
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Ordens de compra criadas com sucesso');
  }
}
//#endregion

//#region Lifecycle
onMounted(() => {
  getPurchaseRequest();
});
//#endregion
</script>

<style scoped>
.back-button {
  width: 14rem;
}

.header-items-conatiner {
  height: unset !important;
  min-height: 50px !important;
}

.header-items-conatiner .header-button {
  width: auto !important;
  font-weight: 500;
}

.best-value-provider {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 0.5px solid;
  padding: 0.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  width: 50%;
  height: 100%;
}

/* HEADER */
.items-conatiner .header-items-conatiner {
  width: 1189px;
  min-height: 50px;
  top: 271px;
  left: 218px;
  border-radius: 10px 10px 0 0;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px #00000040;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.items-conatiner .header-items-conatiner .header-button {
  width: 130px;
  height: 25px;
  top: 234px;
  left: 1114px;
  border-radius: 5px;
  border: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  border: 0.5px solid;
  font-size: 13px;
  padding: 0.5rem 1rem !important;
}

.body-items-conatiner {
  border-bottom: 1px solid #DCDFE6;
  border-radius: 0 0 10px 10px !important;
  box-shadow: 0px 4px 4px 0px #00000040;
  padding: 1rem 2rem !important;
}

/* Tabelas */
.resume-table th:last-child {
  background-color: #fceccc !important;
}

.resume-table td:last-child {
  background-color: #fceccc !important;
  color: #2B2D32 !important;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.table-custom-shaded td {
  padding: 0.5rem 1rem !important;
}

.table-custom-shaded td:last-child,
.table-custom-shaded th:last-child {
  text-align: center !important;
  padding-left: 1rem !important;
}

.dropdown-divider {
  border: 1px solid #DCDFE6 !important;
}

@media (max-width: 768px) {
  .back-button {
    width: 6rem;
  }

  .back-button .btn {
    padding: 0 !important;
  }
}
</style>