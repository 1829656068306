<template>
  <div>
    <card>
      <section class="grid">
        <!-- header -->
        <div class="_sidenav">
          <div
            class="_item-task"
            v-for="(header, index) in headers"
            :key="index"
          >
            <div
              class="_icon"
              v-if="header.iconSrc && header.iconSrc.trim() !== ''"
            >
              <img
                width="20"
                height="20"
                :src="header.iconSrc"
                alt="..."
                class="mt-n1"
              />
            </div>
            <span>{{ truncateText(header.title) }}</span>
          </div>
        </div>
        <div class="_info">
          <div :class="{ 'display-none': !headers.length }">
            <el-popover class="p-0 pr-0 mr-1" placement="right" trigger="click">
              <div class="pt-2 pl-3 pr-2 pb-4 el_popover">
                <div>
                  <span class="text-left"> Disponibilidade</span>
                </div>
                <div class="mt-3">
                  <ul>
                    <li>
                      <div>
                        <span>Parado na central</span>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-end"
                      >
                        {{ getInfo(substatusEnum.RELEASED) }}
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Dosando na central</span>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-end"
                      >
                        {{ getInfo(substatusEnum.DOSING) }}
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>A caminho da obra</span>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-end"
                      >
                        {{ getInfo(substatusEnum.ON_THE_WAY) }}
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Em obra</span>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-end"
                      >
                        {{ getInfo(substatusEnum.DISCHARGING) }}
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Retornando da obra</span>
                      </div>
                      <div
                        class="d-flex justify-content-center align-items-end"
                      >
                        {{ getInfo(substatusEnum.RETURNING) }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <base-button
                slot="reference"
                class="p-0 m-0 shadow-none"
                outiline
                size="sm"
                type="secundary"
              >
                <img
                  width="16"
                  src="/img/icons/icons8/ios/info-squared_black.png"
                  alt="info-squared_black"
                  class="mt-n1"
                />
                <span class="info">Info</span>
              </base-button>
            </el-popover>
          </div>
        </div>
        <div
          class="_nav"
          ref="scrollTop"
          @scroll="syncScroll('scrollTop')"
          @mousedown="startDrag('scrollTop', $event)"
          @mousemove="onDrag"
          @mouseup="stopDrag"
          @mouseleave="stopDrag"
        >
          <div
            class="_timer_line"
            ref="lineTimer"
            :class="{ 'display-none': !headers.length }"
          ></div>
          <div
            class="_availability"
            v-for="hour in hours"
            :key="hour"
            :class="{ 'display-none': !headers.length }"
          >
            <div v-for="(item, index) in headers" :key="index">
              <span
                v-for="(available, indexAvailable) in item.availability[+hour]"
                :key="indexAvailable"
                :style="available < 0 ? { color: '#db4539' } : {}"
                >{{ available }}</span
              >
            </div>
          </div>
        </div>

        <!-- title -->
        <div class="_title _header_order">
          <img
            width="20"
            src="/img/icons/icons8/ios/numeric.png"
            alt="numeric"
          />
        </div>
        <div class="_title _header_sidecontent">
          <span>Viagem</span>
          <img
            width="16"
            src="/img/icons/lessThan_grey.png"
            alt="lessThan_grey"
          />
        </div>
        <div class="_title _header_infocontent">
          <span>Tempo</span>
        </div>
        <div
          class="_title _header_content"
          ref="scrollHeader"
          @scroll="syncScroll('scrollHeader')"
          @mousedown="startDrag('scrollTop', $event)"
          @mousemove="onDrag"
          @mouseup="stopDrag"
          @mouseleave="stopDrag"
        >
          <div class="_timer_line" ref="lineTimer"></div>
          <div class="_scheduled" v-for="hour in hours" :key="hour">
            <div>
              <span>{{ hour }}h</span>
            </div>
            <div>
              <span v-for="(minute, index) in minutesList" :key="index">{{
                minute
              }}</span>
            </div>
          </div>
        </div>

        <!-- content -->
        <div class="_order">
          <div
            class="_task pointer"
            v-for="(item, index) in items"
            :key="index"
            @click="handleItemIndex(item.id)"
          >
            <span>{{ item.index ? item.index : index + 1 }}</span>
          </div>
        </div>
        <div class="_sidecontent">
          <div class="_task" v-for="(item, index) in items" :key="index">
            <span
              class="pointer"
              :title="item.title"
              @click="handleItemDetail(item.id)"
            >
              {{ truncateText(item.title, 60) }}</span
            >
            <img
              v-if="item.iconSrc"
              class="pointer"
              @click="handleItemAction(item.id)"
              width="20"
              :src="item.iconSrc"
              alt="..."
            />
          </div>
        </div>
        <div class="_infocontent">
          <div
            class="_task pointer"
            v-for="(item, index) in items"
            :key="index"
            @click="handleStatusDetail(item.id)"
          >
            <span
              v-if="
                !item.status.openPopoverWithClick ||
                (item.status.openPopoverWithClick !== undefined &&
                  item.status.openPopoverWithClick === false)
              "
              :class="{
                exceeded: item.status.percent && item.status.percent > 100,
              }"
              >{{ item.status.percent }}%</span
            >
            <img
              v-if="
                (item.status.iconSrc &&
                  item.status.iconSrc.trim() !== '' &&
                  !item.status.openPopoverWithClick) ||
                (item.status.openPopoverWithClick !== undefined &&
                  item.status.openPopoverWithClick === false)
              "
              width="18"
              :src="item.status.iconSrc"
              alt="info-squared_black"
            />
            <el-popover
              class="p-0 pr-0 mr-1"
              placement="right"
              trigger="click"
              v-if="
                item.status.openPopoverWithClick &&
                item.status.openPopoverWithClick === true
              "
            >
              <div
                class="pt-2 pl-3 pr-2 pb-4 el_popover"
                :class="'popover-status-detail-' + item.id"
              ></div>
              <base-button
                slot="reference"
                class="p-0 m-0 shadow-none"
                outiline
                size="sm"
                type="secundary"
              >
                <span
                  :class="{
                    exceeded: item.status.percent && item.status.percent > 100,
                  }"
                  >{{ item.status.percent }}%</span
                >
                <img
                  v-if="
                    item.status.iconSrc && item.status.iconSrc.trim() !== ''
                  "
                  width="18"
                  :src="item.status.iconSrc"
                  alt="info-squared_black"
                />
              </base-button>
            </el-popover>
          </div>
        </div>
        <div
          class="_content"
          :ref="'scrollContent_' + 0"
          @scroll="syncScroll('scrollContent_' + 0)"
          @mousedown="startDrag('scrollTop', $event)"
          @mousemove="onDrag"
          @mouseup="stopDrag"
          @mouseleave="stopDrag"
        >
          <div class="_timer_line" ref="lineTimer"></div>
          <div class="_container_task">
            <div class="_new_task" v-for="(item, index) in items" :key="index">
              <svg
                v-if="
                  !task.openPopoverWithClick ||
                  (item.openPopoverWithClick !== undefined &&
                    item.openPopoverWithClick === false)
                "
                v-for="(task, indexTask) in item.tasks"
                :key="indexTask"
                height="35"
                width="2174"
              >
                <rect
                  @click="handleTaskDetail(task.id, item.id)"
                  class="pointer"
                  :x="
                    calculateTaskPosition(task.start_time, task.end_time)[
                      'startPosition'
                    ]
                  "
                  y="15%"
                  :width="
                    calculateTaskPosition(task.start_time, task.end_time)[
                      'endPosition'
                    ] -
                    calculateTaskPosition(task.start_time, task.end_time)[
                      'startPosition'
                    ]
                  "
                  height="30"
                  :style="'fill:' + task.color"
                  rx="14"
                  ry="14"
                ></rect>
                <image
                  v-for="(iconSrc, indexIconSrc) in task.iconSrcList"
                  :key="indexIconSrc"
                  :href="iconSrc"
                  :x="
                    calculateTaskPosition(task.start_time, task.end_time)[
                      'startPosition'
                    ] +
                    SVGAdjustmentValue * (indexIconSrc * 3 + 5)
                  "
                  y="10"
                  width="20"
                  height="20"
                />
                <foreignObject
                  :x="
                    calculateTaskPosition(task.start_time, task.end_time)[
                      'startPosition'
                    ] +
                      (calculateTaskPosition(task.start_time, task.end_time)[
                        'endPosition'
                      ] -
                        calculateTaskPosition(task.start_time, task.end_time)[
                          'startPosition'
                        ]) +
                      SVGAdjustmentValue <
                    2170
                      ? calculateTaskPosition(task.start_time, task.end_time)[
                          'startPosition'
                        ] +
                        (calculateTaskPosition(task.start_time, task.end_time)[
                          'endPosition'
                        ] -
                          calculateTaskPosition(task.start_time, task.end_time)[
                            'startPosition'
                          ]) +
                        SVGAdjustmentValue
                      : calculateTaskPosition(task.start_time, task.end_time)[
                          'endPosition'
                        ] -
                        SVGAdjustmentValue * 20
                  "
                  y="10"
                  width="280"
                  height="80"
                >
                  <div
                    style="
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;
                    "
                  >
                    <div
                      v-for="(detail, indexDetail) in task.details"
                      :key="indexDetail"
                      @click="callbackExec(task, detail.callback)"
                      :class="{ pointer: detail.callback ? true : false }"
                      style="
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                      "
                    >
                      <img
                        v-if="detail.iconSrc"
                        :src="detail.iconSrc"
                        style="margin: 0 2px"
                        width="20"
                        height="20"
                        alt="..."
                      />
                      <span
                        style="
                          margin: 0 2px;
                          font-size: 12px;
                          font-weight: 400;
                          color: #2b2d32;
                        "
                      >
                        {{ detail.description }}
                      </span>
                      <span v-if="task.details.length - 1 !== indexDetail">
                        |
                      </span>
                    </div>
                  </div>
                </foreignObject>
              </svg>

              <div v-else>
                <svg
                  v-for="(task, indexTask) in item.tasks"
                  :key="indexTask"
                  height="35"
                  width="2174"
                >
                  <rect
                    class="pointer"
                    :x="
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'startPosition'
                      ]
                    "
                    y="15%"
                    :width="
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'endPosition'
                      ] -
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'startPosition'
                      ]
                    "
                    height="30"
                    :style="'fill:' + task.color"
                    rx="14"
                    ry="14"
                  ></rect>
                  <foreignObject
                    @click="handleTaskDetail(task.id, item.id)"
                    :x="
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'startPosition'
                      ]
                    "
                    y="15%"
                    :width="
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'endPosition'
                      ] -
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'startPosition'
                      ]
                    "
                    height="30"
                  >
                    <el-popover
                      class="p-0 pr-0 mr-1"
                      placement="top"
                      trigger="click"
                    >
                      <div
                        class="pt-2 pl-3 pr-2 pb-4 el_popover"
                        :class="
                          'popover-task-detail-' + item.id + '-' + task.id
                        "
                      ></div>

                      <div
                        slot="reference"
                        style="
                          display: flex;
                          flex-direction: row;
                          gap: 5px;
                          justify-content: center;
                          align-items: center;
                        "
                        :style="{
                          width:
                            calculateTaskPosition(
                              task.start_time,
                              task.end_time
                            )['endPosition'] -
                            calculateTaskPosition(
                              task.start_time,
                              task.end_time
                            )['startPosition'] -
                            10 +
                            'px',
                        }"
                      >
                        <img
                          v-for="(iconSrc, indexIconSrc) in task.iconSrcList"
                          :key="indexIconSrc"
                          :src="iconSrc"
                          width="20"
                          height="20"
                        />
                      </div>
                    </el-popover>
                  </foreignObject>
                  <foreignObject
                    :x="
                      calculateTaskPosition(task.start_time, task.end_time)[
                        'startPosition'
                      ] +
                        (calculateTaskPosition(task.start_time, task.end_time)[
                          'endPosition'
                        ] -
                          calculateTaskPosition(task.start_time, task.end_time)[
                            'startPosition'
                          ]) +
                        SVGAdjustmentValue <
                      2170
                        ? calculateTaskPosition(task.start_time, task.end_time)[
                            'startPosition'
                          ] +
                          (calculateTaskPosition(
                            task.start_time,
                            task.end_time
                          )['endPosition'] -
                            calculateTaskPosition(
                              task.start_time,
                              task.end_time
                            )['startPosition']) +
                          SVGAdjustmentValue
                        : calculateTaskPosition(task.start_time, task.end_time)[
                            'endPosition'
                          ] -
                          SVGAdjustmentValue * 28
                    "
                    y="10"
                    width="280"
                    height="40"
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                      "
                    >
                      <div
                        v-for="(detail, indexDetail) in task.details"
                        :key="indexDetail"
                        @click="callbackExec(task, detail.callback)"
                        :class="{ pointer: detail.callback ? true : false }"
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: flex-start;
                          align-items: center;
                        "
                      >
                        <img
                          v-if="detail.iconSrc"
                          :src="detail.iconSrc"
                          style="margin: 0 2px"
                          width="20"
                          height="20"
                          alt="..."
                        />
                        <span
                          style="
                            margin: 0 2px;
                            font-size: 12px;
                            font-weight: 400;
                            color: #2b2d32;
                          "
                        >
                          {{ detail.description }}
                        </span>
                        <span v-if="task.details.length - 1 !== indexDetail">
                          |
                        </span>
                      </div>
                    </div>
                  </foreignObject>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
    </card>
  </div>
</template>

<script>
import { ScheduleTravelEnum } from "@/enum/ScheduleTravelEnum";
export default {
  name: "PuzlGantt",
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      required: false,
      validator(items) {
        if (items.length) {
          let invalid = true;
          items.forEach((item) => {
            let keys = Object.keys(item);
            invalid = false;
            if (
              !["title", "iconSrc", "availability"].every((key) =>
                keys.includes(key)
              )
            ) {
              return (invalid = true);
            }
            if (!invalid) {
              if (!item.title || String(item.title).trim() === "") {
                return (invalid = true);
              } else if (item.iconSrc && typeof item.iconSrc !== "string") {
                return (invalid = true);
              } else if (
                item.availability &&
                typeof item.availability !== "object"
              ) {
                return (invalid = true);
              } else if (
                item.availability.length !== 24 ||
                !item.availability.every((current) => {
                  return current.length === 4;
                })
              ) {
                return (invalid = true);
              }
            }
          });
          return !invalid;
        }
        return true;
      },
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
      validator(items) {
        if (items.length) {
          return true;
        }
        return false;
      },
    },
  },
  data() {
    return {
      ScheduleTravelEnum: ScheduleTravelEnum,
      substatusEnum: Object.freeze({
        RELEASED: 0, // Liberada
        CHARGING: 1, // Carregando
        DOSING: 2, // Dosando
        ON_THE_WAY: 3, // A caminho
        WAITING: 4, // Esperando
        DISCHARGING: 5, // Descarregando
        RETURNING: 6, // Retornando
        CLEANING: 7, // Lastro
        STATUS_LEAVING_CONSTRUCTION: 8, // Saindo da obra
      }),
      SVGAdjustmentValue: 10,
      minutesList: ["00", 15, 30, 45],
      hours: Array.from({ length: 24 }, (v, i) =>
        i.toString().padStart(2, "0")
      ),
      isDragging: false,
      startX: 0,
      scrollLeftStart: 0,
      currentScrollRef: null,
    };
  },
  methods: {
    /**
     * @param {string} sourceRef
     * @param {MouseEvent} event
     * @returns {void}
     */
    startDrag(sourceRef, event) {
      const sourceElement = this.$refs[sourceRef];
      this.isDragging = true;
      this.currentScrollRef = sourceRef;
      this.startX = event.pageX;
      this.scrollLeftStart = sourceElement.scrollLeft;
    },
    /**
     * @param {MouseEvent} event
     * @returns {void}
     */
    onDrag(event) {
      if (!this.isDragging || !this.currentScrollRef) return;

      const sourceElement = this.$refs[this.currentScrollRef];
      const deltaX = event.pageX - this.startX;
      sourceElement.scrollLeft = this.scrollLeftStart - deltaX;

      this.syncScroll(this.currentScrollRef);
    },
    /**
     * @returns {void}
     */
    stopDrag() {
      this.isDragging = false;
      this.currentScrollRef = null;
    },
    /**
     * @param {string} sourceRef
     * @returns {void}
     */
    syncScroll(sourceRef) {
      const sourceElement = this.$refs[sourceRef];
      const scrollValue = sourceElement.scrollLeft;

      if (sourceRef !== "scrollTop") {
        this.$refs.scrollTop.scrollLeft = scrollValue;
      }
      if (sourceRef !== "scrollHeader") {
        this.$refs.scrollHeader.scrollLeft = scrollValue;
      }

      Array.from({ length: 1 }, (v, i) => {
        if (sourceRef !== `scrollContent_${i.toString()}`) {
          this.$refs[`scrollContent_${i.toString()}`].scrollLeft = scrollValue;
        }
      });
    },
    closestQuarterHour(minutes) {
      const quarters = [0, 15, 30, 45];
      return quarters.reduce((prev, curr) =>
        Math.abs(curr - minutes) < Math.abs(prev - minutes) ? curr : prev
      );
    },
    /**
     * @param {Object} item
     * @param {string|number} item.id
     * @param {?string} item.uuid
     * @param {function|null} callback
     * @returns {void}
     */
    callbackExec(item, callback = null) {
      if (!callback) {
        return;
      }
      callback(item);
    },
    /**
     * @param {string|number} taskId
     * @param {string|number} itemId
     * @returns {void}
     */
    handleTaskDetail(taskId, itemId) {
      this.$emit("task-detail", { taskId, itemId });
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    handleStatusDetail(id) {
      this.$emit("status-detail", id);
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    handleItemAction(id) {
      this.$emit("item-action", id);
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    handleItemDetail(id) {
      this.$emit("item-detail", id);
    },
    /**
     * @param {string|number} id
     * @returns {void}
     */
    handleItemIndex(id) {
      this.$emit("item-index", id);
    },
    /**
     * Calcula a posição horizontal da linha do tempo (_timer_line) com base na hora atual.
     * A posição é ajustada para o quarto de hora mais próximo (00, 15, 30, 45 minutos).
     * Se os minutos forem superiores a 47, são ajustados para 47.
     * Atualiza a posição da linha e ajusta o scroll se necessário para garantir visibilidade.
     * @returns {void}
     */
    calculateTimerLinePosition() {
      const hourBlockSize = 91;
      const padding = 5;
      let intervalSize = (hourBlockSize - 2 * padding) / 3;

      const now = new Date();
      let hours = now.getHours().toString().padStart(2, "0");
      let minutes = now.getMinutes().toString().padStart(2, "0");

      let targetHour = hours;
      let targetMinutes = minutes;
      if (targetMinutes > 47) {
        targetMinutes = 47;
        minutes = 47;
      }
      let leftPosition = targetHour * hourBlockSize;

      if (![0, 15, 30, 45].includes(targetMinutes)) {
        targetMinutes = this.closestQuarterHour(targetMinutes);
        const ratio = (minutes - targetMinutes) / 15;
        leftPosition += ratio * intervalSize;
      }

      switch (targetMinutes) {
        case 0:
          leftPosition += padding + 7;
          break;
        case 15:
          leftPosition += padding + intervalSize;
          break;
        case 30:
          leftPosition += padding + 2 * intervalSize;
          break;
        case 45:
          leftPosition += padding + 3 * intervalSize;
          break;
        default:
          leftPosition += padding + intervalSize;
          break;
      }
      const timerLines = document.querySelectorAll("._timer_line");
      timerLines.forEach((timerLine) => {
        timerLine.style.left = `${leftPosition}px`;
      });

      const elements = document.querySelectorAll("._timer_line");
      const element = elements[elements.length - 1];

      if (!element) {
        return;
      }

      const scrollPosition = element.offsetLeft;
      if (scrollPosition > 100) {
        this.$refs.scrollHeader.scrollLeft = scrollPosition - 300;
      }
    },
    /**
     * Calcula a posição horizontal da tarefa no diagrama de Gantt com base no horário
     * de início e término.
     * @param {Date} startTime
     * @param {Date} endTime
     * @returns {number}
     */
    calculateTaskPosition(startTime, endTime) {
      let leftStartPosition = null;
      let leftEndPosition = null;
      if (startTime.getDay() !== endTime.getDay()) {
        leftEndPosition = 2170;
      }
      startTime = `${startTime.getHours()}:${startTime.getMinutes()}`;
      endTime = `${endTime.getHours()}:${endTime.getMinutes()}`;
      const hourBlockSize = 91;
      const padding = 5;
      const intervalSize = (hourBlockSize - 2 * padding) / 3;

      let parseTime = (time) => {
        const [hour, minute] = time.split(":").map(Number);
        return { hour, minute };
      };

      let calculatePosition = (targetHour, targetMinutes) => {
        let leftPosition = targetHour * hourBlockSize;

        if (![0, 15, 30, 45].includes(targetMinutes)) {
          targetMinutes = this.closestQuarterHour(targetMinutes);
          const ratio = (targetMinutes - targetMinutes) / 15;
          leftPosition += ratio * intervalSize;
        }

        switch (targetMinutes) {
          case 0:
            leftPosition += padding + 7;
            break;
          case 15:
            leftPosition += padding + intervalSize;
            break;
          case 30:
            leftPosition += padding + 2 * intervalSize;
            break;
          case 45:
            leftPosition += padding + 3 * intervalSize;
            break;
          default:
            leftPosition += padding + intervalSize;
            break;
        }

        return leftPosition - 7;
      };

      const { hour: startHour, minute: startMinute } = parseTime(startTime);
      const { hour: endHour, minute: endMinute } = parseTime(endTime);

      const startPosition =
        leftStartPosition === null
          ? calculatePosition(startHour, startMinute)
          : leftStartPosition;
      const endPosition =
        leftEndPosition === null
          ? calculatePosition(endHour, endMinute)
          : leftEndPosition;

      return { startPosition, endPosition };
    },
    /**
     * @param {string} text
     * @param {?number} quantityMax
     * @returns {string}
     */
    truncateText(text, quantityMax = 40) {
      return text.length > quantityMax
        ? text.slice(0, quantityMax - 3) + "…"
        : text;
    },
    init() {
      this.calculateTimerLinePosition();
    },
    /**
     * Calcula a quantidade total de atividades segundo o status
     */
    getInfo(subStatus) {
      let info = [];
      info[this.substatusEnum.RELEASED] = 0; //
      info[this.substatusEnum.DOSING] = 0;
      info[this.substatusEnum.ON_THE_WAY] = 0;
      info[this.substatusEnum.DISCHARGING] = 0; //
      info[this.substatusEnum.RETURNING] = 0;
      this.items.forEach((item) => {
        if (item.status.situation === ScheduleTravelEnum.IN_PROGRESS) {
          switch (item.status.subSituation) {
            case this.substatusEnum.RELEASED:
              info[this.substatusEnum.RELEASED] += 1;
              break;
            case this.substatusEnum.DOSING:
              info[this.substatusEnum.DOSING] += 1;
              break;
            case this.substatusEnum.ON_THE_WAY:
              info[this.substatusEnum.ON_THE_WAY] += 1;
              break;
            case this.substatusEnum.DISCHARGING:
              info[this.substatusEnum.DISCHARGING] += 1;
              break;
            case this.substatusEnum.RETURNING:
              info[this.substatusEnum.RETURNING] += 1;
              break;
          }
        }
      });
      return info[subStatus];
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped src="./puzlGantt.scss" lang="scss"></style>
