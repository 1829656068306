=
<template>
  <div>
    <AppFixedPageTitle
      title="Créditos e Antecipações"
      icon="/img/icons/icons8/ios/duration-finance.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="true"
          :isLoading="isLoading"
          @onSearchClick="listItems"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="status"
            icon="/img/icons/icons8/ios/progress-indicator_grey.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                Selecionar
              </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.status"
                :items="status"
                :disableBoxShadow="true"
                placeholder="Status"
                :multiple="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="row pr-3 mt-1 pl-3">
              <div class="col-12 px-0 mb-2 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Selecionar
                </label>
                <puzl-select
                  style="width: 100%"
                  v-model.lazy="filter.bank_account_id"
                  :items="$_bank_accounts"
                  :disableBoxShadow="true"
                  placeholder="Contas Bancárias"
                  :multiple="true"
                  class="select-sm col-md-12 px-0 new-default-black-font"
                  :loading="loadingBankAccounts"
                  :disabled="loadingBankAccounts"/>
              </div>
              <div class="col-12 px-0 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Valor da Parcela
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-money="money"
                        v-model.lazy="filter.range_min_value"
                        placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-money="money"
                        v-model.lazy="filter.range_max_value"
                        placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-1 px-0 text-left">
                <label
                  class="form-control-label fs-11 new-default-gray-font font-weight-400"
                >
                  Valor da Venda
                </label>
                <div class="row">
                  <div class="col-6 text-left pr-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-money="money"
                        v-model.lazy="filter.range_min_total_value"
                        placeholder="de"
                      />
                    </div>
                  </div>
                  <div class="col-6 pl-1">
                    <div class="input-custom-group">
                      <div>R$</div>
                      <input inputmode="numeric"
                        v-money="money"
                        v-model.lazy="filter.range_max_total_value"
                        placeholder="até"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection
            name="Marcadores"
            icon="/img/icons/icons8/ios/push-pin_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <button
                @click="filter.pending_identification = Number(!filter.pending_identification)"
                class="col-12 d-flex align-items-center new-default-gray-font"
                style="border: 1px solid #E8E8E8; font-weight: 400 !important; background: white; min-height: 31px !important;"
                :class="{'active-hoverable': filter.pending_identification}"
              >
                <img
                  height="19"
                  src="/img/icons/icons8/ios/no-hidden-fee_warning.png"
                  style="margin-right: 4px;"
                >
                  DEPÓSITO NÃO IDENTIFICADO
              </button>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            text="novo"
            type="success"
            :isDropdown="true"
            icon="/img/icons/plus-math--v1-white.png"
          >
            <AppPageHeaderActionsButtonDropItem
              text="Crédito e antecipação"
              icon="/img/icons/icons8/ios/us-dollar-circled--v1_primary.png"
              @click="handleCreateModalEntity"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Depósito não identific."
              icon="/img/icons/no-hidden-fee-yellow.png"
              @click="handleCreateModalEntity(false)"
            />
          </AppPageHeaderActionsButton>
          <AppPageHeaderActionsButton
            text="Relatórios"
            type="dark"
            :isDropdown="true"
            icon="/img/icons/graph-report.png"
          >
            <AppPageHeaderActionsButtonDropItem
              text="Resumo Analítico"
              icon="/img/icons/excel.png"
              @click.prevent="downloadEntityBillReceiveExcel()"
            />
            <AppPageHeaderActionsButtonDropItem
              text="Saldos do cliente"
              icon="/img/icons/wallet.png"
              @click="$router.push({
                path: '/financial/bill-receive/antecipation-balances',
                name: 'financial.bill-receive-antecipation-balance.index.view',
              })"
            />
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="handleViewTypeChange"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
    <!-- Listagens -->
    <!-- <ListBillReceive :added="added" /> -->
    <ListingManager
      :loadingSkeleton="loadingSkeleton"
      :bill_receives="bill_receives"
      :listType="listType"
      @listItems="listItems"
    />
    <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)"/>

    <!-- Modais de EntityIndex -->
    <CreateBillReceive @added="addedBillReceive" ref="modalCreate" />
    <ModalBankBilletRemittance ref="ModalBankBilletRemittance" :show="showModaModalBankBilletRemittance"
      @close="showModaModalBankBilletRemittance = false" />
    <ModalBankBilletDischarge ref="ModalBankBilletDischarge" :show="showModaModalBankBilletDischarge"
      @close="showModaModalBankBilletDischarge = false" />
    <ModalBillReceiveReport ref="modalBillReceiveReport" />
    <ModalSearchEntity @selected="setEntity" ref="modalSearchEntity" />
    <modal-create-entity @added="addedBillReceive" ref="modalCreateEntity" />
    <modal-report ref="modalReport" />
  </div>
</template>

<script>
import {
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppViewTrigger,
  AppSelect,
  AppTabSelect,
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  TabSelectItemType,
} from "../../../../../components/AppGlobal";
import {mapGetters} from "vuex";
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import ListingManager from "./Shared/Lists/_ListingManager.vue";
// import ListBillReceive from "./Shared/Lists/_ListEntityBillReceive.vue";
import CreateBillReceive from "../Shared/Modals/_Create";
import ModalBankBilletRemittance from "../Shared/Modals/_ModalBankBilletRemittance";
import ModalBankBilletDischarge from "../Shared/Modals/_ModalBankBilletDischarge";
import ModalBillReceiveReport from "../Shared/Modals/_ModalBillReceiveReport";
import ModalSearchEntity from "../../../Configuration/Entity/Shared/_ModalSearchEntity";
import ModalCreateEntity from './Shared/Modals/_ModalCreateEntity';
import ModalReport from '../Shared/Modals/_ModalReport';
import { LoansAndFinancialAdvancesFilterType, initLoansAndFinancialAdvancesListFilterType } from "./types";
import cursorPaginate from "@/mixins/cursorPaginate";
import {base_url_ms, debounce} from '@/plugins';
import { date } from "../../../../../helpers"
import PuzlSelect from "@/components/PuzlSelect";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import {BillReceiveInstallmentEnum} from "@/enum/BillReceiveInstallmentEnum";
import {VMoney} from "v-money";
import AppPageHeaderActionsButtonSection from "../../../../../components/AppGlobal/AppPageHeader/AppPageHeaderActionsButtonSectionDivider.vue";

export default {
  mixins: [cursorPaginate],
  name: "IndexBillReceive",
  components: {
    ModalSearchEntity,
    PuzlBreadcrumb,
    // ListBillReceive,
    ListingManager,
    CreateBillReceive,
    ModalBankBilletRemittance,
    ModalBankBilletDischarge,
    ModalBillReceiveReport,
    ModalCreateEntity,
    ModalReport,
    AppSelect,
    AppTabSelect,
    AppViewTrigger,
    AppSearchBar,
    AppSearchBarFilterSection,
    initSearchBarFilterType,
    SearchBarFilterType,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppPageHeaderActionsButtonDropItem,
    PuzlSelect,
    BaseButtonHoverable,
  },
  directives: {
    money: VMoney,
  },
  computed: {
    ...mapGetters({
      bill_receives: "billReceive/fetch",
      bill_receive_paginate: "billReceive/paginate",
      $_bank_accounts: "bankAccount/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.status.length === 0,
        },
        {
          id: this.BillReceiveInstallmentEnum.RECEIVED,
          name: 'Recebidos',
          selected: this.filter.status.includes(1),
        },
        {
          id: this.BillReceiveInstallmentEnum.APPROVED,
          name: 'Aprovados',
          selected: this.filter.status.includes(8),
        },
        {
          id: this.BillReceiveInstallmentEnum.PENDING,
          name: 'Pendentes',
          selected: this.filter.status.includes(0),
        },
        {
          id: this.BillReceiveInstallmentEnum.CANCELED,
          name: 'Cancelados',
          selected: this.filter.status.includes(3),
        },
      ];
    },
  },
  data() {
    return {
      added: false,
      showModaModalBankBilletRemittance: false,
      showModaModalBankBilletDischarge: false,
      searchBarFilter: initSearchBarFilterType(),
      currentDate: date.make().format(date.FORMAT.ISO_8601),
      filter: initLoansAndFinancialAdvancesListFilterType(),
      BillReceiveInstallmentEnum: BillReceiveInstallmentEnum,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      listType: 'cards',
      endOfPages: false,
      isLoading: true,
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [],
          },
          // {
          //   id: 1,
          //   name: "A-Z",
          //   selected_name: "A-Z",
          //   icon: "/img/icons/icons8/ios/double-down.png",
          //   filter: [{
          //     column: "entity_name",
          //     is_desc: false,
          //   }],
          // },
          // {
          //   id: 2,
          //   name: "Z-A",
          //   selected_name: "Z-A",
          //   icon: "/img/icons/icons8/ios/double-up.png",
          //   filter: [{
          //     column: "entity_name",
          //     is_desc: true,
          //   }],
          // },
          {
            id: 1,
            name: "VALOR MAIOR PARA O MENOR",
            selected_name: "MAIOR > MENOR",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "total_value",
              is_desc: true,
            }],
          },
          {
            id: 2,
            name: "VALOR MENOR PARA O MAIOR",
            selected_name: "MENOR > MAIOR",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "total_value",
              is_desc: false,
            }],
          },
          {
            id: 3,
            name: "LANÇAMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "LANÇ. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "created_at",
              is_desc: true,
            }],
          },
          {
            id: 4,
            name: "LANÇAMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "LANÇ. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "created_at",
              is_desc: false,
            }],
          },
          {
            id: 5,
            name: "VENCIMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "VENC. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "due_date",
              is_desc: true,
            }],
          },
          {
            id: 6,
            name: "VENCIMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "VENC. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "due_date",
              is_desc: false,
            }],
          },
        ]
      },

      base_url_ms: base_url_ms(),
      loadingSkeleton: false,
      loadingInstallmentStatus: false,
      hasMore: null,
      status: [
        {id: 0, name: 'Pendente'},
        {id: 1, name: 'Recebido'},
        {id: 2, name: 'Atrasado'},
        {id: 3, name: 'Cancelado'},
        {id: 8, name: 'Aprovado'},
      ],
      send_to_email: false,
      bill_receive_to_identify: null,
      loadingSkeletonTable: false,
      loadingBankAccounts: true,
      send_emails: [],
      scrolledCurrent: false,
      loadingSpinner: false,
      loadingDetails: false,
      showModalAlterStatus: false,
    };
  },
  methods: {
    fetchNextPageData() {
      this.scrolledCurrent = true;
      let params = {
        paginate: this.paginate
      };
      this.$store
        .dispatch("billReceive/fetchNextPage", params)
        .then(response => {
          this.hasMore = response.has_more;
          this.loadingSkeleton = false;
          this.scrolledCurrent = false;
          this.loadingSpinner = false;
        });
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      const isAlreadyFiltered = this.filter.status.length === 1 && this.filter.status.includes(item.id);
      if (isAlreadyFiltered) {
        return;
      };
      this.filter.status = item.id === null ? [] : [item.id];
      this.listItems();
    },
    handleViewTypeChange(type) {
      this.listType = type;
    },
    handleCreateModal() {
      this.$refs.modalCreate.handleCreateModal();
    },
    handleCreateModalEntity(has_identification = true) {
      if (has_identification) {
        return this.$refs.modalSearchEntity.openModal()
      }
      this.$refs.modalCreateEntity.handleCreateModal(null)
    },
    setEntity(entity) {
      this.$refs.modalCreateEntity.handleCreateModal(entity)
    },
    addedBillReceive() {
      this.added = !this.added;
      this.listItems(false);
    },
    handleShowModalReport() {
      this.$refs.modalReport.openModal()
    },
    showModalBillReceiveExcelReport() {
      this.$refs.modalBillReceiveReport.openModal();
    },
    /**
     * Listar itens
     * @param {boolean} isAccumulateItems
     */
    listItems(isAccumulateItems = false) {
      this.isLoading = true;
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      this.prepareFilter();
      this.$store
        .dispatch('billReceive/fetch',
          {
            filter: this.filter,
            next_page: this.filter.next_page
          },
        )
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false
        });
    },
    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      this.filter.company_plant_id = this.searchBarFilter.company_plant_selected;
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.filter.range.start = this.searchBarFilter.range.start;
      this.filter.range.end = this.searchBarFilter.range.end;
      this.filter.range_min_total_value = this.formatNumber(this.filter.range_min_total_value);
      this.filter.range_max_total_value = this.formatNumber(this.filter.range_max_total_value);
      this.filter.range_min_value = this.formatNumber(this.filter.range_min_value);
      this.filter.range_max_value = this.formatNumber(this.filter.range_max_value);

      const selectedItem = this.orderBy.items[this.orderBy.selected];
      const selectedFilter = selectedItem.filter.length ? selectedItem.filter[0] : null;

      this.filter.order_by = selectedFilter
        ? `${selectedFilter.column}.${selectedFilter.is_desc ? 'desc' : 'asc'}`
        : "";

      const dateTypes = [null ,"due_date", "original_due_date", "paid_at", "entity_bill_receive_competence"];


      this.filter.date_type = dateTypes[this.searchBarFilter.range.selected];
    },
    formatNumber(n){
      if(n === '0,00' || !n){
        return null;
      }

      let formattedNumber = n.replace('.', '');
      formattedNumber = formattedNumber.replace(',', '.');

      return formattedNumber;
    },
    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());
      if (isRefreshList) {
        this.listItems();
      }
    },
    /**
     * Padrão do filtro principal
     * @returns {LoansAndFinancialAdvancesFilterType}
     */
    defaultFilter() {
      const defaultFilter = {
        ...initLoansAndFinancialAdvancesListFilterType(),
        range: {
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      };

      return defaultFilter;
    },
    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [
            { id: 0, name: "Padrão", selected_name: "Padrão" },
            { id: 1, name: "Vencimento", selected_name: "Venc." },
            { id: 2, name: "Vencimento Original", selected_name: "Orig." },
            { id: 3, name: "Baixa", selected_name: "Baixa" },
            { id: 4, name: "Venda", selected_name: "Venda" }
          ],
          selected: 0,
          start: this.filter.range.start ?? this.currentDate,
          end: this.filter.range.end ?? this.currentDate,
        },
      }
    },
    downloadEntityBillReceiveExcel() {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
      let loader = this.$loading.show();
      this.prepareFilter();
      this.$store
        .dispatch("billReceive/getEntityBillReceiveReportExcel", { filter: this.filter })
        .then(async (response) => {
          let blob = new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "Créditos e antecipações.xlsx");
          await link.click();
          this.$notify({
            type: "success",
            message: "Solicitação realizada com sucesso!",
          });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: "Erro ao realizar o download do arquivo excel!",
          });
        }).finally(() => {
          loader.hide();
        });
    },
  },
  mounted() {
    this.loadingBankAccounts = true;
    this.$store.dispatch("bankAccount/fetchItems", {filter:{status:true}}).then((response) => {
      this.loadingBankAccounts = false;
    });
    this.loadingSkeleton = true
    this.clearFilter()
  },
};
</script>

<style scoped>
.imgBaseButton {
  margin-bottom: 2px;
}

.new-default-gray-font {
  color: #606062 !important;
}

.input-custom-group {
	display: flex;
	align-items: center;
	border-radius: 8px;
  border: 1px solid #E8E8E8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
	box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-left: 1px solid #e96262;
  border-left-color: #E8E8E8;
}

.input-custom-group div {
	padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
	display: inline-flex;
	height: 31px;
	background: white;
	align-items: center;
	font-size: 12px;
}

.input-custom-group div {
	color: #606062;
}

.input-custom-group input {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
	outline: none;
}

.active-hoverable {
  background: #ffc107 !important;
  color: white !important;
}

.active-hoverable img {
  filter: brightness(0) invert(1);
}
</style>
