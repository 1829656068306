import store from "../../../../shared/libraries/store";
import { initCompanyPlantIssuerListPagerType, CompanyPlantIssuerListPagerType, CompanyPlantIssuerType } from "../types";
import { findSource, listSources } from "@/shared/store";

/** URLS */
const urls = Object.freeze({
  BASE: "/company/plant/company-plant-issuers/",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "COMPANY_PLANT_ISSUER_ITEM",
  LISTED: "COMPANY_PLANT_ISSUER_LISTED",
  IS_LISTING: "COMPANY_PLANT_ISSUER_IS_LISTING",
  IS_FINDING: "COMPANY_PLANT_ISSUER_IS_FINDING",
});

/** GETTERS */
const getters = {
  /** @returns {?CompanyPlantIssuerType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {CompanyPlantIssuerListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initCompanyPlantIssuerListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {CompanyPlantIssuerType} item
   * @returns {CompanyPlantIssuerType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {CompanyPlantIssuerListPagerType} listed
   * @returns {CompanyPlantIssuerListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),
}

/** ACTIONS */
const actions = {

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?CompanyPlantIssuerType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {Number} company_plant_id - ID da central para busca das contas bancárias
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?CompanyPlantIssuerListPagerType>} - Retorno paginado
   */
  list: async (company_plant_id, isAccumulateItems = false) => {
    await listSources({}, isAccumulateItems, mutations, getters, `${urls.BASE}${company_plant_id}`);
  },
}

export default {
  actions,
  getters,
  mutations,
};
