<template>
  <div ref="popover"
    class="custom-popover-container">
    <div @click.prevent="isShowing = !isShowing"
      style="display: contents;"
      class="pointer">
      <slot name="reference"></slot>
    </div>
    <div class="custom-popover"
      :style="attributes()"
      :class="{'show': isShowing}">
      <div class="custom-popover-title">
        <h5 class="m-0 new-default-black-font">{{ props.title }}</h5>
      </div>
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { AppIcon } from "@/components/AppGlobal";

const isShowing = ref(false);
const popover = ref(null);

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  width: {
    type: [String, Number],
    default: 180
  },
  top: {
    type: [String, Number],
    default: null
  },
  left: {
    type: [String, Number],
    default: null
  },
  bottom: {
    type: [String, Number],
    default: null
  },
  right: {
    type: [String, Number],
    default: null
  }
});

function attributes() {
  return {
    width: `${props.width}px`,
    top: props.top,
    left: props.left,
    bottom: props.bottom,
    right: props.right,
  };
}

const handleClickOutside = (event) => {
  if (popover.value && !popover.value.contains(event.target)) {
    isShowing.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>
<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.custom-popover-container {
  width: fit-content;
  display: inline-flex;
  position: relative;

  .custom-popover {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: -6px;
    top: 18px;
    background: white;
    z-index: 1;
    text-align: left;
    border-radius: 4px;
    overflow: hidden;
    transform: scale(0.95);
    transition: max-height 0s ease-in-out,
    opacity 0.2s ease-in-out,
    visibility 0.2s,
    transform 0.2s ease-out;

    &.show {
      max-height: 700px;
      opacity: 1;
      visibility: visible;
      border: 1px solid #f4f4f4;
      animation: popIn 0.2s ease-out;
    }

    .custom-popover-title {
      border-bottom: 1px solid $muted-light;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 10px;

      h5, h6 {
        padding-left: 10px;
        font-weight: 400 !important;
      }
    }

    @keyframes popIn {
      0% {
        transform: scale(0.95);
        opacity: 0;
      }
      60% {
        transform: scale(1.05);
        opacity: 1;
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
</style>
