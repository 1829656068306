<template>
  <div>
    <AppFixedPageTitle title="Recebimentos" icon="/img/icons/icons8/ios/svgs/card-payment.svg" />
    <div class="container-fluid pt-4">
      <!-- Header Menus -->
      <div v-if="loadingSkeleton">
        <div v-for="(item, index) in 3" :key="index">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button" />
          </div>
        </div>
      </div>
      <template v-else>
        <ContractProposalPageHeader
          :contractInfo="contract_proposal"
          v-if="$refs.list && $refs.list.type === listingType['Recebimentos']"
          class="checklist-info"
        >
          <AppPageHeaderActions>
            <AppPageHeaderActionsButton
              type="success"
              @click.prevent="handleCreateEntity()"
              text="Novo"
              icon="/img/icons/icons8/ios/svgs/plus-math.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              :disabled="Object.values(contract_proposal).length == 0"
              type="primary"
              @click="handleEntityHistory(contract_proposal.entity.id)"
              text="Saldo"
              icon="/img/icons/icons8/ios/svgs/wallet.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
          </AppPageHeaderActions>
        </ContractProposalPageHeader>
        <ContractProposalPageHeader v-else :contractInfo="contract_proposal" showCpfNumber>
          <AppPageHeaderActions>
            <AppPageHeaderActionsButton
              type="success"
              @click.prevent="handleCreateEntity()"
              text="Novo"
              icon="/img/icons/icons8/ios/svgs/plus-math.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
            <AppPageHeaderActionsButton
              :disabled="Object.values(contract_proposal).length == 0"
              type="primary"
              @click="handleEntityHistory(contract_proposal.entity.id)"
              text="Saldo"
              icon="/img/icons/icons8/ios/svgs/wallet.svg"
              class="invert-color"
            >
            </AppPageHeaderActionsButton>
          </AppPageHeaderActions>
        </ContractProposalPageHeader>
      </template>
      <div :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          v-if="contract_proposal"
          :contractUuid="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :entity="contract_proposal.entity"
          :activePayments="true"
          style="margin-top: 12px"
        />
      </div>
    </div>

    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar  
          :searchBarFilter.sync="searchBarFilter" 
          :isLoading="isLoading" 
          @onSearchClick="listItems" 
          @onClearClick="clearFilter"  
          :rangeParams="{
            is_range: true,
            bottom_filter: BOTTOM_FILTER_ENUM.START_TODAY_MONTH,
            only_filter_month: false,
          }"
        >
          <AppSearchBarFilterSection name="Dados" icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="col-12 px-0 mb-2 mt-1 text-left">
              <label class="form-control-label new-default-gray-font font-weight-400"> Contrato </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.contract_proposal_id"
                :items="$_contractProposals"
                label="$code$ | $construction_name$"
                labelMask
                :disableBoxShadow="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-12 px-0 mb-2 mt-1 text-left">
              <label class="form-control-label new-default-gray-font font-weight-400"> Método de pagamento </label>
              <PuzlSelect
                style="width: 100%"
                v-model.lazy="filter.payment_method_id"
                :items="$_paymentMethods"
                :disableBoxShadow="true"
                class="select-sm col-md-12 px-0 new-default-black-font"
              />
            </div>
            <div class="col-12 px-0 mb-2 mt-1 text-left">
              <label class="form-control-label new-default-gray-font font-weight-400"> Valor do pagamento </label>
              <div class="row">
                <div class="col-6 text-left pr-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric" v-money="money" v-model.lazy="filter.range_value.min" placeholder="de" />
                  </div>
                </div>
                <div class="col-6 pl-1">
                  <div class="input-custom-group">
                    <div>R$</div>
                    <input inputmode="numeric" v-money="money" v-model.lazy="filter.range_value.max" placeholder="até" />
                  </div>
                </div>
              </div>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
    </AppPageHeader>

    <AppTabSelect :isShowViewTypes="false" @onTabSelectItemClick="onTabSelectItemClick" class="mt-4" :items="tabSelectItems" />

    <ListPayment :isLoading="loadingSkeletonCards" @init="listItems" ref="list" />
    <AppViewTrigger v-if="!isLoading" @onIntersected="listItems(true)" />
    <CreatePayment ref="createPayment" />
    <ModalGeneralResume ref="modalGeneralResume" />
    <ModalListContacts ref="listContacts" />
    <modal-create-entity @added="listItems(false)" ref="modalCreateEntity" />
    <ModalEntityHistory ref="modalEntityHistory" />
  </div>
</template>

<script>
import ListPayment from "./Shared/_List";
import CreatePayment from "./Shared/_Create";
import { mapGetters } from "vuex";
import BackButton from "../../../../../components/Utils/BackButton";
import MultiFilter from "../../../../../components/Utils/MultiFilterV2";
import ModalGeneralResume from "../Shared/_ModalGeneralResume.vue";
import ModalListContacts from "../../../Operational/Schedule/Weighing/Shared/_ModalListContacts";
import ModalCreateEntity from "../../../Financial/BillReceive/Entity/Shared/Modals/_ModalCreateEntity";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import ContractProposalPageHeader from "../components/ContractProposalPageHeader/ContractProposalPageHeader.vue";
import {
  AppFixedPageTitle,
  AppPageHeaderActions,
  AppIcon,
  AppPageHeaderActionsButton,
  AppSelect,
  AppTabSelect,
  AppViewTrigger,
  AppSearchBar,
  AppPageHeader,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  SearchBarFilterType,
  BOTTOM_FILTER_ENUM,
} from "../../../../../components/AppGlobal";
import cursorPaginate from "@/mixins/cursorPaginate";
import { BillReceiveGetEntitiesFilterType, initBillReceiveGetEntitiesFilterType } from "../../../../../modules/financial/bill-receive/types";
import { BillReceiveGetEntitiesRangeTypeEnum } from "../../../../../modules/financial/bill-receive/enums/BillReceiveGetEntitiesRangeTypeEnum";
import { BillReceiveGetEntitiesTabSelectItemEnum } from "../../../../../modules/financial/bill-receive/enums/BillReceiveGetEntitiesTabSelectItemEnum";
import { date, moneyToFloat } from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import { VMoney } from "v-money";

export default {
  mixins: [cursorPaginate],
  components: {
    MultiFilter,
    BackButton,
    ListPayment,
    CreatePayment,
    ModalGeneralResume,
    ModalListContacts,
    ModalCreateEntity,
    ModalEntityHistory,
    SkeletonPuzl,
    ContractProposalNavigation,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
    AppSelect,
    AppTabSelect,
    AppViewTrigger,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppPageHeader,
    PuzlSelect,
  },
  computed: {
    ...mapGetters({
      contract_proposal: "contractProposal/show",
      $_contractProposals: "contractProposal/fetch",
      $_paymentMethods: "paymentMethod/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: BillReceiveGetEntitiesTabSelectItemEnum.keys.ALL,
          name: "Todos",
          selected: this.filter.status === BillReceiveGetEntitiesTabSelectItemEnum.keys.ALL,
        },
        {
          id: BillReceiveGetEntitiesTabSelectItemEnum.keys.NEEDS_REVIEW,
          name: "Revisão",
          selected: this.filter.status === BillReceiveGetEntitiesTabSelectItemEnum.keys.NEEDS_REVIEW,
        },
        {
          id: BillReceiveGetEntitiesTabSelectItemEnum.keys.CANCELED,
          name: "Cancelado",
          selected: this.filter.status === BillReceiveGetEntitiesTabSelectItemEnum.keys.CANCELED,
        },
      ];
    },
  },
  directives: {
    money: VMoney,
  },
  data() {
    return {
      filter: initBillReceiveGetEntitiesFilterType(),
      listingType: {
        Antecipação: 0,
        Recebimentos: 1,
      },
      loadingSkeleton: true,
      isLoading: true,
      loadingSkeletonCards : true,
      searchBarFilter: initSearchBarFilterType(),
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      BOTTOM_FILTER_ENUM: BOTTOM_FILTER_ENUM,
    };
  },
  methods: {
    handleCreateEntity() {
      this.$refs.modalCreateEntity.handleCreateModal(this.contract_proposal.entity, this.contract_proposal.company_plant_id, null, this.contract_proposal.id);
    },
    handleFilterAdvancedStatus(key) {
      this.filter.advanced = this.filter.advanced === key ? null : key;
      this.init(this.filter);
    },
    handleCreate(default_value = false) {
      this.$refs.createPayment.handleCreateModal(this.contract_proposal.plant, default_value);
    },
    handleGeneralResume() {
      this.$refs.modalGeneralResume.openModal(this.contract_proposal.id);
    },
    handleListContacts() {
      this.$refs.listContacts.openModal(this.contract_proposal.construction.uuid);
    },
    handleEntityHistory(entity_id) {
      this.$refs.modalEntityHistory.openModal(entity_id);
    },
    getContractProposal() {
      this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {});
    },

    listItems(isAccumulateItems = false) {
      this.isLoading = true;

      if (!this.startCursor(this.filter, isAccumulateItems)) {
        this.isLoading = false;
        return;
      }

      this.filter.entity_id = this.contract_proposal.entity.id;
      this.$store
        .dispatch("billReceive/getEntities", this.prepareFilter())
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.isLoading = false;
          this.loadingSkeleton = false;
          this.loadingSkeletonCards = false;
        });
    },

    /* Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Indica se deve listar os itens
     */
    clearFilter(isRefreshList = true) {
      this.loadingSkeletonCards = true;
      Object.assign(this.searchBarFilter, this.defaultSearchBarFilter());
      Object.assign(this.filter, this.defaultFilter());

      if (isRefreshList) {
        this.listItems();
      }
    },

    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
        range: {
          items: [
            { id: BillReceiveGetEntitiesRangeTypeEnum.keys.CREATED_AT, name: "Criação", selected_name: "Cria." },
            { id: BillReceiveGetEntitiesRangeTypeEnum.keys.SALE, name: "Venda", selected_name: "Vend." },
          ],
          selected: BillReceiveGetEntitiesRangeTypeEnum.keys.CREATED_AT,
          start: this.searchBarFilter.range.start ?? date.make().setStartOf(date.UNIT_OF_TIME.MONTH).format(date.FORMAT.ISO_8601),
          end: this.searchBarFilter.range.end ?? date.make().setEndOf(date.UNIT_OF_TIME.MONTH).format(date.FORMAT.ISO_8601),
        },
        custom_search_values:false,
        status: BillReceiveGetEntitiesTabSelectItemEnum.keys.ALL,
      };
    },

    /**
     * Padrão do filtro principal
     * @returns {BillReceiveGetEntitiesFilterType}
     */
    defaultFilter() {
      return {
        ...initBillReceiveGetEntitiesFilterType(),
      };
    },

    /**
     * Preparar filtro antes da listagem
     */
    prepareFilter() {
      const filterCopy = this.$helper.cloneObject(this.filter);
      filterCopy.range_value = {
        min: null,
        max: null,
      };
      filterCopy.range_type = this.searchBarFilter.range.selected;
      // Se o filtro de data for desabilitado, seta a data para o ano 2022
      filterCopy.range.start = this.searchBarFilter.range.default_filterable === false ? null :this.searchBarFilter.range.start;
      filterCopy.range.end = this.searchBarFilter.range.end;
      if (this.filter.range_value && this.filter.range_value.min && this.filter.range_value.min !== "0,00") {
        filterCopy.range_value.min = moneyToFloat(this.filter.range_value.min);
      }
      if (this.filter.range_value && this.filter.range_value.max && this.filter.range_value.max !== "0,00") {
        filterCopy.range_value.max = moneyToFloat(this.filter.range_value.max);
      }
      return filterCopy;
    },

    onTabSelectItemClick(item) {
      if (item.id === this.filter.status) {
        return;
      }
      this.filter.status = item.id;
      this.listItems();
    },
  },
  mounted() {
    this.loadingSkeleton = true;
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {
      this.loadingSkeleton = false;
      this.clearFilter(true);
      this.$store.dispatch("contractProposalCredit/getBalance", this.contract_proposal.id).then((response) => {
        this.contract_proposal.balance = response.data;
      });
      this.$store.dispatch("contractProposal/getAllByEntity", this.contract_proposal.entity.id);
    });
    this.$store.dispatch("paymentMethod/fetchItems");
  },
};
</script>

<style>
.custom-pending-card .card-body {
  padding: 0.6rem !important;
}
.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}
.rounded-circle {
  border: 1px solid #d3d3d3;
}
.background-color-circle {
  background-color: #f3f4fa;
}
.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: #d3d3d3;
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}
.base-button:hover .text-dark {
  color: white !important;
}
</style>

<style scoped>
.colorize-btn-img:hover img,
.show img {
  filter: brightness(0) invert(1);
}
.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  height: 30px;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
}

.input-custom-group:has(> div) input {
  border-left: 1px solid #e96262;
  border-left-color: #e8e8e8;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input,
.input-custom-group div {
  display: inline-flex;
  height: 31px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div {
  color: #606062;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
  outline: none;
}
</style>
