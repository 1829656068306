<template>
  <base-dropdown
    v-if="props.item"
    :style="'width: ' + props.minWidth + 'px'"
    menuOnRight
    class="bank-reconciliation-settings-options">
    <div
      slot="title-container"
      class="app-badge dropdown-arrow"
      :style="'min-width: ' + props.minWidth + 'px'"
      :class="{
        [badgeComponentStatusMap[props.item.status].color]: true,
      }"
      v-bind="$attrs"
      v-on="$listeners">
      <AppIcon
        :icon="badgeComponentStatusMap[props.item.status].icon"
        width="18"
        :color="badgeComponentStatusMap[props.item.status].iconColor"
        class="mr-1"
      />
      <h5>
        {{ badgeComponentStatusMap[props.item.status].text }}
      </h5>
    </div>
    <a class="dropdown-item"
      v-if="props.item.status === BankReconciliationEnum.PENDING"
      @click="emit('eventHandler', 'changeStatus', props.item)">
      <AppIcon
        :icon="badgeComponentStatusMap[BankReconciliationEnum.FINISHED].icon"
        :color="badgeComponentStatusMap[BankReconciliationEnum.FINISHED].iconColor"
        width="20"
      />
      {{ badgeComponentStatusMap[BankReconciliationEnum.FINISHED].text }}
    </a>
    <a class="dropdown-item"
       v-if="props.item.status === BankReconciliationEnum.FINISHED"
       @click="emit('eventHandler', 'changeStatus', props.item)">
      <AppIcon
        :icon="badgeComponentStatusMap[BankReconciliationEnum.PENDING].icon"
        :color="badgeComponentStatusMap[BankReconciliationEnum.PENDING].iconColor"
        width="20"
      />
      {{ badgeComponentStatusMap[BankReconciliationEnum.PENDING].text }}
    </a>
  </base-dropdown>
</template>

<script setup>
import {AppIcon} from "@/components/AppGlobal";
import {ScheduleTravelEnum} from "@/enum/ScheduleTravelEnum";
import {BankReconciliationEnum} from "@/enum/BankReconciliationEnum";
import FinancialApprovalStatusEnum from "@/views/Modules/Financial/CreditRelease/Enums/FinancialApprovalStatusEnum";

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  minWidth: {
    type: [Number,String],
    default: 164,
  }
});

const badgeComponentStatusMap = {
  [BankReconciliationEnum.PENDING]: {
    icon: "hourglass",
    color: "warning",
    text: "Pendente",
    iconColor: "warning",
  },
  [BankReconciliationEnum.FINISHED]: {
    icon: "double-tick",
    color: "primary",
    text: "Concluído",
    iconColor: "primary",
  },
};

/** Eventos emitidos */
const emit = defineEmits([
  "eventHandler",
]);
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

.dropdown-arrow {
  position: relative;

  &::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    margin-left: 12px;
    margin-top: -6px;
    border-left: 1px solid gray; // Cor padrão
    border-bottom: 1px solid gray; // Cor padrão
  }

  &.warning::after {
    border-left: 1px solid $warning;
    border-bottom: 1px solid $warning;
  }

  &.primary::after {
    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }
}

.dropdown-item {
  display: flex;
  padding: 10px !important;

  .app-icon {
    margin-right: 10px;
  }
}

.app-badge {
  margin-right: 0.5rem !important;
  padding: 4px;
  border-radius: 50px;
  min-height: 24px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;

  h5 {
    font-size: 12px;
    margin: 0 0 0 5px;
    height: 20px;
  }

  &.success {
    background-color: $success-lighter;
    border: 1px solid $success-lighter;
    h5 {
      color: $success;
    }
  }
  &.warning {
    background-color: $warning-lighter;
    border: 1px solid $warning-lighter;
    h5 {
      color: $warning;
    }
  }
  &.primary {
    background-color: $primary-lighter;
    border: 1px solid $primary-lighter;
    h5 {
      color: $primary;
    }
  }
}
</style>
<style lang="scss">
.bank-reconciliation-settings-options {
  .dropdown-menu-right {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
}
</style>
