<template>
  <div style="min-height: 800px;">
    <AppFixedPageTitle title="Ordem de compra" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :showCompanyPlants="true"
          :isLoading="$_purchase_order_is_listing" @onSearchClick="listItems" @onClearClick="clearFilter">
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton @click.prevent="$router.push('/purchase/request/create')" text="novo"
            type="success" icon="/img/icons/plus-math--v1-white.png">
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick">
    </AppTabSelect>
    <div class="container-fluid">
      <!-- CARDS -->
      <PurchaseOrderListPageCards class="mt-4" @fetch="listItems" />
      <AppViewTrigger v-if="!$_purchase_order_is_listing" @onIntersected="listItems(true)" />
      <div class="d-flex" v-if="$_purchase_order_is_listing">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData v-if="!$_purchase_order_is_listing && $_purchase_order_listed.items.length <= 0" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  TabSelectItemType,
  AppViewTrigger,
  AppTabSelect,
  initSearchBarFilterType,
} from '../../../../../../../components/AppGlobal';
import purchaseOrderStore from "../../store/purchaseOrderStore";
import PurchaseOrderListPageCards from './PurchaseOrderListPageCards.vue';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PurchaseOrderStatusEnum from "../../enums/PurchaseOrderStatusEnum";
import { initPurchaseOrderListFilterType } from "../../types";
import { date } from "../../../.../../../../../../helpers";
//#endregion


//#region Data
/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());
/** @type {PurchaseOrderListFilterType} - Filtro principal */
const filter = reactive(initPurchaseOrderListFilterType());
const currentDate = date.make().format(date.FORMAT.ISO_8601);
//#endregion

//#region Computeds
/** Store Getters */
const $_purchase_order_listed = computed(() => purchaseOrderStore.getters.getListed());
const $_purchase_order_is_listing = computed(() => purchaseOrderStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: PurchaseOrderStatusEnum.keys.PENDING,
      name: PurchaseOrderStatusEnum.getTitle(PurchaseOrderStatusEnum.keys.PENDING),
      selected: filter.status === PurchaseOrderStatusEnum.keys.PENDING,
    },
    {
      id: PurchaseOrderStatusEnum.keys.OPEN,
      name: PurchaseOrderStatusEnum.getTitle(PurchaseOrderStatusEnum.keys.OPEN),
      selected: filter.status === PurchaseOrderStatusEnum.keys.OPEN,
    },
    {
      id: PurchaseOrderStatusEnum.keys.COMPLETED,
      name: PurchaseOrderStatusEnum.getTitle(PurchaseOrderStatusEnum.keys.COMPLETED),
      selected: filter.status === PurchaseOrderStatusEnum.keys.COMPLETED,
    },
    {
      id: PurchaseOrderStatusEnum.keys.DENIED,
      name: PurchaseOrderStatusEnum.getTitle(PurchaseOrderStatusEnum.keys.DENIED),
      selected: filter.status === PurchaseOrderStatusEnum.keys.DENIED,
    },
    {
      id: PurchaseOrderStatusEnum.keys.CANCELED,
      name: PurchaseOrderStatusEnum.getTitle(PurchaseOrderStatusEnum.keys.CANCELED),
      selected: filter.status === PurchaseOrderStatusEnum.keys.CANCELED,
    },
  ];
});
//#endregion

//#region Methods
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? purchaseOrderStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      start: filter.range.start ?? currentDate,
      end: filter.range.start ?? date.make(currentDate)
            .add(1, date.DURATION_INPUT.YEARS)
            .format(date.FORMAT.ISO_8601),
    },
  }
};

/**
 * Padrão do filtro principal
 * @returns {PurchaseOrderListFilterType}
 */
function defaultFilter() {
  return {
    ...initPurchaseOrderListFilterType(),
  }
};

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  purchaseOrderStore.actions.list(filter, isAccumulateItems);
};

/**
 * Preparar filtro principal para listagem
 */
function prepareFilter() {
  filter.page = "";
  filter.input_company_plant_id = searchBarFilter.company_plant_selected;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.range.start = searchBarFilter.range.start;
  filter.range.end = searchBarFilter.range.end;
};
//#endregion

//#region Lifecycle
onMounted(() => {
  clearFilter(true, true);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  purchaseOrderStore.mutations.resetStates();
  purchaseOrderStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>