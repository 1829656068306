<template>
  <div>
    <div class="container-fluid">
      <div v-if="(travels.length || travels) && !loadingSkeleton"
        class="row card-wrapper">
        <div v-for="(travel, indexTravel) in travels"
          :key="indexTravel"
          class="col-md-4">
          <div class="card card-travels">
            <div class="container p-0">
              <div class="row align-items-center mb-3">
                <div class="col-12">
                  <div class="d-flex align-items-center">
                    <StatusBadge
                      :status="travel.status"
                    />
                    <div class="test-active-spot"
                         v-if="![scheduleTravelEnum.FINISHED, scheduleTravelEnum.CANCELED, scheduleTravelEnum.UNREALIZED].includes(travel.status)"
                         @click.prevent="(travel.status === 1 || loadingHardenedState || loadingSchedule) ? '' : toggleTestButton(travel)"
                         :class="{'active': travel.hardened_state, 'disabled': (travel.status === 1 || loadingHardenedState || loadingSchedule)}">
                      <AppIcon
                        icon="impossible-shapes"
                        color="primary"
                        title="Testes"
                        class="pointer"
                        width="20"
                      />
                      <h5>
                        Ensaio
                      </h5>
                    </div>
                    <div class="icon-spot"
                      :style="(loadingSchedule || loadingSkeleton) ? 'opacity: 0.4' : ''"
                      @click.prevent="
                        !(loadingSchedule || loadingSkeleton)
                        ? $emit('handleTravelCycle', travel.uuid, Schedule.address_construction)
                        : ''
                      "
                    >
                      <AppIcon
                        icon="clock"
                        color="success"
                        title="tempo de carregamento"
                        class="ml-2"
                        width="20"
                      />
                    </div>
                    <template v-if="[scheduleTravelEnum.FINISHED, scheduleTravelEnum.CANCELED, scheduleTravelEnum.UNREALIZED].includes(travel.status)">
                      <div class="icon-spot"
                        :style="(!travel.isset_manual_dosage || travel.concrete_left_over.length) ? 'opacity: 0.4' : ''"
                        @click.prevent="handleWaterControl(travel.uuid, indexTravel)">
                        <AppIcon
                          icon="water"
                          color="primary"
                          title="Traços"
                          class="ml-2"
                          width="20"
                        />
                        <span
                          v-if="!travel.water_control || emptyWaterControl(travel.water_control)"
                          class="badge badge-md badge-circle badge-floating badge-warning border-white">
                          <i class="fas fa-exclamation"/>
                        </span>
                      </div>

                      <div class="icon-spot"
                        :style="!travel.isset_manual_dosage ? 'opacity: 0.4' : ''"
                        @click.prevent="handleFreshStateTest(travel.uuid, indexTravel)">
                        <AppIcon
                          icon="vlc"
                          color="warning"
                          title="Paradas"
                          class="pointer ml-2"
                          width="20"
                        />
                        <span
                          v-if="travel.has_empty_fresh_test"
                          class="badge badge-md badge-circle badge-floating badge-warning border-white">
                          <i class="fas fa-exclamation"/>
                        </span>
                      </div>
                    </template>

                    <div class="icon-spot"
                      v-if="travel.schedule_travel_cycle_attachment_id"
                      @click.prevent="handleModalScheduleTravelCycleAttchment(travel.cycle.id)">
                      <AppIcon
                        icon="attach"
                        color="success"
                        title="Anexo"
                        class="pointer ml-2"
                        width="20"
                      />
                    </div>

                    <div class="icon-spot"
                         v-show="travel.automation_code"
                         @click.prevent="$emit('copy', travel.automation_code)">
                      <AppIcon
                        icon="copy-to-clipboard"
                        color="primary"
                        title="Copiar código automação"
                        class="pointer ml-2"
                        width="20"
                      />
                    </div>
                    <img src="/img/brand/logo.png"
                      width="20"
                      class="pointer ml-2"
                      @click.prevent="showAdjustments(travel)"
                      alt="logo">
                    <SettingsOptions
                      @eventHandler="eventHandler"
                      :operationalPermission="$_operational_permission"
                      :travel="travel"
                    />
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-12">
                  <h3 class="m-0 new-default-black-font">
                    {{ travel.load_truck_time ? travel.load_truck_time.slice(0, 11) : "" }}
                    <i style="width: 5px;display: inline-flex;"></i>
                    <template v-if="travel.status !== 1">
                      {{ travel.load_truck_time ? travel.load_truck_time.slice(-5) : "" }}
                    </template>
                    <template v-if="travel.status === 1 && travel.cycle">
                      {{ travel.cycle.start_load_truck_time ? travel.cycle.start_load_truck_time.slice(11, -3) : "" }}
                      -
                      {{ travel.cycle.free_for_travel ? travel.cycle.free_for_travel.slice(11, -3) : "" }}
                    </template>
                  </h3>
                </div>

                <template v-if="travel.send_plant.uuid === travel.return_plant.uuid">
                  <div class="col-12 my-1">
                    <div class="d-flex align-items-center">
                      <AppIcon
                        icon="sorting-arrows-horizontal"
                        color="primary"
                        title="Histórico de cliente"
                        class="pointer mr-2"
                        width="15"
                      />
                      <h5 class="m-0 new-default-gray-font font-weight-normal">
                        {{ travel.send_plant.short_name }} <template v-if="travel.charge_point"> ({{ travel.charge_point }}) </template>
                      </h5>
                    </div>
                  </div>
                </template>
                <!-- Centrais destino e origem diferentes -->
                <template v-if="travel.send_plant.uuid !== travel.return_plant.uuid">
                  <div class="col-12 mt-1 mb-0">
                    <div class="d-flex align-items-center">
                      <div style="overflow: hidden; height: 8px;">
                        <AppIcon
                          icon="sorting-arrows-horizontal"
                          color="success"
                          title="Histórico de cliente"
                          class="pointer mr-2"
                          width="15"
                        />
                      </div>
                      <h5 class="m-0 new-default-gray-font font-weight-normal">
                        {{ travel.send_plant.short_name }} <template v-if="travel.charge_point"> ({{ travel.charge_point }}) </template>
                      </h5>
                    </div>
                  </div>
                  <div class="col-12 mt-0 mb-1">
                    <div class="d-flex align-items-center">
                      <div style="overflow: hidden; height: 8px;align-items: end; display: flex;">
                        <AppIcon
                          icon="sorting-arrows-horizontal"
                          color="danger"
                          title="Histórico de cliente"
                          class="pointer mr-2"
                          width="15"
                        />
                      </div>
                      <h5 class="m-0 new-default-gray-font font-weight-normal">
                        {{ travel.return_plant.short_name }}
                      </h5>
                    </div>
                  </div>
                </template>
              </div>
              <div class="row mt-3" v-if="travel.driver || travel.equipment || travel.concrete_left_over.length || travel.additional_exists">
                <div class="col-12">
                  <h4 class="m-0 new-default-black-font font-weight-normal"
                    v-if="travel.driver">
                    {{ travel.driver }}
                  </h4>
                </div>
                <div class="col-10">
                  <h4 class="m-0 new-default-black-font font-weight-normal" v-if="travel.equipment">
                    {{ travel.equipment.code }} ({{ travel.equipment.plate }})
                  </h4>
                </div>
                <div class="col-2 text-right">
                  <CustomPopover
                    v-if="travel.concrete_left_over.length"
                    title="Reaproveitamento - Reuso"
                    width="340"
                    top="18px"
                    right="-10px">
                    <AppIcon
                      icon="recycling"
                      color="success"
                      title="Reciclagem"
                      class="pointer"
                      width="20"
                      slot="reference"
                    />
                    <div class="custom-popover-body">
                      <div class="reuse-container-card" v-for="(leftOver, index) in travel.concrete_left_over">
                        <div class="container">
                          <div class="row my-1">
                            <div class="col-12">
                              <div class="d-flex align-items-center">
                                <AppIcon
                                  icon="recycling"
                                  :color="borderLeftOver[`${leftOver.category}${leftOver.category_type}`]"
                                  title="Reciclagem"
                                  class="pointer"
                                  width="20"
                                  slot="reference"
                                />
                                <h6 class="m-0 new-default-black-font ml-2">
                                  {{ categories[`${leftOver.category}${leftOver.category_type}`] }}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <h6 class="m-0 new-default-black-font">
                                Quantidade: {{ leftOver.volume }} m³
                              </h6>
                              <h6 class="m-0 new-default-gray-font"
                                v-for="(reason, index) in leftOver.schedule_travel_left_over_reason"
                                :key="index">
                                {{ reason.left_over_adjustment.reason }}
                              </h6>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col-6 pr-2">
                              <AppButton
                                @click.prevent="$emit('handleEditConcreteLeftOver', travel, leftOver, travel.count_volume_left_over)"
                                class="reuse-button200220252053"
                                label="Editar"
                                icon="create"
                                :size="buttonSizeEnum.keys.SM"
                                isBlock
                                hasOutline
                                type="warning" />
                            </div>
                            <div class="col-6 pl-2">
                              <AppButton
                                @click.prevent="
                                  $emit('handleDeleteConcreteLeftOver', leftOver, {
                                    index: indexTravel,
                                    index_left_over: index,
                                  })
                                "
                                class="reuse-button200220252053"
                                label="Remover"
                                :size="buttonSizeEnum.keys.SM"
                                icon="trash-can"
                                isBlock
                                hasOutline
                                type="danger" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CustomPopover>
                  <CustomPopover
                    v-if="travel.additional_exists"
                    width="180"
                    top="24px"
                    right="-6px"
                    class="ml-2"
                    title="adicionais">
                    <AppIcon
                      @click.once="getAdditionals(travel.uuid)"
                      icon="add-new"
                      color="warning"
                      title="adicionar"
                      class="pointer"
                      width="20"
                      slot="reference"
                    />
                    <div class="custom-popover-body">
                      <div class="row" v-if="loadingAdditionals.includes(travel.uuid)">
                        <div class="col-12" v-for="i in 3" :key="i">
                          <skeleton-puzl type="text"></skeleton-puzl>
                        </div>
                      </div>
                      <template v-else>
                        <template v-if="travel.additionals && travel.additionals.length"
                          v-for="(additional, index) in travel.additionals">
                            <h6 class="m-0 new-default-black-font">
                              {{ additional.additional.short_description }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font">
                              {{ additional.reason }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font">
                              {{ additional.additional.standard_value }} {{ additional.additional.billing_unit_description }}
                            </h6>
                            <h6 class="m-0 new-default-gray-font" v-if="additional.justification">
                              <el-popover trigger="hover" placement="top" class="p-0">
                                <span>{{ additional.justification || "Justificativa não informada" }}</span>
                                <badge v-if="!additional.status"
                                  class="fs-10 font-weight-normal mt-2"
                                  slot="reference"
                                  type="danger"> CANCELADO </badge>
                              </el-popover>
                            </h6>
                            <hr v-if="index < travel.additionals.length-1"
                              class="m-2">
                        </template>
                        <template v-else>
                          <h6 class="m-0 new-default-gray-font">
                            Nenhum adicional vinculado.
                          </h6>
                        </template>
                      </template>
                    </div>
                  </CustomPopover>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="m-0 my-3">
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <h6 class="m-0 new-default-black-font font-weight-400">
                    Volume Carregado
                  </h6>
                  <div class="d-flex align-items-center">
                    <h1 class="m-0 new-default-black-font">
                      {{ travel.volume }}
                    </h1>
                    <p class="m-0 new-default-black-font text-lowercase h2 ml-1">m</p>
                    <h1 class="m-0 new-default-black-font">
                      ³
                    </h1>
                    <p class="new-default-black-font font-weight-normal my-0 ml-2">
                      ({{ indexTravel + 1 }})
                    </p>
                    <AppIcon
                      v-if="enableEdit(travel)"
                      @click.prevent="$emit('handleVolumeEdit', travel)"
                      icon="create"
                      color="warning"
                      width="20"
                      class="pointer ml-3 mr-2"
                    />
                    <CustomPopover
                      :title="setTitleReuse(travel.reuse.category_id)"
                      v-if="travel.reuse"
                      width="340"
                      top="18px"
                      left="-10px">
                      <AppIcon
                        icon="recycling"
                        :color="travel.reuse.category === 1 ? 'success' : 'warning'"
                        title="Reciclagem"
                        class="ml-2 pointer"
                        width="20"
                        slot="reference"
                      />
                      <div class="custom-popover-body">
                        <h6 class="m-0 new-default-black-font">
                          <div class="d-flex align-items-center">
                            <img width="24" src="/img/icons/os.png"/>
                            <h4 class="m-0 ml-2 font-weight-400 new-default-black-font">
                              {{ travel.reuse.number }}
                            </h4>
                          </div>
                        </h6>
                        <h6 class="mx-0 my-1 new-default-gray-font">
                          Quantidade: {{ travel.reuse.volume.replace(".", ",") }}m<sup>3</sup>
                        </h6>
                        <hr class="m-2">
                        <h6 class="m-0 new-default-black-font">
                          <div class="d-flex align-items-center">
                            <img src="/img/icons/test-tube.png" width="20">
                            <h4 class="m-0 ml-2 font-weight-400 new-default-black-font">
                              {{ travel.reuse_title }}
                            </h4>
                          </div>
                        </h6>
                        <h6 class="mx-0 my-1 new-default-gray-font">
                          {{ travel.reuse_body }}
                        </h6>
                        <h6 class="m-0 new-default-gray-font">
                          {{ travel.reuse_footer }}
                        </h6>
                      </div>
                    </CustomPopover>
                  </div>
                </div>
                <div class="col-6">
                  <h6 class="m-0 new-default-black-font font-weight-400 text-right">
                    Volume Acumulado
                  </h6>
                  <div class="d-flex align-items-center">
                    <h1 class="m-0 new-default-black-font ml-auto font-weight-light">
                      {{ travel.accumulated }}
                    </h1>
                    <p class="m-0 new-default-black-font text-lowercase h2 ml-1 font-weight-light">m</p>
                    <h1 class="m-0 new-default-black-font font-weight-light">
                      ³
                    </h1>
                  </div>
                </div>
              </div>
              <div class="row mt-4 documents-placements" v-if="travel.xml || travel.mdfe || travel.number">
                <div class="col-4"
                  v-if="travel.number"
                  @click.prevent="downloadProof(travel)">
                  <DocumentPlacement
                    icon="/img/icons/icons8/ios/os.png"
                    :text="travel.number"
                  />
                </div>
                <div class="col-4"
                  v-if="travel.xml"
                  @click.prevent="downloadDanfe(travel.uuid, travel.xml)">
                  <DocumentPlacement
                    :icon="getTextXml(travel.xml).icon"
                    :text="getTextXml(travel.xml).title"
                  />
                </div>
                <div class="col-4"
                  v-if="travel.mdfe"
                  @click.prevent="downloadMdfe(travel.mdfe)">
                  <DocumentPlacement
                    :icon="getTextMDFe(travel.mdfe).icon"
                    :text="getTextMDFe(travel.mdfe).title"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card-wrapper">
        <SkeletonPuzlGrid size="lg" v-for="(index) in 3" v-show="loadingSkeleton" :key="index"/>
      </div>
    </div>
  </div>
</template>
<script>
import {base_url_ms} from "@/plugins";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import {AppButton, AppIcon} from "@/components/AppGlobal";
import {hasPermission} from "@/plugins/permissions";
import StatusBadge from "./StatusBadge.vue";
import SettingsOptions from "./SettingOptions.vue"
import DocumentPlacement from "./DocumentPlacement.vue"
import CustomPopover from "./CustomPopover.vue"
import ButtonSizeEnum from "@/components/AppGlobal/AppButton/enums/ButtonSizeEnum";
import {ScheduleTravelEnum} from "@/enum/ScheduleTravelEnum";

export default {
  name: "ListWeighing",
  mixins: [cursorPaginate],
  components: {
    AppButton,
    AppIcon,
    SkeletonPuzl,
    PuzlEmptyData,
    SkeletonPuzlGrid,
    LoadingPagination,
    StatusBadge,
    SettingsOptions,
    DocumentPlacement,
    CustomPopover,
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
      travels: "travels/fetch",
      Schedule: "schedule/show",
    }),
  },
  data() {
    return {
      format_mixe: period_types,
      show: false,
      modal_shown: false,
      base_url_ms: base_url_ms(),
      loadingHardenedState: false,
      filter: {
        status: null,
      },
      buttonSizeEnum: ButtonSizeEnum,
      scheduleTravelEnum: ScheduleTravelEnum,
      classSituation: {
        0: "text-success-light",
        1: "text-primary",
        2: "text-indigo",
        3: "text-indigo",
        4: "text-warning",
        5: "text-info",
        6: "text-danger",
        7: "text-danger",
        8: "text-warning",
      },
      textSituation: {
        0: "Liberado",
        1: "Concluído",
        2: "Carregando",
        3: "Em edição",
        4: "Aguardando liberação do cliente",
        5: "Em andamento",
        6: "Cancelado",
        7: "Não realizado",
        8: "Pendência",
      },
      categories: {
        11: "Reaproveitamento - Reuso",
        12: "Redirecionamento - Reuso",
        21: "Sobra de Concreto - descarte",
        22: "Perda de Concreto - descarte",
      },
      borderLeftOver: {
        11: "success",
        12: "primary",
        21: "warning",
        22: "danger",
      },
      loadingAdditionals: [],
      loadingFreshState: true,
      loadingMoldingLocation: true,
    };
  },
  props: {
    loadingSchedule: Boolean,
    loadingSkeleton: Boolean,
    downloadProof: Function,
    handleWaterControl: Function,
    emptyWaterControl: Function,
    showAdjustments: Function,
    handleFreshStateTest: Function,
    handleModalScheduleTravelCycleAttchment: Function,
  },
  methods: {
    eventHandler(func, args){
      this.$emit(func, args);
    },
    toggleTestButton(travel){
      travel.hardened_state = !travel.hardened_state;
      this.setHardenedState(travel);
    },
    hasPermission,
    validateIssueMDFe() {
      return false;
    },
    getTextXml(xml) {
      switch (xml.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
        case 1:
          return {
            title: "n. " + xml.number,
            class: "new-default-black-font",
            icon: "/img/nfe.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/nfe.png"
          }
        case 3:
          return {
            title: "Cancelando NF-e",
            class: "text-warning",
            icon: "/img/nfe_in_cancelation_progress.png"
          }
        case 4:
          return {
            title: "n. " + xml.number,
            class: "text-danger",
            icon: "/img/nfe%20cancelada%20icon.svg"
          }
        case 5:
          return {
            title: "Duplicada",
            class: "text-primary",
            icon: "/img/nfe.png"
          }
        case 6:
          return {
            title: "Denegada",
            class: "text-warning",
            icon: "/img/nfe.png"
          }
      }
    },
    getTextMDFe(mdfe) {
      switch (mdfe.status) {
        case 0:
        case 7:
          return {
            title: "Emitindo",
            class: "text-warning",
            icon: "/img/mdfesefaz.png"
          }
        case 1:
          return {
            title: "n. " + mdfe.number,
            class: "new-default-black-font",
            icon: "/img/mdfesefaz.png"
          }
        case 2:
          return {
            title: "Rejeitada",
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
        case 3:
          return {
            title: "n. " + mdfe.number,
            class: "text-success",
            icon: "/img/mdfesefaz.png"
          }
        case 4:
          return {
            title: "n. " + mdfe.number,
            class: "text-danger",
            icon: "/img/mdfesefaz.png"
          }
      }
    },
    downloadMdfe(mdfe) {
      let loader = this.$loading.show();
      const url = this.base_url_ms + 'download-s3?url=' + mdfe.pdf_file_path
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", mdfe.chNFe +'.pdf');
        await link.click()
      });
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
      loader.hide();
    },
    async downloadDanfe(schedule_travel_uuid, xml) {
      if (xml.status !== 1) {
        return;
      }
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], {type: "application/pdf"});
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${xml.key}.pdf`);
          await link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
    getAdditionals(travel_uuid) {
      this.loadingAdditionals.push(travel_uuid);
      this.$Progress.start();
      let params = {
        filter: {
          schedule_travel_uuid: travel_uuid,
        },
      };
      this.$store
        .dispatch("scheduleAdditional/fetchItems", params)
        .then((response) => {
          this.travels.find((item) => item.uuid === travel_uuid).additionals = response.data;
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, 1);
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          const indexValue = this.loadingAdditionals.findIndex((item) => item === travel_uuid);
          this.loadingAdditionals.splice(indexValue, travel_uuid);
          this.$Progress.finish();
          this.$notify({
            type: error.error_type,
            message: error.message,
          });
        });
    },
    setTitleReuse(type) {
      return this.categories[type];
    },
    enableRecoveryForceFinish() {
      if (this.Schedule && (this.Schedule.complete_travels > 0 || this.Schedule.cancelled_travels > 0) && this.Schedule.force_finished) {
        return true;
      }
      return false;
    },
    toCharging(uuid) {
      this.$router.push(`/operational/schedule/charging/${uuid}`);
    },
    setHardenedState(travel) {
      let params = {
        schedule_travel_uuid: travel.uuid,
        hardened_state: travel.hardened_state,
        features: this.Schedule.contract_proposal_formulation.features,
      };
      this.loadingHardenedState = true;
      this.$store
        .dispatch("travelConfiguration/storeDefaultHardenedStates", params)
        .then((response) => {
          this.loadingHardenedState = false;
          this.$notify({type: "success", message: response.message});
        })
        .catch(() => (this.loadingHardenedState = false));
    },
    /** Verifica se viagem está apta para edição */
    enableEdit(travel) {
      return travel.status !== this.scheduleTravelEnum.FINISHED &&
        travel.status !== this.scheduleTravelEnum.IN_PROGRESS &&
        travel.status !== this.scheduleTravelEnum.CANCELED &&
        travel.status !== this.scheduleTravelEnum.UNREALIZED &&
        travel.status !== this.scheduleTravelEnum.LOADING &&
        travel.isset_manual_dosage === false;
    },
  },
  async created() {
    this.loadingFreshState = true;
    this.$store.dispatch("testFreshState/fetchItems").then(() => {
      this.loadingFreshState = false;
    });
  },
};
</script>

<style scoped>
.fa-edit {
  cursor: pointer;
}

.text-success-light {
  color: #68b879 !important;
}

.text-strong-warning {
  background-color: #f97300 !important;
  border: #f97300 !important;
}
</style>
<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.card-travels {
  -webkit-box-shadow: 0 5px 10px 0 #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid $muted-light;
  box-shadow: 0 5px 10px 0 #0000000D !important;
  padding: 16px 20px 20px;

  .icon-spot {
    display: inline-flex;
    cursor: pointer;

    span.badge {
      position: relative;
      margin-left: -9px;
      height: 16px;
      width: 16px;

      i.fa-exclamation {
        font-size: 10px;
      }
    }
  }

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }

    .reuse-container-card {
      margin-top: 8px;
      border: 1px solid #f4f4f4;
      border-radius: 8px;
      padding: 18px 5px;

      h5, h6 {
        padding-left: 0;
      }
    }
  }

  hr {
    background-color: $muted-light;
    opacity: 0.5;
    color: $muted-light;
  }

  h1 {
    font-size: 25px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }

  .pointer {
    transition: all ease-in-out 0.15s;
    &:hover {
      transform: translateY(-3px) !important;
    }
  }

  .documents-placements {
    > :first-child {
      padding-right: 0.5rem;
    }

    > :nth-child(2) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    > :last-child {
      padding-left: 0.5rem;
    }
  }
}
</style>
<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.reuse-button200220252053 {
  h5 {
    color: $dark !important;
    font-weight: 400 !important;
    font-size: 10px;
  }

  &:hover {
    h5 {
      color: white !important;
    }
  }
}

.test-active-spot {
  border-radius: 50px;
  min-height: 24px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  border: 1px solid #f4f4f4;
  padding: 4px 10px;
  cursor: pointer;

  h5 {
    font-size: 12px;
    margin: 0 0 0 5px;
    font-weight: 400 !important;
    height: 20px;
  }

  &.active, &:hover {
    background-color: $primary;
    border: 1px solid $primary;

    .app-icon {
      background-color: white !important;
    }

    h5 {
      color: white;
    }
  }
}
</style>
