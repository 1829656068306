<template>
  <div>
    <div class="row card-wrapper">
      <div class="col-lg-4" v-for="item in $_service_description_listed.items" :key="item.id">
        <div class="card main-card mb-3">
          <div class="p-3 px-4">
            <div class="row align-items-center">
              <div class="col-10">
                <section class="top-section d-flex align-items-center mb-1">
                    <AppSwitch v-model="item.status" :asyncClick="() => updateStatus(item)" />
                      <div class="row m-0 ml-2">
                        <el-popover
                          class="ml-0"
                          placement="bottom"
                          trigger="hover"
                        >
                          <div class="d-flex mb-2" style="gap: 15px;font-weight: 500;color: #2B2D32">
                            <span v-if="item.updated_by_user_name === null">Criação</span>
                            <span v-else>Última Atualização</span>
                          </div>
                          <hr>
                          <div class="mt-2">
                            <span v-if="item.updated_by_user_name === null">
                              {{ item.created_by_user_name }}<br/>
                              {{ date.make(item.created_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                            </span>
                            <span v-else>
                              {{ item.updated_by_user_name }}<br/>
                              {{ date.make(item.updated_at).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                            </span>
                          </div>
                          <base-button
                            slot="reference"
                            class="p-0 m-0 shadow-none"
                            outiline
                            size="sm"
                            type="secundary"
                          >
                            <AppIcon icon="male-user" width="21" />
                          </base-button>
                        </el-popover>
                      </div>
                </section>
              </div>
              <div class="col-2 text-right">
                <base-dropdown menuOnRight>
                  <div slot="title-container" class="dropdown-toggle rounded m-0">
                    <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                  </div>
                  <a 
                    @click.prevent="onEditItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/edit.png" />
                    EDITAR
                  </a>
                  <div class="dropdown-divider p-0 m-0"></div>
                  <a 
                    @click.prevent="onRemoveItemClick(item.id)"
                    class="dropdown-item font-weight-500 new-default-black-font"
                    style="align-items: center; display: flex"
                  >
                    <img width="18" src="/img/icons/delete.png" />
                    EXCLUIR
                  </a>
                </base-dropdown>
              </div>
            </div>
            <section class="mt-2">
              <div class="mb-1">
                <span style="font-size: 14px;font-weight: 500;color: #2B2D32;">
                  {{ item.service_name }}
                </span>
              </div>
              <div v-show="item.description">
                <span style="font-size: 12px;font-weight: 400;color: #2B2D32;">
                  {{ item.description }}
                </span>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <div class="row card-wrapper" v-show="$_service_description_is_listing">
      <SkeletonPuzlGrid v-for="(index) in 3" :key="index"/>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, ref } from "vue";
import serviceDescriptionStore from "../../store/serviceDescriptionStore";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
import { AppSwitch, AppIcon } from "../../../../../components/AppGlobal";
import { ServiceDescriptionListType } from "../../types/ServiceDescriptionListPagerType";
import { dialogs, date } from "../../../../../helpers";
//#endregion

//#region Emits
const emit = defineEmits([
  "onEditItemClick",
  "onRemoveItemClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_service_description_listed = computed(() => serviceDescriptionStore.getters.getListed());
const $_service_description_is_listing = computed(() => serviceDescriptionStore.getters.getIsListing());
//#endregion

//#region Methods
/**
 * Editar item
 * @param {number} id
 */
 function onEditItemClick(id) {
  emit("onEditItemClick", id);
}

/**
 * Remover item
 * @param {number} id
 */
function onRemoveItemClick(id) {
  emit("onRemoveItemClick", id);
}

/**
 * Atualizar Status por ID
 * @param {ServiceDescriptionListType} item
 */
async function updateStatus(item) {
  await serviceDescriptionStore.actions.updateStatus(!item.status, item.id);    
};
//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}

.card {
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid #ECECEC;
  min-height: 120px;
}

hr {
  border: 0;
  border-top: 0.5px solid #E8E8E8;
  margin: 7px 0;
  width: 116%;
  margin-left: -11px;
}
</style>
