import { computed, getCurrentInstance } from "vue";


export function useServiceDescriptionHook() {
  const store = getCurrentInstance().proxy.$store;

  /**
   * Nome do Serviço
   */
  store.dispatch("service/fetchServices");
  const serviceNames = computed(() => store.getters["service/services"]);


  return {
    serviceNames
  };
}