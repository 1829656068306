import { BaseListFilterType, initBaseListFilterType, RangeDateType, RangeValueType } from "@/shared/types";
import { BillReceiveGetEntitiesRangeTypeEnum } from "../enums/BillReceiveGetEntitiesRangeTypeEnum";
import { BillReceiveGetEntitiesTabSelectItemEnum } from "../enums/BillReceiveGetEntitiesTabSelectItemEnum";
import { date } from "../../../../helpers";
/**
 * Objeto de filtro para listagem
 *
 * @typedef {Object} BillReceiveGetEntitiesFilterExtType
 * @property {number} entity_id - cliente/fornecedor
 * @property {number} status
 * @property {keyof typeof BillReceiveGetEntitiesRangeTypeEnum.keys} range_type - Tipo de data usada no filtro
 * @property {keyof typeof BillReceiveGetEntitiesTabSelectItemEnum.keys} status - Status do filtro
 * @property {RangeDateType} range - Intervalo de datas
 * @property {?number} payment_method_id - Método de pagamento
 * @property {?number} contract_proposal_id - Contrato
 * @property {RangeValueType} valueRange - Intervalo de valores
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & BillReceiveGetEntitiesFilterExtType)} BillReceiveGetEntitiesFilterType
 */
export const BillReceiveGetEntitiesFilterType = {};

/**
 * Inicializar BillReceiveGetEntitiesFilterType
 *
 * @returns {BillReceiveGetEntitiesFilterType}
 */
export const initBillReceiveGetEntitiesFilterType = () => ({
  ...initBaseListFilterType(),
  range_type: BillReceiveGetEntitiesRangeTypeEnum.keys.CREATED_AT,
  status : BillReceiveGetEntitiesTabSelectItemEnum.keys.ALL,
  range: {
    start: date.make().setStartOf(date.UNIT_OF_TIME.MONTH).format(date.FORMAT.ISO_8601),
    end: date.make().setEndOf(date.UNIT_OF_TIME.MONTH).format(date.FORMAT.ISO_8601),
  },
  range_value: {
    min: null,
    max: null,
  },
  payment_method_id: null,
  contract_proposal_id: null,
});
