<template>
  <div>
    <modal :show.sync="modal.open" :backgroundColor="'#F2F4F9'" size="lg">
      <template slot="header">
        <div
          class="d-flex align-items-center container-fluid"
          style="gap: 12px; margin-left: -28px"
        >
          <div>
            <img
              src="/img/icons/icons8/ios/icon-plus-success.png"
              height="28"
              width="28"
            />
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="container-fluid">
              <div class="row mt-2 mb-3">
                <div class="col-12" style="display: flex; align-items: center">
                  <img
                    src="/img/icons/icons8/ios/info-squared_warning.png"
                    width="22"
                  />
                  <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
                  <hr class="ml-3 m-0" style="width: 100%" />
                </div>
              </div>
              <div class="card">
                <div class="d-flex align-items-center">
                  <div>
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Status
                    </h4>
                  </div>
                  <div class="ml-auto" style="min-width: 130px">
                    <AppSwitch v-model="additionalClassification.status" />
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Descrição Técnica
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="
                            additionalClassification.technical_description
                          "
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          @input="
                            () =>
                              commercialDescriptionManual
                                ? null
                                : (additionalClassification.commercial_description =
                                    additionalClassification.technical_description)
                          "
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Descrição Comercial
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="
                            additionalClassification.commercial_description
                          "
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          @change="
                            () =>
                              additionalClassification.commercial_description
                                ? (commercialDescriptionManual = true)
                                : (commercialDescriptionManual = false)
                          "
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Subtipo
                    </h4>
                  </div>
                  <div class="col-6">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-classes="form-control-sm">
                        <puzl-select
                          :clearable="false"
                          v-model="additionalClassification.subtype_ids"
                          :items="[
                            { id: 'all', technical_description: 'Todos' },
                            ...subtypes,
                          ]"
                          @input="setAll"
                          :multiple="true"
                          label="technical_description"
                          :disabled="loadingSubTypes"
                          :loading="loadingSubTypes"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-1 p-0">
                    <base-button
                      @click.prevent="removeSubtypes()"
                      size="sm"
                      type="danger"
                      class="ml-2"
                    >
                      <img height="18px" src="/img/icons/cancel.png" />
                    </base-button>
                  </div>
                </div>
              </div>
              <div class="row mt-5 mb-4">
                <div class="col-12 text-right">
                  <AppButton
                    label="Cancelar"
                    type="danger"
                    class="ml-2"
                    :hasOutline="true"
                    @click="closeModal()"
                  />
                  <AppButton
                    label="Salvar"
                    type="success"
                    class="ml-2"
                    native-type="submit"
                    :isDisabled="invalid"
                  />
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import { AppButton, AppSwitch } from "../../../../../../components/AppGlobal";

export default {
  name: "Create",
  components: {
    PuzlSelect,
    AppButton,
    AppSwitch,
  },
  data() {
    return {
      modal: {
        title: "NOVA CLASSIFICAÇÃO ADICIONAL",
        open: false,
      },
      additionalClassification: {
        status: true,
        technical_description: null,
        commercial_description: null,
        subtype_ids: [],
      },
      loadingSave: false,
      loadingSubTypes: true,
      commercialDescriptionManual: false,
    };
  },
  computed: {
    ...mapGetters({
      subtypes: "mixSubtype/fetch",
    }),
  },
  methods: {
    closeModal() {
      this.modal.open = false;
    },
    handleCreateModal() {
      this.modal.open = true;
      this.additionalClassification = {
        status: true,
        technical_description: null,
        commercial_description: null,
        subtype_ids: [],
      };
    },
    setAll() {
      if (this.additionalClassification.subtype_ids.includes("all")) {
        this.additionalClassification.subtype_ids = [];
        for (let subtype of this.subtypes) {
          this.additionalClassification.subtype_ids.push(subtype.id);
        }
      }
    },
    save() {
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch(
          "mixAdditionalClassifications/add",
          this.additionalClassification
        )
        .then((response) => {
          this.loadingSave = false;
          this.modal.open = false;
          this.additionalClassification = {
            status: true,
            technical_description: null,
            commercial_description: null,
            subtype_ids: [],
          };
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.response.status === 422) {
            let errors = Object.values(error.response.data.errors);
            errors = errors.flat().toString().split(",").join("<br />");

            this.$notify({ type: "danger", message: errors });
          }
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
    removeSubtypes() {
      this.additionalClassification.subtype_ids = [];
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("mixSubtype/fetchItems").then(() => {
      this.loadingSubTypes = false;
    });
  },
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}
.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  padding: 16px 12px;
  border: 1px solid #ececec;
}
</style>
