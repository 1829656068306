<template>
  <div>
    <div>
      <SkeletonPuzlGrid v-if="loadingSkeleton"></SkeletonPuzlGrid>
    </div>
    <div class="row" v-if="!loadingSkeleton && $_subtypes.length">
      <div
        class="col-lg-4 mb-n3"
        v-for="(item, index) in $_subtypes"
        :key="index"
      >
        <div class="card main-card p-4">
          <div class="d-flex align-items-center" style="gap: 8px">
            <div style="min-width: 130px">
              <AppSwitch
                v-model="item.status"
                :asyncClick="() => updateStatus(item)"
              />
            </div>
            <div class="ml-1">
              <el-popover trigger="click" placement="bottom">
                <div class="font-weight-400 fs-13" style="padding-bottom: 8px">
                  Tipos
                </div>
                <div style="height: 0.5px; background-color: #e8e8e8"></div>
                <div
                  v-for="(subtypeType, indexType) in item.types"
                  :key="indexType"
                  style="padding-top: 8px; display: flex; align-items: center"
                >
                  <span class="bullet"></span>
                  <div class="font-weight-300 fs-12 ml-2">
                    {{ subtypeType.technical_description }}
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img
                    width="26px"
                    src="/img/icons/icons8/ios/blockly-black.png"
                  />
                </base-button>
              </el-popover>
            </div>
            <div>
              <el-popover class="ml-0" placement="bottom" trigger="click">
                <div style="min-width: 270px">
                  <div class="font-weight-400 fs-13">LOGS DE ATIVIDADE</div>
                  <hr class="mt-2 mb-2" />
                  <div class="font-weight-400 fs-13 mb-1">CRIAÇÃO</div>
                  <div class="log-card">
                    <div
                      v-if="item.created_by_user"
                      class="font-weight-300 fs-12"
                    >
                      {{ item.created_by_user }}
                    </div>
                    <div v-else class="font-weight-300 fs-12">-</div>
                    <div class="font-weight-300 fs-12">
                      {{
                        $helper.parseDate(item.created_at, "DD MMM YYYY HH:mm")
                      }}
                    </div>
                  </div>
                  <div v-if="item.updated_by_user" class="mt-3">
                    <div class="font-weight-400 fs-13 mb-1">
                      ÚLTIMA ALTERAÇÃO
                    </div>
                    <div class="log-card">
                      <div class="font-weight-300 fs-12">
                        {{ item.updated_by_user }}
                      </div>
                      <div class="font-weight-300 fs-12">
                        {{
                          $helper.parseDate(
                            item.updated_at,
                            "DD MMM YYYY HH:mm"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <base-button
                  slot="reference"
                  class="p-0 m-0 shadow-none"
                  outiline
                  size="sm"
                  type="secundary"
                >
                  <img
                    width="26px"
                    src="/img/icons/icons8/ios/user-male-circle--v1.png"
                  />
                </base-button>
              </el-popover>
            </div>
            <base-dropdown
              menuOnRight
              style="display: block; margin-left: auto"
            >
              <base-button
                style="border: none !important; box-shadow: none !important"
                slot="title-container"
                type="invisible"
                class="ml-auto dropdown-toggle rounded app-button"
              >
                <AppIcon icon="settings" width="30" color="primary" />
              </base-button>
              <a
                class="dropdown-item ts-12 font-weight-400"
                @click.prevent="handleEditType(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/create-new-yellow.png"
                  width="14px"
                  height="14px"
                />
                Editar
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a
                class="dropdown-item ts-12 font-weight-400"
                @click.prevent="handleDeleteType(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/delete-red.png"
                  width="14px"
                  height="14px"
                />
                Excluir
              </a>
            </base-dropdown>
          </div>
          <div class="font-weight-500 fs-16" style="padding-top: 16px">
            {{ item.technical_description }}
          </div>
          <div class="font-weight-400 fs-14">
            {{ item.commercial_description }}
          </div>
        </div>
      </div>
    </div>
    <edit-type ref="editType"></edit-type>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditType from "./_Edit";
import { formatErrorValidation } from "@/plugins";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { AppSwitch } from "../../../../../../components/AppGlobal";
import { mapGetters } from "vuex";
import { date } from "../../../../../../helpers";
export default {
  name: "List",
  props: {
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzlGrid,
    EditType,
    AppIcon,
    AppSwitch,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
      subtype: {
        uuid: "",
        status: true,
        technical_description: null,
        commercial_description: null,
        type_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      $_subtypes: "mixSubtype/fetch",
    }),
  },
  methods: {
    handleEditType(uuid) {
      this.$refs.editType.handleEditModal(uuid);
    },
    handleDeleteType(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("mixSubtype/destroy", uuid)
              .then((response) => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                  this.$Progress.finish();
                } else if (error.response && error.response.status === 422) {
                  let errors = formatErrorValidation(
                    error.response.data.errors
                  );
                  this.$notify({
                    type: "danger",
                    message: errors,
                  });
                }
                this.$Progress.finish();
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
    updateStatus(item) {
      let newStatus = +!item.status;
      this.$store
        .dispatch("mixSubtype/updateStatus", { id: item.id, status: newStatus })
        .then((response) => {
          item.status = newStatus;
          this.$notify({
            type: "success",
            message: "Status atualizado com sucesso!",
          });
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}

.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid #ececec;
}

.log-card {
  box-shadow: none !important;
  border-radius: 4px !important;
  padding: 8px 12px;
  border: 1px solid #ececec;
}

.new-minicard-green {
  background-color: #f2f7f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-red {
  background-color: #fcf3f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.app-button {
  padding: 0 !important;
}

.bullet {
  width: 5px;
  height: 5px;
  border: 1px solid #1a70b7; /* Apenas a borda é colorida */
  border-radius: 50%;
}
</style>
