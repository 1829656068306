import store from "../../../../../../shared/libraries/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";
import { EntityBillReceiveHistoryGetByEntityBillReceiveType } from "../types";

/** URLS */
const urls = Object.freeze({
  BASE: "/financial/entity-bill-receive-history",
});

/** KEYS */
const keys = Object.freeze({
  LISTED: "ENTITY_BILL_RECEIVE_HISTORY_LISTED",
});

const getters = {
  /** @returns {Array<EntityBillReceiveHistoryGetByEntityBillReceiveType>}  */
  getListed: function () {
    return store.getState(keys.LISTED) ?? [];
  },
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {Array<EntityBillReceiveHistoryGetByEntityBillReceiveType>} listed
   * @returns {Array<EntityBillReceiveHistoryGetByEntityBillReceiveType>}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),
};

/** ACTIONS */
const actions = {
  /**
   * Listar
   *
   * @param {number} entityBillReceiveId
   * @returns {Promise<Array<EntityBillReceiveHistoryGetByEntityBillReceiveType>>}
   */
  getByEntityBillReceive: async (entityBillReceiveId) => {
    const res = await httpClient.get(`${urls.BASE}/get-by-entity-bill-receive/${entityBillReceiveId}`);
    if (res.status !== HttpStatusEnum.HTTP_OK) {
      handleUnexpectedStatusCode(res);
      return;
    }

    const data = res.data.data;
    mutations.setListed(data);
    return data;
  },
};

export default {
  actions,
  getters,
  mutations,
};
