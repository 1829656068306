import CompetitorListPage from "../views/list/CompetitorListPage.vue";

export default [
  {
    path: '/commercial/contract-proposal/competitors',
    name: 'commercial.contract-proposal.competitor.view',
    component: CompetitorListPage,
    meta: {
      title: 'Comercial',
      description: 'Concorrentes',
      icon: 'fa fa-home',
      permission: 'commercial.contract-proposal.details.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
        {title: 'Proposta/Contrato', name: null},
        {title: 'Concorrentes', name: null},
      ]
    },
  },
];