<template>
  <div>
    <modal :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <AppIcon icon="order-history" color="dark" width="22" class="mr-3" />
          <h5 class="text-title mb-0">Histórico do lançamento</h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton icon="close" type="link" width="auto" size="sm" :hasOutline="true" @click.prevent="closeModal" />
      </template>
      <div class="mb-2 px-2 mx-1">
        <!-- Itens -->
        <section>
          <div class="row" v-for="(item, index) in items" :key="index">
            <!-- Ícone e círculos -->
            <div class="col-2 py-2 mt-1">
              <!-- Linha vertical com efeito de Threads -->
              <div class="history-vertical-line" v-if="items.length > 1 && index + 1 !== items.length"></div>
              <div class="history-step">
                <div class="history-step-icon">
                  <!-- Ícone circulado -->
                  <div class="history-circle-icon">
                    <AppIcon :icon="getIconName(item.status)" :color="getIconColor(item.status)" width="13" />
                  </div>

                  <div class="history-step-ellipsis">
                    <nav class="circle-1"></nav>
                    <nav class="circle-2"></nav>
                    <nav class="circle-3"></nav>
                  </div>
                </div>
              </div>
            </div>

            <!-- Card das informações de cada etapa do histórico -->
            <div class="col-10">
              <div class="history-step-infos">
                <h4 class="new-default-black-font m-0">{{ EntityBillReceiveHistoryStatusEnum.getTitle(item.status) }}</h4>
                <h5 v-if="item.observations" class="new-default-black-font m-0 font-weight-normal">{{ item.observations }}</h5>
                <h6 class="new-default-gray-font font-weight-normal mb-0 mt-1">{{ item.user_name }}</h6>
                <h6 class="new-default-gray-font font-weight-normal m-0">{{ date.make(item.created_at).format(date.FORMAT.DATE_TIME_SLASH) }}</h6>
              </div>
            </div>
          </div>
        </section>
        <hr class="my-3" />
        <div class="container px-4 mb-3">
          <div class="row">
            <div class="offset-8 col-4 px-2">
              <AppButton label="Fechar" type="primary" :hasOutline="true" :isBlock="true" @click.prevent="closeModal" />
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { ref, getCurrentInstance, computed } from "vue";
import { AppIcon, AppButton } from "../../../../../../../components/AppGlobal";
import entityBillReceiveHistoryStore from "../../store/entityBillReceiveHistoryStore";
import { progress, date } from "../../../../../../../helpers";
import { EntityBillReceiveHistoryStatusEnum } from "../../enums/EntityBillReceiveHistoryStatusEnum";
//#endregion

//#region Data
const isOpen = ref(false);
//#endregion

//#region Computeds
const items = computed(() => entityBillReceiveHistoryStore.getters.getListed());
//#endregion

//#region Methods
async function openModal(entityBillReceiveId) {
  const loader = progress.showLoader();
  entityBillReceiveHistoryStore.actions.getByEntityBillReceive(entityBillReceiveId);
  await loader.hide();
  isOpen.value = true;
}

function closeModal() {
  isOpen.value = false;
}

function getIconName(status) {
  switch (status) {
    case EntityBillReceiveHistoryStatusEnum.keys.CREATED_AT:
      return "cheque";
    case EntityBillReceiveHistoryStatusEnum.keys.APPROVED:
      return "thumbs-up";
    case EntityBillReceiveHistoryStatusEnum.keys.REVIEW:
      return "thumbs-up-down";
    case EntityBillReceiveHistoryStatusEnum.keys.CANCELED:
      return "cancel";
    default:
      return "";
  }
}

function getIconColor(status) {
  switch (status) {
    case EntityBillReceiveHistoryStatusEnum.keys.CREATED_AT:
      return "primary";
    case EntityBillReceiveHistoryStatusEnum.keys.APPROVED:
      return "primary";
    case EntityBillReceiveHistoryStatusEnum.keys.REVIEW:
      return "warning";
    case EntityBillReceiveHistoryStatusEnum.keys.CANCELED:
      return "danger";
    default:
      return "";
  }
}
//#endregion

//#region DefineExposes
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
.history-step-icon {
  display: inline-flex;
  align-items: center;
}

.history-circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}

.history-step-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.history-step-ellipsis nav {
  border: 1px solid #828282;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}
.history-step-infos {
  position: relative;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}
.history-vertical-line {
  background: #e8e8e8;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 29px;
  top: 38px;
}
.circle-1 {
  width: 8px;
  height: 8px;
}

.circle-2 {
  width: 4px;
  height: 4px;
}

.circle-3 {
  width: 2px;
  height: 2px;
}
</style>
