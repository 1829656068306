import * as types from './mutation_types'
import {update} from '@/store/baseStore'
import { createAxios } from "@/plugins/axios";
const endPoint = '/configuration/entity-contact'
export default {
  namespaced: true,
  state: {
    items: [],
    itemsContacts: [],
  },
  getters:{
    fetch: state => state.items,
    fetchContacts: state => state.itemsContacts,
  },
  mutations: {
    [types.PUSH] (state, payload) {
      state.items.unshift(payload)
    },
    [types.PUSH_CONTACTS] (state, payload) {
      state.itemsContacts.unshift(payload)
    },
    [types.SET] (state, payload) {
      state.items = payload
    },
    [types.SET_CONTACTS] (state, payload) {
      state.itemsContacts = payload
    },
    [types.UPDATE](state, payload) {
      update(state, payload)
    }
  },
  actions: {
    add({commit}, payload) {
      return createAxios().post(endPoint, payload)
        .then(({data}) => {
          commit(types.PUSH, data.data)
          commit(types.PUSH_CONTACTS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
      })
    },
    getByEntityId({commit}, entityId) {
      return createAxios().get(`${endPoint}/by-entity-id/${entityId}`)
        .then(({data}) => {
          commit(types.SET_CONTACTS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    show({commit}, params) {
      return createAxios().get(`${endPoint}/${params.id}`)
        .then(({data}) => {
          commit(types.SET_CONTACTS, data.data)
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    showWithoutCommit({commit}, params) {
      return createAxios().get(`${endPoint}/${params.id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
    update({commit}, payload) {
      return createAxios().put(`${endPoint}/${payload.id}`,  payload)
        .then(({data}) => {
          commit(types.UPDATE, data.data, 'itemsContacts', 'id')
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
      })
    },
    destroy(_, id) {
      return createAxios().delete(`${endPoint}/${id}`)
        .then(({data}) =>{
          return Promise.resolve(data)
        }).catch(error =>{
          return Promise.reject(error)
        })
    },
  }
}
