<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
        <div class="container-fluid">
          <div v-if="entity && entity.id" class="row mt-3 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/contract-job-primary.png" width="20">
              <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                Favorecido/Pagador
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div v-if="entity && entity.id" class="row mb-3 align-items-center">
            <div class="col-12">
              <h4 class="new-default-black-font font-weight-normal m-0">
                {{ entity.entity_name }}
              </h4>
              <h4 class="new-default-black-font font-weight-normal mt-1">
                {{ entity.document }}
              </h4>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/exchange-dollar_primary.png" width="18">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Operação
              </h4>
              <div class="ml-3 text-right" style="width: 100%;">
                <div v-if="finance_type_id"
                     class="divider-header"
                     :class="getOperationType.class">
                  {{ getOperationType.text }}
                </div>
                <hr class="m-0" style="width: 100%;">
              </div>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Tipo de operação <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect
                    placeholder="Tipo de operação"
                    v-model="finance_type_id"
                    :items="formattedFinanceTypes"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :clearable="false"
                    :labelMask="true"
                    :multiple="false"
                    :selectable="(i) => i.extra_field != null"
                    extraField="mode"
                    :loading="loadingFinanceTypes"
                    :disabled="loadingFinanceTypes"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Data do vencimento <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <input-date-time-picker
                    v-model="expire_at"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :mode="'date'"/>
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Valor da parcela <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required|min_value:0.01" v-slot="{errors}">
                <base-input input-group-classes="input-group-sm">
                  <AppInputNumber
                    v-model="amount"
                    prepend="R$"
                    :isInvalid="errors[0] ? true : false"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Número do documento
              </h4>
            </div>
            <div class="col-6">
              <validation-provider>
                <base-input input-group-classes="input-group-sm">
                  <input
                    v-model="document_number"
                    type="text"
                    class="form-control form-control-sm placeholder-opacity"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Método <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <PuzlSelect
                  :items="payment_methods"
                  :clearable=false
                  :multiple="false"
                  :class="errors[0] ? 'is-invalid' : ''"
                  v-model="payment_method_id"
                  :disabled="amount === 0"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12" style="display: flex; align-items: center;">
              <img src="/img/icons/icons8/ios/info-squared_warning.png" width="18">
              <h4 class="new-default-black-font m-0 mx-3 ">
                Identificação
              </h4>
              <hr class="ml-3 m-0" style="width: 100%;">
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Central <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <PuzlSelect
                  :items="$_company_plants"
                  @input="changeBankAccount()"
                  @change="setIssuer()"
                  v-model="company_plant_id"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :loading="loadingCompanies"
                  :multiple="false"
                  :clearable="false"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                CNPJ <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <PuzlSelect
                  @input="changeBankAccount()"
                  v-model="company_plant_issuer_id"
                  :items="company_plant_issuers"
                  label="business_name"
                  :loading="loadingCompanyPlantIssuers"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :clearable="false"
                  :multiple="false"
                  :disabled="!company_plant_id"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Conta bancária <span class="text-danger">&nbsp;*</span>
              </h4>
            </div>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{errors}">
                <PuzlSelect
                  v-model="bank_account_id"
                  :disabled="loadingBankAccounts ||  ($_general_settings.show_bank_account_by_issuer ? !company_plant_issuer_id : !company_plant_id)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :items="bank_accounts"
                  :multiple="false"
                  :clearable="false"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Centro de custo
              </h4>
            </div>
            <div class="col-6">
              <validation-provider>
                <PuzlSelect
                  :items="$_cost_centers"
                  v-model="cost_center_id"
                  :disabled="loadingCostCenters"
                  :multiple="false"
                  :clearable="false"
                />
              </validation-provider>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Observações
              </h4>
            </div>
            <div class="col-6">
              <base-input input-group-classes="input-group-sm">
                <textarea type="text" rows="4"
                          v-model="note"
                          class="form-control form-control-sm" placeholder=" ...">
                </textarea>
              </base-input>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <base-button type="danger"
                           size="sm"
                           style="width: 150px;" @click.prevent="$emit('close')">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/cancel-white.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Cancelar
                  </i>
                </div>
              </base-button>
              <base-button type="primary"
                           size="sm"
                           style="width: 150px;" @click.prevent="save(true)">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/checked--v2.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Salvar e baixar
                  </i>
                </div>
              </base-button>
              <base-button type="success"
                           size="sm"
                           style="width: 150px;"
                           v-bind:disabled="invalid"
                           native-type="submit">
                <div style="display: inline-flex; align-items: center;">
                  <img src="/img/icons/save.png" width="20">
                  <i class="m-0 ml-1" style="font-style: normal;">
                    Salvar
                  </i>
                </div>
              </base-button>
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import PuzlSelect from "@/components/PuzlSelect";
import cursorPaginate from "@/mixins/cursorPaginate";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import { formatErrorValidation } from "@/plugins";
import PaymentMethodEnum from '../../../../../../modules/financial/payment-method/enums/PaymentMethodEnum';
import { AppInputNumber } from '../../../../../../components/AppGlobal';
import { GetBankAccountEnum } from "../../../../../../enum/GetBankAccountEnum";

const FINANCE_ACTION_ENUM = Object.freeze({
  REVERSE: 0,
  CONFIRM: 1,
});

export default {
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    InputDateTimePicker,
    AppInputNumber,
  },
  name: "FinanceForm",
  props: {
    finance: {type: Object},
  },
  computed: {
    ...mapGetters({
      $_company_plants: "plant/activeItems",
      $_financeTypes: "financeTypes/fetch",
      $_cost_centers: "costCenter/fetch",
      plants: "plant/activeItems",
      $_general_settings: "generalSetting/show",
    }),
    getOperationType() {
      let operationType = this.$_financeTypes.find(item => item.id === this.finance_type_id);
      return operationType.mode ?
        {
          text: "pagamento/débito",
          class: "",
        } : {
          text: "Recebimento/crédito",
          class: "text-success"
        };
    },
  },
  data() {
    return {
      modal: false,
      title: 'Operação financeira',
      loading: false,
      loadingCostCenters: true,
      loadingCompanyPlantIssuers: false,
      loadingBankAccounts: true,
      loadingCompanies: true,
      loadingFinanceTypes: true,
      formattedFinanceTypes: [],
      id: null,
      document_number: "",
      note: "",
      company_plant_id: null,
      financeActionEnum: FINANCE_ACTION_ENUM,
      company_plant_issuer_id: null,
      bank_account_id: null,
      cost_center_id: null,
      expire_at: null,
      payment_method_id: null,
      finance_type_id: "",
      finance_installment_id: null,
      amount: 0,
      bank_accounts: [],
      company_plant_issuers: [],
      payment_methods: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      entity: {},
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
    }
  },
  methods: {
    reverseString(str) {
      return str.split('').reverse().join('');
    },
    confirm(finance) {
      this.$store.dispatch("financeInstallments/changeStatus", {
        id: finance.id,
        finance_installment_id: finance.finance_installments[0].id,
        action: this.financeActionEnum.CONFIRM,
        discharge_at: finance.finance_installments[0].expire_at,
        status_reason: finance.finance_installments[0].status_reason,
        bank_account_id: finance.finance_installments[0].bank_account_id,
        payment_method_id: finance.finance_installments[0].payment_method_id
      })
        .then(() => {
          this.$emit('close');
        }).catch(error => {
        if (error.response) {
          const errors = error && error.response && error.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({type: "danger", message: errors});
        }
      });
    },
    save(saveAndConfirm = false) {
      const finance = {
        id: this.id,
        entity_id: this.entity ? this.entity.id : null,
        finance_type_id: this.finance_type_id,
        company_plant_id: this.company_plant_id,
        company_plant_issuer_id: this.company_plant_issuer_id,
        amount: this.amount,
        note: this.note,
        finance_installments: [
          {
            id: this.finance_installment_id,
            status: 0,
            bank_account_id: this.bank_account_id,
            payment_method_id: this.payment_method_id,
            cost_center_id: this.cost_center_id,
            expire_at: this.expire_at,
            expire_at_original: this.expire_at,
            index: 1,
            amount: this.amount,
            document_number: this.document_number,
            note: this.note,
          }
        ],
      };

      const action = this.id ? "finances/update" : "finances/add";

      this.$store
        .dispatch(action, finance)
        .then(response => {
          this.loadingSkeleton = false;
          if (saveAndConfirm) {
            this.confirm(response.data);
          } else {
            this.$emit('close');
          }
        })
        .catch(error => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.data)
            : error.response.data.message;
          this.$notify({type: "danger", message: errors});
          this.loadingSkeleton = false;
        });
    },
    closeModal() {
      this.modal = false;
    },
    async setIssuer() {
      this.company_plant_issuer_id = null;
      this.loadingCompanyPlantIssuers = true;
      this.$store
        .dispatch("companyPlantIssuer/fetchItems", {
          allocated: true,
          company_plant_id: this.company_plant_id,
        })
        .then(async (response) => {
          this.company_plant_issuers = response.data;

          const plant = this.plants.find(
            (item) => item.id === this.company_plant_id
          );
          const default_plant_issuer = plant.company_issuers.find(
            (item) => item.default === 1
          );
          if (default_plant_issuer) {
             this.company_plant_issuer_id = default_plant_issuer.id;
            await this.changeBankAccount()
            this.setInvoice();
          }
          this.loadingCompanyPlantIssuers = false;
        })
        .catch(() => {
          this.loadingCompanyPlantIssuers = false;
        });
    },
    changeBankAccount() {
      if (! this.company_plant_issuer_id || !this.company_plant_id) {
        return
      }
      this.bank_account_id = null;
      this.loadingBankAccounts = true;
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant",
          {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: this.$_general_settings.show_bank_account_by_issuer ? this.company_plant_issuer_id : this.company_plant_id
          }
        )
        .then((response) => {
          this.loadingBankAccounts = false;
            this.bank_accounts = response.data.map(({bank_account: {name}, bank_account: {id}}) => ({name, id}));
          if (!this.$_general_settings.show_bank_account_by_issuer) {
            const company_plant = this.$_company_plants.find(
              (item) => item.id === this.company_plant_id
            );
            this.bank_account_id = company_plant.bank_account_id;
          }
          else {
            this.bank_account_id = response.data.find(item => item.default).bank_account_id
          }
        });
    },
    formatFinanceTypesArray() {
      this.formattedFinanceTypes = [];

      this.formattedFinanceTypes.push({
        name: "Recebimento/Crédito"
      });
      this.formattedFinanceTypes.push(...this.$_financeTypes.filter(item => item.mode === 0));

      this.formattedFinanceTypes.push({
        name: "Pagamento/Débito"
      });

      this.formattedFinanceTypes.push(...this.$_financeTypes.filter(item => item.mode === 1));

      this.loadingFinanceTypes = false;
    },
    fetchBankAccounts(bank_account_id) {
      if (! this.company_plant_issuer_id || !this.company_plant_id) {
        return
      }
      this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant",
          {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: this.$_general_settings.show_bank_account_by_issuer ? this.company_plant_issuer_id : this.company_plant_id
          }
        )
        .then((response) => {
          this.loadingBankAccounts = false;
            this.bank_accounts = response.data.map(({bank_account: {name}, bank_account: {id}}) => ({name, id}));
            this.bank_account_id = bank_account_id;
          if (this.id) this.changeBankAccount();
        });
    },
    fetchOperationTypes() {
      this.$store
        .dispatch("financeTypes/fetchItems", {
          page: this.paginate.nextUrl,
          is_active: 1,
          order_by: [
            {
              column: "finance_types.id"
            },
          ],
          limit: 1000,
        })
        .then(response => {
          this.formatFinanceTypesArray();
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.loadingSkeleton = false;
        });
    },
    fetchPaymentMethods() {
      this.$store
        .dispatch("paymentMethod/fetchItems")
        .then((response) => {
          this.payment_methods = response.data.filter(item =>
            item.display_in_anticipation && item.uuid !== PaymentMethodEnum.keys.COMPENSATION
          );
        });
    },
    fetchCostCenters() {
      this.$store
        .dispatch("costCenter/fetchItems")
        .then(() => {
          this.loadingCostCenters = false;
        });
    },
    fetchCompanies() {
      this.$store
        .dispatch("plant/fetchItemsActive")
        .then((response) => {
          this.loadingCompanies = false;
        });
    },
    fetchAll() {
      this.fetchOperationTypes();
      this.fetchCompanies();
      this.fetchPaymentMethods();
      this.fetchCostCenters();

      if (this.id) {
        this.fetchBankAccounts(this.bank_account_id);
        this.setIssuer(this.company_plant_issuer_id);
      }
    },
  },
 async mounted() {
    await this.$store.dispatch('generalSetting/show')
    this.id = this.finance.id;
    this.entity = this.finance.entity;
    this.note = this.finance.note;
    this.document_number = this.finance.document_number;
    this.company_plant_id = this.finance.company_plant_id;
    this.company_plant_issuer_id = this.finance.company_plant_issuer_id;
    this.bank_account_id = this.finance.bank_account_id;
    this.cost_center_id = this.finance.cost_center_id;
    this.expire_at = this.finance.expire_at;
    this.payment_method_id = this.finance.payment_method_id;
    this.finance_type_id = this.finance.finance_type_id;
    this.finance_installment_id = this.finance.finance_installment_id;
    this.amount = this.finance.amount;
    this.fetchAll();
  },
};
</script>
<style scoped>
.divider-header {
  background: #e1e1e1;
  padding: 3px 20px;
  color: #db4539;
  /*  color: #6b8184;*/
  font-size: 11px;
  width: fit-content;
  display: inline-flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.vs__dropdown-option {
  color: black !important;
}
</style>
