<template>
  <!-- Botao para ser usado somente pelo layout sem a funcionalidade de mudar o setActiveTab, deve ser usado com o setRenderedComponent manualmente -->
  <button
    type="button"
    class="app-tabs-trigger btn-reset"
    v-bind="$attrs"
    v-on="$listeners"
    v-if="props.customTrigger"
    @click="handleCustomClick"
  >
    <AppIcon
      v-if="props.icon"
      :icon="props.icon"
      :color="isActive ? 'white' : props.color"
      width="21px"
    />
    {{ props.title }}
    <AppIcon
      icon="check"
      width="16"
      class="ml-auto"
      color="success"
      v-if="hasIntegration"
    />
    <AppIcon v-else icon="more-than" width="16" class="ml-auto" />
  </button>
  <!-- Botao padrao do tab list -->
  <button
    v-else
    type="button"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[
      'app-tabs-trigger',
      'btn-reset',
      isActive ? 'active' : '',
      $attrs.class,
    ]"
    @click="handleClick"
  >
    <AppIcon
      :icon="props.icon"
      :color="isActive ? 'white' : props.color"
      width="20px"
    />
    {{ props.title }}

    <AppIcon
      icon="check"
      width="16"
      class="ml-auto"
      color="success"
      v-if="hasIntegration"
    />
    <AppIcon
      v-else
      icon="more-than"
      width="10"
      class="ml-auto"
      :color="isActive ? 'white' : 'dark'"
    />
  </button>
</template>

<script setup>
import { defineProps, inject, computed } from "vue";
import AppIcon from "../AppIcon/AppIcon.vue";

const props = defineProps({
  value: {
    type: String,
  },
  icon: {
    type: String,
  },
  title: {
    type: String,
    required: true,
  },
  color: {
    type: String,
  },
  hasIntegration: {
    type: Boolean,
  },
  customTrigger: {
    type: Boolean,
  },
});

// Inject the activeTab and setActiveTab provided by AppTabs
const activeTab = inject("activeTab");
const setActiveTab = inject("setActiveTab");
const setRenderedComponent = inject("setRenderedComponent");
const isMobile = inject("isMobile");

// Compute if this trigger represents the active tab
const isActive = computed(() => activeTab.value === props.value);

function handleCustomClick() {
  setRenderedComponent(props.value, activeTab.value);
}

const handleClick = () => {
  if (isMobile.value) {
    setRenderedComponent(props.value);
  } else {
    setActiveTab(props.value);
  }
};
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
.app-tabs-trigger {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  background-color: white;
  cursor: pointer;
  border: none;
  border-radius: 0 !important;
  text-transform: lowercase;
  gap: 12px;
  font-family: Fredoka;
  font-weight: 400 !important;
  font-size: 16px;
  line-height: 19.36px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;

  &::after {
    background-color: $muted-light;
    position: absolute;
    display: block;
    content: "";
    height: 1px;
    width: calc(100% - 12px);
    bottom: 0;
    right: 0;
  }

  &:hover {
    background-color: $muted-light;
    transform: none !important;
  }

  &.active {
    background-color: $primary;
    border: 1px solid $primary;
    &::after {
      display: none;
    }
  }
}

.app-tabs-trigger:has(+ .active) {
  &::after {
    display: none;
  }
}
</style>
