<template>
  <div>
    <div>
      <PuzlEmptyData v-if="!loadingSkeleton && !$_types.length"></PuzlEmptyData>
    </div>
    <div class="row" v-if="!loadingSkeleton && $_types.length">
      <div class="col-lg-4 mb-n3" v-for="(item, index) in $_types" :key="index">
        <div class="card main-card p-4">
          <div class="d-flex">
            <div>
              <div
                v-if="item.status"
                class="new-minicard-green"
                style="display: inline-flex; align-items: center"
              >
                <img
                  class="mr-2"
                  width="16"
                  src="/img/icons/icons8/ios/ok-grenn.png"
                />
                <h4
                  class="fs-12 font-weight-400 m-0 p-0 mr-2"
                  style="color: #149e57"
                >
                  ATIVO
                </h4>
              </div>
              <div
                v-if="!item.status"
                class="new-minicard-red"
                style="display: inline-flex; align-items: center"
              >
                <img
                  class="mr-2"
                  width="16"
                  src="/img/icons/icons8/ios/shutdown--v1_danger.png"
                />
                <h4
                  class="fs-12 font-weight-400 m-0 p-0 mr-2"
                  style="color: #db4539"
                >
                  INATIVO
                </h4>
              </div>
            </div>
            <base-dropdown
              menuOnRight
              style="display: block; margin-left: auto"
            >
              <base-button
                style="border: none !important; box-shadow: none !important"
                slot="title-container"
                type="invisible"
                class="ml-auto dropdown-toggle rounded app-button"
              >
                <AppIcon icon="settings" width="30" color="primary" />
              </base-button>
              <a
                class="dropdown-item"
                @click.prevent="handleEditType(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/create-new-yellow.png"
                  width="21px"
                  height="21px"
                />
                Editar
              </a>
              <div class="dropdown-divider p-0 m-0"></div>
              <a
                class="dropdown-item"
                @click.prevent="handleDeleteType(item.uuid)"
              >
                <img
                  src="/img/icons/icons8/ios/delete-red.png"
                  width="21px"
                  height="21px"
                />
                Excluir
              </a>
            </base-dropdown>
          </div>
          <div class="font-weight-500 fs-16 mt-3">
            {{ item.technical_description }}
          </div>
          <div class="font-weight-400 fs-15">
            {{ item.commercial_description }}
          </div>
          <div
            style="height: 0.5px; background-color: #e8e8e8"
            class="mt-2"
          ></div>
          <div
            v-if="item.specific_gravity_start || item.specific_gravity_start"
            class="font-weight-400 fs-15 mt-2"
          >
            {{ item.specific_gravity_start }} até
            {{ item.specific_gravity_end }}
            (kg/m<sup>3</sup>)
          </div>
          <div v-else class="font-weight-400 fs-15 mt-2">-</div>
        </div>
      </div>
    </div>
    <edit-type ref="editType"></edit-type>
  </div>
</template>

<script>
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import EditType from "./_Edit";
import { formatErrorValidation } from "@/plugins";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { mapGetters } from "vuex";
export default {
  name: "List",
  props: {
    loadingSkeleton: Boolean,
  },
  components: {
    SkeletonPuzlGrid,
    EditType,
    AppIcon,
  },
  data() {
    return {
      loadingSkeleton: false,
      filter: {},
    };
  },
  computed: {
    ...mapGetters({
      $_types: "mixType/fetch",
    }),
  },
  methods: {
    handleEditType(uuid) {
      this.$refs.editType.handleEditModal(uuid);
    },
    handleDeleteType(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("mixType/destroy", uuid)
              .then((response) => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message:
                      "Existem tipos de formulação vinculados a este registro, exclusão não permitida.",
                  });
                  this.$Progress.finish();
                } else if (error.response && error.response.status === 422) {
                  let errors = formatErrorValidation(
                    error.response.data.errors
                  );
                  this.$notify({
                    type: "danger",
                    message: error.response.message,
                  });
                }
                this.$Progress.finish();
              });
            this.$Progress.finish();
          }
        })
        .catch(() => this.$Progress.finish());
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}

.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  border: 1px solid #ececec;
}

.new-minicard-green {
  background-color: #f2f7f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.new-minicard-red {
  background-color: #fcf3f3;
  border-radius: 15px;
  padding: 5px 12px;
}

.app-button {
  padding: 0 !important;
}
</style>
