const keys = Object.freeze({
  CREATED_AT: 0,
  APPROVED: 1,
  REVIEW: 2,
  CANCELED: 3,
});

/**
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.CREATED_AT:
      return "Criação";
    case keys.APPROVED:
      return "Aprovado";
    case keys.REVIEW:
      return "Revisão";
    case keys.CANCELED:
      return "Cancelado";
    default:
      return "";
  }
};

export const EntityBillReceiveHistoryStatusEnum = {
  keys,
  getTitle,
};
