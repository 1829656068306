<template>
  <div>
    <div class="col-auto">
      <span
        :class="['mini-card-' + getStatusColor()]"
        class="badge font-weight-normal fs-14 py-2 px-4 d-flex align-items-center justify-content-center"
        :role="canChangeStockStatus() ? 'button' : null"
        @click="canChangeStockStatus() && changeStockStatus()"
      >
        <AppIcon :color="getStatusColor()" class="mr-2" width="22" icon="big-parcel" />
        <span style="padding-top: 1px">{{ EntryLaunchItemStockStatusEnum.getTitle(props.stockStatus) }}</span>
      </span>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { ref, defineEmits, defineProps } from "vue";
import { EntryLaunchItemStockStatusEnum } from "../../enums/EntryLaunchItemStockStatusEnum";
import { AppIcon } from "../../../../../components/AppGlobal";
import ProductServiceHubTypeEnum from "../../../product-service-hub-type/enums/ProductServiceHubTypeEnum";
//#endregion

//#region Emits
const emit = defineEmits(["onChange"]);
//#endregion

//#region Props
const props = defineProps({
  stockStatus: {
    type: Number,
    required: true,
  },
  productServiceHubTypeId: {
    type: [Number],
    required: true,
  },
  operationSourceIsGenerateStock: {
    type: Boolean,
    required: true,
  },
});
//#endregion

//#region Methods
/**
 * Retorna a cor correspondente ao status do estoque.
 *
 * @returns {string} - A cor correspondente ao status do estoque.
 */
function getStatusColor() {
  switch (props.stockStatus) {
    case EntryLaunchItemStockStatusEnum.keys.NO_STOCK:
      return "danger";
    case EntryLaunchItemStockStatusEnum.keys.STOCK:
      return "primary";
    case EntryLaunchItemStockStatusEnum.keys.USE_AND_CONSUMPTION:
      return "success";
    default:
      return "";
  }
}

/**
 * Altera o status do estoque e emite um evento "onChange" com o novo status.
 */
function changeStockStatus() {
  const newStockStatus =
    props.stockStatus === EntryLaunchItemStockStatusEnum.keys.STOCK
      ? EntryLaunchItemStockStatusEnum.keys.USE_AND_CONSUMPTION
      : EntryLaunchItemStockStatusEnum.keys.STOCK;

  emit("onChange", newStockStatus);
}

/**
 * Verifica se o status do estoque pode ser alterado.
 *
 * @returns {boolean} - Verdadeiro se o status do estoque pode ser alterado, falso caso contrário.
 */
function canChangeStockStatus() {
  return props.operationSourceIsGenerateStock && props.productServiceHubTypeId === ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE;
}
//#endregion
</script>

<style scoped>
.mini-card-primary,
.mini-card-danger,
.mini-card-success {
  box-shadow: none !important;
  border-radius: 18px;
}

.mini-card-success {
  color: #149e57;
}

.mini-card-danger {
  color: #db4539;
}
</style>
