import { BaseListFilterType, initBaseListFilterType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} EntityListFilterExtType
 * @property {null|0|1} status - [null..0..1] Todos: null Inativos: 0 Ativos: 1
 * @property {null|1} physical_person - [null..1] Todos: null Pessoas Físicas: 1
 * @property {null|1} legal_person - [null..1] Todos: null Pessoas Jurídicas: 1
 * @property {null|string} state - Sigla do estado
 * @property {null|string} city - Nome da cidade
 * @property {null|1} is_fuel - [null..1] Todos: null Fornecedor de Combustível: 1
 * @property {null|1} has_sync_error - [null..1] Todos: null, possui erro de integração: 1
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & EntityListFilterExtType)} EntityListFilterType
 */
export const EntityListFilterType = {};

/**
 * Inicializar EntityListFilterType
 *
 * @returns {EntityListFilterType}
 */
export const initEntityListFilterType = () => ({
  ...initBaseListFilterType(),
  order_by: [{ column: "entities.id", is_desc: false }],
  custom_search: {
    columns: ["entities.entity_name", "entities.document"],
    values: [],
  },
  status: null,
  physical_person: null,
  legal_person: null,
  state: null,
  city: null,
  is_fuel: null,
  has_sync_error: null,
});