<template>
  <div class="app-search-bar-filter-section container">
    <div class="ef-section-name row align-items-center">
      <div class="ef-section-title">
        <img v-if="isPng" :src="props.icon" width="20" />
        <AppIcon v-else :icon="props.icon" width="20" :color="props.color" />
        <h3 class="new-default-black-font">{{ props.name }}</h3>
      </div>
    </div>
    <div class="row ef-section-item-column">
      <!-- inputs -->
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import AppIcon from "../AppIcon/AppIcon.vue";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {String} name
 * @property {String} icon
 * @property {String} color
 */
/** @type {Props} */
const props = defineProps({
  name: {
    type: String,
    default: "",
    required: false,
  },
  icon: {
    type: String,
    default: "/img/icons/icons8/ios/progress-indicator_grey.png",
    required: false,
  },
  color: {
    type: String,
    default: "dark",
    required: false,
  },
});

const isPng = computed(() => {
  return props.icon.toLowerCase().endsWith(".png");
});
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.app-search-bar-filter-section {
  :last-child{
    margin-bottom: 0;
  }
  .ef-section-item-column {
    label {
      font-family: Fredoka;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $dark;
      margin-bottom: 4px;
    }
    .app-input, textarea, .vs__dropdown-toggle, button {
      border-color: $muted-medium;
    }
    .v-select,
    .vs__dropdown-toggle {
      height: auto !important;
      min-height: 32px !important;
    }
    .vs__selected {
      height: 22px;
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
.app-search-bar-filter-section {
  margin-bottom: 24px;
}
.ef-section-name {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.ef-section-title {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 0px !important;
  margin-bottom: 8px;
}

h3 {
  @include rhythm(font-size, 14);
  margin-bottom: 0;
}

.ef-section-item-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 12px;
  border: 1px solid $muted-lighter;
  border-radius: 8px;
  padding: 12px;
}
</style>
