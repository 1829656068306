const keys = Object.freeze({
  LATEST_UPDATED_AT: "latest_updated_at",
  CREATED_AT: "created_at",
});

/**
 * @param {number} value
 * @returns {string}
 */
const getTitle = (value) => {
  switch (value) {
    case keys.LATEST_UPDATED_AT:
      return "latest_updated_at";
    case keys.CREATED_AT:
      return "created_at";
    default:
      return "";
  }
};


export default {
  keys,
  getTitle
};
