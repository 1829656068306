import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import auth from "@/middleware/auth";
import CashFlow from '@/views/Modules/Financial/CashFlow/Index';
import BankReconciliation from '@/views/Modules/Financial/BankReconciliation/Index';
import BankReconciliationTransaction
  from '@/views/Modules/Financial/BankReconciliation/Shared/BankReconciliationTransaction/Index';
import BankAccount from '@/views/Modules/Financial/CashFlow/BankAccount/Index';
import BillReceive from '@/views/Modules/Financial/BillReceive/Index';
import EntityBillReceive from '@/views/Modules/Financial/BillReceive/Entity/EntityIndex';
import ApprovalRoute from '../../views/Modules/Financial/Approval/routes/financialApprovalRoute';
import PaymentIntermediary from '@/views/Modules/Financial/BillReceive/PaymentIntermediary/Index';
import PaymentTerm from '@/views/Modules/Financial/BillReceive/PaymentTerm/Index';
import EditPaymentTerm from '@/views/Modules/Financial/BillReceive/PaymentTerm/Shared/_Edit';
import CreatePaymentTerm from '@/views/Modules/Financial/BillReceive/PaymentTerm/Shared/_Create';
import Billing from '@/views/Modules/Financial/Billing/Index';
import AccountAdjustment from '@/views/Modules/Financial/AccountAdjustment/Index';
import CashierResume from '@/views/Modules/Financial/CashierResume/Index';
import BankTransfer from '@/views/Modules/Financial/BankTransfer/Index';
import BillingProposal from '@/views/Modules/Financial/Billing/BillingProposal/Index';
import BillPay from '@/views/Modules/Financial/BillPay/Index';
import NFeIssuedes from '@/views/Modules/Financial/NFe/Issuedes/Index';
import BankBilletIssuedes from '@/views/Modules/Financial/BillReceive/BankBillet/Issuedes/Index';
import DRE from '@/views/Modules/Financial/DRE/Index';
import FinancialDashboard from "@/views/Modules/Financial/Dashboard/Index";
import AllotmentsInvoice from "../../modules/financial/allotments/invoice/routes/invoiceRoute"
import AccountStatement from "@/views/Modules/Financial/AccountStatement/Index";
import BankBalance from "@/views/Modules/Financial/BankBalance/Index";
import GeneralResume from '@/views/Modules/Financial/GeneralResume/Index.vue';
import Detached from '../../../src/views/Modules/Financial/Billing/Detached/routes/DetachedRoute.js';
import CreditRelease from '@/views/Modules/Financial/CreditRelease/Index.vue';
import ProductionResume from '@/views/Modules/Administrative/Resume/Index.vue';
import DriverResume from '@/views/Modules/Administrative/DriverResume/Index.vue';
import EquipmentResume from '@/views/Modules/Administrative/EquipmentResume/Index.vue';
import MCCResume from '@/views/Modules/Administrative/MCCResume/Index.vue';
import AnticipationBalances from '@/views/Modules/Financial/BillReceive/AnticipationBalances/Index';
import CardConciliation from '@/views/Modules/Financial/CardConciliation/Index';
import FinanceType from '@/views/Modules/Financial/Finance/FinanceType/Index';
import Finances from '@/views/Modules/Financial/Finance/Index';
import FinanceTransaction from '@/views/Modules/Financial/FinanceTransaction/Index';
import paymentMethodRoute from '../../modules/financial/payment-method/routes/paymentMethodRoute';
import BankReconciliationRoute from '../../modules/financial/bank-reconciliation/routes/BankReconciliation';

const financial = {
    path: '/financial',
    component: DashboardLayout,
    name: 'Financial',
    meta: {
      middleware: auth,
    },
    children: [
      ...BankReconciliationRoute,
      {
        path: '/cash-flow/bank-balance',
        name: 'cash_flow.bank_balance',
        component: BankBalance,
        meta: {
          title: 'Financeiro',
          description: 'Saldo de contas',
          icon: 'fa fa-home',
          global_filter: true,
          permission: 'cash_flow.bank-balance.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: ''},
            {title: 'Saldo de contas', name: null},
          ]
        },
      },
      {
        path: '/cash_flow/bank-reconciliation/transaction/:bank_reconciliation_id',
        name: 'cash_flow.bank_reconciliation.transaction.view',
        component: BankReconciliationTransaction,
        meta: {
          title: 'Financial',
          description: 'Conciliação Bancária (OFX)',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bank-reconciliation.transaction.dashboard.index'},
            {title: 'Conciliação Bancária (OFX)', name: null},
          ]
        },
      },
      {
        path: '/financial/cash-flow',
        name: 'financial.cash_flow.view',
        component: CashFlow,
        meta: {
          title: 'Financial',
          description: 'Fluxo de Caixa',
          icon: 'fa fa-home',
          permission: 'financial.cash-flow.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.cash_flow.dashboard.index'},
            {title: 'Fluxo de Caixa', name: null},
          ]
        },
      },
      {
        path: '/financial/cash-flow/bank-account',
        name: 'financial.bank-account.view',
        component: BankAccount,
        meta: {
          title: 'BankAccount',
          description: 'Contas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bank-account.dashboard.index'},
            {title: 'Contas', name: null},
          ]
        },
      },
      {
        path: '/financial/bill-receive',
        name: 'financial.bill_receive.view',
        component: BillReceive,
        meta: {
          title: 'Financeiro',
          description: 'Contas a Receber',
          icon: 'fa fa-home',
          permission: 'financial.bill-receive.index.view',
          global_filter: false,
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bill_receive.dashboard.index'},
            {title: 'Contas a Receber', name: null},
          ]
        },
      },
      {
        path: '/financial/entity-bill-receive',
        name: 'financial.entity_bill_receive.view',
        component: EntityBillReceive,
        meta: {
          title: 'Financeiro',
          description: 'Creditos e antecipações',
          icon: 'fa fa-home',
          permission: 'financial.entity-bill-receive.index.view',
          global_filter: false,
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.entity-bill_receive.dashboard.index'},
            {title: 'Créditos e antecipações', name: null},
          ]
        },
      },
      ...ApprovalRoute,
      ...paymentMethodRoute,
      {
        path: '/financial/bill-receive/payment-intermediary',
        name: 'financial.bill_receive.payment_intermediary.view',
        component: PaymentIntermediary,
        meta: {
          title: 'Financeiro',
          description: 'Operador/Bandeira/Financiadora',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bill_receive.payment_intermediary.dashboard.view'},
            {title: 'Mediador de pagamento', name: null},
          ]
        },
      },
      {
        path: '/financial/bill-receive/payment-term',
        name: 'financial.bill_receive.payment_term.view',
        component: PaymentTerm,
        meta: {
          title: 'Financeiro',
          description: 'Condições de Pagamento',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bill_receive.payment_term.dashboard.view'},
            {title: 'Condições de Pagamento', name: null},
          ]
        },
      },
      {
        path: '/financial/bill-receive/payment-term/edit/:id',
        name: 'Editar condição de pagamento',
        component: EditPaymentTerm
      },
      {
        path: '/financial/bill-receive/payment-term/create',
        name: 'Nova condição de pagamento',
        component: CreatePaymentTerm
      },
      {
        path: '/billing/billed',
        name: 'billing.billed',
        component: Billing,
        meta: {
          title: 'Faturamento',
          description: 'Faturado/NFs-e',
          icon: 'fa fa-home',
          global_filter: true,
          permission: 'billing.billed.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: ''},
            {title: 'Faturado/NFs-e', name: null},
          ]
        },
      },
      ...Detached,
      {
        path: '/billing/bank-billet',
        name: 'billing.bank-billet',
        component: BankBilletIssuedes,
        meta: {
          title: 'Faturamento',
          description: 'Boletos',
          icon: 'fa fa-home',
          permission: 'billing.bank-billet.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'billing.dashboard.index'},
            {title: 'Boletos', name: null},
          ]
        },
      },
      {
        path: '/cash-flow/account-adjustment',
        name: 'financial.bank_balance',
        component: AccountAdjustment,
        meta: {
          title: 'Fluxo de caixa',
          description: 'Ajuste de contas',
          icon: 'fa fa-home',
          global_filter: true,
          permission: 'cash_flow.bank-balance.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: ''},
            {title: 'Saldo Bancário', name: null},
          ]
        },
      },
      {
        path: '/cash-flow/cashier-resume',
        name: 'financial.cashier_resume',
        component: CashierResume,
        meta: {
          title: 'Fluxo de caixa',
          description: 'Resumo de Caixa',
          icon: 'fa fa-home',
          global_filter: true,
          permission: 'cash_flow.cashier-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: ''},
            {title: 'Resumo de Caixa', name: null},
          ]
        },
      },
      {
        path: '/cash-flow/bank-balance/bank-transfer',
        name: 'financial.bank_balance.bank_transfer',
        component: BankTransfer,
        meta: {
          title: 'Fluxo de caixa',
          description: 'Transferências Bancárias',
          icon: 'fa fa-home',
          global_filter: true,
          breadcrumbs: [
            {title: 'Saldo Bancário', name: ''},
            {title: 'Transferências Bancárias', name: null},
          ]
        },
      },
      {
        path: '/financial/billing/proposal/:contract_proposal_uuid',
        name: 'financial.billing.proposal',
        component: BillingProposal,
        meta: {
          title: 'Financeiro',
          description: 'Fatura',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: ''},
            {title: 'Faturamento', name: null},
          ]
        },
      },

      {
        path: '/financial/bill-pay',
        name: 'financial.bill-pay.view',
        component: BillPay,
        meta: {
          global_filter: false,
          title: 'Financeiro',
          description: 'Contas a pagar',
          icon: 'fa fa-home',
          permission: 'financial.bill-pay.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bill-pay.dashboard.index'},
            {title: 'Contas a pagar', name: null},
          ]
        },
      },
      {
        path: '/financial/nfe_issuedes',
        name: 'financial.nfe-issuedes.view',
        component: NFeIssuedes,
        meta: {
          permission: 'fiscal.nfe_issuedes.index.view',
          title: 'Financeiro',
          description: 'NF-e Emitidas',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.dashboard.index'},
            {title: 'NF-e Emitidas', name: null},
          ]
        },
      },
      {
        path: '/financial/bill-receive/bank-billet-issuedes',
        name: 'financial.bill_receive.bank_billet_issuedes.view',
        component: BankBilletIssuedes,
        meta: {
          title: 'Financeiro',
          description: 'Boletos Emitidos',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.bill_receive.dashboard.index'},
            {title: 'Boletos Emitidos', name: null},
          ]
        },
      },
      {
        path: '/administrative/dre-puzl',
        name: 'administrative.dre-puzl',
        component: DRE,
        meta: {
          title: 'Gerencial',
          description: 'DRE Puzl',
          icon: 'fa fa-home',
          global_filter: true,
          permission: 'administrative.dre-puzl.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'DRE Puzl', name: null},
          ]
        },
      },
      {
        path: '/financial/dashboard',
        name: 'financial.dashboard.view',
        component: FinancialDashboard,
        meta: {
          title: 'Financeiro',
          description: 'Dashboard financeiro',
          icon: 'fa fa-home',
          permission: 'financial.dashboard.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.dashboard.index'},
            {title: 'Dashboard', name: null},
          ]
        },
      },
      ...AllotmentsInvoice,
      {
        path: '/cash-flow/account-statement',
        name: 'financial.account_statement.view',
        component: AccountStatement,
        meta: {
          title: 'Fluxo de caixa',
          description: 'Extratos',
          icon: 'fa fa-home',
          permission: 'cash_flow.account-statement.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.account_statement.index'},
            {title: 'Extratos', name: null},
          ]
        },
      },
      {
        path: '/administrative/general-resume',
        name: 'administrative.general-resume.index.view',
        component: GeneralResume,
        meta: {
          title: 'Gerencial',
          description: 'Resumo Geral',
          icon: 'fa fa-home',
          permission: 'administrative.general-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'Resumo Geral', name: null},
          ]
        },
      },
      {
        path: '/administrative/production-resume',
        name: 'administrative.production-resume.index.view',
        component: ProductionResume,
        meta: {
          title: 'Gerencial',
          description: 'Resumo de produção',
          icon: 'fa fa-home',
          permission: 'administrative.production-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'Resumo de produção', name: null},
          ]
        },
      },
      {
        path: '/administrative/driver-resume',
        name: 'administrative.driver-resume.index.view',
        component: DriverResume,
        meta: {
          title: 'Gerencial',
          description: 'Resumo de motoristas',
          icon: 'fa fa-home',
          permission: 'administrative.driver-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'Resumo de motoristas', name: null},
          ]
        },
      },
      {
        path: '/administrative/equipment-resume',
        name: 'administrative.equipment-resume.index.view',
        component: EquipmentResume,
        meta: {
          title: 'Gerencial',
          description: 'Resumo de equipamento',
          icon: 'fa fa-home',
          permission: 'administrative.equipment-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'Resumo de equipamento', name: null},
          ]
        },
      },
      {
        path: '/administrative/mcc-resume',
        name: 'administrative.mcc-resume.index.view',
        component: MCCResume,
        meta: {
          title: 'Gerencial',
          description: 'Resumo de MCC',
          icon: 'fa fa-home',
          permission: 'administrative.mcc-resume.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'administrative.dashboard.index'},
            {title: 'Resumo de MCC', name: null},
          ]
        },
      },
      {
        path: '/financial/credit-release',
        name: 'financial.credit-release.index.view',
        component: CreditRelease,
        meta: {
          title: 'Financial',
          description: 'Liberação de Crédito',
          icon: 'fa fa-home',
          permission: 'financial.credit-release.index.view',
          global_filter: false,
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.dashboard.index'},
            {title: 'Liberação de Crédito', name: null},
          ]
        },
      },
      {
        path: '/financial/bill-receive/antecipation-balances',
        name: 'financial.bill-receive-antecipation-balance.index.view',
        component: AnticipationBalances,
        meta: {
          title: 'Financial',
          description: 'Saldos de antecipação',
          icon: 'fa fa-home',
          permission: 'financial.bill-receive.index.view',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.dashboard.index'},
            {title: 'Saldos de antecipação', name: null},
          ]
        },
      },
      {
        path: '/financial/card-conciliation',
        name: 'financial.card-conciliation.index.view',
        component: CardConciliation,
        meta: {
          title: 'Financial',
          description: 'Conciliação de cartões',
          permission: 'cash_flow.electronic-bank-statement.index.view',
          icon: 'fa fa-home',
          breadcrumbs: [
            {title: 'Dashboard', name: 'financial.dashboard.index'},
            {title: 'Conciliação de cartões', name: null},
          ]
        },
      },
      {
        path: '/financial/finances',
        name: 'financial.finances.index.view',
        component: Finances,
        meta: {
          title: 'Financial',
          description: 'Operações Financeiras',
          permission: 'financial.finances.index.view',
          icon: 'fa fa-home',
        },
      },
      {
        path: '/financial/finance-types',
        name: 'financial.finance-type.index.view',
        component: FinanceType,
        meta: {
          title: 'Financial',
          description: 'Tipo de Operação Financeira',
          permission: 'financial.finances.index.view',
          icon: 'fa fa-home',
        },
      },
      {
        path: '/cash-flow/finance-transactions',
        name: 'cash_flow.finance-transactions.index.view',
        component: FinanceTransaction,
        meta: {
          title: 'Financial',
          description: 'Conciliação Financeira',
          permission: 'cash_flow.finance-transactions.index.view',
          icon: 'fa fa-home',
        },
      },
    ]
  }
;

export default financial;
