const keys = Object.freeze({
  RECEIVED_AND_APPROVED: 8,
  CANCELED: 3,
  REVIEW: 100,
});

const getTitle = (value) => {
  switch (value) {
    case keys.RECEIVED_AND_APPROVED:
      return "Recebido e aprovado";
    case keys.REVIEW:
      return "Revisão";
    case keys.CANCELED:
        return "Cancelado";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
