/**
 * Type para Incluir/Editar/Visualizar permissões comerciais do usuário
 *
 * @typedef {Object} UserCommercialPermissionType
 * @property {number | null} user_id - ID do usuário.
 * @property {boolean} minimum_price_approval - Aprovação de preço mínimo.
 * @property {boolean} limit_variation - Permite variação de limite.
 * @property {boolean} allowed_variation - Permite variação permitida.
 * @property {boolean} change_contract_situation - Permite alteração da situação do contrato.
 * @property {boolean} view_margin_details - Permite visualizar detalhes de margem.
 * @property {boolean} change_sale_price_of_delivered_trip - Permite alteração do preço de venda da viagem entregue.
 * @property {boolean} edit_additional_values - Permite edição de valores adicionais.
 * @property {boolean} contract_editor_and_seller - Editor de contrato e vendedor.
 * @property {boolean} is_supplementary_info_before_today - Informações suplementares antes de hoje.
 * @property {boolean} ignore_digital_signature - Ignorar assinatura digital.
 * @property {boolean} is_receipt_editing_allowed - Permite edição de recibo.
 * @property {boolean} is_billing_document_modifiable - Permite modificação do documento de faturamento.
 * @property {boolean} is_edit_intermediary_seller_field - Permite edição do campo de vendedor intermediário.
 * @property {number} created_by_user_id - ID do usuário que criou.
 * @property {number|null} updated_by_user_id - ID do usuário que atualizou.
 * @property {string} margin_sell_limit - Limite de margem de venda.
 * @property {string} margin_sell_limit_percentage - Percentual do limite de margem de venda.
 * @property {string} margin_machining_limit - Limite de margem de usinagem.
 * @property {string} margin_machining_limit_percentage - Percentual do limite de margem de usinagem.
 * @property {string} minimum_price_service_limit - Limite de preço mínimo do serviço.
 * @property {string} minimum_price_service_limit_percentage - Percentual do limite de preço mínimo do serviço.
 * @property {string} minimum_price_m3_service_limit - Limite de preço mínimo por m³ de serviço.
 * @property {string} minimum_price_m3_service_limit_percentage - Percentual do limite de preço mínimo por m³ de serviço.
 * @property {string} minimum_price_hour_service_limit - Limite de preço mínimo por hora de serviço.
 * @property {string} minimum_price_hour_service_limit_percentage - Percentual do limite de preço mínimo por hora de serviço.
 */
export const UserCommercialPermissionType = {};

/**
 * Inicializar UserCommercialPermissionType
 * 
 * @returns {UserCommercialPermissionType}
 */
export const initUserCommercialPermissionType = () => {
    return {
        user_id: null,
        minimum_price_approval: false,
        limit_variation: false,
        allowed_variation: false,
        change_contract_situation: false,
        view_margin_details: false,
        change_sale_price_of_delivered_trip: false,
        edit_additional_values: false,
        contract_editor_and_seller: false,
        is_supplementary_info_before_today: false,
        ignore_digital_signature: false,
        is_receipt_editing_allowed: false,
        is_billing_document_modifiable: false,
        is_edit_intermediary_seller_field: false,
        created_by_user_id: null,
        updated_by_user_id: null,
        margin_sell_limit: "0.00",
        margin_sell_limit_percentage: "0.0",
        margin_machining_limit: "0.00",
        margin_machining_limit_percentage: "0.0",
        minimum_price_service_limit: "0.00",
        minimum_price_service_limit_percentage: "0.0",
        minimum_price_m3_service_limit: "0.00",
        minimum_price_m3_service_limit_percentage: "0.0",
        minimum_price_hour_service_limit: "0.00",
        minimum_price_hour_service_limit_percentage: "0.0",
    };
};
