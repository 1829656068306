<template>
  <div v-if="!isFinding">
    <modal size="lg"
      :show.sync="isOpen"
      :backgroundColor="'#FEF9F2'"
      :showClose="false"
    >
    <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/create-new.png" alt="edit-modal" width="20px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>

      <PaymentMethodForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref } from "vue";
import { PaymentMethodType, initPaymentMethodType } from "../../types";
import paymentMethodStore from "../../store/paymentMethodStore.js";
import { dialogs, progress } from "../../../../../helpers";
import PaymentMethodForm from "../shared/PaymentMethodForm.vue";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Editar Método de Pagamento";
/** @type {PaymentMethodType} */
const formData = reactive(initPaymentMethodType());
//#endregion

//#region Computeds
/** Store Getters */
const isFinding = computed(() => paymentMethodStore.getters.getIsFinding());

//#region Methods
/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  paymentMethodStore.actions.find(id)
    .then((data) => Object.assign(formData, data))
    .finally(() => progress.finish());
}

/** Atualizar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  paymentMethodStore.actions.update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} PaymentMethodEditModalExpose
 * @property {typeof openModal} openModal
 */
 defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped></style>
