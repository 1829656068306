<template>
  <div>
    <modal :show.sync="modal.create" :backgroundColor="'#fef9f2'" size="lg">
      <template slot="header">
        <div
          class="d-flex align-items-center container-fluid"
          style="gap: 12px; margin-left: -28px"
        >
          <div>
            <AppIcon
              icon="phone-contact"
              color="warning"
              width="31px"
              class="mr-3"
            />
          </div>
          <span class="text-title mt-n1">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
        />
      </template>
      <div class="p-2">
        <div class="container">
          <!-- Contatos do Cliente -->
          <div v-if="contractProposal.entity || contractProposal.entity_id" class="contacts-section mb-4">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <AppIcon
                  icon="business-building"
                  color="success"
                  width="26"
                  class="mr-2"
                />
                <h6 class="mb-0 font-weight-bold" style="font-size: 16px;">Contatos do cliente</h6>
              </div>
              <AppButton
                label="Novo"
                type="success"
                size="sm"
                icon="plus-math"
                rounded="xxl"
                width="120px"
                @click.prevent="openModalcreateContact(
                  'entity',
                  contractProposal.entity?.id ?? contractProposal.entity_id
                )"
              />
            </div>
            <div class="outer-border">
              <div class="row">
                <div
                  v-for="(contact, index) in $_contactsEntity"
                  class="col-md-6"
                >
                  <div class="content-card position-relative">
                    <div class="config-button position-absolute">
                      <base-dropdown menuOnRight class="action-button">
                        <div slot="title-container" class="dropdown-toggle m-0">
                          <AppIcon
                            icon="settings"
                            color="primary"
                            width="30"
                            class="rotate-icon"
                          />
                        </div>
                        <a
                          class="dropdown-item"
                          @click="openModalEditContacts('entity', contact.id)"
                        >
                          <AppIcon
                            icon="create"
                            color="warning"
                            width="20"
                            class="mr-2"
                          />
                          Editar
                        </a>
                      </base-dropdown>
                    </div>
                    <div class="contact-info">
                      <p class="mb-0 label-title mb-1">{{ contact.name }}</p>
                      <p class="mb-0 label-item">{{ contact.email }}</p>
                      <p class="mb-0 label-item mt-1">{{ contact.phone }}</p>
                      <hr class="my-3" />
                      <p class="mb-0 label-item mt-3">{{ contact.office }}</p>
                      <p class="mb-0 label-item mt-1" v-if="contact.signature_type != 'nenhum'">
                        {{ contact.signature_type }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Contatos da Obra -->
          <div class="contacts-section">
            <div class="d-flex align-items-center justify-content-between mb-3">
              <div class="d-flex align-items-center">
                <AppIcon
                  icon="brick-wall"
                  color="warning"
                  width="26"
                  class="mr-2"
                />
                <h6 class="mb-0 font-weight-bold" style="font-size: 16px;">Contatos da obra</h6>
              </div>
              <AppButton
                label="Novo"
                type="success"
                size="sm"
                icon="plus-math"
                rounded="xxl"
                width="120px"
                @click.prevent="openModalcreateContact(
                  'construction',
                  null,
                  contractProposal.construction?.uuid ?? contractProposal.customer_construction_uuid
                )"
              />
            </div>
            <div class="outer-border">
              <div class="row">
                <div
                  v-for="(contact, index) in $_contactsConstruction"
                  class="col-md-6"
                >
                  <div class="content-card position-relative">
                    <div class="config-button position-absolute">
                      <base-dropdown menuOnRight class="action-button">
                        <div slot="title-container" class="dropdown-toggle m-0">
                          <AppIcon
                            icon="settings"
                            color="primary"
                            width="30"
                            class="rotate-icon"
                          />
                        </div>
                        <a
                          class="dropdown-item"
                          @click="openModalEditContacts('construction', contact.uuid)"
                        >
                          <AppIcon
                            icon="create"
                            color="warning"
                            width="20"
                            class="mr-2"
                          />
                          Editar
                        </a>
                      </base-dropdown>
                    </div>
                    <div class="contact-info">
                      <p class="mb-0 label-title mb-1">{{ contact.name }}</p>
                      <p class="mb-0 label-item">{{ contact.email }}</p>
                      <p class="mb-0 label-item mt-1">
                        {{ contact.cell_phone || contact.phone }}
                      </p>
                      <hr class="my-3" />
                      <p class="mb-0 label-item mt-3">{{ contact.office }}</p>
                      <p class="mb-0 label-item mt-2" v-if="contact.signature_type != 'nenhum'">
                        {{ contact.signature_type }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4 mb-2">
          <div class="col-12 text-right">
            <AppButton
              label="Fechar"
              type="dark"
              class="ml-2"
              @click="closeModal()"
              :isDisabled="invalid"
              :hasOutline="true"
            />
          </div>
        </div>
      </div>
    </modal>
    <ModalCreateContact ref="createContact" />
    <ModalEditContacts @contactEntitySaved="updateEntityListAfterUpdate" ref="editContact" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppButton from "../../../../../../components/AppGlobal/AppButton/AppButton.vue";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import ModalCreateContact from "./_ModalCreateContacts.vue";
import ModalEditContacts from "./_ModalEditContacts.vue";

export default {
  name: "ModalListContacts",
  components: {
    AppButton,
    AppIcon,
    ModalCreateContact,
    ModalEditContacts,
  },
  computed: {
    ...mapGetters({
      $_contactsEntity: "entityContact/fetchContacts",
      $_contactsConstruction: "customerConstructionConstructionsContact/fetch",
    }),
  },
  data() {
    return {
      modal: {
        title: "Contatos",
        create: false,
      },
      contractProposal: null,
      invalid: false,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * Abre modal de listagem de contatos
     * @param {object} contractProposal
     */
     openModal(contractProposal) {
      this.modal.create = true;
      this.contractProposal = contractProposal;

      const entityId = contractProposal.entity?.id ?? contractProposal.entity_id;
      const constructionUuid = contractProposal.construction?.uuid ?? contractProposal.customer_construction_uuid;

      if (entityId) {
        this.getContactsByEntityId(entityId);
      }
      if (constructionUuid) {
        this.getConstructionContacts(constructionUuid);
      }
    },
    /**
     * @param {number} entityId
     */
    getContactsByEntityId(entityId) {
      this.$store.dispatch("entityContact/getByEntityId", entityId);
    },
    /**
     * @param {string} constructionUuid
     */
    getConstructionContacts(constructionUuid) {
      this.$store.dispatch("customerConstructionConstructionsContact/getContacts", constructionUuid);
    },
    /**
     * @param {string} form_origin ('entity' ou 'construction')
     * @param {number} entity_id
     * @param {string} construction_uuid
     */
    openModalcreateContact(form_origin = null, entity_id = null, construction_uuid = null) {
      this.$refs.createContact.openModal(
        form_origin,
        entity_id,
        construction_uuid
      );
    },
    /**
     * @param {string} form_origin ('entity' ou 'construction')
     * @param {number|string} primary_key_contact
     */
    openModalEditContacts(form_origin = null, primary_key_contact) {
      this.$refs.editContact.openModal(form_origin, primary_key_contact);
    },
    /**
     * Atualiza o card após atualização de um contato
     * @param {object} item
     */
    updateEntityListAfterUpdate(item) {
      const foundEntity = this.$_contactsEntity.find(
        (contact) => contact.id === item.entityData.id
      );
      if (foundEntity) {
        Object.assign(foundEntity, item.entityData);
      }
    },
  },
};
</script>

<style scoped>
.outer-border {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 0.5rem;
  margin: 0.1rem;
}

.content-card {
  height: 158px;
  background-color: #FAFAFA;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 1rem;
  margin: 0.6rem !important;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.config-button {
  top: 10px;
  right: 10px;
  z-index: 2;
}

.contacts-section h6 {
  font-size: 14px;
  letter-spacing: 0.5px;
}
</style>
