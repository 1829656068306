import { PagerType, initPagerType } from "../../../../../shared/types";

/**
 * Type para item da listagem de FinancialApproval
 *
 * @typedef {Object} FinancialApprovalListType
 * @property {number} id
 * @property {number} total_value
 * @property {?string} nsu_code
 * @property {boolean} is_nsu_code_duplicated
 * @property {?number} payment_intermediary_merchant_id
 * @property {number} card_integration_status
 * @property {string} created_at
 * @property {?boolean} is_integrated_on_totvs
 * @property {?boolean} payment_intermediary_has_credentials
 * @property {?boolean} payment_intermediary_accept_txt_file
 * @property {string} competence
 * @property {number} bill_receive_id
 * @property {string} bank_account
 * @property {string} entity_name
 * @property {string} entity_uuid
 * @property {number} anticipation_balance
 * @property {string} document
 * @property {string} company_plant
 * @property {string} payment_method
 * @property {boolean} allow_approve
 * @property {string} payment_term
 * @property {string} user_name
 * @property {number} approved_value
 * @property {number} pending_value
 * @property {number} pay_value
 * @property {number} canceled_value
 * @property {number} denied_value
 * @property {number} late_value
 * @property {boolean} exists_attachments
 * @property {?string} contract_proposal_code
 * @property {?string} contract_proposal_uuid
 * @property {?string} sync_error_message
 * @property {?string} sync_integration_external_id
 */

export const FinancialApprovalListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<FinancialApprovalListType> } )} FinancialApprovalListPagerType
 */
export const FinancialApprovalListPagerType = {};

/**
 * Inicializar FinancialApprovalListPagerType
 * 
 * @returns {FinancialApprovalListPagerType}
 */
export const initFinancialApprovalListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

