<template>
  <button
    :style="buttonStyle"
    :class="[{ 'scale-hover': props.hasGrowEffect && !props.hasOutline }]"
    :disabled="props.isDisabled"
    v-bind="filteredAttrs"
    v-on="$listeners"
    @mouseenter="isHovering = true"
    @mouseleave="isHovering = false"
    class="app-button"
  >
    <div class="button-content" :style="contentStyle">
      <i
        v-if="isLoading"
        class="fas fa-circle-notch fa-spin"
        :style="isLoadingStyle"
      />
      <AppIcon
        v-if="props.icon && !isLoading"
        :icon="props.icon"
        height="16px"
        width="16px"
        :class="{ 'mr-2': !!props.label }"
        :color="appIconColor"
      />
      <h5 :style="labelStyle">
        {{ props.label }}
        <slot />
      </h5>
    </div>
  </button>
</template>

<script setup>
//#region Imports
import { ref, computed, useAttrs } from "vue";
import AppIcon from "../AppIcon/AppIcon.vue";
import { AppButtonVariants } from "./AppButtonVariants";
import { AppIconVariants } from "../AppIcon";
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {?string} label - Título do botão
 * @property {?string} icon - O ícone associado à opção. Utilize AppButtonVariants.variants.icon
 * @property {?boolean} isBlock - Define se o botão deve ocupar toda a largura disponível. Padrão: false
 * @property {?String} rounded - Define o arredondamento das bordas do botão. Utilize AppButtonVariants.variants.rounded. Padrão: DEFAULT.
 * @property {?String} size - Define o tamanho/altura do botão. Utilize AppButtonVariants.variants.size. Padrão: MD.
 * @property {?String} width - Define a largura do botão. Padrão: 150px.
 * @property {?String} borderType - Define o tipo da borda do botão. Utilize AppButtonVariants.variants.borderType. Padrão: solid.
 * @property {?String} type - Define o tipo (estilo/cor) do botão. Utilize AppButtonVariants.variants.type. Padrão: primary.
 * @property {?boolean} hasOutline - Define se o botão terá apenas contorno (true) ou será preenchido (false). Padrão: false
 * @property {?boolean} hasGrowEffect - Define se o botão terá efeito de crescer ao receber foco. (Funciona apenas SEM OUTLINE). Padrão: true.
 * @property {?boolean} isLoading - Exibe um loading no botão quando true. Padrão: false.
 * @property {?boolean} isDisabled - Desativar interação com botão. Padrão: false.
 */
/** @type {Props} */
const props = defineProps({
  label: {
    type: String,
    default: AppButtonVariants.defaultVariants.label,
    required: false,
  },
  icon: {
    type: String,
    default: AppButtonVariants.defaultVariants.icon,
    required: false,
  },
  iconColor:{
    type: String,
    required: false,
  },
  isBlock: {
    type: Boolean,
    default: AppButtonVariants.defaultVariants.isBlock,
    required: false,
  },
  rounded: {
    type: String,
    default: AppButtonVariants.defaultVariants.rounded,
    validator(value) {
      return Object.values(AppButtonVariants.variants.rounded.keys).includes(
        value
      );
    },
    required: false,
  },
  size: {
    type: String,
    default: AppButtonVariants.defaultVariants.size,
    validator(value) {
      return Object.values(AppButtonVariants.variants.size.keys).includes(
        value
      );
    },
    required: false,
  },
  width: {
    type: String,
    default: AppButtonVariants.defaultVariants.width,
    required: false,
  },
  borderType: {
    type: String,
    default: AppButtonVariants.defaultVariants.borderType,
    required: false,
  },
  type: {
    type: String,
    default: AppButtonVariants.defaultVariants.type,
    validator(value) {
      return Object.values(AppButtonVariants.variants.type.keys).includes(
        value
      );
    },
    required: false,
  },
  hasOutline: {
    type: Boolean,
    default: AppButtonVariants.defaultVariants.hasOutline,
    required: false,
  },
  hasGrowEffect: {
    type: Boolean,
    default: AppButtonVariants.defaultVariants.hasGrowEffect,
    required: false,
  },
  isLoading: {
    type: Boolean,
    default: AppButtonVariants.defaultVariants.isLoading,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: AppButtonVariants.defaultVariants.isDisabled,
    required: false,
  },
});
//#endregion

//#region Data
/** Estado do hover do botão */
const isHovering = ref(false);
//#endregion

//#region Computeds
/** Computa os atributos herdados, excluindo "disabled" */
const filteredAttrs = computed(() => {
  const { disabled, ...attrs } = useAttrs();
  return attrs;
});

/** Computa o estilo do botão com base nas propriedades */
const buttonStyle = computed(() => {
  const { primary, success, danger, warning, muted, dark, gray } = AppButtonVariants.variants.typeSchema;
  let color = "#FFFFFF";
  let backgroundColor = primary.backgroundColor;
  let borderColor = primary.borderColor;
  switch (props.type) {
    case AppButtonVariants.variants.type.keys.PRIMARY:
      backgroundColor = primary.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = primary.backgroundOutlineColor;
        borderColor = primary.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = primary.backgroundOutlineHoverColor;
          borderColor = primary.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = primary.backgroundColor;
        borderColor = primary.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.SUCCESS:
      backgroundColor = success.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = success.backgroundOutlineColor;
        borderColor = success.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = success.backgroundOutlineHoverColor;
          borderColor = success.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = success.backgroundColor;
        borderColor = success.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.DANGER:
      backgroundColor = danger.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = danger.backgroundOutlineColor;
        borderColor = danger.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = danger.backgroundOutlineHoverColor;
          borderColor = danger.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = danger.backgroundColor;
        borderColor = danger.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.WARNING:
      backgroundColor = warning.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = warning.backgroundOutlineColor;
        borderColor = warning.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = warning.backgroundOutlineHoverColor;
          borderColor = warning.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = warning.backgroundColor;
        borderColor = warning.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.MUTED:
      backgroundColor = muted.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = muted.backgroundOutlineColor;
        borderColor = muted.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = muted.backgroundOutlineHoverColor;
          borderColor = muted.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = muted.backgroundColor;
        borderColor = muted.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.DARK:
      backgroundColor = dark.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = dark.backgroundOutlineColor;
        borderColor = dark.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = dark.backgroundOutlineHoverColor;
          borderColor = dark.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = dark.backgroundColor;
        borderColor = dark.borderColor;
      }
      break;
    case AppButtonVariants.variants.type.keys.GRAY:
      backgroundColor = gray.backgroundColor;
      if (props.hasOutline) {
        backgroundColor = gray.backgroundOutlineColor;
        borderColor = gray.borderOutlineColor;
        if (isHovering.value) {
          backgroundColor = gray.backgroundOutlineHoverColor;
          borderColor = gray.borderOutlineHoverColor;
        }
      } else {
        backgroundColor = gray.backgroundColor;
        borderColor = gray.borderColor;
      }
      break;
    default:
      break;
  }
  
  const isLinkType = props.type === AppButtonVariants.variants.type.keys.LINK;
  return {
    ...defaultButtonStyle(),
    color: isLinkType ? "#2b2d32" : color,
    background: isLinkType ? "transparent" : backgroundColor,
    border: isLinkType || props.borderType === AppButtonVariants.variants.borderType.keys.NONE
        ? "none"
        : `1px ${props.borderType} ${borderColor}`,
    boxShadow: isLinkType ? "none" : defaultButtonStyle().boxShadow,
    cursor: props.isDisabled ? "not-allowed !important" : "pointer",
    opacity: props.isDisabled ? 0.5 : 1,
    transform: props.hasGrowEffect && !props.hasOutline
        ? ""
        : "translateY(0px) !important",
  };
});

/** Computa o estilo do conteúdo, definindo o layout e a tipografia */
const contentStyle = computed(() => {
  return {
    display: "inline-flex",
    alignItems: "center",
    fontFamily: "Fredoka",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "14.52px",
  };
});

/** Computa o estilo do rótulo do botão, ajustando conforme propriedades */
const labelStyle = computed(() => {
  const { primary, success, danger, warning, muted, dark, gray } = AppButtonVariants.variants.typeSchema;
  let color = "#FFFFFF";
  if (props.hasOutline) {
    const colorMap = {
      [AppButtonVariants.variants.type.keys.PRIMARY]: primary.color,
      [AppButtonVariants.variants.type.keys.SUCCESS]: success.color,
      [AppButtonVariants.variants.type.keys.DANGER]: danger.color,
      [AppButtonVariants.variants.type.keys.WARNING]: warning.color,
      [AppButtonVariants.variants.type.keys.MUTED]: muted.color,
      [AppButtonVariants.variants.type.keys.DARK]: dark.color,
      [AppButtonVariants.variants.type.keys.GRAY]: gray.color,
    };
    color = colorMap[props.type] || color;
    if (isHovering.value) {
      color = "#FFFFFF";
    }
  }
  // Botão do tipo Link
  if (props.type === AppButtonVariants.variants.type.keys.LINK) {
    color = "#2b2d32";
  }
  return {
    margin: 0,
    color: color,
  };
});

/** Computa a cor do ícone do botão, ajustando conforme propriedades */
const appIconColor = computed(() => {
  let color = 'white';

  //Setar cor do icone com valor da prop se exisitr
  if(props.iconColor) return props.iconColor

  if (props.hasOutline) {
    color = props.type;
    if (isHovering.value) {
      color = "white";
    }
  }
  // Botão do tipo Link
  if (props.type === AppButtonVariants.variants.type.keys.LINK) {
    color = AppIconVariants.defaultVariants.color;
  }
  return color;
});

/** Computa a cor do loading, ajustando conforme propriedades */
const isLoadingStyle = computed(() => {
  const { primary, success, danger, warning, muted, dark, gray } = AppButtonVariants.variants.typeSchema;
  let color = "#FFFFFF";
  if (props.hasOutline) {
    const colorMap = {
      [AppButtonVariants.variants.type.keys.PRIMARY]: primary.backgroundColor,
      [AppButtonVariants.variants.type.keys.SUCCESS]: success.backgroundColor,
      [AppButtonVariants.variants.type.keys.DANGER]: danger.backgroundColor,
      [AppButtonVariants.variants.type.keys.WARNING]: warning.backgroundColor,
      [AppButtonVariants.variants.type.keys.MUTED]: muted.backgroundColor,
      [AppButtonVariants.variants.type.keys.DARK]: dark.backgroundColor,
      [AppButtonVariants.variants.type.keys.GRAY]: gray.backgroundColor,
    };
    color = colorMap[props.type] || color;
    if (isHovering.value) {
      color = "#FFFFFF";
    }
  }
  return {
    color: color,
  };
});
//#endregion

//#region Methods
/** Função que retorna o estilo base do botão, ajustando propriedades */
function defaultButtonStyle() {
  return {
    textAlign: "center",
    boxShadow: "0px 5px 10px 0px #0000000D",
    width: props.isBlock ? "100%" : props.width,
    borderRadius: `${AppButtonVariants.variants.rounded.getRadius(props.rounded)} !important`,
    minHeight: `${AppButtonVariants.variants.size.getHeight(props.size)} !important`,
    background: AppButtonVariants.variants.type.keys.PRIMARY,
    color: AppButtonVariants.variants.type.keys.DARK,
  };
}
//#endregion
</script>

<style scoped lang="scss">
.app-button {
  transition: all 0.2s ease-in-out;

  &.scale-hover {
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.07) !important;
    }
  }
}
</style>
