<template>
  <div>

    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(save)" autocomplete="off">
            <div>
              <div
                v-if="contractProposal.entity"
                class="form-group row m-0 p-0 mb-1"
              >
                <label class="col-md-4 pt-3 pb-0  col-form-label form-control-label align-middle">
                  Status
                </label>
                <div class="col-md-8 d-flex justify-content-between">
                  <base-dropdown class="col-md-12 p-0 m-0">
                    <base-button 
                      v-if="status_situation.status != null"
                      slot="title-container"
                      block
                      :type="status[status_situation.status].type"
                      size="sm"
                      class="dropdown-toggle text-uppercase"
                    >
                      {{ status[status_situation.status].name }}
                    </base-button>
                    <div>
                      <a 
                        :key="index" v-for="(item, index) in status"
                        @click.passive="status_situation.status = index"
                        class="dropdown-item"
                        v-if="!(
                          [ContractProposalEnum.ACTIVE, ContractProposalEnum.COMPLETED, ContractProposalEnum.INACTIVE].includes(contractProposal.status) && 
                          index == ContractProposalEnum.PROPOSAL
                        )"
                      >
                        {{ item.name }}
                      </a>
                    </div>
                  </base-dropdown>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                  Situação
                </label>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm">
                  <PuzlSelect 
                    v-model="status_situation.situation_uuid" 
                    :items="situations.situations" 
                    customKey="uuid"
                    @change="verifyRequireSituation" />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                  Motivo <span v-if="require_reason" class="text-danger">*</span>
                </label>
                <div class="col-md-8" v-if="require_reason">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect 
                        v-model="status_situation.reason_uuids" 
                        multiple
                        customKey="uuid"
                        :items="situations.reasons" 
                        label="reason"
                        :disabled="!require_reason" />
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-8" v-else>
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect 
                      v-model="status_situation.reason_uuids" 
                      multiple
                      customKey="uuid"
                      :items="situations.reasons" 
                      label="reason"
                      :disabled="!require_reason" />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-3">
                <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                  Concorrente <span v-if="require_competitor" class="text-danger">*</span>
                </label>
                <div class="col-md-8" v-if="require_competitor">
                  <validation-provider rules="required">
                    <base-input input-classes="form-control-sm">
                      <PuzlSelect 
                        v-model="status_situation.competitor_uuid"
                        customKey="uuid"
                        :items="situations.competitors" 
                        :disabled="!require_competitor" />
                    </base-input>
                  </validation-provider>
                </div>
                <div class="col-md-8" v-else>
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect 
                      v-model="status_situation.competitor_uuid"
                      customKey="uuid"
                      :items="situations.competitors" 
                      :disabled="!require_competitor" />
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-2">
                <label class="col-md-4 pb-0 pt-1 col-form-label form-control-label">
                  Observações
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-model="status_situation.observation"
                      type="text"
                      class="form-control form-control-sm"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <base-button type="danger" @click="closeModal()">
                <img src="/img/icons/cancel-white.png" width="22px" height="22px" class="mr-2" />
                Cancelar
              </base-button>
              <!-- 
                O botão "Salvar" será desabilitado nos seguintes casos:
                1. Se o formulário for inválido (`invalid === true`).
                2. Se o status não tiver sido alterado (`old_status === status`) 
                    E os campos obrigatórios (`situation_uuid`, `reasons`, `competitor_uuid`) não estiverem preenchidos.
              -->
              <base-button 
                type="success"
                native-type="submit"
                :disabled="invalid || (status_situation.old_status == status_situation.status && !status_situation.situation_uuid && !status_situation.reasons && !status_situation.competitor_uuid)"
                :loading="loading || loadingSave"
              >
                <img src="/img/icons/save.png" width="22px" height="22px" class="mr-2" />
                Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";

export default {
  components: {PuzlSelect},
  name: "ModalChangeStatus",
  data() {
    return {
      modal: false,
      title: 'Alteração de Status/Situação',
      validated: false,
      loading: false,
      situation: {},
      loadingSave:false,
      status: {
        0: {
          name: 'Inativo',
          type: 'danger'
        },
        1: {
          name: 'Ativo',
          type: 'success',
        },
        5: {
          name: 'Concluído',
          type: 'primary',
        },
        4: {
          name: 'Proposta',
          type: 'light',
        },
      },
      status_situation: {
        contract_proposal_uuid: null,
        status: null,
        reason_uuids: null,
        situation: {},
        situation_uuid: {},
        competitor_uuid: null,
        observation: null,
      },
      require_reason: false,
      require_competitor: false,
      contractProposal: null,
      ContractProposalEnum: ContractProposalEnum,
    }
  },
  computed: {
    ...mapGetters({
      situations: "contractProposal/getListSituations",
    })
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    openModal() {
      this.modal = true
    },
    handleChangeStatus(contractProposal) {
      this.contractProposal = contractProposal;
      this.status_situation = {
        old_status: contractProposal.status,
        contract_proposal_uuid: contractProposal.uuid,
        status: contractProposal.status,
        reason_uuids: [],
        situation: {},
        situation_uuid: contractProposal.lastApproval.situation ? contractProposal.lastApproval.situation.uuid : null,
        competitor_uuid: null,
        observation: null,
      }
      this.modal = true
    },
    validStatus(index){
      if (index != this.status_situation.status) {
        if (index == 4) {
          if (this.status_situation.old_status === 4) {
            return true
          } else {
            return false
          }
        }else {
          return true
        }
      }
      return false
    },
    verifyRequireSituation() {
      if (this.status_situation.situation_uuid) {
        const situation = this.situations.situations.find(item => item.uuid === this.status_situation.situation_uuid)
        this.require_reason = situation.has_cancellation_reason
        this.require_competitor = situation.has_competitor
        this.status_situation.reason_uuids = []
        this.status_situation.competitor_uuid = null
        this.situation = situation
      } else {
        this.require_competitor = false
        this.require_reason = false
        this.situation = null
      }
    },
    save() {
      this.loadingSave = true;
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {...this.status_situation}
      params.situation = this.situation
      params.status = parseInt(params.status)
      this.$store
        .dispatch("contractProposal/postCreateSituation", params)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.status_situation = {
            contract_proposal_uuid: null,
            status: params.status,
            reason_uuids: [],
            situation: null,
            situation_uuid: null,
            competitor_uuid: null,
            observation: null,
          }
          this.$emit('changeStatus', this.status_situation.status)
          this.closeModal()
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.$Progress.finish();
          this.loadingSave = false;
        });
    },
  },
};
</script>

<style></style>
