<template>
  <validation-observer v-slot="{ invalid, handleSubmit }">
    <form
      class="needs-validation"
      @submit.prevent="handleSubmit(onSubmitClick)"
      autocomplete="off"
    >
      <div class="competitor-form">
        <!-- IDENTIFICAÇÃO -->
        <div class="competitor-form-title">
          <AppIcon icon="info-squared" color="warning" width="22" />
          <h5>IDENTIFICAÇÃO</h5>
        </div>

        <div class="competitor-form-body">
          <!-- STATUS -->
          <div class="competitor-form-group">
            <label for="status" class="col-md-9"> Status </label>
            <div class="wrapper col-md-3 flex-wrap">
              <AppSwitch v-model="formData.status" />
            </div>
          </div>

          <!-- NOME -->
          <div class="competitor-form-group">
            <label for="name" class="col-md-5"> Nome </label>
            <div class="wrapper col-md-7 flex-wrap">
              <validation-provider :rules="'required'" v-slot="{ errors }">
                <input
                  id="name"
                  class="input"
                  type="text"
                  v-model="formData.name"
                  :class="errors[0] ? 'is-invalid' : 'is-valid'"
                />
              </validation-provider>
            </div>
          </div>

          <!-- OBSERVAÇÕES -->
          <div class="competitor-form-group">
            <label for="observation" class="col-md-5"> Observações </label>
            <div class="wrapper col-md-7 flex-wrap">
              <validation-provider v-slot="{ errors }">
                <input
                  id="observation"
                  class="input"
                  type="text"
                  placeholder="..."
                  v-model="formData.observation"
                  :class="errors[0] ? 'is-invalid' : 'is-valid'"
                />
              </validation-provider>
            </div>
          </div>
        </div>

        <div class="competitor-form-footer">
          <AppButton
            label="Cancelar"
            type="danger"
            class="ml-2"
            :hasOutline="true"
            @click="onCancelClick"
          />
          <AppButton
            label="Salvar"
            type="success"
            class="ml-2"
            native-type="submit"
            :isDisabled="invalid"
            :isLoading="$_competitor_is_saving"
          />
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed } from "vue";
import { CompetitorType } from "../../types";
import competitorStore from "../../store/competitorStore";
import { ValidationObserver } from "vee-validate";
import {
  AppButton,
  AppSwitch,
  AppIcon,
} from "../../../../../../components/AppGlobal";
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {CompetitorType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
const emit = defineEmits(["onCancelClick", "onSubmitClick"]);
//#endregion

//#region Computeds
/** Store Getters */
const $_competitor_is_saving = computed(() =>
  competitorStore.getters.getIsSaving()
);
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

// MODAL-BODY
.modal-body {
  .competitor-form-title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;

    h5 {
      margin-bottom: 0;
      color: $dark;
      @include rhythm(font-size, 18);
      font-weight: 500;
    }
  }

  .competitor-form {
    padding: 24px;
  }

  .competitor-form-body {
    border: 1px solid $muted-light;
    padding: 16px 12px 16px 12px;
    border-radius: 8px;
  }

  .competitor-form-footer {
    display: flex;
    justify-content: end;
    margin-top: 24px;
  }

  .competitor-form-group {
    display: flex;
    align-items: start;
    margin-bottom: 1rem;

    @media (max-width: 991.98px) {
      &:not(.badge-reset) {
        flex-wrap: wrap;
      }
    }

    label {
      @include rhythm(font-size, 16);
      margin-bottom: 0;
      font-weight: 400;

      @media (max-width: 991.98px) {
        padding-bottom: 4px;
        width: 100%;
      }
    }

    .wrapper > span {
      display: flex;
      flex-wrap: inherit;
      width: 100%;

      & .v-select {
        width: 100%;

        .vs__search {
          box-shadow: none !important;
        }
      }
    }

    .input {
      display: block;
      border: none;
      @include rhythm(font-size, 14);
      box-shadow: 0 0 0 0.5px #e8e8e8, 0px 5px 10px 0px #0000000d !important;
      border-radius: 4px;
      padding: 4px 8px;
      box-sizing: border-box;
      height: 32px;
      width: 100%;

      &:disabled {
        background-color: $muted-light;
      }

      &.is-invalid {
        box-shadow: 0 0 0 1px $danger, 0px 5px 10px 0px #0000000d !important;
        animation: shake 0.3s ease-in-out;

        &:focus {
          outline: none;
        }
      }

      &.is-valid {
        box-shadow: 0 0 0 1px $success, 0px 5px 10px 0px #0000000d !important;

        &:focus {
          outline: none;
        }
      }

      &.radius-right-0 {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
</style>
