<template>
  <div>
    <div class="container-fluid">
      <multi-filter :status="multi_filter" @clearFilters="clearFilters" ref="multi-filter" @fetch="init"
                    :filter="filter">
        <div class="col-md-3 p-2">
          <input-date-picker :filterable="4" :default-range="range" ref="date-picker"
                             @handleFilterDate="handleFilterDate"/>
        </div>
      </multi-filter>
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"/>
      </div>
      <PuzlEmptyData v-if="!$_items.length && !loadingSkeleton"/>
      <div class="row card-wrapper" v-if="$_items.length && !loadingSkeleton">
        <div class="col-lg-4" v-for="(item, index) in $_items" :key="index">
          <div class="card">
            <div class="card-header pointer" style="padding: 0px !important; height: 22px">
              <div class="row">
                <div class="col-md-12">
                  <base-button
                    size="sm"
                    class="text-white text-uppercase bg-light btn-header"
                    style="opacity: 100%; color: #4f40ff; border: none"
                    block
                  >
                    Sem financeiro
                  </base-button>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="mt-n2">
                <div class="row">
                  <div class="col-9">
                    <h4 class="text-primary">
                      {{ item.operation_source_name }}
                    </h4>
                  </div>
                  <div class="col-3 text-right pl-0">
                    <base-dropdown menuOnRight>
                      <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                        <i class="fas fa-cog"></i>
                      </base-button>
                      <a @click.prevent="$router.push('/configuration/exit-launch/edit/' + item.id)"
                         class="dropdown-item">
                        <i class="fas fa-edit text-warning main-icon-menu"></i>
                        Editar
                      </a>
                      <a v-if="item.nfe_status === 1" @click.prevent="downloadXml(item)" class="dropdown-item">
                        <i class="fas fa-file-code text-primary main-icon-menu"/>
                        XML
                      </a>
                      <a v-if="item.nfe_status === 1" @click.prevent="handleShowModalCorrectionLetter(item)" class="dropdown-item">
                        <img src="/img/icons/icons8/ios/mail-primary.png" class="main-icon-menu" width="19px" height="19px">
                        CARTA DE CORREÇÃO
                      </a>
                      <a v-if="item.nfe_status === 1" @click.prevent="cancelNfe(item)" class="dropdown-item">
                        <i class="fas fa-ban text-danger main-icon-menu"/>
                        Cancelar
                      </a>
                      <a @click.prevent="handleDelete(item.id)" class="dropdown-item">
                        <i class="fas fa-times text-danger main-icon-menu"></i>
                        Excluir
                      </a>
                    </base-dropdown>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 mt-n2">
                    <h4>{{ item.entity_name }}</h4>
                    <h4 class="mt-n2 font-weight-400">{{ item.document }}</h4>
                    <div style="border-top: 1px solid #1a70b7"/>
                    <div class="row mt-3">
                      <div class="col-6">
                        <h4 class="mt-n2 mb-0 font-weight-600"> <img
                          style="width: 25px !important; height: 25px !important"
                          class="icon-8-md mr-1"
                          src="https://img.icons8.com/ios/480/null/purchase-order.png"
                        />
                          <span data-v-8f52b874="" style="text-transform: none;font-size: 13px;font-weight: 400;">n° {{ item.document_number }}</span>
                    </h4>
                      </div>
                      <div class="col-6"></div>
                    </div>
                    <h4 class="mb-0 numeric">{{ item.total_value | currency }}</h4>
                    <h4 class="font-weight-400">
                      {{ item.issue_date | parseDate("DD MMM YYYY") }}
                      <span v-if="item.exit_date">| {{ item.exit_date | parseDate("DD MMM YYYY") }} (Saída)</span>
                    </h4>
                    <div class="row mt-3">
                      <div class="col-6 px-1">
                        <base-button
                          style="display: flex;!important"
                          block
                          disabled
                          outline
                          size="sm"
                          class="no-hover pt-1 pb-1 align-items-center text-uppercase text-default"
                        >
                          <span style="font-size: 0.7rem" class="text-default font-weight-400"
                          >
                              <img
                                style="width: 25px !important; height: 25px !important"
                                class="icon-8-md mr-1"
                                src="https://img.icons8.com/ios/480/null/purchase-order.png"
                              />
                            Pedido
                          </span>
                        </base-button>
                      </div>
                      <div class="col-6 px-1">
                        <base-button
                          @click.prevent="handleIssueNfe(item)"
                          style="display: flex;!important"
                          block
                          outline
                          :type="primaryByNfe(item.nfe_status)"
                          size="sm"
                          class="no-hover pt-1 pb-1 align-items-center text-uppercase text-default"
                        >
                          <span style="font-size: 0.7rem" class="text-default font-weight-400"
                                v-if="item.nfe_status === 2">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            NF-e rejeitada
                          </span>
                          <span style="font-size: 0.7rem" v-if="item.nfe_status === 3"
                                class="text-warning font-weight-400">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            Cancelando NF-e
                          </span>
                          <span style="font-size: 0.7rem" class="text-default font-weight-400"
                                v-if="item.nfe_status === 4">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            NF-e cancelada
                          </span>
                          <span style="font-size: 0.9rem" class="text-primary font-weight-400"
                                v-if="item.nfe_status === 1">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            <span style="font-size: 0.9rem"
                                  class="text-lowercase text-default font-weight-300">n°</span> {{ item.nfe_number }}
                          </span>
                          <span style="font-size: 0.7rem" v-if="item.nfe_status === 0"
                                class="text-default font-weight-400">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            Emitindo NF-e
                          </span>
                          <span style="font-size: 0.7rem" class="text-default font-weight-400"
                                v-if="item.nfe_status === null">
                            <img
                              style="width: 25px !important; height: 25px !important"
                              class="icon-8-md mr-1"
                              src="https://img.icons8.com/color/480/nota-fiscal-eletronica.png"
                            />
                            Emitir
                          </span>
                        </base-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-pagination :show="loading && !loadingSkeleton"/>
    <modal-cancel-nfe
      :selected="selected"
      :show="showModalCancelNfe"
      @close="showModalCancelNfe = false"
    />
    <modal-re-send
      :show="showModalReSend"
      :selected="selected"
      @close="showModalReSend = false"/>
    <ModalCorrectionLetter @updated="setHasCorrectionLetter" ref="modalCorrectionLetter"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import MultiFilter from "@/components/Utils/MultiFilterV2.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import {base_url_ms} from '@/plugins';
import ModalReSend from '../../../Financial/NFe/Issuedes/Shared/_ModalReSend.vue';
import ModalCancelNfe from "@/views/Modules/Financial/NFe/Issuedes/Shared/_ModalCancelNfe.vue";
import { hasPermission } from "@/plugins/permissions";
import moment from "moment";
import ModalCorrectionLetter from  "../../../Financial/NFe/Issuedes/Shared/_ModalCorrectionLetter.vue"
export default {
  name: "ListExitLaunch",
  mixins: [cursorPaginate],
  components: {
    LoadingPagination,
    SkeletonPuzlGrid,
    PuzlEmptyData,
    MultiFilter,
    InputDatePicker,
    ModalReSend,
    ModalCancelNfe,
    ModalCorrectionLetter
  },
  data() {
    return {
      loadingSkeleton: true,
      base_url_ms: base_url_ms(),
      filter: {},
      showModalReSend: false,
      showModalCancelNfe: false,
      selected: false,
      has_permission: hasPermission,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
      },
      range: {
        start: new Date(),
        end: new Date(),
      },
    };
  },
  computed: {
    ...mapGetters({
      $_items: "exitLaunch/fetch",
    }),
  },
  methods: {
    setHasCorrectionLetter(params) {
      let index = this.items.findIndex((item) => item.key === params.key)
      this.items[index].has_correction_letter = true
    },
    primaryByNfe(status) {
      switch (parseInt(status)) {
        case 2:
        case 4:
          return 'danger'
        case 0:
          return 'warning';
        default:
          return 'primary'
      }
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("exitLaunch/fetch", {
          filter: this.filter.range ? this.filter : {range: this.range},
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
        })
        .catch(() => {
          this.resolveCursor();
        });
    },
    clearFilters() {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      delete this.filter.range;
      this.range = {
        start: new Date(),
        end: new Date(),
      };
      this.init();
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    async download(s3_path, key, format) {
      let loader = this.$loading.show()
      const url = this.base_url_ms + 'download-s3?url=' + s3_path
      this.$store.dispatch('exports/download', url).then(async response => {
        let blob = new Blob([response], {type: "application/pdf"});
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", `${key}.${format}`);
        await link.click()
      })
      loader.hide()
    },
    handleShowModalCorrectionLetter(item) {
      this.$refs.modalCorrectionLetter.openModal({
        key: item.nfe_key,
        customer_name: item.entity_name,
        number: item.nfe_number,
        os: item.document_number,
        code: item.operation_source_name,
      })
    },
    reSendXml(item) {
      if (item.status !== 2 && item.status !== 5) {
        return
      }
      this.selected = item
      this.showModalReSend = true
    },
    updateItem(id) {
      let index = this.$_items.findIndex((item) => item.id === id)
      this.$_items[index].nfe_status = 0
    },
    handleIssueNfe(item) {
      if (item.nfe_status === 1) {
        return this.download(item.nfe_pdf_file_path, item.nfe_key, 'pdf')
      }
      if ([2, 5].includes(item.nfe_status)) {
        this.reSendXml({
          key: item.nfe_key,
          id: item.id,
          status: item.nfe_status,
          log: item.nfe_log
        })
      }
      if (item.nfe_status !== null) {
        return;
      }
      this.$Swal.confirmAction("Deseja realizar a emissão da NF-e para este pedido?", ["Sim", "Não"]).then((result) => {
        if (result.isConfirmed) {
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$Progress.start();
          this.$store.dispatch('nfe/postStoreNfe', item.id).then((response) => {
            this.$notify({
              type: response.error_type,
              message: response.message
            })
            item.nfe_status = 0
          })
            .catch(error => {
              this.$notify({
                type: 'danger',
                message: error.data.message,
              });
            })
            .finally(() => {
              this.$Progress.finish();
            })
        }
      })
    },
    handleDelete(id) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$notify({
            type: "info",
            message: "Estamos trabalhando em sua solicitação.",
          });
          this.$store.dispatch("exitLaunch/destroy", id).then(response => {
            this.$notify({
              type: response.error_type,
              message: response.message || response.data.message,
            });
          }).catch((error) => {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            })
          }).finally(() => loader.hide())
        }
      });
    },
    /**
     * Download de um arquivo XML da NFe.
     *
     * @param {Object} item - Objeto contendo as informações do item a ser baixado.
     * @param {string} item.nfe_xml_file_path - Caminho do arquivo XML da NFe.
     * @param {string} item.nfe_key - Chave da NFe usada como nome do arquivo.
     * @returns {Promise<void>} - Não retorna um valor, mas inicia o download do arquivo.
     */
    async downloadXml(item) {
      const loader = this.$loading.show();
      try {
        const payload = {
          urls: [{
            file_path: item.nfe_xml_file_path,
            id: null, file_name: item.nfe_key
          }]
        };
        // Fazendo o download do arquivo como um Blob
        const response = await this.$store.dispatch('exports/getTemporaryUrl', payload);
        const fileUrl = response.data[0].file_path;
        const res = await fetch(fileUrl); // Faz o download do conteúdo do arquivo
        const blob = await res.blob(); // Converte a resposta para Blob

        // Criando o link de download com Blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = item.nfe_key;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);

        this.$notify({ type: 'success', message: 'Download iniciado com sucesso!' });
      } catch (error) {
        this.$notify({ type: 'error', message: 'Erro ao realizar o download. Entre em contato com o suporte.' });
      } finally {
        loader.hide();
      }
    },
    /**
     * @param {object} item | $exit_launches
     */
    cancelNfe(item) {
      // Verificar permissão
      if (!this.has_permission('additional_permissions.cancellation-of-nfe')) {
        this.$notify({
          type: 'danger',
          message: 'Usuário sem permissão para cancelamento de NFe.'
        });
        return;
      }
      // Verificar se data ultrapassou o período de 24 horas
      const issuerDateTime = moment(item.issue_date).startOf('day');
      const nowDateTime = moment().startOf('day');
      const differenceInDays = Math.round(moment.duration(nowDateTime.diff(issuerDateTime)).asDays());
      if (differenceInDays > 1) {
        this.$notify({
          type: 'danger',
          message: 'Não é permitido cancelar a NFe após 24 horas da data de emissão.'
        });
        return;
      }
      // Exibir modal de cancelamento
      this.selected = item;
      this.selected.number = item.nfe_number;
      this.selected.series = item.nfe_series;
      this.selected.key = item.nfe_key;
      this.showModalCancelNfe = true;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.main-icon-menu {
  min-width: 18px;
}

.bg-dark {
  background-color: #2b2d32 !important;
}
</style>
