<template>
  <modal size="lg" :show.sync="modal" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        Lista de itens
      </h5>
    </template>
    <div class="p-3">
      <div>
        <MultiFilter :filter="filter" class="filter" @fetch="init" :type="4" :status="null" />
      </div>
      <!-- TABELA -->
      <div v-if="$_items.length > 0" class="container-items mt-2 pt-2 px-4"
        style="overflow-y: scroll; max-height: 500px">
        <div v-for="(item, index) in $_items" :key="index" class="item mt-3">
          <div @click="cardOpenId === item.id ? cardOpenId = false : cardOpenId = item.id"
            class="d-flex justify-content-between p-2 px-3 pointer">
            <div class="font-weight-500">{{ item.description }}</div>
            <div class="mt-1 pointer">
              <i v-if="cardOpenId === item.id" class="fa-solid fa-chevron-up"></i>
              <i v-else class="fa-solid fa-chevron-down"></i>
            </div>
          </div>
          <div v-if="cardOpenId === item.id" class="d-flex justify-content-between mt-2">
            <div class="d-flex p-2 px-3">
              <base-input input-group-classes="input-group-sm mr-2" width="16rem">
                <textarea type="text" placeholder="Marca" v-model="$_items[index].brand" rows="1"
                  class="form-control form-control-md" style="box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12);" />
              </base-input>
              <base-input input-group-classes="input-group-sm">
                <input @click="$_items[index].quantity = 0"
                  v-on:input="$_items[index].quantity = formatString($event.target.value, '0', '99999.999', 3)"
                  inputmode="numeric" v-model="$_items[index].quantity" class="form-control form-control-sm" />
                <template slot="append">
                  <small class="input-group-sm p-0 m-0 pointer">
                    {{ item.input_unit || 'N/A' }}
                  </small>
                </template>
              </base-input>
            </div>
            <div class="include-button" @click="addProductServiceAndCloseTab(item)">
              <img class="mr-2" src="/img/icons/icons8/ios/right-squared.png" width="16px">
              incluir
            </div>
          </div>
        </div>
        <AppViewTrigger v-if="!loading" @onIntersected="init(false, true)" />
      </div>
      <PuzlEmptyData v-if="!$_items.length" />
      <div class="w-100 d-flex justify-content-end mt-3" @click="closeModal()">
        <div class="offset-8 col-4 px-2">
              <AppButton label="Fechar" type="primary" :hasOutline="true" :isBlock="true" @click.prevent="closeModal" />
            </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilter.vue";
import cursorPaginate from "@/mixins/cursorPaginate";
import { AppViewTrigger, AppButton } from "../../../../../../../../components/AppGlobal";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import entryLaunchItemMixin from "../../../../../../Configuration/EntryLaunch/Mixins/entry_launch_item_mixin";
import entryLaunchMixin from "../../../../../../Configuration/EntryLaunch/Mixins/entry_launch_mixin";
export default {
  name: "ModalDigitalSignatureList",
  mixins: [cursorPaginate, entryLaunchMixin, entryLaunchItemMixin],
  components: { MultiFilter, PuzlEmptyData, AppViewTrigger, AppButton },
  props: {
    itemType: {
      type: Number
    },
    fromBudget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modal: false,
      cardOpenId: false,
      filter: {
        order_by: [
          {
            column: 'products_services.id',
            global: '',
          }
        ],
        type: null,
      },
      items: [],
    }
  },
  computed: {
    ...mapGetters({
      $_items: "productServiceNew/fetch",
    }),
  },
  watch: {
    itemType() {
      this.items = [];
    },
  },
  methods: {
    // Modal
    handleCreateModal() {
      this.modal = true;
      this.init();
    },
    closeModal() {
      this.modal = false
    },
    init(filter = null, isAccumulateItems = false) {
      if (!this.startCursor(this.filter, isAccumulateItems)) {
        return;
      }
      this.filter.product_service_hub_type_id = this.itemType;
      this.filter = { ...this.filter, ...filter };
      let loader = this.$loading.show();
      this.$store
        .dispatch("productServiceNew/fetchItemsPaginate", this.filter)
        .then((res) => this.resolveCursor(res, this.filter))
        .finally(() => {
          this.loading = false;
          this.modal = true;
          loader.hide();
        });      
    },
    addProductServiceAndCloseTab(item) {
      if (item.quantity <= 0) {
        this.$notify({ type: 'warning', message: 'Quantidade inválida!' });
        return
      }
      item.status = 1; // Aprovado  
      this.cardOpenId = false;
      this.$emit('addProductService', item);
      this.$notify({ type: 'success', message: 'Item adicionado com sucesso!' });
      if (this.fromBudget) this.closeModal();
    },
  },
}
</script>

<style scoped>
.filter>>>.container-fluid {
  padding: 0 !important;
}

.container-items {
  overflow: auto;
  max-height: 16rem;
  width: 100%;
  background-color: #f4f8fb;
  padding: 1rem;
  box-shadow: 0px 4px 4px 0px #0000000D;
  border: 1px solid #DCDFE6;
  border-radius: 10px;
  max-height: 500px;
}

.item {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px #00000040;
  background-color: #FFFFFF;
}

.include-button {
  background-color: #1A70B7D9;
  border-radius: 80px 0 9px 0;
  width: 8rem;
  bottom: 0px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-size: 12px;
  align-self: self-end;
  cursor: pointer;
}

.close-button {
  border-radius: 5px;
  border: 1px solid #DCDFE6;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 20%;
  height: 2.3rem;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: transform 0.3s;
  color: #ffff
}

.close-button:hover {
  cursor: pointer;
  transform: translateY(-3px);
}
</style>
