import { computed, getCurrentInstance } from "vue";

export function usePaymentMethodHook() {
  const store = getCurrentInstance().proxy.$store;

  /**
   * Mediador de Pagamento
   */
  store.dispatch("paymentIntermediary/fetchItems");
  const paymentIntermediaries = computed(() => store.getters["paymentIntermediary/fetch"]);

  return {
    paymentIntermediaries
  };
}