/**
 * Type para representar um item de orçamento de compra
 *
 * @typedef {Object} PurchaseOrderItemType
 * @property {number} id [V]
 * @property {number} purchase_request_id [C]
 * @property {string} code [C,A]
 * @property {string} deadline [C,A]
 * @property {string} entity_document [V]
 * @property {string} entity_name [V]
 * @property {string} ipi [C,A]
 * @property {string} icms [C,A]
 * @property {string} delivery_cost [C,A]
 * @property {number} order_status [V]
 * @property {string} discount [C,A]
 * @property {string} payment_method [C,A]
 * @property {number} total_value [V]
 * @property {string} delivery_deadline [C,A]
 * @property {string} quotation_validity [C,A]
 * @property {number} requester_id [C]
 * @property {string} note [C,A]
 * @property {string} purchase_justification [C,A]
 * @property {number} status [C,A]
 * @property {number} product_service_hub_type_id [C]
 * @property {number} cost_center_id [C]
 * @property {number} input_company_plant_id [C]
 * @property {number} company_plant_buyer_id [C]
 * @property {string} created_by_user_name [V]
 * @property {string} created_by_user_email [V]
 * @property {boolean} has_active_budget_entity [V]
 * @property {string} cost_center_name [V]
 * @property {string} purchase_category_description [V]
 * @property {string} input_company_plant_name [V]
 * @property {boolean} has_attachments [V]
 * @property {number} budget_status [V]
 * @property {boolean} can_approve [V]
 * @property {boolean} purchase_category_edit [V]
 * @property {boolean} purchase_order_approve [V]
 * @property {boolean} purchase_order_denied [V]
 * @property {boolean} purchase_order_cancel [V]
 * @property {boolean} purchase_order_complete [V]
 * @property {boolean} already_approved [V]
 * @property {string} url [V]
 * @property {boolean} purchase_order_resume [V]
 * @property {boolean} limit_order_approval_value [V]
 * @property {string} max_approval_value [V]
 * @property {number} final_value [V]
 * @property {number} net_value [V]
 * @property {number} gross_value [V]
 */
export const PurchaseOrderItemType = {};

/**
 * Inicializar PurchaseOrderItemType
 * 
 * @returns {PurchaseOrderItemType}
 */
export const initPurchaseOrderItemType = () => {
  return {
    id: 0,
    purchase_request_id: 0,
    code: '',
    deadline: '',
    entity_document: '',
    entity_name: '',
    ipi: '0.00',
    icms: '0.00',
    delivery_cost: '0.00',
    order_status: 0,
    discount: '0.00',
    payment_method: '',
    total_value: 0,
    delivery_deadline: '',
    quotation_validity: '',
    requester_id: 0,
    note: '',
    purchase_justification: '',
    status: 0,
    product_service_hub_type_id: 0,
    cost_center_id: 0,
    input_company_plant_id: 0,
    company_plant_buyer_id: 0,
    created_by_user_name: '',
    created_by_user_email: '',
    has_active_budget_entity: false,
    cost_center_name: '',
    purchase_category_description: '',
    input_company_plant_name: '',
    has_attachments: false,
    budget_status: 0,
    can_approve: false,
    purchase_category_edit: false,
    purchase_order_approve: false,
    purchase_order_denied: false,
    purchase_order_cancel: false,
    purchase_order_complete: false,
    already_approved: false,
    url: '',
    purchase_order_resume: false,
    limit_order_approval_value: false,
    max_approval_value: '0.00',
    final_value: 0,
    net_value: 0,
    gross_value: 0
  };
};
