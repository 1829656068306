<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 table-responsive mt-2">
        <table class="table-layout-3-1">
          <thead v-if="$_bank_reconciliations.length">
            <tr>
              <th style="width: 15px;">
                <div class="column-head-layout-3-1">
                  Status
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Banco
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Agência
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Conta
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Período
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Recebimentos
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Pagamentos
                </div>
              </th>
              <th style="width: 15px;">
                <div class="column-head-layout-3-1 text-center">
                  Ação
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="($_bank_reconciliations.length || $_bank_reconciliations)"
                v-for="(item, index) in $_bank_reconciliations" :key="index">
              <td>
                <div class="column-body-layout-3-1">
                  <StatusBadge
                    @eventHandler="eventHandler"
                    :item="item"
                    minWidth="160"
                  />
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <img :src="'/img/icons/banks/'+ item.bank_code + '.png'"
                         width="20"
                         alt="conta">
                    <h4 class="m-0 mt-1 ml-2 new-default-black-font font-weight-normal">
                      {{ item.account_name }}
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 mt-1 new-default-black-font font-weight-normal">
                    {{ item.agency }}-{{ item.agency_digit || '#' }}
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 mt-1 new-default-black-font font-weight-normal">
                    {{ item.account }}-{{ item.account_digit || '#'}}
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <h4 class="m-0 mt-1 new-default-black-font font-weight-normal">
                    {{ item.start_at | parseDate('DD/MM/YYYY') }} até {{ item.end_at | parseDate('DD/MM/YYYY') }}
                  </h4>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <h4 class="m-0 mt-1 mr-2 new-default-black-font">
                      {{  toMoney(item.total_receipts) }}
                    </h4>
                    <h4
                      class="m-0 mt-1 new-default-black-font"
                      :class="{'text-success': Number(item.bills_to_receive_reconciled_count) + Number(item.credit_operations_associations_count) === item.bills_to_receive_count}"
                    >
                      {{ Number(item.bills_to_receive_reconciled_count) + Number(item.credit_operations_associations_count) }}/
                    </h4>
                    <h4 class="m-0 mt-1 text-success">
                      {{ item.bills_to_receive_count }}
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1">
                  <div class="d-flex align-items-center">
                    <h4 class="m-0 mt-1 mr-2 new-default-black-font">
                      {{  toMoney(item.total_payments) }}
                    </h4>
                    <h4
                      class="m-0 mt-1 new-default-black-font"
                      :class="{'text-success': Number(item.bills_to_pay_reconciled_count) + Number(item.debit_operations_associations_count) === item.bills_to_pay_count}"
                    >
                      {{ Number(item.bills_to_pay_reconciled_count) + Number(item.debit_operations_associations_count) }}/
                    </h4>
                    <h4 class="m-0 mt-1 text-danger">
                      {{ item.bills_to_pay_count }}
                    </h4>
                  </div>
                </div>
              </td>
              <td>
                <div class="column-body-layout-3-1 text-center">
                  <SettingOptions
                    @eventHandler="eventHandler"
                    :item="item"
                    :iconSize="25"
                  />
                </div>
              </td>
            </tr>
            <tr v-if="props.loading"
                v-for="i in 10" :key="i">
              <td colspan="8">
                <div class="column-body-layout-3-1">
                  <SkeletonPuzl custom_margin="5px 5px 0px 0px" type="text" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, getCurrentInstance, defineEmits} from "vue";
import SettingOptions from "./Common/SettingOptions.vue";
import StatusBadge from "./Common/StatusBadge.vue";
import {toMoney} from "@/helpers";
import CustomPopover from "@/views/Modules/Operational/Schedule/Weighing/Shared/CustomPopover.vue";
import {AppButton, AppIcon} from "@/components/AppGlobal";
import SettingsOptions from "@/views/Modules/Operational/Schedule/Weighing/Shared/SettingOptions.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl.vue";
import DocumentPlacement from "@/views/Modules/Operational/Schedule/Weighing/Shared/DocumentPlacement.vue";

/** Eventos emitidos */
const emit = defineEmits([
  "handleDelete",
  "openTransaction",
  "changeStatus",
]);

const store = getCurrentInstance().proxy.$store;
const $_bank_reconciliations = computed(() => store.getters["bankReconciliation/fetch"]);

const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
})

function eventHandler(event, item) {
  emit(event, item);
}
</script>
<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.card-bank-reconciliation {
  -webkit-box-shadow: 0 5px 10px 0 #0000000D !important;
  border-radius: 8px !important;
  border: 1px solid $muted-light;
  box-shadow: 0 5px 10px 0 #0000000D !important;
  padding: 16px 20px 20px;

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }

    .reuse-container-card {
      margin-top: 8px;
      border: 1px solid #f4f4f4;
      border-radius: 8px;
      padding: 18px 5px;

      h5, h6 {
        padding-left: 0;
      }
    }
  }

  hr {
    background-color: $muted-light;
    opacity: 0.5;
    color: $muted-light;
  }

  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 12px;
  }

  h6 {
    font-size: 10px;
  }

  .pointer {
    transition: all ease-in-out 0.15s;
    &:hover {
      transform: translateY(-3px) !important;
    }
  }

  .document-placement-card {
    text-align: center;
    height: 75px;
    box-shadow: 0px 5px 10px 0px #0000000D;
    display: flex;
    border: 1px solid $muted-light;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    &.transparent-card {
      box-shadow: none;
      border: none;
      height: 22px;
    }

    nav {
      display: block;
      align-items: center;

      h5 {
        font-size: 12px;
        margin: 0 0 0 5px;
        height: 20px;
        font-weight: 400 !important;
        color: $dark;
      }

      img {
        height: 18px;
        margin-right: 5px;
      }
    }
  }
}

.table-layout-3-1 {
  width: 100%;
  border-collapse: collapse;

  h4 {
    font-size: 12px;
  }

  .icon-spot {
    display: inline-flex;
    cursor: pointer;

    span.badge {
      position: relative;
      margin-left: -9px;
      height: 16px;
      width: 16px;

      i.fa-exclamation {
        font-size: 10px;
      }
    }
  }

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }

    .reuse-container-card {
      margin-top: 8px;
      border: 1px solid #f4f4f4;
      border-radius: 8px;
      padding: 18px 5px;

      h5, h6 {
        padding-left: 0;
      }
    }
  }

  thead {
    tr {
      th {
        text-align: left;
        border: none;
        padding: 0;

        div.column-head-layout-3-1 {
          background: $muted-light;
          border: 1px solid #DFDFDF;
          border-right: none;
          border-left: none;
          color: $dark;
          font-size: 12px;
          font-weight: 500;
          padding: 10px;
        }

        &:first-child div.column-head-layout-3-1 {
          border-top-left-radius: 8px;
          border-left: 1px solid #DFDFDF;
        }

        &:last-child div.column-head-layout-3-1 {
          border-top-right-radius: 8px;
          border-right: 1px solid #DFDFDF;
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 2px solid $muted-light;

      td {
        text-align: left;
        border: none;
        padding: 0;

        div.column-body-layout-3-1 {
          background: white;
          padding: 12px;
          height: 50px;
          box-shadow: 10px 5px 10px 0 #00000026;
        }
      }

      &:last-child {

        :first-child div.column-body-layout-3-1 {
          border-bottom-left-radius: 8px;
        }

        :last-child div.column-body-layout-3-1 {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
</style>
