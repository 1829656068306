<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="isOpen" size="lg">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <h5
            style="display: inline-flex"
            class="modal-title p-0 m-0 ml-3 text-white"
          >
            {{ title }}
          </h5>
        </div>
      </template>

      <div>
        <div class="row m-0 p-0" v-show="loading">
          <div class="col-md-8 m-0 p-0">
            <SkeletonPuzl type="button" />
          </div>
          <div class="col-md-4">
            <SkeletonPuzl type="button" />
          </div>
        </div>
        <template>
          <di v-show="!loading">
            <validation-observer
              v-slot="{ invalid, handleSubmit }"
              ref="formValidator"
            >
              <form
                @submit.prevent="handleSubmit(onSubmitClick)"
                class="needs-validation"
                autocomplete="off"
              >
                <div class="container-fluid">
                  <div class="row mt-2 align-items-center">
                    <div class="col-6 mt-1">
                      <h4 class="new-default-black-font font-weight-normal m-0">
                        Token
                      </h4>
                    </div>
                    <div class="col-6">
                      <validation-provider rules="required" v-slot="{ errors }">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            v-model="equipment.puzl_task_token_integration"
                            type="text"
                            class="form-control form-control-sm"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                          />
                        </base-input>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="row mt-5 mb-4">
                    <div class="col-12 text-right">
                      <AppButton
                        label="Cancelar"
                        icon="cancel"
                        type="danger"
                        class="ml-2"
                        :hasOutline="true"
                        @click="closeModal"
                      />
                      <AppButton
                        label="Salvar"
                        icon="save"
                        type="success"
                        class="ml-2"
                        native-type="submit"
                        :isDisabled="invalid"
                        :isLoading="loadingToken"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </validation-observer>
          </di>
        </template>
      </div>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref } from "vue";
import { getCurrentInstance } from "vue";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import { AppButton } from "../../../../../../components/AppGlobal";
import { dialogs, progress } from "../../../../../../helpers";
const { formatErrorValidation } = require("@/plugins");
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Token de Integração Puzl Task";
const loading = ref(false);
const loadingToken = ref(false);
const instance = getCurrentInstance();
const store = instance.proxy.$store;
const equipment_plant = ref({});
const equipment = ref({ token: null, equipment_uuid: null });
//#endregion

//#region Methods
function openModal(uuid, dispatch) {
  fetchData(uuid, dispatch);
  loadingToken.value = false;
  isOpen.value = true;
}

const closeModal = () => {
  isOpen.value = false;
};

const fetchData = (uuid, dispatch) => {
  loading.value = true;
  store.dispatch(dispatch + "/show", uuid).then((response) => {
    equipment_plant.value = response.data;
    equipment.value = {
      equipment_uuid: uuid,
      puzl_task_token_integration:
        response.data.puzl_task_token_integration ?? null,
    };
    loading.value = false;
  });
};

function onSubmitClick() {
  loadingToken.value = true;
  progress.start();
  store
    .dispatch("checklist/updatePuzlZTaskTokenIntegration", equipment.value)
    .then((response) => {
      loadingToken.value = false;
      progress.finish();
      dialogs.notify(response.error_type, response.message);
      closeModal();
    })
    .catch((error) => {
      if (error.status) {
        loadingToken.value = false;
        dialogs.notify(error.data.error_type, error.data.message);
        progress.finish();
      } else {
        if (error.response.status === 422) {
          let message = formatErrorValidation(error.response.data.errors);
          dialogs.notify("danger", message);
          progress.finish();
          loadingToken.value = false;
        } else {
          dialogs.notify(error.data.error_type, error.data.message);
          progress.finish();
          loadingToken.value = false;
        }
      }
    });
}
//#endregion

//#region DefineExposes
/**
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>
