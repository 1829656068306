import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para retorno paginado da listagem
 * 
 * @typedef {Object} ContractProposalListType
 * @property {boolean} approvation
 * @property {string} code - Código da proposta/contrato
 * @property {number} construction_id - ID da construção
 * @property {string} construction_name - Nome da construção
 * @property {string} created_at - Data de criação da proposta/contrato (Formato: YYYY-MM-DD HH:mm:ss)
 * @property {string} created_by_user_name - Nome do usuário que criou a proposta/contrato
 * @property {string} customer_name - Nome do cliente 
 * @property {string | null} entity_uuid - UUID da entidade (null quando proposta)
 * @property {?number} guarantor_entity_id - ID da entidade fiadora (null se não houver)
 * @property {number} id - ID único da proposta/contrato
 * @property {boolean} necessary_approvation - Aprovação necessária
 * @property {boolean} pending_formulation - Indica se há formulação pendente
 * @property {boolean} pending_launch_value - Valor pendente de lançamento
 * @property {boolean} pending_services - Indica se há serviços pendentes
 * @property {?string} situation_name - Nome da situação atual da proposta/contrato
 * @property {boolean} status - 1-Ativo, 0-Inativo
 * @property {boolean} total_construction_attachments - Ianexos de construção
 * @property {string} updated_at - Data de atualização
 * @property {string} updated_by_user_name - Nome do usuário que atualizou a proposta/contrato
 * @property {string} uuid - UUID único da proposta/contrato
 */
export const ContractProposalListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<ContractProposalListType> } )} ContractProposalListPagerType
 */
export const ContractProposalListPagerType = {};

/**
 * Inicializar ContractProposalListPagerType
 * 
 * @returns {ContractProposalListPagerType}
 */
export const initContractProposalListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};
 

