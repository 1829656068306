<template>
  <div>
    <AppFixedPageTitle
      title="Viagens"
      icon="/img/icons/chemical-plant-white.png"
    />
    <div class="container-fluid">
      <div class="row align-items-center mt-4 mb-2">
        <div class="col-xl-4">
          <div class="container m-0">
            <div class="row">
              <div class="col-12 p-0">
                <div class="d-flex align-items-center">
                  <h3 class="m-0 new-default-black-font">
                    Código:
                  </h3>
                  <h3 class="m-0 font-weight-400 new-default-black-font">
                    {{ schedule.contract_proposal.code }}
                  </h3>
                </div>
              </div>
              <div class="col-12 p-0 my-2">
                <div class="d-flex">
                  <h3 class="m-0 new-default-black-font">
                    Cliente:
                  </h3>
                  <h3 class="m-0 font-weight-400 new-default-black-font">
                    {{ schedule.contract_proposal.customer_name }}
                  </h3>
                </div>
              </div>
              <div class="col-12 p-0">
                <div class="d-flex align-items-center">
                  <h3 class="m-0 new-default-black-font">
                    Obra:
                  </h3>
                  <h3 class="m-0 font-weight-400 new-default-black-font">
                    {{ schedule.contract_proposal.construction_name }}
                  </h3>
                </div>
              </div>
              <div class="col-12 p-0 mt-3 mb-2">
                <AppSearchBar
                  class="custom-search-bar240220251135"
                  :searchBarFilter.sync="searchBarFilter"
                  :isLoading="loadingSkeleton"
                  @onSearchClick="listItems"
                  @onClearClick="clearFilter"
                >
                </AppSearchBar>
              </div>
            </div>
          </div>
        </div>
        <div class="offset-xl-3 col-xl-5">
          <div class="container m-0">
            <div class="row justify-content-end">
              <div class="col-xl-3 pr-xl-1 p-0">
                <AppPageHeaderActionsButton
                  class="custom-search-bar240220251135"
                  text="Novo"
                  type="success"
                  @click.prevent="handleModalAddTravel"
                  icon="/img/icons/icons8/ios/plus-math--v1-white.png"
                />
              </div>
              <div class="col-xl-3 px-xl-1 p-0"
                v-show="schedule.next_travel_uuid">
                <AppPageHeaderActionsButton
                  class="custom-search-bar240220251135"
                  text="Carregar"
                  @click.prevent="$router.push({
                    path: '/operational/schedule/charging',
                    name: 'operational.schedule.charging.index',
                    params: { travel_uuid: schedule.next_travel_uuid },
                  })"
                  type="warning"
                  icon="/img/icons/icons8/ios/plus-math--v1-white.png"
                />
              </div>
              <div class="col-xl-3 px-xl-1 p-0"
                v-if="enableForceFinish()">
                <AppPageHeaderActionsButton
                  class="custom-search-bar240220251135"
                  text="Concluir"
                  type="primary"
                  @click.prevent="forceFinish"
                  icon="/img/icons/icons8/ios/double-tick.png"
                />
              </div>
              <div class="col-xl-3 px-xl-1 p-0"
                v-if="enableRecoveryForceFinish()">
                <AppPageHeaderActionsButton
                  class="custom-search-bar240220251135"
                  text="Retomar"
                  type="primary"
                  @click.prevent="recoveryForceFinish"
                  icon="/img/icons/icons8/ios/svgs/reload.svg"
                />
              </div>
              <div class="col-xl-3 pl-xl-1 p-0">
                <AppPageHeaderActionsButton
                  class="custom-search-bar240220251135"
                  text="Voltar"
                  type="light"
                  @click.prevent="$router.push({
                    name: 'operational.schedule.view'
                  })"
                  icon="/img/icons/circled-left.png"
                />
              </div>
            </div>
            <div class="row mt-3"
              v-if="$helper.optionalChaining(schedule, 'contract_proposal_formulation')">
              <div class="col-12 p-0">
                <div class="concrete-stroke-card">
                  <div class="container m-0">
                    <div class="row align-items-center">
                      <div class="col-auto pr-1">
                        <div class="circled-test-tube">
                          <AppIcon
                            icon="test-tube"
                            color="primary"
                            width="32"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <h3 class="m-0 new-default-black-font">
                          {{ schedule.contract_proposal_formulation.title }}
                        </h3>
                        <h4 class="m-0 new-default-gray-font font-weight-normal">
                          {{ schedule.contract_proposal_formulation.body }}
                        </h4>
                        <h4 class="m-0 new-default-gray-font font-weight-normal">
                          {{ schedule.contract_proposal_formulation.footer }}
                        </h4>
                        <template v-if="schedule.contract_proposal_formulation.puzl_additions && schedule.contract_proposal_formulation.puzl_additions.length">
                          <h4 class="m-0 new-default-gray-font font-weight-normal">
                          <template
                            v-for="(puzl_addition, index) in schedule.contract_proposal_formulation.puzl_additions">
                            {{ puzl_addition.name }}
                            ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                            <span class="text-indigo"
                              v-if="index !== schedule.contract_proposal_formulation.puzl_additions.length - 1">
                              +
                            </span>
                            <span v-else></span>
                          </template>
                          </h4>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppTabSelect
      :items="tabSelectItems"
      @onTabSelectItemClick="onTabSelectItemClick"
      @onViewTypeChange="(type) => listType = type"
      :isHideTableOnMobileActive="true"
    >
      <AppSelect
        placeholder="ORDENAR"
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems"
        variant="text-only"
      />
    </AppTabSelect>
<!--              <base-button-->
<!--                @click.prevent="recoveryForceFinish"-->
<!--                block-->
<!--                size="lg"-->
<!--                type="primary"-->
<!--                class="text-uppercase text-strong-warning"-->
<!--                :disabled="loadingSkeleton || loadingSchedule"-->
<!--              >-->
<!--                retomar-->
<!--              </base-button>-->
    <Cards
      v-show="listType === 'cards'"
      :loadingSkeleton="loadingSkeleton"
      :loadingSchedule="loadingSchedule"
      :downloadProof="downloadProof"
      @handleHardenedStateTest="handleHardenedStateTest"
      @handleReleaseTravel="handleReleaseTravel"
      @setToUnrealized="setToUnrealized"
      @downloadNfeDanfe="downloadNfeDanfe"
      @downloadNfeXml="downloadNfeXml"
      @handleShowModalStoreMDFe="handleShowModalStoreMDFe"
      @handleClearMcc="handleClearMcc"
      @handleEditConcreteLeftOver="handleEditConcreteLeftOver"
      @handleDeleteConcreteLeftOver="handleDeleteConcreteLeftOver"
      @copy="copy"
      :handleWaterControl="handleWaterControl"
      :emptyWaterControl="emptyWaterControl"
      :showAdjustments="showAdjustments"
      :handleFreshStateTest="handleFreshStateTest"
      :handleModalScheduleTravelCycleAttchment="handleModalScheduleTravelCycleAttchment"
      @handleVolumeEdit="handleModalEditTravel"
      @handleConcreteLeftover="handleConcreteLeftover"
      @handleCancelTravel="handleCancelTravel"
      @handleTravelCycle="handleTravelCycle"
    />
    <Table
      v-show="listType === 'table'"
      :loadingSkeleton="loadingSkeleton"
      :loadingSchedule="loadingSchedule"
      :downloadProof="downloadProof"
      @handleHardenedStateTest="handleHardenedStateTest"
      @handleReleaseTravel="handleReleaseTravel"
      @setToUnrealized="setToUnrealized"
      @downloadNfeDanfe="downloadNfeDanfe"
      @downloadNfeXml="downloadNfeXml"
      @handleShowModalStoreMDFe="handleShowModalStoreMDFe"
      @handleClearMcc="handleClearMcc"
      @handleEditConcreteLeftOver="handleEditConcreteLeftOver"
      @handleDeleteConcreteLeftOver="handleDeleteConcreteLeftOver"
      @copy="copy"
      :handleWaterControl="handleWaterControl"
      :emptyWaterControl="emptyWaterControl"
      :showAdjustments="showAdjustments"
      :handleFreshStateTest="handleFreshStateTest"
      :handleModalScheduleTravelCycleAttchment="handleModalScheduleTravelCycleAttchment"
      @handleVolumeEdit="handleModalEditTravel"
      @handleConcreteLeftover="handleConcreteLeftover"
      @handleCancelTravel="handleCancelTravel"
      @handleTravelCycle="handleTravelCycle"
    />
    <div class="container-fluid mt-4">
      <PuzlEmptyData v-if="!loadingSkeleton && !$_items.length"/>
    </div>
    <ModalConcreteLeftover @setLeftOver="init()" ref="concreteLeftover" />
    <ModalCancelTravel ref="ModalCancelTravel" @cancel="handleSetTravelCancel" />
    <ModalTravelCycle @updatedCycle="init" ref="travelCycle" />
    <ModalWaterControl ref="waterControl" @setWaterControl="setWaterControl" />
    <ModalAdjustment ref="ModalAdjustment" />
    <ModalFreshStateTest @updatedFreshStateTest="init" ref="freshStateTest" />
    <ModalAddTravel  @addedTravel="init" ref="modalAddTravel" />
    <ModalEditTravel @updatedTravel="init" ref="modalEditTravel" />
    <ModalScheduleTravelCycleAttachment ref="modalScheduleTravelCycleAttchment" />
    <ModalStoreMDFe @update="init" ref="ModalStoreMDFe"/>
    <ModalHardenedStateTest ref="hardenedStateTest"/>
  </div>
</template>

<script>
import Cards from "./Shared/_Cards";
import Table from "./Shared/_Table";
import { mapGetters } from "vuex";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import MultiFilter from "@/components/Utils/MultiFilterV3";
import cursorPaginate from "@/mixins/cursorPaginate";
import ModalConcreteLeftover from "./Shared/_ModalConcreteLeftover";
import ModalCancelTravel from "./Shared/_ModalCancelTravel";
import ModalTravelCycle from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalNewTravelCycle";
import ModalScheduleTravelCycleAttachment from "@/views/Modules/Operational/Schedule/Weighing/Shared/ScheduleTravelCycle/_ModalAttachmentView";
import ModalAddTravel from "./Shared/_ModalAddTravel";
import ModalEditTravel from "./Shared/_ModalEditTravel";
import ModalWaterControl from "./Shared/_ModalWaterControl";
import ModalAdjustment from "../Charging/Shared/_ModalAdjustments.vue";
import ModalFreshStateTest from "./Shared/_ModalFreshStateTest";
import ModalStoreMDFe from '../../Schedule/Charging/Shared/_ModalStoreMDFe';
import {
  AppFixedPageTitle,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppSearchBar,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppIcon,
  AppSelect,
  AppTabSelect
} from "@/components/AppGlobal";
import PuzlEmptyData from "@/components/PuzlEmptyData.vue";
import {ScheduleEnum} from "@/enum/ScheduleEnum";
import {initScheduleTravelListFilterType} from "./types";
import ModalHardenedStateTest from "@/views/Modules/Operational/Schedule/Weighing/Shared/_ModalHardenedStateTest.vue";

export default {
  name: "IndexWeighing",
  mixins: [cursorPaginate],
  components: {
    ModalHardenedStateTest,
    PuzlEmptyData,
    AppTabSelect, AppSelect,
    AppPageHeaderActionsButton,
    AppPageHeaderActions,
    AppSearchBar,
    AppFixedPageTitle,
    Cards,
    SkeletonPuzl,
    SkeletonPuzlGrid,
    MultiFilter,
    Table,
    ModalConcreteLeftover,
    ModalCancelTravel,
    ModalTravelCycle,
    ModalAddTravel,
    ModalWaterControl,
    ModalAdjustment,
    ModalFreshStateTest,
    ModalEditTravel,
    ModalScheduleTravelCycleAttachment,
    ModalStoreMDFe,
    AppIcon
  },
  computed: {
    ...mapGetters({
      $_schedule: "schedule/show",
      $_items: "travels/fetch",
    }),
    tabSelectItems() {
      return [
        {
          id: null,
          name: 'Todos',
          selected: this.filter.status === null,
        },
        {
          id: 0,
          name: 'A realizar',
          selected: this.filter.status === 0,
        },
        {
          id: 6,
          name: 'Cancelados',
          selected: this.filter.status === 6,
        },
        {
          id: 1,
          name: 'Concluídos',
          selected: this.filter.status === 1,
        },
      ]
    }
  },
  data() {
    return {
      loadingSchedule: true,
      loadingSkeleton: true,
      listType: "cards",
      searchBarFilter: initSearchBarFilterType(),
      filter: initScheduleTravelListFilterType(),
      scheduleEnum: ScheduleEnum,
      schedule: {},
      orderBy: {
        selected: 0,
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            filter: [],
          },
          {
            id: 1,
            name: "A-Z",
            selected_name: "A-Z",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "code",
              is_desc: false,
            }],
          },
          {
            id: 2,
            name: "Z-A",
            selected_name: "Z-A",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "code",
              is_desc: true,
            }],
          },
          {
            id: 3,
            name: "LANÇAMENTO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "LANÇ. MAIS NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: [{
              column: "id",
              is_desc: true,
            }],
          },
          {
            id: 4,
            name: "LANÇAMENTO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "LANÇ. MAIS VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: [{
              column: "id",
              is_desc: false,
            }],
          },
        ]
      }
    };
  },
  methods: {
    handleHardenedStateTest(travel) {
      if (travel.status === 1 || !travel.hardened_state) {
        return;
      }
      this.$refs.hardenedStateTest.openModal(travel.uuid, this.schedule.contract_proposal_formulation, travel.send_plant.id);
    },
    /**
     * @param {TabSelectItemType} item
     */
    onTabSelectItemClick(item) {
      if (this.filter.status !== item.id) {
        this.filter.status = item.id;
        if(this.filter.status === null){
          this.filter.show_all = true;
        } else {
          this.filter.show_all = null;
        }
        this.listItems();
      }
    },
    handleClearMcc(uuid) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show()
          this.$Progress.start()
          this.$store
            .dispatch("travels/deleteClearMcc", {uuid: uuid, force: false})
            .then(response => {})
            .catch(error => {
              this.$notify({type: 'danger', message: error.data.message})
            }).finally(() => {
            this.$Progress.finish();
            loader.hide()
            this.$parent.init()
          });
        }
      }).catch()
    },
    handleShowModalStoreMDFe(schedule_travel) {
      this.$refs.ModalStoreMDFe.openModal({
        schedule_travel_id: schedule_travel.id,
        name: schedule_travel.driver,
        document: schedule_travel.driver_document
      })
    },
    /**
     * Download de um arquivo XML da NFe.
     *
     * @param {Object} item - Objeto contendo as informações do item a ser baixado.
     * @param {string} item.xml_file_path - Caminho do arquivo XML da NFe.
     * @param {string} item.key - Chave da NFe usada como nome do arquivo.
     */
    downloadNfeXml(item) {
      let loader = this.$loading.show();
      this.$store.dispatch('exports/download', `${this.base_url_ms}download-s3?url='${item.xml_file_path}`)
        .then(res => {
          let blob = new Blob([res], {type: "application/xml"});
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${item.key}.xml`);
          link.click();
        })
        .finally(() => loader.hide());
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    },
    /**
     * Download de um arquivo PDF da NFe (Danfe).
     *
     * @param {Object} item - Objeto contendo as informações do item a ser baixado.
     * @param {string} item.pdf_file_path - Caminho do arquivo PDF da NFe (Danfe).
     * @param {string} item.key - Chave da NFe usada como nome do arquivo.
     */
    downloadNfeDanfe(item) {
      let loader = this.$loading.show();
      this.$store
        .dispatch('exports/download', `${this.base_url_ms}download-s3?url='${item.pdf_file_path}`)
        .then((res) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([res], {type: "application/pdf"}));
          link.setAttribute("download", `${item.key}.pdf`);
          link.click();
        })
        .finally(() => loader.hide());
      this.$snotify.success('Download iniciado com sucesso!', {
        timeout: 1000,
        icon: false,
        position: "centerBottom",
        showProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false
      });
    },
    enableForceFinish() {
      if (
        this.schedule &&
        (this.schedule.complete_travels > 0 || this.schedule.cancelled_travels > 0) &&
        !this.schedule.force_finished &&
        this.schedule.status.status !== 11 &&
        this.schedule.status.status !== 0 &&
        this.schedule.status.status !== 15
      ) {
        return true;
      }
      return false;
    },
    enableRecoveryForceFinish() {
      if (
        this.schedule &&
        (this.schedule.complete_travels > 0 || this.schedule.cancelled_travels > 0) &&
        this.schedule.force_finished
      ) {
        return true;
      }
      return false;
    },
    setViewType(type) {
      this.listType = type;
    },
    clearFilter(status = null){
      this.filter = initScheduleTravelListFilterType();
      this.searchBarFilter = initSearchBarFilterType();

      if(status.status === this.scheduleEnum.COMPLETED){
        this.filter.status = null;
        this.filter.show_all = true;
      }

      this.listItems();
    },
    listItems(){
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.init(this.filter);
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("travels/fetchItems", {
          uuid: this.$route.params.schedule_uuid,
          code: "1",
          filter: this.filter,
          next_page: this.paginate.nextUrl,
        })
        .then((response) => {
          this.resolveCursor(response);
          this.loadingSkeleton = false;
        });
    },
    handleUpdateVolumeTravel(travel) {
      this.$refs.updateVolumeTravel.openModal(travel);
    },
    downloadProof(travel) {
      let params = {
        uuid: travel.uuid,
        type: "proof",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then((response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", "custom_name.pdf"); // Added Line
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    /**
     * Copia o código da automação
     * @param code
     */
    copy(code) {
      this.$helper.copy(code);
      this.$notify({ type: "success", message: "Código da automação copiado com sucesso!" });
    },
    handleConcreteLeftover(schedule_travel, volume = null) {
      if (schedule_travel.count_volume_left_over === 0) {
        return this.$notify({
          type: "danger",
          message: "Não há sobra de concreto suficiente.",
        });
      }
      this.$refs.concreteLeftover.openModal(schedule_travel, volume);
    },
    handleCancelTravel(travel) {
      this.$refs.ModalCancelTravel.openModal(travel);
    },
    handleSetTravelCancel(travel) {
      this.init();
      this.$refs.ModalCancelTravel.closeModal();
      travel.status = 6;
      this.handleConcreteLeftover(travel, travel.volume);
    },
    /** Liberação de viagens */
    handleReleaseTravel(travel) {
      this.$swal
        .fire({
          title: "Você tem certeza?",
          text: "Todas as viagens anteriores com status aguardando liberação também serão liberadas!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, prosseguir!",
          cancelButtonText: "Não, mantenha",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.loadingSchedule = true;
            this.loadingSkeleton = true;
            await this.$store.dispatch("schedule/releaseTravels", {uuid: travel.schedule_uuid, index: travel.index});
            this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
              this.schedule = response.data;
              this.loadingSchedule = false;
              this.listItems()
            });
          }
        });
    },
    /**
     * Torna ou retoma uma viagem não realizada
     *
     * @param {integer} id
     * @returns {void}
     */
    setToUnrealized(id) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/setToUnrealized", id)
        .then((response) => {
          this.init({});
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
        })
        .finally(() => {
          loader.hide();
        });
    },
    handleTravelCycle(travel, address_construction) {
      this.$refs.travelCycle.openModal(travel, address_construction);
    },
    handleModalScheduleTravelCycleAttchment(schedule_travel_cycle_id) {
      this.$refs.modalScheduleTravelCycleAttchment.openModal(schedule_travel_cycle_id);
    },
    forceFinish() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.loadingSkeleton = true;
      this.$Progress.start();
      this.$store
        .dispatch("schedule/forceFinish", {
          uuid: this.$route.params.schedule_uuid,
        })
        .then((response) => {
          this.$store
            .dispatch("travels/fetchItems", {
              uuid: this.$route.params.schedule_uuid,
              code: "1",
            })
            .then((response) => {
              this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
                this.schedule = response.data;
                this.$Progress.finish();
                this.loadingSkeleton = false;
              });
            });
        })
        .catch((error) => {
          this.$notify({
            type: "danger",
            message: error.data.message,
          });
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    recoveryForceFinish() {
      this.loadingSkeleton = true;
      this.$Progress.start();
      this.$store
        .dispatch("schedule/recoveryForceFinish", {
          uuid: this.$route.params.schedule_uuid,
        })
        .then((response) => {
          this.$store
            .dispatch("travels/fetchItems", {
              uuid: this.$route.params.schedule_uuid,
              code: "1",
            })
            .then((response) => {
              this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
                this.schedule = response.data;
                this.$notify({ type: "success", message: response.message });
                this.$Progress.finish();
                this.loadingSkeleton = false;
              });
            });
        })
        .catch((error) => {
          if (error.status == 200) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loadingSkeleton = false;
          } else if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.$Progress.finish();
          this.loadingSkeleton = false;
        });
    },
    handleEditConcreteLeftOver(travel, leftOver, volumeLimit) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("travelLeftOver/show", leftOver.uuid)
        .then((response) => {
          if (response.data.travel_reference_uuid !== null) {
            loader.hide();
            return this.$notify({type: "warning", message: "Reuso não pode ser editado pois já está sendo utilizado!"});
          }
          leftOver.count_volume_left_over = volumeLimit + parseFloat(leftOver.volume);
          this.$refs.concreteLeftover.editModal(travel, leftOver);
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    handleDeleteConcreteLeftOver(leftOver, params) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show();
          this.$store
            .dispatch("travelLeftOver/destroy", leftOver.uuid)
            .then((response) => {
              this.$notify({type: response.error_type, message: response.message});
              this.travels[params.index].concrete_left_over.splice(params.index_left_over, 1);
              loader.hide();
            })
            .catch((error) => {
              this.$notify({type: error.data.error_type, message: error.data.message});
              loader.hide();
            });
        }
      });
    },
    handleWaterControl(uuid, index) {
      let params = {
        uuid: uuid,
        index: index,
        water_control: this.$_items[index].water_control,
        retained_water: this.$_items[index].retained_water,
      };
      if (this.$_items[index].concrete_left_over.length) {
        return;
      }
      if (!this.$_items[index].isset_manual_dosage) {
        this.$notify({
          type: "danger",
          message: "Carregamento ainda não realizado.",
        });
        return;
      }
      this.$refs.waterControl.openModal(params);
    },
    setWaterControl(params) {
      this.$_items[params.index].water_control = params.water_control;
    },
    emptyWaterControl(water) {
      return !water.company_plant_liters || !water.construction_liters;
    },
    /**
     * Obtém os ajustes associados a uma viagem e exibe-os em um modal
     * @param {Object} schedule_travel - Objeto contendo informações da viagem
     * @param {string} schedule_travel.uuid - O UUID da viagem
     * @param {Boolean} schedule_travel.has_mcc - Valida se a viagem possui MCC associado
     */
    async showAdjustments(schedule_travel) {
      let loader = this.$loading.show();
      this.$Progress.start();
      let response = await this.$store.dispatch("travels/show", { uuid: schedule_travel.uuid });
      let travel = response.data;
      if (!travel) {
        return this.$notify({ type: "danger", message: "Viagem não encontrada!" });
      }
      if (!travel.schedule.contract_proposal_formulation.mix_mixe_id && !travel.has_adjustments) {
        return this.$notify({ type: "danger", message: "Traço não encontrado!" });
      }

      let params = {
        has_dosage: travel.isset_manual_dosage || false,
        schedule_travel_uuid: travel.uuid,
        volume_adjustments: travel.volume || null,
        concrete_piece_adjustments: travel.mix_concreted_piece_uuid,
        temperature_adjustments: travel.temperature || null,
        concrete_cycle_adjustments: travel.concrete_cycle,
        charge_point_adjustments: travel.charge_point_uuid || null,
        company_plant_uuid: travel.send_plant.uuid,
        mix_design_mixe_id: travel.schedule.contract_proposal_formulation.mix_mixe_id,
      };
      this.$store
        .dispatch("travels/getAdjustments", params)
        .then((response) => {
          this.$Progress.finish();
          loader.hide();
          this.$notify({ type: "success", message: response.message });
          this.modal_shown = true;
          this.$refs.ModalAdjustment.openModal();
        })
        .catch((error) => {
          this.$Progress.finish();
        });
    },
    handleFreshStateTest(uuid, index) {
      if (!this.$_items[index].isset_manual_dosage) {
        this.$notify({
          type: "danger",
          message: "Carregamento ainda não realizado.",
        });
        return;
      }
      this.$refs.freshStateTest.openModal(uuid);
    },
    handleModalAddTravel() {
      this.$refs.modalAddTravel.openModal(this.$route.params.schedule_uuid, this.schedule.interval_releases, this.schedule.company_plants[0].company_plant_uuid);
    },
    handleModalEditTravel(schedule_travel) {
      this.$refs.modalEditTravel.openModal(schedule_travel, this.schedule.interval_releases);
    },
  },
  async mounted() {
    await this.$store.dispatch('operationalPermission/getByUserId', this.$user.id);
    this.$store.dispatch("schedule/show", this.$route.params.schedule_uuid).then((response) => {
      this.schedule = response.data;
      this.clearFilter(this.schedule.status);
      this.loadingSchedule = false;
    });
  },
};
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.circled-test-tube {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary;
}

.concrete-stroke-card {
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid #f4f4f4;
  background-color: #FAFAFA;
  box-shadow: 0 5px 10px 0 #0000000D;
}
</style>
<style lang="scss">
.custom-search-bar240220251135 {
  &.app-search-bar {
    width: 100%;
    padding: 3px 0 3px 0;
    border-radius: 12px;

    nav.additional-input-spot {
      width: 100%;

      .filter-option-item {
        border: none;
      }
    }
  }

  &.new-puzl-default, button.new-puzl-default {
    width: 100%;
    min-width: 100%;
    max-width: 100%;

    @media (min-width: 1200px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  &.new-puzl-default {

    @media (max-width: 1199px) {
      margin-left: 0 !important;
    }
  }
}
</style>
