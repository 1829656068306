import { BaseListFilterType, initBaseListFilterType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} PurchaseBudgetListFilterExtType
 * @property {boolean|null} status
 * @property {?Array} range
 * @property {boolean|null} is_external_service // Terceiro
 * @property {boolean|null} concrete_removal // Retira de terceiro
 * 
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & PurchaseBudgetListFilterExtType)} PurchaseBudgetListFilterType
 */
export const PurchaseBudgetListFilterType = {};

/**
 * Inicializar PurchaseBudgetListFilterType
 *
 * @returns {PurchaseBudgetListFilterType}
 */
export const initPurchaseBudgetListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    company_plant_id: null,
    range: {
      start: null,
      end: null,
    },
    order_by: [{ column: "purchase_budgets.id" }],
    custom_search: {
      columns: [
        "purchase_requests.id",
        "purchase_categories.description",
      ],
    },
  };

}