<template>
  <div>
    <modal @close="close" size="md-2" :show.sync="show">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/get-cash-white.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form v-if="installment" @submit.prevent="handleSubmit(save)" class="needs-validation" autocomplete="off">
            <div class="container-fluid">
              <div class="row mt-3 mb-2">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/contract-job-primary.png" width="20">
                  <h4 class="new-default-black-font m-0 mx-3" style="white-space: nowrap;">
                    CLIENTE
                  </h4>
                  <hr class="ml-3 m-0" style="width: 100%;">
                </div>
              </div>
              <div class="row mb-1 mt-n1 align-items-center">
                <div class="col-md-7">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    {{ installment.entity_name }}
                  </h4>
                  <h4 class="new-default-black-font font-weight-normal mt-0">
                    {{ installment.document }}
                  </h4>
                </div>
                <div class="col-md-5">
                  <div class="row">
                    <div class="col-md-12" v-if="installment.invoices && installment.invoices.length">
                      <base-button
                        @click.prevent="downloadInvoice(bill_receive.invoices[0].id)"
                        style="background: white !important;color: black;border-radius: 4px !important;" type="light"
                        size="sm" block>
                        <i class="fa-2x fa-solid fa-file-pdf text-danger" style="font-size: 20px;"></i>
                        <small
                          class="text-truncate new-default-black-font"
                          style="font-size: 12px;text-transform: none"
                        >
                          N° {{ installment.invoices[0].id }}
                        </small>
                      </base-button>
                    </div>
                    <div class="col-md-12 mt-1"
                         v-if="installment.invoices && installment.invoices.length && (installment.invoices[0].number !== 'null' && installment.invoices[0].number !== null)">
                      <base-button
                        @click.prevent="nfseButtonClick(bill_receive.invoices[0].link_pdf)"
                        style="background: white !important;color: black;border-radius: 4px !important;" type="light"
                        size="sm" block>
                        <img src="/img/icons/nfe_blue.png" style="width: 24px;margin-left: -4px;margin-right: 6px;">
                        <small
                          :title="installment.invoices[0].number.toString().slice(-6)"
                          class="text-truncate new-default-black-font"
                          style="font-size: 12px;text-transform: none"
                        >
                          N°{{ installment.invoices[0].number.toString().slice(-6) }}
                        </small>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 align-items-center">
                <div class="col-md-12">
                  <h4 class="new-default-black-font font-weight-normal m-0">
                    {{ installment.contract_proposal_code }}
                  </h4>
                  <h4 class="new-default-black-font font-weight-normal mt-0">
                    {{ installment.construction_name }}
                  </h4>
                </div>
              </div>
              <div class="row mt-4 mb-2">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/exchange-dollar_primary.png" width="20">
                  <h4 class="new-default-black-font m-0 mx-3 ">
                    Operação
                  </h4>
                  <div class="ml-3 mt-n4 text-right" style="width: 100%;">
                    <div
                      class="divider-header text-success">
                      RECEBIMENTO/CRÉDITO
                    </div>
                    <hr class="m-0" style="width: 100%;">
                  </div>
                </div>
              </div>
              <div class="row pt-1 align-items-center">
                <div class="col-9">
                  <label class="new-default-black-font">Parcela</label>
                </div>
                <div class="col-3 text-right">
                  <div class="installment-count">
                    <h4 class="m-0 p-0 mr-2 new-default-black-font font-weight-400">{{ installment.index }}</h4>
                    <div class="vertical-divider"></div>
                    <h4 class="m-0 p-0 ml-2 new-default-black-font font-weight-400">{{ installment.total_index }}</h4>
                  </div>
                </div>
              </div>
              <div class="row pt-1 align-items-center">
                <div class="col-md-7">
                  <label class="new-default-black-font">Vencimento</label>
                </div>
                <div class="col-md-5">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker disabled
                                              :value="installment.due_date"
                                              :mode="'date'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-7 new-default-black-font"
                >
                  Valor bruto
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      :value="installment.value | currency()"
                      disabled
                      ref="feesInput"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-7 new-default-black-font"
                >
                  Descontos
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      @blur="calcTotalValue()"
                      v-money="money"
                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      v-model="payload.discounts"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-7 new-default-black-font"
                >
                  Taxas
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      @blur="calcTotalValue()"
                      v-money="money"
                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      v-model="payload.discounts_fees"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-7 new-default-black-font"
                >
                  Juros/Multa
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      @blur="calcTotalValue()"
                      v-money="money"
                      @keydown="$event.key === '-' ? $event.preventDefault() : null"
                      v-model="payload.fees_fines"
                      ref="feesInput"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-7 new-default-black-font"
                >
                  Valor líquido
                </label>
                <div class="col-md-5">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      disabled
                      :value="payload.total_value | currency()"
                      maxlength="13"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="prepend">
                      <small class="input-group-sm p-0 m-0">
                        R$
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>
              <div class="row pt-4 align-items-center">
                <div class="col-5">
                  <label class="new-default-black-font">Data do crédito</label>
                </div>
                <div class="col-7">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker
                        v-model="payload.paid_at"
                        :disableMaxDate="new Date()"
                        :mode="'date'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-5 new-default-black-font"
                >
                  Método de pagamento
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      :clearable="false"
                      v-model="payload.payment_method_id"
                      :items="$_payment_methods"
                    />
                  </base-input>
                </div>
              </div>
              <div class="row pt-1  align-items-center">
                <label
                  class="col-md-5 new-default-black-font"
                >
                  Conta bancária
                </label>
                <div class="col-md-7">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      :clearable="false"
                      :items="bank_accounts"
                      v-model="payload.bank_account_id"
                    />
                  </base-input>
                </div>
              </div>
              <div class="row mt-5 ">
                <div class="col-12 text-right">
                  <base-button type="danger"
                               size="sm"
                               style="width: 150px;" @click.prevent="close">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/cancel-white.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Cancelar
                      </i>
                    </div>
                  </base-button>
                  <base-button
                    :disabled="!payload.paid_at || loading || !payload.payment_method_id"
                    type="success"
                    size="sm"
                    style="width: 150px;"
                    @click.prevent="save">
                    <div style="display: inline-flex; align-items: center;">
                      <img src="/img/icons/icons8/ios/checked.png" width="20">
                      <i class="m-0 ml-1" style="font-style: normal;">
                        Confirmar
                      </i>
                    </div>
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <!--    <modal @close="close" size="md" :show.sync="show">-->
    <!--      <template slot="header">-->
    <!--        <h5 class="modal-title p-0 m-0">{{ title }}</h5>-->
    <!--      </template>-->
    <!--      <validation-observer-->
    <!--        v-slot="{ invalid }"-->
    <!--        ref="formValidator"-->
    <!--      >-->
    <!--      <div>-->
    <!--        <div v-show="$_bill_receive" class="container-fluid">-->
    <!--          <div class="row">-->
    <!--            <div class="col-md-12 mb-1">-->
    <!--              <label-->
    <!--                class="col-form-label form-control-label"-->
    <!--              >-->
    <!--                Data do crédito <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--              </label>-->
    <!--              <validation-provider rules="required">-->
    <!--                <input-date-time-picker v-model="payload.paid_at" :disableMaxDate="new Date()" />-->
    <!--              </validation-provider>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--          <div class="form-row">-->
    <!--            <label-->
    <!--              class="col-md-12 pt-2 pb-1 col-form-label form-control-label"-->
    <!--            >-->
    <!--              Conta bancária de crédito-->
    <!--              <span class="text-danger">&nbsp;*&nbsp;</span>-->
    <!--            </label>-->
    <!--            <div class="col-md-12">-->
    <!--              <validation-provider rules="required">-->
    <!--                <base-input input-classes="form-control-sm">-->
    <!--                  <puzl-select-->
    <!--                    :disabled="disabled_change_bank_account || $_bill_receive.has_paid"-->
    <!--                    v-model="payload.bank_account_id"-->
    <!--                    :items="$_bank_accounts"-->
    <!--                    :clearable="false"-->
    <!--                    :label="['bank_account', 'name']"-->
    <!--                    :recursiveKey="['bank_account', 'id']"-->
    <!--                    :labelMask="true"/>-->
    <!--                </base-input>-->
    <!--              </validation-provider>-->
    <!--            </div>-->
    <!--            <div class="col-md-12 ml-1 mt-2 border-3 border-dark border-left">-->
    <!--              <h2>-->
    <!--                <small>-->
    <!--                  {{ $_bill_receive.payment_method_name }}-->
    <!--                  <br>-->
    <!--                  {{ $_bill_receive.payment_term_name }}-->
    <!--                </small>-->
    <!--              </h2>-->
    <!--            </div>-->
    <!--            <div class="col-md-12 mt-2">-->
    <!--              <h3>-->
    <!--                <small>-->
    <!--                  Valor total: <span class="numeric font-weight-600 pl-1">{{-->
    <!--                    total_value | currency()-->
    <!--                  }}</span>-->
    <!--                </small>-->
    <!--              </h3>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="d-flex justify-content-center row m-3 pb-1 p-0">-->
    <!--        <div class="col-md-4">-->
    <!--          <base-button block type="secondary" @click="close()"> Fechar</base-button>-->
    <!--        </div>-->
    <!--        <div class="col-md-8 mt-3 mt-md-0">-->
    <!--          <base-button :disabled="invalid" :loading="isLoading" block type="success" @click="save()">-->
    <!--            Confirmar recebimento-->
    <!--          </base-button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      </validation-observer>-->
    <!--    </modal>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {VMoney} from "v-money";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  name: "ModalConfirmPayment",
  components: {PuzlSelect, InputDateTimePicker},
  props: {
    return_payment: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      title: 'Confirmação de recebimento',
      loading: false,
      loadingBankAccounts: false,
      disabled_change_bank_account: false,
      installment: null,
      show: false,
      total_value: 0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      bank_accounts: [],
      payload: {
        id: null,
        cancel_billet: 0,
        bank_account_id: null,
        payment_method_id: null,
        paid_at: null,
        total_value: 0,
        discounts: 0,
        fees_fines: 0,
        discounts_fees: 0,
        value: 0,
      },
    }
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      $_payment_methods: "paymentMethod/fetchUsable",
      $_bill_receive: "billReceive/show",
      $_general_settings: "generalSetting/show",
    }),
  },
  methods: {
    close() {
      this.show = false
    },
    nfseButtonClick(link) {
      this.$notify({type: 'success', message: 'Download iniciado com sucesso!'});
      window.open(link, '_parent');
    },
    convertNumber(num) {
      num = num.toString()
      if (num.includes(',') && num.includes('.')) {
        num = num.toString().replaceAll(".", "").replace(",", ".");
      }
      if (num.includes(',') && !num.includes('.')) {
        num = num.toString().replace(',', '.');
      }
      return Number(num).toFixed(2);
    },
    async calcTotalValue() {
      let current_total_value = Number(this.value)
      let fees_fines = this.convertNumber(this.payload.fees_fines)
      let discounts_fees = this.convertNumber(this.payload.discounts_fees)
      let discounts = this.convertNumber(this.payload.discounts)
      current_total_value += Number(fees_fines)
      current_total_value -= Number(discounts_fees)
      current_total_value -= Number(discounts)
      this.payload.total_value = Number(current_total_value).toFixed(2);
    },
    downloadInvoice(id) {
      this.$Progress.start()
      let loader = this.$loading.show()
      this.$store.dispatch('billingInvoice/download', {
          id: id
        }
      ).then(response => {
        let blob = new Blob([response],
          {type: 'application/pdf'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute("download", 'report.pdf');
        window.open(link, '_blank')
        loader.hide()
        this.$Progress.finish()
      }).catch(error => {
        loader.hide()
        this.$Progress.finish()
      })
    },
    async handleCreateModal(installment, disabled_change_bank_account = false, cancel_billet = 0) {
      await this.$store.dispatch('generalSetting/show')
      await this.$store.dispatch('paymentMethod/fetchItems')
      await this.$store
        .dispatch(
          "companyPlantBankAccount/getByCompanyPlant", {
            allocated: 1,
            type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
            type_id: this.$_general_settings.show_bank_account_by_issuer ? installment.company_plant_issuer_id : installment.company_plant_id
          })
        .then((response) => {
          this.bank_accounts = response.data.map(({bank_account: {name}, bank_account: {id}}) => ({name, id}));
        })
      this.payload.id = installment.installment_id
      this.payload.cancel_billet = cancel_billet
      this.disabled_change_bank_account = disabled_change_bank_account
      this.payload.paid_at = null
      this.payload.bank_account_id = this.$_bill_receive.bank_account_id
      this.payload.payment_method_id = installment.payment_method_id
      this.payload.discounts = installment.discounts || 0
      this.payload.discounts_fees = installment.discounts_fees || 0
      this.payload.fees_fines = installment.fees_fines || 0
      this.installment = installment
      this.value = installment.value
      await this.calcTotalValue()
      this.show = true
    },
    save() {
      this.$Progress.start();
      this.loading = true
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$store
        .dispatch("billReceive/changeInstallmentStatus", {
          id: this.payload.id,
          status: 1,
          type: this.$_bill_receive.type,
          bank_account_id: this.payload.bank_account_id,
          payment_method_id: this.payload.payment_method_id,
          paid_at: this.payload.paid_at,
          return_payment: +this.return_payment,
          manual: 1,
          cancel_billet: this.payload.cancel_billet,
          discounts: this.payload.discounts,
          fees_fines: this.payload.fees_fines,
          discounts_fees: this.payload.discounts_fees,
          total_value: this.payload.total_value,
        })
        .then(response => {
          this.loading = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.show = false
          this.$emit('updateInstallment', {
            data: response.data,
            index: this.index
          })
        })
        .catch(error => {
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.loading = false;
          this.$Progress.finish();
        });
    }
  },
};
</script>

<style scoped>
.divider-header {
  background: #e1e1e1;
  padding: 3px 20px;
  color: #db4539;
  /*  color: #6b8184;*/
  font-size: 11px;
  width: fit-content;
  display: inline-flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.installment-count {
  border: 1px solid #e9ecef !important;
  padding: 0px 15px;
  background-color: #e9ecef;
  box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px !important;
  display: inline-flex;
  align-items: center;
  height: 28px !important;
}

.installment-count h4 {
  color: #5c5757;
}

.installment-count .vertical-divider {
  width: 1px;
  height: 30px;
  background: white;
  margin: 0px 7px;
  transform: rotate(25deg);
}

.installment-count .vl-90 {
  transform: rotate(0deg);
}
</style>
