/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} ServiceType
 * @property {?number} id // [V]
 * @property {string} uuid // [V]
 * @property {boolean} status
 * @property {string} service_name
 * @property {string} equipment_category_uuid
 * @property {number} equipment_category_id
 * @property {string} equipment_category_name // [V]
 * @property {boolean} count_from_the_completed_schedule_travels
 * @property {?string} created_at // [V]
 * @property {?string} updated_at // [V]
 * @property {?number} created_by_user_id // [V]
 * @property {?number} updated_by_user_id // [V]

 */
export const ServiceType = {};

/**
 * Inicializar ServiceType
 * 
 * @returns {ServiceType}
 */
export const initServiceType = () => {
  return {
    id: null,
    uuid: '',
    status: true,
    service_name: '',
    equipment_category_uuid: '',
    equipment_category_id: null,
    equipment_category_name: '',
    count_from_the_completed_schedule_travels: 0,
    created_at: null,
    updated_at: null,
    created_by_user_id: null,
    updated_by_user_id: null,
  }
};