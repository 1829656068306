<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        class="needs-validation"
        @submit.prevent="handleSubmit(onSubmitClick)"
        autocomplete="off"
      >
        <div class="container-fluid">
          <!-- IDENTIFICAÇÃO -->
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <AppIcon icon="info-squared" color="warning" width="22" />
              <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>

          <!-- STATUS -->
          <div class="row mt-2 align-items-center">
            <div class="col-9">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Status
              </h4>
            </div>
            <div class="col-3">
              <AppSwitch v-model="formData.status"/>
            </div>
          </div>

          <!-- SERVIÇO -->
          <div class="row mt-2 align-items-center">
            <label for="service_name" class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Serviço
              </h4>
            </label>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                <input
                id="service_name"
                v-model="formData.service_name"
                type="text"
                class="form-control form-control-sm"
                :class="errors[0] ? 'is-invalid' : 'is-valid'"
                />
                </base-input>
              </validation-provider>
            </div>
          </div>

          <!-- CATEGORIA DE EQUIPAMENTO -->
          <div class="row mt-2 align-items-center">
            <label for="category_name" class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Categoria de Equipamento
              </h4>
            </label>
            <div class="col-6">
              <validation-provider>
                <base-input input-group-classes="input-group-sm">
                <puzl-select
                v-model="formData.equipment_category_id"
                :items="$_equipment_category"
                label="category_name"
                customKey="id"
                />
                </base-input>
              </validation-provider>
            </div>
          </div>

          <!-- CONTABILIZAR A PARTIR DA CONCLUSÃO DE VIAGENS -->
          <div class="row mt-2 align-items-center">
            <div class="col-9">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Contabilizar a partir da conclusão de viagens
              </h4>
            </div>
            <div class="col-3">
              <AppSwitch v-model="formData.count_from_the_completed_schedule_travels"/>
            </div>
          </div>
          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <AppButton
              label="Cancelar"
              type="danger"
              class="ml-2"
              :hasOutline="true"
              @click="onCancelClick"
              />
              <AppButton
              label="Salvar"
              type="success"
              class="ml-2"
              native-type="submit"
              :isDisabled="invalid"
              :isLoading="$_service_is_saving"
              />
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed, getCurrentInstance } from "vue";
import { ServiceType } from "../../types";
import serviceStore from "../../store/serviceStore.js";
import { ValidationObserver } from "vee-validate";
import { AppButton, AppSwitch, AppIcon } from "../../../../../components/AppGlobal";
import PuzlSelect from "@/components/PuzlSelect";
//#endregion

const store = getCurrentInstance().proxy.$store;

store.dispatch("equipmentCategory/fetchItems");
const $_equipment_category = computed(() => store.getters["equipmentCategory/fetch"]);

//#region Props
/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {ServiceType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
/** Eventos emitidos */
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_service_is_saving = computed(() => serviceStore.getters.getIsSaving());
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style scoped>
.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}
.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}
.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}
.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
.button-save:hover {
  background: #149E57;
  color: white;
}
.button-save:hover img {
  filter: brightness(0) invert(1);
}
.button-cancel:hover {
  background: #DB4539;
  color: white;
}
.button-cancel:hover img {
  filter: brightness(0) invert(1);
}
</style>
