import { BaseListFilterType, initBaseListFilterType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} EquipmentListFilterExtType
 * @property {boolean|null} status
 * @property {?number} company_plant_id
 * @property {?Array} in_situation
 * @property {?Array} in_category
 * @property {?Array} in_state
 * @property {boolean|null} is_external_service // Terceiro
 * @property {boolean|null} concrete_removal // Retira de terceiro
 * 
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & EquipmentListFilterExtType)} EquipmentListFilterType
 */
export const EquipmentListFilterType = {};

/**
 * Inicializar EquipmentListFilterType
 *
 * @returns {EquipmentListFilterType}
 */
export const initEquipmentListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    company_plant_id: null,
    in_category: null,
    in_situation: null,
    in_state: null,
    is_external_service: null,
    concrete_removal: null
  };

}