import { BaseListFilterType, initBaseListFilterType } from "../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} CompetitorListFilterExtType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & CompetitorListFilterExtType)} CompetitorListFilterType
 */
export const CompetitorListFilterType = {};

/**
 * Inicializar CompetitorListFilterType
 *
 * @returns {CompetitorListFilterType}
 */
export const initCompetitorListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}