import { BOTTOM_FILTER_ENUM } from "../enums/BottomFilterEnum";

/**
 * Tipo para parâmetros do período de pesquisa (InputDatePicker)
 *
 * @typedef {Object} SearchBarRangeParamsType
 * @property {Boolean} is_range - É um período entre datas
 * @property {Number} bottom_filter - Filtros inferiores da entrada de data. Use BOTTOM_FILTER_ENUM
 * @property {Boolean} only_filter_month - Permite somente intervalo de datas que estejam dentro do mesmo mês
 */
export const SearchBarRangeParamsType = {};

/**
 * Inicializa parâmetros do período de pesquisa com valores padrão
 *
 * @returns {SearchBarRangeParamsType} - Objeto inicializado para parâmetros do período de pesquisa
 */
export const initSearchBarRangeParamsType = () => {
  return {
    is_range: true,
    bottom_filter: BOTTOM_FILTER_ENUM.TODAY_CURRENT_MONTH_PREVIOUS_MONTH,
    only_filter_month: false,
    disable_min_date: null,
    disable_max_date: null,
  };
};
