export const PurchaseRequestHistoryStatusEnum = Object.freeze({
  CREATED: 0, // Criado
  EDITED: 1, // Editado
  APPROVED: 2, // Aprovado
  DENIED: 3, // Negado
  CANCELED: 4 // Cancelado
});

const getTitle = (value) => {
  switch (value) {
      case PurchaseRequestHistoryStatusEnum.CREATED:
          return "Criados";
      case PurchaseRequestHistoryStatusEnum.EDITED:
          return "Editados";
      case PurchaseRequestHistoryStatusEnum.APPROVED:
          return "Aprovados";
      case PurchaseRequestHistoryStatusEnum.DENIED:
          return "Negados";
      case PurchaseRequestHistoryStatusEnum.CANCELED:
          return "Cancelados";
      default:
          return "";
  }
};

export default {
  keys: PurchaseRequestHistoryStatusEnum,
  getTitle,
};