import store from "../../../../../../shared/libraries/store";
import { initPurchaseRequestListPagerType, PurchaseRequestListFilterType, PurchaseRequestListPagerType, PurchaseRequestType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../../shared/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/purchase",
  PURCHASE_REQUESTS: "/purchase/purchase_requests",
  PURCHASE_REQUEST_ITEMS: "/purchase/purchase_request_items",
  PURCHASE_REQUEST_ATTACHMENTS: "/purchase/purchase_request_attachments",
  PURCHASE_REQUEST_HISTORY: "/purchase/purchase_request_history",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "PURCHASE_REQUEST_ITEM",
  LISTED: "PURCHASE_REQUEST_LISTED",
  IS_LISTING: "PURCHASE_REQUEST_IS_LISTING",
  IS_FINDING: "PURCHASE_REQUEST_IS_FINDING",
  IS_SAVING: "PURCHASE_REQUEST_IS_SAVING",
  IS_REMOVING: "PURCHASE_REQUEST_IS_REMOVING",
  PAGE_STATES: "PURCHASE_REQUEST_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?PurchaseRequestType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {PurchaseRequestListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initPurchaseRequestListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{ searchBarFilter: SearchBarFilterType, filter: PurchaseRequestListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {PurchaseRequestType} item
   * @returns {PurchaseRequestType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {PurchaseRequestListPagerType} listed
   * @returns {PurchaseRequestListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {PurchaseRequestType} created
   * @returns {PurchaseRequestType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {PurchaseRequestListPagerType} listed
   * @returns {PurchaseRequestListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {PurchaseRequestType} updated
   * @returns {PurchaseRequestType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: PurchaseRequestListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};

/** ACTIONS */
const actions = {
   /**
   * Listar ordens de compra
   *
   * @param {PurchaseOrderListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?PurchaseOrderListPagerType>} - Retorno paginado
   */
   list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.PURCHASE_REQUESTS),

  /**
   * Listar itens de uma requisição de compra
   *
   * @param {PurchaseRequestListFilterType} filter - Filtro de listagem
   * @returns {Promise<?PurchaseRequestListPagerType>} - Retorno paginado
   */
  fetchRequestItems: async (filter) => {
    try {
      mutations.setIsListing(true);
      const res = await httpClient.get(urls.PURCHASE_REQUEST_ITEMS, { params: filter });
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      return res.data;
    } finally {
      mutations.setIsListing(false);
    }
  },

    /**
   * Adicionar uma nova requisição de compra
   *
   * @param {PurchaseRequestType} params - Dados da requisição de compra
   * @returns {Promise<PurchaseRequestType>} - Retorno da requisição criada
   */
    add: async (params) => {
      try {
        let formData = new FormData();
  
        // Adiciona todos os campos, exceto 'attachments' e 'items'
        Object.entries(params).forEach(([key, value]) => {
          if (key !== "attachments" && key !== "items") {
            formData.append(key, value);
          }
        });
  
        // Adiciona anexos
        params.attachments?.forEach((el, index) => {
          formData.append(`files[${index}]`, el.file);
        });
  
        // Adiciona itens
        params.items?.forEach((item, index) => {
          Object.entries(item).forEach(([key, value]) => {
            formData.append(
              `items[${index}][${key}]`,
              key === "quantity" ? parseFloat(value.replace(",", ".")) : value
            );
          });
        });
  
        const { data } = await httpClient.post(urls.PURCHASE_REQUESTS, formData);
        return data;
      } catch (error) {
        return Promise.reject(error);
      }
    },

  /**
   * Listar anexos de uma requisição de compra
   *
   * @param {PurchaseRequestListFilterType} filter - Filtro de listagem
   * @returns {Promise<?PurchaseRequestListPagerType>} - Retorno paginado
   */
  fetchRequestAttachments: async (filter) => {
    try {
      mutations.setIsListing(true);
      const res = await httpClient.get(urls.PURCHASE_REQUEST_ATTACHMENTS, { params: filter });
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      return res.data;
    } finally {
      mutations.setIsListing(false);
    }
  },

  /**
   * Obter histórico de uma requisição de compra
   *
   * @param {number} id - ID da requisição de compra
   * @returns {Promise<?PurchaseRequestType>} - Retorno do histórico
   */
  getHistory: async (id) => {
    try {
      mutations.setIsFinding(true);
      const res = await httpClient.get(`${urls.PURCHASE_REQUEST_HISTORY}/${id}`);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      return res.data;
    } finally {
      mutations.setIsFinding(false);
    }
  },

  /**
   * Criar uma nova requisição de compra
   *
   * @param {PurchaseRequestType} data - Dados da requisição de compra
   * @returns {Promise<PurchaseRequestType>} - Retorno da requisição criada
   */
  create: async (data) => await createSource(data, mutations, urls.PURCHASE_REQUESTS),

  /**
   * Atualizar uma requisição de compra
   *
   * @param {PurchaseRequestType} data - Dados da requisição de compra
   * @returns {Promise<PurchaseRequestType>} - Retorno da requisição atualizada
   */
  update: async (params) => {
    const formData = new FormData();
  
    Object.entries(params).forEach(([key, value]) => {
      if (key !== "attachments" && key !== "items") {
        formData.append(key, value);
      }
    });
  
    if (params.attachments?.length) {
      params.attachments.forEach((el, index) => {
        formData.append(`files[${index}]`, el.file);
      });
    }
  
    if (params.items?.length) {
      params.items.forEach((item, index) => {
        Object.entries(item).forEach(([key, value]) => {
          if (key === "quantity") {
            value = typeof value === "string" && value.includes(",") 
              ? parseFloat(value.replace(",", ".")) 
              : parseFloat(value);
          }
          formData.append(`items[${index}][${key}]`, value);
        });
      });
    }
  
    try {
      const { data } = await httpClient.post(`${urls.PURCHASE_REQUESTS}/${params.id}`, formData);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  /**
   * Deletar uma requisição de compra
   *
   * @param {number} id - ID da requisição de compra
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.PURCHASE_REQUESTS),

  /**
   * Obter detalhes de uma requisição de compra
   *
   * @param {number} id - ID da requisição de compra
   * @returns {Promise<?PurchaseRequestType>} - Retorno da requisição
   */
  show: async (id) => await findSource(id, mutations, '/purchase/purchase_request'),
};

export default {
  actions,
  getters,
  mutations,
};