<template>
  <div>
    <div>
      <PuzlEmptyData v-if="!items.length && !loadingSkeleton" />
      <div class="row">
        <ShadedTable :length="items.length" :headers="headers" :loading="loadingSkeleton">
          <template v-for="(item, indexItem) in items" :slot="indexItem">
            <ShadedCol style="width: 15%;height: fit-content;">
              <span
                v-if="item.status === NfeIssuedesStatusEnum.AUTHORIZED"
                class="badge-table badge-table-success d-flex"
                style="gap: 5px;"
                @click.prevent="cancelNfe(item)"
              >
                <img src="/img/icons/ok--v1.png" width="15px" height="15px">
                <b>Autorizada</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.REJECTED"
                class="badge-table badge-table-danger d-flex"
                style="gap: 5px;"
                @click.prevent="reSendXml(item)"
              >
                <img src="/img/icons/icons8/ios/fail_danger.png" width="15px" height="15px">
                <b>Rejeitada</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.CANCELLED"
                class="badge-table badge-table-danger d-flex"
                style="gap: 5px;"
              >
                <img src="/img/icons/icons8/ios/fail_danger.png" width="15px" height="15px">
                <b>Cancelada</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.DUPLICATED"
                class="badge-table badge-table-primary d-flex"
                style="gap: 5px;"
                @click.prevent="reSendXml(item)"
              >
                <img src="/img/icons/icons8/ios/copy-white.png" width="15px" height="15px">
                <b>Duplicada</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.DENIED"
                class="badge-table badge-table-danger d-flex"
                style="gap: 5px;"
                @click.prevent="reSendXml(item)"
              >
                <img src="/img/icons/icons8/ios/fail_danger.png" width="15px" height="15px">
                <b>Denegada</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.IN_PROGRESS || item.status === NfeIssuedesStatusEnum.PENDING || item.status === NfeIssuedesStatusEnum.SENDING"
                class="badge-table badge-table-warning d-flex"
                style="gap: 5px;"
              >
                <img src="/img/icons/icons8/ios/sending_warning.png" width="15px" height="15px">
                <b>Emitindo</b>
              </span>
              <span
                v-else-if="item.status === NfeIssuedesStatusEnum.IN_CANCELATION_PROCESS"
                class="badge-table badge-table-warning d-flex"
                style="gap: 5px;"
              >
                <img src="/img/icons/icons8/ios/sending_warning.png" width="15px" height="15px">
                <b>Em processo de cancelamento</b>
              </span>
            </ShadedCol>
            <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-center" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                  {{ item.date_at | parseDate('DD/MM/YYYY') }}
                </span>
            </ShadedCol>
            <ShadedCol style="width: 8%;height: fit-content;">
              <div class="d-flex">
                <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                  {{ item.number }} - {{ item.series }}
                </span>
                <div v-if="item.has_correction_letter" class="mx-1">
                  <a href="#" @click.prevent="handleShowModalCorrectionLetter(item)">
                    <img src="/img/icons/icons8/ios/mail-primary.png" width="25px" height="25px">
                  </a>
                </div>
                <div class="ml-auto">
                  <a href="#" @click.prevent="copy(item.key)">
                    <img style="width: 25px" src="/img/icons/icons8/ios/copy-2_primary.png" />
                  </a>
                </div>
              </div>
            </ShadedCol>
            <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.os }}
              </span>
            </ShadedCol>
            <ShadedCol style="width: 8%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.code }}
              </span>
            </ShadedCol>
            <ShadedCol style="width: 40%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.entity_name }}
              </span>
            </ShadedCol>
            <ShadedCol style="width: 15%;height: fit-content;">
              <span class="text-left" style="font-size: 13px;font-weight: 400;color: #2B2D32;">
                {{ item.created_at | parseDate('DD/MM/YYYY') }}
              </span>
            </ShadedCol>
            <ShadedCol style="width: 5%;">
              <div class="text-center">
                  <base-dropdown menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded m-0">
                      <img width="25px" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <span class="dropdown-item" @click="openModalEditEntity(item.entity_uuid)">
                      <img src="/img/icons/create-new.png" width="19px" height="19px">
                      EDITAR CLIENTE
                    </span>
                    <div v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-divider p-0 m-0"></div>
                    <span v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-item" @click="downloadPdfWithForcedUpdate(item.pdf_file_path, item.key, 'pdf')">
                      <img src="/img/icons/icons8/ios/synchronize_primary.png" width="19px" height="19px">
                      ATUALIZAR PDF
                    </span>
                    <div v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-divider p-0 m-0"></div>
                    <span v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-item" @click="downloadDanfe(item.schedule_travel_uuid, item.key)">
                      <img src="/img/icons/pdf-v2.png" width="19px" height="19px">
                      PDF
                    </span>
                    <div v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-divider p-0 m-0"></div>
                    <span v-if="item.status !== NfeIssuedesStatusEnum.REJECTED" class="dropdown-item" @click="download(item.xml_file_path, item.key, 'xml')">
                      <img src="/img/icons/icons8/ios/source-code.png" width="19px" height="19px">
                      XML
                    </span>
                    <div v-if="item.status === NfeIssuedesStatusEnum.AUTHORIZED" class="dropdown-divider p-0 m-0"></div>
                    <span @click.prevent="handleShowModalCorrectionLetter(item)" v-if="item.status === NfeIssuedesStatusEnum.AUTHORIZED" class="dropdown-item" >
                      <img src="/img/icons/icons8/ios/mail-primary.png" width="19px" height="19px">
                      CARTA DE CORREÇÃO
                    </span>
                  </base-dropdown>
                </div>
            </ShadedCol>
          </template>
        </ShadedTable>
      </div>
    </div>
    <ModalCancelNfe :selected="selected" :show="showModalCancelNfe" @close="showModalCancelNfe = false" />
    <ModalReSend :show="showModalReSend" :selected="selected" @close="showModalReSend = false" />
    <ModalEditEntity ref="modalEditEntity" />
    <ModalCorrectionLetter @updated="setHasCorrectionLetter" ref="modalCorrectionLetter"/>
  </div>
</template>

<script>
import cursorPaginate from "@/mixins/cursorPaginate";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import { NfeIssuedesStatusEnum } from "../Shared/Enums/NfeIssuedesStatusEnum";
import ModalEditEntity from "@/views/Modules/Configuration/Entity/Shared/_Edit";
import { base_url_ms } from '@/plugins'
import ModalCancelNfe from "../Shared/_ModalCancelNfe";
import ModalReSend from "../Shared/_ModalReSend";
import { hasPermission } from "@/plugins/permissions";
import ModalCorrectionLetter from './_ModalCorrectionLetter.vue'

export default {
  name: "ListNfeIssuedes",
  mixins: [cursorPaginate],
  components: {
    PuzlEmptyData,
    ShadedTable,
    ShadedCol,
    ModalEditEntity,
    ModalCancelNfe,
    ModalReSend,
    ModalCorrectionLetter
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    loadingSkeleton: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      NfeIssuedesStatusEnum: NfeIssuedesStatusEnum,
      headers: [
        "Status",
        "Data",
        "NF-E",
        "O.S",
        "Contrato",
        "Cliente",
        "Emissão",
        "Ação"
      ],
      base_url_ms: base_url_ms(),
      selected: false,
      showModalCancelNfe: false,
      has_permission: hasPermission,
      showModalReSend: false,
    }
  },
  methods: {
    setHasCorrectionLetter(params) {
      let index = this.items.findIndex((item) => item.key === params.key)
      this.items[index].has_correction_letter = true
    },
    handleShowModalCorrectionLetter(item) {
      this.$refs.modalCorrectionLetter.openModal(item)
    },
    async downloadDanfe(schedule_travel_uuid, key) {
      let params = {
        uuid: schedule_travel_uuid,
        type: "danfe",
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("travels/download", params)
        .then(async (response) => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.pdf`);
          link.click()
          this.$snotify.success('Download iniciado com sucesso!', {
            timeout: 1000,
            icon: false,
            position: "centerBottom",
            showProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false
          });
          loader.hide();
        });
    },
    async download(s3_path, key, format) {
      // Inicia o download
      const url = this.base_url_ms + 'download-s3?url=' + s3_path
      this.$store.dispatch('exports/download', url)
        .then(async response => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.${format}`); // Added Line
          link.click();
        });
    },
    // TODO: Código ruim e temporário. Será removido nos próximos dias
    async downloadPdfWithForcedUpdate(s3_path, key, format) {
      let loader = this.$loading.show();
      try {
        this.$notify({ type: 'info', message: 'Estamos trabalhando em sua solicitação.' });
        await this.$store.dispatch('xmlIssuedes/getDanfeFixed', { key: key });
        const url = this.base_url_ms + 'download-s3?url=' + s3_path;
        this.$store.dispatch('exports/download', url).then(async response => {
          let blob = new Blob([response], { type: "application/pdf" });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `${key}.${format}`);
          link.click();
        });
      } finally {
        loader.hide();
      }
    },
    /**
     * @param {object} item | items
     */
    cancelNfe(item) {
      if (this.has_permission('additional_permissions.cancellation-of-nfe')) {
        const emissionDateTime = new Date(item.xml_travel_updated_at);
        const now = new Date();
        const timeDifferenceInHours = (now - emissionDateTime) / (1000 * 60 * 60);
        if (timeDifferenceInHours <= 24) {
          this.selected = item;
          this.showModalCancelNfe = true;
        } else {
          this.$notify({
            type: 'danger',
            message: 'Não é permitido cancelar a NFe após 24 horas da data de emissão.'
          });
        }
      } else {
        this.$notify({
          type: 'danger',
          message: 'Usuário sem permissão para cancelamento de NFe.'
        });
      }
    },
    /**
     * @param {object} item | items
     */
    reSendXml(item) {
      if (item.status !== this.NfeIssuedesStatusEnum.REJECTED && item.status !== this.NfeIssuedesStatusEnum.DUPLICATED && item.status !== this.NfeIssuedesStatusEnum.DENIED) {
        return
      }
      this.selected = item
      this.showModalReSend = true
    },
    /**
     * Abre modal de editar clientes/fornecedores
     *
     * @param {string} uuid
     */
    openModalEditEntity(uuid) {
      this.$refs.modalEditEntity.openModal(true, uuid);
    },
    copy(key) {
      this.$helper.copy(key)
      this.$notify({ type: 'success', message: 'Chave de acesso copiada com sucesso!' })
    },
  }
}
</script>

<style scoped>
.badge-table {
  padding: 6px 19px;
  border-radius: 16px;
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.badge-table b {
  font-weight: 400;
  font-size: 12px;
}
.badge-table-danger {
  background-color: #F9E3E1;
  color: #DB4539;
}
.badge-table-success {
  background: #F2F7F3;
  color: #149E57;
}
.badge-table-primary {
  background-color: #3983C1;
  color: #fff;
}
.badge-table-warning {
  background-color: #FEF9F2;
  color: #F2B532;
}
</style>
