import { PagerType, initPagerType } from "../../../../../../shared/types";

/**
 * Type para item da listagem de PurchaseBudget
 *
 * @typedef {Object} PurchaseBudgetListType
 * @property {number} id
 * @property {string} uuid
 * @property {number} status
 * @property {number} concrete_removal
 * @property {string} code
 * @property {string} plate
 * @property {?number} price
 * @property {?string} vehicle_tag
 * @property {?string} brand
 * @property {?string} model
 * @property {?string} entity_document
 */
export const PurchaseBudgetListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<PurchaseBudgetListType> } )} PurchaseBudgetListPagerType
 */
export const PurchaseBudgetListPagerType = {};

/**
 * Inicializar PurchaseBudgetListPagerType
 *
 * @returns {PurchaseBudgetListPagerType}
 */
export const initPurchaseBudgetListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  };
};
