<template>
  <div class="container-fluid" v-if="$_detached_listed.items.length">
    <div class="">
      <div class="table-responsive mt-2">
        <table class="table-layout-3-1">
          <thead>
            <tr>
              <th>
                <div class="column-head-layout-3-1">
                  Status
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Data
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Descrição
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Aprovação
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Contrato
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Cliente
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1">
                  Obra
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  Valor
                </div>
              </th>
              <th>
                <div class="column-head-layout-3-1 text-center">
                  Ações
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in $_detached_listed.items" :key="index">
              <!-- Coluna Status -->
              <td>
                <div class="column-body-layout-3-1">
                  <div class="status-badge d-flex align-items-center justify-content-center"
                    :class="item.invoice_billed_id ? 'billed' : 'pending'">
                    <img v-if="!item.invoice_billed_id" src="/img/icons/icons8/ios/check_gray.png" class="mr-1" width="16" />
                    <img v-if="item.invoice_billed_id" src="/img/icons/check.png" class="mr-1" width="16" height="16" />
                    {{ item.invoice_billed_id ? 'Faturado' : 'Em aberto' }}
                  </div>
                </div>
              </td>
              <!-- Coluna Data -->
              <td>
                <div class="column-body-layout-3-1">
                  {{ $helper.parseDate(item.date_at, 'DD/MM/YYYY') }}
                </div>
              </td>
              <!-- Coluna Descrição -->
              <td>
                <div class="column-body-layout-3-1">
                  {{ item.description }}
                </div>
              </td>
              <!-- Coluna Aprovação -->
              <td>
                <div class="column-body-layout-3-1 pointer">
                  <div @click.prevent="handleApprove(item)" class="approval-status d-flex align-items-center justify-content-center"
                    :class="item.status ? 'approved' : 'pending'">
                    <img v-if="!item.status" class="mr-1" src="/img/icons/hourglass-yellow.png"
                      alt="hourglass-yellow"
                      width="13px">
                    <img v-if="item.status" class="mr-1" src="/img/icons/icons8/ios/hourglass_success.png"
                      alt="hourglass-yellow" width="13px">
                    {{ item.status ? 'APROVADO' : 'PENDENTE' }}
                  </div>
                </div>
              </td>
              <!-- Coluna Contrato -->
              <td>
                <div class="column-body-layout-3-1">
                  {{ item.code }}
                </div>
              </td>
              <!-- Coluna Cliente -->
              <td>
                <div class="column-body-layout-3-1">
                  {{ item.customer_name }}
                </div>
              </td>
              <!-- Coluna Obra -->
              <td>
                <div class="column-body-layout-3-1">
                  {{ item.construction_name }}
                </div>
              </td>
              <!-- Coluna Valor -->
              <td>
                <div class="column-body-layout-3-1 text-center">
                  <span>R$</span>{{ $helper.toMoneyWithoutCurrencyLayout((item.value * item.quantity)) }}
                </div>
              </td>
              <!-- Coluna Ações -->
              <td>
                <div class="column-body-layout-3-1 text-center">
                  <base-dropdown menuOnRight v-if="!item.invoice_billed_id" class="settings-dropdown">
                    <div slot="title-container" class="dropdown-toggle rounded">
                      <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png">
                    </div>
                    <div>
                      <a class="dropdown-item d-flex" @click.prevent="handleDelete(item.id)">
                        <img width="15" src="https://img.icons8.com/ios/100/db4539/delete--v1.png" alt="delete--v1" />Deletar
                      </a>
                    </div>
                  </base-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
// #region Imports
import { computed, defineEmits, ref, onMounted } from "vue";
import DetachedStatusEnum from "../../enums/DetachedStatusEnum";
import { dialogs } from "@/helpers";
import DetachedStore from "../../store/DetachedStore";
import store from "@/store/index";
// #endregion

// #region Emits
const emit = defineEmits(["fetch"]);
// #endregion

//#region Data
const financialLimit = ref(null);
//#endregion

// #region Computeds
/** Store Getters */
const $_detached_listed = computed(() => DetachedStore.getters.getListed());
const $_user = computed(() => store.getters["auth/getUser"]);
// #endregion

// #region Methods
async function fetchFinancialLimits() {
  if (!financialLimit.value) {
    const response = await store.dispatch('permissionFinancialLimit/show',  $_user.value.id);
    financialLimit.value = response.data
  }
}

async function handleDelete(id) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente remover o avulso?');
  if (isConfirmed) {
    await DetachedStore.actions.remove(id);
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Avulso removido com sucesso!');
  }
}

async function handleApprove(item) {
  await fetchFinancialLimits()
  if (!financialLimit.value.invoice_can_add_single) {
    dialogs.notify(dialogs.TYPE_ENUM.DANGER, 'Usuário não possui permissão para aprovar avulso');
    return
  }

  const isConfirmed = await dialogs.confirmAction('Deseja realmente aprovar o avulso?');
  if (isConfirmed) {
    await DetachedStore.actions.approve(item.id, !item.status);
    emit("fetch");
    dialogs.notify();
  }
}
/**
 * Atualiza a lista de ordens de compra.
 */
function fetchEntityBudgets() {
  emit("fetch");
}

// #endregion

// #region Lifecycle
onMounted(() => {
  fetchFinancialLimits();
});
// #endregion
</script>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
.main-content .container-fluid {
  padding: 0 !important;
}
.table-responsive {
  margin-top: 0 !important;
}
.first-content {
  margin-top: 18px;
}
.card {
  box-shadow: none !important;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
}

/* Status Badge */
.status-badge {
  border-radius: 15px;
  font-size: 0.8rem;
  width: 121px;
  height: 28px;
}

.status-badge.billed {
  color: #1A70B7;
  background: hsl(207, 75%, 41%, 20%);
}

.status-badge.pending {
  color: rgb(128 126 121);
  background: rgb(157 151 138 / 20%);
}

/* Info Icons */
.info-icons {
  margin: 0 11px;
}

.icon-img {
  width: 25px;
}

.icon-spacing {
  margin: 0 11px;
}

/* Description */
.description {
  font-weight: 500 !important;
  font-size: 12.36px;
  line-height: 16px;
  color: hsl(223, 8%, 18%, 75%);
  margin: 10px 0;
}

/* Value */
.value {
  font-weight: 500 !important;
  font-size: 24.7px;
  line-height: 24px;
  color: #32325d;
}

.value span {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 16px;
  color: #2b2d32 !important;
  margin-right: 4px;
}

/* Settings Dropdown */
.settings-dropdown {
  margin-left: 3.7rem;
}

/* Approval Status */
.approval-status {
  width: 120px;
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.05rem 0.2rem;
  border: 0.5px solid;
}

.approval-status.approved {
  color: rgb(72 176 122);
  background: rgb(171 210 167 / 20%);
}

.approval-status.pending {
  color: #F2B532;
  border-color: #F2B532;
}

.table-layout-3-1 {
  width: 100%;
  border-collapse: collapse;

  h4 {
    font-size: 12px;
  }

  .custom-popover-body {
    padding: 10px;

    h5, h6 {
      padding-left: 10px;
      font-weight: 400 !important;
    }

    hr {
      background-color: $muted-light;
      opacity: 0.5;
      color: $muted-light;
    }
  }

  thead {
    tr {
      th {
        text-align: left;
        border: none;
        padding: 0;
        font-weight: 600 !important;

        div.column-head-layout-3-1 {
          background: $muted-light;
          border: 1px solid #DFDFDF;
          border-right: none;
          border-left: none;
          color: $dark;
          font-size: 12px;
          padding: 10px;
        }

        &:first-child div.column-head-layout-3-1 {
          border-top-left-radius: 8px;
          border-left: 1px solid #DFDFDF;
        }

        &:last-child div.column-head-layout-3-1 {
          border-top-right-radius: 8px;
          border-right: 1px solid #DFDFDF;
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 2px solid $muted-light;

      td {
        font-weight: 500 !important;
        text-align: left;
        border: none;
        padding: 0;

        div.column-body-layout-3-1 {
          background: white;
          padding: 12px;
          height: 50px;
          box-shadow: 10px 5px 10px 0 #00000026;
        }
      }

      &:last-child {

        :first-child div.column-body-layout-3-1 {
          border-bottom-left-radius: 8px;
        }

        :last-child div.column-body-layout-3-1 {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
</style>
