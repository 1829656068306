<template>
  <div>
    <AppFixedPageTitle
      title="Tipo"
      icon="/img/icons/icons8/ios/physics_white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :searchBarFilter.sync="searchBarFilter"
          :showCompanyPlants="false"
          @onSearchClick="init({})"
          @onClearClick="clearFilter"
        >
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions :isBackButtonEnabled="true">
          <AppPageHeaderActionsButton
            @click="handleCreate"
            text="novo"
            type="success"
            icon="/img/icons/plus-math--v1-white.png"
          />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>

    <AppTabSelect
      :items="tabSelectItem"
      :isShowViewTypes="false"
      @onTabSelectItemClick="onTabSelectItemClick"
    >
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="onSelectOrderBy()"
        variant="text-only"
      />
    </AppTabSelect>
    <div class="container-fluid mt-2">
      <list-type :loadingSkeleton="loadingSkeleton"></list-type>
    </div>

    <create-type ref="createType"></create-type>
  </div>
</template>
<script>
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppPageHeaderActionsButtonDropItem,
  AppSearchBar,
  AppSearchBarFilterSection,
  initSearchBarFilterType,
  AppTabSelect,
  AppSelect,
} from "../../../../../components/AppGlobal";
import { mapGetters } from "vuex";
import ListType from "./Shared/_List";
import CreateType from "./Shared/_Create";
export default {
  components: {
    ListType,
    CreateType,
    AppFixedPageTitle,
    AppPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppPageHeaderActionsButtonDropItem,
    AppSearchBar,
    AppSearchBarFilterSection,
    AppTabSelect,
    AppSelect,
  },
  data() {
    return {
      filter: {},
      searchBarFilter: initSearchBarFilterType(),
      loadingSkeleton: false,
      orderBy: {
        items: [
          {
            id: 0,
            name: "PADRÃO",
            selected_name: "ORDENAR",
            icon: "",
            filter: {
              column: null,
              is_desc: null,
            },
          },
          {
            id: 1,
            name: "A - Z (DESCRIÇÃO TÉCNICA)",
            selected_name: "A - Z",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: "technical_description",
              is_desc: 0,
            },
          },
          {
            id: 2,
            name: "Z - A (DESCRIÇÃO TÉCNICA)",
            selected_name: "Z - A",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: {
              column: "technical_description",
              is_desc: 1,
            },
          },
          {
            id: 3,
            name: "CADASTRO MAIS NOVO PARA O MAIS VELHO",
            selected_name: "CADASTRO NOVO",
            icon: "/img/icons/icons8/ios/double-down.png",
            filter: {
              column: "id",
              is_desc: 1,
            },
          },
          {
            id: 4,
            name: "CADASTRO MAIS VELHO PARA O MAIS NOVO",
            selected_name: "CADASTRO VELHO",
            icon: "/img/icons/icons8/ios/double-up.png",
            filter: {
              column: "id",
              is_desc: 0,
            },
          },
        ],
        selected: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      types: "mixType/fetch",
    }),
    tabSelectItem() {
      return [
        {
          id: null,
          name: "Todos",
          selected: this.filter.status == null,
        },
        {
          id: 1,
          name: "Ativo",
          selected: this.filter.status === 1,
        },
        {
          id: 0,
          name: "Inativo",
          selected: this.filter.status === 0,
        },
      ];
    },
  },
  methods: {
    handleCreate() {
      this.$refs.createType.handleCreateModal();
    },

    onSelectOrderBy() {
      this.filter.order_by = this.orderBy.items[this.orderBy.selected].filter;
      this.init({});
    },

    init() {
      this.filter.global = this.searchBarFilter.custom_search_values;
      this.loadingSkeleton = true;
      this.$store
        .dispatch("mixType/fetchItems", { filter: this.filter })
        .then((response) => {
          this.loadingSkeleton = false;
        })
        .catch((error) => {
          this.loadingSkeleton = false;
        });
    },

    /**
     * Limpar os filtros e listar os itens caso especificado
     * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
     * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
     */
    clearFilter(isRefreshList = true, withStoreFilters = false) {
      let searchBarFilterValue = this.defaultSearchBarFilter();
      Object.assign(this.searchBarFilter, searchBarFilterValue);
      this.init(this.filter);
    },

    /**
     * Padrão de filtro da barra de pesquisa
     * @returns {SearchBarFilterType}
     */
    defaultSearchBarFilter() {
      return {
        ...initSearchBarFilterType(),
      };
    },

    onTabSelectItemClick(item) {
      this.filter.status = item.id;
      this.init(this.filter);
    },
  },
  created() {},
  mounted() {
    this.clearFilter();
  },
};
</script>
<style></style>
