import { BaseListFilterType, initBaseListFilterType } from "@/shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ScheduleTravelFilterExtType
 * @property {Array} global - Filtro global para buscar viagens
 * @property {Number} status - 0: Liberada, 1: Concluída, 2: Carregando, 4: Aguardando liberação, 5: Em andamento, 6: Cancelada, 7: Não realizada, 8: Possui pendência, 9: Solicitação do vendedor
 * @property {boolean?} show_all - Habilita a regra para exibição de todos os cards
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ScheduleTravelFilterExtType)} ScheduleTravelFilterType
 */
export const ScheduleTravelListFilterType = {};

/**
 * Inicializar ScheduleTravelFilterType
 *
 * @returns {ScheduleTravelFilterType}
 */
export const initScheduleTravelListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    range: {
      items: null,
      selected: null,
      start: null,
      end: null,
    },
    global: [],
    status: 0,
    show_all: null,
  };
}
