<template>
  <modal 
    :show.sync="isOpen"
    class="competitor-edit"
    size="lg"
    v-if="!$_competitor_is_finding"
  >
    <template #header>
      <AppIcon icon="create" color="warning" width="21" />
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CompetitorForm
      :formData="formData"
      @onCancelClick="closeModal"
      @onSubmitClick="handleSubmitForm"
    />
  </modal>
</template>

<script setup>
//#region Imports
import { computed, reactive, ref } from "vue";
import { initCompetitorType, CompetitorType } from "../../types";
import competitorStore from "../../store/competitorStore";
import { dialogs, progress } from "../../../../../../helpers";
import CompetitorForm from "../shared/CompetitorForm.vue";
import AppIcon from "../../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Editar Concorrente";
/** @type {CompetitorType} */
const formData = reactive(initCompetitorType());
//#endregion

//#region Computeds
/** Store Getters */
const $_competitor_is_finding = computed(() => competitorStore.getters.getIsFinding());
//#endregion

//#region Methods
/**
 * Abrir modal
 * @param {number} id
 */
function openModal(id) {
  setFormData(id);
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Localizar registro por id e setar dados no formulário */
function setFormData(id) {
  progress.start();
  competitorStore.actions.find(id)
    .then((data) => Object.assign(formData, data))
    .finally(() => progress.finish());
}

/** Atualizar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  competitorStore.actions.update(formData, formData.id)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} CompetitorEditModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.modal.competitor-edit {
  // MODAL-HEADER
  .modal-header {
    padding: 24px !important;
    background-color: $warning-lighter !important;
    align-items: center !important;

    &.pb-1 {
        padding-bottom: 24px !important;
    }

    .app-icon {
        margin-right: 12px;
    }

    h5.modal-title {
        color: $dark !important;
        margin-bottom: 0 !important;
        @include rhythm(font-size, 20);
    }

    button.close {
        padding: 0;
        display: flex;
        color: $dark !important;
        margin: 0;
        margin-left: auto;
        min-height: auto !important;
        // aumentar area de click
        margin-right: -32px;
        padding: 32px;
    }
  }
}
</style>
