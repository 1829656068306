<template>
  <div>
    <div class="row card-wraper">
      <div
        class="col-lg-4"
        v-for="item in $_credit_releases"
        v-if="$_credit_releases.length"
        :key="item.id">
        <div
          class="card"
          style="
            box-shadow: none !important;
            border-radius: 8px !important;
            border: 0.5px solid #e8e8e8;
          "
        >
          <div class="card-body">
            <div class="d-flex justify-content-between" style="height: 28px;">
              <section class="top-section d-flex align-items-center mb-1 mt-n1">
                <div class="container">
                  <div class="row align-items-center">
                    <div class="col-12 px-0">
                      <div class="d-flex align-items-center" style="gap: 10px;">
                        <div
                          v-if="item.has_overdue_bill_receive_installment"
                          @click.prevent="openModalOverdueInstallments(item)"
                          class="app-badge danger"
                          style="min-width: 145px">
                          <AppIcon
                            icon="attention"
                            width="18"
                            color="danger"
                            class="mr-1"
                          />
                          <h5>
                            Inadimplência
                          </h5>
                        </div>
                        <CustomPopover
                          title="Informações"
                          width="250"
                          top="18px"
                          left="-10px">
                          <AppIcon
                            icon="info"
                            color="warning"
                            title="infos"
                            class="pointer"
                            width="22"
                            slot="reference"
                          />
                          <div class="d-flex flex-column p-3" style="gap: 10px">
                            <div
                              class="d-flex align-items-center"
                              style="gap: 5px"
                            >
                              <AppIcon
                                class="align-self-center"
                                icon="chemical-plant"
                                color="success"
                                width="18"
                              />
                              <span
                                style="
                                font-weight: 400;
                                color: #2b2d32;
                                font-size: 14px;
                              "
                              >
                              Vendedor
                            </span>
                            </div>
                            <div class="info-credit-release-card py-2">
                              <div class="ml-3 d-flex">
                              <span
                                style="
                                  font-weight: 300;
                                  color: #2b2d32;
                                  font-size: 13px;
                                "
                              >
                                {{ item.seller_name }}
                              </span>
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-center"
                              style="gap: 5px"
                            >
                              <AppIcon
                                class="align-self-center"
                                icon="cash"
                                color="primary"
                                width="18"
                              />
                              <span
                                style="
                                font-weight: 400;
                                color: #2b2d32;
                                font-size: 14px;
                              "
                              >
                              Método de Pagamento
                            </span>
                            </div>
                            <div class="info-credit-release-card py-2">
                              <div class="ml-3 d-flex">
                              <span
                                style="
                                  font-weight: 300;
                                  color: #2b2d32;
                                  font-size: 13px;
                                "
                              >
                                {{ item.payment_methods }}
                              </span>
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-center"
                              style="gap: 5px"
                            >
                              <AppIcon
                                class="align-self-center"
                                icon="payment-terms"
                                color="success"
                                width="18"
                              />
                              <span
                                style="
                                font-weight: 400;
                                color: #2b2d32;
                                font-size: 14px;
                              "
                              >
                              Condições de Pagamento
                            </span>
                            </div>
                            <div class="info-credit-release-card py-2">
                              <div class="ml-3 d-flex">
                              <span
                                style="
                                  font-weight: 300;
                                  color: #2b2d32;
                                  font-size: 13px;
                                "
                              >
                                {{ item.payment_terms }}
                              </span>
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-center"
                              style="gap: 5px"
                            >
                              <AppIcon
                                class="align-self-center"
                                icon="calendar"
                                color="primary"
                                width="18"
                              />
                              <span
                                style="
                                font-weight: 400;
                                color: #2b2d32;
                                font-size: 14px;
                              "
                              >
                              Próxima Programação
                            </span>
                            </div>
                            <div class="info-credit-release-card py-2 mb-3">
                              <div class="ml-3 d-flex">
                              <span
                                style="
                                  font-weight: 300;
                                  color: #2b2d32;
                                  font-size: 13px;
                                "
                              >
                                {{ date.make(item.concreting_date).format(date.FORMAT.SHORT_DATE_AND_TIME) }}
                              </span>
                              </div>
                            </div>
                          </div>
                        </CustomPopover>
                        <AppIcon
                          @click.prevent="openEntityHistoryCreateModal(item)"
                          icon="wallet"
                          color="primary"
                          width="20"
                          height="20"
                        />
                        <AppIcon
                          icon="contact"
                          color="warning"
                          width="22"
                          height="22"
                          @click.prevent="handleListContacts(item.contract_proposal_uuid)"
                        />
                        <AppIcon
                          icon="writing"
                          color="dark"
                          width="22"
                          height="22"
                          @click.prevent="openModalEntityRegistry(
                            item.entity_id,
                            item.entity_uuid,
                            item.entity_name,
                            item.document
                          )"
                        />
                        <AppIcon
                          icon="attach"
                          :color="item.total_entity_attachments > 0 ? 'green' : 'dark'"
                          width="22"
                          height="22"
                          :title="`Total de anexos: ${item.total_entity_attachments}`"
                          @click.prevent="openModalEntityAttachmentList(
                            item.entity_id,
                            item.entity_name,
                            item.document
                          )"/>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <section class="mid-section mt-2">
              <div class="mb-1">
                <span class="content-card-bold" > {{ item.code }} </span>
              </div>
              <div>
                <el-popover :trigger="item.entity_name.length >= 60 ? 'hover' : ''" placement="top">
                  <span class="content-card-semibold fs-12" > {{ item.entity_name }} </span>
                  <span class="content-card-semibold only-60-ch" slot="reference">
                    {{ item.entity_name }}
                  </span>
                </el-popover>
              </div>
              <div>
                <span class="content-card-semibold" > {{ item.document }} </span>
              </div>
              <div class="mb-1">
                <el-popover :trigger="item.construction_name.length >= 60 ? 'hover' : ''" placement="top">
                  <span class="content-card-semibold fs-12" > {{ item.construction_name }} </span>
                  <span class="content-card-semibold only-60-ch" slot="reference">
                    {{ item.construction_name }}
                  </span>
                </el-popover>
              </div>
              <hr class="hr-card">
              <div class="mb-3">
                <span class="content-card-semibold" > {{ item.company_plant_names }} </span>
              </div>
            </section>
            <section class="end-section">
              <div class="container">
                <div class="row align-items-center" style="gap: 10px">
                  <div class="col px-0">
                    <a
                      class="document-placement-card custom-hover-170220251436 success"
                      @click="openModalEntityCreditLimit(item)"
                      href="#">
                      <div>
                        <AppIcon
                          icon="plus-math-squared"
                          color="success"
                          class="mr-2"
                          width="18"
                        />
                        <h5>
                          Liberar Crédito
                        </h5>
                      </div>
                    </a>
                  </div>
                  <div class="col px-0" v-if="$hasPermission('additional_permissions.financial_approvation')" v-show="item.necessary_approvation">
                    <base-dropdown class="w-100">
                      <a
                        class="document-placement-card custom-hover-170220251436"
                        :class="FinancialApprovalStatusEnum.getColor(item.approvation)"
                        slot="title-container"
                        href="#">
                        <div>
                          <AppIcon
                            :icon="FinancialApprovalStatusEnum.getIcon(item.approvation)"
                            :color="FinancialApprovalStatusEnum.getColor(item.approvation)"
                            class="mr-2"
                            width="18"
                          />
                          <h5>
                            {{ FinancialApprovalStatusEnum.getTitle(item.approvation) }}
                          </h5>
                          <AppIcon
                            icon="down"
                            color="dark"
                            width="15"
                            class="ml-2"
                          />
                        </div>
                      </a>
                      <a class="dropdown-item"
                        :class="{'disabled': !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status)}"
                        @click.prevent="
                          !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status) ?
                          '' :
                          approve(item.contract_proposal_id, FinancialApprovalStatusEnum.keys.PENDING)">
                        <AppIcon
                          :icon="FinancialApprovalStatusEnum.getIcon(FinancialApprovalStatusEnum.keys.PENDING)"
                          :color="FinancialApprovalStatusEnum.getColor(FinancialApprovalStatusEnum.keys.PENDING)"
                          width="20"
                        />
                        {{ FinancialApprovalStatusEnum.getTitle(FinancialApprovalStatusEnum.keys.PENDING) }}
                      </a>
                      <hr class="my-0"/>

                      <a class="dropdown-item"
                         :class="{'disabled': !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status)}"
                         @click.prevent="
                          !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status) ?
                          '' :
                          approve(item.contract_proposal_id, FinancialApprovalStatusEnum.keys.APPROVAL)">
                        <AppIcon
                          :icon="FinancialApprovalStatusEnum.getIcon(FinancialApprovalStatusEnum.keys.APPROVAL)"
                          :color="FinancialApprovalStatusEnum.getColor(FinancialApprovalStatusEnum.keys.APPROVAL)"
                          width="20"
                        />
                        {{ FinancialApprovalStatusEnum.getTitle(FinancialApprovalStatusEnum.keys.APPROVAL) }}
                      </a>
                      <hr class="my-0"/>

                      <a class="dropdown-item"
                         :class="{'disabled': !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status)}"
                         @click.prevent="
                          !$hasPermission('additional_permissions.financial_approvation') || [0,5,4].includes(item.contract_proposal_status) ?
                          '' :
                          approve(item.contract_proposal_id, FinancialApprovalStatusEnum.keys.DISAPPROVAL)">
                        <AppIcon
                          :icon="FinancialApprovalStatusEnum.getIcon(FinancialApprovalStatusEnum.keys.DISAPPROVAL)"
                          :color="FinancialApprovalStatusEnum.getColor(FinancialApprovalStatusEnum.keys.DISAPPROVAL)"
                          width="20"
                        />
                        {{ FinancialApprovalStatusEnum.getTitle(FinancialApprovalStatusEnum.keys.DISAPPROVAL) }}
                      </a>
                    </base-dropdown>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
      </div>
      <SkeletonPuzlGrid
        v-show="props.loading"
        v-for="(index) in 3"
        :key="index">
      </SkeletonPuzlGrid>
    </div>
    <ModalEntityHistory ref="entityHistoryCreateModal" />
    <ModalEntityRegistry ref="entityRegistryCreateModal" />
    <ModalListContacts ref="modalListContacts" />
    <ModalEntityCreditLimit ref="modalEntityCreditLimit" @onSubmitClick="onUpdateStatusClick" />
    <ModalEntityAttachmentList ref="modalEntityAttachmentList" @storedAttachment="onUpdateStatusClick" @deletedLastAttachment="onUpdateStatusClick" />
    <ModalOverdueInstallments ref="modalOverdueInstallments" />
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, defineEmits, computed, onMounted, onUnmounted, Ref, getCurrentInstance } from 'vue';
import { AppIcon, AppButton } from "../../../../../components/AppGlobal";
import { date, progress, dialogs } from "../../../../../helpers";
import ModalEntityHistory from "../../../Configuration/Entity/Shared/_ModalHistory.vue";
import { EntityHistoryCreateModalExpose } from "../../../Configuration/Entity/Shared/_ModalHistory.vue";
import ModalListContacts from "../../../Commercial/ContractProposal/Shared/Contacts/_ModalListContacts.vue";
import ModalEntityRegistry from "../../../Configuration/Entity/Shared/_ModalEntityRegistry.vue";
import ModalEntityCreditLimit from "../../../Configuration/Entity/Shared/_ModalCreditLimit.vue";
import FinancialApprovalStatusEnum from  "../Enums/FinancialApprovalStatusEnum";
import ModalEntityAttachmentList from "@/views/Modules/Configuration/Entity/Shared/_ModalEntityAttachmentList.vue";
import ModalOverdueInstallments from "./_ModalOverdueInstallments";
import CustomPopover from "@/views/Modules/Operational/Schedule/Weighing/Shared/CustomPopover.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid.vue";
//#endregion

//#region Emits
/** Eventos emitidos */
const emit = defineEmits([
  "onUpdateStatusClick",
]);
//#endregion



//#region Data
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
    default: true,
  },
})

const store = getCurrentInstance().proxy.$store;
const $_credit_releases = computed(() => store.getters["creditRelease/fetch"]);
//#endregion

//#region ComponentRefs
/** @type {Ref<EntityHistoryCreateModalExpose>} */
const entityHistoryCreateModal = ref(null);
/** @type {Ref<ModalListContacts>} */
const modalListContacts = ref(null);
/** @type {Ref<ModalEntityRegistry>} */
const entityRegistryCreateModal = ref(null);
/** @type {Ref<ModalEntityCreditLimit>} */
const modalEntityCreditLimit = ref(null);
/** @type {Ref<ModalEntityAttachmentList>} */
const modalEntityAttachmentList = ref(null);
/** @type {Ref<ModalOverdueInstallments>} */
const modalOverdueInstallments = ref(null);
//#endregion

//#region Methods

/**
 * Atualiza status
 *
 * @param {number} contract_proposal_id
 * @param {number} status
 */
async function approve(contract_proposal_id, status) {
  progress.start();

  await store.dispatch("contractProposal/putApprove", { id: contract_proposal_id, status: status })
    .then(() => {
      dialogs.notify();
      onUpdateStatusClick();
    })
    .finally(() => {
      progress.finish();
    })
}

/**
 * Atualiza lista
 */
function onUpdateStatusClick() {
  emit("onUpdateStatusClick");
}

/**
 * Abrir modal carteira de Clientes/Fornecedores
 *
 * @param {Array} creditRelease
 */
function openEntityHistoryCreateModal(creditRelease) {
  entityHistoryCreateModal.value.openModal(
    creditRelease.entity_id,
    { id: creditRelease.entity_id, uuid: creditRelease.entity_uuid, document: creditRelease.document },
    creditRelease.contract_proposal_id
  );
}

function handleListContacts(contract_proposal_uuid) {
  store.dispatch("contractProposal/show", contract_proposal_uuid).then((response) => {
    modalListContacts.value.openModal(response.data);
  });
}

/**
 * Abrir modal de Registros do Cliente
 *
 * @param {number} entity_id
 * @param {string} entity_uuid
 * @param {string} name
 * @param {string} document
 */
function openModalEntityRegistry(entity_id, entity_uuid, name, document) {
  entityRegistryCreateModal.value.handleCreateModal(entity_id, entity_uuid, name, document);
}

/**
 *
 * @param {Object} creditRelease
 */
async function openModalEntityCreditLimit(creditRelease) {
  modalEntityCreditLimit.value.openModal(creditRelease.entity_id, creditRelease.document.length === 18, true);
};

/**
 * Abrir modal para anexos do cliente
 *
 * @param {number} entity_id
 * @param {string} name
 * @param {string} document
 */
async function openModalEntityAttachmentList(entity_id, name, document) {
  modalEntityAttachmentList.value.handleCreateModal(entity_id, name, document);
}

/**
 * Abrir modal de recebimentos atrasados
 *
 * @param {Array} creditRelease
 */
async function openModalOverdueInstallments(creditRelease) {
  modalOverdueInstallments.value.openModal({
    id: creditRelease.entity_id,
    entity_name: creditRelease.entity_name,
    document: creditRelease.document,
  });
}

//#endregion

</script>
<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.custom-hover-170220251436 {
  h5 {
    color: $dark !important;
  }
  &:hover {
    &.success {
      background: $success;
    }

    &.primary {
      background: $primary;
    }

    &.warning {
      background: $warning;
    }

    &.danger {
      background: $danger;
    }

    h5 {
      color: #fff !important;
    }

    div.app-icon {
      background-color: #fff !important;
    }
  }
}
</style>
<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.only-60-ch {
  max-width: 60ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.dropdown-item {
  display: flex;

  &.disabled {
    opacity: 0.8;

    .app-icon {
      opacity: 0.4;
    }
    background-color: $muted-lighter;
  }

  .app-icon {
    margin-right: 10px;
  }
}

.hr-info-credit-release {
  width: 109%;
  margin: 7px 0 7px -11px;
}
.info-credit-release-card {
  border: solid #e8e8e8 0.5px;
  border-radius: 8px;
}
.content-card-bold {
  color: #2B2D32;
  font-size: 16px;
  font-weight: 500;
}
.content-card-semibold {
  color: #2B2D32;
  font-size: 16px;
  font-weight: 400;
}
.hr-card {
  margin: 15px 0;
}

.document-placement-card {
  text-align: center;
  height: 32px;
  box-shadow: 0 5px 10px 0 #0000000D;
  display: flex;
  border: 1px solid $muted-light;
  border-radius: 4px;
  align-items: center;
  justify-content: center;

  &.transparent-card {
    box-shadow: none;
    border: none;
    height: 22px;
  }

  div {
    display: flex;
    align-items: center;

    h5 {
      font-size: 12px;
      margin: 0 0 0 5px;
      font-weight: 400 !important;
      color: $dark;
    }
  }
}

.app-badge {
  padding: 4px;
  border-radius: 50px;
  min-height: 24px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .2s ease-in-out;
  cursor: pointer;

  h5 {
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  &.danger {
    background-color: $danger-lighter;
    border: 1px solid $danger-lighter;
    h5 {
      color: $danger;
    }
  }
}
</style>
