export const PurchaseRequestItemStatusEnum = Object.freeze({
  DENIED: 0, // Negado
  APPROVED: 1, // Aprovado
});

const getTitle = (value) => {
  switch (value) {
    case PurchaseRequestStatusEnum.DENIED:
      return "Negados";
    case PurchaseRequestStatusEnum.APPROVED:
      return "Aprovados";
    default:
      return "";
  }
};

export default {
  keys: PurchaseRequestItemStatusEnum,
  getTitle,
};