const keys = Object.freeze({
  PENDING: 0,
  APPROVAL: 1,
  DISAPPROVAL: 2
});

const getTitle = (value) => {
  switch (value) {
    case keys.PENDING:
      return "Pendência Financeira";
    case keys.APPROVAL:
      return "Aprovação Financeira";
    case keys.DISAPPROVAL:
      return "Reprovação Financeira";
    default:
      return "";
  }
};

const getIcon = (value) => {
  switch (value) {
    case keys.PENDING:
      return "hourglass";
    case keys.APPROVAL:
      return "thumbs-up";
    case keys.DISAPPROVAL:
      return "thumbs-down";
    default:
      return "";
  }
}

const getColor = (value) => {
  switch (value) {
    case keys.PENDING:
      return "warning";
    case keys.APPROVAL:
      return "success";
    case keys.DISAPPROVAL:
      return "danger";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
  getIcon,
  getColor
};
