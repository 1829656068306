import BankReconciliationListPage from "../views/list/BankReconciliationListPage.vue";

export default [
  {
    path: '/cash_flow/bank-reconciliation',
    name: 'cash_flow.bank_reconciliation.view',
    component: BankReconciliationListPage,
    meta: {
      title: 'Financial',
      description: 'Conciliação Bancária',
      icon: 'ni ni-money-coins',
      permission: 'cash_flow.bank-reconciliation.index.view',
      breadcrumbs: [
        {title: 'Financeiro', name: 'cash_flow.bank-reconciliation.dashboard.index'},
        {title: 'Conciliação Bancária', name: null},
      ]
    },
  },
];
