import { createAxios } from "@/plugins/axios";

const endPoint = '/commercial/contract-proposal+microservice'
export default {
  namespaced: true,
  actions: {
    getBusinessPartnerContractProposalById(_, id) {
      return createAxios().get(`/commercial/contract-proposal/business-partner-by-contract-id/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    storeBusinessPartner(_, params) {
      return createAxios().post(`/commercial/contract-proposal/store-business-partner/${params.id}/${params.business_partner_id}/${params.business_partner_created_by_user_id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
    deleteBusinessPartnerContractProposalById(_, id) {
      return createAxios().put(`/commercial/contract-proposal/delete-business-partner/${id}`)
        .then(({data}) => {
          return Promise.resolve(data)
        }).catch(error => {
          return Promise.reject(error)
        })
    },
  }
}
