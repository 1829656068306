/**
 * Type para Incluir/Editar/Visualizar permissões de crédito do usuário
 *
 * @typedef {Object} UserCreditPermissionType
 * @property {number|null} id - ID da permissão financeira.
 * @property {number|null} physical_value - Valor físico.
 * @property {number|null} juridical_value - Valor jurídico.
 * @property {number} days - Quantidade de dias.
 * @property {number|null} user_id - ID do usuário.
 */
export const UserCreditPermissionType = {};

/**
 * Inicializar UserCreditlPermissionType
 * 
 * @returns {UserCreditPermissionType}
 */
export const initUserCreditPermissionType = () => {
    return {
        id: null,
        physical_value: null,
        juridical_value: null,
        days: 0,
        user_id: null,
    };
};