import PaymentMethodListPage from "../views/list/PaymentMethodListPage.vue";

export default [
  {
    path: '/financial/bill-receive/payment-methods',
    name: 'financial.bill_receive.payment_method.view',
    component: PaymentMethodListPage,
    meta: {
      title: 'Financeiro',
      description: 'Métodos de Pagamento',
      icon: 'fa fa-home',
      breadcrumbs: [
        {title: 'Dashboard', name: 'financial.bill_receive.payment_method.dashboard.view'},
        {title: 'Métodos de Pagamento', name: null},
      ]
    },
  },
];