/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} PaymentMethodType
 * @property {?number} id // [V]
 * @property {boolean} status
 * @property {boolean} default
 * @property {string} name
 * @property {boolean} puzl_customer
 * @property {boolean} allow_approve
 * @property {?string} external_code
 * @property {boolean} display_in_anticipation
 * @property {null|0|1} payment_type - null-NENHUM, 0-DÉBITO, 1-CRÉDITO
 * @property {boolean} transaction_code
 * @property {boolean} is_manual_invoice_receipt
 * @property {boolean} is_manual_receivable_confirmation
 * @property {boolean} is_manual_supplier_payment
 * @property {boolean} show_records_on_credit_anticipation_window
 * @property {boolean} allow_auto_billing
 * @property {?string} contract_details
 * @property {?boolean} payment_intermediaries_exists // [V]
 * @property {Array} payment_intermediary_ids
 * @property {?string} created_at // [V]
 * @property {?string} updated_at // [V]
 * @property {?number} created_by_user_id // [V]
 * @property {?number} updated_by_user_id // [V]
 * @property {?string} created_by_user_name // [V]
 * @property {?string} updated_by_user_name // [V]
 */
export const PaymentMethodType = {};

/**
 * Inicializar PaymentMethodType
 * 
 * @returns {PaymentMethodType}
 */
export const initPaymentMethodType = () => {
  return {
    id: null,
    status: true,
    default: false,
    name: "",
    puzl_customer: false,
    allow_approve: false,
    external_code: "",
    display_in_anticipation: false,
    payment_type: null,
    transaction_code: true,
    is_manual_invoice_receipt: true,
    is_manual_receivable_confirmation: true,
    is_manual_supplier_payment: true,
    show_records_on_credit_anticipation_window: false,
    allow_auto_billing: false,
    contract_details: "",
    payment_intermediaries_exists: null,
    payment_intermediary_ids: [],
    created_at: null,
    updated_at: null,
    created_by_user_id: null,
    updated_by_user_id: null,
    created_by_user_name: null,
    updated_by_user_name: null,
  }
};