import { render, staticRenderFns } from "./puzlGantt.vue?vue&type=template&id=ff38af76&scoped=true"
import script from "./puzlGantt.vue?vue&type=script&lang=js"
export * from "./puzlGantt.vue?vue&type=script&lang=js"
import style0 from "./puzlGantt.scss?vue&type=style&index=0&id=ff38af76&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff38af76",
  null
  
)

export default component.exports