<template>
  <div
    id="app-input-number"
    class="app-input-number"
    :class="{ invalid: props.isInvalid || isInputInvalid }"
  >
    <div class="prepend" v-if="props.prepend">
      <AppIcon
        v-if="isAppIcon"
        :icon="props.prepend"
        color="dark"
        width="12px"
      />
      <div v-else class="prepend-text">
        {{ props.prepend }}
      </div>
    </div>
    <input
      type="text"
      class="app-input"
      :style="{ textAlign: props.textAlign }"
      v-model="interactor"
      :disabled="props.isDisabled"
      v-on="listeners"
      v-bind="$attrs"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
    />
    <div class="append" v-if="props.append">
      <AppIcon
        v-if="isAppIcon"
        :icon="props.append"
        color="dark"
        width="12px"
      />
      <div v-else class="append-text">
        {{ props.append }}
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineProps, ref, watch, useListeners } from "vue";
import { formatNumber, strToNum } from "../../../helpers";
import { AppInputNumberVariants } from "./AppInputNumberVariants";
import { AppIconVariants, AppIcon } from "../AppIcon";
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {number|string|null|undefined} value - O valor do input numérico.
 * @property {string|null|undefined} textAlign - Alinhamento de texto do input. Padrão `right`.
 * @property {number} decimalPlaces - O número de casas decimais para o valor. Padrão 2.
 * @property {number|null|undefined} minValue - Valor mínimo. Quando `null|undefined`, não será aplicado limite inferior. Padrão: `null`.
 * @property {number|null|undefined} maxValue - Valor máximo. Quando `null|undefined`, não será aplicado limite superior. Padrão: `null`.
 * @property {boolean} isDisabled - Se o input está desabilitado. Padrão false.
 * @property {boolean} isAutoSelectOnFocus - Selecionar todo o conteúdo quando receber focus. Padrão true.
 * @property {boolean} hasRounding - Indica se o valor deve ser arredondado para as casas decimais setadas. Padrão true.
 * @property {boolean} hasInputFormatting - Indica se o valor deve ser formatado enquanto usuário digita. Padrão true.
 * @property {string|null|undefined} prepend - Prefixo para o input. Pode ser uma string ou o nome do icone de AppIconVariants.variants.svg. Padrão: `null`.
 * @property {string|null|undefined} append - Sufixo para o input. Pode ser uma string ou o nome do icone de AppIconVariants.variants.svg. Padrão: `null`.
 * @property {boolean} isInvalid - Se o input está desabilitado. Padrão false.
 */
/** @type {Props} */
const props = defineProps({
  value: {
    type: [Number, String],
    default: 0,
    required: false,
  },
  textAlign: {
    type: String,
    default: AppInputNumberVariants.defaultVariants.textAlign,
    required: false,
  },
  decimalPlaces: {
    type: Number,
    default: AppInputNumberVariants.defaultVariants.decimalPlaces,
    required: false,
  },
  minValue: {
    type: Number,
    default: AppInputNumberVariants.defaultVariants.minValue,
    required: false,
  },
  maxValue: {
    type: Number,
    default: AppInputNumberVariants.defaultVariants.maxValue,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: AppInputNumberVariants.defaultVariants.isDisabled,
    required: false,
  },
  isAutoSelectOnFocus: {
    type: Boolean,
    default: AppInputNumberVariants.defaultVariants.isAutoSelectOnFocus,
    required: false,
  },
  hasRounding: {
    type: Boolean,
    default: AppInputNumberVariants.defaultVariants.hasRounding,
    required: false,
  },
  hasInputFormatting: {
    type: Boolean,
    default: AppInputNumberVariants.defaultVariants.hasInputFormatting,
    required: false,
  },
  prepend: {
    type: String,
    default: AppInputNumberVariants.defaultVariants.prepend,
    required: false,
  },
  append: {
    type: String,
    default: AppInputNumberVariants.defaultVariants.append,
    required: false,
  },
  isInvalid: {
    type: Boolean,
    default: AppInputNumberVariants.defaultVariants.isInvalid,
    required: false,
  },
});
//#endregion

//#region Emits
const emit = defineEmits(["input"]);
//#endregion

//#region Data
const localValue = ref(props.value);
const displayInput = ref(
  formatNumber(props.value || 0, { decimalPlaces: props.decimalPlaces })
);
const isInputFocused = ref(false);
const keepNegativeSignIfExists = true;
const isInputInvalid = ref(false);
//#endregion

//#region Computeds
const interactor = computed({
  get: () => displayInput.value,
  set: (value) => {
    if (value.startsWith(",")) {
      value = "0" + value;
    }
    const numValue = strToNum(value, 0, keepNegativeSignIfExists);
    const finalValue = props.hasRounding
      ? strToNum(
          formatNumber(numValue, { decimalPlaces: props.decimalPlaces }),
          0,
          keepNegativeSignIfExists
        )
      : numValue;

    emit("input", finalValue);
    displayInput.value = value;
  },
});

// Todos os listeners, menos o 'input'
const listeners = computed(() => {
  const { input, ...rest } = useListeners();
  return rest;
});

// Verifica se o valor de append ou prepend é um ícone válido
const isAppIcon = computed(() => {
  const iconKey = props.append || props.prepend;
  if (!iconKey) {
    return false;
  }
  return iconKey in AppIconVariants.variants.svg;
});
//#endregion

//#region Watchers
/** Sincronização de props.value e localValue */
watch(
  () => props.value,
  (newValue) => (localValue.value = newValue)
);
watch(localValue, (newValue) => {
  if (!isInputFocused.value) {
    displayInput.value = formatNumber(newValue || 0, {
      decimalPlaces: props.decimalPlaces,
    });
  }
  emit("input", newValue);
});

/** Atualiza as casas decimais do valor (Para efeito visual imediato) */
watch(
  () => props.decimalPlaces,
  (newValue) =>
    (interactor.value = formatNumber(localValue.value, {
      decimalPlaces: newValue,
    }))
);
//#endregion

//#region Methods
/**
 * @param {Event} payload
 * @returns {void}
 */
function onInput(payload) {
  const target = /** @type {HTMLInputElement} */ (payload.target);
  if (!target) {
    return;
  }

  isInputInvalid.value = validateMinMaxValue(target) !== undefined;

  // Não formatar input
  if (!props.hasInputFormatting) {
    return;
  }

  // Obtém a posição atual do cursor e o valor original antes de formatar
  const initialCursorPosition = target.selectionStart || 0;
  const originalValue = target.value;

  // Aplicar máscara em valor de target
  const maskedValue = applyTargetMask(target);
  const hasChangedValue = target.value !== maskedValue;

  // Se não houve alteração de valor, apenas garante posição do cursor
  if (!hasChangedValue) {
    target.setSelectionRange(initialCursorPosition, initialCursorPosition);
    return;
  }

  // Se houve alteração de valor, atualiza target com mascara e ajusta posição do cursor
  if (hasChangedValue) {
    target.value = maskedValue;

    // Posição do cursor
    const adjustedCursor = calculateAdjustedCursorPosition(
      initialCursorPosition,
      originalValue,
      maskedValue
    );
    target.setSelectionRange(adjustedCursor, adjustedCursor);

    // Atualizar interactor
    interactor.value = maskedValue;
  }
}

/**
 * Calcula a posição ajustada do cursor após a formatação do valor
 *
 * @param {number} initialCursorPosition - Posição inicial do cursor
 * @param {string} originalValue - Valor original antes da formatação
 * @param {string} maskedValue - Valor após aplicação da máscara
 * @returns {number} - Nova posição do cursor ajustada
 */
function calculateAdjustedCursorPosition(
  initialCursorPosition,
  originalValue,
  maskedValue
) {
  let rawCursorPosition = initialCursorPosition;
  let numNonDigitsBeforeCursor = 0;

  // Conta os caracteres não numéricos antes da posição inicial do cursor
  for (let i = 0; i < rawCursorPosition; i++) {
    if (!/\d/.test(originalValue[i])) {
      numNonDigitsBeforeCursor++;
    }
  }

  // Ajusta a posição do cursor, ignorando os novos caracteres de máscara adicionados
  let newCursorPosition = rawCursorPosition - numNonDigitsBeforeCursor;

  // Ajusta a posição final do cursor de acordo com os caracteres não numéricos no novo valor
  let adjustedCursor = 0;
  for (let i = 0, digitCount = 0; i < maskedValue.length; i++) {
    if (/\d/.test(maskedValue[i])) {
      digitCount++;
    }
    if (digitCount === newCursorPosition) {
      adjustedCursor = i + 1;
      break;
    }
  }

  if (initialCursorPosition > maskedValue.length) {
    adjustedCursor = maskedValue.length;
  }

  return adjustedCursor;
}

/**
 * Valida o valor de target com base nos limites mínimo e máximo definidos em `props`.
 * Se o valor estiver fora dos limites, será retornado o valor mínimo ou máximo permitido.
 * Se o valor estiver dentro dos limites, será retornado undefined.
 *
 * @param {HTMLInputElement} target - Target que contém o valor a ser validado.
 * @returns {number|undefined} - Retorna o valor corrigido se estiver fora dos limites, ou undefined se estiver dentro dos limites.
 */
function validateMinMaxValue(target) {
  const targetValue = strToNum(target.value, 0, keepNegativeSignIfExists);

  // Valor Mínimo
  if (props.minValue !== null && targetValue < props.minValue) {
    return props.minValue;
  }

  // Valor Máximo
  if (props.maxValue !== null && targetValue > props.maxValue) {
    return props.maxValue;
  }

  // Valor dentro dos limites
  return undefined;
}

/**
 * Aplicar máscara no valor do target
 *
 * @param {HTMLInputElement} target
 * @returns {string} - Valor do target com máscara aplicada
 */
function applyTargetMask(target) {
  let result = "";
  let value = target.value.replace(/[^\d]/g, "");
  const isNegative = target.value.startsWith("-");
  const decimalPart =
    props.decimalPlaces > 0 ? "," + "#".repeat(props.decimalPlaces) : "";
  const mask = reverseString("###.###.###.###.###" + decimalPart);
  let valueReversed = reverseString(value);

  for (let x = 0, y = 0; x < mask.length && y < valueReversed.length; ) {
    if (mask.charAt(x) !== "#") {
      result += mask.charAt(x);
      x++;
    } else {
      result += valueReversed.charAt(y);
      y++;
      x++;
    }
  }
  return (isNegative ? "-" : "") + reverseString(result);
}

/**
 * Manipula o evento de foco em um campo de input.
 *
 * @param {FocusEvent} payload - O evento de foco que contém informações sobre o campo de input.
 * @returns {void}
 */
function onFocus(payload) {
  isInputFocused.value = true;

  if (props.isAutoSelectOnFocus) {
    /** @type {HTMLInputElement} */
    const target = payload.target;
    target.select();
  }
}

/**
 * Garante a formatação ao sair do input.
 *
 * @param {FocusEvent} payload - Evento de foco.
 * @returns {void}
 */
function onBlur(payload) {
  isInputFocused.value = false;
  isInputInvalid.value = false;
  displayInput.value = formatNumber(
    strToNum(displayInput.value, 0, keepNegativeSignIfExists),
    { decimalPlaces: props.decimalPlaces }
  );

  // Tratar valor fora dos limites
  const target = /** @type {HTMLInputElement} */ (payload.target);
  const valueOutOfLimits = validateMinMaxValue(target);
  if (valueOutOfLimits !== undefined) {
    target.value = formatNumber(valueOutOfLimits, {
      decimalPlaces: props.decimalPlaces,
    });
    interactor.value = target.value;
    return;
  }
}

/**
 * Lida com o evento de pressionar tecla em um campo de entrada numérica.
 * @param {KeyboardEvent} event - Evento de teclado.
 */
function onKeyDown(event) {
  // Permite o uso da tecla especial, mas não altera o input
  if (isSpecialKey(event)) {
    return;
  }

  // Verifica se a tecla pressionada pode ser inserida
  if (!isAvailableKey(event)) {
    event.preventDefault();
    return;
  }

  // Tratamento para o valor inicial
  const target = /** @type {HTMLInputElement} */ (event.target);
  if (
    target.value === formatNumber(0, { decimalPlaces: props.decimalPlaces })
  ) {
    target.value = event.key;
    interactor.value = event.key;
    event.preventDefault();
  }
}

/**
 * Reverte a ordem dos caracteres em uma string.
 *
 * @param {string} str - A string a ser revertida.
 * @returns {string} A string com os caracteres na ordem inversa.
 */
function reverseString(str) {
  return str.split("").reverse().join("");
}

/**
 * Verifica se a tecla pressionada é uma tecla especial.
 *
 * @param {KeyboardEvent} event - Evento.
 * @returns {boolean} `true` se for uma tecla especial, caso contrário `false`.
 */
function isSpecialKey(event) {
  const key = event.key;

  return (
    (event.ctrlKey &&
      (key === "a" || key === "c" || key === "v" || key === "x")) ||
    key === "Backspace" ||
    key === "Ctrl" ||
    key === "Alt" ||
    key === "Delete" ||
    key === "ArrowLeft" ||
    key === "ArrowRight" ||
    key === "Home" ||
    key === "End" ||
    key === "Tab"
  );
}

/**
 * Verifica se a tecla pressionada pode ser inserida no campo de entrada.
 *
 * @param {KeyboardEvent} event - Evento.
 * @returns {boolean} `true` se a tecla for permitida, caso contrário `false`.
 */
function isAvailableKey(event) {
  const key = event.key;
  const target = /** @type {HTMLInputElement} */ (event.target);
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    "-",
  ];
  const cursorPosition = target.selectionStart ?? target.value.length;
  const hasNegativeSign = target.value.startsWith("-");
  const hasComma = target.value.includes(",");
  const isAfterComma = hasComma && cursorPosition > target.value.indexOf(",");

  // Verifica se o caractere digitado é permitido
  return (
    allowedKeys.includes(key) ||
    (key === "-" && !hasNegativeSign && cursorPosition === 0) ||
    (key === "," && !hasComma) ||
    (key === "." && isAfterComma)
  );
}

//#endregion
</script>

<style></style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";
#app-input-number {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: nowrap;

  &.invalid {
    .prepend,
    .append,
    .app-input {
      border-color: $danger;
    }
  }

  &:has(.prepend) .app-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:has(.append) .app-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .prepend,
  .append {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $muted-medium;
    padding: 0 8px;
  }

  .prepend-text,
  .append-text {
    font-family: Fredoka;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
  }

  .prepend {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-right: none;
  }

  .append {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: none;
  }

  .app-input {
    flex: 1;
    box-shadow: none !important;
    height: 32px;
    border: 1px solid $muted-medium;
    border-radius: 4px;
    width: 100%;
    padding: 8px;

    &:focus {
      outline: none;
    }
  }
}
</style>
