<template>
  <div>
    <modal :show.sync="modal">
      <template slot="header">
        <h5 class="modal-title pl-2">{{ title }} <small>(Realizado)</small></h5>
      </template>
      <div>
        <div>
          <div
            class="border-left border-primary border-3 d-flex justify-content-between"
          >
            <div class="col-7 pl-2">
              <h4>
                {{ service.service_name }}
                <br />
                <small>{{ service.service_description }} </small>
              </h4>
            </div>
            <div v-if="service.code" class="col-6 d-flex justify-content-end">
            <div class="row m-0">
              <small class="text-primary col-2 p-0">
                <img
                  width="55px"
                  src="/img/icons/os.png"
                />
              </small>
              <div class="col-10 pl-4">
                <span class="mb-0 h4 font-weight-bold">nº <span class="text-primary h2">{{ service.code }} </span></span>
                <h5 class="mb-0 font-weight-normal">{{ service.created_at_order | parseDate }}</h5>
              </div>
            </div>
          </div>
          </div>
        </div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="mt-4">
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Volume realizado
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-7 pr-0">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        v-on:input="
                          serviceCycle.volume_performed = formatString(
                            $event.target.value,
                            '0',
                            '999.9',
                            '1'
                          )
                        "
                        :disabled="service.was_billed === true || volume_performed_disabled"
                        v-model="serviceCycle.volume_performed"
                        inputmode="numeric"
                        class="form-control form-control-sm"
                      />
                      <template slot="append">
                        <small class="input-group-sm p-0 m-0">
                          <span style="text-transform: none">m</span><sup>3</sup>
                        </small>
                      </template>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Início do serviço
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-7 pr-0">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                      <input-date-time-picker @input="changedDates" :disabled="service.was_billed === true" v-model="serviceCycle.arrival_time" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Término do serviço
                  <span class="text-danger">&nbsp;*&nbsp;</span>
                </label>
                <div class="col-md-7 pr-0">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm">
                     <input-date-time-picker @input="changedDates" :disabled="service.was_billed === true" v-model="serviceCycle.construction_exit_time" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <!-- Aviso datas diferentes -->
              <div v-if="hasDifferentDates" class="form-group row p-0 m-0 mb-1 mt-4">
                <div
                  class="col-auto d-flex align-items-center pr-0">
                  <a role="button" @click.prevent="differentDatesAuthorized = !differentDatesAuthorized">
                    <img
                      :src="differentDatesAuthorized ? '/img/icons/icons8/ios/checked-checkbox.png' : '/img/icons/icons8/ios/checked-checkbox--v1.png'"
                      class="btn-send-emails" width="32"
                    >
                  </a>
                </div>
                <div class="col pr-0"> 
                  <span class="new-default-black-font fs-12 text-danger"> Atenção o término do serviço está com data diferente do início, marque o checkbox ao lado para confirmar e conseguir concluir o serviço </span>
                </div>
              </div>

              <div
                class="card-header border-header-default-bottom p-2 grid-col--fixed-left mt-3 mb-2"
              >
                <h4 class="mb-0" slot="title">Saída</h4>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Local
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <el-select
                      v-model="serviceCycle.send_company_plant_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                    >
                      <el-option
                        v-for="plant in schedule.company_plants"
                        :key="plant.company_plant_uuid"
                        :label="plant.company_plant_name"
                        :value="plant.company_plant_uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Horário
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <el-time-picker
                      v-model="serviceCycle.send_time"
                      size="mini"
                      format="HH:mm"
                      value-format="HH:mm"
                      :style="'width:100%!important'"
                    >
                    </el-time-picker>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Km
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-on:input="
                        serviceCycle.send_distance = formatString(
                          $event.target.value,
                          '0',
                          '999999',
                          '0'
                        )
                      "
                      inputmode="numeric"
                      v-model="serviceCycle.send_distance"
                      type="text"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        km
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div
                class="card-header border-header-default-bottom p-2 grid-col--fixed-left mt-3 mb-2"
              >
                <h4 class="mb-0" slot="title">Retorno</h4>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Local
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <el-select
                      v-model="serviceCycle.return_company_plant_uuid"
                      size="mini"
                      placeholder="Selecione"
                      filterable
                    >
                      <el-option
                        v-for="plant in schedule.company_plants"
                        :key="plant.company_plant_uuid"
                        :label="plant.company_plant_name"
                        :value="plant.company_plant_uuid"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Horário
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <el-time-picker
                      v-model="serviceCycle.return_time"
                      size="mini"
                      format="HH:mm"
                      value-format="HH:mm"
                      :style="'width:100%!important'"
                    >
                    </el-time-picker>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pt-1 pb-0 col-form-label form-control-label"
                >
                  Km
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      v-on:input="
                        serviceCycle.return_distance = formatString(
                          $event.target.value,
                          '0',
                          '999999',
                          '0'
                        )
                      "
                      v-model="serviceCycle.return_distance"
                      type="text"
                      inputmode="numeric"
                      class="form-control form-control-sm"
                    />
                    <template slot="append">
                      <small class="input-group-sm p-0 m-0">
                        km
                      </small>
                    </template>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label
                  class="col-md-5 pb-0 pt-1 col-form-label form-control-label"
                >
                  Observações
                </label>
                <div class="col-md-7 pr-0">
                  <base-input input-group-classes="input-group-sm">
                    <textarea
                      style="width:100%!important"
                      v-model="serviceCycle.observation"
                      type="text"
                      rows="2"
                      class="form-control form-control-sm"
                    >
                    </textarea>
                  </base-input>
                </div>
              </div>
            </div>
            <div class="modal-footer row d-flex justify-content-center">
              <div class="row w-100">
                <div class="col-md-4 pl-2 pr-2 mb-2">
                <base-button class="pl-0 pr-0" type="secondary" block @click="closeModal()">
                  Cancelar
                </base-button>
                 </div>
                <div class="col-md-4 pl-2 pr-2 mb-2">
                <base-button
                  type="primary"
                  :loading="loading"
                  block
                  :disabled="hasDifferentDates && !differentDatesAuthorized"
                  class="pl-0 pr-0"
                  @click.prevent="save(1)"
                >
                  <i class="fas fa-save"></i> Salvar
                </base-button>
                </div>
                <div class="col-md-4 pl-2 pr-2">
                <base-button
                  type="success"
                  :loading="loading"
                  :disabled="invalid || service.was_billed === true || (hasDifferentDates && !differentDatesAuthorized)"
                  class="pl-0 pr-0"
                  block
                  @click.prevent="save(0)"
                >
                  <i class="fas fa-check"></i>
                  Concluir
                </base-button>
                </div>
            </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import { checkNumberValue, date } from "@/helpers";
import { mask1, formatErrorValidation, formatDate } from "@/plugins";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import {mapGetters} from "vuex";

export default {
  components: {
    InputDateTimePicker,
  },
  name: "ModalServiceCycle",
  data() {
    return {
      modal: false,
      title: "Ciclo de Serviço",
      validated: false,
      loading: false,
      service: {},
      loadingEquipment: false,
      schedule: {},
      serviceCycle: {
        schedule_service_uuid: null,
        volume_performed: null,
        return_time: null,
        send_time: null,
        send_distance: null,
        return_distance: null,
        arrival_time: null,
        construction_exit_time: null,
        observation: null,
        send_company_plant_uuid: null,
        return_company_plant_uuid: null
      },
      volume_performed_disabled: false,
      loadingConstructionExitTime: false,
      differentDatesAuthorized:false,
      hasDifferentDates: false,
      service_status: {
        '0' : 'released',
        '1' : 'completed',
        '2' : 'not_performed',
        '3' : 'canceled',
        '4' : 'in_progress',
        '5' : 'seller_request',
        '6' : 'pending'
      }
    };
  },
  computed: {
    ...mapGetters({
      $_operational_permission: "operationalPermission/show",
    })
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    handleCreate(schedule, service) {
      this.$Progress.start();
      this.schedule = schedule;
      this.service = service;
      this.serviceCycle.schedule_service_uuid = service.uuid;
      this.volume_performed_disabled = true;
      this.loadingConstructionExitTime = false;
      this.differentDatesAuthorized = false;
      this.hasDifferentDates = false;
      this.$store
        .dispatch("scheduleService/getCycle", service.uuid)
        .then(response => {
          this.serviceCycle = {
            volume_performed: parseFloat(response.data.volume_performed) > 0 ? response.data.volume_performed : schedule.volume_performed,
            return_time: response.data.return_time ?? null,
            send_time: response.data.send_time ?? null,
            send_distance: response.data.send_distance ?? null,
            return_distance: response.data.return_distance ?? null,
            arrival_time: response.data.arrival_time ? response.data.arrival_time : service.default_start,
            construction_exit_time:
              response.data.construction_exit_time ?? null,
            observation: response.data.observation ?? null,
            send_company_plant_uuid:
              response.data.send_company_plant_uuid ?? null,
            return_company_plant_uuid:
              response.data.return_company_plant_uuid ?? null
          };
          this.serviceCycle.schedule_service_uuid = service.uuid;
          if(!this.serviceCycle.arrival_time && schedule.concreting_date) {
            this.serviceCycle.arrival_time = formatDate(schedule.concreting_date);
          }
        })
        .catch(error => {
          this.serviceCycle = {
            volume_performed: schedule.volume_performed,
            return_time: null,
            send_time: null,
            send_distance: null,
            return_distance: null,
            arrival_time: service.default_start,
            construction_exit_time: null,
            observation: null,
            send_company_plant_uuid: null,
            return_company_plant_uuid: null
          };
          if(!this.serviceCycle.arrival_time && schedule.volume_performed > 0 && schedule.first_arrival_time) {
            this.serviceCycle.arrival_time = formatDate(schedule.first_arrival_time);
          }
          this.serviceCycle.schedule_service_uuid = service.uuid;
        })
        .finally(() => {
          Promise.all([
            this.$store.dispatch('operationalPermission/getByUserId', this.$user.id),
            this.getPerformedVolume()
          ]).then(() => {
            this.modal = true;
            this.$Progress.finish();
            this.volume_performed_disabled = 
              this.schedule.volume_performed > 0 && !(this.$_operational_permission.can_edit_schedule_service_volume && this.schedule.status.label === 'Concluído');
          });
          if(!this.serviceCycle.construction_exit_time) {
            this.getConstructionExitTime();
          }
        });
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(
          mask1(value.toString(), decimals, ".", true),
          min,
          max
        );
      }
      return checkNumberValue(value, min, max);
    },
    save(only_save = 0) {
      this.$Progress.start();
      this.loading = true;
      this.serviceCycle.only_save = only_save
      this.$store
        .dispatch("scheduleService/setCycle", this.serviceCycle)
        .then(response => {
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.closeModal();
          this.$emit("updatedCycle",response.data);
          this.loading = false;
        })
        .catch(error => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message
            });
            this.$Progress.finish();
            this.loading = false;
          } else if (
            error.response &&
            error.response.status &&
            error.response.status === 422
          ) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
            this.$Progress.finish();
            this.loading = false;
          }
        });
    },
    async getPerformedVolume() {
      if(this.service_status[this.service.status] === 'in_progress') {
        const response = await this.$store.dispatch("scheduleService/getPerformedVolume", this.service.id)
        this.serviceCycle.volume_performed = parseFloat(response.data);
      }
      return this.serviceCycle;
    },
    async getConstructionExitTime() {
      this.loadingConstructionExitTime = true;
      const response = await this.$store.dispatch("schedule/getFinishedLatestTimeFromTravel", this.schedule.id);
      this.loadingConstructionExitTime = false;
      if(response.data) {
        this.serviceCycle.construction_exit_time = response.data;
      }
    },
    changedDates() {
      if(!this.serviceCycle || !this.serviceCycle.construction_exit_time || !this.serviceCycle.arrival_time) {
        return;
      }

      const arrivalTimeDay = date.make(this.serviceCycle.arrival_time).format(date.FORMAT.FULL_DATE);
      const constructionExitTimeDay = date.make(this.serviceCycle.construction_exit_time).format(date.FORMAT.FULL_DATE);
      this.hasDifferentDates = arrivalTimeDay !== constructionExitTimeDay;
    }
  },
};
</script>

<style>
.custom-service-cycle-btn-label {
    padding: 0.863rem 0.363rem;
}
</style>
