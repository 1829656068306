<template>
  <div>
    <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
      <form
        class="needs-validation"
        @submit.prevent="handleSubmit(onSubmitClick)"
        autocomplete="off"
      >
        <div class="container-fluid">
          <!-- IDENTIFICAÇÃO -->
          <div class="row mt-2 mb-3">
            <div class="col-12" style="display: flex; align-items: center">
              <AppIcon icon="info-squared" color="warning" width="22" />
              <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
              <hr class="ml-3 m-0" style="width: 100%" />
            </div>
          </div>

          <!-- STATUS -->
          <div class="row mt-2 align-items-center">
            <div class="col-9">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Status
              </h4>
            </div>
            <div class="col-3">
              <AppSwitch v-model="formData.status"/>
            </div>
          </div>

          <!-- SERVIÇO -->
          <div class="row mt-2 align-items-center">
            <label for="service_name" class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Serviço
              </h4>
            </label>
            <div class="col-6">
              <validation-provider rules="required">
                <base-input input-group-classes="input-group-sm">
                  <PuzlSelect
                    v-model="formData.service_id"
                    :items="serviceNames"
                    label="service_name"                  
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>

          <!-- DESCRIÇÃO -->
          <div class="row mt-2 align-items-center">
            <label for="description" class="col-6 mt-1">
              <h4 class="new-default-black-font font-weight-normal m-0">
                Descrição
              </h4>
            </label>
            <div class="col-6">
              <validation-provider rules="required" v-slot="{ errors }">
                <base-input input-group-classes="input-group-sm">
                  <input
                    id="description"
                    class="form-control form-control-sm"
                    type="text"
                    v-model="formData.description"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </base-input>
              </validation-provider>
            </div>
          </div>

          <div class="row mt-5 mb-4">
            <div class="col-12 text-right">
              <AppButton
              label="Cancelar"
              type="danger"
              class="ml-2"
              :hasOutline="true"
              @click="onCancelClick"
              />
              <AppButton
              label="Salvar"
              type="success"
              class="ml-2"
              native-type="submit"
              :isDisabled="invalid"
              :isLoading="$_service_is_saving"
              />
            </div>
          </div>
        </div>
      </form>    
    </validation-observer>
  </div>
</template>

<script setup>
//#region Imports
import { defineEmits, defineProps, computed } from "vue";
import { ServiceDescriptionType } from "../../types";
import serviceDescriptionStore from "../../store/serviceDescriptionStore";
import { ValidationObserver } from "vee-validate";
import { AppButton, AppSwitch, AppIcon } from "../../../../../components/AppGlobal";
import { useServiceDescriptionHook } from "../../hooks/useServiceDescriptionHook";
import PuzlSelect from "@/components/PuzlSelect";
//#endregion


//#region Data
const { serviceNames } = useServiceDescriptionHook();
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {ServiceDescriptionType} formData
 */
/** @type {Props} */
const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
});
//#endregion

//#region Emits
const emit = defineEmits([
  "onCancelClick", 
  "onSubmitClick"
]);
//#endregion

//#region Computeds
/** Store Getters */
const $_service_description_is_saving = computed(() => serviceDescriptionStore.getters.getIsSaving());
//#endregion

//#region Methods
function onCancelClick() {
  emit("onCancelClick");
}

function onSubmitClick() {
  emit("onSubmitClick");
}
//#endregion
</script>

<style scoped></style>
