<template>
  <div>
    <modal
      :show.sync="modal.create"
      :backgroundColor="'#F2F4F9'"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/icons8/ios/icon-plus-success.png" height="24" width="24">
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer" @click="closeModal()" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
      </template>
      <div class="p-2">
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form class="needs-validation" autocomplete="off">
            <span class="ml-2 label-title">
              <img height="30px" src="/img/icons/icons8/ios/info-squared.png" class="mr-2"/>
              Identificação
            </span>
            <div class="form-group row m-0 p-0 mb-1">
              <div class="col-md-8 mb-1 pr-4">
                <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                  Receber e-mails financeiros
                </div>
              </div>
              <div class="col-md-3 mb-1 mt-2">
                <ToggleButton 
                  :initialValue="entityContact?.is_financial ?? 0" 
                  @update:initialValue="entityContact.is_financial = $event"
                />
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Nome
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8">
                <validation-provider rules="required" v-slot="{ errors }">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="entityContact.name"
                      :class="errors[0] ? 'is-invalid' : 'is-valid'"
                    />
                  </base-input>
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Cargo
              </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="entityContact.office"
                  />
                </base-input>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                E-mail
                <span class="text-danger" v-if="entityContact.is_financial">
                  &nbsp;*
                </span>
              </label>
              <div class="col-md-8">
                <validation-provider
                  :rules="entityContact.is_financial ? 'required' : ''"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    v-model="entityContact.email"
                    :class="errors[0] ? 'is-invalid' : 'is-valid'"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-2">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Celular
                <span class="text-danger">&nbsp;*</span>
              </label>
              <div class="col-md-8">
                <validation-provider rules="required" v-slot="{ errors }">
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    v-model="entityContact.phone"
                    v-mask="['(##) # ####-####']"
                  />
                </validation-provider>
              </div>
            </div>

            <div class="form-group row m-0 p-0 mb-1">
              <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                Assinatura Digital
              </label>
              <div class="col-md-8">
                <base-input input-group-classes="input-group-sm">
                  <select 
                    id="contactSignatureType" 
                    class="form-control"
                    v-model="entityContact.signature_type"
                  >
                    <option value="nenhum">NENHUM</option>
                    <option value="contratante">CONTRATANTE</option>
                    <option value="testemunha">TESTEMUNHA</option>
                    <option value="fiador">FIADOR</option>
                  </select>
                </base-input>
              </div>
            </div>

            <div class="row mt-3 mb-4">
              <div class="col-12 text-right">
                <AppButton
                  label="Cancelar"
                  icon="cancel"
                  type="danger"
                  class="ml-2"
                  :hasOutline="true"
                  @click="closeModal()"
                />
                <AppButton
                  label="Salvar"
                  icon="save"
                  type="success"
                  class="ml-2"
                  native-type="submit"
                  :isDisabled="invalid"
                  :isLoading="loadingStore"
                  @click.prevent="addContact(entityContact)"
                />
              </div>
            </div>

          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import ToggleButton from '../../../../../components/ToggleButton';
import { AppButton } from "../../../../..//components/AppGlobal";

export default {
  name: "CreateEntityContact",
  components: { ToggleButton, AppButton },
  data() {
    return {
      modal: {
        title: "Novo contato",
        create: false,
      },
      entityContact: {
        is_financial: 0,
        name: "",
        office: "",
        email: "",
        phone: "",
        signature_type: "nenhum",
        is_new_registry: true,
      },
      contacts: [],
      loadingStore: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {array} contacts
     */
    openModal(contacts = null) {
      this.clearEntityAccount();
      if (contacts && contacts.length) {
        this.contacts = contacts;
      }
      this.modal.create = true;
    },
    /**
     * Adiciona contato no array.
     * @param {object} contact
     */
    addContact(contact) {
      this.contacts.push(contact);
      this.$emit("storedEntityContact", this.contacts);
      this.contacts = [];
      this.closeModal();
    },
    clearEntityAccount() {
      this.entityContact = {
        is_financial: 0,
        name: "",
        office: "",
        email: "",
        phone: "",
        signature_type: "nenhum",
        is_new_registry: true,
      };
    },
  },
};
</script>

<style scoped>
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
