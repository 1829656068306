<template>
  <div>
    <div class="row">
      <ShadedTable :length="$_syncGeneralListed.items.length" :headers="headers" :loading="$_syncGeneralIsListing">
        <template v-for="(item, index) in $_syncGeneralListed.items" :slot="index">
          <!-- Status -->
          <ShadedCol>
            <div v-if="item.status === SyncGeneralStatusEnum.keys.INTEGRATED" class="table-status" :class="getStatusClass(item.status)">
              <span> {{ SyncGeneralStatusEnum.getTitle(item.status) }} </span>
            </div>
            <el-popover v-else class="ml-0" placement="bottom" trigger="hover">
              <div class="d-flex" style="gap: 15px">
                <span>{{ item.log }}</span>
              </div>
              <div slot="reference" class="table-status" :class="getStatusClass(item.status)">
                <span> {{ SyncGeneralStatusEnum.getTitle(item.status) }} </span>
              </div>
            </el-popover>
          </ShadedCol>

          <!-- Data -->
          <ShadedCol>
            <div class="table-value table-date">
              {{ $helper.parseDate(item.sync_at, "DD MMM YYYY") }}
            </div>
          </ShadedCol>

          <!-- Integração -->
          <ShadedCol>
            <div class="table-value">
              {{ SyncTypeEnum.getTitle(item.type) }}
            </div>
          </ShadedCol>

          <!-- Referência -->
          <ShadedCol>
            <div class="table-value">
              {{ item.label }}
            </div>
          </ShadedCol>

          <!-- Ações -->
          <ShadedCol>
            <base-dropdown menuOnRight class="text-center">
              <div slot="title-container" class="dropdown-toggle rounded m-0">
                <img width="25" src="/img/icons/icons8/ios/settings--v1_primary.png" />
              </div>
              <a @click="resend(item.id)" v-if="item.status !== SyncGeneralStatusEnum.keys.INTEGRATED" class="dropdown-item">
                <AppIcon color="primary" class="mr-2" icon="restart" width="22" />
                Reenviar
              </a>
            </base-dropdown>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { computed, defineEmits, ref } from "vue";
import SyncGeneralStore from "../../store/SyncGeneralStore";
import { dialogs, progress } from "../../../../../helpers";
import ShadedTable from "../../../../../components/ShadedTable.vue";
import ShadedCol from "../../../../../components/ShadedCol";
import { SyncGeneralStatusEnum } from "../../enums/SyncGeneralStatusEnum";
import { SyncTypeEnum } from "../../../shared/enums/SyncTypeEnum";
import { AppIcon } from "../../../../../components/AppGlobal";
//#endregion

//#region Emits
const emit = defineEmits(["onEditItemClick"]);
//#endregion

//#region Data
const headers = ref(["Status", "Data", "Integração", "Referência", "Ação"]);
//#endregion

//#region Computeds
const $_syncGeneralListed = computed(() => SyncGeneralStore.getters.getListed());
const $_syncGeneralIsListing = computed(() => SyncGeneralStore.getters.getIsListing());
//#endregion

//#region Methods
/**
 * Retorna a classe CSS correspondente ao status.
 * @param {string} status - Status da integração.
 * @returns {string} - Classe CSS.
 */
function getStatusClass(status) {
  switch (status) {
    case SyncGeneralStatusEnum.keys.INTEGRATED:
      return "integrated-status";
    case SyncGeneralStatusEnum.keys.ERROR:
      return "error-status";
    default:
      return "";
  }
}

/**
 * Reenvia integração
 *
 * @param {number} id
 * @return void
 */
function resend(id) {
  const loader = progress.showLoader();

  SyncGeneralStore.actions
    .resend(id)
    .then(() => {
      dialogs.notify("success", "Solicitação concluída com sucesso! Este processo será realizado em segundo plano.");
    })
    .finally(() => {
      loader.hide();
    });
}
//#endregion
</script>

<style scoped>
* {
  font-family: Fredoka;
}
.row >>> table thead th:first-child {
  text-align: center !important;
}
.row >>> table thead th {
  text-align: left !important;
}
.row >>> table thead th:last-child {
  text-align: center !important;
}
.table-value {
  font-weight: 400 !important;
  font-size: 0.8125rem;
  line-height: 20px;
  color: #2b2d32;
}

.table-date {
  font-size: 0.9rem;
}
.dropdown-item {
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem !important;
}

.dropdown-divider {
  margin: 0;
}

.table-status {
  border-radius: 8px;
  border-radius: 15px;
  padding: 5px 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.table-status span {
  font-size: 12px !important;
  font-weight: 400 !important;
}

/* Status: Erro */
.error-status {
  background-color: #fcf3f3;
  color: #db4539;
}

/* Status: Integrado */
.integrated-status {
  background-color: #f2f7f3;
  color: #149e57;
}
</style>
