/**
 * Type para Incluir/Editar/Visualizar
 *
 * @typedef {Object} ServiceDescriptionType
 * @property {number} id // [V]
 * @property {string} uuid // [V]
 * @property {string} service_name 
 * @property {number} service_id
 * @property {boolean} status
 * @property {string} description
 * @property {string} created_at // [V]
 * @property {?string} updated_at // [V]
 */
export const ServiceDescriptionType = {};

/**
 * Inicializar ServiceDescriptionType
 * 
 * @returns {ServiceDescriptionType}
 */
export const initServiceDescriptionType = () => {
  return {
    id: null,
    uuid: '',
    service_name: '',
    service_id: null,
    status: true,
    description: '',
    created_at: null,
    updated_at: null, 
  }
};