import httpClient from "../libraries/httpClient";
import { HttpStatusEnum } from "../enums";
import { handleUnexpectedStatusCode } from "../services";
import { BaseListFilterType, PagerType } from "../types";

//#region ✅ CÓDIGO ATUAL
/**
 * Inclusão (Base) de recurso
 *
 * @param {Object} data - Dados do recurso a ser criado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setNewItemInList - Adiciona o novo item à lista existente.
 * @param {string} urlBase - URL para criar o recurso.
 * @returns {Promise<Object>} - O recurso criado.
 */
export const createSource = async (data, mutations, urlBase) => {
  try {
    mutations.setIsSaving(true);

    const execute = async (data) => {
      const res = await httpClient.post(urlBase, data);
      switch (res.status) {
        case HttpStatusEnum.HTTP_CREATED: return res.data.data;
        default: handleUnexpectedStatusCode(res);
      }
    };

    const created = await execute(data);
    return mutations.setNewItemInList(created);
  } finally {
    mutations.setIsSaving(false);
  }
};

/**
 * Localizar (Base) por ID
 *
 * @param {number|string} id - O ID do recurso a ser localizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsFinding - Define o estado de busca (true/false).
 * @param {Function} mutations.setItem - Armazena o recurso encontrado.
 * @param {string} urlBase - URL para localizar o recurso pelo ID.
 * @returns {Promise<?Object>} - O recurso localizado ou null se não encontrado.
 */
export const findSource = async (id, mutations, urlBase) => {
  try {
    mutations.setIsFinding(true);

    const execute = async (id) => {
      const url = `${urlBase}/${id}`;
      const res = await httpClient.get(url);
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK: return res.data.data;
        case HttpStatusEnum.HTTP_NOT_ACCEPTABLE: return null;
        default: handleUnexpectedStatusCode(res);
      }
    };

    const found = await execute(id);
    return mutations.setItem(found);
  } finally {
    mutations.setIsFinding(false);
  }
};


/**
 * Listagem (Base) de recursos
 *
 * @param {BaseListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @param {Object} mutations - Objeto de mutações
 * @param {Function} mutations.setIsListing - Define o estado de listagem (true/false).
 * @param {Function} mutations.setListed - Armazena a lista retornada na mutação.
 * @param {Function} mutations.setListMerge - Mescla a lista existente com a nova lista recebida.
 * @param {Object} getters - Objeto de getters
 * @param {Function} getters.getListed - Retorna a lista atual de itens listados.
 * @param {string} urlBase - URL para listar recursos.
 * @returns {Promise<?PagerType>} - Retorno paginado
 */
export const listSources = async (filter, isAccumulateItems = false, mutations, getters, urlBase) => {
  try {
    mutations.setIsListing(true);
    const execute = async (filter = null) => {
      const res = await httpClient.get(urlBase, {params: filter});
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK:
          return res.data.data;
        default:
          handleUnexpectedStatusCode(res);
      }
    };

    // Retorna a lista requisitada
    if (!isAccumulateItems) {
      const listed = await execute(filter);
      return mutations.setListed(listed);
    }

    // Retorna null quando não há próxima página disponível
    const existingListed = getters.getListed();
    if (!existingListed?.has_next_page) {
      return null;
    }

    // Retorna a lista existente combinada com a lista requisitada
    filter.page = existingListed.next_page;
    const newListed = await execute(filter);
    return mutations.setListMerge(newListed);
  } finally {
    mutations.setIsListing(false);
  }
};

/**
 * Deletar (Base) por ID
 *
 * @param {number} id - O ID do recurso a ser deletado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsRemoving - Define o estado de remoção (true/false).
 * @param {Function} mutations.setRemovedItem - Armazena o ID do recurso removido.
 * @param {string} urlBase - URL para remover o recurso pelo ID.
 * @returns {Promise<void>} - Promise que resolve quando a remoção é concluída.
 */
export const removeSource = async (id, mutations, urlBase) => {
  try {
    mutations.setIsRemoving(true);

    const execute = async (id) => {
      const url = `${urlBase}/${id}`;
      const res = await httpClient.remove(url);
      const removedStatus = [
        HttpStatusEnum.HTTP_OK,
        HttpStatusEnum.HTTP_NO_CONTENT
      ];
      const removed = removedStatus.includes(res.status);
      if (!removed) {
        handleUnexpectedStatusCode(res);
      }
    };

    await execute(id);
    mutations.setRemovedItem(id);
  } finally {
    mutations.setIsRemoving(false);
  }
};

/**
 * Atualizar (Base) por ID
 *
 * @param {Object} data - Dados do recurso a serem atualizados.
 * @param {number} id - O ID do recurso a ser atualizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setUpdatedItem - Armazena o recurso atualizado.
 * @param {string} urlBase - URL para atualizar o recurso pelo ID.
 * @returns {Promise<Object>} - O recurso atualizado.
 */
export const updateSource = async (data, id, mutations, urlBase) => {
  try {
    mutations.setIsSaving(true);

    const execute = async (data, id) => {
      const url = `${urlBase}/${id}`;
      const res = await httpClient.put(url, data);
      switch (res.status) {
        case HttpStatusEnum.HTTP_OK: return res.data.data;
        default: handleUnexpectedStatusCode(res);
      }
    };

    const updated = await execute(data, id);
    return mutations.setUpdatedItem(updated);
  } finally {
    mutations.setIsSaving(false);
  }
};
//#endregion

//#region ⚠️ DEPRECIADO
/**
 * @deprecated Utilize `createSource` em vez desta função.
 * Inclusão (Base) de recurso
 *
 * @param {Object} data - Dados do recurso a ser criado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setNewItemInList - Adiciona o novo item à lista existente.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para criar o recurso, recebendo os dados.
 * @returns {Promise<Object>} - O recurso criado.
 */
export const createResource = async (data, mutations, service) => {
  try {
    mutations.setIsSaving(true);

    const created = await service.execute(data);
    return mutations.setNewItemInList(created);
  } finally {
    mutations.setIsSaving(false);
  }
};

/**
 * @deprecated Utilize `findSource` em vez desta função.
 * Localizar (Base) por ID
 *
 * @param {number|string} id - O ID do recurso a ser localizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsFinding - Define o estado de busca (true/false).
 * @param {Function} mutations.setItem - Armazena o recurso encontrado.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para localizar o recurso pelo ID.
 * @returns {Promise<?Object>} - O recurso localizado ou null se não encontrado.
 */
export const findResource = async (id, mutations, service) => {
  try {
    mutations.setIsFinding(true);

    const found = await service.execute(id);
    return mutations.setItem(found);
  } finally {
    mutations.setIsFinding(false);
  }
};


/**
 * @deprecated Utilize `listSources` em vez desta função.
 * Listagem (Base) de recursos
 *
 * @param {BaseListFilterType} filter - Filtro de listagem
 * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
 * @param {Object} mutations - Objeto de mutações
 * @param {Function} mutations.setIsListing - Define o estado de listagem (true/false).
 * @param {Function} mutations.setListed - Armazena a lista retornada na mutação.
 * @param {Function} mutations.setListMerge - Mescla a lista existente com a nova lista recebida.
 * @param {Object} getters - Objeto de getters
 * @param {Function} getters.getListed - Retorna a lista atual de itens listados.
 * @param {Object} service - Objeto de serviço
 * @param {Function} service.execute - Executa a chamada para listar recursos, recebendo um filtro e retornando os dados.
 * @returns {Promise<?PagerType>} - Retorno paginado
 */
export const listResources = async (filter, isAccumulateItems = false, mutations, getters, service) => {
  try {
    mutations.setIsListing(true);

    // Retorna a lista requisitada
    if (!isAccumulateItems) {
      const listed = await service.execute(filter);
      return mutations.setListed(listed);
    }

    // Retorna null quando não há próxima página disponível
    const existingListed = getters.getListed();
    if (!existingListed?.has_next_page) {
      return null;
    }

    // Retorna a lista existente combinada com a lista requisitada
    filter.page = existingListed.next_page;
    const newListed = await service.execute(filter);
    return mutations.setListMerge(newListed);
  } finally {
    mutations.setIsListing(false);
  }
};

/**
 * @deprecated Utilize `removeSource` em vez desta função.
 * Deletar (Base) por ID
 *
 * @param {number} id - O ID do recurso a ser deletado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsRemoving - Define o estado de remoção (true/false).
 * @param {Function} mutations.setRemovedItem - Armazena o ID do recurso removido.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para deletar o recurso pelo ID.
 * @returns {Promise<void>} - Promise que resolve quando a remoção é concluída.
 */
export const removeResource = async (id, mutations, service) => {
  try {
    mutations.setIsRemoving(true);

    await service.execute(id);
    mutations.setRemovedItem(id);
  } finally {
    mutations.setIsRemoving(false);
  }
};

/**
 * @deprecated Utilize `updateSource` em vez desta função.
 * Atualizar (Base) por ID
 *
 * @param {Object} data - Dados do recurso a serem atualizados.
 * @param {number} id - O ID do recurso a ser atualizado.
 * @param {Object} mutations - Objeto de mutações.
 * @param {Function} mutations.setIsSaving - Define o estado de salvamento (true/false).
 * @param {Function} mutations.setUpdatedItem - Armazena o recurso atualizado.
 * @param {Object} service - Objeto de serviço.
 * @param {Function} service.execute - Executa a chamada para atualizar o recurso pelo ID, recebendo os dados.
 * @returns {Promise<Object>} - O recurso atualizado.
 */
export const updateResource = async (data, id, mutations, service) => {
  try {
    mutations.setIsSaving(true);

    const updated = await service.execute(data, id);
    return mutations.setUpdatedItem(updated);
  } finally {
    mutations.setIsSaving(false);
  }
};
//#endregion
