<template>
  <div class="header fixed-title">
    <h2 class="m-0 text-white fixed-title">
      <img width="32" :src="icon" class="mr-2"/>
      {{ title }}
    </h2>
  </div>
</template>

<script setup>
import { ref, toRefs, defineProps } from 'vue';

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {String} title
 * @property {String} icon
 */
/** @type {Props} */
const props = defineProps({
  title: {
    type: String,
    description: 'Título da tela',
    default: '',
  },
  icon: {
    type: String,
    description: 'Ícone que será usado na tela',
    default: '',
  },
})

const { title, icon } = toRefs(props);
const prevRoute = ref(null);
</script>


<style scoped lang="scss">
.fixed-title {
  position: absolute;
  white-space: nowrap;
  display: flex;
  align-items: center;

  img{
    filter: brightness(0) invert(1);
  }
}

@media (max-width: 767px) {
  .fixed-title {
    top: 13px;
    left: 23px;
    font-size: 14px;
  }

  .fixed-title h2 img {
    display: none;
  }
}

@media (min-width: 768px) {
  .fixed-title {
    top: 10px;
    left: 35px;
  }

  .fixed-title h2 img {
    display: inline-flex;
  }
}

@media (min-width: 1200px) {
  .fixed-title {
    top: 10px;
    left: 15px;
  }
}
</style>
