export const PurchaseOrderStatusEnum = Object.freeze({
  PENDING: 0, // Pendente
  OPEN: 1, // Aprovado
  DENIED: 2, // Negado
  CANCELED: 3, // Cancelado
  COMPLETED: 4 // Concluído
});

const getTitle = (value) => {
  switch (value) {
      case PurchaseOrderStatusEnum.PENDING:
          return "Pendentes";
      case PurchaseOrderStatusEnum.OPEN:
          return "Abertas";
      case PurchaseOrderStatusEnum.DENIED:
          return "Negados";
      case PurchaseOrderStatusEnum.CANCELED:
          return "Cancelados";
      case PurchaseOrderStatusEnum.COMPLETED:
          return "Concluídas";
      default:
          return "";
  }
};

export default {
  keys: PurchaseOrderStatusEnum,
  getTitle,
};