import { BaseListFilterType, initBaseListFilterType } from "../../../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} DetachedListFilterExtType
 * @property {boolean|null} status
 * @property {?Array} range
 * @property {boolean|null} is_external_service // Terceiro
 * @property {boolean|null} concrete_removal // Retira de terceiro
 * 
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & DetachedListFilterExtType)} DetachedListFilterType
 */
export const DetachedListFilterType = {};

/**
 * Inicializar DetachedListFilterType
 *
 * @returns {DetachedListFilterType}
 */
export const initDetachedListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
    company_plant_id: null,
    range: {
      start: null,
      end: null,
    },
    range_value: {
      min: null,
      max: null
    },
    order_by: [{ column: "single_additional_invoice.id" }],
    custom_search: {
      columns: [
        "single_additional_invoice.date_at",
        "single_additional_invoice.description",
        "contract_proposals.code",
        "customer_constructions.customer_name",
        "constructions.construction_name",
        "users.name",
        "company_plants.name",
      ],
    },
  };

}