/**
 * Type para Incluir/Editar/Visualizar permissões tecnológicas do usuário
 *
 * @typedef {Object} UserTechPermissionType
 * @property {number | null} created_by_user_id - ID do usuário que criou.
 * @property {boolean} is_block_completion_possible_with_excessive_deviation - Bloqueio de conclusão com desvio excessivo é possível.
 * @property {boolean} is_change_technical_references_of_mcc_groups - Pode alterar referências técnicas dos grupos MCC.
 * @property {boolean} is_cmc_group_business_references_modified - Referências de negócios do grupo CMC foram modificadas.
 * @property {boolean} is_mcc_prices_changed - Os preços MCC foram alterados.
 * @property {boolean} is_puzl_adjust_applied - Ajuste PUZL foi aplicado.
 * @property {boolean} is_view_technical_data_of_traces_in_test_results_launch - Pode visualizar dados técnicos de rastros nos lançamentos de resultados de teste.
 * @property {number | null} updated_by_user_id - ID do usuário que atualizou.
 * @property {number | null} user_id - ID do usuário.
 */
export const UserTechPermissionType = {};

/**
 * Inicializar UserTechPermissionType
 * 
 * @returns {UserTechPermissionType}
 */
export const initUserTechPermissionType = () => {
    return {
        created_by_user_id: null,
        is_block_completion_possible_with_excessive_deviation: false,
        is_change_technical_references_of_mcc_groups: false,
        is_cmc_group_business_references_modified: false,
        is_mcc_prices_changed: false,
        is_puzl_adjust_applied: false,
        is_view_technical_data_of_traces_in_test_results_launch: false,
        updated_by_user_id: null,
        user_id: null,
    };
};