import { EntryLaunchItemStockStatusEnum } from "../enums/EntryLaunchItemStockStatusEnum";
import ProductServiceHubTypeEnum from "../../product-service-hub-type/enums/ProductServiceHubTypeEnum";

export function useEntryLaunchStockHook() {
  //#region Methods
  /**
   * Gerencia o status de estoque dos itens com base no tipo de nota fiscal e na definição de geração de estoque no Tipo de Operação Puzl.
   *
   * @param {array} items
   * @param {number} productServiceHubTypeId
   * @param {boolean} operationSourceIsGenerateStock
   */
  function setItemsStockStatus(items, productServiceHubTypeId, operationSourceIsGenerateStock) {
    let newStockStatus = EntryLaunchItemStockStatusEnum.keys.NO_STOCK;
    if (operationSourceIsGenerateStock) {
      newStockStatus =
        productServiceHubTypeId === ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE
          ? EntryLaunchItemStockStatusEnum.keys.USE_AND_CONSUMPTION
          : EntryLaunchItemStockStatusEnum.keys.STOCK;
    }

    items.map((item) => {
      item.stock_status = newStockStatus;
    });
  }
  //#endregion

  return {
    setItemsStockStatus,
  };
}
