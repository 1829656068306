import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} ServiceDescriptionListType
 * @property {number} id
 * @property {string} uuid
 * @property {string} service_name
 * @property {number} service_id
 * @property {boolean} status
 * @property {string} description
 * @property {string} created_at
 * @property {?string} updated_at
 */
export const ServiceDescriptionListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<ServiceDescriptionListType> } )} ServiceDescriptionListPagerType
 */
export const ServiceDescriptionListPagerType = {};

/**
 * Inicializar ServiceDescriptionListPagerType
 * 
 * @returns {ServiceDescriptionListPagerType}
 */
export const initServiceDescriptionListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

