<template>
  <div>
    <AppFixedPageTitle
      title="Serviços"
      icon="/img/icons/icons8/ios/svgs/todo-list.svg"
    />
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loading">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        v-else
        :contractInfo="contract_proposal"
      >
        <AppPageHeaderActions>
          <router-link :to="{
            name:'commercial.contract-proposal.services.create',
            path:'/commercial/contract-proposal/services/create',
            params: { contract_proposal_uuid: contract_proposal.uuid}
          }">
            <AppPageHeaderActionsButton
              :disabled="loading || [0, 5].includes(contract_proposal.status)"
              type="success"
              text="Novo"
              icon="/img/icons/icons8/ios/svgs/plus-math.svg"
              class="invert-color"
              >
            </AppPageHeaderActionsButton>
          </router-link>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>
      
      <ContractProposalNavigation
        v-if="contract_proposal.uuid"
        :contractUuid="contract_proposal.uuid"
        :contractStatus="contract_proposal.status"
        :entity="contract_proposal.entity"
        :activeServices="true"
        style="margin-top: 12px;"
      />
    </div>
    <div>
      <div class="container-fluid">
        <card>
          <div>
            <div class="row card-wrapper" v-if="contract_services.length && !loadingSkeleton">
              <div class="col-lg-4" v-for="(service, indexService) in contract_services" :key="indexService">
                <div class="card main-card" style="overflow: hidden;border-radius: 20px !important">
                  <div class="pt-3 pb-2 px-4">
                    <div class="row align-items-center">
                      <div class="col-10">
                        <h4 class="new-default-black-font font-weight-500 mb-0">{{ service.service.name }}</h4>
                      </div>
                      <div class="col-2 text-right">
                        <base-dropdown menuOnRight>
                          <div slot="title-container"
                            class="dropdown-toggle rounded m-0">
                            <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png">
                          </div>
                          <a class="dropdown-item" style="display: flex;align-items: center;"
                            v-if="![0,5].includes(contract_proposal.status)"
                            @click.prevent="handleEditService(service)">
                            <img src="/img/icons/create-new.png" width="22" alt="">
                            <span class="text-right">Editar</span>
                          </a>
                          <div class="dropdown-divider p-0 m-0" v-if="![0,5].includes(contract_proposal.status)"></div>
                          <a class="dropdown-item" style="display: flex;align-items: center;"
                            v-if="![0,5].includes(contract_proposal.status)"
                            @click.prevent="handleNewValidity(service)">
                            <img src="/img/icons/icons8/ios/today_primary.png" width="22" alt="">
                            <span class="text-right">Nova Vigência</span>
                          </a>
                          <div class="dropdown-divider p-0 m-0" v-if="![0,5].includes(contract_proposal.status)"></div>
                          <a class="dropdown-item" style="display: flex;align-items: center;"
                            @click.prevent="handleResume(service.id)">
                            <img src="/img/icons/icons8/ios/profit-report.png" width="22" alt="">
                            <span class="text-right">Resumo do Serviço</span>
                          </a>
                          <div v-if="![0,5].includes(contract_proposal.status)" class="dropdown-divider p-0 m-0"></div>
                          <a class="dropdown-item" style="display: flex;align-items: center;"
                            v-if="![0,5].includes(contract_proposal.status)"
                            @click.prevent="handleRemoveService(service.uuid, service.futures.length)">
                            <img src="/img/icons/icons8/ios/delete--v1_danger.png" width="22" alt="">
                            <span class="text-right">Excluir</span>
                          </a>
                        </base-dropdown>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 my-1">
                        <h5 class="new-default-gray-font font-weight-normal" style="display: flex;align-items: center;">
                          {{ service.service.description }}
                        </h5>
                        <i v-if="service.expected_volume" style="font-style: normal; font-size: 13px;">
                          {{ service.expected_volume }}
                          <span style="text-transform: none">m</span><sup>3</sup>
                          (previsto)
                        </i>
                        <i class="mx-2" v-if="service.expected_hour"
                          style="font-style: normal;font-size: 13px;">
                          |
                        </i>
                        <i v-if="service.expected_hour" style="font-style: normal; font-size: 13px;">
                          {{ service.expected_hour }} {{ service.expected_hour > 1 ? 'horas' : 'hora' }} (previsto)
                        </i>
                      </div>
                    </div>
                    <div class="row align-items-center my-1">
                      <div class="col-12">
                        <div style="display: inline-flex;">
                          <h5 class="new-default-gray-font font-weight-normal my-0" style="display: flex;align-items: center;">
                            <i style="font-style: normal;font-size: 13px;">
                              V.{{ service.version }}
                            </i>
                            <i v-if="service.situation"
                              class="mx-2"
                              style="font-style: normal;font-size: 13px;">
                              |
                            </i>
                            <i v-if="service.situation" style="font-style: normal;font-size: 13px;">
                              {{ service.situation.created_at | parseDate('DD MMM YYYY')  }}
                            </i>
                          </h5>
                          <div v-if="!service.situation" style="margin: 0;width: 170px; justify-content: center; height: 25px;display: flex; align-items: center;"
                            class="text-uppercase mini-card-yellow ml-3"
                            type="button">
                            <h4 class="m-0">
                              Pendente de aprovação
                            </h4>
                          </div>
                          <div v-else-if="service.situation && service.situation.situation == 2"
                            style="margin: 0;width: 145px; justify-content: center; height: 25px;display: flex; align-items: center;"
                            class="text-uppercase mini-card-yellow ml-3"
                            type="button">
                            <h4 class="m-0">
                              Pendente de revisão
                            </h4>
                          </div>
                        </div>
                        <div class="float-right">
                          <base-button @click.prevent="handleResume(service.id)" outline size="sm" type="secundary"
                                       class="p-0 m-0 shadow-none text-indigo">
                            <img src="/img/icons/icons8/ios/profit-report.png" width="26">
                          </base-button>
                          <el-popover v-if="service.observation" trigger="click" placement="bottom" class="p-0 pr-0">
                            <span style="width: 290px; word-break: break-word;"
                              class="row">
                              <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                <img class="mr-2" src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png" width="22">
                                <h4 style="font-size: 16px;" class="p-0 m-0">Observações</h4>
                              </div>
                              <div class="col-12 mt-1">
                                <h5 class="font-weight-normal">
                                  {{ service.observation }}
                                </h5>
                              </div>
                            </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 ml-2 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/speech-bubble-with-dots--v1.png" width="26">
                            </base-button>
                          </el-popover>
                          <el-popover trigger="click" placement="bottom" class="p-0 pr-0">
                            <span style="width: 150px;" class="row">
                              <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                <img class="mr-2" src="/img/icons/chemical-plant-black.png" width="22">
                                <h4 style="font-size: 16px;" class="p-0 m-0">Central</h4>
                              </div>
                              <div class="col-12 mt-1">
                                <h5 class="font-weight-normal">{{ service.plant }}</h5>
                              </div>
                            </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 ml-2 shadow-none text-indigo">
                              <img src="/img/icons/chemical-plant-black.png" width="26">
                            </base-button>
                          </el-popover>
                          <el-popover trigger="click" placement="bottom" class="p-0 pr-0">
                            <span class="row" style="width: 150px;">
                              <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                <h4 style="font-size: 16px;" class="p-0 m-0">Usuário</h4>
                              </div>
                              <div class="col-12 mt-1">
                                <h5 class="font-weight-normal">{{ service.user }}</h5>
                                <h5 class="font-weight-normal">{{ service.date_at | parseDate('DD MMM YYYY') }}</h5>
                              </div>
                            </span>
                            <base-button outline slot="reference" size="sm" type="secundary"
                                         class="p-0 ml-2 shadow-none text-indigo">
                              <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
                            </base-button>
                          </el-popover>
                        </div>
                      </div>
                    </div>
                    <hr class="p-0 mt-3 mb-3">
                    <div class="row mb-5 align-items-center">
                      <div class="col-12" style="display: inline-flex;">
                        <h4 class="new-default-black-font m-0">
                          Valores vigentes
                        </h4>
                        <base-dropdown class="ml-3">
                          <div style="margin: 0;width: 95px; justify-content: center; height: 25px;display: flex; align-items: center;" slot="title-container"
                            class="text-uppercase"
                            :class="service.status === 1 ? 'mini-card-green' : 'mini-card-red'"
                            type="button">
                            <h4 class="m-0 dropdown-toggle">
                              {{ service.status === 1 ? 'Ativo' : 'Inativo' }}
                            </h4>
                          </div>
                          <a v-if="service.status === 1"
                            @click.prevent="handleSetStatus(service.uuid, 0, indexService)"
                            class="dropdown-item text-uppercase new-default-black-font"
                            style="font-size: 12px;">
                            Inativo
                          </a>
                          <a v-else
                            @click.prevent="handleSetStatus(service.uuid, 1, indexService)"
                            class="dropdown-item text-uppercase new-default-black-font"
                            style="font-size: 12px;">
                            Ativo
                          </a>
                        </base-dropdown>
                      </div>
                    </div>
                    <div class="row align-items-end">
                      <div class="col-4">
                        <div style="width: fit-content; display: inline-block;">
                          <h4 class="new-default-gray-font fixed-height">
                            Fixo
                          </h4>
                          <h4 class="new-default-gray-font fixed-height">
                            Por M³
                          </h4>
                          <h4 class="new-default-gray-font fixed-height">
                            Por hora
                          </h4>
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div style="width: fit-content; display: inline-block;">
                          <h6 class="new-default-gray-font text-left fixed-height">
                            Mínimo
                          </h6>
                          <h4 class="new-default-gray-font text-left fixed-height" v-if="service.proposal_price_fixed">
                            {{ service.old_service.price_fixed | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-if="service.service.by_meters">
                            {{ service.old_service.by_meters | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-if="service.service.by_hour">
                            {{ service.old_service.by_hour | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <div style="width: fit-content; display: inline-block;">
                          <h6 class="new-default-gray-font text-left fixed-height">
                            Venda
                          </h6>
                          <h4 class="text-left fixed-height" v-if="service.proposal_price_fixed"
                            :class="typeBetween(service.proposal_price_fixed, service.old_service.price_fixed)">
                            {{ service.proposal_price_fixed | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                          <h4 class="text-left fixed-height" v-if="service.proposal_by_meters"
                            :class="typeBetween(service.proposal_by_meters, service.old_service.by_meters)">
                            {{ service.proposal_by_meters | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                          <h4 class="text-left fixed-height" v-if="service.proposal_by_hours"
                            :class="typeBetween(service.proposal_by_hours, service.old_service.by_hour)">
                            {{ service.proposal_by_hours | currency() }}
                          </h4>
                          <h4 class="new-default-gray-font text-left fixed-height" v-else>
                            -
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12">
                        <collapse v-if="$_commercial_permission.minimum_price_approval"
                                  class="border rounded p-0 mt-3">
                          <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                            <h5 slot="title" class="mb-0 ">Aprovações</h5>
                            <div class="row">
                              <div @click.prevent="handleShowModalSituation(service, 1)"
                                class="col p-1 px-1 pointer">
                                <a class="card-with-box-shadow text-center" style="display: inherit;"
                                  :class="service.situation && service.situation.situation === 1 && 'card-wbs-success'">
                                  <div class="py-1">
                                    <img class="mr-1" width="20"
                                      src="/img/icons/icons8/ios/thumb-up_success.png"/>
                                    <i style="font-style: normal;font-size: 12px;"
                                      :class="service.situation && service.situation.situation === 1 ? 'text-success' : 'new-default-black-font'">
                                      Aprovar
                                    </i>
                                  </div>
                                </a>
                              </div>
                              <div @click.prevent="handleShowModalSituation(service, 0)"
                                class="col p-1 px-1 pointer">
                                <a class="card-with-box-shadow text-center" style="display: inherit;"
                                  :class="service.situation && service.situation.situation === 0 && 'card-wbs-danger'">
                                  <div class="py-1">
                                    <img class="mr-1" width="20"
                                      src="/img/icons/icons8/ios/thumbs-down_danger.png"/>
                                    <i style="font-style: normal;font-size: 12px;"
                                      :class="service.situation && service.situation.situation === 0 ? 'text-danger' : 'new-default-black-font'">
                                      Reprovar
                                    </i>
                                  </div>
                                </a>
                              </div>
                              <div @click.prevent="handleShowModalSituation(service, 2)"
                                class="col p-1 px-1 pointer">
                                <a class="card-with-box-shadow text-center" style="display: inherit;"
                                  :class="service.situation && service.situation.situation === 2 && 'card-wbs-warning'">
                                  <div class="py-1">
                                    <img class="mr-1" width="20"
                                      src="/img/icons/icons8/ios/thumbs-up-down_warning.png"/>
                                    <i style="font-style: normal;font-size: 12px;"
                                      :class="service.situation && service.situation.situation === 2 ? 'text-warning' : 'new-default-black-font'">
                                      Revisar
                                    </i>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div class="row mt-2 align-items-baseline" v-if="service.situation">
                              <div class="col-6">
                                <h5 class="new-default-black-font m-0 font-weight-normal" v-if="service.situation && service.situation.user">
                                  {{ service.situation.user.name }}
                                </h5>
                                <h5 class="new-default-black-font font-weight-normal" v-if="service.situation && service.situation.user">
                                  {{ service.situation.created_at | parseDate('DD MMM YYYY') }}
                                </h5>
                              </div>
                              <div class="col-6" v-if="service.situation.observation">
                                <span class="mb-0">
                                  <b class="new-default-black-font font-weight-400"
                                    style="font-size: 12px;">
                                    Observação:
                                  </b>
                                  <i class="new-default-gray-font"
                                    style="font-size: 12px;font-style: normal;">
                                    {{ service.situation.observation }}
                                  </i>
                                </span>
                              </div>
                              <div class="col-12 mt-2">
                                <base-button
                                  outline
                                  block
                                  class="btn-sm"
                                  @click.prevent="handleShowModalSituationHistories(service.situation_histories)">
                                  Histórico
                                </base-button>
                              </div>
                            </div>
                          </collapse-item>
                        </collapse>
                        <collapse v-if="service.futures.length" class="border rounded p-0 mt-3">
                          <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                            <h5 slot="title" class="mb-0 ">Vigência futura</h5>
                            <div v-for="(future, indexFuture) in service.futures" class="mt-4">
                              <div class="row align-items-center">
                                <div class="col-12">
                                  <h4 class="new-default-black-font font-weight-500 mb-0" style="display: inline-flex;">
                                    {{ future.service.name }}
                                  </h4>
                                  <div class="float-right">
                                    <el-popover trigger="click" placement="left" class="p-0 pr-0">
                                      <h5 class="font-weight-normal m-0">{{ future.plant }}</h5>
                                      <base-button outline slot="reference" size="sm" type="secundary"
                                                   class="p-0 ml-2 shadow-none text-indigo">
                                        <img src="/img/icons/chemical-plant-black.png" width="26">
                                      </base-button>
                                    </el-popover>
                                    <el-popover trigger="click" placement="bottom" class="p-0 pr-0">
                                      <span class="row" style="width: 150px;">
                                        <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                          <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                          <h4 style="font-size: 16px;" class="p-0 m-0">Usuário</h4>
                                        </div>
                                        <div class="col-12 mt-1">
                                          <h5 class="font-weight-normal">{{ future.user }}</h5>
                                          <h5 class="font-weight-normal">{{ future.date_at | parseDate('DD MMM YYYY') }}</h5>
                                        </div>
                                      </span>
                                      <base-button outline slot="reference" size="sm" type="secundary"
                                                   class="p-0 ml-2 shadow-none text-indigo">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
                                      </base-button>
                                    </el-popover>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 my-1">
                                  <h5 class="new-default-gray-font font-weight-normal" style="display: flex;align-items: center;">
                                    {{ future.service.description }}
                                  </h5>
                                  <i v-if="future.expected_volume" style="font-style: normal; font-size: 13px;">
                                    {{ future.expected_volume }}
                                    <span style="text-transform: none">m</span><sup>3</sup>
                                    (previsto)
                                  </i>
                                  <i class="mx-2" v-if="future.expected_hour"
                                    style="font-style: normal;font-size: 13px;">
                                    |
                                  </i>
                                  <i v-if="future.expected_hour" style="font-style: normal; font-size: 13px;">
                                    {{ future.expected_hour }} {{ future.expected_hour > 1 ? 'horas' : 'hora' }} (previsto)
                                  </i>
                                </div>
                              </div>
                              <div class="row mt-2 align-items-end">
                                <div class="col-4">
                                  <h4 class="new-default-gray-font">
                                    Fixo
                                  </h4>
                                  <h4 class="new-default-gray-font">
                                    Por M³
                                  </h4>
                                  <h4 class="new-default-gray-font">
                                    Por hora
                                  </h4>
                                </div>
                                <div class="col-4 text-center">
                                  <div style="width: fit-content; display: inline-block;">
                                    <h6 class="new-default-gray-font text-left">
                                      Mínimo
                                    </h6>

                                    <h4 class="new-default-gray-font text-left" v-if="future.service.price_fixed">
                                      {{ future.service.price_fixed | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="new-default-gray-font text-left" v-if="future.service.by_meters">
                                      {{ future.service.by_meters | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="new-default-gray-font text-left" v-if="future.service.by_hour">
                                      {{ future.service.by_hour | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                  </div>
                                </div>
                                <div class="col-4 text-right">
                                  <div style="width: fit-content; display: inline-block;">
                                    <h6 class="new-default-gray-font text-left">
                                      Venda
                                    </h6>
                                    <h4 class="text-left" v-if="future.proposal_price_fixed"
                                      :class="typeBetween(future.proposal_price_fixed, future.service.price_fixed)">
                                      {{ future.proposal_price_fixed | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="text-left" v-if="future.proposal_by_meters"
                                      :class="typeBetween(future.proposal_by_meters, future.service.by_meters)">
                                      {{ future.proposal_by_meters | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="text-left" v-if="future.proposal_by_hours"
                                      :class="typeBetween(future.proposal_by_hours, future.service.by_hour)">
                                      {{ future.proposal_by_hours | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-12">
                                  <base-button
                                    v-if="![0,5].includes(contract_proposal.status)"
                                    @click.prevent="handleRemoveFuture(future.uuid, indexService, indexFuture)"
                                    block
                                    size="sm"
                                    type="danger"
                                  >
                                    <i class="fa fa-trash text-white"></i>
                                  </base-button>
                                </div>
                              </div>
                              <hr v-if="indexFuture != service.futures.length -1">
                            </div>
                          </collapse-item>
                        </collapse>
                        <collapse v-if="service.histories.length" class="border rounded p-0 mt-3">
                          <collapse-item name="1" class="header-gray" back-ground="border-header-default-bottom">
                            <h5 slot="title" class="mb-0 ">Histórico</h5>
                            <div v-for="(history, indexHistory) in service.histories" class="mt-4">
                              <div class="row align-items-center">
                                <div class="col-12">
                                  <h4 class="new-default-black-font font-weight-500 mb-0" style="display: inline-flex;">
                                    {{ service.service.name }}
                                  </h4>
                                  <div class="float-right">
                                    <el-popover trigger="click" placement="left" class="p-0 pr-0">
                                      <h5 class="font-weight-normal m-0">{{ history.plant }}</h5>
                                      <base-button outline slot="reference" size="sm" type="secundary"
                                                   class="p-0 ml-2 shadow-none text-indigo">
                                        <img src="/img/icons/chemical-plant-black.png" width="26">
                                      </base-button>
                                    </el-popover>
                                    <el-popover trigger="click" placement="bottom" class="p-0 pr-0">
                                      <span class="row" style="width: 150px;">
                                        <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                          <img class="mr-2" src="/img/icons/icons8/ios/user-male-circle--v1.png" width="22">
                                          <h4 style="font-size: 16px;" class="p-0 m-0">Usuário</h4>
                                        </div>
                                        <div class="col-12 mt-1">
                                          <h5 class="font-weight-normal">{{ history.user }}</h5>
                                          <h5 class="font-weight-normal">{{ history.date_at | parseDate('DD MMM YYYY') }}</h5>
                                        </div>
                                      </span>
                                      <base-button outline slot="reference" size="sm" type="secundary"
                                                   class="p-0 ml-2 shadow-none text-indigo">
                                        <img src="/img/icons/icons8/ios/user-male-circle--v1.png" width="26">
                                      </base-button>
                                    </el-popover>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 my-1">
                                  <h5 class="new-default-gray-font font-weight-normal" style="display: flex;align-items: center;">
                                    {{ service.service.description }}
                                  </h5>
                                  <i v-if="history.expected_volume" style="font-style: normal; font-size: 13px;">
                                    {{ history.expected_volume }}
                                    <span style="text-transform: none">m</span><sup>3</sup>
                                    (previsto)
                                  </i>
                                  <i class="mx-2" v-if="history.expected_hour"
                                    style="font-style: normal;font-size: 13px;">
                                    |
                                  </i>
                                  <i v-if="service.expected_hour" style="font-style: normal; font-size: 13px;">
                                    {{ history.expected_hour }} {{ history.expected_hour > 1 ? 'horas' : 'hora' }} (previsto)
                                  </i>
                                </div>
                              </div>
                              <div class="row mt-2 align-items-end">
                                <div class="col-4">
                                  <h4 class="new-default-gray-font">
                                    Fixo
                                  </h4>
                                  <h4 class="new-default-gray-font">
                                    Por M³
                                  </h4>
                                  <h4 class="new-default-gray-font">
                                    Por hora
                                  </h4>
                                </div>
                                <div class="col-4 text-center">
                                  <div style="width: fit-content; display: inline-block;">
                                    <h6 class="new-default-gray-font text-left">
                                      Mínimo
                                    </h6>

                                    <h4 class="new-default-gray-font text-left" v-if="history.service.price_fixed">
                                      {{ history.service.price_fixed | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="new-default-gray-font text-left" v-if="history.service.by_meters">
                                      {{ history.service.by_meters | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="new-default-gray-font text-left" v-if="history.service.by_hour">
                                      {{ history.service.by_hour | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                  </div>
                                </div>
                                <div class="col-4 text-right">
                                  <div style="width: fit-content; display: inline-block;">
                                    <h6 class="new-default-gray-font text-left">
                                      Venda
                                    </h6>
                                    <h4 class="text-left" v-if="history.proposal_price_fixed"
                                      :class="typeBetween(history.proposal_price_fixed, history.service.price_fixed)">
                                      {{ history.proposal_price_fixed | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="text-left" v-if="history.proposal_by_meters"
                                      :class="typeBetween(history.proposal_by_meters, history.service.by_meters)">
                                      {{ history.proposal_by_meters | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                    <h4 class="text-left" v-if="history.proposal_by_hours"
                                      :class="typeBetween(history.proposal_by_hours, history.service.by_hour)">
                                      {{ history.proposal_by_hours | currency() }}
                                    </h4>
                                    <h4 class="new-default-gray-font text-left" v-else>
                                      -
                                    </h4>

                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-12" v-if="history.situation">
                                  <div class="row">
                                    <div class="col-md-6 mb-2">
                                      <base-button
                                        block
                                        size="sm"
                                        type="primary"
                                        @click.prevent="handleResume(history.id)"
                                      >
                                        <i class="fa fa-list text-white"></i>
                                      </base-button>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="d-flex justify-content-between">
                                        <base-button
                                          outline
                                          block
                                          class="btn-sm"
                                          @click.prevent="handleShowModalSituationHistories(history.situation_histories)">
                                          <i class="fas fa-history"></i>
                                        </base-button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr v-if="indexHistory != service.histories.length -1">
                            </div>
                          </collapse-item>
                        </collapse>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row card-wrapper" v-show="loadingSkeleton">
              <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingSkeleton" :key="index"></SkeletonPuzlGrid>
            </div>
            <PuzlEmptyData v-if="!contract_services.length && !loadingSkeleton"></PuzlEmptyData>
          </div>
        </card>
      </div>
    </div>
    <ModalSituationHistories ref="ModalSituationHistories"/>
    <ModalResume ref="modalResume"/>
    <modal-situation @updateSituation="handleUpdateSituation" ref="ModalSituation"></modal-situation>
    <edit-service ref="ModalEditService"/>
    <new-validity ref="ModalNewValidity" @close="init"/>
  </div>
</template>
<script>

import {mapGetters} from "vuex";
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalSituation from './_ModalSituation'
import ModalSituationHistories from './_ModalSituationHistories'
import EditService from './_EditService'
import newValidity from './_NewValidity'
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import BackButton from "../../../../../../components/Utils/BackButton";
import ModalResume from "./_ModalResume.vue";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import {ContractProposalEnum} from "@/enum/ContractProposalEnum";
import ContractProposalPageHeader from '../../components/ContractProposalPageHeader/ContractProposalPageHeader.vue'
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../../components/AppGlobal";

export default {
  name: 'ListContractProposalServices',
  components: {
    BackButton,
    ModalSituation,
    EditService,
    ModalSituationHistories,
    newValidity,
    SkeletonPuzlGrid,
    ModalResume,
    SkeletonPuzl,
    PuzlEmptyData,
    ContractProposalNavigation,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  data() {
    return {
      format_mixe: period_types,
      loadingSkeleton: true,
      loading: false,
      selected: 1,
      ContractProposalEnum: ContractProposalEnum,
    }
  },
  computed: {
    ...mapGetters({
      $_commercial_permission: "commercialPermission/show",
      contract_proposal: "contractProposal/show",
      contract_services: "contractService/fetch"
    })
  },
  methods: {
    init() {
      this.loadingSkeleton = true;
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(response => {
          this.$store
            .dispatch(
              "contractService/fetchItems", response.data.uuid
            )
            .then(() => {
              this.loadingSkeleton = false;
            });
          this.loading = false;
        });
    },
    handleEditService(service) {
      if (service.has_schedule_service) {
        return this.$refs.ModalNewValidity.openModal(service)
      }
      this.$refs.ModalEditService.openModal(service)
    },
    handleNewValidity(service) {
      this.$refs.ModalNewValidity.openModal(service)
    },
    validPrice(service) {
      let border = 'border-success'
      if (service.proposal_by_hours) {
        if (service.proposal_by_hours < service.service.by_hour) {
          return 'border-danger'
        }
      }
      if (service.proposal_price_fixed) {
        if (service.proposal_price_fixed < service.service.price_fixed) {
          return 'border-danger'
        }
      }
      if (service.proposal_by_meters) {
        if (service.proposal_by_meters < service.service.by_meters) {
          return 'border-danger'
        }
      }
      return border
    },
    handleSetStatus(uuid, status, index) {
      this.$Progress.start();
      this.$notify({
        type: 'info',
        message: 'Estamos trabalhando em sua solicitação.'
      });
      let params = {
        uuid: uuid,
        status: status,
      }
      this.$store.dispatch('contractService/setStatus', params).then(response => {
        this.$Progress.finish();
        this.$notify({
          type: response.error_type,
          message: response.message
        })
        this.$store.state.contractService.items[index].status = status
      })
    },

    handleResume(id) {
      this.$refs.modalResume.openModal(id);
    },

    handleShowModalSituationHistories(histories) {
      this.$notify({
        type: 'success',
        message: 'Dados carregados com sucesso!'
      })
      this.$refs.ModalSituationHistories.openModal(histories)
    },
    handleShowModalSituation(service, type) {
      let service_price = {...service.service}
      if (this.$_commercial_permission.limit_variation && type == 1) {
        let fixed_value = 0
        let m3_value = 0
        let hour_value = 0

        let monetary_fixed_value = 0
        let monetary_m3_value = 0
        let monetary_hour_value = 0

        let fixed_value_pct = 0
        let m3_value_pct = 0
        let hour_value_pct = 0
        if (service_price.price_fixed > 0) {
          if (Number(this.$_commercial_permission.minimum_price_service_limit) > 0) {
            monetary_fixed_value = service_price.price_fixed - this.$_commercial_permission.minimum_price_service_limit
          }
          if (Number(this.$_commercial_permission.minimum_price_hour_service_limit_percentage) > 0) {
            fixed_value_pct = service_price.price_fixed * (1 - (this.$_commercial_permission.minimum_price_service_limit_percentage / 100))
          }
          fixed_value = Math.max(monetary_fixed_value, fixed_value_pct)
        }

        if (service_price.by_meters > 0) {
          if (Number(this.$_commercial_permission.minimum_price_m3_service_limit) > 0) {
            monetary_m3_value = service_price.by_meters - this.$_commercial_permission.minimum_price_m3_service_limit
          }
          if (Number(this.$_commercial_permission.minimum_price_m3_service_limit_percentage) > 0) {
            m3_value_pct = service_price.by_meters * (1 - (this.$_commercial_permission.minimum_price_m3_service_limit_percentage / 100))
          }
          m3_value = Math.max(monetary_m3_value, m3_value_pct)
        }

        if (service_price.by_hour > 0) {
          if (Number(this.$_commercial_permission.minimum_price_hour_service_limit) > 0) {
            monetary_hour_value = service_price.by_hour - this.$_commercial_permission.minimum_price_hour_service_limit
          }
          if (Number(this.$_commercial_permission.minimum_price_hour_service_limit_percentage) > 0) {
            hour_value_pct = service_price.by_hour * (1 - (this.$_commercial_permission.minimum_price_hour_service_limit_percentage / 100))
          }
          hour_value = Math.max(monetary_hour_value, hour_value_pct)
        }
        if (service.proposal_price_fixed < fixed_value || service.proposal_by_meters < m3_value || service.proposal_by_hours < hour_value) {
          return this.$notify({
            type: 'danger',
            message: `Este usuário não possui limite de desconto suficiente para realizar essa aprovação.`
          });
        }
      }
      let params = {
        uuid: service.uuid,
        type: type,
      }
      let type_situation = ''
      let description = {
        name: '',
        type: ''
      }
      switch (params.type) {
        case 1:
          type_situation = 'aprovada'
          description = {
            name: 'Aprovar',
            type: 'success'
          }
          break;
        case 0:
          type_situation = 'reprovada'
          description = {
            name: 'Reprovar',
            type: 'danger'
          }
          break;
        case 2:
          type_situation = 'em revisão'
          description = {
            name: 'Revisar',
            type: 'warning'
          }
          break;
      }
      if (service.situation && service.situation.situation == type) {
        return this.$notify({
          type: 'warning',
          message: `Situação da proposta já definida como ${type_situation}`
        });
      }
      this.$refs.ModalSituation.openModal(params, description)
    },
    handleUpdateSituation(situation) {
      if (!this.contract_proposal) {
        return
      }
      let index = this.contract_services.findIndex(item => item.uuid === situation.situation.service_uuid)
      this.contract_services[index].situation = situation.situation
      this.contract_services[index].situation_histories.unshift(situation.situation)
    },
    typeBetween(a, b) {
      if (Number(a) >= Number(b)) {
        return 'text-success'
      }
      if (Number(a) < Number(b)) {
        return 'text-danger'
      }
      return 'light'
    },
    handleRemoveFuture(uuid, indexService, indexFuture) {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.loading = true;
      this.$Progress.start();
      this.$store
        .dispatch(
          "contractService/destroyVersion",
          uuid)
        .then(response => {
          this.loading = false;
          this.$Progress.finish();
          this.contract_services[indexService].futures.splice(
            indexFuture, 1
          );
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        });
    },
    handleRemoveService(uuid, hasFutureServices) {
      if(hasFutureServices)
      {
        this.$notify({
          type: "danger",
          message: "Não é possível excluir, pois existe vigência futura cadastrada."
        });
      } else {
        this.$notify({
          type: "info",
          message: "Estamos trabalhando em sua solicitação."
        });
        this.loading = true;
        this.$Progress.start();
        this.$store
          .dispatch(
            "contractService/destroy",
            uuid)
          .then(response => {
            this.loading = false;
            this.$Progress.finish();
            this.init()
            this.$notify({
              type: response.error_type,
              message: response.message
            });
          })
          .catch((error) => {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
            this.$Progress.finish();
            this.loading = false;
          });
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    this.init()
  }
}
</script>
<style scoped>
.new-default-gray-font {
  color: #606062;
}

.card-wbs-danger {
  background-color: #f459592e;
}

.uncolor-img {
  filter: brightness(0);
}

.card-wbs-warning {
  background-color: #f4dc592e;
}

.card-wbs-success {
  background-color: #8bd5af52;
}

.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 1rem !important;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}

.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.colorize-on-hover:hover {
  background-color: #1b6eba;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
  filter: brightness(0) invert(1);
}

h4.fixed-height {
  min-height: 22px;
  height: 22px;
}

h5.fixed-height {
  min-height: 15px;
  height: 15px;
}
</style>
<style>
.border-3 {
  border-width: 4px !important;
}

.main-menu {
  flex-wrap: nowrap !important;
  display: flex;
  justify-content: space-between;
}

.rounded-circle {
  border: 1px solid #D3D3D3;
}

.background-color-circle {
  background-color: #f3f4fa;
}

.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}

.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.base-button:hover .text-dark {
  color: white !important;
}
</style>
