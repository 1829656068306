<template>
  <modal size="lg" :show.sync="isOpen" class="pb-5">
    <template slot="header">
      <h5 class="modal-title p-0 m-0">
        <img src="/img/icons/icons8/ios/menu-squared-2-white.png" height="25" class="mr-2" />
        ADICIONAR CATEGORIAS DE COMPRAS
      </h5>
    </template>
    <div class="p-3">
      <!-- INFORMAÇOES -->
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/info-squared-yellow.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3">IDENTIFICAÇÃO</span>
        <div class="gray-divider mb-2" />
      </div>

      <div class="form-group row mt-4 mb-1 mx-1 d-flex justify-content-between align-items-center info-content">
        <label class="mt-1">
          Nova categoria de compras
        </label>
        <div class="col-md-7 pr-0">
          <input type="text" v-model="newPurchaseCategory.description" maxlength="150"
            placeholder="CATEGORIA DE COMPRAS" class="form-control" rows="3" />
        </div>
        <div v-if="newPurchaseCategory.description" class="justify-content-end d-flex mt-4 w-100">
          <base-button size="sm" type="danger" @click="newPurchaseCategory.description = ''" style="width: 150px;">
            <img src="/img/icons/cancel-white.png" width="20px" height="20px" class="mr-1" />
            Cancelar
          </base-button>
          <div class="d-flex justify-content-end">
            <base-button size="sm" style="width: 150px;" type="success" @click="add()">
              <img src="/img/icons/save.png" width="20px" height="20px" class="mr-1" />
              Salvar
            </base-button>
          </div>

        </div>
      </div>
      <div class="gray-divider mb-3 mt-4" />
      <div class="d-flex align-items-center">
        <img class="mr-1" width="25" height="25" src="/img/icons/icons8/ios/hierarchy_danger.png" alt="info-squared" />
        <span style="font-size: 18px; color: #2B2D32; font-weight: 500" class="ml-2 mr-3 text-nowrap">CATEGORIAS
          CADASTRADOS</span>
        <div class="gray-divider mb-2" />
      </div>

      <div class="table-responsive mt-2" style="min-height: 280px;">
        <table class="table table-sm">
          <thead>
            <tr>
              <th class="text-center">Status</th>
              <th>Descrição</th>
              <th class="text-center" style="width: 2%;">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in categories" :key="index">
              <!-- Status -->
              <td class="text-center">
                <div @click="updateStatus(item)" class="status-button pointer"
                  :class="item.status ? 'active-status' : 'disabled-status'">
                  <div>
                    <img :src="item.status ? '/img/icons/ok--v1.png' : '/img/icons/icons8/ios/ok--v1_danger.png'"
                      class="mr-1" width="18">
                    {{ item.status ? 'ATIVO' : 'INATIVO' }}
                  </div>
                </div>
              </td>
              <!-- Descrição -->
              <td>
                <span style="font-size: 16px; font-weight: 400; color: #2B2D32;">
                  {{ item.description }}
                </span>
              </td>
              <!-- Ações -->
              <td class="text-center">
                <div class="pointer d-flex align-items-center justify-content-center">
                  <!-- EDITAR -->
                  <base-dropdown ref="dropdown" :closeOnClick="false" menuOnRight>
                    <div slot="title-container" class="dropdown-toggle rounded green-button px-3">
                      <img height="22px" src="/img/icons/create-new.png" />
                    </div>
                    <div class="p-3" style="min-width: 21rem !important; padding-bottom: 0.6rem !important;">
                      <div class="d-flex align-items-center mb-3">
                        <img width="26" src="/img/icons/hand-with-pen-primary.png" alt="hand-with-pen" />
                        <span class="text-nowrap">Editar categoria</span>
                      </div>
                      <base-input class="col-12 pr-1 mb-1 input-name-email-signatory" name="Name"
                        v-model="newDescription" :placeholder="item.description" :required="true" />
                      <div class="col-12 d-flex justify-content-end pr-0">
                        <base-button @click="updateDescription(item)" type="success" native-type="submit"
                          class="mt-2 align-self-end" size="sm">
                          <img width="22" src="/img/icons/save.png" alt="info-squared" class="mr-1" />
                          Salvar
                        </base-button>
                      </div>
                    </div>
                  </base-dropdown>
                  <!-- EXCLUIR -->
                  <img @click="destroy(item.id)" height="22px" src="/img/icons/icons8/ios/trash_can.png" class="ml-2" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!categories.length">
        <div class="text-center text-muted mt-3">Nenhuma categoria encontrada</div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="offset-8 col-4 px-2">
              <AppButton label="Fechar" type="primary" :hasOutline="true" :isBlock="true" @click.prevent="closeModal" />
            </div>
    </div>
  </modal>
</template>

<script setup>
//#region Imports
import { ref } from "vue";
import { dialogs } from "../../../../../../../../helpers";
import store from "@/store/index";
import PurchaseCategoryStatusEnum from "../../../enums/PurchaseCategoryStatusEnum";
import { AppIcon, AppButton } from "@/components/AppGlobal";
//#endregion

//#region Emits
const emit = defineEmits(['fetchCategories']);
//#endregion

//#region Refs
const isOpen = ref(false);
const newPurchaseCategory = ref({ description: '' });
const newDescription = ref('');
const categories = ref([]);
//#endregion

//#region Methods
function handleCreateModal() {
  listCategories();
}

function closeModal() {
  isOpen.value = false;
}

function listCategories() {
  store.dispatch('purchaseCategory/fetchItemsPaginate', { order_by: [{ column: 'purchase_categories.id' }] })
    .then((response) => {
      categories.value = response.data.items;
      isOpen.value = true;
    })
}

function add() {
  const existingCategory = categories.value.find(
    (category) => category.description.toLowerCase() === newPurchaseCategory.value.description.toLowerCase()
  );
  if (existingCategory) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Essa categoria já existe!');
    return;
  }

  store.dispatch('purchaseCategory/add', {
    description: newPurchaseCategory.value.description,
    status: 1,
  })
    .then(() => {
      listCategories();
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Categoria adicionada com sucesso!');
      emit('fetchCategories');
    });
}

function updateDescription(purchaseCategory) {
  const existingCategory = categories.value.find(
    (category) => category.description.toLowerCase() === newDescription.value.toLowerCase()
  );
  if (existingCategory) {
    dialogs.notify(dialogs.TYPE_ENUM.WARNING, 'Descrição já existe!');
    return;
  }

  purchaseCategory.description = newDescription.value;
  store.dispatch('purchaseCategory/update', purchaseCategory)
    .then(() => {
      listCategories();
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Descrição atualizada!');
      emit('fetchCategories');
    });
}

function updateStatus(purchaseCategory) {
  purchaseCategory.status = purchaseCategory.status ? PurchaseCategoryStatusEnum.keys.ACTIVE : PurchaseCategoryStatusEnum.keys.INACTIVE;
  store.dispatch('purchaseCategory/update', purchaseCategory)
    .then(() => {
      listCategories();
      dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Status atualizado!');
      emit('fetchCategories');
    });
}

async function destroy(id) {
  const isConfirmed = await dialogs.confirmAction(`Tem certeza que deseja excluir essa categoria?`);
  if (isConfirmed) {
    store.dispatch('purchaseCategory/destroy', id)
      .then(() => {
        listCategories();
        dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Categoria removida!');
        emit('fetchCategories');
      });
  }
}
//#endregion

defineExpose({
  handleCreateModal,
});
</script>
<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";

.input-group-sm > input, .input-group-text, input{
    box-shadow: none !important;
  }

* {
  font-family: Fredoka;
}

.card {
  border-radius: 10px !important;
  box-shadow: 0px 5px 10px 0px #0000000D;

  margin-bottom: 1.6rem !important;
}

.info-content {
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  padding: 8px 12px;
  margin-top: 1rem;
  padding: 1rem;
}

.status-button {
  padding: 2px 4px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  min-width: 8rem !important;
  justify-content: center;
}

.pending-status {
  background-color: #fdf2db;
  color: #F2B532;
}

.approved-status {
  background-color: #d2e3f1;
  color: #1A70B7;
}

.denied-status {
  background-color: #f8dad7;
  color: #DB4539;
}

.gray-divider {
  background-color: #2b2d32;
  opacity: 0.1;
  color: #2b2d32;
  margin: 0;
  margin-top: 9px;
  margin-bottom: 7px;
}

.table {
  $default-table-text-color: #2b2d32;
  border-collapse: separate;
  border-spacing: 2px 2px;

  th,
  td {
    box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    -webkit-box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15) !important;
    color: $default-table-text-color;
    vertical-align: middle;
    border-radius: 5px !important;
    font-size: 12px !important;

  }

  th {
    padding-left: 8px;
    padding-right: 8px;
    background: #cccccc !important;
    font-family: Fredoka !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  th:first-of-type,
  td:last-of-type {
    width: 5% !important;
  }

  td {
    font-weight: 400 !important;
    font-family: Fredoka !important;
    font-size: 8px !important;
    padding-left: 8px;
    padding-right: 8px;
    background-color: white;

    span {
      font-size: 12.35px !important;
    }


    &.p-16 {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }

  .limit-text {
    display: inline-block;
    max-width: 30ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}



td>div {
  font-size: 12px !important;
}



.active-status {
  background-color: #f2f7f3;
  color: #149E57;
}

.disabled-status {
  background-color: #fcf3f3;
  color: #DB4539;
}
</style>
