/**
 * Type para Incluir/Editar/Visualizar permissões de compra do usuário
 *
 * @typedef {Object} UserPurchasePermissionType
 * @property {number} user_id - Categorias que o usuário pode visualizar.
 * @property {string[]} view_categories - Categorias que o usuário pode visualizar.
 * @property {string[]} edit_categories - Categorias que o usuário pode editar.
 * @property {string[]} order_permission - Permissões de pedidos do usuário.
 * @property {boolean} view_all_categories - Indica se pode visualizar todas as categorias.
 * @property {boolean} edit_all_categories - Indica se pode editar todas as categorias.
 * @property {boolean} purchase_request_approve - Indica se pode aprovar solicitações de compra.
 * @property {boolean} purchase_request_view_own - Indica se pode visualizar suas próprias solicitações de compra.
 * @property {boolean} purchase_category_edit - Indica se pode editar categorias de compra.
 * @property {boolean} purchase_budget_view_own - Indica se pode visualizar seus próprios pedidos de compra.
 * @property {boolean} purchase_order_approve - Indica se pode aprovar pedidos de compra.
 * @property {boolean} purchase_order_cancel - Indica se pode cancelar pedidos de compra.
 * @property {boolean} purchase_order_complete - Indica se pode completar pedidos de compra.
 * @property {boolean} purchase_order_denied - Indica se pode negar pedidos de compra.
 * @property {boolean} purchase_order_resume - Indica se pode retomar pedidos de compra.
 * @property {boolean} limit_order_approval_value - Indica se há um limite para aprovação de pedidos.
 * @property {number} max_approval_value - Valor máximo de aprovação de pedidos.
 * @property {Array<{ purchase_category_id: number, can_approve: boolean }>} categories - Lista de categorias com permissão de aprovação.
 */
export const UserPurchasePermissionType = {};

/**
 * Inicializar UserPurchasePermissionType
 * 
 * @returns {UserPurchasePermissionType}
 */
export const initUserPurchasePermissionType = () => {
    return {
        user_id: null,
        //Inicializei como zero por no codigo antigo sempre era atribuido esse valor.
        view_categories: [0],
        //Inicializei como zero por no codigo antigo sempre era atribuido esse valor.
        edit_categories: [0],
        order_permission: [],
        view_all_categories: false,
        edit_all_categories: false,
        purchase_request_approve: false,
        purchase_request_view_own: false,
        purchase_category_edit: false,
        purchase_budget_view_own: false,
        purchase_order_approve: false,
        purchase_order_cancel: false,
        purchase_order_complete: false,
        purchase_order_denied: false,
        purchase_order_resume: false,
        limit_order_approval_value: false,
        max_approval_value: 50000.00,
        categories: [],
    };
};
