import { PagerType, initPagerType } from "../../../../shared/types";
import { SyncGeneralType } from "./SyncGeneralType";

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<SyncGeneralType> } )} SyncGeneralListPagerType
 */
export const SyncGeneralListPagerType = {};

/**
 * Inicializar SyncGeneralListPagerType
 *
 * @returns {SyncGeneralListPagerType}
 */
export const initSyncGeneralListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  };
};
