import * as types from "./mutation_types";
import {
  destroy,
  update,
  cursorPaginate,
  setUrlWithNextPage,
} from "@/store/baseStore";
import { createAxios } from "@/plugins/axios";
const endPoint = "/commercial/service/price";
export default {
  namespaced: true,
  state: {
    items: [],
    item: [],
  },
  getters: {
    show: (state) => state.item,
    fetch: (state) => state.items,
  },
  mutations: {
    [types.SET](state, payload) {
      state.items = payload;
    },
    [types.PUSH](state, payload) {
      state.items.push(payload);
    },
    [types.UPDATE](state, payload) {
      update(state, payload);
    },
    [types.SHOW](state, payload) {
      state.item = payload;
    },
    [types.DESTROY](state, id) {
      destroy(state, id);
    },
  },
  actions: {
    fetch({ commit, state }, params) {
      return createAxios()
        .get(setUrlWithNextPage(endPoint, params), { params: params })
        .then(({ data }) => {
          cursorPaginate(state, {
            data: data.data,
            next_page: params.next_page,
          });
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    add({ commit, state }, payload) {
      return createAxios()
        .post(endPoint, payload)
        .then(({ data }) => {
          commit(types.PUSH, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    update({ commit }, payload) {
      return createAxios()
        .put(`${endPoint}${payload.id}`, payload)
        .then(({ data }) => {
          commit(types.UPDATE, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    show({ commit, dispatch }, id) {
      return createAxios()
        .get(endPoint + id)
        .then(({ data }) => {
          commit(types.SHOW, data.data);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    destroy({ commit, dispatch }, id) {
      return createAxios()
        .delete(endPoint + id)
        .then(({ data }) => {
          commit(types.DESTROY, id);
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
    updateStatus({}, payload) {
      return createAxios()
        .put(`${endPoint}/update-status/${payload.uuid}/${payload.status}`)
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
};
