<template>
  <div>
    <base-header class="bg-gray-content">
      <!-- Header Menus -->
      <div class="row align-items-center py-4">
        <div class="col">
          <h2 style="font-size: 1.2rem !important" class="text-danger mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/login-rounded.png" class="mr-3" />
            Lançamento de despesa
          </h2>
        </div>
        <div v-show="entry_launch.payment_method_id && [1, 4].includes(entry_launch.payment_method_id)" class="col-md-2 mb-2">
          <base-button @click.prevent="handleEntityAccount" block type="success" class="text-uppercase">
            <i class="fas fa-plus" /> CONTA
          </base-button>
        </div>
        <div class="col-md-2 mb-2">
          <back-button :disabled="false" />
        </div>
      </div>
    </base-header>

    <div class="container-fluid">
      <div>
        <card>
          <!-- Navegação -->
          <Navegation
            v-if="!loadingEntity"
            :entity_name="entity.entity_name"
            :entity_document="entity.document"
            :document_number="entry_launch.document_number"
            :series="entry_launch.series"
            :tab_value="tab_value"
            @input="tab_value = $event; selected_items = null;"
            :disabledPayment="bill_pay_installments.length < 1 || entry_launch.total_value <= 0 || dontHasFinancial"
          />

          <!-- Aba documento -->
          <div class="mt-4" v-show="tab_value === 'document'">
            <!-- Identificação -->
            <div class="row">
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                    IDENTIFICAÇÃO
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Central de entrada -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de Entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="
                          getStockLocations();
                          getEquipments();
                        "
                        v-model="entry_launch.entry_company_plant_id"
                        :items="company_plants"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Central de custo -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de custo
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect v-model="entry_launch.company_plant_id" :items="company_plants" :clearable="false" />
                    </div>
                  </div>

                  <!-- CNPJ Comprador -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      CNPJ Comprador
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6" v-if="entry_launch.default_buyer_ein">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          inputmode="numeric"
                          :value="entry_launch.default_buyer_ein.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                  </div>

                  <!-- Documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.document_template_id"
                        :items="formattedDocumentTemplates"
                        label="label"
                        :clearable="false"
                        :loading="loadingDocumentTemplate"
                        :disabled="entry_launch.document_template_id !== null"
                      />
                    </div>
                  </div>

                  <!-- Tipo de itens -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Tipo de itens
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        :disabled="
                          loadingProductServiceHubType ||
                          has_internal_item === 1 ||
                          loadingItems ||
                          entry_launch.product_service_hub_type_id === 4
                        "
                        v-model="entry_launch.product_service_hub_type_id"
                        :items="[{ id: 2, name: 'MCC' }, ...filtered_product_service_hub_types]"
                        :loading="loadingProductServiceHubType"
                        @input="changedProductServiceHubType"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>

                <!-- Operação -->
                <div class="card py-4 px-3">
                  <div class="row">
                    <div class="col-6">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                        <img height="30px" src="/img/icons/icons8/ios/upload-link-document_primary.png" class="mr-3" />
                        Operação
                      </h3>
                    </div>
                    <div v-if="operation_source" class="col-6 d-flex justify-content-end">
                      <base-button
                        v-if="operation_source.is_entry_value"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/working-mom_success.png" class="mr-2" />
                        <span class="fs-12">Nota mãe </span>
                      </base-button>
                      <base-button
                        v-else-if="!operation_source.is_entry_value && !operation_source.is_generate_financial"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/women-age-group-2_success.png" class="mr-2" />
                        <span class="fs-12">Nota filha </span>
                      </base-button>
                      <base-button
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="danger"
                        v-if="operation_source.is_generate_financial"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/duration-finance--v1_danger.png" class="mr-2" />
                        Financeiro
                      </base-button>
                      <base-button
                        v-if="operation_source.is_generate_stock"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="primary"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/open-box.png" class="mr-2" />
                        Estoque
                      </base-button>
                    </div>
                  </div>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                  <!-- Natureza de operação -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Natureza de operação
                      <img role="button" v-if="entry_launch.external_type === 1" class="ml-2" @click="copyAccessKey(entry_launch.access_key)" src="/img/icons/icons8/ios/copy-2_primary.png" width="22px" height="22px" />
                    </label>
                    <div class="col-md-6">
                      <input disabled maxlength="30" v-model="entry_launch.nature_operation" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Tipo de operação puzl -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font d-flex align-items-center mb-2 mb-md-0">
                      Tipo de operação puzl
                      <span class="text-danger">&nbsp;*</span>
                      <base-button
                        class="text-uppercase ml-2"
                        size="sm"
                        type="success"
                        @click.prevent="handleModalOperationSourceCreate"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id || loadingDefaultOperationSource"
                      >
                        <i class="fas fa-plus"></i> Novo
                      </base-button>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="checkFinancial(true)"
                        v-model="entry_launch.operation_source_id"
                        :items="$_operation_sources"
                        :loading="loadingOperationSource"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id || loadingDefaultOperationSource"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Nota filha -->
                  <div v-if="!entry_launch.is_entry_value && operation_source && !operation_source.is_generate_financial" class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center"> Nota mãe </label>
                    <div class="col-md-6">
                      <PuzlSelect label="document_number" v-model="entry_launch.parent_id" :items="$_parents" :disabled="loadingParents" />
                    </div>
                  </div>
                  <!-- Nota mãe, desabilitado por hora. -->
                  <!-- <div
                    v-else-if="operation_source && operation_source.is_entry_value && operation_source.is_generate_financial"
                    class="row mt-3"
                  >
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Notas filhas
                      <div class="col-5">
                        <base-button-hoverable @click="handleModalSearchChildren(entry_launch.id)" size="sm" type="success" icon="plus--v1" platform="ios"> Incluir </base-button-hoverable>
                      </div>
                    </label>
                    <div v-if="!refreshLoading" class="col-md-6">
                      <div class="row">
                        <label v-if="entry_launch.children_balance" class="col-6 new-default-black-font fs-12">
                          {{ entry_launch.children_balance | currency }}
                        </label>
                         <label v-else class="col-6 new-default-black-font fs-12">
                          {{ entry_launch.total_value | currency }}
                        </label>
                        <label class="col-6 new-default-black-font text-right fs-12">
                          {{ entry_launch.total_value | currency }} Saldo
                        </label>
                      </div>
                      <base-progress type="success" :value="getChildrenPercentage"></base-progress>
                    </div>
                  </div> -->
                </div>
              </div>

              <!-- Dados -->
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/data-arrived_primary.png" class="mr-3" />
                    DADOS
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Contabilidade -->
                  <div v-if="entry_launch.external_type === 0" class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Contabilidade
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <base-switch v-model="entry_launch.accounting" type="success" class="success ml-2" offText="Não" onText="Sim" />
                    </div>
                  </div>

                  <!-- Data de entrada -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker :disableMaxDate="max_date" :mode="'dateTime'" :size="'lg'" v-model="entry_launch.entry_date" />
                    </div>
                  </div>

                  <!-- Data de emissão -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de emissão
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker disabled :mode="'date'" v-model="entry_launch.issue_date" />
                    </div>
                  </div>

                  <!-- Nº do documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Nº do documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <InputLimit
                        disabled
                        :start_value="entry_launch.document_number"
                        :options="{
                          min: 0,
                        }"
                        max_length="15"
                        input_group_classes="input-group-sm pb-0"
                        v-model="entry_launch.document_number"
                        @value="entry_launch.document_number = $event"
                      />
                    </div>
                  </div>

                  <!-- Série -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Série
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <input disabled maxlength="30" v-model="entry_launch.series" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Observações -->
                  <div class="row mt-2">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center"> Observações </label>
                    <div class="col-12">
                      <textarea v-model="entry_launch.observations" type="text" rows="3" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Foto do documento -->
                  <div class="row mt-3">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center">
                      Foto do documento
                      <span class="text-danger"> {{ entry_launch.accounting ? "&nbsp;*" : "" }}</span>
                    </label>
                    <div class="col-12">
                      <InputFile :multiple="false" ref="inputEntryLaunchFile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- Aba itens -->
            <div class="mt-4" v-show="tab_value === 'items'">
              <div class="row m-0">
                <!-- Salvar -->
                <div
                  class="col-md-2 pl-md-1 pr-md-1"
                  v-if="has_external_item && !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id)"
                >
                  <base-button-hoverable
                    @click="handleModalUpdateManyProductServiceHub"
                    size="sm"
                    type="success"
                    icon="plus--v1"
                    platform="ios"
                  >
                    Salvar todos
                  </base-button-hoverable>
                </div>
                <!-- Selecionar -->
                <div v-if="!has_external_item" class="col-md-2 pl-md-1 pr-md-1">
                  <base-button-hoverable
                    @click="setSelectedItems"
                    :active="selected_items"
                    size="sm"
                    type="primary"
                    icon="choose"
                    platform="ios"
                  >
                    Selecionar
                  </base-button-hoverable>
                </div>

                <!-- Selecionar todos -->
                <div v-if="selected_items" class="col-md-2 pl-md-1 pr-md-1">
                  <base-button-hoverable @click="addAllToSelectedItems" size="sm" type="primary" icon="hand-drag" platform="ios">
                    Selecionar todos
                  </base-button-hoverable>
                </div>

                <!-- Desmarcar todos -->
                <div v-show="selected_items" class="col-md-2 pl-md-1 pr-md-1">
                  <base-button-hoverable @click="removeAllFromSelectedItems" size="sm" type="light" icon="undo" platform="ios">
                    Desmarcar todos
                  </base-button-hoverable>
                </div>
              </div>

              <!-- Itens -->
              <div class="mt-4">
                <PuzlEmptyData v-show="!items.length" />
                <div class="row card-wrapper" v-if="items.length">
                  <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                    <card>
                      <div class="row align-items-center">
                        <EntryLaunchItemStockStatusLabel
                          v-if="!item.external_type"
                          @onChange="item.stock_status = $event"
                          :stockStatus="item.stock_status"
                          :productServiceHubTypeId="entry_launch.product_service_hub_type_id"
                          :operationSourceIsGenerateStock="operation_source && operation_source.is_generate_stock ? true : false"
                        />
                        <div v-else class="col-auto">
                          <span
                            class="badge font-weight-400 text-warning fs-14 py-2 px-4 d-flex align-items-center justify-content-center"
                            style="background-color: #fdf3df"
                          >
                            <img height="18px" src="/img/icons/icons8/ios/open-box_warning.png" class="mr-1" />
                            Sem Cadastro
                          </span>
                        </div>
                        <el-popover v-if="item.code" class="col-auto" placement="right" trigger="hover">
                          <h4 class="new-default-black-font">Código do fornecedor</h4>
                          <h5 class="mb-0 new-default-black-font">
                            Código : <span class="font-weight-normal"> {{ item.code }} </span>
                          </h5>
                          <span role="button" slot="reference">
                            <img src="/img/icons/info.png" width="26px" class="float-right" />
                          </span>
                        </el-popover>

                        <!-- Impostos -->
                        <div v-if="['Produtos e Serviços', 'MCC'].includes(entry_launch_types[entry_launch.product_service_hub_type_id])" class="col-auto px-1">
                          <span @click="handleOpenModalTaxes(item, index)" role="button">
                            <img height="27px" src="/img/icons/icons8/ios/general-ledger_default.png"/>
                          </span>
                        </div>
                        <div v-if="!selected_items" class="col text-right pl-0">
                          <base-dropdown menuOnRight>
                            <div slot="title-container" class="dropdown-toggle rounded m-0">
                              <img width="32" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                            </div>
                            <a
                              v-show="
                                item.external_type === 0 &&
                                entry_launch.entry_company_plant_id &&
                                [1, 3, 7].includes(entry_launch.product_service_hub_type_id)
                              "
                              @click="handleModalEquipmentApportionment(item, index)"
                              class="dropdown-item"
                            >
                              <img height="20px" src="/img/icons/icons8/ios/split_success.png" class="mr-3 ml-n1" />
                              Rateio
                            </a>
                            <a
                              v-if="
                                item.external_type === 1 &&
                                !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id)
                              "
                              @click.prevent="handleModalCreateProductServiceHub(item)"
                              class="dropdown-item d-flex align-items-center"
                            >
                              <img height="25px" src="/img/icons/icons8/ios/save_success.png" class="main-icon-menu ml-n1 mr-3" />
                              <span>Salvar </span>
                            </a>
                            <a
                              v-if="
                                !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id) &&
                                item.external_type === 1
                              "
                              @click.prevent="handleModalSearchProductServiceHub(index)"
                              class="dropdown-item d-flex align-items-center"
                            >
                              <i class="fas fa-search fa-2x main-icon-menu text-primary"></i>
                              <span>Associar</span>
                            </a>
                            <a
                              v-if="
                                item.external_type === 0 &&
                                !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id)
                              "
                              @click.prevent="handleModalSearchProductServiceHub(index)"
                              class="dropdown-item d-flex align-items-center"
                            >
                              <i class="fas fa-exchange-alt text-primary main-icon-menu"></i>
                              Reassociar
                            </a>
                          </base-dropdown>
                        </div>
                        <div v-else class="col text-right pl-0">
                          <base-button
                            style="padding: 0.32rem 0.5rem !important"
                            v-if="selected_items.includes(index)"
                            @click.prevent="removeFromSelectedItems(index)"
                            class="float-right"
                            type="success"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                          <base-button
                            style="padding: 0.32rem 0.5rem !important; background-color: white"
                            v-else
                            @click.prevent="addToSelectedItems(index)"
                            class="float-right"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                        </div>
                        <div class="col-12 justify-content-center pr-0 mt-2">
                          <h3 class="new-default-black-font mb-0 fs-16 font-weight-500">
                            {{ item.product_service_hub.product_name }}
                          </h3>
                          <div v-if="item.product_service_hub.product_service_hub_type_id === 2">
                            <h3 class="mb-0 new-default-black-font fs-16 font-weight-400">
                              {{ item.product_service_hub.supplier_name }}
                            </h3>
                            <h3
                              v-if="item.product_service_hub && item.product_service_hub.origin_name"
                              class="mb-1 new-default-black-font fs-16 font-weight-400"
                            >
                              ({{ item.product_service_hub.origin_name }})
                            </h3>
                          </div>
                        </div>
                        <div class="row col-12 p-0 m-0 mt-3">
                          <div class="col-4">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Quantidade</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">
                              {{ item.quantity }} {{ item.product_service_hub.purchase_unit }}
                            </h5>
                          </div>
                          <div class="col-4 p-0">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor unitário</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">
                              {{ toMoneyWithThreeDecimals(item.price) }} / {{ item.product_service_hub.purchase_unit }}
                            </h5>
                          </div>
                          <div class="col-4 text-right">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor final</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">{{ item.total_value | currency }}</h5>
                          </div>
                          <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mx-3 mt-3 mb-1 col" />
                        </div>
                      </div>
                      <div>
                        <div
                          v-show="!has_external_item && [ProductServiceHubTypeEnum.keys.CMC, ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE].includes(entry_launch.product_service_hub_type_id) && operation_source && operation_source.is_generate_stock"
                          class="w-100 mt-3"
                        >
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'stock_location_id', item.product_service_hub.is_fuel)"
                              v-if="entry_launch.product_service_hub_type_id !== 2"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="item.product_service_hub.is_fuel ? fuel_tank_stock_locations : stock_locations"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                            <puzl-select
                              v-else
                              @input="applyToAllItems($event, 'stock_location_id')"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="getStockLocationsByCmcCategory(item.cmc_category_id ? item.cmc_category_id :  item.product_service_hub.cmc_category_id)"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                          </base-input>
                        </div>
                        <div class="w-100 mt-2">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'chart_account_id')"
                              placeholder="Plano de Contas"
                              v-model="item.chart_account_id"
                              :items="$_chart_accounts"
                              :clearable="false"
                              :labelMask="true"
                              label="$full_code$ $name$"
                              :selectable="(i) => i.extra_field !== null"
                              :disabled="loadingChartAccount ||  ! entry_launch.operation_source_id"
                              extraField="chart_account_parent_uuid"
                            />
                          </base-input>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>

            <!-- Frete -->
            <div v-show="tab_value === 'transport'">
              <div class="row">
                <div class="col-md-6">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/in-transit_primary.png" class="mr-3" />
                    Transporte
                  </h3>
                </div>
                <div class="col-md-6">
                  <base-button-hoverable v-if="freight && freight.observations" size="sm" type="success" icon="plus--v1" platform="ios">
                  </base-button-hoverable>
                </div>
                <div class="col-12">
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                </div>
              </div>
              <div
                v-if="
                  entry_launch.product_service_hub_type_id &&
                  (entry_launch.product_service_hub_type_id === 2 || entry_launch.product_service_hub_type_id === 3) &&
                  entry_launch.company_plant_buyer_id &&
                  entry_launch.company_plant_id
                "
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="card py-4 px-3">
                      <div class="row m-0">
                        <div class="col-6">
                          <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                            Transportadora
                            <base-button
                              v-if="freight.freight_modality_id"
                              @click.prevent="getFreight"
                              size="sm"
                              type="primary"
                              class="text-uppercase ml-2"
                            >
                              <i class="fas fa-search" />
                            </base-button>
                          </h3>
                        </div>
                        <div class="col-6 d-flex justify-content-end">
                          <div v-if="['MCC' , 'Produtos e Serviços'].includes(entry_launch_types[entry_launch.product_service_hub_type_id]) && !freight.entity_id" class="col-6 p-0">
                            <base-button-hoverable
                              @click="handleModalSearchEntryLaunch"
                              size="sm"
                              type="success"
                              icon="plus--v1"
                              platform="ios"
                            >
                              Incluir
                            </base-button-hoverable>
                          </div>
                          <div v-if="freight.entity_id" class="col-6 p-0 ml-2">
                            <base-button-hoverable
                              @click="handleFreightDelete(entry_launch.id)"
                              size="sm"
                              type="danger"
                              icon="delete"
                              platform="ios"
                            >
                              Excluir
                            </base-button-hoverable>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                      </div>
                      <div v-if="freight.freight_modality_id" class="border-left border-indigo border-3 mx-3 mt-1">
                        <h4 class="mb-0 col-12 pl-2">{{ freight_entity.entity_name }}</h4>
                        <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ freight_entity.document }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="px-4">
                      <div class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label"> Frete </label>
                        <div class="col-6 pr-md-0">
                          <el-select
                            @change="getFreight"
                            v-model="freight.freight_modality_id"
                            size="mini"
                            placeholder="Selecione"
                            filterable
                            class="w-100"
                            disabled
                          >
                            <el-option
                              v-for="freight_modality in freight_modalities"
                              :key="freight_modality.id"
                              :label="`${freight_modality.code} - ${freight_modality.name}`"
                              :value="freight_modality.id"
                            >
                            </el-option>
                            <el-option :value="null" label="9 - Sem frete"> </el-option>
                          </el-select>
                        </div>
                      </div>
                      <div v-if="freight.freight_modality_id">
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label">
                            Quantidade <span class="text-danger">&nbsp;*</span></label
                          >
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  inputmode="numeric"
                                  v-on:input="freight.quantity = formatString($event.target.value, '0', '99999999')"
                                  v-model="freight.quantity"
                                  class="form-control form-control-sm"
                                />
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label">
                            Valor do frete <span class="text-danger">&nbsp;*</span></label
                          >
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <input
                                  :disabled="entry_launch.external_type === 1"
                                  v-money="money"
                                  v-model.lazy="freight.total_value"
                                  maxlength="10"
                                  inputmode="numeric"
                                  class="form-control form-control-sm"
                                />
                                <template slot="prepend">
                                  <small> R$ </small>
                                </template>
                              </base-input>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-6 mb-1 col-form-label form-control-label"> Foto do frete </label>
                          <div class="col-6 pr-md-0">
                            <div class="w-100 pr-md-0">
                              <base-input input-group-classes="input-group-sm">
                                <InputFile :multiple="false" ref="inputFreightFile" />
                              </base-input>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Frete pendente -->
                      <div v-else class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label"> Frete pendente </label>
                        <div class="col-6 pr-md-0 d-flex align-items-center justify-content-end">
                          <base-input input-classes="form-control-sm">
                            <base-switch
                              v-model="entry_launch.pending_freight"
                              type="success"
                              offText="Não"
                              onText="Sim"
                              :disabled="entry_launch.product_service_hub_type_id === 4"
                            >
                            </base-switch>
                          </base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Motorista -->
              <div class="row">
                <div class="col-md-6">
                  <div class="card py-3 px-3">
                    <div class="row m-0">
                      <label class="col-6 mb-1 mb-md-0 col-form-label form-control-label"> Motorista (Insumos) </label>
                      <div class="col-md-6 d-flex align-items-center w-100">
                        <div class="w-100">
                          <PuzlSelect v-model="entry_launch.driver_id" :items="$_drivers" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Pagamento -->
            <div v-show="tab_value === 'payment'">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                      <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                      IDENTIFICAÇÃO
                    </h3>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                    <!-- Conta Bancária -->
                    <div class="row">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Conta Bancária <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingBankAccount"
                          v-model="entry_launch.bank_account_id"
                          @change="$forceUpdate()"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option
                            v-for="bank_account in bank_accounts"
                            :key="bank_account.bank_account_id"
                            :label="bank_account.bank_account.name"
                            :value="bank_account.bank_account_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Método de pagamento -->
                    <div class="row mt-2">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Método de pagamento <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingPaymentMethod"
                          v-model="entry_launch.payment_method_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option v-for="method in $_payment_methods" :key="method.id" :label="method.name" :value="method.id">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Valor total da nota -->
                    <div class="row mt-4">
                      <h3 class="col-6">Valor total da nota</h3>
                      <h3 class="col-6 text-right">{{ entry_launch.total_value | currency() }}</h3>
                    </div>

                    <!-- Valor final -->
                    <div class="row mt-2">
                      <h3 class="col-6 text-primary">Valor final</h3>
                      <h3 class="col-6 text-right text-primary">{{ entry_launch.final_value | currency() }}</h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <div class="row">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center col-md-6">
                        <img height="30px" src="/img/icons/icons8/ios/how-many-quest--v2_success.png" class="mr-3" />
                        PARCELAS
                      </h3>
                      <div class="col-md-3" />
                      <div class="col-md-3">
                        <input
                          @change="changeInstallments"
                          v-on:input="installment_quantity = formatString($event.target.value, '0', '99')"
                          v-model="installment_quantity"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                    <Installments
                      @applyDueDate="applyDueDateToAllInstallments"
                      ref="installments"
                      @getFinalValue="getFinalValue"
                      :items="bill_pay_installments"
                      :entry_launch="entry_launch"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="entry_launch.product_service_hub_type_id && entry_launch.company_plant_buyer_id">
            <base-button
              class="col-2"
              type="primary"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 3.4rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="goToNextTab"
              v-if="!getNextTabButtonStatus()"
            >
              <img height="30px" src="/img/icons/icons8/ios/right-squared.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">PRÓXIMO</span>
            </base-button>
            <base-button
              class="col-2"
              v-else
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 50px !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="store"
            >
              <img height="30px" src="/img/icons/save.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">SALVAR</span>
            </base-button>
          </div>
          <div v-if="selected_items">
            <base-button
              class="col-auto"
              type="warning"
              :disabled="!selected_items.length"
              style="bottom: 195px !important; right: 2.95rem !important; position: fixed; z-index: 9999;"
              @click.prevent="handleModalSetChartAccount"
            >
              <img height="30px" src="/img/icons/icons8/ios/accounting.png" class="mr-3" />
              <span style="font-size: 0.75rem">PLANO DE CONTAS</span>
            </base-button>
            <base-button
              class="col-2"
              :disabled="
                !entry_launch.entry_company_plant_id ||
                ![1, 3, 7].includes(entry_launch.product_service_hub_type_id) ||
                !selected_items.length
              "
              type="success"
              style="bottom: 265px !important; right: 3rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="handleModalSetEquipmentApportionment"
            >
              <img height="30px" src="/img/icons/icons8/ios/split.png" class="mr-3" />
              <span style="font-size: 0.75rem">RATEIO</span>
            </base-button>
            <base-button
              class="col-auto"
              :disabled="entry_launch.product_service_hub_type_id !== ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE || !selected_items.length || !operation_source || (operation_source && !operation_source.is_generate_stock)"
              type="primary"
              style="bottom: 330px !important; right: 3.5rem !important; position: fixed; z-index: 9999;"
              @click.prevent="handleModalSetStockLocation"
            >
              <img height="30px" src="/img/icons/open-box-white.png" class="mr-3" />
              <span style="font-size: 0.75rem">LOCAL DE ESTOQUE</span>
            </base-button>
          </div>
        </card>
      </div>
    </div>

    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalCreateProductServiceHub @created="associateProductServiceHub" ref="modalCreateProductServiceHub" />
    <ModalUpdateManyProductServiceHub @updated="getEntryLaunchItems" ref="modalUpdateManyProductServiceHub" />
    <ModalSearchProductServiceHub @selected="associatedItem" ref="modalSearchProductServiceHub" />
    <ModalEditProductServiceHub @updated="getEntryLaunchItems" ref="modalEditProductServiceHub" />
    <ModalEntityAccount ref="entityAccount" />
    <ModalEquipmentApportionment @added="setEquipmentApportionments" ref="modalEquipmentApportionment" />
    <ModalSetChartAccount @store="applyToSelectedItems($event, 'chart_account_id')" ref="modalSetChartAccount" />
    <ModalSetEquipmentApportionment
      @store="applyToSelectedItems($event, 'equipment_apportionments')"
      ref="modalSetEquipmentApportionment"
    />
    <ModalSearchEntryLaunch :must_save="false" @selected="storeSelectedFreight" ref="modalSearchEntryLaunch" />
    <ModalSetStockLocation
      :items="stock_locations"
      @store="applyToSelectedItems($event, 'stock_location_id')"
      @storeStockStatus="applyToSelectedItems($event, 'stock_status')"
      :enableStockStatus="operation_source && operation_source.is_generate_stock"
      ref="modalSetStockLocation"
    />
    <ModalSearchChildren @updated="refreshEntryLaunch" ref="modalSearchChildren" />
    <ModalOperationSourceCreate @created="getOperationSources" ref="modalOperationSourceCreate" />
    <ModalTaxes :disabled="true" ref="modalTaxes" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { mapGetters } from "vuex";
import BackButton from "@/components/Utils/BackButtonV2";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import { checkNumberValue, moneyToFloat } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalSearchEntity from "../../Entity/Shared/_ModalSearchEntity";
import { VMoney } from "v-money";
import ModalCreateProductServiceHub from "../../ProductService/Shared/_ModalCreate.vue";
import ModalUpdateManyProductServiceHub from "../../ProductService/Shared/_ModalUpdateMany.vue";
import ModalSearchProductServiceHub from "../../ProductService/Shared/_ModalSearchProductServiceHub.vue";
import billPayMixin from "../../../Financial/BillPay/Mixins/bill_pay_mixin";
import ModalEditProductServiceHub from "../../ProductService/Shared/_ModalEdit.vue";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import ModalEntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEquipmentApportionment from "../Item/_ModalEquipmentApportionment.vue";
import Installments from "../Shared/_Installments";
import ModalSetChartAccount from "../Shared/_ModalSetChartAccount";
import ModalSetEquipmentApportionment from "../Shared/_ModalSetEquipmentApportionment";
import ModalSetStockLocation from "../Shared/_ModalSetStockLocation";
import ModalSearchEntryLaunch from "../Shared/_ModalSearchEntryLaunch";
import Navegation from "../Shared/_Navegation";
import ModalSearchChildren from "../Shared/_ModalSearchChildren";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import InputLimit from "@/components/Utils/InputLimit";
import ModalOperationSourceCreate from "@/views/Modules/Fiscal/OperationSource/Shared/_ModalCreate";
import ModalTaxes from "../../../../../modules/configuration/entry-launch/aggregates/entry-launch-item-tax/views/shared/ModalTaxes.vue";
import EntryLaunchItemStockStatusLabel from "../../../../../modules/configuration/entry-launch/views/shared/EntryLaunchItemStockStatusLabel.vue";
import { useEntryLaunchStockHook } from "../../../../../modules/configuration/entry-launch/hooks/useEntryLaunchStockHook";
import { EntryLaunchItemStockStatusEnum } from "../../../../../modules/configuration/entry-launch/enums/EntryLaunchItemStockStatusEnum";
import ProductServiceHubTypeEnum from "../../../../../modules/configuration/product-service-hub-type/enums/ProductServiceHubTypeEnum";

export default {
  name: "CreateExternalEntryLaunch",
  mixins: [billPayMixin, entryLaunchMixin],
  components: {
    BackButton,
    PuzlBreadcrumb,
    InputDateTimePicker,
    InputFile,
    PuzlEmptyData,
    SkeletonPuzlFullWidth,
    ModalSearchEntity,
    ModalCreateProductServiceHub,
    ModalSearchProductServiceHub,
    ModalUpdateManyProductServiceHub,
    ModalEditProductServiceHub,
    ModalEntityAccount,
    PuzlSelect,
    ModalEquipmentApportionment,
    Installments,
    ModalSetChartAccount,
    ModalSetEquipmentApportionment,
    ModalSearchEntryLaunch,
    ModalSetStockLocation,
    Navegation,
    BaseButtonHoverable,
    InputLimit,
    ModalSearchChildren,
    ModalOperationSourceCreate,
    ModalTaxes,
    EntryLaunchItemStockStatusLabel,
  },
  data() {
    return {
      tab: 0,
      entry_launch: {
        status: 1,
        product_service_hub_type_id: null,
        company_plant_id: null,
        entry_date: null,
        imported_at: null,
        issue_date: null,
        document_template_id: null,
        observations: null,
        final_value: 0,
        total_value: 0,
        discount: 0,
        series: null,
        nature_operation: null,
        payment_method_id: null,
        external_type: 1,
        access_key: null,
        bank_account_id: null,
        accounting: false,
        children_balance: 0,
        driver_id: null,
      },
      entity: {},
      loadingStore: false,
      loadingCompanyPlant: true,
      loadingDocumentTemplate: true,
      loadingEntity: true,
      loadingCompanyPlantBuyer: true,
      loadingProductServiceHubType: true,
      loadingCostCenter: true,
      loadingPaymentMethod: true,
      loadingItems: true,
      loadingBankAccount: true,
      loadingDefaultOperationSource: true,
      loadingParents: true,
      company_plant_buyers: [],
      items: [],
      freight: {
        total_value: 0,
        photo: null,
        quantity: null,
        gross_weight: null,
        net_weight: null,
        plate: null,
        model: null,
        species: null,
        entity_id: null,
        freight_modality_id: null,
      },
      bill_pay_installments: [],
      bank_accounts: [],
      installment_quantity: null,
      freight_entity: null,
      has_internal_item: false,
      has_external_item: true,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      refreshLoading: false,
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
      $_document_templates: "documentTemplate/fetch",
      $_product_service_hub_types: "productServiceHubType/fetch",
      $_cost_centers: "costCenter/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_chart_accounts: "managerialChartAccount/fetch",
      $_operation_sources: "operationSource/fetch",
      $_parents: "entryLaunch/getParents",
      $_drivers: "user/getEntryDrivers",
      $_general_settings: "generalSetting/show",
    }),
    // se for nfe so retorna tipo de nfe, senão retorna todos os tipos.
    filtered_product_service_hub_types() {
      return this.$_product_service_hub_types.filter((item) => this.entry_launch.document_template_id !== 5 || item.id === 3);
    },
    product_service_hub_types() {
      return this.$_product_service_hub_types.filter((item) => !["Não informado", "Serviço"].includes(this.entry_launch_types[item.id]));
    },
  },
  methods: {
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    checkItemAlreadyExists(item) {
      return this.items.findIndex((payload) => payload.product_service_hub_id === item.product_service_hub_id) >= 0;
    },
    getFreight() {
      let current_freight_modality_id = this.freight.freight_modality_id;
      switch (true) {
        case current_freight_modality_id &&
          this.freight_modalities.filter(function (item) {
            return item.id === current_freight_modality_id && parseInt(item.code) === 0;
          }).length === 1:
          this.freight_entity = this.entity;
          this.freight.entity_id = this.entity.id;
          break;
        case current_freight_modality_id !== null:
          this.handleSelectEntity();
          break;
      }
    },
    handleModalSearchProductServiceHub(index) {
      this.associate_index_item = index;
      let type = this.entry_launch.product_service_hub_type_id;
      this.$refs.modalSearchProductServiceHub.openModal(this.entry_launch.entity_id, type);
    },
    async changeInstallments(show_message = true) {
      this.bill_pay_installments = [];
      let val = parseInt(this.installment_quantity);
      switch (true) {
        /* caso não haja parcelas a serem manipuladas */
        case !val:
          this.installment_quantity = 1;
          if (show_message) {
            this.$notify({ type: "danger", message: "Deve haver ao menos uma parcela!" });
          }
          await this.generateInstallments(1);
          break;
        default:
          await this.generateInstallments(val);
      }
      this.$refs.installments.resetPage();
    },
    checkItemsRules() {
      this.has_internal_item =
        this.default_product_service_hub_types.includes(this.entry_launch.product_service_hub_type_id) ||
        this.items.findIndex((item) => item.external_type === 0) !== -1;
      this.has_external_item =
        !this.default_product_service_hub_types.includes(this.entry_launch.product_service_hub_type_id) &&
        this.items.findIndex((item) => item.external_type === 1) !== -1;
    },
    handleModalCreateProductServiceHub(product) {
      const entry_launch_data = {
        product_service_id: product.product_service_id,
        entry_launch_item_id: product.id,
        entity_id: this.entry_launch.entity_id,
      };
      this.$refs.modalEditProductServiceHub.handleEditModal(product.product_service_id, this.entry_launch.product_service_hub_type_id, entry_launch_data);
    },
    handleModalUpdateManyProductServiceHub() {
      this.$refs.modalUpdateManyProductServiceHub.openModal(this.entry_launch.id, this.entry_launch.product_service_hub_type_id);
    },
    associateProductServiceHub(product, index) {
      index = this.items.findIndex((item) => item.index === index);
      this.items[index].external_type = 0;
      this.items[index].product_service_hub_id = product.id;
      this.items[index].puzl_code = product.code;
      this.items[index].type_name = product.type_name;
    },
    generateInstallments(to_generate) {
      /* valor total informado pelo usuário */
      let bill_pay_total_value = Number(this.entry_launch.final_value).toFixed(2);
      /* Valor por parcela */
      let value_per_installment = bill_pay_total_value ? (bill_pay_total_value / to_generate).toFixed(2) : 0;

      for (let i = 0; i < to_generate; i++) {
        let date =
          moment(this.entry_launch.due_date ? this.entry_launch.due_date : this.entry_launch.entry_date)
            .add(i, "months")
            .format("YYYY-MM-DD") + " 00:00:00";
        this.bill_pay_installments.push({
          status: 0,
          index: i + 1,
          due_date: date,
          original_due_date: date,
          paid_at: null,
          discounts_fees: null,
          fees_fines: null,
          final_value: value_per_installment,
          total_value: value_per_installment,
        });
      }
      let real_total_value = this.bill_pay_installments.reduce(function (a, item) {
        return a + parseFloat(item.total_value);
      }, 0);
      /** Diferença de valor real e teórico */
      let difference = this.$helper.toDecimal(real_total_value - bill_pay_total_value, 2, true);

      if (difference !== 0) {
        /** Checa integridade do valor */
        switch (true) {
          case difference > 0:
            this.bill_pay_installments[0].total_value = Number(parseFloat(this.bill_pay_installments[0].total_value) - difference).toFixed(
              2
            );
            this.bill_pay_installments[0].final_value = Number(parseFloat(this.bill_pay_installments[0].final_value) - difference).toFixed(
              2
            );
            break;
          case difference < 0:
            this.bill_pay_installments[0].total_value = Number(
              parseFloat(this.bill_pay_installments[0].total_value) + Math.abs(difference)
            ).toFixed(2);
            this.bill_pay_installments[0].final_value = Number(
              parseFloat(this.bill_pay_installments[0].final_value) + Math.abs(difference)
            ).toFixed(2);
            break;
        }
      }
      return difference;
    },
    getFinalValue(regenerate_installments = true) {
      /** Iguala valor total á valor final. */
      this.entry_launch.final_value = parseFloat(this.entry_launch.total_value);

      if (regenerate_installments) {
        this.installment_quantity = 1;
        this.changeInstallments(false);
      } else {
        /* Valores a partir da parcela */
        this.bill_pay_installments.map(function (item) {
          let final_value = item.total_value.includes(",") ? moneyToFloat(item.total_value) : parseFloat(item.total_value);
          /** Caso haja taxas á retirar do valor total */
          if (item.discounts_fees && item.discounts_fees !== "0,00") {
            let discount = item.discounts_fees.includes(",") ? moneyToFloat(item.discounts_fees) : parseFloat(item.discounts_fees);
            final_value -= discount;
            if (final_value < 0) {
              this.$notify({ type: "danger", message: "O valor do desconto deve ser menor que o valor total da parcela." });
              final_value += discount;
              discount = 0;
              item.discounts_fees = 0;
            }
            this.entry_launch.final_value -= discount;
          }
          /** Caso haja juros á acrescentar do valor total */
          if (item.fees_fines && item.fees_fines !== "0,00") {
            let fees = item.fees_fines.includes(",") ? moneyToFloat(item.fees_fines) : parseFloat(item.fees_fines);
            final_value += fees;
            this.entry_launch.final_value += fees;
          }
          item.final_value = final_value.toFixed(2);
        }, this);
      }
    },
    finalFormat() {
      let payload = {
        entry_launch: { ...this.entry_launch },
        items: JSON.parse(JSON.stringify(this.items)),
        freight: { ...this.freight },
        bill_pay_installments: JSON.parse(JSON.stringify(this.bill_pay_installments)),
      };
      let formData = new FormData();
      payload.items.filter(function (obj) {
        obj.quantity = obj.quantity.replace(",", ".");
        obj.price = obj.price.toString().includes(",") ? moneyToFloat(obj.price) : parseFloat(obj.price);
      });
      payload.entry_launch.imported = 1;
      if (payload.freight && payload.freight.entity_id) {
        payload.freight.total_value = payload.freight.total_value.replaceAll(".", "").replace(",", ".");
        for (let key of Object.keys(payload.freight)) {
          formData.append(`freight[${key}]`, payload.freight[key] ? payload.freight[key] : "");
        }
        if (this.$refs.inputFreightFile.fileRecords.length) {
          formData.append("freight[photo]", this.$refs.inputFreightFile.fileRecords[0].file);
        }
      }
      payload.entry_launch.entry_date = moment(this.entry_launch.entry_date).format("YYYY-MM-DD HH:mm:ss");
      payload.entry_launch.issue_date = moment(this.entry_launch.issue_date).format("YYYY-MM-DD");
      payload.entry_launch.pending_freight = this.entry_launch.pending_freight ? 1 : 0;
      payload.entry_launch.accounting = this.entry_launch.accounting ? 1 : 0;
      if (!payload.entry_launch.entry_company_plant_id) payload.entry_launch.entry_company_plant_id = payload.entry_launch.company_plant_id;
      for (let key of Object.keys(payload.entry_launch)) {
        formData.append(`entry_launch[${key}]`, payload.entry_launch[key] !== null ? payload.entry_launch[key] : "");
      }
      if (this.$refs.inputEntryLaunchFile.fileRecords.length) {
        formData.append("entry_launch[photo]", this.$refs.inputEntryLaunchFile.fileRecords[0].file);
      }

      payload.items.map(function (obj, index) {
        for (let key of Object.keys(payload.items[index])) {
          formData.append(`items[${index}][${key}]`, payload.items[index][key] !== null ? payload.items[index][key] : "");
        }
        if (obj.equipment_apportionments) {
          obj.equipment_apportionments.map(function (item, itemIndex) {
            if (item.value.includes(",")) item.value = moneyToFloat(item.value);
            for (let key of Object.keys(obj.equipment_apportionments[itemIndex])) {
              formData.append(
                `items[${index}][equipment_apportionments][${itemIndex}][${key}]`,
                obj.equipment_apportionments[itemIndex][key] !== null ? obj.equipment_apportionments[itemIndex][key] : ""
              );
            }
          });
        }
        // Impostos
        if(obj.tax) {
          obj.tax = this.getEntryLaunchItemTaxTransformed(obj.tax);
          for (let key of Object.keys(obj.tax)) {
            formData.append(`items[${index}][tax][${key}]`, obj.tax[key] !== null ? obj.tax[key] : "");
          }
        }
      }, this);

      payload.bill_pay_installments.map(function (obj, index) {
        obj.bank_account_id = obj.bank_account_id ? obj.bank_account_id : payload.entry_launch.bank_account_id;
        obj.due_date = obj.due_date ? moment(obj.due_date).format("YYYY-MM-DD") : null;
        payload.bill_pay_installments[index].original_due_date = payload.bill_pay_installments[index].original_due_date
          ? moment(payload.bill_pay_installments[index].original_due_date).format("YYYY-MM-DD")
          : null;
        obj.final_value = this.normalizeValue(obj.final_value);
        obj.total_value = this.normalizeValue(obj.total_value);
        obj.discounts_fees = obj.discounts_fees !== "0,00" ? this.normalizeValue(obj.discounts_fees) : null;
        obj.fees_fines = obj.fees_fines !== "0,00" ? this.normalizeValue(obj.fees_fines) : null;
        for (let key of Object.keys(payload.bill_pay_installments[index])) {
          formData.append(
            `bill_pay_installments[${index}][${key}]`,
            payload.bill_pay_installments[index][key] !== null ? payload.bill_pay_installments[index][key] : ""
          );
        }
      }, this);
      formData.append("dont_has_financial", this.dontHasFinancial ? 1 : 0);
      formData.append("freight_by_entry_launch", this.freight_by_entry_launch ? 1 : 0);
      formData.append("_method", "PUT");
      return formData;
    },
    async store() {
      this.loadingStore = true;
      let payload = await this.finalFormat();

      // Verifica se houve alteração de cnpj comprador padrão
      if (this.entry_launch.default_buyer_ein && this.company_plant_buyers.length) {
        const formatted_default_buyer_ein = this.entry_launch.default_buyer_ein.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
        const company_plant_buyer = this.company_plant_buyers.find((item) => item.id === this.entry_launch.company_plant_buyer_id);
        if (company_plant_buyer.issuer.ein !== formatted_default_buyer_ein) {
          this.loadingStore = false;
          return this.$notify({
            type: "danger",
            message: "Operação não permitida, não é possível realizar a alteração do CNPJ comprador.",
          });
        }
      }

      if (!this.formDataLengthIsValid(payload)) {
        return this.$notify({
          type: "danger",
          message: "Nota indisponível, favor entrar em contato com o suporte.",
        });
      }
      this.$store
        .dispatch("entryLaunch/update", payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$router.push(`/configuration/entry-launch/external/${this.entry_launch.company_plant_buyer_id}`);
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    handleSelectEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    selectedEntity(entity) {
      this.freight_entity = entity;
      this.freight.entity_id = entity.id;
    },
    getCompanyPlantBuyers() {
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.entry_launch.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        if (this.company_plant_buyers.length) {
          let default_company_plant_buyer = 0;
          // Tenta buscar cnpj comprador da nota
          if (this.entry_launch.default_buyer_ein) {
            const formatted_default_buyer_ein = this.entry_launch.default_buyer_ein.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            );
            default_company_plant_buyer = this.company_plant_buyers.find((item) => item.issuer.ein === formatted_default_buyer_ein);
          }
          // Considera cnpj comprador o registro marcado como padrão ou o primeiro da lista
          if (!default_company_plant_buyer) {
            default_company_plant_buyer = this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          }
          this.entry_launch.company_plant_buyer_id = default_company_plant_buyer.id;
        } else {
          this.entry_launch.company_plant_buyer_id = null;
        }
        this.getParentsWithChildrenBalance();
        this.loadingCompanyPlantBuyer = false;
      });
    },
    getEntryLaunchItems() {
      this.loadingItems = true;
      this.$store.dispatch("entryLaunchItem/getByEntryLaunch", this.$route.params.id).then((response) => {
        this.items = response.data;
        let general_type = this.items.filter((item) => item.product_service_hub_type_id !== 8)[0] ?? null;
        let type_name = null;

        /* Verifica se existe algum tipo informado nos itens */
        if (general_type) {
          type_name = general_type.type_name;
          general_type = general_type.product_service_hub_type_id;
          this.entry_launch.product_service_hub_type_id = general_type;
        } else {
          /* Caso não possua itens conciliados considera sempre como mcc */
          general_type = 2;
          type_name = "MCC";
          this.entry_launch.product_service_hub_type_id = general_type;
        }

        this.items.map(function (item, key) {
          item.quantity = item.quantity.replace(".", ",");
          item.stock_status = EntryLaunchItemStockStatusEnum.keys.NO_STOCK;
          this.items[key].product_service_hub = {
            product_service_hub_type_id: general_type || item.product_service_hub_type_id,
            category_name: item.category_name,
            product_name: item.product_name,
            purchase_unit: item.purchase_unit,
            ncm: item.ncm,
            supplier_name: item.supplier_name,
            origin_name: item.origin_name,
            type_name: type_name || item.type_name,
            is_fuel: item.is_fuel,
          };
        }, this);

        if(this.operation_source){
          useEntryLaunchStockHook().setItemsStockStatus(this.items, this.entry_launch.product_service_hub_type_id, this.operation_source.is_generate_stock);
        }

        this.$store.dispatch("billPay/getByEntryLaunch", this.$route.params.id).then((response) => {
          this.installment_quantity = response.data.length;
          this.getBankAccounts(this.entry_launch);
          this.bill_pay_installments = response.data;
          this.bill_pay_installments.map((item) => (item.due_date = item.due_date + " 00:00:00"));
          this.loadingInstallment = false;
          switch (true) {
            /* Caso já exista parcelas geradas pelo usuário. */
            case this.bill_pay_installments.length > 0:
              this.getFinalValue(false);
              break;
            /* Caso não exista parcelas geradas pelo usuário mas exista mais de uma duplicata/parcela vinda da sefaz. */
            case !this.bill_pay_installments.length && this.entry_launch.original_installments !== null:
              const original_installments = JSON.parse(this.entry_launch.original_installments);
              const bill_pay_total_value = Number(this.entry_launch.final_value).toFixed(2);
              this.installment_quantity = original_installments.length;
              for (let i = 0; i < original_installments.length; i++) {
                let date = moment(original_installments[i].dVenc).format("YYYY-MM-DD 00:00:00");
                this.bill_pay_installments.push({
                  status: 0,
                  index: i + 1,
                  due_date: date,
                  original_due_date: date,
                  paid_at: null,
                  discounts_fees: null,
                  fees_fines: null,
                  final_value: original_installments[i].vDup,
                  total_value: original_installments[i].vDup,
                });
              }
              let real_total_value = this.bill_pay_installments.reduce(function (a, item) {
                return a + parseFloat(item.total_value);
              }, 0);
              /** Diferença de valor real e teórico */
              let difference = this.$helper.toDecimal(real_total_value - bill_pay_total_value, 2, true);

              if (difference !== 0) {
                /** Checa integridade do valor */
                switch (true) {
                  case difference > 0:
                    this.bill_pay_installments[0].total_value = Number(
                      parseFloat(this.bill_pay_installments[0].total_value) - difference
                    ).toFixed(2);
                    this.bill_pay_installments[0].final_value = Number(
                      parseFloat(this.bill_pay_installments[0].final_value) - difference
                    ).toFixed(2);
                    break;
                  case difference < 0:
                    this.bill_pay_installments[0].total_value = Number(
                      parseFloat(this.bill_pay_installments[0].total_value) + Math.abs(difference)
                    ).toFixed(2);
                    this.bill_pay_installments[0].final_value = Number(
                      parseFloat(this.bill_pay_installments[0].final_value) + Math.abs(difference)
                    ).toFixed(2);
                    break;
                }
              }
              this.getFinalValue(false);
              break;
            /* Por padrão regera as parcelas */
            default:
              this.getFinalValue(true);
          }
        });
        this.checkItemsRules();
        this.getStockLocations(false);
        this.$store
          .dispatch("entryLaunch/getPreviousOperationSourceByEntity", {
            entity_id: this.entry_launch.entity_id,
            nature_operation: this.entry_launch.nature_operation,
          })
          .then((response) => {
            if (!this.entry_launch.operation_source_id) {
              this.entry_launch.operation_source_id = response.data ?? null;
            }
            this.loadingDefaultOperationSource = false;
            this.getOperationSources(true);
          });
        this.loadingItems = false;
      });
    },
    handleEntityAccount() {
      this.$refs.entityAccount.handleCreateModal(this.entity.id);
    },
    changedProductServiceHubType() {
      this.getStockLocations();
      this.entry_launch.operation_source_id = null;
      this.operation_source = false;
      this.getOperationSources();
      this.getParentsWithChildrenBalance();
    },

    handleModalSetChartAccount() {
      this.$refs.modalSetChartAccount.openModal();
    },

    handleModalSetEquipmentApportionment() {
      this.$refs.modalSetEquipmentApportionment.openModal();
    },

    handleModalSetStockLocation() {
      this.$refs.modalSetStockLocation.openModal();
    },

    /**
     * Copia chave de acesso
     *
     * @param {string} access_key
     *
     * @returns void
     */
    copyAccessKey(access_key) {
      this.$helper.copy(access_key);
      this.$notify({ type: "success", message: `Chave de acesso copiada com sucesso!` });
    },
    handleModalSearchChildren(entry_launch_id) {
      this.$refs.modalSearchChildren.openModal(entry_launch_id);
    },
    async refreshEntryLaunch() {
      const loader = this.$loading.show();
      this.refreshLoading = true;
      await this.$store.dispatch("entryLaunch/show", this.$route.params.id).then((response) => {
        this.entry_launch.children_balance = response.data.children_balance;
        loader.hide();
        this.refreshLoading = false;
      });
    },
  },
 async mounted() {
    await this.$store.dispatch('generalSetting/show')
    this.loadingOperationSource = true;
    let loader = this.$loading.show();
    this.$store.dispatch("entryLaunch/show", this.$route.params.id).then((response) => {
      /* Caso usuário tente burlar voltando no histórico do navegador. */
      if (response.data.imported === 1 && response.data.status !== 6) {
        this.$notify({
          type: "danger",
          message: "A nota selecionada já foi importada, operação não permitida.",
        });
        loader.hide();
        return this.$router.push("/configuration/entry-launch");
      } else if ([5, 6].includes(response.data.status) && !response.data.default_buyer_ein && response.data.created_by_barcode === 1) {
        this.$notify({
          type: "danger",
          message: "Essa nota ainda não está na base da PUZL, aguarde a próxima atualização.",
        });
        loader.hide();
        return this.$router.push("/configuration/entry-launch");
      }
      /* fornecedor */
      this.entity = response.data.entity;
      if (response.data.freight) {
        this.freight_entity = response.data.freight.entity;
        delete response.data.freight.entity;
        this.freight = response.data.freight;
      }
      this.loadingEntity = false;
      this.entry_launch = response.data;
      this.entry_launch.status = 1;
      this.entry_launch.issue_date = this.entry_launch.issue_date + " 00:00:00";
      this.entry_launch.due_date = this.entry_launch.due_date ? this.entry_launch.due_date + " 00:00:00" : null;
      this.entry_launch.imported_at = moment().format("YYYY-MM-DD HH:mm:ss");
      this.entry_launch.company_plant_id = parseInt(this.$route.params.company_plant_id);
      this.entry_launch.entry_company_plant_id = this.entry_launch.entry_company_plant_id
        ? this.entry_launch.entry_company_plant_id
        : this.entry_launch.company_plant_id;
      this.entry_launch.company_plant_buyer_id = parseInt(this.$route.params.company_plant_buyer_id);
      this.entry_launch.discount = parseFloat(this.entry_launch.discount);

      loader.hide();
      this.getEntryLaunchItems();
      this.$store.dispatch("plant/fetchItemsActive").then(() => {
        this.getEquipments();
        this.loadingCompanyPlant = false;
      });
      this.getParentsWithChildrenBalance();
    });

    this.$store.dispatch("productServiceHubType/fetch").then(() => {
      this.loadingProductServiceHubType = false;
    });
    this.$store.dispatch("costCenter/fetchItems").then(() => {
      this.loadingCostCenter = false;
    });
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethod = false;
    });
    this.$store.dispatch("documentTemplate/fetch").then(() => {
      this.loadingDocumentTemplate = false;
    });
    this.getChartAccounts();
    this.$store.dispatch("user/getEntryDrivers");
  },
};
</script>

<style>
.custom-entry-launch-prepend-input .form-group .input-group .input-group-prepend .input-group-text {
  padding: 0.35rem !important;
}
</style>
