<template>
  <div class="row card-wrapper" v-if="$_detached_listed.items">
    <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(item, index) in $_detached_listed.items" :key="index">
      <div class="card main-card">
        <div class="w-100 d-flex justify-content-between">
          <div class="first-content">
            <div class="d-flex">
              <div class="status-badge d-flex align-items-center justify-content-center ml-4 mb-1"
                :class="item.invoice_billed_id ? 'billed' : 'pending'">
                <img v-if="!item.invoice_billed_id" src="/img/icons/icons8/ios/check_gray.png" class="mr-1" width="16" />
                <img v-if="item.invoice_billed_id" src="/img/icons/check.png" class="mr-1" width="16" height="16"  />
                {{ item.invoice_billed_id ? 'Faturado' : 'Em aberto' }}
              </div>
              <!-- <div class="first-description ml-4">
                {{ item.description }}
              </div> -->
              <!-- Icones informativos -->
              <div class="info-icons ml-3 mr-4 d-flex">
                <div>
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <div>
                      <div class="info-title mb-2">
                        <img class="mr-2" src="/img/icons/price-tag-usd-green.png" width="20px" height="20px" />
                        <span>INFO PREÇO</span>
                      </div>
                      <div>
                        QUANTIDADE: {{ item.quantity }}
                      </div>
                      <div>
                        VALOR POR UNIDADE: {{ $helper.toMoney(item.value) }}
                      </div>
                    </div>
                    <div slot="reference">
                      <img class="icon-img" width="25px" src="/img/icons/info.png" />
                    </div>
                  </el-popover>
                </div>
                <div class="icon-spacing">
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <div>
                      <div>Usuário: {{ item.user_name }}</div>
                      <div class="mt-1">Data: {{ $helper.parseDate(item.created_at) }}</div>
                    </div>
                    <div slot="reference">
                      <img class="icon-img" width="25" src="/img/icons/icons8/ios/user-male-circle--v1.png">
                    </div>
                  </el-popover>
                </div>
                <div>
                  <el-popover trigger="click" placement="bottom" class="p-0">
                    <div>
                      <div>
                        <b>CENTRAL: </b>
                        <span>{{ item.plant_name }}</span>
                      </div>
                    </div>
                    <div slot="reference">
                      <img class="icon-img" width="25px" src="/img/icons/icons8/ios/chemical-plant-v1.png" />
                    </div>
                  </el-popover>
                </div>
              </div>
            </div>
            <div class="description ml-4">
              {{ item.description }}
            </div>
            <div class="d-flex">
              <div class="value ml-4 mb-2">
                <span>R$</span>{{ $helper.toMoneyWithoutCurrencyLayout((item.value * item.quantity)) }}
              </div>
            </div>
            <div class="w-100 mb-1 d-flex justify-content-between">
              <div class="date ml-4" v-if="item.user_name">
                {{ $helper.parseDate(item.date_at, 'DD/MM/YYYY') }}
              </div>
            </div>
          </div>
          <div>
            <!-- Menu engrenagem -->
            <base-dropdown menuOnRight v-if="!item.invoice_billed_id" class="settings-dropdown">
              <div slot="title-container" class="dropdown-toggle rounded mr-4 mt-3">
                <img width="35" src="/img/icons/icons8/ios/settings--v1_primary.png">
              </div>
              <div>
                <a class="dropdown-item d-flex" @click.prevent="handleDelete(item.id)">
                  <img width="15" src="https://img.icons8.com/ios/100/db4539/delete--v1.png" alt="delete--v1" />Deletar
                </a>
              </div>
            </base-dropdown>
          </div>
        </div>
        <div class="approval-status ml-4 mb-1 mt-1 pointer d-flex align-items-center justify-content-center"
          v-if="!item.invoice_billed_id" @click.prevent="handleApprove(item)" :class="item.status ? 'approved' : 'pending'">
          <img v-if="!item.status" class="mr-1" src="/img/icons/hourglass-yellow.png" alt="hourglass-yellow"
            width="13px">
          <img v-if="item.status" class="mr-1" src="/img/icons/icons8/ios/hourglass_success.png" alt="hourglass-yellow"
            width="13px">
          {{ item.status ? 'APROVADO' : 'PENDENTE' }}
        </div>
        <div class="col-12 p-0 pr-4 mb-0 pl-4 mt-2">
          <div class="divider" />
        </div>
        <div class="w-100 d-flex justify-content-start mt-1">
          <div>
            <div class="code ml-4">
              {{ item.code }}
            </div>
            <div>
              <div class="customer-name ml-4">
                {{ item.customer_name }}
              </div>
            </div>
            <div>
              <div class="construction-name ml-4 mb-3">
                {{ item.construction_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// #region Imports
import { computed, defineEmits, ref, onMounted } from "vue";
import DetachedStatusEnum from "../../enums/DetachedStatusEnum";
import { dialogs } from "@/helpers";
import DetachedStore from "../../store/DetachedStore";
import store from "@/store/index";
// #endregion

// #region Emits
const emit = defineEmits(["fetch"]);
// #endregion

//#region Data
const financialLimit = ref(null);
//#endregion

// #region ComponentRefs
const modalListItemsApproval = ref(null);
// #endregion

// #region Computeds
/** Store Getters */
const $_detached_listed = computed(() => DetachedStore.getters.getListed());
const $_user = computed(() => store.getters["auth/getUser"]);

// #endregion

// #region Methods
async function fetchFinancialLimits() {
  if (!financialLimit.value) {
    const response = await store.dispatch('permissionFinancialLimit/show',  $_user.value.id);
    financialLimit.value = response.data
  }
}

async function handleDelete(id) {
  const isConfirmed = await dialogs.confirmAction('Deseja realmente remover o avulso?');
  if (isConfirmed) {
    await DetachedStore.actions.remove(id);
    dialogs.notify(dialogs.TYPE_ENUM.SUCCESS, 'Avulso removido com sucesso!');
  }
}

async function handleApprove(item) {
  await fetchFinancialLimits()
  if (!financialLimit.value.invoice_can_add_single) {
    dialogs.notify(dialogs.TYPE_ENUM.DANGER, 'Usuário não possui permissão para aprovar avulso');
    return
  }

  const isConfirmed = await dialogs.confirmAction('Deseja realmente aprovar o avulso?');
  if (isConfirmed) {
    await DetachedStore.actions.approve(item.id, !item.status);
    emit("fetchDetached");
    dialogs.notify();
  }
}
// #endregion

// #region Lifecycle
onMounted(() => {
  fetchFinancialLimits();
});
// #endregion
</script>

<style scoped>
.first-content {
  margin-top: 18px;
}
.card {
  box-shadow: none !important;
  border-radius: 16px;
  border: 1px solid #e8e8e8;
}

/* Status Badge */
.status-badge {
  border-radius: 15px;
  font-size: 0.8rem;
  width: 121px;
  height: 28px;
}

.status-badge.billed {
  color: #1A70B7;
  background: hsl(207, 75%, 41%, 20%);
}

.status-badge.pending {
  color: rgb(128 126 121);
  background: rgb(157 151 138 / 20%);
}

/* Info Icons */
.info-icons {
  margin: 0 11px;
}

.icon-img {
  width: 25px;
}

.icon-spacing {
  margin: 0 11px;
}

/* Description */
.description {
  font-weight: 500 !important;
  font-size: 12.36px;
  line-height: 16px;
  color: hsl(223, 8%, 18%, 75%);
  margin: 10px 0;
}

/* Value */
.value {
  font-weight: 500 !important;
  font-size: 24.7px;
  line-height: 24px;
  color: #32325d;
}

.value span {
  font-weight: 600 !important;
  font-size: 16px;
  line-height: 16px;
  color: #2b2d32 !important;
  margin-right: 4px;
}

/* Date */
.date {
  font-size: 14px;
  font-weight: 400 !important;
}

/* Settings Dropdown */
.settings-dropdown {
  margin-left: 3.7rem;
}

/* Approval Status */
.approval-status {
  width: 120px;
  border-radius: 12px;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500 !important;
  padding: 0.05rem 0.2rem;
  border: 0.5px solid;
}

.approval-status.approved {
  color: rgb(72 176 122);
  background: rgb(171 210 167 / 20%);
}

.approval-status.pending {
  color: #F2B532;
  border-color: #F2B532;
}

/* Divider */
.divider {
  border-top: 1px solid hsl(240, 1%, 38%, 15%);
  margin-bottom: 5px;
}

/* Code */
.code {
  font-size: 16px;
  font-weight: 600 !important;
  color: #0C3252;
}

/* Customer Name */
.customer-name {
  font-weight: 400 !important;
  font-size: 16px;
  color: #2B2D32;
  margin-top: 0.1rem;
}

/* Construction Name */
.construction-name {
  font-weight: 400 !important;
  font-size: 16px;
  color: #2B2D32;
  margin-top: 0.1rem;
}
</style>
