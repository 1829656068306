import DetachedListPage from "../views/list/DetachedListPage";

export default [
  {
    path: '/billing/single',
    name: 'billing.single',
    component: DetachedListPage,
    meta: {
      title: 'Avulsos',
      description: 'Avulsos',
      icon: 'fa fa-home',
      permission: 'billing.single.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: ''},
        {title: 'Avulsos', name: null},
      ]
    },
  },
];
