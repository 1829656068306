<template>
  <div>
    <modal :borderInfo="'2px solid #1b6eba'" :show.sync="modal" size="lg">
      <template slot="header">
        <div style="display: flex;align-items: center;">
          <img style="display: inline-flex;" src="/img/icons/icons8/ios/exchange.png" width="22">
          <h5 style="display: inline-flex;" class="modal-title p-0 m-0 ml-3 text-white">{{ title }}</h5>
        </div>
      </template>
      <FinanceForm :finance="finance" @close="closeModal()" />
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import FinanceForm from './Forms/_FinanceForm';
import cursorPaginate from "@/mixins/cursorPaginate";
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  mixins: [cursorPaginate],
  components: {
    PuzlSelect,
    InputDateTimePicker,
    FinanceForm
  },
  name: "ModalEditFinance",
  data() {
    return {
      modal: false,
      title: 'Operação financeira',
      finance: {},
    }
  },
  methods: {
    closeModal() {
      this.$emit('refresh');
      this.modal = false;
    },
    openModal(id){
      this.$store
        .dispatch("finances/show", id)
        .then(response => {
          const item = response.data;

          this.finance.id = id;
          this.finance.document_number = item.finance_installments[0].document_number;
          this.finance.note = item.note;
          this.finance.company_plant_id = item.company_plant_id;
          this.finance.cost_center_id = item.finance_installments[0].cost_center_id;;
          this.finance.expire_at = item.finance_installments[0].expire_at;
          this.finance.bank_account_id = item.finance_installments[0].bank_account_id;
          this.finance.expire_at_original = item.finance_installments[0].expire_at_original;
          this.finance.payment_method_id = item.finance_installments[0].payment_method_id;
          this.finance.finance_type_id = item.finance_type_id;
          this.finance.finance_type_mode = item.finance_type_mode;
          this.finance.amount = item.finance_installments[0].amount.toFixed(2);
          this.finance.company_plant_issuer_id = item.company_plant_issuer_id;
          this.finance.finance_installment_id = item.finance_installments[0].id;

          this.finance.entity = {
            id: item.entity_id,
            entity_name: item.entity_name,
            document: item.entity_document,
          };
          this.$store.dispatch('generalSetting/show')
          this.modal = true;
        });
    },
  },
};
</script>

<style scoped>

</style>
