<template>
  <div v-if="selected">
    <modal
      size="md-2"
      :show.sync="modal.show"
      data-class="programming-weighing-info"
    >
      <template slot="header">
        <h5 class="modal-title">
          <img src="/img/icons/info.png" width="25" class="mt-n2 mr-2" />
          <span>{{ modal.title }}</span>
        </h5>
      </template>
      <div class="pl-3">
        <div class="row">
          <div class="col-md-12 mb-3 info-section">
            <img
              src="/img/icons/icons8/ios/concrete-pump.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Serviço</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div>
          <div
            class="col-md-6"
            style="display: flex; flex-direction: column; gap: 8px"
          >
            {{ selected.service.name }}
            <small>{{ selected.service.description }} </small>
          </div>
          <div class="col-md-12 mt-3 info-section">
            <img
              src="/img/icons/icons8/ios/business.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Contrato</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div>
          <div
            class="col-md-12"
            style="display: flex; flex-direction: column; gap: 8px"
          >
            <strong class="mt-2">{{
              selected.schedule.contract_proposal.code
            }}</strong>
            <span>{{ selected.schedule.contract_proposal.customer_name }}</span>
            <span
              v-if="
                selected?.schedule?.addresses &&
                selected.schedule?.addresses.length
              "
              >Endereço: {{ selected.schedule.addresses[0].address }}</span
            >
            <span
              >Contato: {{ selected.schedule.responsible_receiver.name }} ({{
                selected.schedule.responsible_receiver.phone
                  ? selected.schedule.responsible_receiver.phone
                  : selected.schedule.responsible_receiver.email
              }})</span
            >
          </div>
          <div class="col-md-12 mt-3 info-section">
            <img
              src="/img/icons/chemical-plant.png"
              width="20"
              height="20"
              style="margin-right: 1.5%"
            /><Strong class="mb-3">Programação</Strong>
            <div class="pzl-container-divider">
              <div></div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-4">Previsto</div>
              <div class="col-md-4">Realizado</div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Início</div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img src="/img/icons/calendar-black.png" width="15px" />
                  </div>
                  <input
                    style="background-color: #e8e8e8"
                    type="text"
                    readonly
                    class="read-info"
                    :value="
                      selected.schedule.first_arrival_time
                        | parseDate('DD/MM/Y HH:mm')
                    "
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img src="/img/icons/calendar-black.png" width="15px" />
                  </div>
                  <input
                    style="background-color: #f2f4f9"
                    type="text"
                    readonly
                    class="read-info"
                    :value="formattedDate(selected.start_at)"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Término</div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img src="/img/icons/calendar-black.png" width="15px" />
                  </div>
                  <input
                    style="background-color: #e8e8e8"
                    type="text"
                    readonly
                    class="read-info"
                    :value="
                      selected.schedule.last_exit_time
                        | parseDate('DD/MM/Y HH:mm')
                    "
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img src="/img/icons/calendar-black.png" width="15px" />
                  </div>
                  <input
                    style="background-color: #f2f4f9"
                    type="text"
                    readonly
                    class="read-info"
                    :value="formattedDate(selected.end_at)"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Tempo</div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img
                      src="/img/icons/icons8/ios/clock-light.png"
                      width="15px"
                    />
                  </div>
                  <input
                    style="background-color: #e8e8e8"
                    type="text"
                    readonly
                    class="read-info"
                    :value="selected.schedule.concreting_time"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img
                      src="/img/icons/icons8/ios/clock-light.png"
                      width="15px"
                    />
                  </div>
                  <input
                    style="background-color: #f2f4f9"
                    type="text"
                    readonly
                    class="read-info"
                    :value="_difference"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Volume</div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img
                      src="/img/icons/icons8/ios/test-tube.png"
                      width="15px"
                    />
                  </div>
                  <input
                    style="background-color: #e8e8e8"
                    type="text"
                    readonly
                    class="read-info"
                    :value="selected.volume + ' m³'"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="position-relative">
                  <div class="container-icon">
                    <img
                      src="/img/icons/icons8/ios/test-tube.png"
                      width="15px"
                    />
                  </div>
                  <input
                    style="background-color: #f2f4f9"
                    type="text"
                    readonly
                    class="read-info"
                    :value="selected.schedule.total_volume + ' m³'"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">Equipamento</div>
              <div class="col-md-8">
                <div class="position-relative">
                  <input
                    type="text"
                    readonly
                    class="read-info"
                    :value="selected?.equipment?.code"
                    style="padding-left: 2%"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">RESPONSÁVEIS</div>
              <div class="col-md-8">
                <div class="position-relative">
                  <input
                    type="text"
                    readonly
                    class="read-info"
                    :value="selected.schedule.responsible_receiver.name"
                    style="padding-left: 2%"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-md-4">Observações</div>
              <div class="col-md-8">
                <div class="position-relative">
                  <textarea class="read-info" style="padding-left: 2%">{{
                    selected.observations ? selected.observations : "-"
                  }}</textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-2 p-2">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <div class="close-btn pointer" @click.prevent="modal.show = false">
            <i class="fas fa-times" style="font-size: 20px"></i>
            <span>Fechar</span>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import { ScheduleServiceEnum } from "../Utils/ScheduleServiceEnum";

export default {
  name: "ServiceModalInfo",
  components: {},
  data() {
    return {
      modal: {
        title: "Informações",
        show: false,
      },
      selected: null,
    };
  },
  computed: {
    _difference() {
      if (this.selected.start_at === null || this.selected.end_at === null) {
        return null;
      }
      const start = moment(this.selected.start_at);
      const end = moment(this.selected.end_at);
      const diffCalculate = end.diff(start, "minutes");
      const hours = Math.floor(diffCalculate / 60);
      const minutes = diffCalculate % 60;
      let result = `${hours}h`;
      if (minutes > 0) {
        result = `${result} ${minutes}min`;
      }
      return result;
    },
  },
  methods: {
    openModal(item) {
      this.selected = this.$helper.cloneObject(item);
      if (+this.selected.status !== ScheduleServiceEnum.COMPLETED) {
        this.selected.end_at = null;
        this.selected.start_at === null;
        this.selected.schedule.total_volume = 0;
      }
      this.modal.show = true;
    },
    formattedDate(date) {
      return date ? moment(date).format("DD/MM/YYYY HH:mm") : "";
    },
  },
};
</script>

<style scoped>
/deep/ div[data-class="programming-weighing-info"] div.modal-header.pb-1 {
  background: #fef9f2 !important;
}

/deep/
  div[data-class="programming-weighing-info"]
  div.modal-header.pb-1
  > button
  i {
  border: 1px solid #000 !important;
  color: #000;
  padding: 3%;
  font-size: 14px !important;
}

h5.modal-title > span,
h5.modal-title > small {
  color: #2b2d32;
  font-size: 18px !important;
}

div.info-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 23px;
}

div.info-section > div.pzl-container-divider {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2%;
}

div.info-section > div.pzl-container-divider > div {
  width: 100%;
  border: 0.5px solid #e8e8e8;
}

div.close-btn {
  border-radius: 8px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 115px;
  border: 0.5px solid #e8e8e8;
  gap: 10px;
}

div.close-btn > i {
  border: 1px solid #000;
  color: #000;
  padding: 3%;
  font-size: 8px !important;
}

div.close-btn > span {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  font-family: "Fredoka";
}

.read-info {
  border: 0.5px solid #e8e8e8;
  height: 31px;
  padding: 8px 2px 8px 23px;
  border-radius: 4px;
  width: 100%;
}

textarea.read-info {
  height: 51px;
}

.container-icon {
  width: 25px;
  height: 31px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
