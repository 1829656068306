import PurchaseRequestListPage from "../views/list/PurchaseRequestListPage";
import PurchaseRequestCreatePage from "../views/create/PurchaseRequestCreatePage";
import PurchaseRequestEditPage from "../views/edit/PurchaseRequestEditPage";


export default [
  {
    path: '/purchase/request',
    name: 'purchase.request.view',
    component: PurchaseRequestListPage,
    meta: {
      title: 'Requisição de compra',
      description: 'Requisição de compra',
      icon: 'fa fa-home',
      permission: 'purchases.request.index.view',
      breadcrumbs: [
        {title: 'Requisição', name: null},
      ]
    },
  },
  {
    path: '/purchase/request/create',
    name: 'purchase.request.view',
    component: PurchaseRequestCreatePage,
    meta: {
      title: 'Criar requisição',
      description: 'Criação da requisição de compra',
      icon: 'fa fa-home',
      permission: 'purchases.request.index.view',
      breadcrumbs: [
        {title: 'Criar Requisição', name: null},
      ]
    },
  },
  {
    path: '/purchase/request/edit/:id',
    name: 'purchase.request.view',
    component: PurchaseRequestEditPage,
    meta: {
      title: 'Criar requisição',
      description: 'Edição da requisição de compra',
      icon: 'fa fa-home',
      permission: 'purchases.request.index.view',
      breadcrumbs: [
        {title: 'Editar Requisição', name: null},
      ]
    },
  },
];