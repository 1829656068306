import { PagerType, initPagerType } from "../../../../shared/types";

/**
 * Type para item da listagem
 *
 * @typedef {Object} ServiceListType
 * @property {number} id
 * @property {string} uuid
 * @property {boolean} status
 * @property {string} service_name
 * @property {?string} equipment_category_uuid
 * @property {?number} equipment_category_id
 * @property {?string} equipment_category_name
 * @property {boolean} count_from_the_completed_schedule_travels
 * @property {string} created_at
 * @property {?string} updated_at
 * @property {number} created_by_user_id
 * @property {?number} updated_by_user_id
 * @property {string} created_by_user_name
 * @property {string} updated_by_user_name

 */
export const ServiceListType = {};

/**
 * Type para retorno paginado da listagem
 *
 * @typedef {( PagerType & { items: Array<ServiceListType> } )} ServiceListPagerType
 */
export const ServiceListPagerType = {};

/**
 * Inicializar ServiceListPagerType
 * 
 * @returns {ServiceListPagerType}
 */
export const initServiceListPagerType = () => {
  return {
    ...initPagerType(),
    items: [],
  }
};

