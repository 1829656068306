<template>
  <div style="min-height: 800px;">
    <AppFixedPageTitle title="Requisição de compra" icon="/img/icons/cart.png" />
    <AppPageHeader class="mb-3">
      <template slot="search-bar">
        <AppSearchBar :searchBarFilter.sync="searchBarFilter" :showCompanyPlants="true"
          :isLoading="$_purchase_request_is_listing" @onSearchClick="listItems" @onClearClick="clearFilter">
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png">
            <div class="w-100">
              <div class="col-12 px-0 mb-2 text-left">
                <label class="form-control-label fs-11 new-default-gray-font font-weight-400" >
                  SOLICITANTE
                </label>
              </div>
              <PuzlSelect
                v-model.lazy="filter.requester_id"
                style="width: 100%"
                :items="users"
                :disableBoxShadow="true"
                placeholder="Status"
                :multiple="true"
                class="" />
            </div>
            <div class="w-100">
              <div class="col-12 px-0 mb-2 text-left">
                <label class="form-control-label fs-11 new-default-gray-font font-weight-400" >
                  CATEGORIAS
                </label>
              </div>
              <PuzlSelect
                v-model.lazy="filter.purchase_category_id"
                style="width: 100%"
                :items="categories"
                :disableBoxShadow="true"
                placeholder="Descrição"
                label="description"
                :multiple="true"
                class="" />
            </div>
          </AppSearchBarFilterSection>
          <AppSearchBarFilterSection name="Marcadores" icon="/img/icons/icons8/ios/push-pin_gray.png">
            <div class="col-12 px-0 mt-1">
              <BaseButtonHoverable :active="filter.denied_request"
                @click="filter.denied_request = !filter.denied_request" size="sm" type="danger"
                icon="menu-squared" platform="ios" style="height: 32px;">
                ITENS NÃO APROVADOS
              </BaseButtonHoverable>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton @click.prevent="$router.push('/purchase/request/create')" text="novo"
            type="success" icon="/img/icons/plus-math--v1-white.png">
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <AppTabSelect :items="tabSelectItems" @onTabSelectItemClick="onTabSelectItemClick" :isShowViewTypes="true"
      @onViewTypeChange="(type) => listType = type">
      <AppSelect
        v-model="orderBy.selected"
        :items.sync="orderBy.items"
        @onSelectItemClick="listItems(false)"
        variant="text-only"
      />
    </AppTabSelect>
      
    <div class="container-fluid">
      <!-- CARDS -->
      <PurchaseRequestListPageCards v-if="listType === 'cards'" class="mt-4" @fetch="listItems" />
      <PurchaseRequestListPageTable v-else class="mt-4" @fetch="listItems" />
      <AppViewTrigger v-if="!$_purchase_request_is_listing" @onIntersected="listItems(true)" />
      <div class="d-flex" v-if="$_purchase_request_is_listing">
        <SkeletonPuzlGrid class="p-2" v-for="index in 3" :key="index" />
      </div>
      <PuzlEmptyData v-if="!$_purchase_request_is_listing && $_purchase_requests_listed.items.length <= 0" />
    </div>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref, computed, onMounted, onUnmounted } from 'vue';
import {
  AppFixedPageTitle,
  AppPageHeader,
  AppSearchBar,
  AppPageHeaderActions,
  AppPageHeaderActionsButton,
  AppViewTrigger,
  AppTabSelect,
  initSearchBarFilterType,
  AppSearchBarFilterSection,
  AppSelect,
} from '../../../../../../../components/AppGlobal';
import PuzlEmptyData from "@/components/PuzlEmptyData";
import PuzlSelect from "@/components/PuzlSelect";
import store from "@/store/index";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PurchaseRequestListPageCards from './PurchaseRequestListPageCards.vue';
import PurchaseRequestListPageTable from './PurchaseRequestListPageTable.vue';
import PurchaseRequestStatusEnum from "../../enums/PurchaseRequestStatusEnum";
import { initPurchaseRequestListFilterType } from "../../types";
import { date } from "../../../.../../../../../../helpers";
import purchaseRequestStore from "../../store/purchaseRequestStore";
//#endregion

//#region Data
const searchBarFilter = reactive(initSearchBarFilterType());
const filter = reactive(initPurchaseRequestListFilterType());
const currentDate = date.make().format(date.FORMAT.ISO_8601);
const listType = ref('cards');

const users = ref([]);
const categories = ref([]);
const orderBy = reactive({
  items: [
  {
      id: 0,
      name: "REGISTRO MAIS NOVO PARA O MAIS VELHO ",
      selected_name: "MAIS NOVO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "purchase_requests.created_at",
        is_desc: true,
      }],
    },
    {
      id: 1,
      name: "REGISTRO MAIS VELHO PARA O MAIS NOVO",
      selected_name: "MAIS VELHO",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "purchase_requests.created_at",
        is_desc: false,
      }],
    },
    {
      id: 2,
      name: "PRAZO MAIS PRÓXIMO PARA O MAIS LONGE",
      selected_name: "MAIS PRÓXIMO",
      icon: "/img/icons/icons8/ios/double-down.png",
      filter: [{
        column: "purchase_requests.deadline",
        is_desc: true,
      }],
    },
    {
      id: 3,
      name: "PRAZO MAIS LONGE PARA O MAIS PERTO",
      selected_name: "MAIS LONGE",
      icon: "/img/icons/icons8/ios/double-up.png",
      filter: [{
        column: "purchase_requests.deadline",
        is_desc: false,
      }],
    },
  ],
  selected: 0,
});
//#endregion

//#region Computeds
/** Store Getters */
const $_purchase_requests_listed = computed(() => purchaseRequestStore.getters.getListed());
const $_purchase_request_is_listing = computed(() => purchaseRequestStore.getters.getIsListing());

const tabSelectItems = computed(() => {
  return [
    {
      id: null,
      name: 'Todos',
      selected: filter.status === null,
    },
    {
      id: PurchaseRequestStatusEnum.keys.PENDING,
      name: PurchaseRequestStatusEnum.getTitle(PurchaseRequestStatusEnum.keys.PENDING),
      selected: filter.status === PurchaseRequestStatusEnum.keys.PENDING,
    },
    {
      id: PurchaseRequestStatusEnum.keys.APPROVED,
      name: PurchaseRequestStatusEnum.getTitle(PurchaseRequestStatusEnum.keys.APPROVED),
      selected: filter.status === PurchaseRequestStatusEnum.keys.APPROVED,
    },
    {
      id: PurchaseRequestStatusEnum.keys.DENIED,
      name: PurchaseRequestStatusEnum.getTitle(PurchaseRequestStatusEnum.keys.DENIED),
      selected: filter.status === PurchaseRequestStatusEnum.keys.DENIED,
    },
    {
      id: PurchaseRequestStatusEnum.keys.CANCELED,
      name: PurchaseRequestStatusEnum.getTitle(PurchaseRequestStatusEnum.keys.CANCELED),
      selected: filter.status === PurchaseRequestStatusEnum.keys.CANCELED,
    },
  ];
});

//#region Methods
/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = false) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? purchaseRequestStore.getters.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }

  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
};

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return {
    ...initSearchBarFilterType(),
    range: {
      items: [
        { id: 0, name: "Criação", selected_name: "Cria." },
        { id: 1, name: "Vencimento", selected_name: "Venc." },
      ],
      selected: 0,
      start: filter.range.start ?? currentDate,
      end: filter.range.start ?? date.make(currentDate)
        .add(1, date.DURATION_INPUT.YEARS)
        .format(date.FORMAT.ISO_8601),
    },
  }
};

/**
 * Padrão do filtro principal
 * @returns {PurchaseRequestListFilterType}
 */
function defaultFilter() {
  return {
    ...initPurchaseRequestListFilterType(),
  }
};

/**
 * @param {TabSelectItemType} item
 */
function onTabSelectItemClick(item) {
  if (filter.status !== item.id) {
    filter.status = item.id;
    listItems();
  };
};

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
function listItems(isAccumulateItems = false) {
  prepareFilter();
  purchaseRequestStore.actions.list(filter, isAccumulateItems);
};

function prepareFilter() {
  filter.input_company_plant_id = searchBarFilter.company_plant_selected;
  filter.custom_search.values = searchBarFilter.custom_search_values;
  filter.order_by = orderBy.items[orderBy.selected].filter;
  filter.range = searchBarFilter.range;
}

async function setFilterItemsData() {
  const usersResponse = await store.dispatch("user/getAllActiveUsersSimplified");
  const categoriesResponse = await store.dispatch('purchaseCategory/fetchItemsPaginate', { order_by: [{ column: 'purchase_categories.id' }] });

  users.value = usersResponse?.data || [];
  categories.value = categoriesResponse?.data?.items || [];
}
//#endregion

//#region Lifecycle
onMounted(() => {
  setFilterItemsData();
  clearFilter(true, true);
});
onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  purchaseRequestStore.mutations.resetStates();
  purchaseRequestStore.mutations.setPageState({ filter, searchBarFilter });
});
//#endregion
</script>