<template>
  <div>
    <span ref="trigger"></span>
    <div v-if="props.hasBackToTop && scrollIsNotAtTop"
      class="scroll-to-top"
      @click="goToTop">
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount, defineProps, defineEmits, onUnmounted, computed} from "vue";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Object} options - IntersectionObserverInit
 * @property {?HTMLElement} options.root - O elemento que é usado como viewport para verificar a visibilidade do alvo.
 * @property {String} options.threshold - 0 significa que um pixel está visível e 1 que todo o elemento está visível.
 * @property {Number} delayOnMounted - Atraso na montagem do componente [milesegundos]. Padrão = 1000ms.
 * @property {Number} delayOnIntersect - Atraso no evento de interseção. Padrão = 0ms.
 * @property {Boolean} hasBackToTop - Opção de voltar ao topo da listagem. Padrão = true.
 */
/** @type {Props} */
const props = defineProps({
  options: {
    type: Object,
    default: () => ({
      root: null,
      threshold: "0",
    }),
    required: false,
  },
  delayOnMounted: {
    type: Number,
    default: () => 1000,
    required: false,
  },
  delayOnIntersect: {
    type: Number,
    default: () => 0,
    required: false,
  },
  hasBackToTop: {
    type: Boolean,
    default: () => true,
    required: false,
  }
});

/**
 * Define a função de emissão para emitir eventos.
 *
 * @emits onIntersected - Emitido quando a interseção é observada.
 */
const emit = defineEmits(["onIntersected"]);

/** @type {import('vue').Ref<?HTMLElement>} */
const trigger = ref(null);

/** @type {import('vue').Ref<?IntersectionObserver>} */
const observer = ref(null);

/** @type {import('vue').Ref<boolean>} */
const scrollIsNotAtTop = ref(false);

/**
 * Lida com o evento de interseção.
 *
 * @param {IntersectionObserverEntry} entry - A entrada do elemento observado.
 */
const handleIntersect = async (entry) => {
  if (entry.isIntersecting) {
    await new Promise(resolve => setTimeout(resolve, props.delayOnIntersect));
    emit("onIntersected");
  }
};

const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
}

/**
 * Verifica se scroll não está no topo
 *
 */
const checkScroll = () => {
  scrollIsNotAtTop.value = window.scrollY > 0;
};

/**
 * Remove o escutador ao evento de rolagem da página
 */
onUnmounted(() => {
  window.removeEventListener("scroll", checkScroll);
});

/**
 * Inicializa o IntersectionObserver quando o componente é montado.
 * Adiciona um escutador ao evento de rolagem da página
 */
onMounted(async () => {
  window.addEventListener("scroll", checkScroll);
  checkScroll();

  await new Promise(resolve => setTimeout(resolve, props.delayOnMounted));
  observer.value = new IntersectionObserver((entries) => {
    handleIntersect(entries[0]);
  }, props.options);

  if (trigger.value) {
    observer.value.observe(trigger.value);
  }
});

/**
 * Desconecta o IntersectionObserver quando o componente está prestes a ser desmontado.
 */
onBeforeUnmount(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});
</script>
<style lang="scss">
@import "@/style/PuzlCustom/App.scss";

.scroll-to-top {
  width: 60px;
  height: 60px;
  background-color: white;
  border: 2px solid $muted-light;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px $muted-lighter;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: $muted-medium;
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
    margin-left: -8px;
  }

  &::before {
    margin-right: -8px;
  }
}
</style>
