export const keys = Object.freeze({
    INACTIVE: 0, // Inativo
    ACTIVE: 1, // Ativo
    PENDING_REVIEW: 2, // Pendente de revisão
    PENDING_APPROVAL: 3, // Pendente de aprovação
    PROPOSAL: 4, // Proposta (antigo rascunho)
    COMPLETED: 5 // Completo
});

export const ContractProposalEnum = {
    ...keys,
}
