<template>
  <div>
    <base-header class="bg-gray-content">
      <div class="row align-items-center py-4">
        <div class="col">
          <h2 style="font-size: 1.2rem !important" class="text-danger mb-0 d-flex align-items-center mb-2 mb-md-0">
            <img height="30px" src="/img/icons/login-rounded.png" class="mr-3" />
            Lançamento de despesa
          </h2>
        </div>
        <div v-if="items.length > 0" class="col-md-2 mb-2 ml-4">
          <base-button @click.prevent="handlePurchaseOrderAssociation" block size="lg" type="warning" class="text-uppercase">
            <i class="fas fa-plus" /> VINCULAR O.C.
          </base-button>
        </div>
        <div v-show="entry_launch.payment_method_id && [1, 4].includes(entry_launch.payment_method_id)" class="col-md-2 mb-2">
          <base-button @click.prevent="handleEntityAccount" block size="lg" type="success" class="text-uppercase">
            <i class="fas fa-plus" /> CONTA
          </base-button>
        </div>
        <div class="col-md-2 mb-0">
          <back-button />
        </div>
        <div class="col-md-2 mb-0">
          <AppPageHeaderActionsButton
            @click="handleShowModal({ ref: 'modalPurchaseCategory', data: entityBudget })"
            text="Cadastros"
            type="warning"
            icon="/img/icons/product-documents.png"
            />
        </div>
      </div>
      <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-0 mb-4 mx-1" />
    </base-header>
    <div class="container-fluid">
      <div>
        <card>
          <!-- Navegação -->
          <Navegation
            v-if="!loading"
            :entity_name="entity.entity_name"
            :entity_document="entity.document"
            :document_number="entry_launch.document_number"
            :series="entry_launch.series"
            :tab_value="tab_value"
            @input="tab_value = $event"
            :disabledPayment="
              bill_pay_installments.length < 1 ||
              entry_launch.total_value <= 0 ||
              loadingInstallment ||
              entry_launch.has_agroup ||
              entry_launch.status === 7 ||
              dontHasFinancial
            "
          />

          <!-- Aba documento -->
          <div class="mt-4" v-show="tab_value === 'document'">
            <!-- Identificação -->
            <div class="row">
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                    IDENTIFICAÇÃO
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Central de entrada -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de Entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="
                          getStockLocations();
                          getEquipments();
                        "
                        v-model="entry_launch.entry_company_plant_id"
                        :items="company_plants"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Central de custo -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Central de custo
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.company_plant_id"
                        :items="company_plants"
                        :clearable="false"
                        @change="entry_launch.external_type === 0 && getCompanyPlantBuyers(true)"
                      />
                    </div>
                  </div>

                  <!-- CNPJ Comprador -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      CNPJ Comprador
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6" v-if="entry_launch.default_buyer_ein && entry_launch.external_type === 1">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          disabled
                          inputmode="numeric"
                          :value="entry_launch.default_buyer_ein.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')"
                          class="form-control form-control-sm"
                        />
                      </base-input>
                    </div>
                    <div v-else class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.company_plant_buyer_id"
                        :items="company_plant_buyers"
                        :clearable="false"
                        :disabled="loadingCompanyPlantBuyer || entry_launch.external_type === 1"
                        :labelMask="true"
                        :label="['issuer', 'ein']"
                      />
                    </div>
                  </div>

                  <!-- Documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.document_template_id"
                        :items="formattedDocumentTemplates"
                        label="label"
                        :clearable="false"
                        :loading="loadingDocumentTemplate"
                        ::disabled="loadingDocumentTemplate || loading || entry_launch.external_type === 1 "
                      />
                    </div>
                  </div>

                  <!-- Tipo de itens -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Tipo de itens
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        v-model="entry_launch.product_service_hub_type_id"
                        :items="[{ id: 2, name: 'MCC' }, ...$_product_service_hub_types]"
                        :loading="loadingProductServiceHubType"
                        disabled
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>

                <!-- Operação -->
                <div class="card py-4 px-3">
                  <div class="row">
                    <div class="col-6">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                        <img height="30px" src="/img/icons/icons8/ios/upload-link-document_primary.png" class="mr-3" />
                        Operação
                      </h3>
                    </div>
                    <div v-if="operation_source" class="col-6 d-flex justify-content-end">
                      <base-button
                        v-if="operation_source.is_entry_value"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/working-mom_success.png" class="mr-2" />
                        <span class="fs-12">Nota mãe </span>
                      </base-button>
                      <base-button
                        v-else-if="!operation_source.is_entry_value && !operation_source.is_generate_financial"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="success"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/women-age-group-2_success.png" class="mr-2" />
                        <span class="fs-12">Nota filha </span>
                      </base-button>
                      <base-button
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="danger"
                        v-if="operation_source.is_generate_financial"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/icons8/ios/duration-finance--v1_danger.png" class="mr-2" />
                        Financeiro
                      </base-button>
                      <base-button
                        v-if="operation_source.is_generate_stock"
                        class="rounded-pill text-uppercase d-flex align-items-center"
                        type="primary"
                        outline
                        size="sm"
                        disabled
                      >
                        <img height="18px" src="/img/icons/open-box.png" class="mr-2" />
                        Estoque
                      </base-button>
                    </div>
                  </div>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                  <!-- Natureza de operação -->
                  <div class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Natureza de operação
                      <img role="button" v-if="entry_launch.external_type === 1" class="ml-2" @click="copyAccessKey(entry_launch.access_key)" src="/img/icons/icons8/ios/copy-2_primary.png" width="22px" height="22px" />
                    </label>
                    <div class="col-md-6">
                      <input maxlength="30" v-model="entry_launch.nature_operation" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Tipo de operação puzl -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font d-flex align-items-center mb-2 mb-md-0">
                      Tipo de operação puzl
                      <span class="text-danger">&nbsp;*</span>
                      <base-button
                        class="text-uppercase ml-2"
                        size="sm"
                        type="success"
                        @click.prevent="handleModalOperationSourceCreate"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id"
                      >
                        <i class="fas fa-plus"></i> Novo
                      </base-button>
                    </label>
                    <div class="col-md-6">
                      <PuzlSelect
                        @input="checkFinancial(true)"
                        v-model="entry_launch.operation_source_id"
                        :items="$_operation_sources"
                        :loading="loadingOperationSource"
                        :disabled="loadingOperationSource || !entry_launch.product_service_hub_type_id"
                        :clearable="false"
                      />
                    </div>
                  </div>

                  <!-- Nota filha -->
                  <div
                    v-if="operation_source && !operation_source.is_entry_value && !operation_source.is_generate_financial"
                    class="row mt-2"
                  >
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center"> Nota mãe </label>
                    <div class="col-md-6">
                      <PuzlSelect label="document_number" v-model="entry_launch.parent_id" :items="$_parents" :disabled="loadingParents" />
                    </div>
                  </div>
                  <!-- Nota mãe -->
                  <div
                    v-else-if="operation_source && operation_source.is_entry_value && operation_source.is_generate_financial"
                    class="row mt-3"
                  >
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Notas filhas
                      <div class="col-5">
                        <base-button-hoverable
                          @click="handleModalSearchChildren(entry_launch.id)"
                          size="sm"
                          type="success"
                          icon="plus--v1"
                          platform="ios"
                        >
                          Incluir
                        </base-button-hoverable>
                      </div>
                    </label>
                    <div v-if="!refreshLoading" class="col-md-6">
                      <div class="row">
                        <label class="col-6 new-default-black-font fs-12">
                          {{ getChildrenValue | currency }}
                        </label>
                        <label class="col-6 new-default-black-font text-right fs-12">
                          {{ entry_launch.children_balance | currency }} Saldo
                        </label>
                      </div>
                      <base-progress type="success" :value="getChildrenPercentage"></base-progress>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Dados -->
              <div class="col-md-6">
                <div class="card py-4 px-3">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/data-arrived_primary.png" class="mr-3" />
                    DADOS
                  </h3>
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                  <!-- Contabilidade -->
                  <div v-if="entry_launch.external_type === 0" class="row">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Contabilidade
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <base-switch v-model="entry_launch.accounting" type="success" class="success ml-2" offText="Não" onText="Sim" />
                    </div>
                  </div>

                  <!-- Data de entrada -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de entrada
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker
                        :disabled="entry_launch.total_paid > 0"
                        :disableMaxDate="max_date"
                        :mode="'dateTime'"
                        :size="'lg'"
                        v-model="entry_launch.entry_date"
                      />
                    </div>
                  </div>

                  <!-- Data de emissão -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Data de emissão
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6 d-flex align-items-center justify-content-md-end">
                      <InputDateTimePicker
                        :disableMaxDate="max_date"
                        :disabled="entry_launch.external_type === 1"
                        :mode="'date'"
                        v-model="entry_launch.issue_date"
                      />
                    </div>
                  </div>

                  <!-- Nº do documento -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Nº do documento
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <InputLimit
                        :disabled="loading || entry_launch.external_type === 1"
                        :start_value="entry_launch.document_number"
                        :options="{
                          min: 0,
                        }"
                        max_length="15"
                        input_group_classes="input-group-sm pb-0"
                        v-model="entry_launch.document_number"
                        @value="entry_launch.document_number = $event"
                      />
                    </div>
                  </div>

                  <!-- Série -->
                  <div class="row mt-2">
                    <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                      Série
                      <span class="text-danger">&nbsp;*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        :disabled="entry_launch.external_type === 1"
                        maxlength="30"
                        v-model="entry_launch.series"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>

                  <!-- Observações -->
                  <div class="row mt-2">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center"> Observações </label>
                    <div class="col-12">
                      <textarea v-model="entry_launch.observations" type="text" rows="3" class="form-control form-control-sm" />
                    </div>
                  </div>

                  <!-- Foto do documento -->
                  <div class="row mt-3">
                    <label class="col-12 new-default-black-font mb-2 d-flex align-items-center">
                      Foto do documento
                      <span class="text-danger"> {{ entry_launch.accounting ? "&nbsp;*" : "" }}</span>
                    </label>
                    <div class="col-12">
                      <InputFile :multiple="false" ref="inputEntryLaunchFile" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <!-- Aba itens -->
            <div class="mt-4" v-show="tab_value === 'items'">
              <div class="row m-0">
                <!-- Incluir -->
                <div
                  v-show="
                    !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id) &&
                    entry_launch.external_type === 0
                  "
                  class="col-md-2 pr-md-1 pl-md-0"
                >
                  <base-button-hoverable @click="handleModalCreateEntryLaunchItem" size="sm" type="success" icon="plus--v1" platform="ios">
                    Incluir
                  </base-button-hoverable>
                </div>
              </div>

              <!-- Itens -->
              <div class="mt-4">
                <PuzlEmptyData v-show="!items.length" />
                <div class="row card-wrapper" v-if="items.length">
                  <div class="col-lg-4" v-for="(item, index) in items" :key="index">
                    <card>
                      <div class="row align-items-center">

                        <!-- Status de estoque -->
                        <EntryLaunchItemStockStatusLabel
                          @onChange="item.stock_status = $event"
                          :stockStatus="item.stock_status"
                          :productServiceHubTypeId="entry_launch.product_service_hub_type_id"
                          :operationSourceIsGenerateStock="false"
                        />

                        <el-popover v-if="item.code" class="col-auto" placement="right" trigger="hover">
                          <h4 class="new-default-black-font">Código do fornecedor</h4>
                          <h5 class="mb-0 new-default-black-font">
                            Código : <span class="font-weight-normal"> {{ item.code }} </span>
                          </h5>
                          <span role="button" slot="reference">
                            <img src="/img/icons/info.png" width="26px" class="float-right" />
                          </span>
                        </el-popover>
                        <!-- Impostos -->
                        <div v-if="['Produtos e Serviços', 'MCC'].includes(entry_launch_types[entry_launch.product_service_hub_type_id]) && item.id" class="col-auto px-1">
                          <span @click="handleOpenModalTaxes(item, index)" role="button">
                            <img height="27px" src="/img/icons/icons8/ios/general-ledger_default.png"/>
                          </span>
                        </div>
                        <el-popover trigger="click" placement="top" class="p-0">
                          <div>
                            <span class="new-default-black-font">Ordem de compra</span>
                            <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                            {{ 'OC-' + item.order_id || 'N/A' }}
                          </div>
                          <span slot="reference">
                            <img v-if="item.order_id" class="mx-2" height="27px" src="/img/icons/cart-success.png"/>
                          </span>
                        </el-popover>
                        <el-popover v-if="!item.order_id && !item.ignore_order" trigger="click" placement="top" class="p-0">
                          <div>
                            <span class="new-default-black-font">Ordem de compra</span>
                            <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                            <span>IGNORAR O.C</span>
                            <div @click="handleIgnoreOrder(item, index)" class="mt-2 p-1 d-flex justify-content-center align-items-center pointer"
                              style="font-size: 12px;border: 0.5px solid #CFCFCF; border-radius: 5px; color: #1A70B7;">
                              Confirmar
                            </div>
                          </div>
                          <span slot="reference">
                            <img class="mx-2" height="27px" src="/img/icons/cart-gray.png"/>
                          </span>
                        </el-popover>
                        <el-popover v-if="item.ignore_order && !item.order_id" trigger="click" placement="top" class="p-0">
                          <div>
                            <span class="new-default-black-font">Ordem de compra</span>
                            <div class="my-1" style="height: 1px; width: 100%; background-color: #E8E8E8" />
                            Ordem de compra ignorada
                          </div>
                          <span slot="reference">
                            <img class="mx-2" height="27px" src="/img/icons/cart-danger.png"/>
                          </span>
                        </el-popover>
                        <div v-if="!selected_items" class="col text-right pl-0">
                          <base-dropdown menuOnRight>
                            <div slot="title-container" class="dropdown-toggle rounded m-0">
                              <img width="32" class="mx-2" src="/img/icons/icons8/ios/settings--v1_primary.png" />
                            </div>
                            <a v-if="entry_launch.external_type === 0" @click.prevent="handleModalEditEntryLaunchItem(item, index)" class="dropdown-item">
                              <i class="fas fa-edit text-warning main-icon-menu"></i>
                              Editar
                            </a>
                            <a v-if="entry_launch.external_type === 0 && entry_launch_types[entry_launch.product_service_hub_type_id] !== 'Devolução de Receita'" @click.prevent="deleteItem(index)" class="dropdown-item">
                              <i class="fas fa-times text-danger main-icon-menu"></i>
                              Excluir
                            </a>
                            <a
                              v-show="
                                entry_launch.entry_company_plant_id &&
                                [
                                  'Serviço',
                                  'Produtos e Serviços',
                                  'Financiamento',
                                  'Frete',
                                  'Tributos Automáticos',
                                  'Empréstimo',
                                  'Taxas e Tributos',
                                ].includes(entry_launch_types[entry_launch.product_service_hub_type_id])
                              "
                              @click="handleModalEquipmentApportionment(item, index)"
                              class="dropdown-item"
                            >
                              <img height="20px" src="/img/icons/icons8/ios/split_success.png" class="mr-3 ml-n1" />
                              Rateio
                            </a>
                            <a
                              v-if="
                                entry_launch.external_type === 1 &&
                                !default_product_service_hub_types.includes(entry_launch.product_service_hub_type_id)
                              "
                              @click.prevent="handleModalSearchProductServiceHub(index)"
                              class="dropdown-item d-flex align-items-center"
                            >
                              <i class="fas fa-exchange-alt text-primary main-icon-menu"></i>
                              Reassociar
                            </a>
                          </base-dropdown>
                        </div>
                        <div v-else class="col-3 text-right pl-0">
                          <base-button
                            style="padding: 0.32rem 0.5rem !important"
                            v-if="selected_items.includes(index)"
                            @click.prevent="removeFromSelectedItems(index)"
                            class="float-right"
                            type="success"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                          <base-button
                            style="padding: 0.32rem 0.5rem !important; background-color: white"
                            v-else
                            @click.prevent="addToSelectedItems(index)"
                            class="float-right"
                          >
                            <i class="fas fa-check text-white" />
                          </base-button>
                        </div>
                        <div class="col-12 justify-content-center pr-0 mt-2">
                          <h3 class="new-default-black-font mb-0 fs-16 font-weight-500">
                            {{ item.product_service_hub.product_name }}
                          </h3>
                          <div v-if="item.product_service_hub.product_service_hub_type_id === 2">
                            <h3 class="mb-0 new-default-black-font fs-16 font-weight-400">
                              {{ item.product_service_hub.supplier_name }}
                            </h3>
                            <h3 class="mb-1 new-default-black-font fs-16 font-weight-400">({{ item.product_service_hub.origin_name }})</h3>
                          </div>
                        </div>
                        <div class="row col-12 p-0 m-0 mt-3">
                          <div class="col-4">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Quantidade</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">
                              {{ item.quantity }} {{ item.product_service_hub.purchase_unit }}
                            </h5>
                          </div>
                          <div class="col-4 p-0">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor unitário</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">
                              {{ toMoneyWithThreeDecimals(item.price) }} / {{ item.product_service_hub.purchase_unit }}
                            </h5>
                          </div>
                          <div class="col-4 text-right">
                            <h5 class="mb-0 new-default-black-font font-weight-400 fs-12">Valor final</h5>
                            <h5 class="mb-0 new-default-black-font font-weight-500 fs-16">{{ item.total_value | currency }}</h5>
                          </div>
                          <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mx-3 mt-3 mb-1 col" />
                        </div>
                      </div>
                      <div>
                        <div
                          v-show="[ProductServiceHubTypeEnum.keys.CMC, ProductServiceHubTypeEnum.keys.PRODUCT_SERVICE].includes(entry_launch.product_service_hub_type_id) && operation_source && operation_source.is_generate_stock"
                          class="w-100 mt-3"
                        >
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'stock_location_id', item.product_service_hub.is_fuel)"
                              v-if="entry_launch.product_service_hub_type_id !== 2"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="item.product_service_hub.is_fuel ? fuel_tank_stock_locations : stock_locations"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                            <puzl-select
                              v-else
                              @input="applyToAllItems($event, 'stock_location_id')"
                              placeholder="Local de Estoque"
                              v-model="item.stock_location_id"
                              :items="getStockLocationsByCmcCategory(item.cmc_category_id ? item.cmc_category_id :  item.product_service_hub.cmc_category_id)"
                              :clearable="false"
                              :loading="loadingStockLocation"
                              :disabled="loadingStockLocation"
                            />
                          </base-input>
                        </div>
                        <div class="w-100 mt-2">
                          <base-input input-classes="form-control-sm">
                            <puzl-select
                              @input="applyToAllItems($event, 'chart_account_id')"
                              placeholder="Plano de Contas"
                              v-model="item.chart_account_id"
                              :items="$_chart_accounts"
                              :clearable="false"
                              :labelMask="true"
                              label="$full_code$ $name$"
                              :selectable="(i) => i.extra_field !== null"
                              :disabled="loadingChartAccount"
                              extraField="chart_account_parent_uuid"
                            />
                          </base-input>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
              </div>
            </div>

            <!-- Frete -->
            <div v-show="tab_value === 'transport'">
              <div class="row">
                <div class="col-md-6">
                  <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                    <img height="30px" src="/img/icons/icons8/ios/in-transit_primary.png" class="mr-3" />
                    Transporte
                  </h3>
                </div>
                <div class="col-md-6">
                  <base-button-hoverable v-if="freight && freight.observations" size="sm" type="success" icon="plus--v1" platform="ios">
                  </base-button-hoverable>
                </div>
                <div class="col-12">
                  <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <div class="row m-0">
                      <div class="col-6">
                        <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                          Transportadora
                          <base-button
                            v-if="freight.freight_modality_id"
                            @click.prevent="getFreight"
                            size="sm"
                            type="primary"
                            class="text-uppercase ml-2"
                          >
                            <i class="fas fa-search" />
                          </base-button>
                        </h3>
                      </div>
                      <div class="col-6 d-flex justify-content-end">
                        <div v-if="['MCC' , 'Produtos e Serviços'].includes(entry_launch_types[entry_launch.product_service_hub_type_id]) && !freight.entity_id" class="col-6 p-0">
                          <base-button-hoverable
                            @click="handleModalSearchEntryLaunch"
                            size="sm"
                            type="success"
                            icon="plus--v1"
                            platform="ios"
                          >
                            Incluir
                          </base-button-hoverable>
                        </div>
                        <div v-if="freight.entity_id" class="col-6 p-0 ml-2">
                          <base-button-hoverable
                            @click="handleFreightDelete(entry_launch.id)"
                            size="sm"
                            type="danger"
                            icon="delete"
                            platform="ios"
                          >
                            Excluir
                          </base-button-hoverable>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                    </div>
                    <div v-if="freight.freight_modality_id" class="border-left border-indigo border-3 mx-3 mt-1">
                      <h4 class="mb-0 col-12 pl-2">{{ freight_entity.entity_name }}</h4>
                      <h4 class="mb-0 col-12 font-weight-normal pl-2">{{ freight_entity.document }}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="px-4">
                    <div class="row">
                      <label class="col-6 mb-1 col-form-label form-control-label"> Frete </label>
                      <div class="col-6 pr-md-0">
                        <el-select
                          @change="getFreight"
                          v-model="freight.freight_modality_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          class="w-100"
                          disabled
                        >
                          <el-option
                            v-for="freight_modality in freight_modalities"
                            :key="freight_modality.id"
                            :label="`${freight_modality.code} - ${freight_modality.name}`"
                            :value="freight_modality.id"
                          >
                          </el-option>
                          <el-option :value="null" label="9 - Sem frete"> </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div v-if="freight.freight_modality_id">
                      <div class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label">
                          Quantidade <span class="text-danger">&nbsp;*</span></label
                        >
                        <div class="col-6 pr-md-0">
                          <div class="w-100 pr-md-0">
                            <base-input input-group-classes="input-group-sm">
                              <input
                                inputmode="numeric"
                                v-on:input="freight.quantity = formatString($event.target.value, '0', '99999999')"
                                v-model="freight.quantity"
                                class="form-control form-control-sm"
                              />
                            </base-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label">
                          Valor do frete <span class="text-danger">&nbsp;*</span></label
                        >
                        <div class="col-6 pr-md-0">
                          <div class="w-100 pr-md-0">
                            <base-input input-group-classes="input-group-sm">
                              <input
                                :disabled="entry_launch.external_type === 1"
                                v-money="money"
                                v-model.lazy="freight.total_value"
                                maxlength="10"
                                inputmode="numeric"
                                class="form-control form-control-sm"
                              />
                              <template slot="prepend">
                                <small> R$ </small>
                              </template>
                            </base-input>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <label class="col-6 mb-1 col-form-label form-control-label"> Foto do frete </label>
                        <div class="col-6 pr-md-0">
                          <div class="w-100 pr-md-0">
                            <base-input input-group-classes="input-group-sm">
                              <InputFile :multiple="false" ref="inputFreightFile" />
                            </base-input>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Frete pendente -->
                    <div v-else class="row">
                      <label class="col-6 mb-1 col-form-label form-control-label"> Frete pendente </label>
                      <div class="col-6 pr-md-0 d-flex align-items-center justify-content-end">
                        <base-input input-classes="form-control-sm">
                          <base-switch
                            v-model="entry_launch.pending_freight"
                            type="success"
                            offText="Não"
                            onText="Sim"
                            :disabled="entry_launch.product_service_hub_type_id === 4"
                          >
                          </base-switch>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Motorista -->
              <div class="row">
                <div class="col-md-6">
                  <div class="card py-3 px-3">
                    <div class="row m-0">
                      <label class="col-6 mb-1 mb-md-0 col-form-label form-control-label"> Motorista (Insumos) </label>
                      <div class="col-md-6 d-flex align-items-center w-100">
                        <div class="w-100">
                          <PuzlSelect v-model="entry_launch.driver_id" :items="$_drivers" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Pagamento -->
            <div v-show="tab_value === 'payment'">
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center">
                      <img height="30px" src="/img/icons/icons8/ios/info-squared_warning.png" class="mr-3" />
                      IDENTIFICAÇÃO
                    </h3>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />

                    <!-- Conta Bancária -->
                    <div class="row">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Conta Bancária <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingBankAccount"
                          v-model="entry_launch.bank_account_id"
                          @change="$forceUpdate()"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option
                            v-for="bank_account in bank_accounts"
                            :key="bank_account.bank_account_id"
                            :label="bank_account.bank_account.name"
                            :value="bank_account.bank_account_id"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Método de pagamento -->
                    <div class="row mt-2">
                      <label class="col-md-6 new-default-black-font mb-0 d-flex align-items-center">
                        Método de pagamento <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <el-select
                          class="w-100"
                          :disabled="loadingPaymentMethod"
                          v-model="entry_launch.payment_method_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                        >
                          <el-option v-for="method in $_payment_methods" :key="method.id" :label="method.name" :value="method.id">
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <!-- Valor total da nota -->
                    <div class="row mt-4">
                      <h3 class="col-6">Valor total da nota</h3>
                      <h3 class="col-6 text-right">{{ entry_launch.total_value | currency() }}</h3>
                    </div>

                    <!-- Valor final -->
                    <div class="row mt-2">
                      <h3 class="col-6 text-primary">Valor final</h3>
                      <h3 class="col-6 text-right text-primary">{{ entry_launch.final_value | currency() }}</h3>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card py-4 px-3">
                    <div class="row">
                      <h3 style="line-height: normal !important" class="new-default-black-font mb-0 d-flex align-items-center col-md-6">
                        <img height="30px" src="/img/icons/icons8/ios/how-many-quest--v2_success.png" class="mr-3" />
                        PARCELAS
                      </h3>
                      <div class="col-md-3" />
                      <div class="col-md-3">
                        <input
                          :disabled="entry_launch.has_agroup"
                          @change="changeInstallments"
                          v-on:input="installment_quantity = formatString($event.target.value, '0', '99')"
                          v-model="installment_quantity"
                          inputmode="numeric"
                          class="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <hr style="background: rgba(0, 0, 0, 0.05) !important" class="new-default-black mt-3 mb-4" />
                    <Installments
                      @applyDueDate="applyDueDateToAllInstallments"
                      ref="installments"
                      @getFinalValue="getFinalValue"
                      :items="bill_pay_installments"
                      :entry_launch="entry_launch"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <base-button
              class="col-2"
              type="primary"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 3.4rem !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="goToNextTab"
              v-if="!getNextTabButtonStatus()"
            >
              <img height="30px" src="/img/icons/icons8/ios/right-squared.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">PRÓXIMO</span>
            </base-button>
            <base-button
              v-else
              type="success"
              :loading="loadingStore"
              style="bottom: 130px !important; right: 50px !important; position: fixed; z-index: 9999; max-width: 11%"
              @click.prevent="update"
              :disabled="loading || loadingInstallment"
            >
              <img height="30px" src="/img/icons/save.png" class="mr-md-3 ml-n2 ml-md-0" />
              <span class="show-md" style="font-size: 0.75rem">SALVAR</span>
            </base-button>
          </div>
        </card>
      </div>
    </div>

    <ModalCreateEntryLaunchItem @added="addedItem" ref="modalCreateEntryLaunchItem" />
    <ModalEditEntryLaunchItem @updated="updatedItem" ref="modalEditEntryLaunchItem" />
    <ModalSearchEntity @selected="selectedEntity" ref="modalSearchEntity" />
    <ModalEditProductServiceHub @updated="updatedProductServiceHub" ref="modalEditProductServiceHub" />
    <ModalSearchProductServiceHub @selected="associatedItem($event, false)" ref="modalSearchProductServiceHub" />
    <ModalEntityAccount ref="entityAccount" />
    <ModalPurchaseOrderAssociation v-if="!loading" @save="savePurchaseOrderAssociation" :entity_document="entity.document" :items="items" ref="purchaseOrderAssociation" />
    <ModalEquipmentApportionment @added="setEquipmentApportionments" ref="modalEquipmentApportionment" />
    <ModalSearchEntryLaunch :must_save="false" @selected="storeSelectedFreight" ref="modalSearchEntryLaunch" />
    <ModalSearchChildren @updated="refreshEntryLaunch" ref="modalSearchChildren" />
    <ModalOperationSourceCreate @created="getOperationSources" ref="modalOperationSourceCreate" />
    <ModalTaxes :disabled="entry_launch.external_type === 1" @onSave="setTax" ref="modalTaxes" />
  </div>
</template>

<script>
import PuzlBreadcrumb from "@/components/PuzlBreadcrumb";
import { mapGetters } from "vuex";
import BackButton from "@/components/Utils/BackButton";
import InputDateTimePicker from "@/components/InputDateTimePicker";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import { checkNumberValue, moneyToFloat } from "@/helpers";
import { mask1, formatErrorValidation } from "@/plugins";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import ModalCreateEntryLaunchItem from "../Item/_ModalCreate";
import ModalEditEntryLaunchItem from "../Item/_ModalEdit";
import SkeletonPuzlFullWidth from "@/components/SkeletonPuzlFullWidth";
import ModalEditProductServiceHub from "../../ProductService/Shared/_ModalEdit.vue";
import ModalSearchEntity from "../../Entity/Shared/_ModalSearchEntity";
import ModalSearchProductServiceHub from "../../ProductService/Shared/_ModalSearchProductServiceHub.vue";
import { VMoney } from "v-money";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import billPayMixin from "../../../Financial/BillPay/Mixins/bill_pay_mixin";
import entryLaunchMixin from "../Mixins/entry_launch_mixin";
import ModalEntityAccount from "@/views/Modules/Configuration/Entity/EntityAccount/Index";
import ModalPurchaseOrderAssociation from "./_ModalPurchaseOrderAssociation";
import PuzlSelect from "@/components/PuzlSelect";
import ModalEquipmentApportionment from "../Item/_ModalEquipmentApportionment.vue";
import InputLimit from "@/components/Utils/InputLimit";
import Installments from "./_Installments";
import ModalSearchEntryLaunch from "./_ModalSearchEntryLaunch";
import Navegation from "./_Navegation";
import BaseButtonHoverable from "@/components/Utils/BaseButtonHoverable.vue";
import ModalSearchChildren from "./_ModalSearchChildren";
import ModalOperationSourceCreate from "@/views/Modules/Fiscal/OperationSource/Shared/_ModalCreate";
import ModalTaxes from "../../../../../modules/configuration/entry-launch/aggregates/entry-launch-item-tax/views/shared/ModalTaxes.vue";
import EntryLaunchItemStockStatusLabel from "../../../../../modules/configuration/entry-launch/views/shared/EntryLaunchItemStockStatusLabel.vue";
import { useEntryLaunchStockHook } from "../../../../../modules/configuration/entry-launch/hooks/useEntryLaunchStockHook";
import { EntryLaunchItemStockStatusEnum } from "../../../../../modules/configuration/entry-launch/enums/EntryLaunchItemStockStatusEnum";
import ProductServiceHubTypeEnum from "../../../../../modules/configuration/product-service-hub-type/enums/ProductServiceHubTypeEnum";
import Vue from 'vue';

export default {
  name: "EditEntryLaunch",
  mixins: [billPayMixin, entryLaunchMixin],
  components: {
    BackButton,
    PuzlBreadcrumb,
    InputDateTimePicker,
    InputFile,
    ModalCreateEntryLaunchItem,
    PuzlEmptyData,
    ModalEditEntryLaunchItem,
    SkeletonPuzlFullWidth,
    ModalSearchEntity,
    ModalEditProductServiceHub,
    SkeletonPuzlGrid,
    ModalSearchProductServiceHub,
    ModalEntityAccount,
    ModalPurchaseOrderAssociation,
    PuzlSelect,
    ModalEquipmentApportionment,
    InputLimit,
    Installments,
    ModalSearchEntryLaunch,
    Navegation,
    BaseButtonHoverable,
    ModalSearchChildren,
    ModalOperationSourceCreate,
    ModalTaxes,
    EntryLaunchItemStockStatusLabel,
  },
  data() {
    return {
      tab: 0,
      entry_launch: {
        status: 1,
        product_service_hub_type_id: null,
        company_plant_id: null,
        entry_date: null,
        issue_date: null,
        document_template_id: null,
        observations: null,
        final_value: 0,
        total_value: 0,
        discount: 0,
        cost_center_id: null,
        series: null,
        nature_operation: null,
        payment_method_id: null,
        bank_account_id: null,
        accounting: false,
        children_balance: 0,
        driver_id: null,
      },
      loadingStore: false,
      loadingCompanyPlant: true,
      loadingDocumentTemplate: true,
      loading: true,
      loadingItems: true,
      loadingCompanyPlantBuyer: true,
      loadingProductServiceHubType: true,
      loadingCostCenter: true,
      loadingPaymentMethod: true,
      loadingInstallment: true,
      loadingBankAccount: true,
      loadingParents: true,
      hasOperationSource: true,
      company_plant_buyers: [],
      items: [],
      freight: {
        total_value: 0,
        photo: null,
        quantity: null,
        gross_weight: null,
        net_weight: null,
        plate: null,
        model: null,
        species: null,
        entity_id: null,
        freight_modality_id: null,
      },
      bill_pay_installments: [],
      bank_accounts: [],
      installment_quantity: null,
      freight_entity: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false,
      },
      hasPaidBillPay: false,
      refreshLoading: false,
      ProductServiceHubTypeEnum: ProductServiceHubTypeEnum,
    };
  },
  directives: { money: VMoney },
  computed: {
    ...mapGetters({
      company_plants: "plant/activeItems",
      $_document_templates: "documentTemplate/fetch",
      $_product_service_hub_types: "productServiceHubType/fetch",
      $_cost_centers: "costCenter/fetch",
      $_payment_methods: "paymentMethod/fetch",
      $_chart_accounts: "managerialChartAccount/fetch",
      $_operation_sources: "operationSource/fetch",
      $_parents: "entryLaunch/getParents",
      $_drivers: "user/getEntryDrivers",
      $_general_settings: "generalSetting/show",
    }),
  },
  watch: {
    items() {
      if (this.loadingInstallment === false) {
        this.entry_launch.total_value = this.items.reduce((partialSum, a) => partialSum + parseFloat(a.total_value), 0);
        this.getFinalValue();
      }
    },
  },
  methods: {
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    handleModalCreateEntryLaunchItem() {
      this.$refs.modalCreateEntryLaunchItem.openModal(this.entry_launch.product_service_hub_type_id, this.entry_launch.entity_id);
    },
    handleModalEditEntryLaunchItem(item, index) {
      this.$refs.modalEditEntryLaunchItem.openModal(item, index);
    },
    handleModalEditProductServiceHub(id) {
      this.$refs.modalEditProductServiceHub.handleEditModal(id);
    },
    handleModalSearchProductServiceHub(index) {
      this.associate_index_item = index;
      this.$refs.modalSearchProductServiceHub.openModal(this.entry_launch.entity_id, this.entry_launch.product_service_hub_type_id);
    },
    addedItem(item) {
      item.product_name = item.product_service_hub.product_name;
      item.category_name = item.product_service_hub.category_name;
      item.type = item.product_service_hub.product_service_hub_type_id;
      item.supplier_name = item.product_service_hub.supplier_name;
      item.origin_name = item.product_service_hub.origin_name;
      item.stock_status = EntryLaunchItemStockStatusEnum.keys.NO_STOCK;

      /* Se o item for do tipo combustível será marcado */
      if (item.product_service_hub.is_fuel) {
        this.getFuelTankStockLocations();
      }

      if(this.operation_source){
        useEntryLaunchStockHook().setItemsStockStatus([item], this.entry_launch.product_service_hub_type_id, this.operation_source.is_generate_stock);
      }

      this.items.push(item);
    },
    updatedItem(item, index) {
      item.product_name = item.product_service_hub.product_name;
      item.category_name = item.product_service_hub.category_name;
      item.product_service_hub_type_id = item.product_service_hub.product_service_hub_type_id;
      item.supplier_name = item.product_service_hub.supplier_name;
      item.origin_name = item.product_service_hub.origin_name;
      delete item.tax;
      this.items.splice(index, 1, item);
    },
    checkItemAlreadyExists(item) {
      return this.items.findIndex((payload) => payload.product_service_hub_id === item.product_service_hub_id) >= 0;
    },
    getFreight() {
      let current_freight_modality_id = this.freight.freight_modality_id;
      switch (true) {
        case current_freight_modality_id &&
          this.freight_modalities.filter(function (item) {
            return item.id === current_freight_modality_id && parseInt(item.code) === 0;
          }).length === 1:
          this.freight_entity = this.entity;
          this.freight.entity_id = this.entity.id;
          break;
        case current_freight_modality_id !== null:
          this.handleSelectEntity();
          break;
      }
    },
    deleteItem(index) {
      this.items.splice(index, 1);
    },
    async changeInstallments(show_message = true) {
      this.bill_pay_installments = [];
      let val = parseInt(this.installment_quantity);
      switch (true) {
        /* caso não haja parcelas a serem manipuladas */
        case !val:
          this.installment_quantity = 1;
          if (show_message) {
            this.$notify({ type: "danger", message: "Deve haver ao menos uma parcela!" });
          }
          await this.generateInstallments(1);
          break;
        default:
          await this.generateInstallments(val);
      }
      this.$refs.installments.resetPage();
    },
    generateInstallments(to_generate) {
      /* valor total informado pelo usuário */
      let bill_pay_total_value = this.entry_launch.total_value;
      /* Valor por parcela */
      let value_per_installment = bill_pay_total_value ? (bill_pay_total_value / to_generate).toFixed(2) : 0;

      for (let i = 0; i < to_generate; i++) {
        let date = this.entry_launch.due_date
          ? moment(this.entry_launch.due_date).add(i, "months").format("YYYY-MM-DD hh:mm:ss")
          : moment()
              .add(i + 1, "months")
              .format("YYYY-MM-DD hh:mm:ss");
        this.bill_pay_installments.push({
          status: 0,
          index: i + 1,
          due_date: date,
          original_due_date: date,
          paid_at: null,
          discounts_fees: null,
          fees_fines: null,
          final_value: value_per_installment,
          total_value: value_per_installment,
        });
      }
      let real_total_value = this.bill_pay_installments.reduce(function (a, item) {
        return a + parseFloat(item.total_value);
      }, 0);

      /** Diferença de valor real e teórico */
      let difference = this.$helper.toDecimal(real_total_value - bill_pay_total_value, 2, true);

      if (difference !== 0) {
        /** Checa integridade do valor */
        switch (true) {
          case difference > 0:
            this.bill_pay_installments[0].total_value = Number(parseFloat(this.bill_pay_installments[0].total_value) - difference).toFixed(
              2
            );
            this.bill_pay_installments[0].final_value = Number(parseFloat(this.bill_pay_installments[0].final_value) - difference).toFixed(
              2
            );
            break;
          case difference < 0:
            this.bill_pay_installments[0].total_value = Number(
              parseFloat(this.bill_pay_installments[0].total_value) + Math.abs(difference)
            ).toFixed(2);
            this.bill_pay_installments[0].final_value = Number(
              parseFloat(this.bill_pay_installments[0].final_value) + Math.abs(difference)
            ).toFixed(2);
            break;
        }
      }
      return difference;
    },
    getFinalValue(regenerate_installments = true) {
      /** Iguala valor total á valor final. */
      this.entry_launch.final_value = this.entry_launch.total_value;
      if (regenerate_installments) {
        this.installment_quantity = 1;
        this.changeInstallments(false);
      } else {
        /* Valores a partir da parcela */
        this.bill_pay_installments.map(function (item) {
          let final_value = item.total_value.includes(",") ? moneyToFloat(item.total_value) : parseFloat(item.total_value);
          /** Caso haja taxas á retirar do valor total */
          if (item.discounts_fees && item.discounts_fees !== "0,00") {
            let discount = item.discounts_fees.includes(",") ? moneyToFloat(item.discounts_fees) : parseFloat(item.discounts_fees);
            final_value -= discount;
            if (final_value < 0) {
              this.$notify({ type: "danger", message: "O valor do desconto deve ser menor que o valor total da parcela." });
              final_value += discount;
              discount = 0;
              item.discounts_fees = 0;
            }
            this.entry_launch.final_value = Number(this.entry_launch.final_value) - discount;
          }
          /** Caso haja juros á acrescentar do valor total */
          if (item.fees_fines && item.fees_fines !== "0,00") {
            let fees = item.fees_fines.includes(",") ? moneyToFloat(item.fees_fines) : parseFloat(item.fees_fines);
            final_value += fees;
            this.entry_launch.final_value = Number(this.entry_launch.final_value) + fees;
          }
          item.final_value = final_value.toFixed(2);
        }, this);
      }
    },
    finalFormat() {
      /** payload inicial */
      let payload = {
        entry_launch: { ...this.entry_launch },
        items: JSON.parse(JSON.stringify(this.items)),
        freight: { ...this.freight },
        bill_pay_installments: JSON.parse(JSON.stringify(this.bill_pay_installments)),
      };
      let formData = new FormData();

      /** formata preços */
      payload.items.filter(function (obj) {
        obj.price = obj.price.includes(",") ? obj.price.replaceAll(".", "").replace(",", ".") : obj.price;
      });

      /** caso tenha frete */
      if (payload.freight && payload.freight.entity_id) {
        payload.freight.total_value = payload.freight.total_value.replaceAll(".", "").replace(",", ".");
        /** Tratando anexo */
        switch (true) {
          /** Caso seja um anexo nativo */
          case this.$refs.inputFreightFile.fileRecords.length > 0 && this.$refs.inputFreightFile.fileRecords[0].lastModified > 0:
            formData.append("freight[photo]", this.$refs.inputFreightFile.fileRecords[0].file);
            break;
          /** Caso não haja anexos */
          case this.$refs.inputFreightFile.fileRecords.length < 1:
            payload.freight.photo = null;
            break;
          /** Caso não haja alterações nos anexos presentes */
          case payload.freight.photo !== null:
            delete payload.freight.photo;
            break;
        }
        /** Adicionando frete a formData */
        for (let key of Object.keys(payload.freight)) {
          formData.append(`freight[${key}]`, payload.freight[key] !== null ? payload.freight[key] : "");
        }
      }

      payload.entry_launch.entry_date = moment(this.entry_launch.entry_date).format("YYYY-MM-DD HH:mm:ss");
      payload.entry_launch.issue_date = moment(this.entry_launch.issue_date).format("YYYY-MM-DD");
      payload.entry_launch.pending_freight = this.entry_launch.pending_freight ? 1 : 0;
      payload.entry_launch.accounting = this.entry_launch.accounting ? 1 : 0;

      /** Tratando anexo */
      switch (true) {
        /** Caso seja um anexo nativo */
        case this.$refs.inputEntryLaunchFile.fileRecords.length > 0 && this.$refs.inputEntryLaunchFile.fileRecords[0].lastModified > 0:
          formData.append("entry_launch[photo]", this.$refs.inputEntryLaunchFile.fileRecords[0].file);
          break;
        /** Caso não haja anexos */
        case this.$refs.inputEntryLaunchFile.fileRecords.length < 1:
          payload.entry_launch.photo = null;
          break;
        /** Caso não haja alterações nos anexos presentes */
        case payload.entry_launch.photo !== null:
          delete payload.entry_launch.photo;
          break;
      }

      /** Adicionando lançamento ao formData */
      for (let key of Object.keys(payload.entry_launch)) {
        formData.append(`entry_launch[${key}]`, payload.entry_launch[key] !== null ? payload.entry_launch[key] : "");
      }

      /** Adicionando items ao formData */
      payload.items.map(function (obj, index) {
        obj.quantity = obj.quantity.replace(",", ".");
        for (let key of Object.keys(payload.items[index])) {
          formData.append(`items[${index}][${key}]`, payload.items[index][key] !== null ? payload.items[index][key] : "");
        }
        if (obj.equipment_apportionments) {
          obj.equipment_apportionments.map(function (item, itemIndex) {
            if (item.value.includes(",")) item.value = moneyToFloat(item.value);
            for (let key of Object.keys(obj.equipment_apportionments[itemIndex])) {
              formData.append(
                `items[${index}][equipment_apportionments][${itemIndex}][${key}]`,
                obj.equipment_apportionments[itemIndex][key] !== null ? obj.equipment_apportionments[itemIndex][key] : ""
              );
            }
          });
        }
        // Impostos
        if(obj.tax) {
          obj.tax = this.getEntryLaunchItemTaxTransformed(obj.tax);
          for (let key of Object.keys(obj.tax)) {
            formData.append(`items[${index}][tax][${key}]`, obj.tax[key] !== null ? obj.tax[key] : "");
          }
        }
      }, this);

      payload.bill_pay_installments.map(function (obj, index) {
        obj.bank_account_id = obj.bank_account_id ? obj.bank_account_id : payload.entry_launch.bank_account_id;
        obj.due_date = obj.due_date ? moment(obj.due_date).format("YYYY-MM-DD") : null;
        payload.bill_pay_installments[index].original_due_date = payload.bill_pay_installments[index].original_due_date
          ? moment(payload.bill_pay_installments[index].original_due_date).format("YYYY-MM-DD")
          : null;
        obj.final_value = this.normalizeValue(obj.final_value);
        obj.total_value = this.normalizeValue(obj.total_value);
        obj.discounts_fees = obj.discounts_fees !== "0,00" ? this.normalizeValue(obj.discounts_fees) : null;
        obj.fees_fines = obj.fees_fines !== "0,00" ? this.normalizeValue(obj.fees_fines) : null;
        for (let key of Object.keys(payload.bill_pay_installments[index])) {
          formData.append(
            `bill_pay_installments[${index}][${key}]`,
            payload.bill_pay_installments[index][key] !== null ? payload.bill_pay_installments[index][key] : ""
          );
        }
      }, this);
      formData.append("dont_has_financial", this.dontHasFinancial ? 1 : 0);
      formData.append("freight_by_entry_launch", this.freight_by_entry_launch ? 1 : 0);
      formData.append("_method", "PUT");
      return formData;
    },
    async update() {
      if (this.$_general_settings.enable_oc_validation_on_invoice_entry && this.items.some(item => !item.order_id)) {
        return this.$notify({
          type: "warning",
          message: "Todos os itens devem estar vinculados a uma ordem de compra.",
        });
      }
      if (this.hasPaidBillPay) {
        return this.$notify({
          type: "danger",
          message: "Existem parcelas pagas vinculadas a esta solicitação, operação não permitida.",
        });
      }
      this.loadingStore = true;
      let payload = await this.finalFormat();
      if (!this.formDataLengthIsValid(payload)) {
        return this.$notify({
          type: "danger",
          message: "Nota indisponível, favor entrar em contato com o suporte.",
        });
      }
      this.$store
        .dispatch("entryLaunch/update", payload, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.loadingStore = false;
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
          this.$router.push("/configuration/entry-launch");
        })
        .catch((error) => {
          if (error.status) {
            this.$notify({
              type: "danger",
              message: error.data.message,
            });
          } else if (error.response && error.response.status && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors,
            });
          }
          this.loadingStore = false;
        });
    },
    handleSelectEntity() {
      this.$refs.modalSearchEntity.openModal();
    },
    updatedProductServiceHub() {
      this.loadingItems = true;
      this.getEntryLaunchItems();
    },
    selectedEntity(entity) {
      this.freight_entity = entity;
      this.freight.entity_id = entity.id;
    },
    getEntryLaunchItems() {
      this.$store.dispatch("entryLaunchItem/getByEntryLaunch", this.$route.params.id).then((response) => {
        this.items = response.data;
        let general_type = null;
        /* Caso seja do tipo não informado */
        if (this.entry_launch.product_service_hub_type_id === 8) {
          /* Verifica se existe algum tipo informado nos itens */
          general_type = this.items.filter((item) => item.product_service_hub_type_id !== 8);
          if (general_type[0]) {
            general_type = general_type[0].product_service_hub_type_id;
            this.entry_launch.product_service_hub_type_id = general_type;
          }
        }
        this.items.map(function (item, key) {
          item.quantity = item.quantity.replace(".", ",");
          this.items[key].product_service_hub = {
            product_service_hub_type_id: general_type || item.product_service_hub_type_id,
            category_name: item.category_name,
            product_name: item.product_name,
            purchase_unit: item.purchase_unit,
            ncm: item.ncm,
            supplier_name: item.supplier_name,
            origin_name: item.origin_name,
            type_name: item.type_name,
            is_fuel: item.is_fuel,
          };
        }, this);
        this.$store.dispatch("billPay/getByEntryLaunch", this.$route.params.id).then(async (response) => {
          /* Informa que a nota atual possui ao menos uma parcela paga */
          this.hasPaidBillPay = response.data.findIndex((item) => item.status === 1) !== -1;
          this.installment_quantity = response.data.length;
          if (this.installment_quantity > 0) {
            this.entry_launch.payment_method_id = response.data[0].payment_method_id;
            this.entry_launch.bank_account_id = response.data[0].bill_pay_bank_account_id;
          }
          await this.getCompanyPlantBuyers();
          this.bill_pay_installments = response.data;
          this.bill_pay_installments.map(function (item) {
            item.due_date += " 00:00:00";
            item.original_due_date = item.original_due_date ? item.original_due_date + " 00:00:00" : null;
            item.final_value = Number(item.final_value).toFixed(2);
            item.total_value = Number(item.total_value).toFixed(2);
          });
          this.loadingInstallment = false;
          this.getFinalValue(this.bill_pay_installments.length === 0);
          this.checkPayment();
        });
        this.getStockLocations(false);
        this.loadingItems = false;
        this.getEquipments();
      });
    },
    /* Reset : Caso seja para resetar o campo cnpj comprador */
    getCompanyPlantBuyers(reset = false) {
      this.entry_launch.company_plant_buyer_id = reset ? null : this.entry_launch.company_plant_buyer_id;
      this.loadingCompanyPlantBuyer = true;
      this.$store.dispatch("companyPlantBuyer/getByCompanyPlant", this.entry_launch.company_plant_id).then((response) => {
        this.company_plant_buyers = response.data || [];
        this.loadingCompanyPlantBuyer = false;
        this.getBankAccounts(this.entry_launch);
        if (!this.entry_launch.entry_company_plant_id) this.entry_launch.entry_company_plant_id = this.entry_launch.company_plant_id;
        if (this.company_plant_buyers.length && reset === true) {
          let default_company_plant_buyer =
            this.company_plant_buyers.filter((item) => item.default === 1)[0] || this.company_plant_buyers[0];
          this.entry_launch.company_plant_buyer_id = default_company_plant_buyer.id;
        } else if (reset === true) {
          this.entry_launch.company_plant_buyer_id = null;
        }
        this.getParentsWithChildrenBalance();
      });
    },
    handleEntityAccount() {
      this.$refs.entityAccount.handleCreateModal(this.entity.id);
    },
    handlePurchaseOrderAssociation() {
      this.$refs.purchaseOrderAssociation.openModal();
    },
    savePurchaseOrderAssociation(items) {
      this.items = items;
    },
    handleIgnoreOrder(item, index) {
      Vue.set(this.items, index, { ...item, ignore_order: true });
    },
    /**
     * Define se botão de próxima aba deve ser habilitado
     *
     * @returns {bool}
     */
    getNextTabButtonStatus() {
      switch (true) {
        case this.tab_value === "payment":
        case this.tab_value === "document" && (this.loadingItems || this.loadingStockLocation):
        case this.tab_value === "transport" &&
          (this.bill_pay_installments.length < 1 ||
            this.entry_launch.total_value <= 0 ||
            this.loadingInstallment ||
            this.entry_launch.has_agroup ||
            this.entry_launch.status === 7 ||
            this.dontHasFinancial):
          return true;
        default:
          return false;
      }
    },

    /**
     * Copia chave de acesso
     *
     * @param {string} access_key
     *
     * @returns void
     */
    copyAccessKey(access_key) {
      this.$helper.copy(access_key);
      this.$notify({ type: "success", message: `Chave de acesso copiada com sucesso!` });
    },
    handleModalSearchChildren(entry_launch_id) {
      this.$refs.modalSearchChildren.openModal(entry_launch_id);
    },
    async refreshEntryLaunch() {
      const loader = this.$loading.show();
      this.refreshLoading = true;
      await this.$store.dispatch("entryLaunch/show", this.$route.params.id).then((response) => {
        this.entry_launch.children_balance = response.data.children_balance;
        loader.hide();
        this.refreshLoading = false;
      });
    },
    setTax(tax, totalValue) {
      this.items[tax.line_number].tax = tax;
      this.items[tax.line_number].total_value = totalValue;
      this.entry_launch.total_value = this.items.reduce((partialSum, a) => partialSum + a.total_value, 0);
      this.getFinalValue();
    },
  },
  async mounted() {
    this.loadingOperationSource = true;
    this.$store.dispatch("entryLaunch/show", this.$route.params.id).then((response) => {
      if (response.data.freight) {
        this.freight_entity = response.data.freight.entity;
        delete response.data.freight.entity;
        this.freight = response.data.freight;
      }
      this.entity = response.data.entity;
      delete response.data.entity;
      delete response.data.freight;
      this.entry_launch = response.data;
      this.entry_launch.discount = this.entry_launch.discount ? this.entry_launch.discount : 0;
      this.entry_launch.issue_date = this.entry_launch.issue_date + " 00:00:00";
      if (this.entry_launch.photo) {
        this.$refs.inputEntryLaunchFile.setFileByObject(JSON.parse(this.entry_launch.photo), true);
      }
      if (!this.entry_launch.operation_source_id) this.hasOperationSource = false;
      this.entry_launch.discount = parseFloat(this.entry_launch.discount);
      this.loading = false;
      this.getEntryLaunchItems();
      this.$store
        .dispatch("operationSource/fetchItems", {
          filter: {
            product_service_hub_type_id: this.entry_launch.product_service_hub_type_id,
            type: 0,
          },
        })
        .then(() => {
          this.loadingOperationSource = false;
          this.checkFinancial();
          if (this.freight.photo) {
            this.$refs.inputFreightFile.setFileByObject(JSON.parse(this.freight.photo), true);
          }
        });
    });

    this.$store.dispatch("documentTemplate/fetch").then((response) => {
      this.loadingDocumentTemplate = false;
    });
    this.$store.dispatch("productServiceHubType/fetch").then(() => {
      this.loadingProductServiceHubType = false;
    });
    this.$store.dispatch("costCenter/fetchItems").then(() => {
      this.loadingCostCenter = false;
    });
    this.$store.dispatch("paymentMethod/fetchItems").then(() => {
      this.loadingPaymentMethod = false;
    });
    this.$store.dispatch("user/getEntryDrivers");
    this.$store.dispatch('generalSetting/show');
  },
};
</script>

<style>
.custom-entry-launch-prepend-input .form-group .input-group .input-group-prepend .input-group-text {
  padding: 0.35rem !important;
}
</style>
