<template>
  <div class="contract-proposal-edit-card">
    <div class="app-card-header">
      <div class="app-card-title-wrapper p-0">
        <AppIcon
          v-if="props.icon"
          :icon="props.icon"
          :color="props.color"
          width="24"
          height="24"
        />
        <h2 class="app-card-title">
          {{ props.title }}
        </h2>
        <slot name="contract-input">
          
        </slot>
      </div>

      <div class="col p-0 button-wrapper">
        <slot name="card-header-buttons"></slot>
      </div>

      <div
        class="col-12 d-flex justify-content-end mt-3 p-0"
        v-if="showAccordionButton"
      >
        <div @click="toggleOpen" class="d-flex align-items-center" style="gap: 8px;">
          {{ isOpen ? "Ocultar" : "Expandir" }}
          <img
            height="17px"
            src="/img/icons/icons8/ios/arrow-up.png"
            class="collapse-icon"
            :class="{ rotate: isOpen }"
          />
        </div>
      </div>
    </div>
    <div class="app-card-body" :class="{ show: isOpen }">
      <div class="app-card-body-wrapper">
        <slot></slot>
        </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { AppIcon } from "../../../../../../components/AppGlobal/";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: false,
  },
  color: {
    type: String,
    required: false,
  },
  showAccordionButton: {
    type: Boolean,
    required: false,
    default: false
  }
});

const isOpen = ref(true);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

onMounted(() => {
  if (props.showAccordionButton) {
    isOpen.value = false;
  }
})
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";
.contract-proposal-edit-card {
  background-color: white;
  margin-bottom: 16px;
  border-radius: 8px;
  border: 1px solid $muted-light;
  box-shadow: 0px 5px 10px 0px #0000000d;

  .app-card-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 21px 16px;
    border-bottom: 1px solid $muted-light;
    min-height: 73px;

    .app-card-title-wrapper {
      display: flex;
      align-items: center;
      @media screen and (max-width: 900px) {
        margin-bottom: 12px;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .app-card-title {
      margin-right: auto;
      font-family: Fredoka;
      font-size: 16px !important;
      font-weight: 500 !important;
      color: $dark;
      margin-bottom: 0;
      margin-left: 12px;
    }

    .collapse-icon{
      transform: rotate(180deg);
      transition: all ease-in-out 0.3s;

      &.rotate{
      transform: rotate(0);
      }
    }
  }

  .app-card-body {
    height: 0;
    transition: all ease-in-out .3s;

    &-wrapper {
      display: none;
    }

    &.show{
      height: auto;
      padding: 16px 16px 32px 48px;

      .app-card-body-wrapper{
        display: block;
      }
    }
  }
}
</style>
