import { AppSwitchVariants } from "../AppSwitchVariants";

/**
 * @typedef {Object} SwitchToogleLayoutType
 * @property {?string} label - O texto exibido para a opção.
 * @property {?string} icon - O ícone associado à opção. Utilize AppSwitchVariants.variants.icon
 * @property {?string} iconColor - A cor do ícone associado à opção. Utilize AppSwitchVariants.variants.iconColor
 * @property {?string} backgroundColor - A cor de fundo associada à opção.
 * @property {?string} labelColor - A cor do texto associada à opção.
 */

/**
 * @typedef {Object} SwitchToogleLayoutsType
 * @property {?SwitchToogleLayoutType} off - Configuração para o estado falso.
 * @property {?SwitchToogleLayoutType} on - Configuração para o estado verdadeiro.
 */
export const SwitchToogleLayoutsType = {};

/**
 * Inicializa um objeto do tipo SwitchToogleLayoutsType com valores padrão.
 * @returns {SwitchToogleLayoutsType}
 */
export const initSwitchToogleLayoutsType = () => {
  return {
    off: {
      label: "Inativo",
      icon: AppSwitchVariants.variants.icon.shutdown,
      iconColor: AppSwitchVariants.variants.iconColor.danger,
      backgroundColor: "#fcf3f3", // TODO: Criar variant para backgroundColor
      labelColor: '#DB4539',  // TODO: Criar variant para color
    },
    on: {
      label: "Ativo",
      icon: AppSwitchVariants.variants.icon.check,
      iconColor: AppSwitchVariants.variants.iconColor.success,
      backgroundColor: "#f2f7f3", // TODO: Criar variant para backgroundColor
      labelColor: '#149E57', // TODO: Criar variant para color
    }
  };
};