import PurchaseOrderListPage from "../views/list/PurchaseOrderListPage";

export default [
  {
    path: '/purchase/order',
    name: 'purchase.order.view',
    component: PurchaseOrderListPage,
    meta: {
      title: 'Ordem de compra',
      description: 'Ordem de compra',
      icon: 'fa fa-home',
      permission: 'purchases.order.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'purchase.order.view'},
        {title: 'Ordem', name: null},
      ]
    },
  },
];
