<template>
  <div
    v-if="isActive"
    :class="[
      $attrs.class,
      'app-tab-content',
    ]"
    id="app-tab-content"
  >
    <div class="app-tab-title">
      <div class="d-flex align-items-center">
        <AppIcon :icon="props.icon" :color="props.color" width="24px" />
        <h3>{{ props.title }}</h3>
      </div>
      <AppButton
        v-if="(canGoBack && isMobile) || (canGoBack && !isMobile)"
        @click="goBack"
        class="back-button ml-auto"
        type="muted"
        size="sm"
        rounded="full"
        icon="back-arrow"
      >
        voltar
      </AppButton>
    </div>
    <slot />
  </div>
</template>

<script setup>
import { defineProps, inject, computed } from "vue";
import AppIcon from "../AppIcon/AppIcon.vue";
import AppButton from "../AppButton/AppButton.vue";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: false,
    default: "dark",
  },
});

const activeTab = inject("activeTab");
const renderedComponent = inject("renderedComponent");
const isMobile = inject("isMobile");
const goBack = inject("goBack");
const canGoBack = inject("canGoBack");

const isActive = computed(() => {
  if (renderedComponent && renderedComponent.value) {
    return renderedComponent.value === props.value;
  }
  return activeTab.value === props.value;
});
</script>

<style lang="scss" scoped>
@import "@/style/PuzlCustom/App.scss";
.app-tab-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  padding: 24px 24px 32px 24px;
  transition: opacity 0.3s ease;

  .app-tab-title {
    display: flex;
    justify-content: start;
    align-items: center;
    h3 {
      margin-bottom: 0;
      font-family: Fredoka;
      font-weight: 500;
      @include rhythm(font-size, 18);
      line-height: 21.78px;
      margin-left: 12px;
      color: $dark;
    }
  }
}
</style>

<style lang="scss">
#app-tab-content.app-tab-content {
  .app-tab-list-wrapper {
    border-right: none;
  }
}
</style>
