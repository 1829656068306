export const SET = 'SET'
export const SET_BROKEN_SEQUENCE = 'SET_BROKEN_SEQUENCE'
export const SET_WIDGETS = 'SET_WIDGETS'
export const SET_PROPOSAL_OPEN_BILLING = 'SET_PROPOSAL_OPEN_BILLING'
export const SET_BILLS = 'SET_BILLS'
export const SET_MENTIONS = 'SET_MENTIONS'
export const DEFAULT_MENTION = 'DEFAULT_MENTION'
export const PUSH = 'PUSH'
export const SHOW = 'SHOW'
export const DESTROY = 'DESTROY'
export const DESTROY_BILLS = 'DESTROY_BILLS'
export const UPDATE = 'UPDATE'

export const SET_CORRECTION_LETTERS = 'SET_CORRECTION_LETTERS'
