const keys = Object.freeze({
  ALL: null,
  INSTALLMENT_PAYMENT: 1,
  BARTER_PAYMENT: 2,
  PREPAID_PAYMENT: 3,
});

const getTitle = (value) => {
  switch (value) {
    case keys.ALL:
      return "Todos";
    case keys.INSTALLMENT_PAYMENT:
      return "A prazo";
    case keys.BARTER_PAYMENT:
      return "Permuta";
    case keys.PREPAID_PAYMENT:
      return "Antecipado";
    default:
      return "";
  }
};

export default {
  keys,
  getTitle,
};
