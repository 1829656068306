<template>
  <div>
    <modal size="lg" :backgroundColor="'#e8e8e8'" :show.sync="isOpen">
      <template slot="header">
        <div class="d-flex align-items-center">
          <h5 class="text-title mb-0"><img class="mr-2" src="/img/icons/icons8/ios/mail_primary--v1.png" width="25px"
                                           height="25px">Carta de correção</h5>
        </div>
      </template>
      <template slot="close-button">
        <img class="pointer mt-2" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
             width="17px"/>
      </template>
      <div class="mb-2 px-2 mx-1">
        <div class="row">
          <div class="col-12">
            <h5 class="h4 new-default-black-font p-1 mb-0">
              <img
                height="24px"
                src="/img/icons/icons8/ios/info-squared_warning.png"
                class="mr-3"> IDENTIFICAÇÃO
            </h5>
          </div>
          <div class="col-12 mt-1">
            <card style="box-shadow: none !important">
              <div class="row">
                <div class="col-8">
                  <h5 class="h4 new-default-black-font p-1 mb-0">
                    {{ itemSelected.code }}
                  </h5>
                  <h5 class="h4 new-default-black-font pl-1 mt-0 mb-0 font-weight-400">
                    {{ itemSelected.customer_name }}
                  </h5>
                  <h5 class="h4 new-default-black-font pl-1 mt-0 mb-0 font-weight-400">
                    {{ $helper.parseDate(itemSelected.date_at) }}
                  </h5>
                </div>
                <div class="col-4 p-0 text-right">
                  <div class="row ">
                    <div class="col-6 pr-2 pl-4" v-if="itemSelected.os">
                      <base-button block
                                   style="background: white;box-shadow:none !important;border: 1px solid #ddd8d8;">
                        <img width="22px" src="/img/icons/os.png" class="ml-0 zoom-img">
                        <br>
                        <span class="new-default-black-font font-weight-400" style="font-size: 12px">{{
                            itemSelected.os
                          }}</span>
                      </base-button>
                    </div>
                    <div class="col-6 pr-4">
                      <base-button block
                                   style="background: white;box-shadow:none !important;border: 1px solid #ddd8d8;">
                        <img width="16px" src="/img/nfe.png" class="ml-0 zoom-img">
                        <br>
                        <div class="new-default-black-font font-weight-400 mt-1" style="font-size: 12px">
                          {{ itemSelected.number }}
                        </div>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>

          <div class="col-12 mt-n2">
            <h5 class="h4 new-default-black-font p-1 mb-0">
              <img
                height="24px"
                src="/img/icons/create-new.png"
                class="mr-3"> TEXTO DA CARTA
            </h5>
          </div>
          <div class="col-12 mt-1">
            <textarea v-model="payload.justification" class="form-control" rows="4">

            </textarea>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12 mt-1 text-right">
            <base-button @click.prevent="closeModal" class="text-danger"
                         style="border-radius: 4px !important;background: white;border: 1px solid #ddd8d8">
              Cancelar
            </base-button>
            <base-button @click.prevent="save" class="text-success"
                         style="border-radius: 4px !important;background: white;border: 1px solid #ddd8d8">
              Emitir
            </base-button>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-12">
            <h5 class="h4 new-default-black-font p-1 mb-0">
              <img
                height="24px"
                src="/img/icons/order-history.png"
                class="mr-3"> HISTÓRICO
            </h5>
          </div>
          <div class="col-12 mt-1">
            <card style="height: 300px;overflow:auto">
              <card v-for="item in $_correction_letters" class="mb-2" style="box-shadow: none !important">
                <div class="row mb-n3 mt-n3">
                  <div class="col-4" style="border-right: 1px solid #e1e0e0">
                    <h5 class="h4 new-default-black-font p-1 mb-0 font-weight-400">
                      {{ item.created_user_name }}
                    </h5>
                    <h5 class="h4 new-default-black-font p-1 mt-n2 font-weight-400">
                       {{ $helper.parseDate(item.created_at) }}
                    </h5>
                  </div>
                  <div class="col-8">
                    {{ item.justification }}
                  </div>
                </div>
              </card>
            </card>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import {defineEmits, ref, getCurrentInstance, computed, reactive} from "vue";
import {dialogs, progress} from "../../../../../../helpers";
import mdfeStore from "@/modules/fiscal/mdfe/store/mdfeStore";

let itemSelected = ref({})
const isOpen = ref(false);
const payload = ref({
  justification: undefined,
  key: undefined,
});
const store = getCurrentInstance().proxy.$store;
const {proxy} = getCurrentInstance()

const $_correction_letters = computed(() =>
  store.getters["xmlIssuedes/fetchCorrectionLetter"]
);
async function openModal(item) {
  itemSelected.value = item
  payload.value.key = item.key
  payload.value.justification = null
  let loader = progress.showLoader()
  store.dispatch('xmlIssuedes/getCorrectionLetters', item.key).then(() => {
    isOpen.value = true;
    loader.hide()
  })
}

async function save() {
  if (payload.value.justification.length < 15) {
   return dialogs.notifyError({
      message: "A correção deve conter pelo menos 15 caracteres"
    })
  }
  const isConfirmed = await dialogs.confirmAction('Deseja realmente enviar essa carta de correção?');
  if (isConfirmed) {
    let loader = progress.showLoader()
    store.dispatch('xmlIssuedes/postStoreCorrectionLetters', payload.value)
      .then((response) => {
        emit('updated', {
          key: payload.value.key
        })
        dialogs.notify()
      })
      .catch((error) => {
        dialogs.notify(dialogs.TYPE_ENUM.DANGER, error.message)
      })
      .finally(() => {
        loader.hide()
      })
  }
}

function closeModal() {
  isOpen.value = false;
}

defineExpose({
  openModal,
});

const emit = defineEmits([
  "updated",
]);
</script>
