<template>
  <div>
    <modal :show.sync="modal.create" :backgroundColor="'#F2F4F9'">
      <template slot="header">
        <div
          class="d-flex align-items-center container-fluid"
          style="gap: 12px; margin-left: -28px"
        >
          <div>
            <AppIcon
              icon="handshake"
              color="warning"
              width="23px"
              class="mr-3"
            />
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
        />
      </template>
      <div class="p-2">
        <div class="container">
          <!-- Contrato -->
          <div class="mb-4">
            <div class="d-flex align-items-center mb-3">
              <AppIcon
                icon="info-squared"
                color="warning"
                width="24px"
                class="mr-3"
              />
              <h5 class="mb-0 label-title">Contrato</h5>
            </div>
            <div class="content-card">
              <div>
                <div class="label-title">{{ contractProposal.code }}</div>
                <div class="label-item">
                  {{ contractProposal.entity.entity_name }}
                </div>
                <div class="label-item">
                  {{ contractProposal.construction.construction_name }}
                </div>
              </div>
            </div>
          </div>
          <!-- Parceiro Comercial -->
          <div class="mb-4">
            <div class="d-flex align-items-center mb-3">
              <AppIcon
                icon="handshake"
                color="warning"
                width="24px"
                class="mr-3"
              />
              <h5 class="mb-0 label-title">Parceiro comercial</h5>
            </div>
            <div class="content-card">
              <div>
                <div class="label-item">
                  {{ contractProposal.BusinessPartner.entity_name }}
                </div>
                <div class="label-item">
                  {{ contractProposal.BusinessPartner.document }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-4">
          <div class="col-12 text-right">
            <AppButton
              label="Excluir"
              icon="cancel"
              type="danger"
              class="ml-2"
              :hasOutline="true"
              @click="deleteBusinessPartner"
            />
            <AppButton
              label="Editar"
              icon="save"
              type="warning"
              class="ml-2"
              @click="selectBusinessPartner"
              :isDisabled="invalid"
            />
          </div>
        </div>
      </div>
    </modal>
    <ModalSearchEntity
      @selected="updateBusinessPartner"
      ref="modalSearchEntity"
    />
  </div>
</template>

<!-- <script setup>
//#region Imports
import { useStore } from "@/shared/hooks/useStore.js";
import { getCurrentInstance } from "vue";

import { AppButton, AppIcon } from "../../../../../components/AppGlobal";

import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";

//#endregion

//#region ComponentRefs

//#endregion

//#region Data
const { proxy } = getCurrentInstance();
const store = useStore();

//#endregion

//#region Computeds
/** Store Getters */
const $_user = store.getters["auth/getUser"];
//#endregion

//#region Methods

//#endregion

//#region Lifecycle

//#endregion
</script> -->

<script>
import { mapGetters } from "vuex";
import ModalSearchEntity from "@/views/Modules/Configuration/Entity/Shared/_ModalSearchEntity";
import AppButton from "../../../../../components/AppGlobal/AppButton/AppButton.vue";
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";

export default {
  name: "ModalBusinessPartner",
  components: { ModalSearchEntity, AppButton, AppIcon },
  computed: {
    ...mapGetters({
      $_user: "auth/getUser",
    }),
  },
  data() {
    return {
      modal: {
        title: "Parceiro comercial",
        create: false,
      },
      contractProposal: null,
    };
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * @param {object} contractProposal
     */
    openModal(contractProposal) {
      this.modal.create = true;
      this.contractProposal = contractProposal;
    },
    selectBusinessPartner() {
      this.$refs.modalSearchEntity.openModal();
    },
    /**
     * @param {object} entity
     */
    updateBusinessPartner(entity) {
      this.$Progress.start();
      this.$store
        .dispatch("businessPartner/storeBusinessPartner", {
          id: this.contractProposal.id,
          business_partner_id: entity.id,
          business_partner_created_by_user_id: this.$_user.id,
        })
        .then((response) => {
          this.contractProposal.BusinessPartner.entity_name =
            response.data.BusinessPartner.entity_name;
          this.contractProposal.BusinessPartner.document =
            response.data.BusinessPartner.document;
          this.contractProposal.userBusinessPartnerCreated.name =
            response.data.userBusinessPartnerCreated.name;
          this.contractProposal.business_partner_created_at =
            response.data.business_partner_created_at;
          this.$emit("contractProposalUpdated", response.data);
          this.$notify({
            type: "success",
            message: "Parceiro comercial atualizado com sucesso!",
          });
        })
        .finally(() => {
          this.$Progress.finish();
        });
    },
    deleteBusinessPartner() {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$store
            .dispatch(
              "businessPartner/deleteBusinessPartnerContractProposalById",
              this.contractProposal.id
            )
            .then(() => {
              this.$emit("deletedBusinessPartner", this.contractProposal.id);
              this.closeModal();
              this.$notify({
                type: "success",
                message: "Parceiro comercial excluído com sucesso!",
              });
            })
            .finally(() => {
              this.$Progress.finish();
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.content-card {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 1rem;
}
</style>
