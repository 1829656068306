<template>
  <div>
    <modal size="lg" :show.sync="isOpen" :backgroundColor="'#fef9f2'" :showClose="false">
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/icons/cart-warning.png" alt="plus-math-success" width="25px">
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png"
                alt="close-window" width="17px">
            </div>
          </div>
        </template>
        <!-- IDENTIFICAÇÃO -->
        <div class="py-2 px-4">
          <div class="d-flex align-items-center indentification">
            <img class="mr-2" src="/img/icons/info-squared-yellow.png" alt="close-window" width="20px">
            <div>IDENTIFICAÇÃO</div>
            <div class="divider mx-2" />
          </div>
          <div class="mt-3 order-select">
            <div class="d-flex align-items-center">
              <div class="col-md-6">ORDEM DE COMPRA</div>
              <div class="col-md-6 mb-2">
                <base-input input-classes="form-control-sm">
                  <PuzlSelect placeholder="Ordens de Compra" :items="orders.data" :clearable="false" multiple
                    :labelMask="true" label="description" v-model="selectedOrderId" :loading="false"
                    :disabled="false" />
                </base-input>
              </div>
            </div>
            <div v-if="selectedOrderId.length" class="d-flex align-items-center justify-content-end mt-3">
              <div @click="updateSelectedOrder" class="mr-3 include-button pointer">
                incluir
              </div>
            </div>
          </div>
          <!-- VALIDAÇÃO -->
          <div v-if="selectedOrder.length" class="mt-4">
            <div class="d-flex align-items-center validation-header">
              <img class="mr-2" src="/img/icons/icons8/ios/hierarchy_danger.png" alt="close-window" width="20px">
              <div>VALIDAÇÃO</div>
              <div class="divider mx-2" />
            </div>
            <div v-for="(order, indexOrder) in selectedOrder" :key="indexOrder" class="p-3">
              <div class="d-flex align-items-center justify-content-between">
                <div>{{ 'OC-' + order.id }}</div>
                <img @click="removeSelectedOrder(order.id, indexOrder)" class="mr-2"
                  src="/img/icons/icons8/ios/trash_can.png" alt="close-window" width="20px">
              </div>
              <div class="divider my-2" />
              <div>
                <div v-for="(item, index) in order.items" :key="index">
                  <div class="d-flex align-items-center">
                    <div class="col-md-6 p-0">{{ item.description }} ({{ toMoney(item.total_amount) }})</div>
                    <div class="col-md-6 p-0 my-1">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect placeholder="Itens" :items="getAvailableItems(indexOrder, index)" :clearable="false"
                          :labelMask="true" customKey="product_service_hub_id" label="product_name_value"
                          v-model="getSelectedItem(indexOrder, index)[index]" />
                      </base-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex mt-3 mb-2 justify-content-end">
              <div @click="closeModal" class="d-flex align-items-center footer-button mr-3">
                <span style="font-weight: 500;">
                  Cancelar
                </span>
              </div>
              <div @click="saveData" class="d-flex align-items-center footer-button">
                <span style="font-weight: 500;">
                  Salvar
                </span>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import Vue from 'vue';
import { ref } from "vue";
import PuzlSelect from '@/components/PuzlSelect';
import store from "@/store/index";
import { toMoney } from "../../../../../helpers";
import { PurchaseOrderStatusEnum } from "@/enum/PurchaseOrderStatusEnum";
//#endregion

// #region Emits
const emit = defineEmits(["save"]);
// #endregion

//#region Data
const title = "VINCULAR O.C";
const isOpen = ref(false);
const orders = ref([]);
const selectedOrderId = ref([]);
const selectedOrder = ref([]);
const selectedItems = ref([]);
const localItems = ref([]);
//#endregion

//#region Props
/**
 * @typedef {Object} Props
 * @property {entryLaunchItemType} items
 */
/** @type {Props} */
const props = defineProps({
  items: {
    type: Array,
  },
  entity_document: {
    type: String,
  }
});
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  isOpen.value = true;

  // Resetar dados
  orders.value = [];
  selectedOrderId.value = [];
  selectedOrder.value = [];
  selectedItems.value = [];
  localItems.value = [];

  setOrders();

  if (props.items) {
    localItems.value = props.items.map(item => ({
      ...item,
      product_name_value: `${item.product_name} (${toMoney(item.total_value)})`,
    }));
  }
}
//#endregion
/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Busca as ordens de compra */
async function setOrders() {
  orders.value = await store.dispatch("purchaseOrder/getOrdersByStatus", PurchaseOrderStatusEnum.OPEN);

  if (orders.value.data.length) {
    orders.value.data = orders.value.data.map(order => ({
      ...order,
      description: `OC-${order.id}`
    }));
  }
}

/** Atualiza a ordem de compra selecionada */
function updateSelectedOrder() {
  const newOrders = orders.value.data.filter(order => selectedOrderId.value.includes(order.id));
  selectedOrder.value.push(...newOrders);
  orders.value.data = orders.value.data.filter(order => !selectedOrderId.value.includes(order.id));
  selectedOrderId.value = [];

  setItensAuto();
}

/** Remove a ordem de compra selecionada */
function removeSelectedOrder(id, index) {
  const removedOrder = selectedOrder.value.find(order => order.id === id);
  if (removedOrder) {
    orders.value.data.push(removedOrder);
  }
  selectedOrder.value.splice(index, 1);
  selectedItems.value.splice(index, 1);
}

/** Busca o item selecionado */
function getSelectedItem(indexOrder, index) {
  if (!selectedItems.value[indexOrder]) {
    Vue.set(selectedItems.value, indexOrder, []);
  }

  if (selectedItems.value[indexOrder][index] === undefined) {
    Vue.set(selectedItems.value[indexOrder], index, null); // Garante que o elemento no índice index seja reativo
  }

  return selectedItems.value[indexOrder];
}

/** Seta automaticamente os itens com mesmo nome e valor */
function setItensAuto() {
  const usedItems = new Set();

  selectedOrder.value.forEach((order, indexOrder) => {
    order.items.forEach((item, index) => {
      const matchedItem = localItems.value.find(
        propItem =>
          propItem.product_name === item.description &&
          toMoney(propItem.price) === toMoney(item.unit_value) &&
          !usedItems.has(propItem.product_service_hub_id)
      );

      if (!selectedItems.value[indexOrder]) {
        Vue.set(selectedItems.value, indexOrder, []);
      }

      if (matchedItem) {
        Vue.set(selectedItems.value[indexOrder], index, matchedItem.product_service_hub_id);
        usedItems.add(matchedItem.product_service_hub_id);
      } else {
        Vue.set(selectedItems.value[indexOrder], index, null);
      }
    });
  });
}

/** Busca os itens da entrada disponíveis para seleção */
function getAvailableItems(indexOrder, index) {
  // Obter todos os itens já selecionados, exceto o atual
  const selectedIds = selectedItems.value.flatMap((order, idxOrder) =>
    order?.filter((itemId, idx) => !(idxOrder === indexOrder && idx === index)) || []
  );

  return localItems.value.filter(item => item.product_service_hub_id &&
    toMoney(item.price) === toMoney(selectedOrder.value[indexOrder].items[index].unit_value)
    && !selectedIds.includes(item.product_service_hub_id));
}

function saveData() {
  const updatedItems = selectedOrder.value.flatMap((order, indexOrder) => {
    return order.items.map((item, index) => {
      const selectedItemId = getSelectedItem(indexOrder, index)[index];
      if (selectedItemId) {
        return {
          ...localItems.value.find(propItem => propItem.product_service_hub_id === selectedItemId),
          order_id: order.id,
        };
      }
      return null; // Ignorar itens não selecionados
    }).filter(item => item !== null); // Remover os itens nulos
  });

  // Emitir os itens atualizados
  emit('save', updatedItems);
  // Fechar o modal após salvar
  closeModal();
}

//#endregion

//#region DefineExposes
/**
 * @typedef {Object} AdjustmentFinalSlumpCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped>
template,
div {
  color: #2B2D32 !important;
}

.indentification {
  font-weight: 500;
  color: #2B2D32;
}

.indentification div {
  font-size: 16px;
}

.validation-header {
  font-weight: 500;
  color: #2B2D32;
}

.validation-header div {
  font-size: 16px;
}

.divider {
  width: 100%;
  height: 0.5px;
  background-color: #E8E8E8;
}

.include-button {
  width: 115px;
  height: 31px;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  border-radius: 4px;
  border: 0.5px solid var(--Cores-Global-Azul-Puzl, #1A70B7);
  box-shadow: 0px 5px 10px 0px #0000000D;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1A70B7 !important;
  font-size: 12px;
  font-weight: 500
}

.footer-button {
  box-shadow: 0px 5px 10px 0px #0000000D;
  border: 0.5px solid #149E57;
  color: #149E57 !important;
  width: 115px;
  height: 31px;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: Fredoka;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14.52px !important;
  cursor: pointer;
}

.footer-button:first-child {
  border: 0.5px solid #DB4539;
  color: #DB4539 !important;
}
</style>