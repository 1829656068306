import PurchaseBudgetListPage from "../views/list/PurchaseBudgetListPage";
import PurchaseBudgetCreate from '../views/create/PurchaseBudgetCreatePage';


export default [
  {
    path: '/purchase/budget',
    name: 'purchase.budget.view',
    component: PurchaseBudgetListPage,
    meta: {
      title: 'Cotação de compra',
      description: 'Cotação de compra',
      icon: 'fa fa-home',
      permission: 'purchases.budget.index.view',
      breadcrumbs: [
        {title: 'Cotação', name: null},
      ]
    },
  },
  {
    path: '/purchase/budget/create/:id',
    name: 'purchase.budget.view',
    component: PurchaseBudgetCreate,
    meta: {
      title: 'Criar cotação',
      description: 'Criação de cotação de compras',
      icon: 'fa fa-home',
      permission: 'purchases.budget.index.view',
      breadcrumbs: [
        {title: 'Criar Cotação', name: null},
      ]
    },
  },
];