import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ServiceDescriptionListFilterExtType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ServiceDescriptionListFilterExtType)} ServiceDescriptionListFilterType
 */
export const ServiceDescriptionListFilterType = {};

/**
 * Inicializar ServiceDescriptionListFilterType
 *
 * @returns {ServiceDescriptionListFilterType}
 */
export const initServiceDescriptionListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}