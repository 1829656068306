<template>
  <div class="status-container" :class="hydrateStatus.class">
    <img
      v-if="hydrateStatus.iconSrc"
      :src="hydrateStatus.iconSrc"
      width="16px"
      height="16px"
    /><span>{{ hydrateStatus.text }}</span>
  </div>
</template>

<script>
export default {
  name: "BodyCardStatus",
  inheritAttrs: false,
  props: {
    status: {
      type: Object,
      required: true,
      validator(value) {
        return (
          value &&
          "scheduleServiceStatus" in value &&
          "scheduleStatus" in value &&
          value.scheduleStatus &&
          "label" in value.scheduleStatus &&
          "status" in value.scheduleStatus
        );
      },
    },
  },
  computed: {
    hydrateStatus() {
      let statusList = {};
      statusList[0] = {
        class: "green",
        text: "Liberado",
        iconSrc: "/img/icons/icons8/ios/like-success.png",
      };
      statusList[1] = {
        class: "blue",
        text: "Concluido",
        iconSrc: "/img/icons/icons8/ios/success-double-check-primary.png",
      };
      statusList[3] = {
        class: "red",
        text: "Cancelado",
        iconSrc: "/img/icons/cancel-red.png",
      };
      statusList[4] = { class: "gray", text: "Andamento", iconSrc: null };
      statusList[5] = {
        class: "yellow",
        text: "Solicitação",
        iconSrc: "/img/icons/icons8/ios/loading-warning.png",
      };
      statusList[6] = {
        class: "yellow",
        text: "Pendente",
        iconSrc: "/img/icons/icons8/ios/loading-warning.png",
      };
      return statusList[this.status.scheduleServiceStatus];
    },
  },
  data() {
    return {
      scheduleStatus: {
        0: "canceled",
        1: "rescheduled",
        2: "seller_request",
        3: "not_confirmed",
        8: "interrupted",
        9: "charging",
        10: "in_progress",
        11: "completed",
        13: "released",
        14: "unknown",
        15: "not_performed",
        16: "pending",
      },
    };
  },
};
</script>

<style scoped>
div.status-container {
  border-radius: 16px;
  height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  padding: 8px 12px 8px 12px;
  max-width: 125px;
}

div.status-container > span {
  font-family: "Fredoka", sans-serif;
  font-weight: 400;
  line-height: 12.1px;
  font-size: 12.5px;
}

div.status-container.red {
  background-color: #fcf3f3;
  color: #db4539;
  border: 1px solid #fcf3f3;
}

div.status-container.green {
  background-color: #f2f7f3;
  color: #149e57;
  border: 1px solid #f2f7f3;
}

div.status-container.blue {
  background-color: #f2f4f9;
  color: #1a70b7;
  border: 1px solid #f2f4f9;
}

div.status-container.yellow {
  background-color: #fef9f2;
  color: #f2b532;
  border: 1px solid #fef9f2;
}

div.status-container.gray {
  background-color: #ccc;
  color: #2b2d32;
  border: 1px solid #ccc;
}
</style>
