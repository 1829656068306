import store from "../../../../shared/libraries/store";
import { initContractProposalListPagerType, ContractProposalListFilterType, ContractProposalListPagerType, ContractProposalType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../shared/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
    BASE: "/commercial/contract-proposal",
});

/** KEYS */
const keys = Object.freeze({
    ITEM: "CONTRACT_PROPOSAL_ITEM",
    LISTED: "CONTRACT_PROPOSAL_LISTED",
    IS_LISTING: "CONTRACT_PROPOSAL_IS_LISTING",
    IS_FINDING: "CONTRACT_PROPOSAL_IS_FINDING",
    IS_SAVING: "CONTRACT_PROPOSAL_IS_SAVING",
    IS_REMOVING: "CONTRACT_PROPOSAL_IS_REMOVING",
    PAGE_STATES: "CONTRACT_PROPOSAL_PAGE_STATE",
});

/** GETTERS */
const getters = {
    /** @returns {?ContractProposalType} */
    getItem: () => store.getState(keys.ITEM),

    /** @returns {ContractProposalListPagerType} */
    getListed: () => store.getState(keys.LISTED) ?? initContractProposalListPagerType(),

    /** @returns {boolean} */
    getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

    /** @returns {boolean} */
    getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

    /** @returns {boolean} */
    getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

    /** @returns {boolean} */
    getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

    /** @returns {{  searchBarFilter: SearchBarFilterType, filter: ContractProposalListFilterType }} */
    getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
    /**
     * @param {ContractProposalType} item
     * @returns {ContractProposalType}
     */
    setItem: (item) => store.commit(keys.ITEM, item),

    /**
     * @param {ContractProposalListPagerType} listed
     * @returns {ContractProposalListPagerType}
     */
    setListed: (listed) => store.commit(keys.LISTED, listed),

    /**
     * @param {boolean} isListing
     * @returns {boolean}
     */
    setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

    /**
     * @param {boolean} isFinding
     * @returns {boolean}
     */
    setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

    /**
     * @param {boolean} isSaving
     * @returns {boolean}
     */
    setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

    /**
     * @param {boolean} isRemoving
     * @returns {boolean}
     */
    setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

    /**
     * @param {ContractProposalType} created
     * @returns {ContractProposalType}
     */
    setNewItemInList: (created) => store.commitNewItemToList(keys, created),

    /**
     * @param {ContractProposalListPagerType} listed
     * @returns {ContractProposalListPagerType}
     */
    setListMerge: (listed) => store.commitListMerge(keys, listed),

    /**
     * @param {number} id
     */
    setRemovedItem: (id) => store.commitRemovedItem(keys, id),

    /**
     * @param {ContractProposalType} updated
     * @returns {ContractProposalType}
     */
    setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

    /** @param {{ searchBarFilter: SearchBarFilterType, filter: ContractProposalListFilterType }} filters */
    setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

    /** Resetar chaves de estado */
    resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
    /**
     * Remover proposta/contrato localmente 
     *
     * @param {number} id - ID da proposta/contrato
     */
    removeContractProposalFromList: (id) => {
        const updatedList = {
            ...getters.getListed(),
            items: getters.getListed().items.filter((item) => item.id !== id),
        };

        mutations.setListed(updatedList);
    },

    /**
     * Incluir
     *
     * @param {ContractProposalType} data
     * @returns {Promise<ContractProposalType>}
     */
    create: async (data) => await createSource(data, mutations, urls.BASE),

    /**
     * Localizar por ID
     *
     * @param {number} id
     * @returns {Promise<?ContractProposalType>}
     */
    find: async (id) => await findSource(id, mutations, urls.BASE),

    /**
     * Listar
     *
     * @param {ContractProposalListFilterType} filter - Filtro de listagem
     * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
     * @returns {Promise<?ContractProposalListPagerType>} - Retorno paginado
     */
    list: async (filter, isAccumulateItems = false) => {
        const type = filter.type
        await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE + `/${type}/list+microservice`)
    },

    /**
     * Deletar por ID
     *
     * @param {string} uuid
     * @returns {Promise<void>}
     */
    remove: async (id) => await removeSource(uuid, mutations, urls.BASE),

    /**
     * Atualizar por ID
     *
     * @param {ContractProposalType} data
     * @param {number} id
     * @returns {Promise<ContractProposalType>}
     */
    update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),

    /**
     * Atualizar Status por ID
     *
     * @param {0|1} status
     * @param {number} id
     * @returns {Promise<ContractProposalType>}
     */
    updateStatus: async (status, id) => {
        try {
            mutations.setIsSaving(true);

            const url = `${urls.BASE}/${id}/status/${status}`;
            const res = await httpClient.put(url);
            if (res.status !== HttpStatusEnum.HTTP_OK) {
                handleUnexpectedStatusCode(res);
                return;
            }

            const updated = res.data.data;
            return mutations.setUpdatedItem(updated);
        } finally {
            mutations.setIsSaving(false);
        }
    },
}

export default {
    actions,
    getters,
    mutations,
};
