import { AppIconVariants } from "../AppIcon";
import InputNumberDecimalPlacesEnum from "./enums/InputNumberDecimalPlacesEnum";
import InputNumberTextAlignEnum from "./enums/InputNumberTextAlignEnum";

export const AppInputNumberVariants = Object.freeze({
  defaultVariants: Object.freeze({
    textAlign: InputNumberTextAlignEnum.keys.RIGHT,
    decimalPlaces: InputNumberDecimalPlacesEnum.keys.TWO,
    minValue: null,
    maxValue: null,
    isDisabled: false,
    isAutoSelectOnFocus: true,
    hasRounding: true,
    hasInputFormatting: true,
    prepend: null,
    append: null,
    isInvalid: false,
  }),
  variants: Object.freeze({
    textAlign: InputNumberTextAlignEnum,
    decimalPlaces: InputNumberDecimalPlacesEnum,
    prepend: AppIconVariants.variants.svg,
    append: AppIconVariants.variants.svg,
  }),
});
