<template>
  <div
    v-show="props.show"
    style="border-radius: 12px"
    @keydown.esc="onCloseClick"
    tabindex="0"
    ref="modalRef"
  >
    <div class="app-search-bar-filter">
      <div class="app-search-bar-filter-header">
        <h2 class="m-0 new-default-black-font">Filtros</h2>
        <div
          class="app-search-bar-filter-closer pointer"
          @click.prevent="onCloseClick"
        >
          <AppIcon icon="close" width="10px" />
        </div>
      </div>
      <div class="app-search-bar-filter-content">
        <!-- INÍCIO de uma Seção - SERÁ UM COMPONENTE -->
        <slot />
        <!-- FINAL de uma Seção - SERÁ UM COMPONENTE -->
      </div>
      <!-- INÍCIO das actions -->
      <div class="ef-actions-section">
        <div class="ef-action-buttons">
          <div @click.prevent="onResetClick" class="ef-button muted pointer">
            <h4>Redefinir</h4>
          </div>
          <div @click.prevent="onApplyClick" class="ef-button primary pointer">
            <h4>Aplicar</h4>
          </div>
        </div>
      </div>
      <!-- FINAL das actions -->
    </div>
    <nav class="app-search-bar-filter-blur" @click.prevent="onCloseClick"></nav>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, watch, ref, nextTick } from "vue";
import AppIcon from "../AppIcon/AppIcon.vue";

/**
 * Propriedades para o componente
 * @typedef {Object} Props
 * @property {Boolean} show - Exibir modal
 * @property {Boolean} isCloseOnReset - Fechar modal ao redefinir filtros
 */
/** @type {Props} */
const props = defineProps({
  show: {
    type: Boolean,
    default: () => true,
    required: false,
  },
  isCloseOnReset: {
    type: Boolean,
    default: () => true,
    required: false,
  },
});

const emit = defineEmits(["handleClose", "handleClear", "handleSearch"]);

const modalRef = ref(null);

function onCloseClick() {
  emit("handleClose");
}

function onApplyClick() {
  emit("handleSearch");
  emit("handleClose");
}

function onResetClick() {
  emit("handleClear");
  if (props.isCloseOnReset) {
    emit("handleClose");
  }
}

/** Esconder scroll do html quando props.show for verdadeiro */
watch(
  () => props.show,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        modalRef.value && modalRef.value.focus();
      });
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.height = "";
      document.body.style.height = "";
      document.documentElement.style.overflow = "";
    }
  }
);
</script>

<style lang="scss">
.app-search-bar-filter-section {
  &:last-child {
    margin-bottom: 0;
  }

  .v-select {
    ul {
      position: relative;
      z-index: 1000000000;
    }
  }
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.app-search-bar-filter {
  position: absolute;
  background: white;
  border-radius: 12px;
  overflow: visible;
  max-height: 800px;
  top: 50px;
  right: -100%;
  margin-left: -38px;
  z-index: 99999;
}

.app-search-bar-filter-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.63);
  top: 0;
  left: 0;
  z-index: 9999;
}

@media (max-width: 767px) {
  .app-search-bar-filter {
    min-width: 340px;
  }
}

@media (min-width: 768px) {
  .app-search-bar-filter {
    min-width: 420px;
  }
}

@media (min-width: 1200px) {
  .app-search-bar-filter {
    margin-left: -38px;
    right: unset;
  }
}

.app-search-bar-filter-header {
  background-color: #e4eaf2;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  h2 {
    @include rhythm(font-size, 16);
  }
}

.app-search-bar-filter-closer {
  display: block;
  padding: 20px;
  margin: -20px;
  margin-left: auto;
}

.app-search-bar-filter-content {
  gap: 24px;
  padding: 16px 16px 0px 16px;
  max-height: 600px;
  overflow: auto;
}

.ef-actions-section {
  padding: 16px;
  padding-bottom: 24px;
  text-align: right;
}

.ef-action-buttons {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.ef-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 9px 4px 9px;
  border-radius: 30px;
  min-width: 120px;
  transition: all ease-in-out 0.2s;

  h4 {
    margin: 0;
    font-weight: 400;
  }

  &.muted {
    border: 1px solid $muted;

    &:hover {
      background-color: darken(white, 12%);
    }
  }

  &.primary {
    border: 1px solid $primary;
    background-color: $primary;

    &:hover {
      background-color: darken($primary, 15%);
    }

    h4 {
      color: white;
    }
  }
}
</style>
