import SyncGeneralListPage from "../views/list/SyncGeneralListPage";

export default [
  {
    path: '/configuration/sync-general',
    name: 'configuration.sync-general.view',
    component: SyncGeneralListPage,
    meta: {
      title: 'Integração',
      description: 'Integrações',
      icon: 'fa fa-home',
      permission: 'configuration.sync-general.index.view',
      breadcrumbs: [
        {title: 'Dashboard', name: 'configuration.sync-general.index'},
        {title: 'Integrações', name: null},
      ]
    },
  },
];
