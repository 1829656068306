// Start Concrete Price
import ConcretePrice from '@/views/Modules/Commercial/ConcretePrice/Index';
import ConcretePriceParameter from '@/views/Modules/Commercial/ConcretePrice/Parameter/Grid.vue';
import ConcretePriceParameterCreate from '@/views/Modules/Commercial/ConcretePrice/Parameter/Create.vue';
import ConcretePriceParameterEdit from '@/views/Modules/Commercial/ConcretePrice/Parameter/Edit.vue';

// Start Contract Proposal
import {Contract, Proposal} from '@/modules/commercial/contract-proposal/routes/contractProposalRoute.js'
import ContractProposalCreate from '@/views/Modules/Commercial/ContractProposal/Shared/_Create.vue';
import ContractProposalEdit from '@/views/Modules/Commercial/ContractProposal/Shared/_Edit.vue';
import ContractProposalFormulation from '@/views/Modules/Commercial/ContractProposal/Formulation/Index.vue';
import ContractProposalFormulationConcurrent
  from '@/views/Modules/Commercial/ContractProposal/Formulation/Shared/_Concurrent';
import ContractProposalFormulationCreate from '@/views/Modules/Commercial/ContractProposal/Formulation/Shared/_Create';
import ContractProposalFormulationNewValidity from '@/views/Modules/Commercial/ContractProposal/Formulation/Shared/_newValidity';
import ContractProposalFormulationEdit from '@/views/Modules/Commercial/ContractProposal/Formulation/Shared/_Edit';
import ContractProposalServices from '@/views/Modules/Commercial/ContractProposal/Services/Index.vue';
import ContractProposalServicesCreate from '@/views/Modules/Commercial/ContractProposal/Services/Shared/_Create.vue';
import ContractProposalServicesConcurrent
  from '@/views/Modules/Commercial/ContractProposal/Services/Shared/_Concurrent.vue';
import ContractProposalAdditional from '@/views/Modules/Commercial/ContractProposal/Additional/Index';
import ContractProposalAdditionalCreate from '@/views/Modules/Commercial/ContractProposal/Additional/Shared/_Create';
import ContractProposalSituations from '@/views/Modules/Commercial/ContractProposal/Situation/Index.vue';
import ContractProposalCompetitors from '@/views/Modules/Commercial/ContractProposal/Competitor/Index.vue';
import ContractProposalContracts from '@/views/Modules/Commercial/ContractProposal/Contract/Index.vue';
import DigitalSignatureResume from '@/views/Modules/Commercial/ContractProposal/DigitalSignatureResume/Index.vue';
import ContractProposalContractsPages from '@/views/Modules/Commercial/ContractProposal/Contract/Page/Index';
  // Start Payments
import ContractProposalPayments from '@/views/Modules/Commercial/ContractProposal/Payments/Index';
  // End Payments

// Start Dashboard
import CommercialDashboard from '@/views/Modules/Commercial/Dashboard/Index';
// End Dashboard

// Start Purchases
import PurchaseRequest from '../../../src/views/Modules/Commercial/Purchases/Request/routes/PurchaseRequestRoute';
import PurchaseBudget from '../../../src/views/Modules/Commercial/Purchases/Budget/routes/PurchaseBudgetRoute';
import PurchaseOrder from '../../../src/views/Modules/Commercial/Purchases/Order/routes/PurchaseOrderRoute';
// End Purchases

// End Contract Proposal

// Start Customer Constructions
import CustomerConstruction from '@/views/Modules/Commercial/CustomerConstruction/Index.vue';
import CustomerConstructionConstructions from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Index.vue';
import CustomerConstructionContact
  from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Contact/Index.vue';
import CustomerConstructionProspection
  from '@/views/Modules/Commercial/CustomerConstruction/ProspectionMethod/Index.vue';
import CustomerConstructionType from '@/views/Modules/Commercial/CustomerConstruction/Type/Index.vue';
import AddressConstruction from '@/views/Modules/Commercial/CustomerConstruction/Constructions/Address/Index.vue';
// End Customer Constructions

import ContractProposalInvoices from '@/views/Modules/Commercial/ContractProposal/Invoices/Index';
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import ReasonCancellation from '@/views/Modules/Commercial/ContractProposal/ReasonCancellationGrid.vue';
import ServiceDescription from '@/views/Modules/Commercial/ServiceDescription/Grid.vue';
import ServicePrice from '@/views/Modules/Commercial/ServicePrice/Grid.vue';
import SalesResume from '@/views/Modules/Commercial/SalesResume/Index.vue';
import auth from "@/middleware/auth";
import competitorRoute from '../../modules/commercial/contract-proposal/competitor/routes/competitorRoute';
import serviceDescriptionRoute from '../../modules/commercial/service-description/routes/serviceDescriptionRoute';

const commercial = {
  path: '/commercial',
  component: DashboardLayout,
  name: 'Comercial',
  meta: {
    middleware: auth,
  },
  children: [
    ...PurchaseOrder,
    ...PurchaseBudget,
    ...PurchaseRequest,
    {
      path: '/commercial/service-price',
      name: 'Preço de Serviço',
      component: ServicePrice,
      meta: {
        description: 'Preço de serviço',
        permission: 'commercial.service-price.index.view',
        middleware: auth,
      },
    },
    {
      path: '/commercial/concrete-price',
      name: 'commercial.concrete-price.index',
      component: ConcretePrice,
      meta: {
        title: 'Comercial',
        description: 'Preço de Concreto',
        icon: 'fa fa-home',
        permission: 'commercial.concrete-price.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.concrete-price.dashboard.index'},
          {title: 'Preço de Concreto', name: null},
        ]
      }
    },
    {
      path: '/commercial/concrete-price/parameter',
      name: 'Parâmetros de preços mínimo de concreto',
      component: ConcretePriceParameter,
      meta: {
        title: 'Comercial',
        description: 'Parâmetros de preços mínimo de concreto',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.parameter.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Parâmetros de preços mínimo de concreto', name: null},
        ]
      }
    },
    {
      path: '/commercial/concrete-price/parameter/create',
      name: 'Parâmetros de preços mínimo - formulação',
      component: ConcretePriceParameterCreate,
    },
    {
      path: '/commercial/concrete-price/parameter/edit/:uuid',
      name: 'Parâmetros de preços mínimo - editar formulação',
      component: ConcretePriceParameterEdit,
    },
    {
      path: '/commercial/customer-construction',
      name: 'Cliente/Obras',
      meta: {
        global_filter: true,
        permission: 'commercial.customer-construction.index.view',
        description: 'Cliente/obras',
      },
      component: CustomerConstruction,
    },
    {
      path: '/commercial/customer-construction/type',
      name: 'commercial.customer-construction.type',
      component: CustomerConstructionType,
      meta: {
        title: 'Tipo de obra',
        description: 'Tipo de obra',
        // icon: 'icon-file-xml mr-2',
        breadcrumbs: [
          {title: 'Home', name: 'customer-construction.type.index'},
          {title: 'Tipos de Obras', name: null},
        ]
      }
    },
    {
      path: '/commercial/customer-construction/address/:construction_uuid',
      name: 'commercial.customer-construction.address',
      component: AddressConstruction,
      meta: {
        title: 'Endereços',
        description: 'Endereços da obra',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Home', name: 'customer-construction.address.index'},
          {title: 'Endereços', name: null},
        ]
      }
    },
    {
      path: '/commercial/customer-construction/prospection-method',
      name: 'commercial.customer-construction.prospection-method',
      component: CustomerConstructionProspection,
      meta: {
        title: 'Métodos de prospecção',
        description: 'Métodos de prospecção',
        // icon: 'icon-file-xml mr-2',
        breadcrumbs: [
          {title: 'Home', name: 'customer-construction.prospection-method.index'},
          {title: 'Método de Prospecção', name: null},
        ]
      }
    },
    {
      path: '/commercial/customer-construction/constructions/:customer_construction_uuid',
      name: 'commercial.customer-construction.constructions',
      component: CustomerConstructionConstructions,
      meta: {
        global_filter: true,
        title: 'Comercial',
        description: 'Cliente Obra',
        // icon: 'icon-file-xml mr-2',
        breadcrumbs: [
          {title: 'Home', name: 'customer-construction.constructions.index'},
          {title: 'Obras', name: null},
        ]
      }
    },
    {
      path: '/commercial/customer-construction/contact/:construction_uuid',
      name: 'commercial.customer-construction.contact',
      component: CustomerConstructionContact,
      meta: {
        title: 'Comercial',
        description: 'CLiente Obra',
        // icon: 'icon-file-xml mr-2',
        breadcrumbs: [
          {title: 'Home', name: 'customer-construction.contact.index'},
          {title: 'Acessos / Contatos', name: null},
        ]
      }
    },
    ...Contract,
    ...Proposal,
    {
      path: '/commercial/contract-proposal/create',
      name: 'commercial.contract-proposal.create',
      component: ContractProposalCreate,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Nova Proposta/Contrato', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/edit/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.edit',
      component: ContractProposalEdit,
      meta: {
        title: 'Comercial',
        description: 'Editar Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Edição Proposta/Contrato', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/formulation/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.formulation',
      component: ContractProposalFormulation,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Formulação', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/formulation/create/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.formulation.create',
      component: ContractProposalFormulationCreate,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Nova Formulação', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/formulation/new-validity/:contract_proposal_uuid/:formulation_uuid',
      name: 'commercial.contract-proposal.formulation.new_validity',
      component: ContractProposalFormulationNewValidity,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Nova Vigência Formulação', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/formulation/edit/:contract_proposal_uuid/:formulation_uuid',
      name: 'commercial.contract-proposal.formulation.edit',
      component: ContractProposalFormulationEdit,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Editar Formulação', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/formulation/concurrent/:contract_proposal_formulation_uuid',
      name: 'commercial.contract-proposal.formulation.concurrent.view',
      component: ContractProposalFormulationConcurrent,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Formulação', name: 'commercial.contract-proposal.formulation.view'},
          {title: 'Concorrentes', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/services/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.services.view',
      component: ContractProposalServices,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Serviços', name: null},
        ]
      },
    },
    {
      path: '/commercial/contract-proposal/services/create/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.services.create',
      component: ContractProposalServicesCreate,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Novo Serviço - Proposta/Contrato', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/services/concurrent/:contract_proposal_service_uuid',
      name: 'commercial.contract-proposal.services.concurrent.view',
      component: ContractProposalServicesConcurrent,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Formulação', name: 'commercial.contract-proposal.services.view'},
          {title: 'Serviços Concorrentes', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/additional/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.additional.view',
      component: ContractProposalAdditional,
      meta: {
        title: 'Comercial',
        description: 'Proposta e Contratos',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Adicionais', name: null},
        ]
      },
    },
    {
      path: '/commercial/contract-proposal/payments/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.payments.view',
      component: ContractProposalPayments,
      meta: {
        title: 'Comercial',
        description: 'Proposta e Contratos',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Recebimentos', name: null},
        ]
      },
    },
    {
      path: '/commercial/contract-proposal/invoices/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.invoices.view',
      component: ContractProposalInvoices,
      meta: {
        title: 'Comercial',
        description: 'Proposta e Contratos',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Faturas', name: null},
        ]
      },
    },
    {
      path: '/commercial/contract-proposal/additional/create/:contract_proposal_uuid',
      name: 'commercial.contract-proposal.additional.create',
      component: ContractProposalAdditionalCreate,
      meta: {
        title: 'Comercial',
        description: 'Proposta/Contrato',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: 'commercial.contract-proposal.edit.view'},
          {title: 'Novo Adicional ', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/reason-cancellation',
      name: 'Motivo de situação do contrato',
      meta: {
        description: 'Motivo de situação do contrato',
        permission: 'commercial.contract-proposal.details.view'
      },
      component: ReasonCancellation,
    },
    {
      path: '/commercial/contract-proposal/situations',
      name: 'commercial.contract-proposal.situations.index',
      component: ContractProposalSituations,
      meta: {
        title: 'Comercial',
        description: 'Situação de Contrato',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.details.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: null},
          {title: 'Situação de Proposta', name: null},
        ]
      }
    },
    ...competitorRoute,
    {
      path: '/commercial/contract-proposal/contracts',
      name: 'commercial.contract-proposal.contracts.index',
      component: ContractProposalContracts,
      meta: {
        title: 'Comercial',
        description: 'Contratos',
        icon: 'fa fa-home',
        permission: 'commercial.contract-proposal.details.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: null},
          {title: 'Contratos', name: null},
        ]
      }
    },
    {
      path: '/administrative/digital-signature-resume',
      name: 'commercial.contract-proposal.digital-signature-resume.index',
      component: DigitalSignatureResume,
      meta: {
        title: 'Comercial',
        description: 'Resumo da assinautra digital',
        icon: 'fa fa-home',
        permission: 'administrative.signature-resume.index.view',
        global_filter : true,
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Proposta/Contrato', name: null},
          {title: 'Contratos', name: null},
        ]
      }
    },
    {
      path: '/commercial/contract-proposal/contracts/pages/:contract_uuid',
      name: 'commercial.contract-proposal.pages.index',
      component: ContractProposalContractsPages,
      meta: {
        title: 'Comercial',
        description: 'Contrato Paginas',
        icon: 'fa fa-home',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.contract-proposal.dashboard.index'},
          {title: 'Contrato Paginas', name: null},
        ]
      }
    },
    {
      path: '/commercial/dashboard',
      name: 'commercial.dashboard',
      component: CommercialDashboard,
      meta: {
        title: 'Commercial',
        description: 'Dashboard comercial',
        icon: 'fa fa-home',
        permission: 'commercial.dashboard.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.dashboard.index'},
          {title: 'Dashboard', name: null},
        ]
      },
    },
    ...serviceDescriptionRoute,
    {
      path: '/commercial/sales-resume',
      name: 'commercial.sales-resume.index.view',
      component: SalesResume,
      meta: {
        title: 'Commercial',
        description: 'Resumo de vendas',
        icon: 'fa fa-home',
        permission: 'commercial.sales-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.dashboard.index'},
          {title: 'Resumo de vendas', name: null},
        ]
      },
    },
    {
      path: '/commercial/sales-resume/:contract_uuid',
      name: 'commercial.sales-resume.index.view',
      component: SalesResume,
      meta: {
        title: 'Commercial',
        description: 'Resumo de vendas',
        icon: 'fa fa-home',
        permission: 'commercial.sales-resume.index.view',
        breadcrumbs: [
          {title: 'Dashboard', name: 'commercial.dashboard.index'},
          {title: 'Resumo de vendas', name: null},
        ]
      },
    },
  ]
};

export default commercial;
