import store from "../../../../../../shared/libraries/store";
import { initPurchaseBudgetListPagerType, PurchaseBudgetListFilterType, PurchaseBudgetListPagerType, PurchaseBudgetType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../../shared/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/purchase/purchase-budgets",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "PURCHASE_BUDGET_ITEM",
  LISTED: "PURCHASE_BUDGET_LISTED",
  IS_LISTING: "PURCHASE_BUDGET_IS_LISTING",
  IS_FINDING: "PURCHASE_BUDGET_IS_FINDING",
  IS_SAVING: "PURCHASE_BUDGET_IS_SAVING",
  IS_REMOVING: "PURCHASE_BUDGET_IS_REMOVING",
  PAGE_STATES: "PURCHASE_BUDGET_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?PurchaseBudgetType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {PurchaseBudgetListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initPurchaseBudgetListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{ searchBarFilter: SearchBarFilterType, filter: PurchaseBudgetListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {PurchaseBudgetType} item
   * @returns {PurchaseBudgetType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {PurchaseBudgetListPagerType} listed
   * @returns {PurchaseBudgetListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {PurchaseBudgetType} created
   * @returns {PurchaseBudgetType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {PurchaseBudgetListPagerType} listed
   * @returns {PurchaseBudgetListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {PurchaseBudgetType} updated
   * @returns {PurchaseBudgetType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: PurchaseBudgetListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};


/** ACTIONS */
const actions = {
   /**
   * Listar ordens de compra
   *
   * @param {PurchaseOrderListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?PurchaseOrderListPagerType>} - Retorno paginado
   */
   list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),
  /**
   * Adicionar um novo orçamento de entidade
   *
   * @param {object} params - Dados do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  addEntityBudget: async (params) => {
    try {
      mutations.setIsSaving(true);

      const url = 'purchase/purchase-entity-budget';
      const res = await httpClient.post(url, params);

      if (res.status !== HttpStatusEnum.HTTP_CREATED) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const created = res.data.data;
      return mutations.setNewItemInList(created);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Buscar orçamentos de entidades
   *
   * @param {object} params - Parâmetros de filtro
   * @returns {Promise<?PurchaseBudgetListPagerType>}
   */
  fetchEntityBudgets: async (params) => {
    try {
      mutations.setIsListing(true);

      const url = 'purchase/purchase-entity-budget';
      const res = await httpClient.get(url, { params });

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data.data;
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsListing(false);
    }
  },

  /**
   * Atualizar um orçamento de entidade
   *
   * @param {object} params - Dados do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  update: async (params) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/purchase-entity-budget/${params.id}`;
      const res = await httpClient.post(url, params);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Atualizar parcialmente um orçamento de entidade
   *
   * @param {object} params - Dados do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  updatePartial: async (params) => {
    try {
      mutations.setIsSaving(true);

      const url = `purchase/purchase-budget/${params.id}`;
      const res = await httpClient.put(url, params);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Atualizar o código de um orçamento de entidade
   *
   * @param {number} id - ID do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  updateCode: async (id) => {
    try {
      mutations.setIsSaving(true);

      const url = `purchase/purchase-budget-code/${id}`;
      const res = await httpClient.put(url);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Atualizar permissão de edição de uma entidade em um orçamento
   *
   * @param {object} params - Dados do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  updateEntityCanEdit: async (params) => {
    try {
      mutations.setIsSaving(true);

      const url = `purchase/purchase-budget-entity-can-edit/${params.id}`;
      const res = await httpClient.put(url, params);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },
    /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, '/purchase/purchase-entity-budget'),

  /**
   * Login de uma entidade para acessar o orçamento
   *
   * @param {object} payload - Dados de login
   * @returns {Promise<object>}
   */
  login: async (payload) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/entity-purchase-budget/login`;
      const res = await httpClient.post(url, payload);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data.data;
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Logout de uma entidade
   *
   * @param {string} token - Token da entidade
   * @returns {Promise<object>}
   */
  logout: async (token) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/entity-purchase-budget/logout/${token}`;
      const res = await httpClient.post(url);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data.data;
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Buscar dados de um orçamento de entidade
   *
   * @param {string} token - Token da entidade
   * @returns {Promise<object>}
   */
  getEntityPurchaseBudget: async (token) => {
    try {
      mutations.setIsFinding(true);

      const url = `${urls.BASE}/entity-purchase-budget/${token}`;
      const res = await httpClient.get(url);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const formattedData = formatResponseData(res.data.data);
      return formattedData;
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsFinding(false);
    }
  },

  /**
   * Atualizar um orçamento de entidade
   *
   * @param {object} params - Dados do orçamento de entidade
   * @returns {Promise<PurchaseBudgetType>}
   */
  updateEntityBudget: async (params) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/entity-purchase-budget/${params.token}`;
      const res = await httpClient.post(url, params);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } catch (error) {
      throw error;
    } finally {
      mutations.setIsSaving(false);
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};