<template>
  <div>

    <modal :show.sync="modal" size="lg">
      <template slot="header">
        <h5 class="modal-title">{{ title }}</h5>
      </template>
      <div>
        <!--        <div class="border-left border-3 border-primary p-1">-->
        <!--          <h4>{{cycle.plant.plant_name}}</h4>-->
        <!--        </div>-->

        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="savePuzlAdditions" autocomplete="off">
            <div v-if="mix_design">
              <div class="border-left border-3 border-primary p-2 mt-2">
                <div class="row">
                  <div class="col-md-7">
                    <h5 class="h3 mb-1">
                      {{ mix_design.plant.name }}
                    </h5>
                    <div class="row"
                         v-for="feature in mix_design.features"
                         :key="feature.id">
                      <div class="col-md-12">
                        <h5 class="h mb-0">
                          {{ feature.short_description || feature.feature.short_description }}
                          {{
                          feature && feature.feature ?  format_mixe(feature.period || feature.feature.period, feature.period_type || feature.feature.period_type) : ''
                          }}
                          {{ feature.item_description || feature.description }}
                          {{ feature.letter }}
                        </h5>
                      </div>
                    </div>
                    <div class="mt-2">
                      <span class="p small">
                        {{ mix_design.type.technical_description }}
                        {{ mix_design.subtype ? '|' + mix_design.subtype.technical_description : '' }}
                        |
                        {{ mix_design.slump_flow.nomenclature }}
                        |
                        {{ mix_design.maximum_size.description }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 pt-2" v-if="mix_design">
              <div v-for="(puzl_addition, indexPuzlAddition) in mix_design.puzl_additions" :key="indexPuzlAddition">
                <collapse class="border rounded p-0 mt-3" v-if="!puzl_addition.no_show">
                  <collapse-item back-ground="border-header-indigo-bottom">
                    <div slot="title" class="d-flex justify-content-between">
                      <span class="h4">
                      {{ puzl_addition.cmc_group && puzl_addition.cmc_group.group_name || puzl_addition.name }}
                        <span class="text-muted"> {{ puzl_addition.technical_explanation }} </span>
                      </span>
                      <span class="h4 pr-5 text-success" v-if="adjustment[indexPuzlAddition]">
                        R$ {{ calc(puzl_addition, indexPuzlAddition) }} <span class="text-lowercase">/m</span><sup>3</sup>
                      </span>
                    </div>
                    <h5 class="h4 small mb-2">
                      {{ puzl_addition.details }}
                    </h5>
                    <div class="row">
                      <div class="col-3">
                        <img v-show="puzl_addition.url_image"
                             style="width: 120px;max-height: 100px; border-radius: 10px"
                             class="photo"
                             :src="image(puzl_addition.url_image)"
                        />
                      </div>
                      <div class="col-6">
                        <div class="col-md-12 d-flex justify-content-center mt-2">
                          <span v-if="puzl_addition.dosage_range_start != puzl_addition.dosage_range_end"
                                class="text-center">{{ adjustment[indexPuzlAddition] || 0.00 }}</span>
                        </div>
                        <base-slider v-if="puzl_addition.dosage_range_start != puzl_addition.dosage_range_end"
                                     v-model="adjustment[indexPuzlAddition]"
                                     :connect="true"
                                     :options="{step: 0.01, range : {min: puzl_addition.dosage_range_start, max: puzl_addition.dosage_range_end}}"
                                     :decimals="2"
                        >
                        </base-slider>
                        <h4 v-if="puzl_addition.dosage_range_start == puzl_addition.dosage_range_end"
                            class="text-muted">A dosagem inicial e final não podem ser iguais.</h4>
                      </div>
                      <div class="col-2"/>
<!--                      <div class="col-md-1 col-sm-12 d-flex justify-content-center align-items-center">-->
<!--                        <base-button-->
<!--                          :disabled="!adjustment[indexPuzlAddition] || !deleteRemove || dontEdit"-->
<!--                          @click.prevent="handleRemoveAddition(indexPuzlAddition)"-->
<!--                          block-->
<!--                          size="sm"-->
<!--                          type="danger"-->
<!--                        >-->
<!--                          <i class="fa fa-trash text-white"></i>-->
<!--                        </base-button>-->
<!--                      </div>-->
                    </div>
                  </collapse-item>
                </collapse>
              </div>
            </div>
            <div class="d-flex justify-content-center m-3">
              <!--            <div class="modal-footer">-->
              <base-button type="secondary" @click="closeModal()">Cancelar</base-button>
              <base-button type="success" native-type="submit"
                           :disabled="invalid || dontEdit"
                           :loading="loadingSave">Salvar
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";

export default {
  props: {
    deleteRemove: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {},
  name: "ModalAddPuzlAdmixture",
  data() {
    return {
      format_mixe: period_types,
      modal: false,
      title: 'Puzl Admix',
      validated: false,
      loadingPlant: true,
      loadingSave: false,
      invalid: false,
      oldAdjustment: [],
      mixe: {
        plant_uuid: null,
      },
      adjustment: [],
      mix_design: null,
      dontEdit: false,
    }
  },
  computed: {},
  methods: {
    closeModal() {
      this.adjustment = [...this.oldAdjustment]
      this.modal = false
    },
    savePuzlAdditions() {
      let additions = []
      this.adjustment.map((item, index) => {
        let add = {
          technical_explanation: this.mix_design.puzl_additions[index].technical_explanation,
          id: this.mix_design.puzl_additions[index].id,
          volume: item,
          name: this.mix_design.puzl_additions[index].cmc_group && this.mix_design.puzl_additions[index].cmc_group.group_name ||
            this.mix_design.puzl_additions[index].name,
          price: this.calc(this.mix_design.puzl_additions[index], index),
          real_price: this.mix_design.puzl_additions[index].real_price,
          percentage: this.mix_design.puzl_additions[index].percentage.percentage ||
            this.mix_design.puzl_additions[index].percentage
        }
        if (!this.mix_design.puzl_additions[index].no_show) {
          additions.push(add)
        } else {
          add.no_show = 1
          additions.push(add)
        }
      })
      this.oldAdjustment = [...this.adjustment]
      this.$emit('savePuzlAdditions', additions)
      this.modal = false
    },
    openModal(selected) {
      this.mix_design = {...selected}
      this.modal = true
    },
    handleSetDefault(selected) {
      this.dontEdit = false
      for (var index in selected.puzl_additions) {
        if (!selected.puzl_additions[index].no_show) {
          this.adjustment[index] = selected.puzl_additions[index].volume
        } else {
          this.dontEdit = true
        }
      }
      this.oldAdjustment = [...this.adjustment]
    },
    calc(puzl_addition, indexPuzlAddition) {
      const percentage = puzl_addition.percentage.percentage ? puzl_addition.percentage.percentage : puzl_addition.percentage
      puzl_addition.price = puzl_addition.real_price.toString().replace(',', '.')
      const value = parseFloat(puzl_addition.real_price) * (1 + percentage / 100) * this.adjustment[indexPuzlAddition]
      return value.toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    image(image) {
      return image
    },
    handleRemoveAddition(index) {
      this.adjustment.splice(index, 1)
      this.$notify({
        type: "success",
        message: "Adição desvinculada com sucesso"
      });
    },
  },
};
</script>
