export const PurchaseRequestStatusEnum = Object.freeze({
  PENDING: 0, // Pendente
  APPROVED: 1, // Aberto
  DENIED: 2, // Negado
  CANCELED: 3 // Cancelado
});

const getTitle = (value) => {
  switch (value) {
      case PurchaseRequestStatusEnum.PENDING:
          return "Pendentes";
      case PurchaseRequestStatusEnum.APPROVED:
          return "Aprovados";
      case PurchaseRequestStatusEnum.DENIED:
          return "Negados";
      case PurchaseRequestStatusEnum.CANCELED:
          return "Cancelados";
      default:
          return "";
  }
};

export default {
  keys: PurchaseRequestStatusEnum,
  getTitle,
};