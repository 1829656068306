<template>
  <div v-if="!isPrinting">
    <AppFixedPageTitle
      title="Dre anual"
      icon="/img/icons/icons8/ios/proposal-white.png"
    />
    <AppPageHeader>
      <template slot="search-bar">
        <AppSearchBar
          :showCompanyPlants="true"
          :searchBarFilter.sync="searchBarFilter"
          :allowClearCompanyPlantFilter="false"
          @onSearchClick="load"
          @onClearClick="clearFilter"
        >
          <AppSearchBarFilterSection
            name="Dados"
            icon="/img/icons/icons8/ios/info-squared_gray.png"
          >
            <div class="col-md-12 mt-1 mb-2 px-0 text-left">
              <label>Opção de plano de contas</label>
              <PuzlSelect
                :clearable="false"
                placeholder="Opção de plano de contas"
                v-model="filter.chart_account"
                :items="[{id: 1, name: 'PAGAMENTO'}, {id: 2, name: 'COMPETÊNCIA'}]"
              />
            </div>
            <div class="col-md-12 mt-1 mb-6 px-0 text-left">
              <label>Ano</label>
              <PuzlSelect :clearable="false" v-model="filter.year"
                          :items="years"/>
            </div>
          </AppSearchBarFilterSection>
        </AppSearchBar>
      </template>
      <template slot="header-buttons">
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            text="Plano gerencial"
            @click="redirectToChartAccounts"
            type="success"
            classButton="text-white"
            icon="/img/icons/icons8/ios/increase.png"/>
          <AppPageHeaderActionsButton
            @click="openDreConfigModal"
            text="Config."
            type="black"
            classButton="new-default-black text-white"
            icon="/img/icons/icons8/ios/administrative-tools.png"/>
          <AppPageHeaderActionsButton
            text="Imprimir"
            @click="openGenerateReportModal"
            type="primary"
            classButton="text-white"
            icon="/img/icons/icons8/ios/print-white.png" />
        </AppPageHeaderActions>
      </template>
    </AppPageHeader>
    <div class="container-fluid">
      <PuzlEmptyData class="mt-6" msg="Selecione uma central para filtrar os dados"
                     v-if="!searchBarFilter.company_plant_selected"/>
      <div
        v-show="searchBarFilter.company_plant_selected"
        id="col_scrolled_title"
        class="row mt-5 px-2 mb-0 flex-row flex-nowrap dre-overflow"
        style="white-space: nowrap;overflow: hidden;">
        <div
          class="dre-overflow-margin"
          style="max-width: 364px;flex: 0 0 364px;margin-left: 16px;left: 3px;z-index: 3;border-right: none !important;">
          <div class="row flex-row flex-nowrap mb-n3">
            <div class="px-1"
                 style="flex: 0 0 100% !important;max-width: 100% !important;border-radius: 10px !important;">
              <div class="card card-block p-2" ref="cardPayment"
                   style="height: 77px;background: #C6D2E5;box-shadow: none !important;z-index: 3;padding: 24px">
                <div class="row">
                  <div class="col-12 mt-3 ml-4 dre-text" style="font-size: 17px;">
                    <img src="/img/icons/icons8/ios/today_black.svg"
                         style="width: 27px; margin-right: 10px; margin-top: -8px;">
                    PLANO DE CONTAS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 dre-range-overflow">
          <div class="container-fluid">
            <div class="row flex-row flex-nowrap mb-n3">
              <div class="px-1 custom-col-max"
                   style=";padding-right: 6px !important;padding-left: 6px !important">
                <div class="card card-block p-2"
                     style="height: 77px;background: rgb(178, 178, 178);border-radius: 8px !important;padding: 12px;box-shadow: none !important;margin-left: -6px;z-index: 2 !important;">
                  <div class="row">
                    <div
                      class="col-12 text-center dre-text"
                      style="font-size: 14px;">
                      ACUMULADO
                    </div>
                    <div
                      class="col-12 text-center dre-text"
                      style="text-transform: none !important;font-weight: 500;font-size: 20px;">
                      {{ toDecimal(getTotalVolume(), 1) }} m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-1 custom-col" v-for="item in MonthsEnum"
                   style=";padding-right: 6px !important;padding-left: 6px !important">
                <div class="card card-block p-2"
                     style="height: 77px;background: #C6D2E5;border-radius: 8px !important;padding: 12px;box-shadow: none !important;margin-left: -5px">
                  <div class="row" style="height: 30px !important;">
                    <div class="col-12 text-center dre-text" style="font-size: 14px;">
                      {{ item?.name }}
                    </div>
                    <div class="col-12 text-center dre-text" style="text-transform: none !important;font-weight: 500;font-size: 20px;">
                      {{ toDecimal(getVolumeByMonth(item.id), 1) }} m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="searchBarFilter.company_plant_selected" class="row mt-0 px-2 flex-row flex-nowrap dre-overflow"
           id="col_scrolled"
           ref="mainTableDre"
           style="overflow: auto;white-space: nowrap;">
        <div class="dre-overflow-margin"
             style="max-width: 364px;flex: 0 0 364px;margin-left: 16px;left: 3px;z-index: 3;border-right: none !important;">
          <template v-for="(item, indexConfiguration) in getConfigurations()">
            <div :key="indexConfiguration" v-show="item.before" id="before" class="row flex-row flex-nowrap mb-2 mt-1"
                 style=";width: 389px !important;">
              <div class="px-1 chart-account"
                   style="background: rgb(242, 244, 249)">
                <div class="card card-block "
                     style="border-radius: 0 !important;border: none !important;background: rgb(242, 244, 249);box-shadow: none !important;z-index: 3">
                  <badge class="pt-4"
                         style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                    <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                      <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                           style="color: rgb(43, 45, 50); font-size: 16px;">
                        (=) {{ item?.before?.name }}
                      </div>
                    </div>
                  </badge>
                </div>
              </div>
            </div>
            <div class="row flex-row flex-nowrap mb-2"
                 style=";width: 389px !important;">
              <div class="px-1 chart-account"
              >
                <div class="card card-block mt-3"
                     style="border-radius: 0 !important;border: none !important;background: white;box-shadow: none !important;z-index: 3">
                  <badge class="mb-3" style="max-height: 42px !important;height: 42px !important;background: white;">
                    <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                      <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                           style="color: rgb(43, 45, 50); font-size: 16px;">
                        <a @click.prevent="setOpenedAllChartAccount(item.id)" v-show="wasOpenedConfiguration(item.id)" href="#">
                          <img src="/img/icons/icons8/ios/minus_key.png" alt="minus_key"
                               width="22px">
                        </a>
                        <a @click.prevent="setOpenedAllChartAccount(item.id)" v-show="!wasOpenedConfiguration(item.id)" href="#">
                          <img src="/img/icons/icons8/ios/plus_key.png" alt="plus_key"
                               width="22px">
                        </a>
                        {{ item.id === 1 ? '(+)' : '(-)' }} {{ item?.name }}
                      </div>
                    </div>
                  </badge>
                  <badge v-show="wasOpenedConfiguration(item.id)" v-for="chartAccount in item.chart_accounts" style="border-left: 1px solid #ccc9c9;background: white;border-top: 1px solid #ccc9c9;border-top-right-radius: 0;
    border-bottom-right-radius: 0;margin-right: -4px;
    border-bottom: 1px solid #ccc9c9;margin-left: 20px">
                    <div id="header_accumulated" class="row p-2" style="height: 30px !important;width: 100%">
                      <div class="col-12 text-truncate text-left font-weight-400"
                           style="color: rgb(43, 45, 50); font-size: 14px;">
                        <a @click.prevent="setOpenedChartAccount(chartAccount)" v-if="wasOpenedChartAccount(chartAccount)" href="#">
                          <img src="/img/icons/icons8/ios/minus_key.png" alt="minus_key"
                               width="22px">
                        </a>
                        <a @click.prevent="setOpenedChartAccount(chartAccount)" v-else href="#">
                          <img src="/img/icons/icons8/ios/plus_key.png" alt="plus_key"
                               width="22px">
                        </a>
                        {{
                          getChartAccountById(chartAccount)
                        }}
                      </div>
                    </div>
                    <div v-show="wasOpenedChartAccount(chartAccount)" v-for="subChartAccount in getSubChartAccounts(chartAccount)" id="header_accumulated"
                         class="row p-2" style="height: 30px !important;width: 100%">
                      <div class="col-12 ml-4 text-truncate text-left font-weight-400"
                           style="color: rgb(43, 45, 50); font-size: 14px;">
                        {{
                          subChartAccount?.name
                        }}
                      </div>
                    </div>
                  </badge>
                </div>
              </div>
            </div>
            <div v-show="indexConfiguration === getConfigurations().length - 1"  class="row flex-row flex-nowrap mb-2 mt-1"
                 style=";width: 389px !important;">
              <div class="px-1 chart-account"
                   style="background: rgb(242, 244, 249)"
              >
                <div class="card card-block "
                     style="border-radius: 0 !important;border: none !important;background: rgb(242, 244, 249);box-shadow: none !important;z-index: 3">
                  <badge class="pt-4"
                         style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                    <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                      <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                           style="color: rgb(43, 45, 50); font-size: 16px;">
                        (=) LUCRO / PREJUÍZO
                      </div>
                    </div>
                  </badge>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="col-8 dre-range-overflow">
          <div class="container-fluid">
            <!--            card principal-->
            <!--            card segundario-->
            <template v-for="(item, indexConfiguration) in getConfigurations()">
              <div v-show="item.before" id="before" class="row flex-row flex-nowrap mb-2 mt-1"
                   style="width: 389px !important;">
                <div class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);margin-left: -4px">
                  <div class="card card-block  priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important;z-index: 2 !important;">
                    <badge class="pt-4"
                           style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div class="col-6 text-truncate text-center  font-weight-500"
                             style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getSumAccumulatedByCategoriesBefore(item.id) | currency() }}
                        </div>
                        <div class="col-6 text-truncate text-center  font-weight-400"
                             style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                          {{ (getSumAccumulatedByCategoriesBefore(item.id) / getTotalVolume() || 0) | currency() }}
                          /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>

                <!--              MESES HEADER-->
                <div v-for="month in MonthsEnum" class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);">
                  <div class="card card-block "
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important; z-index: 1;">
                    <badge class="pt-4"
                           style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div class="col-6 text-truncate text-center  font-weight-500"
                             style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getSumAccumulatedByCategoriesBefore(item.id, month) | currency() }}
                        </div>
                        <div class="col-6 text-truncate text-center  font-weight-400"
                             style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                          {{
                            (getSumAccumulatedByCategoriesBefore(item.id, month) / getVolumeByMonth(month.id) || 0) | currency()
                          }}
                          /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>
              </div>
              <div  class="row flex-row flex-nowrap mb-2"
                    style="width: 389px !important;">
                <div class="px-0 custom-col"
                     style="; background: white;margin-left: -4px">
                  <div class="card card-block mt-3 priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: white; box-shadow: none !important;">
                    <badge class="mb-3"
                           style="max-height: 42px !important;height: 42px !important;background: rgb(232, 232, 232);
                         margin-left: -5px;
    margin-right: -4px;">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div  class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAllByMenu(item.chart_accounts) | currency() }}
                        </div>
                        <div class="col-6 text-center font-weight-400 pointer"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAllByMenu(item.chart_accounts) / getTotalVolume() | currency() }} /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                    <!--                  ACUMULADO BODY-->
                    <badge v-show="wasOpenedConfiguration(item.id)"  v-for="(itemChartAccount, indexChartAccount) in item.chart_accounts"
                           :class="getBorderedAccumulated(indexChartAccount, item.chart_accounts.length)"
                           style="
    border: 1px solid transparent;
    ;background: rgb(232, 232, 232);border-radius: 0px;
    margin-right: -4px;margin-left: -5px">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAllBySubMenu(itemChartAccount) | currency() }}
                        </div>
                        <div class="col-6 text-center font-weight-400"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAllBySubMenu(itemChartAccount) / getTotalVolume() | currency() }} /m<sup>3</sup>
                        </div>
                      </div>
                      <div v-show="wasOpenedChartAccount(itemChartAccount)" v-for="subChartAccount in getSubChartAccountsSum(itemChartAccount)" id="body_accumulated"
                           class="row p-2" style="height: 30px !important;">
                        <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, null, subChartAccount.name)" class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ subChartAccount.title | currency() }}
                        </div>
                        <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, null, subChartAccount.name)" class="col-6 text-center font-weight-400 pointer"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                          {{ (subChartAccount.title > 0 ? subChartAccount.title  / getTotalVolume() : 0) | currency() }}  /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>
                <!--              MESES HEADER-->
                <div v-for="month in MonthsEnum" class="px-0 custom-col"
                     style="; background: white;">
                  <div class="card card-block mt-3"
                       style="border-radius: 0 !important; border: none !important; background: white; box-shadow: none !important; z-index: 1;">
                    <badge
                      :style="month.id === 1 ? 'margin-left: 12px !important;' : month.id === 12 && 'margin-right: 10px'"
                      class="mb-3" style="max-height: 42px !important;height: 42px !important;background: #F2F4F9;margin-left: 6px;
    margin-right: 2px;">
                      <div style="height: 30px !important" id="header_accumulated" class="row p-2"
                           :style="month.id === 1 && 'margin-left: -30px'">
                        <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAccumulatedByMonth(month, item.chart_accounts) | currency() }}
                        </div>
                        <div class="col-6 text-center font-weight-400"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAccumulatedVolumePerMonth(month, item.chart_accounts) | currency() }} /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                    <badge v-show="wasOpenedConfiguration(item.id)" v-for="chartAccount in item.chart_accounts"
                           :class="month.id === 1 && 'bordered-dre'"
                           :style="month.id === 12 && 'margin-right: 10px;border-right: 1px solid #ccc9c9;border-top-right-radius: 10px !important;border-bottom-right-radius: 10px !important;border-radius: 0;'"
                           class="" style="background: white;border-top: 1px solid #ccc9c9;
    border-bottom: 1px solid #ccc9c9;
    border-radius: 0;">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;"
                           :style="month.id !== 1 && 'margin-left: -6px'">
                        <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAccumulatedByMonth(month, [chartAccount]) | currency() }}
                        </div>
                        <div class="col-6 text-center font-weight-400"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getAccumulatedVolumePerMonth(month, [chartAccount]) | currency() }} /m<sup>3</sup>
                        </div>
                      </div>
                      <div v-show="wasOpenedChartAccount(chartAccount)" v-for="subChartAccount in getSubChartAccounts(chartAccount, month.id)"
                           id="header_accumulated"
                           class="row p-2" style="height: 30px !important;"
                           :style="month.id !== 1 && 'margin-left: -6px'">
                        <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, month.id, subChartAccount.name)" class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ subChartAccount.title | currency() }}
                        </div>
                        <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, month.id, subChartAccount.name)" class="col-6 text-center font-weight-400 pointer"
                             style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;"> {{
                            (getVolumeByMonth(month.id) > 0 ? subChartAccount.title / getVolumeByMonth(month.id) : 0) | currency()
                          }}
                          /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>
              </div>
              <div id="total" v-if="indexConfiguration === getConfigurations().length - 1" class="row flex-row flex-nowrap mb-2 mt-1"
                   style="width: 389px !important;">
                <div class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);margin-left: -4px">
                  <div class="card card-block  priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important;">
                    <badge class="pt-4"
                           style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div class="col-6 text-truncate text-center  font-weight-500"
                             style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getSumAccumulatedByCategoriesBefore(null) | currency() }}
                        </div>
                        <div class="col-6 text-truncate text-center  font-weight-400"
                             style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none">
                          {{ (getSumAccumulatedByCategoriesBefore(null) / getTotalVolume() || 0) | currency() }}
                          /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>

                <!--              MESES HEADER-->
                <div v-for="month in MonthsEnum" class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);">
                  <div class="card card-block "
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important; z-index: 1;">
                    <badge class="pt-4"
                           style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                      <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                        <div class="col-6 text-truncate text-center  font-weight-500"
                             style="color: rgb(43, 45, 50); font-size: 14px;">
                          {{ getSumAccumulatedByCategoriesBefore(null, month) | currency() }}
                        </div>
                        <div class="col-6 text-truncate text-center  font-weight-400"
                             style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                          {{
                            (getSumAccumulatedByCategoriesBefore(null, month) / getVolumeByMonth(month.id) || 0) | currency()
                          }}
                          /m<sup>3</sup>
                        </div>
                      </div>
                    </badge>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <DreConfigurationModal @update="load" ref="dreConfigurationModal"/>
    <ModalShowEntries @fetch="load" ref="modalShowEntries"/>
    <GenerateReportModal @onSelected="generateReportModalHandle" ref="generateReportModal"/>
  </div>
  <div v-else>
    <div class="container-fluid">
      <PuzlEmptyData class="mt-6" msg="Selecione uma central para filtrar os dados"
                     v-if="!searchBarFilter.company_plant_selected"/>
      <div
        v-show="searchBarFilter.company_plant_selected"
        class="row mt-5 px-2 mb-0 flex-row flex-nowrap dre-overflow"
        style="white-space: nowrap;overflow: hidden;">
        <div
          class="dre-printing-margin"
          style="max-width: 364px;flex: 0 0 364px;margin-left: 16px;left: 3px;z-index: 3;border-right: none !important;">
          <div class="row flex-row flex-nowrap mb-n3">
            <div class="pl-1 pr-0"
                 style="flex: 0 0 100% !important;max-width: 100% !important;border-radius: 10px !important;">
              <div class="card card-block p-2" ref="cardPayment"
                   style="height: 77px;background: #C6D2E5;box-shadow: none !important;z-index: 3;padding: 24px">
                <div class="row">
                  <div class="col-12 mt-3 ml-4 dre-text" style="font-size: 17px;">
                    <img src="/img/icons/icons8/ios/today_black.svg"
                         style="width: 27px; margin-right: 10px; margin-top: -8px;">
                    PLANO DE CONTAS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8 dre-range-overflow">
          <div class="container-fluid">
            <div class="row flex-row flex-nowrap mb-n3">
              <div class="pl-0 pr-1 custom-col-max">
                <div class="card card-block p-2"
                     style="height: 77px;background: rgb(178, 178, 178);border-radius: 8px !important;padding: 12px;box-shadow: none !important;margin-left: -6px;z-index: 2 !important;">
                  <div class="row">
                    <div
                      class="col-12 text-center dre-text"
                      style="font-size: 14px;">
                      ACUMULADO
                    </div>
                    <div
                      class="col-12 text-center dre-text"
                      style="text-transform: none !important;font-weight: 500;font-size: 20px;">
                      {{ toDecimal(getTotalVolume(), 1) }} m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-1 custom-col" v-for="item in selectedMonth"
                   :key="item.id"
                   style="padding-right: 6px !important;padding-left: 6px !important">
                <div class="card card-block p-2"
                     style="height: 77px;background: #C6D2E5;border-radius: 8px !important;padding: 12px;box-shadow: none !important;margin-left: -5px">
                  <div class="row" style="height: 30px !important;">
                    <div class="col-12 text-center dre-text" style="font-size: 14px;">
                      {{ item?.name }}
                    </div>
                    <div class="col-12 text-center dre-text" style="text-transform: none !important;font-weight: 500;font-size: 20px;">
                      {{  toDecimal(getVolumeByMonth(item.id), 1) }} m<sup>3</sup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="searchBarFilter.company_plant_selected" class="row mt-0 px-2 flex-row flex-nowrap"
           style="white-space: nowrap;max-height: 100% !important;">
        <div class="dre-printing-margin"
             style="max-width: 364px;flex: 0 0 364px;margin-left: 16px;left: 3px;z-index: 3;border-right: none !important;">
          <template v-for="(item, indexConfiguration) in getConfigurations()">
            <div :key="indexConfiguration" v-show="item.before" id="before" class="row flex-row flex-nowrap mb-2 mt-1"
                 style="width: 410px !important;">
              <div class="px-1 chart-account"
                   style="background: rgb(242, 244, 249)">
                <div class="card card-block "
                     style="border-radius: 0 !important;border: none !important;background: rgb(242, 244, 249);box-shadow: none !important;z-index: 3">
              <span class="pt-4"
                    style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                  <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                       style="color: rgb(43, 45, 50); font-size: 16px;">
                    (=) {{ item?.before?.name }}
                  </div>
                </div>
              </span>
                </div>
              </div>
            </div>
            <div class="row flex-row flex-nowrap mb-2"
                 style="width: 410px !important;">
              <div class="px-1 chart-account"
              >
                <div class="card card-block mt-3"
                     style="border-radius: 0 !important;border: none !important;background: white;box-shadow: none !important;z-index: 3">
              <span class="mb-3" style="max-height: 42px !important;height: 42px !important;background: white;">
                <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                  <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                       style="color: rgb(43, 45, 50); font-size: 16px;">
                    <a @click.prevent="setOpenedAllChartAccount(item.id)" v-show="wasOpenedConfiguration(item.id)" href="#">
                      <img src="/img/icons/icons8/ios/minus_key.png" alt="minus_key"
                           width="22px">
                    </a>
                    <a @click.prevent="setOpenedAllChartAccount(item.id)" v-show="!wasOpenedConfiguration(item.id)" href="#">
                      <img src="/img/icons/icons8/ios/plus_key.png" alt="plus_key"
                           width="22px">
                    </a>
                    {{ item.id === 1 ? '(+)' : '(-)' }} {{ item?.name }}
                  </div>
                </div>
              </span>
                  <span v-show="wasOpenedConfiguration(item.id)" v-for="chartAccount in item.chart_accounts" style="border-left: 1px solid #ccc9c9;background: white;border-top: 1px solid #ccc9c9;border-top-right-radius: 0;
    border-bottom-right-radius: 0;margin-right: -4px;
    border-bottom: 1px solid #ccc9c9;margin-left: 20px">
                <div id="header_accumulated" class="row p-2" style="height: 30px !important;width: 100%">
                  <div class="col-12 text-truncate text-left font-weight-400"
                       style="color: rgb(43, 45, 50); font-size: 14px;">
                    <a @click.prevent="setOpenedChartAccount(chartAccount)" v-if="wasOpenedChartAccount(chartAccount)" href="#">
                      <img src="/img/icons/icons8/ios/minus_key.png" alt="minus_key"
                           width="22px">
                    </a>
                    <a @click.prevent="setOpenedChartAccount(chartAccount)" v-else href="#">
                      <img src="/img/icons/icons8/ios/plus_key.png" alt="plus_key"
                           width="22px">
                    </a>
                    {{
                      getChartAccountById(chartAccount)
                    }}
                  </div>
                </div>
                <div v-show="wasOpenedChartAccount(chartAccount)" v-for="subChartAccount in getSubChartAccounts(chartAccount)" id="header_accumulated"
                     class="row p-2" style="height: 30px !important;width: 100%">
                  <div class="col-12 ml-4 text-truncate text-left font-weight-400"
                       style="color: rgb(43, 45, 50); font-size: 14px;">
                    {{
                      subChartAccount?.name
                    }}
                  </div>
                </div>
              </span>
                </div>
              </div>
            </div>
            <div v-show="indexConfiguration === getConfigurations().length - 1"  class="row flex-row flex-nowrap mb-2 mt-1"
                 style="width: 410px !important;">
              <div class="px-1 chart-account"
                   style="background: rgb(242, 244, 249)"
              >
                <div class="card card-block "
                     style="border-radius: 0 !important;border: none !important;background: rgb(242, 244, 249);box-shadow: none !important;z-index: 3">
              <span class="pt-4"
                    style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                  <div class="col-12 text-truncate text-left pl-4 font-weight-500"
                       style="color: rgb(43, 45, 50); font-size: 16px;">
                    (=) LUCRO / PREJUÍZO
                  </div>
                </div>
              </span>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="col-8 dre-range-overflow">
          <div class="container-fluid">
            <template v-for="(item, indexConfiguration) in getConfigurations()">
              <div v-show="item.before" id="before" class="row flex-row flex-nowrap mb-2 mt-1"
                   style="width: 410px !important;">
                <div class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);margin-left: -4px">
                  <div class="card card-block  priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important;z-index: 2 !important;">
                <span class="pt-4"
                      style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div class="col-6 text-truncate text-center  font-weight-500"
                         style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getSumAccumulatedByCategoriesBefore(item.id) | currency() }}
                    </div>
                    <div class="col-6 text-truncate text-center  font-weight-400"
                         style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                      {{ (getSumAccumulatedByCategoriesBefore(item.id) / getTotalVolume() || 0) | currency() }}
                      /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>

                <!--              MESES HEADER-->
                <div v-for="month in selectedMonth" class="px-0 custom-col"
                     :key="month.id"
                     style="background: rgb(242, 244, 249);">
                  <div class="card card-block "
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important; z-index: 1;">
                <span class="pt-4"
                      style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div class="col-6 text-truncate text-center  font-weight-500"
                         style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getSumAccumulatedByCategoriesBefore(item.id, month) | currency() }}
                    </div>
                    <div class="col-6 text-truncate text-center  font-weight-400"
                         style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                      {{
                        (getSumAccumulatedByCategoriesBefore(item.id, month) / getVolumeByMonth(month.id) || 0) | currency()
                      }}
                      /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>
              </div>
              <div  class="row flex-row flex-nowrap mb-2"
                    style="width: 410px !important;">
                <div class="px-0 custom-col"
                     style="; background: white;margin-left: -4px">
                  <div class="card card-block mt-3 priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: white; box-shadow: none !important;">
                <span class="mb-3"
                      style="max-height: 42px !important;height: 42px !important;background: rgb(232, 232, 232);
                         margin-left: -5px;
    margin-right: -4px;">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div  class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAllByMenu(item.chart_accounts) | currency() }}
                    </div>
                    <div class="col-6 text-center font-weight-400 pointer"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAllByMenu(item.chart_accounts) / getTotalVolume() | currency() }} /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                    <!--                  ACUMULADO BODY-->
                    <span v-show="wasOpenedConfiguration(item.id)"  v-for="(itemChartAccount, indexChartAccount) in item.chart_accounts"
                          :class="getBorderedAccumulated(indexChartAccount, item.chart_accounts.length)"
                          style="
    border: 1px solid transparent;
    ;background: rgb(232, 232, 232);border-radius: 0px;
    margin-right: -4px;margin-left: -5px">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAllBySubMenu(itemChartAccount) | currency() }}
                    </div>
                    <div class="col-6 text-center font-weight-400"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAllBySubMenu(itemChartAccount) / getTotalVolume() | currency() }} /m<sup>3</sup>
                    </div>
                  </div>
                  <div v-show="wasOpenedChartAccount(itemChartAccount)" v-for="subChartAccount in getSubChartAccountsSum(itemChartAccount)" id="body_accumulated"
                       class="row p-2" style="height: 30px !important;">
                    <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, null, subChartAccount.name)" class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ subChartAccount.title | currency() }}
                    </div>
                    <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, null, subChartAccount.name)" class="col-6 text-center font-weight-400 pointer"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                      {{ (subChartAccount.title > 0 ? subChartAccount.title  / getTotalVolume() : 0) | currency() }}  /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>
                <!--              MESES HEADER-->
                <div v-for="month in selectedMonth" class="px-0 custom-col"
                     :key="month.id"
                     style="background: white;">
                  <div class="card card-block mt-3"
                       style="border-radius: 0 !important; border: none !important; background: white; box-shadow: none !important; z-index: 1;">
                <span
                  :style="month.id === 1 ? 'margin-left: 12px !important;' : month.id === 12 && 'margin-right: 10px'"
                  class="mb-3" style="max-height: 42px !important;height: 42px !important;background: #F2F4F9;margin-left: 6px;
    margin-right: 2px;">
                  <div style="height: 30px !important" id="header_accumulated" class="row p-2"
                       :style="month.id === 1 && 'margin-left: -30px'">
                    <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAccumulatedByMonth(month, item.chart_accounts) | currency() }}
                    </div>
                    <div class="col-6 text-center font-weight-400"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAccumulatedVolumePerMonth(month, item.chart_accounts) | currency() }} /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                    <span v-show="wasOpenedConfiguration(item.id)" v-for="chartAccount in item.chart_accounts"
                          :class="month.id === 1 && 'bordered-dre'"
                          :style="month.id === 12 && 'margin-right: 10px;border-right: 1px solid #ccc9c9;border-top-right-radius: 10px !important;border-bottom-right-radius: 10px !important;border-radius: 0;'"
                          class="" style="background: white;border-top: 1px solid #ccc9c9;
    border-bottom: 1px solid #ccc9c9;
    border-radius: 0;">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;"
                       :style="month.id !== 1 && 'margin-left: -6px'">
                    <div class="col-6 text-center font-weight-500" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAccumulatedByMonth(month, [chartAccount]) | currency() }}
                    </div>
                    <div class="col-6 text-center font-weight-400"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getAccumulatedVolumePerMonth(month, [chartAccount]) | currency() }} /m<sup>3</sup>
                    </div>
                  </div>
                  <div v-show="wasOpenedChartAccount(chartAccount)" v-for="subChartAccount in getSubChartAccounts(chartAccount, month.id)"
                       id="header_accumulated"
                       class="row p-2" style="height: 30px !important;"
                       :style="month.id !== 1 && 'margin-left: -6px'">
                    <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, month.id, subChartAccount.name)" class="col-6 text-center font-weight-500 pointer" style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ subChartAccount.title | currency() }}
                    </div>
                    <div @click.prevent="handleShowModalEntries(subChartAccount.id, subChartAccount.title, month.id, subChartAccount.name)" class="col-6 text-center font-weight-400 pointer"
                         style="text-transform: none !important; color: rgb(43, 45, 50); font-size: 14px;"> {{
                        (getVolumeByMonth(month.id) > 0 ? subChartAccount.title / getVolumeByMonth(month.id) : 0) | currency()
                      }}
                      /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>
              </div>
              <div id="total" v-if="indexConfiguration === getConfigurations().length - 1" class="row flex-row flex-nowrap mb-2 mt-1"
                   style="width: 410px !important;">
                <div class="px-0 custom-col"
                     style="background: rgb(242, 244, 249);margin-left: -4px">
                  <div class="card card-block  priority-index"
                       ref="secondaryCard"
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important;">
                <span class="pt-4"
                      style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div class="col-6 text-truncate text-center  font-weight-500"
                         style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getSumAccumulatedByCategoriesBefore(null) | currency() }}
                    </div>
                    <div class="col-6 text-truncate text-center  font-weight-400"
                         style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none">
                      {{ (getSumAccumulatedByCategoriesBefore(null) / getTotalVolume() || 0) | currency() }}
                      /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>

                <!--              MESES HEADER-->
                <div v-for="month in selectedMonth" class="px-0 custom-col"
                     :key="month.id"
                     style="background: rgb(242, 244, 249);">
                  <div class="card card-block "
                       style="border-radius: 0 !important; border: none !important; background: rgb(242, 244, 249); box-shadow: none !important; z-index: 1;">
                <span class="pt-4"
                      style="max-height: 42px !important;height: 42px !important;background: rgb(242, 244, 249);">
                  <div id="header_accumulated" class="row p-2" style="height: 30px !important;">
                    <div class="col-6 text-truncate text-center  font-weight-500"
                         style="color: rgb(43, 45, 50); font-size: 14px;">
                      {{ getSumAccumulatedByCategoriesBefore(null, month) | currency() }}
                    </div>
                    <div class="col-6 text-truncate text-center  font-weight-400"
                         style="color: rgb(43, 45, 50); font-size: 14px;text-transform: none;overflow: visible !important;">
                      {{
                        (getSumAccumulatedByCategoriesBefore(null, month) / getVolumeByMonth(month.id) || 0) | currency()
                      }}
                      /m<sup>3</sup>
                    </div>
                  </div>
                </span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//#region Imports
import {MonthsEnum} from "@/enum/MonthsEnum";
import {reactive, ref, computed, onMounted, onUnmounted, onBeforeUnmount, nextTick} from 'vue';
import dreStore from '../../store';
import {initDreListType} from '../../types';
import PuzlEmptyData from '@/components/PuzlEmptyData'
import {
  AppFixedPageTitle,
  AppPageHeader,
  initSearchBarFilterType,
  SearchBarFilterType,
  AppSearchBar,
  AppPageHeaderActions,
  AppSearchBarFilterSection,
  AppPageHeaderActionsButton,
  TabSelectItemType,
} from '../../../../../components/AppGlobal';
import {dialogs, progress, toDecimal } from '../../../../../helpers';
import ModalShowEntries from '@/views/Modules/Administrative/DRE/Shared/_ModalShowEntries';
import GenerateReportModal from './GenerateReportModal';
import DreConfigurationModal from "../shared/DreConfigurationModal.vue";
import {DreConfigurationModalExpose} from '../shared/DreConfigurationModal.vue';
import chart_account from "@/store/modules/configuration/chart-account/chart_account";
import PuzlSelect from "@/components/PuzlSelect.vue";
import {AppTabSelect} from "@/components/AppGlobal";
//#endregion

//#region ComponentRefs
const cardPayment = ref('cardPayment')
const secondaryCard = ref('secondaryCard')
const mainTableDre = ref(null)

/** @type {Ref<DreConfigurationModalExpose>} */
const dreConfigurationModal = ref(null);
const modalShowEntries = ref(null);
const generateReportModal = ref(null);
//#endregion

//#region Data
const isPrinting = ref(false)
const selectedMonth = ref([]);

/** @type {SearchBarFilterType} - Filtro do AppSearchBar */
const searchBarFilter = reactive(initSearchBarFilterType());

/** @type {DreListFilterType} - Filtro principal */
const filter = reactive(initDreListType());

const openedChartAccounts = ref([])
const openedConfigurations = ref([])

let years = ref([])
//#endregion

//#region Computeds
/** Store Getters */
const listed = computed(() => dreStore.getListed());
const isListing = computed(() => dreStore.getIsListing());
//#endregion

//#region Methods
function adjustTableHeight() {
  if (mainTableDre.value) {
    const offsetTop = mainTableDre.value.getBoundingClientRect().top;
    mainTableDre.value.style.maxHeight = `calc(100vh - ${offsetTop + 30}px)`;
  }
}
function generateReportModalHandle(month) {
  expandAllConfigurationAndChartAccounts();
  selectedMonth.value = [month];
  isPrinting.value = true;

  printDreReport(month);
}

const printDreReport = async (month) => {
  await nextTick();

  window.print();

  isPrinting.value = false;
};
function expandAllConfigurationAndChartAccounts(){
  const configurationsList = getConfigurations();

  configurationsList.forEach((config) => {
    if (!openedConfigurations.value.includes(config.id)) {
      openedConfigurations.value.push(config.id);
    }
    config.chart_accounts.forEach((chartAccount) => {
      if (!openedChartAccounts.value.includes(chartAccount)) {
        openedChartAccounts.value.push(chartAccount);
      }
    });
  });
}
function openGenerateReportModal() {
  generateReportModal.value.openModal();
}
function getPastYears(numberOfYears) {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: numberOfYears }, (_, index) => ({
    id: currentYear - index,
    name: (currentYear - index).toString(),
  }));
}
function validBorderAccumulated(index, chart_accounts) {
  if (chart_accounts.length === 1) {
    return 'bordered-start bordered-end'
  }
  if (index === 0) {
    return 'bordered-start'
  }
  if (index === chart_accounts.length - 1) {
    return 'bordered-end'
  }
}

function redirectToChartAccounts() {
  window.open('/configuration/chart-account-resume', '_blank')
}
function wasOpenedChartAccount(chartAccountId) {
  return openedChartAccounts.value.includes(chartAccountId)
}

function wasOpenedConfiguration(configurationId) {
  return openedConfigurations.value.includes(configurationId)
}

function setOpenedChartAccount(chartAccountId) {
  const wasOpened = openedChartAccounts.value.findIndex((item) => item === chartAccountId)
  if (wasOpened === -1) {
    openedChartAccounts.value.push(chartAccountId);
  } else {
    openedChartAccounts.value.splice(wasOpened, 1);
  }
}

function setOpenedAllChartAccount(configurationId) {
  const wasOpened = openedConfigurations.value.findIndex((item) => item === configurationId)
  if (wasOpened === -1) {
    openedConfigurations.value.push(configurationId);
  } else {
    openedConfigurations.value.splice(wasOpened, 1);
  }
}

async function load() {
  if (!searchBarFilter.company_plant_selected) return;
  let loader = progress.showLoader()
  await getConfiguration()
  listItems()
  loader.hide()
}

function handleShowModalEntries(chart_account_id, title = 0,  month = null, name) {
  name = name ? name : getChartAccountById(chart_account_id)
  let monthName = Object.values(MonthsEnum).find((item) => item.id === month).name
  modalShowEntries.value.handleCreateModal(chart_account_id, {
    item: {name: name},
    title: title,
    month: monthName,
    index: month,
    year: filter.year,
    company_plant_id: searchBarFilter.company_plant_selected,
  }, filter.chart_account)
}

function getConfigurations() {
  return Object.values(configurations ?? []).filter((config) => !!config.chart_accounts)
}

function getSumAccumulatedByCategoriesBefore(category_id, month = null) {
  let categories = []
  if (category_id) {
    categories = Object.values(configurations ?? []).filter((config) => config.id < category_id && config.id !== 1)
  }
  else {
    categories = Object.values(configurations ?? []).filter((config) => config.id !== 1)
  }
  let chart_account_ids = []
  for (let category of categories) {
    if (category.chart_accounts) {
      chart_account_ids = [...chart_account_ids, ...category.chart_accounts]
    }
  }
  let total = 0
  let brutal_receipt = 0
  let BrutalReceiptChartAccount = Object.values(configurations ?? []).find((config) => config.id === 1)
  if (month) {
    if (BrutalReceiptChartAccount.chart_accounts) {
      brutal_receipt = getAccumulatedByMonth(month, BrutalReceiptChartAccount.chart_accounts)
    }
    return brutal_receipt - getAccumulatedByMonth(month, chart_account_ids)
  }
  for (let i = 1; i <= 12; i++) {
    if (BrutalReceiptChartAccount.chart_accounts) {
      brutal_receipt += getAccumulatedByMonth({id: i}, BrutalReceiptChartAccount.chart_accounts)
    }
    total -= getAccumulatedByMonth({id: i}, chart_account_ids)
  }
  return brutal_receipt + total
}

function getVolumeByMonth(month) {
  if (!listed.value.total_volume || !listed.value.total_volume.total) {
    return 0
  }
  let monthFound = Object.values(listed.value.total_volume.months).find((item) => item.month === month)
  let total = monthFound ? monthFound.total : 0
  return isNaN(total) ? 0 : total
}

function getAccumulatedVolumePerMonth(month, chart_accounts) {
  let total = getAccumulatedByMonth(month, chart_accounts)
  let volume = getVolumeByMonth(month.id)
  return volume > 0 && total > 0 ? total / volume : 0
}

/**
 * Pega o acumulado por mes
 */
function getAccumulatedByMonth(month, chart_accounts) {
  if (!chart_accounts) {
    return 0
  }
  let total = 0
  const chartAccounts = Object.values(listed.value.chart_accounts)

  for (let i in chartAccounts) {
    if (!chart_accounts.includes(chartAccounts[i].id)) {
      continue;
    }
    if (!chartAccounts[i].months) {
      continue;
    }
    for (let m in chartAccounts[i].months[month.id].details) {
      total += Number(chartAccounts[i].months[month.id].details[m].title)
    }
  }
  return isNaN(total) ? 0 : total
}

function getTotalVolume() {
  if (!listed.value.total_volume || !listed.value.total_volume.total) {
    return 0
  }
  return listed.value.total_volume.total
}

function getAllBySubMenu(chartAccountId) {
  let selected = Object.values(listed.value.chart_accounts).find((item) => item.id === chartAccountId)
  return selected && selected.total ? selected.total : 0
}

function getBorderedAccumulated(indexChartAccount, chartAccountsLength) {
  if (indexChartAccount === 0) {
    return 'bordered-top'
  }
  if (indexChartAccount === chartAccountsLength - 1) {
    return 'bordered-bottom'
  }
}

function getAllByMenu(chartAccounts) {
  let selected = Object.values(listed.value.chart_accounts).filter((item) => chartAccounts.includes(item.id))
  return selected.reduce((ac, val) => {
    return ac + val.total;
  }, 0);
}

function getSubChartAccounts(chartAccount, month = 1) {
  if (!listed.value.chart_accounts[chartAccount]?.months) {
    return []
  }
  return Object.values(listed.value.chart_accounts[chartAccount]?.months[month].details)
}

function getSubChartAccountsSum(chartAccount) {
  let details = {}
  for (let i = 1; i <= 12; i++) {
    let subChartAccounts = Object.values(listed.value.chart_accounts[chartAccount]?.months[i]?.details ?? [])
    for (let index in subChartAccounts) {
      let item = subChartAccounts[index]
      if (details[item.id]) {
        details[item.id].title += Number(item.title)
      } else {
        details[item.id] = {
          id: item.id,
          title: Number(item.title),
          name: item.name
        }
      }
    }
  }
  return details
}

function getChartAccountById(id) {
  let selected = Object.values(listed.value.chart_accounts).find((item) => item.id === id)
  if (selected && selected.code) {
    return selected.code + '. ' + selected.name
  }
  return selected && selected.name ? selected.name : null
}

/**
 * Limpa os filtros e atualiza a lista de itens.
 *
 * @param {boolean} isRefreshList - Atualiza a lista após limpar os filtros.
 * @param {boolean} withStoreFilters - Usa filtros da store se verdadeiro.
 */
function clearFilter(isRefreshList = true, withStoreFilters = true) {
  // Inicializa filtros com os valores padrão
  let searchBarFilterValue = defaultSearchBarFilter();
  let filterValue = defaultFilter();

  // Caso `withStoreFilters` seja verdadeiro, obter filtros armazenados na store
  const storeFilters = withStoreFilters ? dreStore.getPageState() : null;
  if (storeFilters) {
    searchBarFilterValue = storeFilters.searchBarFilter;
    filterValue = storeFilters.filter;
  }
  // Aplica os filtros
  Object.assign(searchBarFilter, searchBarFilterValue);
  Object.assign(filter, filterValue);

  // Listar itens
  if (isRefreshList) {
    listItems();
  }
}

/**
 * Padrão do filtro AppSearchBar
 * @returns {SearchBarFilterType}
 */
function defaultSearchBarFilter() {
  return initSearchBarFilterType();
};

/**
 * Padrão do filtro principal
 * @returns {MdfeListFilterType}
 */
function defaultFilter() {
  return {
    ...initDreListType(),
    status: null,
  };
}

/**
 * Listar itens
 * @param {boolean} isAccumulateItems
 */
async function listItems(isAccumulateItems = false) {
  // prepareFilter();
  if (!searchBarFilter.company_plant_selected) return;
  let loader = progress.showLoader()
await  dreStore.list({...filter, ...searchBarFilter}, isAccumulateItems);
  loader.hide()
}
/**
 * Abrir modal para criar registro
 */
function openDreConfigModal() {
  dreConfigurationModal.value.openModal();
}

const configurations = reactive({})

async function getConfiguration() {
  const data = await dreStore.getConfiguration()
  Object.assign(configurations, data)
  for (let i in data) {
    openedConfigurations.value.push(Number(data[i].id))
  }
}

async function init() {
  let loader = progress.showLoader()
  await clearFilter(true, true);
  loader.hide()
}

/**
 * Remover registro
 * @param {number} id
 */
async function removeStockLocation(id) {
  const isConfirmed = await dialogs.confirmRemove();
  if (isConfirmed) {
    await dreStore.remove(id);
    dialogs.notifyRemoved();
  }
}
//#endregion

//#region Lifecycle
onBeforeUnmount(() => {
  window.removeEventListener('resize', adjustTableHeight);
});

onUnmounted(() => {
  searchBarFilter.custom_search_values = [];
  dreStore.resetStates();
  dreStore.setPageState({filter, searchBarFilter});
});

onMounted(async () => {
  adjustTableHeight();
  window.addEventListener('resize', adjustTableHeight);
  await getConfiguration()
  await init();
  years.value = getPastYears(3)
  const col_scrolled = document.getElementById('col_scrolled')
  if (col_scrolled) {
    col_scrolled.addEventListener("scroll", function (e) {
      document.getElementById('col_scrolled_title').scrollLeft = e.target.scrollLeft;
      if (e.target.scrollLeft >= 25) {
        for (let i in secondaryCard.value) {
          secondaryCard.value[i].classList.add('medium-index')
          secondaryCard.value[i].classList.remove('priority-index')
        }
        cardPayment.value.classList.add('border-no-radius-right')
        cardPayment.value.classList.remove('border-radius-right')
      } else {
        for (let i in secondaryCard.value) {
          secondaryCard.value[i].classList.remove('medium-index')
          secondaryCard.value[i].classList.add('priority-index')
        }
        cardPayment.value.classList.add('border-radius-right')
        cardPayment.value.classList.remove('border-no-radius-right')
      }
    })
  }
});
//#endregion
</script>

<style>
.medium-index {
  z-index: 2 !important;
}

.priority-index {
  z-index: 4 !important;
}

@media only screen and (min-width: 600px) {
  .dre-overflow-margin {
    margin-right: -0.75rem;
    position: sticky !important;
  }

}

@media only screen and (max-width: 600px) {
  .dre-overflow-margin {
    margin-right: 5px
  }
}

.border-no-radius-right {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-radius-right {
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.bordered-start {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 8px !important;
}

.bordered-end {
  border-bottom-left-radius: 12px !important;
  border-bottom-right-radius: 8px !important;
}

.chart-account {
  flex: 0 0 100% !important;
  max-width: 100% !important;
  background: white;
  z-index: 3;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  margin-left: 4px !important;
  border-right: none !important;
}

.dre-text {
  color: #2B2D32;
}

.custom-col {
  flex: 0 0 274px !important;
  max-width: 274px !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.custom-col-max {
  flex: 0 0 278px !important;
  max-width: 278px !important;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.bordered-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.bordered-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
</style>
