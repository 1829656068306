<template>
  <div>
    <modal size="lg"
      :show.sync="isOpen"
      :backgroundColor="'#F2F7F3'"
      :showClose="false"
    >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <AppIcon icon="plus-math" color="success" width="21" />
            </div>
            <span class="text-title">
              {{ title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="isOpen = false;" src="/img/icons/icons8/ios/close-window.png" alt="close-window" width="17px">
            </div>
          </div>
        </template>
      </template>

      <ServiceForm
        :formData="formData"
        @onCancelClick="closeModal"
        @onSubmitClick="handleSubmitForm"
      />
    </modal>
  </div>
</template>

<script setup>
//#region Imports
import { reactive, ref } from "vue";
import { ServiceType, initServiceType } from "../../types";
import serviceStore from "../../store/serviceStore.js"
import { dialogs, progress } from "../../../../../helpers";
import ServiceForm from "../shared/ServiceForm.vue";
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
//#endregion

//#region Data
const isOpen = ref(false);
const title = "Adicionar Serviço";
/** @type {ServiceType} */
const formData = reactive(initServiceType());
//#endregion

//#region Methods
/** Abrir modal */
function openModal() {
  setFormData();
  isOpen.value = true;
}

/** Fechar modal */
function closeModal() {
  isOpen.value = false;
}

/** Resetar dados do formulário */
function setFormData() {
  Object.assign(formData, initServiceType());
}

/** Criar registro */
function handleSubmitForm() {
  progress.start();
  const loader = progress.showLoader();
  serviceStore.actions.create(formData)
    .then(() => {
      dialogs.notify();
      closeModal();
    })
    .finally(() => {
      progress.finish();
      loader.hide();
    });
}
//#endregion

//#region DefineExposes
/**
 * @typedef {Object} ServiceCreateModalExpose
 * @property {typeof openModal} openModal
 */
defineExpose({
  openModal,
});
//#endregion
</script>

<style scoped></style>
