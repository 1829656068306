<template>
  <div class="card-body mt-n2 p-0">
    <div class="row">
      <ShadedTable :length="bill_receives.length"
                   :headers="headers"
                   :loading="loadingSkeleton">
        <template v-for="(bill_receive, index) in bill_receives" :slot="index">
          <ShadedCol align="center" customStyle="display: flex;">
            <BillReceivePossibleActions :bill_receive="bill_receive"
                                        :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
                                        :changeInstallmentStatus="changeInstallmentStatus"
                                        :listType="listType"
                                        :index="index"/>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="m-0 new-default-black-font font-weight-normal"
                  style="display: inline-flex;">
                {{ bill_receive.contract_proposal_code }}
              </h5>
              <div style="float: right;">
                <el-popover v-if="bill_receive.compensation_user_name && bill_receive.status === 1" trigger="click"
                            placement="left">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/icons8/ios/us-dollar-circled--v1.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Compensação de Crédito</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{ bill_receive.compensation_user_name }}</h5>
                    </div>
                    <div class="col-12">
                      <h5 class="font-weight-normal">{{
                          bill_receive.compensation_created_at | parseDate("DD/MM/YYYY")
                        }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/icons8/ios/us-dollar-circled--v1.png" width="25">
                  </base-button>
                </el-popover>
                <el-popover v-else trigger="click" placement="left">
                  <span style="width: 290px;"
                        class="row">
                    <div class="col-12 mb-2" style="display: flex; align-items: center;">
                      <img class="mr-2" src="/img/icons/merchant-account.png" width="22">
                      <h4 style="font-size: 14px;" class="p-0 m-0 font-weight-500">Conta bancária</h4>
                    </div>
                    <div class="col-12 mt-0 mb-n2">
                      <h5 class="font-weight-normal">{{bill_receive.installment_bank_account_name || bill_receive.bank_account_name }}</h5>
                    </div>
                  </span>
                  <base-button outline slot="reference" size="sm" type="secundary"
                               class="p-0 m-0 mr-2 shadow-none text-indigo">
                    <img src="/img/icons/merchant-account.png" width="25">
                  </base-button>
                </el-popover>
                <base-button @click.prevent="$emit('handleListContacts', bill_receive.contract_proposal_uuid)"
                             outline size="sm" type="secundary"
                             class="p-0 m-0 shadow-none text-indigo">
                  <img src="/img/icons/icons8/ios/phone-contact_warning.png" width="25">
                </base-button>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="m-0 new-default-black-font font-weight-normal"
                style="display: inline-flex;">
                {{ $helper.truncateText(bill_receive.entity_name, 43) }}
              </h5>
              <h5 v-if="!bill_receive.entity_id || bill_receive.type === 0"
                  class="m-0 new-default-black-font font-weight-normal"
                  style="display: inline-flex;">
                <small class=" text-muted">
                  <img width="22" src="/img/icons/not_identified.png"/>
                  DEPÓSITO NÃO IDENTIFICADO
                </small>
              </h5>
              <base-button style="display: inline-flex;float: right" outline size="sm" type="secundary"
                           :title="`Saldos do cliente - Antecipação: R$: ${formatCurrency(bill_receive.anticipation_balance)}`"
                           @click.prevent="$emit('handleModalEntityHistory',
                  bill_receive.entity_id,
                  bill_receive.entity_uuid,
                  bill_receive.document,
                  bill_receive.entity_name
                )"
                           class="p-0 m-0 shadow-none text-indigo">
                <img src="/img/icons/wallet.png" width="25">
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                {{ bill_receive.document }}
              </h5>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                {{ bill_receive.payment_method_name }}
              </h5>
            </div>
            <div>
              <base-button outline size="sm" type="secundary"
                           v-if="bill_receive.last_billet !== false"
                           @click.prevent="$emit('downloadBilletBatch', bill_receive.bank_billet_batch_id, bill_receive.last_billet)"
                           :class="backgroundBillet(bill_receive.last_billet)"
                           class="p-0 m-0 shadow-none text-indigo">
                <i class="mr-1 fa-solid fa-barcode"
                   :title="`Status: ${textBillet(bill_receive.last_billet)}`"
                   :class="backgroundBillet(bill_receive.last_billet)"
                   style="font-size: 16px;"></i>
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 v-if="bill_receive.invoices.length && bill_receive.type === 1"
                  class="m-0 mr-2 new-default-black-font font-weight-normal">
                {{ bill_receive.invoices[0].id }}
              </h5>
            </div>
            <div>
              <base-button v-if="bill_receive.invoices.length && bill_receive.type === 1"
                           @click.prevent="$emit('downloadInvoice', bill_receive.invoices[0].id)"
                           outline size="sm" type="secundary"
                           class="p-0 m-0 shadow-none text-indigo">
                <img src="/img/icons/pdf-v2.png" class="ml-1" width="22">
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 v-if="bill_receive.invoices.length"
                  class="m-0 mr-2 new-default-black-font font-weight-normal">
                {{
                  (bill_receive.invoices[0].number !== 'null' && bill_receive.invoices[0].number !== null)
                    ? bill_receive.invoices[0].number.toString().slice(-6) : 'N/E'
                }}
              </h5>
            </div>
            <div>
              <base-button v-if="bill_receive.invoices.length && bill_receive.type === 1"
                           @click.prevent="$emit('nfseButtonClick', bill_receive.invoices[0].link_pdf)"
                           outline size="sm" type="secundary"
                           class="p-0 m-0 shadow-none text-indigo">
                <img class="ml-1" width="24" src="/img/icons/nfe_blue.png"/>
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div>
              <h5 class="m-0 mt-1 new-default-black-font font-weight-normal">
                {{ bill_receive.due_date | parseDate('DD MMM YYYY') }}
              </h5>
            </div>
            <div>
              <img
                class="pointer mt-1"
                v-show="Boolean(bill_receive.exists_attachments)"
                src="/img/icons/attach-green.png"
                @click.prevent="$emit('handleShowAttachments', bill_receive, index)"
                width="20"
              />
              <base-button
                v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && validBillet(bill_receive)"
                @click.prevent="$emit('openModalChangeDueDate', bill_receive.installment_id, bill_receive.original_due_date)"
                outline size="sm" type="secundary"
                class="p-0 m-0 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/overtime_warning.png"/>
              </base-button>
              <base-button
                v-if="$hasPermission('bill_receive.change_due_date') && bill_receive.type === 1 && (validFinancialLimit()) && !([1, 3].includes(bill_receive.status) || bill_receive.identified || bill_receive.reconciled) && (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5' && bill_receive.last_billet !== false)"
                @click.prevent="$emit('showModalEditGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type, bill_receive.index, bill_receive.index)"
                outline size="sm" type="secundary"
                class="p-0 m-0 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/overtime_warning.png"/>
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol :align="!bill_receive.paid_at && showCompensationButtons(bill_receive) ? 'center' : 'left'">
            <h5
              v-if="bill_receive.paid_at"
              class="new-default-black-font font-weight-normal m-0 mt-1">
              {{ bill_receive.paid_at | parseDate('DD MMM YYYY') }}
            </h5>
            <div v-else-if="!bill_receive.paid_at && showCompensationButtons(bill_receive)">
              <base-button
                v-if="$hasPermission('additional_permissions.financial_approvation') &&
                $helper.strToNum(bill_receive.anticipation_balance) > 0"
                @click.prevent="$emit('showModalEntityPayment', bill_receive.contract_proposal_payment_uuid, bill_receive.installment_id, bill_receive.contract_proposal_uuid)"
                outline size="sm" type="secundary"
                title="Compensação Parcial"
                class="p-0 m-0 ml-1 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/refund-2_primary.png"/>
              </base-button>
              <base-button
                v-if="bill_receive.type === 1 &&
                compareBalance(bill_receive.anticipation_balance, bill_receive.total_value) &&
                $hasPermission('bill_receive.receive_installment')" outline size="sm" type="secundary"
                @click.prevent="$emit('handlePayOffPayment', bill_receive)"
                title="Compensação Total"
                class="p-0 m-0 ml-1 shadow-none text-indigo">
                <img class="mr-1" width="24" src="/img/icons/icons8/ios/us-dollar-circled--v1.png"/>
              </base-button>
            </div>
          </ShadedCol>
          <ShadedCol>
            <div style="align-items: center;">
              <h5 class="m-0 new-default-black-font font-weight-normal"
                  style="display: inline-flex;">
                {{ bill_receive.value  | currency() }}
              </h5>
              <div class="no-shadow-card-blue"
                   style="float: right;margin-top: 4px;">
                <h4 class="m-0 p-0">
                  {{ bill_receive.index + "/" + bill_receive.total_index }}
                </h4>
              </div>
            </div>
          </ShadedCol>
          <ShadedCol align="center">
            <SettingOptions :bill_receive="bill_receive"
                            :listType="listType"
                            :paymentInstallmentStatusEnum="paymentInstallmentStatusEnum"
                            @handleEditBillReceive="$emit('handleEditBillReceive', bill_receive.contract_proposal_payment_uuid, bill_receive.contract_proposal_uuid)"
                            @handleShowHistory="$emit('handleShowHistory', bill_receive)"
                            @handleOpenModalInfo="$emit('handleOpenModalInfo', bill_receive)"
                            @handleIdentifyEntity="$emit('handleIdentifyEntity', bill_receive)"
                            @handleModalEntityRegistry="$emit('handleModalEntityRegistry', bill_receive.entity_id, bill_receive.entity_uuid, bill_receive.entity_name,bill_receive.document,)"
                            @openModalChangeDueDate="$emit('openModalChangeDueDate', bill_receive.installment_id, bill_receive.original_due_date)"
                            @showModalEditGenerateBilletHasGenerated="$emit('showModalEditGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type, bill_receive.index, bill_receive.index)"
                            @showModalGenerateBillet="$emit('showModalGenerateBillet', bill_receive.id, bill_receive.total_index, bill_receive.type)"
                            @handleDeleteBillReceive="$emit('handleDeleteBillReceive', bill_receive.id)"
                            @showModalGenerateBilletHasGenerated="$emit('showModalGenerateBilletHasGenerated', bill_receive.id, bill_receive.total_index, bill_receive.type)"
                            @postBatchUpdate="$emit('postBatchUpdate', bill_receive.bank_billet_batch_id)"/>
          </ShadedCol>
        </template>
      </ShadedTable>
    </div>
  </div>
</template>
<script>
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ShadedTable from "@/components/ShadedTable.vue";
import ShadedCol from "@/components/ShadedCol.vue";
import SettingOptions from "../Common/_SettingOptions.vue";
import BillReceivePossibleActions from "../Common/_BillReceivePossibleActions.vue";

export default {
  name: "TableBillReceive",
  props: {
    bill_receives: Array,
    loadingSkeleton: Boolean,
    paymentInstallmentStatusEnum: Object,
    showCompensationButtons: {type: Function},
    changeInstallmentStatus: {type: Function},
    formatCurrency: {type: Function},
    compareBalance: {type: Function},
    listType: String,
  },
  components: {
    ShadedTable,
    ShadedCol,
    SettingOptions,
    BillReceivePossibleActions
  },
  data() {
    return {
      headers: ["Status", "Contrato", "Cliente", "CPF/CNPJ", "Método", "Fatura", "NFS-E", "Vencimento", "Crédito", "Valor", "Ação"]
    };
  },
  methods: {
    validFinancialLimit() {
      return ((this.$user.financial_limit && this.$user.financial_limit.disable_max_extension_due_date && this.$user.financial_limit.general_extension_due_date > 0) || !this.$user.financial_limit || !this.$user.financial_limit.disable_max_extension_due_date)
    },
    validBillet(bill_receive) {
      if (bill_receive.payment_method_uuid === '947bf938-e098-4d1e-965a-c33fc9aa05d5') {
        if (bill_receive.last_billet === false) {
          return true
        } else {
          return false
        }
      }
      return true
    },
    backgroundBillet(bank_billet) {
      switch (bank_billet.status) {
        case 0:
          return 'text-primary'
        case 1:
          return 'text-warning'
        case 2:
          return 'text-danger'
        case 3:
          return 'text-success'
        case 4:
          return 'text-darkred'
        case 5:
        case 6:
          return 'text-dark'
      }
    },
    textBillet(bank_billet) {
      switch (bank_billet.status) {
        case 0:
          return 'Gerando'
        case 1:
          return 'Emitido'
        case 2:
          return 'Cancelado'
        case 3:
          return 'Pago'
        case 4:
          return 'Vencido'
        case 5:
          return 'Falha'
        case 6:
          return 'Inválido'
      }
    },
  },
};
</script>

<style scoped>
.mini-card {
  border: 1px solid #E8E8E8;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 1rem !important;
}

.colorize-on-hover:hover {
  background-color: #1b6eba;
}

.colorize-on-hover:hover small, .colorize-on-hover:hover img {
  filter: brightness(0) invert(1);
}


.card-with-box-shadow {
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 10px !important;
}

.no-shadow-card-blue {
  border-radius: 5px !important;
  background-color: #5394c9;
  padding: 0px 5px;
  box-shadow: 0 0 2px 0 #0b7bfd52;
}

.no-shadow-card-blue h4 {
  color: white;
  padding: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.mini-card-green {
  background-color: #8bd5af52;
  border-radius: 5px;
  padding: 1px 5px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 #149e5752;
}

.mini-card-red {
  background-color: #f459592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #d2272752;
}

.mini-card-yellow {
  background-color: #f4dc592e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #fdef0b52;
}

.mini-card-blue {
  background-color: #4182e52e;
  border-radius: 5px;
  padding: 1px 5px;
  box-shadow: 0 0 5px 0 #0b7bfd52;
}

.mini-card-green h4 {
  color: #149e57;
}

.mini-card-red h4 {
  color: #db4539;
}

.mini-card-yellow h4 {
  color: #f2b532;
}

.mini-card-blue h4 {
  color: #1b6eba;
}

.mini-card-red h4, .mini-card-green h4, .mini-card-yellow h4, .mini-card-blue h4 {
  font-size: 12px;
}
</style>


