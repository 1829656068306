/**
 * Tipo para representar um item do avulso
 *
 * @typedef {Object} DetachedItemType
 * @property {number} id [V]                       
 * @property {string} description                 
 * @property {number} status                      
 * @property {string} date_at                     
 * @property {number} quantity                    
 * @property {number} value                       
 * @property {number} company_plant_id            
 * @property {number} contract_proposal_id        
 * @property {string} created_at [V]              
 * @property {number} created_by_user_id [V]       
 * @property {string} code [V]                    
 * @property {string} construction_name [V]     
 * @property {string} customer_name [V]           
 * @property {string} plant_name [V]            
 * @property {string} user_name [V]             
 * @property {number} billed                   
 */
export const DetachedItemType = {};

/**
 * Inicializar DetachedItemType
 * 
 * @returns {DetachedItemType}
 */
export const initDetachedItemType = () => {
  return {
    id: 0,
    description: '',
    status: 0,
    date_at: '',
    quantity: 0,
    value: 0,
    company_plant_id: 0,
    contract_proposal_id: 0,
    created_at: '',
    created_by_user_id: 0,
    code: '',
    construction_name: '',
    customer_name: '',
    plant_name: '',
    user_name: '',
    billed: 0
  };
};
