import store from "../../../../shared/libraries/store";
import { initServiceDescriptionListPagerType, ServiceDescriptionListFilterType, ServiceDescriptionListPagerType, ServiceDescriptionType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../shared/store";
import httpClient from "../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/commercial/service-descriptions",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "SERVICE_DESCRIPTION_ITEM",
  LISTED: "SERVICE_DESCRIPTION_LISTED",
  IS_LISTING: "SERVICE_DESCRIPTION_IS_LISTING",
  IS_FINDING: "SERVICE_DESCRIPTION_IS_FINDING",
  IS_SAVING: "SERVICE_DESCRIPTION_IS_SAVING",
  IS_REMOVING: "SERVICE_DESCRIPTION_IS_REMOVING",
  PAGE_STATES: "SERVICE_DESCRIPTION_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?ServiceDescriptionType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {ServiceDescriptionListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initServiceDescriptionListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{  searchBarFilter: SearchBarFilterType, filter: ServiceDescriptionListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
}

/** MUTATIONS */
const mutations = {
  /**
   * @param {ServiceDescriptionType} item
   * @returns {ServiceDescriptionType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),
  
  /**
   * @param {ServiceDescriptionListPagerType} listed
   * @returns {ServiceDescriptionListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),
  
  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),
  
  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),
  
  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),
  
  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),
  
  /**
   * @param {ServiceDescriptionType} created
   * @returns {ServiceDescriptionType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),
  
  /**
   * @param {ServiceDescriptionListPagerType} listed
   * @returns {ServiceDescriptionListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),
  
  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),
  
  /**
   * @param {ServiceDescriptionType} updated
   * @returns {ServiceDescriptionType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),
  
  /** @param {{ searchBarFilter: SearchBarFilterType, filter: ServiceDescriptionListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),
  
  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
}

/** ACTIONS */
const actions = {
  /**
   * Incluir
   *
   * @param {ServiceDescriptionType} data
   * @returns {Promise<ServiceDescriptionType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Localizar por ID
   *
   * @param {number} id
   * @returns {Promise<?ServiceDescriptionType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar
   *
   * @param {ServiceDescriptionListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?ServiceDescriptionListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Deletar por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar por ID
   *
   * @param {ServiceDescriptionType} data
   * @param {number} id
   * @returns {Promise<ServiceDescriptionType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),
  
  /**
   * Atualizar Status por ID
   *
   * @param {boolean} status
   * @param {number} id
   * @returns {Promise<ServiceDescriptionType>}
   */
  updateStatus: async (status, id) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/${id}/status/${status?1:0}`;
      const res = await httpClient.put(url);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }
      
      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },
}

export default {
  actions,
  getters,
  mutations,
};
