<template>
  <div>
    <modal size="lg"
      :show.sync="modal"
      :backgroundColor="'#F2F4F9'"
    >
      <template slot="header">
        <div class="d-flex align-items-center container-fluid" style="gap: 12px; margin-left: -28px;">
          <div>
            <img src="/img/icons/create-new.png" height="28" width="28">
          </div>
          <span class="text-title">
            {{ title }}
          </span>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal()"
        />
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <span class="ml-2 mb-2 label-title">
                <img height="30px" src="/img/icons/icons8/ios/info-squared.png" class="mr-2"/>
                Identificação do cliente
              </span>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Status
                </label>
                <div class="col-md-3">
                  <base-input input-group-classes="input-group-sm">
                    <ToggleButton 
                      :initialValue="entity?.status ?? false"
                      inactiveLabel="Bloqueado"
                      inactiveImage="/img/icons/icons8/ios/lock_danger.png"
                      @update:initialValue="entity.status = $event"
                    />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  CPF/CNPJ
                  <span class="text-danger">&nbsp;*</span>
                  <el-popover trigger="hover" placement="bottom" class="p-0 pr-0" v-if="alertDocument">
                    <span> Já existe um cliente/obras cadastrado com esse mesmo documento. </span>
                    <base-button outline slot="reference" size="sm" type="secundary" class="p-0 m-0 shadow-none text-warning">
                      <i class="fa-solid fa-circle-exclamation fa-lg mr-n4"></i>
                    </base-button>
                  </el-popover>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm" group class="mb-2">
                      <input
                        type="text"
                        disabled
                        v-model="entity.document"
                        class="form-control form-control-sm"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                        v-on:keypress="changeIsLegal"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  Nome
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider rules="required" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text" v-model="entity.entity_name" class="form-control form-control-sm"
                        :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  E-mail Principal
                </label>
                <div class="col-md-8">
                  <validation-provider rules="email" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <input type="text" v-model="entity.email"
                            :class="errors[0] ? 'is-invalid' : 'is-valid'"
                            class="form-control form-control-sm"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Telefone principal
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input type="tel" v-model="entity.phone" class="form-control form-control-sm"
                          v-mask="['(##) ####-####', '(##) #####-####']"/>
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 pr-0 col-form-label form-control-label label-item">
                  Indicador Insc. Estadual
                </label>
                <div class="col-md-8">
                  <validation-provider :rules="entity.contributing_number && 'required'" v-slot="{errors}">
                    <base-input input-classes="form-control-sm">
                      <el-select v-model="entity.state_registration" size="mini" filterable placeholder="Selecione"
                                :class="errors[0] ? 'is-invalid' : 'is-valid'">
                        <el-option v-for="state_registration in option_state_registrations"
                                  :key="state_registration.value" :label="state_registration.label"
                                  :value="state_registration.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div 
                v-if="entity.state_registration !== stateRegistrationEnum.non_contributor.id" 
                class="form-group row m-0 p-0 mb-1"
              >
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Insc. Estadual
                  <span
                    v-if="entity.state_registration === stateRegistrationEnum.contributor.id" 
                    class="text-danger"
                  >
                    &nbsp;*
                  </span>
                </label>
                <div class="col-md-8">
                  <validation-provider
                  :rules="
                      entity.state_registration === stateRegistrationEnum.contributor.id 
                        ? 'required' 
                        : ''
                    "
                  >
                    <base-input input-group-classes="input-group-sm">
                      <input
                        type="text"
                        v-model="entity.contributing_number"
                        class="form-control form-control-sm"
                        @input="removeSpecialCharactersContributingNumber"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 col-form-label form-control-label label-item">
                  Insc. Municipal
                </label>
                <div class="col-md-8">
                  <base-input input-group-classes="input-group-sm">
                    <input
                      type="text"
                      v-model="entity.municipal_registration"
                      class="form-control form-control-sm"
                      @input="removeSpecialCharactersMunicipalRegistration"/>
                  </base-input>
                </div>
              </div>

              <div class="form-group row m-0 p-0 mb-1">
                <label class="col-md-4 pb-0 pr-0 col-form-label form-control-label label-item">
                  Intermediador
                </label>
                <div class="col-md-8">
                  <base-input input-classes="form-control-sm">
                    <PuzlSelect 
                      v-model="entity.user_intermediary_id" 
                      :items="$_usersIntermediaries"  
                      :loading="loadingUsersIntermediaries"
                      :disabled="! $_commercial_permission.is_edit_intermediary_seller_field"
                    />
                  </base-input>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <div class="form-group row m-0 p-0">
                <label class="col-md-4 mt-n1 col-form-label form-control-label label-title">
                  <img 
                    height="32px" 
                    src="/img/icons/contacts.png" 
                    class="mr-2 mb-2" 
                  />
                  Contatos
                </label>
                <div class="col-md-8">
                  <span 
                    @click="openModalCreateEntityContact"
                    style="width: 80px;display: flex; align-items: center;"
                    class="btn btn-sm btn-outline-success p-1 mt-2 text-center float-right"
                  >
                    <i class="fas fa-plus ml-2 mr-2"></i> NOVO
                  </span>
                </div>
                <div style="width: 100%;">
                  <div 
                    v-for="(item, index) in entityContacts" 
                    :key="index" 
                    style="width: 100%; margin-bottom: 8px;"
                  >
                    <div 
                      class="card"
                      :title="getContactTitle(item)"
                      style="
                        width: 100%;
                        padding: 1rem;
                        margin-bottom: 0.5rem;
                        background-color: #f8f9fa;
                        border: 1px solid #dee2e6;
                      "
                    >
                      <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="display: flex; align-items: center; flex-grow: 1;">
                          <span>{{ item.name }}</span>
                          <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          <span v-if="item.office">
                            {{ item.office }}
                            <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          </span>
                          <span v-if="item.email">
                            {{ item.email }}
                            <b class="text-dark" style="margin-left: 1.5rem; margin-right: 1.5rem;">|</b>
                          </span>
                          <span>{{ item.phone }}</span>
                        </div>
                        <div style="margin-left: 15px;">
                          <a href="#" @click="openModalEditEntityContact(item.id)">
                            <img
                              v-if="!item.is_new_registry"
                              height="23px" 
                              src="/img/icons/icons8/ios/create-new-yellow.png" 
                              class="ml-2" />
                          </a>
                          <a href="#" @click="deleteEntityContact(item)">
                            <img 
                              height="23px" 
                              src="/img/icons/icons8/ios/delete_danger.png" 
                              alt="Deletar"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2" style="margin-bottom: 15px !important;">
              <div class="form-group row m-0 p-0 mt-3">
                <label class="col-md-5 mt-n1 col-form-label form-control-label label-title">
                  <img 
                    height="32px" 
                    src="/img/icons/check.png" 
                    class="mr-2 mb-2" 
                  />
                  Observação para nfs-e
                </label>
                <label class="col-md-10 pb-0 mb-4 mt-n2 col-form-label form-control-label label-item">
                  Obrigatório
                </label>
                <div class="col-md-2">
                  <base-input input-group-classes="input-group-sm float-right" style="margin-right: -11px !important;">
                      <ToggleButton 
                        :initialValue="entity?.is_nfse_observations_required ?? false " 
                        @update:initialValue="entity.is_nfse_observations_required = $event"
                      />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0 mb-3" v-if="entity.is_nfse_observations_required">
                <label class="col-md-4 pb-0 mb-1 col-form-label form-control-label label-item">
                  Observação
                  <span class="text-danger">&nbsp;*</span>
                </label>
                <div class="col-md-8">
                  <validation-provider :rules="entity.is_nfse_observations_required ? 'required' : ''" v-slot="{errors}">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="entity.default_text"
                        maxlength="255"
                        class="form-control"
                        rows="3" />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
            </div>

            <div class="card p-2 m-2 mt-3" style="margin-bottom: 15px !important;">
              <div class="mt-3">
                <span class="mr-3 label-title">
                  <img height="32px" src="/img/icons/icons8/ios/order-delivered_primary.png" class="mr-2"/>
                  Endereço
                </span>
              </div>
              <div class="mb-2">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      CEP<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input inputmode="numeric" type="text" v-model="entity.postal_code"
                              class="form-control form-control-sm" v-mask="['#####-###']" v-on:blur="getAddressByCode"
                              :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      UF<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="entity.state"
                          :items="states"
                          @change="getCities()"
                          customKey="letter"
                          label="letter"/>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 pr-0 col-form-label form-control-label label-item">
                      Cidade <span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          v-model="entity.city"
                          :items="cities"
                          customKey="title"
                          label="title"
                          :disabled="loadingCities"
                          :loading="loadingCities"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <div class="col-md-12">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      Endereço<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text" v-model="entity.address" class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0">
                  <div class="col-md-4">
                    <div class="pb-0 mb-1 col-form-label form-control-label label-item">
                      N°<span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input maxlength="5"
                              v-if="!without_number"
                              v-model="entity.number"
                              v-mask="'#####'"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                        />
                        <input v-else maxlength="5"
                              v-model="entity.number"
                              disabled
                              type="text"
                              inputmode="numeric"
                              class="form-control form-control-sm"
                        />
                        <template slot="append">
                          <small class="input-group-sm p-0 m-0" style="text-transform: none">
                            <a href="#" @click.prevent="without_number = !without_number">
                              <i v-if="without_number" class="fa-solid fa-square-check"></i>
                              <i v-else class="fa-regular fa-square"></i>
                            </a> Sem número
                          </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                  <div class="col-md-4">
                    <div class="pb-0 col-form-label form-control-label label-item">
                      Complemento
                    </div>
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="entity.complement"
                          maxlength="20"
                          class="form-control form-control-sm mt-1"
                        />
                      </base-input>
                  </div>

                  <div class="col-md-4">
                    <div class="pb-0 pr-0 col-form-label form-control-label label-item">
                      Bairro <span class="text-danger">&nbsp;*</span>
                    </div>
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="entity.district"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="danger button" @click="closeModal()">
                <AppIcon icon="cancel" color="danger" width="16" />
                Cancelar
              </button>
              <button
                class="success button"
                type="submit"
                v-bind:disabled="invalid"
                :loading="loadingStore"
              >
                <AppIcon icon="save" color="success" width="16" />
                Salvar
              </button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalEditEntityContact ref="modalEditEntityContact" @editedEntityContact="listEntityContactAfterEditing" />
    <ModalCreateEntityContact ref="modalCreateEntityContact" @storedEntityContact="listEntityContacts" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {viacep} from "@/helpers";
import PuzlSelect from "@/components/PuzlSelect";
import { entity as $_totvs}  from "@/plugins/microservices/totvs";
const {formatErrorValidation} = require("@/plugins");
import ModalEditEntityContact from "./_ModalEditEntityContact";
import ModalCreateEntityContact from "./_ModalCreateEntityContact";
import ToggleButton from '../../../../../components/ToggleButton';
import AppIcon from "../../../../../components/AppGlobal/AppIcon/AppIcon.vue";
import { date } from "../../../../../helpers";
import { AppButton } from "../../../../../components/AppGlobal";

export default {
  name: "ModalEditEntity",
  components: {
    PuzlSelect,
    ModalEditEntityContact,
    ModalCreateEntityContact,
    ToggleButton,
    AppIcon,
    AppButton,
  },
  data() {
    return {
      title: 'Editar cliente/fornecedor',
      modal: false,
      loadingCities: false,
      without_number: false,
      entity: {
        status: true,
        customer: false,
        is_legal: '',
        types: '',
        entity_name: '',
        document: '',
        email: '',
        phone: '',
        options: '',
        birthday: '',
        alias_name: '',
        registration_situation: '',
        tax_regime: '',
        municipal_registration: '',
        user_intermediary_id: false,
        state_registration: '',
        contributing_number: '',
        suframa_number: '',
        postal_code: '',
        state: '',
        address: '',
        number: '',
        complement: '',
        district: '',
        city: '',
        is_nfse_observations_required: false,
        default_text: '',
      },
      entityContacts: [],
      validated: false,
      showLegalEntity: false,
      showIndividualEntity: false,
      loadingStore: false,
      alertDocument: false,
      customerConstructionName: null,
      nameEntityWhenOpeningModal: '',
      stateRegistrationEnum: {
        contributor: { id: 1, name: "Contribuinte" },
        exempt_contributor: { id: 2, name: "Contribuinte isento" },
        non_contributor: { id: 9, name: "Não Contribuinte" },
      },
      loadingUsersIntermediaries: true,
      originalDocument: "",
    }
  },
  computed: {
    ...mapGetters({
      'states': 'localizations/states',
      'cities': 'localizations/cities',
      'option_types': 'entity/option_types',
      'option_situations': 'entity/option_situations',
      'option_tax_regimes': 'entity/option_tax_regimes',
      'option_state_registrations': 'entity/option_state_registrations',
      $_usersIntermediaries: 'user/getActiveUsersIntermediaries',
      $_commercial_permission: "commercialPermission/show",
    }),
  },
  watch: {
    'without_number': function (val) {
      if (val) {
        this.entity.number = 'SN'
      } else {
        this.entity.number = ''
      }
    },
  },
  methods: {
    closeModal() {
      this.modal = false
    },
    setOption(option) {
      let index = this.entity.options.findIndex(item => item === option)
      if (index != -1) {
        return this.entity.options.splice(index, 1)
      }
      this.entity.options.push(option)
    },
    async openModal(open, uuid) {
      this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'})
      let loader = this.$loading.show();
      this.without_number = false;

      this.getCommercialPermission();
      this.getAllUsersIntermediaries();
      
      await this.$store.dispatch('entity/show', uuid)
        .then((response) => {
          this.entity = {
            id: response.data.id || null,
            uuid: response.data.uuid || null,
            status: response.data.status || false,
            customer: response.data.customer || false,
            types: response.data.types || [],
            entity_name: response.data.entity_name || null,
            document: response.data.document || null,
            email: response.data.email || null,
            phone: response.data.phone || null,
            options: response.data.options || [],
            postal_code: response.data.address.postal_code || null,
            state: response.data.address.state || null,
            address: response.data.address.address || null,
            number: response.data.address.number || null,
            complement: response.data.address.complement || null,
            district: response.data.address.district || null,
            city: response.data.address.city || null,
            is_nfse_observations_required: response.data.is_nfse_observations_required || false,
            default_text: response.data.default_text || null,
          }
          if (this.entity.number === 'SN') {
            this.without_number = true
          }
          if (response.data.legal_entity.length) {
            this.showLegalEntity = true
            let [legal_entity] = response.data.legal_entity
            this.entity.alias_name = legal_entity.alias_name || null
            this.entity.registration_situation = legal_entity.registration_situation || null
            this.entity.tax_regime = legal_entity.tax_regime || null
            this.entity.municipal_registration = legal_entity.municipal_registration || null
            this.entity.user_intermediary_id = response.data.user_intermediary_id || null
            this.entity.state_registration = legal_entity.state_registration || null
            this.entity.contributing_number = legal_entity.contributing_number || null
            this.entity.suframa_number = legal_entity.suframa_number || null
          }
          if (response.data.individual_entity.length) {
            this.showIndividualEntity = true
            let [individual_entity] = response.data.individual_entity
            this.entity.birthday = individual_entity.birthday || null
            this.entity.state_registration = individual_entity.state_registration || null
            this.entity.contributing_number = individual_entity.contributing_number || null
            this.entity.municipal_registration = individual_entity.municipal_registration || null
          }
          this.entityContacts = response.data.entityContacts || [];
          this.nameEntityWhenOpeningModal = this.entity.entity_name;

          this.originalDocument = this.entity.document;

          this.changeIsLegal()
          this.$notify({type: response.error_type, message: response.message})
          loader.hide()
        }).catch((error) => {
        if (error.status === 422) {
          let message = formatErrorValidation(error.response.data.errors)
          this.$notify({type: 'danger', message: message})
        } else {
          this.$notify({type: error.data.error_type, message: error.data.message})
        }
        loader.hide();
        this.loadingSave = false
      });
      // Se existir um cliente/obra com o mesmo documento, seu nome deve atualizado conforme o nome do cliente/fornecedor.
      this.$store.dispatch("customerConstruction/getCustomerByDocument", {document: this.entity.document}).then(response => {
        if (response.data) {
          this.alertDocument = true;
          const customerConstructionId = response.data.id;
          this.$store.dispatch("customerConstruction/updateNameCustomerConstruction", {id: customerConstructionId, entityName: this.entity.entity_name});
        }
        this.modal = true;
      });
    },
    changeIsLegal() {
      if (this.entity.document.length >= 14) {
        if (this.entity.document.length >= 15) {
          this.entity.is_legal = 1;
          this.showIndividualEntity = false;
          this.showLegalEntity = true;
        } else {
          this.entity.is_legal = 0;
          this.showLegalEntity = false;
          this.showIndividualEntity = true;
        }
      }
    },
    getRecipeNet() {
      this.$Progress.start();
      this.$store.dispatch('recipe_net/fetch', {ein: this.entity.document})
        .then((response) => {
          this.entity.entity_name = response.data.nome || '';
          this.entity.alias_name = response.data.fantasia || response.data.nome;
          this.entity.email = response.data.email || '';
          this.entity.phone = response.data.telefone || '';
          this.entity.number = response.data.numero || '';
          this.entity.registration_situation = response.data.situacao || '';
          this.entity.postal_code = response.data.cep.replace(/[^\d]+/g, '') || '';
          this.$Progress.finish();
        })
    },
    getAddressByCode() {
      this.$Progress.start();
      let postal_code = this.entity.postal_code.replace(/[^\d]+/g, '')
      if (postal_code) {
        viacep(postal_code).then((response) => {
          this.getCities();
          this.entity.state = response.data.uf || null;
          this.entity.city = response.data.localidade || null;
          this.entity.address = response.data.logradouro || null;
          this.entity.district = response.data.bairro || null;
          if (! this.entity.state || ! this.entity.city || ! this.entity.address || ! this.entity.district) {
            this.$notify({
              type: 'warning',
              message: 'Dados incompletos para o CEP fornecido. Preencha manualmente.'
            });
          } else {
            this.$notify({type: 'success', message: 'Dados carregados com sucesso.'});
          }
          this.$Progress.finish();
        }).catch((error) => {
          this.$notify({type: 'danger', message: 'Não foi possível encontrar o endereço.'});
        })
      }
    },
    getCities() {
      this.entity.city = ''
      this.$Progress.start();
      this.$store.dispatch('localizations/fetchCities', {uf: this.entity.state})
        .then((response) => {
          this.$Progress.finish();
        })
        .catch((error) => {
        });
    },
    async store() {
      // Valida se o usuário digitou letras ou caracteres especiais.
      let regex = /[a-zA-Z]/;
      let specialRegex = /[!@#$%^&*(),.?":{}|<>]/g;
      if (this.entity.contributing_number && (regex.test(this.entity.contributing_number) || specialRegex.test(this.entity.contributing_number))) {
        this.$notify({type: 'warning', message: 'Número do contribuinte deve ter apenas números'});
        return;
      }
      if (this.entity.municipal_registration && (regex.test(this.entity.municipal_registration) || specialRegex.test(this.entity.municipal_registration))) {
        this.$notify({type: 'warning', message: 'Insc. municipal deve ter apenas números'});
        return;
      }

      if (this.entity.entity_name.trim().split(" ").length < 2) {
        this.$notify({type: 'warning', message: 'Preencha nome e sobrenome'});
        return;
      }

      const isPostalCodeFormatValid = this.entity.postal_code.length === 9 && this.entity.postal_code !== '00000-000';
      if (!isPostalCodeFormatValid) {
        this.$notify({type: 'warning', message: 'O CEP informado não é válido.'});
        return;
      }

      // Verificações caso o usuário tente alterar cidade, endereço ou bairro e fique em desacordo com o cep cadastrado.
      let postal_code = this.entity.postal_code.replace(/[^\d]+/g, '');
      if (postal_code) {
        var erro = false;
        var state = '';
        var city = '';
        var address = '';
        var district = '';
        await viacep(postal_code).then((response) => {
          erro = response.data.erro;
          state = response.data.uf;
          city = response.data.localidade;
          address = response.data.logradouro;
          district = response.data.bairro;
        });
        /*
          A verificação será válida apenas se o endereço e bairro encontrados na busca,
          tiverem menos de 50 caracteres, caso tenha mais, o usuário vai precisar abreviar
          esses campos para conseguir emitir a nota fiscal.
        */
        if (
          ! erro &&
          state &&
          city &&
          address && address.length < 50 &&
          district && district.length < 50
        ) {
          if (this.entity.city != city) {
            this.$notify({type: 'warning', message: 'Cidade fornecida diverge do CEP informado.'})
            return;
          }
        }
      }

      //Verificação caso o usuário modifique o cep e em seguida clique no botão salvar.
      if (! this.entity.state && ! this.entity.city && ! this.entity.address && ! this.entity.district) {
        return;
      }

      // Verifica se o documento foi modificado (campo sempre será desabilitado, mas usuário pode conseguir habilitar).
      if (this.entity.document !== this.originalDocument) {
        this.$notify({type: 'warning', message: 'O documento não pode ser alterado.'})
        return;
      }

      this.$Progress.start();
      this.loadingStore = true;

      if (this.entityContacts.length) {
        this.entity.entityContacts = this.entityContacts;
      }

      this.$store.dispatch('entity/update', this.entity)
        .then(response => {
          this.loadingStore = false;
          this.modal = false;
          $_totvs.update(response.data);
          this.entity = {
            status: true,
            customer: false,
            is_legal: '',
            types: '',
            entity_name: '',
            document: '',
            email: '',
            phone: '',
            birthday: '',
            alias_name: '',
            registration_situation: '',
            tax_regime: '',
            municipal_registration: '',
            user_intermediary_id: false,
            state_registration: '',
            contributing_number: '',
            suframa_number: '',
            postal_code: '',
            state: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            is_nfse_observations_required: false,
            default_text: '',
          };
          //Se o nome do cliente/fornecedor foi alterado
          if (this.nameEntityWhenOpeningModal != response.data.entity_name) {
            // Se existir um cliente/obra com o mesmo documento, seu nome deve atualizado conforme o nome do cliente/fornecedor.
            const entityDocument = response.data.document;
            const entityName = response.data.entity_name;
            this.$store.dispatch("customerConstruction/getCustomerByDocument", {
              document: entityDocument
            }).then(response => {
              if (response.data) {
                this.alertDocument = true;
                const customerConstructionId = response.data.id;
                this.$store.dispatch("customerConstruction/updateNameCustomerConstruction", {
                  id: customerConstructionId, 
                  entityName: entityName,
                }).then(() => {
                  this.$emit('set_entity_name', entityName);
                });
              }
            });
          }
          this.$Progress.finish()
          this.$notify({type: response.error_type, message: response.message})
          this.$emit('updatedEntity', response.data)
        })
        .catch(error => {
          if (error.status === 422) {
            let message = formatErrorValidation(error.response.data.errors)
            this.$notify({type: 'danger', message: message})
          } else {
            this.$notify({type: error.data.error_type, message: error.data.message})
          }
          this.$Progress.finish()
          this.loadingStore = false
        })
    },
    removeSpecialCharactersContributingNumber(inputEvent) {
      let input = inputEvent.target.value;
      let regex = /^[0-9]*$/;
      if (!regex.test(input)) {
        this.entity.contributing_number = input.slice(0, -1);
      }
    },
    removeSpecialCharactersMunicipalRegistration(inputEvent) {
      let input = inputEvent.target.value;
      let regex = /^[0-9]*$/;
      if (!regex.test(input)) {
        this.entity.municipal_registration = input.slice(0, -1);
      }
    },
    /**
     * Abre modal de edição de contatos do cliente/fornecedor.
     * @param {number} entityContactId
    */
    openModalEditEntityContact(entityContactId) {
      this.$refs.modalEditEntityContact.openModal(entityContactId);
    },
    /**
     * Abre modal de cadastro de contatos do cliente/fornecedor
     */
    openModalCreateEntityContact() {
      this.$refs.modalCreateEntityContact.openModal(this.entityContacts);
    },
    /**
     * Lista contatos após o cadastro
    * @param {array} entityContacts
    */
    listEntityContacts(entityContacts) {
      this.entityContacts = entityContacts;
    },
    /**
     * Atuliza item na lista de contatos após edição na modal. 
     * @param {object} entityContact
    */
    listEntityContactAfterEditing(entityContact) {
      let indexEntityContact = this.entityContacts.findIndex((item) => item.id === entityContact.id);
      this.$set(this.entityContacts, indexEntityContact, entityContact);
    },
    /**
    * @param {object} entityContact
    */
    deleteEntityContact(entityContact) {
      this.$Swal.confirmDelete().then((result) => {
        if (result.isConfirmed) {
          this.$Progress.start();
          this.$notify({
            type: 'info',
            message: 'Estamos trabalhando em sua solicitação.'
          });
          if (entityContact.id) {
            // Se o contato tem um ID, exclua usando o ID
            this.$store.dispatch('entityContact/destroy', entityContact.id)
              .then((response) => {
                this.entityContacts.splice(this.entityContacts.indexOf(entityContact), 1);
                this.$notify({
                  type: 'success',
                  message: response.message
                });
              })
              .catch(error => {
                this.$notify({
                  type: error.data.error_type,
                  message: error.data.message
                });
              });
          } else {
            // Se não tiver ID, encontre o índice do objeto no array e exclua
            const index = this.entityContacts.findIndex(contact => contact === entityContact);
            if (index !== -1) {
              this.entityContacts.splice(index, 1);
              this.$notify({
                type: 'success',
                message: 'Contato excluído com sucesso'
              });
            } else {
              this.$notify({
                type: 'error',
                message: 'Contato não encontrado para exclusão'
              });
            }
          }
        }
      }).finally(() => this.$Progress.finish());
    },
    getCommercialPermission() {
      this.$store.dispatch('commercialPermission/getByUserId', this.$user.id);
    },
    getAllUsersIntermediaries() {
      this.loadingUsersIntermediaries = true;
      this.$store.dispatch("user/userGetAllIntermediary").then(() => {
        this.loadingUsersIntermediaries = false;
      });
    },
    getContactTitle(item) {
      const updatedAt = date.make(item.updated_at);
      const createdAt = date.make(item.created_at).toDate();
      const wasUpdated = updatedAt.isAfter(createdAt);
  
      return `Criado por: ${item.created_by_user_name || 'Não informado'}
        Atualizado por: ${wasUpdated ? (item.updated_by_user_name || 'Não informado') : 'Não informado'}
        Data de criação: ${date.make(item.created_at).format('DD/MM/YYYY HH:mm')}
        Data de atualização: ${wasUpdated ? date.make(item.updated_at).format('DD/MM/YYYY HH:mm') : 'Não informado'}`;
    },
  },
  mounted() {
    this.$refs.formValidator.validate()
    this.$store.dispatch('localizations/fetchStates')
  },
}
</script>

<style scoped>
.label-title {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.label-item {
  color: #2B2D32;
  font-family: Fredoka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>