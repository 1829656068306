import store from "../../../../../../shared/libraries/store";
import { initPurchaseOrderListPagerType, PurchaseOrderListFilterType, PurchaseOrderListPagerType, PurchaseOrderType } from "../types";
import { createSource, findSource, listSources, removeSource, updateSource } from "../../../../../../shared/store";
import httpClient from "../../../../../../shared/libraries/httpClient";
import { HttpStatusEnum } from "../../../../../../shared/enums";
import { handleUnexpectedStatusCode } from "../../../../../../shared/services";

/** URLS */
const urls = Object.freeze({
  BASE: "/purchase/purchase-orders",
});

/** KEYS */
const keys = Object.freeze({
  ITEM: "PURCHASE_ORDER_ITEM",
  LISTED: "PURCHASE_ORDER_LISTED",
  IS_LISTING: "PURCHASE_ORDER_IS_LISTING",
  IS_FINDING: "PURCHASE_ORDER_IS_FINDING",
  IS_SAVING: "PURCHASE_ORDER_IS_SAVING",
  IS_REMOVING: "PURCHASE_ORDER_IS_REMOVING",
  PAGE_STATES: "PURCHASE_ORDER_PAGE_STATE",
});

/** GETTERS */
const getters = {
  /** @returns {?PurchaseOrderType} */
  getItem: () => store.getState(keys.ITEM),

  /** @returns {PurchaseOrderListPagerType} */
  getListed: () => store.getState(keys.LISTED) ?? initPurchaseOrderListPagerType(),

  /** @returns {boolean} */
  getIsListing: () => store.getState(keys.IS_LISTING) ?? false,

  /** @returns {boolean} */
  getIsFinding: () => store.getState(keys.IS_FINDING) ?? false,

  /** @returns {boolean} */
  getIsSaving: () => store.getState(keys.IS_SAVING) ?? false,

  /** @returns {boolean} */
  getIsRemoving: () => store.getState(keys.IS_REMOVING) ?? false,

  /** @returns {{ searchBarFilter: SearchBarFilterType, filter: PurchaseOrderListFilterType }} */
  getPageState: () => store.getState(keys.PAGE_STATES),
};

/** MUTATIONS */
const mutations = {
  /**
   * @param {PurchaseOrderType} item
   * @returns {PurchaseOrderType}
   */
  setItem: (item) => store.commit(keys.ITEM, item),

  /**
   * @param {PurchaseOrderListPagerType} listed
   * @returns {PurchaseOrderListPagerType}
   */
  setListed: (listed) => store.commit(keys.LISTED, listed),

  /**
   * @param {boolean} isListing
   * @returns {boolean}
   */
  setIsListing: (isListing) => store.commit(keys.IS_LISTING, isListing),

  /**
   * @param {boolean} isFinding
   * @returns {boolean}
   */
  setIsFinding: (isFinding) => store.commit(keys.IS_FINDING, isFinding),

  /**
   * @param {boolean} isSaving
   * @returns {boolean}
   */
  setIsSaving: (isSaving) => store.commit(keys.IS_SAVING, isSaving),

  /**
   * @param {boolean} isRemoving
   * @returns {boolean}
   */
  setIsRemoving: (isRemoving) => store.commit(keys.IS_REMOVING, isRemoving),

  /**
   * @param {PurchaseOrderType} created
   * @returns {PurchaseOrderType}
   */
  setNewItemInList: (created) => store.commitNewItemToList(keys, created),

  /**
   * @param {PurchaseOrderListPagerType} listed
   * @returns {PurchaseOrderListPagerType}
   */
  setListMerge: (listed) => store.commitListMerge(keys, listed),

  /**
   * @param {number} id
   */
  setRemovedItem: (id) => store.commitRemovedItem(keys, id),

  /**
   * @param {PurchaseOrderType} updated
   * @returns {PurchaseOrderType}
   */
  setUpdatedItem: (updated) => store.commitUpdatedItem(keys, updated),

  /** @param {{ searchBarFilter: SearchBarFilterType, filter: PurchaseOrderListFilterType }} filters */
  setPageState: (filters) => store.commit(keys.PAGE_STATES, filters),

  /** Resetar chaves de estado */
  resetStates: () => store.resetStates(Object.values(keys)),
};

/** ACTIONS */
const actions = {
  /**
   * Criar uma nova ordem de compra
   *
   * @param {PurchaseOrderType} data
   * @returns {Promise<PurchaseOrderType>}
   */
  create: async (data) => await createSource(data, mutations, urls.BASE),

  /**
   * Buscar uma ordem de compra por ID
   *
   * @param {number} id
   * @returns {Promise<?PurchaseOrderType>}
   */
  find: async (id) => await findSource(id, mutations, urls.BASE),

  /**
   * Listar ordens de compra
   *
   * @param {PurchaseOrderListFilterType} filter - Filtro de listagem
   * @param {boolean} isAccumulateItems - Incluir mais itens na lista existente
   * @returns {Promise<?PurchaseOrderListPagerType>} - Retorno paginado
   */
  list: async (filter, isAccumulateItems = false) => await listSources(filter, isAccumulateItems, mutations, getters, urls.BASE),

  /**
   * Remover uma ordem de compra por ID
   *
   * @param {number} id
   * @returns {Promise<void>}
   */
  remove: async (id) => await removeSource(id, mutations, urls.BASE),

  /**
   * Atualizar uma ordem de compra por ID
   *
   * @param {PurchaseOrderType} data
   * @param {number} id
   * @returns {Promise<PurchaseOrderType>}
   */
  update: async (data, id) => await updateSource(data, id, mutations, urls.BASE),

  add: async (data) => {
    try {
      mutations.setIsSaving(true);
  
      const res = await httpClient.post(urls.BASE, data);
      if (res.status !== HttpStatusEnum.HTTP_CREATED) {
        handleUnexpectedStatusCode(res);
        return;
      }
    } finally {
      mutations.setIsSaving(false);
    }
  },
  

  /**
   * Aprovar uma ordem de compra
   *
   * @param {number} id
   * @param {object} params
   * @returns {Promise<PurchaseOrderType>}
   */
  approval: async (id, params) => {
    try {
      mutations.setIsSaving(true);

      const url = `${urls.BASE}/purchase-order-approval/${id}`;
      const res = await httpClient.put(url, params);
      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      const updated = res.data.data;
      return mutations.setUpdatedItem(updated);
    } finally {
      mutations.setIsSaving(false);
    }
  },

  /**
   * Gerar relatório PDF de uma ordem de compra
   *
   * @param {number} orderId
   * @returns {Promise<Blob>}
   */
  getReportPdf: async (orderId) => {
    try {
      const isAsync = 0;
      const url = `${urls.BASE}/report/${isAsync}/pdf`;
      const res = await httpClient.post(url, { id: orderId });

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Buscar histórico de uma ordem de compra
   *
   * @param {number} id
   * @returns {Promise<object>}
   */
  getHistory: async (id) => {
    try {
      const url = `${urls.BASE}/purchase_order_history/${id}`;
      const res = await httpClient.get(url);

      if (res.status !== HttpStatusEnum.HTTP_OK) {
        handleUnexpectedStatusCode(res);
        return;
      }

      return res.data;
    } catch (error) {
      console.error("Erro ao buscar histórico:", error);
      throw error;
    }
  },
};

export default {
  actions,
  getters,
  mutations,
};