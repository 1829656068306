<template>
  <div>
    <modal size="md" :show.sync="modal.create">
      <template slot="header">
        <h5 class="modal-title">{{ modal.title }}</h5>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(store)" autocomplete="off">
            <div>
              <div class="border-left border-3 border-primary pr-1 mt-0 mb-2" v-if="contract_proposal">
                <div class="col-md-12">
                  <span class="display-5 mb-0">
                    <b>
                    {{
                        (contract_proposal && contract_proposal.construction && contract_proposal.construction.construction_name) || ""
                      }}
                    </b>
                  </span>
                  <br>
                  <span class="display-5 mb-0">
                    <b>
                      {{
                        (contract_proposal &&
                          contract_proposal.construction &&
                          contract_proposal.construction.customer_construction &&
                          contract_proposal.construction.customer_construction.customer_name) ||
                        ""
                      }}                    </b>
                  </span>
                  <br>
                  <span class="display-5 mb-0">
                    <b>
                    {{
                        (contract_proposal && contract_proposal.service_receiver && contract_proposal.service_receiver.document) || ""
                      }}                </b>
                  </span>
                </div>
              </div>
              <div class="border-left border-3 border-success pr-1 mt-2 mb-3"
                   v-if="contract_proposal && contract_proposal.balance && contract_proposal.balance.total_accomplish > 0">
                <div class="col-12">
                  <span class="display-5 mb-0">
                    Programado : <b>{{ contract_proposal.balance.total_accomplish | currency }} </b>
                    <i
                      @click.prevent="getValueFromSchedule()"
                      style="font-size: 20px !important"
                      role="button"
                      class="fas fa-download p-2 text-success"
                    />
                  </span>
                </div>
              </div>
              <div class="row mb-2 m-0 p-0">
                <div class="col-md-3 mb-1 p-1">
                  <base-button size="sm"
                               :class="type === 1 && 'active'"
                               @click.prevent="type = 1" block
                               outline
                               type="primary">Pagamento
                  </base-button>
                </div>
                <div class="col-md-3 mb-1 p-1">
                  <base-button size="sm"
                               :disabled="!$hasPermission('additional_permissions.financial_approvation') || payload.payment.company_plant_issuer_id === null"
                               :class="type === 2 && 'active'"
                               @click.prevent="type = 2" block outline
                               type="primary">Faturas  <i v-if="invoices.length > 0" class="fa-solid fa-circle-exclamation text-warning"></i>
                  </base-button>
                </div>
                <div class="col-md-3 p-1">
                  <base-button size="sm"
                               :class="type === 3 && 'active'"
                               :disabled="payload.installments.length < 1"
                               @click.prevent="type = 3" block outline
                               type="primary">Parcelas
                  </base-button>
                </div>
                <div class="col-md-3 p-1">
                  <base-button size="sm"
                               :class="type === 4 && 'active'"
                               @click.prevent="type = 4" block outline
                               type="primary">Anexos
                  </base-button>
                </div>
              </div>
              <section v-show="type == 1">
                <div class="form-group row m-0 p-0 mb-1 limited-row">
                  <label class="col-md-6 pb-1 pt-2  col-form-label form-control-label">
                    Competência
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <input-date-time-picker
                          @input="generateInstallments"
                          :mode="'date'"
                          v-model="payload.payment.competence"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                    Valor total (a receber)
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        @blur="generateInstallments(1)"
                        v-money="money"
                        maxlength="13"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        inputmode="numeric"
                        v-model.lazy="payload.payment.total_value"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
                <!--                <div class="form-group row m-0 p-0 mb-2 limited-row">-->
                <!--                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
                <!--                    Retenção ISS-->
                <!--                  </label>-->
                <!--                  <div class="col-md-6">-->
                <!--                    <base-input input-group-classes="input-group-sm">-->
                <!--                      <input-->
                <!--                        @blur="generateInstallments(1)"-->
                <!--                        v-money="money"-->
                <!--                        maxlength="10"-->
                <!--                        @keydown="$event.key === '-' ? $event.preventDefault() : null"-->
                <!--                        inputmode="numeric"-->
                <!--                        v-model.lazy="payload.payment.iss_retain"-->
                <!--                        type="text"-->
                <!--                        class="form-control form-control-sm"-->
                <!--                      />-->
                <!--                      <template slot="prepend">-->
                <!--                        <small class="input-group-sm p-0 m-0">-->
                <!--                          R$-->
                <!--                        </small>-->
                <!--                      </template>-->
                <!--                    </base-input>-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="form-group row m-0 p-0 mb-1">
                  <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                    Valor Final
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-money="money"
                        maxlength="13"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        inputmode="numeric"
                        v-model.lazy="payload.payment.final_value"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>


                <div class="card-header mt-n4 mb-3"></div>
                <div class="form-group row m-0 p-0 mb-1 limited-row">
                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                    Método de pagamento
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="getPaymentTerms(true)"
                          @input="setRequireNsuCode()"
                          v-model="payload.payment.payment_method_uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :loading="loadingPlant"
                          :disabled="
                          loadingPlant || payload.payment.total_value === '0,00'
                        ">
                          <el-option
                            v-for="method in payment_methods_selected"
                            :key="method.uuid"
                            :label="method.name"
                            :value="method.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0  limited-row">
                  <label
                    class="col-md-6 pt-1 pb-1 col-form-label form-control-label"
                  >
                    Condição de pagamento
                    <span class="text-danger" v-show="hasInstallmentPaid">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          @change="generateInstallments"
                          v-model="payload.payment.payment_term_uuid"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="
                          loadingPlant || payload.payment.total_value === '0,00' || !payload.payment.payment_method_uuid"
                        >
                          <el-option
                            v-for="term in payment_terms_selected"
                            :key="term.uuid"
                            :label="term.name"
                            :value="term.uuid"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
<!--                <div class="form-group row m-0 p-0 mt-1 mb-2 limited-row">-->
<!--                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
<!--                    Mediador de pagamento-->
<!--                    <span class="text-danger" v-show="payment_intermediaries_exists">&nbsp;*&nbsp;</span>-->
<!--                  </label>-->
<!--                  <div class="col-md-6">-->
<!--                    <validation-provider-->
<!--                      :rules="(payment_intermediaries_exists) && 'required' || ''">-->
<!--                      <base-input input-classes="form-control-sm">-->
<!--                        <PuzlSelect-->
<!--                          placeholder="Nenhum"-->
<!--                          @input="generateInstallments()"-->
<!--                          @change="getPaymentIntermediaryMerchants()"-->
<!--                          :items="payment_intemerdiaries_selected"-->
<!--                          :disabled="loadingPaymentIntermediaries || !payload.payment.payment_method_uuid"-->
<!--                          v-model="payload.payment.payment_intermediary_id"-->
<!--                        />-->
<!--                      </base-input>-->
<!--                    </validation-provider>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div-->
<!--                  v-if="isDisplayPaymentIntermediaryMerchants(payload.payment.payment_intermediary_id)"-->
<!--                  class="form-group row m-0 p-0 mt-1 mb-2 limited-row"-->
<!--                >-->
<!--                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">-->
<!--                    Ponto de Venda (PV)-->
<!--                  </label>-->
<!--                  <div class="col-md-6">-->
<!--                    <base-input input-classes="form-control-sm">-->
<!--                      <PuzlSelect-->
<!--                        placeholder="Nenhum"-->
<!--                        :items="payment_intermediary_merchants"-->
<!--                        label="description"-->
<!--                        v-model="payload.payment.payment_intermediary_merchant_id"-->
<!--                      />-->
<!--                    </base-input>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="form-group row m-0 p-0 mb-2 limited-row">-->
<!--                  <label-->
<!--                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"-->
<!--                  >-->
<!--                    NSU da transação-->
<!--                    <span class="text-danger" v-show="require_nsu_code">&nbsp;*&nbsp;</span>-->
<!--                    <span v-if="-->
<!--                      modalCardIntegrations &&-->
<!--                      payload.payment.payment_intermediary_id &&-->
<!--                      payload.payment.payment_intermediary_merchant_id &&-->
<!--                      payload.payment.payment_method_uuid === '947bf91c-abd0-43d4-a0b7-97a50a481cdd' ||-->
<!--                      payload.payment.payment_method_uuid === '947bf924-efbd-4263-809a-b2105f6674e2'"-->
<!--                    >-->
<!--                      &lt;!&ndash; <a href="javascript:void(0)"-->
<!--                        @click.prevent="handleCreateModalCardIntegration"-->
<!--                        class="fa-regular fa-credit-card text-success fa-xl">-->
<!--                      </a> &ndash;&gt;-->
<!--                    </span>-->
<!--                  </label>-->
<!--                  <div class="col-md-6">-->
<!--                    <validation-provider :rules="require_nsu_code && 'required' || ''">-->
<!--                      <base-input input-classes="form-control-sm">-->
<!--                        <input-->
<!--                          inputmode="numeric"-->
<!--                          v-mask="'####################'"-->
<!--                          v-model="payload.payment.nsu_code"-->
<!--                          class="form-control form-control-sm"/>-->
<!--                      </base-input>-->
<!--                    </validation-provider>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="card-header mt-n4 mb-3"></div>

                <div class="form-group row m-0 p-0 mb-2">
                  <label
                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                  >
                    Central
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          @change="changeBankAccount()"
                          :clearable="false"
                          v-model="payload.payment.company_plant_id"
                          :items="$_company_plants"
                          @input="setInvoice(true)"
                          :loading="loadingCompanyPlants"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                  >
                    CNPJ Beneficiário
                    <span class="text-danger">&nbsp;*</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-group-classes="input-group-sm">
                        <puzl-select
                          :clearable="false"
                          v-model="payload.payment.company_plant_issuer_id"
                          :items="company_plant_issuers"
                          label="alias_name"
                          @input="setInvoice();changeBankAccount()"
                          :loading="loadingCompanyPlantIssuers"
                          :disabled="payload.payment.company_plant_id === null"/>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label
                    class="col-md-6 pt-2 pb-1 col-form-label form-control-label"
                  >
                    Conta bancária de crédito
                    <span class="text-danger">&nbsp;*&nbsp;</span>
                  </label>
                  <div class="col-md-6">
                    <validation-provider rules="required">
                      <base-input input-classes="form-control-sm">
                        <el-select
                          v-model="payload.payment.bank_account_id"
                          size="mini"
                          placeholder="Selecione"
                          filterable
                          :disabled="loadingBankAccounts"
                        >
                          <el-option
                            v-for="item in bank_accounts"
                            :key="item.bank_account.id"
                            :label="formatAccountLabel(item.bank_account)"
                            :value="item.bank_account.id"
                          >
                          </el-option>
                        </el-select>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>

                <div class="form-group row m-0 p-0 ">
                  <label class="col-md-6 pt-2 pb-1 col-form-label form-control-label">
                    Observações
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <textarea
                        v-model="payload.payment.observations"
                        rows="3"
                        type="text"
                        class="form-control form-control-sm"
                      />
                    </base-input>
                  </div>
                </div>
              </section>
              <section v-show="type == 2">
                <div class="form-group row m-0 p-0 mb-1">
                  <label
                    class="col-md-12 pt-2 pb-1 col-form-label form-control-label"
                  >
                    Faturas
                  </label>
                  <div class="col-md-12">
                    <base-input input-classes="form-control-sm">
                      <el-select
                        @change="calcInvoicedAmount()"
                        v-model="payload.invoices"
                        size="mini"
                        placeholder="Selecione"
                        filterable
                        multiple
                      >
                        <el-option
                          v-for="invoice in invoices"
                          :key="invoice.id"
                          :label="`Fatura ${invoice.id} (${invoice.pending_to_paid.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} /
                          ${invoice.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})})`"
                          :value="invoice.id"
                        >
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
                <div class="form-group row m-0 p-0 mb-2 limited-row">
                  <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label">
                    Valor faturado
                  </label>
                  <div class="col-md-6">
                    <base-input input-group-classes="input-group-sm">
                      <input
                        disabled
                        v-money="money"
                        maxlength="13"
                        inputmode="numeric"
                        v-model="invoiced_amount"
                        type="text"
                        class="form-control form-control-sm"
                      />
                      <template slot="prepend">
                        <small class="input-group-sm p-0 m-0">
                          R$
                        </small>
                      </template>
                    </base-input>
                  </div>
                </div>
<!--                <div class="form-group row m-0 p-0 mb-1 limited-row">-->
<!--                  <label class="col-md-6 pt-1 pb-0 col-form-label form-control-label">-->
<!--                    Saldo-->
<!--                  </label>-->
<!--                  <div class="col-md-6">-->
<!--                    <base-input input-group-classes="input-group-sm">-->
<!--                      <input-->
<!--                        disabled-->
<!--                        v-money="money"-->
<!--                        maxlength="13"-->
<!--                        inputmode="numeric"-->
<!--                        v-model.lazy="balance"-->
<!--                        type="text"-->
<!--                        class="form-control form-control-sm"-->
<!--                      />-->
<!--                      <template slot="prepend">-->
<!--                        <small class="input-group-sm p-0 m-0">-->
<!--                          R$-->
<!--                        </small>-->
<!--                      </template>-->
<!--                    </base-input>-->
<!--                  </div>-->
<!--                </div>-->
              </section>
              <section v-show="type == 3">
                <!-- Start Payments -->
                <div v-if="payload.installments.length">
                  <div class="border-header-danger-bottom mb-2 mt-3">
                    <h5 class="h3 p-1 mb-0">Pagamentos</h5>
                  </div>
                  <!-- Start Orders-->
                  <div v-for="(installment, indexInstallment) in payload.installments" :key="installment.index">
                    <div class="form-group row m-0  mb-1 limited-row" :class="installment.index > 1 ? 'mt-4' : ''">
                      <label class="col-md-6 pt-2 pb-0 pt-1 col-form-label form-control-label font-weight-bold">
                        <h5 class="h4  mb-1">
                          Parcela {{ installment.index }}
                          <span class="text-danger">&nbsp;*</span>
                        </h5>
                      </label>
                      <div class="col-md-6">
<!--                        <base-dropdown class="col-md-12 p-0 m-0">-->
                          <base-button
                            :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                            block
                            size="sm"
                            :type="getColor(installment.status)"
                            class="text-uppercase"
                          >
                            {{ getTitle(installment.status) }}
                          </base-button>
<!--                          <div>-->
<!--                            <a @click.prevent="installment.status = 1" v-if="installment.status !== 1"-->
<!--                               class="dropdown-item">Pago</a>-->
<!--                            <div v-if="installment.status !== 1" class="dropdown-divider p-0 m-0"></div>-->
<!--                            <a @click.prevent="installment.status = 3" v-if="installment.status !== 3"-->
<!--                               class="dropdown-item">Cancelado</a>-->
<!--                            <div v-if="installment.status !== 3" class="dropdown-divider p-0 m-0"></div>-->
<!--                            <a @click.prevent="installment.status = 0" v-if="installment.status !== 0"-->
<!--                               class="dropdown-item">Pendente</a>-->
<!--                          </div>-->
<!--                        </base-dropdown>-->
                      </div>
                    </div>
                    <div class="form-group row m-0  mb-1 limited-row">
                      <label class="col-md-6 pt-2  pb-0  col-form-label form-control-label">
                        Vencimento
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <validation-provider rules="required">
                          <base-input input-group-classes="input-group-sm">
                            <input-date-time-picker
                              :mode="'date'"
                              v-model="installment.due_date"
                            />
                          </base-input>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label class="col-md-6  pt-2 pb-0 col-form-label form-control-label">
                        Valor
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @blur="calcTotalValue(installment, indexInstallment)"
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model.lazy="installment.value"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                      >
                        Descontos
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            v-money="money"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.discounts"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6 pt-2 pb-0 col-form-label form-control-label"
                      >
                        Taxas
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            v-money="money"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.discounts_fees"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label
                        class="col-md-6  pt-2 pb-0 col-form-label form-control-label"
                      >
                        Juros/Multa
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            @keydown="$event.key === '-' ? $event.preventDefault() : null"
                            ref="feesInput"
                            @blur="
                              calcTotalValue(installment, indexInstallment)
                            "
                            v-money="money"
                            :disabled="!$hasPermission('additional_permissions.financial_approvation')"
                            maxlength="13"
                            inputmode="numeric"
                            v-model="installment.fees_fines"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <div class="form-group row m-0 p-0 mb-1 limited-row">
                      <label class="col-md-6 pt-2 pb-0 col-form-label form-control-label">
                        Valor final
                        <span class="text-danger">&nbsp;*</span>
                      </label>
                      <div class="col-md-6">
                        <base-input input-group-classes="input-group-sm">
                          <input
                            disabled
                            v-money="money"
                            maxlength="13"
                            inputmode="numeric"
                            v-model.lazy="installment.total_value"
                            type="text"
                            class="form-control form-control-sm"
                          />
                          <template slot="prepend">
                            <small class="input-group-sm p-0 m-0">
                              R$
                            </small>
                          </template>
                        </base-input>
                      </div>
                    </div>
                    <!--                    ANEXO-->
<!--                    <div class="form-group row m-0 p-0 mb-4">-->
<!--                      <div class="col-md-12 mt-2">-->
<!--                        <InputFile :ref="`input-file-${indexInstallment}`"/>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                  <!-- End Orders-->
                </div>
                <!-- End Payments -->
              </section>
<!--              ANEXOS-->
              <section v-show="type === 4">
                <div class="form-group row m-0 p-0 mb-4">
                  <div class="col-md-12 mt-2">
                    <label class="form-control-label">Anexos</label>
                    <InputFile :maxFiles="10" ref="files"/>
                  </div>
                </div>
              </section>
            </div>
            <div class="modal-footer">
              <base-button type="secondary" @click="closeModal('create')">
                Cancelar
              </base-button>
              <base-button v-if="type === 4" type="success" native-type="submit" :disabled="invalid || payload.invoices.length === 0 || payload.installments.length === 0" :loading="loadingStore">
                Salvar
              </base-button>
              <base-button
                v-else
                @click.prevent="type += 1"
                type="success"
                native-type="submit"
                v-bind:disabled="invalid"
                class="btn-custom">
                <span class="btn-label span-custom" ><i class="fa-solid fa-circle-right"></i></span>
                Próximo
              </base-button>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
    <ModalCardIntegration ref="ModalCardIntegration" @selectedTransactionProps="setTransactionProps"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {VMoney} from "v-money";
import {checkNumberValue} from "@/helpers";
import {mask1, formatErrorValidation} from "@/plugins";
import moment from "moment";
import InputFile from "@/components/Utils/InputFile";
import PuzlSelect from "@/components/PuzlSelect";
import InputDateTimePicker from "@/components/InputDateTimePickerV2";
import ModalCardIntegration from "@/views/Modules/Financial/BillReceive/Shared/Modals/_ModalCardIntegration";
import {GetBankAccountEnum} from "@/enum/GetBankAccountEnum";

export default {
  name: "ModalCreatePayment",
  components: {
    InputFile,
    PuzlSelect,
    InputDateTimePicker,
    ModalCardIntegration,
  },
  data() {
    return {
      modal: {
        title: "Recebimentos",
        create: false,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      invoiced_amount: 0,
      balance: 0,
      type: 1,
      payment_intemerdiaries_selected: [],
      loadingCompanyPlantIssuers: false,
      loadingPaymentIntermediaries: false,
      loadingPaymentIntermediaryMerchants: false,
      loadingCompanyPlants: false,
      loadingBankAccounts: false,
      hasInstallmentPaid: false,
      Payment_methods: [],
      require_nsu_code: false,
      payment_terms_by_method: [],
      payment_methods_selected: [],
      payment_terms_selected: [],
      bank_accounts: [],
      payment_intermediary_merchants: [],
      payment_intermediaries_exists: false,
      payload: {
        payment: {
          competence: null,
          iss_retain: null,
          company_plant_id: null,
          company_plant_issuer_id: null,
          name: null,
          total_value: null,
          observations: null,
          company_plant_uuid: null,
          final_value: null,
          payment_term_uuid: null,
          payment_method_uuid: null,
          contract_proposal_uuid: this.$route.params.contract_proposal_uuid,
          bank_account_id: null,
          payment_intermediary_id: null,
          payment_intermediary_merchant_id: null,
          transaction_code: null,
          nsu_code: null,
        },
        invoices: [],
        installments: []
      },
      loadingStore: false,
      loadingPlant: false,
      loadingMethod: true,
      loading: true,
      file: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: false
      },
      modalCardIntegrations: false,
    };
  },
  directives: {
    money: VMoney
  },
  computed: {
    ...mapGetters({
      plants: "plant/activeItems",
      payment_methods: "paymentMethod/fetch",
      contract_proposal: "contractProposal/show",
      payment_terms: "paymentTerm/fetch",
      invoices: "billingInvoice/invoices",
      $_company_plants: "plant/activeItems",
      company_plant_issuers: "companyPlantIssuer/fetch",
      $_payment_intermediaries: "paymentIntermediary/fetch",
      $_general_settings: "generalSetting/show",
    })
  },
  mounted() {
    this.$watch('payload.installments', function () {
      const self = this
      this.hasInstallmentPaid = false
      self.payload.installments.forEach(function (item) {
        if (item.status === 1) {
          self.hasInstallmentPaid = true
        }
      })
    }, {
      immediate: true,
      deep: true
    })
  },
  methods: {
    /**
     * Exibir campos de ponto de venda de acordo com ID do Mediador de Pagamento
     *
     * @param {integer} paymentIntermediaryId Id de mediador de pagamento
     * @returns {boolean} Exibir/Não exibir pontos de venda
     */
    isDisplayPaymentIntermediaryMerchants(paymentIntermediaryId){
      const paymentIntermediaryIdFound = this.payment_intemerdiaries_selected.find((item) => item.id === paymentIntermediaryId);
      return paymentIntermediaryIdFound
        && paymentIntermediaryIdFound.has_credentials
        && (this.payment_intermediary_merchants.length > 0);
    },
    handleCreateModalCardIntegration() {
      this.$refs.ModalCardIntegration.handleCreateModal(
        this.payload.payment.payment_intermediary_id,
        this.payload.payment.payment_intermediary_merchant_id,
        this.payload.payment.competence,
      );
    },
    /**
     * @param {object} selectedTransactionProps
     * @param {integer} selectedTransactionProps.payment_intermediary_id
     * @param {integer} selectedTransactionProps.paymentIntermediaryMerchantId
     * @param {string} selectedTransactionProps.nsu_code
     */
    setTransactionProps(selectedTransactionProps) {
      this.payload.payment.payment_intermediary_id = selectedTransactionProps.paymentIntermediaryId;
      this.payload.payment.payment_intermediary_merchant_id = selectedTransactionProps.paymentIntermediaryMerchantId;
      this.payload.payment.nsu_code = selectedTransactionProps.nsu_code;
      this.payload.payment.competence = selectedTransactionProps.competence;
    },
    getPaymentIntermediaryMerchants(){
      this.payload.payment.payment_intermediary_merchant_id = null
      this.loadingPaymentIntermediaryMerchants = true
      const params = {
        filter: {
          payment_intermediary_id: this.payload.payment.payment_intermediary_id,
          status: true,
        }
      };
      this.$store
        .dispatch("paymentIntermediaryMerchant/fetchItems", params)
        .then(response => {
          this.payment_intermediary_merchants = response.data;
          if (this.payment_intermediary_merchants.length === 1){
            this.payload.payment.payment_intermediary_merchant_id = this.payment_intermediary_merchants[0].id;
          }
          this.loadingPaymentIntermediaryMerchants = false;
        });
    },
    changeBankAccount() {
      this.getBankAccounts()
    },
    /** regras após inserção mediador de pagamento */
    /** validações por método de pagamento */
    setRequireNsuCode() {
      this.modalCardIntegrations = true;
      this.payload.payment.payment_intermediary_id = null
      this.payment_intemerdiaries_selected = []
      let payment_method = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid)
      if (!payment_method) {
        this.payment_intermediaries_exists = false
        return this.require_nsu_code = false
      }
      this.payment_intermediaries_exists = payment_method.payment_intermediaries_exists
      // if (payment_method.payment_intermediaries_exists) {
      //   this.payload.installments = []
      //
      //   this.payment_intemerdiaries_selected = this.$_payment_intermediaries.filter(function (item) {
      //     if (payment_method.payment_intermediaries.includes(item.id) && item.status) {
      //       return item
      //     }
      //   })
      // }
      return this.require_nsu_code = Boolean(payment_method.transaction_code)
    },
    getPaymentTerms(set_installments = false) {
      this.payload.payment.payment_term_uuid = null
      const payment_methods_selected = [this.payload.payment.payment_method_uuid]
      const self = this
      this.payment_terms_selected = this.payment_terms.filter(function (item) {
        for (var payment_method of item.payment_methods) {
          if (payment_methods_selected.includes(payment_method.uuid)
            && self.contract_proposal.payment_terms_uuids.includes(item.uuid)) {
            return item;
          }
        }
      })
      if (this.contract_proposal.payment_terms_uuids.length === 1) {
        const payment_term_uuid = this.contract_proposal.payment_terms_uuids[0]
        const exists = this.payment_terms_selected.find((item) => item.uuid = payment_term_uuid)
        if (exists) {
          this.payload.payment.payment_term_uuid = payment_term_uuid
        }
      }
      if (set_installments) {
        this.generateInstallments()
      }
    },
    /** fim */
    closeModal() {
      this.modal.create = false;
    },
    validHaveTotal(value) {
      value = value ? value.toString().replace(",", "") : 0;
      if (value < 1) {
        return true;
      }
      return false;
    },
    calcTotalValueToReceive(){
      const sum = this.payload.installments.reduce(function(new_value, obj) {
        new_value += parseFloat(obj.value.includes(",") ? obj.value.replaceAll(".", "").replace(",", ".") : obj.value);
        return new_value;
      }, 0);
      return this.payload.payment.total_value = Number(sum).toFixed(2)
    },
    calcFinalValue() {
      let total = 0;
      let total_value = 0;
      let final_value = 0
      for (let i in this.payload.installments) {
        const day = Number(moment(this.payload.installments[i].due_date).day());
        if ([0, 6].includes(day)) {
          if (day === 0) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(1, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
          if (day === 6) {
            const due_date = moment(this.payload.installments[i].due_date)
              .add(2, "days")
              .format("YYYY/MM/DD");
            this.payload.installments[i].due_date = due_date;
            this.payload.installments[i].original_due_date = due_date;
          }
        }
        total += Number(this.$helper.strToNum(this.payload.installments[i].value))
        final_value += Number(this.$helper.strToNum(this.payload.installments[i].total_value))
      }
      total_value = this.payload.payment.total_value
        .replace(".", "")
        .replace(",", ".");
      if (this.payload.installments.length) {
        if (Number(total_value).toFixed(2) > Number(total).toFixed(2)) {
          const diff = (Number(total_value) - total).toFixed(2);
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) + Number(diff)
          ).toFixed(2);
        }
        if (Number(total_value).toFixed(2) < Number(total).toFixed(2)) {
          const diff = Number(total).toFixed(2) - Number(total_value).toFixed(2)
          this.payload.installments[0].value = Number(
            Number(this.$helper.strToNum(this.payload.installments[0].value)) - Number(diff)
          ).toFixed(2);
        }
      }
      this.payload.payment.final_value = Number(final_value).toFixed(2);
    },
    calcIss() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (id) {
        let invoice = self.invoices.find(function (item) {
          return item.id === id;
        });
        total_value += invoice.iss_value.toFixed(2);
      });
      this.payload.payment.iss_retain = 0
      this.$nextTick(function () {
        this.generateInstallments(1)
      })
    },
    calcInvoicedAmount() {
      const self = this;
      let total_value = 0;
      this.payload.invoices.map(function (obj) {
        let invoice = self.invoices.find(function (item) {
          return item.id == obj;
        })
        total_value += Number(invoice.pending_billing);
      });
      this.invoiced_amount = total_value.toFixed(2);
     const total_value_amount = this.payload.payment.total_value.replace(".", "").replace(",", ".");
      this.balance =  (Number(total_value_amount) - Number(this.invoiced_amount)).toFixed(2);
      if (this.balance < 1) {
        this.balance = 0;
      }
    },
    formatString(value, min, max, decimals = "") {
      if (decimals) {
        this.$forceUpdate();
        return checkNumberValue(mask1(value.toString(), decimals, ".", true), min, max);
      }
      return checkNumberValue(value, min, max);
    },
    setPaymentTerms() {
      let paymentMethod = this.payment_methods.find(item => item.uuid === this.payload.payment.payment_method_uuid)
      this.payment_terms_by_method = this.payment_terms.filter(function (item) {
        let has_method = item.payment_methods.find(method => method.uuid === paymentMethod.uuid)
        if (has_method) {
          return item
        }
      })
    },
    getColor(status) {
      switch (status) {
        case 0:
          return "warning";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
      }
    },
    getTitle(status) {
      switch (status) {
        case 0:
          return "Pendente";
        case 1:
          return "Pago";
        case 2:
          return "Atrasado";
        case 3:
          return "Cancelado";
      }
    },
    getCompanyPlantIssuers() {
      this.payload.payment.company_plant_issuer_id = null
      this.loadingCompanyPlantIssuers = true
      this.$store.dispatch('companyPlantIssuer/fetchItems', {
        allocated: true,
        company_plant_id: this.payload.payment.company_plant_id,
      }).then(response => {
        this.loadingCompanyPlantIssuers = false
        this.$notify({
          type: response.error_type,
          message: response.message
        });
      })
        .catch(() => {
          this.loadingCompanyPlantIssuers = false
        });
    },
    setInvoice(set_plant_issuers = false) {
      if (set_plant_issuers) {
        this.payload.payment.company_plant_issuer_id = null
        this.loadingCompanyPlantIssuers = true
        this.$store.dispatch('companyPlantIssuer/fetchItems', {
          allocated: true,
          company_plant_id: this.payload.payment.company_plant_id
        }).then(response => {
          const plant = this.plants.find((item) => item.id === this.payload.payment.company_plant_id)
          const default_plant_issuer = plant.company_issuers.find((item) => item.default === 1)
          if (default_plant_issuer) {
            this.payload.payment.company_plant_issuer_id = default_plant_issuer.id
            this.getBankAccounts()
            this.setInvoice()
          }
          this.loadingCompanyPlantIssuers = false
        })
          .catch(() => {
            this.loadingCompanyPlantIssuers = false
          });
      }
      if (this.payload.payment.company_plant_issuer_id === null ||
        this.payload.payment.company_plant_id === null) {
        return;
      }
      let loader = this.$loading.show()
      this.payload.invoices = []
      this.$store
        .dispatch("billingInvoice/init", {
          filter: {
            // with_iss_retained_flag: true,
            pending_payment: 1,
            contract_proposal_id: this.contract_proposal.id,
            not_canceled: true,
          }
        })
        .then(response => {
          loader.hide()
        })
    },
    setMethods() {
      let Payment_methods = []
      for (var i of this.contract_proposal.payment_terms_uuids) {
        const payment_methods = this.payment_terms.find(item => item.uuid === i).payment_methods
        Payment_methods = [...Payment_methods, ...payment_methods]
      }
      const objectMap = Payment_methods.reduce((map, object) => {
        map.set(object.id, object);
        return map
      }, new Map())
      this.Payment_methods = Array.from(objectMap, ([_, value]) => value)
    },
    getBankAccounts() {
      if (this.$_general_settings.show_bank_account_by_issuer && !this.payload.payment.company_plant_issuer_id) {
        return
      }
      this.payload.payment.bank_account_id = null
      this.loadingBankAccounts = true
      this.$store.dispatch("companyPlantBankAccount/getByCompanyPlant",
        {
          allocated: 1,
          type: this.$_general_settings.show_bank_account_by_issuer ? GetBankAccountEnum.COMPANY_PLANT_ISSUER : GetBankAccountEnum.COMPANY_PLANT,
          type_id: this.$_general_settings.show_bank_account_by_issuer ? this.payload.payment.company_plant_issuer_id : this.payload.payment.company_plant_id
        }).then((response) => {
        this.loadingBankAccounts = false
        this.bank_accounts = response.data
          if (!this.$_general_settings.show_bank_account_by_issuer) {
            const company_plant = this.$_company_plants.find(item => item.id === this.payload.payment.company_plant_id)
            this.payload.payment.bank_account_id = company_plant.bank_account_id
          }
          else {
            this.payload.payment.bank_account_id = response.data.find(item => item.default).bank_account_id
          }
      })
    },
    async handleCreateModal(companyPlant, default_value = false) {
      await this.$store.dispatch('generalSetting/show')
      let loader = this.$loading.show()
      this.modalCardIntegrations = false;
      Promise.all([
        this.$store.dispatch("paymentTerm/fetchItems"),
        this.$store.dispatch("paymentIntermediary/fetchItems"),
        this.$store.dispatch("plant/fetchItemsActive"),
        this.$store.dispatch("paymentMethod/fetchItems"),
      ]).then(() => {
        this.loadingPaymentIntermediaries = false;
        this.loadingCompanyPlants = false;
        this.modal.create = true;
        this.payment_methods_selected =
          this.payment_methods.filter((item) => this.contract_proposal.payment_methods_uuids.includes(item.uuid))
        if (this.contract_proposal.payment_methods_uuids.length === 1) {
          this.payload.payment.payment_method_uuid = this.contract_proposal.payment_methods_uuids[0]
        }
        this.getPaymentTerms()
        this.type = 1
        this.payload.payment.competence = null
        this.payload.payment.name = null
        this.payload.payment.total_value = default_value ? Number(default_value).toFixed(2) : null
        this.payload.payment.iss_retain = 0
        this.payload.payment.observations = null
        this.payload.payment.company_plant_uuid = companyPlant.uuid
        this.payload.payment.final_value = null
        this.payload.payment.payment_term_uuid = null
        this.payload.payment.payment_method_uuid = null
        this.payload.payment.payment_intermediary_id = null
        this.payload.payment.transaction_code = null
        this.payload.payment.nsu_code = null
        this.payload.payment.contract_proposal_uuid = this.$route.params.contract_proposal_uuid
        this.payload.payment.company_plant_id = this.contract_proposal.plant.id
        this.setInvoice(true)
        this.payload.invoices = []
        this.payload.installments = []
        this.balance = 0;
        this.invoiced_amount = 0;
        this.loadingSpinner = false;
        this.payload.payment.type = 1;
        this.changeBankAccount()
        loader.hide()
      })
    },
    finalFormat() {
      let payload = JSON.parse(JSON.stringify(this.payload));
      payload.payment.total_value = payload.payment.total_value.replaceAll(".", "").replace(",", ".");
      payload.payment.final_value = payload.payment.final_value.replaceAll(".", "").replace(",", ".");
      payload.payment.iss_retain = 0
      payload.installments.map(function (obj, key) {
        obj.value = obj.value.replaceAll(".", "").replace(",", ".");
        obj.total_value = obj.total_value.replaceAll(".", "").replace(",", ".");
      });
      payload.payment.balance = this.balance.toString().replaceAll(".", "").replace(",", ".");
      return payload;
    },
    /**
     * Validar pagamento de comerciante (Transações de cartão de crédito/Débito)
     * @returns boolean
     */
    async isValidPaymentIntermediaryMerchant(){
      // Campos obrigatórios para consulta
      const paymentIntermediaryMerchantIsFilled = this.payload.payment.payment_intermediary_id
        && this.payload.payment.payment_intermediary_merchant_id
        && this.payload.payment.nsu_code;
      if (!paymentIntermediaryMerchantIsFilled){
        this.$notify({
          type: "danger",
          message: `Quando NSU da transação é informado, é necessário preencher "Mediador de Pagamento" e "Ponto de Venda (PV)".`
        });
        return false;
      }

      // Verificar existência da transação
      // const params = {
      //   id: this.payload.payment.payment_intermediary_merchant_id,
      //   saleDate: this.$helper.parseDate(this.payload.payment.competence, 'YYYY-MM-DD'),
      //   nsuCode: this.payload.payment.nsu_code,
      // };
      // return await this.$store
      //   .dispatch("paymentIntermediaryMerchant/getSaleFromCardIntegrationByNsuCode", params)
      //   .then((response) => {
      //     if (response.data.length === 0){
      //       this.$notify({
      //         type: "danger",
      //         message: `NSU da transação "${params.nsuCode}" não foi encontrado em competência ${this.$helper.parseDate(params.saleDate, 'DD/MM/YYYY')}`,
      //       });
      //       return false
      //     }
      //     let isValid = true;
      //     const {credentialTypeName, amount, installmentQuantity, typeName, tid} = response.data;
      //     const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid)
      //     const isCreditTypeName = payment_method && payment_method.id == 2;
      //     this.payload.payment.transaction_code = tid;

      //     // Comparar valor com API de Mediador de Pagamento
      //     if (this.$helper.strToNum(this.payload.payment.total_value) !== amount){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Valor Total (A Receber) cadastrado é diferente do encontrado em ${credentialTypeName}: R$ ${amount.toFixed(2)}.`
      //       });
      //     };

      //     // Comparar quantidade de parcelas com API de Mediador de Pagamento
      //     if (this.payload.installments.length !== installmentQuantity){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Quantidade de Parcelas cadastrada é diferente do encontrado em ${credentialTypeName}: ${installmentQuantity} Parcelas.`
      //       });
      //     }

      //     // Compararar tipo de cartão (Crédito ou Débito)
      //     if (isCreditTypeName && (typeName.toUpperCase() !== 'CREDIT')){
      //       isValid = false;
      //       this.$notify({
      //         type: "danger",
      //         message: `Tipo de Cartão [Crédito/Débito] não confere com o encontrado em ${credentialTypeName}: ${typeName.toUpperCase()}O.`
      //       });
      //     }

      //     return isValid;
      //   }).catch(error => {
      //     const technicalMessage = error.status ? error.data.message : formatErrorValidation(error.response.data.errors);
      //     this.$notify({type: "danger", message: `Transação: ${params.nsuCode} não encontrada.`});
      //     this.$notify({type: "danger", message: `Mensagem técnica: ${technicalMessage}.`});
      //     return false;
      //   });
      return true;
    },
    async store() {
      let total_value = Number(this.payload.payment.total_value.replaceAll(".", "").replace(",", "."))
      let invoice_amount = Number(this.invoiced_amount.replaceAll(".", "").replace(",", "."))
      if (total_value > invoice_amount) {
        return this.$notify({
          type: "danger",
          message: 'O valor total do recebimento não pode ser maior que o valor faturado'
        });
      }

      const has_final_less = this.payload.installments.some((item) => Number(item.total_value.replaceAll(".", "").replace(",", ".")) < 0)
      const has_total_less = this.payload.installments.some((item) => Number(item.value.replaceAll(".", "").replace(",", ".")) <= 0)
      if (has_total_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser igual ou menor a R$ 0,00.'
        });
      }
      if (has_final_less) {
        this.$notify({
          type: "danger",
          message: 'O valor total da parcela não pode ser menor a R$ 0,00.'
        });
      }
      if (has_final_less || has_total_less) {
        return;
      }
      // Verificar NSU da transação quando obrigatório
      this.loadingStore = true;
      const payment_intermediary = this.payment_intemerdiaries_selected.find((item) => item.id === this.payload.payment.payment_intermediary_id);
      // const hasCredentials = payment_intermediary && payment_intermediary.has_credentials;
      // const payment_method = this.payment_methods.find((item) => item.uuid === this.payload.payment.payment_method_uuid)
      // const nsuCodeIsRequired = payment_method && [2,3].includes(payment_method.id) && hasCredentials;
      // if (nsuCodeIsRequired){
      //   if (!this.payload.payment.nsu_code) {
      //     this.$notify({type: "danger", message: `NSU da transação é obrigatório.`});
      //     this.loadingStore = false;
      //     return;
      //   }
      //   if (!await this.isValidPaymentIntermediaryMerchant()) {
      //     this.loadingStore = false;
      //     return;
      //   }
      // }
      const has = this.validHasSufficientBalance();
      if (!has) {
        return this.$notify({
          type: "danger",
          message: "Não há saldo suficiente para pagamento das faturas."
        });
      }
      this.$Progress.start();
      this.loadingStore = true;
      this.payload.payment.competence = this.$helper.parseDate(this.payload.payment.competence, 'YYYY-MM-DD');
      let payload = this.finalFormat();
      let formData = new FormData();
      for (let key of Object.keys(payload.payment)) {
        if (payload.payment[key]) {
          formData.append(`payment[${key}]`, payload.payment[key]);
        }
      }
      for (let i = 0; i < payload.installments.length; i++) {
        for (let key of Object.keys(payload.installments[i])) {
          if (payload.installments[i][key]) {
            formData.append(`installments[${i}][${key}]`, payload.installments[i][key]);
          }
        }
      }
      for (let indexFile in this.$refs.files.fileRecords) {
        formData.append(
          indexFile,
          this.$refs.files.fileRecords[indexFile]["file"]
        );
      }
      // payload.installments.map(function (item, key) {
      //   for (let indexFile in this.$refs[`input-file-${key}`][0].fileRecords) {
      //     const index_name = key + '-' + indexFile
      //     formData.append(index_name, this.$refs[`input-file-${key}`][0].fileRecords[indexFile]['file']);
      //   }
      // }, this);
      payload.invoices.map(function (item, key) {
        formData.append(`invoices[${key}]`, payload.invoices[key]);
      });
      this.$store
        .dispatch("contractProposalPayment/add", formData, {
          headers: {
            "content-type": "multipart/form-data"
          }
        })
        .then(response => {
          this.$emit("added");
          this.contract_proposal.pending_launch_value -= this.payload.payment.total_value.replaceAll(".", "").replace(",", ".");
          this.loadingStore = false;
          this.closeModal();
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            let errors = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: errors
            });
          }
          else {
            this.$notify({type: 'danger', message: error.data.message})
          }
          this.$Progress.finish();
          this.loadingStore = false;
        });
    },
    formatAccountLabel(item) {
      return item.name
    },
    generateInstallments(recalc = 0) {
      if (recalc === 1) {
        this.payload.payment.final_value = this.payload.payment.total_value;
      }
      if (this.payload.payment.payment_term_uuid && this.payload.payment.payment_method_uuid) {
        let self = this;
        let uuid = this.payload.payment.payment_term_uuid;
        let payment_term = this.payment_terms.filter(
          item => item.uuid === uuid
        )[0];

        let payment_method = this.payment_methods.find(
          item => item.uuid === self.payload.payment.payment_method_uuid
        );
        /*
         * Buscamos as parcelas da condição de pagamento
         */
        let installments = payment_term.installments;
        let payload_installments = [];
        /*
         * Percorremos as parcelas de condição de pagamento
         * e montamos as nossas para pagamento de proposta contrato.
         */
        // valor total
        const total = Number(self.payload.payment.total_value
          .replaceAll(".", "")
          .replace(",", "."))
        // valor retido
        // const iss_retain = Number(this.payload.payment.iss_retain
        //   .replaceAll(".", "")
        //   .replace(",", "."))
        const total_value = total

        const payment_intermediary = this.$_payment_intermediaries.find((item) => item.id === this.payload.payment.payment_intermediary_id)
        if (payment_intermediary) {
          let total_percentage_tax = 0
          // SE FOR CRÉDITO PARCELADO ACUMULADO
          /** checked */
          if (payment_intermediary.credit_in_installments.receipt_method === 0 &&
            payment_method.payment_type === 1 && payment_term.total_installment > 1) {
            const deadline = payment_intermediary.credit_in_installments.deadline
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })

            total_percentage_tax = Object.values(payment_intermediary
              .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
            total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

            let date = moment(self.payload.payment.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
            discounts = parseFloat(discounts) + tax_percentage_intermediary
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2)
            };
            this.payload.installments = [installment]
            return this.calcFinalValue()
          }
          // SE FOR CRÉDITO PARCELADO COM RECEBIMENTO PARCELADO
          /** checked */
          if (payment_intermediary.credit_in_installments.receipt_method === 1 &&
            payment_method.payment_type === 1 && payment_term.total_installment > 1) {
            // Object.values(payment_intermediary.additional_installments).map(function (obj) {
            //   if (obj.installment <= payment_term.total_installment) {
            //     total_percentage_tax += Number(obj.tax_percentage.toString().replace(',', '.'))
            //   }
            // })
            total_percentage_tax = Object.values(payment_intermediary
              .additional_installments).find((item) => item.installment === payment_term.total_installment).tax_percentage
            total_percentage_tax = Number(total_percentage_tax.toString().replace(',', '.'))

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            let tax_percentage_intermediary = parseFloat(payment_intermediary.credit_in_installments.tax_percentage.toString().replace(',', '.'))
            discounts = parseFloat(discounts) + tax_percentage_intermediary
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(payment_intermediary.credit_in_installments.tax_monetary.toString().replace(',', '.'))
            installments.map(function (obj, key) {
              let date = moment(self.payload.payment.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (value - (discounts / payment_term.total_installment)).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: (discounts / payment_term.total_installment).toFixed(2)
              };
              payload_installments.push(installment);
            })
            this.payload.installments = payload_installments
            return this.calcFinalValue()
          }
          // SE FOR CRÉDITO A VISTA
          if (payment_method.payment_type === 1 && payment_term.total_installment === 1) {
            const deadline = payment_intermediary.credit_on_sight.deadline
            total_percentage_tax = Number(payment_intermediary.credit_on_sight.tax_percentage.toString().replace(',', '.'))
            let date = moment(self.payload.payment.competence)
              .add("days", deadline)
              .format("YYYY-MM-DD");

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(payment_intermediary.credit_on_sight.tax_monetary.toString().replace(',', '.'))
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2)
            };
            this.payload.installments = [installment]
            return this.calcFinalValue()
          }
          // SE FOR DÉBITO A VISTA
          if (payment_method.payment_type === 0 && payment_term.total_installment === 1) {
            const deadline = payment_intermediary.debit.deadline
            total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))
            let date = moment(self.payload.payment.competence)
              .add("days", parseInt(deadline) + installments[0].deadline)
              .format("YYYY-MM-DD");
            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
            let installment = {
              value: total_value.toFixed(2),
              index: 1,
              total_value: (total_value - discounts).toFixed(2),
              due_date: date,
              original_due_date: date,
              status: 0,
              paid_at: null,
              fees_fines: null,
              discounts: null,
              discounts_fees: discounts.toFixed(2)
            };
            this.payload.installments = [installment]
            return this.calcFinalValue()
          }
          // SE FOR DÉBITO PARCELADO
          if (payment_method.payment_type === 0 && payment_term.total_installment > 1) {
            total_percentage_tax = Number(payment_intermediary.debit.tax_percentage.toString().replace(',', '.'))

            let discounts = parseFloat(total_percentage_tax).toFixed(2);
            discounts = (discounts / 100) * total_value.toFixed(2);
            discounts += Number(payment_intermediary.debit.tax_monetary.toString().replace(',', '.'))
            installments.map(function (obj, key) {
              let date = moment(self.payload.payment.competence)
                .add("days", obj.deadline)
                .format("YYYY-MM-DD");

              let percentage = parseFloat(obj.percentage);
              let value = (percentage / 100) * total_value;

              let installment = {
                value: value.toFixed(2),
                index: obj.index,
                total_value: (value - discounts).toFixed(2),
                due_date: date,
                original_due_date: date,
                status: 0,
                paid_at: null,
                fees_fines: null,
                discounts: null,
                discounts_fees: discounts.toFixed(2)
              };
              payload_installments.push(installment);
            })
            this.payload.installments = payload_installments
            return this.calcFinalValue()
          }
        }

        installments.map(function (obj, key) {
          /*
           * Calculamos a data de vencimento a atribuir.
           */
          let date = moment(self.payload.payment.competence)
            .add("days", obj.deadline)
            .format("YYYY-MM-DD");

          /*
           * Calculamos o valor da parcela.
           */
          let payment_total_value = total_value
          let percentage = parseFloat(obj.percentage);
          let value = (percentage / 100) * payment_total_value;

          let installment = {
            value: value.toFixed(2),
            index: obj.index,
            total_value: value.toFixed(2),
            due_date: date,
            original_due_date: date,
            status: 0,
            paid_at: null,
            fees_fines: null,
            discounts: null,
            discounts_fees: null
          };
          payload_installments.push(installment);
        });
        this.payload.installments = payload_installments;
      }
      this.$forceUpdate();
      this.calcFinalValue()
      this.calcInvoicedAmount()
    },
    // Verifica se o valor total consegue realizar o pagamento de todas as faturas
    validHasSufficientBalance() {
      if (this.balance.toString().replaceAll(".", "")
        .replace(",", ".") > 0) {
        return true
      }
      let balance = 0
      let total = this.payload.payment.total_value
        .replaceAll(".", "")
        .replace(",", ".");
      total = parseFloat(total)
      balance += total
      for (let id of this.payload.invoices) {
        if (balance <= 0) {
          return false
        }
        const invoice = this.invoices.find(item => item.id === id)
        const value = Number(invoice.pending_billing)
        if (balance > value) {
          balance -= value
        } else {
          balance = 0
        }
      }
      return true
    },
    async calcTotalValue(installment, index) {
      let discounts = 0;
      let fees = 0;
      let current_total_value = 0;
      let current_value = 0;
      current_total_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_value = parseFloat(
        installment.value.replaceAll(".", "").replace(",", ".")
      );
      current_total_value -= Number(installment.discounts_fees.replaceAll(".", "").replace(",", "."));
      current_total_value -= Number(installment.discounts.replaceAll(".", "").replace(",", "."));
      current_total_value += Number(installment.fees_fines.replaceAll(".", "").replace(",", "."));
      this.payload.installments[
        index
        ].total_value = current_total_value.toFixed(2);
      this.$forceUpdate();
      await this.calcTotalValueToReceive();
      this.calcInvoicedAmount();
      this.calcFinalValue();
    },
    getValueFromSchedule() {
      this.payload.payment.total_value = Number(this.contract_proposal.balance.total_accomplish).toFixed(2);
      this.generateInstallments(1)
    }
  },
};
</script>

<style scoped>
@media (min-width: 768px) {
  .limited-row {
    max-height: 28px !important;
  }
}
</style>
