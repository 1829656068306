import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} PaymentMethodListFilterExtType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & PaymentMethodListFilterExtType)} PaymentMethodListFilterType
 */
export const PaymentMethodListFilterType = {};

/**
 * Inicializar PaymentMethodListFilterType
 *
 * @returns {PaymentMethodListFilterType}
 */
export const initPaymentMethodListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}