var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal',{attrs:{"show":_vm.modal.create,"backgroundColor":'#fef9f2',"size":"lg"},on:{"update:show":function($event){return _vm.$set(_vm.modal, "create", $event)}}},[_c('template',{slot:"header"},[_c('div',{staticClass:"d-flex align-items-center container-fluid",staticStyle:{"gap":"12px","margin-left":"-28px"}},[_c('div',[_c('AppIcon',{staticClass:"mr-3",attrs:{"icon":"phone-contact","color":"warning","width":"31px"}})],1),_c('span',{staticClass:"text-title mt-n1"},[_vm._v(" "+_vm._s(_vm.modal.title)+" ")])])]),_c('template',{slot:"close-button"},[_c('AppButton',{attrs:{"icon":"close","width":"auto","type":"link","size":"sm","hasOutline":true},on:{"click":function($event){return _vm.closeModal()}}})],1),_c('div',{staticClass:"p-2"},[_c('div',{staticClass:"container"},[(_vm.contractProposal.entity || _vm.contractProposal.entity_id)?_c('div',{staticClass:"contacts-section mb-4"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"business-building","color":"success","width":"26"}}),_c('h6',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v("Contatos do cliente")])],1),_c('AppButton',{attrs:{"label":"Novo","type":"success","size":"sm","icon":"plus-math","rounded":"xxl","width":"120px"},on:{"click":function($event){$event.preventDefault();return _vm.openModalcreateContact(
                'entity',
                _vm.contractProposal.entity?.id ?? _vm.contractProposal.entity_id
              )}}})],1),_c('div',{staticClass:"outer-border"},[_c('div',{staticClass:"row"},_vm._l((_vm.$_contactsEntity),function(contact,index){return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"content-card position-relative"},[_c('div',{staticClass:"config-button position-absolute"},[_c('base-dropdown',{staticClass:"action-button",attrs:{"menuOnRight":""}},[_c('div',{staticClass:"dropdown-toggle m-0",attrs:{"slot":"title-container"},slot:"title-container"},[_c('AppIcon',{staticClass:"rotate-icon",attrs:{"icon":"settings","color":"primary","width":"30"}})],1),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.openModalEditContacts('entity', contact.id)}}},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"create","color":"warning","width":"20"}}),_vm._v(" Editar ")],1)])],1),_c('div',{staticClass:"contact-info"},[_c('p',{staticClass:"mb-0 label-title mb-1"},[_vm._v(_vm._s(contact.name))]),_c('p',{staticClass:"mb-0 label-item"},[_vm._v(_vm._s(contact.email))]),_c('p',{staticClass:"mb-0 label-item mt-1"},[_vm._v(_vm._s(contact.phone))]),_c('hr',{staticClass:"my-3"}),_c('p',{staticClass:"mb-0 label-item mt-3"},[_vm._v(_vm._s(contact.office))]),(contact.signature_type != 'nenhum')?_c('p',{staticClass:"mb-0 label-item mt-1"},[_vm._v(" "+_vm._s(contact.signature_type)+" ")]):_vm._e()])])])}),0)])]):_vm._e(),_c('div',{staticClass:"contacts-section"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between mb-3"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"brick-wall","color":"warning","width":"26"}}),_c('h6',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"16px"}},[_vm._v("Contatos da obra")])],1),_c('AppButton',{attrs:{"label":"Novo","type":"success","size":"sm","icon":"plus-math","rounded":"xxl","width":"120px"},on:{"click":function($event){$event.preventDefault();return _vm.openModalcreateContact(
                'construction',
                null,
                _vm.contractProposal.construction?.uuid ?? _vm.contractProposal.customer_construction_uuid
              )}}})],1),_c('div',{staticClass:"outer-border"},[_c('div',{staticClass:"row"},_vm._l((_vm.$_contactsConstruction),function(contact,index){return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"content-card position-relative"},[_c('div',{staticClass:"config-button position-absolute"},[_c('base-dropdown',{staticClass:"action-button",attrs:{"menuOnRight":""}},[_c('div',{staticClass:"dropdown-toggle m-0",attrs:{"slot":"title-container"},slot:"title-container"},[_c('AppIcon',{staticClass:"rotate-icon",attrs:{"icon":"settings","color":"primary","width":"30"}})],1),_c('a',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.openModalEditContacts('construction', contact.uuid)}}},[_c('AppIcon',{staticClass:"mr-2",attrs:{"icon":"create","color":"warning","width":"20"}}),_vm._v(" Editar ")],1)])],1),_c('div',{staticClass:"contact-info"},[_c('p',{staticClass:"mb-0 label-title mb-1"},[_vm._v(_vm._s(contact.name))]),_c('p',{staticClass:"mb-0 label-item"},[_vm._v(_vm._s(contact.email))]),_c('p',{staticClass:"mb-0 label-item mt-1"},[_vm._v(" "+_vm._s(contact.cell_phone || contact.phone)+" ")]),_c('hr',{staticClass:"my-3"}),_c('p',{staticClass:"mb-0 label-item mt-3"},[_vm._v(_vm._s(contact.office))]),(contact.signature_type != 'nenhum')?_c('p',{staticClass:"mb-0 label-item mt-2"},[_vm._v(" "+_vm._s(contact.signature_type)+" ")]):_vm._e()])])])}),0)])])]),_c('div',{staticClass:"row mt-4 mb-2"},[_c('div',{staticClass:"col-12 text-right"},[_c('AppButton',{staticClass:"ml-2",attrs:{"label":"Fechar","type":"dark","isDisabled":_vm.invalid,"hasOutline":true},on:{"click":function($event){return _vm.closeModal()}}})],1)])])],2),_c('ModalCreateContact',{ref:"createContact"}),_c('ModalEditContacts',{ref:"editContact",on:{"contactEntitySaved":_vm.updateEntityListAfterUpdate}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }