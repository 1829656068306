<template>
  <div>
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loading">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        v-else
        :contractInfo="$_contract_proposal"
      >
        <AppPageHeaderActions>
            <AppPageHeaderActionsButton
              @click.prevent="$router.push(`/operational/contract-proposal/schedule/create/${$route.params.contract_proposal_uuid}`)"
              :disabled="loading || [0, 5, 4].includes($_contract_proposal.status)"
              type="success"
              text="Novo"
              icon="/img/icons/icons8/ios/svgs/plus-math.svg"
              class="invert-color"
              >
            </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>

      <div :class="$_contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          :contractUuid="$route.params.contract_proposal_uuid"
          :contractStatus="$_contract_proposal.status"
          :entity="$_contract_proposal.entity"
          :activeSchedules="true"
          style="margin-top: 12px;"
        />
      </div>

      <multi-filter :prohibited="[11, 10, 16]" @clearFilters="clearFilter" @fetch="init" :filter="filter" :status="multi_filter">
        <div class="col-md-3 p-2">
          <input-date-picker :filterable="2" ref="date-picker" @handleFilterDate="handleFilterDate" :default-range="range"/>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.status === 11 && 'active'" @click="handleFilterAdvancedStatus(11)" outline block type="primary">
            <i class="fas fa-check-square"></i> CONCLUÍDO
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.status === 10 && 'active'" @click="handleFilterAdvancedStatus(10)" outline block type="default">
            <i class="fas fa-route"></i> EM ANDAMENTO
          </base-button>
        </div>
        <div class="col-md-3 p-2">
          <base-button :class="filter.status === 16 && 'active'" @click="handleFilterAdvancedStatus(16)" outline block type="warning">
            <i class="fas fa-lock"></i> PENDÊNCIAS
          </base-button>
        </div>
      </multi-filter>
    </div>
    <div class="container-fluid">
      <div class="row card-wrapper" v-show="loadingSkeleton">
        <SkeletonPuzlGrid v-for="index in 3" :key="index"></SkeletonPuzlGrid>
      </div>
      <PuzlEmptyData v-if="!schedules.length && !loadingSkeleton && !loading"></PuzlEmptyData>

      <!-- Start Card ContractProposal -->
      <div class="row card-wrapper pt-2" v-if="schedules.length && !loadingSkeleton">
        <div class="col-md-6 col-lg-4 col-xlg-4" v-for="(schedule, indexSchedule) in schedules" :key="indexSchedule">
          <!-- Basic with action button -->
          <card :class="'card-border-top-' + getColor(schedule.status.status)">
            <!-- Card header -->
            <div class="row align-items-center mb-2">
              <!-- Status -->
              <div class="col-9">
                <!-- Title -->
                <div>
                  <h5 class="h3" :class="'text-' + getColor(schedule.status.status)">
                    {{ schedule.status.label }} {{schedule.status.status === 2 ? `| ${schedule.requested_by_user_name}` : ''}}
                  </h5>
                </div>
              </div>
              <div class="col-3">
                <base-dropdown menuOnRight>
                  <base-button slot="title-container" type="primary" class="dropdown-toggle p-2 rounded m-0">
                    <i class="fas fa-cog"></i>
                  </base-button>
                  <a class="dropdown-item" @click.prevent="handleHistorie(schedule.uuid)">
                    <i class="fas fa-history text-indigo main-icon-menu"></i>
                    <span class="text-right">Histórico</span>
                  </a>
                  <div v-if="['seller_request', 'not_confirmed'].includes(schedule_status[schedule.status.status])">
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      v-if="!['canceled', 'rescheduled', 'not_performed'].includes(schedule_status[schedule.status.status])"
                      class="dropdown-item"
                      @click.prevent="
                        handleModalListService(schedule.contract_proposal.uuid, schedule.uuid, schedule.company_plants, {
                          total_volume: schedule.total_volume,
                          first_arrival_time: schedule.first_arrival_time,
                          last_exit_time: schedule.last_exit_time,
                        })
                      "
                    >
                      <i class="fas fa-plus text-success main-icon-menu"></i>
                      <span class="text-right">Serviço</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a
                      @click.prevent="$router.push(`/operational/contract-proposal/schedule/edit/${schedule.uuid}`)"
                      class="dropdown-item"
                    >
                      <i class="fas fa-edit text-warning main-icon-menu"></i>
                      <span class="text-right">Editar</span>
                    </a>
                    <div class="dropdown-divider p-0 m-0"></div>
                    <a @click.prevent="handleDeleteProgramming(schedule.uuid)" class="dropdown-item">
                      <i class="fas fa-times text-danger main-icon-menu"></i>
                      <span>Excluir</span>
                    </a>
                  </div>
                </base-dropdown>
              </div>
            </div>

            <!-- Card body -->
            <div class="mt-4">
              <div
                :class="
                  schedule.status.companies && schedule.status.companies.includes(plant.uuid)
                    ? 'border-left border-danger border-3 p-2'
                    : 'border-left border-primary border-3 p-2'
                "
                style="padding-bottom: 2px !important; padding-top: 2px !important"
                v-for="(plant, indexPlant) in schedule.company_plants"
                :key="indexPlant"
              >
                <h4 class="mb-0" :class="schedule.status.companies && schedule.status.companies.includes(plant.uuid) ? 'text-danger' : ''">
                  {{ plant.company_plant_name }}
                </h4>
              </div>
            </div>
            <div v-if="schedule.next_travel" class="border-left border-primary border-3 p-2 mt-0">
              <h4 class="small">
                De: <b>{{ schedule.first_arrival_time }}</b>
              </h4>
              <h4 class="small">Até: {{ schedule.last_exit_time }}</h4>
              <h4 class="small">
                Concretagem:
                {{ schedule.concreting_time }}
              </h4>
            </div>
            <div v-if="!schedule.next_travel" class="border-left border-primary border-3 p-2 mt-0">
              <h4 class="small">Tempo de concretagem</h4>
              <h4 class="small">Total: test</h4>
              <h4 class="small">De: tst</h4>
              <h4 class="small">Até: tst</h4>
            </div>
            <div class="row m-0">
              <div class="col-3"/>
              <div class="col-6 d-flex justify-content-center flex-column align-items-center">
                <h3 class="text-success">{{ schedule.volume_performed }} m<sup>3</sup> ({{ schedule.travel_performed }})</h3>
                <h4 class="text-default">
                  {{ schedule.total_volume }} m<sup>3</sup>
                  {{ "(" + schedule.total_travels + ")" }}
                </h4>
              </div>
              <div class="col-3 pl-4 d-flex pr-0 justify-content-center">
                <a role="button" @click.prevent="handleModalResume(schedule.id)" >
                  <i class="fas fa-file fa-3x text-info"></i>
                </a>
              </div>
            </div>
            <div>
              <h3>
                {{ schedule.concreted_piece.description }}
                <small v-if="schedule.piece_identification">({{ schedule.piece_identification }}) </small>
              </h3>
            </div>
            <!-- Orientations -->
            <div v-if="schedule.orientations" class="border-left border-3 border-primary p-2">
              <h5 class="mb-0 font-weight-normal">
                {{ schedule.orientations }}
              </h5>
            </div>

            <!-- Collapse Formulation -->
            <collapse class="border rounded p-0 mt-3">
              <collapse-item name="1" class="header-gray" back-ground="border-header-primary-bottom">
                <h5 slot="title" class="mb-0">Formulação</h5>
                <div>
                  <div class="border-left border-3 border-primary p-2">
                    <h5 class="h4 mb-0">
                      {{ schedule.contract_proposal_formulation.title }}
                    </h5>
                    <div class="mt-2 mb-3">
                      <h5 class="small">
                        {{ schedule.contract_proposal_formulation.body }}
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  class="mt-2 border-left border-3 border-indigo p-2"
                  v-if="
                    schedule.contract_proposal_formulation.puzl_additions && schedule.contract_proposal_formulation.puzl_additions.length
                  "
                >
                  <div v-for="(puzl_addition, index) in schedule.contract_proposal_formulation.puzl_additions" :key="puzl_addition.id">
                    <h5 class="text-indigo mb-0">
                      {{ puzl_addition.name }}
                      ({{ puzl_addition.volume }} kg/m<sup>3</sup>)
                      <span class="text-indigo" v-if="index != schedule.contract_proposal_formulation.puzl_additions.length - 1">+</span>
                    </h5>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <!-- Collapse Services -->
            <collapse v-if="schedule.services.length" class="border rounded p-0 mt-3">
              <collapse-item name="1" class="header-gray" back-ground="border-header-dark-bottom">
                <h5 slot="title" class="mb-0">Serviços</h5>
                <!-- Collapse Item Service -->
                <div v-for="(service, indexService) in schedule.services" :key="indexService" class="mb-2">
                  <div class="border-left border-primary border-3 d-flex justify-content-between">
                    <div class="col-10 pr-0 pl-2 h-100">
                      <h5 class="h4 mb-1" :class="'text-' + getServiceColor(service.status)">
                        {{ getServiceText(service.status) }}
                      </h5>
                      <h5 slot="title" class="mb-1 mt-2">
                        {{ service.company_plant }}
                      </h5>
                      <h5 slot="title" class="mb-0 mt-2">
                        {{ service.service_name }}
                      </h5>
                      <h4 class="mb-1" v-if="service.service_description">
                        {{ service.service_description }}
                      </h4>
                      <!-- Volume realizado do serviço -->
                      <span v-if="service.volume_performed" class="h3 small mt-1 text-success">
                        {{ service.volume_performed + " m" }}
                        <sup>3</sup> /
                      </span>
                      <!-- Volume -->
                      <span class="h3 small">
                        {{ service.volume ? service.volume + " m" : "" }}
                        <sup v-if="service.volume">3</sup>
                      </span>
                      <br v-if="service.volume" />
                      <!-- Período -->
                      <span class="h3 small mt-4 mb-2">
                        <!-- De -->
                        {{ service.start_at ? "De: " + service.start_at : "" }}
                        <br v-if="service.start_at" />
                        <!-- Até -->
                        {{ service.end_at ? "Até: " + service.end_at : "" }}
                      </span>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                      <div class="d-inline">
                        <a
                          v-if="schedule.status.status === 2 || schedule.status.status === 3"
                          href="#"
                          @click.prevent="handleScheduleService(service, schedule.company_plants, schedule.uuid)"
                          ><i class="fa fa-edit text-warning pl-1"></i
                        ></a>
                        <br />
                        <a v-if="service.code" href="#" @click.prevent="getReport(service.order_uuid)"
                          ><i class="far fa-2x fa-file-pdf text-danger mt-5"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <!-- Motivo do cancelamento -->
                  <div v-if="service.status === 3" class="border-left mt-3 border-danger border-3 d-flex justify-content-between">
                    <h5 class="h4 pl-2 mb-1">{{ service.cancellation_reason }}</h5>
                  </div>

                  <div class="d-flex justify-content-center mt-2 w-100">
                    <div>
                      <base-button
                        size="sm"
                        type="success"
                        class="text-uppercase pl-3 pr-3 m-2 text-center d-flex"
                        disabled
                        @click.prevent="handleSchedule(schedule, service)"
                      >
                        <i class="fa fa-clock m-0"></i>
                        <br />
                        <br />
                      </base-button>
                    </div>
                    <div>
                      <base-button
                        v-if="!service.code"
                        @click.prevent="createServiceOrder(service)"
                        size="sm"
                        disabled
                        type="primary"
                        class="text-uppercase pl-3 pr-3 m-2 d-flex text-center align-middle"
                      >
                        <div class="mt-2 mb-2">o. s.</div>
                      </base-button>
                      <base-button
                        v-if="service.code"
                        size="sm"
                        :type="getServiceOrderColor(service.order_status)"
                        class="text-uppercase pl-3 pr-3 m-2"
                      >
                        o. s. <br />
                        {{ service.code }}
                      </base-button>
                    </div>
                    <div>
                      <base-button
                        v-if="!service.code"
                        size="sm"
                        type="danger"
                        :disabled="schedule.status.status !== 2"
                        @click.prevent="deleteService(service.uuid)"
                        class="text-uppercase pl-3 pr-3 m-2 text-center d-flex"
                      >
                        <i class="fa fa-trash text-white m-0"></i>
                        <br />
                        <br />
                      </base-button>
                      <base-button
                        v-if="service.code && service.order_status !== 2"
                        size="sm"
                        type="danger"
                        :disabled="schedule.status.status !== 2"
                        @click.prevent="cancelServiceOrder(service.uuid)"
                        class="text-uppercase pl-3 pr-3 m-2 text-center d-flex"
                      >
                        <i class="fa fa-ban text-white m-0"></i>
                        <br />
                        <br />
                      </base-button>
                    </div>
                  </div>
                </div>
              </collapse-item>
            </collapse>
            <!-- Pendências -->
            <collapse class="border rounded p-0 mt-3" v-if="schedule.status.pendencies.length">
              <collapse-item name="1" class="header-gray" back-ground="border-header-warning-bottom">
                <h5 slot="title" class="mb-0">Pendências</h5>
                <div class="border-left border-warning border-3 d-flex justify-content-between">
                  <div class="col-10 pr-0 pl-2 h-100">
                    <h5 v-for="(pendencie, index) in schedule.status.pendencies" :key="index" class="mb-1">
                      {{ pendencie }}
                    </h5>
                  </div>
                </div>
              </collapse-item>
            </collapse>
          </card>
        </div>
      </div>
      <!-- End Card ContractProposal -->
      <LoadingPagination :show="loading && !loadingSkeleton" />
    </div>
    <ModalEditService :bySeller="true" @updatedService="handleChangeService" ref="modalScheduleService" />
    <ModalScheduleListService @addedService="handleChangeService" ref="modalScheduleListService" />
    <ModalServiceCycle @updatedCycle="handleChangeService" ref="modalServiceCycle" />
    <ModalScheduleHistory ref="modalScheduleHistorie" />
    <ModalServiceCancellation @updatedService="handleChangeService" ref="modalServiceCancellation" />
    <ModalResume ref="modalResume" />
  </div>
</template>
<script>
import { period_types } from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEditService from "../../Service/_Edit.vue";
import ModalScheduleListService from "../../Shared/_ModalScheduleListService";
import ModalScheduleHistory from "../../Shared/_ModalScheduleHistory";
import ModalServiceCancellation from "../../Service/_ModalServiceCancellation";
import ModalServiceCycle from "../../Service/_ModalServiceCycle.vue";
import { mapGetters } from "vuex";
import MultiFilter from "@/components/Utils/MultiFilterV2";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import PuzlEmptyData from "@/components/PuzlEmptyData";
import cursorPaginate from "@/mixins/cursorPaginate";
import LoadingPagination from "@/components/LoadingPagination";
import InputDatePicker from "@/components/InputDatePicker";
import ModalResume from "./_ModalResume";
import SkeletonPuzl from "@/components/SkeletonPuzl";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import { AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../../components/AppGlobal";
import ContractProposalPageHeader from "../../../../Commercial/ContractProposal/components/ContractProposalPageHeader/ContractProposalPageHeader.vue";


export default {
  name: "ListSchedule",
  mixins: [cursorPaginate],
  components: {
    ModalEditService,
    ModalServiceCycle,
    SkeletonPuzlGrid,
    PuzlEmptyData,
    MultiFilter,
    ModalServiceCancellation,
    ModalScheduleHistory,
    ModalScheduleListService,
    LoadingPagination,
    InputDatePicker,
    ModalResume,
    SkeletonPuzl,
    ContractProposalNavigation,
    ContractProposalPageHeader,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  data() {
    return {
      format_mixe: period_types,
      loadingSkeleton: true,
      loadingContractProposal: true,
      filter: {
        status: null,
      },
      schedule_length: null,
      multi_filter: {
        null: {
          name: "Todos",
          type: "primary",
        },
        1: {
          name: "Aguardando liberação",
          type: "success",
        },
        0: {
          name: "Cancelado",
          type: "danger",
        },
        13: {
          name: "Liberado",
          type: "success-light",
        },
        15: {
          name: "Não realizado",
          type: "danger",
        },
        1: {
          name: "Reagendado",
          type: "danger",
        },
      },
      range: {
        start: new Date(),
        end: new Date(),
        default_filterable: false,
      },
      colors: ["", "success-light", "primary", "warning", "success"],
      schedule_status: {
        0 : 'canceled',
        1 : 'rescheduled',
        2 : 'seller_request',
        3 : 'not_confirmed',
        8 : 'interrupted',
        9 : 'charging',
        10 : 'in_progress',
        11 : 'completed',
        13 : 'released',
        14 : 'unknown',
        15 : 'not_performed',
        16 : 'pending',
      },
    };
  },
  computed: {
    ...mapGetters({
      schedules: "schedule/fetch",
      $_contract_proposal : "contractProposal/show",
    }),
  },
  methods: {
    handleScheduleService(service, plants, schedule_uuid) {
      service.schedule_uuid = schedule_uuid;
      this.$refs.modalScheduleService.handleCreate(service, plants);
    },
    editions(status) {
      let forbidden = [1, 9, 10, 11, 15];
      return forbidden.includes(status) ? false : true;
    },
    handleSchedule(schedule, service) {
      this.$refs.modalServiceCycle.handleCreate(schedule, service);
    },
    handleHistorie(uuid) {
      this.$refs.modalScheduleHistorie.openModal(uuid);
    },
    handleFilterAdvancedStatus(status) {
      this.loadingSkeleton = true;
      this.paginate = {
        hasMore: false,
        nextUrl: null,
      };
      this.filter.status = status;
      this.init();
    },
    init(filter = null) {
      this.startCursor(filter);
      this.$store
        .dispatch("schedule/getAllByContractProposal", {
          filter: this.filter,
          next_page: this.paginate.nextUrl,
          contract_proposal_uuid: this.$route.params.contract_proposal_uuid,
        })
        .then((response) => {
          this.loadingSkeleton = false;
          this.resolveCursor(response);
        })
        .catch((error) => {
          this.resolveCursor();
          this.loadingSkeleton = false;
        });
    },
    handleFilterDate(filter) {
      this.range = filter;
      this.init({});
    },
    clearFilter() {
      this.loadingSkeleton = true;

      this.$refs['date-picker'].resetDate();
      this.range = {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
      };
      this.filter = {
        range: this.range
      };
      this.init();
    },
    deleteService(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("scheduleService/destroy", uuid)
              .then((response) => {
                this.$notify({ type: response.error_type, message: response.message });
                this.$Progress.finish();
                this.handleChangeService(response.data);
              })
              .catch((error) => {
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    cancelServiceOrder(uuid) {
      this.$swal
        .fire({
          title: "Confirmar cancelamento da O.S?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$refs.modalServiceCancellation.handleCreate(uuid);
          }
        });
    },
    handleChangeService(params) {
      this.$store.dispatch("schedule/forceUpdate", params);
    },
    createServiceOrder(service) {
      this.$swal
        .fire({
          title: "Confirmar emissão da O.S?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            let loader = this.$loading.show();
            let service_order = {
              service_uuid: service.uuid,
              company_plant_uuid: service.company_plant_uuid,
              status: 0,
            };

            this.$store
              .dispatch("scheduleService/addServiceOrder", service_order)
              .then((response) => {
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
                let service_order_uuid = response.data.uuid;
                this.$store
                  .dispatch("scheduleService/generateReport", service_order_uuid)
                  .then((response) => {
                    let blob = new Blob([response], {
                      type: "application/pdf",
                    });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    window.open(link, "_blank");
                    this.init();
                    loader.hide();
                  })
                  .catch((error) => {
                    loader.hide();
                  });
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                  loader.hide();
                }
              });
          }
        });
    },
    getReport(service_order_uuid) {
      let loader = this.$loading.show();
      this.$store
        .dispatch("scheduleService/generateReport", service_order_uuid)
        .then((response) => {
          let blob = new Blob([response], {
            type: "application/pdf",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          window.open(link, "_blank");
          loader.hide();
        })
        .catch((error) => {
          loader.hide();
        });
    },
    handleModalListService(contract_proposal_uuid, schedule_uuid, company_plants, params) {
      this.$refs.modalScheduleListService.openModal(contract_proposal_uuid, schedule_uuid, company_plants, params);
    },
    handleDeleteProgramming(uuid) {
      this.$Swal
        .confirmDelete()
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.$notify({
              type: "info",
              message: "Estamos trabalhando em sua solicitação.",
            });
            this.$store
              .dispatch("schedule/destroy", uuid)
              .then((response) => {
                this.$Progress.finish();
                this.$notify({
                  type: response.error_type,
                  message: response.message,
                });
                this.init();
              })
              .catch((error) => {
                if (error.status == 200) {
                  this.$notify({
                    type: "danger",
                    message: error.data.message,
                  });
                  this.$Progress.finish();
                  this.init();
                }
                this.$Progress.finish();
              });
          }
        })
        .catch(() => this.$Progress.finish());
    },
    getColor(status) {
      let color = "";
      switch (status) {
        case 0:
        case 1:
        case 3:
        case 15:
          color = "danger";
          break;
        case 16:
          color = "warning";
          break;
        case 8:
          color = "warning-strength";
          break;
        case 10:
          color = "default";
          break;
        case 11:
          color = "primary";
          break;
        case 13:
          color = "success-light";
          break;
        case 2:
          color = "light";
          break;
      }
      return color;
    },
    getServiceColor(status) {
      switch (status) {
        case 0:
          return "success-light";
        case 1:
          return "success";
        case 2:
        case 3:
          return "danger";
        case 4:
          return "primary";
        case 5:
          return "light";
      }
    },
    getServiceText(status) {
      switch (status) {
        case 0:
          return "Liberado";
        case 1:
          return "Concluído";
        case 2:
          return "Não realizado";
        case 3:
          return "Cancelado";
        case 4:
          return "Em andamento";
        case 5:
          return "Solicitação do vendedor";
      }
    },
    getServiceOrderColor(status) {
      switch (status) {
        case 0:
          return "primary";
        case 1:
          return "success";
        case 2:
          return "danger";
      }
    },
    handleModalResume(id) {
      this.$refs.modalResume.openModal(id);
    },
  },
  mounted() {
    this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then((response) => {
      this.loadingContractProposal = false;
    });
    this.init();
    this.$refs.modalScheduleListService.$refs.modalAddService.bySeller = true
  },
};
</script>

<style scoped>
.text-success-light {
  color: #68b879 !important;
}

.card-border-top-success-light {
  border-top: 8px solid #8edb9f;
}

.text-warning-strength {
  color: #68b879 !important;
}

.card-border-top-warning-strength {
  border-top: 8px solid #8edb9f;
}

.card-border-top-default {
  border-top: 8px solid #172b4d;
}

.main-icon-menu {
  min-width: 18px;
}

.text-light {
  color: #8e8e8f !important;
}

.rounded-circle {
  border: 1px solid #D3D3D3;
}

.background-color-circle {
  background-color: #f3f4fa;
}

.icon:hover {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  transform: scale(1.1);
  background-color: #D3D3D3;
}

.base-button:hover .invert-on-hover {
  filter: brightness(0) invert(1);
}

.base-button:hover .text-dark {
  color: white !important;
}
</style>
