import { BaseListFilterType, initBaseListFilterType } from "../../../../shared/types";

/**
 * Type para filtro de listagem (Extensão)
 *
 * @typedef {Object} ServiceListFilterExtType
 * @property {boolean|null} status
 */

/**
 * Type para filtro de listagem
 *
 * @typedef {(BaseListFilterType & ServiceListFilterExtType)} ServiceListFilterType
 */
export const ServiceListFilterType = {};

/**
 * Inicializar ServiceListFilterType
 *
 * @returns {ServiceListFilterType}
 */
export const initServiceListFilterType = () => {
  return {
    ...initBaseListFilterType(),
    status: null,
  };
}