<template>
  <div>
    <AppFixedPageTitle
      title="Serviços"
      icon="/img/icons/icons8/ios/svgs/todo-list.svg"
    />
    <div class="container-fluid py-4">
      <!-- Header Menus -->
      <div v-if="loading">
        <div v-for="item in 3">
          <div class="col-3 ml-n4">
            <SkeletonPuzl type="button"/>
          </div>
        </div>
      </div>
      <ContractProposalPageHeader
        :contractInfo="contract_proposal"
      >
        <AppPageHeaderActions>
          <AppPageHeaderActionsButton
            type="light"
            @click.prevent="$router.go(-1)"
            text="Voltar"
            icon="/img/icons/circled-left.png"
            class="invert-color"
            >
          </AppPageHeaderActionsButton>
        </AppPageHeaderActions>
      </ContractProposalPageHeader>
    
     
      <div :class="contract_proposal.entity ? '' : 'd-flex justify-content-center'">
        <ContractProposalNavigation
          v-if="contract_proposal.uuid"
          :contractStatus="contract_proposal.status"
          :contractUuid="contract_proposal.uuid"
          :entity="contract_proposal.entity"
          :activeServices="true"
          style="margin-top: 12px;"
        />
      </div>
    </div>
    <div>
      <div class="container-fluid">
        <card>
          <div>
            <div class="form-group row mb-1">
              <div class="col-12 text-right" style="display: inline-flex;align-items: center; justify-content: end;">
                <label class="form-control-label m-0 mr-2">
                  Central
                </label>
                <validation-provider rules="required">
                  <base-input input-classes="form-control-sm">
                    <puzl-select
                      style="min-width: 250px;"
                      v-model="filter.plant_id"
                      :items="plants"
                       @input="searchServices()"
                      :loading="loadingPlant"
                      :disabled="loadingPlant" />
                  </base-input>
                </validation-provider>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-12">
                <div class="row card-wrapper" v-show="loadingService">
                  <SkeletonPuzlGrid v-for="(index) in 3" v-show="loadingService" :key="index"></SkeletonPuzlGrid>
                </div>
                <div v-if="services && !loadingService" v-for="(service, indexService) in services.data" :key="indexService">
                  <div class="card-with-box-shadow mt-2">
                    <div class="row align-items-center">
                      <div class="col-6">
                        <h4 class="new-default-black-font mb-0 pl-3 pt-3" v-if="service.service">
                          {{ service.service.service_name }}
                        </h4>
                        <h5 class="new-default-gray-font pl-3 pb-3 font-weight-normal">
                          {{ service.description.description }}
                        </h5>
                      </div>
                      <div class="col-6 text-right">
                        <div class="row">
                          <div class="col-12 pt-3">
                            <el-popover trigger="click" placement="bottom" class="p-0">
                              <span style="width: 200px;" class="row">
                                <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                  <img class="mr-2" src="/img/icons/price-tag-usd-green.png" width="22">
                                  <h4 style="font-size: 16px;" class="p-0 m-0">Preços</h4>
                                </div>
                                <div class="col-12 mt-1">
                                  <h5 class="font-weight-normal" v-if="service.price_fixed">
                                    <b style="font-size: 12px;">Mínimo:</b> {{ service.price_fixed | currency() }}
                                  </h5>
                                  <h5 class="font-weight-normal" v-if="service.by_meters">
                                    <b style="font-size: 12px;">Por M³:</b> {{ service.by_meters | currency() }} /m<sup>3</sup>
                                  </h5>
                                  <h5 class="font-weight-normal" v-if="service.by_hour">
                                    <b style="font-size: 12px;">Por hora:</b> {{ service.by_hour | currency() }} /hora
                                  </h5>
                                </div>  
                              </span>
                              <base-button outline slot="reference" size="sm" type="secundary"
                                           class="p-0 ml-2 shadow-none text-indigo">
                                <img src="/img/icons/price-tag-usd-green.png" width="26">
                              </base-button>
                            </el-popover>
                            <el-popover trigger="click" placement="bottom" class="p-0 pr-3">
                              <span style="width: 150px;" class="row">
                                <div class="col-12 mb-2" style="display: flex; align-items: center;">
                                  <img class="mr-2" src="/img/icons/chemical-plant-black.png" width="22">
                                  <h4 style="font-size: 16px;" class="p-0 m-0">Central</h4>
                                </div>
                                <div class="col-12 mt-1">
                                  <h5 class="font-weight-normal">{{ getPlantName(filter.plant_id) }}</h5>
                                </div>  
                              </span>
                              <base-button outline slot="reference" size="sm" type="secundary"
                                           class="p-0 ml-2 shadow-none text-indigo">
                                <img src="/img/icons/chemical-plant-black.png" width="26">
                              </base-button>
                            </el-popover>
                          </div>
                          <div class="col-12 mt-3">
                            <div @click.prevent="handleNewService(service)"
                              class="card-blue-bottom text-center pointer">
                              <a style="height: 35px; display: inline-block;"
                                href="#">
                                <h3 style="text-transform: none;padding: 5px 3px 5px 5px;">
                                  <span class="mr-1">
                                    <img src="/img/icons/icons8/ios/right-squared.png" width="20px">
                                  </span>
                                  <small class="mr-1 text-white" style="font-size: 12px">
                                    CONTINUAR
                                  </small>
                                </h3>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <puzl-empty-data :with-card="false" v-if="!services.data && !loadingService"/>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <ModalNewService ref="newService"/>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {
  period_types
} from "@/views/Modules/Technology/Mix/helpers/mixe";
import SearchBarPuzl from "@/components/SearchBarPuzl";
import ModalNewService from './_NewService';
import PuzlSelect from "@/components/PuzlSelect";
import PuzlEmptyData from "../../../../../../components/PuzlEmptyData";
import ContractProposalNavigation from "@/components/ContractProposalNavigation";
import SkeletonPuzlGrid from "@/components/SkeletonPuzlGrid";
import ContractProposalPageHeader from '../../components/ContractProposalPageHeader/ContractProposalPageHeader.vue'
import { AppFixedPageTitle, AppPageHeaderActions, AppIcon, AppPageHeaderActionsButton } from "../../../../../../components/AppGlobal";

export default {
  name: 'CreateContractProposalServices',
  components: {
    PuzlEmptyData,
    ModalNewService,
    SearchBarPuzl,
    PuzlSelect,
    ContractProposalNavigation,
    SkeletonPuzlGrid,
    ContractProposalPageHeader,
    AppFixedPageTitle,
    AppPageHeaderActions,
    AppPageHeaderActionsButton,
    AppIcon,
  },
  data() {
    return {
      format_mixe: period_types,
      service: {},
      loadingPlant: true,
      loadingSave: false,
      loadingService: true,
      invalid: false,
      plant: {},
      loadingConcretePiece: true,
      formulation: {
        concreted_piece_ids: null,
      },
      filter: {
        plant_id: null
      },
      active: null,
      filters: [],
      services: []
    }
  },
  computed: {
    ...mapGetters({
      'concrete_pieces': 'mixConcretePiece/activeItems',
      'plants': 'plant/activeItems',
      contract_proposal: "contractProposal/show"
    }),
  },
  methods: {
    getPlantName(id){
      const plant = this.plants.find(item => item.id == this.filter.plant_id);
      return plant.name;
    },
    handleNewService(service) {
      service.plant = this.plants.find(item => item.id == this.filter.plant_id)
      this.$refs.newService.openModal(service)
    },
    searchServices() {
      const loader = this.$loading.show()
      let params = {
        filter: this.filter,
        no_paginate:true,
      }
      this.loadingService = true
      this.$Progress.start();
      this.$axios.get("/commercial/service/price", {params: params})
        .then(({data}) => {
          this.services = data
          this.loadingService = false
          this.$Progress.finish();
          loader.hide()
        })
        .catch((error) => {
          loader.hide()
          this.$Progress.finish();
        });
    },
    changeState() {
      this.active = !this.active;
    },
    getData() {
      this.searchServices()
    },
  },
  mounted() {
    this.$store.dispatch('mixConcretePiece/fetchItems').then(() => {
      this.loadingConcretePiece = false
    })
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
    this.$store
      .dispatch(
        "contractProposal/show",
        this.$route.params.contract_proposal_uuid
      )
      .then(() => {
        this.filter.plant_id = this.contract_proposal.company_plant_id
        this.getData()
      });
  }
}
</script>
<style scoped>
.card-with-box-shadow{
  box-shadow: 0 2px 4px 0 #f3f3f3;
  border: 1px solid #E8E8E8;
  box-shadow: 0 2px 5px 0 rgba(12, 25, 40, 0.15);
  border-radius: 15px !important;
}

.card-blue-bottom {
  border-bottom-right-radius: 15px;
}

.new-default-gray-font {
  color: #606062;
}
</style>