<template>
  <div>
    <div class="container-fluid mt-4">
      <div
        class="row mt-3"
      >
        <div
          class="col-lg-6"
        >
          <div
            class="card py-3 px-2"
          >
            <div
              class="container"
            >
              <div
                class="row align-items-center"
              >
                <div
                  class="col-auto"
                >
                  <div style="display: flex; align-items: center;">
                    <img
                      src="/img/icons/icons8/ios/test-tube_primary.png"
                      width="20"
                    />
                    <h3
                      class="ml-2 m-0 new-default-black-font"
                    >
                      Concreto
                    </h3>
                  </div>
                </div>
                <div
                  class="col"
                >
                  <hr class="gray-divider">
                </div>
              </div>
              <div class="row my-3 align-items-center">
                <div class="col-12">
                  <div class="mini-section-blue py-3 px-2">
                    <div class="container">
                      <div class="row align-items-center">
                        <div class="col-6">
                          <h4 class="m-0 new-default-black-font">
                            Traço: {{ selected ? selected.code : '' }}
                          </h4>
                        </div>
                        <!-- ESCONDER NA EDIÇÃO -->
                        <div
                          class="col-6 text-right"
                          v-if="!isEditing"
                        >
                          <base-button
                            @click.prevent="addMixe"
                            :disabled="Number(loading) > 0"
                            outline
                            slot="reference"
                            size="sm"
                            type="secundary"
                            class="p-0 m-0 shadow-none mini-section-button warning"
                          >
                            <img src="/img/icons/create-new.png" width="16">
                            <h4 class="m-0">Editar</h4>
                          </base-button>
                        </div>
                      </div>
                      <div
                        class="row mb-4"
                        v-if="selected"
                      >
                        <div class="col-12">
                          <h4
                            v-for="feature in selected.features" :key="feature.id"
                            class="m-0 new-default-black-font font-weight-400"
                          >
                            <template v-if="selected.version">
                              {{ feature.feature.short_description }}
                              {{ format_mixe(feature.feature.period, feature.feature.period_type) }}
                              {{ feature.description }}
                              {{ feature.letter }}
                            </template>
                            <template v-else>
                              {{ feature.short_description }}
                              {{ format_mixe(feature.period, feature.period_type) }}
                              {{ feature.item_description }}
                              {{ feature.letter }}
                            </template>
                          </h4>
                          <h4
                            class="m-0 new-default-black-font font-weight-400"
                          >
                            {{ selected.type.technical_description }}
                            {{ selected.subtype ? " | " + selected.subtype.technical_description : "" }}
                            {{ selected.classification ? " | " + selected.classification.technical_description : "" }}
                            |
                            {{ selected.slump_flow.nomenclature }}
                            |
                            {{ selected.maximum_size.description }}
                          </h4>
                          <span class="p small">
                            {{ selected.footer }}
                          </span>
                          <h5 class="m-0 new-default-gray-font font-weight-400">{{ selected.plant && selected.plant.name || '-' }}</h5>
                        </div>
                      </div>
                      <!-- ESCONDER NA EDIÇÃO -->
                      <div class="row align-items-center"
                           v-if="!isEditing && getSlumpFlow().length">
                        <div class="col-6 col-lg-8">
                          <h4 class="m-0 new-default-black-font font-weight-400">
                            Slump Final <span class="text-danger">*</span>
                          </h4>
                        </div>
                        <div
                          v-if="getSlumpFlow().length"
                          class="col-6 col-lg-4"
                        >
                          <PuzlSelect
                            style="width: 100%"
                            clearable
                            @change="setSlump"
                            label="nomenclature"
                            v-model="formulation.minimum_price.slump_flow_id"
                            :items="getSlumpFlow()"
                            :disableBoxShadow="true"
                            placeholder="Nenhum"
                            class="select-sm white-bg-021020241711 col-md-12 px-0 new-default-black-font"
                            :class="!formulation.minimum_price.slump_flow_id ? 'border-danger-091020241905' : ''"
                            :loading="loadingSlumpFlow"
                          />
                        </div>
                      </div>
                      <!-- ESCONDER NA EDIÇÃO -->
                      <div
                        class="row"
                        v-if="selected && $_general_settings && $_general_settings.show_coarse_aggregate">
                        <div
                          class="col-12"
                          v-if="selected.content_groups"
                        >
                          <div style="display: flex;align-items: center;">
                            <img src="/img/icons/icons8/ios/wheelbarrow_dark.png" width="15">
                            <h5 class="ml-2 m-0 new-default-black-font font-weight-400">
                              <template
                                v-for="(group, index) in selected.content_groups">
                                {{
                                  group.name
                                }}
                                ({{
                                  group.percentage
                                }})
                                {{
                                  selected.content_groups[index + 1]
                                    ? "+"
                                    : ""
                                }}
                              </template>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row align-items-center"
              >
                <div
                  class="col-6"
                >
                  <h3
                    class="m-0 new-default-black-font"
                  >
                    Total
                  </h3>
                </div>
                <div
                  class="col-6 text-right"
                >
                  <h3
                    class="price-display new-default-black-font"
                  >
                    <p class="currency-symbol">R$</p>
                    {{
                      selected ?
                        minimum_price.toLocaleString("pt-br", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })
                        : "0,00"
                    }}
                    /
                    <p class="unit-symbol">
                      {{ selected && selected.price
                      ? selected.price.unit_letter
                      : 'm³'
                      }}
                    </p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card py-3 px-2"
          >
            <div
              class="container"
            >
              <div
                class="row align-items-center"
              >
                <div
                  class="col-auto"
                >
                  <div style="display: flex; align-items: center;">
                    <img
                      src="/img/brand/logo.png"
                      width="20"
                    />
                    <h3
                      class="ml-2 m-0 new-default-black-font"
                    >
                      Puzl Admix
                    </h3>
                  </div>
                </div>
                <div
                  class="col"
                >
                  <hr class="gray-divider">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-6 offset-6 text-right">
                  <base-button
                    @click.prevent="addPuzlAdmixture"
                    :disabled="!selected || isEditing || (selected && selected.puzl_additions.length < 1)"
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 shadow-none mini-section-button success"
                  >
                    <img src="/img/icons/icons8/ios/add-success.png" width="16">
                    <h4 class="m-0">Incluir</h4>
                  </base-button>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="selected && puzl_additions.length"
                v-for="(addition, indexAddition) in puzl_additions"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{ addition && addition.name || '-' }}
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    R$ {{ addition.volume }} Kg/<b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <hr class="gray-divider">
                </div>
              </div>
              <div
                class="row align-items-center"
              >
                <div
                  class="col-6"
                >
                  <h3
                    class="m-0 new-default-black-font"
                  >
                    Total
                  </h3>
                </div>
                <div
                  class="col-6 text-right"
                >
                  <h3
                    class="price-display new-default-black-font"
                    v-if="puzl_addition_price"
                  >
                    <p class="currency-symbol">R$</p>
                    {{
                      puzl_addition_price.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}
                    /
                    <p class="unit-symbol">m<sup>3</sup></p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card py-3 px-2"
          >
            <div
              class="container"
            >
              <div
                class="row align-items-center"
              >
                <div
                  class="col-auto"
                >
                  <div style="display: flex; align-items: center;">
                    <img
                      src="/img/icons/icons8/ios/clock_warning.png"
                      width="20"
                    />
                    <h3
                      class="ml-2 m-0 new-default-black-font"
                    >
                      Custos variáveis
                    </h3>
                  </div>
                </div>
                <div
                  class="col"
                >
                  <hr class="gray-divider">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 text-right">
                  <base-button
                    :disabled="!selected || loadingCycle || Number(loading) > 0"
                    @click.prevent="editCycle"
                    outline
                    slot="reference"
                    size="sm"
                    type="secundary"
                    class="p-0 m-0 shadow-none mini-section-button warning"
                  >
                    <img src="/img/icons/create-new.png" width="16">
                    <h4 class="m-0">Editar Ciclo</h4>
                  </base-button>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="cycleMaximumDiff.contains"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">CICLO ({{ cycleMaximumDiff.diff }} MINUTOS)</h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{
                      cycleMaximumDiff.value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                      })
                    }}
                    / <b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="transportMaximumDiff.contains"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    DISTÂNCIA
                    ({{
                      parseFloat(transportMaximumDiff.diff).toFixed(2).toString().replace(".", ",")
                    }} Km)
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{
                      transportMaximumDiff.value.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL"
                      })
                    }}
                    / <b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="concreted_piece && concreted_piece.mix_concreted_piece && concreted_piece.percentage > 0"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    PEÇA A CONCRETAR -
                    {{ concreted_piece.mix_concreted_piece.description }}
                    ({{ concreted_piece.percentage }}%)
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{
                      parseFloat(concretedPieceDiff.value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 2
                      })
                    }}
                    / <b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="Number(slumpDiff.percentage) !== 0 && slump_flow_selected"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    SLUMP FINAL -
                    {{ slump_flow_selected.nomenclature }}
                    ({{ slump_flow_selected.percentage.percentage }}%)
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{
                      parseFloat(slumpDiff.value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 2
                      })
                    }}
                    / <b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div
                class="row mt-1"
                v-if="Number(featureLevelDiff.percentage) !== 0"
              >
                <div class="col-8">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    Nível de aplicação -
                    {{ feature_level_selected.label }}
                    ({{ feature_level_selected.percentage }}%)
                  </h4>
                </div>
                <div class="col-4 text-right">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    {{
                      parseFloat(featureLevelDiff.value).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                        maximumFractionDigits: 2
                      })
                    }}
                    / <b class="font-weight-400" style="text-transform: lowercase;">m</b><sup>3</sup>
                  </h4>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <hr class="gray-divider">
                </div>
              </div>
              <div
                class="row align-items-center"
              >
                <div
                  class="col-6"
                >
                  <h3
                    class="m-0 new-default-black-font"
                  >
                    Total
                  </h3>
                </div>
                <div
                  class="col-6 text-right"
                >
                  <h3 class="price-display new-default-black-font">
                    <p class="currency-symbol">R$</p>
                    {{
                      getVariableCost().toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })
                    }}/
                    <p class="unit-symbol">m<sup>3</sup></p>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div
            class="card py-3 px-2"
          >
            <div
              class="container"
            >
              <div
                class="row align-items-center"
              >
                <div
                  class="col-auto"
                >
                  <div style="display: flex; align-items: center;">
                    <img
                      src="/img/icons/icons8/ios/sell-stock_success.png"
                      width="20"
                    />
                    <h3
                      class="ml-2 m-0 new-default-black-font"
                    >
                      Venda
                    </h3>
                  </div>
                </div>
                <div
                  class="col"
                >
                  <hr class="gray-divider">
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="mini-section-gray py-3 px-2">
                    <div class="container">
                      <div
                        class="row align-items-center"
                      >
                        <div
                          class="col-6"
                        >
                          <h3
                            class="m-0 new-default-black-font"
                          >
                            PREÇO MÍNIMO SUGERIDO
                          </h3>
                        </div>
                        <div
                          class="col-6 text-right"
                        >
                          <h3 class="price-display new-default-black-font">
                            <p class="currency-symbol">R$</p>
                            {{
                              formulation.minimum_price.minimum_price.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                              })
                            }}/
                            <p class="unit-symbol">m<sup>3</sup></p>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Esconder na edição -->
              <div class="row align-items-center mt-4">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    Data da vigência
                    <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input input-group-classes="input-group-sm inputdatepicker-031020241040">
                      <input-date-time-picker
                        :disabled="true"
                        v-model="formulation.minimum_price.date_at"
                        :mode="'date'"/>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-6">
                  <h4 class="m-0 new-default-black-font font-weight-400">
                    Peça a concretar
                    <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input>
                      <PuzlSelect
                        style="width: 100%"
                        @input="changeConcretedPiece"
                        v-model="formulation.minimum_price.concreted_piece_ids"
                        :items="getConcretePieces()"
                        label="description"
                        :disableBoxShadow="true"
                        placeholder=""
                        :multiple="true"
                        class="select-sm white-bg-021020241711 col-md-12 px-0 new-default-black-font"
                        :class="!formulation.minimum_price.concreted_piece_ids.length ? 'border-danger-091020241905' : ''"
                        :disabled="!selected"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-6">
                  <div style="display: flex; align-items: center;">
                    <h4 class="m-0 mr-2 new-default-black-font font-weight-400">
                      Nível de aplicação
                    </h4>
                    <el-popover
                      v-if="$_application_level_guideline"
                      trigger="hover"
                      placement="top"
                      class="p-0 pr-0"
                    >
                      <div style="width: 310px;word-break: break-word;">
                        <h5 class="font-weight-normal">
                          <b class="h5">{{ $_application_level_guideline.commercial_definition_low }}:</b>
                          {{ $_application_level_guideline.description_low }}
                        </h5>
                        <h5 class="font-weight-normal">
                          <b class="h5">{{ $_application_level_guideline.commercial_definition_medium }}:</b>
                          {{ $_application_level_guideline.description_medium }}
                        </h5>
                        <h5 class="font-weight-normal">
                          <b class="h5">{{ $_application_level_guideline.commercial_definition_high }}:</b>
                          {{ $_application_level_guideline.description_high }}
                        </h5>
                      </div>
                      <img
                        src="/img/icons/info.png"
                        class="pointer"
                        width="16"
                        slot="reference"
                      >
                    </el-popover>
                  </div>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    :rules="$_general_settings.demand_level_responsibility ? 'required' : ''"
                    v-slot="{errors}"
                  >
                    <base-input>
                      <PuzlSelect
                        style="width: 100%"
                        :disabled="!selected || selected.block_level_feature_adjustment"
                        @change="setLevelResponsibility"
                        v-model="formulation.minimum_price.feature_level"
                        :items="feature_levels"
                        customKey="value"
                        label="label"
                        :disableBoxShadow="true"
                        class="select-sm white-bg-021020241711 col-md-12 px-0 new-default-black-font"
                        :class="!formulation.minimum_price.feature_level && $_general_settings.demand_level_responsibility ? 'border-danger-091020241905' : ''"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-6">
                  <h4 class="m-0 mr-2 new-default-black-font font-weight-400">
                    Volume Previsto
                    <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input>
                      <div class="input-custom-group">
                        <input
                          class="form-control form-control-sm"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          v-model="formulation.minimum_price.expected_volume"
                          :class="{'border-danger': formulation.minimum_price.expected_volume === '0,00'}"
                          inputmode="numeric"
                          v-money="volumeMeasurement"
                        >
                        <div>
                          <nav>
                            <p>
                              m
                            </p>
                            <sup>
                              3
                            </sup>
                          </nav>
                        </div>
                      </div>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-6">
                  <div style="display: flex; align-items: center;">
                    <h4 class="m-0 mr-2 new-default-black-font font-weight-400">
                      Preço de venda
                      <span class="text-danger">&nbsp;*</span>
                    </h4>
                    <el-popover
                      v-if="isBiggerPrice == false"
                      trigger="hover"
                      placement="top"
                      class="p-0 pr-0"
                    >
                      <div class="h5" style="width: 290px;word-break: break-word;font-weight: normal !important;">
                        Preço de venda menor do que o sugerido.
                      </div>
                      <base-button
                        slot="reference"
                        class="p-0 m-0 shadow-none"
                        outiline
                        size="sm"
                        type="secundary"
                      >
                        <img
                          src="/img/icons/icons8/ios/error--v1_danger.png"
                          class="pointer"
                          width="16"
                        >
                      </base-button>
                    </el-popover>
                  </div>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input>
                      <div class="input-custom-group">
                        <div>
                          R$
                        </div>
                        <input
                          class="form-control form-control-sm"
                          maxlength="10"
                          inputmode="numeric"
                          v-money="money"
                          @keydown="$event.key === '-' ? $event.preventDefault() : null"
                          :class="{'border-danger': formulation.minimum_price.value === '0,00' && !formulation.minimum_price.delivery_type}"
                          v-model.lazy="formulation.minimum_price.value"
                        >
                        <div>
                          <nav>
                            <p>
                              m
                            </p>
                            <sup>
                              3
                            </sup>
                          </nav>
                        </div>
                      </div>
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-6">
                  <h4 class="m-0 mr-2 new-default-black-font font-weight-400">
                    Tipo de entrega
                    <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-md-6">
                  <validation-provider rules="required">
                    <base-input>
                      <PuzlSelect
                        style="width: 100%"
                        customKey="id"
                        v-model="formulation.minimum_price.delivery_type"
                        :class="!formulation.minimum_price.delivery_type ? 'border-danger-091020241905' : ''"
                        :items="
                          formulation.minimum_price.value == '0,00' ?
                          [
                            {
                              name: 'Doação',
                              id: 2,
                            },
                            {
                              name: 'TRANSFERÊNCIA',
                              id: 3,
                            },
                            {
                              name: 'Uso interno',
                              id: 4,
                            },
                            {
                              name: 'Reparo de concretagem',
                              id: 5,
                            },
                          ] : [
                            {
                              name: 'Venda de concreto',
                              id: 1
                            }
                          ]"
                        :disableBoxShadow="true"
                        class="select-sm white-bg-021020241711 col-md-12 px-0 new-default-black-font"
                      />
                    </base-input>
                  </validation-provider>
                </div>
              </div>
              <div class="row align-items-center mt-3">
                <div class="col-md-9">
                  <h4 class="m-0 mr-2 new-default-black-font font-weight-400">
                    Retira de terceiros
                    <span class="text-danger">&nbsp;*</span>
                  </h4>
                </div>
                <div class="col-md-3">
                  <div
                    class="new-minicard-table"
                    @click.prevent="() => { if (selected) formulation.minimum_price.concrete_removal = !formulation.minimum_price.concrete_removal }"
                    :class="[
                      formulation.minimum_price.concrete_removal ? 'new-minicard-green' : 'new-minicard-red',
                      { 'minicard-disabled': !selected }
                    ]"
                  >
                    <div>
                      <img
                        :src="
                          formulation.minimum_price.concrete_removal
                          ? '/img/icons/icons8/ios/shutdown_success.png'
                          : '/img/icons/icons8/ios/shutdown--v1_danger.png'"
                        width="12"
                      >
                      <h4>
                        {{ formulation.minimum_price.concrete_removal ? 'Ativo' : 'Inativo' }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-6">
                  <h4 class="m-0 mr-2 new-default-black-font font-weight-400">Observações</h4>
                </div>
                <div class="col-md-6">
                  <base-input input-group-classes="input-group-sm">
                    <!-- v-model="note" -->
                    <textarea type="text" rows="4"
                              v-model="formulation.minimum_price.observation"
                              class="form-control form-control-sm white-bg-021020241711" placeholder=" ...">
                    </textarea>
                  </base-input>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="mini-section-green py-3 px-2">
                    <div class="container">
                      <div
                        class="row align-items-center"
                      >
                        <div
                          class="col-6"
                        >
                          <h3
                            class="m-0 new-default-black-font"
                          >
                            VALOR TOTAL
                          </h3>
                        </div>
                        <div
                          class="col-6 text-right"
                        >
                          <h3 class="price-display text-success">
                            <p class="currency-symbol">R$</p>
                            {{ formatNumberToBRL(getTotalPrice) }}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="actions">
            <div class="py-3 px-0">
              <div class="container px-0">
                <div class="row">
                  <div class="col-12 text-right">
                    <div class="actions-section">
                      <base-button
                        outline
                        slot="reference"
                        size="sm"
                        type="secundary"
                        class="p-0 m-0 shadow-none action-custom-button action-custom-button-red"
                        @click.prevent="$router.push({
                          path: '/commercial/contract-proposal/formulation',
                          name: 'commercial.contract-proposal.formulation',
                          params: { contract_proposal_uuid: contract_proposal.uuid }
                        })"
                      >
                        <div>
                          <img src="/img/icons/icons8/ios/cancel_danger.png" width="16">
                          <h4>Cancelar</h4>
                        </div>
                      </base-button>
                      <base-button
                        outline
                        slot="reference"
                        size="sm"
                        type="secundary"
                        class="p-0 m-0 shadow-none action-custom-button action-custom-button-green"
                        native-type="submit"
                        :disabled="
                          loadingSave ||
                          Number(loading) > 0 ||
                          invalid ||
                          !haveCycle ||
                          !formulation.minimum_price.concreted_piece_ids.length ||
                          (getSlumpFlow().length && !formulation.minimum_price.slump_flow_id) ||
                          (!formulation.minimum_price.feature_level && !selected.block_level_feature_adjustment) ||
                          !formulation.minimum_price.delivery_type ||
                          ! formulation.minimum_price.expected_volume
                        "
                        @click.native="handleSaveFormulation"
                      >
                        <div>
                          <img src="/img/icons/icons8/ios/save_success.png" width="16">
                          <h4>Salvar</h4>
                        </div>
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modais -->
    <ModalEditCycle @addCycle="setCycle" ref="editCycle"/>
    <ModalAddMixe @set_mix_design="handleSetMixDesignForCreating" ref="addMixe"/>
    <ModalAddPuzlAdmixture @savePuzlAdditions="savePuzlAdditions" ref="addPuzlAdmixture"/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {debounce, toFixed} from "@/plugins";
import {period_types} from "@/views/Modules/Technology/Mix/helpers/mixe";
import ModalEditCycle from "../_ModalEditCycle";
import ModalAddPuzlAdmixture from "../_ModalAddPuzlAdmixtures";
import ModalAddMixe from "../_ModalAddMixe";
import {formatErrorValidation} from "@/plugins";
import {VMoney} from 'v-money';
import PuzlSelect from '@/components/PuzlSelect.vue';
import InputDateTimePicker from "@/components/InputDateTimePicker";

export default {
  name: "FormulationForm",
  components: {
    ModalEditCycle,
    ModalAddPuzlAdmixture,
    ModalAddMixe,
    PuzlSelect,
    InputDateTimePicker,
  },
  props: {},
  computed: {
    ...mapGetters({
      $_price: "concretePrice/getPriceAdjustment",
      plants: 'plant/activeItems',
      concrete_pieces: "mixConcretePiece/activeItems",
      concreted_piece: "formulations/getConcretedPiece",
      cycle_values: "formulations/getCycleValues",
      contract_proposal: "contractProposal/show",
      feature_levels: 'mixConcretePiece/feature_levels',
      $_general_settings: "generalSetting/show",
      $_application_level_guideline: "applicationLevelGuideline/fetch",
    }),
    getTotalPrice() {
      const volume = (this.formulation.minimum_price.expected_volume || 1).toString().replaceAll('.', '').replace(",", ".")
      const price = (this.formulation.minimum_price.value || 1).toString().replaceAll('.', '').replace(",", ".")
      return (price * volume).toFixed(2)
    }
  },
  data() {
    return {
      format_mixe: period_types,
      loadingPlant: true,
      loadingSave: false,
      loader : false,
      invalid: false,
      once: false,
      isEditing: false,
      loadingConcretePiece: true,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      minimum_price: 0,
      slump_flow_selected: null,
      selected: null,
      volumeMeasurement: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 1,
        masked: false
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      cycleMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      transportMaximumDiff: {
        diff: 0,
        value: 0,
        contains: false
      },
      slumpDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      featureLevelDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      },
      concretedPieceDiff: {
        percentage: null,
        value: 0,
        contains: false,
        name: ''
      },
      cycleMinimum: 0,
      loadingCycle: true,
      haveCycle: false,
      is_confirm_donation: false,
      feature_level_selected: null,
      loading: 4,
      loadingSlumpFlow: true,
      formulation: {
        minimum_price: {
          minimum_price: 0,
          concrete_removal: 0,
          delivery_type: null,
          feature_level: null,
          status: 1,
          expected_volume: "",
          value: null,
          observation: "",
          date_at: "",
          concreted_piece_ids: []
        }
      },
      puzl_additions: [],
      puzl_addition_price: 0,
      slump_flows: [],
      isBiggerPrice: null,
      filter: {
        plant: '',
        mix_design_mixe: null
      },
    };
  },
  directives: {money: VMoney},
  methods: {
    getVariableCost(){
      if(!this.selected){
        return 0;
      }

      const totalSum =
        parseFloat(this.cycleMaximumDiff?.value ?? "") +
        parseFloat(this.transportMaximumDiff?.value ?? "") +
        parseFloat(this.concretedPieceDiff?.value ?? "") +
        parseFloat(this.slumpDiff?.value ?? "") +
        parseFloat(this.featureLevelDiff?.value ?? "");

      return totalSum;
    },
    formatNumberToBRL(value) {
      if(!value){
        return "0,00"
      }

      return Number(value)
        .toFixed(2)
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setDeliveryType() {
      const price = Number((this.formulation.minimum_price.value || '0.00').toString().replaceAll('.', '').replace(",", "."))
      if (price > 0 && this.formulation.minimum_price.delivery_type !== 1) {
        return this.formulation.minimum_price.delivery_type = 1
      }
      if (price < 0.01 && this.formulation.minimum_price.delivery_type === 1) {
        return this.formulation.minimum_price.delivery_type = null
      }
    },
    async handleSearchAdjustment() {
      let loader = this.$loading.show()
      await this.$store.dispatch('concretePrice/getPriceByPuzlAdjustment', {
        mix_design_mixe_id: this.selected.id,
        adjustments: {
          default: true,
          concrete_piece_adjustments: null,
          slump_flow_id: this.formulation.minimum_price.slump_flow_id || this.selected.slump_flow_id,
          company_plant_id: this.contract_proposal.company_plant_id,
          level_feature: this.formulation.minimum_price.feature_level,
          distance: null,
          cycle: null,
        }
      }).then(async ({data}) => {
        this.minimum_price = data.minimum_price
        this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + data.minimum_price;
        this.selected.mcc_atual = data.cmc
        this.selected.concrete_price.mcc_price = data.cmc
        this.selected.concrete_price.minimum_price = data.minimum_price
        const slump_id = this.formulation.minimum_price.slump_flow_id || this.selected.slump_flow_id
        const slump_flow_selected = this.slump_flows.find((item) => item.id == slump_id)
        if (slump_flow_selected) {
          const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
          if (percentage !== 0) {
            this.slumpDiff.contains = true
          }
          this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
        }
        const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
        if (feature_level_selected) {
          const percentage = Number(feature_level_selected.percentage)
          if (percentage !== 0) {
            this.featureLevelDiff.contains = true
          }
          this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
        }
        let params = {
          concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
        };
        await this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
          if (response.data) {
            this.concretedPieceDiff.contains = true
            this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
          }
        });
        loader.hide()
      })
    },
    /**
     * Atualiza o preço minimo
     */
    setMinimumPrice() {
      this.formulation.minimum_price.minimum_price =
        parseFloat(this.slumpDiff.value) +
        parseFloat(this.featureLevelDiff.value) +
        parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
      if (this.selected && this.loading === 0) {
        this.$snotify.info('O PREÇO MÍNIMO FOI ATUALIZADO', {
          timeout: 1000,
          icon: false,
          position: "centerTop",
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false
        });
      }
    },
    /**
     * Retorna apenas peças a concretar vinculadas a formulação
     * @returns {*}
     */
    getConcretePieces() {
      if(!this.selected) return [];

      if (this.selected.block_concreted_piece_ids && this.selected.block_concreted_piece_ids.length) {

        return this.concrete_pieces.filter(item => {
          return !this.selected.block_concreted_piece_ids.includes(item.id) || this.selected?.concreted_pieces?.includes(item.id)
        })
      }

      if (this.selected.concreted_piece_selecteds && this.selected.concreted_piece_selecteds.length) {
        return this.concrete_pieces.filter(item => {
          return this.selected.concreted_piece_selecteds.includes(item.id) || this.selected?.concreted_pieces?.includes(item.id)
        })
      }
      return this.concrete_pieces
    },
    validIsBiggerPrice() {
      if (!this.formulation.minimum_price.value) {
        return true;
      }
      const value = this.formulation.minimum_price.value.replace(',', '.').replace('.', '')
      if (value > 0) {
        return false
      }
      return true
      // if (! value > 0) {
      //   return true
      // }
      // return false
    },
    changeConcretedPiece: debounce(async function () {
      let params = {
        concreted_piece_ids: this.formulation.minimum_price.concreted_piece_ids
      };
      this.concretedPieceDiff = {
        percentage: null,
        value: 0,
        contains: false,
        name: ""
      };
      this.$Progress.start();
      this.$store.dispatch("formulations/getConcretedPieces", params).then(async response => {
        this.$Progress.finish();
        this.concretedPieceDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        };
        await this.handleSearchAdjustment()
        if (response.data) {
          this.concretedPieceDiff.contains = true
          this.percentage(this.minimum_price, response.data.percentage, response.data.mix_concreted_piece.description);
        }
        if (this.loading > 0) {
          this.loading -= 1
        }
        this.setMinimumPrice()
      });
    }, 500),
    handleSaveFormulation(){
      if(this.isEditing) {
        this.updateFormulation();
      } else {
        this.createFormulation();
      }
    },
    createFormulation() {
      let title = ''
      let body = ''
      let footer = ''
      let puzl_additions_text = ''
      // add title
      // dados do traço
      for (var feature of this.selected.features) {
        feature.item_description = feature.item_description.replace('.', ',')
        title = title.concat(feature.short_description)
        title = title.concat(' ' + this.format_mixe(feature.period, feature.period_type))
        title = title.concat(' ' + feature.item_description)
        /**
         * Regra essencial, não remover validação de null, undefined
         */
        if (feature.letter && feature.letter != null && feature.letter != undefined) {
          title = title.concat(' ' + feature.letter || '')
        }
        if (this.selected.features[this.selected.features.length - 1] != feature) {
          title = title.concat(' + ')
        }
      }
      body = body.concat(this.selected.type.technical_description || '')
      body = body.concat(this.selected.subtype ? " | " + this.selected.subtype.technical_description || '' : "")
      body = body.concat(this.selected.classification ? " | " + this.selected.classification.technical_description || '' : "")
      body = body.concat(' | ')
      body = body.concat(this.selected.slump_flow.nomenclature || '')
      body = body.concat(' | ')
      body = body.concat(this.selected.maximum_size.description || '')
      if (this.slump_flow_selected) {
        footer = footer.concat('Slump final: ' + this.slump_flow_selected.nomenclature)
      }
      for (var puzl_addition of this.puzl_additions) {
        puzl_additions_text = puzl_additions_text.concat(puzl_addition.name)
        puzl_additions_text = puzl_additions_text.concat(' (' + puzl_addition.volume + ' kg/m<sup>3</sup>)')
        if (this.puzl_additions[this.puzl_additions.length - 1] !== puzl_addition) {
          puzl_additions_text = puzl_additions_text.concat(' + ')
        }
      }
      let formulation = {
        ...this.formulation
      };
      formulation.cycle = {
        ...this.cycle
      };
      formulation.title = title
      formulation.body = body
      formulation.footer = footer
      formulation.slump_flow_id = this.formulation.minimum_price.slump_flow_id || this.selected.slump_flow_id
      formulation.puzl_additions_text = puzl_additions_text
      formulation.formulation_uuid = this.selected.formulation_uuid;
      formulation.cycle = this.prepareCycle(formulation.cycle);
      formulation.mix_design_mixe_id = this.selected.id;
      formulation.additionals = {
        cycle_maximum: this.cycleMaximumDiff,
        transport_maximum: this.transportMaximumDiff
      };
      formulation.minimum_price.value = formulation.minimum_price.value.replaceAll(".", "").replace(",", ".");
      formulation.concrete_price = this.selected.concrete_price;
      formulation.minimum_price.puzl_additions = this.puzl_additions;
      formulation.minimum_price.mixe_minimum_price = this.selected.price.value.toString().replace(",", ".");
      formulation.minimum_price.minimum_price = formulation.minimum_price.minimum_price.toString().replace(",", ".");
      formulation.minimum_price.addition_price = 0;
      formulation.contract_proposal_uuid = this.$route.params.contract_proposal_uuid;
      if (this.concretedPieceDiff.percentage) {
        formulation.additionals.concretedPiece = this.concretedPieceDiff;
      }
      if (Number(this.slumpDiff.percentage) !== 0) {
        formulation.additionals.slumpFinal = this.slumpDiff;
      }
      if (Number(this.featureLevelDiff.percentage) !== 0) {
        formulation.additionals.featureLevel = this.featureLevelDiff;
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("formulations/add", formulation)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          if (response && response.data && response.data.hasOwnProperty('confirm_service')) {
            return this.$Swal.confirmAction('Deseja ser redirecionado a tela de serviços?', ['Sim', 'Não'])
              .then((result) => {
                if (result.isConfirmed) {
                  this.$router.push("/commercial/contract-proposal/services/create/" + this.$route.params.contract_proposal_uuid);
                } else {
                  this.$router.push("/commercial/contract-proposal/formulation/" + this.$route.params.contract_proposal_uuid);
                }
              }).catch(() => this.$Progress.finish())
          }
          return this.$router.push("/commercial/contract-proposal/formulation/" + this.$route.params.contract_proposal_uuid);
        })
        .catch(error => {
          this.loadingSave = false;
          this.$Progress.finish();
          if (error && error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: message
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
        });
    },
    updateFormulation() {
      let formulation = {
        ...this.formulation
      };
      formulation.cycle = {
        ...this.cycle
      };

      if (/^(0+|0+([,.]0+)|0+\.0+)$/.test(formulation.minimum_price.expected_volume)) {
        this.$notify({
          type: 'warning',
          message: 'Volume previsto não pode ser menor ou igual a zero!'
        });
        return;
      }

      formulation.cycle = this.prepareCycle(formulation.cycle);
      formulation.mix_design_mixe_id = this.selected.id;
      formulation.formulation_uuid = this.$route.params.formulation_uuid
      formulation.additionals = {
        cycle_maximum: this.cycleMaximumDiff,
        transport_maximum: this.transportMaximumDiff
      };
      formulation.minimum_price.value = formulation.minimum_price.value
        .replaceAll('.', '')
        .replace(',', '.')
      formulation.concrete_price = this.selected.concrete_price
      /*
        dados atualizados de imposto
       */
      formulation.concrete_price.client_puzl = this.$_price.client_puzl
      formulation.concrete_price.client_puzl_name = this.$_price.client_puzl_name
      formulation.concrete_price.cycle_name = this.$_price.cycle_name
      formulation.concrete_price.cycle_price = this.$_price.cycle_price
      formulation.concrete_price.machining_price = this.$_price.machining_price
      formulation.concrete_price.mcc_price = this.$_price.mcc_price
      formulation.concrete_price.minimum_price = this.$_price.minimum_price
      formulation.concrete_price.puzl_customer = this.$_price.puzl_customer
      formulation.concrete_price.tax_name = this.$_price.tax_name
      formulation.concrete_price.tax_price = this.$_price.tax_price
      formulation.concrete_price.transport_name = this.$_price.transport_name
      formulation.concrete_price.transport_price = this.$_price.transport_price
      formulation.concrete_price.type_name = this.$_price.type_name
      formulation.concrete_price.type_price = this.$_price.type_price

      formulation.minimum_price.puzl_additions = this.puzl_additions;
      formulation.minimum_price.old_price = this.selected.minimum_price;
      formulation.minimum_price.slump_flow_id = this.formulation.minimum_price.slump_flow_id || this.selected.slump_flow_id
      formulation.minimum_price.mixe_minimum_price = this.selected.mixe_minimum_price
      formulation.minimum_price.minimum_price = formulation.minimum_price.minimum_price
        .toString()
        .replace(",", ".");
      formulation.minimum_price.addition_price = 0;
      formulation.contract_proposal_uuid = this.$route.params.contract_proposal_uuid;
      if (this.concretedPieceDiff.percentage) {
        formulation.additionals.concretedPiece = this.concretedPieceDiff;
      }
      if (Number(this.slumpDiff.percentage) !== 0) {
        formulation.additionals.slumpFinal = this.slumpDiff;
      }
      if (Number(this.featureLevelDiff.percentage) !== 0) {
        formulation.additionals.featureLevel = this.featureLevelDiff;
      }
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação."
      });
      formulation.uuid = this.selected.uuid
      if (this.selected.block_level_feature_adjustment) {
        formulation.minimum_price.feature_level = null
      }
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("formulations/update", formulation)
        .then(response => {
          this.loadingSave = false;
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message
          });
          this.$router.push(
            "/commercial/contract-proposal/formulation/" +
            this.$route.params.contract_proposal_uuid
          );
        })
        .catch(error => {
          this.loadingSave = false;
          this.$Progress.finish();
          if (error && error.response && error.response.status === 422) {
            let message = formatErrorValidation(error.response.data.errors);
            this.$notify({
              type: "danger",
              message: message
            });
          } else {
            this.$notify({
              type: error.data.error_type,
              message: error.data.message
            });
          }
        });
    },
    prepareCycle(cycle) {
      delete cycle.transport_additional_cost;
      delete cycle.transport_maximum_distance;
      cycle.plant_id = cycle.plant.id;
      delete cycle.plant;
      delete cycle.cycle_additional_cost;
      delete cycle.cycle_additional;
      return cycle;
    },
    percentageLevelResponsibility(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.featureLevelDiff.percentage = to;
      this.featureLevelDiff.value = val;
      this.featureLevelDiff.name = description;
    },
    async setSlump() {
      await this.handleSearchAdjustment()

      const slump_flow_selected = this.slump_flows.find((item) => item.id === this.formulation.minimum_price.slump_flow_id || item.id === this.selected.slump_flow_id)

      if (!slump_flow_selected) {
        this.slumpDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }

      this.slump_flow_selected = slump_flow_selected
      const percentage = Number(slump_flow_selected.percentage && slump_flow_selected.percentage.percentage)
      if (percentage !== 0) {
        this.slumpDiff.contains = true
      }
      this.percentageSlump(this.minimum_price, percentage, slump_flow_selected.nomenclature);
      this.setMinimumPrice()
    },
    async setLevelResponsibility() {
      await this.handleSearchAdjustment()
      const feature_level_selected = this.feature_levels.find((item) => item.value === this.formulation.minimum_price.feature_level)
      if (!feature_level_selected) {
        this.featureLevelDiff = {
          percentage: null,
          value: 0,
          contains: false,
          name: ""
        }
        return this.setMinimumPrice()
      }
      this.feature_level_selected = feature_level_selected
      const percentage = Number(feature_level_selected.percentage)
      if (percentage !== 0) {
        this.featureLevelDiff.contains = true
      }
      this.percentageLevelResponsibility(this.minimum_price, percentage, feature_level_selected.label);
      return this.setMinimumPrice()
    },
    percentageSlump(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.slumpDiff.percentage = to;
      this.slumpDiff.value = val;
      this.slumpDiff.name = description;
    },
    getSlumpFlow() {
      if(!this.selected?.slump_flow_ids || this.isEditing)
      {
        return [];
      }

      return this.slump_flows.filter((item) => this.selected.slump_flow_ids.includes(item.id))
    },
    setCycle(cycle) {
      this.cycle = cycle;
      this.haveCycle = true;
      for (var index in cycle) {
        const conditions = ["total_cycle", "increse_route_truck_empty_return", "return_addition", "send_addition"];
        const contains = conditions.some(el => index.includes(el));
        if (contains) {
          continue;
        }

        if (Number(cycle[index]) < 0 || cycle[index] == null) {
          this.haveCycle = false;
        }
      }
      this.cycleMaximumDiff.contains = false;
      this.cycleMaximumDiff.value = 0;
      this.cycleMaximumDiff.diff = 0;
      this.transportMaximumDiff.contains = false;
      this.transportMaximumDiff.value = 0;
      this.transportMaximumDiff.diff = 0;
      if (this.cycle.total_cycle > this.cycle.cycle_additional) {
        let diff = this.cycle.total_cycle - this.cycle.cycle_additional;
        this.cycleMaximumDiff.diff = diff;
        this.cycleMaximumDiff.value = diff * this.cycle.cycle_additional_cost;
        this.cycleMaximumDiff.contains = true;
      }
      if (this.cycle.distance > this.cycle.transport_maximum_distance) {
        let diff = this.cycle.distance - this.cycle.transport_maximum_distance;
        this.transportMaximumDiff.diff = diff;
        this.transportMaximumDiff.value = diff * this.cycle.transport_additional_cost;
        this.transportMaximumDiff.contains = true;
      }
      let selected_price = this.minimum_price;
      this.cycleMinimum = this.parseValue(this.transportMaximumDiff.value) + this.parseValue(this.cycleMaximumDiff.value) + selected_price;
      this.formulation.minimum_price.minimum_price =
        parseFloat(this.slumpDiff.value) + parseFloat(this.featureLevelDiff.value) +
        parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price);
    },
    parseValue(val) {
      if (! val) {
        return val
      }
      val = val.toString().replaceAll(",", ".");
      return parseFloat(val);
    },
    percentage(off, to, description) {
      off = off.toString().replace(",", ".");
      to = to.toString().replace(",", ".");
      let val = (off * to) / 100;
      val = val.toFixed(2);
      this.concretedPieceDiff.percentage = to;
      this.concretedPieceDiff.value = val;
      this.concretedPieceDiff.name = description
    },
    editCycle() {
      this.$refs.editCycle.openModal();
    },
    addPuzlAdmixture() {
      this.$refs.addPuzlAdmixture.openModal(this.selected);
    },
    addPuzlAdmixtureDefault(selected) {
      this.$refs.addPuzlAdmixture.handleSetDefault(selected);
    },
    savePuzlAdditions(additions) {
      const toMoney = (value) => {
        value = value.toString().replace(',', '.')
        return Number(value)
      }
      this.puzl_additions = additions;
      this.puzl_addition_price = 0
      for (var item of additions) {
        this.puzl_addition_price += toMoney(item.price)
      }
      this.formulation.minimum_price.minimum_price = parseFloat(this.slumpDiff.value) + parseFloat(this.featureLevelDiff.value) + parseFloat(this.concretedPieceDiff.value) + parseFloat(this.cycleMinimum) + parseFloat(this.puzl_addition_price)
    },
    async handleSetMixDesignForCreating(mix_design) {
      let loader = this.$loading.show();
      this.selected = mix_design;

      await this.handleSearchAdjustment()
      let filters = {
        company_plant_id: this.selected.plant.id,
        construction_id: this.contract_proposal.construction.id,
        contract_proposal_uuid: this.contract_proposal.uuid,
      };
      this.loadingCycle = true;
      await this.$store
        .dispatch("formulations/getCycleValues", {
          filter: filters
        })
        .then(async response => {
          let cycle = {
            ...this.cycle_values
          };
          const has_minimum_cost = response.data.has_minimum_cost
          if (!has_minimum_cost) {
            this.$notify({
              type: "warning",
              message: "A central selecionada não possui parâmetros de preço, entre em contato com o gestor da sua empresa."
            });
          }
          this.valid_incomplet_cycle = true
          cycle["plant"] = this.selected.plant;
          cycle["average_volume_per_bt"] = this.contract_proposal.average_volume_per_bt;
          this.$refs.editCycle.mountData(cycle)
          this.loadingCycle = false;
          this.$notify({
            type: "success",
            message: "Traço vinculado com sucesso"
          });
          this.cycleMaximumDiff = {
            diff: 0,
            value: 0,
            contains: false
          };
          this.transportMaximumDiff = {
            diff: 0,
            value: 0,
            contains: false
          };
          let date = new Date();
          let dateAt = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
          this.formulation.minimum_price.date_at = dateAt;
          this.formulation = {
            minimum_price: {
              slump_flow_id: null,
              delivery_type: null,
              concrete_removal: 0,
              minimum_price: 0,
              status: 1,
              expected_volume: "",
              value: null,
              observation: "",
              date_at: dateAt,
              concreted_piece_ids: []
            }
          };
          this.concretedPieceDiff = {
            percentage: null,
            value: 0,
            contains: false,
            name: ""
          };
          this.$store.state.formulations.concreted_piece = {};
          this.puzl_additions = [];
          this.setMinimumPrice(false)
          loader.hide();
        }).catch(error => {
          this.loadingCycle = false;
          loader.hide();
          this.$notify({
            type: error.data.error_type,
            message: error.data.message
          });
          this.selected = null
        });
    },
    handleSetMixDesignForEditing(plant_id) {
      let filters = {
        company_plant_id: plant_id,
        construction_id: this.contract_proposal.construction.id,
        formulation_uuid: this.$route.params.formulation_uuid,
        contract_proposal_uuid: this.contract_proposal.uuid,
      };
      this.loadingCycle = true;
      this.$store
        .dispatch("formulations/getCycleValues", {
          filter: filters
        })
        .then(response => {
          this.changeConcretedPiece()
          this.loadingCycle = false;
          let cycle = {
            ...this.cycle_values
          };
          cycle["plant"] = {
            plant_name: this.selected.plant.name
          };
          cycle["distance"] = this.selected.cycle.distance
          cycle["time_mixing"] = this.selected.cycle.time_mixing
          cycle["time_construction_stay"] = this.selected.cycle.time_construction_stay
          cycle["return_duration"] = this.selected.cycle.return_duration
          cycle["send_duration"] = this.selected.cycle.send_duration
          cycle["interval_releases"] = this.selected.cycle.interval_releases
          cycle[
            "average_volume_per_bt"
            ] = this.selected.cycle.average_volume_per_bt;
          this.loading -= 1
          this.$refs.editCycle.mountData(cycle);
        });
    },
    addMixe() {
      this.$refs.addMixe.openModal(this.contract_proposal.company_plant_id);
    },
  },
  mounted() {
    this.haveCycle = false;
    this.loadingFeatureLevels = true
    this.$store.dispatch("mixConcretePiece/getFeatureLevels").then(() => {
      this.loadingFeatureLevels = false;
    });

    this.loader = this.$loading.show()
    this.loading = 4
    this.transportMaximumDiff = {
      diff: 0,
      value: 0,
      contains: false
    }
    this.formulation = {
      minimum_price: {
        minimum_price: 0,
        concrete_removal: 0,
        feature_level: null,
        status: 1,
        delivery_type: null,
        expected_volume: "",
        value: null,
        observation: "",
        date_at: "",
        concreted_piece_ids: []
      }
    }
    this.concretedPieceDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.slumpDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.featureLevelDiff = {
      percentage: null,
      value: 0,
      contains: false,
      name: ''
    }
    this.puzl_additions = []
    this.$forceUpdate()
    this.$store.dispatch('plant/fetchItemsActive').then(() => {
      this.loadingPlant = false
    })
    this.$store.dispatch("mixConcretePiece/fetchItems").then(() => {
      this.loadingConcretePiece = false;
    });

    this.isEditing = Boolean(this.$route.params.formulation_uuid);

    if(this.isEditing)
    {
      this.$store
        .dispatch(
          "contractProposal/show",
          this.$route.params.contract_proposal_uuid
        )
        .then(() => {
          this.loading -= 1
          this.$store
            .dispatch(
              "formulations/show",
              this.$route.params.formulation_uuid
            )
            .then(async response => {
              this.selected = response.data;
              this.filter.plant = this.selected.plant.id
              for (var index in this.selected?.concreted_pieces) {
                this.formulation.minimum_price.concreted_piece_ids.push(this.selected.concreted_pieces[index])
              }
              this.selected.concrete_price.minimum_price = this.selected.minimum_price_mcc
              this.formulation.minimum_price.value = this.selected.value
              this.formulation.minimum_price.observation = this.selected.observation
              this.formulation.minimum_price.feature_level = this.selected.feature_level
              this.formulation.minimum_price.delivery_type = this.selected.delivery_type
              this.formulation.minimum_price.concrete_removal = this.selected.concrete_removal
              this.formulation.minimum_price.expected_volume = parseFloat(this.selected.expected_volume).toLocaleString('pt-BR', {minimumFractionDigits: 1})
              this.puzl_additions = this.selected.puzl_additions
              this.puzl_addition_price = this.selected.puzl_addition_price
              this.addPuzlAdmixtureDefault(this.selected)
              await this.handleSearchAdjustment()
              this.handleSetMixDesignForEditing(response.data.plant.id)
              this.loading -= 1
              this.$store.dispatch('mixSlumpFlow/fetchItems').then(response => {
                /**
                 * não utilizado store, para não ocorrer intereferencia. Método já está sendo usado no modal de adição do traço.
                 */
                this.slump_flows = response.data
                this.setSlump()
                this.setLevelResponsibility()
                this.loadingSlumpFlow = false
              })
            });
        });
    } else {
      this.loadingSlumpFlow = true
      this.$store.dispatch('mixSlumpFlow/fetchItems').then(response => {
        /**
         * não utilizado store, para não ocorrer intereferencia. Método já está sendo usado no modal de adição do traço.
         */
        this.slump_flows = response.data
        this.loadingSlumpFlow = false
      })

      this.$store.dispatch("contractProposal/show", this.$route.params.contract_proposal_uuid).then(() => {
        this.loading = 0;
        this.$refs.addMixe.openModal(this.contract_proposal.company_plant_id);
      });
    }
    let data = new Date();
    let dateAt = data.getFullYear() + "-" + (data.getMonth() + 1) + "-" + data.getDate();
    this.formulation.minimum_price.date_at = dateAt;
    const self = this
    this.$watch('loading', function (value) {
      if (!Boolean(value)) {
        this.loader.hide()
      }
    })
    this.$watch("formulation.minimum_price.value", function (val) {
      self.setDeliveryType()
      if (val == "0,00") {
        self.formulation.minimum_price.delivery_type = null;
        return (self.isBiggerPrice = null);
      } else {
        self.formulation.minimum_price.delivery_type = 1;
      }
      val = val.toString().replaceAll('.', '')
      val = val.toString().replace(',', '.')
      if (Number(val) >= self.formulation.minimum_price.minimum_price) {
        self.isBiggerPrice = true;
      } else {
        self.isBiggerPrice = false;
      }
    });
    this.$watch("formulation.minimum_price.minimum_price", function (val) {
      if (self.formulation.minimum_price.value === "0,00") {
        return self.isBiggerPrice = null;
      }
      let value = self.formulation.minimum_price.value
      value = value.toString().replaceAll('.', '')
      value = value.toString().replace(',', '.')
      if (parseFloat(val) <= parseFloat(value)) {
        self.isBiggerPrice = true;
      } else {
        self.isBiggerPrice = false;
      }
    });
    // Carregar configurações gerais.
    this.$store.dispatch('generalSetting/show');
    this.$store.dispatch('applicationLevelGuideline/fetchItems');
  }
}
</script>
<style scoped>
.card {
  border: 1px solid #E8E8E8 !important;
  box-shadow: 0px 5px 10px 0px #0000000D !important;
  border-radius: 8px !important;
}

.gray-divider {
  background-color: #b0b0b0;
  margin: 0;
}

.price-display {
  display: inline-flex;
  align-items: baseline;
  margin: 0;
}

.currency-symbol,
.unit-symbol {
  margin: 0 0.25rem; /* mx-1 */
  font-weight: 500;
  font-size: 10px;
}

.unit-symbol {
  text-transform: lowercase;
}

.mini-section-blue {
  border: 1px solid#EEE;
  background-color: #F2F4F9;
  border-radius: 8px;
}

.mini-section-gray {
  background-color: #E8E8E8;
  border-radius: 8px;
}

.mini-section-green {
  background-color: #F2F7F3;
  border-radius: 8px;
}

.mini-section-button {
  background-color: white;
  display: inline-flex;
  align-items: center;
  padding: 8px 12px 8px 12px !important;
  border-radius: 8px !important;
  border: 1px solid #E8E8E8;
}

.mini-section-button h4 {
  margin-left: 7px !important;
  text-transform: uppercase;
}

.mini-section-button.warning h4 {
  color: #F2B532;
}

.mini-section-button.success h4 {
  color: #149E57;
}

.input-custom-group {
  display: flex;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  height: 30px;
  border: 1px solid #ECECEC;
  background-color: #ECECEC;
}

.input-custom-group:has(.border-danger) {
  border-color: #db4539;
}

.input-custom-group input {
  box-shadow: none !important;
  border: none;
  padding-left: 5px;
  border-radius: 0;
}

.input-custom-group div {
  padding: 0px 10px;
}

.input-custom-group input, .input-custom-group div {
  display: inline-flex;
  height: 30px;
  background: white;
  align-items: center;
  font-size: 12px;
}

.input-custom-group div, .input-custom-group div nav p {
  color: #7d7d7e;
  font-size: 12px;
}

.input-custom-group > div:first-child {
  margin-right: 1px;
}

.input-custom-group > div:last-child {
  margin-left: 1px;
}

.input-custom-group div nav {
  display: flex;
  align-items: center;
}

.input-custom-group div nav p {
  text-transform: lowercase;
  margin: 0;
}

.input-custom-group input {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.input-custom-group input:focus {
  outline: none;
}

.new-minicard-green {
  background-color: #F2F7F3;
  border-radius: 15px;
  padding: 1px 12px;
  font-size: 12px;
}

.new-minicard-red {
  background-color: #FCF3F3;
  border-radius: 15px;
  padding: 1px 12px;
  font-size: 12px;
}

.new-minicard-green h4 {
  color: #149e57;
}

.new-minicard-red h4 {
  color: #db4539;
}

.new-minicard-red, .new-minicard-green {
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  text-align: center;
}

.new-minicard-red img:first-child, .new-minicard-green img:first-child {
  width: 16px;
}

.new-minicard-red h4, .new-minicard-green h4 {
  font-size: 12px;
  margin: 0 6px 0 6px;
  font-weight: normal !important;
}

.new-minicard-table {
  display: block;
}

.minicard-disabled {
  opacity: 0.6;
}

.new-minicard-table div {
  display: inline-flex;
  align-items: center;
  margin-top: 3px;
}

.actions-section {
  display: flex;
  gap: 10px;
}

.actions-section button:first-child {
  margin-left: auto !important;
}

.action-custom-button {
  display: flex;
  flex-flow: column;
  width: 130px;
  align-items: center;
  padding: 8px 12px 8px 12px !important;
  background-color: white;
  border-radius: 8px !important;
}

.action-custom-button div {
  display: inline-flex;
  gap: 10px;
  align-items: center;
}

.action-custom-button h4 {
  margin: 0;
  font-weight: 400;
}

.action-custom-button-red {
  border: 1px solid #DB4539;
}

.action-custom-button-green {
  border: 1px solid #149E57;
}

.action-custom-button-red:hover {
  background-color: #DB4539;
}

.action-custom-button-green:hover {
  background-color: #149E57;
}

.action-custom-button-red h4 {
  color: #DB4539;
}

.action-custom-button-green h4 {
  color: #149E57;
}

.action-custom-button-red:hover h4, .action-custom-button-red:hover img, .action-custom-button-green:hover h4, .action-custom-button-green:hover img {
  filter: brightness(0) invert(1);
}
</style>
<style>
.white-bg-021020241711 #vs1__combobox {
  background: white !important;
}

textarea.white-bg-021020241711 {
  border: 1px solid #ECECEC !important;
}

.inputdatepicker-031020241040 input {
  height: 30px;
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid #ECECEC !important;
}

.inputdatepicker-031020241040 input:disabled {
  color: #d5d5d5;
}

.inputdatepicker-031020241040 i {
  margin-top: 1px !important;
}

div.border-danger-091020241905 .vs__dropdown-toggle {
  border-color: #db4539 !important;
}
</style>