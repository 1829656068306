<template>
  <div>
    <modal :show.sync="modal" :backgroundColor="'#E8E8E8'" bodyClasses="p-0">
      <template slot="header">
        <div style="display: flex; align-items: center">
          <img
            src="/img/icons/icons8/ios/order-history_secondary.png"
            alt="history"
            width="24"
          />
          <h5 class="modal-title new-default-black-font m-0 ml-3">
            Histórico de contrato
          </h5>
        </div>
      </template>
      <template slot="close-button">
        <AppButton
          icon="close"
          width="auto"
          type="link"
          size="sm"
          :hasOutline="true"
          @click="closeModal"
        />
      </template>
      <div class="container px-3 py-2" style="height: 40vh; overflow-x: auto;">
        <div class="mb-1" style="font-size: 11.5px;" title="Data da criação do registro">
          <span class="text-dark" style="font-size: 11.2px;">
            Proposta:
          </span>
          {{ $helper.parseDate($_histories[0].contract_proposal_created_at, 'DD/MM/YYYY HH:mm') }}
        </div>
        <div v-if="$_histories[0].contract_proposal_converted_to_contract_at" class="mb-1" style="font-size: 11.5px;" title="Data de conversão da proposta em contrato">
          <span class="text-dark" style="font-size: 11.2px;">
            Contrato:
          </span>
          {{ $helper.parseDate($_histories[0].contract_proposal_converted_to_contract_at, 'DD/MM/YYYY HH:mm') }}
        </div>

        <div class="row" v-for="(history, index) in $_histories" :key="index">
          <div class="col-2 py-2 mt-1">
            <div class="history-vertical-line" v-if="index + 1 !== $_histories.length"></div>
            <div class="history-step">
              <div class="history-step-icon">
                <div class="history-circle-icon">
                  <AppIcon
                    :icon="status[history.status].icon"
                    :color="status[history.status].color"
                  />
                </div>
                <div class="history-step-ellipsis">
                  <nav class="circle-1"></nav>
                  <nav class="circle-2"></nav>
                  <nav class="circle-3"></nav>
                </div>
              </div>
            </div>
          </div>
          <!-- Gambiarra pra pegar criacao do contrato ja que ainda nao rastreamos isso, vai ser corrigido em breve -->
          <div class="col-10"
            v-if="index === $_histories.length - 1 && history.status === 1"
          >
            <div class="history-step-infos">
              <h4 class="new-default-black-font m-0">
                CRIAÇÃO DO CONTRATO
              </h4>
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.user_name }}
              </h6>
              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ $helper.parseDate(history.created_at, 'DD/MM/YYYY HH:mm') }}
              </h6>
            </div>
          </div>
          <div class="col-10" v-else>
            <div class="history-step-infos">
              <h4 class="new-default-black-font m-0">
                {{ status[history.status].name }}
              </h4>
              <h6 v-if="history.observation" class="new-default-gray-font font-weight-normal m-0">
                <span v-html="history.observation">
                </span>
              </h6>
              <h6 v-if="history.entity_name_before || history.entity_name_after" class="new-default-gray-font font-weight-normal m-0">
                <span v-if="history.entity_name_before" title="O nome do tomador antes da atualização: ">
                  Anterior: {{ history.entity_name_before }}
                </span>
                <br>
                <span title="O novo nome do tomador após a atualização: ">
                  Atual: {{ history.entity_name_after }}
                </span>
              </h6>

              <h6 v-if="history.seller_name_before || history.seller_name_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="O vendedor antes da atualização: ">
                  Anterior: {{ history.seller_name_before }}
                </span>
                <br>
                <span title="O novo vendedor após a atualização: ">
                  Atual: {{ history.seller_name_after }}
                </span>
              </h6>

              <h6 v-if="history.company_plant_name_before || history.company_plant_name_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="A central antes da atualização: ">
                  Anterior: {{ history.company_plant_name_before }}
                </span>
                <br>
                <span title="A nova central após a atualização: ">
                  Atual: {{ history.company_plant_name_after }}
                </span>
              </h6>

              <h6 v-if="history.address_before || history.address_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="O endereço da obra antes da atualização: ">
                  Anterior: {{ history.address_before }}
                </span>
                <br>
                <span title="O novo endereço da obra após a atualização: ">
                  Atual: {{ history.address_after }}
                </span>
              </h6>

              <h6 v-if="history.payment_term_names_before || history.payment_term_names_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="A condição de pagamento antes da atualização: ">
                  Anterior: {{ history.payment_term_names_before }}
                </span>
                <br>
                <span title="A nova condição de pagamento após a atualização: ">
                  Atual: {{ history.payment_term_names_after }}
                </span>
              </h6>

              <h6 v-if="history.payment_method_names_before || history.payment_method_names_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="O método de pagamento antes da atualização: ">
                  Anterior: {{ history.payment_method_names_before }}
                </span>
                <br>
                <span title="O novo método de pagamento após a atualização: ">
                  Atual: {{ history.payment_method_names_after }}
                </span>
              </h6>

              <h6 v-if="history.situation_name_before || history.situation_name_after" class="new-default-gray-font font-weight-normal m-0">
                <span title="A situação antes da atualização: ">
                  Anterior: {{ history.situation_name_before }}
                </span>
                <br>
                <span title="A situação após a atualização: ">
                  Atual: {{ history.situation_name_after }}
                </span>
              </h6>

              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ history.user_name }}
              </h6>

              <h6 class="new-default-gray-font font-weight-normal m-0">
                {{ $helper.parseDate(history.created_at, 'DD/MM/YYYY HH:mm') }}
              </h6>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-3" />
      <div class="container px-4 mb-3">
        <div class="row">
          <div class="offset-8 col-4 px-2">
            <AppButton
              label="Fechar"
              type="dark"
              :hasOutline="true"
              :isBlock="true"
              @click.prevent="closeModal"
            />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { AppButton, AppIcon } from "../../../../../components/AppGlobal";
import { ContractProposalStatusEnum } from "../../../../../modules/commercial/contract-proposal/Enum/ContractProposalStatusEnum";
import cursorPaginate from "@/mixins/cursorPaginate";
import {mapGetters} from "vuex";

export default {
  components: {
    AppButton,
    AppIcon,
  },
  name: "ModalContractProposalApprovalHistory",
  mixins: [cursorPaginate],
  computed: {
    ...mapGetters({
      $_histories: "contractProposal/getHistoriesItems",
    }),
  },
  data() {
    return {
      modal: false,
      validated: false,
      loading: false,
      ContractProposalStatusEnum: ContractProposalStatusEnum,
      status: {
        [ContractProposalStatusEnum.INACTIVE]: {
          name: "CONTRATO INATIVADO",
          icon: "shutdown",
          color: "danger",
        },
        [ContractProposalStatusEnum.ACTIVE]: {
          name: "CONTRATO REATIVADO",
          icon: "check",
          color: "success",
        },
        [ContractProposalStatusEnum.PENDING_REVIEW]: {
          name: "Pendência Revisão",
          icon: "hourglass",
          color: "warning",
        },
        [ContractProposalStatusEnum.PENDING_APPROVAL]: {
          name: "Pendência Aprovação",
          icon: "hourglass",
          color: "warning",
        },
        [ContractProposalStatusEnum.PROPOSAL]: {
          name: "Criação de Proposta",
          icon: "briefcase",
          color: "success",
        },
        [ContractProposalStatusEnum.COMPLETED]: {
          name: "Contrato Concluído",
          icon: "double-tick",
          color: "primary",
        },
        [ContractProposalStatusEnum.ENTITY_UPDATED]: {
          name: "Atualização de cliente/fornecedor",
          icon: "create",
          color: "success",
        },
        [ContractProposalStatusEnum.SELLER_UPDATED]: {
          name: "Alteração de vendedor",
          icon: "salary-male",
          color: "success",
        },
        [ContractProposalStatusEnum.COMPANY_PLANT_UPDATED]: {
          name: "Alteração de central",
          icon: "chemical-plant-3",
          color: "warning",
        },
        [ContractProposalStatusEnum.ADDRESS_UPDATED]: {
          name: "Alteração do endereço da obra",
          icon: "brick-wall",
          color: "warning",
        },
        [ContractProposalStatusEnum.PAYMENT_TERM_UPDATED]: {
          name: "Alteração na condição de pagamento",
          icon: "crowdfunding",
          color: "warning",
        },
        [ContractProposalStatusEnum.PAYMENT_METHOD_UPDATED]: {
          name: "Alteração no método de pagamento",
          icon: "split-transaction",
          color: "success",
        },
        [ContractProposalStatusEnum.FINANCIAL_APPROVED]: {
          name: "Aprovação financeira",
          icon: "thumbs-up",
          color: "success",
        },
        [ContractProposalStatusEnum.FINANCIAL_DISAPPROVED]: {
          name: "Reprovação financeira",
          icon: "thumbs-down",
          color: "danger",
        },
        [ContractProposalStatusEnum.FINANCIAL_PENDING]: {
          name: "Pendência financeira",
          icon: "clock",
          color: "warning",
        },
        [ContractProposalStatusEnum.SITUATION_UPDATED]: {
          name: "Situação alterada",
          icon: "blockly-blue",
          color: "primary",
        },
        [ContractProposalStatusEnum.ADJUSTMENT]: {
          name: "Reajuste de contrato",
          icon: "blockly-blue",
          color: "primary",
        },
      },
    };
  },
  methods: {
    closeModal() {
      this.modal = false;
    },
    openModal() {
      this.modal = true;
    },
    /**
     * Exibe um valor JSON formatado ou "Não informado"
     * @param {array} value
     * @returns {string}
     */
    parseJson(value) {
      if (!value) return "Não informado";
      try {
        const parsed = typeof value === "string" ? JSON.parse(value) : value;
        return Array.isArray(parsed) ? parsed.join(", ") : Object.values(parsed).join(", ");
      } catch (e) {
        return value; // Retorna como string se não for JSON válido
      }
    },
  },
};
</script>

<style>
.border-3 {
  border-width: 3px !important;
}
</style>

<style scoped lang="scss">
@import "@/style/PuzlCustom/App.scss";

.history-vertical-line {
  background: #e8e8e8;
  width: 1px;
  height: 100%;
  position: absolute;
  left: 29px;
  top: 38px;
}

.history-step-icon {
  display: inline-flex;
  align-items: center;
}

.history-circle-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #e8e8e8;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
}

.history-step-ellipsis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.history-step-ellipsis nav {
  border: 1px solid #828282;
  border-radius: 50%;
  background-color: white;
  margin: 0 5px;
}

.circle-1 {
  width: 8px;
  height: 8px;
}

.circle-2 {
  width: 4px;
  height: 4px;
}

.circle-3 {
  width: 2px;
  height: 2px;
}

.history-step-infos {
  position: relative;
  width: 100%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 12px;
  margin: 4px 0;
}

@media (max-width: 767px) {
  .nfe-spot {
    right: 10px;
    bottom: 10px;
  }
}

@media (min-width: 768px) {
  .nfe-spot {
    right: 10px;
    top: 10px;
  }
}

.nfe-spot {
  position: absolute;
  display: flex;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  border-radius: 4px;
  background-color: #fff;
  height: 26px;
  min-width: 125px;
  text-align: center;
  box-shadow: 0px 5px 10px 0px #0000000d;
}

.nfe-spot div {
  flex: auto;
  gap: 5px;
}

.nfe-spot div img,
.nfe-spot div h5 {
  display: inline-flex;
  margin-left: 7px;
  margin-right: 7px;
}

.nfe-spot div h5 {
  font-size: 12px;
  color: #2b2d32;
}

.nfe-spot div:hover img,
.nfe-spot div:hover h5 {
  filter: brightness(0) invert(1);
  color: white !important;
}

.nfe-spot.danger:hover {
  background-color: #db4539;
  border-color: #db4539;
}

.nfe-spot.warning:hover {
  background-color: #f2b532;
  border-color: #f2b532;
}

.nfe-spot.primary:hover {
  background-color: #1a70b7;
  border-color: #1a70b7;
}

.nfe-spot.success:hover {
  background-color: #149e57;
  border-color: #149e57;
}
</style>
