import { ref, watch, computed, reactive, getCurrentInstance, onMounted } from "vue";
import { dialogs } from "../../../../helpers";
import productServiceHubTypeStore from "../../../configuration/product-service-hub-type/store";

/**
 * Hook criado para substituir o mixin.
 */
export function useOperationSourceHook() {
  const store = getCurrentInstance().proxy.$store;

  const state = reactive({
    operation_source_types: [
      {
        value: 0,
        text: "ENTRADA",
        color: "success",
      },
      {
        value: 1,
        text: "SAÍDA",
        color: "danger",
      },
      {
        value: 2,
        text: "DEVOLUÇÃO",
        color: "indigo",
      },
      {
        value: 3,
        text: "TRANSFERÊNCIA",
        color: "warning",
      },
    ],
  });

  /**
   * Obs: Como os valores são fixos na store, acessa apenas o getter
   * Regimes Tributários
   */
  const option_tax_regimes = store.getters["entity/option_tax_regimes"];

  function changedTaxRegimes(value, payload) {
    if (
      value.length > 1 &&
      (value.includes("Simples Nacional") || value.includes("MEI"))
    ) {
      const is_valid =
        value.filter((item) => item === "Simples Nacional" || item === "MEI")
          .length === value.length;
      if (!is_valid) {
        payload.tax_regimes = [];
        dialogs.notify(
          "danger",
          "Não é permitido associar os regimes Simples Nacional e MEI aos demais regimes."
        );
      }
    }
  }

  /**
   * Categorias
   */
  const product_service_hub_types = ref([]);

  function updateProductServiceHubTypes() {
    const listed = productServiceHubTypeStore.getListed();
    product_service_hub_types.value = Array.isArray(listed) ? listed : [];
  };

  onMounted(() => {
    productServiceHubTypeStore.list();
    updateProductServiceHubTypes();
  });

  watch(
    () => productServiceHubTypeStore.getListed(),
    (newValue) => {
      product_service_hub_types.value = newValue || [];
    },
    { immediate: true }
  );

  const filteredHubTypes = computed(() => {
    const keepGoing = product_service_hub_types && product_service_hub_types.value &&  Array.isArray(product_service_hub_types.value);
    if (!keepGoing) {
      return [];
    }
    return product_service_hub_types.value.filter((item) => item.name !== "Serviço");
  });

  /**
   * Documentos
   */
  store.dispatch("documentTemplate/fetch");
  const document_templates = computed(() => store.getters["documentTemplate/fetch"]);
  function formattedDocumentTemplates() {
    if (!document_templates.value) return [];
    return [
      { id: null, label: "Não" },
      ...document_templates.value.map(function (item) {
        item.label = `${item.code ? item.code + " - " : ""}${item.name}`;
        return item;
      }),
    ];
  }

  /**
   * Plano de Contas Gerencial
   */
  store.dispatch("chartAccount/getFirstLevel");
  const chart_accounts = computed(() => store.getters["chartAccount/fetchFirstLevels"]);

  return {
    operation_source_types: state.operation_source_types,
    option_tax_regimes,
    formattedDocumentTemplates,
    changedTaxRegimes,
    chart_accounts,
    product_service_hub_types,
    filteredHubTypes
  };
}
