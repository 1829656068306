<template>
  <div>
    <modal :show.sync="modal.open" :backgroundColor="'#F2F4F9'" size="lg">
      <template slot="header">
        <div
          class="d-flex align-items-center container-fluid"
          style="gap: 12px; margin-left: -28px"
        >
          <div>
            <img
              src="/img/icons/icons8/ios/icon-plus-success.png"
              height="28"
              width="28"
            />
          </div>
          <span class="text-title">
            {{ modal.title }}
          </span>
        </div>
      </template>
      <div>
        <validation-observer
          v-slot="{ invalid, handleSubmit }"
          ref="formValidator"
        >
          <form
            class="needs-validation"
            @submit.prevent="handleSubmit(save)"
            autocomplete="off"
          >
            <div class="container-fluid">
              <div class="row mt-2 mb-3">
                <div class="col-12" style="display: flex; align-items: center">
                  <img
                    src="/img/icons/icons8/ios/info-squared_warning.png"
                    width="22"
                  />
                  <h4 class="new-default-black-font m-0 mx-3">Identificação</h4>
                  <hr class="ml-3 m-0" style="width: 100%" />
                </div>
              </div>
              <div class="card">
                <div class="d-flex align-items-center">
                  <div>
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      Status
                    </h4>
                  </div>
                  <div class="ml-auto" style="min-width: 130px">
                    <AppSwitch v-model="slumpflow.status" />
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      NOMENCLATURA
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          type="text"
                          v-model="slumpflow.nomenclature"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      MÍNIMO
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          inputmode="numeric"
                          v-model="slumpflow.minimum"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        <template slot="append">
                          <small
                            class="input-group-sm p-0 m-0"
                            style="text-transform: none"
                          >
                            MM
                          </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="row align-items-center" style="margin-top: 12px">
                  <div class="col-5 mt-1">
                    <h4 class="new-default-black-font font-weight-normal m-0">
                      MÁXIMO
                    </h4>
                  </div>
                  <div class="col-7">
                    <validation-provider rules="required" v-slot="{ errors }">
                      <base-input input-group-classes="input-group-sm">
                        <input
                          inputmode="numeric"
                          v-model="slumpflow.maximum"
                          class="form-control form-control-sm"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                        <template slot="append">
                          <small
                            class="input-group-sm p-0 m-0"
                            style="text-transform: none"
                          >
                            MM
                          </small>
                        </template>
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
              </div>
              <div class="row mt-4 mb-4">
                <div class="col-12 text-right">
                  <AppButton
                    label="Cancelar"
                    type="danger"
                    class="ml-2"
                    :hasOutline="true"
                    @click="closeModal()"
                  />
                  <AppButton
                    label="Salvar"
                    type="success"
                    class="ml-2"
                    native-type="submit"
                    :isDisabled="invalid"
                  />
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
const { formatErrorValidation } = require("@/plugins");
import PuzlSelect from "@/components/PuzlSelect";
import { AppButton, AppSwitch } from "../../../../../../components/AppGlobal";

export default {
  name: "Create",
  components: {
    PuzlSelect,
    AppButton,
    AppSwitch,
  },
  data() {
    return {
      modal: {
        title: "NOVO SLUMP/FLOW",
        open: false,
      },
      slumpflow: {
        status: true,
        nomenclature: null,
        minimum: null,
        maximum: null,
      },
      loadingSave: false,
      loadingSubTypes: true,
      commercialDescriptionManual: false,
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.modal.open = false;
    },
    handleCreateModal() {
      this.modal.open = true;
      this.slumpflow = {
        status: true,
        nomenclature: null,
        minimum: null,
        maximum: null,
      };
    },
    save() {
      this.$notify({
        type: "info",
        message: "Estamos trabalhando em sua solicitação.",
      });
      this.$Progress.start();
      this.loadingSave = true;
      this.$store
        .dispatch("mixSlumpFlow/add", this.slumpflow)
        .then((response) => {
          this.loadingSave = false;
          this.modal.open = false;
          this.slumpflow = {
            status: true,
            nomenclature: null,
            minimum: null,
            maximum: null,
          };
          this.$Progress.finish();
          this.$notify({
            type: response.error_type,
            message: response.message,
          });
        })
        .catch((error) => {
          if (error.status) {
            this.$Progress.finish();
            this.loadingSave = false;
            this.$notify({
              type: error.data.error_type,
              message: error.data.message,
            });
          } else {
            if (error.response.status === 422) {
              let message = formatErrorValidation(error.response.data.errors);
              this.$notify({
                type: "danger",
                message: message,
              });
              this.$Progress.finish();
              this.loadingSave = false;
            } else {
              this.$notify({
                type: error.data.error_type,
                message: error.data.message,
              });
              this.$Progress.finish();
              this.loadingSave = false;
            }
          }
        });
    },
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.$store.dispatch("mixSubtype/fetchItems").then(() => {
      this.loadingSubTypes = false;
    });
  },
  created() {},
};
</script>

<style scoped>
* {
  color: #2b2d32;
}
.card {
  box-shadow: none !important;
  border-radius: 16px !important;
  padding: 16px 12px;
  border: 1px solid #ececec;
}
</style>
