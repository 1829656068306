import { computed, getCurrentInstance } from "vue";

export function useInvoiceAllotmentHook() {
  const store = getCurrentInstance().proxy.$store;

  /**
   * SEÇÃO DE FILTROS
   * Termos de pagamento
   */
  store.dispatch("paymentTerm/fetchItems");
  const paymentTerms = computed(() => store.getters["paymentTerm/fetch"]);

  /**
   * Intermediários de pagamento
   */
  store.dispatch("paymentIntermediary/fetchItems");
  const paymentIntermediaries = computed(() => store.getters["paymentIntermediary/fetch"]);

  /**
   * Métodos de pagamento
   */
  store.dispatch("paymentMethod/fetchItems");
  const paymentMethods = computed(() => store.getters["paymentMethod/fetch"]);

  /**
   * Contas bancárias
   */
  store.dispatch("bankAccount/fetchItems", {});
  const bankAccounts = computed(() => store.getters["bankAccount/fetch"]);

  /**
   * Centrais e plants
   */
  store.dispatch("plant/fetchItemsActive");
  const companyPlants = computed(() => store.getters["plant/activeItems"]);

  /**
   * Configurações gerais
   */
  store.dispatch("generalSetting/show");
  const generalSettings = computed(() => store.getters["generalSetting/show"]);

  return {
    paymentTerms,
    paymentIntermediaries,
    paymentMethods,
    bankAccounts,
    companyPlants,
    generalSettings,
  };
}
